import { Row, Col } from "antd";
import dayjs from "dayjs";
import { Paragraph } from "@trace-one/design-system";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { ProductSeasonalityFormData } from "components/SpecificationSection/components/FormSchema/types";
import { getMonthsForSeasonalityWidget } from "components/SpecificationSection/components/FormSchema/utils";
import styles from "./styles.module.less";

const SeasonalityWidgetReadOnly: React.FC<ProductSeasonalityFormData> = ({
  startDateUtc,
  endDateUtc,
}) => {
  const { getTranslationForKey } = useFormSchemaTranslation();

  const noneTranslation = getTranslationForKey("none");
  if (!startDateUtc || !endDateUtc) {
    return (
      <Col>
        <Paragraph size="xs" color="grey-5">
          {noneTranslation}
        </Paragraph>
      </Col>
    );
  }

  const startDate = dayjs(startDateUtc);
  const endDate = dayjs(endDateUtc);

  const months: Array<{
    id: string;
    value: string;
    activeClass: string;
  }> = getMonthsForSeasonalityWidget({
    startDate,
    endDate,
    styles,
  });

  const toTranslation = getTranslationForKey("to");
  const startDateTitle = startDate.format("MMMM D");
  const endDateTitle = endDate.format("MMMM D");

  return (
    <>
      <Col>
        <div>
          <Paragraph
            size="xs"
            color="grey-5"
            className={styles.dateAvailabilityReadOnly}
          >
            {`${startDateTitle} ${toTranslation} ${endDateTitle}`}
          </Paragraph>
        </div>
      </Col>
      <Row gutter={[3, 5]} className={styles.seasonalContainerReadOnly}>
        {months.map(month => {
          const { activeClass, value } = month;

          return (
            <Col key={value} span={2} className={styles.wrapper}>
              <Paragraph size="xs">{getTranslationForKey(value)}</Paragraph>
              <div className={activeClass} />
            </Col>
          );
        })}
      </Row>
    </>
  );
};

export default SeasonalityWidgetReadOnly;
