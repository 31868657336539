import { defineMessages } from "react-intl";

export const messages = defineMessages({
  createANewMaterial: {
    id: "library.chapterForm.widgets.recipe.actions.createANewMaterial",
    defaultMessage: "Create a new material",
  },
  addAnExistingMaterial: {
    id: "library.chapterForm.widgets.recipe.actions.addAnExistingMaterial",
    defaultMessage: "Add an existing material",
  },
  modalTitle: {
    id: "library.chapterForm.widgets.recipe.addAnExistingMaterial.modalTitle",
    defaultMessage: "Select Existing materials",
  },
  modalSubTitle: {
    id:
      "library.chapterForm.widgets.recipe.addAnExistingMaterial.modalSubTitle",
    defaultMessage: "You can select one or multiple materials",
  },
  modalConfirmBtnText: {
    id:
      "library.chapterForm.widgets.recipe.addAnExistingMaterial.modalConfirmBtnText",
    defaultMessage: "Add to Recipe",
  },
  name: {
    id: "library.recipeTable.column.title.name",
    defaultMessage: "Name",
  },
  type: {
    id: "library.recipeTable.column.title.type",
    defaultMessage: "Type",
  },
  function: {
    id: "library.recipeTable.column.title.function",
    defaultMessage: "Function",
  },
  percentage: {
    id: "library.recipeTable.column.title.percentage",
    defaultMessage: "%",
  },
  totalPercentage: {
    id: "library.recipeTable.footer.title.totalPercentage",
    defaultMessage: "Total",
  },
  tableSubtitle: {
    id: "library.recipeTable.subtitle",
    defaultMessage: "Raw materials, composites and additives",
  },
});
