import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  updatePackagingSystemName,
  fetchPackagingSystemSmartDoc,
  addPackagingSystemTags,
  deletePackagingSystemTag,
  updatePackagingSystemsTemplateBlock,
  updateComponentsAssociationForPackagingSystem,
  fetchPackagingComponentCollection,
} from "apis/PKG";
import { PackagingSystemComponentData, PackagingSystemData } from "models";
import { AsyncThunkConfig } from "store";

export const updateName = createAsyncThunk<
  void,
  PackagingSystemData["name"],
  AsyncThunkConfig
>("packagingSystemForm/updateName", async (name, { getState, dispatch }) => {
  if (!name) {
    return;
  }

  const {
    packagingSystemForm: {
      generalInfo: { packagingSystemId },
    },
  } = getState();

  if (packagingSystemId) {
    await updatePackagingSystemName({
      packagingSystemId,
      name,
    });
    dispatch(getPackagingSystemSmartDoc());
  }
});

export const getPackagingSystemSmartDoc = createAsyncThunk<
  PackagingSystemData,
  void,
  AsyncThunkConfig
>("packagingSystemForm/getPackagingSystemSmartDoc", async (_, { getState }) => {
  const {
    packagingSystemForm: {
      generalInfo: { packagingSystemId },
    },
    user: { userLanguagePreference },
  } = getState();

  if (packagingSystemId) {
    const { data } = await fetchPackagingSystemSmartDoc({
      packagingSystemId,
      languageCode: userLanguagePreference,
    });

    const { componentIds } = data || {};

    const { data: components = [] } = await fetchPackagingComponentCollection({
      componentIds,
    });

    return { ...data, components };
  }
  return;
});

export const addTag = createAsyncThunk<void, string, AsyncThunkConfig>(
  "packagingSystemForm/addTag",
  async (tagId, { getState, dispatch }) => {
    const {
      packagingSystemForm: {
        generalInfo: { packagingSystemId },
      },
    } = getState();

    if (packagingSystemId) {
      await addPackagingSystemTags({
        packagingSystemId,
        tagIds: [tagId],
      });
      dispatch(getPackagingSystemSmartDoc());
    }
  }
);

export const removeTag = createAsyncThunk<void, string, AsyncThunkConfig>(
  "packagingSystemForm/removeTag",
  async (tagId, { getState, dispatch }) => {
    const {
      packagingSystemForm: {
        generalInfo: { packagingSystemId },
      },
    } = getState();

    if (packagingSystemId) {
      await deletePackagingSystemTag({
        packagingSystemId,
        tagId,
      });

      dispatch(getPackagingSystemSmartDoc());
    }
  }
);

export const updateGtin = createAsyncThunk<
  PackagingSystemData,
  { templateBlockId: string; dataAsJson: string },
  AsyncThunkConfig
>(
  "packagingSystemForm/updateGtin",
  async ({ templateBlockId, dataAsJson }, { getState }) => {
    const state = getState();

    const {
      packagingSystemForm: {
        generalInfo: { packagingSystemId },
      },
    } = state;

    if (packagingSystemId) {
      const { data } = await updatePackagingSystemsTemplateBlock({
        packagingSystemId,
        templateBlockId,
        dataAsJson,
      });

      const { componentIds } = data || {};

      const { data: components = [] } = await fetchPackagingComponentCollection(
        {
          componentIds,
        }
      );

      return { ...data, components };
    }

    return;
  }
);

export const updateComponentsAssociationForPackagingSystemAsync = createAsyncThunk<
  void,
  {
    packagingSystemId: PackagingSystemData["packagingSystemId"];
    packagingComponentIds: PackagingSystemComponentData["componentId"][];
    mainComponentId?: string;
  },
  AsyncThunkConfig
>(
  "packaging/updateComponentsForPackagingSystem",
  async (
    { packagingSystemId, packagingComponentIds, mainComponentId },
    { dispatch }
  ) => {
    if (packagingSystemId) {
      await updateComponentsAssociationForPackagingSystem({
        packagingSystemId,
        data: {
          componentIds: packagingComponentIds,
          mainComponentId,
        },
      });

      await dispatch(getPackagingSystemSmartDoc());
    }
  }
);
