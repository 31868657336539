import React, { useEffect } from "react";
import { useRedirect } from "hooks";
import { useDispatch, useSelector } from "react-redux";
import { resetRevisedSpecification } from "store/specificationDetails/specificationDetailsSlice";
import { selectRevisedSpecification } from "store/specificationDetails/selectors";
import { getOnIsRevisedChange } from "./utils";

const withReviseSpecificationAction = Component => props => {
  const dispatch = useDispatch();
  const revisedSpecification = useSelector(selectRevisedSpecification);
  const { redirectToSpecificationDetails } = useRedirect();

  useEffect(() => {
    getOnIsRevisedChange({
      dispatch,
      revisedSpecification,
      resetRevisedSpecification,
      redirectToSpecificationDetails,
    });
  }, [revisedSpecification]);

  return <Component {...props} />;
};

export default withReviseSpecificationAction;
