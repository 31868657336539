import AddButtons from "./components/AddButtons";
import Folder from "./components/Folder";
import NoProduct from "./components/NoProduct";
import Specification from "./components/Specification";

const AssignedProduct = {
  AddButtons,
  Folder,
  NoProduct,
  Specification,
};

export default AssignedProduct;
