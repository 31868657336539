import classNames from "classnames";
import claimFormStyles from "../../styles.module.less";
import { Heading, Divider, Datatable } from "@trace-one/design-system";
import { ClaimSentenceTranslation as ClaimSentenceTranslationType } from "models/claim";
import { useClaimSentenceTranslation } from "./hooks";
import { useClaimSentence } from "../ClaimConfigureContent/hooks";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { claimMessages } from "messages";

const ClaimSentenceTranslation = ({
  claimSentences,
}: {
  claimSentences: ClaimSentenceTranslationType[];
}) => {
  const { formatMessage } = useIntl();

  const { isLoading, languages } = useClaimSentence();

  const languageDictionary = useMemo(
    () =>
      languages?.reduce((acc, { languageCode, label }) => {
        acc[languageCode] = label;
        return acc;
      }, {}),
    [languages]
  );

  const { columns } = useClaimSentenceTranslation({ languageDictionary });

  return (
    <div className={classNames(claimFormStyles["section-wrapper"], "mr-3")}>
      <div className={classNames("pt-2", "pb-2", "pl-3", "pr-3")}>
        <Heading size="xs" color="grey-5">
          {formatMessage(claimMessages.claimSentenceTranslationTitle)}
        </Heading>

        <Divider height={16} color="grey-3" />

        <Datatable.Plain
          columns={columns}
          pagination={false}
          dataSource={claimSentences}
          rowKey={({ languageCode }) => languageCode}
          loading={isLoading}
          emptyText={formatMessage(
            claimMessages.claimSentenceTranslationEmptyState
          )}
        />
      </div>
    </div>
  );
};

export default ClaimSentenceTranslation;
