import { Card, Typography } from "@trace-one/design-system";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { useImageUrl } from "hooks";
import styles from "../SelectedLibraryItems/styles.module.less";
import Spin from "components/Spin";

const SelectedLibraryItem = ({ selectedLibraryItem, onRemoveClaim }) => {
  const { getTranslationForKey } = useFormSchemaTranslation();

  const { imageUrl, isDownloading } = useImageUrl({
    fileId: selectedLibraryItem?.logoFileId,
  });

  const defaultLanguageText = (
    selectedLibraryItem?.sentenceTranslations ?? []
  ).find(translation => translation.isDefault)?.text;

  return (
    <Card
      key={selectedLibraryItem.id}
      size="xxsmall"
      type="expandable"
      title={selectedLibraryItem?.name}
      headerSubtitles={selectedLibraryItem?.formattedType}
      className={styles["card-without-border-radius"]}
      headerActions={[
        {
          key: selectedLibraryItem?.id,
          type: "button",
          args: {
            iconName: "trash",
            type: "tertiary",
            color: "red",
            onClick: onRemoveClaim(selectedLibraryItem.id),
          },
        },
      ]}
    >
      <Typography variant={"body-s"} color={"grey-4"} className="mb-1">
        {getTranslationForKey(selectedLibraryItem.type)}
      </Typography>
      <div className={styles["selected-library-item-content-horizontal"]}>
        {isDownloading && (
          <div className={styles["selected-library-item-image"]}>
            <Spin size="small" />
          </div>
        )}

        {imageUrl && !isDownloading && (
          <img
            src={imageUrl}
            alt="claim-logo"
            className={styles["selected-library-item-image"]}
          />
        )}

        <Typography variant={"body-s"} color={"grey-4"}>
          {defaultLanguageText}
        </Typography>
      </div>
    </Card>
  );
};

export default SelectedLibraryItem;
