import { Datatable } from "@trace-one/design-system";
import RowTitle from "components/RowTitle";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { useColumns } from "./hooks";
import { getRowKeyForTable } from "utils/table";
import { CrossContaminationAllergensProps } from "./types";
import styles from "../../../styles.module.less";

const CrossContaminationAllergens: React.FC<CrossContaminationAllergensProps> = ({
  crossContaminationAllergens,
  uiSchema,
  displayActions,
  noItemsMessage,
}) => {
  const { getTranslationForKey } = useFormSchemaTranslation();
  const { columns } = useColumns({ uiSchema, displayActions });

  return (
    <>
      {uiSchema["ui:title"] && (
        <RowTitle
          title={getTranslationForKey(uiSchema["ui:title"])}
          displayActions={displayActions}
        />
      )}
      <Datatable
        tableLayout="fixed"
        className={styles.readOnlyTableWrapper}
        columns={columns}
        dataSource={crossContaminationAllergens}
        emptyText={noItemsMessage}
        pagination={false}
        rowKey={getRowKeyForTable("allergen")}
      />
    </>
  );
};

export default CrossContaminationAllergens;
