export const preparePropsForContent = ({ properties, disabled }) => ({
  ...properties,
  props: {
    ...properties.props,
    disabled,
    uiSchema: {
      ...properties.props.uiSchema,
      "ui:no:wrap": true,
    },
  },
});

export const isValueFieldDisabled = ({ schemaName, display }) =>
  schemaName === "gtin" || !display;
