import { defineMessages } from "react-intl";

export const messages = defineMessages({
  specificationDetails: {
    id: "specificationCreation.specificationDetails",
    defaultMessage: "Specification details",
  },
  specificationDetailsSubtitle: {
    id: "specificationCreation.specificationDetails.subtitle",
    defaultMessage: "Please, enter basic information about you specification",
  },
  assignProduct: {
    id: "specificationCreation.assignProduct",
    defaultMessage: "Assign product & supplier",
  },
  assignProductSubtitle: {
    id: "specificationCreation.assignProduct.subtitle",
    defaultMessage: "Select the product and the supplier of your specification",
  },
  createNewProduct: {
    id: "creationFolder.products.createNewProduct",
    defaultMessage: "Create a new product",
  },
  addExisitingProduct: {
    id: "creationFolder.products.addExisitingProduct",
    defaultMessage: "Add an existing product",
  },
  formProductType: {
    id: "creationFolder.form.productType",
    defaultMessage: "Product type",
  },
  formProductTypePlaceholder: {
    id: "creationFolder.form.productType.placeholder",
    defaultMessage: "Choose a product type",
  },
  formRetailerContact: {
    id: "creationFolder.form.retailerContact",
    defaultMessage: "Retailer Contact",
  },
  formRetailerContactPlaceholder: {
    id: "creationFolder.form.retailerContact.placeholder",
    defaultMessage: "Choose a Retailer contact",
  },
  firstHelpMessage: {
    id: "specificationCreation.product.addExistingProduct.firstHelpMessage",
    defaultMessage: "Add an existing product as you need in your folder.",
  },
  secondHelpMessage: {
    id: "creationFolder.products.addExistingProduct.secondHelpMessage",
    defaultMessage:
      "Don't worry, you can always add more or remove some of them later.",
  },
  noSupplier: {
    id: "creationFolder.products.noSupplier",
    defaultMessage: "No supplier",
  },
  productTypeTooltipTitle: {
    id: "creation.productTypeTooltip.title",
    defaultMessage:
      "Please remove the assigned products to change the product type",
  },

  errorFieldMessage: {
    id: "creation.errors.notSaved",
    defaultMessage: "An error has occurred, the last changes were not saved",
  },
});
