import { useSelector } from "react-redux";
import {
  archiveModificationRequest,
  completeModificationRequest,
  declineModificationRequest,
  deleteModificationRequest,
  performModificationRequest,
} from "apis/SPEC";
import { selectLanguageCode } from "store/user/selectors";
import { ModificationRequestData } from "models/modificationRequest";

export interface UseRfmPanelCardProps {
  requestId: ModificationRequestData["requestId"];
  refetchModificationRequests: Function;
}

const useRfmPanelCard = ({
  refetchModificationRequests,
  requestId,
}: UseRfmPanelCardProps) => {
  const languageCode = useSelector(selectLanguageCode);

  const onPerform = async () => {
    try {
      await performModificationRequest({ requestId, params: { languageCode } });

      refetchModificationRequests(true);
    } catch {}
  };

  const onDecline = async () => {
    try {
      await declineModificationRequest({ requestId, params: { languageCode } });

      refetchModificationRequests(true);
    } catch {}
  };

  const onDelete = async () => {
    try {
      await deleteModificationRequest({ requestId });

      refetchModificationRequests(true);
    } catch {}
  };

  const onArchive = async () => {
    try {
      await archiveModificationRequest({ requestId, params: { languageCode } });

      refetchModificationRequests(true);
    } catch {}
  };

  const onComplete = async () => {
    try {
      await completeModificationRequest({
        requestId,
        params: { languageCode },
      });

      refetchModificationRequests(true);
    } catch (error) {}
  };

  return {
    onPerform,
    onDecline,
    onDelete,
    onArchive,
    onComplete,
  };
};

export default useRfmPanelCard;
