import { useState, useEffect } from "react";
import {
  updateSpecificationSignature,
  fetchSpecificationsWaitingCount,
} from "apis/SPEC";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { selectLanguageCode, selectUserId } from "store/user/selectors";
import { ROUTES } from "utils/constants";
import { UseDoubleValidationSignProps } from "./types";

const useDoubleValidation = ({
  specificationId,
  onClose,
}: UseDoubleValidationSignProps) => {
  const [isSpecSigned, setIsSpecSigned] = useState(false);
  const [hasSignError, setHasSignError] = useState(false);
  const [isSigning, setIsSigning] = useState(false);
  const [isDone, setIsDone] = useState(false);

  const [
    specificationsWaitingNumber,
    setSpecificationsWaitingNumber,
  ] = useState(null);

  const languageCode = useSelector(selectLanguageCode);
  const { push } = useHistory();
  const userId = useSelector(selectUserId);

  const doubleValidationSignSpecification = async () => {
    try {
      await updateSpecificationSignature({
        id: specificationId,
        languageCode,
      });
      setIsSpecSigned(true);
    } catch (_) {
      setHasSignError(true);
    }
  };

  const fetchSpecificationsWaitingNumber = async () => {
    try {
      const { data } = await fetchSpecificationsWaitingCount({
        userId,
      });

      setSpecificationsWaitingNumber(data);
    } catch (_) {}
  };

  const closeModalHandler = () => {
    onClose();
    if (isDone) {
      window.location.reload();
    }
  };

  const handleRedirectionToFilteredSpecifications = (status: string) => () => {
    push(`${ROUTES.SPECIFICATION_LISTING}?statuses=${status}`);
  };

  const handleRedirectionToSpecifications = () => {
    push(`${ROUTES.SPECIFICATION_LISTING}`);
  };

  const handleSigning = () => {
    if (isSigning || isSpecSigned) {
      return;
    }

    setIsSigning(true);

    doubleValidationSignSpecification();
  };

  useEffect(() => {
    if (isSpecSigned && !hasSignError && isSigning) {
      setTimeout(() => {
        fetchSpecificationsWaitingNumber();

        setIsDone(true);

        setIsSigning(false);
      }, 1500);
    }
  }, [isSpecSigned, hasSignError, isSigning]);

  return {
    handleSigning,
    fetchSpecificationsWaitingNumber,
    isSpecSigned,
    isDone,
    hasSignError,
    specificationsWaitingNumber,
    closeModalHandler,
    handleRedirectionToFilteredSpecifications,
    handleRedirectionToSpecifications,
  };
};

export default useDoubleValidation;
