import { useSelector } from "react-redux";
import { useAppDispatch } from "store";
import { updatePackagingComponentAsync } from "store/packaging/asyncActions";
import { selectPackagingComponent } from "store/packaging/selectors";
import { SectionBlockData } from "models";

const usePackaging = () => {
  const dispatch = useAppDispatch();

  const { componentId: packagingComponentId } = useSelector(
    selectPackagingComponent
  );

  const onSaveTemplateBlock = (
    templateBlockId: SectionBlockData["templateBlockId"]
  ) => (dataAsJson: SectionBlockData["dataAsJson"]) =>
    dispatch(
      updatePackagingComponentAsync({
        packagingComponentId,
        templateBlockId,
        dataAsJson,
      })
    );

  return {
    onSaveTemplateBlock,
  };
};

export default usePackaging;
