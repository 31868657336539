import { useEffect, useState } from "react";
import { Row } from "antd";
import { Alert, Button, Datatable } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import MultipleSelectLibraryObjectModal from "components/MultipleSelectLibraryObjectModal";
import FormCard from "../../../FormCard";
import RecipeTableFooter from "components/RecipeTableFooter";
import RowTitle from "components/RowTitle";
import { useRecipeTableActions } from "./hooks";
import { usePagination, useRecipe } from "hooks";
import { useMaterialPermissions } from "components/Library/components/MaterialForm/hooks";
import { isListEmpty, isObjectEmpty } from "utils/general";
import { LIBRARY_OBJECT_TYPES } from "utils/constants";
import { getRowKeyForTable } from "utils/table";
import { getColumns, getProcessingAidIngredientsColumn } from "./utils";
import { RecipeTableProps } from "./types";
import { categorizeIngredients } from "dtos/material";
import messages from "./messages";
import libraryMessages from "messages/library";
import styles from "components/Library/components/MaterialForm/styles.module.less";

const RecipeTable: React.FC<RecipeTableProps> = ({
  recipe,
  displayActions = true,
  categoryDictionary,
  getCategoryItem,
  historyId,
  materialId,
  catalogDictionary,
}) => {
  const { ingredients, totalPercentage, recipeType, sortingFilter } = recipe;

  const [sortedIngredients, setSortedIngredients] = useState(ingredients);
  const { formatMessage } = useIntl();
  const { disableActions } = useMaterialPermissions();
  const { sorter, onTableChange } = usePagination();
  const { recipeIngredients, sortMaterialRecipeIngredients } = useRecipe({
    id: materialId,
    recipeType,
    shouldCallApi: false,
  });

  useEffect(() => {
    setSortedIngredients(ingredients);
  }, [ingredients]);

  useEffect(() => {
    if (!isListEmpty(recipeIngredients)) {
      setSortedIngredients(recipeIngredients);
    }
  }, [recipeIngredients]);

  const {
    onAddIngredients,
    onRemoveIngredient,
    onUpdateIngredientPercentage,
    onUpdateIngredientFunction,
    onOpenModal,
    onCloseModal,
    isModalOpen,
  } = useRecipeTableActions({
    sorter,
    sortRecipeIngredients: sortMaterialRecipeIngredients,
  });

  const { otherIngredients, processingAidIngredients } = categorizeIngredients(
    sortedIngredients
  );

  const columns = getColumns({
    formatMessage,
    onRemoveIngredient,
    onUpdateIngredientPercentage,
    displayActions,
    disableActions,
    categoryDictionary,
    getCategoryItem,
    updateIngredientFunction: onUpdateIngredientFunction,
    recipeSorter: isObjectEmpty(sorter) ? sortingFilter : sorter,
    canSort: otherIngredients?.length > 1,
    catalogDictionary,
  }).filter(item => !isObjectEmpty(item));

  return (
    <FormCard title={formatMessage(messages.cardTitle)} name="recipe-table">
      {displayActions && !disableActions && (
        <Alert
          closable
          type="info"
          message={formatMessage(
            libraryMessages.validationMessageSumOfPercentage
          )}
          className={styles.alertReadOnlyTableMargin}
        />
      )}

      <RowTitle title={formatMessage(messages.tableSubtitle)} />
      <Datatable
        tableLayout="fixed"
        indentSize={25}
        columns={columns}
        dataSource={otherIngredients}
        pagination={false}
        rowKey={getRowKeyForTable("ingredient")}
        emptyText={formatMessage(messages["noIngredient"])}
        className={styles.ingredientsContainer}
        footer={
          !isListEmpty(otherIngredients)
            ? () => (
                <RecipeTableFooter
                  totalPercentage={totalPercentage}
                  displayActions={displayActions}
                  type={LIBRARY_OBJECT_TYPES.MATERIALS}
                />
              )
            : null
        }
        childrenColumnName="ingredients"
        data-test-id="other-ingredients-table"
        onChange={onTableChange}
      />

      {!isListEmpty(processingAidIngredients) && (
        <div className={styles.processingAidsTableWrapper}>
          <RowTitle
            title={formatMessage(messages.processingAidsTableSubtitle)}
          />
          <Datatable
            tableLayout="fixed"
            indentSize={25}
            columns={getProcessingAidIngredientsColumn({
              formatMessage,
              onRemoveIngredient,
              onUpdateIngredientPercentage,
              updateIngredientFunction: onUpdateIngredientFunction,
              getCategoryItem,
              categoryDictionary,
              displayActions,
              disableActions,
              recipeSorter: isObjectEmpty(sorter) ? sortingFilter : sorter,
              canSort: processingAidIngredients?.length > 1,
              catalogDictionary,
            }).filter(item => !isObjectEmpty(item))}
            dataSource={processingAidIngredients}
            pagination={false}
            rowKey={ingredient => JSON.stringify(ingredient)}
            className={styles.ingredientsContainer}
            data-test-id="processing-aid-ingredients-table"
            onChange={onTableChange}
          />
        </div>
      )}

      {displayActions && (
        <Row justify="center" className={styles.rowAddButton}>
          <Button
            onClick={onOpenModal}
            data-testid="add-ingredient-made-in-house"
            disabled={disableActions}
          >
            {formatMessage(messages.addAction)}
          </Button>
        </Row>
      )}
      {isModalOpen && (
        <MultipleSelectLibraryObjectModal
          title={formatMessage(messages.modalTitle)}
          subTitle={formatMessage(messages.modalSubTitle)}
          type={LIBRARY_OBJECT_TYPES.MATERIALS}
          confirmBtnText={formatMessage(messages.modalConfirmBtnText)}
          onClose={onCloseModal}
          onConfirm={onAddIngredients}
          historyId={historyId}
          excludedLibraryItemIds={[materialId]}
        />
      )}
    </FormCard>
  );
};

export default RecipeTable;
