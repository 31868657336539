import { useMemo } from "react";
import { Col, Divider, Form, Row } from "antd";
import { Card } from "@trace-one/react-components";
import { Select, Heading, Button } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  removeRequirementProduct,
  setRequirementChapter,
} from "store/folderCreation/asyncActions";
import { selectUnitsOfMeasure } from "store/generalData/selectors";
import {
  selectChapters,
  selectErrors,
  selectProducts,
} from "store/folderCreation/selectors";
import ProductName from "components/ProductName";
import { createNameForProduct } from "utils/form";
import { getVisibleChapters } from "utils/chapters";
import {
  getDetailsForRequirementTradeItems,
  getOnChapterSelect,
  getOnRemoveProduct,
  getMessageKeyForLabel,
  getErrorsProps,
} from "./utils";
import { messages } from "./messages";
import styles from "./styles.module.less";
import { ComponentRequirementsTabsProps } from "./types";

const Requirement: React.FC<ComponentRequirementsTabsProps> = ({
  requirement,
  displayActions,
}) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const chapters = useSelector(selectChapters);
  const folderProducts = useSelector(selectProducts);
  const {
    changeChapters: chapterErrors,
    removeRequirementProducts: removeRequirementProductsErrors,
  } = useSelector(selectErrors);
  const unitsOfMeasure = useSelector(selectUnitsOfMeasure);

  const requirementProducts = useMemo(
    getDetailsForRequirementTradeItems({
      folderProducts,
      requirementTradeItemIds: requirement.tradeItemIds,
    }),
    [requirement.tradeItemIds, folderProducts]
  );

  const visibleChapters = useMemo(
    getVisibleChapters({
      chapters,
    }),
    [chapters]
  );

  const productErrorProps = getErrorsProps({
    errorMessage: formatMessage(messages.errorFieldMessage),
    removeProductsErrors:
      removeRequirementProductsErrors[requirement.requirementId],
  });

  return (
    <>
      <div
        className={styles.productsCount}
        data-test-id="number-of-assigned-requirements"
      >
        <Heading size="m">
          {formatMessage(messages.productsCount, {
            count: requirement.tradeItemIds.length,
          })}
        </Heading>
      </div>
      <Divider />
      <Form.Item
        className={styles.selectChapterLabel}
        colon={false}
        labelAlign="left"
        label={formatMessage(messages[getMessageKeyForLabel(displayActions)])}
      >
        <Select
          allowClear={false}
          data-test-id="select-chapter-for-requirement"
          disabled={!displayActions}
          value={requirement.chapterType}
          onSelect={getOnChapterSelect({
            dispatch,
            setRequirementChapter,
            requirementId: requirement.requirementId,
          })}
          options={visibleChapters.map(({ chapterType, name }) => ({
            value: chapterType,
            name: name,
          }))}
          error={chapterErrors[requirement.requirementId]}
          errorMessage={formatMessage(messages.errorFieldMessage)}
        />
      </Form.Item>
      <Row gutter={[30, 30]}>
        {requirementProducts.map(
          ({ itemName, gtin, brandName, netContents, id }) => (
            <Col xs={24} sm={24} md={24} lg={12} xl={8} key={id}>
              <Card
                title={
                  <ProductName
                    productName={createNameForProduct(
                      { gtin, itemName, brandName, netContents },
                      unitsOfMeasure
                    )}
                  />
                }
                {...(displayActions && {
                  extra: (
                    <Button
                      data-testid="remove-product"
                      type="tertiary"
                      iconName="close"
                      onClick={getOnRemoveProduct({
                        dispatch,
                        removeRequirementProduct,
                        requirementId: requirement.requirementId,
                        tradeItemId: id,
                      })}
                      color="grey"
                      size="small"
                    />
                  ),
                })}
                {...productErrorProps(id)}
              />
            </Col>
          )
        )}
      </Row>
    </>
  );
};

export default Requirement;
