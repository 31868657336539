import { isListEmpty } from "utils/general";

export const getCards = (
  titles: string[],
  contents: string[]
): Array<{ title: string; content: string }> => {
  if (
    titles.length !== contents.length ||
    isListEmpty(titles) ||
    isListEmpty(contents)
  ) {
    return [];
  }

  let cards = [];
  titles.forEach((title, i) =>
    cards.push({ title: title, content: contents[i] })
  );

  return cards;
};
