import { useIntl } from "react-intl";
import { Form } from "antd";
import SelectItem from "components/Library/components/SelectItem";
import { isLibraryObjectPublished } from "utils/library";
import { LIBRARY_OBJECTS_PAGES } from "utils/constants";
import {
  useSelectTypes,
  useChapterTypes,
  useConfirmChangeChapterType,
} from "./hooks";
import { messages } from "components/Library/components/ChapterForm/messages";
import { ChapterTypeProps } from "./types";
import { useReferenceListsItems } from "hooks";
import ConfirmChangeChapterTypeModal from "./components/ConfirmChangeChapterTypeModal";

const ChapterType: React.FC<ChapterTypeProps> = ({
  onUpdateChapterType,
  onUpdateProductType,
  chapterType,
  productTypeId,
  chapterState,
  page,
}) => {
  const { formatMessage } = useIntl();

  const {
    isLoading,
    hasError,
    chapterTypes,
    productTypeIds,
  } = useChapterTypes();

  const { refList: productTypes } = useReferenceListsItems({
    refListIds: productTypeIds,
    shouldFilterIds: false,
  });

  const { productTypeOptions, chapterTypeOptions } = useSelectTypes({
    chapterTypes,
    productTypes,
    chapterType,
    productTypeId,
  });

  const {
    selectedChapterType,
    selectedProductTypeId,
    isModalVisible,
    handleOnChangeChapterType,
    handleOnChangeProductType,
    onConfirmChangeChapterType,
    onCancelChangeChapterType,
  } = useConfirmChangeChapterType({
    onUpdateProductType,
    onUpdateChapterType,
    chapterType,
    productTypeId,
  });

  const isSelectDisabled =
    isLibraryObjectPublished(chapterState) ||
    page === LIBRARY_OBJECTS_PAGES.EDIT;

  return (
    <>
      <Form.Item
        required
        name="productTypeId"
        label={formatMessage(messages.productTypeLabel)}
        rules={[{ required: true }]}
      >
        <SelectItem
          options={productTypeOptions}
          onChange={handleOnChangeProductType}
          selectedValue={selectedProductTypeId}
          placeholder={formatMessage(messages.productTypePlaceholder)}
          disabled={isSelectDisabled}
        />
      </Form.Item>
      <Form.Item
        required
        name="type"
        label={formatMessage(messages.chapterTypeLabel)}
        rules={[{ required: true }]}
      >
        <SelectItem
          isLoading={isLoading}
          hasError={hasError}
          options={chapterTypeOptions}
          selectedValue={selectedChapterType}
          onChange={handleOnChangeChapterType}
          placeholder={formatMessage(messages.chapterTypePlaceholder)}
          disabled={isSelectDisabled}
        />
      </Form.Item>

      {isModalVisible && (
        <ConfirmChangeChapterTypeModal
          onConfirmModal={onConfirmChangeChapterType}
          onCloseModal={onCancelChangeChapterType}
        />
      )}
    </>
  );
};

export default ChapterType;
