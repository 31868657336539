import {
  addDocumentsToSpecification,
  deleteDocumentFromSpecification,
  validateSpecificationDocuments,
} from "apis/SPEC";
import { useBoolean, useFilesInfo } from "hooks";
import { File } from "models";

const useChapterDocuments = ({
  specificationId,
  chapterType,
  refetchChapter,
  fileIds: defaultFileIds,
}: {
  specificationId: string;
  chapterType: string;
  refetchChapter: Function;
  fileIds: string[];
}) => {
  const { files } = useFilesInfo(defaultFileIds);
  const { value: isValidating, setTrue, setFalse } = useBoolean(false);

  const onUploadFiles = async (selectedFiles: File[]) => {
    const fileIds = (selectedFiles ?? []).map(({ id }) => id).filter(id => id);

    if (fileIds.length) {
      try {
        await addDocumentsToSpecification({
          specificationId,
          chapterType,
          fileIds,
        });

        await refetchChapter();
      } catch (e) {}
    }
  };

  const onFileRemove = async (file: File) => {
    try {
      await deleteDocumentFromSpecification({
        specificationId,
        fileId: file?.id,
      });

      await refetchChapter();
    } catch (e) {}
  };

  const onValidateDocuments = async () => {
    try {
      setTrue();
      await validateSpecificationDocuments({
        specificationId,
        chapterType,
      });
      await refetchChapter();
    } catch (error) {
    } finally {
      setFalse();
    }
  };

  return {
    onUploadFiles,
    onFileRemove,
    files,
    isValidating,
    onValidateDocuments,
  };
};

export default useChapterDocuments;
