import { Row, Col } from "antd";
import { getColSpans } from "./utils";
import { PackagingWidgetProps } from "./types";

const PackagingWidget: React.FC<PackagingWidgetProps> = ({
  properties,
  schema,
  formContext,
}) => {
  const { validationRequired = [] } = schema;
  const { displayActions } = formContext;

  return (
    <Row gutter={[20, 10]}>
      {properties.map(property => {
        const { props } = property.content;
        const { name, uiSchema } = props;
        const colSpans = getColSpans({
          itemSpan: uiSchema["ui:itemSpan"],
          displayActions,
        });

        return (
          <Col {...colSpans} key={name} data-testid={`packaging-${name}`}>
            {{
              ...property.content,
              props: {
                ...props,
                required: validationRequired.includes(name),
              },
            }}
          </Col>
        );
      })}
    </Row>
  );
};

export default PackagingWidget;
