import { isObjectEmpty } from "utils/general";

export const isLastRow = ({ index, gridRows }) => {
  return index < gridRows.length - 1;
};

export const onCellValueChanged = ({ onChange }) => cell => {
  const {
    node: { rowIndex },
    column: { colId },
    newValue,
    data,
  } = cell;

  onChange({ rowIndex, name: colId, value: newValue, data });
};

export const onCustomCellValueChanged = ({
  onChange,
  rowIndex,
  colId,
  data,
}) => newValue => {
  let cellInfo = {
    node: { rowIndex },
    column: { colId },
    data,
  };
  switch (typeof newValue) {
    case "string":
    case "boolean":
    case "object":
      cellInfo.newValue = newValue;
      break;
    default:
      const {
        target: { checked },
      } = newValue;

      cellInfo.newValue = checked;
      break;
  }

  onCellValueChanged({ onChange })(cellInfo);
};

export const onCustomCellClear = ({
  onChange,
  rowIndex,
  colId,
  data,
}) => () => {
  onCellValueChanged({ onChange })({
    node: { rowIndex },
    column: { colId },
    newValue: null,
    data,
  });
};

export const onGridReady = ({ setGridApi }) => params => {
  setGridApi(params.api);
};

export const getGridData = gridApi => {
  let gridData = [];
  gridApi.forEachNode(node => gridData.push(node.data));
  return gridData;
};

export const getOnAddRows = ({ gridApi, rows, index }) => {
  gridApi.applyTransaction({
    add: rows,
    addIndex: index,
  });
};

export const getOnRemoveRows = ({ gridApi, rows }) => {
  gridApi.applyTransaction({ remove: rows });
};

export const getOnUpdateGrid = ({ gridApi, updateItems }) => {
  if (gridApi) {
    updateItems();
  }
};

export const getOnUpdateRows = ({ gridApi, rows }) => {
  gridApi.applyTransaction({ update: rows });
};

export const getOnUpdateRowValues = ({ currentDataRow, newDataRow }) => {
  if (!isObjectEmpty(currentDataRow)) {
    Object.keys(currentDataRow).forEach(key => {
      if (currentDataRow[key] !== newDataRow[key]) {
        currentDataRow[key] = newDataRow[key];
      }
    });
  }

  if (!isObjectEmpty(newDataRow)) {
    Object.keys(newDataRow).forEach(key => {
      if (currentDataRow[key] !== newDataRow[key]) {
        currentDataRow[key] = newDataRow[key];
      }
    });
  }
};
