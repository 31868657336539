import { Label } from "@trace-one/react-components";
import { ItemProps } from "./types";

const Item: React.FC<ItemProps> = ({ children, label, required }) => {
  return (
    <Label required={required} title={label}>
      {children}
    </Label>
  );
};

Item.defaultProps = {
  label: "",
  required: false,
};

export default Item;
