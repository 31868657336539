import { LibraryObjectData } from "models";

export const prepareSelectedLibraryObject = ({
  libraryObject,
  selectedLibraryObjects,
}: {
  libraryObject: LibraryObjectData;
  selectedLibraryObjects: LibraryObjectData[];
}) => {
  if (
    selectedLibraryObjects.findIndex(item => item.id === libraryObject.id) > -1
  ) {
    return selectedLibraryObjects.filter(item => item.id !== libraryObject.id);
  }
  return [...selectedLibraryObjects, libraryObject];
};
