import { defineMessages } from "react-intl";

export const messages = defineMessages({
  home: {
    id: "navMenu.home",
    defaultMessage: "Home",
  },

  specifications: {
    id: "navMenu.specifications",
    defaultMessage: "Specification",
  },

  folderList: {
    id: "navMenu.specifications.folderList",
    defaultMessage: "Folder List",
  },

  specificationList: {
    id: "navMenu.specifications.specificationList",
    defaultMessage: "Specification List",
  },

  translationList: {
    id: "navMenu.specifications.translationList",
    defaultMessage: "Translation list",
  },

  tags: {
    id: "navMenu.administration.tags",
    defaultMessage: "Tags",
  },

  library: {
    id: "navMenu.library",
    defaultMessage: "Library",
  },
});

export default messages;
