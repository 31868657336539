import { defineMessages } from "react-intl";

export const messages = defineMessages({
  templateModalTitle: {
    id: "template.modal.title",
    defaultMessage: "Template list",
  },
  allTemplates: {
    id: "template.allTemplates",
    defaultMessage: "All templates",
  },
  searchPlaceholder: {
    id: "template.modal.searchPlaceholder",
    defaultMessage: "Search by template name",
  },
});
