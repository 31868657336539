import { SchemaPropertyList } from "components/SpecificationSection/types";
import { isObjectEmpty } from "utils/general";

export const getExternalCategoryTreeSelectName = (
  name?: string,
  list?: SchemaPropertyList
) => {
  if (!name && isObjectEmpty(list)) {
    return "";
  }

  return name ? name : `${list.api}-${list.route}`;
};
