import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { fetchFolder } from "apis/SPEC";
import { FolderData } from "models";
import { selectLanguageCode } from "store/user/selectors";

const useFolderDetails = () => {
  const { folderId } = useParams<{
    folderId: FolderData["folderId"];
  }>();
  const languageCode = useSelector(selectLanguageCode);

  const [folderDetails, setFolderDetails] = useState<FolderData>({});
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const fetchFolderDetails = async () => {
    setIsLoading(true);
    setHasError(false);

    try {
      const { data } = await fetchFolder({
        languageCode,
        id: folderId,
      });

      setFolderDetails(data);
    } catch (error) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchFolderDetails();
  }, [folderId, languageCode]);

  return { folderDetails, isLoading, hasError };
};

export default useFolderDetails;
