import { useState } from "react";
import { useSelector } from "react-redux";
import { exportSpecificationHistoryChanges } from "apis/SPEC";
import { selectLanguageCode } from "store/user/selectors";
import { HistorisationFilters } from "components/SpecificationHistorisationModal/types";
import { getSelectedFiltersQueryParams } from "../useSpecificationHistorisation/utils";
import { SpecificationData } from "models";

const useExportAction = ({
  specificationId,
  selectedFilters,
}: {
  specificationId: SpecificationData["id"];
  selectedFilters: HistorisationFilters;
}) => {
  const languageCode = useSelector(selectLanguageCode);
  const [isExportInProgress, setIsExportInProgress] = useState(false);

  const handleExport = async () => {
    try {
      setIsExportInProgress(true);

      const {
        modifiedByUserIds,
        actions,
        formattedDates,
        chapters,
      } = getSelectedFiltersQueryParams(selectedFilters);

      const respond = await exportSpecificationHistoryChanges({
        specificationId,
        params: {
          languageCode,
          fromDate: formattedDates[0],
          toDate: formattedDates[1],
        },
        bodyParams: {
          modifiedByUserIds,
          actions,
          chapters,
        },
      });

      if (respond.data) {
        const file = new Blob([respond.data], {
          type: "text/csv",
        });
        const fileURL = URL.createObjectURL(file);

        window.open(fileURL);
      }
    } catch (_) {
    } finally {
      setIsExportInProgress(false);
    }
  };
  return {
    isExportInProgress,
    handleExport,
  };
};

export default useExportAction;
