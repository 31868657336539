import { MenuActions } from "types/general";
import { isObjectEmpty } from "utils/general";

export const getActionList = ({
  actions,
  displayActionList,
}: {
  actions: {
    [key: string]: MenuActions;
  };
  displayActionList: boolean;
}) => {
  if (!displayActionList) return [];
  if (isObjectEmpty(actions)) return [];

  let currentActions = [];

  for (const [key, action] of Object.entries(actions)) {
    switch (key) {
      case "edit":
      case "revise":
      case "resume":
        currentActions.push({ ...action, label: action.name });
    }
  }

  return currentActions;
};
