import { Heading, Tooltip } from "@trace-one/design-system";
import classnames from "classnames";
import styles from "./styles.module.less";
import { ProductNameProps } from "./types";

const ProductName: React.FC<ProductNameProps> = ({
  productName,
  isHighlighted = false,
  productGtin = "",
}) => {
  return (
    <Tooltip text={productName} placement="topLeft">
      <Heading
        className={classnames(
          styles.title,
          isHighlighted && styles.highlighted
        )}
        size="xs"
        data-testid={`trade-item-${productGtin}`}
      >
        {productGtin && (
          <span className={styles.tradeItemGtin1}>{productGtin} - </span>
        )}
        {productName}
      </Heading>
    </Tooltip>
  );
};

export default ProductName;
