import { defineMessages } from "react-intl";

const messages = defineMessages({
  folder: {
    id: "stepper.folder",
    defaultMessage: "Folder",
  },
  specificationProduct: {
    id: "stepper.product",
    defaultMessage: "Product",
  },
  folderProduct: {
    id: "stepper.products",
    defaultMessage: "Products",
  },
  invitation: {
    id: "stepper.invitation",
    defaultMessage: "Invitation",
  },
  specification: {
    id: "stepper.specification",
    defaultMessage: "Specification",
  },
  folderSpecifications: {
    id: "stepper.spec",
    defaultMessage: "Specifications",
  },
  summary: {
    id: "stepper.summary",
    defaultMessage: "Summary",
  },
});

export default messages;
