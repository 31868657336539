import { useContext } from "react";
import { useIntl } from "react-intl";
import { Button } from "@trace-one/design-system";
import { SelectedChapterContext } from "pages/Specification/components/Details/components/SelectedChapter/contexts";
import RequirementCardContent from "../RequirementCardContent";
import { messages } from "pages/Specification/components/Details/messages";
import styles from "./styles.module.less";

export interface RequirementListProps {
  onOpenModal?: () => void;
}

function RequirementsList({ onOpenModal }: RequirementListProps) {
  const { formatMessage } = useIntl();

  const {
    chapterData: { requirements },
  } = useContext(SelectedChapterContext);

  return (
    <div className={styles.requirementsList}>
      {requirements?.map(requirement => {
        return (
          <RequirementCardContent
            key={requirement.requirementId}
            requirement={requirement}
          />
        );
      })}
      {onOpenModal && (
        <Button type="tertiary" onClick={onOpenModal}>
          {formatMessage(messages.addRequirements)}
        </Button>
      )}
    </div>
  );
}

export default RequirementsList;
