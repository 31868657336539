import { useState } from "react";
import { useIntl } from "react-intl";
import { TextArea } from "@trace-one/design-system";
import { claimMessages } from "messages/claim";

export const TranslationEditor = ({ onChange, selectedTabKey, text }) => {
  const { formatMessage } = useIntl();
  const [value, setValue] = useState(text);

  return (
    <TextArea
      value={value}
      onChange={({ target: { value } }) => {
        setValue(value);
        onChange(value);
      }}
      placeholder={formatMessage(claimMessages.claimSentenceLabel)}
      key={selectedTabKey}
      rows={5}
      data-test-id={`claim-form-sentence-translation-${selectedTabKey}`}
    />
  );
};
