import PropTypes from "prop-types";
import { Row, Col } from "antd";
import { Label } from "@trace-one/react-components";
import { useIntl } from "react-intl";
import Spin from "components/Spin";
import Card from "components/Card";
import ProductSpecification from "./components/ProductSpecification";
import { isListEmpty } from "utils/general";
import { getHasSpecificationError, getLabelMessageKey } from "./utils";
import { messages } from "./messages";
import styles from "./styles.module.less";

const ProductSpecifications = ({
  displayActions,
  displayActionList,
  displayCheckbox,
  isLoading,
  onChange,
  removeNewSpecification,
  specifications,
  newSpecifications,
  addSpecificationErrors,
  removeSpecificationErrors,
}) => {
  const intl = useIntl();

  if (isListEmpty(newSpecifications) && isListEmpty(specifications)) {
    return null;
  }

  const hasSpecificationError = getHasSpecificationError({
    addSpecificationErrors,
    removeSpecificationErrors,
  });

  const colSpans = {
    xs: 24,
    sm: 24,
    md: 12,
    lg: 8,
  };

  return (
    <div className={styles.existingSpecifications}>
      <Label
        title={intl.formatMessage(
          messages[getLabelMessageKey({ displayActions, displayActionList })]
        )}
      >
        <Row gutter={[25, 25]}>
          {newSpecifications.map(({ specificationId, supplierName }) => (
            <Col key={specificationId} {...colSpans}>
              <Card.Object
                isSelected
                hasError={hasSpecificationError(specificationId)}
                errorMessage={intl.formatMessage(messages.errorFieldMessage)}
                object={{
                  name: supplierName,
                  type: "specification",
                }}
                {...(displayActions && {
                  onDelete: removeNewSpecification(specificationId),
                })}
              />
            </Col>
          ))}
          {isLoading ? (
            <Spin />
          ) : (
            specifications.map(specification => (
              <Col key={specification.specificationId} {...colSpans}>
                <ProductSpecification
                  displayActions={displayActions}
                  displayActionList={displayActionList}
                  displayCheckbox={displayCheckbox}
                  onChange={onChange}
                  specification={specification}
                  hasError={hasSpecificationError(
                    specification.specificationId
                  )}
                  errorMessage={intl.formatMessage(messages.errorFieldMessage)}
                />
              </Col>
            ))
          )}
        </Row>
      </Label>
    </div>
  );
};

ProductSpecifications.defaultProps = {
  displayActions: true,
  displayCheckbox: true,
  displayActionList: false,
  onChange: () => {},
  removeNewSpecification: () => () => {},
  specifications: [],
  newSpecifications: [],
  addSpecificationErrors: {},
  removeSpecificationErrors: {},
};

ProductSpecifications.propTypes = {
  displayActions: PropTypes.bool,
  displayActionList: PropTypes.bool,
  displayCheckbox: PropTypes.bool,
  isLoading: PropTypes.bool,
  onChange: PropTypes.func,
  specifications: PropTypes.array,
  newSpecifications: PropTypes.array,
  removeNewSpecification: PropTypes.func,
  addSpecificationErrors: PropTypes.object,
  removeSpecificationErrors: PropTypes.object,
};

export default ProductSpecifications;
