import { useEffect, useMemo, useState } from "react";
import { RadioChangeEvent } from "antd";
import { useListItems } from "hooks";
import { SpecListRadioWidgetProps } from "../../types";
import { isWidgetDisabled } from "../../../utils";

const useSpecListRadioWidget = ({
  disabled,
  formContext,
  onChange: jsonOnChange,
  readonly,
  schema,
  value,
}: SpecListRadioWidgetProps) => {
  const [radioValue, setRadioValue] = useState(value);

  const { propertyId, list } = schema;

  const { readonlyAsDisabled = true, isWidgetEditable } = formContext;

  const { items } = useListItems(list);

  const isDisabled = useMemo(
    () =>
      isWidgetDisabled({
        isWidgetEditable: isWidgetEditable(propertyId),
        disabled,
        readonlyAsDisabled,
        readonly,
      }),
    [disabled, readonlyAsDisabled, readonly, propertyId]
  );

  const onChange = ({ target: { value } }: RadioChangeEvent) => {
    jsonOnChange(value);

    setRadioValue(value);
  };

  useEffect(() => {
    setRadioValue(value);
  }, [value]);

  return {
    options: items,
    onChange,
    value: radioValue,
    disabled: isDisabled,
  };
};

export default useSpecListRadioWidget;
