import { defineMessage } from "react-intl";

export const messages = defineMessage({
  title: {
    requirement: {
      id: "library.draftValidation.title.requirement",
      defaultMessage: "Requirement already validated",
    },
  },
  content: {
    requirement: {
      id: "library.draftValidation.content.requirement",
      defaultMessage:
        "Another user finalized the validation of this requirement",
    },
  },
});
