import { defineMessages } from "react-intl";

export const messages = defineMessages({
  productName: {
    id: "product.productName",
    defaultMessage: "Product name",
  },
  gtin: {
    id: "filters.gtin",
    defaultMessage: "GTIN",
  },
  productBrand: {
    id: "filters.productBrand",
    defaultMessage: "Brand",
  },
  category: {
    id: "filters.category",
    defaultMessage: "Category",
  },
  type: {
    id: "product.type",
    defaultMessage: "Type",
  },
  status: {
    id: "product.status",
    defaultMessage: "Status",
  },
  netContents: {
    id: "product.netContents",
    defaultMessage: "Net content(s)",
  },

  comingSoon: {
    id: "product.comingSoon",
    defaultMessage: "Coming soon",
  },
});
