/* istanbul ignore file */

import { isListEmpty } from "utils/general";
import { ModificationRequestViewModel } from "viewModels/modificationRequest";

export const getSubSectionsData = data => {
  return data.map(
    ({
      specificationId,
      templateSectionId,
      specificationSectionId,
      subSections,
      specificationBlocks,
      permissions,
      linkedLibrarySectionId,
    }) => {
      return {
        specificationId,
        templateSectionId,
        specificationSectionId,
        specificationBlocks,
        permissions,
        linkedLibrarySectionId,
        subSections: isListEmpty(subSections)
          ? []
          : getSubSectionsData(subSections),
      };
    }
  );
};

export const updateModificationRequests = ({
  existingRequests,
  updatedRequest,
}: {
  existingRequests: ModificationRequestViewModel[];
  updatedRequest: ModificationRequestViewModel;
}) => {
  if (isListEmpty(existingRequests)) {
    return;
  }

  return existingRequests?.map(request => {
    if (request.requestId === updatedRequest.requestId) {
      return updatedRequest;
    }
    return request;
  });
};
