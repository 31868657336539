import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Form } from "antd";
import { Heading, Tag } from "@trace-one/design-system";
import {
  selectLanguageCode,
  selectOwningCompanyId,
} from "store/user/selectors";
import { fetchReferenceListsItem } from "apis/RLMD";
import { isObjectEmpty } from "utils/general";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { useReferenceListsItems, useProduct } from "hooks";
import CategoriesListing from "./components/CategoriesListing";
import NetContentsListing from "./components/NetContentsListing";
import ProductWidgetReadOnly from "./components/ProductWidgetReadOnly";
import FormItem from "./components/FormItem";
import RoSeasonality from "./components/RoSeasonality";
import { ProductWidgetProps } from "./types";
import styles from "./styles.module.less";

const ProductWidget: React.FC<ProductWidgetProps> = ({
  formContext,
  formData,
}) => {
  const languageCode = useSelector(selectLanguageCode);
  const { getTranslationForKey } = useFormSchemaTranslation();
  const { displayActions, emptyValue } = formContext;
  const [typeValue, setTypeValue] = useState(emptyValue);
  const owningCompanyId = useSelector(selectOwningCompanyId);

  const { id } = formData;

  const { productData } = useProduct({ productId: id });

  const { refListDictionary } = useReferenceListsItems({
    refListIds:
      productData?.netContents?.map(({ unitOfMeasure }) => unitOfMeasure) || [],
  });

  const rowProps = {
    gutter: 10,
    className: styles.noPageBreak,
  };

  const colSpans = {
    xs: displayActions ? 12 : 6,
    sm: 6,
    md: 6,
    lg: 6,
  };

  useEffect(() => {
    const getType = async () => {
      try {
        const { data: type } = await fetchReferenceListsItem({
          languageCode,
          id: productData?.templateTypeId,
          companyId: owningCompanyId,
        });
        setTypeValue(type.text);
      } catch (_) {}
    };

    if (productData?.templateTypeId) {
      getType();
    }
  }, [languageCode, productData?.templateTypeId]);

  if (isObjectEmpty(productData)) {
    return null;
  }

  if (!displayActions) {
    return (
      //@ts-ignore
      <ProductWidgetReadOnly
        {...{
          formContext,
          formData,
          productData,
          refListDictionary,
          typeValue,
        }}
      />
    );
  }

  return (
    <>
      <Row gutter={[2, 10]} className={styles.noPageBreak}>
        <Heading size="xs">{productData?.itemName} </Heading>
        <Heading className={styles.brandName} size="xs">
          {" - "}
          {productData?.brandName}
        </Heading>
      </Row>
      <Row {...rowProps}>
        <Col>
          <Tag
            label={`${getTranslationForKey(
              "templateTypeIdTitle"
            )}: ${typeValue}`}
            size="medium"
            color="grey"
            mode="light"
          />
        </Col>
        <CategoriesListing
          categories={productData?.productCategories}
          title={getTranslationForKey("categoryTitle")}
        />
      </Row>
      <Row {...rowProps}>
        <Col {...colSpans}>
          <NetContentsListing
            netContents={productData?.netContents}
            refListDictionary={refListDictionary}
            title={getTranslationForKey("netContentTitle")}
          />
        </Col>
        <Col {...colSpans}>
          <FormItem
            label={getTranslationForKey("dryWeightTitle")}
            emptyValue={emptyValue}
          />
        </Col>
        <Form.Item
          label={getTranslationForKey("seasonalityTitle")}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <RoSeasonality
            startDateUtc={productData?.seasonalAvailabilityStartDate}
            endDateUtc={productData?.seasonalAvailabilityEndDate}
          />
        </Form.Item>
      </Row>
      <Row {...rowProps}>
        <Col {...colSpans}>
          <FormItem
            label={getTranslationForKey("mainPackagingTitle")}
            emptyValue={emptyValue}
          />
        </Col>
        <Col {...colSpans}>
          <FormItem
            label={getTranslationForKey("packagingTypeTitle")}
            emptyValue={emptyValue}
          />
        </Col>
      </Row>
      <hr />
      <Row {...rowProps} justify="space-between">
        <Col span={6}>
          <FormItem
            label={getTranslationForKey("gtinTitle")}
            value={productData?.gtin}
            dataTestId="product-gtin"
            emptyValue={emptyValue}
          />
        </Col>
        <Col span={6}>
          <FormItem
            label={getTranslationForKey("ean7Title")}
            value={productData?.ean7Weight}
            emptyValue={emptyValue}
          />
        </Col>

        <Col span={12}>
          <FormItem
            label={getTranslationForKey("internalGencodeTitle")}
            emptyValue={emptyValue}
          />
        </Col>
      </Row>
    </>
  );
};

export default ProductWidget;
