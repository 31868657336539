import { useEffect, useRef } from "react";
import { InputNumber } from "@trace-one/design-system";
import { JSONSchema7 } from "json-schema";
import validateFormData from "@rjsf/core/lib/validate";
import { useInputNumberProps } from "components/SpecificationSection/components/FormSchema/hooks";
import { getServingValue } from "../../utils";
import { AjvError } from "@rjsf/core";
import { isListEmpty } from "utils/general";
import ErrorsList from "components/ErrorsList";
import { NutritionServingValueProps } from "./types";

const NutritionServingValue: React.FC<NutritionServingValueProps> = ({
  fieldKey,
  formContext,
  extraActions,
  roundedDailyIntakeSchema,
  unroundedDailyIntakeSchema,
  roundedValueSchema,
  unroundedValueSchema,
  nutrient,
  servingId,
  errors,
  automaticCalculation,
  hasUpdatePermission,
}) => {
  const { setErrors, transformErrors } = formContext;

  const roundedValueRef = useRef(null);
  const unroundedValueRef = useRef(null);
  const roundedDailyIntakeRef = useRef(null);
  const unroundedDailyIntakeRef = useRef(null);
  const errorPathRef = useRef("");
  const isFirstRender = useRef(true);

  useEffect(() => {
    const servingValue = getServingValue({
      servings: nutrient.servings,
      servingId,
      key: fieldKey,
    });

    const servingIndex = nutrient.servings?.findIndex(
      serving => serving.servingId === servingId
    );

    if (fieldKey === "roundedDailyIntake") {
      roundedDailyIntakeRef.current = servingValue || null;
    } else if (fieldKey === "unroundedDailyIntake") {
      unroundedDailyIntakeRef.current = servingValue || null;
    } else if (fieldKey === "roundedValue" && !automaticCalculation) {
      roundedValueRef.current = servingValue || null;
    } else if (fieldKey === "unroundedValue" && !automaticCalculation) {
      unroundedValueRef.current = servingValue || null;
    }

    errorPathRef.current = `.nutrientFamilies.${nutrient.key}.servings[${servingIndex}].${fieldKey}`;
  }, [nutrient.servings, servingId, fieldKey]);

  const handleChangeAutomaticCalculation = () => {
    if (!isFirstRender.current) {
      let data;
      if (fieldKey === "roundedDailyIntake") {
        data = {
          dailyIntake: {
            servingId,
            roundedValue: roundedDailyIntakeRef.current ?? 0,
            unroundedValue:
              getServingValue({
                servings: nutrient.servings,
                servingId,
                key: "unroundedDailyIntake",
              }) ?? 0,
          },
        };
      } else if (fieldKey === "unroundedDailyIntake") {
        data = {
          dailyIntake: {
            servingId,
            roundedValue:
              getServingValue({
                servings: nutrient.servings,
                servingId,
                key: "roundedDailyIntake",
              }) ?? 0,
            unroundedValue: unroundedDailyIntakeRef.current ?? 0,
          },
        };
      }

      extraActions?.updateSpecificationNutritionDeclarationAction({
        nutrientId: nutrient.nutrientId,
        data,
      });
    } else {
      isFirstRender.current = false;
    }
  };

  const handleChangeManualCalculation = () => {
    if (!isFirstRender.current) {
      let data;
      if (fieldKey === "roundedDailyIntake") {
        data = {
          roundedDailyIntake: roundedDailyIntakeRef.current ?? 0,
          ...preparePayloadData({ automaticCalculation, fieldKey }),
        };
      } else if (fieldKey === "unroundedDailyIntake") {
        data = {
          unroundedDailyIntake: unroundedDailyIntakeRef.current ?? 0,
          ...preparePayloadData({ automaticCalculation, fieldKey }),
        };
      } else if (fieldKey === "unroundedValue") {
        data = {
          unroundedValue: unroundedValueRef.current ?? 0,
          ...preparePayloadData({ automaticCalculation, fieldKey }),
        };
      } else if (fieldKey === "roundedValue") {
        data = {
          roundedValue: roundedValueRef.current ?? 0,
          ...preparePayloadData({ automaticCalculation, fieldKey }),
        };
      }

      extraActions?.updateSpecificationNutritionDeclarationManualServingAction({
        nutrientId: nutrient.nutrientId,
        servingId,
        data,
      });
    } else {
      isFirstRender.current = false;
    }
  };

  const inputNumberPropsRoundedDailyIntake = useInputNumberProps({
    // @ts-ignore
    properties: roundedDailyIntakeSchema,
    onChange: (value: number) => {
      if (value) {
        if (hasErrors(value, roundedDailyIntakeSchema)) return;
        if (isFirstRender.current) isFirstRender.current = false;

        roundedDailyIntakeRef.current = value;
      } else {
        if (!isFirstRender.current) roundedDailyIntakeRef.current = 0;
      }
      automaticCalculation
        ? handleChangeAutomaticCalculation()
        : handleChangeManualCalculation();
    },
    value: roundedDailyIntakeRef.current,
  });

  const inputNumberPropsUnroundedDailyIntake = useInputNumberProps({
    // @ts-ignore
    properties: unroundedDailyIntakeSchema,
    onChange: (value: number) => {
      if (value) {
        if (hasErrors(value, unroundedDailyIntakeSchema)) return;
        if (isFirstRender.current) isFirstRender.current = false;

        unroundedDailyIntakeRef.current = value;
      } else {
        if (!isFirstRender.current) unroundedDailyIntakeRef.current = 0;
      }
      automaticCalculation
        ? handleChangeAutomaticCalculation()
        : handleChangeManualCalculation();
    },
    value: unroundedDailyIntakeRef.current,
  });

  const inputNumberPropsUnroundedValue = useInputNumberProps({
    // @ts-ignore
    properties: unroundedValueSchema,
    onChange: (value: number) => {
      if (value) {
        if (hasErrors(value, unroundedValueSchema)) return;
        if (isFirstRender.current) isFirstRender.current = false;

        unroundedValueRef.current = value;
      } else {
        if (!isFirstRender.current) unroundedValueRef.current = 0;
      }
      automaticCalculation
        ? handleChangeAutomaticCalculation()
        : handleChangeManualCalculation();
    },
    value: unroundedValueRef.current,
  });

  const inputNumberPropsRoundedValue = useInputNumberProps({
    // @ts-ignore
    properties: roundedValueSchema,
    onChange: (value: number) => {
      if (value) {
        if (hasErrors(value, roundedValueSchema)) return;
        if (isFirstRender.current) isFirstRender.current = false;

        roundedValueRef.current = value;
      } else {
        if (!isFirstRender.current) roundedValueRef.current = 0;
      }
      automaticCalculation
        ? handleChangeAutomaticCalculation()
        : handleChangeManualCalculation();
    },
    value: roundedValueRef.current,
  });

  const hasErrors = (value: number, schema: JSONSchema7) => {
    const formErrors = validateFormData(
      value,
      schema,
      undefined,
      transformErrors as (errors: AjvError[]) => AjvError[]
    );

    if (!isListEmpty(formErrors.errors)) {
      setErrors([
        {
          error: formErrors.errors[0].name,
          path: errorPathRef.current,
        },
      ]);
    }

    return !isListEmpty(formErrors.errors);
  };

  const preparePayloadData = ({
    automaticCalculation,
    fieldKey,
  }: {
    automaticCalculation: boolean;
    fieldKey: string;
  }) => {
    let payloadFields;
    if (automaticCalculation) {
      payloadFields = ["roundedDailyIntake", "unroundedDailyIntake"];
    } else {
      payloadFields = [
        "unroundedValue",
        "unroundedDailyIntake",
        "roundedValue",
        "roundedDailyIntake",
      ];
    }

    return payloadFields.reduce((acc, curr) => {
      if (curr !== fieldKey) {
        acc = {
          ...acc,
          [curr]:
            getServingValue({
              servings: nutrient.servings,
              servingId,
              key: curr,
            }) ?? 0,
        };
      }
      return acc;
    }, {});
  };

  const getInputNumberProps = (fieldKey: string) => {
    switch (fieldKey) {
      case "roundedDailyIntake":
        return inputNumberPropsRoundedDailyIntake;

      case "unroundedDailyIntake":
        return inputNumberPropsUnroundedDailyIntake;

      case "roundedValue":
        return inputNumberPropsRoundedValue;

      case "unroundedValue":
        return inputNumberPropsUnroundedValue;

      default:
        break;
    }
  };

  return (
    <InputNumber
      {...getInputNumberProps(fieldKey)}
      data-test-id={fieldKey}
      error={!isListEmpty(errors)}
      errorMessage={!isListEmpty(errors) && <ErrorsList list={errors} />}
      disabled={!hasUpdatePermission}
    />
  );
};

export default NutritionServingValue;
