import { LibraryItemDetails } from "pages/Library/hocs/withLibraryItemDetails/types";
import { PageType } from "components/Library/components/types";
import Library from "components/Library";

export interface ClaimProps {
  libraryItemDetails: LibraryItemDetails;
  page: PageType;
  updateActionsInformation?: Function;
  updateSecondaryActionInformation?: Function;
}

const Claim: React.FC<ClaimProps> = ({
  libraryItemDetails,
  page,
  updateActionsInformation,
  updateSecondaryActionInformation,
}) => {
  return (
    <Library.ClaimForm
      page={page}
      updateActionsInformation={updateActionsInformation}
      updateSecondaryActionInformation={updateSecondaryActionInformation}
      initialValues={libraryItemDetails}
    />
  );
};

export default Claim;
