import { useEffect, useState } from "react";
import { isListEmpty, isObjectEmpty } from "utils/general";
import { SiteWidgetProps } from "./types";
import Card from "components/Card";

const SiteWidget = ({ siteData, refListDictionary }: SiteWidgetProps) => {
  const [activities, setActivities] = useState([]);
  const {
    actions,
    siteFunctions: activityIds,
    siteAddress1,
    siteAddress2,
    siteCity,
    siteName,
    sitePostalCode,
    siteCountry,
  } = siteData;

  useEffect(() => {
    if (!isListEmpty(activityIds) && !isObjectEmpty(refListDictionary)) {
      setActivities(
        activityIds.reduce((previousState, currentActivityId) => {
          if (refListDictionary[currentActivityId]) {
            return [...previousState, refListDictionary[currentActivityId]];
          }
          return previousState;
        }, [])
      );
    }
  }, [activityIds, refListDictionary]);

  return (
    <Card.Site
      actions={actions}
      activities={activities}
      addressLine1={siteAddress1}
      addressLine2={siteAddress2}
      city={siteCity}
      country={siteCountry}
      name={siteName}
      postalCode={sitePostalCode}
    />
  );
};

export default SiteWidget;
