import React from "react";
import PropTypes from "prop-types";
import { Filter } from "@trace-one/react-components";
import { useIntl } from "react-intl";
import { messages } from "./messages";

const Container = ({
  filters,
  onClearFiltersClick,
  onClearSearch,
  onSearch,
  onSearchChange,
  searchPlaceholder,
  searchValue,
  selectedFilters,
}) => {
  const intl = useIntl();

  return (
    <Filter.Panel
      clearAllFiltersText={intl.formatMessage(messages.clearAllFilters)}
      hideFiltersText={intl.formatMessage(messages.hideFilters)}
      onClearFiltersClick={onClearFiltersClick}
      onClearSearch={onClearSearch}
      onSearch={onSearch}
      onSearchChange={onSearchChange}
      panelTitle={intl.formatMessage(messages.panelTitle)}
      searchButtonText={intl.formatMessage(messages.searchBtn)}
      searchPlaceholder={searchPlaceholder}
      searchValue={searchValue}
      showFiltersText={intl.formatMessage(messages.showFilters)}
      filters={filters}
      selectedFilters={selectedFilters}
    />
  );
};

Container.defaultProps = {
  selectedFilters: [],
};

Container.propTypes = {
  filters: PropTypes.array.isRequired,
  onClearFiltersClick: PropTypes.func.isRequired,
  onClearSearch: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  searchPlaceholder: PropTypes.string,
  searchValue: PropTypes.string,
  selectedFilters: PropTypes.array,
};

export default Container;
