import { createApi } from "@reduxjs/toolkit/query/react";
import { TagData } from "models";
import { fetchBaseQuery } from "./fetchBaseQuery";

export const tagsRtkBaseApi = createApi({
  reducerPath: "tags",
  baseQuery: fetchBaseQuery,
  tagTypes: ["tags"],
  endpoints: () => ({}),
});

export const tagsApi = tagsRtkBaseApi.injectEndpoints({
  endpoints: build => ({
    getTags: build.query<TagData[], string[]>({
      query: tagIds => ({
        url: "tags/collection",
        method: "POST",
        data: { tagIds },
      }),
      providesTags: result =>
        result
          ? [
              { type: "tags", tagId: "LIST" },
              ...result.map(({ tagId }) => ({ type: "tags" as const, tagId })),
            ]
          : [{ type: "tags", tagId: "LIST" }],
    }),
  }),
});

export const { useGetTagsQuery: useGetTags } = tagsApi;
