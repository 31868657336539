import { Button } from "@trace-one/design-system";
import { FormattedMessage } from "react-intl";
import { Heading } from "@trace-one/design-system";
import { messages } from "./messages";
import styles from "./styles.module.less";
import { PanelProps } from "./types";

const Panel: React.FC<PanelProps> = ({ onClose, title, children }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Heading size="xs">{title}</Heading>
        <Button
          type="link"
          iconName="close"
          size="s"
          onClick={onClose}
          data-testid="panel-close-box"
        >
          <FormattedMessage {...messages.close} />
        </Button>
      </div>
      <div className={styles.container}>{children}</div>
    </div>
  );
};
export default Panel;
