import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import RequestModificationPopover, {
  RequestPopinOptions,
} from "components/RequestModificationPopover";
import { useModalVisibility } from "hooks";
import { useAppDispatch } from "store";
import {
  createSpecificationModificationRequest,
  updateSpecificationModificationRequest,
  deleteSpecificationModificationRequest,
} from "store/specificationDetails/asyncActions";
import { selectLanguageCode, selectUserName } from "store/user/selectors";
import { selectRequestModificationDetail } from "store/specificationDetails/selectors";
import { isStatusCodeSuccessful } from "utils/httpUtils";

const withRequestModificationPopover = Component => props => {
  const languageCode = useSelector(selectLanguageCode);
  const userName = useSelector(selectUserName);
  const request = useSelector(selectRequestModificationDetail);

  const [selectedField, setSelectedField] = useState<{
    propertyId: string;
    listItemKey?: string;
  }>(null);
  const [requestModificationMessage, setRequestModificationMessage] = useState(
    request?.message
  );

  useEffect(() => {
    setRequestModificationMessage(request?.message);
  }, [request]);

  const [modalStyle, setModalStyle] = useState<{
    top?: number | string;
    left?: number | string;
    right?: number | string;
  }>({
    top: 0,
    left: 0,
    right: 0,
  });

  const dispatch = useAppDispatch();
  const { isModalOpen, onOpenModal, onCloseModal } = useModalVisibility();

  const [
    requestPopoverOptions,
    setRequestPopoverOptions,
  ] = useState<RequestPopinOptions>({
    isReadOnly: false,
  });

  const {
    formContext: { specificationData, chapterData, templateBlockId },
  } = props;

  const handleUpdate = async () => {
    try {
      await dispatch(
        // @ts-ignore
        updateSpecificationModificationRequest({
          requestId: request?.requestId,
          message: requestModificationMessage,
        })
      );
    } catch (error) {}
  };

  const handleAdd = async () => {
    try {
      const thunkRes = await dispatch(
        // @ts-ignore
        createSpecificationModificationRequest({
          specificationId: specificationData?.specificationId,
          chapterType: chapterData?.chapterType,
          blockId: templateBlockId,
          propertyId: selectedField?.propertyId,
          listItemKey: selectedField?.listItemKey,
          message: requestModificationMessage,
        })
      );

      // @ts-ignore
      if (!isStatusCodeSuccessful(thunkRes?.payload?.statusCode)) {
        throw thunkRes?.payload;
      }
    } catch {}
  };

  const handleClose = () => {
    document
      .querySelector(".target-mode__border")
      ?.classList.remove("target-mode__border");
    setSelectedField(null);
    setRequestModificationMessage(null);
    onCloseModal();
  };

  const handleCancelRequest = async () => {
    try {
      if (request?.requestId) {
        await dispatch(
          // @ts-ignore
          deleteSpecificationModificationRequest({
            requestId: request?.requestId,
          })
        );
        handleClose?.();
      }
    } catch {}
  };

  return (
    <div className="target-mode__rm-popover-wrapper">
      <Component
        {...props}
        onOpenModal={onOpenModal}
        onCloseModal={onCloseModal}
        selectedCell={selectedField}
        setSelectedField={setSelectedField}
        setModalStyle={setModalStyle}
        requestPopoverOptions={requestPopoverOptions}
        setRequestPopoverOptions={setRequestPopoverOptions}
      />
      {isModalOpen ? (
        <RequestModificationPopover
          onConfirm={request ? handleUpdate : handleAdd}
          onClose={handleClose}
          onCancelRequest={handleCancelRequest}
          editorContent={requestModificationMessage}
          setEditorContent={setRequestModificationMessage}
          languageCode={languageCode}
          userName={userName}
          modalStyle={modalStyle}
          requestPopoverOptions={requestPopoverOptions}
          selectedRequest={request}
          setRequestPopoverOptions={setRequestPopoverOptions}
        />
      ) : null}
    </div>
  );
};

export default withRequestModificationPopover;
