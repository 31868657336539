import React from "react";
import { Button } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import { LIBRARY_OBJECT_TYPES } from "utils/constants";
import { useAction } from "./hooks";
import { messages } from "./messages";

const CreateButton: React.FC<{ type: LIBRARY_OBJECT_TYPES }> = ({ type }) => {
  const { formatMessage } = useIntl();
  const action = useAction(type);

  return (
    <Button onClick={action.onClick} data-testid={action["data-test-id"]}>
      {formatMessage(messages.createBtn)}
    </Button>
  );
};

export default CreateButton;
