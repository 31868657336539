import { Select } from "@trace-one/design-system";
import { useRefListAutocomplete } from "hooks";
import { getCurrentValueFromValue, getNotSelectedOptions } from "utils/select";
import { useReflistSelect } from "./hooks";
import { ReflistSelectProps } from "./types";

const ReflistSelect: React.FC<ReflistSelectProps> = ({
  refListId,
  parentItemIds = [],
  selectedOptionIds = [],
  defaultValue,
  value,
  onBlur: defaultOnBlur,
  onChange: defaultOnChange,
  onDeselect: defaultOnDeselect,
  onSelect: defaultOnSelect,
  onDropdownVisibleChange: defaultOnDropdownVisibleChange,
  labelInValue: defaultLabelInValue,
  showSearch = true,
  mode,
  refListDictionary,
  shouldCallApi,
  filterRefListIds,
  ...rest
}) => {
  const {
    focus,
    valueWithLabel,
    searchText,
    onBlur,
    onChange,
    onDeselect,
    onSelect,
    onSearch,
    onDropdownVisibleChange,
  } = useReflistSelect({
    defaultValue: getCurrentValueFromValue(defaultValue),
    value: getCurrentValueFromValue(value),
    defaultLabelInValue,
    defaultOnBlur,
    defaultOnChange,
    defaultOnDeselect,
    defaultOnSelect,
    defaultOnDropdownVisibleChange,
    mode,
    shouldCallApi,
    refListDictionary,
  });

  const { isLoading, refList, onScrollToBottom } = useRefListAutocomplete({
    refListId,
    parentItemIds,
    shouldCallApi: focus,
    searchText,
  });

  return (
    <Select
      showArrow
      {...rest}
      labelInValue
      filterOption={false}
      defaultValue={valueWithLabel}
      value={valueWithLabel}
      loading={isLoading}
      mode={mode}
      options={getNotSelectedOptions({
        data: refList.filter(
          item => !(filterRefListIds ?? []).includes(item.id)
        ),
        selectedValue: valueWithLabel,
        name: "text",
        value: "id",
        selectedOptionIds,
      })}
      showSearch={showSearch}
      onBlur={onBlur}
      onChange={onChange}
      onDeselect={onDeselect}
      onPopupScroll={onScrollToBottom}
      onSelect={onSelect}
      onDropdownVisibleChange={onDropdownVisibleChange}
      {...(showSearch && {
        onSearch,
      })}
    />
  );
};

export default ReflistSelect;
