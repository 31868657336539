import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store";
import { createRequirement } from "store/requirementForm/asyncActions";
import {
  selectCreationStatus,
  selectRequirementInformation,
} from "store/requirementForm/selectors";
import { setFormData } from "store/requirementForm/requirementFormSlice";
import { isObjectEmpty } from "utils/general";
import { RequirementData } from "models";

const useRequirementFormInitialisation = ({
  initialFormData = {},
}: {
  initialFormData: RequirementData;
}) => {
  const dispatch = useAppDispatch();
  const { inProgress } = useSelector(selectCreationStatus);
  const requirementInformation = useSelector(selectRequirementInformation);
  const [isLoading, setIsLoading] = useState(true);

  const isInitialFormDataEmpty = useMemo(() => isObjectEmpty(initialFormData), [
    initialFormData,
  ]);

  useEffect(() => {
    if (isInitialFormDataEmpty && !requirementInformation.id) {
      dispatch(createRequirement());
    }
  }, [initialFormData]);

  useEffect(() => {
    const prepareRequirement = async () => {
      setIsLoading(true);

      if (!isInitialFormDataEmpty) {
        await dispatch(setFormData(initialFormData));
      }

      setIsLoading(false);
    };

    prepareRequirement();
  }, []);

  return {
    isLoading: inProgress || isLoading,
  };
};

export default useRequirementFormInitialisation;
