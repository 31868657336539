/* istanbul ignore file */

import { useEffect, useState } from "react";
import { axiosInstance as specAxiosInstance } from "apis/SPEC";
import { axiosInstance as pkgAxiosInstance } from "apis/PKG";
import { AxiosRequestConfig } from "axios";
import { ERROR_CODES } from "utils/constants";
import { isErrorCodeTagNotFound } from "utils/general";

const useErrorCodeCheck = (errorCode: ERROR_CODES) => {
  const [isDesiredErrorCode, setIsDesiredErrorCode] = useState(false);
  const [errorResponseData, setErrorResponseData] = useState(null);

  useEffect(() => {
    const onFulfilled = (config: AxiosRequestConfig) => {
      setIsDesiredErrorCode(false);

      return config;
    };

    const onRejected = error => {
      setIsDesiredErrorCode(error?.response?.data?.errorCode === errorCode);

      setErrorResponseData(
        error?.response?.data?.errorCode === errorCode &&
          isErrorCodeTagNotFound(errorCode)
          ? error?.response?.data?.tagIds
          : null
      );

      return Promise.reject(error);
    };

    const specIndexRequestInterceptor = specAxiosInstance.interceptors.request.use(
      onFulfilled,
      onRejected
    );

    const specIndexResponseInterceptor = specAxiosInstance.interceptors.response.use(
      onFulfilled,
      onRejected
    );

    const pkgIndexRequestInterceptor = pkgAxiosInstance.interceptors.request.use(
      onFulfilled,
      onRejected
    );

    const pkgIndexResponseInterceptor = pkgAxiosInstance.interceptors.response.use(
      onFulfilled,
      onRejected
    );

    return () => {
      specAxiosInstance.interceptors.request.eject(specIndexRequestInterceptor);
      specAxiosInstance.interceptors.response.eject(
        specIndexResponseInterceptor
      );
      pkgAxiosInstance.interceptors.request.eject(pkgIndexRequestInterceptor);
      pkgAxiosInstance.interceptors.response.eject(pkgIndexResponseInterceptor);
    };
  }, []);

  return {
    isDesiredErrorCode,
    errorResponseData,
  };
};

export default useErrorCodeCheck;
