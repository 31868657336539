import { defineMessages } from "react-intl";

const rawMaterialFormMessages = defineMessages({
  modalTitle: {
    id: "library.rawMaterialForm.allergens.modal.title",
    defaultMessage: "Add allergens",
  },

  addButton: {
    id: "library.rawMaterialForm.allergens.add.button",
    defaultMessage: "Add an allergen",
  },
});

export default rawMaterialFormMessages;
