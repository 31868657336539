import English from "../translations/en-US.json";
import French from "../translations/fr-FR.json";

export const translations = {
  "en-US": English,
  "fr-FR": French,
  fallbackLanguage: English,
};

export const getTranslations = languageCode => {
  return translations[languageCode] || translations.fallbackLanguage;
};
