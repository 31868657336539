import { RequirementData, TemplateSectionData, TradeItemData } from "models";
import { isObjectEmpty } from "utils/general";

export const getDetailsForRequirementTradeItems = ({
  folderProducts,
  requirementTradeItemIds,
}: {
  folderProducts: TradeItemData[];
  requirementTradeItemIds: TradeItemData["id"][];
}) => () =>
  requirementTradeItemIds.map(tradeItemId =>
    folderProducts.find(product => product.id === tradeItemId)
  );

export const getErrorsProps = ({
  removeProductsErrors,
  errorMessage,
}: {
  removeProductsErrors: object;
  errorMessage: string;
}) => (productId: TradeItemData["id"]) => {
  if (isObjectEmpty(removeProductsErrors) || !removeProductsErrors[productId]) {
    return {};
  }
  return {
    hasError: true,
    errorMessage,
  };
};

export const getOnChapterSelect = ({
  dispatch,
  setRequirementChapter,
  requirementId,
}: {
  dispatch: Function;
  setRequirementChapter: Function;
  requirementId: RequirementData["id"];
}) => (chapterType: TemplateSectionData["chapterType"]) => {
  dispatch(
    setRequirementChapter({
      requirementId,
      chapterType,
    })
  );
};

export const getOnRemoveProduct = ({
  requirementId,
  tradeItemId,
  dispatch,
  removeRequirementProduct,
}: {
  requirementId: RequirementData["id"];
  tradeItemId: TradeItemData["id"];
  dispatch: Function;
  removeRequirementProduct: Function;
}) => () => {
  dispatch(
    removeRequirementProduct({
      requirementId,
      tradeItemId,
    })
  );
};

export const getMessageKeyForLabel = (displayActions: boolean) =>
  displayActions ? "selectChapterLabel" : "selectedChapterLabel";
