import { useContext } from "react";
import { LibraryDetails } from "pages/Library/components/Details/contexts";
import Configure from "components/Library/components/ChapterForm/components/Configure";
import { LIBRARY_OBJECTS_PAGES } from "utils/constants";
import styles from "./styles.module.less";

const ChapterDetails = () => {
  const { libraryItemDetails } = useContext(LibraryDetails);

  const { sections, structure, progressState } = libraryItemDetails;

  return (
    <div className={styles.chapterDetails}>
      <Configure
        sections={sections}
        structure={structure}
        progressState={progressState}
        page={LIBRARY_OBJECTS_PAGES.DETAILS}
        generalInfo={libraryItemDetails?.generalInfo}
      />
    </div>
  );
};

export default ChapterDetails;
