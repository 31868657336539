import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fetchExternalList } from "apis/PKG";
import { CategoryListItemData } from "models";
import { selectLanguageCode } from "store/user/selectors";
import { CategoryListItemsData, CategoryListItemsProps } from "./types";

const useCategoryListItems = ({
  api,
  route,
  isOnlyLeafSelectable,
}: CategoryListItemsProps) => {
  const languageCode = useSelector(selectLanguageCode);
  const [items, setItems] = useState<CategoryListItemsData[]>([]);

  useEffect(() => {
    getItemsFromApi();
  }, [api, route, isOnlyLeafSelectable, languageCode]);

  const getItemsFromApi = async () => {
    let listItems: CategoryListItemData[] = [];

    try {
      const { data } = await fetchExternalList({
        route,
        languageCode,
      });
      listItems = data;

      setItems(
        listItems.map(
          (
            { categoryItemId, patrentCategoryItemId, categoryLevel, value },
            _,
            items
          ) => ({
            title: value,
            value: categoryItemId,
            id: categoryItemId,
            pId: patrentCategoryItemId,
            selectable: isOnlyLeafSelectable
              ? !items.some(
                  item => item.patrentCategoryItemId === categoryItemId
                ) && categoryLevel > 1
              : true,
          })
        )
      );
    } catch (error) {
      setItems([]);
    }
  };

  return { items };
};

export default useCategoryListItems;
