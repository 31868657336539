import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import { Alert } from "@trace-one/design-system";
import {
  useChapterDraftValidation,
  useLinkedSpecifications,
  useQuery,
  useRedirect,
} from "hooks";
import { useLibraryFormActions } from "components/Library/hooks";
import {
  useChapterFormActions,
  useChapterFormInitialisation,
} from "components/Library/components/ChapterForm/hooks";
import { useChapterNavigation, useDeleteChapterVersion } from "./hooks";
import Spin from "components/Spin";
import DeleteChapterVersionModal from "./components/DeleteChapterVersionModal";
import CancelDeleteChapterModal from "components/Library/components/ChapterForm/components/CancelDeleteChapterModal";
import { onTabChange } from "pages/Library/utils";
import { isLibraryObjectDraft, isLibraryObjectLocked } from "utils/library";
import {
  CHAPTER_TYPES_API,
  LIBRARY_OBJECTS_PAGES,
  LIBRARY_TAB_KEYS,
  ROUTES,
} from "utils/constants";
import { ChapterFormProvider } from "components/Library/components/ChapterForm/contexts";
import {
  selectGeneraInfo,
  selectUpdateStatus,
} from "store/chapterForm/selectors";
import { ChapterProps } from "./types";
import { messages } from "./messages";
import libraryMessages from "messages/library";
import styles from "./styles.module.less";

const Chapter: React.FC<ChapterProps> = ({
  initialChapterData,
  updateActionsInformation,
  updateTabsInformation,
  updateHeaderInformation,
}) => {
  const { formatMessage } = useIntl();
  const { isLoading } = useChapterFormInitialisation({
    initialFormData: initialChapterData,
  });

  const query = useQuery();
  const prevId = query.get("prevVersionId");

  const generalInfo = useSelector(selectGeneraInfo);
  const {
    id: chapterId,
    type: chapterType,
    state: chapterState,
    name: chapterName,
  } = generalInfo;

  const {
    isDeleteModalOpen,
    onOpenDeleteModal,
    onDeleteChapterVersion,
    onCancelDeleteChapterVersion,
  } = useDeleteChapterVersion(chapterId);

  const {
    totalCount: numberOfLinkedSpecs,
    getLinkedSpecifications,
  } = useLinkedSpecifications({
    chapterType,
    chapterId,
  });

  const { allowVersionCreation } = useChapterDraftValidation({
    chapterType,
    chapterId,
  });

  const { inProgress: isUpdateInProgress } = useSelector(selectUpdateStatus);

  const { areActionButtonsDisabled, onFieldsChange } = useLibraryFormActions({
    requiredFields: ["name", "productTypeId", "type"],
    isUpdateInProgress,
    libraryItem: generalInfo,
  });

  const {
    redirectToLibraryEdition,
    redirectToLibraryCreateNewVersion,
  } = useRedirect();

  const {
    activeTab,
    setActiveTab,
    tabItems,
    tabContent,
  } = useChapterNavigation({
    generalInfo,
    onFieldsChange,
    numberOfLinkedSpecs,
    linkedSpecificationsCallback: getLinkedSpecifications,
  });

  const isNewVersionPage = useRouteMatch(ROUTES.LIBRARY.CREATE_NEW_VERSION);

  const isNewVersion = isNewVersionPage?.isExact;

  const { onPublishChapter, onKeepAsDraftClick } = useChapterFormActions({
    chapterId,
    chapterType,
    chapterName,
    isNewVersion,
    page: LIBRARY_OBJECTS_PAGES.EDIT,
  });

  const actionButtonItems = [
    ...(isLibraryObjectDraft(chapterState)
      ? [
          {
            name: formatMessage(libraryMessages.publishButton),
            label: formatMessage(libraryMessages.publishButton),
            onClick: onPublishChapter,
          },
        ]
      : []),
    ...(isNewVersion
      ? [
          {
            name: formatMessage(libraryMessages.keepAsDraftButton),
            label: formatMessage(libraryMessages.keepAsDraftButton),
            onClick: onKeepAsDraftClick,
          },
        ]
      : [
          {
            name: formatMessage(libraryMessages.deleteVersion),
            label: formatMessage(libraryMessages.deleteVersion),
            onClick: onOpenDeleteModal,
            disabled: isLibraryObjectLocked(chapterState),
          },
        ]),
  ];

  useEffect(() => {
    updateActionsInformation({
      props: {
        disabled: isLibraryObjectLocked(chapterState)
          ? areActionButtonsDisabled || !allowVersionCreation
          : areActionButtonsDisabled,
        ...(!isLibraryObjectLocked(chapterState) && {
          items: actionButtonItems,
          onClick: () => {},
        }),
      },
    });
  }, [chapterState, areActionButtonsDisabled, allowVersionCreation]);

  useEffect(() => {
    updateTabsInformation({
      defaultActiveKey: LIBRARY_TAB_KEYS.GENERAL_INFORMATION,
      onTabClick: setActiveTab,
      items: tabItems,
    });
  }, [chapterId, chapterType]);

  useEffect(() => {
    updateTabsInformation({
      activeKey: activeTab,
    });
  }, [activeTab]);

  useEffect(() => {
    updateTabsInformation({
      onChange: tabKey =>
        onTabChange({
          tabKey,
          libraryItemId: chapterId,
          libraryItemType: CHAPTER_TYPES_API[chapterType],
          redirectToPage: isNewVersion
            ? redirectToLibraryCreateNewVersion
            : redirectToLibraryEdition,
        }),
    });
  }, [chapterId, chapterType]);

  useEffect(() => {
    updateTabsInformation({
      items: tabItems,
    });
  }, [tabContent, numberOfLinkedSpecs]);

  useEffect(() => {
    updateHeaderInformation({
      libraryItemName: chapterName,
      libraryItemState: chapterState,
    });
  }, [chapterState, chapterName]);

  return (
    <ChapterFormProvider
      chapterId={chapterId}
      getLinkedSpecifications={getLinkedSpecifications}
    >
      {isLoading ? (
        <Spin />
      ) : (
        <>
          {!isLibraryObjectLocked(initialChapterData.generalInfo.state) ? (
            <Alert
              message={formatMessage(messages.alertMessage)}
              description={formatMessage(messages.alertDescription)}
              type="warning"
              showIcon={true}
              closable={true}
              className={styles.alert}
            />
          ) : null}
          {tabContent}
        </>
      )}

      {isNewVersion && (
        <CancelDeleteChapterModal
          isNewVersion={isNewVersion}
          previousVersionChapterId={prevId}
        />
      )}

      {isDeleteModalOpen && (
        <DeleteChapterVersionModal
          onDeleteChapterVersion={onDeleteChapterVersion}
          onCancelDeleteChapterVersion={onCancelDeleteChapterVersion}
        />
      )}
    </ChapterFormProvider>
  );
};

export default Chapter;
