import { getIsRetailer } from "utils/user";

export const getValidationToggleProps = ({
  companyActivity,
  onValidate,
  sectionStatus,
}: {
  companyActivity?: string;
  onValidate: Function;
  sectionStatus: string;
}) => {
  const isRetailer = getIsRetailer(companyActivity);

  switch (sectionStatus) {
    case "inProgress":
    case "toFill":
      return {
        checked: false,
        onClick: onValidate,
      };
    case "validatedBySupplier":
      return {
        ...(isRetailer && {
          checked: false,
          onClick: onValidate,
          classNameKey: "toggleBorderSupplier",
        }),
        ...(!isRetailer && {
          checked: true,
          classNameKey: "toggleSupplierBackground",
        }),
      };
    case "validatedByRetailer":
      return {
        checked: true,
        classNameKey: "toggleRetailerBackground",
      };
    case "validatedByRetailerWithoutSupplier":
      return {
        ...(isRetailer
          ? {
              checked: true,
            }
          : {
              checked: false,
              onClick: onValidate,
              classNameKey: "toggleBorderRetailer",
            }),
      };
    default:
      return {
        checked: false,
        onClick: onValidate,
      };
  }
};

export const isValidationToggleDisabled = ({
  isValidationDisabled,
  hasValidationErrors,
  hasValidationPermission,
}: {
  isValidationDisabled: boolean;
  hasValidationErrors: boolean;
  hasValidationPermission: boolean;
}) => isValidationDisabled || hasValidationErrors || !hasValidationPermission;
