import { Row, Col } from "antd";
import TargetDate from "./components/TargetDate";
import ProductType from "./components/ProductType";
import Owner from "./components/Owner";
import Description from "./components/Description";
import { DetailsProps } from "./types";
import styles from "./styles.module.less";

const Details: React.FC<DetailsProps> = ({ folderDetails }) => {
  return (
    <div className={styles.details}>
      <Row gutter={[16, 20]}>
        <Col xs={24} sm={24} md={8}>
          <TargetDate targetDateUtc={folderDetails.targetDateUtc} />
        </Col>
        <Col xs={24} sm={24} md={8}>
          <ProductType productTypeId={folderDetails.productTypeId} />
        </Col>
        <Col xs={24} sm={24} md={8}>
          <Owner ownerId={folderDetails.ownerUserId} />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Description folderDescription={folderDetails.folderDescription} />
        </Col>
      </Row>
    </div>
  );
};

export default Details;
