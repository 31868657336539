import React from "react";
import { Col, Row } from "antd";
import RowTitle from "components/RowTitle";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { getPropertiesObjectFromArray } from "../../templates/utils";
import { WidgetProps } from "components/SpecificationSection/types";

const BusinessNameAndAddressWidget: React.FC<WidgetProps> = props => {
  const { properties, uiSchema } = props;

  const {
    consumerServiceInformation = {},
    additionalInformation = {},
    healthMarks = {},
    packerCodes = {},
  } = getPropertiesObjectFromArray(properties);

  const { getTranslationForKey } = useFormSchemaTranslation();

  return (
    <Row>
      <Col xs={24}>
        <RowTitle title={getTranslationForKey(uiSchema?.["ui:title"])} />
        {consumerServiceInformation.content}
        {additionalInformation.content}
        <RowTitle title={getTranslationForKey("codes")} />
        {healthMarks.content}
        {packerCodes.content}
      </Col>
    </Row>
  );
};

export default BusinessNameAndAddressWidget;
