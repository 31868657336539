import { useState, useEffect } from "react";
import { isObjectEmpty } from "utils/general";
import { Dictionary } from "types/general";
import { MaterialAllergenViewModel } from "viewModels";

const useAllergensInOrder = ({
  selectedAllergens,
  allergenDictionary,
}: {
  selectedAllergens: MaterialAllergenViewModel[];
  allergenDictionary?: Dictionary;
}) => {
  const [currentAllergens, setCurrentAllergens] = useState<
    MaterialAllergenViewModel[]
  >([]);

  useEffect(() => {
    // we want the order of the allergens to be in the order as in the modal allergens window
    if (!isObjectEmpty(allergenDictionary)) {
      let allergens: MaterialAllergenViewModel[] = [];

      Object.keys(allergenDictionary).forEach(value => {
        selectedAllergens.forEach(allergen => {
          if (value === allergen.allergenId) {
            allergens.push(allergen);
          }
        });
      });

      setCurrentAllergens(allergens);
    }
  }, [allergenDictionary, selectedAllergens]);

  return { currentAllergens };
};

export default useAllergensInOrder;
