import { CategoryItemData } from "models";
import { Dictionary } from "types/general";
import { isListEmpty } from "utils/general";
import { CategoryTreeItem, CategoryTreeSelectProps } from "./types";

export const getUniqueTreeData = (list: CategoryTreeItem[]) => {
  let uniqueList: CategoryTreeItem[] = [];

  list.forEach(item => {
    if (uniqueList.findIndex(({ id }) => id === item.id) === -1) {
      uniqueList.push(item);
    }
  });

  return uniqueList;
};

export const getPrepareItemTitle = (
  highlightedItems: string[],
  nameDictionary: Dictionary
) => ({ categoryItemId, categoryItemName }: CategoryItemData) => {
  const name = categoryItemName ?? nameDictionary[categoryItemId];

  return highlightedItems?.includes(categoryItemId) ? (
    <strong>{name}</strong>
  ) : (
    name
  );
};

export const shouldCategoryListAPiBeCalled = ({
  multiple,
  valueWithLabel,
}: {
  multiple: boolean;
  valueWithLabel: CategoryTreeSelectProps["value"];
}) => {
  if (multiple) {
    return isListEmpty(valueWithLabel as CategoryItemData[]);
  }

  return !(valueWithLabel as CategoryItemData)?.categoryItemId;
};

export const mapToCategoriesValue = (
  rawValue,
  multiParentSelection: boolean
) => {
  const categoriesObjList = rawValue.map(({ value, ...rest }) => ({
    value: JSON.parse(value),
    ...rest,
  }));

  const result = [];
  for (let item of categoriesObjList) {
    const { label, value } = item;
    const isUnique =
      result.findIndex(item => item.categoryId === value.categoryId) === -1;

    if (multiParentSelection) {
      result.push({ categoryItemName: label, ...value });
    } else {
      if (isUnique) {
        result.push({ categoryItemName: label, ...value });
      }
    }
  }
  return result;
};
