import { useIntl } from "react-intl";
import dayjs from "dayjs";
import { Row } from "antd";
import { messages } from "messages/specification";
import generalMessages from "messages/general";
import { displayValueForSpecificationDraftState } from "pages/Specification/utils";
import { ChapterVersion, GeneralInfoData, VersionData } from "models";
import { getVersionName } from "utils/specifications";
import ActionButton from "components/ActionButton";
import { getActionsList } from "./utils";
import { useUserInfo } from "hooks";
import { ModalActionType } from "components/DetailsAndLinksChapterModal/types";

const useVersionHistoryColumns = ({
  onCellClick,
  handleOpenModal,
}: {
  onCellClick: Function;
  handleOpenModal?: ({
    chapterId,
    actionType,
  }: {
    chapterId: GeneralInfoData["id"];
    actionType: ModalActionType;
  }) => () => void;
}) => {
  const intl = useIntl();
  const { isRetailer } = useUserInfo();

  const columns = [
    {
      title: intl.formatMessage(generalMessages.name),
      dataIndex: "name",
      key: "name",
      onCell: onCellClick,
    },
    {
      title: intl.formatMessage(generalMessages.version),
      dataIndex: "version",
      key: "version",
      render: (version: VersionData) => {
        return getVersionName(version?.number);
      },
      onCell: onCellClick,
    },
    {
      title: intl.formatMessage(messages.lastModification),
      dataIndex: "lastUpdateDateUtc",
      key: "lastUpdateDateUtc",
      render: (date, specification) =>
        displayValueForSpecificationDraftState({
          specificationState: specification.state,
          valueToDisplay: dayjs(date).local().format("L"),
        }),
      onCell: onCellClick,
    },
    {
      title: intl.formatMessage(generalMessages.links),
      dataIndex: "linkedSpecifications",
      key: "linkedSpecifications",
      onCell: onCellClick,
    },
    {
      title: intl.formatMessage(generalMessages.actions),
      dataIndex: "version",
      key: "actions",
      width: 90,
      render: (version: VersionData, chapter: ChapterVersion) => {
        return (
          <Row justify="center">
            <ActionButton
              actions={getActionsList({
                formatMessage: intl.formatMessage,
                activeVersion: version?.activeVersion,
                chapterId: chapter?.chapterId,
                chapterState: chapter?.state,
                handleOpenModal,
                isRetailer,
              })}
            />
          </Row>
        );
      },
    },
  ];

  return {
    columns,
  };
};

export default useVersionHistoryColumns;
