import { MutableRefObject, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import qs from "qs";
import { fetchRequirementListing } from "apis/SPEC";
import { selectOwningCompanyId } from "store/user/selectors";
import {
  LIBRARY_OBJECT_STATES,
  LIBRARY_OBJECT_TYPES,
  TAKE_ITEMS_FOR_INFINITE_PAGINATION,
} from "utils/constants";
import { RequirementListItemData } from "models";
import { RequirementType } from "types/library";
import { prepareLibraryListItemFromApiData } from "dtos/libraryItem";
import { useLibraryItemTypeDictionary } from "hooks";
import { getSkipParamForApi } from "utils/general";

const useRequirementItems = ({
  infiniteScrollWrapper,
}: {
  infiniteScrollWrapper?: MutableRefObject<HTMLDivElement>;
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [canLoadMore, setCanLoadMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState<string>(undefined);
  const [
    requirementTypesFilter,
    setRequirementTypesFilter,
  ] = useState<RequirementType>(null);
  const [totalNumberOfItems, setTotalNumberOfItems] = useState(1);
  const [requirementItems, setRequirementItems] = useState<
    RequirementListItemData[]
  >([]);

  const { typeDictionary } = useLibraryItemTypeDictionary({
    type: "requirements",
  });
  const isFirstRender = useRef(true);

  const ownerCompanyId = useSelector(selectOwningCompanyId);

  const searchRequirements = async (isNewSearch: boolean = false) => {
    try {
      setIsLoading(true);

      const params = {
        take: TAKE_ITEMS_FOR_INFINITE_PAGINATION,
        skip: getSkipParamForApi({
          isNewSearch,
          currentPage,
          pageSize: TAKE_ITEMS_FOR_INFINITE_PAGINATION,
        }),
        ownerCompanyId,
        requirementType: requirementTypesFilter || null,
        name: searchText || null,
        states: [LIBRARY_OBJECT_STATES.LOCKED, LIBRARY_OBJECT_STATES.PUBLISHED],
      };

      const {
        data: { items, totalNumberOfItems },
      } = await fetchRequirementListing({
        params,
        paramsSerializer: () => qs.stringify(params, { arrayFormat: "comma" }),
      });

      let newItemsLength = items.length;

      setCurrentPage(previousState => (isNewSearch ? 2 : previousState + 1));

      if (isNewSearch) {
        setRequirementItems(items);
      } else {
        setRequirementItems(previousState => {
          const newItemsList = [...previousState, ...items];

          newItemsLength = newItemsList.length;

          return newItemsList;
        });
      }

      setTotalNumberOfItems(totalNumberOfItems);

      setCanLoadMore(totalNumberOfItems > newItemsLength);
    } catch (_) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    searchRequirements();
  }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    infiniteScrollWrapper?.current.scrollTo({ top: 0 });

    searchRequirements(true);
  }, [searchText, requirementTypesFilter]);

  return {
    isLoading,
    canLoadMore,
    totalNumberOfItems,
    requirementItems: requirementItems.map(item =>
      prepareLibraryListItemFromApiData({
        libraryItem: item,
        typeDictionary,
        type: LIBRARY_OBJECT_TYPES.REQUIREMENTS,
      })
    ),
    currentPage,
    searchRequirements,
    setSearchText,
    setRequirementTypesFilter,
  };
};

export default useRequirementItems;
