export const getIsLastRow = ({
  widgetName,
  title,
}: {
  widgetName: string;
  title?: string;
}) =>
  widgetName?.toUpperCase() === "RANGENUMBERWIDGET" ||
  (widgetName?.toUpperCase() === "COMPACTWIDGET" &&
    title?.toUpperCase().includes("IMPORT"));
