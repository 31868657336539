import { useMemo } from "react";
import { Col, Row } from "antd";
import { Datatable } from "@trace-one/design-system";
import { getRowKeyForTable, getTableSize } from "utils/table";
import { isListEmpty } from "utils/general";
import ExpandedRowKeysButtons from "components/SpecificationSection/components/FormSchema/components/ExpandedRowKeysButtons";
import { getExpandedRowKeys } from "./utils";
import { useExpandedRowKeys } from "hooks";
import { NutritionBasicProvider } from "./contexts";
import { NutritionBasicProps } from "./types";
import styles from "./styles.module.less";

const NutritionBasic: React.FC<NutritionBasicProps> = ({
  formContext,
  schema,
  columns,
  dataSource,
}) => {
  const { displayActions, sectionId } = formContext;

  const allExpandableRowKeys = useMemo(() => getExpandedRowKeys(dataSource), [
    JSON.stringify(dataSource),
  ]);

  const {
    expandedRowKeys,
    onExpandHandler,
    onExpandAllHandler,
    onCollapseAllHandler,
  } = useExpandedRowKeys({
    itemKey: "nutrientId",
    allExpandableRowKeys,
  });

  return (
    <NutritionBasicProvider
      sectionId={sectionId}
      formContext={formContext}
      schema={schema}
    >
      <Row gutter={[10, 10]} className={styles.nutritionContainer}>
        <Col span={24}>
          <Datatable
            shouldRemoveEmptyChildren
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            rowKey={getRowKeyForTable("nutrition")}
            expandedRowKeys={expandedRowKeys}
            onExpand={onExpandHandler}
            size={getTableSize(displayActions)}
          />
        </Col>
        {displayActions && !isListEmpty(dataSource) && (
          <Col span={24}>
            <ExpandedRowKeysButtons
              onExpandAllClick={onExpandAllHandler}
              onCollapseAllClick={onCollapseAllHandler}
              expandedRowKeys={expandedRowKeys}
              allExpandableRowKeys={allExpandableRowKeys}
            />
          </Col>
        )}
      </Row>
    </NutritionBasicProvider>
  );
};

export default NutritionBasic;
