import { useEffect } from "react";
import { isListEmpty } from "utils/general";
import { updateInfoForStructure } from "utils/library";
import { UseChapterDataProps } from "./types";

const useChapterData = ({
  selectedChapter,
  updateChapterInformation,
  refetchSpecificationInformation,
  chapterData,
  fetchChapter,
  fetchChapterWithLoading,
  setSelectedChapterOwnerCompanyId,
}: UseChapterDataProps) => {
  const refetchChapter = () => {
    fetchChapter();

    refetchSpecificationInformation();
  };

  const refetchChapterWithLoading = () => {
    fetchChapterWithLoading();

    refetchSpecificationInformation();
  };

  useEffect(() => {
    if (!isListEmpty(chapterData?.sections)) {
      updateChapterInformation({
        chapterType: selectedChapter?.chapterType,
        sections: chapterData.sections,
      });
    } else {
      updateChapterInformation({
        chapterType: selectedChapter?.chapterType,
        sections: updateInfoForStructure({
          sections: [],
          structure: selectedChapter?.sections,
        }),
      });
    }

    setSelectedChapterOwnerCompanyId?.(chapterData?.ownerCompanyId);
  }, [JSON.stringify(chapterData)]);

  return {
    refetchChapter,
    refetchChapterWithLoading,
  };
};

export default useChapterData;
