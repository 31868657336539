import { useState, useEffect } from "react";

const useTableInfiniteScroll = ({
  tableContent,
  canLoadMore,
}: {
  tableContent: Element;
  canLoadMore: boolean;
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  const scrollEventListener = (event: Event) => {
    // @ts-ignore
    let maxScroll = event.target.scrollHeight - event.target.clientHeight;
    // @ts-ignore
    let currentScroll = Math.ceil(event.target.scrollTop);

    if (currentScroll >= maxScroll) {
      if (canLoadMore) {
        setCurrentPage(previousState => previousState + 1);
      }
    }
  };

  const scrollToTop = () => {
    tableContent.scrollTop = 0;
  };

  useEffect(() => {
    tableContent?.addEventListener("scroll", scrollEventListener);

    return () => {
      tableContent?.removeEventListener("scroll", scrollEventListener);
    };
  }, [tableContent]);

  return { currentPage, scrollToTop, setCurrentPage };
};

export default useTableInfiniteScroll;
