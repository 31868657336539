import {
  RootNutrientName,
  UnroundedValue,
} from "components/SpecificationSection/components/FormSchema/components/NutritionBasic/shared/components";
import {
  NutrientItem,
  NutrientTableDataItem,
  NutrientItemDictionary,
} from "components/SpecificationSection/components/FormSchema/components/NutritionBasic/types";
import { useColumnProps } from "components/SpecificationSection/components/FormSchema/components/NutritionBasic/shared/hooks";
import { DisplayToConsumer } from "./components";
import { accessObjectPropertyWithPath } from "utils/general";
import { UseNutritionColumnsProps } from "./types";
import useServingColumns from "../useServingColumns";
import useServings from "../useServings";

const useNutritionColumns = ({
  statusNutrientFamilyDictionary,
  uiSchema,
  formData,
  formContext,
  schema,
  errorSchema,
  nutrientDictionary,
  mandatoryNutrientIds,
  extraActions,
  displayActions,
  specificationId,
  shouldRefetchServings,
  onRemoveServing,
  onEditServing,
  hasUpdatePermission,
}: UseNutritionColumnsProps) => {
  const isNotRootNutrient = (nutrient: NutrientTableDataItem) =>
    !nutrient.hasOwnProperty("nutrientFamilyId");

  const { getColumnProps } = useColumnProps({
    schema,
    formData,
    uiSchema,
  });

  const getColumnErrors = (path: string) => {
    const errors: string[] = accessObjectPropertyWithPath({
      object: errorSchema,
      path,
    })?.__errors;

    return errors;
  };

  const { nutritionServings } = useServings({
    specificationId,
    shouldRefetchServings,
  });

  const servingColumns = useServingColumns({
    uiSchema,
    nutritionServings,
    schema,
    nutrientDictionary,
    isNotRootNutrient,
    extraActions,
    formContext,
    getColumnErrors,
    onRemoveServing,
    onEditServing,
    hasUpdatePermission,
  });

  const columns = [
    {
      ...getColumnProps("nutrientId"),
      render: (value, nutrient: NutrientTableDataItem) => {
        if (isNotRootNutrient(nutrient)) {
          return (nutrientDictionary[value] as NutrientItemDictionary)?.name;
        }
        if (!statusNutrientFamilyDictionary?.[value]) {
          return null;
        }

        return (
          <RootNutrientName
            nutrientName={
              (nutrientDictionary[value] as NutrientItemDictionary)?.name
            }
            statusData={statusNutrientFamilyDictionary[value]}
          />
        );
      },
    },
    {
      ...getColumnProps("unitId"),
      render: value => nutrientDictionary[value],
      hasDivider: false,
    },
    {
      ...getColumnProps("unroundedValue"),
      render: (value, nutrient: NutrientTableDataItem) => {
        return (
          isNotRootNutrient(nutrient) && (
            <UnroundedValue
              value={value}
              nutrientData={nutrient as NutrientItem}
              errors={getColumnErrors(`${nutrient.key}.unroundedValue`)}
              displayActions={displayActions}
            />
          )
        );
      },
      hasDivider: false,
    },
    ...servingColumns,
    {
      ...getColumnProps("displayToConsumer"),
      render: (value, nutrient: NutrientTableDataItem) => {
        return (
          isNotRootNutrient(nutrient) && (
            <DisplayToConsumer
              value={value}
              disabled={mandatoryNutrientIds.includes(nutrient.nutrientId)}
              nutrientId={nutrient.nutrientId}
              onChange={
                extraActions?.updateSpecificationNutritionDeclarationAction
              }
            />
          )
        );
      },
    },
  ];

  return {
    columns,
  };
};

export default useNutritionColumns;
