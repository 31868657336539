import { Button } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import { isMaterialRawMaterialType } from "utils/library";
import libraryMessages from "messages/library";
import { RecipePanelActionsProps } from "./types";
import styles from "./styles.module.less";

const RecipePanelActions: React.FC<RecipePanelActionsProps> = ({
  ingredient,
  isConvertToCompositeDisabled,
  isSaveDisabled,
  onAddIngredient,
  onConvertToComposite,
  onSaveIngredient,
}) => {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.actionButtons}>
      {isMaterialRawMaterialType(ingredient.type) ? (
        <Button
          disabled={isConvertToCompositeDisabled}
          onClick={onConvertToComposite}
          type="secondary"
          data-test-id="recipe-panel-convert-to-composite"
        >
          {formatMessage(libraryMessages.convertToComposite)}
        </Button>
      ) : (
        <Button
          onClick={onAddIngredient}
          type="secondary"
          data-test-id="recipe-panel-add-ingredient"
        >
          {formatMessage(libraryMessages.addIngredient)}
        </Button>
      )}
      <Button
        disabled={isSaveDisabled()}
        onClick={onSaveIngredient}
        data-test-id="recipe-panel-save"
      >
        {formatMessage(libraryMessages.save)}
      </Button>
    </div>
  );
};

export default RecipePanelActions;
