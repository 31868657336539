import { ColProps } from "antd";
import { useMemo } from "react";
import { UiSchemaInfo } from "types/general";

const VERTICAL_LABEL_COL = { span: 24 };
const VERTICAL_WRAPPER_COL = { span: 24 };
let HORIZONTAL_LABEL_COL = { xs: 24, sm: 24, md: 8, lg: 8 };
let HORIZONTAL_WRAPPER_COL = { xs: 24, sm: 24, md: 16, lg: 16 };

const useFormItemCols = ({
  labelCol = VERTICAL_LABEL_COL,
  wrapperCol = VERTICAL_WRAPPER_COL,
  displayActions = true,
  uiSchema,
}: {
  labelCol?: ColProps;
  wrapperCol?: ColProps;
  displayActions?: boolean;
  uiSchema: UiSchemaInfo;
}) => {
  if (!displayActions) {
    HORIZONTAL_LABEL_COL = { ...HORIZONTAL_LABEL_COL, xs: 10 };
    HORIZONTAL_WRAPPER_COL = { ...HORIZONTAL_WRAPPER_COL, xs: 14 };
  }

  let isHorizontal = false;

  if (uiSchema["ui:horizontal"]) {
    isHorizontal = true;
  }

  const formItemLabelCol = useMemo(() => {
    if (uiSchema["ui:label:col"]) {
      return uiSchema["ui:label:col"];
    }

    if (isHorizontal) {
      return HORIZONTAL_LABEL_COL;
    }

    return labelCol;
  }, [isHorizontal, labelCol]);

  const formItemWrapperCol = useMemo(() => {
    if (uiSchema["ui:wrapper:col"]) {
      return uiSchema["ui:wrapper:col"];
    }

    if (isHorizontal) {
      return HORIZONTAL_WRAPPER_COL;
    }

    return wrapperCol;
  }, [isHorizontal, wrapperCol]);

  return {
    formItemLabelCol,
    formItemWrapperCol,
  };
};

export default useFormItemCols;
