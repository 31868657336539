import { useEffect, useState } from "react";
import { NutritionServingData, SpecificationData } from "models";
import { fetchNutritionServings } from "apis/SPEC";
import { useSelector } from "react-redux";
import { selectLanguageCode } from "store/user/selectors";
import { useAppDispatch } from "store";
import { updateServingDictionary } from "store/generalData/asyncActions";

const useServings = ({
  specificationId,
  shouldRefetchServings,
}: {
  specificationId: SpecificationData["id"];
  shouldRefetchServings?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const [nutritionServings, setNutritionServings] = useState<
    NutritionServingData[]
  >([]);

  const languageCode = useSelector(selectLanguageCode);

  const fetchServings = async () => {
    try {
      const { data } = await fetchNutritionServings({
        specificationId,
        languageCode,
      });

      setNutritionServings(data);

      dispatch(
        updateServingDictionary(
          data.reduce((acc, { name, servingId }) => {
            acc = { ...acc, [servingId]: name };
            return acc;
          }, {})
        )
      );
    } catch (_) {}
  };

  useEffect(() => {
    fetchServings();
  }, [specificationId]);

  useEffect(() => {
    if (shouldRefetchServings) {
      fetchServings();
    }
  }, [shouldRefetchServings]);

  return {
    nutritionServings,
  };
};

export default useServings;
