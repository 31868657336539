import { defineMessages } from "react-intl";

export const messages = defineMessages({
  collapseAll: {
    id: "specificationDetails.widgets.nutrition.collapseAll",
    defaultMessage: "Collapse all",
  },
  expandAll: {
    id: "specificationDetails.widgets.nutrition.expandAll",
    defaultMessage: "Expand all",
  },
});
