import React from "react";
import RefListWidget from "../RefListWidget";
import CompactInput from "../../components/CompactInput";

const RoCompactWidget = ({ properties, uiSchema }) => {
  const refListFilter = p =>
    Object.keys(p.content.props.schema).includes("referenceList");
  const refList = properties.find(refListFilter);
  const input = properties.find(p => !refListFilter(p));

  return !!input?.content.props.formData ? (
    <CompactInput>
      <span>{input?.content.props.formData}</span>&nbsp;
      <RefListWidget
        {...(refList?.content.props || {})}
        value={refList.content.props.formData}
        placeholder={uiSchema["ui:list:placeholder"]}
        options={{ dropdown: false }}
      />
    </CompactInput>
  ) : (
    <span>-</span>
  );
};

export default RoCompactWidget;
