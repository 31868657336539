import { API_VERSION } from "apis/constants";
import { axiosInstance } from "./";
import { SpecificationListItemData } from "models";

export const fetchTradeItemSpecifications = ({
  tradeItemId,
  ownerCompanyId,
  supplierId,
}: {
  tradeItemId: string;
  ownerCompanyId: string;
  supplierId?: string;
}) =>
  axiosInstance.get<SpecificationListItemData[]>(
    `api/${API_VERSION}trade-items/${tradeItemId}/specifications`,
    {
      params: {
        ownerCompanyId,
        supplierId,
      },
    }
  );
