import { TradeItemData } from "models";

export const getOnRemoveClick = ({
  dispatch,
  removeFolderProduct,
  productId,
}: {
  dispatch: Function;
  removeFolderProduct: Function;
  productId: TradeItemData["id"];
}) => event => {
  event.stopPropagation();

  dispatch(removeFolderProduct(productId));
};

export const getOnSaveProducts = ({
  dispatch,
  setFolderProducts,
}: {
  dispatch: Function;
  setFolderProducts: Function;
}) => async products => {
  await dispatch(setFolderProducts([...products]));
};
