import { useIntl, FormattedMessage } from "react-intl";
import { Button } from "@trace-one/design-system";
import Spin from "components/Spin";
import { useSelector } from "react-redux";
import { selectCompanyName } from "store/specificationDetails/selectors";
import { useRevisionActions } from "./hooks";
import { messages } from "./messages";
import styles from "components/Header/styles.module.less";
import { RevisionActionsProps } from "./types";

const RevisionActions: React.FC<RevisionActionsProps> = ({ id, history }) => {
  const companyName = useSelector(selectCompanyName);

  const { formatMessage } = useIntl();

  const { rejectRevision, acceptRevision, loading } = useRevisionActions({
    specificationId: id,
    history,
  });

  return (
    <Spin spinning={loading}>
      <div className={styles.revisionButtonsWrapper}>
        <span>
          <FormattedMessage
            {...messages.stickyBarText}
            values={{
              supplierName: companyName,
              b: (...chunks) => (
                <span className={styles.green}>&nbsp;{chunks}</span>
              ),
            }}
          />
        </span>
        <Button type="secondary" onClick={rejectRevision}>
          {formatMessage(messages.rejectRevision)}
        </Button>
        <Button type="primary" onClick={acceptRevision}>
          {formatMessage(messages.acceptRevision)}
        </Button>
      </div>
    </Spin>
  );
};

export default RevisionActions;
