import { useEffect, useState } from "react";
import { useRedirect } from "hooks";
import { LibraryObjectData, MaterialIngredientData } from "models";
import { LIBRARY_OBJECT_URL_TYPES } from "utils/constants";
import { EnhancedExtraActions } from "components/SpecificationSection/types";
import { Sorter } from "types/pagination";
import { MaterialType, RecipeType } from "types/library";
import { MaterialRecipeIngredientViewModel } from "viewModels";

const useRecipeTableActions = ({
  addRecipeIngredient,
  updateRecipeIngredient,
  removeRecipeIngredient,
  fetchRecipeIngredients,
  sortRecipeIngredient,
  sorter,
  fetchAllergens,
}: {
  addRecipeIngredient: EnhancedExtraActions["addRecipeIngredient"];
  updateRecipeIngredient: EnhancedExtraActions["updateRecipeIngredient"];
  removeRecipeIngredient: EnhancedExtraActions["removeRecipeIngredient"];
  fetchRecipeIngredients: EnhancedExtraActions["fetchRecipeIngredients"];
  sortRecipeIngredient: EnhancedExtraActions["sortRecipeIngredient"];
  sorter: Sorter;
  fetchAllergens: EnhancedExtraActions["fetchAllergens"];
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { redirectToLibraryDetails } = useRedirect();

  const onAddIngredients = async (selectedObjects: LibraryObjectData[]) => {
    try {
      for (let object of selectedObjects) {
        const { id } = object;

        await addRecipeIngredient({
          materialId: id,
        });
      }

      fetchRecipeIngredients();
      fetchAllergens();
      onCloseModal();
    } catch {}
  };
  const onUpdateIngredientPercentage = async (
    ingredient: MaterialRecipeIngredientViewModel,
    percentage: number
  ) => {
    const { ingredientId, functionId } = ingredient;

    await updateRecipeIngredient({
      ingredientId,
      functionId,
      percentage,
    });
    fetchRecipeIngredients();
  };

  const onUpdateIngredientFunction = async ({
    ingredientId,
    functionId,
    percentage,
  }: {
    ingredientId: MaterialIngredientData["ingredientId"];
    functionId: MaterialIngredientData["functionId"];
    percentage: MaterialIngredientData["percentage"];
  }) => {
    await updateRecipeIngredient({
      ingredientId,
      functionId,
      percentage,
    });
    fetchRecipeIngredients();
  };

  const onRemoveIngredient = ({
    ingredientId,
  }: {
    ingredientId: MaterialIngredientData["ingredientId"];
  }) => async () => {
    await removeRecipeIngredient({ ingredientId });
    fetchRecipeIngredients();
    fetchAllergens();
  };

  const onViewMaterialAction = ({
    materialId,
    type,
  }: {
    materialId: MaterialIngredientData["materialId"];
    type: MaterialType | RecipeType;
  }) => () => {
    redirectToLibraryDetails({
      id: materialId,
      type: LIBRARY_OBJECT_URL_TYPES[type],
    });
  };

  const onCloseModal = () => {
    setIsModalOpen(false);
  };

  const onOpenModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (sorter?.orderBy) {
      sortRecipeIngredient?.({ ...sorter });
    }
  }, [sorter]);

  return {
    isModalOpen,
    onAddIngredients,
    onUpdateIngredientPercentage,
    onUpdateIngredientFunction,
    onRemoveIngredient,
    onViewMaterialAction,
    onCloseModal,
    onOpenModal,
  };
};

export default useRecipeTableActions;
