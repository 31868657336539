import { useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router";
import { ROUTES } from "utils/constants";
import { persistor } from "store";
import { useUserInfo } from "hooks";
import { getOnCreateButtonClick } from "./utils";
import { messages } from "./messages";

const useButton = () => {
  const { formatMessage } = useIntl();
  const { push } = useHistory();
  const { pathname } = useLocation();
  const { isRetailer } = useUserInfo();

  const onCreateButtonClick = getOnCreateButtonClick({
    persistor,
    pathname,
    push,
  });

  const items = [
    {
      label: formatMessage(messages.createSpecificationBtn),
      onClick: onCreateButtonClick(ROUTES.SPECIFICATION_CREATION_PRODUCT_STEP),
    },
    {
      label: formatMessage(messages.createFolderBtn),
      onClick: onCreateButtonClick(ROUTES.CREATION_DETAILS_STEP),
    },
  ];
  const dataTestId = "action-create-button";

  const text = formatMessage(messages.createBtn);

  return {
    buttonProps: isRetailer ? { items, text, "data-test-id": dataTestId } : {},
  };
};

export default useButton;
