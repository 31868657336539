import qs from "qs";
import { fetchTags } from "apis/TAGS";
import { LibraryObjectData } from "models";
import {
  LIBRARY_OBJECT_API_TYPES,
  LIBRARY_OBJECT_TYPES,
} from "utils/constants";
import { isListEmpty } from "utils/general";

export const getTagsByLibraryObjects = async (
  libraryObjects: LibraryObjectData[]
) => {
  const tagIds = libraryObjects.reduce((tags, libraryObject) => {
    if (!isListEmpty(libraryObject.tagIds)) {
      tags.push(...libraryObject.tagIds);
    }
    return tags;
  }, []);

  if (!isListEmpty(tagIds)) {
    const res = await fetchTags({
      ids: tagIds,
    });

    return res.data;
  }
  return [];
};

export const prepareTypeParameter = (
  type: LIBRARY_OBJECT_TYPES
): LIBRARY_OBJECT_API_TYPES[] => {
  switch (type) {
    case LIBRARY_OBJECT_TYPES.PACKAGING_SYSTEMS:
      return undefined;
    default:
      return [LIBRARY_OBJECT_API_TYPES[type]];
  }
};

export const paramsSerializer = params =>
  qs.stringify(params, { arrayFormat: "comma" });
