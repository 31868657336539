import { NutriscorePointData } from "models";
import { Dictionary } from "types/general";

export const getPositiveNutrients = ({
  nutriscorePoints = [],
  catalogDictionary = {},
}: {
  nutriscorePoints: NutriscorePointData[];
  catalogDictionary: Dictionary;
}) => {
  const positiveNutrients = nutriscorePoints
    .filter(({ isNegativeScoreComponent }) => !isNegativeScoreComponent)
    .map(({ nutrientId, ...rest }) => ({
      name: catalogDictionary[nutrientId],
      nutrientId,
      ...rest,
    }));

  return positiveNutrients || [];
};

export const getNegativeNutrients = ({
  nutriscorePoints = [],
  catalogDictionary = {},
}: {
  nutriscorePoints: NutriscorePointData[];
  catalogDictionary: Dictionary;
}) => {
  const negativeNutrients: NutriscorePointData[] = nutriscorePoints
    .filter(({ isNegativeScoreComponent }) => isNegativeScoreComponent)
    .map(({ nutrientId, ...rest }) => ({
      name: catalogDictionary[nutrientId],
      nutrientId,
      ...rest,
    }));

  return negativeNutrients || [];
};

export const getNutrientIdsNotInCatalogDictionary = ({
  nutriscorePoints = [],
  catalogDictionary = {},
}: {
  nutriscorePoints: NutriscorePointData[];
  catalogDictionary: Dictionary;
}) => {
  const nutrientIdsNotInDictionary = nutriscorePoints
    .filter(
      nutriscorePoint =>
        nutriscorePoint.nutrientId &&
        !catalogDictionary.hasOwnProperty(nutriscorePoint.nutrientId)
    )
    .map(item => item.nutrientId);

  return nutrientIdsNotInDictionary;
};
