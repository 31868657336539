/* istanbul ignore file */

import axios, { AxiosResponse } from "axios";
import ProductMasterDataApi, {
  CreateManufacturedItem,
  CreateTradeItem,
  TradeItemParams,
} from "@trace-one/api-clients.pmd";
import { ParamsWithPagination } from "@trace-one/api-clients.base";
import { TradeItemData } from "models";

export const axiosInstance = axios.create({
  baseURL: window.env.PMD_URL,
});

export const PmdAPI = new ProductMasterDataApi(axiosInstance);

export const createManufacturedItem = ({
  id,
  body,
}: {
  id: string;
  body: CreateManufacturedItem;
}) => PmdAPI.createManufacturedItem(id, body);

export const createTradeItem = ({ body }: { body: CreateTradeItem }) =>
  PmdAPI.createTradeItem(body);

export const fetchTradeItems = ({
  ids,
  ownerCompanyId,
}: {
  ids: string[];
  ownerCompanyId?: string;
}): Promise<AxiosResponse<TradeItemData[]>> =>
  PmdAPI.getTradeItemsByIdCollection(
    { idCollection: ids },
    ownerCompanyId
      ? {
          ownerCompanyId,
        }
      : {}
  );

export const fetchTradeItem = ({ id }: { id: string }) =>
  PmdAPI.getTradeItemById(id);

export const filterTradeItems = ({
  params,
  searchText,
  tradeItemStatuses,
}: {
  params: ParamsWithPagination<TradeItemParams>;
  tradeItemStatuses?: string[];
  searchText?: string;
}) => PmdAPI.getTradeItemsByFilters({ searchText, tradeItemStatuses }, params);

export const fetchBrands = ({ ownerCompanyId }: { ownerCompanyId: string }) =>
  PmdAPI.getBrandsByOwnerCompanyId({ ownerCompanyId });

export const fetchManufacturedItems = ({
  ownerCompanyId,
}: {
  ownerCompanyId: string;
}) =>
  PmdAPI.getManufacturedItemsByFilters({
    ownerCompanyId,
  });
