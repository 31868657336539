import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import cx from "classnames";
import { Stepper } from "@trace-one/design-system";
import { Title } from "@trace-one/react-components";
import { UploadFiles } from "@trace-one/business-components";
import {
  selectSelectedProduct,
  selectSpecification,
  selectSpecificationTemplate,
  selectWizard,
} from "store/specificationCreation/selectors";
import { selectLanguageCode } from "store/user/selectors";
import {
  fetchSpecificationTemplates,
  validateStep,
} from "store/specificationCreation/asyncActions";
import withUnitsOfMeasure from "hocs/withUnitsOfMeasure";
import CreationWizzard from "components/CreationWizzard";
import AssignedProduct from "components/AssignedProduct";
import Note from "components/Note";
import { useSteps } from "pages/Specification/components/Creation/hooks";
import Details from "./components/Details";
import Footer from "./components/Footer";
import { useFiles } from "components/CreationWizzard/components/Files/hooks";
import { isListEmpty } from "utils/general";
import { messages } from "./messages";
import styles from "./styles.module.less";
import SpecificationTemplate from "components/SpecificationTemplate";

export const SpecificationSummary = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { note, draftId, files } = useSelector(selectSpecification);

  const template = useSelector(selectSpecificationTemplate);
  const { chapters } = template;

  const { filesWithDropdown } = useFiles({ files, chapters, disabled: true });

  const wizard = useSelector(selectWizard);

  const { stepIndex, currentIsValid, steps, onPreviousStep } = useSteps({
    currentStep: wizard.currentStep,
    stepsState: wizard.state,
  });

  useEffect(() => {
    wizard.currentStep === "summary" &&
      !currentIsValid &&
      dispatch(validateStep());
  }, [wizard.currentStep]);

  const selectedProduct = useSelector(selectSelectedProduct);
  const languageCode = useSelector(selectLanguageCode);

  useEffect(() => {
    dispatch(fetchSpecificationTemplates());
  }, [languageCode]);

  return (
    <>
      <CreationWizzard.Title.Specification />
      <Stepper
        stepIndex={stepIndex}
        currentIsValid={currentIsValid}
        steps={steps}
      />
      <Title
        level={2}
        value={intl.formatMessage(messages.detailsSectionTitle)}
        subtitle={intl.formatMessage(messages.detailsSectionSubtitle)}
      />
      <div className={cx(styles.details, styles.marginBottom)}>
        <Details />
      </div>
      <Title
        level={2}
        value={intl.formatMessage(messages.assignedProductSectionTitle)}
        subtitle={intl.formatMessage(messages.assignedProductSectionSubtitle)}
      />
      <div className={styles.marginBottom}>
        <div data-testid="list-items">
          <AssignedProduct.Specification
            displayActions={false}
            product={selectedProduct}
          />
        </div>
      </div>

      <SpecificationTemplate
        template={template}
        displayCounter={false}
        displayActions={false}
      />

      {!isListEmpty(filesWithDropdown) && (
        <>
          <Title
            level={2}
            value={intl.formatMessage(messages.documentSectionTitle)}
            subtitle={intl.formatMessage(messages.documentSectionSubtitle)}
          />
          <div className={cx(styles.marginBottom, styles.filesList)}>
            <UploadFiles.List fileList={filesWithDropdown} showDropdown />
          </div>
        </>
      )}

      <Title
        level={2}
        value={intl.formatMessage(messages.notesSectionTitle)}
        subtitle={intl.formatMessage(messages.notesSectionSubtitle)}
      />
      <Note content={note} type="specification" />
      <Footer specificationId={draftId} onPreviousStep={onPreviousStep} />
    </>
  );
};

export default withUnitsOfMeasure(SpecificationSummary);
