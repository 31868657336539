import { FolderData } from "models";
import { ROUTES } from "utils/constants";

export const onCreateClick = ({ push }: { push: Function }) => () => {
  push(ROUTES.CREATION_DETAILS_STEP);
};

export const onViewClick = ({
  redirectToFolderDetails,
  folderId,
}: {
  redirectToFolderDetails: Function;
  folderId: FolderData["folderId"];
}) => () => {
  redirectToFolderDetails(folderId);
};
