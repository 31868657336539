import { isListEmpty } from "utils/general";
import { NutrientFamilyData, NutrientItemData } from "models";
import { TreeNode } from "types/general";

const isItemInTreeChecked = (
  defaultCheckedItems: string[],
  children: NutrientItemData[]
) => {
  return children.every(({ id, hasChildren, children }) => {
    if (!hasChildren) {
      return defaultCheckedItems.includes(id);
    } else {
      return isItemInTreeChecked(defaultCheckedItems, children);
    }
  });
};

export const prepareCheckableTreeData = (
  data: NutrientFamilyData[] | NutrientItemData[],
  defaultCheckedItems: string[]
) => {
  if (!isListEmpty(data)) {
    return data.map(tree => {
      const nutrientId = tree.familyId || tree.id;
      const nutrientName = tree.familyName || tree.name;

      if (
        (tree?.children && !isListEmpty(tree.children)) ||
        (tree?.nutrients && !isListEmpty(tree.nutrients))
      ) {
        const isItemChecked = isItemInTreeChecked(
          defaultCheckedItems,
          tree.nutrients || tree.children
        );

        return {
          title: nutrientName,
          key: nutrientId,
          disabled: !!tree?.familyId ? false : isItemChecked,
          isChecked: isItemChecked,
          checkable: !tree?.familyId,
          children: [
            ...prepareCheckableTreeData(
              tree.nutrients || tree.children,
              defaultCheckedItems
            ),
          ],
        };
      }

      return {
        title: nutrientName,
        key: nutrientId,
        disabled: !!tree?.familyId
          ? false
          : defaultCheckedItems.includes(nutrientId),
        isChecked: defaultCheckedItems.includes(nutrientId),
        checkable: !tree?.familyId,
      };
    });
  }
};

export const collectDataFromTreeNode = (
  data: TreeNode[],
  checkedNutrientIds?: string[]
) => {
  if (!checkedNutrientIds) {
    checkedNutrientIds = [];
  }

  if (!isListEmpty(data)) {
    data.forEach(tree => {
      const isChecked = tree?.isChecked;
      if (isChecked) {
        checkedNutrientIds.push(tree.key);
      }
      if (tree?.children && !isListEmpty(tree.children)) {
        collectDataFromTreeNode(tree.children, checkedNutrientIds);
      }
    });
  }
  return { checkedNutrientIds };
};
