import { useSelector } from "react-redux";
import { useAppDispatch } from "store";
import {
  addFileId,
  addTag,
  removeTag,
  updateAdditionalInformation,
  updateContent,
  updateName,
  updateType,
} from "store/requirementForm/asyncActions";
import { selectRequirementInformation } from "store/requirementForm/selectors";
import { File, RequirementData } from "models";
import { RequirementType } from "types/library";
import { BaseSyntheticEvent } from "react";

const useRequirementInfoActions = () => {
  const dispatch = useAppDispatch();
  const requirementInformation = useSelector(selectRequirementInformation);

  const onSaveName = async (value: RequirementData["name"]) => {
    await dispatch(updateName(value));
  };

  const onSaveTags = async (tagIds: string[]) => {
    const formDataTags = requirementInformation?.tagIds || [];

    if (formDataTags?.length === tagIds.length) {
      return;
    }

    if (formDataTags?.length > tagIds.length) {
      const tagToRemove = formDataTags.find(
        currentTagId => !tagIds.includes(currentTagId)
      );

      await dispatch(removeTag(tagToRemove));
    } else {
      const tagToAdd = tagIds.find(
        currentTagId => !formDataTags.includes(currentTagId)
      );

      await dispatch(addTag(tagToAdd));
    }
  };

  const onSaveContent = async (content: RequirementData["content"]) => {
    if (content) {
      await dispatch(updateContent(content));
    }
  };

  const onChangeType = async (type: RequirementType) => {
    await dispatch(updateType(type));
  };

  const onUploadFiles = async (files: File[]) => {
    await dispatch(addFileId(files[0].id));
  };

  const onSaveAdditionalInformation = async ({
    target: { value },
  }: BaseSyntheticEvent) => {
    await dispatch(updateAdditionalInformation(value));
  };

  return {
    onSaveName,
    onSaveTags,
    onSaveContent,
    onChangeType,
    onUploadFiles,
    onSaveAdditionalInformation,
  };
};

export default useRequirementInfoActions;
