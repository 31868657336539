import { FormInstance } from "antd";
import { createContext } from "react";

export const ClaimFormContext = createContext<{
  setLogoFile?: Function;
  form?: FormInstance;
  isLoading?: boolean;
}>({
  setLogoFile: () => {},
  form: null,
  isLoading: false,
});
