import { useMemo } from "react";
import { Row, Col } from "antd";
import { Heading, Paragraph } from "@trace-one/design-system";
import classnames from "classnames";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { selectRefListDictionary } from "store/generalData/selectors";
import {
  selectContacts,
  selectFolderDetails,
  selectFolderOwnerUserDetails,
} from "store/folderCreation/selectors";
import { getSelectedOwnerContactsNames } from "pages/utils";
import { messages } from "./messages";
import styles from "./styles.module.less";

const FolderDetails = () => {
  const { folderName, targetDateUtc, folderDescription } = useSelector(
    selectFolderDetails
  );
  const contacts = useSelector(selectContacts);
  const refListDictionary = useSelector(selectRefListDictionary);
  const ownerUserDetails = useSelector(selectFolderOwnerUserDetails);
  const { productTypeId } = useSelector(selectFolderDetails);

  const selectedContacts = useMemo(
    () =>
      getSelectedOwnerContactsNames([
        { ...ownerUserDetails, isSelected: true },
        ...contacts,
      ]),
    [contacts]
  );

  return (
    <div className={classnames(styles.folderDetails)}>
      <Row
        className={styles.detailsRow}
        justify="space-between"
        gutter={[20, 20]}
      >
        <Col>
          <Heading size="xs">
            <FormattedMessage {...messages.detailsFolderName} />:
            <span className={styles.value}>{folderName}</span>
          </Heading>
        </Col>
        <Col>
          <Heading size="xs">
            <FormattedMessage {...messages.detailsRetailerContact} />:
            <span className={styles.value}>{selectedContacts}</span>
          </Heading>
        </Col>
        <Col>
          <Heading size="xs">
            <FormattedMessage {...messages.detailsProductType} />:
            <span className={styles.value}>
              {refListDictionary[productTypeId]}
            </span>
          </Heading>
        </Col>
        <Col>
          <Heading size="xs">
            <FormattedMessage {...messages.detailsTargetDate} />:
            <span className={styles.value}>
              {dayjs(targetDateUtc).format("DD-MM-YYYY")}
            </span>
          </Heading>
        </Col>
      </Row>
      <Row className={styles.detailsRow} gutter={[16, 16]}>
        <Col span={24}>
          <Heading size="xs">
            <FormattedMessage {...messages.detailsDescription} />:
          </Heading>
          <Paragraph
            dangerouslySetInnerHTML={{ __html: folderDescription }}
            className={styles.description}
          />
        </Col>
      </Row>
    </div>
  );
};

export default FolderDetails;
