import { GeneralInfoData } from "models";

export enum ModalActionType {
  UNLINK = "unlink",
  UPDATE = "update",
  REVISE = "revise",
}

export interface DetailsAndLinksChapterModalProps {
  onCloseModal: Function;
  chapterId?: GeneralInfoData["id"];
  selectedChapterId?: GeneralInfoData["id"];
  chapterType?: GeneralInfoData["type"];
  states?: string[];
  rowSelection?: {
    selectedRowKeys?: string[];
    onSelect?: Function;
    onSelectAll?: Function;
  };
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  primaryButtonLabel?: React.ReactNode;
  onPrimaryButtonClick?: Function;
  secondaryButtonLabel?: React.ReactNode;
  onSecondaryButtonClick?: Function;
  actionType?: ModalActionType;
}
