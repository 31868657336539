import { defineMessages } from "react-intl";

export const messages = defineMessages({
  modificationRequest: {
    id: "modificationRequest.request",
    defaultMessage: "Modification request",
  },
  modificationRequests: {
    id: "modificationRequest.requests",
    defaultMessage: "Modification requests",
  },

  seeAllRequests: {
    id: "modificationRequest.seeAllRequests",
    defaultMessage: "See all requests",
  },
  requestModification: {
    id: "modificationRequest.requestModification",
    defaultMessage: "Request modification",
  },
  draftBannerDescription: {
    id: "modificationRequest.draftBanner.description",
    defaultMessage:
      "Your request is saved as a draft. Please notify collaborator to make it an active request.",
  },
  draftBannerDescriptionLink: {
    id: "modificationRequest.draftBanner.descriptionLink",
    defaultMessage: "Notify collaborators",
  },
  draftBannerDescriptionLinkTooltip: {
    id: "modificationRequest.draftBanner.descriptionLink.tooltipTitle",
    defaultMessage: "Do you want to share this request?",
  },
  confirmationToasterTitle: {
    id: "modificationRequest.confirmationToaster.title",
    defaultMessage: "Collaborators notified",
  },
  confirmationToasterDescription: {
    id: "modificationRequest.confirmationToaster.description",
    defaultMessage:
      "{companyDisplayName} has been notified of your request for modification.",
  },
  messagePlaceholder: {
    id: "modificationRequest.message.placeholder",
    defaultMessage: "Enter a message here...",
  },
  modificationRequestNotifyModalTitle: {
    id: "modificationRequest.notifyModal.title",
    defaultMessage: "Requests created",
  },
  modificationRequestNotifyModalDescription: {
    id: "modificationRequest.notifyModal.description",
    defaultMessage: "Your requests have been created.",
  },
  modificationRequestNotifyModalStartOverDescription: {
    id: "modificationRequest.notifyModal.startOverDescription",
    defaultMessage:
      "You can notify your collaborators now or save them as draft and notify them later.",
  },
  modificationRequestNotifyModalPrimaryBtnLabel: {
    id: "modificationRequest.notifyModal.primaryBtnLabel",
    defaultMessage: "Save and notify now",
  },
  modificationRequestNotifyModalSecondaryBtnLabel: {
    id: "modificationRequest.notifyModal.secondaryBtnLabel",
    defaultMessage: "Keep as draft",
  },
  modificationRequestActionSetAsPerformed: {
    id: "modificationRequest.action.setAsPerformed",
    defaultMessage: "Set as performed",
  },
  modificationRequestActionDecline: {
    id: "modificationRequest.action.decline",
    defaultMessage: "Decline request",
  },
  modificationRequestActionSetAsCompleted: {
    id: "modificationRequest.action.setAsCompleted",
    defaultMessage: "Set as completed",
  },
});
