import { TemplateSectionData } from "models";

export const getChangeVisibilityForSection = ({
  onHideSection,
  onUnhideSection,
}: {
  onHideSection: Function;
  onUnhideSection: Function;
}) => (sectionId: TemplateSectionData["id"]) => async (visible: boolean) => {
  if (visible) {
    await onUnhideSection(sectionId);
  } else {
    await onHideSection(sectionId);
  }
};

export const getChangeVisibilityForChapter = ({
  onHideChapter,
  onUnhideChapter,
}: {
  onHideChapter: Function;
  onUnhideChapter: Function;
}) => (sectionId: TemplateSectionData["id"]) => async (visible: boolean) => {
  if (visible) {
    await onUnhideChapter(sectionId);
  } else {
    await onHideChapter(sectionId);
  }
};

export const getOnCancelSectionComment = ({
  setCommentModal,
}: {
  setCommentModal: Function;
}) => () => {
  setCommentModal({});
};

export const getOnSaveSectionComment = ({
  onUpdateSectionComment,
  sectionId,
  chapterType,
  onCancel,
}: {
  onUpdateSectionComment: Function;
  sectionId: TemplateSectionData["id"];
  chapterType?: TemplateSectionData["chapterType"];
  onCancel: Function;
}) => async (comment: string) => {
  await onUpdateSectionComment({
    sectionId,
    comment,
    chapterType,
  });

  onCancel();
};
