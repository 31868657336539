import { createSlice } from "@reduxjs/toolkit";
import initialState from "./initialState";
import reducers from "./reducers";

export const slice = createSlice({
  name: "collaborations",
  initialState,
  reducers,
});

export const { setCollaborationData } = slice.actions;

export default slice.reducer;
