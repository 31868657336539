import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectOwningCompanyId } from "store/user/selectors";
import { useLibraryItemTypeDictionary } from "hooks";
import { prepareLibraryListItemFromApiData } from "dtos/libraryItem";
import { getSkipParamForApi } from "utils/general";
import {
  LIBRARY_OBJECT_TYPES,
  TAKE_ITEMS_FOR_INFINITE_PAGINATION,
} from "utils/constants";
import { UseTemplateModalProps } from "./types";
import { fetchTemplates } from "apis/SPEC";
import { LibraryListItemViewModel } from "viewModels";

const useTemplateModal = ({
  infiniteScrollWrapper,
  productTypeId,
}: UseTemplateModalProps) => {
  const ownerCompanyId = useSelector(selectOwningCompanyId);

  const [templates, setTemplates] = useState<LibraryListItemViewModel[]>([]);

  const [isLoading, setIsLoading] = useState(true);
  const [canLoadMore, setCanLoadMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalNumberOfItems, setTotalNumberOfItems] = useState(1);
  const [searchText, setSearchText] = useState<string>();

  const { typeDictionary } = useLibraryItemTypeDictionary({
    type: LIBRARY_OBJECT_TYPES.TEMPLATES,
  });

  const loadTemplates = async ({
    searchText,
    isNewSearch,
  }: {
    searchText: string;
    isNewSearch?: boolean;
  }) => {
    try {
      setIsLoading(true);

      const {
        data: { items, totalNumberOfItems },
      } = await fetchTemplates({
        params: {
          take: TAKE_ITEMS_FOR_INFINITE_PAGINATION,
          skip: getSkipParamForApi({
            isNewSearch,
            currentPage,
            pageSize: TAKE_ITEMS_FOR_INFINITE_PAGINATION,
          }),
          ownerCompanyId,
          name: searchText,
          productTypeId,
          excludeDrafts: true,
        },
      });

      const itemsInViewModal = items.map(libraryItem =>
        prepareLibraryListItemFromApiData({
          libraryItem,
          type: LIBRARY_OBJECT_TYPES.TEMPLATES,
          typeDictionary,
        })
      );

      let newItemsLength = itemsInViewModal.length;
      setCurrentPage(previousState => (isNewSearch ? 2 : previousState + 1));

      if (isNewSearch) {
        setTemplates(itemsInViewModal);
      } else {
        setTemplates(previousState => {
          const newItemsList = [...previousState, ...itemsInViewModal];

          newItemsLength = newItemsList.length;

          return newItemsList;
        });
      }

      setTotalNumberOfItems(totalNumberOfItems);

      setCanLoadMore(totalNumberOfItems > newItemsLength);
    } catch (_) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadTemplates({ searchText });
  }, []);

  useEffect(() => {
    if (searchText !== undefined) {
      // this is solving the issue of making multiple requests because the scroll is not at the top
      infiniteScrollWrapper.current.scrollTo({ top: 0 });

      loadTemplates({ searchText, isNewSearch: true });
    }
  }, [searchText]);

  return {
    isLoading,
    templates,
    canLoadMore,
    currentPage,
    totalNumberOfItems,
    loadTemplates,
    searchText,
    setSearchText,
  };
};

export default useTemplateModal;
