import { useContext } from "react";
import { useIntl } from "react-intl";
import { TreeSelect, Label } from "@trace-one/react-components";
import { useChapters } from "./hooks";
import { HistorisationContext } from "components/SpecificationHistorisationModal/contexts";
import { filterMessages } from "components/SpecificationHistorisationModal/messages";

const Chapters = () => {
  const { formatMessage } = useIntl();

  const {
    chapters,
    filterTags,
    addChapter,
    clearChapter,
    onClearFiltersClick,
    areFiltersDisabled,
  } = useContext(HistorisationContext);

  const {
    treeData,
    selectedChapters,
    onSelect,
    onDeselect,
    onClear,
  } = useChapters({
    chapters,
    filterTags,
    addChapter,
    clearChapter,
    onClearFiltersClick,
  });

  return (
    <Label title={formatMessage(filterMessages.chapters)}>
      <TreeSelect
        name="chapter-section-field"
        treeData={treeData}
        placeholder={formatMessage(filterMessages.chapters)}
        disabled={areFiltersDisabled}
        onSelect={onSelect}
        onDeselect={onDeselect}
        onClear={onClear}
        value={selectedChapters}
        multiple
      />
    </Label>
  );
};

export default Chapters;
