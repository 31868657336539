/* istanbul ignore file */

import axios from "axios";

export const axiosInstance = axios.create({
  baseURL: window.env.SPEC_URL,
});

export * from "./chapter";
export * from "./folder";
export * from "./specification";
export * from "./specificationDraft";
export * from "./specificationHistory";
export * from "./requirement";
export * from "./tradeItem";
export * from "./lists";
export * from "./material";
export * from "./template";
export * from "./wizard";
export * from "./modificationRequest";
export * from "./claim";
