import { Modal, Row } from "antd";
import { useSelectedLibraryObject } from "hooks";
import ModalCloseIcon from "components/ModalCloseIcon";
import { SelectLibraryObjectsContext } from "./contexts";
import { SelectLibraryObjectsModalProps } from "./types";
import styles from "./styles.module.less";

const SelectLibraryObjectsModal: React.FC<SelectLibraryObjectsModalProps> = ({
  children,
  onClose,
  ...rest
}) => {
  const {
    selectedLibraryObjects,
    onSelectLibraryObject,
    setSelectedLibraryObjects,
  } = useSelectedLibraryObject({});

  return (
    <Modal
      open
      centered
      className={styles.modal}
      closeIcon={<ModalCloseIcon />}
      width="90%"
      // @ts-ignore
      height="90%"
      footer={null}
      onCancel={onClose}
      {...rest}
    >
      <SelectLibraryObjectsContext.Provider
        value={{
          selectedLibraryObjects,
          setSelectedLibraryObjects,
          onSelectLibraryObject,
          onClose,
        }}
      >
        <Row className={styles.modalContainer}>{children}</Row>
      </SelectLibraryObjectsContext.Provider>
    </Modal>
  );
};

export default SelectLibraryObjectsModal;
