import { defineMessages } from "react-intl";

export const messages = defineMessages({
  createBtn: {
    id: "header.create",
    defaultMessage: "Create",
  },

  createSpecificationBtn: {
    id: "header.create.specification",
    defaultMessage: "Create a specification",
  },

  createFolderBtn: {
    id: "header.create.folder",
    defaultMessage: "Create a folder",
  },
});
