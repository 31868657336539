import { Row, Col } from "antd";
import { isListEmpty, isObjectEmpty } from "utils/general";
import { NetContentsListingProps } from "./types";
import styles from "../../styles.module.less";

const NetContentsListing: React.FC<NetContentsListingProps> = ({
  netContents,
  title,
  refListDictionary,
}) => {
  return (
    <>
      <Row className={styles.netContentsRow}>
        <Col className={styles.netContentsTitle}>{title}</Col>
        {!isListEmpty(netContents) &&
          !isObjectEmpty(refListDictionary) &&
          netContents.map(netContent => (
            <Col
              key={netContent.unitOfMeasure}
              className={styles.netContentValue}
            >
              {`${netContent.value} - ${
                refListDictionary[netContent.unitOfMeasure]
              }`}
            </Col>
          ))}
      </Row>
    </>
  );
};

export default NetContentsListing;
