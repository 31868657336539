import { Select } from "@trace-one/design-system";
import { useListItems } from "hooks";
import { ExternalListSelectProps } from "./types";
import { isListEmpty } from "utils/general";
import { useExternalListAutocomplete, useExternalListSelect } from "./hooks";

const ExternalListSelect: React.FC<ExternalListSelectProps> = ({
  list,
  placeholder,
  mode,
  showSearch = true,
  value,
  defaultValue,
  allowClear,
  onBlur: defaultOnBlur,
  onChange: defaultOnChange,
  onDeselect: defaultOnDeselect,
  onSelect: defaultOnSelect,
  onDropdownVisibleChange: defaultOnDropdownVisibleChange,
  onSearch: defaultOnSearch,
  disabled,
}) => {
  const { items } = useListItems(list);

  const { onSelect, onDeselect } = useExternalListSelect({
    defaultOnSelect,
    defaultOnDeselect,
  });

  const { selectOptions, onSearch, onBlur } = useExternalListAutocomplete({
    items,
    defaultOnSearch,
    defaultOnBlur,
  });

  return (
    <Select
      disabled={disabled}
      showArrow
      labelInValue
      placeholder={placeholder}
      filterOption={false}
      mode={mode}
      options={isListEmpty(selectOptions) ? items : selectOptions}
      allowClear={allowClear}
      value={value || undefined}
      defaultValue={defaultValue}
      onBlur={onBlur}
      onChange={defaultOnChange}
      onDeselect={onDeselect}
      onSelect={onSelect}
      onDropdownVisibleChange={defaultOnDropdownVisibleChange}
      {...(showSearch && {
        onSearch,
      })}
    />
  );
};

export default ExternalListSelect;
