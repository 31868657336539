import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store/rootReducer";

export const selectCollaboration = createSelector(
  (state: RootState) => state,
  ({ collaboration }) => collaboration
);

export const selectCollaborationData = createSelector(
  (state: RootState) => state,
  ({ collaboration }) => collaboration.collaborationData
);
