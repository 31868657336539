import Input from "./components/Input";
import InputNumber from "./components/InputNumber";
import Textarea from "./components/Textarea";

const Debounced = {
  Input,
  InputNumber,
  Textarea,
};

export default Debounced;
