import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import Card from "components/Card";
import { selectFolderDetails } from "store/folderCreation/selectors";
import { useActions, useDisabledProps } from "./hooks";

const ProductSpecification = ({
  specification,
  displayActionList,
  displayCheckbox,
  errorMessage,
  hasError,
  onChange,
}) => {
  const { folderId } = useSelector(selectFolderDetails);

  const { isDone, actionList } = useActions({
    specification,
    displayActionList,
  });

  const disabledProps = useDisabledProps({
    currentFolderId: folderId,
    folderId: specification.folderId,
    state: specification.state,
    lastVersion: specification.lastVersion,
  });

  return (
    <Card.Object
      actions={actionList}
      actionsProps={{
        loading: !isDone,
      }}
      checkboxProps={{
        ...disabledProps,
        hasCheckbox: displayCheckbox,
        isChecked: specification.folderId === folderId,
        onChange: onChange(specification.specificationId),
      }}
      status={specification.state}
      hasError={hasError}
      errorMessage={errorMessage}
      object={{
        name: specification.supplierName,
        type: "specification",
        versionNumber: specification.version?.number,
      }}
    />
  );
};

ProductSpecification.propTypes = {
  specification: PropTypes.object.isRequired,
  displayActionList: PropTypes.bool,
  displayCheckbox: PropTypes.bool,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func,
};

export default ProductSpecification;
