import { GeneralInfoData, ReorderSectionsPayload, TemplateData } from "models";
import { specRtkBaseApi } from "../specRtkApi";

const templateApi = specRtkBaseApi.injectEndpoints({
  endpoints: build => ({
    createTemplate: build.mutation<
      TemplateData,
      {
        params: any;
        payload: Partial<GeneralInfoData> & { templateName: string };
      }
    >({
      query: ({ params, payload }) => ({
        url: `templates`,
        method: "POST",
        data: payload,
        params,
      }),
      invalidatesTags: ["Templates"],
    }),

    shareTemplate: build.mutation<
      void,
      {
        templateId: string;
        companyId: string;
      }
    >({
      query: ({ templateId, companyId }) => ({
        url: `templates/${templateId}/share`,
        method: "POST",
        data: { companyId },
      }),
      invalidatesTags: ["Templates"],
    }),

    publishTemplate: build.mutation<void, string>({
      query: templateId => ({
        url: `templates/${templateId}/publish`,
        method: "POST",
      }),
      invalidatesTags: ["Templates"],
    }),

    deleteTemplate: build.mutation<TemplateData, string>({
      query: id => ({
        url: `templates/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Templates"],
    }),

    updateTemplateName: build.mutation<
      TemplateData,
      {
        params: any;
        payload: { id: string; name: string };
      }
    >({
      query: ({ params, payload: { id, name } }) => {
        return {
          url: `templates/${id}/name`,
          method: "PUT",
          data: name,
          params,
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      invalidatesTags: template => [
        { type: "Templates", id: template?.generalInfo?.id },
      ],
    }),

    getTemplateById: build.query<
      TemplateData,
      {
        templateId: string;
        params?: { languageCode?: string; excludeHiddenSections?: boolean };
      }
    >({
      query: ({ templateId, params }) => ({
        url: `templates/${templateId}`,
        method: "GET",
        params,
      }),
      providesTags: (_result, _err, { templateId }) => [
        { type: "Templates", templateId },
      ],
    }),

    addTag: build.mutation<
      TemplateData,
      { params: any; payload: { templateId: string; tagId: string } }
    >({
      query: ({ params, payload: { templateId, tagId } }) => ({
        url: `templates/${templateId}/tags`,
        method: "POST",
        data: [tagId],
        params,
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["Templates"],
    }),

    removeTag: build.mutation<
      TemplateData,
      { templateId: string; tagId: string }
    >({
      query: ({ templateId, tagId }) => ({
        url: `templates/${templateId}/tags/${tagId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Templates"],
    }),

    hideChapter: build.mutation<
      TemplateData,
      { templateId: string; payload: { chapterType: string } }
    >({
      query: ({ templateId, payload }) => ({
        url: `templates/${templateId}/hide-chapter`,
        method: "POST",
        data: payload,
      }),
      invalidatesTags: ["Templates"],
    }),

    unhideChapter: build.mutation<
      TemplateData,
      { templateId: string; payload: { chapterType: string } }
    >({
      query: ({ templateId, payload }) => ({
        url: `templates/${templateId}/un-hide-chapter`,
        method: "POST",
        data: payload,
      }),
      invalidatesTags: ["Templates"],
    }),

    hideSection: build.mutation<
      TemplateData,
      {
        templateId: string;
        payload: { chapterType: string; sectionId: string };
      }
    >({
      query: ({ templateId, payload }) => ({
        url: `templates/${templateId}/hide-section`,
        method: "POST",
        data: payload,
      }),
      invalidatesTags: ["Templates"],
    }),

    unhideSection: build.mutation<
      TemplateData,
      {
        templateId: string;
        payload: { chapterType: string; sectionId: string };
      }
    >({
      query: ({ templateId, payload }) => ({
        url: `templates/${templateId}/un-hide-section`,
        method: "POST",
        data: payload,
      }),
      invalidatesTags: ["Templates"],
    }),
    reorderSections: build.mutation<
      TemplateData,
      {
        templateId: string;
        payload: ReorderSectionsPayload;
        params: { languageCode: string };
      }
    >({
      query: ({ templateId, payload, params }) => ({
        url: `templates/${templateId}/reorder-sections`,
        method: "POST",
        data: payload,
        params,
      }),
      invalidatesTags: ["Templates"],
    }),

    updateUserGuide: build.mutation<
      TemplateData,
      {
        id: string;
        payload: { content: string; sectionId: string; chapterType: string };
        params: { languageCode: string };
      }
    >({
      query: ({ id, payload, params }) => {
        return {
          url: `templates/${id}/user-guide`,
          method: "PUT",
          data: payload,
          params,
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      invalidatesTags: template => [
        { type: "Templates", id: template?.generalInfo?.id },
      ],
    }),
  }),
});

export const {
  useCreateTemplateMutation: useCreateTemplate,
  useRemoveTagMutation: useRemoveTag,
  useAddTagMutation: useAddTag,
  useUpdateTemplateNameMutation: useUpdateTemplateName,
  useDeleteTemplateMutation: useDeleteTemplate,
  useShareTemplateMutation: useShareTemplate,
  useHideChapterMutation: useHideChapter,
  useUnhideChapterMutation: useUnhideChapter,
  useLazyGetTemplateByIdQuery: useGetTemplate,
  usePublishTemplateMutation: usePublishTemplate,
  useHideSectionMutation: useHideSection,
  useUnhideSectionMutation: useUnhideSection,
  useReorderSectionsMutation: useReorderSections,
  useUpdateUserGuideMutation: useUpdateUserGuide,
} = templateApi;
