import { useRedirect } from "hooks";
import { useDispatch } from "react-redux";
import {
  removeFolder,
  updateCollaboration,
} from "store/folderCreation/asyncActions";

const useCreationActions = () => {
  const dispatch = useDispatch();
  const { redirectToFolderListing } = useRedirect();
  const getOnFolderDelete = () => async () => {
    await dispatch(removeFolder());
    redirectToFolderListing();
  };

  const handleChangeEnableSupplierCollaboration = ({
    collaboratingCompanyId,
    isEnabled,
  }) => {
    dispatch(updateCollaboration({ collaboratingCompanyId, isEnabled }));
  };

  return {
    getOnFolderDelete,
    handleChangeEnableSupplierCollaboration,
  };
};

export default useCreationActions;
