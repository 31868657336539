import { defineMessages } from "react-intl";

export const messages = defineMessages({
  cancelBtn: {
    id: "components.button.cancel",
    defaultMessage: "Cancel",
  },

  confirmBtn: {
    id: "components.button.addToTable",
    defaultMessage: "Add to the table",
  },
});
