import Creation from "./components/Creation";
import Details from "./components/Details";
import List from "./components/List";

const Folder = {
  Creation,
  Details,
  List,
};

export default Folder;
