export const getErrorsProps = ({
  hasRemoveProductError,
  hasRemoveSupplierError,
  errorRemoveProductMessage,
  errorRemoveSupplierMessage,
}) => {
  if (hasRemoveProductError) {
    return {
      hasError: true,
      errorMessage: errorRemoveProductMessage,
    };
  }

  if (hasRemoveSupplierError) {
    return {
      hasError: true,
      errorMessage: errorRemoveSupplierMessage,
    };
  }

  return {};
};
export const getHandleSelectSupplier = ({
  dispatch,
  setSpecificationSupplier,
}) => value => {
  dispatch(setSpecificationSupplier(value));
};
