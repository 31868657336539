import { useEffect, useState } from "react";

const useModalVisibility = (defaultVisibitlity = false) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onOpenModal = () => {
    setIsModalOpen(true);
  };

  const onCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    setIsModalOpen(defaultVisibitlity);
  }, [defaultVisibitlity]);

  return {
    isModalOpen,
    onOpenModal,
    onCloseModal,
  };
};

export default useModalVisibility;
