import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { toaster as toasterService } from "@trace-one/design-system";
import { linkChapterToSpecification } from "apis/SPEC";
import { useBoolean } from "hooks";
import { LibraryObjectData, SpecificationData } from "models";
import { messages } from "../../messages";

const useLinkChapter = ({
  specificationId,
  chapterType,
  afterLinkCallback,
}: {
  specificationId: SpecificationData["id"];
  chapterType: string;
  afterLinkCallback: Function;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { value: isOpen, setFalse: onClose, setTrue } = useBoolean(false);
  const [selectedChapter, setSelectedChapter] = useState<LibraryObjectData>(
    undefined
  );
  const { formatMessage } = useIntl();

  useEffect(() => {
    setSelectedChapter(undefined);
  }, [isOpen]);

  const onChapterSelect = (item: LibraryObjectData) => {
    setSelectedChapter(item);
  };

  const onLinkChapterToSpecification = async () => {
    const { id, name } = selectedChapter;

    try {
      setIsLoading(true);

      await linkChapterToSpecification({
        specificationId,
        chapterType,
        chapterId: id,
      });

      toasterService.open({
        message: formatMessage(messages.chapterLinkedSuccessTitle),
        description: formatMessage(messages.chapterLinkedSuccessDescription, {
          chapterName: name,
        }),
        type: "confirmation",
      });

      if (typeof afterLinkCallback === "function") {
        afterLinkCallback();
      }

      onClose();
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    isOpen,
    selectedChapter,
    onChapterSelect,
    onLinkChapterToSpecification,
    onBrowseClick: setTrue,
    onClose,
  };
};

export default useLinkChapter;
