import { TreeSelect } from "@trace-one/react-components";
import { useCategoryTreeSelect } from "./hooks";
import { CategoryTreeSelectProps } from "./types";

const CategoryTreeSelect: React.FC<CategoryTreeSelectProps> = ({
  value,
  multiple,
  onChange: defaultOnChange,
  onFullChange,
  onDropdownVisibleChange: defaultOnDropdownVisibleChange,
  multiParentSelection,
  minLengthToAsyncSearch,
  name,
  shouldCurrentValueBeUpdated,
  highlightedItems,
  categoryId,
  // if parentId is provided is used for filtering when making API calls
  parentId,
  shouldRemoveFirstLevelParent = false,
  shouldFirstLevelBeSelectable = true,
  shouldCallApi = true,
  ...rest
}) => {
  const {
    selectProps,
    searchItemName,
    isLoading,
    treeData,
    treeExpandedKeys,
    onChange,
    onLoadData,
    onSearch,
    onTreeExpand,
    onDropdownVisibleChange,
  } = useCategoryTreeSelect({
    value,
    highlightedItems,
    multiple,
    onFullChange,
    minLengthToAsyncSearch,
    categoryId,
    parentId,
    onChange: defaultOnChange,
    onDropdownVisibleChange: defaultOnDropdownVisibleChange,
    shouldCurrentValueBeUpdated,
    multiParentSelection,
    shouldFirstLevelBeSelectable,
    shouldCallApi,
  });

  return (
    <TreeSelect
      name={name}
      loading={isLoading}
      multiple={multiple}
      {...rest}
      treeExpandedKeys={treeExpandedKeys}
      treeLoadedKeys={treeExpandedKeys}
      {...selectProps}
      loadData={onLoadData}
      treeData={
        shouldRemoveFirstLevelParent
          ? treeData.filter(({ pId }) => pId !== null)
          : treeData
      }
      searchValue={searchItemName}
      onChange={onChange}
      onSearch={onSearch}
      onTreeExpand={onTreeExpand}
      onDropdownVisibleChange={onDropdownVisibleChange}
      filterTreeNode={false}
      labelInValue
    />
  );
};

CategoryTreeSelect.defaultProps = {
  minLengthToAsyncSearch: 3,
  name: "category-tree-select",
  shouldCurrentValueBeUpdated: true,
  highlightedItems: [],
  onChange: () => {},
};

export default CategoryTreeSelect;
