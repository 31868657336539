import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { fetchChapterVersions } from "apis/SPEC";
import { ChapterVersion, GeneralInfoData } from "models";
import { useModalVisibility, useRowSelection } from "hooks";
import { detailsAndLinksChapterMessages } from "../../messages";
import { messages as specificationMessages } from "messages/specification";
import generalMessages from "messages/general";
import { SPECIFICATION_STATE } from "utils/constants";
import { ModalActionType } from "components/DetailsAndLinksChapterModal/types";

const ActionTypeMapping = {
  [ModalActionType.UNLINK]: {
    states: [
      SPECIFICATION_STATE.inProgress,
      SPECIFICATION_STATE.validatedByRetailer,
      SPECIFICATION_STATE.validatedBySupplier,
    ],
    title: detailsAndLinksChapterMessages.unlinkSpecificationsTitle,
    subtitle: detailsAndLinksChapterMessages.unlinkSpecificationsSubtitle,
    primaryButtonLabel: detailsAndLinksChapterMessages.unlinkButtonLabel,
  },
  [ModalActionType.UPDATE]: {
    states: [
      SPECIFICATION_STATE.inProgress,
      SPECIFICATION_STATE.validatedByRetailer,
      SPECIFICATION_STATE.validatedBySupplier,
    ],
    title: detailsAndLinksChapterMessages.updateToLatestVersionTitle,
    subtitle: detailsAndLinksChapterMessages.updateToLatestVersionSubtitle,
    primaryButtonLabel: generalMessages.update,
  },
  [ModalActionType.REVISE]: {
    states: [SPECIFICATION_STATE.signedByRetailer],
    title: specificationMessages.createARevision,
    subtitle: detailsAndLinksChapterMessages.createARevisionSubtitle,
    primaryButtonLabel: detailsAndLinksChapterMessages.createRevisionBtn,
  },
};

const useVersionHistory = ({
  chapterType,
  chapterId,
}: {
  chapterType: string;
  chapterId: string;
}) => {
  const { formatMessage } = useIntl();

  const [versionHistoryList, setVersionHistoryList] = useState<
    ChapterVersion[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedChapterId, setSelectedChapterId] = useState(null);
  const [activeChapterId, setActiveChapterId] = useState(null);
  const {
    selectedRowKeys,
    onSelectRow,
    onSelectAllRows,
    resetSelectedRowKeys,
  } = useRowSelection();
  const [modalProps, setModalProps] = useState<{
    title?: React.ReactNode;
    subtitle?: React.ReactNode;
    primaryButtonLabel?: React.ReactNode;
    secondaryButtonLabel?: React.ReactNode;
    onSecondaryButtonClick?: Function;
  }>(null);
  const [actionType, setActionType] = useState<ModalActionType>(null);
  const [
    shouldRefetchVersionHistory,
    setShouldRefetchVersionHistory,
  ] = useState(false);
  const [states, setStates] = useState([]);

  const {
    isModalOpen: isDetailsAndLinksModalOpen,
    onOpenModal,
    onCloseModal,
  } = useModalVisibility();

  const getVersionHistoryList = async () => {
    try {
      setIsLoading(true);

      const { data } = await fetchChapterVersions({
        chapterType,
        chapterId,
      });

      setActiveChapterId(
        data.find(chapterVersion => chapterVersion?.version?.activeVersion)
          .chapterId
      );
      setVersionHistoryList(data);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (chapterType && chapterId) {
      getVersionHistoryList();
    }
  }, [chapterType, chapterId]);

  useEffect(() => {
    if (shouldRefetchVersionHistory && chapterType && chapterId) {
      getVersionHistoryList();
    }
  }, [shouldRefetchVersionHistory]);

  const handleOpenModal = ({
    chapterId,
    actionType,
  }: {
    chapterId: GeneralInfoData["id"];
    actionType?: ModalActionType;
  }) => () => {
    setSelectedChapterId(chapterId);
    setModalProps({
      secondaryButtonLabel: formatMessage(generalMessages.cancel),
      onSecondaryButtonClick: handleCloseModal,
    });
    setActionType(actionType);

    const actionTypeData = ActionTypeMapping[actionType];

    setStates(actionTypeData.states);
    setModalProps(prevState => ({
      ...prevState,
      title: formatMessage(actionTypeData.title),
      subtitle: formatMessage(actionTypeData.subtitle),
      primaryButtonLabel: formatMessage(actionTypeData.primaryButtonLabel),
    }));

    onOpenModal();
  };

  const handleCloseModal = () => {
    resetSelectedRowKeys();
    onCloseModal();
  };

  const rowSelection = {
    selectedRowKeys,
    onSelect: onSelectRow,
    onSelectAll: onSelectAllRows,
  };

  return {
    versionHistoryList,
    isLoading,
    isDetailsAndLinksModalOpen,
    modalProps,
    handleOpenModal,
    handleCloseModal,
    rowSelection,
    actionType,
    selectedChapterId,
    activeChapterId,
    shouldRefetchVersionHistory,
    setShouldRefetchVersionHistory,
    states,
  };
};

export default useVersionHistory;
