import { createContext, useState } from "react";
import { Row } from "antd";
import { Heading } from "@trace-one/design-system";
import { FormattedMessage } from "react-intl";
import { isObjectEmpty } from "utils/general";
import UserGuideModal from "components/UserGuideModal";
import Chapter from "./components/Chapter";
import {
  getOnCancelSectionComment,
  getChangeVisibilityForSection,
  getOnSaveSectionComment,
  getChangeVisibilityForChapter,
} from "./utils";
import { messages } from "./messages";
import {
  ChaptersContextProps,
  ChaptersProps,
  CommentModal as CommentModalType,
} from "./types";
import styles from "./styles.module.less";

export const ChaptersContext = createContext<ChaptersContextProps>({
  displayActions: true,
  errors: {},
  getChangeVisibilityForChapter: () => () => {},
  getChangeVisibilityForSection: () => () => {},
  onMoveDownSection: () => () => {},
  onMoveUpSection: () => () => {},
  setCommentModal: () => {},
  onSetAllChapterSectionsVisibility: () => {},
  areAllChapterSectionsChecked: false,
  areChapterSectionsIndeterminate: false,
});

const Chapters: React.FC<ChaptersProps> = ({
  chapters,
  page,
  onHideSection, // to be removed when template creation is removed from spec and folder wizards
  onUnhideSection, // to be removed when template creation is removed from spec and folder wizards
  displayCounter = true,
  displayActions = true,
  errors = {},
  onHideChapter = () => {},
  onUnhideChapter = () => {},
  onHideChapterSection = () => {},
  onUnhideChapterSection = () => {},
  onUpdateSectionComment = () => {},
  onSetAllChapterSectionsVisibility,
  onMoveDownSection = () => () => {},
  onMoveUpSection = () => () => {},
  onReorderSections,
  numberOfProducts = 0,
  numberOfSuppliers = 0,
  areAllChapterSectionsChecked,
  areChapterSectionsIndeterminate,
}) => {
  const [commentModal, setCommentModal] = useState<CommentModalType>({});

  const onCancel = getOnCancelSectionComment({ setCommentModal });

  return (
    <>
      {displayCounter && (
        <div className={styles.productCount}>
          <Heading size="xs">
            <FormattedMessage
              {...messages.selectedCounter}
              values={{
                productCount: numberOfProducts,
                supplierCount: numberOfSuppliers,
                b: (...chunks) => (
                  <span className={styles.greenBold}>{chunks}</span>
                ),
              }}
            />
          </Heading>
        </div>
      )}
      <ChaptersContext.Provider
        value={{
          displayActions,
          page,
          errors,
          getChangeVisibilityForSection: getChangeVisibilityForSection({
            onHideSection: onHideSection || onHideChapterSection,
            onUnhideSection: onUnhideSection || onUnhideChapterSection,
          }),
          getChangeVisibilityForChapter: getChangeVisibilityForChapter({
            onHideChapter,
            onUnhideChapter,
          }),
          onMoveDownSection,
          onMoveUpSection,
          onReorderSections,
          setCommentModal,
          onSetAllChapterSectionsVisibility,
          areAllChapterSectionsChecked,
          areChapterSectionsIndeterminate,
        }}
      >
        <Row className={styles.marginBottom} gutter={[16, 25]}>
          {chapters.map(chapter => {
            return <Chapter key={`chapter-${chapter.id}`} chapter={chapter} />;
          })}
        </Row>
      </ChaptersContext.Provider>
      {!isObjectEmpty(commentModal) && (
        <UserGuideModal
          userGuide={commentModal.initialComment}
          onSave={getOnSaveSectionComment({
            onUpdateSectionComment,
            sectionId: commentModal.sectionId,
            chapterType: commentModal?.chapterType,
            onCancel,
          })}
          onCancel={onCancel}
          displayActions={displayActions}
        />
      )}
    </>
  );
};

export default Chapters;
