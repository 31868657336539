import { Title, InsightLinkWidget } from "@trace-one/react-components";
import { Button, Heading, Paragraph } from "@trace-one/design-system";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import {
  selectLanguageCode,
  selectOwningCompanyId,
  selectUserApplication,
} from "store/user/selectors";
import { useUserInfo } from "hooks";
import { selectCompanyCountry } from "store/oidc/selectors";
import { APPLICATION_TYPE_IDS } from "utils/constants";
import { isObjectEmpty } from "utils/general";
import Image from "components/Image";
import { messages } from "./messages";
import styles from "./styles.module.less";

const Home = () => {
  const intl = useIntl();
  const accessToken = useSelector(selectLanguageCode);
  const ownerId = useSelector(selectOwningCompanyId);
  const companyLanguageCode = useSelector(selectCompanyCountry);
  const languageCode = useSelector(selectLanguageCode);
  const insightApp = useSelector(
    selectUserApplication(APPLICATION_TYPE_IDS.NEW_INSIGHT)
  );
  const { isRetailer } = useUserInfo();

  return (
    <>
      <Heading
        size="l"
        className={styles.homeTitle}
        data-test-id={languageCode}
      >
        {intl.formatMessage(messages.homeTitle)}
      </Heading>
      <Title
        level={2}
        value={intl.formatMessage(messages.sectionTitle)}
        subtitle={intl.formatMessage(messages.sectionSubtitle)}
      />
      <div className={styles.homeContent} data-testid="home-content">
        <div>
          <Paragraph>
            <FormattedMessage {...messages.firstParagraph} />
          </Paragraph>
          <Paragraph>
            <FormattedMessage {...messages.secondParagraph} />
          </Paragraph>
          <Paragraph>
            <FormattedMessage {...messages.thirdParagraph} />
          </Paragraph>
          <Button data-testid="coming-soon-btn" disabled type="secondary">
            <FormattedMessage {...messages.comingSoonBtn} />
          </Button>
        </div>
        <div>
          {isRetailer && !isObjectEmpty(insightApp) && (
            <InsightLinkWidget
              insightStsUrl={insightApp.stsEndpointUrl}
              insightGetUrl={`${insightApp.getEndpointUrl}/home`}
              companyLanguageCode={companyLanguageCode}
              accessToken={accessToken}
              ownerId={ownerId}
              appName="spec"
            />
          )}
        </div>
      </div>
      <div data-testid="home-footer-img" className={styles.image}>
        <Image name="HomeFooter" />
      </div>
    </>
  );
};

export default Home;
