import { useState } from "react";
import { Row, Col } from "antd";
import classNames from "classnames";
import {
  Paragraph,
  Select,
  Typography,
  EmptyState,
  InputNumber,
} from "@trace-one/design-system";
import NutriScoreCard from "components/NutriScoreCard";
import ConditionalWrapper from "components/ConditionalWrapper";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import useNutriscoreWidget from "./useNutriscoreWidget";
import { NutriscoreFormData } from "models";
import { debounce } from "lodash";
import { getInputNumberProps } from "../utils";
import { getPropertiesObjectFromArray } from "../../templates/utils";
import { isListEmpty, isObjectEmpty } from "utils/general";
import { NUTRISCORE_CALCULATION_STATE } from "utils/constants";
import {
  isNutriScoreCalculationStateError,
  isNutriScoreCalculationStateMandatoryFieldsMissing,
} from "utils/nutrition";
import { ObjectFieldTemplateProps } from "components/SpecificationSection/types";
import styles from "./styles.module.less";
const nutriscorePropertiesName = {
  displayOnPack: "displayOnPack",
  productType: "productType",
  presenceOfNonNutritiveSweeteners: "presenceOfNonNutritiveSweeteners",
  percentageOfFruitsVegetablesAndLegumes:
    "percentageOfFruitsVegetablesAndLegumes",
  nutriscoreCalculationMissingReason: "nutriscoreCalculationMissingReason",
};

const NutriscoreWidget: React.FC<
  ObjectFieldTemplateProps<NutriscoreFormData>
> = ({ schema, properties, formData, uiSchema, formContext }) => {
  const {
    isWidgetEditable,
    extraActions: { onUpdateNutriscore },
    extraErrors,
  } = formContext;
  const {
    productType,
    percentageOfFruitsVegetablesAndLegumes,
    nutriscore,
    nutriscoreCalculationMissingReason = "",
    servingId,
  } = formData;

  const { validationRequired } = schema;

  const [fruitsVegetableSchemaError, setFruitsVegetableSchemaError] = useState(
    undefined
  );
  const { getTranslationForKey } = useFormSchemaTranslation();
  const {
    productTypeOptions,
    hasError: hasCatalogApiError,
  } = useNutriscoreWidget();

  const propertyObjects = getPropertiesObjectFromArray(properties);
  const {
    min: minPercentage,
    max: maxPercentage,
    precision,
  } = getInputNumberProps(
    schema?.properties?.percentageOfFruitsVegetablesAndLegumes
  );

  const displayEmptyState =
    isObjectEmpty(nutriscore) ||
    !nutriscore.grade ||
    !!nutriscoreCalculationMissingReason;

  const areNonNutritiveSweetenersPresent =
    nutriscore?.presenceOfNonNutritiveSweeteners;

  const nutriscoreCalculationStateMessageKeyMap = {
    [NUTRISCORE_CALCULATION_STATE.ERROR.toUpperCase()]: "nutriScoreCalculationCatalogApiError",
    [NUTRISCORE_CALCULATION_STATE.MANDATORY_FIELDS_MISSING.toUpperCase()]: "nutriscoreEmptyMessage",
    [NUTRISCORE_CALCULATION_STATE.PRODUCTION_CHAPTER_NOT_LINKED.toUpperCase()]: "nutriscoreEmptyMessage",
  };

  const hasProductTypeError =
    !isListEmpty(
      extraErrors?.[nutriscorePropertiesName.productType]?.__errors
    ) || hasCatalogApiError;
  const productTypeErrorMessage =
    extraErrors?.[nutriscorePropertiesName.productType]?.__errors[0] ||
    getTranslationForKey("nutriscoreCatalogApiError");

  const hasPercentageOfFruitsVegetablesAndLegumesError =
    !isListEmpty(
      extraErrors?.[
        nutriscorePropertiesName.percentageOfFruitsVegetablesAndLegumes
      ]?.__errors
    ) || fruitsVegetableSchemaError;
  const percentageOfFruitsVegetablesAndLegumesErrorMsg =
    fruitsVegetableSchemaError ||
    extraErrors?.[
      nutriscorePropertiesName.percentageOfFruitsVegetablesAndLegumes
    ]?.__errors[0];

  return (
    <>
      {{
        ...propertyObjects[nutriscorePropertiesName.displayOnPack]?.content,
        props: {
          ...propertyObjects[nutriscorePropertiesName.displayOnPack]?.content
            ?.props,
          onChange: (value: boolean) => {
            onUpdateNutriscore({
              ...formData,
              [nutriscorePropertiesName.displayOnPack]: value,
            });
          },
        },
      }}

      <Row className="mb-2" align="middle">
        <Col md={10}>
          <Row>
            <Typography variant="heading-xxs" color="grey-5">
              {uiSchema?.[nutriscorePropertiesName.productType]?.["ui:title"]}
            </Typography>

            {validationRequired?.includes(
              nutriscorePropertiesName.productType
            ) && (
              <Typography variant="body-xxs" color="red">
                *
              </Typography>
            )}
          </Row>
        </Col>
        <Col md={10}>
          <Select
            allowClear={false}
            value={productType}
            data-test-id="nutri-score-product-type-select"
            options={productTypeOptions}
            placeholder={getTranslationForKey("refListPlaceholder")}
            onChange={(value: string) => {
              onUpdateNutriscore({
                ...formData,
                [nutriscorePropertiesName.productType]: value,
              });
            }}
            disabled={
              !isWidgetEditable(schema?.properties?.productType?.propertyId) ||
              hasCatalogApiError
            }
            error={hasProductTypeError}
            errorMessage={productTypeErrorMessage}
          />
        </Col>
      </Row>
      {(areNonNutritiveSweetenersPresent === true ||
        areNonNutritiveSweetenersPresent === false) && (
        <Row className="mb-2" align="middle">
          <Col md={10}>
            <Row>
              <Typography variant="heading-xxs" color="grey-5">
                {
                  uiSchema?.["nutriscore"]?.[
                    nutriscorePropertiesName.presenceOfNonNutritiveSweeteners
                  ]?.["ui:title"]
                }
              </Typography>
              {validationRequired?.includes(
                nutriscorePropertiesName.presenceOfNonNutritiveSweeteners
              ) && (
                <Typography variant="body-xxs" color="red">
                  *
                </Typography>
              )}
            </Row>
          </Col>
          <Col>
            <Paragraph>
              {getTranslationForKey(
                areNonNutritiveSweetenersPresent ? "yes" : "no"
              )}
            </Paragraph>
          </Col>
        </Row>
      )}

      <Row className="mb-2" align="middle">
        <Col md={10}>
          <Row>
            <Typography variant="heading-xxs" color="grey-5">
              {
                uiSchema?.[
                  nutriscorePropertiesName
                    .percentageOfFruitsVegetablesAndLegumes
                ]?.["ui:title"]
              }
            </Typography>
            {validationRequired?.includes(
              nutriscorePropertiesName.percentageOfFruitsVegetablesAndLegumes
            ) && (
              <Typography variant="body-xxs" color="red">
                *
              </Typography>
            )}
          </Row>
        </Col>
        <Col md={10}>
          <InputNumber
            disabled={
              !isWidgetEditable(
                schema?.properties?.percentageOfFruitsVegetablesAndLegumes
                  ?.propertyId
              )
            }
            defaultValue={percentageOfFruitsVegetablesAndLegumes}
            onChange={debounce((value: number) => {
              setFruitsVegetableSchemaError(undefined);
              if (value < minPercentage) {
                setFruitsVegetableSchemaError(getTranslationForKey("minimum"));
                return;
              }
              if (value > maxPercentage) {
                setFruitsVegetableSchemaError(getTranslationForKey("maximum"));
                return;
              }

              onUpdateNutriscore({
                ...formData,
                [nutriscorePropertiesName.percentageOfFruitsVegetablesAndLegumes]: value,
              });
            }, 300)}
            type="number"
            precision={precision}
            error={!!hasPercentageOfFruitsVegetablesAndLegumesError}
            errorMessage={percentageOfFruitsVegetablesAndLegumesErrorMsg}
          />
        </Col>
      </Row>

      <Row
        justify={displayEmptyState ? "center" : "start"}
        className={classNames(styles["nutri-score"], {
          [styles["nutri-score-empty"]]: displayEmptyState,
        })}
      >
        {displayEmptyState ? (
          <EmptyState
            description={
              <ConditionalWrapper
                condition={
                  isNutriScoreCalculationStateError(
                    nutriscoreCalculationMissingReason
                  ) ||
                  isNutriScoreCalculationStateMandatoryFieldsMissing(
                    nutriscoreCalculationMissingReason
                  )
                }
                wrapper={children => (
                  <span className="color-red">{children}</span>
                )}
              >
                {getTranslationForKey(
                  nutriscoreCalculationStateMessageKeyMap[
                    nutriscoreCalculationMissingReason.toUpperCase()
                  ] || "nutriscoreEmptyMessage"
                )}
              </ConditionalWrapper>
            }
          />
        ) : (
          <NutriScoreCard
            nutriScore={nutriscore}
            uiSchema={uiSchema?.nutriscore}
            servingId={servingId}
          />
        )}
      </Row>
    </>
  );
};

export default NutriscoreWidget;
