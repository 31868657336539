import { useState } from "react";

const useInputValidation = (onChange: Function) => {
  const [hasError, setHasError] = useState(false);

  const onInputChange = (value: React.ReactNode) => {
    if (!value) {
      setHasError(true);
    } else {
      onChange?.(value);
      setHasError(false);
    }
  };

  return {
    hasError,
    onInputChange,
  };
};

export default useInputValidation;
