import { useState } from "react";
import { useIntl } from "react-intl";
import { OriginField, OriginProps } from "components/Origin/types";
import messages from "./messages";

const useOrigin = ({ data, onChange }: OriginProps) => {
  const { formatMessage } = useIntl();

  const { originTypeId, countryId, regionId, zoneId } = data;

  const getName = (item: string): string => data?.[item]?.name || item;

  const countryName = getName("countryId");
  const regionName = getName("regionId");
  const zoneName = getName("zoneId");

  const [originData, setOriginData] = useState({
    [countryName]: countryId.value,
    [regionName]: regionId.value,
    [zoneName]: zoneId.value,
    ...(originTypeId && {
      [originTypeId.name]: originTypeId.value,
    }),
  });

  const relativeChildrenKeys = {
    typeId: [],
    [countryName]: [regionName, zoneName],
    [regionName]: [zoneName],
    [zoneName]: [],
  };

  const updateChildrenValue = (key, form) => {
    const updatedForm = { ...form };

    relativeChildrenKeys[key].forEach(key => {
      updatedForm[key] = null;
    });

    return updatedForm;
  };

  const handleChange = ({ name }: { name: OriginField["name"] }) => (
    value: string
  ) => {
    const updatedFormWithChildren = updateChildrenValue(name, originData);

    const dataToSend = {
      ...updatedFormWithChildren,
      [name]: value,
    };

    setOriginData(dataToSend);

    onChange(dataToSend, name);
  };

  const getMessage = (item: string, type: string): string =>
    data?.[item]?.[type] || formatMessage(messages[item][type]);

  return {
    originTypeId,
    countryId,
    regionId,
    zoneId,
    originData,
    handleChange,
    getMessage,
    getName,
  };
};

export default useOrigin;
