import { defineMessages } from "react-intl";

export const messages = defineMessages({
  menuTitle: {
    id: "section.menu.title",
    defaultMessage: "Navigation",
  },
  otherActions: {
    id: "section.menu.otherActions",
    defaultMessage: "Other actions",
  },
});
