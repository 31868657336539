import { useEffect, useState } from "react";
import { fetchSpecificationFiles } from "apis/SPEC";
import { SpecificationData, SpecificationFileData } from "models";

const useSpecificationFiles = ({
  specificationId,
  shouldFetchSpecificationFilesInitially = true,
}: {
  specificationId: SpecificationData["id"];
  shouldFetchSpecificationFilesInitially?: boolean;
}) => {
  const [
    specificationFileData,
    setSpecificationFileData,
  ] = useState<SpecificationFileData>({});

  const fetchSpecificationFileData = async () => {
    if (specificationId) {
      try {
        const { data } = await fetchSpecificationFiles({
          specificationId,
        });

        setSpecificationFileData(data);
      } catch (e) {
        setSpecificationFileData({});
      }
    }
  };

  useEffect(() => {
    if (shouldFetchSpecificationFilesInitially) {
      fetchSpecificationFileData();
    }
  }, [shouldFetchSpecificationFilesInitially]);

  return {
    certificateId: specificationFileData?.digitalSignatureCertificateId,
    signedPdfId: specificationFileData?.signedPdfId,
    fetchSpecificationFileData,
  };
};

export default useSpecificationFiles;
