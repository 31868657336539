import classnames from "classnames";
import { Heading } from "@trace-one/design-system";
import { StepTitleProps } from "./types";
import styles from "./styles.module.less";

const StepTitle: React.FC<StepTitleProps> = ({
  step,
  subtitle,
  title,
  titleClassName = "",
  subtitleClassName = "",
}) => {
  return (
    <>
      <Heading
        className={classnames(styles.title, titleClassName)}
        size="s"
      >{`${step ? `${step}. ` : ""}${title}`}</Heading>

      {subtitle ? (
        <Heading
          className={classnames(styles.subtitle, subtitleClassName)}
          size="xxs"
        >
          {subtitle}
        </Heading>
      ) : null}
    </>
  );
};

export default StepTitle;
