import { useMemo } from "react";
import { useIntl } from "react-intl";
import { utils } from "@rjsf/core";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import {
  NutrientItemDictionary,
  NutrientItem,
} from "components/SpecificationSection/components/FormSchema/components/NutritionBasic/types";
import NutritionServingValue from "./components/NutritionServingValue";
import { useReferenceListsItems } from "hooks";
import { UseServingColumnsProps } from "./types";
import { getServingValue } from "./utils";
import {
  getServingGroupingColor,
  getServingGroupingColumns,
  getServingGroupingName,
} from "utils/nutrition";
import { messages } from "components/SpecificationSection/components/FormSchema/widgets/NutritionDeclarationWidget/messages";

const { resolveSchema } = utils;

const useServingColumns = ({
  uiSchema,
  schema,
  nutritionServings,
  nutrientDictionary,
  isNotRootNutrient,
  extraActions,
  formContext,
  getColumnErrors,
  onRemoveServing,
  onEditServing,
  hasUpdatePermission,
}: UseServingColumnsProps) => {
  const { getTranslationForKey } = useFormSchemaTranslation();
  const { formatMessage } = useIntl();

  const servingIds = useMemo(
    () =>
      nutritionServings?.reduce((acc, serving) => {
        if (serving?.size?.unit) {
          acc.push(serving?.size?.unit);
        }

        return acc;
      }, []),
    [nutritionServings]
  );

  useReferenceListsItems({
    refListIds: [...servingIds],
  });

  const roundedDailyIntakeSchema = resolveSchema(
    // @ts-ignore-next
    schema.properties?.nutrientFamilies?.items?.properties?.nutrients?.items
      ?.properties?.servings?.items?.properties?.roundedDailyIntake,
    schema
  );
  const unroundedDailyIntakeSchema = resolveSchema(
    // @ts-ignore-next
    schema.properties?.nutrientFamilies?.items?.properties?.nutrients?.items
      ?.properties?.servings?.items?.properties?.unroundedDailyIntake,
    schema
  );

  const roundedValueSchema = resolveSchema(
    // @ts-ignore-next
    schema.properties?.nutrientFamilies?.items?.properties?.nutrients?.items
      ?.properties?.servings?.items?.properties?.roundedValue,
    schema
  );

  const unroundedValueSchema = resolveSchema(
    // @ts-ignore-next
    schema.properties?.nutrientFamilies?.items?.properties?.nutrients?.items
      ?.properties?.servings?.items?.properties?.unroundedValue,
    schema
  );

  const getServingColumn = (
    key: string,
    servingId?: string,
    isDefaultServing?: boolean,
    automaticCalculation?: boolean
  ) => {
    if (isDefaultServing || automaticCalculation) {
      if (key === "unroundedDailyIntake" || key === "roundedDailyIntake") {
        return {
          dataIndex: ["servings", key],
          title: getTranslationForKey(
            uiSchema?.nutrientFamilies?.items?.nutrients?.items?.servings
              ?.items?.[key]?.["ui:title"]
          ),
          render: (_, nutrient: NutrientItem) => {
            const servingIndex = nutrient.servings?.findIndex(
              serving => serving.servingId === servingId
            );

            if (
              (nutrientDictionary[
                nutrient.nutrientId
              ] as NutrientItemDictionary)?.dailyIntake === null &&
              nutrient.unroundedValue &&
              isNotRootNutrient(nutrient)
            ) {
              return (
                <NutritionServingValue
                  fieldKey={key}
                  formContext={formContext}
                  extraActions={extraActions}
                  roundedDailyIntakeSchema={roundedDailyIntakeSchema}
                  unroundedDailyIntakeSchema={unroundedDailyIntakeSchema}
                  roundedValueSchema={roundedValueSchema}
                  unroundedValueSchema={unroundedValueSchema}
                  nutrient={nutrient}
                  servingId={servingId}
                  errors={getColumnErrors(
                    `${nutrient.key}.servings[${servingIndex}].${key}`
                  )}
                  automaticCalculation={automaticCalculation}
                  hasUpdatePermission={hasUpdatePermission}
                />
              );
            } else {
              return getServingValue({
                servings: nutrient.servings,
                servingId,
                key,
              });
            }
          },
        };
      }

      return {
        dataIndex: ["servings", key],
        title: getTranslationForKey(
          uiSchema?.nutrientFamilies?.items?.nutrients?.items?.servings
            ?.items?.[key]?.["ui:title"]
        ),
        render: (_, nutrient: NutrientItem) => {
          return getServingValue({
            servings: nutrient.servings,
            servingId,
            key,
          });
        },
      };
    } else {
      return {
        dataIndex: ["servings", key],
        title: getTranslationForKey(
          uiSchema?.nutrientFamilies?.items?.nutrients?.items?.servings
            ?.items?.[key]?.["ui:title"]
        ),
        render: (_, nutrient: NutrientItem) => {
          const servingIndex = nutrient.servings?.findIndex(
            serving => serving.servingId === servingId
          );

          if (nutrient.unroundedValue) {
            return (
              <NutritionServingValue
                fieldKey={key}
                formContext={formContext}
                extraActions={extraActions}
                roundedDailyIntakeSchema={roundedDailyIntakeSchema}
                unroundedDailyIntakeSchema={unroundedDailyIntakeSchema}
                roundedValueSchema={roundedValueSchema}
                unroundedValueSchema={unroundedValueSchema}
                nutrient={nutrient}
                servingId={servingId}
                errors={getColumnErrors(
                  `${nutrient.key}.servings[${servingIndex}].${key}`
                )}
                automaticCalculation={automaticCalculation}
                hasUpdatePermission={hasUpdatePermission}
              />
            );
          } else {
            return null;
          }
        },
      };
    }
  };

  return nutritionServings.map(nutrition => {
    const {
      name,
      servingId,
      default: isDefaultServing,
      automaticCalculation,
      size,
    } = nutrition;
    const shouldShowActions = !isDefaultServing && hasUpdatePermission;

    return {
      title: getServingGroupingName({
        isDefaultServing,
        name,
        size,
        nutrientDictionary,
        getTranslationForKey,
      }),
      children: getServingGroupingColumns(isDefaultServing).map(columnName =>
        getServingColumn(
          columnName,
          servingId,
          isDefaultServing,
          automaticCalculation
        )
      ),
      color: getServingGroupingColor(isDefaultServing),
      ...(shouldShowActions
        ? {
            actions: [
              {
                key: `edit_serving_${servingId}`,
                label: formatMessage(messages.editServing),
                onClick: () => onEditServing(nutrition),
              },
              {
                key: `remove_serving_${servingId}`,
                label: formatMessage(messages.removeServing),
                onClick: () => onRemoveServing(servingId),
              },
            ],
          }
        : {}),
    };
  });
};

export default useServingColumns;
