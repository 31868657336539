import { useState } from "react";
import { useSelector } from "react-redux";
import { selectLanguageCode } from "store/user/selectors";
import { fetchUnitsOfMeasureForNutrient } from "apis/CATALOGUE";
import { NutrientItem } from "components/SpecificationSection/components/FormSchema/components/NutritionBasic/types";
import { extractEditableFieldsForNutrition } from "components/SpecificationSection/components/FormSchema/components/NutritionBasic/utils";
import { LabeledValue } from "antd/lib/select";
import { isListEmpty } from "utils/general";
import { EnhancedExtraActions } from "components/SpecificationSection/types";

const useUnitOfMeasure = ({
  nutrientData,
  updateNutritionAction,
}: {
  nutrientData: NutrientItem;
  updateNutritionAction: EnhancedExtraActions["updateNutritionAction"];
}) => {
  const languageCode = useSelector(selectLanguageCode);

  const [unitOptions, setUnitOptions] = useState<LabeledValue[]>([]);
  const [value, setValue] = useState<LabeledValue>();
  const [areOptionsLoading, setAreOptionsLoading] = useState(false);

  const handleVisibleDropdownChange = async (open: boolean) => {
    if (open && isListEmpty(unitOptions)) {
      setAreOptionsLoading(true);
      try {
        const { data } = await fetchUnitsOfMeasureForNutrient({
          nutrientId: nutrientData.nutrientId,
          languageCode,
        });
        setUnitOptions(
          data.map(({ id, name }) => {
            return { value: id, label: name };
          })
        );
      } catch (_) {
      } finally {
        setAreOptionsLoading(false);
      }
    }
  };

  const handleSelect = (valueItem: LabeledValue) => {
    setValue(valueItem);

    updateNutritionAction({
      nutrientId: nutrientData.nutrientId,
      data: {
        ...extractEditableFieldsForNutrition(nutrientData),
        unitId: valueItem.value,
      },
    });
  };

  return {
    handleVisibleDropdownChange,
    handleSelect,
    unitOptions,
    value,
    areOptionsLoading,
  };
};

export default useUnitOfMeasure;
