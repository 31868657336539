import { combineReducers, StateFromReducersMapObject } from "@reduxjs/toolkit";
import user from "./user/userSlice";
import oidc from "./oidc/oidcSlice";
import generalData from "./generalData/generalDataSlice";
import folderCreation from "./folderCreation/folderCreationSlice";
import specificationDetails from "./specificationDetails/specificationDetailsSlice";
import specificationCreation from "./specificationCreation/specificationCreationSlice";
import materialForm from "./materialForm/materialFormSlice";
import packaging from "./packaging/packagingSlice";
import chapterForm from "./chapterForm/chapterFormSlice";
import packagingSystemForm from "./packagingSystemForm/packagingSystemFormSlice";
import requirementForm from "./requirementForm/requirementFormSlice";
import templateForm from "./templates/templateFormSlice";
import claimForm from "./claimForm/claimFormSlice";
import { tagsRtkBaseApi } from "apis/tags-rtk";
import { specRtkBaseApi } from "apis/SPEC/specRtkApi";
import collaboration from "./collaboration/collaborationSlice";

const rootReducer = {
  user,
  oidc,
  generalData,
  folderCreation,
  specificationCreation,
  specificationDetails,
  materialForm,
  packaging,
  chapterForm,
  packagingSystemForm,
  requirementForm,
  templateForm,
  claimForm,
  [specRtkBaseApi.reducerPath]: specRtkBaseApi.reducer,
  [tagsRtkBaseApi.reducerPath]: tagsRtkBaseApi.reducer,
  collaboration,
};

export type RootState = StateFromReducersMapObject<typeof rootReducer>;

export default combineReducers(rootReducer);
