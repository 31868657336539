import { useState, useEffect } from "react";
import { v4 } from "uuid";
import { isListEmpty, isObjectEmpty, getDictionary } from "utils/general";
import { fetchCollaborationsByIds } from "apis/CLB";
import { fetchUsers } from "apis/CUMD";
import { UseCollaborationsProps } from "./types";
import { CollaborationData } from "../../types";

const useExistingCollaborations = ({
  existingCollaborationIds,
  refListDictionary,
}: UseCollaborationsProps) => {
  const [existingCollaborations, setExistingCollaborations] = useState<
    CollaborationData[]
  >([]);
  const [
    isLoadingExistingCollaborations,
    setIsLoadingExistingCollaborations,
  ] = useState(!isListEmpty(existingCollaborationIds));
  const [
    supplierIdsWithExistingCollaborations,
    setSupplierIdsWithExistingCollaborations,
  ] = useState<string[]>([]);

  const getExistingCollaborations = async () => {
    try {
      const { data } = await fetchCollaborationsByIds({
        ids: existingCollaborationIds,
      });

      const userIdsWithExistingCollaborations = data.reduce(
        (acc, { collaboratingUserId }) => {
          if (collaboratingUserId) {
            return [...acc, collaboratingUserId];
          }
          return acc;
        },
        []
      );

      let usersDictionary = {};

      if (!isListEmpty(userIdsWithExistingCollaborations)) {
        const { data: users } = await fetchUsers({
          ids: userIdsWithExistingCollaborations,
        });

        usersDictionary = getDictionary({
          data: users,
          key: "userId",
          value: "userName",
        });
      }

      const {
        existingCollaborationsData,
        collaboratingCompanyIds,
      } = data.reduce(
        (acc, collaboration) => {
          return {
            existingCollaborationsData: [
              ...acc.existingCollaborationsData,
              {
                id: collaboration.collaborationId,
                key: v4(),
                status: collaboration.status,
                collaboratingCompanyId: collaboration.collaboratingCompanyId,
                responsibilityId: collaboration.responsibilityIds[0],
                responsibilityName:
                  refListDictionary[collaboration.responsibilityIds[0]],
                userEmail: collaboration.collaboratingUserEmail,
                userId: collaboration.collaboratingUserId,
                userName: usersDictionary?.[collaboration.collaboratingUserId],
              },
            ],
            collaboratingCompanyIds: [
              ...acc.collaboratingCompanyIds,
              collaboration.collaboratingCompanyId,
            ],
          };
        },
        { existingCollaborationsData: [], collaboratingCompanyIds: [] }
      );

      setExistingCollaborations(existingCollaborationsData);

      setSupplierIdsWithExistingCollaborations(collaboratingCompanyIds);

      setIsLoadingExistingCollaborations(false);
    } catch (_) {}
  };

  useEffect(() => {
    if (
      !isListEmpty(existingCollaborationIds) &&
      !isObjectEmpty(refListDictionary)
    ) {
      getExistingCollaborations();
    } else {
      setExistingCollaborations([]);
      setSupplierIdsWithExistingCollaborations([]);
    }
  }, [refListDictionary, existingCollaborationIds]);

  return {
    existingCollaborations,
    isLoadingExistingCollaborations,
    supplierIdsWithExistingCollaborations,
  };
};

export default useExistingCollaborations;
