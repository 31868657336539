import { isObjectEmpty } from "utils/general";
import { MenuActions } from "types/general";
import { isFAndVSpecTemplate } from "utils/specifications";

export const getActionsList = ({
  actions,
  onOpenSelectSupplierModal,
  onOpenHistoryModal,
  productTypeId,
}: {
  actions: { [key: string]: MenuActions };
  onOpenSelectSupplierModal: Function;
  onOpenHistoryModal: Function;
  productTypeId: string;
}) => {
  if (isObjectEmpty(actions)) return [];

  const isFandV = isFAndVSpecTemplate(productTypeId);

  let itemActions: MenuActions[] = [];

  if (isFandV && actions.history) {
    itemActions.push({
      label: actions.history.name,
      onClick: onOpenHistoryModal,
    });
  }

  if (isFandV && actions.duplicate) {
    itemActions.push({
      label: actions.duplicate.name,
      onClick: onOpenSelectSupplierModal,
    });
  }

  if (actions.revise) {
    itemActions.push({
      label: actions.revise.name,
      onClick: actions.revise.onClick,
      disabled: actions.revise.disabled,
    });
  }

  return itemActions;
};
