import { useEffect, useState } from "react";
import { Row, Col } from "antd";
import RowTitle from "components/RowTitle";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { getPropertiesObjectFromArray } from "../../templates/utils";
import { NatureWidgetProps } from "./types";
import NatureWidgetReadOnly from "./components/NatureWidgetReadOnly";

const NatureWidget: React.FC<NatureWidgetProps> = ({
  properties,
  formData,
  formContext,
  uiSchema,
}) => {
  const { acceptAlternativeVariety = false } = formData;
  const [showMultipleReflist, setShowMultipleReflist] = useState(
    acceptAlternativeVariety
  );
  const propertiesObject = getPropertiesObjectFromArray(properties);
  const { displayActions, refListDictionary, emptyValue } = formContext;

  const colSpans = {
    xs: displayActions ? 24 : 8,
    sm: 12,
    md: 8,
    lg: 8,
  };

  useEffect(() => {
    setShowMultipleReflist(acceptAlternativeVariety);
  }, [acceptAlternativeVariety]);

  const { getTranslationForKey } = useFormSchemaTranslation();

  const getContentForMultipleReflist = (name: string) => ({
    ...propertiesObject[name].content,
    props: {
      ...propertiesObject[name].content.props,
      schema: {
        ...propertiesObject[name].content.props.schema,
        items: {
          ...propertiesObject[name].content.props.schema.items,
          referenceList: {
            ...propertiesObject[name].content.props.schema.items.referenceList,
            parentItemIds: formData.specieId ? [formData.specieId] : undefined,
          },
        },
      },
    },
  });

  const varietyIdsContent = getContentForMultipleReflist("varietyIds");

  let alternativeVarietyIdsContent;
  if (showMultipleReflist) {
    alternativeVarietyIdsContent = getContentForMultipleReflist(
      "alternativeVarietyIds"
    );
  }

  const readOnlyProps = {
    propertiesObject,
    varietyIdsContent,
    alternativeVarietyIdsContent,
    refListDictionary,
    emptyValue,
  };

  return (
    <Row gutter={[10, 0]}>
      <Col span={24}>
        <RowTitle
          title={getTranslationForKey(uiSchema["ui:title"])}
          displayActions={displayActions}
        />
      </Col>

      {displayActions ? (
        <>
          <Col {...colSpans}>{propertiesObject.specieId.content}</Col>
          <Col {...colSpans} md={16} lg={16}>
            {getContentForMultipleReflist("varietyIds")}
          </Col>
          <Col {...colSpans}>
            {propertiesObject?.["acceptAlternativeVariety"]?.content}
          </Col>
          {showMultipleReflist && (
            <Col xs={24}>
              {getContentForMultipleReflist("alternativeVarietyIds")}
            </Col>
          )}
        </>
      ) : (
        <NatureWidgetReadOnly {...readOnlyProps} />
      )}
    </Row>
  );
};

export default NatureWidget;
