import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDebounce } from "@trace-one/business-components";
import { useBoolean } from "hooks";
import { selectLanguageCode } from "store/user/selectors";
import { useAppDispatch } from "store";
import {
  fetchSubstanceFunction,
  fetchSubstanceFunctions,
} from "apis/CATALOGUE";
import {
  AREA_CATEGORY_TYPES,
  REFLIST_IDS,
  numberOfCharactersForAutoComplete,
} from "utils/constants";
import { SubstanceFunctionData } from "models";
import { isListEmpty } from "utils/general";
import { MaterialType } from "types/library";

interface UseSubstanceFunctionProps {
  substanceId: string;
  materialType: MaterialType;
  substanceFunctionId?: string;
  onUpdate: Function;
}

const useSubstanceFunction = ({
  substanceId,
  substanceFunctionId,
  materialType,
  onUpdate,
}: UseSubstanceFunctionProps) => {
  const [searchTerm, setSearchTerm] = useState<string>(null);
  const [substanceFunctions, setSubstanceFunctions] = useState<
    SubstanceFunctionData[]
  >(null);
  const [
    selectedSubstanceFunction,
    setSubstanceFunction,
  ] = useState<SubstanceFunctionData>(null);

  const languageCode = useSelector(selectLanguageCode);

  const clearedSubstanceFunctionIdRef = useRef(null);

  const debouncedSearchTerm = useDebounce(searchTerm, 1000);

  const dispatch = useAppDispatch();
  const { value: isLoading, setTrue, setFalse } = useBoolean(false);

  const getSubstanceFunctions = async (searchTerm?: string) => {
    try {
      if (searchTerm === selectedSubstanceFunction?.id) {
        return;
      }

      setTrue();

      const { data } = await fetchSubstanceFunctions({
        substanceId,
        params: {
          languageCode,
          searchTerm,
          areaId: REFLIST_IDS.FOOD_PRODUCT_TYPE,
          areaCategory: AREA_CATEGORY_TYPES[materialType],
        },
      });

      setSubstanceFunctions(data);
    } catch (error) {
    } finally {
      setFalse();
    }
  };

  const getSubstanceFunction = async () => {
    try {
      const { data } = await fetchSubstanceFunction({
        substanceId,
        substanceFunctionId,
        params: { languageCode },
      });
      setSubstanceFunction(data);
    } catch (error) {}
  };

  useEffect(() => {
    if (
      clearedSubstanceFunctionIdRef.current !== substanceFunctionId &&
      substanceFunctions?.some(item => item?.id === substanceFunctionId)
    ) {
      setSubstanceFunction(
        substanceFunctionId
          ? substanceFunctions?.find(item => item?.id === substanceFunctionId)
          : null
      );
    } else if (
      isListEmpty(substanceFunctions) &&
      substanceId &&
      substanceFunctionId
    ) {
      getSubstanceFunction();
    }
  }, [substanceFunctions, substanceId, substanceFunctionId]);

  useEffect(() => {
    if (debouncedSearchTerm?.length === 0) {
      getSubstanceFunctions("");
    }
    if (debouncedSearchTerm?.length >= numberOfCharactersForAutoComplete) {
      getSubstanceFunctions(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm, languageCode]);

  const onSelect = async (selectedValue: string) => {
    try {
      setTrue();
      await dispatch(onUpdate?.(selectedValue));

      setSubstanceFunction(
        selectedValue
          ? substanceFunctions?.find(item => item?.id === selectedValue)
          : null
      );
    } catch {
    } finally {
      setFalse();
    }
  };

  const onDropdownVisibleChange = (open: boolean) => {
    if (open) {
      getSubstanceFunctions("");
    }
  };

  const onClear = async () => {
    try {
      setTrue();
      clearedSubstanceFunctionIdRef.current = selectedSubstanceFunction?.id;
      await dispatch(onUpdate?.(null));

      setSubstanceFunction(null);
    } catch {
    } finally {
      setFalse();
    }
  };

  const onSearch = (value: string) => {
    setSearchTerm(value);
  };

  const options = useMemo(() => {
    return substanceFunctions?.map(substance => ({
      label: substance?.name,
      name: substance?.name,
      value: substance?.id,
    }));
  }, [substanceFunctions]);

  return {
    isLoading,
    options,
    selectedSubstanceFunction,
    onSearch,
    onSelect,
    onClear,
    onDropdownVisibleChange,
  };
};

export default useSubstanceFunction;
