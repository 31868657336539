import cx from "classnames";
import { Spinner } from "@trace-one/design-system";
import { SpinProps } from "./types";
import styles from "./styles.module.less";

const Spin: React.FC<SpinProps> = ({ fixed, className, ...rest }) => {
  return (
    <Spinner
      data-testid="spinner"
      className={cx(styles.spinner, fixed && styles.fixed, className)}
      size="large"
      {...rest}
    />
  );
};

Spin.defaultProps = {
  fixed: false,
};

export default Spin;
