import { useEffect, useState } from "react";
import { isListEmpty } from "utils/general";
import { SpecificationQuickViewData } from "models";

const useQuickView = ({
  fetcher,
  shouldRefetch,
}: {
  fetcher: Function;
  shouldRefetch?: boolean;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [sectionNumber, setSectionNumber] = useState(0);
  const [specifications, setSpecifications] = useState<
    SpecificationQuickViewData[]
  >([]);

  const fetchQuickView = async () => {
    try {
      setIsLoading(true);
      setHasError(false);

      const { data: specifications } = await fetcher();

      setSectionNumber(
        !isListEmpty(specifications)
          ? specifications[0].progression.toFillSectionCount +
              specifications[0].progression.inProgressSectionCount +
              specifications[0].progression.validatedByRetailerSectionCount +
              specifications[0].progression.validatedBySupplierSectionCount
          : 0
      );
      setSpecifications(specifications);
    } catch (e) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchQuickView();
  }, []);

  useEffect(() => {
    if (shouldRefetch) {
      fetchQuickView();
    }
  }, [shouldRefetch]);

  return {
    isLoading,
    hasError,
    specifications,
    sectionNumber,
  };
};

export default useQuickView;
