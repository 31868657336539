import { useMemo } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Datatable } from "@trace-one/design-system";
import { selectCompanyActivity } from "store/oidc/selectors";
import { useLinkedSpecifications, usePagination, useRedirect } from "hooks";
import { getRowKeyForTable, shouldPaginationBeDisplay } from "utils/table";
import { getProductOrigin } from "pages/Specification/utils";
import { getColumns } from "./utils";
import { SpecificationListItemData } from "models";
import { LinkedSpecificationsProps } from "./types";
import styles from "./styles.module.less";

const LinkedSpecifications = ({
  chapterId,
  chapterType,
}: LinkedSpecificationsProps) => {
  const intl = useIntl();
  const productOrigin = getProductOrigin({
    companyActivity: useSelector(selectCompanyActivity),
  });
  const { pagination, sorter, onTableChange } = usePagination();
  const { redirectToSpecificationDetails } = useRedirect();
  const rowKey = useMemo(() => getRowKeyForTable("specification"), []);

  const { specifications, totalCount, isLoading } = useLinkedSpecifications({
    chapterType,
    chapterId,
    sorter,
    pagination,
  });

  const onRowClick = (row: SpecificationListItemData) => {
    redirectToSpecificationDetails(row.specificationId);
  };

  return (
    <Datatable
      loading={isLoading}
      rowClassName={styles.row}
      onChange={onTableChange}
      pagination={{
        ...pagination,
        total: totalCount,
        hideOnSinglePage: !shouldPaginationBeDisplay(totalCount),
      }}
      columns={getColumns({
        intl,
        productOrigin,
      })}
      dataSource={specifications}
      rowKey={rowKey}
      onRow={(record: SpecificationListItemData) => {
        return {
          onClick: () => onRowClick(record),
        };
      }}
    />
  );
};

export default LinkedSpecifications;
