import axios from "axios";
import { DiscussionData, MessageData } from "models/discussion";

export const axiosInstance = axios.create({
  baseURL: window.env.DISCUSS_URL,
});

export const fetchDiscussionById = ({
  discussionId,
}: {
  discussionId: DiscussionData["id"];
}) => axiosInstance.get<DiscussionData>(`/api/v2/discussions/${discussionId}`);

export const createDiscussionMessage = ({
  discussionId,
  payload,
}: {
  discussionId: DiscussionData["id"];
  payload?: {
    authorUserId: MessageData["authorUserId"];
    text: MessageData["text"];
  };
}) =>
  axiosInstance.post<DiscussionData>(
    `/api/v2/discussions/${discussionId}/messages`,
    payload
  );
