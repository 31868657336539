import { AjvError } from "@rjsf/core";
import { ReferenceListItemData, TradeItemData } from "models";
import { isListEmpty } from "./general";

export const createNameForProduct = (
  product: TradeItemData,
  unitsOfMeasure: ReferenceListItemData[] = []
): string => {
  const { gtin, itemName, brandName, netContents } = product;
  const netContentsTexts = formatNetContents({
    netContents,
    unitsOfMeasure,
  });

  return [gtin, itemName, brandName, netContentsTexts]
    .filter(Boolean)
    .join(" - ");
};

export const getUnitOfMeasureText = (
  unitOfMeasure: string,
  unitsOfMeasure: ReferenceListItemData[]
): string => {
  if (!unitsOfMeasure) return "";

  const index = unitsOfMeasure.findIndex(item => {
    return item.id === unitOfMeasure;
  });

  if (index > -1) {
    const { text } = unitsOfMeasure[index];
    return text;
  }

  return "";
};

export const formatNetContents = ({
  netContents,
  unitsOfMeasure,
  separator = " - ",
  emptyValue = "",
}: {
  netContents: TradeItemData["netContents"];
  unitsOfMeasure: ReferenceListItemData[];
  separator?: string;
  emptyValue?: string;
}): string => {
  let netContentsTexts = "";

  if (!isListEmpty(netContents)) {
    netContentsTexts = netContents
      .map(netContent => {
        const { value, unitOfMeasure } = netContent;
        const unitOfMeasureText =
          getUnitOfMeasureText(unitOfMeasure, unitsOfMeasure) || unitOfMeasure;

        return `${value} ${unitOfMeasureText}`;
      })
      .join(separator);
  } else {
    netContentsTexts += emptyValue;
  }

  return netContentsTexts;
};

export const getOnProductTypeTooltipVisibleChange = ({
  setIsProductTypeTooltipVisible,
  hasAssignedProducts,
}: {
  setIsProductTypeTooltipVisible: Function;
  hasAssignedProducts: boolean;
}) => (visible: boolean) => {
  if (!hasAssignedProducts) {
    setIsProductTypeTooltipVisible(false);
  } else {
    setIsProductTypeTooltipVisible(visible);
  }
};

export const getOnShareButtonClick = ({
  setIsShareModalOpen,
}: {
  setIsShareModalOpen: Function;
}) => () => {
  setIsShareModalOpen(true);
};

export const getOnShareSpecificationModalClose = ({
  setIsShareModalOpen,
}: {
  setIsShareModalOpen: Function;
}) => () => {
  setIsShareModalOpen(false);
};

export const filterOptionsBySelectedValues = ({
  options,
  selectedValues,
  optionKey,
  selectedValueKey,
}) => {
  return options.filter(option => {
    const index = selectedValues.findIndex(value => {
      return value[selectedValueKey] === option[optionKey];
    });

    return index > -1 ? false : true;
  });
};

export const transformErrors = (getTranslationForKey: Function) => (
  errors: AjvError[]
) =>
  errors.map(error => {
    switch (error.name) {
      case "maxLength":
        error.message = getTranslationForKey("maxLength", {
          maxLength: error.params.limit,
        });
        break;
      default:
        error.message = getTranslationForKey(error.name);
    }
    return error;
  });
