import { Row, Col } from "antd";
import { Icon } from "@trace-one/design-system";
import { WidgetProps } from "components/SpecificationSection/types";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";

function ReadOnlyTextWidget(props: WidgetProps) {
  const { uiSchema, value, label } = props;

  const title = uiSchema["ui:title"];
  const icon = uiSchema["ui:title:icon:name"];
  const iconSize = uiSchema["ui:title:icon:size"];
  const labelCol = uiSchema["ui:label:col"] ?? {};
  const valueCol = uiSchema["ui:value:col"] ?? {};

  const { getTranslationForKey } = useFormSchemaTranslation();

  return (
    <Row gutter={16}>
      <Col span={12} {...labelCol}>
        <Row gutter={8}>
          {icon && (
            <Col>
              <Icon name={icon} size={iconSize} />
            </Col>
          )}
          <Col>
            <span>{getTranslationForKey(title || label)}</span>
          </Col>
        </Row>
      </Col>
      <Col span={12} {...valueCol}>
        <span>{value}</span>
      </Col>
    </Row>
  );
}

export default ReadOnlyTextWidget;
