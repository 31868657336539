import { defineMessages } from "react-intl";

export const messages = defineMessages({
  specificationInAnotherFolder: {
    id: "creationFolder.products.specificationInAnotherFolder",
    defaultMessage: "This Specification is already part of another Folder",
  },

  noRevisePermission: {
    id: "creationFolder.products.noRevisePermission",
    defaultMessage:
      "This Specification is signed, you need revision rights to create a revision",
  },

  supplierIsWorkingOnRevision: {
    id: "creationFolder.products.supplierIsWorkingOnRevision",
    defaultMessage: "The supplier is currently working on a revision request",
  },
});
