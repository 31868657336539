import { useIntl } from "react-intl";
import { Button } from "@trace-one/design-system";
import { useSelector } from "react-redux";
import { selectSpecificationPermissions } from "store/specificationDetails/selectors";
import { useSpecificationActions } from "hooks";
import { useRevisionActions } from "./hooks";
import { messages } from "./messages";
import { RevisionActionsProps } from "./types";
import styles from "components/Header/styles.module.less";

const RevisionActions: React.FC<RevisionActionsProps> = ({
  specificationId,
  state,
  onClickSendRequest,
  history,
}) => {
  const permissions = useSelector(selectSpecificationPermissions);
  const { share, cancelRevision } = useSpecificationActions(
    {
      specificationId,
      state,
    },
    permissions
  );
  const { formatMessage } = useIntl();
  const { onCancelRevision } = useRevisionActions({
    specificationId,
    history,
  });

  return (
    <div className={styles.revisionButtonsWrapper}>
      <span>{formatMessage(messages.specificationInRevision)}</span>
      {!!cancelRevision && (
        <Button type="secondary" onClick={onCancelRevision}>
          {formatMessage(messages.cancel)}
        </Button>
      )}
      {!!share && (
        <Button type="primary" onClick={onClickSendRequest}>
          {formatMessage(messages.sendRequest)}
        </Button>
      )}
    </div>
  );
};

export default RevisionActions;
