import { State } from "./types";

const reducers = {
  setFormData: (state: State, action) => {
    state.generalInfo = action.payload.generalInfo;
    state.progressState = action.payload.progressState;
    state.structure = action.payload.structure;
    state.sections = action.payload.sections;
  },
};

export default reducers;
