import React from "react";
import { Select } from "@trace-one/design-system";
import { SelectItemProps } from "./types";

const SelectItem: React.FC<SelectItemProps> = ({
  selectedValue,
  options,
  isLoading,
  hasError,
  onChange,
  placeholder,
  disabled,
  allowClear,
}) => {
  return (
    <Select
      loading={isLoading}
      error={hasError}
      options={options}
      value={selectedValue}
      onChange={onChange}
      placeholder={placeholder}
      disabled={disabled}
      allowClear={allowClear}
    />
  );
};

export default SelectItem;
