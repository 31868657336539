import { useGetChapterTypes } from "apis/SPEC/chapter-rtk";
import { TemplateSectionData } from "models";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectLanguageCode } from "store/user/selectors";
import { isListEmpty } from "utils/general";

export const useSpecificationChapters = ({ chapters = [], productTypeId }) => {
  const [specChapters, setSpecChapters] = useState<TemplateSectionData[]>();
  const languageCode = useSelector(selectLanguageCode);

  const { data: chaptersFromProductType } = useGetChapterTypes(
    {
      params: { languageCode },
    },
    {
      /**
       * Make the call only if chapters are empty,
       * i.e template is not selected
       */
      skip: !isListEmpty(chapters),
    }
  );

  useEffect(() => {
    if (!isListEmpty(chapters)) {
      setSpecChapters(chapters);
      return;
    }

    /**
     * Filter chapters based on selected product types
     */
    if (!isListEmpty(chaptersFromProductType)) {
      let selectedProductChapters = chaptersFromProductType
        .map(chapter => {
          if (chapter.productTypeId === productTypeId) {
            return {
              name: chapter.chapterName,
              chapterType: chapter.chapterType,
              id: chapter.chapterType,
              visible: true,
            };
          }
          return undefined;
        })
        .filter(chapter => chapter);

      setSpecChapters(selectedProductChapters);
    }
  }, [chapters, chaptersFromProductType]);

  return {
    specChapters,
  };
};
