import { useContext, useEffect, useMemo, useState } from "react";
import { Col } from "antd";
import { Card } from "@trace-one/react-components";
import { Checkbox, Toggle } from "@trace-one/design-system";
import arrayMove from "array-move";
import { useIntl } from "react-intl";
import { useRouteMatch } from "react-router-dom";
import { ChaptersContext } from "components/Chapters";
import Sections from "./components/Sections";
import { isLibraryObjectDetailsPage } from "utils/library";
import {
  getIsChapterSwitchDisabled,
  getOnChangeChapterVisibility,
  handleDragEnd,
} from "./utils";
import { ROUTES } from "utils/constants";
import { messages } from "components/Chapters/messages";
import { ChapterProps } from "./types";
import styles from "./styles.module.less";

export const Chapter: React.FC<ChapterProps> = ({ chapter }) => {
  const { formatMessage } = useIntl();
  const {
    errors,
    displayActions,
    getChangeVisibilityForSection,
    getChangeVisibilityForChapter,
    onMoveUpSection,
    onMoveDownSection,
    onReorderSections,
    page,
    onSetAllChapterSectionsVisibility,
    areAllChapterSectionsChecked,
    areChapterSectionsIndeterminate,
  } = useContext(ChaptersContext);

  const [areUserGuidesUpdating, setAreUserGuidesUpdating] = useState<boolean>(
    false
  );

  // temporary check to support template creation in folder and spec wizards
  // to be removed when template creation is removed from folder and spec wizards
  const isSpecCreationPage = useRouteMatch(
    ROUTES.SPECIFICATION_CREATION_SPECIFICATION_STEP
  );

  const isFolderSpecCreationPage = useRouteMatch(
    ROUTES.CREATION_SPECIFICATIONS_STEP
  );

  const { id, mandatory, name, sections, visible, chapterType } = chapter;

  const [isChapterVisible, setIsChapterVisible] = useState(visible);
  const [chapterSections, setChapterSections] = useState(sections);

  const changeVisibilityForSection =
    isSpecCreationPage || isFolderSpecCreationPage
      ? getChangeVisibilityForSection(id)
      : getChangeVisibilityForChapter(id);

  const isChapterSwitchDisabled = useMemo(
    () =>
      getIsChapterSwitchDisabled({
        mandatory,
        displayActions,
      }),
    [mandatory, displayActions]
  );

  useEffect(() => {
    setChapterSections(sections);
  }, [sections]);

  useEffect(() => {
    setIsChapterVisible(visible);
  }, [visible]);

  return (
    <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
      <Card
        title={name}
        extra={
          typeof onSetAllChapterSectionsVisibility === "function" ? (
            <Checkbox
              checked={areAllChapterSectionsChecked}
              indeterminate={areChapterSectionsIndeterminate}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onSetAllChapterSectionsVisibility(event.target.checked)
              }
              data-test-id="translation-template-checkbox"
              data-testid="translation-template-checkbox"
            />
          ) : (
            !isLibraryObjectDetailsPage(page) && (
              <Toggle
                data-testid="chapterSwitch"
                onChange={getOnChangeChapterVisibility({
                  changeVisibilityForSection,
                  setIsChapterVisible,
                  setAreUserGuidesUpdating,
                })}
                checked={isChapterVisible}
                disabled={isChapterSwitchDisabled}
              />
            )
          )
        }
        hasError={errors[id]}
        className={styles.card}
        errorMessage={formatMessage(messages.errorFieldMessage)}
      >
        <Sections
          sections={chapterSections}
          isChapterVisible={isChapterVisible}
          onDragEnd={handleDragEnd({
            arrayMove,
            sections: chapterSections,
            moveUpSection: onMoveUpSection(chapter.id),
            moveDownSection: onMoveDownSection(chapter.id),
            setChapterSections,
            onReorderSections,
            chapterType: chapter.chapterType,
          })}
          chapterType={chapterType}
          areUserGuidesUpdating={areUserGuidesUpdating}
        />
      </Card>
    </Col>
  );
};

export default Chapter;
