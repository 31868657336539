import { useState } from "react";

const useDropdownActionsVisibility = ({
  onOpenDropdown,
}: {
  onOpenDropdown?: Function;
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const onVisibleChange = (open: boolean) => {
    if (!open) {
      setIsVisible(false);
    } else {
      if (typeof onOpenDropdown === "function") {
        onOpenDropdown();
      }

      setIsVisible(true);
    }
  };

  return { isVisible, onVisibleChange };
};

export default useDropdownActionsVisibility;
