import { createSlice } from "@reduxjs/toolkit";
import initialState from "./initialState";
import reducers from "./reducers";
import {
  fetchUser,
  fetchUserApplications,
  fetchUserCompanyDisplayName,
} from "./asyncActions";

export const slice = createSlice({
  name: "user",
  initialState,
  reducers,
  extraReducers: builder => {
    builder
      .addCase(fetchUser.pending, state => {
        state.isLoading = true;
      })
      .addCase(fetchUser.fulfilled, (state, action) => ({
        ...state,
        ...action.payload,
        isLoading: false,
      }))
      .addCase(fetchUser.rejected, state => {
        state.isLoading = false;
      })
      .addCase(fetchUserApplications.pending, state => {
        state.isLoadingApplications = true;
      })
      .addCase(fetchUserApplications.fulfilled, (state, action) => {
        state.applications = action.payload;
        state.isLoadingApplications = false;
      })
      .addCase(fetchUserApplications.rejected, state => {
        state.isLoadingApplications = false;
      })
      .addCase(fetchUserCompanyDisplayName.fulfilled, (state, action) => {
        state.owningCompanyName = action.payload.companyDisplayName;
      });
  },
});

export const { setUserLanguage, setCompanyId } = slice.actions;

export default slice.reducer;
