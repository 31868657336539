import React from "react";
import { FormattedMessage } from "react-intl";
import { messages } from "./messages";
import styles from "../../styles.module.less";

const Error = () => {
  return (
    <div className={styles.errorMessage}>
      <FormattedMessage {...messages.errorMessage} />
    </div>
  );
};

export default Error;
