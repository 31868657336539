import { defineMessages } from "react-intl";

export const messages = defineMessages({
  claims: {
    id: "library.navigation.claims",
    defaultMessage: "Claims",
  },
  requirements: {
    id: "library.navigation.requirements",
    defaultMessage: "Requirements",
  },
  materials: {
    id: "library.navigation.materials",
    defaultMessage: "Materials",
  },
  rawMaterial: {
    id: "library.navigation.rawMaterial",
    defaultMessage: "Raw Material",
  },
  chapters: {
    id: "library.navigation.chapters",
    defaultMessage: "Chapters",
  },
  packagings: {
    id: "library.navigation.packagings",
    defaultMessage: "Packagings",
  },
  templates: {
    id: "library.navigation.templates",
    defaultMessage: "Templates",
  },

  requirement: {
    id: "library.tooltip.requirement",
    defaultMessage: "requirement",
  },
  material: {
    id: "library.tooltip.material",
    defaultMessage: "material",
  },
  chapter: {
    id: "library.tooltip.chapter",
    defaultMessage: "chapter",
  },
  template: {
    id: "library.tooltip.template",
    defaultMessage: "template",
  },
  claim: {
    id: "library.tooltip.claim",
    defaultMessage: "claim",
  },

  errorMessage: {
    id: "library.error.message",
    defaultMessage:
      "Sorry, an error occurred while accessing Library item data.",
  },

  contentLabel: {
    id: "library.requirement.content.label",
    defaultMessage: "Content",
  },

  fileLabel: {
    id: "library.requirement.file.label",
    defaultMessage: "Upload your requirement",
  },
  fileNotFound: {
    id: "library.file.notFound",
    defaultMessage: "File not found",
  },

  documentLabel: {
    id: "library.requirement.document.label",
    defaultMessage: "Document",
  },

  additionalInformationLabel: {
    id: "library.requirement.additionalInformation.label",
    defaultMessage: "Additional information",
  },

  additionalInformationPlaceholder: {
    id: "library.requirement.additionalInformation.placeholder",
    defaultMessage: "Any relevant information to this requirement document",
  },

  nameLabel: {
    id: "library.requirement.name.label",
    defaultMessage: "Name",
  },
  namePlaceholder: {
    id: "library.requirement.name.placeholder",
    defaultMessage: "Enter a name",
  },

  typeLabel: {
    id: "library.requirement.type.label",
    defaultMessage: "Type",
  },
  typeText: {
    id: "library.requirement.type.text",
    defaultMessage: "Text",
  },
  typeDocument: {
    id: "library.requirement.type.document",
    defaultMessage: "Document",
  },

  typeRawMaterialPlaceholder: {
    id: "library.rawMaterialForm.type.placeholder",
    defaultMessage: "Select a type",
  },
  typeRawMaterialOptionLabel: {
    id: "library.rawMaterialForm.type.rawMaterial",
    defaultMessage: "Raw material",
  },
  typeCompositeOptionLabel: {
    id: "library.rawMaterialForm.type.composite",
    defaultMessage: "Composite",
  },
  typeAdditiveOptionLabel: {
    id: "library.materialForm.type.additive",
    defaultMessage: "Additive",
  },
  typeProcessingAidOptionLabel: {
    id: "library.materialForm.type.processingAid",
    defaultMessage: "Processing aid",
  },

  tagsLabel: {
    id: "library.requirement.tags.label",
    defaultMessage: "Tags",
  },
  tagsNotFound: {
    id: "library.tags.notFound",
    defaultMessage: "Some tags were not found",
  },

  lastModificationLabel: {
    id: "library.requirement.lastModification.label",
    defaultMessage: "Last modification",
  },

  statusField: {
    // @ts-ignore
    label: {
      id: "library.rawMaterialForm.status.label",
      defaultMessage: "Status",
    },
    active: {
      id: "library.rawMaterialForm.status.active",
      defaultMessage: "Active",
    },
    inactive: {
      id: "library.rawMaterialForm.status.inactive",
      defaultMessage: "Inactive",
    },
  },

  recipeField: {
    // @ts-ignore
    label: {
      id: "library.rawMaterialForm.recipe.label",
      defaultMessage: "Recipe",
    },

    madeInHouse: {
      id: "library.rawMaterialForm.recipe.madeInHouse",
      defaultMessage: "Made in house",
    },

    boughtToThirdParty: {
      id: "library.rawMaterialForm.recipe.boughtToThirdParty",
      defaultMessage: "Bought to third party",
    },

    info: {
      boughtToThirdParty: {
        id: "library.rawMaterialForm.recipe.info.boughToThirdParty",
        defaultMessage:
          "Create a recipe by manually detailing every ingredient in the table bellow",
      },
    },
  },

  validateButton: {
    id: "library.form.button.validate",
    defaultMessage: "Validate",
  },

  publishButton: {
    id: "library.form.button.publish",
    defaultMessage: "Publish",
  },

  publishButtonTooltip: {
    id: "library.form.button.publish.tooltip",
    defaultMessage:
      "Please fill every mandatory fields to publish your {libraryObject}",
  },

  editButton: {
    id: "library.actions.edit",
    defaultMessage: "Edit",
  },

  deleteAction: {
    id: "library.actions.delete",
    defaultMessage: "Delete",
  },

  createNewVersion: {
    id: "library.actions.createNewVersion",
    defaultMessage: "Create new version",
  },

  createNewVersionTitle: {
    id: "library.chapterForm.createNewVersion.title",
    defaultMessage: "New version of chapter created",
  },

  createNewVersionDescription: {
    id: "library.actions.createNewVersion.description",
    defaultMessage:
      "The new version of {libraryItemName} has been created successfully",
  },
  deleteVersion: {
    id: "library.actions.deleteVersion",
    defaultMessage: "Delete version",
  },

  chapterDuplicateTitle: {
    id: "library.chapter.duplicate.title",
    defaultMessage: "Chapter duplicated",
  },

  chapterDuplicateDescription: {
    id: "library.chapter.duplicate.description",
    defaultMessage:
      "The chapter {chapterName} has been successfully duplicated.",
  },

  keepAsDraftButton: {
    id: "library.form.button.keepAsDraft",
    defaultMessage: "Keep as draft",
  },

  cancelButton: {
    id: "library.form.button.cancel",
    defaultMessage: "Cancel",
  },

  formRequired: {
    id: "form.validation.required",
    defaultMessage: "Mandatory",
  },

  formMaxCharLength: {
    id: "form.validation.maxCharLength",
    defaultMessage: "This field is limited to {max} characters",
  },

  columns: {
    // @ts-ignore
    name: {
      id: "library.recipeTable.column.title.name",
      defaultMessage: "Name",
    },

    type: {
      id: "library.recipeTable.column.title.type",
      defaultMessage: "Type",
    },

    classification: {
      id: "library.recipeTable.column.title.classification",
      defaultMessage: "Classification",
    },

    function: {
      id: "library.recipeTable.column.title.function",
      defaultMessage: "Function",
    },

    action: {
      id: "library.recipeTable.column.title.action",
      defaultMessage: "Action",
    },
  },

  recipeActionsDisplay: {
    id: "library.recipeTable.actions.display",
    defaultMessage: "Display",
  },

  type: {
    // @ts-ignore
    rawMaterial: {
      id: "library.table.type.rawMaterial",
      defaultMessage: "Raw material",
    },

    additive: {
      id: "library.table.type.additive",
      defaultMessage: "Additive",
    },

    processingAid: {
      id: "library.table.type.processingAid",
      defaultMessage: "Processing aid",
    },

    composite: {
      id: "library.table.type.composite",
      defaultMessage: "Composite",
    },
  },

  functionsPlaceholder: {
    id: "library.rawMaterialForm.details.functions.placeholder",
    defaultMessage: "Add a function",
  },

  cardTitle: {
    // @ts-ignore
    allergens: {
      id: "library.rawMaterialForm.allergens.cardTitle",
      defaultMessage: "Allergens",
    },
  },

  allergensColumns: {
    // @ts-ignore
    allergen: {
      id: "library.rawMaterialForm.allergens.column.title.allergen",
      defaultMessage: "Allergen",
    },

    ingredients: {
      id: "library.rawMaterialForm.allergens.column.title.ingredients",
      defaultMessage: "Ingredients",
    },

    present: {
      id: "library.rawMaterialForm.allergens.column.title.present",
      defaultMessage: "Present",
    },

    unintentionalPresent: {
      id: "library.rawMaterialForm.allergens.column.title.unintentionalPresent",
      defaultMessage: "Unintentional present",
    },

    action: {
      id: "library.rawMaterialForm.allergens.column.title.action",
      defaultMessage: "Action",
    },
  },

  noAllergens: {
    id: "library.rawMaterialForm.allergens.noAllergen",
    defaultMessage: "No allergen",
  },
  validationMessageSumOfPercentage: {
    id: "library.rawMaterialForm.recipeTable.validationMessage",
    defaultMessage: "The sum of ingredients should be 100%",
  },

  recipePanelTitleCreate: {
    id: "library.rawMaterialForm.recipeTable.panelTitle.create",
    defaultMessage: "New ingredient details",
  },

  recipePanelTitleEdit: {
    id: "library.rawMaterialForm.recipeTable.panelTitle.edit",
    defaultMessage: "Ingredient details",
  },

  ingredientNamePlaceholder: {
    id: "library.rawMaterialForm.recipeTable.namePlaceholder.create",
    defaultMessage: "New ingredient",
  },

  percentage: {
    id: "library.rawMaterialForm.recipeTable.panel.percentage",
    defaultMessage: "Percentage",
  },
  save: {
    id: "library.rawMaterialForm.recipeTable.panel.save",
    defaultMessage: "Save",
  },
  convertToComposite: {
    id: "library.rawMaterialForm.recipeTable.panel.convertToComposite",
    defaultMessage: "Convert to composite",
  },
  addIngredient: {
    id: "library.rawMaterialForm.recipeTable.panel.addIngredient",
    defaultMessage: "Add ingredient",
  },
  materialEditionModalTitle: {
    id: "library.material.materialEditionModalTitle",
    defaultMessage: "Material edition",
  },
  materialEditionModalBody: {
    id: "library.material.materialEditionModalBody",
    defaultMessage:
      "Any modification will be saved and applied automatically. Every linked specifications and composite made in house are going to be impacted.",
  },
  materialEditionAlertTitle: {
    id: "library.material.materialEditionAlertTitle",
    defaultMessage: "Modifications are automatically saved.",
  },
  materialEditionAlertDescription: {
    id: "library.material.materialEditionAlertDescription",
    defaultMessage:
      "Modifications are automatically saved and applied if the material is linked to specifications or composite made in house.",
  },

  internalCodeLabel: {
    id: "library.material.internalCode.label",
    defaultMessage: "Internal code",
  },
  internalCodePlaceholder: {
    id: "library.material.internalCode.placeholder",
    defaultMessage: "Enter an internal code",
  },
  chapterEditionModalTitle: {
    id: "library.chapterForm.impactSpecificationModal.title",
    defaultMessage: "Chapter edition",
  },
  chapterEditionModalBody: {
    id: "library.chapterForm.impactSpecificationModal.description",
    defaultMessage:
      "Any modification will be saved and applied automatically. Every linked specifications are going to be impacted.",
  },
  requirementEditionModalTitle: {
    id: "library.requirement.requirementEditionModalTitle",
    defaultMessage: "Requirement edition",
  },
  requirementEditionModalBody: {
    id: "library.requirement.requirementEditionModalBody",
    defaultMessage:
      "Any modification will be saved and applied automatically. Every linked specifications are going to be impacted.",
  },
  deleteRequirement: {
    id: "library.requirement.delete",
    defaultMessage: "Delete requirement",
  },
  cancelRequirementCreationTitle: {
    id: "library.requirement.cancelRequirementCreationTitle",
    defaultMessage: "Cancel requirement creation",
  },
  cancelRequirementCreationDescription: {
    id: "library.requirement.cancelRequirementCreationDescription",
    defaultMessage:
      "The requirement has been automatically saved as a draft. By leaving now, you can either keep it or cancel the creation and delete it.",
  },
  requirementTitle: {
    id: "library.create.requirement.title",
    defaultMessage: "New requirement",
  },
  requirementEditionAlertTitle: {
    id: "library.requirement.requirementEditionAlertTitle",
    defaultMessage: "Modifications are automatically saved.",
  },
  requirementEditionAlertDescription: {
    id: "library.requirement.requirementEditionAlertDescription",
    defaultMessage:
      "Modifications are automatically saved and applied if the requirement is linked to specifications.",
  },
  requirementEditionDraftAlertDescription: {
    id: "library.requirement.requirementEditionDraftAlertDescription",
    defaultMessage: "Modifications are automatically saved.",
  },
  chapterTitle: {
    id: "library.create.chapter.title",
    defaultMessage: "New chapter",
  },

  characteristicAndControlPlansTitle: {
    id: "library.create.characteristicAndControlPlans.title",
    defaultMessage: "New characteristic & control plans table",
  },

  materialTitle: {
    id: "library.create.material.title",
    defaultMessage: "New material",
  },
  addComponent: {
    id: "library.packagingSystemForm.button.addComponent",
    defaultMessage: "Add a component",
  },
  addARequirement: {
    id: "specificationDetails.sections.requirements.addARequirement",
    defaultMessage: "Add a requirement",
  },

  claimTitle: {
    id: "library.create.claim.title",
    defaultMessage: "New claim",
  },

  templateTitle: {
    id: "library.create.template.title",
    defaultMessage: "New template",
  },
  templateStructureTitle: {
    id: "library.template.structure.title",
    defaultMessage: "Structure",
  },
  templateEditionAlertTitle: {
    id: "library.template.templateEditionAlertTitle",
    defaultMessage: "Modifications are automatically saved.",
  },
  templateEditionAlertDescription: {
    id: "library.template.templateEditionAlertDescription",
    defaultMessage:
      "Any modification will be saved and applied automatically. All the specifications using this template are going to be impacted.",
  },

  primaryIngredient: {
    id: "recipe.primaryIngredient",
    defaultMessage: "Primary ingredient",
  },
  characteristicIngredient: {
    id: "recipe.characteristicIngredient",
    defaultMessage: "Characteristic ingredient",
  },

  processingAidsTableSubtitle: {
    id: "library.recipeTable.processingAids.subtitle",
    defaultMessage: "Processing aids",
  },

  processingAidsTableDisabledTooltip: {
    id: "library.recipeTable.processingAids.tooltip",
    defaultMessage:
      "Inherited from {materialName}, please edit in the composite.",
  },
  conflictAlert: {
    id: "library.conflictAlert",
    defaultMessage:
      "This {libraryObjectType} has been changed by someone else since you opened it. You will need to refresh it and discard your changes.",
  },
  claimsCountLabel: {
    id: "library.claims",
    defaultMessage: "claims",
  },
  chaptersCountLabel: {
    id: "library.chapters",
    defaultMessage: "chapters",
  },
});

export const tabMessages = defineMessages({
  generalInformation: {
    id: "library.tabs.generalInformation",
    defaultMessage: "General information",
  },
  linkedSpecifications: {
    id: "library.tabs.linkedSpecifications",
    defaultMessage: "Linked Specifications ({numberOfLinkedSpecs})",
  },
  versionHistory: {
    id: "library.tabs.versionHistory",
    defaultMessage: "Version history",
  },
});

export default messages;
