import { MenuActions } from "types/general";
import { isObjectEmpty } from "utils/general";
import { refetchSpecificationListing } from "../../../../utils";

export const getActionsList = ({
  actions,
  resetCurrentPage,
  onOpenSelectSupplierModal,
  currentPage,
  setShouldRefetchSpecifications,
}: {
  actions: {
    [key: string]: MenuActions;
  };
  resetCurrentPage: Function;
  onOpenSelectSupplierModal: Function;
  currentPage: number;
  setShouldRefetchSpecifications: Function;
}) => {
  if (isObjectEmpty(actions)) return [];

  let currentActions = [];

  const { revise: reviseAction, ...restOfActions } = actions;

  for (const [key, action] of Object.entries(restOfActions)) {
    switch (key) {
      case "share":
      case "history":
        continue;
      case "duplicate":
        currentActions.push({
          ...action,
          label: action.name,
          onClick: onOpenSelectSupplierModal,
        });
        break;
      case "delete":
      case "cancelRevision":
        currentActions.push({
          ...action,
          label: action.name,
          onClick: undefined,
          tooltipProps: {
            ...action?.tooltipProps,
            onConfirmation: async () => {
              await action.onClick();

              refetchSpecificationListing({
                currentPage,
                setShouldRefetchSpecifications,
                resetCurrentPage,
              })();
            },
          },
        });
        break;
      default:
        currentActions.push({
          ...action,
          label: action.name,
        });
    }
  }

  if (!isObjectEmpty(reviseAction)) {
    currentActions.push({
      ...reviseAction,
      label: reviseAction.name,
    });
  }

  return currentActions;
};
