import { Row } from "antd";
import { Heading, Icon } from "@trace-one/design-system";
import { TitleProps } from "./types";
import styles from "./styles.module.less";

const Title: React.FC<TitleProps> = ({ sectionIcon, sectionName }) => {
  const headingProps = {
    size: "xxs",
    color: "white",
  };

  return (
    <Row justify="start" align="middle" className={styles.readOnlyHeading}>
      <Icon
        name={sectionIcon}
        size="small"
        color="white"
        className={styles.readOnlySectionIcon}
      />
      <Heading {...headingProps}>{sectionName}</Heading>
    </Row>
  );
};

export default Title;
