import { Route, Redirect, RouteProps } from "react-router-dom";
import { useUserInfo } from "hooks";
import { ROUTES } from "utils/constants";

const RetailerRoute = (props: RouteProps) => {
  const { isRetailer } = useUserInfo();

  return isRetailer ? <Route {...props} /> : <Redirect to={ROUTES.HOME} />;
};

export default RetailerRoute;
