import { Dayjs } from "dayjs";
import { Dictionary } from "types/general";
import { isListEmpty, isObjectEmpty } from "utils/general";
import { RangeNumberFormData } from "./types";
import { getArrayIndexFromId } from "./widgets/utils";

export const convertArrayToObjectOfArrays = ({ array, id }) => {
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[id]]: [...(obj[item[id]] || []), item],
    };
  }, {});
};

export const getLabelFromListById = ({ list, id }) => {
  const index = list.findIndex(item => item.id === id);
  const text = index > -1 ? list[index].text : "";
  return text;
};

export const getOnFieldSave = ({
  saveTemplateBlock,
}: {
  saveTemplateBlock: Function;
}) => async (data, previousData) => {
  await saveTemplateBlock(
    JSON.stringify({ items: data }),
    JSON.stringify({ items: previousData })
  );
};

export const getMonthsForSeasonalityWidget = ({
  startDate,
  endDate,
  styles,
}: {
  startDate: Dayjs;
  endDate: Dayjs;
  styles: Record<string, string>;
}) => {
  const startMonth = parseInt(startDate.format("M"), 10);
  const endMonth = parseInt(endDate.format("M"), 10);

  const allMonths = [
    { id: 1, value: "jan" },
    { id: 2, value: "feb" },
    { id: 3, value: "mar" },
    { id: 4, value: "apr" },
    { id: 5, value: "may" },
    { id: 6, value: "jun" },
    { id: 7, value: "jul" },
    { id: 8, value: "aug" },
    { id: 9, value: "sep" },
    { id: 10, value: "oct" },
    { id: 11, value: "nov" },
    { id: 12, value: "dec" },
  ];

  let months = [];

  if (startMonth > endMonth) {
    months = allMonths.map(month => {
      return {
        ...month,
        activeClass:
          month.id >= startMonth || month.id <= endMonth
            ? styles.isActive
            : styles.box,
      };
    });
  } else if (startMonth < endMonth) {
    months = allMonths.map(month => {
      return {
        ...month,
        activeClass:
          month.id <= endMonth && month.id >= startMonth
            ? styles.isActive
            : styles.box,
      };
    });
  } else {
    const startDay = startDate.format("DD");
    const endDay = endDate.format("DD");

    if (startDay <= endDay) {
      months = allMonths.map(month => {
        return {
          ...month,
          activeClass: month.id === startMonth ? styles.isActive : styles.box,
        };
      });
    } else {
      months = allMonths.map(month => {
        return {
          ...month,
          activeClass: styles.isActive,
        };
      });
    }
  }

  return months;
};

export const getDataFieldId = (
  propertyId: string | number,
  listItemKey?: string
) => {
  return `${listItemKey ? `${listItemKey}-` : ""}${propertyId}`;
};

export const getBooleanTranslation = (
  val: boolean,
  getTranslationForKey: Function
): string => (!!val ? getTranslationForKey("yes") : getTranslationForKey("no"));

export const getRangeString = ({
  range,
  refListDictionary,
  emptyValue,
  getTranslationForKey,
  properties,
  listItemKey,
}: {
  range: RangeNumberFormData;
  refListDictionary: Dictionary<string>;
  emptyValue: string;
  getTranslationForKey: Function;
  properties?: {
    [key: string]: {
      [key: string]: string | number;
    };
  };
  listItemKey?: string;
}) => {
  if (isObjectEmpty(range)) {
    return emptyValue;
  }

  const { minimumValue, maximumValue, unitId, unit } = properties || {};

  return `<span data-field-id=${getDataFieldId(
    minimumValue?.propertyId,
    listItemKey
  )}>${range?.minimumValue ?? emptyValue}</span> ${getTranslationForKey(
    "to"
  )} <span data-field-id=${getDataFieldId(
    maximumValue?.propertyId,
    listItemKey
  )}>${
    range?.maximumValue ?? emptyValue
  }</span><span data-field-id=${getDataFieldId(
    unitId?.propertyId || unit?.propertyId,
    listItemKey
  )}>${
    refListDictionary[range?.unit || range?.unitId]
      ? ` ${refListDictionary[range?.unit || range?.unitId]}`
      : ""
  }</span>`;
};

export const getFieldReadOnlyContent = ({
  widgetName,
  element,
  getTranslationForKey,
  refListDictionary,
  emptyValue,
  options,
}: {
  widgetName: string;
  element: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  getTranslationForKey: Function;
  refListDictionary: Dictionary<string>;
  emptyValue: string;
  options?: {
    value?: string;
    label?: React.ReactNode;
  }[];
}) => {
  if (!widgetName || isObjectEmpty(element)) {
    return null;
  }

  const { formData, schema, idSchema, formContext } = element.props;

  const { formData: listFormData, arraySchemaItemKey } = formContext || {};

  const { properties } = schema || {};

  const index = getArrayIndexFromId(idSchema?.$id);
  const currentListFormData = listFormData?.items?.[index] || {};
  const listItemKey = currentListFormData?.[arraySchemaItemKey] as string;

  let content = "";

  switch (widgetName.toUpperCase()) {
    case "RADIOWIDGET": {
      content =
        formData === undefined
          ? emptyValue
          : typeof formData === "boolean"
          ? getBooleanTranslation(formData, getTranslationForKey)
          : emptyValue;

      break;
    }
    case "SPECLISTRADIOWIDGET": {
      content =
        (!isListEmpty(options) &&
          formData &&
          (options.filter(option => option.value === formData)[0]
            .label as string)) ||
        emptyValue;

      break;
    }
    case "RANGENUMBERWIDGET": {
      content = getRangeString({
        range: formData,
        refListDictionary,
        emptyValue,
        getTranslationForKey,
        properties,
        listItemKey,
      });

      break;
    }

    case "COMPACTWIDGET": {
      const { value, unitId, unit } = properties || {};
      content =
        !formData?.value &&
        !refListDictionary[formData?.unit || formData?.unitId]
          ? emptyValue
          : `<span data-field-id=${getDataFieldId(
              value?.propertyId,
              listItemKey
            )}>${
              formData?.value || emptyValue
            }</span><span data-field-id=${getDataFieldId(
              unitId?.propertyId || unit?.propertyId,
              listItemKey
            )}>${
              refListDictionary[formData?.unit || formData?.unitId]
                ? ` ${refListDictionary[formData?.unit || formData?.unitId]}`
                : ""
            }</span>`;
      break;
    }
    default:
      break;
  }

  return content;
};
