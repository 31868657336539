import { Row, Col, Form } from "antd";
import RowTitle from "components/RowTitle";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import OriginsReadOnly from "../OriginWidget/components/OriginsReadOnly";
import { useCultivationOriginWidget } from "./hooks";
import { getPropertiesObjectFromArray } from "../../templates/utils";
import { CultivationOriginWidgetProps } from "./types";

const CultivationOriginWidget: React.FC<CultivationOriginWidgetProps> = ({
  properties,
  formContext,
  formData,
  uiSchema,
}) => {
  const { displayActions, refListDictionary, emptyValue } = formContext;

  const {
    showAlternativeOrigin,
    handleAcceptsAlternativeOriginClick,
  } = useCultivationOriginWidget({ formContext, formData });

  const propertiesObject = getPropertiesObjectFromArray(properties);
  const { getTranslationForKey } = useFormSchemaTranslation();

  return (
    <Row gutter={[10, 0]}>
      <Col span={24}>
        <RowTitle
          title={getTranslationForKey(uiSchema["ui:title"])}
          displayActions={displayActions}
        />
      </Col>

      {displayActions ? (
        <>
          <Col xs={24}>
            <Form.Item label={getTranslationForKey("mainOriginsTitle")} />
          </Col>
          <Col xs={24}>{propertiesObject?.mainOrigins?.content}</Col>
          <Col xs={24}>
            {{
              ...propertiesObject?.acceptsAlternativeOrigin?.content,
              props: {
                ...propertiesObject?.acceptsAlternativeOrigin?.content?.props,
                onChange: handleAcceptsAlternativeOriginClick,
              },
            }}
          </Col>
          {showAlternativeOrigin && (
            <Col xs={24}>{propertiesObject.alternativeOrigins?.content}</Col>
          )}
        </>
      ) : (
        <>
          {propertiesObject.mainOrigins?.content ? (
            <OriginsReadOnly
              originsContent={propertiesObject.mainOrigins?.content}
              refListDictionary={refListDictionary}
              emptyValue={emptyValue}
              isMainOrigin={true}
            />
          ) : null}
          {propertiesObject.alternativeOrigins?.content ? (
            <OriginsReadOnly
              originsContent={propertiesObject.alternativeOrigins?.content}
              refListDictionary={refListDictionary}
              emptyValue={emptyValue}
              isMainOrigin={false}
              acceptsAlternativeOriginContent={
                propertiesObject.acceptsAlternativeOrigin?.content
              }
            />
          ) : null}
        </>
      )}
    </Row>
  );
};

export default CultivationOriginWidget;
