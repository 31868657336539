import React, { useEffect, useMemo, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useIntl } from "react-intl";
import { Row } from "antd";
import {
  Typography,
  Button,
  EmptyState,
  ToggleButtons,
} from "@trace-one/design-system";
import Spin from "components/Spin";
import DiscussionPanel from "components/DiscussionPanel";
import ModificationRequestPanelCard from "./ModificationRequestPanelCard";
import { useBoolean, useModificationRequestPanel, useScroll } from "hooks";
import { useAppDispatch } from "store";
import { setIsRequestPanelOpen } from "store/specificationDetails/specificationDetailsSlice";
import { fetchModificationRequests } from "apis/SPEC";
import { ModificationRequestViewModel } from "viewModels/modificationRequest";
import { isListEmpty } from "utils/general";
import {
  getPanelHeight,
  getRequestStatesForFilter,
} from "utils/modificationRequest";
import {
  MODIFICATION_REQUEST_STATE_FILTER,
  PANEL_DATA_HEADER_ID,
  STICKY_POSITION_OFFSET,
} from "utils/constants";
import { generalMessages } from "messages";
import { ChapterStructureFormData } from "types/library";
import { ModificationRequestStateFilter } from "types/modificationRequest";
import styles from "./styles.module.less";

export interface ModificationRequestPanelProps {
  title: string;
  params: {
    [key: string]: string | string[];
  };
  onClickSubSection?: Function;
  selectedChapter?: ChapterStructureFormData;
  isSpecContact?: boolean;
  userId?: string;
}

const ModificationRequestPanel: React.FC<ModificationRequestPanelProps> = ({
  title,
  params,
  onClickSubSection,
  selectedChapter,
  isSpecContact,
  userId,
}) => {
  const { formatMessage } = useIntl();

  const [selectedRequestId, setSelectedRequestId] = useState(null);
  const [
    requestStateFilter,
    setRequestStateFilter,
  ] = useState<ModificationRequestStateFilter>(
    MODIFICATION_REQUEST_STATE_FILTER.ALL
  );

  const infiniteScrollRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();
  const {
    value: isDiscussionPanelOpen,
    setTrue: openDiscussionPanel,
    setFalse: closeDiscussionPanel,
  } = useBoolean(false);

  const { ref: panelRef } = useScroll(STICKY_POSITION_OFFSET);
  const panelHeight = getPanelHeight();

  useEffect(() => {
    return () => {
      dispatch(setIsRequestPanelOpen(false));
    };
  }, []);

  const {
    data,
    users,
    loadData,
    canLoadMore,
    searchText,
    totalNumberOfItems,
    isLoading,
    requestStateFilterOptions,
    refetchModificationRequests,
  } = useModificationRequestPanel({
    ref: infiniteScrollRef,
    pageSize: 10,
    params,
    fetcher: fetchModificationRequests,
    isDiscussionPanelOpen,
    requestStateFilter,
  });

  const onClickCard = (
    requestId: ModificationRequestViewModel["requestId"]
  ) => {
    setSelectedRequestId(requestId);
    openDiscussionPanel();
  };

  const requests: ModificationRequestViewModel[] = useMemo(() => {
    const requests = data?.map(request => {
      const user = users?.find(user => user.userId === request.ownerUserId);

      if (user) {
        return {
          ...request,
          user: {
            id: user.userId,
            name: `${user.userFirstName} ${user.userLastName}`,
            photoUrl: user.userPhotoUrl,
          },
        };
      }

      return {
        ...request,
        user: null,
      };
    });

    return requests;
  }, [data, users]);

  return (
    <div ref={panelRef}>
      <div className={styles["request-panel"]}>
        {isDiscussionPanelOpen ? (
          <DiscussionPanel
            selectedRequestId={selectedRequestId}
            onClose={closeDiscussionPanel}
            isSpecContact={isSpecContact}
            userId={userId}
          />
        ) : (
          <div className={styles["request-panel-inner"]}>
            <div
              className={styles["request-panel-header"]}
              data-header-id={PANEL_DATA_HEADER_ID}
            >
              <div className={styles["request-panel-header-title"]}>
                <Typography variant={"heading-xs"}>
                  {title} ({totalNumberOfItems})
                </Typography>
              </div>
              <Button
                onClick={() => {
                  dispatch(setIsRequestPanelOpen(false));
                }}
                iconName="close"
                type="tertiary"
                size="small"
              ></Button>
            </div>

            <div
              style={{
                height: panelHeight,
              }}
              id="scrollableInfiniteScrollWrapper"
              ref={infiniteScrollRef}
              className={styles["infinite-scroll-wrapper"]}
            >
              <div className={styles["request-panel-filter"]}>
                <ToggleButtons
                  value={requestStateFilter}
                  onChange={setRequestStateFilter}
                  options={requestStateFilterOptions}
                  disabled={!totalNumberOfItems || isLoading}
                />
              </div>
              {isLoading && !canLoadMore && <Spin />}
              {!isLoading && isListEmpty(requests) && (
                <Row justify="center">
                  <EmptyState
                    heading={formatMessage(generalMessages.noResultsFound)}
                  />
                </Row>
              )}
              <InfiniteScroll
                scrollableTarget="scrollableInfiniteScrollWrapper"
                dataLength={requests?.length || 0}
                hasMore={canLoadMore}
                loader={
                  <div className={styles.infiniteScrollLoadingContainer}>
                    <Spin />
                  </div>
                }
                next={() =>
                  loadData({
                    searchText,
                    requestStates: getRequestStatesForFilter(
                      requestStateFilter
                    ),
                  })
                }
              >
                {requests?.map(request => {
                  return (
                    <div
                      key={request.requestId}
                      className={styles["discussion-card"]}
                    >
                      <ModificationRequestPanelCard
                        request={request}
                        onClick={onClickCard}
                        onClickSubSection={onClickSubSection}
                        selectedChapter={selectedChapter}
                        refetchModificationRequests={
                          refetchModificationRequests
                        }
                        isSpecContact={isSpecContact}
                        userId={userId}
                      />
                    </div>
                  );
                })}
              </InfiniteScroll>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ModificationRequestPanel;
