import { defineMessages } from "react-intl";

export const messages = defineMessages({
  materialInformationTitle: {
    id: "library.materialForm.generalInfo.title",
    defaultMessage: "Define material information",
  },
  materialTypeModificationTitle: {
    id: "library.materialForm.typeModification.title",
    defaultMessage: "Material modification",
  },
  materialTypeModificationDescription: {
    id: "library.materialForm.typeModification.description",
    defaultMessage:
      "Changing the material type will erase all filled information.",
  },
  materialTypeModificationStartOverDescription: {
    id: "library.materialForm.typeModification.startOver.description",
    defaultMessage: "You will need to start over.",
  },
});
