import { Button } from "@trace-one/design-system";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useResume } from "hooks";
import { selectIsResumeInProgress } from "store/folderCreation/selectors";
import { getOnResumeButtonClick } from "./utils";
import { messages } from "./messages";
import { ResumeButtonProps } from "./types";

const ResumeButton: React.FC<ResumeButtonProps> = ({ id }) => {
  const isResumeInProgress = useSelector(selectIsResumeInProgress);
  const { resumeFolder } = useResume();

  return (
    <Button
      type="primary"
      onClick={getOnResumeButtonClick({
        resumeFolder,
        id,
      })}
      data-testid="resume-btn"
      loading={isResumeInProgress}
    >
      <FormattedMessage {...messages.resume} />
    </Button>
  );
};

export default ResumeButton;
