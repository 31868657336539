/* istanbul ignore file */

import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { selectWizard } from "store/specificationCreation/selectors";
import { getProperStepUrl } from "utils/wizard";
import { SPECIFICATION_WIZARD_STEPS } from "utils/constants";

const withProperStepValidation = (currentStep: string) => (
  Component: React.ComponentType
) => props => {
  const history = useHistory();
  const { state } = useSelector(selectWizard);

  const properStep = getProperStepUrl(
    currentStep,
    SPECIFICATION_WIZARD_STEPS,
    state
  );

  useEffect(() => {
    if (properStep) {
      history.push(properStep);
    }
  }, [properStep]);

  return !properStep ? <Component {...props} /> : null;
};

export default withProperStepValidation;
