import { useContext, useEffect, useMemo, useState } from "react";
import cx from "classnames";
import { Heading, Button, Checkbox, Tooltip } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import { ChaptersContext } from "components/Chapters";
import {
  getHasUserGuideCursor,
  getIsSectionCheckboxDisabled,
  getIsSectionDisabled,
  getOnIconUserGuideClick,
  getOnChangeSectionVisibility,
  getIsUserGuideDisabled,
} from "./utils";
import { SectionWrapperProps } from "./types";
import { messages } from "components/Chapters/messages";
import styles from "./styles.module.less";
import { isLibraryObjectDetailsPage } from "utils/library";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

export const SectionWrapper: React.FC<SectionWrapperProps> = ({
  section,
  isChapterVisible,
  chapterType,
  areUserGuidesUpdating,
}) => {
  const { formatMessage } = useIntl();
  const {
    displayActions,
    errors,
    getChangeVisibilityForSection,
    setCommentModal,
    page,
    onSetAllChapterSectionsVisibility,
  } = useContext(ChaptersContext);
  const { userGuide, id, mandatory, name, visible } = section;
  const [isSectionVisibility, setSectionVisibility] = useState(visible);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const showInteractiveElements = !isLibraryObjectDetailsPage(page);
  const hasUserGuide = !!userGuide;

  /** Checkbox component is displayed only on Labeling Translation templates,
      and onSetAllChapterSectionsVisibility determines Checkbox component visibility 
  */
  const isLabelingTranslationTemplate =
    typeof onSetAllChapterSectionsVisibility === "function";

  const changeVisibilityForSection = getChangeVisibilityForSection(id);

  const { setNodeRef, transform, transition, listeners } = useSortable({ id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  const shouldAllowSorting = isChapterVisible && displayActions;

  const isSectionDisabled = useMemo(
    () =>
      getIsSectionDisabled({ isChapterVisible, displayActions }) &&
      showInteractiveElements,
    [isChapterVisible, displayActions]
  );
  const isSectionCheckboxDisabled = useMemo(
    () =>
      getIsSectionCheckboxDisabled({
        isSectionMandatory: mandatory,
        isChapterVisible,
        displayActions,
      }),
    [mandatory, isChapterVisible, displayActions]
  );
  const hasUserGuideCursor = useMemo(
    () =>
      getHasUserGuideCursor({
        hasUserGuide,
        isChapterVisible,
      }),
    [hasUserGuide, isChapterVisible]
  );
  const isUserGuideDisabled = useMemo(
    () =>
      getIsUserGuideDisabled({
        isChapterVisible,
        displayActions,
        hasUserGuide,
      }),
    [isChapterVisible, displayActions, hasUserGuide]
  );

  const {
    displayCheckbox,
    displayUserGuide,
    displayReorderButton,
  } = useMemo(() => {
    return {
      displayCheckbox: showInteractiveElements || isLabelingTranslationTemplate,
      displayUserGuide:
        !isLabelingTranslationTemplate &&
        (showInteractiveElements || hasUserGuide),
      displayReorderButton:
        !isLabelingTranslationTemplate && showInteractiveElements,
    };
  }, [showInteractiveElements, hasUserGuide, isLabelingTranslationTemplate]);

  useEffect(() => {
    setSectionVisibility(visible);
  }, [visible]);

  return (
    <>
      <div
        className={cx(
          styles.sectionWrapper,
          isSectionDisabled && styles.disabled,
          errors[id] && styles.hasError
        )}
        ref={setNodeRef}
        style={style}
      >
        <div>
          {displayCheckbox && (
            <Checkbox
              data-testid="chapterSectionCheckbox"
              className={styles.checkbox}
              disabled={
                isSectionCheckboxDisabled || isLoading || areUserGuidesUpdating
              }
              checked={!isLoading && isSectionVisibility}
              onChange={getOnChangeSectionVisibility({
                changeVisibilityForSection,
                setIsLoading,
              })}
            />
          )}
          <Tooltip text={name} placement="top">
            <Heading size="xxs" className={styles.text}>
              {name}
            </Heading>
          </Tooltip>
        </div>
        <div>
          {displayUserGuide && (
            <Button
              data-testid="commentIcon"
              iconName="comment"
              type="tertiary"
              onClick={getOnIconUserGuideClick({
                setCommentModal,
                initialComment: userGuide,
                sectionId: id,
                chapterType,
              })}
              disabled={
                isUserGuideDisabled ||
                !isSectionVisibility ||
                areUserGuidesUpdating ||
                isLoading
              }
              color={hasUserGuideCursor ? "primary" : "grey"}
            />
          )}
          {displayReorderButton && (
            <Button
              disabled={isSectionDisabled}
              data-testid="moveIcon"
              iconName="move"
              type="tertiary"
              color="grey"
              {...(shouldAllowSorting ? listeners : {})}
            />
          )}
        </div>
      </div>
      {errors[id] ? (
        <div className={styles.errorMessage}>
          {formatMessage(messages.errorFieldMessage)}
        </div>
      ) : null}
    </>
  );
};

export default SectionWrapper;
