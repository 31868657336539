import { useEffect } from "react";
import { useAppDispatch } from "store";
import {
  setFolderCollaboration,
  removeFolderCollaborations,
  getFolderCollaborations,
} from "store/folderCreation/asyncActions";
import { setIsInvitationStepValid } from "store/folderCreation/folderCreationSlice";

const useFolderCollaboration = () => {
  const dispatch = useAppDispatch();

  const handleCreateCollaboration = (collaborationId: string) => {
    // @ts-ignore
    dispatch(setFolderCollaboration(collaborationId));
  };

  const handleRemoveCollaborations = (collaborationIds: string[]) => {
    // @ts-ignore
    dispatch(removeFolderCollaborations(collaborationIds));
  };

  const handleChangeValidation = (isValid: boolean) => {
    // @ts-ignore
    dispatch(setIsInvitationStepValid(isValid));
  };

  useEffect(() => {
    dispatch(getFolderCollaborations());
  }, []);

  return {
    handleCreateCollaboration,
    handleRemoveCollaborations,
    handleChangeValidation,
  };
};

export default useFolderCollaboration;
