import { useEffect, useState } from "react";
import { CategoryItemData, MaterialIngredientData } from "models";
import { useCategoryItems } from "hooks";
import { isListEmpty } from "utils/general";
import { getHierarchyCategoryItem } from "utils/hierarchy";
import { prepareIngredientsFunctionIdsData } from "./utils";

const useCategoryDetails = ({
  ingredientData,
}: {
  ingredientData: MaterialIngredientData[];
}) => {
  const [categoryItemsIds, setCategoryItemsIds] = useState<string[]>([]);
  const [currentCategoryItems, setCurrentCategoryItems] = useState<
    CategoryItemData[]
  >([]);
  const { categoryItemsDictionary, categoryItems } = useCategoryItems({
    categoryItemsIds,
    shouldFilterIds: false,
  });

  const getCategoryItemsIds = () => {
    let currentCategoryItemsIds: string[] = [];

    if (!isListEmpty(ingredientData)) {
      currentCategoryItemsIds.push.apply(
        currentCategoryItemsIds,
        prepareIngredientsFunctionIdsData({ ingredientData })
      );
    }

    setCategoryItemsIds(currentCategoryItemsIds);
  };

  const getCategoryItem = (
    searchCategoryItemId: CategoryItemData["categoryItemId"]
  ) => {
    return getHierarchyCategoryItem(
      searchCategoryItemId,
      currentCategoryItems,
      categoryItemsDictionary
    );
  };

  useEffect(() => {
    setCurrentCategoryItems(previousState => [
      ...previousState,
      ...categoryItems,
    ]);
  }, [categoryItems]);

  useEffect(() => {
    getCategoryItemsIds();
  }, [ingredientData]);

  return {
    categoryItemsDictionary,
    getCategoryItem,
  };
};

export default useCategoryDetails;
