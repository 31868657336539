import { useLocation, useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Paragraph } from "@trace-one/design-system";
import Spin from "components/Spin";
import { useLibraryItemDetails } from "./hooks";
import { LIBRARY_OBJECTS_PAGES } from "utils/constants";
import { LocationState, ParamTypes } from "pages/Library/components/types";
import messages from "messages/library";

const withLibraryItemDetails = Component => props => {
  const { id, type } = useParams<ParamTypes>();
  const { state } = useLocation<LocationState>();
  const page = props?.location?.pathname?.includes("/edit")
    ? LIBRARY_OBJECTS_PAGES.EDIT
    : LIBRARY_OBJECTS_PAGES.DETAILS;

  const { libraryItemDetails, isLoading, hasError } = useLibraryItemDetails({
    id,
    type,
    shouldRefetch: state?.shouldRefetch,
    page,
  });

  if (isLoading) return <Spin />;

  if (hasError)
    return (
      <Paragraph color="red">
        <FormattedMessage {...messages.errorMessage} />
      </Paragraph>
    );

  return <Component {...props} libraryItemDetails={libraryItemDetails} />;
};

export default withLibraryItemDetails;
