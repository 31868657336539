import { defineMessages } from "react-intl";

export const messages = defineMessages({
  listingResultNumber: {
    id: "specificationListing.table.listingResultNumber",
    defaultMessage: "Displaying {current} out of {total} specifications",
  },

  creationDate: {
    id: "folderListing.table.creationDate",
    defaultMessage: "Creation date",
  },

  lastUpdate: {
    id: "folderListing.table.lastUpdate",
    defaultMessage: "Last update",
  },

  lifeTime: {
    id: "specificationListing.table.lifetime",
    defaultMessage: "Life Time",
  },

  oneDay: {
    id: "specificationListing.table.oneDay",
    defaultMessage: "1 day",
  },

  owner: {
    id: "folderListing.table.owner",
    defaultMessage: "Owner",
  },

  productName: {
    id: "specificationListing.table.productName",
    defaultMessage: "Product",
  },

  productType: {
    id: "folderListing.table.productType",
    defaultMessage: "Product type",
  },

  progression: {
    id: "specificationListing.table.progression",
    defaultMessage: "Progression (%)",
  },

  retailerName: {
    id: "specificationListing.table.retailerName",
    defaultMessage: "Retailer",
  },

  supplierName: {
    id: "specificationListing.table.supplierName",
    defaultMessage: "Supplier",
  },

  status: {
    id: "status",
    defaultMessage: "Status",
  },

  today: {
    id: "general.today",
    defaultMessage: "Today",
  },

  switchText: {
    id: "specificationListing.table.switchText",
    defaultMessage: "My Specifications only",
  },
});
