import { OptionProps, SelectValue } from "antd/lib/select";
import { ReferenceListItemData } from "models";
import { isListEmpty } from "./general";

export const getCurrentValueFromValue = value => {
  if (!isListEmpty(value)) {
    return value.map(getCurrentValueFromValue);
  }

  return typeof value === "object" && value !== null
    ? (value.value as string)
    : (value as string);
};

export const getNotSelectedOptions = ({
  data,
  selectedOptionIds,
  selectedValue,
  name,
  value,
}: {
  data: ReferenceListItemData[];
  selectedOptionIds: string[];
  selectedValue: SelectValue;
  name: string;
  value: string;
}): {
  name: string;
  value: string;
}[] => {
  return data.reduce((prevValue, currentValue) => {
    if (
      !selectedOptionIds.includes(currentValue[value]) ||
      getCurrentValueFromValue(selectedValue) === currentValue[value]
    ) {
      prevValue = [
        ...prevValue,
        {
          name: currentValue[name],
          value: currentValue[value],
        },
      ];
    }

    return prevValue;
  }, []);
};

export const filterOption = (input: string, option: OptionProps) => {
  const optionText = option?.children || option?.name || option?.value;

  return optionText.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

export const filterOptionWithLength = (inpuLength = 0) => (
  input: string,
  option: OptionProps
) => {
  if (input.length <= inpuLength) return true;

  return filterOption(input, option);
};
