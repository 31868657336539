import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectSectionSavingInProgress } from "store/specificationDetails/selectors";
import { useWidgetActions } from "components/SpecificationSection/components/FormSchema/hooks";
import {
  ArrayFieldTemplateProps,
  ArraySchema,
  FormContext,
} from "components/SpecificationSection/types";

const useArrayFieldTemplateProps = ({
  onAddClick,
  formContext,
  schema,
  items = [],
}: {
  formContext: FormContext;
  schema: ArraySchema;
  onAddClick: ArrayFieldTemplateProps["onAddClick"];
  items: Array<any>;
}) => {
  const {
    isWidgetEditable,
    sectionId,
    templateBlockId,
    isTypingInProgress,
    isSpecificationUpdating,
  } = formContext;
  const { propertyId, maxItems } = schema;

  const isSavingInProgress = useSelector(
    selectSectionSavingInProgress(sectionId)
  );

  const [isAddDelayed, setIsAddDelayed] = useState(false);

  const { getWidgetLastChanges } = useWidgetActions({
    templateBlockId,
    sectionId,
  });

  const hasRemove = isWidgetEditable(propertyId) || !isSavingInProgress;

  const isAddButtonDisabled =
    !isWidgetEditable(propertyId) ||
    isSavingInProgress ||
    isSpecificationUpdating ||
    (!!maxItems && items.length >= maxItems);

  const lastChanges = getWidgetLastChanges(propertyId);

  const addNewItem = () => {
    onAddClick();
    setIsAddDelayed(false);
  };

  const onAddBtnClick = () => {
    if (!isTypingInProgress) {
      addNewItem();
    } else {
      setIsAddDelayed(true);
    }
  };

  useEffect(() => {
    if (isAddDelayed && !isTypingInProgress && hasRemove) {
      addNewItem();
    }
  }, [isAddDelayed, isTypingInProgress, hasRemove]);

  return {
    hasRemove,
    lastChanges,
    onAddBtnClick,
    isAddButtonDisabled,
  };
};

export default useArrayFieldTemplateProps;
