import { useIntl } from "react-intl";
import { LIBRARY_OBJECT_TYPES } from "utils/constants";
import messages from "messages/library";
import { generateListPath, isFAndVOnly, showClaims } from "utils/general";
import useUserPermission from "../useUserPermission";

const useLibraryNavigation = () => {
  const { formatMessage } = useIntl();
  const permissions = useUserPermission();
  const {
    templatesCreate,
    requirementsCreate,
    materialsCreate,
    chaptersCreate,
    packagingSystemsWrite,
    claimsCreate,
  } = permissions;

  let tabList = [];

  if (requirementsCreate) {
    tabList.push({
      key: LIBRARY_OBJECT_TYPES.REQUIREMENTS,
      path: generateListPath(LIBRARY_OBJECT_TYPES.REQUIREMENTS),
      text: formatMessage(messages.requirements),
      title: formatMessage(messages.requirements),
    });
  }

  if (materialsCreate) {
    tabList.push({
      key: LIBRARY_OBJECT_TYPES.MATERIALS,
      path: generateListPath(LIBRARY_OBJECT_TYPES.MATERIALS),
      text: formatMessage(messages.materials),
      title: formatMessage(messages.materials),
    });
  }

  if (chaptersCreate) {
    tabList.push({
      key: LIBRARY_OBJECT_TYPES.CHAPTERS,
      path: generateListPath(LIBRARY_OBJECT_TYPES.CHAPTERS),
      text: formatMessage(messages.chapters),
      title: formatMessage(messages.chapters),
    });
  }

  if (claimsCreate && showClaims) {
    tabList.push({
      key: LIBRARY_OBJECT_TYPES.CLAIMS,
      path: generateListPath(LIBRARY_OBJECT_TYPES.CLAIMS),
      text: formatMessage(messages.claims),
      title: formatMessage(messages.claims),
    });
  }

  if (packagingSystemsWrite && !isFAndVOnly) {
    tabList.push({
      key: LIBRARY_OBJECT_TYPES.PACKAGING_SYSTEMS,
      path: generateListPath(LIBRARY_OBJECT_TYPES.PACKAGING_SYSTEMS),
      text: formatMessage(messages.packagings),
      title: formatMessage(messages.packagings),
    });
  }

  if (templatesCreate) {
    tabList.push({
      key: LIBRARY_OBJECT_TYPES.TEMPLATES,
      path: generateListPath(LIBRARY_OBJECT_TYPES.TEMPLATES),
      text: formatMessage(messages.templates),
      title: formatMessage(messages.templates),
    });
  }

  return tabList;
};

export default useLibraryNavigation;
