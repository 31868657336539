import { useState } from "react";
import { SpecificationData, TradeItemSupplierData } from "models";
import { duplicateSpecification } from "apis/SPEC";

const useSelectSupplierModal = ({
  closeSelectSupplierModal,
  specificationId,
}: {
  closeSelectSupplierModal: Function;
  specificationId: SpecificationData["id"];
}) => {
  const [isDuplicationInProgress, setIsDuplicationInProgress] = useState(false);
  const [isConfirmedDuplicationStep, setIsConfirmedDuplicationStep] = useState(
    false
  );
  const [supplierListWithSuccess, setSupplierListWithSuccess] = useState<
    TradeItemSupplierData["supplierName"][]
  >([]);
  const [supplierListWithError, setSupplierListWithError] = useState<
    TradeItemSupplierData["supplierName"][]
  >([]);

  const onModalClose = e => {
    if (!isDuplicationInProgress) {
      return closeSelectSupplierModal(e);
    }
    return;
  };

  const onDuplicateSpecification = (
    selectedSuppliers: TradeItemSupplierData[]
  ) => async () => {
    let temporarySupplierListWithError: TradeItemSupplierData["supplierName"][] = [];
    let temporarySupplierListWithSuccess: TradeItemSupplierData["supplierName"][] = [];

    setIsDuplicationInProgress(true);

    for (let { supplierId, supplierName } of selectedSuppliers) {
      try {
        await duplicateSpecification({
          specificationId,
          supplierId,
        });
        temporarySupplierListWithSuccess.push(supplierName);
      } catch (e) {
        temporarySupplierListWithError.push(supplierName);
      }
    }

    setIsConfirmedDuplicationStep(true);
    setIsDuplicationInProgress(false);
    setSupplierListWithSuccess(temporarySupplierListWithSuccess);
    setSupplierListWithError(temporarySupplierListWithError);
  };

  return {
    isDuplicationInProgress,
    isConfirmedDuplicationStep,
    supplierListWithSuccess,
    supplierListWithError,
    onModalClose,
    onDuplicateSpecification,
  };
};

export default useSelectSupplierModal;
