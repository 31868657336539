import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";

/* istanbul ignore next */
export const _selectRequirementForm = (state: RootState) =>
  state.requirementForm;

export const selectRequirementInformation = createSelector(
  _selectRequirementForm,
  requirementForm => requirementForm.requirementInformation
);

export const selectRequirementType = createSelector(
  _selectRequirementForm,
  requirementForm => requirementForm.type
);

export const selectCreationStatus = createSelector(
  _selectRequirementForm,
  requirementForm => requirementForm.creationStatus
);

export const selectUpdateStatus = createSelector(
  _selectRequirementForm,
  requirementForm => requirementForm.updateStatus
);

export const selectKeepAsDraftStatus = createSelector(
  _selectRequirementForm,
  requirementForm => requirementForm.keepAsDraftStatus
);
