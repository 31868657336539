import { useEffect, useRef, useState } from "react";

const useScroll = (headerHeight = 80) => {
  const [isSticky, setIsSticky] = useState(false);
  const [isStickToBottom, setIsStickToBottom] = useState(false);
  const contentRef = useRef(null);
  const ref = useRef(null);

  const handleScroll = event => {
    if (contentRef.current && ref.current) {
      const target = event.target.scrollingElement;

      const remainingDistanceToTheBottom =
        target.scrollHeight - target.scrollTop - target.clientHeight;

      const isMenuHigherThanWindowHeight =
        ref.current.clientHeight > target.clientHeight - headerHeight;

      const isSticky =
        contentRef.current.getBoundingClientRect().top < headerHeight &&
        contentRef.current.getBoundingClientRect().height > 600;

      setIsSticky(isSticky);

      setIsStickToBottom(
        remainingDistanceToTheBottom < 50 && isMenuHigherThanWindowHeight
      );
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return {
    isSticky,
    isStickToBottom,
    contentRef,
    ref,
  };
};

export default useScroll;
