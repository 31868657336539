import {
  isLibraryObjectClaimType,
  isLibraryObjectMaterialType,
  isLibraryObjectRequirement,
  isLibraryObjectTemplatesType,
} from "utils/library";
import {
  LIBRARY_OBJECTS_PAGES,
  LIBRARY_OBJECT_API_TYPES,
} from "utils/constants";
import Requirement from "../Requirement";
import Material from "../Material";
import PackagingSystem from "../PackagingSystem";
import Chapter from "../Chapter";
import Template from "../Template";
import Claim from "../Claim";
import { ContentProps } from "./types";

const Content: React.FC<ContentProps> = ({
  libraryItemDetails,
  updateActionsInformation,
  updateTabsInformation,
  updateHeaderInformation,
  updateSecondaryActionInformation,
}) => {
  if (isLibraryObjectMaterialType(libraryItemDetails.materialType)) {
    return (
      <Material
        libraryItemDetails={libraryItemDetails}
        updateActionsInformation={updateActionsInformation}
      />
    );
  }

  if (isLibraryObjectRequirement(libraryItemDetails.generalInformation.type)) {
    return (
      <Requirement
        libraryItemDetails={libraryItemDetails}
        updateActionsInformation={updateActionsInformation}
      />
    );
  }

  if (
    libraryItemDetails.generalInformation.type ===
    LIBRARY_OBJECT_API_TYPES["packaging-systems"]
  ) {
    return (
      <PackagingSystem
        initialPackagingSystemData={libraryItemDetails}
        updateTabsInformation={updateTabsInformation}
        updateActionsInformation={updateActionsInformation}
      />
    );
  }

  if (
    isLibraryObjectTemplatesType(libraryItemDetails?.generalInformation?.type)
  ) {
    return (
      <Template
        libraryItemDetails={libraryItemDetails}
        page={LIBRARY_OBJECTS_PAGES.EDIT}
        updateActionsInformation={updateActionsInformation}
      />
    );
  }

  if (isLibraryObjectClaimType(libraryItemDetails?.generalInformation?.type)) {
    return (
      <Claim
        libraryItemDetails={libraryItemDetails}
        page={LIBRARY_OBJECTS_PAGES.EDIT}
        updateActionsInformation={updateActionsInformation}
        updateSecondaryActionInformation={updateSecondaryActionInformation}
      />
    );
  }

  return (
    <Chapter
      initialChapterData={libraryItemDetails}
      updateActionsInformation={updateActionsInformation}
      updateTabsInformation={updateTabsInformation}
      updateHeaderInformation={updateHeaderInformation}
    />
  );
};

export default Content;
