import { useSelector } from "react-redux";
import StickyBar from "components/StickyBar";
import { useRbac } from "hooks";
import { selectSpecificationPermissions } from "store/specificationDetails/selectors";
import { PERMISSIONS } from "utils/constants";
import RevisionActions from "./components/RevisionActions";
import { isStateInRevisionBySupplier } from "utils/specifications";
import { SpecificationStickyBarRetailerProps } from "./types";

const SpecificationStickyBarRetailer: React.FC<SpecificationStickyBarRetailerProps> = ({
  id,
  state,
  history,
}) => {
  const permissions = useSelector(selectSpecificationPermissions);
  const { hasPermission } = useRbac(permissions);
  const hasAcceptRevisionPermission = hasPermission(
    PERMISSIONS.SPECIFICATION.ACCEPT_REVISION
  );

  return (
    <>
      {isStateInRevisionBySupplier(state) && hasAcceptRevisionPermission && (
        <StickyBar>
          <RevisionActions id={id} history={history} />
        </StickyBar>
      )}
    </>
  );
};

export default SpecificationStickyBarRetailer;
