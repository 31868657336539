import { useContext, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Toggle, Datatable } from "@trace-one/design-system";
import { Col } from "antd";
import { fetchSpecificationHistory } from "apis/SPEC";
import TableHeader from "components/TableHeader";
import { selectCompanyActivity } from "store/oidc/selectors";
import { selectLanguageCode } from "store/user/selectors";
import Quickview, { QuickViewType } from "components/Quickview";
import { quickViewMessages } from "components/Quickview/messages";
import { isStateDraft } from "utils/specifications";
import {
  getNumberPageDisplayed,
  getRowKeyForTable,
  shouldPaginationBeDisplay,
} from "utils/table";
import { useRowSelection } from "hooks";
import { usePermissionsRowClick, usePollingSpecifications } from "./hooks";
import { ResultTableContext } from "contexts";
import { getProductOrigin } from "pages/Specification/components/List/utils";
import { getColumns, refetchSpecificationListing } from "./utils";
import { ROUTES } from "utils/constants";
import { messages } from "./messages";
import { ResultTableProps } from "./types";
import { SpecificationQuickViewData } from "models";
import { SpecificationListItemViemModel } from "viewModels/specificationList";
import styles from "./styles.module.less";

const ResultTable: React.FC<ResultTableProps> = ({
  specifications,
  totalNumberOfSpecifications,
  pagination,
  onTableChange,
  isLoading,
  mySpecificationsOnly,
  onUpdateFilters,
}) => {
  const { pathname } = useLocation();

  const intl = useIntl();
  const productOrigin = getProductOrigin({
    companyActivity: useSelector(selectCompanyActivity),
  });

  const {
    setShouldRefetchSpecifications,
    resetCurrentPage,
    currentPage,
  } = useContext(ResultTableContext);

  const { selectedRowKeys, onSelectRow, onSelectAllRows } = useRowSelection();

  const rowKey = useMemo(() => getRowKeyForTable("specification"), []);

  const { isPollingInProgress } = usePollingSpecifications();
  const { onCellClick, setSpecificationPermissions } = usePermissionsRowClick();

  const languageCode = useSelector(selectLanguageCode);

  const current = useMemo(
    () =>
      getNumberPageDisplayed({
        totalNumber: totalNumberOfSpecifications,
        current: pagination.current,
        pageSize: pagination.pageSize,
      }),
    [totalNumberOfSpecifications, pagination.current, pagination.pageSize]
  );

  const rowSelection = {
    selectedRowKeys,
    onSelect: onSelectRow,
    onSelectAll: onSelectAllRows,
  };

  const onMySpecificationsOnlyChange = (value: boolean) => {
    onUpdateFilters({
      mySpecificationsOnly: value,
    });
  };

  // temporary check to disable sorting and hide action column for Translation listing
  const isTranslationListing = pathname?.includes(ROUTES.TRANSLATION_LISTING);

  return (
    <>
      <TableHeader
        listResult={intl.formatMessage(messages.listingResultNumber, {
          current,
          total: totalNumberOfSpecifications,
        })}
        tableActions={isTranslationListing ? null : []}
        {...(typeof onUpdateFilters === "function" && {
          extraComponent: (
            <Col>
              <Toggle
                data-test-id="specification-list-my-specification-only"
                checked={mySpecificationsOnly}
                onChange={onMySpecificationsOnlyChange}
                text={<b>{intl.formatMessage(messages.switchText)}</b>}
              />
            </Col>
          ),
        })}
      />
      <Datatable
        loading={isLoading || isPollingInProgress}
        rowClassName={styles.row}
        onChange={onTableChange}
        pagination={{
          ...pagination,
          total: totalNumberOfSpecifications,
          hideOnSinglePage: !shouldPaginationBeDisplay(
            totalNumberOfSpecifications
          ),
        }}
        rowSelection={isTranslationListing ? null : rowSelection}
        {...(!isTranslationListing && {
          expandable: {
            expandedRowRender: (record: SpecificationListItemViemModel) => (
              <Quickview.Specification
                type={QuickViewType.SPEC}
                fetcher={() =>
                  fetchSpecificationHistory({
                    id: record.specificationId,
                    languageCode,
                  })
                }
                header={totalItem => {
                  return (
                    <FormattedMessage
                      {...quickViewMessages.specQuickviewHeader}
                      values={{
                        historyNumber: totalItem,
                      }}
                    />
                  );
                }}
                body={(items: SpecificationQuickViewData[]) => {
                  return items.map(specification => (
                    <Quickview.SpecificationRow
                      type={QuickViewType.SPEC}
                      key={specification.specificationId}
                      specification={specification}
                      title={
                        <Quickview.SpecificationTitle
                          specification={specification}
                        />
                      }
                    />
                  ));
                }}
              />
            ),
            rowExpandable: ({ state }) => !isStateDraft(state),
          },
        })}
        columns={getColumns({
          formatMessage: intl.formatMessage,
          onCellClick: isTranslationListing ? null : onCellClick,
          productOrigin,
          setSpecificationPermissions,
          reloadListing: refetchSpecificationListing({
            currentPage,
            setShouldRefetchSpecifications,
            resetCurrentPage,
          }),
          isTranslationListing,
        })}
        dataSource={specifications}
        rowKey={rowKey}
      />
    </>
  );
};

export default ResultTable;
