import { Tabs, Picker, Icon } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import { Form } from "antd";
import ClaimLogo from "../ClaimLogo";
import Spin from "components/Spin";
import { useClaimSentence } from "./hooks";
import { claimMessages } from "messages/claim";
import styles from "./styles.module.less";
import { useMemo } from "react";

const ClaimConfigureContent = () => {
  const { formatMessage } = useIntl();

  const {
    tabItems,
    isLoading,
    languages,
    selectedLanguages,
    onTabChange,
    onLanguageChange,
    onEdit,
    activeKey,
    userPreferredLanguage,
  } = useClaimSentence();

  const sortedLanguages = useMemo(() => {
    const selected = [];
    const unselected = [];

    languages?.forEach(language => {
      const languageData = {
        ...language,
        value: language?.languageCode,
        label: language?.label,
      };

      if (language?.languageCode === userPreferredLanguage?.languageCode) {
        selected.unshift(languageData);
      } else if (selectedLanguages?.includes(language.languageCode)) {
        selected.push(languageData);
      } else {
        unselected.push(languageData);
      }
    });

    return [...selected, ...unselected];
  }, [languages, selectedLanguages]);

  if (isLoading) return <Spin />;

  const languageDropdown = (
    <Picker.Filter
      title={formatMessage(claimMessages.addTranslation)}
      items={sortedLanguages}
      onChange={onLanguageChange}
      value={selectedLanguages}
      showBadgeCount={false}
      className={styles["picker-as-link"]}
      hideFooter
      afterIcon={<Icon name="more" size="small" color="primary" />}
    />
  );

  return (
    <>
      <Form.Item
        required
        label={formatMessage(claimMessages.claimSentenceLabel)}
      >
        <Tabs.Mini
          items={tabItems}
          tabBarExtraContent={languageDropdown}
          hideAdd
          onChange={onTabChange}
          activeKey={activeKey}
          type="editable-card"
          onEdit={onEdit}
          className={styles["translation-tabs"]}
        />
      </Form.Item>
      <ClaimLogo />
    </>
  );
};

export default ClaimConfigureContent;
