import { FormattedMessage, useIntl } from "react-intl";
import { Modal, Paragraph } from "@trace-one/design-system";
import generalMessages from "messages/general";
import { messages } from "../../messages";
import { UnlinkChapterModalProps } from "./types";

const UnlinkChapterModal: React.FC<UnlinkChapterModalProps> = ({
  onConfirmModal,
  onCloseModal,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Modal.Simple
      visible
      size="m"
      illustrationName="broken-link"
      illustrationColor="grey"
      keyboard={false}
      onCancel={onCloseModal}
      onPrimaryButtonClick={onConfirmModal}
      primaryButtonText={formatMessage(
        messages.unlinkChapterModalActionConfirm
      )}
      onSecondaryButtonClick={onCloseModal}
      secondaryButtonText={formatMessage(generalMessages.cancel)}
      title={formatMessage(messages.unlinkChapterModalTitle)}
    >
      <Paragraph>
        <FormattedMessage {...messages.unlinkChapterModalDescription} />
      </Paragraph>
    </Modal.Simple>
  );
};

export default UnlinkChapterModal;
