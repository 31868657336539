import { useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Stepper } from "@trace-one/design-system";
import { Title } from "@trace-one/react-components";
import CreationWizzard from "components/CreationWizzard";
import Debounced from "components/Debounced";
import {
  fetchFolderTemplate,
  setFolderSpecificationNote,
  setFolderTemplate,
  updateFolderFiles,
} from "store/folderCreation/asyncActions";
import {
  resetFolderCreationErrors,
  setFolderChapters,
} from "store/folderCreation/folderCreationSlice";
import {
  selectErrors,
  selectProducts,
  selectProductSpecifications,
  selectFolderDetails,
  selectWizard,
  selectFolderTemplate,
} from "store/folderCreation/selectors";
import { selectLanguageCode } from "store/user/selectors";
import {
  useSteps,
  useCreationActions,
} from "pages/Folder/components/Creation/hooks";
import {
  getOnSpecificationNoteChange,
  getUniqueNumberOfSuppliers,
} from "./utils";
import { messages } from "./messages";
import Requirements from "./components/Requirements";
import documentMessages from "messages/documents";
import { LibraryObjectData } from "models";
import { useSpecificationChapters } from "hooks/useSpecificationChapters";
import { useAppDispatch } from "store";
import { isListEmpty } from "utils/general";
import { Col, Row } from "antd";
import SpecificationTemplate from "components/SpecificationTemplate";

const Specifications = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { getOnFolderDelete } = useCreationActions();
  const products = useSelector(selectProducts);
  const productSpecifications = useSelector(selectProductSpecifications);
  const {
    folderId,
    specificationNote,
    fileAssociations,
    ownerCompanyId,
    productTypeId,
  } = useSelector(selectFolderDetails);
  const wizard = useSelector(selectWizard);

  const {
    stepIndex,
    currentIsValid,
    steps,
    onPreviousStep,
    onNextStep,
  } = useSteps({
    currentStep: wizard.currentStep,
    stepsState: wizard.state,
  });

  const languageCode = useSelector(selectLanguageCode);

  const template = useSelector(selectFolderTemplate);

  const {
    sections: chaptersErrors,
    specificationNote: hasSpecificationNoteError,
  } = useSelector(selectErrors);

  const { specChapters } = useSpecificationChapters({
    chapters: template?.chapters,
    productTypeId,
  });

  const numberOfSuppliers = useMemo(
    () =>
      getUniqueNumberOfSuppliers({
        folderId,
        productSpecifications,
      }),
    [productSpecifications]
  );

  useEffect(() => {
    if (!isListEmpty(specChapters)) {
      dispatch(setFolderChapters(specChapters));
    }
  }, [dispatch, specChapters]);

  useEffect(() => {
    dispatch(fetchFolderTemplate());
  }, [dispatch, languageCode]);

  useEffect(() => {
    dispatch(resetFolderCreationErrors());
  }, [dispatch]);

  const onTemplateSelect = async (selectedTemplate: LibraryObjectData) => {
    await dispatch(setFolderTemplate(selectedTemplate.id));
    await dispatch(fetchFolderTemplate());
  };

  return (
    <div>
      <CreationWizzard.Title.Folder />
      <Stepper
        stepIndex={stepIndex}
        currentIsValid={currentIsValid}
        steps={steps}
      />

      <Row gutter={[0, 48]}>
        <Col span={24}>
          <SpecificationTemplate
            template={template}
            onTemplateSelect={onTemplateSelect}
            chaptersErrors={chaptersErrors}
            numberOfProducts={products?.length}
            numberOfSuppliers={numberOfSuppliers}
            productTypeId={productTypeId}
          />
        </Col>
        <Col span={24}>
          <Title
            level={2}
            value={intl.formatMessage(messages.requirementsTitle)}
            subtitle={intl.formatMessage(messages.requirementsSubtitle)}
          />
          <Requirements />
        </Col>
      </Row>

      <Title
        level={2}
        value={intl.formatMessage(documentMessages.documents)}
        subtitle={intl.formatMessage(documentMessages.creationDocumentSubtitle)}
      />
      <CreationWizzard.Files
        files={fileAssociations}
        chapters={specChapters}
        onUpdate={updateFolderFiles}
        ownerCompanyId={ownerCompanyId}
      />

      <Title
        level={2}
        value={intl.formatMessage(messages.note)}
        subtitle={intl.formatMessage(messages.noteDescription)}
      />

      <Debounced.Textarea
        defaultValue={specificationNote}
        onChange={getOnSpecificationNoteChange({
          dispatch,
          setFolderSpecificationNote,
        })}
        {...(hasSpecificationNoteError && {
          errorMessage: intl.formatMessage(messages.errorFieldMessage),
        })}
      />

      <CreationWizzard.Footer
        onBackClick={onPreviousStep}
        onNextClick={onNextStep}
        onCancel={getOnFolderDelete()}
        isNextDisabled={isListEmpty(template?.chapters)}
      />
    </div>
  );
};

export default Specifications;
