import ErrorsList from "components/ErrorsList";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { getErrorList } from "./utils";
import { SectionErrorsListProps } from "./types";

const SectionErrorsList: React.FC<SectionErrorsListProps> = ({ errors }) => {
  const { getTranslationForKey } = useFormSchemaTranslation();

  return <ErrorsList list={getErrorList(errors, getTranslationForKey)} />;
};

export default SectionErrorsList;
