import classNames from "classnames";
import { NutriScoreGrade } from "types/nutrition";
import {
  isNutriScoreA,
  isNutriScoreB,
  isNutriScoreC,
  isNutriScoreD,
  isNutriScoreE,
} from "utils/nutrition";
import styles from "./styles.module.less";

type NutriScoreIndividualIndicatorProps = {
  nutriScore: NutriScoreGrade;
};

const NutriScoreIndividualIndicator: React.FC<NutriScoreIndividualIndicatorProps> = ({
  nutriScore,
}) => {
  if (!nutriScore) {
    return null;
  }

  const dataTestId = `nutri-score-individual-indicator-${nutriScore.toLowerCase()}`;

  return (
    <span
      className={classNames(styles["nutri-score"], {
        [styles["nutri-score-a"]]: isNutriScoreA(nutriScore),
        [styles["nutri-score-b"]]: isNutriScoreB(nutriScore),
        [styles["nutri-score-c"]]: isNutriScoreC(nutriScore),
        [styles["nutri-score-d"]]: isNutriScoreD(nutriScore),
        [styles["nutri-score-e"]]: isNutriScoreE(nutriScore),
      })}
      data-test-id={dataTestId}
      date-testid={dataTestId}
    >
      {nutriScore}
    </span>
  );
};

export default NutriScoreIndividualIndicator;
