import { useMemo } from "react";
import classnames from "classnames";
import { ErrorPage, PageHeading } from "@trace-one/react-components";
import { Container } from "@trace-one/design-system";
import { ErrorBoundary } from "react-error-boundary";
import { LayoutContext } from "contexts";
import Header from "layouts/Header";
import ErrorPageFallback from "components/ErrorPageFallback";
import {
  useLegalMonster,
  usePageHeading,
  useScrollToTopOnRouteChange,
  useErrorPage,
} from "./hooks";
import { MainLayoutProps } from "./types";
import styles from "./styles.module.less";

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  useLegalMonster();
  useScrollToTopOnRouteChange();

  const {
    pageHeadingInfo: {
      title,
      subtitle,
      illustration,
      tabs,
      currentTab,
      onTabClick,
    },
    updatePageHeadingInfo,
  } = usePageHeading();

  const {
    isAppAllowed,
    helpCenterUrl,
    onNotAllowedButtonPressed,
  } = useErrorPage();

  const hasPageHeading = useMemo(() => !!title, [title]);

  if (!isAppAllowed) {
    return (
      <ErrorPage
        hasCustomClickAction
        status={403}
        onButtonPress={onNotAllowedButtonPressed}
        secondaryButton={{
          path: helpCenterUrl,
          isVisible: true,
        }}
      />
    );
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorPageFallback}>
      <Header />
      {hasPageHeading && (
        <div className={styles.pageHeadingContainer}>
          <PageHeading.List
            app="spec"
            title={title}
            subtitle={subtitle}
            illustration={illustration}
            tabs={tabs}
            currentTab={currentTab}
            onTabClick={onTabClick}
          />
        </div>
      )}
      <LayoutContext.Provider
        value={{
          updatePageHeadingInfo,
        }}
      >
        <div
          className={classnames({
            [styles.container]: hasPageHeading,
          })}
        >
          <Container>{children}</Container>
        </div>
      </LayoutContext.Provider>
    </ErrorBoundary>
  );
};

export default MainLayout;
