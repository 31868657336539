import React from "react";
import { Row, Col } from "antd";
import { Paragraph } from "@trace-one/design-system";
import AcceptsAlternativeOrigin from "./components/AcceptsAlternativeOrigin";
import {
  getRefListDictionaryValue,
  isListEmpty,
  isObjectEmpty,
} from "utils/general";
import { Dictionary } from "types/general";
import styles from "./styles.module.less";

const OriginsReadOnly = ({
  originsContent,
  refListDictionary,
  emptyValue,
  isMainOrigin,
  acceptsAlternativeOriginContent,
}: {
  originsContent: React.ReactElement;
  refListDictionary: Dictionary<string>;
  emptyValue: string;
  isMainOrigin: boolean;
  acceptsAlternativeOriginContent?: React.ReactElement;
}) => {
  const originFields = [
    "originTypeId",
    "countryId",
    "regionId",
    "zoneOrDepartmentId",
  ];

  const { formData, uiSchema } = originsContent?.props;

  return (
    <>
      {acceptsAlternativeOriginContent ? (
        <Col span={24}>
          <AcceptsAlternativeOrigin
            acceptsAlternativeOriginContent={acceptsAlternativeOriginContent}
          />
        </Col>
      ) : null}

      {isMainOrigin && !isListEmpty(formData) ? (
        <Col xs={24} className={styles.headingReadOnly}>
          <Row>
            <Col xs={4}>
              <Paragraph size="xs" color="grey-5">
                {uiSchema?.["ui:title"]}
              </Paragraph>
            </Col>
          </Row>
        </Col>
      ) : null}

      {!isListEmpty(formData) && (
        <Col span={24} className={styles.originsReadOnly}>
          {formData.map(origin => (
            <Row
              key={`${origin[originFields[1]]}`}
              className={styles.originsRow}
              gutter={[0, 16]}
            >
              {originFields.map(field =>
                !isObjectEmpty(uiSchema.items[field]) ? (
                  <React.Fragment key={uiSchema.items[field]?.["ui:title"]}>
                    <Col xs={6}>
                      <Paragraph size="xs" color="grey-5">
                        {uiSchema.items[field]?.["ui:title"]}
                      </Paragraph>
                    </Col>
                    <Col xs={6}>
                      <Paragraph size="xs" color="grey-4">
                        {!isObjectEmpty(origin) && origin[field]
                          ? getRefListDictionaryValue({
                              id: origin[field],
                              refListDictionary,
                              emptyValue,
                            })
                          : emptyValue}
                      </Paragraph>
                    </Col>
                  </React.Fragment>
                ) : null
              )}
            </Row>
          ))}
        </Col>
      )}
    </>
  );
};

export default OriginsReadOnly;
