import { useRef } from "react";
import Suppliers from "../Suppliers";
import Attachment from "../Attachment";
import Characteristics from "../Characteristics";
import ControlPlans from "../ControlPlans";
import Allergens from "../Allergens";
import SubstanceInformation from "./components/SubstanceInformation";
import { useMaterialDetails } from "../../hooks";
import { isLibraryObjectLocked } from "utils/library";
import { ConfigureProps } from "../Configure/types";

export interface SubstanceDetailsProps extends ConfigureProps {
  displayActions?: boolean;
}

const SubstanceDetails: React.FC<SubstanceDetailsProps> = ({
  displayActions = true,
  materialId,
  allergens,
  suppliers,
  state,
  fileIds,
  materialType,
  substanceId,
}) => {
  const allergensRef = useRef<HTMLDivElement | null>(null);

  const { refListDictionary, catalogDictionary } = useMaterialDetails({
    allergens,
    suppliers,
  });

  return (
    <>
      <SubstanceInformation
        displayActions={displayActions}
        materialType={materialType}
        substanceId={substanceId}
      />
      <Suppliers
        refListDictionary={refListDictionary}
        displayActions={displayActions}
        suppliers={suppliers}
        materialId={materialId}
        materialType={materialType}
      />
      <div ref={allergensRef}>
        <Allergens
          allergenDictionary={catalogDictionary}
          displayActions={displayActions}
          selectedAllergens={allergens}
        />
      </div>
      <Attachment
        fileIds={fileIds}
        displayActions={displayActions && !isLibraryObjectLocked(state)}
      />
      <Characteristics
        displayActions={displayActions}
        materialId={materialId}
        materialType={materialType}
        refListDictionary={refListDictionary}
      />
      <ControlPlans
        displayActions={displayActions}
        materialId={materialId}
        materialType={materialType}
        refListDictionary={refListDictionary}
      />
    </>
  );
};

export default SubstanceDetails;
