import { useEffect, useState } from "react";
import axios from "axios";
import { searchCompanies } from "apis/CUMD";
import { APPLICATION_TYPE_IDS } from "utils/constants";
import { SpecificationData, TradeItemSupplierData } from "models";
import { fetchTradeItemSpecifications } from "apis/SPEC";

const useTradeItemSuppliers = ({
  tradeItemId,
  owningCompanyId,
}: {
  tradeItemId?: SpecificationData["tradeItemId"];
  owningCompanyId?: string;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [supplierName, setSupplierName] = useState("");
  const [supplierList, setSupplierList] = useState<TradeItemSupplierData[]>([]);
  const [source, setSource] = useState("");

  const getCompanies = async () => {
    let companies;
    try {
      if (source) {
        //@ts-ignore
        source.cancel();
      }
      const newSource = axios.CancelToken.source();
      //@ts-ignore
      setSource(newSource);

      const { data } = await searchCompanies({
        data: {
          applicationTypeIds: [APPLICATION_TYPE_IDS.SPEC],
        },
        params: {
          ...{
            withoutSpecificationsOnly: true,
          },
          isEnabled: true,
          companyDisplayName: supplierName,
          skip: 0,
          take: 1000,
        },
        cancelToken: newSource.token,
      });
      companies = data.companies;
    } catch (error) {
    } finally {
      return companies;
    }
  };

  const getProductSpecifications = async () => {
    let productSpecifications = [];

    try {
      const {
        data: existingSpecifications,
      } = await fetchTradeItemSpecifications({
        tradeItemId,
        ownerCompanyId: owningCompanyId,
      });

      productSpecifications = existingSpecifications;
    } catch (error) {
    } finally {
      return productSpecifications;
    }
  };

  const fetchSuppliers = async () => {
    try {
      setIsLoading(true);
      setHasError(false);

      await Promise.all([getCompanies(), getProductSpecifications()]).then(
        result => {
          const [companies, productSpecifications] = result;
          const suppliers = companies?.reduce((acc, curr) => {
            if (
              !productSpecifications?.some(
                specification => specification.supplierId === curr.companyId
              )
            ) {
              acc.push({
                supplierId: curr.companyId,
                supplierName: curr.companyDisplayName,
              });
            }

            return acc;
          }, []);

          setSupplierList(suppliers);
        }
      );
    } catch (e) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (value: string) => {
    setSupplierName(value);
  };

  useEffect(() => {
    if (tradeItemId) {
      fetchSuppliers();
    }
  }, [tradeItemId, supplierName]);

  return {
    supplierList,
    isLoading,
    hasError,
    handleSearch,
  };
};

export default useTradeItemSuppliers;
