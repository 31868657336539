import { LIBRARY_REQUIREMENT_TYPES } from "utils/constants";
import { State } from "./types";

const initialState: State = {
  requirementInformation: {
    name: null,
    tagIds: [],
  },
  type: LIBRARY_REQUIREMENT_TYPES.TEXT,
  creationStatus: {
    inProgress: false,
    isDone: false,
    hasError: false,
  },
  updateStatus: {
    inProgress: false,
    isDone: false,
    hasError: false,
  },
  publishStatus: {
    inProgress: false,
    isDone: false,
    hasError: false,
  },
  keepAsDraftStatus: {
    inProgress: false,
    isDone: false,
    hasError: false,
  },
  deleteStatus: {
    inProgress: false,
    isDone: false,
    hasError: false,
  },
};

export default initialState;
