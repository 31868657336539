import { Header as TOHeader } from "@trace-one/business-components";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { useUserPermission } from "hooks";
import { APPLICATION_TYPE_IDS } from "utils/constants";
import { ROUTES, HELP_CENTER_URLS } from "utils/constants";
import { selectUserId } from "store/user/selectors";
import { selectAccessToken } from "store/oidc/selectors";
import { useNavigation, useButton } from "./hooks";
import navMenuMessages from "messages/navMenu";
import { messages } from "./messages";

const Header = () => {
  const intl = useIntl();
  const routes = useNavigation();

  const { hasAccessToAdministration } = useUserPermission();

  const { logout } = useReactOidc();

  const { buttonProps } = useButton();

  const userId = useSelector(selectUserId);
  const accessToken = useSelector(selectAccessToken);

  return (
    <>
      <div id="notificationsRoot" />
      <TOHeader
        appLogo="spec"
        appName={intl.formatMessage(messages.appName)}
        homeUrl="/home"
        helpLinks={HELP_CENTER_URLS}
        routes={routes}
        userId={userId}
        onLogout={logout}
        appSwitcherProps={{
          accessToken,
          excludeApps: [APPLICATION_TYPE_IDS.SPEC],
        }}
        buttonProps={buttonProps}
        {...(hasAccessToAdministration && {
          displayAdminUserAndAccessRoute: true,
          adminSubRoutes: [
            {
              text: intl.formatMessage(navMenuMessages.tags),
              path: ROUTES.ADMINISTRATION.TAGS,
            },
          ],
          adminUserAndAccessUrl: window.env.UI_ADMIN_USERS_LIST_URL,
        })}
      />
    </>
  );
};

export default Header;
