import { TooltipProps } from "antd";
import { timeMessages } from "messages/time";
import {
  MODIFICATION_REQUEST_STATE,
  MODIFICATION_REQUEST_STATE_FILTER,
  PANEL_DATA_HEADER_ID,
  STICKY_POSITION_OFFSET,
} from "./constants";
import { isListEmpty } from "./general";
import { isChapterOwnedByCurrentUserCompany } from "./specifications";
import { ModificationRequestViewModel } from "viewModels/modificationRequest";
import {
  ModificationRequestState,
  ModificationRequestStateFilter,
} from "types/modificationRequest";
import { generalMessages, modificationRequestMessages } from "messages";

export const getTimeSince = ({
  elapsedTime,
  formatMessage,
  languageCode = "en-US",
}: {
  elapsedTime: number;
  formatMessage: Function;
  languageCode?: string;
}) => {
  const timeUnit = getTimeUnit(elapsedTime, formatMessage);

  if (timeUnit === formatMessage(timeMessages.justNow)) {
    return timeUnit;
  }

  const timeString = `${getTimeValue(elapsedTime)} ${timeUnit}`;

  if (languageCode === "en-US") {
    return `${timeString} ${formatMessage(timeMessages.ago)}`;
  } else if (languageCode === "fr-FR") {
    return `${formatMessage(timeMessages.ago)} ${timeString}`;
  }
};

export const getTimeUnit = (
  timeValue: number,
  formatMessage: Function
): string => {
  let unit: string = null;

  if (timeValue < 60 * 1000) {
    unit = formatMessage(timeMessages.justNow);
  } else if (timeValue < 60 * 60 * 1000) {
    unit = formatMessage(timeMessages.minuteAbbreviation);
  } else if (timeValue < 24 * 60 * 60 * 1000) {
    unit = formatMessage(timeMessages.hourAbbreviation);
  } else {
    unit = formatMessage(timeMessages.dayAbbreviation);
  }

  return unit;
};

export const getTimeValue = (timeValue: number): number => {
  let value: number = null;

  if (timeValue < 60 * 1000) {
    return value;
  } else if (timeValue < 60 * 60 * 1000) {
    value = Math.floor(timeValue / (60 * 1000));
  } else if (timeValue < 24 * 60 * 60 * 1000) {
    value = Math.floor(timeValue / (60 * 60 * 1000));
  } else {
    value = Math.floor(timeValue / (24 * 60 * 60 * 1000));
  }

  return value;
};

export const getPanelHeight = () => {
  const panelHeaderHeight =
    document.querySelector(`[data-header-id="${PANEL_DATA_HEADER_ID}"]`)
      ?.clientHeight ?? 0;
  // root container padding bottom
  const extraHeight = 48;

  return (
    window.innerHeight -
    STICKY_POSITION_OFFSET -
    (panelHeaderHeight + extraHeight)
  );
};

export const isModificationRequestDraft = (
  state: ModificationRequestState
): boolean => state === MODIFICATION_REQUEST_STATE.DRAFT;

export const isModificationRequestInProgress = (
  state: ModificationRequestState
): boolean => state === MODIFICATION_REQUEST_STATE.IN_PROGRESS;

export const isModificationRequestPerformed = (
  state: ModificationRequestState
): boolean => state === MODIFICATION_REQUEST_STATE.PERFORMED;

export const isModificationRequestDeclined = (
  state: ModificationRequestState
): boolean => state === MODIFICATION_REQUEST_STATE.DECLINED;

export const isModificationRequestCompleted = (
  state: ModificationRequestState
): boolean => state === MODIFICATION_REQUEST_STATE.COMPLETED;

export const isModificationRequestActive = (
  state: ModificationRequestState
): boolean =>
  ![
    MODIFICATION_REQUEST_STATE.ARCHIVED,
    MODIFICATION_REQUEST_STATE.COMPLETED,
    MODIFICATION_REQUEST_STATE.DRAFT,
  ].includes(state as MODIFICATION_REQUEST_STATE);

export const getSelectPopoverStyle = ({
  wrapperBoundingClientRect,
  targetBoundingClientRect,
}: {
  wrapperBoundingClientRect: DOMRect;
  targetBoundingClientRect: DOMRect;
}) =>
  getIsMultiselectTagClicked({
    wrapperBoundingClientRectWidth: wrapperBoundingClientRect?.width,
    targetBoundingClientRectWidth: targetBoundingClientRect?.width,
  })
    ? {
        left:
          targetBoundingClientRect?.right -
          wrapperBoundingClientRect?.left -
          50,
        top:
          targetBoundingClientRect?.bottom -
          wrapperBoundingClientRect?.top -
          15,
      }
    : {
        left: "50%",
        top: "50%",
      };

export const getIsMultiselectTagClicked = ({
  wrapperBoundingClientRectWidth,
  targetBoundingClientRectWidth,
}: {
  wrapperBoundingClientRectWidth: number;
  targetBoundingClientRectWidth: number;
}) => wrapperBoundingClientRectWidth - targetBoundingClientRectWidth > 50;

export const getSelectClosestClassname = ({
  wrapperBoundingClientRectWidth,
  targetBoundingClientRectWidth,
  isSimpleList = false,
}: {
  wrapperBoundingClientRectWidth: number;
  targetBoundingClientRectWidth: number;
  isSimpleList?: boolean;
}) => {
  if (isSimpleList) {
    return ".ant-select-selector";
  }

  return getIsMultiselectTagClicked({
    wrapperBoundingClientRectWidth,
    targetBoundingClientRectWidth,
  })
    ? ".ant-select-selection-item"
    : ".ant-select-selector";
};

export const canTargetField = ({
  isTargetMode,
  propertyId,
  readOnlyFieldIds,
  specOwnerCompanyId,
  chapterOwnerCompanyId,
}: {
  isTargetMode: boolean;
  propertyId?: string;
  readOnlyFieldIds?: string[];
  specOwnerCompanyId: string;
  chapterOwnerCompanyId: string;
}) => {
  if (!isListEmpty(readOnlyFieldIds)) {
    return (
      isTargetMode &&
      !readOnlyFieldIds?.includes(propertyId) &&
      !isChapterOwnedByCurrentUserCompany({
        specOwnerCompanyId,
        chapterOwnerCompanyId,
      })
    );
  }
  return (
    isTargetMode &&
    !isChapterOwnedByCurrentUserCompany({
      specOwnerCompanyId,
      chapterOwnerCompanyId,
    })
  );
};

export const doesFieldHaveModificationRequest = ({
  modificationRequests,
  propertyId,
  listItemKey,
}: {
  modificationRequests: ModificationRequestViewModel[];
  propertyId: string;
  listItemKey?: string;
}) =>
  modificationRequests?.findIndex(request => {
    const { targetField } = request;

    if (!listItemKey) {
      return (
        targetField?.propertyId === propertyId && !targetField?.listItemKey
      );
    }

    return (
      targetField?.propertyId === propertyId &&
      targetField?.listItemKey === listItemKey
    );
  }) > -1;

export const getDraftRequestFromAllRequests = ({
  modificationRequests,
  propertyId,
  listItemKey,
}: {
  modificationRequests: ModificationRequestViewModel[];
  propertyId: string;
  listItemKey?: string;
}) =>
  modificationRequests?.find(request => {
    const { targetField, state } = request;

    if (!listItemKey) {
      return (
        targetField?.propertyId === propertyId &&
        isModificationRequestDraft(state) &&
        !targetField?.listItemKey
      );
    }

    return (
      targetField?.propertyId === propertyId &&
      isModificationRequestDraft(state) &&
      targetField?.listItemKey === listItemKey
    );
  });

export const isModificationRequestStateFilterAll = (
  filter: ModificationRequestStateFilter
) => filter === MODIFICATION_REQUEST_STATE_FILTER.ALL;

export const isModificationRequestStateFilterActive = (
  filter: ModificationRequestStateFilter
) => filter === MODIFICATION_REQUEST_STATE_FILTER.ACTIVE;

export const getRequestStatesForFilter = (
  filter: ModificationRequestStateFilter
): ModificationRequestState[] => {
  if (!filter) {
    return null;
  }

  if (isModificationRequestStateFilterAll(filter)) {
    return [
      MODIFICATION_REQUEST_STATE.DRAFT,
      MODIFICATION_REQUEST_STATE.IN_PROGRESS,
      MODIFICATION_REQUEST_STATE.PERFORMED,
      MODIFICATION_REQUEST_STATE.DECLINED,
      MODIFICATION_REQUEST_STATE.COMPLETED,
    ];
  }

  if (isModificationRequestStateFilterActive(filter)) {
    return [
      MODIFICATION_REQUEST_STATE.IN_PROGRESS,
      MODIFICATION_REQUEST_STATE.PERFORMED,
      MODIFICATION_REQUEST_STATE.DECLINED,
    ];
  }

  return [filter as ModificationRequestState];
};

export const getModificationRequestionActions = ({
  formatMessage,
  onPerform,
  onDecline,
  onComplete,
  canPerformAndDecline,
  canSetAsComplete,
  state,
  canCancel,
  onArchive,
  canDelete,
  onDelete,
  tooltipPlacement = null,
}: {
  formatMessage: Function;
  onPerform: () => Promise<void>;
  onDecline: () => Promise<void>;
  onComplete: () => Promise<void>;
  canPerformAndDecline: boolean;
  canSetAsComplete: boolean;
  canDelete?: boolean;
  onDelete?: () => Promise<void>;
  canCancel?: boolean;
  onArchive?: () => Promise<void>;
  state: ModificationRequestState;
  tooltipPlacement?: TooltipProps["placement"];
}) => {
  return [
    ...(isModificationRequestPerformed(state) ||
    isModificationRequestDeclined(state)
      ? [
          {
            label: formatMessage(
              modificationRequestMessages.modificationRequestActionSetAsCompleted
            ),
            key: formatMessage(
              modificationRequestMessages.modificationRequestActionSetAsCompleted
            ),
            onClick: onComplete,
            disabled: !canSetAsComplete,
          },
        ]
      : []),
    ...(isModificationRequestDraft(state)
      ? [
          {
            label: formatMessage(generalMessages.delete),
            key: formatMessage(generalMessages.delete),
            disabled: !canDelete,
            ...(canDelete
              ? {
                  tooltipProps: {
                    onConfirmation: onDelete,
                    ...(tooltipPlacement
                      ? { placement: tooltipPlacement }
                      : {}),
                    getPopupContainer: trigger => trigger.parentNode,
                  },
                }
              : {}),
          },
        ]
      : []),
    ...(isModificationRequestActive(state)
      ? [
          {
            label: formatMessage(generalMessages.cancel),
            key: formatMessage(generalMessages.cancel),
            disabled: !canCancel,
            ...(canCancel
              ? {
                  tooltipProps: {
                    onConfirmation: onArchive,
                    ...(tooltipPlacement
                      ? { placement: tooltipPlacement }
                      : {}),
                    getPopupContainer: trigger => trigger.parentNode,
                  },
                }
              : {}),
          },
        ]
      : []),
    ...(isModificationRequestInProgress(state)
      ? [
          {
            label: formatMessage(
              modificationRequestMessages.modificationRequestActionSetAsPerformed
            ),
            key: formatMessage(
              modificationRequestMessages.modificationRequestActionSetAsPerformed
            ),
            onClick: onPerform,
            disabled: !canPerformAndDecline,
          },
          {
            label: formatMessage(
              modificationRequestMessages.modificationRequestActionDecline
            ),
            key: formatMessage(
              modificationRequestMessages.modificationRequestActionDecline
            ),
            onClick: onDecline,
            disabled: !canPerformAndDecline,
          },
        ]
      : []),
  ];
};
