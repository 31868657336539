/* istanbul ignore file */

import { createAsyncThunk } from "@reduxjs/toolkit";
import qs from "qs";
import {
  fetchSpecificationPermissions,
  updateSpecificationRevision,
  fetchSpecificationSectionLastChanges,
  fetchModificationRequests,
  createModificationRequest,
  updateModificationRequest,
  deleteModificationRequest,
  notifyModificationRequest,
  fetchModificationRequestById,
  performModificationRequest,
  declineModificationRequest,
  completeModificationRequest,
  archiveModificationRequest,
} from "apis/SPEC";
import { createDiscussionMessage, fetchDiscussionById } from "apis/DISCUSS";
import { isModificationRequestDraft } from "utils/modificationRequest";
import { CHAPTER_TYPES_API } from "utils/constants";

export const fetchSpecificationPermissionsFromId = createAsyncThunk(
  "specificationDetails/fetchSpecificationPermissionsFromId",
  async specificationId => {
    const { data: permissions } = await fetchSpecificationPermissions({
      specificationId,
    });
    return permissions;
  }
);

export const reviseSpecification = createAsyncThunk(
  "specificationDetails/reviseSpecification",
  async id => {
    const { data } = await updateSpecificationRevision({ id });

    return data;
  }
);

export const getSpecificationSectionLastChanges = createAsyncThunk(
  "specification/getSpecificationSectionLastChanges",
  async (_, { getState }) => {
    const {
      specificationDetails: {
        specification: { specificationId },
        selectedSection: { sectionId },
      },
    } = getState();

    const { data } = await fetchSpecificationSectionLastChanges({
      specificationId,
      sectionId,
      bySupplier: true,
    });

    return data.reduce(
      (
        previousState,
        { templateBlockId, propertyId, arrayItem, itemIndex, ...rest }
      ) => {
        if (!previousState[templateBlockId]) {
          previousState[templateBlockId] = {};
        }

        if (arrayItem) {
          return {
            ...previousState,
            [templateBlockId]: {
              ...previousState[templateBlockId],
              [propertyId]: {
                ...previousState[templateBlockId][propertyId],
                [itemIndex]: rest,
              },
            },
          };
        }

        return {
          ...previousState,
          [templateBlockId]: {
            ...previousState[templateBlockId],
            [propertyId]: rest,
          },
        };
      },
      {}
    );
  }
);

export const getSpecificationModificationRequests = createAsyncThunk(
  "specificationDetails/getModificationRequests",
  async ({ specificationId, chapterType, requestStates }, { getState }) => {
    const {
      user: { userLanguagePreference },
    } = getState();

    const params = {
      specificationId,
      chapterType,
      requestStates,
      languageCode: userLanguagePreference,
    };

    const { data } = await fetchModificationRequests({
      params,
      paramsSerializer: () => qs.stringify(params, { arrayFormat: "comma" }),
    });

    return data;
  }
);

export const createSpecificationModificationRequest = createAsyncThunk(
  "specificationDetails/addModificationRequest",
  async (
    { specificationId, chapterType, blockId, propertyId, listItemKey, message },
    { getState, rejectWithValue }
  ) => {
    const {
      user: { userLanguagePreference },
    } = getState();

    const payload = {
      specificationId,
      blockId,
      propertyId,
      listItemKey,
      message,
    };

    const params = {
      languageCode: userLanguagePreference,
    };

    try {
      const { data } = await createModificationRequest({
        chapterType: CHAPTER_TYPES_API[chapterType],
        payload,
        params,
      });

      return data;
    } catch (error) {
      return rejectWithValue({ statusCode: error?.response?.status });
    }
  }
);

export const updateSpecificationModificationRequest = createAsyncThunk(
  "specificationDetails/updateModificationRequest",
  async ({ requestId, message }, { getState, rejectWithValue }) => {
    const {
      user: { userLanguagePreference },
    } = getState();

    const payload = {
      message,
    };

    const params = {
      languageCode: userLanguagePreference,
    };

    try {
      const { data } = await updateModificationRequest({
        requestId,
        payload,
        params,
      });

      return data;
    } catch (error) {
      return rejectWithValue({ statusCode: error?.response?.status });
    }
  }
);

export const deleteSpecificationModificationRequest = createAsyncThunk(
  "specificationDetails/deleteModificationRequest",
  async ({ requestId }, { getState }) => {
    if (!requestId) {
      return;
    }

    const {
      specificationDetails: { modificationRequests },
    } = getState();

    try {
      await deleteModificationRequest({ requestId });

      const updatedRequests = modificationRequests?.filter(
        request => request?.requestId !== requestId
      );

      return updatedRequests;
    } catch {}
  }
);

export const notifySpecificationModificationRequest = createAsyncThunk(
  "specificationDetails/notifyModificationRequest",
  async ({ requestId }, { getState, dispatch }) => {
    if (!requestId) return;

    const {
      specificationDetails: { selectedModificationRequest },
      user: { userLanguagePreference },
    } = getState();

    try {
      const { data } = await notifyModificationRequest({
        requestId,
        params: {
          languageCode: userLanguagePreference,
        },
      });

      if (selectedModificationRequest?.detail?.requestId) {
        await dispatch(getSpecificationModificationRequestById({ requestId }));
      }

      return data;
    } catch {}
  }
);

export const performSpecificationModificationRequest = createAsyncThunk(
  "specificationDetails/performModificationRequest",
  async ({ requestId }, { getState, dispatch }) => {
    if (!requestId) return;

    const {
      specificationDetails: { selectedModificationRequest },
      user: { userLanguagePreference },
    } = getState();

    try {
      const { data } = await performModificationRequest({
        requestId,
        params: {
          languageCode: userLanguagePreference,
        },
      });

      if (selectedModificationRequest?.detail?.requestId) {
        await dispatch(getSpecificationModificationRequestById({ requestId }));
      }

      return data;
    } catch {}
  }
);
export const declineSpecificationModificationRequest = createAsyncThunk(
  "specificationDetails/declineModificationRequest",
  async ({ requestId }, { getState, dispatch }) => {
    if (!requestId) return;

    const {
      specificationDetails: { selectedModificationRequest },
      user: { userLanguagePreference },
    } = getState();

    try {
      const { data } = await declineModificationRequest({
        requestId,
        params: {
          languageCode: userLanguagePreference,
        },
      });

      if (selectedModificationRequest?.detail?.requestId) {
        await dispatch(getSpecificationModificationRequestById({ requestId }));
      }

      return data;
    } catch {}
  }
);

export const completeSpecificationModificationRequest = createAsyncThunk(
  "specificationDetails/completeSpecificationModificationRequest",
  async ({ requestId }, { getState, dispatch }) => {
    if (!requestId) return;

    const {
      specificationDetails: { selectedModificationRequest },
      user: { userLanguagePreference },
    } = getState();

    try {
      const { data } = await completeModificationRequest({
        requestId,
        params: {
          languageCode: userLanguagePreference,
        },
      });

      if (selectedModificationRequest?.detail?.requestId) {
        await dispatch(getSpecificationModificationRequestById({ requestId }));
      }

      return data;
    } catch {}
  }
);

export const getSpecificationModificationRequestById = createAsyncThunk(
  "specificationDetails/getModificationRequestById",
  async ({ requestId }, { getState, dispatch }) => {
    if (!requestId) {
      return;
    }

    const {
      user: { userLanguagePreference },
    } = getState();

    const params = {
      languageCode: userLanguagePreference,
    };

    const { data } = await fetchModificationRequestById({
      requestId,
      params,
    });

    if (!isModificationRequestDraft(data?.state?.value) && data?.discussionId) {
      await dispatch(
        getSpecificationModificationRequestDiscussionById({
          discussionId: data?.discussionId,
        })
      );
    }

    return data;
  }
);

export const getSpecificationModificationRequestDiscussionById = createAsyncThunk(
  "specificationDetails/getModificationRequestDiscussionById",
  async ({ discussionId }) => {
    if (!discussionId) {
      return;
    }

    const { data } = await fetchDiscussionById({
      discussionId,
    });

    return data;
  }
);

export const archiveSpecificationModificationRequest = createAsyncThunk(
  "specificationDetails/archiveModificationRequest",
  async ({ requestId }, { getState }) => {
    if (!requestId) {
      return;
    }

    const {
      specificationDetails: { modificationRequests },
      user: { userLanguagePreference },
    } = getState();

    try {
      await archiveModificationRequest({
        requestId,
        params: {
          languageCode: userLanguagePreference,
        },
      });

      const updatedRequests = modificationRequests?.filter(
        request => request?.requestId !== requestId
      );

      return updatedRequests;
    } catch {}
  }
);

export const createModificationRequestDiscussionMessage = createAsyncThunk(
  "specificationDetails/createModificationRequestMessage",
  async ({ text }, { getState, dispatch, rejectWithValue }) => {
    if (!text) {
      return;
    }

    try {
      const {
        specificationDetails: {
          selectedModificationRequest: { discussion },
        },
        user: { userId },
      } = getState();

      if (!discussion?.id) {
        return;
      }

      await createDiscussionMessage({
        discussionId: discussion?.id,
        payload: {
          authorUserId: userId,
          text,
        },
      });

      await dispatch(
        getSpecificationModificationRequestDiscussionById({
          discussionId: discussion?.id,
        })
      );
    } catch (error) {
      return rejectWithValue({ statusCode: error?.response?.status });
    }
  }
);
