import { useRedirect } from "hooks";
import { SpecificationDraftData, UserData } from "models";
import { useAppDispatch } from "store";
import {
  addSpecificationOwnerContact,
  removeSpecification,
  removeSpecificationOwnerContact,
  removeSpecificationProduct,
  removeSpecificationDraftCollaborations,
  setSpecificationNote,
  setSpecificationProduct,
  setSpecificationProductType,
  setSpecificationDraftCollaboration,
  updateCollaboration,
} from "store/specificationCreation/asyncActions";
import { setIsInvitationStepValid } from "store/specificationCreation/specificationCreationSlice";

const useCreationActions = () => {
  const dispatch = useAppDispatch();
  const { redirectToSpecificationListing } = useRedirect();

  const onSpecificationDelete = async () => {
    await dispatch(removeSpecification());
    redirectToSpecificationListing();
  };

  const changeProductType = (productTypeId: string) => {
    dispatch(setSpecificationProductType(productTypeId));
  };

  const onAddOwnerContact = (userId: UserData["userId"]) => {
    dispatch(addSpecificationOwnerContact(userId));
  };

  const onRemoveOwnerContact = (userId: UserData["userId"]) => () => {
    dispatch(removeSpecificationOwnerContact(userId));
  };

  const onRemoveProduct = event => {
    event.stopPropagation();

    dispatch(removeSpecificationProduct());
  };

  const onSaveProducts = products => {
    products.forEach(product => dispatch(setSpecificationProduct(product)));
  };

  const onSpecificationNoteChange = (
    specificationNote: SpecificationDraftData["note"]
  ) => {
    dispatch(setSpecificationNote(specificationNote));
  };

  const handleCreateCollaboration = (collaborationId: string) => {
    dispatch(setSpecificationDraftCollaboration(collaborationId));
  };

  const handleRemoveCollaborations = (collaborationIds: string[]) => {
    dispatch(removeSpecificationDraftCollaborations(collaborationIds));
  };

  const handleChangeValidation = (isValid: boolean) => {
    dispatch(setIsInvitationStepValid(isValid));
  };

  const handleChangeEnableSupplierCollaboration = ({
    collaboratingCompanyId,
    isEnabled,
  }) => {
    dispatch(updateCollaboration({ collaboratingCompanyId, isEnabled }));
  };

  return {
    onSpecificationDelete,
    changeProductType,
    onAddOwnerContact,
    onRemoveOwnerContact,
    onRemoveProduct,
    onSaveProducts,
    onSpecificationNoteChange,
    handleCreateCollaboration,
    handleRemoveCollaborations,
    handleChangeValidation,
    handleChangeEnableSupplierCollaboration,
  };
};

export default useCreationActions;
