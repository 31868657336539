import { ROUTES } from "utils/constants";
import { isStateDraft } from "utils/specifications";
import {
  shareSpecification as shareSpecificationApi,
  shareSpecificationDraft as shareSpecificationDraftApi,
} from "apis/SPEC";
import { SpecificationData } from "models";

export const onCreateClick = ({ push }: { push: Function }) => () => {
  push(ROUTES.SPECIFICATION_CREATION_PRODUCT_STEP);
};

export const getOnViewClick = ({
  push,
  specificationId,
  pathname,
}: {
  push: Function;
  specificationId: SpecificationData["id"];
  pathname: string;
}) => () => {
  const specificationDetailsRoute = ROUTES.SPECIFICATION_DETAILS.replace(
    ":specificationId",
    specificationId
  );

  if (pathname.includes(specificationDetailsRoute)) {
    window.location.reload();
  } else {
    push(specificationDetailsRoute);
  }
};

export const getOnYesClick = ({
  shareSpecification,
}: {
  shareSpecification: Function;
}) => async () => {
  await shareSpecification();
};

export const getShareSpecification = ({
  setIsDone,
  setIsSharing,
  setHasError,
  setSharedSpecificationId,
  specificationId,
  state,
}: {
  setIsDone: Function;
  setIsSharing: Function;
  setHasError: Function;
  setSharedSpecificationId: Function;
  specificationId: SpecificationData["id"];
  state: SpecificationData["state"];
}) => async () => {
  try {
    setIsDone(false);
    setIsSharing(true);
    setHasError(false);

    if (state === undefined || isStateDraft(state)) {
      const { data } = await shareSpecificationDraftApi({
        draftId: specificationId,
      });

      setSharedSpecificationId(data);
    } else {
      const { data } = await shareSpecificationApi({
        id: specificationId,
      });
      setSharedSpecificationId(data.specificationId);
    }
  } catch (_) {
    setHasError(true);
  } finally {
    setIsDone(true);
    setIsSharing(false);
  }
};

export const getStartConfirmedSharingModal = ({
  setIsConfirmedSharignModalOpen,
}: {
  setIsConfirmedSharignModalOpen: Function;
}) => () => {
  setIsConfirmedSharignModalOpen(true);
};
