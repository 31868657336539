import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Row, Col } from "antd";
import { DatePicker, Stepper, Tooltip } from "@trace-one/design-system";
import { Title } from "@trace-one/react-components";
import { useAppDispatch } from "store";
import { selectLanguageCode } from "store/user/selectors";
import {
  addFolderContact,
  getContacts,
  removeFolderContact,
  setFolderDescription,
  setFolderName,
  setFolderProductType,
  setFolderTargetDate,
  startFolderCreation,
} from "store/folderCreation/asyncActions";
import {
  selectContacts,
  selectDetailsStepIsValid,
  selectIsSaving,
  selectFolderDetails,
  selectErrors,
  selectSelectedContacts,
  selectProducts,
  selectIsCreationInProgress,
  selectFolderOwnerUserDetails,
  selectWizard,
} from "store/folderCreation/selectors";
import { resetFolderCreationErrors } from "store/folderCreation/folderCreationSlice";
import { selectRefListDictionary } from "store/generalData/selectors";
import CreationWizzard from "components/CreationWizzard";
import ReflistSelect from "components/ReflistSelect";
import Spin from "components/Spin";
import Debounced from "components/Debounced";
import { REFLIST_IDS } from "utils/constants";
import { isFAndVOnly, isListEmpty } from "utils/general";
import { getOnProductTypeTooltipVisibleChange } from "utils/form";
import {
  useSteps,
  useCreationActions,
} from "pages/Folder/components/Creation/hooks";
import {
  getInitialTargetDateUtc,
  getOnContactDelete,
  getOnDescriptionChange,
  getOnNameChange,
  getOnSelectContact,
  getOnSelectProductTypeId,
  getOnSelectTargetDate,
  shouldCurrentDateBeDisabled,
} from "./utils";
import { messages } from "./messages";
import styles from "./styles.module.less";

const Details = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { getOnFolderDelete } = useCreationActions();
  const isSaving = useSelector(selectIsSaving);
  const isCreationInProgress = useSelector(selectIsCreationInProgress);
  const preferedLanguage = useSelector(selectLanguageCode);
  const contactList = useSelector(selectContacts);
  const selectedRetailerContacts = useSelector(selectSelectedContacts);
  const selectedProducts = useSelector(selectProducts);
  const ownerUserDetails = useSelector(selectFolderOwnerUserDetails);
  const formIsValid = useSelector(selectDetailsStepIsValid);
  const {
    folderId,
    folderDescription,
    folderName,
    productTypeId,
    targetDateUtc,
  } = useSelector(selectFolderDetails);
  const wizard = useSelector(selectWizard);

  const { stepIndex, currentIsValid, steps, onNextStep } = useSteps({
    currentStep: wizard.currentStep,
    stepsState: wizard.state,
  });

  const {
    contacts: hasContactsError,
    folderDescription: hasFolderDescriptionError,
    folderName: hasFolderNameError,
    folderProductType: hasFolderProductTypeError,
    targetDateUtc: hasTargetDateUtcError,
  } = useSelector(selectErrors);

  const refListDictionary = useSelector(selectRefListDictionary);

  const [
    isProductTypeTooltipVisible,
    setIsProductTypeTooltipVisible,
  ] = useState(false);

  useEffect(() => {
    if (!folderId) {
      dispatch(startFolderCreation());
    }
  }, []);

  useEffect(() => {
    dispatch(getContacts());
    dispatch(resetFolderCreationErrors());
  }, [dispatch]);

  return (
    <Spin fixed spinning={isCreationInProgress}>
      <CreationWizzard.Title.Folder />
      <Stepper
        stepIndex={stepIndex}
        currentIsValid={currentIsValid}
        steps={steps}
      />
      <Title
        level={2}
        value={intl.formatMessage(messages.folderDetails)}
        subtitle={intl.formatMessage(messages.folderDetailsSub)}
      />

      <Row gutter={[8, 0]}>
        <Col xs={24} sm={12} md={8} lg={8}>
          <CreationWizzard.Item
            required
            label={intl.formatMessage(messages.formFolderName)}
          >
            <Debounced.Input
              id="basic_folderName"
              defaultValue={folderName}
              onChange={getOnNameChange({
                dispatch,
                setFolderName,
              })}
              data-testid="folderName"
              placeholder={intl.formatMessage(
                messages.formFolderNamePlaceholder
              )}
              error={hasFolderNameError}
              errorMessage={intl.formatMessage(messages.errorFieldMessage)}
            />
          </CreationWizzard.Item>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8}>
          <Tooltip
            open={isProductTypeTooltipVisible}
            text={intl.formatMessage(messages.productTypeTooltipTitle)}
            onOpenChange={getOnProductTypeTooltipVisibleChange({
              setIsProductTypeTooltipVisible,
              hasAssignedProducts: !isListEmpty(selectedProducts),
            })}
            placement="bottom"
          >
            <CreationWizzard.Item
              required
              label={intl.formatMessage(messages.formProductType)}
            >
              <ReflistSelect
                refListId={REFLIST_IDS.PRODUCT_TYPES}
                allowClear={false}
                value={productTypeId}
                disabled={!isListEmpty(selectedProducts)}
                name="productTypeId"
                data-testid="productTypeId"
                onSelect={getOnSelectProductTypeId({
                  dispatch,
                  setFolderProductType,
                })}
                placeholder={intl.formatMessage(
                  messages.formProductTypePlaceholder
                )}
                error={hasFolderProductTypeError}
                errorMessage={intl.formatMessage(messages.errorFieldMessage)}
                shouldCallApi={false}
                refListDictionary={refListDictionary}
                filterRefListIds={
                  isFAndVOnly ? [REFLIST_IDS.FOOD_PRODUCT_TYPE] : []
                }
              />
            </CreationWizzard.Item>
          </Tooltip>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8}>
          <CreationWizzard.Item
            required
            label={intl.formatMessage(messages.formTargetDate)}
          >
            <DatePicker
              defaultValue={getInitialTargetDateUtc(targetDateUtc)}
              allowClear={false}
              data-testid="targetDateUtc"
              placeholder={intl.formatMessage(
                messages.formTargetDatePlaceholder
              )}
              disabledDate={shouldCurrentDateBeDisabled}
              locale={preferedLanguage}
              onChange={getOnSelectTargetDate({
                dispatch,
                setFolderTargetDate,
              })}
              error={hasTargetDateUtcError}
              errorMessage={intl.formatMessage(messages.errorFieldMessage)}
            />
          </CreationWizzard.Item>
        </Col>
      </Row>

      <Row gutter={[20, 0]} className={styles.fieldSpace}>
        <CreationWizzard.SelectRetailerContacts
          retailerContacts={contactList}
          selectedRetailerContacts={selectedRetailerContacts}
          ownerUserDetails={ownerUserDetails}
          onDelete={getOnContactDelete({
            dispatch,
            removeFolderContact,
          })}
          onSelect={getOnSelectContact({
            addFolderContact,
            dispatch,
          })}
          name="contact"
          dataTestIdForDropdown="contact"
          colSpansForList={{
            xs: 24,
            sm: 24,
            md: 24,
            lg: 18,
          }}
          error={hasContactsError}
          errorMessage={intl.formatMessage(messages.errorFieldMessage)}
        />
      </Row>

      <Row>
        <Col span={24}>
          <CreationWizzard.Item
            label={intl.formatMessage(messages.formDescription)}
          >
            <Debounced.Textarea
              defaultValue={folderDescription}
              onChange={getOnDescriptionChange({
                dispatch,
                setFolderDescription,
              })}
              name="folderDescription"
              {...(hasFolderDescriptionError && {
                error: true,
                errorMessage: intl.formatMessage(messages.errorFieldMessage),
              })}
            />
          </CreationWizzard.Item>
        </Col>
      </Row>

      <CreationWizzard.Footer
        isSaving={isSaving}
        isNextDisabled={!formIsValid}
        hasBackButton={false}
        onNextClick={onNextStep}
        onCancel={getOnFolderDelete()}
      />
    </Spin>
  );
};

export default Details;
