export const getNumberPageDisplayed = ({
  totalNumber,
  current,
  pageSize,
}: {
  totalNumber: number;
  current: number;
  pageSize: number;
}): number => {
  if (totalNumber && current * pageSize > totalNumber) {
    return totalNumber - (current - 1) * pageSize;
  } else if (totalNumber && totalNumber < pageSize) {
    return totalNumber;
  } else if (totalNumber) {
    return pageSize;
  }
  return 0;
};

export const getRowKeyForTable = (tableType?: string) => ({
  folderId,
  id,
  specificationId,
  ingredientId,
  materialId,
  allergenId,
  nutrientId,
  componentId,
  type,
}: {
  folderId?: string;
  id?: string;
  specificationId?: string;
  ingredientId?: string;
  materialId?: string;
  allergenId?: string;
  nutrientId?: string;
  componentId?: string;
  type?: string;
}) => {
  switch (tableType) {
    case "folder":
      return folderId;
    case "specification":
      return specificationId;
    case "ingredient":
      return ingredientId;
    case "material":
      return materialId;
    case "allergen":
      return allergenId;
    case "nutrition":
      return nutrientId;
    case "packagingComponent":
      return componentId;
    case "packagingComponentMaterial":
      return type;
    default:
      return id;
  }
};

export const shouldPaginationBeDisplay = (totalItemsNumber: number): boolean =>
  totalItemsNumber > 10;

export const getTableSize = (displayActions: boolean) =>
  displayActions ? "middle" : "small";
