import { defineMessages } from "react-intl";

export const messages = defineMessages({
  guaranteedShelfLife: {
    id:
      "specificationDetails.widgets.labelling.consumerInstruction.codeAndDates.slaughter",
    defaultMessage: "Slaughter, catch, harvest",
  },
  production: {
    id:
      "specificationDetails.widgets.labelling.consumerInstruction.codeAndDates.production",
    defaultMessage: "Production",
  },
  packaging: {
    id:
      "specificationDetails.widgets.labelling.consumerInstruction.codeAndDates.packaging",
    defaultMessage: "Packaging",
  },
  shipping: {
    id:
      "specificationDetails.widgets.labelling.consumerInstruction.codeAndDates.shipping",
    defaultMessage: "Shipping",
  },
  delivery: {
    id:
      "specificationDetails.widgets.labelling.consumerInstruction.codeAndDates.delivery",
    defaultMessage: "Delivery",
  },
});
