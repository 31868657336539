import { useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { ButtonProps } from "@trace-one/design-system";
import { fetchReferenceListItems } from "apis/RLMD";
import { useAppDispatch } from "store";
import { selectLanguageCode } from "store/user/selectors";
import { updateRefListDictionary } from "store/generalData/asyncActions";
import { ReferenceListItemData } from "models";
import generalMessages from "messages/general";

const useDual = ({
  listId,
  onChange,
  parentItemIds,
}: {
  listId: string;
  onChange: Function;
  parentItemIds?: string[];
}): { items: ButtonProps["items"]; buttonProps: ButtonProps } => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const languageCode = useSelector(selectLanguageCode);
  const [isLoading, setIsLoading] = useState(false);
  const [referenceList, setReferenceList] = useState<ReferenceListItemData[]>(
    []
  );

  const onChangeUnit = ({ text, id }: ReferenceListItemData) => () => {
    dispatch(
      updateRefListDictionary({
        [id]: text,
      })
    );

    onChange(id);
  };

  const onVisibleChange = async (open: boolean) => {
    if (open) {
      try {
        setIsLoading(true);

        const { data } = await fetchReferenceListItems({
          id: listId,
          languageCode,
          parentItemIds,
        });

        setReferenceList(data.referenceListItems);
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
    }
  };

  return {
    items: isLoading
      ? [{ label: formatMessage(generalMessages.loading), disabled: true }]
      : referenceList.map(({ text, id }) => ({
          label: text,
          onClick: onChangeUnit({ text, id }),
        })),
    buttonProps: {
      loading: isLoading,
      dropdownProps: {
        onVisibleChange,
      },
    },
  };
};

export default useDual;
