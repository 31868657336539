import { useEffect, useState } from "react";
import { fetchCompanies } from "apis/CUMD";
import { UseSuppliersProps } from "./types";
import { CompanyListItem } from "components/CreateCollaboration/types";
import { isListEmpty } from "utils/general";

const useSuppliers = ({
  companyIdsWithLinkedProducts,
  hideSupplierBar,
  onChangeEnableSupplierCollaboration,
  disableCompanyFetch,
}: UseSuppliersProps) => {
  const [companyList, setCompanyList] = useState<CompanyListItem[]>([]);
  const [isLoadingCompanies, setIsLoadingCompanies] = useState(true);

  useEffect(() => {
    if (!disableCompanyFetch && !isListEmpty(companyIdsWithLinkedProducts)) {
      getCompanies();
    }
  }, []);

  useEffect(() => {
    if (disableCompanyFetch && !isListEmpty(companyIdsWithLinkedProducts)) {
      setCompanyList(companyIdsWithLinkedProducts);
    }
  }, [disableCompanyFetch, companyIdsWithLinkedProducts]);

  useEffect(() => {
    // if there is created collaboration for the supplier the toggle button should be enabled
    if (!isLoadingCompanies) {
      setCompanyList(prev =>
        prev.map(supplier => {
          const { isCollaborationEnabled } = findSupplier(
            supplier.collaboratingCompanyId
          );
          return {
            ...supplier,
            isCollaborationEnabled,
          };
        })
      );
    }
  }, [JSON.stringify(companyIdsWithLinkedProducts), isLoadingCompanies]);

  const findSupplier = (id: string) =>
    companyIdsWithLinkedProducts.find(
      ({ collaboratingCompanyId }) => id === collaboratingCompanyId
    );

  const getCompanies = async () => {
    try {
      const { data } = await fetchCompanies({
        ids: companyIdsWithLinkedProducts.map(
          ({ collaboratingCompanyId }) => collaboratingCompanyId
        ),
      });
      const preparedSuppliersList = data.map(company => {
        const { numberOfLinkedProducts } = findSupplier(company.companyId);
        return {
          collaboratingCompanyId: company.companyId,
          name: company.companyDisplayName,
          numberOfLinkedProducts,
          isCollaborationEnabled: hideSupplierBar,
        };
      });

      setCompanyList(preparedSuppliersList);
    } catch (_) {
    } finally {
      setIsLoadingCompanies(false);
    }
  };

  const handleChangeSupplierCollaboration = (
    collaboratingCompanyId: string
  ) => (value: boolean) => {
    onChangeEnableSupplierCollaboration?.({
      collaboratingCompanyId,
      isEnabled: value,
    });
    setCompanyList(previousState =>
      previousState.map(supplier => {
        if (supplier.collaboratingCompanyId === collaboratingCompanyId) {
          return {
            ...supplier,
            isCollaborationEnabled: value,
          };
        } else {
          return { ...supplier };
        }
      })
    );
  };

  return { companyList, handleChangeSupplierCollaboration };
};

export default useSuppliers;
