import React from "react";
import { useIntl } from "react-intl";
import EmptyState from "components/Library/components/EmptyState";
import StepTitle from "components/Library/components/StepTitle";
import ClaimConfigureContent from "../ClaimConfigureContent";
import { claimMessages } from "messages/claim";

export interface ClaimConfigureProps {
  claimType: string;
}

const ClaimConfigure: React.FC<ClaimConfigureProps> = ({ claimType }) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <StepTitle title={formatMessage(claimMessages.stepTwoTitle)} step={2} />
      {claimType ? (
        <ClaimConfigureContent />
      ) : (
        <EmptyState
          description={formatMessage(claimMessages.stepTwoDescription)}
        />
      )}
    </>
  );
};

export default ClaimConfigure;
