import { useEffect, useState } from "react";

const useExpandedRowKeys = ({
  itemKey = "id",
  allExpandableRowKeys,
}: {
  itemKey: string;
  allExpandableRowKeys: string[];
}) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

  const onCollapseAllHandler = () => {
    setExpandedRowKeys([]);
  };

  const onExpandHandler = (isExpanded: boolean, row) => {
    setExpandedRowKeys(prev => {
      if (isExpanded) {
        return [...prev, row[itemKey]];
      } else {
        return prev.filter(item => item !== row[itemKey]);
      }
    });
  };

  const onExpandAllHandler = () => {
    setExpandedRowKeys(allExpandableRowKeys);
  };

  useEffect(() => {
    setExpandedRowKeys(allExpandableRowKeys);
  }, [JSON.stringify(allExpandableRowKeys)]);

  return {
    expandedRowKeys,
    onCollapseAllHandler,
    onExpandHandler,
    onExpandAllHandler,
  };
};

export default useExpandedRowKeys;
