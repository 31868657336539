import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store";
import { selectLanguageCode } from "store/user/selectors";
import { selectGeneraInfo } from "store/chapterForm/selectors";
import { getChapter } from "store/chapterForm/asyncActions";
import { setFormData } from "store/chapterForm/chapterFormSlice";
import { isObjectEmpty, isUuidEmpty } from "utils/general";
import { ChapterData } from "models";

const useChapterFormInitialisation = ({
  initialFormData = {},
}: {
  initialFormData: ChapterData;
}) => {
  const dispatch = useAppDispatch();
  const generalInfo = useSelector(selectGeneraInfo);
  const languageCode = useSelector(selectLanguageCode);
  const [isLoading, setIsLoading] = useState(true);

  const isInitialFormDataEmpty = useMemo(
    () =>
      isObjectEmpty(initialFormData) ||
      isUuidEmpty(initialFormData.generalInfo.id),
    [initialFormData]
  );

  // Refetch the chapter on the creation page when the language is changed
  useEffect(() => {
    if (isInitialFormDataEmpty && generalInfo.id) {
      dispatch(getChapter());
    }
  }, [languageCode]);

  useEffect(() => {
    const prepareChapter = async () => {
      setIsLoading(true);

      if (!isInitialFormDataEmpty) {
        dispatch(setFormData(initialFormData));
      }

      setIsLoading(false);
    };

    prepareChapter();
  }, [JSON.stringify(initialFormData)]);

  return {
    isLoading,
  };
};

export default useChapterFormInitialisation;
