import { defineMessages } from "react-intl";

const supplierDetailsMessages = defineMessages({
  noSupplier: {
    id: "library.rawMaterialForm.supplierDetails.noSupplier",
    defaultMessage: "No supplier",
  },
  rawMaterialSupplierDetailsCardTitle: {
    id: "library.rawMaterialForm.supplierDetails.cardTitle",
    defaultMessage: "Suppliers",
  },
  rawMaterialSupplierDetailsAddSupplier: {
    id: "library.rawMaterialForm.supplierDetails.addSupplier",
    defaultMessage: "Add a supplier",
  },
});

export default supplierDetailsMessages;
