import { Col, Row } from "antd";
import { Divider } from "@trace-one/design-system";
import NutriscorePointDetails from "components/NutriscorePointDetails";
import styles from "./styles.module.less";
import { NutriscorePointData } from "models";
import React from "react";
import { useNutriscorePointsDetails } from "./hooks";
import Spin from "components/Spin";

export type NutriscorePointsDetailsProps = {
  nutriscorePoints: NutriscorePointData[];
};

const NutriscorePointsDetails: React.FC<NutriscorePointsDetailsProps> = ({
  nutriscorePoints = [],
}) => {
  const {
    positiveNutrients,
    negativeNutrients,
    isLoading,
  } = useNutriscorePointsDetails({
    nutriscorePoints,
  });

  if (isLoading) {
    return <Spin size="default" />;
  }

  return (
    <Row className="mt-2" wrap={false}>
      <Col span={11}>
        {positiveNutrients.map(({ nutrientId, score, name }) => (
          <NutriscorePointDetails
            name={name}
            score={score}
            statusColor="green"
            key={nutrientId}
          />
        ))}
      </Col>
      <Col span={2} className={styles["nutriscore-points-detail__divider"]}>
        <Divider type="vertical" color="grey-3" height="100%" />
      </Col>
      <Col span={11}>
        {negativeNutrients.map(({ nutrientId, score, name }) => (
          <NutriscorePointDetails
            name={name}
            score={score}
            statusColor="red"
            key={nutrientId}
          />
        ))}
      </Col>
    </Row>
  );
};

export default NutriscorePointsDetails;
