import { Row, Col } from "antd";
import {
  Paragraph,
  Heading,
  DisplayCard,
  ToggleButtons,
} from "@trace-one/design-system";
import { useIntl } from "react-intl";
import classnames from "classnames";
import InfiniteScroll from "react-infinite-scroll-component";
import Spin from "components/Spin";
import { getVersionName } from "utils/specifications";
import { useDisplayItems } from "./hooks";
import { InfiniteScrollLibraryContentProps } from "./types";
import { generalMessages } from "messages";
import styles from "./styles.module.less";

const InfiniteScrollLibraryContent: React.FC<InfiniteScrollLibraryContentProps> = ({
  title,
  totalNumberOfItems,
  canLoadMore,
  libraryObjectList,
  nextFetchCallback,
  onSelectLibraryItem,
  existingLibraryObjectIds,
  selectedLibraryObjectIds,
  infiniteScrollWrapper,
}) => {
  const { displayItems, options, onDisplayItemChange } = useDisplayItems();
  const { formatMessage } = useIntl();

  return (
    <>
      <Row
        justify="space-between"
        align="middle"
        className={styles.modalBodyTitleRow}
      >
        <Col>
          <Heading className={styles.modalBodyTitle} size="xs">
            {title}
          </Heading>
          <Paragraph className={styles.modalBodyTotalNumber} size="s">
            {` (${totalNumberOfItems})`}
          </Paragraph>
        </Col>
        <Col>
          <ToggleButtons
            onChange={onDisplayItemChange}
            defaultValue="display-4-columns"
            options={options}
          />
        </Col>
      </Row>
      <div
        id="scrollableInfiniteScrollWrapper"
        ref={infiniteScrollWrapper}
        className={styles.infiniteScrollWrapper}
      >
        <InfiniteScroll
          scrollableTarget="scrollableInfiniteScrollWrapper"
          className={classnames(
            styles.infiniteScrollDisplay,
            styles[displayItems],
            styles.infiniteScroll
          )}
          dataLength={libraryObjectList.length}
          hasMore={canLoadMore}
          loader={
            <div className={styles.infiniteScrollLoadingContainer}>
              <Spin />
            </div>
          }
          next={nextFetchCallback}
        >
          {!totalNumberOfItems && (
            <Paragraph size="s">
              {formatMessage(generalMessages.noResultsFound)}
            </Paragraph>
          )}
          {libraryObjectList.map(libraryObject => {
            return (
              <DisplayCard
                key={libraryObject.id}
                title={libraryObject.name}
                subtitle={libraryObject.formattedType}
                iconName={libraryObject.iconName}
                version={getVersionName(libraryObject.versionNumber)}
                onClick={() => onSelectLibraryItem(libraryObject)}
                disabled={existingLibraryObjectIds?.includes(libraryObject.id)}
                selected={selectedLibraryObjectIds?.includes(libraryObject.id)}
              />
            );
          })}
        </InfiniteScroll>
      </div>
    </>
  );
};

export default InfiniteScrollLibraryContent;
