import { useIntl } from "react-intl";
import { Alert } from "@trace-one/design-system";
import Library from "components/Library";
import { LIBRARY_OBJECTS_PAGES } from "utils/constants";
import { isLibraryObjectLocked } from "utils/library";
import { ContentProps } from "../Content/types";
import libraryMessages from "messages/library";

const Material: React.FC<ContentProps> = ({
  libraryItemDetails,
  updateActionsInformation,
}) => {
  const { formatMessage } = useIntl();

  return (
    <>
      {!isLibraryObjectLocked(libraryItemDetails.generalInformation.state) && (
        <Alert
          showIcon
          closable
          message={formatMessage(libraryMessages.materialEditionAlertTitle)}
          description={formatMessage(
            libraryMessages.materialEditionAlertDescription
          )}
          type="warning"
          data-testid="warning-banner-material"
        />
      )}
      <Library.MaterialForm
        initialValues={libraryItemDetails}
        page={LIBRARY_OBJECTS_PAGES.EDIT}
        updateActionsInformation={updateActionsInformation}
      />
    </>
  );
};

export default Material;
