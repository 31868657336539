import { useEffect, useState } from "react";
import {
  fetchSpecificationPermissions as fetchSpecificationPermissionsApi,
  fetchSpecificationDraftPermissions,
} from "apis/SPEC";
import { SpecificationData } from "models";
import { Permissions } from "types/general";
import { isStateDraft } from "utils/specifications";

const useSpecificationPermissionsFromApi = ({
  specificationId,
  state,
}: {
  specificationId: SpecificationData["id"];
  state: SpecificationData["state"];
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [permissionList, setPermissionList] = useState<Permissions>([]);

  const fetchSpecificationPermissions = async () => {
    try {
      let permissions: Permissions;
      setIsLoading(true);

      if (isStateDraft(state)) {
        const {
          data: draftPermissions,
        } = await fetchSpecificationDraftPermissions({
          draftId: specificationId,
        });

        permissions = draftPermissions;
      } else {
        const {
          data: specificationPermissions,
        } = await fetchSpecificationPermissionsApi({
          specificationId,
        });

        permissions = specificationPermissions;
      }

      setPermissionList(permissions);
    } catch (error) {
    } finally {
      setIsLoading(false);
      setIsDone(true);
    }
  };

  useEffect(() => {
    if (specificationId) {
      fetchSpecificationPermissions();
    }
  }, [specificationId]);

  return {
    permissionList,
    isLoading,
    isDone,
    fetchSpecificationPermissions,
  };
};

export default useSpecificationPermissionsFromApi;
