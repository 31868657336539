import { createAsyncThunk } from "@reduxjs/toolkit";
import { AsyncThunkConfig } from "store";
import {
  fetchPackagingComponentSmartDoc,
  updatePackagingComponentName,
  updatePackagingComponentSectionTemplateBlock,
  updatePackagingComponentUsageLevel,
} from "apis/PKG";
import {
  PackagingSystemComponentData,
  PackagingSystemComponentObjectData,
} from "models/packaging";
import { SectionBlockData } from "models";
import {
  setPackagingComponentName,
  setPackagingComponentUsageLevel,
} from "./packagingSlice";

export const fetchPackagingComponentAsync = createAsyncThunk<
  PackagingSystemComponentObjectData,
  {
    packagingComponentId: PackagingSystemComponentData["componentId"];
    withoutLoading?: boolean;
  },
  AsyncThunkConfig
>(
  "packaging/fetchPackagingComponentSmartDoc",
  async ({ packagingComponentId }, { getState }) => {
    const {
      user: { userLanguagePreference },
    } = getState();

    const { data: packagingComponent } = await fetchPackagingComponentSmartDoc({
      packagingComponentId,
      languageCode: userLanguagePreference,
    });

    return packagingComponent;
  }
);

export const updatePackagingComponentAsync = createAsyncThunk<
  void,
  {
    packagingComponentId: PackagingSystemComponentData["componentId"];
    templateBlockId: SectionBlockData["templateBlockId"];
    dataAsJson: SectionBlockData["dataAsJson"];
  },
  AsyncThunkConfig
>(
  "packaging/updatePackagingComponent",
  async (
    { packagingComponentId, templateBlockId, dataAsJson },
    { dispatch }
  ) => {
    await updatePackagingComponentSectionTemplateBlock({
      packagingComponentId,
      templateBlockId,
      dataAsJson,
    });

    await dispatch(
      fetchPackagingComponentAsync({
        packagingComponentId,
        withoutLoading: true,
      })
    );
  }
);

export const updatePackagingComponentNameAsync = createAsyncThunk<
  void,
  {
    packagingComponentId: PackagingSystemComponentData["componentId"];
    packagingComponentName: PackagingSystemComponentData["name"];
  },
  AsyncThunkConfig
>(
  "packaging/updatePackagingComponentName",
  async ({ packagingComponentId, packagingComponentName }, { dispatch }) => {
    await updatePackagingComponentName({
      packagingComponentId,
      name: packagingComponentName,
    });

    dispatch(setPackagingComponentName(packagingComponentName));
  }
);

export const updatePackagingComponentUsageLevelAsync = createAsyncThunk<
  void,
  {
    packagingComponentId: PackagingSystemComponentData["componentId"];
    usageLevel: PackagingSystemComponentData["usageLevel"];
  },
  AsyncThunkConfig
>(
  "packaging/updatePackagingComponentUsageLevel",
  async ({ packagingComponentId, usageLevel }, { dispatch }) => {
    await updatePackagingComponentUsageLevel({
      packagingComponentId,
      usageLevel,
    });

    dispatch(setPackagingComponentUsageLevel(usageLevel));
  }
);
