import { defineMessages } from "react-intl";

export const messages = defineMessages({
  addNutrient: {
    id: "specificationDetails.widgets.nutrition.addNutrient",
    defaultMessage: "Add a nutrient",
  },
  addNutrientModalTitle: {
    id: "specificationDetails.widgets.nutrition.addNutrient.modalTitle",
    defaultMessage: "Add a nutrient",
  },
  secondaryButtonText: {
    id: "components.button.cancel",
    defaultMessage: "Cancel",
  },
  primaryButtonText: {
    id: "components.button.addToTable",
    defaultMessage: "Add to the table",
  },
  noItemFound: {
    id: "select.notFoundMessage",
    defaultMessage: "No item found",
  },
});
