import { Illustration, Paragraph } from "@trace-one/design-system";
import styles from "./styles.module.less";

const EmptyState = ({ description }) => {
  return (
    <div className={styles.noChapterTypeWrapper}>
      <Illustration color="grey" name="settings" />
      <Paragraph size="s" className={styles.title}>
        {description}
      </Paragraph>
    </div>
  );
};

export default EmptyState;
