import { SectionData, TemplateSectionData } from "models";

export const getOnUpdateSectionComment = ({
  dispatch,
  setSectionComment,
}: {
  dispatch: Function;
  setSectionComment: Function;
}) => ({
  comment,
  sectionId,
}: {
  comment: string;
  sectionId: SectionData["sectionId"];
}) => {
  dispatch(
    setSectionComment({
      comment,
      sectionId,
    })
  );
};
export const getOnMoveSection = ({
  dispatch,
  callback,
}: {
  dispatch: Function;
  callback: Function;
}) => (chapterId: string) => ({
  sectionId,
  number,
}: {
  sectionId: SectionData["sectionId"];
  number: number;
}) => {
  if (number !== 0) {
    dispatch(
      callback({
        chapterId,
        sectionId,
        number,
      })
    );
  }
};

export const getOnUpdateSection = ({
  dispatch,
  callback,
}: {
  dispatch: Function;
  callback: Function;
}) => (sectionId: SectionData["sectionId"]) => {
  dispatch(callback(sectionId));
};

export const getVisibleChapters = ({
  chapters,
}: {
  chapters: TemplateSectionData[];
}) => () => chapters.filter(({ visible }) => visible);
