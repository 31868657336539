import { useContext } from "react";
import MaterialList from "components/SpecificationSection/components/FormSchema/components/MaterialList";
import { AllergensArrayFieldTemplateContext } from "components/SpecificationSection/components/FormSchema/templates/ArrayFieldTemplate/AllergensArrayFieldTemplate/contexts";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";

const useColumns = () => {
  const { getTranslationForKey } = useFormSchemaTranslation();
  const { catalogDictionary } = useContext(AllergensArrayFieldTemplateContext);

  return {
    columns: [
      {
        title: getTranslationForKey("rawMaterialsAllergensName"),
        dataIndex: "allergenId",
        render: allergenId => catalogDictionary[allergenId],
      },
      {
        title: getTranslationForKey("rawMaterialsAllergensFromOrigin"),
        dataIndex: "ingredients",
        render: materials => {
          return <MaterialList materials={materials} />;
        },
      },
    ],
  };
};

export default useColumns;
