import WarningMessage from "components/Header/components/WarningMessage";
import { useWarnings } from "./hooks";
import { WarningsProps } from "./types";

const Warnings: React.FC<WarningsProps> = ({
  state,
  id,
  lastVersion,
  history,
}) => {
  const { warningMessage } = useWarnings({
    state,
    lastVersion,
    id,
    history,
  });

  return warningMessage ? (
    <>
      <WarningMessage>{warningMessage}</WarningMessage>
    </>
  ) : null;
};

export default Warnings;
