import { HistorisationFilters } from "components/SpecificationHistorisationModal/types";
import { isListEmpty } from "utils/general";

export const hasFilters = (selectedFilters: HistorisationFilters) => {
  let containsFilterCriterias = false;

  Object.keys(selectedFilters).forEach(key => {
    if (!isListEmpty(selectedFilters[key])) {
      containsFilterCriterias = true;
    }
  });

  return containsFilterCriterias;
};
