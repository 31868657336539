import { defineMessages } from "react-intl";

const messages = defineMessages({
  rawMaterialSupplierDetailsIngredient: {
    id: "library.rawMaterialForm.supplierDetails.ingredient.label",
    defaultMessage: "Ingredient",
  },
  rawMaterialSupplierDetailsIngredientPlaceholder: {
    id: "library.rawMaterialForm.supplierDetails.ingredient.placeholder",
    defaultMessage: "Choose an ingredient",
  },
  rawMaterialSupplierDetailsIngredientEmpty: {
    id: "library.rawMaterialForm.supplierDetails.ingredient.empty",
    defaultMessage: "No ingredient found",
  },
});

export default messages;
