import { LIBRARY_OBJECT_TYPES } from "utils/constants";
import { isFAndVOnly } from "utils/general";
import {
  isLibraryObjectPackagingSystemsType,
  isLibraryObjectTemplatesType,
} from "utils/library";

export const getOnTabClick = ({
  redirectToLibraryListing,
  currentType,
}: {
  redirectToLibraryListing: Function;
  currentType: string;
}) => (type: string) => {
  if (currentType !== type) redirectToLibraryListing(type);
};

export const checkIfUserHasPermissionForThisPage = ({
  isRetailer,
  isRetailerAdmin,
  type,
  redirectToHome,
}: {
  isRetailer: boolean;
  isRetailerAdmin: boolean;
  type: string;
  redirectToHome: Function;
}) => {
  if (
    (type === LIBRARY_OBJECT_TYPES.REQUIREMENTS && !isRetailer) ||
    (isLibraryObjectPackagingSystemsType(type) && isFAndVOnly) ||
    (isLibraryObjectTemplatesType(type) && !isRetailerAdmin)
  ) {
    redirectToHome();
  }
};
