import { useIntl } from "react-intl";
import { Button } from "@trace-one/design-system";
import { ErrorBoundary } from "react-error-boundary";
import { Row } from "antd";
import { translationManagementMessages } from "messages";
import StepTitle from "components/Library/components/StepTitle";
import CompanyLanguageCard from "./CompanyLanguageCard";
import WarningFallback from "components/WarningFallback";
import { useTranslationCompanies } from "components/TranslationManagement/hooks";
import Spin from "components/Spin";

export type TranslationCompaniesProps = {
  isLoading?: boolean;
};

const TranslationCompanies: React.FC<TranslationCompaniesProps> = ({
  isLoading,
}) => {
  const { formatMessage } = useIntl();

  const {
    companies,
    onAddCompany,
    onLanguageChange,
    onChange,
    excludedCompanies,
    excludedLanguages,
    onRemoveBlock,
  } = useTranslationCompanies();

  return (
    <ErrorBoundary FallbackComponent={WarningFallback}>
      <div className={"mt-2"}>
        <StepTitle
          step={2}
          title={formatMessage(
            translationManagementMessages.translationCompaniesTitle
          )}
          subtitle={formatMessage(
            translationManagementMessages.translationCompaniesSubtitle
          )}
        />

        {isLoading ? (
          <Spin />
        ) : (
          <>
            {companies?.map((company, index) => {
              return (
                <CompanyLanguageCard
                  key={index}
                  onCompanySelect={selectedCompany =>
                    onChange(index, "company", selectedCompany)
                  }
                  excludedCompanies={excludedCompanies}
                  excludedLanguages={excludedLanguages}
                  onLanguageSelect={selectedLanguage =>
                    onLanguageChange(index, "select", selectedLanguage)
                  }
                  onLanguageDeselect={deselectedLanguage =>
                    onLanguageChange(index, "deselect", deselectedLanguage)
                  }
                  companyBlock={company}
                  index={index}
                  onRemove={() => onRemoveBlock(index)}
                  onLanguagesClear={() => onChange(index, "languages", [])}
                  onCompanyClear={() => onChange(index, "company", null)}
                />
              );
            })}

            <Row className="mt-3">
              <Button type="secondary" onClick={onAddCompany}>
                {formatMessage(translationManagementMessages.addCompany)}
              </Button>
            </Row>
          </>
        )}
      </div>
    </ErrorBoundary>
  );
};

export default TranslationCompanies;
