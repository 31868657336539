import { Col, Row } from "antd";
import { WidgetProps } from "components/SpecificationSection/types";
import { getPropertiesObjectFromArray } from "../../templates/utils";

export const PackagingGtinWidget: React.FC<WidgetProps> = props => {
  const { properties, formData, formContext } = props;
  const { hasPermissionToSave } = formContext;

  const propertyObjects = getPropertiesObjectFromArray(properties);

  return (
    <Row>
      <Col xs={24}>
        {{
          ...propertyObjects["displayGtin"]?.content,
          props: {
            ...propertyObjects["displayGtin"]?.content?.props,
            disabled: !hasPermissionToSave,
          },
        }}
      </Col>
      <Col xs={24}>
        {{
          ...propertyObjects["displayedComponent"]?.content,
          props: {
            ...propertyObjects["displayedComponent"]?.content?.props,
            disabled: !formData.displayGtin || !hasPermissionToSave,
          },
        }}
      </Col>
    </Row>
  );
};

export default PackagingGtinWidget;
