import { defineMessages } from "react-intl";

export const messages = defineMessages({
  selectSuppliersPlaceholder: {
    id: "filters.supplier.placeholder",
    defaultMessage: "Select a supplier",
  },
  noSuppliers: {
    id: "filters.supplier.empty",
    defaultMessage: "No supplier",
  },
  selectRetailersPlaceholder: {
    id: "filters.retailer.placeholder",
    defaultMessage: "Select a retailer",
  },
  noRetailers: {
    id: "filters.retailer.empty",
    defaultMessage: "No retailer",
  },
});
