import { useEffect, useState } from "react";
import cleanDeep from "clean-deep";
import { v4 } from "uuid";
import { isListEmpty } from "utils/general";
import { cleanTableOnEmptyRow } from "../../../utils";
import { getOnFieldSave } from "components/SpecificationSection/components/FormSchema/utils";
import { CharacteristicFormData } from "components/SpecificationSection/components/FormSchema/types";

const useCharacteristicsField = ({
  formData,
  saveTemplateBlock,
  displayActions,
}: {
  formData: CharacteristicFormData[];
  saveTemplateBlock: Function;
  displayActions?: boolean;
}) => {
  const [actualForm, setActualForm] = useState(formData);

  useEffect(() => {
    setActualForm(JSON.parse(JSON.stringify(formData)));

    if (displayActions && isListEmpty(formData)) {
      const uid = v4();
      handleChange({ data: { id: uid }, rowIndex: 0, value: uid, name: "id" });
    }
  }, [formData, displayActions]);

  const handleChange = ({
    name,
    value,
    data,
    rowIndex,
  }: {
    name: string;
    value: boolean | string;
    data: CharacteristicFormData;
    rowIndex: number;
  }) => {
    setActualForm(previousItem => {
      let newForm = [];
      let index;

      if (isListEmpty(previousItem)) {
        newForm = [
          {
            id: data?.id,
            [name]: value,
          },
        ];
        index = 0;
      } else if (!data.id) {
        newForm = [
          ...previousItem,
          {
            id: v4(),
            [name]: value,
          },
        ];
        index = newForm.length - 1;
      } else {
        newForm = previousItem.map(currentItem => {
          if (currentItem.id === data.id) {
            return {
              ...currentItem,
              [name]: value,
            };
          }
          return currentItem;
        });

        const emptyRowCount = newForm?.filter(item => {
          const keys = Object.keys(item);
          return keys?.length === 1 && keys[0] === "id";
        })?.length;

        if (!emptyRowCount) {
          newForm = [
            ...newForm,
            {
              id: v4(),
            },
          ];
        }
        index = rowIndex < newForm.length ? rowIndex : newForm.length - 1;
      }

      let formToSend = [...newForm];

      if (newForm?.length > 1) {
        formToSend = cleanTableOnEmptyRow({
          array: newForm,
          index,
          id: "id",
        });
      }

      getOnFieldSave({ saveTemplateBlock })(
        cleanDeep(formToSend, { emptyArrays: false }),
        previousItem
      );

      return formToSend;
    });
  };

  return { actualForm, handleChange };
};

export default useCharacteristicsField;
