import { Dictionary } from "types/general";
import { isListEmpty } from "utils/general";

export const getRefListValues = ({
  ids,
  refListDictionary,
}: {
  ids: Array<string>;
  refListDictionary: Dictionary<string>;
}) => {
  if (isListEmpty(ids)) {
    return null;
  }

  return ids.map(id => refListDictionary[id]).join(", ");
};
