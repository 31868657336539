import { useIntl } from "react-intl";
import Chapters from "components/Chapters";
import StepTitle from "components/Library/components/StepTitle";
import EmptyState from "components/Library/components/EmptyState";
import { useTemplateForm } from "./hooks";
import { isListEmpty } from "utils/general";
import { isLibraryObjectDraft } from "utils/library";
import { ChaptersProps } from "components/Chapters/types";
import { LIBRARY_OBJECT_STATES } from "utils/constants";
import { templateMessages } from "messages";

export interface TemplateFormConfigureProps extends ChaptersProps {
  state?: LIBRARY_OBJECT_STATES;
}

export function TemplateFormConfigure({
  chapters,
  state,
}: TemplateFormConfigureProps) {
  const { formatMessage } = useIntl();

  const {
    onHideChapter,
    onUnhideChapter,
    onHideSection,
    onUnhideSection,
    onReorderSections,
    onUpdateUserGuide,
  } = useTemplateForm({});

  return (
    <>
      <StepTitle
        title={formatMessage(templateMessages.templateCustomizeStructure)}
        step={2}
        {...(!isListEmpty(chapters) && {
          subtitle: formatMessage(
            templateMessages.templateCustomizeStructureDescription
          ),
        })}
      />
      {!isListEmpty(chapters) ? (
        <Chapters
          chapters={chapters}
          displayCounter={false}
          displayActions={isLibraryObjectDraft(state)}
          onHideSection={undefined} // to be removed when template creation is removed from spec and folder wizards
          onUnhideSection={undefined} // to be removed when template creation is removed from spec and folder wizards
          onHideChapter={onHideChapter}
          onUnhideChapter={onUnhideChapter}
          onHideChapterSection={onHideSection}
          onUnhideChapterSection={onUnhideSection}
          onUpdateSectionComment={onUpdateUserGuide}
          onReorderSections={onReorderSections}
        />
      ) : (
        <EmptyState
          description={formatMessage(
            templateMessages.templateCustomizeStructureDescription
          )}
        />
      )}
    </>
  );
}
