import { useContext, useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Form } from "antd";
import { Tags } from "@trace-one/react-components";
import { Tag as TagTO } from "@trace-one/design-system";
import { selectLanguageCode } from "store/user/selectors";
import { isLibraryObjectLocked, isLibraryObjectPublished } from "utils/library";
import { ChapterFormDispatchContext } from "../../contexts";
import ChapterType from "./components/ChapterType";
import { useGeneralInfo, useGeneralInfoActions } from "./hooks";
import { APPLICATION_TYPE_IDS, MAX_CHAR_LENGTH } from "utils/constants";
import LibraryItemName from "components/Library/components/LibraryItemName";
import StepTitle from "components/Library/components/StepTitle";
import libraryMessages from "messages/library";
import { messages } from "components/Library/components/ChapterForm/messages";
import { GeneralInfoProps } from "./types";

const GeneralInfo: React.FC<GeneralInfoProps> = ({
  generalInfo,
  onFieldsChange,
  page,
}) => {
  const { formatMessage } = useIntl();
  const dispatch = useContext(ChapterFormDispatchContext);
  const languageCode = useSelector(selectLanguageCode);
  const {
    onUpdateChapterName,
    onUpdateChapterType,
    onUpdateProductType,
    onUpdateTags,
  } = useGeneralInfoActions();

  const { tagNames } = useGeneralInfo({ tagIds: generalInfo?.tagIds });

  const [form] = Form.useForm();

  useEffect(() => {
    dispatch?.({ type: "initializeForm", form });
  }, []);

  return (
    <Form
      name="chapterForm"
      layout="horizontal"
      validateMessages={{
        required: formatMessage(libraryMessages.formRequired),
        whitespace: formatMessage(libraryMessages.formRequired),
        string: {
          max: formatMessage(libraryMessages.formMaxCharLength, {
            max: MAX_CHAR_LENGTH,
          }),
        },
      }}
      initialValues={generalInfo}
      form={form}
      labelAlign="left"
      labelCol={{
        span: 4,
      }}
      wrapperCol={{
        xs: 24,
        md: 9,
      }}
      onFieldsChange={onFieldsChange}
    >
      <StepTitle
        title={formatMessage(messages.generalInformationTitle)}
        step={1}
      />
      <LibraryItemName
        onChange={onUpdateChapterName}
        defaultValue={generalInfo.name}
        libraryItemId={generalInfo.id}
        disableActions={
          isLibraryObjectPublished(generalInfo.state) ||
          isLibraryObjectLocked(generalInfo.state) ||
          generalInfo?.version?.number > 1
        }
        form={form}
        disabledOnBlur={true}
      />
      <ChapterType
        onUpdateChapterType={onUpdateChapterType}
        onUpdateProductType={onUpdateProductType}
        chapterType={generalInfo.type}
        productTypeId={generalInfo.productTypeId}
        chapterState={generalInfo.state}
        page={page}
      />
      <Form.Item
        name="tagIds"
        label={formatMessage(libraryMessages.tagsLabel)}
        wrapperCol={{
          xs: 24,
          md: 20,
        }}
      >
        {isLibraryObjectLocked(generalInfo.state) ? (
          tagNames?.map(tagName => (
            <TagTO mode="full" color="default" label={tagName} />
          ))
        ) : (
          <Tags.Select
            languageCode={languageCode}
            applicationTypeId={APPLICATION_TYPE_IDS.SPEC}
            tagIds={generalInfo?.tagIds || []}
            onTagListChange={onUpdateTags}
          />
        )}
      </Form.Item>
    </Form>
  );
};

export default GeneralInfo;
