import { FormContext, Property } from "components/SpecificationSection/types";
import { MAX_INPUT_NUMBER_LENGTH } from "utils/constants";
import { getInputNumberProps } from "../../widgets/utils";
import useTextActions from "../useTextActions";

const useInputNumberProps = ({
  properties,
  id,
  onChange,
  value,
  key,
  isTypingInProgress,
  onUpdateIsTypingInProgress,
}: {
  properties: Property;
  onChange: Function;
  value: string | number;
  id?: string;
  key?: string;
  isTypingInProgress: FormContext["isTypingInProgress"];
  onUpdateIsTypingInProgress: FormContext["onUpdateIsTypingInProgress"];
}) => {
  const { min, precision, step } = getInputNumberProps(properties);

  const {
    inputValue,
    handleInputNumberBlur,
    handleInputNumberChange,
  } = useTextActions({
    id,
    onChange: onChange,
    min,
    value,
    type: properties.type,
    precision,
    isTypingInProgress,
    onUpdateIsTypingInProgress,
  });

  return {
    precision,
    step,
    value: inputValue,
    onBlur: handleInputNumberBlur,
    onChange: handleInputNumberChange,
    maxLength: properties.maxLength || MAX_INPUT_NUMBER_LENGTH,
    "data-test-id": `${id}_${key}`,
  };
};

export default useInputNumberProps;
