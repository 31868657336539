import { Permissions } from "types/general";
import { isSpecificationSigned } from "utils/specifications";
import useRbac from "hooks/useRbac";
import { PERMISSIONS } from "utils/constants";
import { SpecificationState } from "types/specification";

const useSpecificationPermissions = ({
  state,
  permissions,
}: {
  state: SpecificationState;
  permissions?: Permissions;
}) => {
  const { hasPermission } = useRbac(permissions);

  const hasPermissionToUpdateRequirement =
    hasPermission(PERMISSIONS.SPECIFICATION.UPDATE_REQUIREMENTS) &&
    !isSpecificationSigned(state);

  const hasPermissionToUpdateSpecification =
    hasPermission(PERMISSIONS.SPECIFICATION.UPDATE) &&
    !isSpecificationSigned(state);

  const hasPermissionToValidateRequirements =
    hasPermission(PERMISSIONS.SPECIFICATION.VALIDATE_REQUIREMENTS) &&
    !isSpecificationSigned(state);

  const hasPermissionToValidateDocuments =
    hasPermission(PERMISSIONS.SPECIFICATION.VALIDATE_DOCUMENTS) &&
    !isSpecificationSigned(state);

  return {
    hasPermissionToUpdateRequirement,
    hasPermissionToUpdateSpecification,
    hasPermissionToValidateRequirements,
    hasPermissionToValidateDocuments,
  };
};

export default useSpecificationPermissions;
