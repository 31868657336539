import { isStateDraft } from "utils/specifications";

export const getOnChange = ({
  dispatch,
  removeProductSpecification,
  setFolderSpecification,
  tradeItemId,
}) => specificationId => isChecked => {
  if (isChecked) {
    dispatch(
      setFolderSpecification({
        tradeItemId,
        specificationId,
      })
    );
  } else {
    dispatch(
      removeProductSpecification({
        tradeItemId,
        specificationId,
      })
    );
  }
};

export const getOnSelectSupplier = ({
  dispatch,
  setProductSupplier,
  tradeItemId,
}) => event => {
  dispatch(
    setProductSupplier({
      tradeItemId,
      supplier: {
        name: event.label,
        id: event.value,
      },
    })
  );
};

export const getRemoveNewSpecification = ({
  dispatch,
  removeProductSupplier,
  tradeItemId,
}) => specificationId => () => {
  dispatch(
    removeProductSupplier({
      tradeItemId,
      specificationId,
    })
  );
};

export const getSpecificationsListToDisplay = ({
  displayActions,
  existingSpecifications,
  selectedSpecifications,
}) => (displayActions ? existingSpecifications : selectedSpecifications);

export const prepareSpecifications = ({ productSpecifications, folderId }) => {
  return productSpecifications.reduce(
    (previousState, specification) => {
      let newSpecifications = previousState.newSpecifications;
      let specifications = previousState.specifications;

      if (
        isStateDraft(specification.state) &&
        folderId === specification.folderId
      ) {
        newSpecifications = [specification, ...newSpecifications];
      } else {
        specifications = [...specifications, specification];
      }

      return {
        newSpecifications,
        specifications,
      };
    },
    {
      newSpecifications: [],
      specifications: [],
    }
  );
};
