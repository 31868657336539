import { useSelector } from "react-redux";
import {
  isModificationRequestActive,
  isModificationRequestDeclined,
  isModificationRequestDraft,
  isModificationRequestInProgress,
  isModificationRequestPerformed,
} from "utils/modificationRequest";
import { isObjectEmpty } from "utils/general";
import { ModificationRequestViewModel } from "viewModels/modificationRequest";
import { selectOwningCompanyId } from "store/user/selectors";
import { selectRequestModificationDetail } from "store/specificationDetails/selectors";

interface UseModificationRequestPermissionProps {
  userId: string;
  modificationRequest?: ModificationRequestViewModel;
  isSpecContact: boolean;
}

const useModificationRequestPermission = ({
  userId,
  modificationRequest,
  isSpecContact,
}: UseModificationRequestPermissionProps) => {
  const ownerCompanyId = useSelector(selectOwningCompanyId);

  const selectedModificationRequest = useSelector(
    selectRequestModificationDetail
  );

  const request = {
    ...(isObjectEmpty(modificationRequest)
      ? selectedModificationRequest
      : modificationRequest),
  };

  const {
    ownerUserId,
    ownerCompanyId: requestOwnerCompanyId,
    recipientCompanyId,
    state,
  } = request;

  const isRequestOwner = userId === ownerUserId;

  const canDelete = isRequestOwner && isModificationRequestDraft(state);

  const canCancel = isRequestOwner && isModificationRequestActive(state);

  const canNotify = requestOwnerCompanyId === ownerCompanyId;

  const canPerformAndDecline =
    recipientCompanyId === ownerCompanyId &&
    isSpecContact &&
    isModificationRequestInProgress(state);

  const canSetAsComplete =
    isRequestOwner &&
    (isModificationRequestPerformed(state) ||
      isModificationRequestDeclined(state));

  return {
    isRequestOwner,
    canCancel,
    canDelete,
    canPerformAndDecline,
    canSetAsComplete,
    canNotify,
  };
};

export default useModificationRequestPermission;
