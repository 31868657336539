import { SelectValue } from "antd/lib/select";
import { utils } from "@rjsf/core";
import ExternalCategoryTreeSelect from "components/CategoryTreeSelect/components/ExternalCategoryTreeSelect";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { CategoryListWidgetProps } from "./types";

const CategoryListWidget: React.FC<CategoryListWidgetProps> = ({
  schema = {
    list: { api: null, getItemBy: null, route: null },
  },
  uiSchema,
  value,
  allowClear: defaultAllowClear = false,
  placeholder,
  onChange,
  onDeselect: defaultOnDeselect,
}) => {
  const { getTranslationForKey } = useFormSchemaTranslation();
  const { list } = schema;

  const onSelect = (value: SelectValue, option) => {
    onChange(value, option);
  };

  const onDeselect = (value: SelectValue) => {
    if (typeof defaultOnDeselect === "function") {
      defaultOnDeselect(value);
    }
  };

  const { getUiOptions } = utils;
  const uiOptions = getUiOptions(uiSchema);
  const allowClear =
    uiOptions.allowClear || uiOptions["allow:clear"] || defaultAllowClear;

  return (
    <ExternalCategoryTreeSelect
      list={list}
      value={value}
      allowClear={allowClear && !!value}
      placeholder={getTranslationForKey(placeholder || "refListPlaceholder")}
      onSelect={onSelect}
      onDeselect={onDeselect}
    />
  );
};

export default CategoryListWidget;
