import { defineMessages } from "react-intl";

export const messages = defineMessages({
  alertMessage: {
    id: "specificationDetails.selectedChapter.alerts.warning.message",
    defaultMessage: "Awaiting supplier validation",
  },
  alertDescription: {
    id: "specificationDetails.selectedChapter.alerts.warning.description",
    defaultMessage:
      "This chapter is not yet validated by the supplier, this validation is mandatory in order to sign the specification.",
  },
});
