import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Alert, Heading, Paragraph, Button } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import DiscussionPanelHeader from "./components/DiscussionPanelHeader";
import DiscussionPanelFooter from "./components/DiscussionPanelFooter";
import DiscussionPanelMessage from "./components/DiscussionPanelMessage";
import ConfirmationTooltip from "components/ConfirmationTooltip";
import Spin from "components/Spin";
import { useModificationRequestPermission } from "hooks";
import { useDiscussionPanel } from "./hooks";
import {
  getModificationRequestionActions,
  getPanelHeight,
  isModificationRequestActive,
  isModificationRequestDraft,
} from "utils/modificationRequest";
import { useAppDispatch } from "store";
import { setRequestModification } from "store/specificationDetails/specificationDetailsSlice";
import {
  selectRequestModificationDetail,
  selectSelectedModificationRequestDiscussion,
} from "store/specificationDetails/selectors";
import { isListEmpty } from "utils/general";
import { prepareDiscussionPanelMessages } from "dtos/modificationRequest";
import {
  generalMessages,
  modificationRequestMessages,
  statusMessages,
} from "messages";
import styles from "./styles.module.less";

interface DiscussionPanelProps {
  selectedRequestId: string;
  onClose: Function;
  isSpecContact?: boolean;
  userId?: string;
}

const DiscussionPanel: React.FC<DiscussionPanelProps> = ({
  selectedRequestId,
  onClose,
  isSpecContact,
  userId,
}) => {
  const { formatMessage } = useIntl();

  const selectedModificationRequest = useSelector(
    selectRequestModificationDetail
  );

  const { state } = selectedModificationRequest || {};

  const selectedModificationRequestDiscussion = useSelector(
    selectSelectedModificationRequestDiscussion
  );

  const dispatch = useAppDispatch();

  const handleClose = () => {
    document
      .querySelector(".target-mode__border")
      ?.classList.remove("target-mode__border", "target-mode__background");
    onClose?.();
  };

  const messages = prepareDiscussionPanelMessages({
    modificationRequest: selectedModificationRequest,
    discussion: selectedModificationRequestDiscussion,
  });

  const {
    isLoading,
    onNotify,
    onPerform,
    onDecline,
    onComplete,
    onDelete,
    onArchive,
    discussionUsers = [],
  } = useDiscussionPanel({
    requestId: selectedRequestId,
    onClose: handleClose,
  });

  const {
    canCancel,
    canDelete,
    canPerformAndDecline,
    canSetAsComplete,
    canNotify,
  } = useModificationRequestPermission({
    userId,
    isSpecContact,
  });

  const footerTertiaryActions = [
    ...(canNotify
      ? [
          {
            key: formatMessage(generalMessages.notify),
            label: formatMessage(generalMessages.notify),
            disabled: !isModificationRequestDraft(state),
            ...(isModificationRequestDraft(state) && {
              tooltipProps: {
                onConfirmation: onNotify,
                placement: "left",
                getPopupContainer: trigger => trigger.parentNode,
                text: formatMessage(
                  modificationRequestMessages.draftBannerDescriptionLinkTooltip
                ),
              },
            }),
            "data-test-id": "discussion-panel-footer-notify",
          },
        ]
      : []),
    ...getModificationRequestionActions({
      formatMessage,
      onPerform,
      onDecline,
      canPerformAndDecline,
      onComplete,
      canSetAsComplete,
      canCancel,
      onArchive,
      canDelete,
      onDelete,
      state,
      tooltipPlacement: "left",
    }),
  ];

  const panelHeight = getPanelHeight();

  useEffect(() => {
    return () => {
      dispatch(
        setRequestModification({
          detail: null,
          discussion: null,
        })
      );
    };
  }, []);

  return (
    <>
      <div className={styles["discussion-panel-header-wrapper"]}>
        <DiscussionPanelHeader
          onClose={handleClose}
          modificationRequest={selectedModificationRequest}
        />
      </div>

      {isLoading && isListEmpty(messages) ? (
        <div
          className={styles["discussion-panel-loading-wrapper"]}
          style={{
            minHeight: panelHeight,
          }}
        >
          <Spin />
        </div>
      ) : (
        <div
          className={styles["discussion-panel-content-wrapper"]}
          style={{
            minHeight: panelHeight,
          }}
        >
          <>
            {isModificationRequestDraft(state) && (
              <Alert
                type="info"
                showIcon
                closable
                banner
                message={
                  <Heading size="xxs">
                    {formatMessage(statusMessages.draft)}
                  </Heading>
                }
                description={
                  <>
                    <Paragraph
                      size="xs"
                      className={
                        styles["discussion-panel__draft-banner-description"]
                      }
                    >
                      {formatMessage(
                        modificationRequestMessages.draftBannerDescription
                      )}
                    </Paragraph>
                    <ConfirmationTooltip
                      text={formatMessage(
                        modificationRequestMessages.draftBannerDescriptionLinkTooltip
                      )}
                      onConfirmation={onNotify}
                    >
                      <Button
                        type="link"
                        linkColor="blue"
                        size="small"
                        className={
                          styles[
                            "discussion-panel__draft-banner-description-link"
                          ]
                        }
                        data-test-id="discussion-panel-description-notify"
                      >
                        {formatMessage(
                          modificationRequestMessages.draftBannerDescriptionLink
                        )}
                      </Button>
                    </ConfirmationTooltip>
                  </>
                }
                className={styles["discussion-panel__draft-banner"]}
                data-testid="info-banner-discussion-panel"
              />
            )}

            <div
              style={{
                maxHeight: panelHeight - 88,
              }}
              className={styles["discussion-panel-message-list-wrapper"]}
            >
              {messages.map(message => (
                <DiscussionPanelMessage
                  key={message?.id}
                  message={message}
                  discussionUsers={discussionUsers}
                />
              ))}
            </div>

            <div className={styles["discussion-panel-footer-wrapper"]}>
              <DiscussionPanelFooter
                tertiaryActions={footerTertiaryActions}
                canSendMessage={
                  isSpecContact && isModificationRequestActive(state)
                }
              />
            </div>
          </>
        </div>
      )}
    </>
  );
};

export default DiscussionPanel;
