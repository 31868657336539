import { useAppDispatch } from "store";
import {
  removeOrigin,
  createOrigin,
  updateOrigin,
} from "store/materialForm/asyncActions";
import { Dictionary } from "types/general";
import { MaterialOriginData, MaterialSupplierData } from "models";

const useOriginActions = ({
  supplierId,
  refListDictionary,
  displayActions,
}: {
  supplierId: MaterialSupplierData["supplierId"];
  refListDictionary: Dictionary;
  displayActions: boolean;
}) => {
  const dispatch = useAppDispatch();

  const onAddOrigin = (typeId: MaterialOriginData["typeId"]) => {
    dispatch(createOrigin({ supplierId, typeId }));
  };

  const onRemoveOrigin = (typeId: MaterialOriginData["typeId"]) => async () => {
    await dispatch(
      removeOrigin({
        supplierId,
        typeId,
      })
    );
  };

  const onUpdateOrigin = async (payloadData: MaterialOriginData) => {
    await dispatch(
      updateOrigin({
        supplierId,
        payloadData,
      })
    );
  };

  const getValue = (value: string) => {
    if (displayActions) return value || null;
    return refListDictionary?.[value] || value;
  };

  return {
    onAddOrigin,
    onRemoveOrigin,
    onUpdateOrigin,
    getValue,
  };
};

export default useOriginActions;
