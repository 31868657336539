import { useEffect, useState } from "react";
import { useDebounce } from "@trace-one/business-components";
import { LabeledValue } from "antd/lib/select";
import { TreeSelectProps } from "antd";
import { useSelector } from "react-redux";
import {
  fetchCategoryItems,
  fetchCategoryListDirectChildren,
  fetchCategoryLists,
  searchCategoryItems,
} from "apis/RLMD";
import {
  selectLanguageCode,
  selectOwningCompanyId,
} from "store/user/selectors";
import { isListEmpty, parseToJSON } from "utils/general";
import {
  getPrepareItemTitle,
  getUniqueTreeData,
  mapToCategoriesValue,
  shouldCategoryListAPiBeCalled,
} from "components/CategoryTreeSelect/utils";
import { CategoryItemData } from "models";
import { Dictionary } from "types/general";
import {
  CategoryTreeItem,
  CategoryTreeSelectProps,
} from "components/CategoryTreeSelect/types";
import { ChangeEventExtra } from "rc-tree-select/lib/interface";
import { useAppDispatch } from "store";
import { updateCategoryDictionary } from "store/generalData/asyncActions";

const useCategoryTreeSelect = ({
  highlightedItems,
  multiple,
  value,
  onFullChange,
  minLengthToAsyncSearch,
  categoryId,
  onChange: defaultOnChange,
  onDropdownVisibleChange: defaultOnDropdownVisibleChange,
  shouldCurrentValueBeUpdated = true,
  multiParentSelection,
  shouldFirstLevelBeSelectable,
  shouldCallApi = true,
  parentId,
}: CategoryTreeSelectProps) => {
  const dispatch = useAppDispatch();
  const companyId = useSelector(selectOwningCompanyId);
  const languageCode = useSelector(selectLanguageCode);
  const [treeExpandedKeys, setTreeExpandedKeys] = useState<string[]>([]);
  const [valueWithLabel, setValueWithLabel] = useState(value);
  const [searchItemName, setSearchItemName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [treeData, setTreeData] = useState<CategoryTreeItem[]>([]);
  const [nameDictionary, setNameDictionary] = useState<Dictionary>({});

  const debouncedSearchItemName = useDebounce(searchItemName, 500);

  const onValueWithLabelChange = (value: CategoryTreeSelectProps["value"]) => {
    if (shouldCurrentValueBeUpdated) {
      setValueWithLabel(value);
    }
    onFullChange && onFullChange(value);
  };

  const onSearch = (searchValue: string) => {
    setSearchItemName(searchValue);
  };

  const onChange = (
    newValue: LabeledValue | LabeledValue[],
    labelist: React.ReactNode[],
    extra: ChangeEventExtra
  ) => {
    if (multiple) {
      const result = mapToCategoriesValue(newValue, multiParentSelection);

      onValueWithLabelChange(result);

      defaultOnChange(
        result.map(({ categoryId, categoryItemId }) => ({
          categoryId,
          categoryItemId,
        })),
        labelist,
        extra
      );
    } else {
      if (!newValue) {
        defaultOnChange(newValue, labelist, extra);
        onValueWithLabelChange(null);
      } else {
        const { label, value } = newValue as LabeledValue;

        const result = {
          categoryItemName: label,
          ...JSON.parse(value as string),
        };

        if (shouldCurrentValueBeUpdated) {
          prepareTreeData([result]);
        }

        dispatch(
          updateCategoryDictionary({
            ...nameDictionary,
            [result.categoryItemId]: result.categoryItemName,
          })
        );

        defaultOnChange(result.categoryItemId, labelist, extra);
        onValueWithLabelChange(result);
      }
    }
  };

  const prepareItemData = (
    item: CategoryItemData,
    parentId: string | number
  ) => {
    let prepareItemName = getPrepareItemTitle(highlightedItems, nameDictionary);

    const value = parseToJSON(item);

    return {
      id: item.categoryItemId,
      pId: parentId,
      key: value,
      value,
      title: prepareItemName({
        categoryItemId: item.categoryItemId,
        categoryItemName: item.categoryItemName,
      }),
      selectable: shouldFirstLevelBeSelectable ? true : !!parentId,
      categoryListId: item.categoryId,
    };
  };

  const prepareTreeData = (
    data: CategoryItemData[],
    shouldOverwrite: boolean = true
  ) => {
    let newExpandedKeys = [];
    let newNameDictionary: Dictionary = {};
    let newTreeData = data.reduce(
      (
        previousState,
        {
          categoryId: currentCategoryId,
          categoryItemId,
          categoryItemName,
          parent1CategoryItemId,
          parent1CategoryItemName,
          parent2CategoryItemId,
          parent2CategoryItemName,
          parent3CategoryItemId,
          parent3CategoryItemName,
          parent4CategoryItemId,
          parent4CategoryItemName,
        }
      ) => {
        let newState = [...previousState];
        let closestParentId = "";

        if (parent1CategoryItemId) {
          const item = prepareItemData(
            {
              categoryId: currentCategoryId,
              categoryItemId: parent1CategoryItemId,
              categoryItemName: parent1CategoryItemName,
              parent1CategoryItemId: parent2CategoryItemId,
              parent1CategoryItemName: parent2CategoryItemName,
              parent2CategoryItemId: parent3CategoryItemId,
              parent2CategoryItemName: parent3CategoryItemName,
              parent3CategoryItemId: parent4CategoryItemId,
              parent3CategoryItemName: parent4CategoryItemName,
              parent4CategoryItemId: null,
              parent4CategoryItemName: null,
            },
            parent2CategoryItemId
          );

          newState.push(item);

          newExpandedKeys.push(item.value);

          if (parent1CategoryItemName) {
            newNameDictionary[parent1CategoryItemId] = parent1CategoryItemName;
          }

          if (parent2CategoryItemId) {
            const item = prepareItemData(
              {
                categoryId: currentCategoryId,
                categoryItemId: parent2CategoryItemId,
                categoryItemName: parent2CategoryItemName,
                parent1CategoryItemId: parent3CategoryItemId,
                parent1CategoryItemName: parent3CategoryItemName,
                parent2CategoryItemId: parent4CategoryItemId,
                parent2CategoryItemName: parent4CategoryItemName,
                parent3CategoryItemId: null,
                parent3CategoryItemName: null,
                parent4CategoryItemId: null,
                parent4CategoryItemName: null,
              },
              parent3CategoryItemId
            );

            newState.push(item);

            newExpandedKeys.push(item.value);

            if (parent2CategoryItemName) {
              newNameDictionary[
                parent2CategoryItemId
              ] = parent2CategoryItemName;
            }

            if (parent3CategoryItemId) {
              const item = prepareItemData(
                {
                  categoryId: currentCategoryId,
                  categoryItemId: parent3CategoryItemId,
                  categoryItemName: parent3CategoryItemName,
                  parent1CategoryItemId: parent4CategoryItemId,
                  parent1CategoryItemName: parent4CategoryItemName,
                  parent2CategoryItemId: null,
                  parent2CategoryItemName: null,
                  parent3CategoryItemId: null,
                  parent3CategoryItemName: null,
                  parent4CategoryItemId: null,
                  parent4CategoryItemName: null,
                },
                parent4CategoryItemId
              );

              newState.push(item);

              newExpandedKeys.push(item.value);

              if (parent3CategoryItemName) {
                newNameDictionary[
                  parent3CategoryItemId
                ] = parent3CategoryItemName;
              }

              if (parent4CategoryItemId) {
                const item = prepareItemData(
                  {
                    categoryId: currentCategoryId,
                    categoryItemId: parent4CategoryItemId,
                    categoryItemName: parent4CategoryItemName,
                    parent1CategoryItemId: null,
                    parent1CategoryItemName: null,
                    parent2CategoryItemId: null,
                    parent2CategoryItemName: null,
                    parent3CategoryItemId: null,
                    parent3CategoryItemName: null,
                    parent4CategoryItemId: null,
                    parent4CategoryItemName: null,
                  },
                  0
                );

                newState.push(item);

                newExpandedKeys.push(item.value);

                if (parent4CategoryItemName) {
                  newNameDictionary[
                    parent4CategoryItemId
                  ] = parent4CategoryItemName;
                }

                closestParentId = parent4CategoryItemId;
              }

              closestParentId = parent3CategoryItemId;
            }

            closestParentId = parent2CategoryItemId;
          }

          closestParentId = parent1CategoryItemId;
        }

        if (!categoryId && !closestParentId) {
          closestParentId = currentCategoryId;
        }

        const item = prepareItemData(
          {
            categoryId: currentCategoryId,
            categoryItemId,
            categoryItemName,
            parent1CategoryItemId,
            parent1CategoryItemName,
            parent2CategoryItemId,
            parent2CategoryItemName,
            parent3CategoryItemId,
            parent3CategoryItemName,
            parent4CategoryItemId,
            parent4CategoryItemName,
          },
          closestParentId
        );

        newNameDictionary[categoryItemId] = categoryItemName;

        newState.push(item);

        return [...newState];
      },
      []
    );

    if (shouldOverwrite) {
      setTreeData(getUniqueTreeData(newTreeData));
      setTreeExpandedKeys(newExpandedKeys);
    } else {
      setTreeData(previousState =>
        getUniqueTreeData([...previousState, ...newTreeData])
      );
    }

    setNameDictionary(previousState => ({
      ...previousState,
      ...newNameDictionary,
    }));
  };

  const onLoadData = ({ id, categoryListId, pId }: CategoryTreeItem) =>
    fetchCategoryListDirectChildren({
      languageCode,
      categoryId: categoryListId,
      parentId: pId === 0 ? null : id,
    })
      .then(({ data }) => {
        prepareTreeData(data, false);
      })
      .catch(error => {});

  const getCategoryList = async () => {
    if (
      shouldCategoryListAPiBeCalled({
        multiple,
        valueWithLabel,
      })
    ) {
      try {
        setTreeExpandedKeys([]);

        setIsLoading(true);

        if (categoryId) {
          const { data } = await fetchCategoryListDirectChildren({
            languageCode,
            categoryId,
            parentId,
          });

          prepareTreeData(data);
        } else {
          const { data: categories } = await fetchCategoryLists({
            companyId,
          });

          setTreeData(
            categories.map(({ categoryListId, categoryListName }) => ({
              id: categoryListId,
              pId: 0,
              key: categoryListId,
              value: categoryListId,
              title: categoryListName,
              selectable: false,
              categoryListId,
            }))
          );
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    }
  };

  const searchByName = async () => {
    if (debouncedSearchItemName?.trim().length >= minLengthToAsyncSearch) {
      setIsLoading(true);

      try {
        const { data } = await searchCategoryItems({
          itemName: debouncedSearchItemName,
          languageCode,
          categoryId,
          parentCategoryItemId: parentId,
        });

        prepareTreeData(data);
      } catch (_) {
      } finally {
        setIsLoading(false);
      }
    }
  };

  const onTreeExpand = (expandedKeys: string[]) => {
    setTreeExpandedKeys(expandedKeys);
  };

  const onDropdownVisibleChange = (visible: boolean) => {
    if (visible) {
      getCategoryList();
    }
    defaultOnDropdownVisibleChange && defaultOnDropdownVisibleChange(visible);
  };

  let selectProps: {
    value?: TreeSelectProps<any>["value"];
  } = {};

  if (multiple) {
    selectProps.value = (valueWithLabel as CategoryItemData[])?.map(
      ({ categoryItemName, ...item }) => ({
        value: parseToJSON(item),
        label: categoryItemName,
      })
    );
  } else {
    const { categoryItemName, ...item } =
      (valueWithLabel as CategoryItemData) ?? {};

    if (!shouldCurrentValueBeUpdated) {
      selectProps.value = [];
    } else {
      selectProps.value = valueWithLabel
        ? { value: parseToJSON(item), label: categoryItemName }
        : null;
    }
  }

  /* This useEffect is used to get the info for the already selected value when we render the component */
  useEffect(() => {
    if (shouldCallApi) {
      let valueIdCollection: string[] = [];

      if (multiple) {
        valueIdCollection =
          (valueWithLabel as CategoryItemData[]).map(
            ({ categoryItemId }) => categoryItemId
          ) ?? [];
      } else if ((valueWithLabel as CategoryItemData)?.categoryItemId) {
        valueIdCollection.push(
          (valueWithLabel as CategoryItemData).categoryItemId
        );
      }

      if (isListEmpty(valueIdCollection)) {
        return;
      }

      fetchCategoryItems({
        languageCode,
        ids: valueIdCollection,
      })
        .then(({ data }) => {
          prepareTreeData(data);

          if (multiple) {
            onValueWithLabelChange(
              (valueWithLabel as CategoryItemData[])?.map(
                ({ categoryItemId, categoryItemName, ...rest }) => ({
                  ...rest,
                  categoryItemId,
                  categoryItemName: data.find(
                    item => item.categoryItemId === categoryItemId
                  )?.categoryItemName,
                })
              )
            );
          } else {
            if (!isListEmpty(data)) {
              const { categoryId, categoryItemName } = data[0];

              onValueWithLabelChange({
                ...valueWithLabel,
                categoryId,
                categoryItemName,
              });
            }
          }
        })
        .catch(error => {});
    } else {
      if (value) {
        prepareTreeData([value as CategoryItemData]);
      }
    }
  }, [shouldCallApi, languageCode]);

  useEffect(() => {
    if (!value && valueWithLabel) {
      onValueWithLabelChange(value);
    } else if (
      !multiple &&
      (value as CategoryItemData)?.categoryItemId !==
        (valueWithLabel as CategoryItemData)?.categoryItemId
    ) {
      prepareTreeData([value as CategoryItemData]);

      onValueWithLabelChange(value);
    }
  }, [value]);

  useEffect(() => {
    searchByName();
  }, [debouncedSearchItemName, languageCode]);

  return {
    onChange,
    onLoadData,
    onSearch,
    onTreeExpand,
    onDropdownVisibleChange,
    searchItemName,
    isLoading,
    treeData,
    selectProps,
    treeExpandedKeys,
  };
};

export default useCategoryTreeSelect;
