import { useState } from "react";
import classNames from "classnames";
import { Card } from "@trace-one/react-components";
import { Heading } from "@trace-one/design-system";
import { getOnToggle } from "utils/card";
import { FormCardProps } from "./types";
import styles from "../../styles.module.less";

const FormCard: React.FC<FormCardProps> = ({
  children,
  className,
  title,
  disableCollapse = false,
  name,
}) => {
  const [isCardOpen, setIsCardOpen] = useState(true);

  return (
    <div
      className={styles.rawMaterialFormSectionWrapper}
      data-test-id={`material-section-${name}`}
    >
      <Card
        className={classNames(className, {
          [styles.isCollapsed]: !isCardOpen,
        })}
        collapsible
        title={<Heading size="xs">{title}</Heading>}
        collapseProps={{
          in: isCardOpen,
          onToggle: getOnToggle({
            setIsCardOpen,
          }),
          disabled: disableCollapse,
        }}
        children={children}
      />
    </div>
  );
};

export default FormCard;
