import { MutableRefObject, useEffect, useRef, useState } from "react";
import qs from "qs";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { useIntl } from "react-intl";
import { fetchUsers } from "apis/CUMD";
import { UserData } from "models";
import {
  getRequestStatesForFilter,
  isModificationRequestStateFilterAll,
} from "utils/modificationRequest";
import { PaginatedList, PaginationParams } from "types/api";
import {
  ModificationRequestState,
  ModificationRequestStateFilter,
} from "types/modificationRequest";
import { generalMessages, statusMessages } from "messages";
import {
  MODIFICATION_REQUEST_STATE,
  MODIFICATION_REQUEST_STATE_FILTER,
} from "utils/constants";
import useBoolean from "hooks/useBoolean";
import { prepareModificationRequests } from "dtos/modificationRequest";
import { ModificationRequestData } from "models/modificationRequest";
import { ModificationRequestViewModel } from "viewModels/modificationRequest";

export interface UseModificationRequestPanelProps {
  ref: MutableRefObject<HTMLDivElement>;
  pageSize?: number;
  params?: {
    [key: string]: any;
  };
  fetcher: <T>(_: {
    params: PaginationParams & {
      [key: string]: any;
    };
    paramsSerializer?: AxiosRequestConfig["paramsSerializer"];
  }) => Promise<AxiosResponse<PaginatedList<T>>>;
  isDiscussionPanelOpen?: boolean;
  requestStateFilter?: ModificationRequestStateFilter;
}

export const useModificationRequestPanel = ({
  ref,
  pageSize = 10,
  params,
  fetcher,
  isDiscussionPanelOpen,
  requestStateFilter,
}: UseModificationRequestPanelProps) => {
  const { formatMessage } = useIntl();

  const [data, setData] = useState<ModificationRequestViewModel[]>([]);
  const [users, setUsers] = useState<UserData[]>([]);

  const [isLoading, setIsLoading] = useState(true);
  const [canLoadMore, setCanLoadMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalNumberOfItems, setTotalNumberOfItems] = useState(0);
  const [searchText, setSearchText] = useState<string>();
  const { value: shouldCallApi, setTrue, setFalse } = useBoolean();

  const isFirstRender = useRef(true);

  const loadData = async ({
    isNewSearch,
    requestStates,
  }: {
    searchText?: string;
    isNewSearch?: boolean;
    requestStates?: ModificationRequestState[];
  }) => {
    try {
      setIsLoading(true);
      setData(prevState => (isNewSearch ? [] : prevState));

      const {
        data: { items, totalNumberOfItems },
      } = await fetcher<ModificationRequestData>({
        params: {
          take: pageSize,
          skip: isNewSearch ? 0 : (currentPage - 1) * pageSize,
          ...(params || {}),
          requestStates,
        },
        paramsSerializer: params =>
          qs.stringify(params, { arrayFormat: "comma" }),
      });

      let newItemsLength = items.length;
      setCurrentPage(previousState => (isNewSearch ? 2 : previousState + 1));

      let allRequests = prepareModificationRequests(items);

      if (!isNewSearch) {
        allRequests = [...data, ...allRequests];
        newItemsLength = allRequests.length;
      }

      setData(allRequests);

      const userIds = Array.from(
        // @ts-ignore
        new Set(allRequests.map(item => item?.ownerUserId))
      );

      if (userIds?.length) {
        const { data: users } = await fetchUsers({ ids: userIds });
        setUsers(users);
      }

      setTotalNumberOfItems(prevState =>
        isModificationRequestStateFilterAll(requestStateFilter)
          ? totalNumberOfItems
          : prevState
      );

      setCanLoadMore(totalNumberOfItems > newItemsLength);
    } catch (_) {
    } finally {
      setIsLoading(false);
    }
  };

  const requestStateFilterOptions = [
    {
      name: formatMessage(generalMessages.all),
      value: MODIFICATION_REQUEST_STATE_FILTER.ALL,
      color: "amethyst",
      "data-test-id": "view-all",
    },
    {
      name: formatMessage(generalMessages.active),
      value: MODIFICATION_REQUEST_STATE_FILTER.ACTIVE,
      color: "amethyst",
      "data-test-id": "view-active",
    },
    {
      name: formatMessage(statusMessages.draft),
      value: MODIFICATION_REQUEST_STATE.DRAFT,
      color: "amethyst",
      "data-test-id": "view-draft",
    },
  ];

  useEffect(() => {
    loadData({
      requestStates: getRequestStatesForFilter(requestStateFilter),
      isNewSearch: true,
    });
  }, [requestStateFilter]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else if (!isDiscussionPanelOpen && !isFirstRender.current) {
      loadData({
        searchText,
        requestStates: getRequestStatesForFilter(requestStateFilter),
        isNewSearch: true,
      });
    } else if (isDiscussionPanelOpen && !isFirstRender.current) {
      setData([]);
    }
  }, [isDiscussionPanelOpen]);

  useEffect(() => {
    if (searchText !== undefined) {
      ref.current.scrollTo({ top: 0 });

      loadData({ searchText, isNewSearch: true });
    }
  }, [searchText]);

  useEffect(() => {
    if (shouldCallApi) {
      loadData({
        requestStates: getRequestStatesForFilter(requestStateFilter),
        isNewSearch: true,
      });
      setFalse();
    }
  }, [shouldCallApi]);

  return {
    isLoading,
    data,
    users,
    canLoadMore,
    currentPage,
    totalNumberOfItems,
    loadData,
    searchText,
    setSearchText,
    requestStateFilterOptions,
    refetchModificationRequests: setTrue,
  };
};

export default useModificationRequestPanel;
