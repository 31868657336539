import { useEffect, useState, useMemo } from "react";
import { Toggle } from "@trace-one/design-system";
import { isWidgetDisabled } from "../utils";
import { SwitchWidgetProps } from "./types";

const SwitchWidget: React.FC<SwitchWidgetProps> = ({
  value,
  formContext,
  disabled,
  readonly,
  schema,
  onChange,
  options,
}) => {
  const [currentValue, setCurrentValue] = useState<boolean>(value);
  const { propertyId } = schema;
  const { size = "small" } = options;
  const {
    readonlyAsDisabled = true,
    isWidgetEditable,
    isTargetMode,
  } = formContext;

  const isDisabled = useMemo(
    () =>
      isWidgetDisabled({
        isWidgetEditable: isWidgetEditable(propertyId),
        disabled,
        readonlyAsDisabled,
        readonly,
      }),
    [disabled, readonlyAsDisabled, readonly, propertyId, isTargetMode]
  );

  const onSwitchChange = (checked: boolean) => {
    onChange(checked);

    setCurrentValue(checked);
  };

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  return (
    <Toggle
      onChange={onSwitchChange}
      checked={currentValue}
      disabled={isDisabled}
      size={size}
    />
  );
};

export default SwitchWidget;
