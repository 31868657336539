import cx from "classnames";
import { Icon } from "@trace-one/design-system";
import { Link } from "react-router-dom";
import { ROUTES } from "utils/constants";
import ProgressBar from "components/Quickview/components/ProgressBar";
import { FolderRowProps } from "./types";
import styles from "components/Quickview/styles.module.less";
import { getCircleStyleKey } from "../utils";

const FolderRow: React.FC<FolderRowProps> = ({ specification }) => {
  const {
    progression,
    companyDisplayName,
    specificationId,
    state,
  } = specification;

  const {
    toFillSectionCount,
    inProgressSectionCount,
    validatedByRetailerSectionCount,
    validatedBySupplierSectionCount,
  } = progression;

  const totalCount =
    toFillSectionCount +
    inProgressSectionCount +
    validatedByRetailerSectionCount +
    validatedBySupplierSectionCount;

  return (
    <div
      className={styles.sectionRow}
      data-testid={`specification-${specificationId}`}
    >
      <div className={cx(styles.firstColumn, styles.name)}>
        <Icon name="hierarchy-link" size="small" />
        <Link
          to={ROUTES.SPECIFICATION_DETAILS.replace(
            ":specificationId",
            specificationId
          )}
          className={cx(styles.padding, styles.link)}
        >
          {companyDisplayName}
        </Link>
        <div className={styles.greyBar} />
      </div>
      <div className={styles.secondColumn}>
        <ProgressBar
          totalCount={totalCount}
          toFillSectionCount={toFillSectionCount}
          inProgressSectionCount={inProgressSectionCount}
          validatedBySupplierSectionCount={validatedBySupplierSectionCount}
          validatedByRetailerSectionCount={validatedByRetailerSectionCount}
        />
      </div>
      <div className={cx(styles.circleWrapper)}>
        <div className={cx(styles.circle, styles[getCircleStyleKey(state)])} />
      </div>
    </div>
  );
};

export default FolderRow;
