import { UploadFiles } from "@trace-one/business-components";
import { useSelector } from "react-redux";
import { selectSectionValidationDisabled } from "store/specificationDetails/selectors";
import { FILE_TYPES } from "utils/constants";
import { ActionsFilesProps } from "./types";
import styles from "../../../styles.module.less";

const ActionsFiles: React.FC<ActionsFilesProps> = ({ files, formContext }) => {
  const { sectionId, ownerCompanyId } = formContext;
  const isValidationDisabled = useSelector(
    selectSectionValidationDisabled(sectionId)
  );

  return (
    <div className={styles.uploadComponent}>
      <UploadFiles.Dragger
        multiple
        disabled={isValidationDisabled}
        fileList={files}
        onRemove={formContext?.extraActions?.removeFileAction}
        onUploadFiles={formContext?.extraActions?.uploadFilesAction}
        fileType={FILE_TYPES.SPECIFICATION_ATTACHMENT}
        size="large"
        ownerCompanyId={ownerCompanyId}
      />
    </div>
  );
};

export default ActionsFiles;
