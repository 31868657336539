import { FormattedMessage } from "react-intl";
import { Paragraph } from "@trace-one/design-system";
import { Col } from "antd";
import ResultTable from "components/ResultTable";
import { useTranslationPaginatedList } from "hooks";
import { errorMessages } from "messages";
import styles from "./styles.module.less";

const TranslationList = () => {
  const {
    isLoading,
    hasError,
    specifications,
    totalNumberOfSpecifications,
    pagination,
    onTableChange,
  } = useTranslationPaginatedList();

  return (
    <div className={styles["translation-list-container"]}>
      <Col>
        {hasError && (
          <Paragraph color="red" data-testid="searchErrorMessage">
            <FormattedMessage {...errorMessages.searchError} />
          </Paragraph>
        )}
        <ResultTable
          onTableChange={onTableChange}
          pagination={pagination}
          isLoading={isLoading}
          specifications={specifications}
          totalNumberOfSpecifications={totalNumberOfSpecifications}
          onUpdateFilters={null}
        />
      </Col>
    </div>
  );
};

export default TranslationList;
