import { createAsyncThunk } from "@reduxjs/toolkit";
import { RcFile } from "antd/lib/upload";
import { uploadFile } from "apis/DOCUMENT";
import { createTag } from "apis/TAGS";
import { FileData, TagData } from "models";
import { AsyncThunkConfig } from "store";
import { APPLICATION_TYPE_IDS, FILE_TYPES } from "utils/constants";

export const createClaimTag = createAsyncThunk<
  TagData,
  string,
  AsyncThunkConfig
>("claimForm/createTag", async (tagText, { getState, dispatch }) => {
  if (!tagText) return;

  try {
    const { data } = await createTag({
      applicationTypeId: APPLICATION_TYPE_IDS.SPEC,
      tagText,
      ignoreIfDoesExist: true,
    });

    return data;
  } catch (error) {}
});

export const uploadClaimLogoFile = createAsyncThunk<
  FileData,
  RcFile,
  AsyncThunkConfig
>("claimForm/uploadLogoFile", async (logoFile, { getState, dispatch }) => {
  if (!logoFile) return;

  try {
    const { data } = await uploadFile({
      file: logoFile,
      fileType: FILE_TYPES.CLAIM_LOGO,
    });

    return data;
  } catch (error) {}
});
