import { ChapterProgressData } from "models";

export const getOnSectionClick = ({
  onSectionClick,
  section,
}: {
  onSectionClick: Function;
  section: ChapterProgressData;
}) => () => {
  onSectionClick(section);
};
