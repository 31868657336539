import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectUserPermissions } from "store/oidc/selectors";
import { isListEmpty } from "utils/general";
import { Permissions } from "types/general";

const useRbac = (permissions?: Permissions) => {
  const userPermission = useSelector(selectUserPermissions);
  const [permissionsList, setPermissionsList] = useState(
    !!permissions ? permissions : userPermission
  );

  useEffect(() => {
    if (!!permissions) {
      setPermissionsList(permissions);
    }
  }, [permissions]);

  const hasPermission = (permissionToCheck: string) =>
    !isListEmpty(permissionsList) &&
    permissionsList.indexOf(permissionToCheck) > -1;

  const hasAtleastOnePermissions = (permissionsToCheck: Permissions) =>
    !isListEmpty(permissionsList) &&
    !isListEmpty(permissionsToCheck) &&
    permissionsToCheck.some(
      currentPermission => permissionsList.indexOf(currentPermission) > -1
    );

  const hasAllPermissions = (permissionsToCheck: Permissions) =>
    !isListEmpty(permissionsList) &&
    !isListEmpty(permissionsToCheck) &&
    permissionsToCheck.every(
      currentPermission => permissionsList.indexOf(currentPermission) > -1
    );

  return {
    hasPermission,
    hasAtleastOnePermissions,
    hasAllPermissions,
  };
};

export default useRbac;
