import { useEffect, useState } from "react";
import { Select } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import { MaterialRecipeIngredientData } from "models";
import { isObjectEmpty } from "utils/general";
import { filterOption } from "utils/select";
import { SelectRawMaterialsProps } from "./types";
import { messages } from "./messages";

const SelectRawMaterials: React.FC<SelectRawMaterialsProps> = ({
  ingredients,
  onSelectIngredient,
  onDeselectIngredient,
  type,
  allergenMaterials,
  disableActions,
}) => {
  const { formatMessage } = useIntl();
  const [selectedValues, setSelectedValues] = useState<
    MaterialRecipeIngredientData["ingredientId"][]
  >([]);

  useEffect(() => {
    let values = [];

    if (!isObjectEmpty(allergenMaterials?.[type])) {
      values = allergenMaterials[type];
    }

    setSelectedValues(values);
  }, [allergenMaterials, type]);

  return (
    <div id="select-raw-materials">
      <Select
        showArrow
        onDeselect={onDeselectIngredient}
        mode="multiple"
        options={ingredients.map(({ ingredientId, name }) => ({
          value: ingredientId,
          name,
        }))}
        value={selectedValues}
        onSelect={onSelectIngredient}
        placeholder={formatMessage(messages.selectMaterialPlaceholder)}
        filterOption={filterOption}
        getPopupContainer={() =>
          document.getElementById("select-raw-materials")
        }
        disabled={disableActions}
        allowClear={false}
      />
    </div>
  );
};

export default SelectRawMaterials;
