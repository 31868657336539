import { toaster as toasterService } from "@trace-one/design-system";
import {
  createSpecificationRequirements,
  deleteSpecificationRequirement,
  validateRequirement,
} from "apis/SPEC";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { RequirementType } from "types/library";
import { getRequirementType } from "utils/requirement";

const useRequirementsActions = ({
  specificationId,
  chapterType,
  refetchChapter,
  onCloseModal,
}: {
  specificationId: string;
  chapterType: string;
  refetchChapter: Function;
  onCloseModal: Function;
}) => {
  const { getTranslationForKey } = useFormSchemaTranslation();

  const onLinkRequirementsToSpecification = async (
    requirementIds: string[]
  ) => {
    try {
      await createSpecificationRequirements({
        specificationId,
        chapterType,
        requirementIds,
      });

      await refetchChapter();

      toasterService.confirmation({
        message: getTranslationForKey(
          "requirementsSectionAddARequirementLinkedMessage"
        ),
        description: getTranslationForKey(
          "requirementsSectionAddARequirementLinkedDescription"
        ),
      });

      onCloseModal();
    } catch (e) {}
  };

  const onUnlinkRequirementFromSpecification = async (
    requirementId: string
  ) => {
    try {
      await deleteSpecificationRequirement({
        specificationId,
        requirementId,
      });

      await refetchChapter();
    } catch (e) {}
  };

  const onValidateRequirement = async ({
    requirementId,
    requirementType,
  }: {
    requirementId: string;
    requirementType: RequirementType;
  }) => {
    try {
      await validateRequirement({
        requirementId,
        requirementType: getRequirementType(requirementType),
      });
      await refetchChapter();
    } catch {}
  };

  return {
    onLinkRequirementsToSpecification,
    onUnlinkRequirementFromSpecification,
    onValidateRequirement,
  };
};

export default useRequirementsActions;
