import { useEffect } from "react";
import { useHistory } from "react-router";
import { scrollToTop } from "utils/general";

export default function useScrollToTopOnRouteChange() {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      scrollToTop();
    });
    return () => {
      unlisten();
    };
  }, []);
}
