import { useEffect, useState, useMemo } from "react";
import { Row, Col } from "antd";
import classnames from "classnames";
import { Paragraph, Checkbox } from "@trace-one/design-system";
import { useWidgetActions } from "../../hooks";
import { isWidgetDisabled } from "../utils";
import { getOnCheckboxChange } from "./utils";
import { DisplayGtinWidgetProps } from "./types";
import styles from "./styles.module.less";
import widgetStyle from "../styles.module.less";

const DisplayGtinWidget = ({
  disabled,
  formContext,
  onChange,
  readonly,
  schema,
  value,
  options,
}: DisplayGtinWidgetProps) => {
  const {
    readonlyAsDisabled = true,
    isWidgetEditable,
    templateBlockId,
    sectionId,
    displayActions,
    productData,
  } = formContext;

  const { propertyId } = schema;
  const { isWidgetChanged } = useWidgetActions({ templateBlockId, sectionId });

  const isDisabled = useMemo(
    () =>
      isWidgetDisabled({
        isWidgetEditable: isWidgetEditable(propertyId),
        disabled,
        readonlyAsDisabled,
        readonly,
      }),
    [disabled, readonlyAsDisabled, readonly, propertyId]
  );

  const [checkboxValue, setCheckboxValue] = useState(value);

  useEffect(() => {
    setCheckboxValue(value);
  }, [value]);

  const colSpans = {
    xs: displayActions ? 24 : 8,
    sm: 8,
  };
  return (
    <Row>
      <Col {...colSpans} className={styles.label}>
        <Checkbox
          className={classnames({
            [widgetStyle.inRevisionChange]: isWidgetChanged(propertyId),
          })}
          checked={!!checkboxValue}
          disabled={isDisabled}
          onChange={getOnCheckboxChange({
            onChange,
            setCheckboxValue,
          })}
        />
        <Paragraph>{options?.title}</Paragraph>
      </Col>
      <Col {...colSpans} className={styles.value}>
        <Paragraph>{productData?.gtin}</Paragraph>
      </Col>
    </Row>
  );
};

export default DisplayGtinWidget;
