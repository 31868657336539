import { useEffect, useState } from "react";
import { FormContext } from "components/SpecificationSection/types";
import { CultivationOriginFormData } from "components/SpecificationSection/components/FormSchema/types";
import { isListEmpty } from "utils/general";
import { hasAlternativeOrigins } from "../../utils";

const useCultivationOriginWidget = ({
  formContext,
  formData,
}: {
  formContext: FormContext;
  formData: CultivationOriginFormData;
}) => {
  const { saveTemplateBlock } = formContext;

  const {
    acceptsAlternativeOrigin = false,
    alternativeOrigins,
    mainOrigins,
  } = formData;

  const [showAlternativeOrigin, setShowAlternativeOrigin] = useState(
    hasAlternativeOrigins(formData)
  );
  const [
    isAddEmptyAlternativeOriginItem,
    setIsAddEmptyAlternativeOriginItem,
  ] = useState(false);

  const addEmptyAlternativeOriginsItem = () => {
    setIsAddEmptyAlternativeOriginItem(false);

    saveTemplateBlock(
      JSON.stringify({
        acceptsAlternativeOrigin: true,
        alternativeOrigins: [{}],
        mainOrigins,
      })
    );
  };

  const handleAcceptsAlternativeOriginClick = (value: boolean) => {
    if (value) {
      setIsAddEmptyAlternativeOriginItem(true);
    } else {
      setShowAlternativeOrigin(false);
    }

    saveTemplateBlock(
      JSON.stringify({
        acceptsAlternativeOrigin: value,
        mainOrigins,
      })
    );
  };

  useEffect(() => {
    if (isAddEmptyAlternativeOriginItem) {
      addEmptyAlternativeOriginsItem();
    }
  }, [acceptsAlternativeOrigin]);

  useEffect(() => {
    setShowAlternativeOrigin(!isListEmpty(alternativeOrigins));
  }, [alternativeOrigins?.length]);

  return {
    showAlternativeOrigin,
    handleAcceptsAlternativeOriginClick,
  };
};

export default useCultivationOriginWidget;
