import { Redirect, useParams } from "react-router-dom";
import { useUserInfo } from "hooks";
import { ParamTypes } from "pages/Library/components/types";
import { getIsMaterialType } from "pages/Library/utils";
import { getComponentDisplayName } from "utils/general";

const withUserValidation = <P extends object>(
  WrappedComponent: React.ComponentType<P>
) => props => {
  const { type } = useParams<ParamTypes>();
  const { isRetailer } = useUserInfo();

  if (isRetailer && getIsMaterialType(type)) {
    return <Redirect to="/home" />;
  }

  WrappedComponent.displayName = getComponentDisplayName(
    withUserValidation,
    WrappedComponent
  );

  return <WrappedComponent {...(props as P)} />;
};

export default withUserValidation;
