import { Button } from "@trace-one/design-system";
import ConfirmationTooltip from "components/ConfirmationTooltip";
import { AllergenActionsProps } from "./types";

const AllergenActions: React.FC<AllergenActionsProps> = ({
  areActionsDisabled,
  onRemoveAllergen,
}) => {
  return (
    <ConfirmationTooltip onConfirmation={onRemoveAllergen}>
      <Button
        type="tertiary"
        iconName="trash"
        data-test-id="table-item-delete-icon"
        disabled={areActionsDisabled}
      />
    </ConfirmationTooltip>
  );
};

AllergenActions.defaultProps = {
  onRemoveAllergen: () => {},
};

export default AllergenActions;
