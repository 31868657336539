import { useMemo } from "react";
import { Datatable } from "@trace-one/design-system";
import TableHeader from "components/TableHeader";
import { FormattedMessage, useIntl } from "react-intl";
import Quickview, { QuickViewType } from "components/Quickview";
import { useRedirect, useRowSelection } from "hooks";
import {
  getNumberPageDisplayed,
  getRowKeyForTable,
  shouldPaginationBeDisplay,
} from "utils/table";
import { isStateDraft } from "utils/specifications";
import { getColumns, getOnCellClick } from "./utils";
import { messages } from "./messages";
import { ResultTableProps } from "./types";
import { quickViewMessages } from "components/Quickview/messages";
import { fetchFolderQuickView } from "apis/SPEC";
import { QuickViewTradeItemData } from "models";
import styles from "./styles.module.less";

export const ResultTable: React.FC<ResultTableProps> = ({
  pagination,
  onTableChange,
  folders,
  isLoading,
  totalNumberOfFolders,
}) => {
  const intl = useIntl();
  const { redirectToFolderDetails } = useRedirect();
  const { selectedRowKeys, onSelectRow, onSelectAllRows } = useRowSelection(
    "folderId"
  );
  const current = useMemo(
    () =>
      getNumberPageDisplayed({
        totalNumber: totalNumberOfFolders,
        current: pagination.current,
        pageSize: pagination.pageSize,
      }),
    [totalNumberOfFolders, pagination.current, pagination.pageSize]
  );

  return (
    <>
      <TableHeader
        listResult={intl.formatMessage(messages.listingResultNumber, {
          current,
          total: totalNumberOfFolders,
        })}
        tableActions={[]}
      />
      <Datatable
        loading={isLoading}
        rowClassName={styles.row}
        onChange={onTableChange}
        pagination={{
          ...pagination,
          total: totalNumberOfFolders,
          hideOnSinglePage: !shouldPaginationBeDisplay(totalNumberOfFolders),
        }}
        rowSelection={{
          selectedRowKeys,
          onSelect: onSelectRow,
          onSelectAll: onSelectAllRows,
        }}
        expandable={{
          expandedRowRender: record => (
            <Quickview.Specification
              type={QuickViewType.FOLDER}
              fetcher={() =>
                fetchFolderQuickView({
                  id: record.folderId,
                })
              }
              header={(totalItem, sectionNumber) => {
                return (
                  <FormattedMessage
                    {...quickViewMessages.folderQuickviewHeader}
                    values={{
                      nbOfSpec: totalItem,
                      nbOfSection: sectionNumber,
                    }}
                  />
                );
              }}
              body={(items: QuickViewTradeItemData[]) => {
                return <Quickview.FolderRowList tradeItems={items} />;
              }}
            ></Quickview.Specification>
          ),

          rowExpandable: ({ state }) => !isStateDraft(state),
        }}
        columns={getColumns({
          intl,
          onCellClick: getOnCellClick({ redirectToFolderDetails }),
        })}
        dataSource={folders}
        rowKey={getRowKeyForTable("folder")}
      />
    </>
  );
};

export default ResultTable;
