import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fetchSpecification } from "apis/SPEC";
import { fetchCompany } from "apis/CUMD";
import { fetchTradeItem } from "apis/PMD";
import { useAppDispatch } from "store";
import { selectLanguageCode } from "store/user/selectors";
import { setCompanyId } from "store/user/userSlice";
import { SpecificationPdfData } from "models";

const usePdfSpecificationData = ({
  specificationId,
}: {
  specificationId: string;
}) => {
  const dispatch = useAppDispatch();
  const languageCode = useSelector(selectLanguageCode);

  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [
    specificationData,
    setSpecificationData,
  ] = useState<SpecificationPdfData>({});

  const getCompanyName = async (id: string) => {
    if (id) {
      const {
        data: { companyDisplayName },
      } = await fetchCompany({
        id,
      });
      return companyDisplayName;
    } else {
      return null;
    }
  };

  const getTradeItemName = async (id: string) => {
    if (id) {
      const {
        data: { itemName },
      } = await fetchTradeItem({
        id,
      });

      return itemName;
    } else {
      return null;
    }
  };

  const getSpecificationDetailsPdf = async () => {
    setIsLoading(true);

    try {
      const { data } = await fetchSpecification({
        id: specificationId,
        languageCode,
      });

      dispatch(setCompanyId(data.ownerCompanyId));

      const results: PromiseSettledResult<string>[] = await Promise.allSettled([
        Promise.resolve(getTradeItemName(data.tradeItemId)),
        Promise.resolve(getCompanyName(data.supplierId)),
        Promise.resolve(getCompanyName(data.ownerCompanyId)),
      ]);

      const [tradeItemName, supplierName, ownerCompanyName] = results.reduce(
        (acc: string[], curr: PromiseSettledResult<string>) => {
          if (curr.status === "fulfilled") {
            acc.push(curr.value);
          }
          return acc;
        },
        []
      );

      setSpecificationData({
        ...data,
        supplierName,
        tradeItemName,
        ownerCompanyName,
      });
    } catch (e) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getSpecificationDetailsPdf();
  }, [specificationId]);

  return { isLoading, hasError, specificationData };
};

export default usePdfSpecificationData;
