import { Paragraph } from "@trace-one/design-system";
import { Row, Col, Form } from "antd";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { JSONSchema7 } from "json-schema";
import { DisplayProductNameOnPackWidgetProps } from "./types";

const DisplayProductNameOnPackWidget: React.FC<DisplayProductNameOnPackWidgetProps> = ({
  formContext,
  schema,
  registry,
  options,
  ...props
}) => {
  const {
    widgets: { SwitchWidget },
  } = registry;

  const { labelAlign, productData } = formContext;

  const { getTranslationForKey } = useFormSchemaTranslation();

  return (
    <Row>
      <Col span={23}>
        <Row align="middle" justify="space-between">
          <Col span={22}>
            <Form.Item
              label={getTranslationForKey(options.title)}
              labelAlign={labelAlign}
              labelCol={options?.["label:col"] as object}
              wrapperCol={options?.["wrapper:col"] as object}
            >
              <Paragraph data-test-id="product-product-name">
                {productData?.itemName}
              </Paragraph>
            </Form.Item>
          </Col>
          <Col span={2}>
            <SwitchWidget
              formContext={formContext}
              schema={schema as JSONSchema7}
              options={options}
              {...props}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default DisplayProductNameOnPackWidget;
