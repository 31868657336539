import { useMemo } from "react";
import { Col, Form, Row } from "antd";
import { SelectValue } from "antd/lib/select";
import { WidgetProps } from "components/SpecificationSection/types";
import SelectWidget from "../SelectWidget";
import { getPropertiesObjectFromArray } from "../../templates/utils";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";

export const PackagingDisplayedComponentWidget: React.FC<WidgetProps> = props => {
  const { formContext, properties, formData } = props;

  const propertyObjects = getPropertiesObjectFromArray(properties);

  const packagingComponentIdProps =
    propertyObjects?.["packagingComponentId"]?.content?.props;

  const { getTranslationForKey } = useFormSchemaTranslation();

  const {
    packagingSystemData: { generalInformation },
  } = formContext;

  const options: SelectValue = useMemo(() => {
    return generalInformation?.components?.map(component => {
      return {
        value: component.componentId,
        label: component.name,
      };
    });
  }, [generalInformation]);

  return (
    <Row>
      <Col md={8}>
        <Form.Item
          label={getTranslationForKey(
            packagingComponentIdProps?.uiSchema?.["ui:title"]
          )}
          labelCol={{ span: 24 }}
        >
          <SelectWidget
            {...packagingComponentIdProps}
            allowClear={true}
            options={{
              enumOptions: options,
            }}
            formContext={formContext}
            value={formData.packagingComponentId}
          />
        </Form.Item>
      </Col>
      <Col md={6} offset={1}>
        {propertyObjects?.["magnification"]?.content}
      </Col>
    </Row>
  );
};

export default PackagingDisplayedComponentWidget;
