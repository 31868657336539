import { useSelector } from "react-redux";
import { Button, Heading, Paragraph } from "@trace-one/design-system";
import cx from "classnames";
import { Col, Modal, Row } from "antd";
import { FormattedMessage } from "react-intl";
import { selectOwningCompanyName } from "store/user/selectors";
import ModalCloseIcon from "components/ModalCloseIcon";
import Error from "../Error";
import { useDoubleValidationSign } from "../../hooks";
import handShakeImg from "../../contract-handshake.svg";
import { RetailerProps } from "./types";
import { messages } from "../../messages";
import styles from "../../styles.module.less";

const Retailer: React.FC<RetailerProps> = ({
  companyName,
  onClose,
  tradeItem,
  visible,
  specificationId,
}) => {
  const owningCompanyName = useSelector(selectOwningCompanyName);

  const {
    handleSigning,
    closeModalHandler,
    handleRedirectionToFilteredSpecifications,
    handleRedirectionToSpecifications,
    isSpecSigned,
    isDone,
    hasSignError,
    specificationsWaitingNumber,
  } = useDoubleValidationSign({
    specificationId,
    onClose,
  });

  const renderSignatureSection = () => {
    return (
      <div>
        <div className={styles.information}>
          <img src={handShakeImg} alt="handshake" />
          <Heading size="s">
            <FormattedMessage {...messages.signature} />
          </Heading>
          <Paragraph size="m">
            <FormattedMessage
              {...messages.specificationSignedBySupplier}
              values={{ name: <span>{tradeItem}</span> }}
            />
          </Paragraph>
          <Heading size="xxs">
            <FormattedMessage {...messages.waitingForSignature} />
          </Heading>
          {hasSignError && <Error />}
        </div>
        <Row className={cx(styles.customButtons, styles.buttons)}>
          <Col xs={12}>
            <button disabled className={styles.active}>
              <FormattedMessage
                {...messages.signedBy}
                values={{ name: companyName }}
              />
            </button>
          </Col>
          <Col xs={12}>
            <button
              onClick={handleSigning}
              className={cx({
                [styles.active]: isSpecSigned,
              })}
              data-testid="sign-specifications-button"
            >
              <FormattedMessage
                {...messages.clickToSign}
                values={{ name: owningCompanyName }}
              />
            </button>
          </Col>
        </Row>
      </div>
    );
  };

  const renderConfirmationSection = () => {
    return (
      <div>
        <div className={styles.information}>
          <img src={handShakeImg} alt="handshake" />
          <Heading size="s">
            <FormattedMessage {...messages.specificationSigned} />
          </Heading>
          <Paragraph size="m">
            <FormattedMessage {...messages.contractSent} />
          </Paragraph>
        </div>
        <Row className={styles.buttons}>
          <Col xs={12}>
            <Button
              data-testid="view-all-specifications-button"
              type="secondary"
              onClick={handleRedirectionToSpecifications}
            >
              <FormattedMessage {...messages.viewAllSpecifications} />
            </Button>
          </Col>
          <Col xs={12}>
            <Button
              disabled={!specificationsWaitingNumber}
              data-testid="view-other-specifications-button"
              onClick={handleRedirectionToFilteredSpecifications(
                "signedBySupplier"
              )}
            >
              <FormattedMessage {...messages.signSpecifications} />
            </Button>
            <span className={styles.specificationsWaitingNumber}>
              <Heading size="xxs">{specificationsWaitingNumber}</Heading>
              <Paragraph size="m">
                <FormattedMessage
                  {...messages.specificationsLeft}
                  values={{ number: specificationsWaitingNumber }}
                />
              </Paragraph>
            </span>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <Modal
      centered
      open={visible}
      footer={null}
      closeIcon={
        <ModalCloseIcon data-testid="close-sign-specification-modal-icon" />
      }
      onCancel={closeModalHandler}
      maskStyle={{ backgroundColor: "rgba(0, 0, 0, 0.25)" }}
      width={800}
      wrapClassName={styles.modal}
    >
      {!isDone ? renderSignatureSection() : renderConfirmationSection()}
    </Modal>
  );
};

export default Retailer;
