import { AxiosRequestConfig, CancelToken } from "axios";
import {
  ChapterData,
  ChapterTypeData,
  ChapterVersion,
  ChapterVersionCreationAllowedData,
} from "models/chapter";
import {
  GeneralInfoData,
  LibraryObjectData,
  MaterialRecipeData,
  SpecificationListItemData,
  SpecificationQuickViewData,
} from "models";
import { axiosInstance } from "./";
import {
  ChapterIngredientsApiParams,
  ChapterSortingIngredientsApiParams,
  ChapterFreeFromAllergensApiParams,
  ChapterNutritionApiParams,
  PaginatedList,
  PaginationParams,
} from "types/api";
import { LibraryItemState } from "types/library";
import { CHAPTER_TYPES_API } from "utils/constants";

export const fetchChapter = ({
  chapterId,
  chapterType,
  languageCode,
}: {
  chapterId: GeneralInfoData["id"];
  chapterType: GeneralInfoData["type"];
  languageCode: string;
}) =>
  axiosInstance.get<ChapterData>(`/api/${chapterType}/${chapterId}`, {
    params: {
      languageCode,
    },
  });

export const updateChapterName = ({
  chapterId,
  chapterType,
  chapterName,
}: {
  chapterId: GeneralInfoData["id"];
  chapterType: GeneralInfoData["type"];
  chapterName?: GeneralInfoData["name"];
}) =>
  axiosInstance.put<ChapterData>(
    `/api/${chapterType}/${chapterId}/name`,
    chapterName,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

export const updateChapterType = ({
  chapterType,
  productTypeId,
  chapterName,
  tagIds,
  languageCode,
}: {
  chapterType: ChapterTypeData["chapterType"];
  chapterName?: GeneralInfoData["name"];
  tagIds?: GeneralInfoData["tagIds"];
  productTypeId: ChapterTypeData["productTypeId"];
  languageCode: string;
}) =>
  axiosInstance.post<ChapterData>(
    `/api/${chapterType}`,
    {
      name: chapterName,
      productTypeId,
      tagIds,
    },
    {
      params: {
        languageCode,
      },
    }
  );

export const deleteChapterType = ({
  chapterId,
  chapterType,
}: {
  chapterId?: GeneralInfoData["id"];
  chapterType?: GeneralInfoData["type"];
}) => axiosInstance.delete(`/api/${chapterType}/${chapterId}`);

export const fetchChapterTypes = ({ languageCode }: { languageCode: string }) =>
  axiosInstance.get<ChapterTypeData[]>(`/api/chapter-types`, {
    params: {
      languageCode,
    },
  });

export const addChapterTags = ({
  chapterId,
  chapterType,
  tagIds,
}: {
  chapterId: GeneralInfoData["id"];
  chapterType: GeneralInfoData["type"];
  tagIds: GeneralInfoData["tagIds"];
}) =>
  axiosInstance.post<ChapterData>(
    `/api/${chapterType}/${chapterId}/tags`,
    tagIds,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

export const deleteChapterTag = ({
  chapterId,
  chapterType,
  tagId,
}: {
  chapterId: GeneralInfoData["id"];
  chapterType: GeneralInfoData["type"];
  tagId: string;
}) =>
  axiosInstance.delete<ChapterData>(
    `/api/${chapterType}/${chapterId}/tags/${tagId}`
  );

export const updateChapterBlock = ({
  chapterId,
  chapterType,
  blockId,
  blockJson,
}: {
  chapterId: GeneralInfoData["id"];
  chapterType: GeneralInfoData["type"];
  blockId?: string;
  blockJson?: string;
}) =>
  axiosInstance.put<ChapterData>(
    `/api/${chapterType}/${chapterId}/blocks/${blockId}`,
    JSON.stringify(blockJson),
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

export const validateChapterSection = ({
  chapterId,
  chapterType,
}: {
  chapterId: GeneralInfoData["id"];
  chapterType: GeneralInfoData["type"];
}) => (sectionId?: string) =>
  axiosInstance.post(
    `/api/${chapterType}/${chapterId}/validate`,
    {},
    {
      params: {
        sectionId,
      },
    }
  );

export const fetchChapters = ({
  params,
  paramsSerializer,
}: {
  params: PaginationParams & {
    ownerCompanyId: GeneralInfoData["ownerCompanyId"];
    productTypeId?: GeneralInfoData["productTypeId"];
    chapterTypes?: GeneralInfoData["type"][];
    states?: LibraryItemState[];
    name?: GeneralInfoData["name"];
    version?: string;
  };
  paramsSerializer?: AxiosRequestConfig["paramsSerializer"];
}) =>
  axiosInstance.get<PaginatedList<LibraryObjectData>>(`/api/chapters/search`, {
    params,
    paramsSerializer,
  });

export const shareChapter = ({
  chapterId,
  chapterType,
  companyId,
}: {
  chapterId: GeneralInfoData["id"];
  chapterType: GeneralInfoData["type"];
  companyId?: string;
}) =>
  axiosInstance.post(`/api/${chapterType}/${chapterId}/share`, {
    companyId,
  });

export const publishChapter = ({
  chapterId,
  chapterType,
}: {
  chapterId?: GeneralInfoData["id"];
  chapterType?: GeneralInfoData["type"];
}) => axiosInstance.post(`/api/${chapterType}/${chapterId}/publish`);

export const createChapterNutrition = ({
  chapterId,
  chapterType,
  nutrientIds,
}: {
  chapterId?: ChapterNutritionApiParams["chapterId"];
  chapterType?: ChapterNutritionApiParams["chapterType"];
  nutrientIds: string[];
}) =>
  axiosInstance.post<ChapterData>(
    `/api/${chapterType}/${chapterId}/nutrients`,
    nutrientIds
  );

export const updateChapterNutrition = ({
  chapterId,
  chapterType,
  nutrientId,
  data,
}: ChapterNutritionApiParams) =>
  axiosInstance.put<ChapterData>(
    `/api/${chapterType}/${chapterId}/nutrients/${nutrientId}`,
    data
  );

export const removeChapterNutrient = ({
  chapterId,
  chapterType,
  nutrientId,
}: ChapterNutritionApiParams) =>
  axiosInstance.delete<ChapterData>(
    `/api/${chapterType}/${chapterId}/nutrients/${nutrientId}`
  );

export const createChapterIngredient = ({
  id,
  chapterType,
  materialId,
}: ChapterIngredientsApiParams) =>
  axiosInstance.post<ChapterData>(`/api/${chapterType}/${id}/ingredients`, {
    materialId,
  });

export const updateEnergyCalculation = ({
  id,
  chapterType,
  automaticCalculation,
}: ChapterIngredientsApiParams) =>
  axiosInstance.put<ChapterData>(
    `/api/${chapterType}/${id}/energy-calculation`,
    {
      automaticCalculation,
    }
  );

export const deleteChapterIngredient = ({
  id,
  chapterType,
  ingredientId,
}: ChapterIngredientsApiParams) =>
  axiosInstance.delete<ChapterData>(
    `/api/${chapterType}/${id}/ingredients/${ingredientId}`
  );

export const updateChapterRecipeIngredient = ({
  id,
  chapterType,
  ingredientId,
  percentage,
  functionId,
}: ChapterIngredientsApiParams) =>
  axiosInstance.put<ChapterData>(
    `/api/${chapterType}/${id}/ingredients/${ingredientId}`,
    {
      percentage,
      functionId,
    }
  );

export const fetchChapterRecipe = ({
  id,
  chapterType,
}: {
  id?: GeneralInfoData["id"];
  chapterType?: GeneralInfoData["type"];
}) => axiosInstance.get<MaterialRecipeData>(`/api/${chapterType}/${id}/recipe`);

export const sortChapterRecipe = ({
  id,
  chapterType,
  orderBy,
  orderByDescending,
}: ChapterSortingIngredientsApiParams) =>
  axiosInstance.post<MaterialRecipeData>(
    `/api/${chapterType}/${id}/sort-recipe`,
    {},
    {
      params: {
        orderBy,
        orderByDescending,
      },
    }
  );

export const updateChapterSites = ({
  chapterId,
  chapterType,
  siteIds,
}: {
  chapterId?: GeneralInfoData["id"];
  chapterType?: GeneralInfoData["type"];
  siteIds?: string;
}) =>
  axiosInstance.put<ChapterData>(
    `/api/${chapterType}/${chapterId}/sites`,
    JSON.stringify(siteIds),
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

export const fetchChapterAllergens = ({
  id,
  chapterType,
  unintentionalPresent,
}: {
  id: GeneralInfoData["id"];
  chapterType: GeneralInfoData["type"];
  unintentionalPresent?: boolean;
}) =>
  axiosInstance.get(`/api/${chapterType}/${id}/allergens`, {
    params: {
      unintentionalPresent,
    },
  });

export const updateChapterCrossContaminationAllergen = ({
  chapterId,
  chapterType,
  allergenId,
  controlledRisk,
}: {
  chapterId?: GeneralInfoData["id"];
  chapterType?: GeneralInfoData["type"];
  allergenId?: string;
  controlledRisk?: boolean;
}) =>
  axiosInstance.put<ChapterData>(
    `/api/${chapterType}/${chapterId}/cross-contamination`,
    JSON.stringify({
      allergenId,
      controlledRisk,
    }),
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

export const addChapterFreeFromAllergens = ({
  chapterId,
  chapterType,
  allergenIds,
}: ChapterFreeFromAllergensApiParams) =>
  axiosInstance.post<ChapterData>(
    `/api/${chapterType}/${chapterId}/free-from`,
    allergenIds
  );

export const deleteChapterFreeFromAllergen = ({
  chapterId,
  chapterType,
  allergenId,
}: ChapterFreeFromAllergensApiParams) =>
  axiosInstance.delete<ChapterData>(
    `/api/${chapterType}/${chapterId}/free-from/${allergenId}`
  );

export const updateChapterFreeFromAllergen = ({
  chapterId,
  chapterType,
  allergenId,
  fileId,
}: ChapterFreeFromAllergensApiParams) =>
  axiosInstance.put<ChapterData>(
    `/api/${chapterType}/${chapterId}/free-from`,
    JSON.stringify({
      allergenId,
      proofFileId: fileId,
    }),
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

export const fetchChapterSpecifications = ({
  chapterType,
  chapterId,
  params,
}: {
  chapterType: string;
  chapterId: string;
  params: PaginationParams & {
    languageCode?: string;
    states?: string;
  };
}) =>
  axiosInstance.get<PaginatedList<SpecificationListItemData>>(
    `/api/${chapterType}/${chapterId}/specifications`,
    {
      params,
    }
  );

export const createChapterVersion = ({
  chapterType,
  chapterId,
  params,
}: {
  chapterType: GeneralInfoData["type"];
  chapterId: GeneralInfoData["id"];
  params: {
    languageCode?: string;
  };
}) =>
  axiosInstance.post<GeneralInfoData["id"]>(
    `/api/${chapterType}/${chapterId}/new-version`,
    {},
    {
      params,
    }
  );

export const duplicateChapter = ({
  chapterType,
  chapterId,
  params,
}: {
  chapterType: GeneralInfoData["type"];
  chapterId: GeneralInfoData["id"];
  params: {
    languageCode?: string;
  };
}) =>
  axiosInstance.post<ChapterData>(
    `/api/${chapterType}/${chapterId}/duplicate`,
    {},
    {
      params,
    }
  );

export const getIsChapterVersionCreationAllowed = ({
  chapterType,
  chapterId,
}: {
  chapterType: GeneralInfoData["type"];
  chapterId: GeneralInfoData["id"];
}) =>
  axiosInstance.get<ChapterVersionCreationAllowedData>(
    `/api/${chapterType}/${chapterId}/allows-new-version`
  );

export const fetchChapterQuickView = ({
  chapterType,
  chapterId,
}: {
  chapterType: GeneralInfoData["type"];
  chapterId: GeneralInfoData["id"];
}) =>
  axiosInstance.get<SpecificationQuickViewData[]>(
    `/api/${chapterType}/${chapterId}/quick-view`
  );

export const fetchChapterVersions = ({
  chapterType,
  chapterId,
}: {
  chapterType: GeneralInfoData["type"];
  chapterId: GeneralInfoData["id"];
}) =>
  axiosInstance.get<ChapterVersion[]>(
    `/api/${chapterType}/${chapterId}/versions`
  );

export const searchChapterTranslations = ({
  chapterType,
  params,
  cancelToken,
}: {
  chapterType: GeneralInfoData["type"];
  params: PaginationParams & {
    languageCode?: string;
  };
  cancelToken?: CancelToken;
}) =>
  axiosInstance.get<PaginatedList<SpecificationListItemData>>(
    `/api/${CHAPTER_TYPES_API[chapterType]}-translations/search`,
    {
      params,
      ...(cancelToken && {
        cancelToken,
      }),
    }
  );
