import { createContext } from "react";
import { FreeFromAllergensArrayFieldTemplateProviderProps } from "./types";

export const FreeFromAllergensArrayFieldTemplateContext = createContext(null);
export const FreeFromAllergensArrayFieldTemplateDispatchContext = createContext(
  null
);

export const FreeFromAllergensArrayFieldTemplateProvider = ({
  children,
  onRemoveAllergen,
  onUploadFile,
  onRemoveFile,
  displayActions,
  ownerCompanyId,
}: FreeFromAllergensArrayFieldTemplateProviderProps) => {
  return (
    <FreeFromAllergensArrayFieldTemplateContext.Provider
      value={{
        onRemoveAllergen,
        onUploadFile,
        onRemoveFile,
        displayActions,
        ownerCompanyId,
      }}
    >
      <FreeFromAllergensArrayFieldTemplateDispatchContext.Provider
        value={() => {}}
      >
        {children}
      </FreeFromAllergensArrayFieldTemplateDispatchContext.Provider>
    </FreeFromAllergensArrayFieldTemplateContext.Provider>
  );
};
