import { WizardData } from "models";
import { axiosInstance } from "./";

export const updateWizardCurrentStep = ({
  id,
  step,
}: {
  id: string;
  step: string;
}) =>
  axiosInstance.post<WizardData>(`/api/wizards/${id}/current-step`, null, {
    params: { step },
  });

export const validateWizardStep = ({
  id,
  step,
}: {
  id: string;
  step: string;
}) =>
  axiosInstance.post<WizardData>(`/api/wizards/${id}/validate`, null, {
    params: { step },
  });

export const invalidateWizardSteps = ({
  id,
  step,
}: {
  id: string;
  step: string;
}) =>
  axiosInstance.post<WizardData>(`/api/wizards/${id}/invalidate`, null, {
    params: { step },
  });

export const updateWizardCollaboration = ({
  id,
  collaboratingCompanyId,
  isEnabled,
}: {
  id: string;
  collaboratingCompanyId: string;
  isEnabled: boolean;
}) =>
  axiosInstance.post<WizardData>(
    `/api/wizards/${id}/collaborations/${collaboratingCompanyId}`,
    null,
    {
      params: { enabled: isEnabled },
    }
  );
