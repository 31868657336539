import Container from "./components/Container";
import OwnersOrContacts from "./components/OwnersOrContacts";
import ProductTreeCategories from "./components/ProductTreeCategories";
import ProductTypes from "./components/ProductTypes";
import Statuses from "./components/Statuses";
import RequirementType from "./components/RequirementType";

const Filters = {
  Container,
  OwnersOrContacts,
  ProductTreeCategories,
  ProductTypes,
  Statuses,
  RequirementType,
};

export default Filters;
