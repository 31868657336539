export const isLoading = ({
  isSpecLoading,
  isTradeItemsLoading,
}: {
  isSpecLoading: boolean;
  isTradeItemsLoading: boolean;
}) => isSpecLoading || isTradeItemsLoading;

export const hasError = ({
  hasSpecError,
  hasTradeItemsError,
}: {
  hasSpecError: boolean;
  hasTradeItemsError: boolean;
}) => hasSpecError || hasTradeItemsError;
