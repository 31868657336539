import { FormattedMessage } from "react-intl";
import { isStateDraft } from "utils/specifications";
import WarningMessage from "components/Header/components/WarningMessage";
import ResumeButton from "./components/ResumeButton";
import { messages } from "./messages";
import { ActionProps } from "./types";
import styles from "../../../../styles.module.less";

const Action: React.FC<ActionProps> = ({ id, state }) => {
  return (
    <div className={styles.actionWrapper}>
      {isStateDraft(state) && (
        <>
          <WarningMessage>
            <FormattedMessage {...messages.draft} />
          </WarningMessage>
          <ResumeButton id={id} />
        </>
      )}
    </div>
  );
};

Action.defaultProps = {
  id: null,
  state: null,
};

export default Action;
