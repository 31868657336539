import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "creationFolder.title",
    defaultMessage: "Folder creation",
  },

  folderDetails: {
    id: "creationFolder.details.title",
    defaultMessage: "Folder details",
  },

  folderDetailsSub: {
    id: "creationFolder.details.subtitle",
    defaultMessage: "Please, enter basic information about your folder",
  },

  formFolderName: {
    id: "creationFolder.form.folderName",
    defaultMessage: "Folder name",
  },

  formFolderNamePlaceholder: {
    id: "creationFolder.form.folderName.placeholder",
    defaultMessage: "Fill out the folder's name",
  },

  formProductType: {
    id: "creationFolder.form.productType",
    defaultMessage: "Product type",
  },

  formProductTypePlaceholder: {
    id: "creationFolder.form.productType.placeholder",
    defaultMessage: "Choose a product type",
  },

  formTargetDate: {
    id: "creationFolder.form.targetDate",
    defaultMessage: "Target date",
  },

  formTargetDatePlaceholder: {
    id: "creationFolder.form.targetDate.placeholder",
    defaultMessage: "dd/mm/yyyy",
  },

  formDescription: {
    id: "creationFolder.form.description",
    defaultMessage: "Description",
  },

  productTypeTooltipTitle: {
    id: "creation.productTypeTooltip.title",
    defaultMessage:
      "Please remove the assigned products to change the product type",
  },

  errorFieldMessage: {
    id: "creation.errors.notSaved",
    defaultMessage: "An error has occurred, the last changes were not saved",
  },
});
