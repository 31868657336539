import { Heading, Paragraph, Spinner } from "@trace-one/design-system";
import { FormattedMessage } from "react-intl";
import { useProductType } from "./hooks";
import { messages } from "./messages";
import { ProductTypeProps } from "./types";
import styles from "../../styles.module.less";

const ProductType: React.FC<ProductTypeProps> = props => {
  const { isLoading, productType } = useProductType(props);

  return (
    <>
      <Heading className={styles.title} size="xs">
        <FormattedMessage {...messages.productType} />
      </Heading>
      {isLoading ? (
        <Spinner />
      ) : (
        <Paragraph color="grey-3">{productType}</Paragraph>
      )}
    </>
  );
};

export default ProductType;
