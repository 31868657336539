import { useContext, useEffect, useState } from "react";
import { FolderListingContext } from "pages/Folder/components/List/context";
import { useFolderActions, useFolderPermissions } from "hooks";
import { FolderData } from "models";
import { MenuActions } from "types/general";
import { isObjectEmpty } from "utils/general";
import { getActionsList } from "./utils";

const useActions = ({ folder }: { folder: FolderData }) => {
  const { setShouldRefetchFolders, resetCurrentPage, currentPage } = useContext(
    FolderListingContext
  );
  const [actionList, setActionList] = useState<MenuActions[]>([{}]);

  const {
    permissionList,
    isLoading,
    getFolderPermissions,
  } = useFolderPermissions({
    folderId: folder.folderId,
  });

  let actions = useFolderActions(folder, permissionList);

  useEffect(() => {
    if (!isObjectEmpty(actions)) {
      setActionList(
        getActionsList({
          actions,
          resetCurrentPage,
          currentPage,
          setShouldRefetchFolders,
        })
      );
    }
  }, [JSON.stringify(actions)]);

  return { isLoading, actionList, onOpenDropdown: getFolderPermissions };
};

export default useActions;
