import { isListEmpty } from "utils/general";

export const getOnCloseAddExistingProductsModal = ({
  onSaveProducts,
}: {
  onSaveProducts: Function;
}) => ({ selectedProducts }: { selectedProducts: string[] }) => {
  if (!isListEmpty(selectedProducts)) {
    onSaveProducts(selectedProducts);
  }
};
