import { useState } from "react";
import useInterval from "../useInterval";

const timeBetweenApiCallsInPolling = 1000;

const useApiPolling = ({
  pollingFunction,
  stopPolling,
  ignoreMaximumApiCalls = false,
}: {
  pollingFunction: () => void;
  stopPolling: boolean;
  ignoreMaximumApiCalls?: boolean;
}) => {
  const [apiCalls, setApiCalls] = useState(0);

  const maxApiCalls = window.env.MAX_API_CALLS_POLLING ?? 3;

  const hasReachedMaxApiCalls = apiCalls === maxApiCalls;

  const getInterval = () => {
    if (ignoreMaximumApiCalls) {
      return timeBetweenApiCallsInPolling;
    }
    return stopPolling || hasReachedMaxApiCalls
      ? null
      : timeBetweenApiCallsInPolling;
  };

  useInterval(() => {
    pollingFunction();
    setApiCalls(prev => prev + 1);
  }, getInterval());

  return { hasReachedMaxApiCalls };
};

export default useApiPolling;
