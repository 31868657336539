import { useIntl } from "react-intl";
import { Button } from "@trace-one/design-system";
import { useRbac } from "hooks";
import { PERMISSIONS } from "utils/constants";
import { isSectionCodeLabeling } from "utils/specifications";
import { translationManagementMessages } from "messages";
import { Permissions } from "types/general";

export type ManageTranslationButtonProps = {
  sectionCode: string;
  specificationPermissions?: Permissions;
  onClick?: () => void;
  className?: string;
  isTargetMode?: boolean;
};

const ManageTranslationButton: React.FC<ManageTranslationButtonProps> = ({
  sectionCode,
  specificationPermissions = [],
  onClick,
  className,
  isTargetMode,
}) => {
  const { formatMessage } = useIntl();

  const { hasPermission: hasSpecificationPermission } = useRbac(
    specificationPermissions
  );

  if (
    !hasSpecificationPermission(PERMISSIONS.CHAPTER.TRANSLATION_MANAGEMENT) ||
    !isSectionCodeLabeling(sectionCode) ||
    typeof onClick !== "function"
  ) {
    return null;
  }

  return (
    <Button
      type="primary"
      onClick={onClick}
      disabled={isTargetMode}
      {...(className && { className })}
    >
      {formatMessage(translationManagementMessages.manageTranslation)}
    </Button>
  );
};

export default ManageTranslationButton;
