import { isListEmpty } from "utils/general";

export const getOnUnitsOfMeasureFetch = ({
  dispatch,
  fetchUnitsOfMeasure,
  unitsOfMeasure,
  hasLanguageChanged = false,
}) => {
  if (isListEmpty(unitsOfMeasure) || hasLanguageChanged) {
    dispatch(fetchUnitsOfMeasure());
  }
};
