import { Col, Row } from "antd";
import { Button, Heading } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import classNames from "classnames";
import { messages } from "../../messages";
import styles from "./styles.module.less";

const Header = ({ isMinified, toggleMinified }) => {
  const { formatMessage } = useIntl();

  return (
    <Row>
      {!isMinified ? (
        <Col span={21}>
          <Heading size="xs">{formatMessage(messages.menuTitle)}</Heading>
        </Col>
      ) : null}

      <Col
        span={isMinified ? 24 : 3}
        className={classNames({ [styles.minifiedWrapper]: isMinified })}
      >
        <Button
          type="tertiary"
          iconName={isMinified ? "arrow-double-right" : "arrow-double-left"}
          color="primary"
          onClick={toggleMinified}
          data-test-id="minify-menu-button"
        />
      </Col>
    </Row>
  );
};

export default Header;
