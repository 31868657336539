import { defineMessages } from "react-intl";

const messages = defineMessages({
  functionTooltip: {
    id: "ingredientFunctionsDropdown.tooltip",
    defaultMessage:
      "Functions in bold are the ones declared in the raw material",
  },
  functionsPlaceholder: {
    id: "ingredientFunctionsDropdown.placeholder",
    defaultMessage: "Add a function",
  },
  additiveFunctionTooltip: {
    id: "library.recipeTable.ingredient.function.additiveTooltip",
    defaultMessage: "Defining a function is mandatory for Additives",
  },
});

export default messages;
