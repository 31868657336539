/* istanbul ignore file */

import axios from "axios";

export const axiosInstance = axios.create({
  baseURL: window.env.NOTIF_URL,
});

export const fetchWebNotifications = ({
  Skip,
  Take,
  LanguageCode,
  SenderZone,
  SearchText,
  NotificationEventUtcDateTimeAfter,
}) =>
  axiosInstance.get("/api/webNotifications", {
    params: {
      Skip,
      Take,
      LanguageCode,
      SenderZone,
      SearchText,
      NotificationEventUtcDateTimeAfter,
    },
  });

export const fetchWebNotificationIds = ({
  languageCode,
  senderZone,
  searchText,
  NotificationEventUtcDateTimeAfter,
}) =>
  axiosInstance.get("/api/webNotifications/ids", {
    params: {
      languageCode,
      senderZone,
      searchText,
      NotificationEventUtcDateTimeAfter,
    },
  });

export const updateReadStatus = (occurrenceUserInstanceIds, newStatus) =>
  axiosInstance.patch("api/occurrenceUserInstances/list/updateStatus", {
    occurrenceUserInstanceIds,
    newStatus,
  });

export const deleteNotifications = occurrenceUserInstanceIds =>
  axiosInstance.delete("api/occurrenceUserInstances/list", {
    data: { occurrenceUserInstanceIds },
  });

export const fetchCount = () =>
  axiosInstance.get("/api/webNotifications/countUnread", {
    params: { withDetailsPerSender: true },
  });

export const fetchNotificationPreferences = ({
  languageCode,
  senderZone,
  level,
}) =>
  axiosInstance.get("/api/notificationPreferences", {
    params: {
      languageCode,
      senderZone,
      level,
    },
  });

export const updateNotificationPreferences = ({ notificationPreferences }) =>
  axiosInstance.post("/api/notificationPreferences", {
    notificationPreferences,
  });
