import { useState } from "react";
import { shareSpecification } from "apis/SPEC";
import { SpecificationData } from "models";

const useAskForRevisionModalActions = ({
  id,
}: {
  id: SpecificationData["id"];
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [
    isSpecificationSharedBySupplier,
    setIsSpecificationSharedBySupplier,
  ] = useState(false);

  const cancelHandler = () => {
    setIsModalVisible(false);
  };

  const sendRequestHandler = () => {
    setIsModalVisible(true);
  };

  const shareSpecificationHandler = async () => {
    try {
      await shareSpecification({ id });
      setIsModalVisible(false);
      setIsSpecificationSharedBySupplier(true);
    } catch (_) {}
  };

  return {
    isModalVisible,
    cancelHandler,
    sendRequestHandler,
    shareSpecificationHandler,
    isSpecificationSharedBySupplier,
  };
};

export default useAskForRevisionModalActions;
