import { Toggle } from "@trace-one/design-system";
import { useSelector } from "react-redux";
import { selectCompanyActivity } from "store/oidc/selectors";
import { getValidationToggleProps, isValidationToggleDisabled } from "./utils";
import { ValidationToggleProps } from "./types";
import styles from "./styles.module.less";

const ValidationToggle: React.FC<ValidationToggleProps> = ({
  hasValidationErrors,
  isValidationDisabled,
  onValidate,
  sectionStatus,
  validationText,
  isValidationLoading,
  hasValidationPermission,
}) => {
  const companyActivity = useSelector(selectCompanyActivity);

  const { checked, classNameKey, onClick } = getValidationToggleProps({
    sectionStatus,
    companyActivity,
    onValidate,
  });

  return (
    <div className={styles.validation}>
      <Toggle
        loading={isValidationLoading}
        disabled={isValidationToggleDisabled({
          isValidationDisabled,
          hasValidationErrors,
          hasValidationPermission,
        })}
        checked={checked}
        onClick={onClick}
        className={styles[classNameKey]}
        text={validationText}
        textPlacement="left"
      />
    </div>
  );
};

ValidationToggle.defaultProps = {
  hasValidationErrors: false,
  validationText: "",
};

export default ValidationToggle;
