/* istanbul ignore file */

import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { selectLanguageCode, selectUserId } from "store/user/selectors";
import { LegalMonsterData } from "../../models";
import { getCookieSettingsWidgetPublicKey, parseLanguage } from "./utils";

const useLegalMonsterRef = () => {
  const userId = useSelector(selectUserId).toUpperCase();
  const userLanguage = parseLanguage(useSelector(selectLanguageCode));

  const legalMonsterRef = useRef<LegalMonsterData>({
    userId,
    userLanguage,
  });

  useEffect(() => {
    legalMonsterRef.current.userId = userId;
    legalMonsterRef.current.userLanguage = userLanguage;
  }, [userId, userLanguage]);

  useEffect(() => {
    if (userId) {
      const widgetPublicKey = getCookieSettingsWidgetPublicKey(userLanguage);

      window.legal.widget({
        type: "cookie",
        widgetPublicKey,
        locale: userLanguage,
        identifier: userId,
      });
    }

    return () => {
      document.getElementById("legalmonster-closeButtonPrivacy")?.click();
    };
  }, [userLanguage, userId]);

  return legalMonsterRef;
};

export default useLegalMonsterRef;
