import { createSlice } from "@reduxjs/toolkit";
import initialState from "./initialState";
import reducers from "./reducers";

export const slice = createSlice({
  name: "oidc",
  initialState,
  reducers,
});

export const {
  setAccessToken,
  setCompanyActivity,
  setCompanyCountry,
  setUserPermissions,
} = slice.actions;

export default slice.reducer;
