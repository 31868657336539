import { useMemo } from "react";
import { Row, Col, Form } from "antd";
import { EmptyState } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import { isListEmpty } from "utils/general";
import { useReferenceListsItems } from "hooks";
import InfiniteScrollSelect from "components/InfiniteScrollSelect";
import { SiteWidget } from "components/SpecificationSection/components/FormSchema/widgets";
import { ArrayFieldTemplateProps } from "components/SpecificationSection/types";
import { useSitesArray, useSiteActions } from "./hooks";
import { messages } from "./messages";
import styles from "../styles.module.less";

const SitesArrayFieldTemplate: React.FC<ArrayFieldTemplateProps<string[]>> = ({
  formData,
  schema,
  formContext,
}) => {
  const intl = useIntl();

  const { propertyId } = schema;
  const {
    displayActions,
    isWidgetEditable,
    extraActions,
    specificationData,
  } = formContext;

  const colStyles = {
    xs: displayActions ? 24 : 12,
    sm: displayActions ? 24 : 12,
    md: 12,
    lg: 8,
  };

  const hasUpdatePermission = useMemo(() => isWidgetEditable(propertyId), [
    propertyId,
  ]);

  const {
    addedSites,
    siteOptions,
    handleDropdownVisibleChange,
    hasSitesError,
    isSitesLoading,
    getSites,
  } = useSitesArray({
    siteIds: formData,
    specificationData,
  });

  const {
    handleRemoveSite,
    handleAddSite,
    isSiteActionInProgress,
  } = useSiteActions({
    siteIds: formData,
    addSiteAction: extraActions?.addSiteAction,
    removeSiteAction: extraActions?.removeSiteAction,
  });

  const isUpdateActionDisabled = useMemo(() => {
    return !hasUpdatePermission || isSiteActionInProgress;
  }, [isSiteActionInProgress, hasUpdatePermission]);

  const { refListDictionary } = useReferenceListsItems({
    refListIds: addedSites.flatMap(({ siteFunctions }) => siteFunctions),
  });

  return (
    <Row gutter={[0, 30]}>
      <Col span={24}>
        <Row gutter={[30, 30]}>
          {!isListEmpty(addedSites) ? (
            addedSites.map(site => (
              <Col {...colStyles} key={site.id}>
                <SiteWidget
                  refListDictionary={refListDictionary}
                  siteData={{
                    ...site,
                    actions:
                      isUpdateActionDisabled || !displayActions
                        ? []
                        : [
                            {
                              disabled: isSiteActionInProgress,
                              onClick: handleRemoveSite(site.id),
                              label: intl.formatMessage(messages.removeTheSite),
                            },
                          ],
                  }}
                />
              </Col>
            ))
          ) : (
            <Col span={24}>
              <Row gutter={[0, 30]} justify="center">
                <EmptyState
                  heading={intl.formatMessage(messages.noSiteAdded)}
                />
              </Row>
            </Col>
          )}
        </Row>
      </Col>
      {displayActions ? (
        <Col span={24}>
          <Row>
            <Col xs={24} lg={12}>
              <Form.Item
                className={styles.selectLabelWrapper}
                colon={false}
                labelAlign="left"
                label={intl.formatMessage(messages.addASite)}
              >
                <InfiniteScrollSelect
                  placeholder={intl.formatMessage(messages.chooseASite)}
                  disabled={isUpdateActionDisabled}
                  loading={isSitesLoading}
                  notFoundMessage={intl.formatMessage(messages.noSite)}
                  value={null}
                  onSelect={handleAddSite}
                  options={siteOptions}
                  onDropdownVisibleChange={handleDropdownVisibleChange}
                  {...(hasSitesError && {
                    errorMessage: intl.formatMessage(messages.siteError),
                    error: true,
                  })}
                  next={getSites}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      ) : null}
    </Row>
  );
};

export default SitesArrayFieldTemplate;
