import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { useAppDispatch } from "store";
import {
  removeFolderProduct,
  setFolderProducts,
} from "store/folderCreation/asyncActions";
import { selectProducts } from "store/folderCreation/selectors";
import withUnitsOfMeasure from "hocs/withUnitsOfMeasure";
import { isListEmpty } from "utils/general";
import AssignedProduct from "components/AssignedProduct";
import { useCategory } from "pages/Folder/components/Creation/hooks";
import { getOnSaveProducts, getOnRemoveClick } from "./utils";
import { messages } from "../../messages";

const AssignProducts = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const products = useSelector(selectProducts);

  const alreadySelectedProductIds = useMemo(
    () => products.map(({ id }) => id),
    [products]
  );

  const { categoryNamesDictionary } = useCategory({ products });

  return (
    <>
      {isListEmpty(products) ? (
        <AssignedProduct.NoProduct />
      ) : (
        <div data-testid="list-items">
          {products.map(product => {
            let productWithCategoryName = {
              ...product,
              categoryName:
                categoryNamesDictionary?.[product?.category?.categoryItemId],
            };

            return (
              <AssignedProduct.Folder
                key={product.id}
                onRemoveClick={getOnRemoveClick({
                  dispatch,
                  removeFolderProduct,
                  productId: product.id,
                })}
                product={productWithCategoryName}
              />
            );
          })}
        </div>
      )}
      <AssignedProduct.AddButtons
        onSaveProducts={getOnSaveProducts({
          dispatch,
          setFolderProducts,
        })}
        maxNumberOfProducts={50}
        alreadySelectedProductIds={alreadySelectedProductIds}
        modalDescription={
          <span data-testid="page-subtitle">
            {formatMessage(messages.firstHelpMessage)}
            <br />
            {formatMessage(messages.secondHelpMessage)}
          </span>
        }
      />
    </>
  );
};

export default withUnitsOfMeasure(AssignProducts);
