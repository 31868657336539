/* eslint-disable no-else-return */
import { useEffect, useState, useMemo } from "react";
import classnames from "classnames";
import { utils } from "@rjsf/core";
import { Select } from "@trace-one/design-system";
import { getCurrentValueFromValue } from "utils/select";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { useWidgetActions } from "../../hooks";
import { isWidgetDisabled } from "../utils";
import { SelectWidgetProps } from "./types";
import widgetStyle from "../styles.module.less";

const { asNumber, guessType } = utils;

const SELECT_STYLE = {
  width: "100%",
};

const nums = new Set(["number", "integer"]);

/**
 * This is a silly limitation in the DOM where option change event values are
 * always retrieved as strings.
 */
const processValue = (schema, value) => {
  // "enum" is a reserved word, so only "type" and "items" can be destructured
  const { type, items } = schema;

  if (value === "") {
    return undefined;
  } else if (type === "array" && items && nums.has(items.type)) {
    return value.map(asNumber);
  } else if (type === "boolean") {
    return value === "true";
  } else if (type === "number") {
    return asNumber(value);
  }

  // If type is undefined, but an enum is present, try and infer the type from
  // the enum values
  if (schema.enum) {
    if (schema.enum.every(x => guessType(x) === "number")) {
      return asNumber(value);
    } else if (schema.enum.every(x => guessType(x) === "boolean")) {
      return value === "true";
    }
  }

  return value;
};

const SelectWidget: React.FC<SelectWidgetProps> = ({
  autofocus,
  disabled,
  formContext,
  id,
  multiple,
  onBlur,
  onChange,
  onFocus,
  options,
  placeholder,
  readonly,
  schema,
  value,
  className,
  allowClear,
}) => {
  const {
    displayActions,
    readonlyAsDisabled = true,
    isWidgetEditable,
    templateBlockId,
    sectionId,
    emptyValue,
    isTargetMode,
  } = formContext;

  const { propertyId } = schema;

  const { enumOptions } = options;

  const { isWidgetChanged } = useWidgetActions({ templateBlockId, sectionId });

  const [textValue, setTextValue] = useState(null);

  const isDisabled = useMemo(
    () =>
      isWidgetDisabled({
        isWidgetEditable: isWidgetEditable(propertyId),
        disabled,
        readonlyAsDisabled,
        readonly,
      }),
    [disabled, readonlyAsDisabled, readonly, propertyId, isTargetMode]
  );

  const { getTranslationForKey } = useFormSchemaTranslation();

  const handleChange = nextValue => {
    const newValue = processValue(schema, nextValue);
    setTextValue(newValue);
    onChange(newValue);
  };

  const handleBlur = () => onBlur(id, processValue(schema, value));

  const handleFocus = () => onFocus(id, processValue(schema, value));

  const getPopupContainer = node => node.parentNode;

  const classNames = classnames(className, {
    [widgetStyle.inRevisionChange]: isWidgetChanged(propertyId, id),
  });

  useEffect(() => {
    setTextValue(getCurrentValueFromValue(value));
  }, [value]);

  if (!displayActions) {
    if (textValue) {
      return textValue;
    }

    if (emptyValue) {
      return emptyValue;
    }

    return "";
  }

  return (
    <Select
      autoFocus={autofocus}
      className={classNames}
      disabled={isDisabled}
      getPopupContainer={getPopupContainer}
      id={id}
      mode={typeof multiple !== "undefined" ? "multiple" : undefined}
      name={id}
      onBlur={!readonly ? handleBlur : undefined}
      onChange={!readonly ? handleChange : undefined}
      onFocus={!readonly ? handleFocus : undefined}
      placeholder={getTranslationForKey(placeholder)}
      style={SELECT_STYLE}
      value={textValue}
      options={((enumOptions as any[]) || []).map(
        ({ value: optionValue, label: optionLabel }) => ({
          value: optionValue,
          name: optionLabel,
        })
      )}
      allowClear={allowClear}
    />
  );
};

export default SelectWidget;
