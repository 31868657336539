import { ChapterProgressData } from "models";

export const getAllChaptersCount = (sections: ChapterProgressData[]) => {
  let allChaptersCount = {
    totalCount: 0,
    toFillSectionCount: 0,
    inProgressSectionCount: 0,
    validatedBySupplierSectionCount: 0,
    validatedByRetailerSectionCount: 0,
  };

  sections.forEach(section => {
    allChaptersCount.toFillSectionCount += section.toFillSectionCount;
    allChaptersCount.inProgressSectionCount += section.inProgressSectionCount;
    allChaptersCount.validatedBySupplierSectionCount +=
      section.validatedBySupplierSectionCount;
    allChaptersCount.validatedByRetailerSectionCount +=
      section.validatedByRetailerSectionCount;
  });

  allChaptersCount.totalCount =
    allChaptersCount.toFillSectionCount +
    allChaptersCount.inProgressSectionCount +
    allChaptersCount.validatedByRetailerSectionCount +
    allChaptersCount.validatedBySupplierSectionCount;

  return allChaptersCount;
};
