import { useState } from "react";
import { UseSiteActionsProps } from "./types";

const useSiteActions = ({
  siteIds,
  addSiteAction,
  removeSiteAction,
}: UseSiteActionsProps) => {
  const [isSiteActionInProgress, setIsSiteActionInProgress] = useState(false);

  const handleAddSite = async (id: string) => {
    if (isSiteActionInProgress) {
      return null;
    }
    setIsSiteActionInProgress(true);

    try {
      if (
        !siteIds.some(item => {
          return item === id;
        })
      ) {
        await addSiteAction?.({
          siteId: id,
          currentSiteIds: [...siteIds, id],
        });
      }
    } catch (_) {
    } finally {
      setIsSiteActionInProgress(false);
    }
  };

  const handleRemoveSite = (id: string) => async () => {
    if (isSiteActionInProgress) {
      return null;
    }

    setIsSiteActionInProgress(true);

    try {
      await removeSiteAction?.({
        siteId: id,
        currentSiteIds: siteIds.filter(siteId => siteId !== id),
      });
    } catch (_) {
    } finally {
      setIsSiteActionInProgress(false);
    }
  };

  return {
    handleRemoveSite,
    handleAddSite,
    isSiteActionInProgress,
  };
};

export default useSiteActions;
