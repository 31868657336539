import cx from "classnames";
import { isStatusNotNone } from "utils/specifications";
import StatusIcon from "./components/StatusIcon";
import styles from "./styles.module.less";
import { CircleProps } from "./types";

const Circle: React.FC<CircleProps> = ({ status, withIcon }) => {
  return (
    isStatusNotNone(status) && (
      <div
        className={cx(styles.subSectionStatus, styles[status], {
          [styles.big]: withIcon,
        })}
      >
        {withIcon && <StatusIcon status={status} />}
      </div>
    )
  );
};

Circle.defaultProps = {
  withIcon: false,
};

export default Circle;
