import { Col, Row } from "antd";
import { Paragraph } from "@trace-one/design-system";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { getRefListValues } from "./utils";
import { NatureWidgetReadOnlyProps } from "./types";
import styles from "./styles.module.less";
import { getBooleanTranslation } from "components/SpecificationSection/components/FormSchema/utils";

const NatureWidgetReadOnly: React.FC<NatureWidgetReadOnlyProps> = ({
  propertiesObject,
  varietyIdsContent,
  alternativeVarietyIdsContent,
  refListDictionary,
  emptyValue,
}) => {
  const { getTranslationForKey } = useFormSchemaTranslation();

  const acceptsAlternativeVariety = getBooleanTranslation(
    propertiesObject?.["acceptAlternativeVariety"]?.content.props.formData,
    getTranslationForKey
  );

  const specie =
    refListDictionary[propertiesObject?.specieId?.content?.props?.formData];

  const varieties = getRefListValues({
    ids: varietyIdsContent.props.formData,
    refListDictionary,
  });

  const alternativeVarieties = getRefListValues({
    ids: alternativeVarietyIdsContent?.props?.formData,
    refListDictionary,
  });

  return (
    <>
      <Col xs={24} className={styles.natureWidgetReadOnly}>
        <Row className={styles.natureWidgetRow}>
          <Col xs={4}>
            <Paragraph size="xs" color="grey-5">
              {propertiesObject.specieId.content.props.uiSchema["ui:title"]}
            </Paragraph>
          </Col>
          <Col xs={20}>
            <Paragraph size="xs" color="grey-4">
              {specie ? specie : emptyValue}
            </Paragraph>
          </Col>
        </Row>
        <Row className={styles.natureWidgetRow}>
          <Col xs={4}>
            <Paragraph size="xs" color="grey-5">
              {varietyIdsContent.props.uiSchema["ui:title"]}
            </Paragraph>
          </Col>
          <Col xs={20}>
            <Paragraph size="xs" color="grey-4">
              {varieties ? varieties : emptyValue}
            </Paragraph>
          </Col>
        </Row>
        <Row className={styles.natureWidgetRow}>
          <Col xs={4}>
            <Paragraph size="xs" color="grey-5">
              {
                propertiesObject?.["acceptAlternativeVariety"]?.content?.props
                  .uiSchema["ui:title"]
              }
            </Paragraph>
          </Col>
          <Col xs={20}>
            <Paragraph size="xs" color="grey-4">
              {acceptsAlternativeVariety}
            </Paragraph>
          </Col>
        </Row>
      </Col>
      {alternativeVarieties ? (
        <Col xs={24} className={styles.alternativeVarieties}>
          <Paragraph size="xs" color="grey-5">
            {alternativeVarieties}
          </Paragraph>
        </Col>
      ) : null}
    </>
  );
};

export default NatureWidgetReadOnly;
