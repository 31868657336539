/* istanbul ignore file */

import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import {
  selectOwningCompanyName,
  selectUserCompanyActivityNameInEng,
  selectUserJobTitle,
  selectUserLogin,
} from "store/user/selectors";
import { LegalMonsterData } from "../../models";
import { UserProperty } from "./types";

const useHeapAnalytic = (
  legalMonsterRef: React.MutableRefObject<LegalMonsterData>
) => {
  const userLogin = useSelector(selectUserLogin);
  const userJobTitle = useSelector(selectUserJobTitle);
  const userCompanyName = useSelector(selectOwningCompanyName);
  const userCompanyActivityName = useSelector(
    selectUserCompanyActivityNameInEng
  );
  const handleEnableHeapAnalyticRef = useRef<() => void>();

  useEffect(() => {
    const addUserProperties = () => {
      window.heap.addUserProperties({
        [UserProperty.MAIL]: userLogin,
        [UserProperty.JOB_TITLE]: userJobTitle,
        [UserProperty.COMPANY_NAME]: userCompanyName,
        [UserProperty.COMPANY_ACTIVITY_NAME]: userCompanyActivityName,
      });
    };

    handleEnableHeapAnalyticRef.current = () => {
      if (!window.heap) {
        return;
      }

      const isHeapInitiated = typeof window.heap?.loaded === "boolean";

      if (isHeapInitiated) {
        window.heap.loaded = true;
      } else {
        window.heap.load(window.env.HEAP_APP_ID);
        window.heap.identify(legalMonsterRef.current.userId);

        addUserProperties();
      }
    };

    if (window.heap?.loaded) {
      addUserProperties();
    }
  }, [userLogin, userJobTitle, userCompanyName, userCompanyActivityName]);

  useEffect(() => {
    const handleEnableHeapAnalytic = () => {
      handleEnableHeapAnalyticRef.current();
    };

    const handleDisableHeapAnalytic = () => {
      const isHeapInitiated = typeof window.heap?.loaded === "boolean";

      if (isHeapInitiated) {
        window.heap.loaded = false;
      }
    };

    window.addEventListener(
      "legalmonster.cookie.analytics.accepted",
      handleEnableHeapAnalytic
    );

    window.addEventListener(
      "legalmonster.cookie.analytics.rejected",
      handleDisableHeapAnalytic
    );

    return () => {
      window.removeEventListener(
        "legalmonster.cookie.analytics.accepted",
        handleEnableHeapAnalytic
      );
      window.removeEventListener(
        "legalmonster.cookie.analytics.rejected",
        handleDisableHeapAnalytic
      );
    };
  }, []);
};

export default useHeapAnalytic;
