import { useState } from "react";

const useSelectSuppliersModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onOpenSelectSuppliersModal = () => {
    setIsModalOpen(true);
  };

  const onCloseSelectSuppliersModal = () => {
    setIsModalOpen(false);
  };

  return {
    isModalOpen,
    onOpenSelectSuppliersModal,
    onCloseSelectSuppliersModal,
  };
};

export default useSelectSuppliersModal;
