import { fetchTags } from "apis/TAGS";
import { LibraryObjectData } from "models";
import { isListEmpty } from "utils/general";

export const getTagsByTagIds = async (tagIds: LibraryObjectData["tagIds"]) => {
  if (!isListEmpty(tagIds)) {
    const res = await fetchTags({
      ids: tagIds,
    });

    return res.data;
  }
  return [];
};
