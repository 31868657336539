import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./styles.module.less";

const StickyBar = ({ children, position }) => {
  return (
    <div className={classnames(styles.fixed, styles[position])}>{children}</div>
  );
};

StickyBar.defaultProps = {
  position: "bottom",
};

StickyBar.propTypes = {
  children: PropTypes.node.isRequired,
  position: PropTypes.oneOf(["top", "bottom"]),
};

export default StickyBar;
