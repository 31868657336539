import { useSelector } from "react-redux";
import {
  selectSelectedSectionTemplateBlockLastChanges,
  selectSelectedSection,
} from "store/specificationDetails/selectors";
import { isObjectEmpty } from "utils/general";
import { isStateInRevisionBySupplier } from "utils/specifications";

const useWidgetActions = ({ templateBlockId, sectionId }) => {
  const lastChanges = useSelector(
    selectSelectedSectionTemplateBlockLastChanges(templateBlockId)
  );
  const { subSections } = useSelector(selectSelectedSection);

  const isCurrentSubSectionIsInRevisionBySupplier = () => {
    const currentSubSection = subSections.find(
      subsection => subsection.sectionId === sectionId
    );

    if (currentSubSection === undefined) return false;

    return isStateInRevisionBySupplier(currentSubSection.sectionStatus);
  };

  const getWidgetLastChanges = propertyId => {
    if (!isCurrentSubSectionIsInRevisionBySupplier()) {
      return {};
    }

    if (sectionId) return lastChanges?.[propertyId];
  };

  const isWidgetChanged = (propertyId, id) => {
    if (
      isObjectEmpty(lastChanges) ||
      isObjectEmpty(lastChanges[propertyId]) ||
      !isCurrentSubSectionIsInRevisionBySupplier()
    )
      return false;

    if (id) {
      let index;

      switch (typeof id) {
        case "number":
          index = id;

          break;
        case "string":
          const idSplit = id.split("_");

          if (idSplit.length > 2) {
            index = idSplit[2];
          }
          break;
        default:
          index = -1;
      }

      if (index > -1) {
        return !isObjectEmpty(lastChanges[propertyId][index]);
      }
    }

    return true;
  };

  return {
    getWidgetLastChanges,
    isWidgetChanged,
  };
};

export default useWidgetActions;
