import { defineMessages } from "react-intl";

export const tabMessages = defineMessages({
  generalInformation: {
    id: "library.packagingSystemForm.tabs.generalInformation",
    defaultMessage: "General information",
  },
  components: {
    id: "library.packagingSystemForm.tabs.components",
    defaultMessage: "Components ({numberOfComponents})",
  },
});

export const messages = defineMessages({
  generalInformationTitle: {
    id: "library.packagingSystemForm.tabs.generalInformationTitle",
    defaultMessage: "General information",
  },
});
