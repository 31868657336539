import { defineMessages } from "react-intl";

export const messages = defineMessages({
  resumeCreation: {
    id: "folder.actions.draft.resume",
    defaultMessage: "Resume creation",
  },
  deleteGeneral: {
    id: "folder.actions.delete",
    defaultMessage: "Delete folder",
  },
  deleteGeneralConfirmation: {
    id: "folder.actions.delete.confirmation",
    defaultMessage:
      "Deleting this folder will not remove the related specifications",
  },
  deleteDraft: {
    id: "folder.actions.draft.delete",
    defaultMessage: "Delete draft",
  },
});
