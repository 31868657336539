import { MaterialOriginData } from "models";
import { isListEmpty } from "utils/general";

export const isAddButtonDisabled = ({
  disableActions,
  isAddOriginDisabled,
  origins,
}: {
  disableActions: boolean;
  isAddOriginDisabled: boolean;
  origins: MaterialOriginData[];
}) => {
  if (disableActions || isAddOriginDisabled) return true;

  if (isListEmpty(origins)) return false;

  return (
    origins.findIndex(({ typeId, originTypeId }) => {
      return !(typeId || originTypeId);
    }) > -1
  );
};
