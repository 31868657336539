import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fetchCategoryItems } from "apis/RLMD";
import { selectLanguageCode } from "store/user/selectors";
import { isListEmpty } from "utils/general";

const useCategory = ({ products }) => {
  const languageCode = useSelector(selectLanguageCode);
  const [categoryNamesDictionary, setCategoryNamesDictionary] = useState({});

  const categoryIds = products.reduce((previousState, { category }) => {
    let newState = [...previousState];

    if (category?.categoryItemId) {
      newState.push(category?.categoryItemId);
    }

    return newState;
  }, []);

  const getCategory = async () => {
    try {
      const { data } = await fetchCategoryItems({
        ids: categoryIds,
        languageCode,
      });
      const temporaryDictionary = {};
      data.forEach(
        category =>
          (temporaryDictionary[category.categoryItemId] =
            category.categoryItemName)
      );

      setCategoryNamesDictionary(temporaryDictionary);
    } catch (error) {
      return [];
    }
  };

  useEffect(() => {
    if (!isListEmpty(categoryIds)) {
      getCategory();
    }
  }, [categoryIds.length]);

  return {
    categoryNamesDictionary,
  };
};

export default useCategory;
