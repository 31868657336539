import { useBoolean } from "hooks";
import { MaterialIngredientData, MaterialData } from "models";
import { useEffect } from "react";
import { useAppDispatch } from "store";
import {
  addMadeInHouseIngredient,
  removeIngredient,
  updateIngredient,
} from "store/materialForm/asyncActions";
import { Sorter } from "types/pagination";

const useRecipeTableActions = ({
  sorter,
  sortRecipeIngredients,
}: {
  sorter?: Sorter;
  sortRecipeIngredients?: Function;
}) => {
  const dispatch = useAppDispatch();

  const {
    value: isModalOpen,
    setTrue: onOpenModal,
    setFalse: onCloseModal,
  } = useBoolean();

  const onAddIngredients = async (selectedMaterials: MaterialData[]) => {
    for (let object of selectedMaterials) {
      const { id } = object;

      try {
        await dispatch(
          addMadeInHouseIngredient({
            materialId: id,
          })
        );
      } catch (_) {}
    }

    onCloseModal();
  };

  const onRemoveIngredient = (
    ingredientId: MaterialIngredientData["ingredientId"]
  ) => () => {
    dispatch(removeIngredient({ ingredientId }));
  };

  const onUpdateIngredientPercentage = (
    ingredientId: MaterialIngredientData["ingredientId"],
    functionId: MaterialIngredientData["functionId"]
  ) => async (percentage: MaterialIngredientData["percentage"]) => {
    await dispatch(
      updateIngredient({
        ingredientId,
        percentage,
        functionId,
      })
    );
  };

  const onUpdateIngredientFunction = ({
    ingredientId,
    functionId,
    percentage,
  }: {
    ingredientId: MaterialIngredientData["ingredientId"];
    functionId: MaterialIngredientData["functionId"];
    percentage: MaterialIngredientData["percentage"];
  }) => async () => {
    await dispatch(
      updateIngredient({
        ingredientId,
        percentage,
        functionId,
      })
    );
  };

  useEffect(() => {
    if (sorter?.orderBy) {
      sortRecipeIngredients?.({ ...sorter });
    }
  }, [sorter]);

  return {
    onAddIngredients,
    onRemoveIngredient,
    onUpdateIngredientPercentage,
    onUpdateIngredientFunction,
    onOpenModal,
    onCloseModal,
    isModalOpen,
  };
};

export default useRecipeTableActions;
