import { useContext, useState, useMemo } from "react";
import { Row } from "antd";
import { useIntl } from "react-intl";
import { ErrorBoundary } from "react-error-boundary";
import { getParsedData, isObjectEmpty } from "utils/general";
import { useRbac } from "hooks";
import SpecificationSection from "components/SpecificationSection";
import WarningFallback from "components/WarningFallback";
import { useSectionActions } from "./hooks";
import { SelectedChapterContext } from "pages/Specification/components/Details/components/SelectedChapter/contexts";
import { ValidationChapterContext } from "contexts";
import { PERMISSIONS } from "utils/constants";
import { isStatusNotNone } from "utils/specifications";
import { messages } from "./messages";
import { SectionContentProps } from "./types";
import { getIsValidationDisabled } from "utils/library";
import styles from "./styles.module.less";

const SectionContent: React.FC<SectionContentProps> = ({
  sectionId,
  blocks,
  permissions,
  sectionCode,
  sectionStatus,
  validationStatus,
  validatedBySupplier,
}) => {
  const { formatMessage } = useIntl();
  const {
    chapterData,
    productData,
    specificationId,
    supplierId,
    ownerCompanyId,
    ownerUserId,
    specificationStructure,
    isChapterLibrary,
    chapterType,
    refetchChapter,
    isRetailer,
    isChapterOwnedBySupplier,
    sortRecipeIngredient,
    isTargetMode,
    isSpecContact,
    isRetailerContact,
  } = useContext(SelectedChapterContext);

  const {
    isSectionValidationLoading,
    validateHandler,
    selectSectionValidationErrors,
    setSectionBlockValidationError,
    resetSectionValidationErrors,
  } = useContext(ValidationChapterContext);

  const {
    isSavingInProgress,
    getSaveTemplateBlockBySection,
    addContactAction,
    removeContactAction,
    setSignatoryContactAction,
    setSpecificationCollaborationAction,
    removeSpecificationCollaborationAction,
    uploadFilesAction,
    removeFileAction,
    updateSitesAction,
    updateSpecificationNutritionDeclarationManualServingAction,
    updateSpecificationNutritionDeclarationAction,
    createNutritionServingAction,
    updateNutritionServingAction,
    onDeleteNutritionServing,
    onUpdateIngredientList,
    onUpdateCharacteristicIngredients,
    onUpdateClaims,
    onUpdateNutriscore,
  } = useSectionActions({
    specificationId,
    sectionId,
    chapterType,
    refetchChapter,
    resetSectionValidationErrors,
  });

  const { hasPermission } = useRbac(permissions);

  const saveTemplateBlock = getSaveTemplateBlockBySection(sectionCode);

  const sectionValidationErrors = selectSectionValidationErrors(sectionId);
  const setBlockValidationError = setSectionBlockValidationError(sectionId);

  const [isSectionTypingInProgress, setIsSectionTypingInProgress] = useState(
    false
  );

  const isValidationDisabled = useMemo(
    () =>
      getIsValidationDisabled({
        isRetailer,
        sectionStatus,
        isChapterOwnedBySupplier,
        validatedBySupplier,
      }),
    [isRetailer, sectionStatus, isChapterOwnedBySupplier, validatedBySupplier]
  );

  return (
    <ErrorBoundary FallbackComponent={WarningFallback}>
      {blocks.map(
        ({ jsonSchema, uiSchema, blockId, dataAsJson, readOnlyFieldIds }) => {
          return (
            <SpecificationSection.Block
              displayActions={!isChapterLibrary || isTargetMode}
              hasPermissionToSave={
                hasPermission(PERMISSIONS.SECTION.SAVE) && !isChapterLibrary
              }
              key={blockId}
              templateSectionId={sectionId}
              templateBlockId={blockId}
              jsonSchema={getParsedData(jsonSchema)}
              uiSchema={getParsedData(uiSchema)}
              formData={getParsedData(dataAsJson)}
              readOnlyFieldIds={readOnlyFieldIds}
              sectionId={sectionId}
              specificationData={{
                specificationId,
                supplierId,
                ownerCompanyId,
                ownerUserId,
                chapters: specificationStructure,
              }}
              chapterData={chapterData}
              productData={productData}
              saveTemplateBlock={saveTemplateBlock(blockId)}
              extraActions={{
                addContactAction,
                removeContactAction,
                setSignatoryContactAction,
                setSpecificationCollaborationAction,
                removeSpecificationCollaborationAction,
                uploadFilesAction,
                removeFileAction,
                addSiteAction: updateSitesAction?.(blockId),
                removeSiteAction: updateSitesAction?.(blockId),
                updateSpecificationNutritionDeclarationManualServingAction,
                updateSpecificationNutritionDeclarationAction,
                createNutritionServingAction,
                updateNutritionServingAction,
                sortRecipeIngredient,
                onDeleteNutritionServing,
                onUpdateIngredientList,
                onUpdateCharacteristicIngredients,
                onUpdateClaims,
                onUpdateNutriscore,
              }}
              extraTemplateBlockErrors={sectionValidationErrors[blockId]}
              onErrorsChange={setBlockValidationError(blockId)}
              onChangeTypingInProgress={setIsSectionTypingInProgress}
              isTargetMode={isTargetMode}
              isSpecContact={isSpecContact}
              isRetailerContact={isRetailerContact}
            />
          );
        }
      )}
      {isStatusNotNone(sectionStatus) && (
        <Row justify="space-between" className={styles.validation}>
          <SpecificationSection.ErrorsList errors={sectionValidationErrors} />
          <SpecificationSection.ValidationToggle
            sectionStatus={validationStatus}
            hasValidationErrors={!isObjectEmpty(sectionValidationErrors)}
            isValidationLoading={isSectionValidationLoading(sectionId)}
            isValidationDisabled={
              isSavingInProgress ||
              isSectionTypingInProgress ||
              isValidationDisabled ||
              isTargetMode
            }
            hasValidationPermission={hasPermission(
              PERMISSIONS.SECTION.VALIDATE
            )}
            onValidate={validateHandler(sectionId)}
            validationText={formatMessage(messages.validationLabel)}
          />
        </Row>
      )}
    </ErrorBoundary>
  );
};

SectionContent.defaultProps = {
  sectionId: null,
  blocks: [],
};

export default SectionContent;
