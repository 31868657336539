import React from "react";
import { Modal, Paragraph } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import { messages } from "../../messages";
import libraryMessages from "messages/library";
import generalMessages from "messages/general";
import { DeleteChapterVersionModalProps } from "./types";

const DeleteChapterVersionModal: React.FC<DeleteChapterVersionModalProps> = ({
  onDeleteChapterVersion,
  onCancelDeleteChapterVersion,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Modal.Simple
      open={true}
      size="s"
      illustrationName="delete"
      illustrationColor="grey"
      keyboard={false}
      title={formatMessage(messages.deleteChapterVersionModalTitle)}
      onPrimaryButtonClick={onDeleteChapterVersion}
      primaryButtonText={formatMessage(libraryMessages.deleteVersion)}
      secondaryButtonText={formatMessage(generalMessages.cancel)}
      onSecondaryButtonClick={onCancelDeleteChapterVersion}
      onCancel={onCancelDeleteChapterVersion}
    >
      <Paragraph>
        {formatMessage(messages.deleteChapterVersionModalDescription)}
      </Paragraph>
    </Modal.Simple>
  );
};

export default DeleteChapterVersionModal;
