import ActionsButton from "./components/ActionsButton";
import ShareButton from "./components/ShareButton";
import Warnings from "./components/Warnings";
import SpecificationStickyBarSupplier from "./components/SpecificationStickyBarSupplier";
import SpecificationStickyBarRetailer from "./components/SpecificationStickyBarRetailer";
import RequestModificationButton from "./components/RequestModificationButton";
import { useUserInfo } from "hooks";
import { useAppDispatch } from "store";
import { getSpecificationModificationRequests } from "store/specificationDetails/asyncActions";
import { displayRFM } from "utils/general";
import { MODIFICATION_REQUEST_STATE } from "utils/constants";
import { ActionProps } from "./types";
import styles from "components/Header/styles.module.less";

const Action: React.FC<ActionProps> = ({
  id,
  ownerCompanyId,
  supplierId,
  lastVersion = false,
  state,
  history,
  productTypeId,
  productData,
  version,
  ownerContacts,
  supplierContacts,
  selectedChapterOwnerCompanyId,
  isSelectedChapterLibraryChapter,
  isSelectedChapterLinked,
  isTargetMode,
  onTargetModeToggle,
  onOpenModificationRequestNotifyModal,
  permissionList,
}) => {
  const { isRetailer } = useUserInfo();
  const dispatch = useAppDispatch();

  const handleRequestModificationClick = () => {
    dispatch(
      // @ts-ignore
      getSpecificationModificationRequests({
        specificationId: id,
        requestStates: [
          MODIFICATION_REQUEST_STATE.DRAFT,
          MODIFICATION_REQUEST_STATE.IN_PROGRESS,
          MODIFICATION_REQUEST_STATE.PERFORMED,
          MODIFICATION_REQUEST_STATE.DECLINED,
        ],
      })
    );
  };

  return (
    <div className={styles["action-buttons__wrapper"]}>
      <Warnings
        id={id}
        state={state}
        lastVersion={lastVersion}
        history={history}
      />
      {isRetailer && <ShareButton specificationId={id} state={state} />}
      <div className={styles["action-buttons"]}>
        <ActionsButton
          specification={{
            specificationId: id,
            state,
            tradeItemId: productData?.id,
            tradeItemName: productData?.itemName,
            productTypeId,
            ownerCompanyId,
            supplierId,
            version,
            ownerContacts,
            supplierContacts,
          }}
        />

        {displayRFM && (
          <RequestModificationButton
            permissionList={permissionList}
            onRequestModificationClick={handleRequestModificationClick}
            isTargetMode={isTargetMode}
            onTargetModeToggle={onTargetModeToggle}
            onOpenModificationRequestNotifyModal={
              onOpenModificationRequestNotifyModal
            }
            selectedChapterOwnerCompanyId={selectedChapterOwnerCompanyId}
            isSelectedChapterLibraryChapter={isSelectedChapterLibraryChapter}
            isSelectedChapterLinked={isSelectedChapterLinked}
            state={state}
          />
        )}
      </div>
      {isRetailer ? (
        <SpecificationStickyBarRetailer
          id={id}
          state={state}
          history={history}
        />
      ) : (
        <SpecificationStickyBarSupplier
          id={id}
          state={state}
          history={history}
        />
      )}
    </div>
  );
};

export default Action;
