import { useContext, useState } from "react";
import { useIntl } from "react-intl";
import { PackagingSystemComponentMaterialData } from "models";
import { messages } from "messages/packaging";
import { PackagingSystemComponentMaterialArrayFieldTemplateContext } from "components/SpecificationSection/components/FormSchema/templates/ArrayFieldTemplate/PackagingSystemComponentMaterialArrayFieldTemplate/contexts";

const useActions = (
  packagingSystemComponentMaterial: PackagingSystemComponentMaterialData
) => {
  const { formatMessage } = useIntl();
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [idToDelete, setIdToDelete] = useState("");

  const {
    setMaterialFormData,
    setIsMaterialFormModalOpen,
    setMaterialFormModalTitle,
    setMaterialModalPrimaryButtonText,
    onDeleteMaterial,
    prevMaterialFormDataRef,
  } = useContext(PackagingSystemComponentMaterialArrayFieldTemplateContext);

  const onVisibleChange = () => {
    if (isPopoverVisible) {
      setIsPopoverVisible(false);
    }
  };

  const onDeleteClick = (id: string) => {
    setIdToDelete(id);
    setIsPopoverVisible(true);
  };

  const deleteMaterial = () => {
    onDeleteMaterial(idToDelete);
    setIsPopoverVisible(false);
  };

  const actions = [
    {
      key: formatMessage(messages.packagingComponentActionsEdit),
      label: formatMessage(messages.packagingComponentActionsEdit),
      onClick: () => {
        prevMaterialFormDataRef.current = packagingSystemComponentMaterial;
        setMaterialFormModalTitle(formatMessage(messages.editMaterial));
        setMaterialModalPrimaryButtonText(formatMessage(messages.modify));
        setIsMaterialFormModalOpen(true);
        setMaterialFormData({
          ...packagingSystemComponentMaterial,
        });
      },
      "data-testid": "edit-packaging-system-component-material",
    },
    {
      key: formatMessage(messages.packagingComponentActionsDelete),
      label: formatMessage(messages.packagingComponentActionsDelete),
      onClick: () => {
        onDeleteClick(packagingSystemComponentMaterial.type as string);
      },
      "data-testid": "delete-packaging-system-component-material",
    },
  ];

  return {
    actions,
    deleteMaterial,
    isPopoverVisible,
    setIsPopoverVisible,
    onVisibleChange,
  };
};

export default useActions;
