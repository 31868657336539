import { useState } from "react";

const useProductTypeTooltip = () => {
  const [isVisible, setIsVisible] = useState(false);

  const getOnVisibleChange = (hasAssignedProducts: boolean) => (
    visible: boolean
  ) => {
    if (!hasAssignedProducts) {
      setIsVisible(false);
    } else {
      setIsVisible(visible);
    }
  };

  return { isVisible, getOnVisibleChange };
};

export default useProductTypeTooltip;
