import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import { Heading } from "@trace-one/design-system";
import Spin from "components/Spin";
import { useQuickView, useSpecificationsTradeItems } from "hooks";
import QuickviewLegend from "../QuickviewLegend";
import { QuickViewType, SpecificationQuickViewProps } from "./types";
import { quickViewMessages } from "components/Quickview/messages";
import styles from "components/Quickview/styles.module.less";

const SpecificationQuickview: React.FC<SpecificationQuickViewProps> = ({
  header,
  fetcher,
  type,
  body,
  shouldRefetch,
}) => {
  const { isLoading, hasError, specifications, sectionNumber } = useQuickView({
    fetcher,
    shouldRefetch,
  });

  const { tradeItems } = useSpecificationsTradeItems({
    specifications,
    shouldFetch: type !== QuickViewType.SPEC,
  });

  if (isLoading) {
    return <Spin />;
  }

  if (hasError) {
    return (
      <Heading
        size="xxs"
        color="red"
        className={styles.error}
        data-testid="quickview-error"
      >
        <FormattedMessage {...quickViewMessages.specQuickviewError} />
      </Heading>
    );
  }

  return (
    <div className={styles.quickview}>
      <div className={styles.head}>
        <div
          className={classNames({
            [styles.firstSpecificationColumn]: type !== QuickViewType.FOLDER,
            [styles.firstColumn]: type === QuickViewType.FOLDER,
          })}
        >
          <Heading size="xs">
            {header && header(specifications.length, sectionNumber)}
          </Heading>
        </div>
        <QuickviewLegend
          hasDownloadColumn={type !== QuickViewType.FOLDER}
          hasSignatureColumn
        />
      </div>
      <div className={styles.specificationWrapper}>
        {body(type === QuickViewType.SPEC ? specifications : tradeItems)}
      </div>
    </div>
  );
};

export default SpecificationQuickview;
