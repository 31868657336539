import { createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import initialState from "./initialState";
import reducers from "./reducers";
import {
  addSpecificationOwnerContact,
  checkIfSpecificationIsFinalized,
  fetchExistingSpecifications,
  fetchOwnerContacts,
  fetchSpecificationTemplates,
  invalidateSteps,
  removeSpecificationDraftCollaborations,
  removeSpecificationOwnerContact,
  removeSpecificationProduct,
  setInitialSpecficationDetails,
  setSpecificationDraftCollaboration,
  setSpecificationNote,
  setSpecificationProduct,
  setSpecificationProductType,
  setSpecificationSupplier,
  setSpecificationWizard,
  startSpecificationCreation,
  updateCurrentStep,
  updateCollaboration,
  validateStep,
  updateSpecificationDraftFiles,
  getTemplateById,
  setSpecificationTemplate,
} from "./asyncActions";

export const slice = createSlice({
  name: "specificationCreation",
  initialState,
  reducers,
  extraReducers: builder => {
    /* istanbul ignore next*/
    builder.addCase(PURGE, () => ({ ...initialState }));

    builder.addCase(fetchOwnerContacts.fulfilled, (state, action) => {
      state.ownerContacts = action.payload;
    });

    builder.addCase(startSpecificationCreation.pending, state => {
      state.isCreationInProgress = true;
    });
    builder.addCase(startSpecificationCreation.fulfilled, (state, action) => {
      state.ownerUserDetails = action.payload.user;
      state.specification = action.payload.specification;
      state.hasError = false;
      state.isCreationInProgress = false;
    });
    builder.addCase(startSpecificationCreation.rejected, state => {
      state.hasError = true;
      state.isCreationInProgress = false;
    });

    builder.addCase(
      setInitialSpecficationDetails.fulfilled,
      (state, action) => {
        const {
          template,
          chapters,
          selectedProduct,
          selectedSupplier,
          ownerContacts,
          user,
          specification,
          wizard,
        } = action.payload;

        state.template = template;
        state.chapters = chapters;
        state.selectedProduct = selectedProduct;
        state.selectedSupplier = selectedSupplier;
        state.ownerContacts = ownerContacts;
        state.ownerUserDetails = user;
        state.specification = specification;
        state.wizard = wizard;
      }
    );

    builder.addCase(fetchSpecificationTemplates.fulfilled, (state, action) => {
      state.template = action.payload;
      state.chapters = action.payload.chapters;
    });
    builder.addCase(fetchSpecificationTemplates.rejected, state => {
      state.template = initialState.template;
    });

    builder.addCase(getTemplateById.fulfilled, (state, action) => {
      state.template = action.payload;
    });

    builder.addCase(getTemplateById.rejected, state => {
      state.template = initialState.template;
    });

    builder.addCase(fetchExistingSpecifications.fulfilled, (state, action) => {
      state.existingSpecifications = action.payload;
    });

    builder.addCase(setSpecificationProductType.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(setSpecificationProductType.fulfilled, (state, action) => {
      state.specification.productTypeId = action.meta.arg;
      state.specification = action.payload;
      state.errors.productType = false;
      state.isSaving = false;
    });
    builder.addCase(setSpecificationProductType.rejected, state => {
      state.errors.productType = true;
      state.isSaving = false;
    });

    builder.addCase(setSpecificationTemplate.pending, state => {
      state.isSaving = true;
    });

    builder.addCase(setSpecificationTemplate.fulfilled, (state, action) => {
      state.specification = action.payload;
      state.isSaving = false;
    });

    builder.addCase(setSpecificationTemplate.rejected, state => {
      state.isSaving = false;
    });

    builder.addCase(addSpecificationOwnerContact.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(addSpecificationOwnerContact.fulfilled, (state, action) => {
      const ownerContacts = [...state.ownerContacts];

      state.ownerContacts = ownerContacts.map(ownerContact => ({
        ...ownerContact,
        isSelected:
          ownerContact.userId === action.meta.arg
            ? true
            : ownerContact.isSelected,
      }));

      state.specification = action.payload;

      state.errors.ownerContacts = false;

      state.isSaving = false;
    });
    builder.addCase(addSpecificationOwnerContact.rejected, state => {
      state.errors.ownerContacts = true;
      state.isSaving = false;
    });

    builder.addCase(removeSpecificationOwnerContact.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(
      removeSpecificationOwnerContact.fulfilled,
      (state, action) => {
        const ownerContacts = [...state.ownerContacts];

        state.ownerContacts = ownerContacts.map(ownerContact => ({
          ...ownerContact,
          isSelected:
            ownerContact.userId === action.meta.arg
              ? false
              : ownerContact.isSelected,
        }));

        state.specification = action.payload;

        state.errors.ownerContacts = false;

        state.isSaving = false;
      }
    );
    builder.addCase(removeSpecificationOwnerContact.rejected, state => {
      state.errors.ownerContacts = true;
      state.isSaving = false;
    });

    builder.addCase(setSpecificationProduct.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(setSpecificationProduct.fulfilled, (state, action) => {
      state.selectedProduct = action.meta.arg;
      state.specification = action.payload;
      state.errors.addProduct = false;
      state.isSaving = false;
    });
    builder.addCase(setSpecificationProduct.rejected, state => {
      state.errors.addProduct = true;
      state.isSaving = false;
    });

    builder.addCase(removeSpecificationProduct.pending, state => {
      state.errors.removeProduct = false;
      state.errors.removeSupplier = false;

      state.isSaving = true;
    });
    builder.addCase(removeSpecificationProduct.fulfilled, (state, action) => {
      state.specification = action.payload;
      state.selectedProduct = initialState.selectedProduct;
      state.selectedSupplier = initialState.selectedSupplier;
      state.existingSpecifications = initialState.existingSpecifications;

      state.errors.removeSupplier = false;
      state.errors.removeProduct = false;

      state.isSaving = false;
    });
    builder.addCase(removeSpecificationProduct.rejected, (state, action) => {
      // @ts-ignore
      const { supplierError, productError } = action.payload;

      if (supplierError) {
        state.errors.removeSupplier = true;
      }

      if (productError) {
        state.selectedSupplier = initialState.selectedSupplier;
        state.errors.removeProduct = true;
      }

      state.isSaving = false;
    });

    builder.addCase(setSpecificationSupplier.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(setSpecificationSupplier.fulfilled, (state, action) => {
      state.selectedSupplier = action.meta.arg;
      state.specification = action.payload;
      state.errors.addSupplier = false;
      state.isSaving = false;
    });

    builder.addCase(setSpecificationSupplier.rejected, state => {
      state.errors.addSupplier = true;
      state.isSaving = false;
    });

    builder.addCase(setSpecificationNote.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(setSpecificationNote.fulfilled, (state, action) => {
      state.specification = action.payload;
      state.errors.note = false;
      state.isSaving = false;
    });
    builder.addCase(setSpecificationNote.rejected, state => {
      state.errors.note = true;
      state.isSaving = false;
    });

    builder.addCase(setSpecificationDraftCollaboration.pending, state => {
      state.isSaving = true;
      state.errors.addCollaboration = false;
    });
    builder.addCase(
      setSpecificationDraftCollaboration.fulfilled,
      (state, action) => {
        state.specification = action.payload;
        state.isSaving = false;
      }
    );
    builder.addCase(setSpecificationDraftCollaboration.rejected, state => {
      state.isSaving = false;
      state.errors.addCollaboration = true;
    });

    builder.addCase(removeSpecificationDraftCollaborations.pending, state => {
      state.isSaving = true;
      state.errors.removeCollaborations = false;
    });
    builder.addCase(
      removeSpecificationDraftCollaborations.fulfilled,
      (state, action) => {
        state.specification = action.payload;
        state.isSaving = false;
      }
    );
    builder.addCase(removeSpecificationDraftCollaborations.rejected, state => {
      state.isSaving = false;
      state.errors.removeCollaborations = true;
    });

    builder.addCase(checkIfSpecificationIsFinalized.pending, state => {
      state.isSpecificationCheckInProgress = true;
    });
    builder.addCase(
      checkIfSpecificationIsFinalized.fulfilled,
      (state, action) => {
        state.isSpecificationFinalized = action.payload.isFinalized;
        state.existingSpecificationId = action.payload.existingSpecificationId;
        state.isSpecificationCheckInProgress = false;
      }
    );
    builder.addCase(checkIfSpecificationIsFinalized.rejected, state => {
      state.isSpecificationCheckInProgress = false;
    });
    builder.addCase(setSpecificationWizard.pending, state => {
      state.isSaving = true;
      state.errors.wizard = false;
    });
    builder.addCase(setSpecificationWizard.fulfilled, (state, action) => {
      state.wizard = action.payload;
      state.isSaving = false;
    });
    builder.addCase(setSpecificationWizard.rejected, state => {
      state.isSaving = false;
      state.errors.wizard = true;
    });
    builder.addCase(updateCurrentStep.fulfilled, (state, action) => {
      state.wizard = action.payload;
    });

    builder.addCase(validateStep.fulfilled, (state, action) => {
      state.wizard = action.payload;
    });

    builder.addCase(invalidateSteps.fulfilled, (state, action) => {
      state.wizard = action.payload;
    });

    builder.addCase(updateCollaboration.fulfilled, (state, action) => {
      state.wizard = action.payload;
    });

    builder.addCase(updateSpecificationDraftFiles.pending, state => {
      state.isSaving = true;
      state.errors.files = false;
    });
    builder.addCase(
      updateSpecificationDraftFiles.fulfilled,
      (state, action) => {
        state.specification = action.payload;
        state.isSaving = false;
      }
    );
    builder.addCase(updateSpecificationDraftFiles.rejected, state => {
      state.isSaving = false;
      state.errors.files = true;
    });
  },
});

export const {
  setSpecificationId,
  setIsInvitationStepValid,
  resetSpecificationErrors,
} = slice.actions;

export default slice.reducer;
