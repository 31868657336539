import { useAppDispatch } from "store";
import {
  addFunctionId,
  removeFunctionId,
  updateClassificationId,
  updatePartId,
} from "store/materialForm/asyncActions";
import { updateCategoryDictionary } from "store/generalData/asyncActions";
import { CategoryItemData, MaterialData } from "models";

const useRawMaterialActions = () => {
  const dispatch = useAppDispatch();

  const onChangeClassification = async (
    classificationId?: MaterialData["classificationId"]
  ) => {
    if (!classificationId) {
      return;
    }
    dispatch(updateClassificationId(classificationId));
  };

  const onChangePart = async (partId?: MaterialData["partId"]) => {
    if (!partId) {
      return;
    }
    dispatch(updatePartId(partId));
  };

  const onSelectFunction = async (categoryItem?: CategoryItemData) => {
    if (!categoryItem?.categoryItemId) return;

    dispatch(
      updateCategoryDictionary({
        [categoryItem.categoryItemId]: categoryItem.categoryItemName,
      })
    );

    dispatch(addFunctionId(categoryItem.categoryItemId));
  };

  const onRemoveFunction = async (functionId?: string) => {
    if (!functionId) return;

    dispatch(removeFunctionId(functionId));
  };

  return {
    onChangeClassification,
    onChangePart,
    onSelectFunction,
    onRemoveFunction,
  };
};

export default useRawMaterialActions;
