import orderBy from "lodash/orderBy";
import { DiscussionData } from "models/discussion";
import { ModificationRequestData } from "models/modificationRequest";
import {
  DiscussionPanelMessageViewModel,
  ModificationRequestViewModel,
} from "viewModels/modificationRequest";
import { isModificationRequestDraft } from "utils/modificationRequest";
import { isListEmpty, isObjectEmpty } from "utils/general";

export const prepareModificationRequest = (
  modificationRequest: ModificationRequestData
) => {
  if (isObjectEmpty(modificationRequest)) {
    return;
  }

  const modifiedModificationRequest: ModificationRequestViewModel = {
    ...modificationRequest,
    state: modificationRequest?.state?.value,
    reason: modificationRequest?.state?.reason,
  };

  return modifiedModificationRequest;
};

export const prepareModificationRequests = (
  modificationRequests: ModificationRequestData[]
) => {
  if (isListEmpty(modificationRequests)) {
    return;
  }

  const modifiedModificationRequests: ModificationRequestViewModel[] = modificationRequests.map(
    request => prepareModificationRequest(request)
  );

  return modifiedModificationRequests || [];
};

export const prepareDiscussionPanelMessages = ({
  modificationRequest,
  discussion,
}: {
  modificationRequest: ModificationRequestViewModel;
  discussion?: DiscussionData;
}) => {
  let messages: DiscussionPanelMessageViewModel[] = [];

  if (
    isModificationRequestDraft(modificationRequest?.state) &&
    modificationRequest?.message
  ) {
    messages = prepareModificationRequestMessages(modificationRequest);
  } else if (!isListEmpty(discussion?.messages)) {
    messages = prepareDiscussionMessages(discussion);
  }

  return messages;
};

const prepareModificationRequestMessages = (
  modificationRequest: ModificationRequestViewModel
): DiscussionPanelMessageViewModel[] => [
  {
    id: modificationRequest?.requestId,
    authorUserId: modificationRequest?.ownerUserId,
    text: modificationRequest?.message,
    createdAt: modificationRequest?.createdAtUtc,
    updatedAt: modificationRequest?.lastUpdateDateUtc,
    showHeader: true,
  },
];

const prepareDiscussionMessages = (
  discussion: DiscussionData
): DiscussionPanelMessageViewModel[] => {
  let previousMessage: DiscussionPanelMessageViewModel = null;

  const sortedMessages: DiscussionPanelMessageViewModel[] = orderBy(
    discussion?.messages,
    (message: DiscussionPanelMessageViewModel) => new Date(message?.createdAt)
  );

  return sortedMessages?.map(message => {
    const showHeader =
      message.authorUserId !== previousMessage?.authorUserId &&
      new Date(message.createdAt).getDate() !==
        new Date(previousMessage?.createdAt).getDate();

    const showUserInfo = message.authorUserId !== previousMessage?.authorUserId;

    previousMessage = message;

    return {
      id: message?.id,
      authorUserId: message?.authorUserId,
      text: message?.text,
      createdAt: message?.createdAt,
      updatedAt: null,
      showHeader,
      showUserInfo,
    };
  });
};
