export const getOnIsRevisedChange = ({
  dispatch,
  redirectToSpecificationDetails,
  revisedSpecification,
  resetRevisedSpecification,
}) => {
  if (revisedSpecification) {
    redirectToSpecificationDetails(revisedSpecification);

    dispatch(resetRevisedSpecification());
  }
};
