import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { FormInstance } from "antd";
import { useAppDispatch } from "store";
import {
  updatePackagingComponentNameAsync,
  updatePackagingComponentUsageLevelAsync,
} from "store/packaging/asyncActions";
import { selectPackagingComponent } from "store/packaging/selectors";
import { PackagingSystemComponentObjectData } from "models";
import { isListEmpty } from "utils/general";
import { messages } from "messages/packaging";
import { ERROR_CODES } from "utils/constants";
import { useErrorCodeCheck } from "hooks";

const useBasicInformation = (form: FormInstance) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const isFieldValidating = form?.isFieldValidating("packaging-component-name");

  const [basicInformationFormErrors, setBasicInformationFormErrors] = useState(
    []
  );
  const [errorMessageKey, setErrorMessageKey] = useState("");

  useEffect(() => {
    if (isFieldValidating) {
      setErrorMessageKey("");
    }
  }, [isFieldValidating]);

  useEffect(() => {
    if (errorMessageKey) {
      form.validateFields(["packaging-component-name"]);
    }
  }, [errorMessageKey]);

  const hasFormErrorsRef = useRef(false);

  const { isDesiredErrorCode } = useErrorCodeCheck(
    ERROR_CODES.DUPLICATE_LIBRARY_NAME
  );

  useEffect(() => {
    if (isDesiredErrorCode) {
      setErrorMessageKey("duplicateName");
    }
  }, [isDesiredErrorCode]);

  useEffect(() => {
    hasFormErrorsRef.current = basicInformationFormErrors.some(
      item => !isListEmpty(item.errors)
    );
  }, [basicInformationFormErrors]);

  const {
    componentId: packagingComponentId,
    name: packagingComponentName,
    usageLevel,
  } = useSelector(selectPackagingComponent);

  const onChangeUsageLevel = ({
    value,
  }: {
    value: PackagingSystemComponentObjectData["usageLevel"];
  }) =>
    dispatch(
      updatePackagingComponentUsageLevelAsync({
        packagingComponentId,
        usageLevel: value,
      })
    );

  const onChangeName = ({
    value,
  }: {
    value: PackagingSystemComponentObjectData["name"];
  }) => {
    dispatch(
      updatePackagingComponentNameAsync({
        packagingComponentId,
        packagingComponentName: value,
      })
    );
  };

  return {
    initialValues: {
      "packaging-component-name": packagingComponentName,
      "packaging-component-usage-level": messages[usageLevel]
        ? formatMessage(messages[usageLevel])
        : undefined,
    },
    hasFormErrors: hasFormErrorsRef.current,
    setBasicInformationFormErrors,
    onChangeName,
    onChangeUsageLevel,
    errorMessageKey,
  };
};

export default useBasicInformation;
