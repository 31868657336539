import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useBoolean, useModalVisibility } from "hooks";
import { useAppDispatch } from "store";
import { notifySpecificationModificationRequest } from "store/specificationDetails/asyncActions";
import { selectModificationRequests } from "store/specificationDetails/selectors";
import { setModificationRequests } from "store/specificationDetails/specificationDetailsSlice";
import { isModificationRequestDraft } from "utils/modificationRequest";
import { ModificationRequestViewModel } from "viewModels/modificationRequest";

const useModificationRequestModalActions = ({
  onTargetModeToggle,
}: {
  onTargetModeToggle: Function;
}) => {
  const dispatch = useAppDispatch();
  const modificationRequests: ModificationRequestViewModel[] = useSelector(
    selectModificationRequests
  );

  const { value: isNotifying, setTrue, setFalse } = useBoolean();

  useEffect(() => {
    return () => {
      dispatch(setModificationRequests([]));
    };
  }, []);

  const {
    isModalOpen: isModificationRequestNotifyModalOpen,
    onOpenModal: onOpenModificationRequestNotifyModal,
    onCloseModal: onCloseModificationRequestNotifyModal,
  } = useModalVisibility();

  const onSaveAndNotify = async () => {
    try {
      setTrue();
      for (const { requestId, state } of modificationRequests) {
        if (requestId && isModificationRequestDraft(state)) {
          await dispatch(
            // @ts-ignore
            notifySpecificationModificationRequest({
              requestId,
            })
          );
        }
      }
    } finally {
      setFalse();
      onCloseModificationRequestNotifyModal();
      onTargetModeToggle();
    }
  };

  const onKeepAsDraft = () => {
    onTargetModeToggle();
    onCloseModificationRequestNotifyModal();
  };

  return {
    isModificationRequestNotifyModalOpen,
    onOpenModificationRequestNotifyModal,
    onCloseModificationRequestNotifyModal,
    onSaveAndNotify,
    onKeepAsDraft,
    isNotifying,
  };
};

export default useModificationRequestModalActions;
