import { messages } from "messages/packaging";

export const getUsageLevelOptions = (
  options: string[],
  formatMessage?: Function
) =>
  options.map(option => ({
    value: option,
    name:
      formatMessage && messages[option]
        ? formatMessage(messages[option])
        : option,
  }));
