import { TreeNode } from "types/general";
import { isListEmpty } from "utils/general";

const useCheckedChildren = ({
  defaultCheckedParents,
  treeData,
}: {
  defaultCheckedParents: string[];
  treeData: TreeNode[];
}) => {
  const getCheckedChildren = (
    currentTreeData: TreeNode[],
    addAllChildren?: boolean
  ) =>
    currentTreeData.reduce((previousState: string[], currentItem) => {
      if (addAllChildren) {
        previousState.push(currentItem.key);
      }

      if (addAllChildren || defaultCheckedParents.includes(currentItem.key)) {
        let childIds = currentItem.children.reduce(
          (previousState, currentChild) => {
            previousState.push(currentChild.key);

            if (!isListEmpty(currentChild.children)) {
              previousState.push.apply(
                previousState,
                getCheckedChildren(currentChild.children, true)
              );
            }

            return previousState;
          },
          []
        );

        if (!isListEmpty(childIds)) return [...previousState, ...childIds];
      }

      return [...previousState];
    }, []);

  const prepareData = () =>
    treeData.reduce((previousState, { key, children }) => {
      previousState.push.apply(
        previousState,
        getCheckedChildren(children, defaultCheckedParents.includes(key))
      );

      return previousState;
    }, []);

  return { checkedChildren: prepareData() };
};

export default useCheckedChildren;
