import { defineMessages } from "react-intl";

export const messages = defineMessages({
  supplier: {
    id: "specificationDetails.supplier",
    defaultValue: "Supplier",
  },
  retailer: {
    id: "specificationDetails.retailer",
    defaultValue: "Retailer",
  },
  progress: {
    id: "quickview.progressList.title",
    defaultMessage: "Progress",
  },
});
