import { defineMessages } from "react-intl";

export const messages = defineMessages({
  folderListingTitle: {
    id: "folderListing.title",
    defaultMessage: "Folder listing",
  },

  folderListingSubtitle: {
    id: "folderListing.subtitle",
    defaultMessage: "Search and find any folder created by your company",
  },

  libraryTitle: {
    id: "library.title",
    defaultMessage: "Library",
  },

  librarySubtitle: {
    id: "library.subtitle",
    defaultMessage: "Search and find any library items from your company",
  },

  specificationListTitle: {
    id: "specificationListing.title",
    defaultMessage: "List of specifications",
  },

  specificationListSubtitle: {
    id: "specificationListing.subtitle",
    defaultMessage:
      "Search and find any specification accessible to your company",
  },
});
