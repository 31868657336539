import React from "react";
import SectionCount from "./components/SectionCount";
import { ProgressBarProps } from "./types";
import styles from "./styles.module.less";

const ProgressBar: React.FC<ProgressBarProps> = ({
  totalCount,
  toFillSectionCount,
  inProgressSectionCount,
  validatedBySupplierSectionCount,
  validatedByRetailerSectionCount,
}) => {
  return (
    <div className={styles.progressBar}>
      <SectionCount
        currentCount={toFillSectionCount}
        totalCount={totalCount}
        dataTestId="to-fill-section-count"
        className="fillBar"
      />
      <SectionCount
        currentCount={inProgressSectionCount}
        totalCount={totalCount}
        dataTestId="in-progress-section-count"
        className="writingBar"
      />
      <SectionCount
        currentCount={validatedBySupplierSectionCount}
        totalCount={totalCount}
        dataTestId="validated-by-supplier-section-count"
        className="suplierBar"
      />
      <SectionCount
        currentCount={validatedByRetailerSectionCount}
        totalCount={totalCount}
        dataTestId="validated-by-retailer-section-count"
        className="retailerBar"
      />
    </div>
  );
};

export default ProgressBar;
