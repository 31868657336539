import { ArrayFieldTemplateProps } from "components/SpecificationSection/types";
import ConditionalArray from "./components/ConditionalArray";

function withConditionalArray(Component: React.FC<ArrayFieldTemplateProps>) {
  return (props: ArrayFieldTemplateProps) => {
    const { uiSchema } = props;

    if (!uiSchema["ui:conditional"]) {
      return <Component {...props} />;
    }

    return (
      <ConditionalArray {...props}>
        <Component {...props} />
      </ConditionalArray>
    );
  };
}

export default withConditionalArray;
