import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fetchAllergensHierarchy } from "apis/CATALOGUE";
import { useAppDispatch } from "store";
import { selectLanguageCode } from "store/user/selectors";
import { updateCatalogDictionary } from "store/generalData/asyncActions";
import { CatalogListItemData } from "models";
import { isListEmpty } from "utils/general";
import { Dictionary, TreeNode } from "types/general";

const useHierarchyData = ({
  defaultDisabledItems,
  isReadyToFetch = true,
}: {
  defaultDisabledItems: string[];
  isReadyToFetch?: boolean;
}) => {
  const languageCode = useSelector(selectLanguageCode);
  const dispatch = useAppDispatch();
  const [treeData, setTreeData] = useState<TreeNode[]>([]);
  const [catalogList, setCatalogList] = useState<CatalogListItemData[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  let dictionary: Dictionary = {};

  const prepareListForTree = (
    list: CatalogListItemData,
    disableAllChildren?: boolean
  ) => {
    const isItemDisabled =
      disableAllChildren || defaultDisabledItems.includes(list.id);

    let newList = {
      key: list.id,
      title: list.name,
      disabled: isItemDisabled,
      children: [],
    };

    dictionary[list.id] = list.name;

    newList.children = list.children?.reduce((previousState, categoryItem) => {
      let newState = [...previousState];

      newState.push(prepareListForTree(categoryItem, isItemDisabled));

      return newState;
    }, []);

    return newList;
  };

  const fetchListHierarchy = async () => {
    setIsLoading(true);

    try {
      const { data } = await fetchAllergensHierarchy({
        languageCode,
      });

      setCatalogList(data);
    } catch (_) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isReadyToFetch && isListEmpty(treeData)) {
      fetchListHierarchy();
    }
  }, [isReadyToFetch]);

  useEffect(() => {
    if (!isListEmpty(catalogList)) {
      setTreeData(catalogList.map(item => prepareListForTree(item)));

      dispatch(updateCatalogDictionary(dictionary));
    }
  }, [JSON.stringify(defaultDisabledItems), catalogList.length]);

  return {
    treeData,
    isLoading,
  };
};

export default useHierarchyData;
