import { ConditionalEllipsisWithTooltip } from "@trace-one/design-system";
import { formatNetContents } from "utils/form";
import { useSelector } from "react-redux";
import { selectUnitsOfMeasure } from "store/generalData/selectors";
import withUnitsOfMeasure from "hocs/withUnitsOfMeasure";
import { ChapterQuickViewTitleProps } from "./types";

const ChapterQuickViewTitle: React.FC<ChapterQuickViewTitleProps> = ({
  tradeItem,
}) => {
  const unitsOfMeasure = useSelector(selectUnitsOfMeasure);

  const {
    tradeItemName,
    tradeItemSpecifications,
    tradeItemNetContents,
  } = tradeItem;
  const companyName = tradeItemSpecifications[0]?.companyDisplayName;

  const netContentsTexts = formatNetContents({
    netContents: [tradeItemNetContents?.[0]],
    unitsOfMeasure,
  });

  const title = `${companyName} - ${tradeItemName} ${
    netContentsTexts.length ? `-${netContentsTexts}` : ""
  }`;

  return (
    <ConditionalEllipsisWithTooltip text={title} placement="bottom">
      {title}
    </ConditionalEllipsisWithTooltip>
  );
};

export default withUnitsOfMeasure(ChapterQuickViewTitle);
