import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { PageHeader } from "@trace-one/design-system";
import {
  useGoBack,
  useLibraryItemActions,
  useLibraryItemPermissions,
  useLibraryItemTypeDictionary,
  useRedirect,
  useTag,
} from "hooks";
import { useTabNavigation } from "./hooks";
import { LibraryDetails } from "./contexts";
import { prepareLibraryItemActionItem } from "dtos/libraryItem";
import { CHAPTER_TYPES_API, LIBRARY_TAB_KEYS } from "utils/constants";
import { getNameWithVersion } from "utils/general";
import {
  isLibraryObjectChapterSubType,
  isLibraryObjectClaimType,
  isLibraryObjectLocked,
  isLibraryObjectRequirement,
  isLibraryObjectTemplatesType,
} from "utils/library";
import { onTabChange } from "pages/Library/utils";
import { getLibraryItemInfo } from "./utils";
import { LibraryDetailsProps } from "./types";
import { ParamTypes, goBackParams } from "../types";
import messages from "messages/library";
import { usePageHeadingInformation } from "pages/Library/hooks";
import { generalMessages } from "messages";
import { useEffect } from "react";
import styles from "./styles.module.less";

export const Details: React.FC<LibraryDetailsProps> = ({
  libraryItemDetails,
}) => {
  const { type } = useParams<ParamTypes>();
  const { formatMessage } = useIntl();
  const { redirectToLibraryDetails } = useRedirect();

  const { goBack } = useGoBack(goBackParams[type]);
  const { hasModifyPermission } = useLibraryItemPermissions(libraryItemDetails);

  const { edit, createNewVersion, duplicate } = useLibraryItemActions({
    libraryItemInfo: prepareLibraryItemActionItem(
      getLibraryItemInfo({ type, libraryItemDetails })
    ),
  });

  const {
    actionsInformation,
    updateActionsInformation,
  } = usePageHeadingInformation(
    libraryItemDetails?.generalInformation?.state,
    libraryItemDetails?.generalInformation?.name
  );

  const {
    tag: { label, color, mode },
  } = useTag(
    libraryItemDetails.state || libraryItemDetails?.generalInfo?.state
  );
  const { typeDictionary } = useLibraryItemTypeDictionary({ type });

  const { activeTab, setActiveTab, tabItems, tabContent } = useTabNavigation({
    libraryItemDetails,
    type,
  });

  const isLocked = isLibraryObjectLocked(
    libraryItemDetails.state || libraryItemDetails?.generalInfo?.state
  );

  const isRequirementObject = isLibraryObjectRequirement(
    libraryItemDetails.generalInformation.type
  );

  const isTemplateObject = isLibraryObjectTemplatesType(
    libraryItemDetails.generalInformation.type
  );

  const isChapterObject = isLibraryObjectChapterSubType(
    libraryItemDetails.generalInformation.type
  );

  const isClaimObject = isLibraryObjectClaimType(
    libraryItemDetails.generalInformation.type
  );

  let onPrimaryButtonClick = isLocked ? createNewVersion.onClick : edit.onClick;

  if (isChapterObject) {
    onPrimaryButtonClick = () => {};
  }

  useEffect(() => {
    if (isChapterObject) {
      updateActionsInformation({
        props: {
          items: [
            {
              ...(isLocked
                ? {
                    name: formatMessage(messages.createNewVersion),
                    label: formatMessage(messages.createNewVersion),
                    onClick: createNewVersion?.onClick,
                  }
                : {
                    name: formatMessage(messages.editButton),
                    label: formatMessage(messages.editButton),
                    onClick: edit?.onClick,
                  }),
            },
            {
              name: formatMessage(generalMessages.duplicate),
              label: formatMessage(generalMessages.duplicate),
              onClick: duplicate?.onClick,
            },
          ],
        },
      });
    }
  }, []);

  return (
    <div className={styles.detailsContainer}>
      <PageHeader
        title={
          isTemplateObject
            ? libraryItemDetails?.generalInformation?.name
            : getNameWithVersion(
                libraryItemDetails?.generalInformation?.name || "",
                libraryItemDetails.versionNumber
              )
        }
        onBack={goBack}
        tag={label && { label, color, mode }}
        {...(hasModifyPermission && {
          onPrimaryButtonClick,
          primaryButtonLabel: formatMessage(
            isLocked ? messages.createNewVersion : messages.editButton
          ),
        })}
        primaryButtonProps={{
          ...actionsInformation?.props,
          ...(isLocked && {
            disabled: isRequirementObject || isTemplateObject || isClaimObject,
          }),
        }}
        tabs={{
          defaultActiveKey: LIBRARY_TAB_KEYS.GENERAL_INFORMATION,
          items: tabItems,
          onChange: (tabKey: string) => {
            onTabChange({
              tabKey,
              libraryItemId: libraryItemDetails?.generalInfo?.id,
              libraryItemType:
                CHAPTER_TYPES_API[libraryItemDetails?.generalInfo?.type],
              redirectToPage: redirectToLibraryDetails,
            });
          },
          activeKey: activeTab,
          onTabClick: setActiveTab,
        }}
      />
      <LibraryDetails.Provider
        value={{
          libraryItemDetails,
          typeDictionary,
        }}
      >
        {tabContent}
      </LibraryDetails.Provider>
    </div>
  );
};

export default Details;
