import { Select } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import { filterOptionWithLength } from "utils/select";
import { useUsers } from "./hooks";
import filterMessages from "messages/filters";
import { OwnersOrContactsProps } from "./types";

const OwnersOrContacts: React.FC<OwnersOrContactsProps> = props => {
  const { formatMessage } = useIntl();

  const { isLoading, hasError, users } = useUsers();

  return (
    <Select
      showArrow
      labelInValue
      mode="multiple"
      name="ownerOrContactIds"
      placeholder={formatMessage(filterMessages.ownerPlaceholder)}
      options={users.map(user => ({
        value: user.userId,
        name: `${user.userFirstName} ${user.userLastName}`,
      }))}
      optionFilterProp="children"
      filterOption={filterOptionWithLength(2)}
      notFoundMessage={formatMessage(filterMessages.noOwner)}
      loading={isLoading}
      {...(hasError && {
        errorMessage: formatMessage(filterMessages.ownerError),
        error: true,
      })}
      {...props}
    />
  );
};

export default OwnersOrContacts;
