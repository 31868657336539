import { defineMessages } from "react-intl";

export const messages = defineMessages({
  validationOverflowMessage: {
    id: "selectRequirements.step.assignedProducts.validationOverflowMessage",
    defaultMessage: "Select a requirement first",
  },

  assignedProductsTitle: {
    id: "selectRequirements.step.assignedProducts.title",
    defaultMessage: "Assign to products",
  },

  assignedProductsSubtitle: {
    id: "selectRequirements.step.assignedProducts.subtitle",
    defaultMessage:
      "You can assign the selected requirements to one or multiple products",
  },

  selectedProductsCount: {
    id: "selectRequirements.step.assignedProducts.selectedProductsCount",
    defaultMessage:
      "{number} selected {number , plural, one {product} other {products}}",
  },

  selectAllBtn: {
    id: "selectRequirements.step.assignedProducts.buttons.selectAll",
    defaultMessage: "Select all",
  },

  deselectAllBtn: {
    id: "selectRequirements.step.assignedProducts.buttons.deselectAll",
    defaultMessage: "Deselect all",
  },

  applyBtn: {
    id: "selectRequirements.step.assignedProducts.buttons.apply",
    defaultMessage: "Apply",
  },

  validateBtn: {
    id: "selectRequirements.buttons.validate",
    defaultMessage: "Validate",
  },
});
