import { defineMessages } from "react-intl";

export const messages = defineMessages({
  validateAllSections: {
    id: "specificationDetails.sections.validateAllSections",
    defaultMessage: "validate all sections",
  },

  chapterValidationErrorMessage: {
    id: "specificationDetails.sections.chapterValidationErrorMessage",
    defaultMessage:
      "Please correct the errors in the sections below that prevents from validating the chapter.",
  },
});
