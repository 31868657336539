import { ConditionalEditableWidgetFormData } from "components/SpecificationSection/components/FormSchema/types";

export const preparePropsForContent = ({
  properties,
  disabled,
}: {
  properties: React.ReactElement;
  disabled: boolean;
}) => ({
  ...properties,
  props: {
    ...properties.props,
    disabled,
    uiSchema: {
      ...properties.props.uiSchema,
      "ui:no:wrap": true,
    },
  },
});

export const isValueFieldDisabled = ({
  formData,
  schema,
}: {
  formData: ConditionalEditableWidgetFormData;
  schema: any;
}) =>
  formData.editable !== undefined
    ? !formData.editable
    : !schema.properties.editable.default;
