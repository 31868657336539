import { Tag as TagTO } from "@trace-one/design-system";
import { useGetTags } from "apis/tags-rtk";

export interface TagsListProps {
  tagIds: string[];
}

export default function TagsList({ tagIds }: TagsListProps) {
  const { data: tags } = useGetTags(tagIds, {
    skip: !tagIds?.length,
  });

  return (
    <div>
      {(tags ?? []).map(tag => (
        <TagTO
          key={tag.tagId}
          mode="full"
          color="default"
          label={tag.tagText}
        />
      ))}
    </div>
  );
}
