import { useIntl } from "react-intl";
import cleanDeep from "clean-deep";
import { useRbac } from "hooks";
import { MenuActions, Permissions } from "types/general";
import { PERMISSIONS } from "utils/constants";
import { messages } from "../../messages";

const useSelectedChapterActions = ({
  permissionList,
  isChapterLibrary,
  isChapterLinked,
  onOpenUnlinkModal,
  onOpenDetailsAndLinksModal,
}: {
  permissionList: Permissions;
  isChapterLibrary: boolean;
  isChapterLinked: boolean;
  onOpenUnlinkModal: Function;
  onOpenDetailsAndLinksModal: Function;
}) => {
  const { formatMessage } = useIntl();
  const { hasPermission } = useRbac(permissionList);

  const hasUpdatePermission = hasPermission(PERMISSIONS.SPECIFICATION.UPDATE);

  let sectionActions: MenuActions[] = [];

  if (isChapterLibrary && isChapterLinked) {
    sectionActions = [
      {
        name: formatMessage(messages.detailsAndLinksChapterAction),
        label: formatMessage(messages.detailsAndLinksChapterAction),
        onClick: onOpenDetailsAndLinksModal,
        "data-test-id": "details-links-chapter-action",
      },
    ];

    if (hasUpdatePermission) {
      sectionActions.push({
        name: formatMessage(messages.unlinkChapterAction),
        label: formatMessage(messages.unlinkChapterAction),
        onClick: onOpenUnlinkModal,
        "data-test-id": "unlink-chapter-action",
      });
    }
  }

  return cleanDeep(sectionActions, { emptyArrays: false });
};

export default useSelectedChapterActions;
