import { useEffect, useState } from "react";

const useBoolean = (defaultValue?: boolean) => {
  const [value, setValue] = useState(!!defaultValue);

  const setTrue = () => {
    setValue(true);
  };
  const setFalse = () => {
    setValue(false);
  };
  const onToggle = () => {
    setValue(value => !value);
  };

  useEffect(() => {
    setValue(!!defaultValue);
  }, [defaultValue]);

  return { value, setTrue, setFalse, onToggle };
};

export default useBoolean;
