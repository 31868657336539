import { FolderRowListProps } from "./types";
import FolderRow from "../FolderRow";
import styles from "components/Quickview/styles.module.less";

const FolderRowList = ({ tradeItems }: FolderRowListProps) => {
  return (
    <>
      {(tradeItems ?? []).map(
        ({
          tradeItemId,
          tradeItemGtin,
          tradeItemName,
          tradeItemSpecifications,
        }) => (
          <div key={tradeItemId} data-testid={`trade-item-${tradeItemId}`}>
            <div className={styles.listing}>
              <div className={styles.sectionTitle}>
                {tradeItemGtin && (
                  <>
                    <strong>{tradeItemGtin}</strong>
                    &nbsp;-&nbsp;
                  </>
                )}
                {tradeItemName}
              </div>

              {tradeItemSpecifications.map(specification => (
                <FolderRow
                  key={specification.specificationId}
                  specification={specification}
                />
              ))}
            </div>
          </div>
        )
      )}
    </>
  );
};

export default FolderRowList;
