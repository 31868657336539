import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";

/* istanbul ignore next */
export const _selectPackagingSystemForm = (state: RootState) =>
  state.packagingSystemForm;

export const selectGeneralInfo = createSelector(
  _selectPackagingSystemForm,
  packagingSystemForm => packagingSystemForm.generalInfo
);
