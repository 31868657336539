import { defineMessages } from "react-intl";

export const messages = defineMessages({
  action: {
    id: "specificationDetails.sections.nutrition.action",
    defaultMessage: "Action",
  },
  modalTitle: {
    id: "specificationDetails.sections.nutrition.modalTitle",
    defaultMessage: "Add a nutrient",
  },
  addElement: {
    id: "specificationDetails.sections.nutrition.addElement",
    defaultMessage: "Add an element",
  },
  removeElementConfirmationMessage: {
    id:
      "specificationDetails.sections.nutrition.removeElement.confirmationMessage",
    defaultMessage:
      "This will delete the nutrient line, do you confirm this choice ?",
  },
  cancelRemoveElement: {
    id: "components.button.cancel",
    defaultMessage: "Cancel",
  },
  deleteElement: {
    id: "specificationDetails.sections.nutrition.removeElement.delete",
    defaultMessage: "Delete",
  },
});
