import { Label } from "@trace-one/react-components";
import { PanelFieldProps } from "./types";
import styles from "./styles.module.less";

const PanelField: React.FC<PanelFieldProps> = ({
  title,
  isMandatory,
  rightComponent,
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.label}>
        <Label required={isMandatory} title={title} />
      </div>
      <div className={styles.component}>{rightComponent}</div>
    </div>
  );
};

export default PanelField;
