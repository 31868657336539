import {
  Button,
  Heading,
  Paragraph,
  Tag,
  Tooltip,
} from "@trace-one/design-system";
import Sun from "./Sun";
import styles from "./styles.module.less";
import { SiteProps } from "./types";

const Site: React.FC<SiteProps> = ({
  actions = [],
  activities = [],
  addressLine1 = "",
  addressLine2 = "",
  city = "",
  country = "",
  name = "",
  postalCode = "",
}) => {
  return (
    <div className={styles.siteCard}>
      <div className={styles.siteCardActions}>
        <Button items={actions} type="tertiary" iconName="kebab" color="grey" />
      </div>
      <div className={styles.siteCardTop}>
        <div className={styles.avatar}>
          <Sun height={64} />
        </div>
        <Tooltip text={name} placement="top">
          <Heading size="xxs" className={styles.siteCardName}>
            {name}
          </Heading>
        </Tooltip>
        <div className={styles.activityTags}>
          {activities.map(activity => {
            return (
              <div className={styles.activityTag} key={activity}>
                <Tag size="large" color="blue" mode="light" label={activity} />
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.siteCardFooter}>
        <Paragraph size="s">
          <Tooltip text={addressLine1} placement="top">
            {addressLine1}
          </Tooltip>
        </Paragraph>
        <Paragraph size="s">
          <Tooltip text={addressLine2} placement="top">
            {addressLine2}
          </Tooltip>
        </Paragraph>
        <Paragraph size="s">
          <Tooltip text={`${postalCode} ${city}`} placement="top">
            {postalCode} {city}
          </Tooltip>
        </Paragraph>
        <Paragraph size="s">{country}</Paragraph>
      </div>
    </div>
  );
};

export default Site;
