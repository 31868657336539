import { useEffect, useState } from "react";
import { fetchFolderPermissions as fetchFolderPermissionsApi } from "apis/SPEC";
import { FolderData } from "models";
import { Permissions } from "types/general";

const useFolderPermissions = ({
  folderId,
}: {
  folderId: FolderData["folderId"];
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isReadyToFetch, setIsReadyToFetch] = useState(false);
  const [permissionList, setPermissionList] = useState<Permissions>([]);

  const getFolderPermissions = () => {
    setIsReadyToFetch(true);
  };

  const fetchFolderPermissions = async () => {
    if (folderId) {
      setIsLoading(true);
      try {
        const { data } = await fetchFolderPermissionsApi({
          folderId,
        });

        setPermissionList(data);
      } catch (error) {
      } finally {
        setIsLoading(false);
        setIsReadyToFetch(false);
      }
    }
  };

  useEffect(() => {
    if (isReadyToFetch && folderId) {
      fetchFolderPermissions();
    }
  }, [isReadyToFetch]);

  return {
    getFolderPermissions,
    permissionList,
    isLoading,
  };
};

export default useFolderPermissions;
