import { useContext } from "react";
import RequirementContent from "components/Library/components/RequirementForm/components/RequirementContent";
import { LibraryDetails } from "pages/Library/components/Details/contexts";
import { RequirementType } from "types/library";
import styles from "./styles.module.less";

const RequirementDetails = () => {
  const { libraryItemDetails } = useContext(LibraryDetails);

  const {
    content,
    fileId,
    additionalInformation,
    generalInformation,
  } = libraryItemDetails;

  return (
    <div className={styles.requirementDetails}>
      <RequirementContent
        displayActions={false}
        requirementType={generalInformation.type as RequirementType}
        requirementInformation={{
          content,
          fileId,
          additionalInformation,
        }}
      />
    </div>
  );
};

export default RequirementDetails;
