import { Col, Row } from "antd";
import { Heading, UserCard, Spinner } from "@trace-one/design-system";
import { FormattedMessage } from "react-intl";
import { useOwnerInformation } from "./hooks";
import { messages } from "./messages";
import { OwnerProps } from "./types";
import styles from "../../styles.module.less";

const Owner: React.FC<OwnerProps> = ({ ownerId }) => {
  const { isLoading, hasError, owner } = useOwnerInformation({
    ownerId,
  });

  return (
    <Row>
      <Col span={24}>
        <Heading className={styles.title} size="xs">
          <FormattedMessage {...messages.owner} />
        </Heading>
      </Col>
      {isLoading ? (
        <Spinner />
      ) : hasError ? (
        <Heading size="xxs" color="red">
          <FormattedMessage {...messages.errorOwnerMessage} />
        </Heading>
      ) : (
        <Col>
          <UserCard
            size="s"
            name={`${owner.userFirstName} ${owner.userLastName}`}
            photoUrl={owner.userPhotoUrl}
            title={owner.userJobTitle}
            phone={owner.userPhone}
            email={owner.userNotificationEmail}
          />
        </Col>
      )}
    </Row>
  );
};

export default Owner;
