import { isListEmpty } from "utils/general";

export const getProductWidgetFields = (fields: Array<string>) => {
  if (isListEmpty(fields)) {
    return null;
  }

  return fields.map(field => ({
    name: field === "ean7" ? `${field}Weight` : field,
    title: `${field}Title`,
  }));
};
