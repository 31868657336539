import { Button, Heading, Modal, Datatable } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import { isListEmpty } from "utils/general";
import {
  useActions,
  useExportAction,
  useFilters,
  useFilterTags,
  useSpecificationHistorisation,
} from "./hooks";
import { HistorisationContext } from "./contexts";
import FilterTags from "./components/FilterTags";
import HeaderChildren from "./components/HeaderChildren";
import { SpecificationHistorisationModalProps } from "./types";
import generalMessages from "messages/general";
import { messages } from "./messages";
import styles from "./styles.module.less";

const SpecificationHistorisationModal: React.FC<SpecificationHistorisationModalProps> = ({
  specificationId,
  ownerCompanyId,
  supplierId,
  onCloseModal,
}) => {
  let tableContent = document.querySelector(".ant-table-body");

  const {
    filterTags,
    onAddFilterTag,
    resetFilterTags,
    onRemoveFilterTag,
  } = useFilterTags();

  const {
    selectedFilters,
    chapters,
    setDates,
    setSelectedFilterCriterias,
    onClearFiltersClick,
    addChapter,
    clearChapter,
  } = useFilters({
    specificationId,
    onAddFilterTag,
    onRemoveFilterTag,
    resetFilterTags,
  });

  const { isExportInProgress, handleExport } = useExportAction({
    specificationId,
    selectedFilters,
  });

  const {
    isLoading: isLoadingActions,
    hasError: hasErrorActions,
    actions,
    actionsDictionary,
  } = useActions();

  const {
    columns,
    specificationHistoryChanges,
    isLoading,
    scrollToTop,
  } = useSpecificationHistorisation({
    specificationId,
    tableContent,
    selectedFilters,
    actionsDictionary,
  });
  const { formatMessage } = useIntl();

  return (
    <Modal
      open
      size="l"
      onCancel={onCloseModal}
      onPrimaryButtonClick={handleExport}
      primaryButtonText={formatMessage(messages.export)}
      primaryButtonProps={{
        disabled: isListEmpty(specificationHistoryChanges),
        loading: isExportInProgress,
      }}
      closable={!isExportInProgress}
      keyboard={!isExportInProgress}
      onSecondaryButtonClick={onCloseModal}
      secondaryButtonText={formatMessage(generalMessages.cancel)}
      secondaryButtonProps={{
        disabled: isExportInProgress,
      }}
      title={formatMessage(messages.history)}
      headerChildren={
        <HistorisationContext.Provider
          value={{
            specificationId,
            ownerCompanyId,
            supplierId,
            selectedFilters,
            filterTags,
            chapters,
            addChapter,
            clearChapter,
            areFiltersDisabled: isExportInProgress,
            setSelectedFilterCriterias,
            onClearFiltersClick,
            actions,
            isLoadingActions,
            hasErrorActions,
          }}
        >
          <HeaderChildren setDates={setDates} />
        </HistorisationContext.Provider>
      }
    >
      <>
        <FilterTags filterTags={filterTags} disabled={isExportInProgress} />
        <div className={styles.resultsRow}>
          <Heading size="xs">{formatMessage(messages.results)}</Heading>&nbsp;
          <Heading size="xxs">({specificationHistoryChanges.length})</Heading>
        </div>
        <Datatable
          columns={columns}
          dataSource={specificationHistoryChanges}
          pagination={false}
          loading={isLoading}
          rowKey={element => JSON.stringify(element)}
          scroll={{
            scrollToFirstRowOnChange: false,
            y: "50vh",
          }}
        />
        <Button
          iconName="arrow-up"
          className={styles.backToTop}
          onClick={scrollToTop}
        />
      </>
    </Modal>
  );
};

export default SpecificationHistorisationModal;
