import { useEffect, useState } from "react";
import { fetchFolderQuickView } from "apis/SPEC";
import { isListEmpty } from "utils/general";
import useApiPolling from "../useApiPolling";
import { areTradeItemsAssociatedToFolder } from "./utils";
import { FolderData, FolderSpecificationData, TradeItemData } from "models";

const useFolderSpecifications = ({
  folderId,
  tradeItemIds,
}: {
  folderId?: FolderData["folderId"];
  tradeItemIds?: TradeItemData["id"][];
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [sectionNumber, setSectionNumber] = useState(0);
  const [specifications, setSpecifications] = useState<
    FolderSpecificationData[]
  >([]);
  const [stopPolling, setStopPolling] = useState(false);

  const fetchSpecifications = async () => {
    try {
      setIsLoading(true);
      setHasError(false);

      const { data: specifications } = await fetchFolderQuickView({
        id: folderId,
      });

      if (areTradeItemsAssociatedToFolder({ specifications, tradeItemIds })) {
        setStopPolling(true);
      } else {
        return;
      }

      setSectionNumber(
        !isListEmpty(specifications)
          ? specifications[0].progression.toFillSectionCount +
              specifications[0].progression.inProgressSectionCount +
              specifications[0].progression.validatedByRetailerSectionCount +
              specifications[0].progression.validatedBySupplierSectionCount
          : 0
      );
      setSpecifications(specifications);
    } catch (e) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useApiPolling({
    pollingFunction: fetchSpecifications,
    stopPolling,
  });

  useEffect(() => {
    if (folderId) {
      fetchSpecifications();
    } else {
      setStopPolling(true);
    }
  }, [folderId]);

  return {
    isLoading,
    hasError,
    specifications,
    sectionNumber,
  };
};

export default useFolderSpecifications;
