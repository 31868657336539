import {
  SectionSimpleViewData,
  SpecificationData,
  SpecificationHistoryActionData,
  SpecificationHistoryChange,
} from "models";

import {
  SpecificationChangesBodyParams,
  SpecificationChangesParams,
} from "types/filters";

import { axiosInstance } from "./";

export const fetchSpecificationHistoryChange = ({
  specificationId,
  params,
  bodyParams,
}: {
  specificationId: SpecificationData["id"];
  params?: SpecificationChangesParams;
  bodyParams?: SpecificationChangesBodyParams;
}) =>
  axiosInstance.post<{
    items: SpecificationHistoryChange[];
    totalItems: number;
  }>(
    `/api/specification-history/${specificationId}`,
    { ...bodyParams },
    {
      params,
    }
  );

export const exportSpecificationHistoryChanges = ({
  specificationId,
  params,
  bodyParams,
}: {
  specificationId: SpecificationData["id"];
  params?: SpecificationChangesParams;
  bodyParams?: SpecificationChangesBodyParams;
}) =>
  axiosInstance.post(
    `/api/specification-history/${specificationId}/export-csv`,
    { ...bodyParams },
    {
      params,
      responseType: "blob",
    }
  );

export const fetchSpecificationFields = ({
  specificationId,
  languageCode,
}: {
  specificationId: SpecificationData["id"];
  languageCode?: string;
}) =>
  axiosInstance.get<SectionSimpleViewData[]>(
    `/api/specification-history/${specificationId}/fields`,
    {
      params: {
        languageCode,
      },
    }
  );

export const fetchSpecificationHistoryActions = ({
  languageCode,
}: {
  languageCode?: string;
}) =>
  axiosInstance.get<SpecificationHistoryActionData[]>(
    `/api/specification-history/actions`,
    {
      params: {
        languageCode,
      },
    }
  );
