import { Typography } from "antd";
import { Button, Heading } from "@trace-one/design-system";
import { RequirementItemProps } from "./types";
import styles from "./styles.module.less";

const RequirementItem: React.FC<RequirementItemProps> = ({
  onRemove,
  name,
}) => {
  return (
    <div
      className={styles.requirement}
      data-test-id="card-of-assigned-requirement"
    >
      <div className={styles.name}>
        <Heading size="xs">
          <Typography.Text
            ellipsis={{
              tooltip: name,
            }}
            data-test-id="name-of-assigned-requirement"
          >
            {name}
          </Typography.Text>
        </Heading>
      </div>
      <Button
        type="tertiary"
        iconName="close"
        onClick={onRemove}
        data-testid="remove-requirement-icon"
        data-test-id="remove-requirement-icon"
        color="grey"
        size="small"
      />
    </div>
  );
};

export default RequirementItem;
