export const getCloseShareFolderModal = ({
  setIsShareFolderModalOpen,
}) => () => {
  setIsShareFolderModalOpen(false);
};

export const getOnNextButtonClick = ({
  dispatch,
  checkIfFolderIsFinalized,
  setIsShareFolderModalOpen,
}) => async () => {
  await dispatch(checkIfFolderIsFinalized());
  setIsShareFolderModalOpen(true);
};

export const getStartConfirmedSharingModal = ({
  setIsConfirmedSharignModalOpen,
  setIsShareFolderModalOpen,
}) => () => {
  setIsShareFolderModalOpen(false);
  setIsConfirmedSharignModalOpen(true);
};
