import { useState, useEffect, useRef } from "react";
import { GeneralInfoData } from "models";
import { useSelector } from "react-redux";
import { selectGeneraInfo } from "store/chapterForm/selectors";
import { isObjectEmpty } from "utils/general";

const useConfirmChangeChapterType = ({
  onUpdateProductType,
  onUpdateChapterType,
  chapterType,
  productTypeId,
}: {
  onUpdateProductType: Function;
  onUpdateChapterType: Function;
  chapterType: string;
  productTypeId: string;
}) => {
  const generalInfo = useSelector(selectGeneraInfo);
  const erroredChapterType = useRef("");
  const erroredProductType = useRef("");

  useEffect(() => {
    if (generalInfo.name && erroredChapterType.current) {
      handleOnChangeChapterType(erroredChapterType.current);
      erroredChapterType.current = "";
    } else if (generalInfo.name && erroredProductType.current) {
      handleOnChangeProductType(erroredProductType.current);
      erroredProductType.current = "";
    }
  }, [generalInfo.name]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedChapterType, setSelectedChapterType] = useState<
    GeneralInfoData["type"]
  >(chapterType);
  const [selectedProductTypeId, setSelectedProductTypeId] = useState<
    GeneralInfoData["productTypeId"]
  >(productTypeId);

  const [
    newlySelectedChapterType,
    setNewlySelectedChapterType,
  ] = useState<string>();
  const [
    newlySelectedProductTypeId,
    setNewlySelectedProductTypeId,
  ] = useState<string>();

  const handleOnChangeChapterType = async (value: string) => {
    if (value && chapterType && chapterType !== value && productTypeId) {
      setNewlySelectedChapterType(value);
      setIsModalVisible(true);
    } else {
      setSelectedChapterType(value);
      const meta = await onUpdateChapterType(value);
      if (!isObjectEmpty(meta) && meta?.requestStatus === "rejected") {
        erroredChapterType.current = meta.arg;
      }
    }
  };

  const handleOnChangeProductType = async (value: string) => {
    if (value && productTypeId && productTypeId !== value && chapterType) {
      setNewlySelectedProductTypeId(value);
      setIsModalVisible(true);
    } else {
      setSelectedProductTypeId(value);
      const meta = await onUpdateProductType(value);
      if (!isObjectEmpty(meta) && meta?.requestStatus === "rejected") {
        erroredProductType.current = meta.arg;
      }
    }
  };

  const onConfirmChangeChapterType = async () => {
    if (newlySelectedChapterType) {
      await onUpdateChapterType(newlySelectedChapterType);

      setSelectedChapterType(newlySelectedChapterType);
      setNewlySelectedChapterType(undefined);
    }

    if (newlySelectedProductTypeId) {
      await onUpdateProductType(newlySelectedProductTypeId);
      setSelectedProductTypeId(newlySelectedProductTypeId);
      setNewlySelectedProductTypeId(undefined);
    }

    setIsModalVisible(false);
  };

  const onCancelChangeChapterType = () => {
    if (newlySelectedChapterType) {
      setNewlySelectedChapterType(undefined);
      setSelectedChapterType(chapterType);
    }

    if (newlySelectedProductTypeId) {
      setNewlySelectedProductTypeId(undefined);
      setSelectedProductTypeId(productTypeId);
    }

    setIsModalVisible(false);
  };

  return {
    selectedChapterType,
    selectedProductTypeId,
    isModalVisible,
    handleOnChangeChapterType,
    handleOnChangeProductType,
    onConfirmChangeChapterType,
    onCancelChangeChapterType,
  };
};

export default useConfirmChangeChapterType;
