import cx from "classnames";
import { Icon } from "@trace-one/design-system";
import ProgressBar from "components/Quickview/components/ProgressBar";
import { getOnSectionClick } from "./utils";
import { ProgressRowProps } from "./types";
import styles from "components/Quickview/styles.module.less";

const ProgressRow: React.FC<ProgressRowProps> = ({
  section,
  onSectionClick,
}) => {
  const {
    toFillSectionCount,
    inProgressSectionCount,
    validatedBySupplierSectionCount,
    validatedByRetailerSectionCount,
    sectionId,
    sectionName,
  } = section;

  const totalCount =
    toFillSectionCount +
    inProgressSectionCount +
    validatedByRetailerSectionCount +
    validatedBySupplierSectionCount;

  return (
    <div className={cx(styles.sectionRow)} data-testid={`section-${sectionId}`}>
      <div className={cx(styles.firstSpecificationColumn, styles.name)}>
        <Icon name="hierarchy-link" size="small" />
        <div
          className={cx(styles.padding, styles.link)}
          onClick={getOnSectionClick({ onSectionClick, section })}
        >
          {sectionName}
        </div>
        <div className={styles.greyBar} />
      </div>
      <div className={styles.secondColumn}>
        <ProgressBar
          totalCount={totalCount}
          toFillSectionCount={toFillSectionCount}
          inProgressSectionCount={inProgressSectionCount}
          validatedBySupplierSectionCount={validatedBySupplierSectionCount}
          validatedByRetailerSectionCount={validatedByRetailerSectionCount}
        />
      </div>
    </div>
  );
};

export default ProgressRow;
