export const getKeyForServiceType = (productOrigin: string) =>
  productOrigin === "suppliers" ? "supplierIds" : "ownerCompanyIds";

export const getProductOrigin = ({
  companyActivity,
}: {
  companyActivity: string;
}) => (companyActivity === "2" ? "retailers" : "suppliers");

export const prepareInitialSelectedServiceTypes = ({
  serviceTypeId,
  serviceTypeName,
}) => () =>
  serviceTypeId ? [{ value: serviceTypeId, label: serviceTypeName }] : [];

export const prepareInitialselectedStatuses = ({
  formatMessage,
  messages,
  specificationStatus,
  statuses,
}) => () =>
  specificationStatus && statuses.indexOf(specificationStatus) > -1
    ? [
        {
          value: specificationStatus,
          label: messages[specificationStatus]
            ? formatMessage(messages[specificationStatus])
            : specificationStatus,
        },
      ]
    : [];
