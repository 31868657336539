import { useContext, useEffect } from "react";
import { useIntl } from "react-intl";
import classNames from "classnames";
import { Heading, Paragraph } from "@trace-one/design-system";
import { useTranslationManagement } from "components/TranslationManagement/hooks";
import StepTitle from "components/Library/components/StepTitle";
import Chapters from "components/Chapters";
import Spin from "components/Spin";
import { isListEmpty } from "utils/general";
import { translationManagementMessages } from "messages";
import TranslationCompanies from "../TranslationCompanies";
import TranslationCollaboration from "../TranslationCollaboration";
import { TranslationManagementContext } from "components/TranslationManagement/contexts";
import styles from "../../styles.module.less";

const TranslationManagementContent: React.FC = () => {
  const { formatMessage } = useIntl();

  const {
    specificationId,
    specificationHeaderTitle,
    translationTemplateId,
    translationTemplate,
    setTranslationTemplate,
    setPreviousTranslationTemplate,
    setShouldDeleteOnCancel,
    setSpecificationLabelingTranslationData,
  } = useContext(TranslationManagementContext);

  const {
    isTemplateLoading,
    onOpenOverlay,
    onHideChapterSection,
    onUnhideChapterSection,
    onSetAllChapterSectionsVisibility,
    isChecked,
    isIndeterminate,
  } = useTranslationManagement({
    specificationId,
    translationTemplate,
    setTranslationTemplate,
    setPreviousTranslationTemplate,
    setShouldDeleteOnCancel,
    translationTemplateId,
    setSpecificationLabelingTranslationData,
  });

  useEffect(() => {
    onOpenOverlay();
  }, []);

  return (
    <div
      className={classNames(
        styles["translation-management-content"],
        "hide-scrollbar"
      )}
    >
      <header>
        <Heading size="m" className="mb-2">
          {formatMessage(translationManagementMessages.overlayTitle)}
        </Heading>
        <Paragraph className="mb-2">
          {formatMessage(translationManagementMessages.overlaySubtitle)}
        </Paragraph>
        <Heading size="xxs">
          <ul>
            <li>{specificationHeaderTitle}</li>
          </ul>
        </Heading>
      </header>
      {isTemplateLoading ? (
        <Spin />
      ) : (
        <>
          <StepTitle
            title={formatMessage(translationManagementMessages.stepOneTitle)}
            subtitle={formatMessage(
              translationManagementMessages.stepOneDescription
            )}
            step={1}
          />
          {!isListEmpty(translationTemplate?.chapters) && (
            <Chapters
              chapters={translationTemplate?.chapters}
              displayCounter={false}
              displayActions={true}
              onHideSection={undefined} // to be removed when template creation is removed from spec and folder wizards
              onUnhideSection={undefined} // to be removed when template creation is removed from spec and folder wizards
              onHideChapterSection={onHideChapterSection}
              onUnhideChapterSection={onUnhideChapterSection}
              onSetAllChapterSectionsVisibility={
                onSetAllChapterSectionsVisibility
              }
              areAllChapterSectionsChecked={isChecked}
              areChapterSectionsIndeterminate={isIndeterminate}
            />
          )}
          <TranslationCompanies isLoading={isTemplateLoading} />
          <TranslationCollaboration isLoading={isTemplateLoading} />
        </>
      )}
    </div>
  );
};

export default TranslationManagementContent;
