import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectLanguageCode } from "store/user/selectors";
import { fetchTradeItems } from "apis/PMD";
import { fetchReferenceListsItems } from "apis/RLMD";
import { ReferenceListItemData, TradeItemData } from "models";
import { isListEmpty } from "utils/general";

const useNetContentFromTradeItems = ({
  tradeItemIds,
}: {
  tradeItemIds: string[];
}) => {
  const languageCode = useSelector(selectLanguageCode);
  const [tradeItems, setTradeItems] = useState<TradeItemData[]>([]);
  const [netContentRefList, setNetContentRefList] = useState<
    ReferenceListItemData[]
  >([]);

  const getNetContent = async () => {
    let netContentUnitIds = [];

    try {
      const { data } = await fetchTradeItems({
        ids: tradeItemIds,
      });

      data.forEach(({ netContentUnit }) => {
        if (netContentUnit) {
          netContentUnitIds.push(netContentUnit);
        }
      });

      setTradeItems(prev => {
        return [...prev, ...data];
      });
    } catch (_) {}

    if (!isListEmpty(netContentUnitIds)) {
      try {
        const { data } = await fetchReferenceListsItems({
          ids: [...new Set(netContentUnitIds)],
          languageCode,
        });
        setNetContentRefList(prev => {
          return [...prev, ...data];
        });
      } catch (_) {}
    }
  };
  useEffect(() => {
    if (!isListEmpty(tradeItemIds)) {
      getNetContent();
    }
  }, [JSON.stringify(tradeItemIds)]);

  return {
    tradeItems,
    netContentRefList,
  };
};

export default useNetContentFromTradeItems;
