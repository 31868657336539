import { useEffect, useState } from "react";
import { Col } from "antd";
import { ToggleButtons } from "@trace-one/design-system";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import TextWidget from "../TextWidget";
import DateWidget from "../DateWidget";
import { DatePickerSwitchWidgetProps } from "./types";
import { getOnItemChange, isValidDate } from "./utils";
import styles from "./styles.module.less";

const DatePickerSwitchWidget: React.FC<DatePickerSwitchWidgetProps> = ({
  disabled,
  formContext,
  id,
  placeholder,
  schema,
  onChange,
  uiSchema,
  value,
}) => {
  const { getTranslationForKey } = useFormSchemaTranslation();
  const { displayActions, isWidgetEditable } = formContext;
  const [actualForm, setActualForm] = useState(value);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [switchValue, setSwitchValue] = useState(
    isValidDate({ value }) ? "calendar" : "text-code"
  );

  const onItemChange = getOnItemChange({
    setActualForm,
    onChange,
    setIsDatePickerOpen,
  });

  useEffect(() => {
    setActualForm(value);
  }, [value]);

  const options = [
    {
      iconName: "text-code",
      value: "text-code",
      "data-test-id": "type-text",
      name: getTranslationForKey("code"),
    },
    {
      iconName: "calendar",
      value: "calendar",
      "data-test-id": "type-datePicker",
      name: getTranslationForKey("calendar"),
    },
  ];

  return (
    <Col className={styles.container}>
      {displayActions && (
        <Col xs={24} md={12} className={styles.switcher}>
          <ToggleButtons
            disabled={disabled || !isWidgetEditable(schema.propertyId)}
            options={options}
            value={switchValue}
            onChange={value => {
              setSwitchValue(value);
              onItemChange(null);
              setIsDatePickerOpen(true);
            }}
          />
        </Col>
      )}
      <Col xs={24} md={12}>
        {switchValue === "calendar" ? (
          <DateWidget
            isOpen={isDatePickerOpen}
            disabled={disabled}
            id={id}
            onChange={onItemChange}
            placeholder={
              getTranslationForKey(uiSchema["ui:date:placeholder"]) ||
              placeholder
            }
            onBlur={() => setIsDatePickerOpen(false)}
            onFocus={() => setIsDatePickerOpen(true)}
            value={actualForm}
            schema={schema}
            formContext={formContext}
            hasSuffixIcon={false}
          />
        ) : (
          <TextWidget
            value={actualForm}
            onChange={onItemChange}
            disabled={disabled}
            schema={schema}
            formContext={formContext}
            placeholder={
              getTranslationForKey(uiSchema["ui:text:placeholder"]) ||
              placeholder
            }
          />
        )}
      </Col>
    </Col>
  );
};

export default DatePickerSwitchWidget;
