import { Paragraph, Icon } from "@trace-one/design-system";

const WarningMessage = ({ children }) => {
  return (
    <>
      <Icon
        data-testid="draftIcon"
        name="file-warning"
        size="large"
        color="primary"
      />
      <Paragraph>{children}</Paragraph>
    </>
  );
};

export default WarningMessage;
