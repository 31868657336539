import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "store";
import { PackagingSystemComponentData } from "models/packaging";
import Spin from "components/Spin";
import {
  selectIsLoading,
  selectPackagingComponent,
} from "store/packaging/selectors";
import { fetchPackagingComponentAsync } from "store/packaging/asyncActions";
import { useReferenceListsItems, useSpecificationReflistIds } from "hooks";

const withPackagingComponentDetails = Component => props => {
  const dispatch = useAppDispatch();

  const { packagingComponentId } = useParams<{
    packagingComponentId: PackagingSystemComponentData["componentId"];
  }>();

  useEffect(() => {
    dispatch(fetchPackagingComponentAsync({ packagingComponentId }));
  }, [packagingComponentId]);

  const packagingComponentDetails = useSelector(selectPackagingComponent);
  const isLoading = useSelector(selectIsLoading);

  const { getReflistIdsFromSections } = useSpecificationReflistIds();

  const ids = useMemo(() => {
    return getReflistIdsFromSections(packagingComponentDetails.sections);
  }, [JSON.stringify(packagingComponentDetails.sections)]);

  useReferenceListsItems({
    refListIds: [...(ids?.reflistIds || [])],
  });

  if (isLoading) return <Spin />;

  return (
    <Component
      {...props}
      packagingComponentDetails={packagingComponentDetails}
    />
  );
};

export default withPackagingComponentDetails;
