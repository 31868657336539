import { ClaimData, File } from "models";
import { ReduxClaimType } from "store/claimForm/claimFormSlice";
import { isObjectEmpty } from "utils/general";

export const prepareClaimFormData = ({
  libraryItemDetails,
  file = {},
  shouldSaveTagAsArrayOfString = false,
}: {
  libraryItemDetails: ClaimData;
  file?: File;
  shouldSaveTagAsArrayOfString?: boolean;
}) => {
  const claimForm: ReduxClaimType = {
    generalInfo: {
      id: libraryItemDetails?.id,
      name: libraryItemDetails?.name,
      productTypeId: libraryItemDetails?.productTypeId,
      claimType: libraryItemDetails?.claimType,
      tags: shouldSaveTagAsArrayOfString
        ? libraryItemDetails?.tagIds
        : libraryItemDetails?.tagIds?.map(tagId => ({
            tagId,
          })),
      requiresSupportingDocuments:
        libraryItemDetails?.requiresSupportingDocuments,
    },
    sentenceTranslations: libraryItemDetails?.sentenceTranslations,
    file: {
      id: libraryItemDetails?.logoFileId || undefined,
      ...(!isObjectEmpty(file) && {
        fileName: file?.name,
        fileSize: file?.size,
      }),
    },
  };

  return claimForm;
};

export const prepareClaimDetailsData = (
  claimData: ClaimData,
  shouldSetTags: boolean = false
) => {
  const claimDetails = {
    ...claimData,
    sentenceTranslations: claimData?.sentenceTranslations,
    logoFileId: claimData?.logoFileId,
    versionNumber: claimData?.version?.number,
    generalInformation: {
      name: claimData?.name,
      type: claimData?.claimType,
      state: claimData?.state,
      lastModificationDate: claimData?.lastUpdateDateUtc,
      tags: shouldSetTags ? claimData?.tagIds : [],
      requiresSupportingDocuments: claimData?.requiresSupportingDocuments,
    },
  };

  return claimDetails;
};
