import { ProductSpecification } from "store/folderCreation/types";
import { CollaborationWizard } from "models";
import { getDictionary } from "utils/general";

export const getCompanyIdsWithLinkedProducts = ({
  productSpecifications,
  folderId,
  collaborations,
}: {
  productSpecifications: {
    [key: string]: ProductSpecification[];
  };
  folderId: string;
  collaborations: CollaborationWizard[];
}) => {
  const companies = [];

  const suppliersEnableCollaborationDictionary = getDictionary({
    data: collaborations,
    key: "collaboratingCompanyId",
    value: "enabled",
  });

  Object.values(productSpecifications).forEach(specifications => {
    specifications.forEach(specification => {
      if (specification.folderId === folderId) {
        if (
          companies.findIndex(
            ({ collaboratingCompanyId }) =>
              collaboratingCompanyId === specification.supplierId
          ) === -1
        ) {
          companies.push({
            collaboratingCompanyId: specification.supplierId,
            numberOfLinkedProducts: 1,
            isCollaborationEnabled:
              suppliersEnableCollaborationDictionary[specification.supplierId],
          });
        } else {
          const findIndex = companies.findIndex(
            ({ collaboratingCompanyId }) =>
              specification.supplierId === collaboratingCompanyId
          );
          companies[findIndex].numberOfLinkedProducts++;
        }
      }
    });
  });

  return companies;
};
