import { useIntl } from "react-intl";
import classNames from "classnames";
import { Icon } from "@trace-one/design-system";
import Checkbox from "./components/Checkbox";
import Details from "./components/Details";
import { InfoProps } from "./types";
import { messages } from "./messages";
import styles from "./styles.module.less";

const Info: React.FC<InfoProps> = ({
  isDisabled,
  object,
  onClick,
  checkboxProps,
}) => {
  const { formatMessage } = useIntl();
  const { hasCheckbox, ...restCheckboxProps } = checkboxProps;

  return (
    <div
      {...(!isDisabled && {
        onClick,
      })}
      className={classNames(styles.info, {
        [styles.isClickable]: !isDisabled && typeof onClick === "function",
      })}
    >
      {hasCheckbox ? <Checkbox {...restCheckboxProps} /> : null}
      <div>
        <Icon
          name="file"
          size="large"
          color={isDisabled ? "grey-3" : "primary"}
        />
      </div>
      <Details
        name={object.name}
        typeName={formatMessage(messages.specification)}
      />
    </div>
  );
};

export default Info;
