import { Row, Col } from "antd";
import { getPropertiesObjectFromArray } from "../../templates/utils";
import TreatmentWidgetReadOnly from "./components/TreatmentWidgetReadOnly";
import { TreatmentWidgetProps } from "./types";

const TreatmentWidget: React.FC<TreatmentWidgetProps> = ({
  properties,
  schema,
  formContext,
}) => {
  const propertyObjects = getPropertiesObjectFromArray(properties);
  const { displayActions, refListDictionary, emptyValue } = formContext;
  const colSpans = {
    lg: 8,
    md: 12,
    ...(displayActions
      ? {
          sm: 24,
          xs: 24,
        }
      : {
          sm: 8,
          xs: 8,
        }),
  };
  const { validationRequired = [] } = schema;

  const preHarvestEndOfUseProps =
    propertyObjects?.preHarvestEndOfUse?.content?.props || false;

  const prepareContent = name => ({
    ...propertyObjects[name].content,
    props: {
      ...propertyObjects[name].content.props,
      required: validationRequired.includes(name),
    },
  });

  const typeContent = prepareContent("type");
  const productNameContent = prepareContent("productName");
  const frequencyOfUseContent = prepareContent("frequencyOfUse");
  const preHarvestEndOfUseContent = !!preHarvestEndOfUseProps
    ? prepareContent("preHarvestEndOfUse")
    : null;
  const commentContent = prepareContent("comment");

  if (!displayActions) {
    const readOnlyProps = {
      typeContent,
      productNameContent,
      frequencyOfUseContent,
      preHarvestEndOfUseContent,
      commentContent,
      refListDictionary,
      emptyValue,
    };

    return <TreatmentWidgetReadOnly {...readOnlyProps} />;
  }

  return (
    <>
      <Row gutter={20}>
        <Col {...colSpans}>{typeContent}</Col>
        <Col {...colSpans}>{productNameContent}</Col>
        <Col {...colSpans}>{frequencyOfUseContent}</Col>
      </Row>
      {!!preHarvestEndOfUseProps ? (
        <Row gutter={20}>
          <Col {...colSpans} lg={12} xl={10}>
            {preHarvestEndOfUseContent}
          </Col>
        </Row>
      ) : null}
      <Row>
        <Col sm={24}>{commentContent}</Col>
      </Row>
    </>
  );
};

export default TreatmentWidget;
