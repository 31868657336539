import { LibraryObjectData } from "models";
import { LIBRARY_OBJECTS_VIEW_SIZES } from "utils/constants";
import { isListEmpty } from "utils/general";

export const getColSpansFromView = (view: LIBRARY_OBJECTS_VIEW_SIZES) => {
  switch (view) {
    case "small":
      return {
        xl: 12,
        lg: 24,
        md: 24,
        sm: 24,
        xs: 24,
      };
    default:
      return {
        xl: 6,
        lg: 8,
        md: 12,
        sm: 24,
        xs: 24,
      };
  }
};

export const isLibraryItemDisabled = ({
  disabledLibraryItems,
  libraryItemId,
}: {
  disabledLibraryItems: string[];
  libraryItemId: LibraryObjectData["id"];
}) =>
  !isListEmpty(disabledLibraryItems) &&
  disabledLibraryItems.findIndex(id => libraryItemId === id) > -1;
