import { LibraryObjectData } from "models";

export const getRedirectToAction = ({
  libraryItemInfo,
  purge,
}: {
  libraryItemInfo: LibraryObjectData;
  purge: Function;
}) => (redirectTo: Function) => () => {
  purge();
  redirectTo(libraryItemInfo);
};
