import { Row, Col } from "antd";
import { useIntl } from "react-intl";
import ButtonWithModalTree from "components/ButtonWithModalTree";
import { REFLIST_IDS } from "utils/constants";
import { getOnConfirmModalHandler } from "./utils";
import { AddAnAllergenProps } from "./types";
import messages from "./messages";
import styles from "components/Library/components/MaterialForm/styles.module.less";

const AddAnAllergen: React.FC<AddAnAllergenProps> = ({
  onAddAllergens,
  selectedAllergens,
  disableActions,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Row justify="center" className={styles.rowAddButton}>
      <Col>
        <ButtonWithModalTree
          modalTitle={formatMessage(messages.modalTitle)}
          openBtnLabel={formatMessage(messages.addButton)}
          onConfirmModal={getOnConfirmModalHandler(onAddAllergens)}
          defaultCheckedItems={[...selectedAllergens]}
          isButtonDisabled={disableActions}
          hierarchyId={REFLIST_IDS.ALLERGENS}
        />
      </Col>
    </Row>
  );
};

export default AddAnAllergen;
