import { useIntl } from "react-intl";
import { Row, Col } from "antd";
import dayjs from "dayjs";
import {
  Modal,
  Paragraph,
  Heading,
  Datatable,
  Button,
  Divider,
} from "@trace-one/design-system";
import { useHistory } from "react-router-dom";
import Status from "components/Status";
import { useChapterDetailsModal, useLinkedSpecifications } from "./hooks";
import { getRowKeyForTable } from "utils/table";
import { isListEmpty } from "utils/general";
import { LIBRARY_TAB_KEYS } from "utils/constants";
import { DetailsAndLinksChapterModalProps } from "./types";
import { messages } from "./messages";
import generalMessages from "messages/general";
import styles from "./styles.module.less";

const DetailsAndLinksChapterModal: React.FC<DetailsAndLinksChapterModalProps> = ({
  onCloseModal,
  chapterId,
  selectedChapterId,
  chapterType,
  states,
  rowSelection: defaultRowSelection,
  title,
  subtitle,
  primaryButtonLabel,
  onPrimaryButtonClick,
  secondaryButtonLabel,
  onSecondaryButtonClick,
  actionType,
}) => {
  const { formatMessage } = useIntl();
  const { location } = useHistory();

  const { chapterInfo } = useChapterDetailsModal({
    chapterId: selectedChapterId || chapterId,
    chapterType,
  });

  let tableContent = document.querySelector(".ant-table-body");

  const {
    columns,
    linkedSpecifications,
    isLoading,
    scrollToTop,
    rowSelection,
  } = useLinkedSpecifications({
    tableContent,
    onCloseModal,
    chapterId: selectedChapterId || chapterId,
    chapterType,
    states,
    defaultRowSelection,
    actionType,
  });

  return (
    <Modal.Simple
      visible
      size="l"
      keyboard={false}
      onCancel={onCloseModal}
      title={title}
      primaryButtonText={primaryButtonLabel}
      onPrimaryButtonClick={onPrimaryButtonClick}
      primaryButtonProps={{
        disabled: isListEmpty(rowSelection?.selectedRowKeys),
      }}
      secondaryButtonText={secondaryButtonLabel}
      onSecondaryButtonClick={onSecondaryButtonClick}
    >
      <div>
        {subtitle && (
          <Row className={styles.subtitle}>
            <Paragraph variant={"body-regular"}>{subtitle}</Paragraph>
          </Row>
        )}
        {!location.pathname.includes(LIBRARY_TAB_KEYS.VERSION_HISTORY) && (
          <Row align="middle" gutter={[16, 0]}>
            <Col>
              <Heading size="xxs">
                {formatMessage(messages.detailsAndLinksChapterModalChapterName)}
              </Heading>
            </Col>
            <Col>
              <Paragraph>{chapterInfo?.name}</Paragraph>
            </Col>
          </Row>
        )}
        <Row
          align="middle"
          gutter={[16, 0]}
          className={styles.topSpace}
          wrap={false}
        >
          <Col>
            <Heading size="xxs">
              {formatMessage(messages.detailsAndLinksChapterModalChapterStatus)}
            </Heading>
          </Col>
          <Col>
            <Status.Tag state={chapterInfo?.state} />
          </Col>
          {!location.pathname.includes(LIBRARY_TAB_KEYS.VERSION_HISTORY) && (
            <>
              <Col offset={2}>
                <Heading size="xxs">
                  {formatMessage(
                    messages.detailsAndLinksChapterModalChapterCreationDate
                  )}
                </Heading>
              </Col>
              <Col>
                <Paragraph>
                  {dayjs(chapterInfo?.creationDateUtc)
                    .local()
                    .format("DD/MM/YYYY")}
                </Paragraph>
              </Col>
            </>
          )}
          <Col offset={2}>
            <Heading size="xxs">
              {formatMessage(generalMessages.version)}
            </Heading>
          </Col>
          <Col>
            <Paragraph>{chapterInfo?.version?.number}</Paragraph>
          </Col>
        </Row>
        <Divider color={"grey-3"} dashed={true} height={16} />
        <div className={styles.table}>
          <Datatable.Plain
            columns={columns}
            dataSource={linkedSpecifications}
            pagination={false}
            loading={isLoading}
            rowKey={getRowKeyForTable("specification")}
            rowSelection={rowSelection}
            scroll={{
              scrollToFirstRowOnChange: false,
              y: "35vh",
            }}
          />
          <Button
            iconName="arrow-up"
            className={styles.backToTop}
            onClick={scrollToTop}
          />
        </div>
      </div>
    </Modal.Simple>
  );
};

export default DetailsAndLinksChapterModal;
