import { createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import {
  updateName,
  getPackagingSystemSmartDoc,
  addTag,
  removeTag,
  updateGtin,
} from "./asyncActions";
import initialState from "./initialState";
import reducers from "./reducers";
import {
  handleActionFulfilled,
  handleActionPending,
  handleActionRejected,
} from "../helpers";

export const slice = createSlice({
  name: "packagingSystemForm",
  initialState,
  reducers,
  extraReducers: builder => {
    /* istanbul ignore next*/
    builder.addCase(PURGE, () => ({ ...initialState }));

    builder.addCase(updateName.pending, state => {
      handleActionPending(state.updateStatus);
    });
    builder.addCase(updateName.fulfilled, (state, action) => {
      state.generalInfo.name = action.meta.arg;

      handleActionFulfilled(state.updateStatus);
    });
    builder.addCase(updateName.rejected, state => {
      handleActionRejected(state.updateStatus);
    });

    builder.addCase(getPackagingSystemSmartDoc.pending, state => {
      handleActionPending(state.updateStatus);
    });
    builder.addCase(getPackagingSystemSmartDoc.fulfilled, (state, action) => {
      state.generalInfo = action.payload;

      handleActionFulfilled(state.updateStatus);
    });
    builder.addCase(getPackagingSystemSmartDoc.rejected, state => {
      handleActionRejected(state.updateStatus);
    });

    builder.addCase(addTag.pending, state => {
      handleActionPending(state.updateStatus);
    });
    builder.addCase(addTag.fulfilled, (state, action) => {
      handleActionFulfilled(state.updateStatus);
    });
    builder.addCase(addTag.rejected, state => {
      handleActionRejected(state.updateStatus);
    });

    builder.addCase(removeTag.pending, state => {
      handleActionPending(state.updateStatus);
    });
    builder.addCase(removeTag.fulfilled, (state, action) => {
      handleActionFulfilled(state.updateStatus);
    });
    builder.addCase(removeTag.rejected, state => {
      handleActionRejected(state.updateStatus);
    });

    builder.addCase(updateGtin.pending, state => {
      handleActionPending(state.updateStatus);
    });
    builder.addCase(updateGtin.fulfilled, (state, action) => {
      state.generalInfo = action.payload;
      handleActionFulfilled(state.updateStatus);
    });
    builder.addCase(updateGtin.rejected, state => {
      handleActionRejected(state.updateStatus);
    });
  },
});

export const { setFormData } = slice.actions;

export default slice.reducer;
