import {
  isStateDraft,
  isStateSignedBySupplier,
  isStateValidatedByRetailer,
} from "utils/specifications";
import { fetchFolder } from "apis/SPEC";
import { FolderData, SpecificationData } from "models";

export const getSignProperties = ({
  specificationState,
  onButtonClick,
  hasSignPermission,
  isRetailer,
  isTargetMode,
}: {
  specificationState: SpecificationData["state"];
  onButtonClick: Function;
  hasSignPermission: boolean;
  isRetailer: boolean;
  isTargetMode: boolean;
}) => {
  if (isTargetMode) {
    return {
      buttonProps: {
        disabled: true,
      },
      messageKey: "sign",
    };
  }

  if (!isRetailer) {
    if (isStateValidatedByRetailer(specificationState)) {
      return {
        buttonProps: {
          disabled: !hasSignPermission,
          onClick: onButtonClick,
        },
        messageKey: "sign",
      };
    }

    if (isStateSignedBySupplier(specificationState)) {
      return {
        buttonProps: {
          disabled: true,
        },
        messageKey: "waitingForRetailer",
      };
    }
  }

  if (isRetailer) {
    if (isStateValidatedByRetailer(specificationState)) {
      return {
        buttonProps: {
          disabled: true,
        },
        messageKey: "waitingForSupplier",
      };
    }

    if (isStateSignedBySupplier(specificationState)) {
      return {
        buttonProps: {
          disabled: !hasSignPermission,
          onClick: onButtonClick,
        },
        messageKey: "sign",
      };
    }
  }

  return {
    buttonProps: {
      disabled: true,
    },
    messageKey: "sign",
  };
};

export const getOnButtonClick = ({
  isRetailer,
  folderId,
  languageCode,
  handleOpenModal,
  setIsConfirmationDialogVisible,
}: {
  isRetailer: boolean;
  folderId: FolderData["folderId"];
  languageCode: string;
  handleOpenModal: Function;
  setIsConfirmationDialogVisible: Function;
}) => async () => {
  if (!isRetailer) {
    handleOpenModal();
    return;
  }

  if (!folderId) {
    handleOpenModal();
    return;
  }

  const {
    data: { state },
  } = await fetchFolder({ id: folderId, languageCode });

  if (isStateDraft(state)) {
    setIsConfirmationDialogVisible(true);
  } else {
    handleOpenModal();
  }
};

export const getOnConfirmClick = ({
  setIsConfirmationDialogVisible,
  handleOpenModal,
}: {
  setIsConfirmationDialogVisible: Function;
  handleOpenModal: Function;
}) => () => {
  setIsConfirmationDialogVisible(false);
  handleOpenModal();
};

export const getOnVisibleChange = ({
  setIsConfirmationDialogVisible,
}: {
  setIsConfirmationDialogVisible: Function;
}) => (visible: boolean) => {
  if (!visible) {
    setIsConfirmationDialogVisible(visible);
  }
};

export const getPlacementForConfirmationDialog = (lg: boolean) =>
  lg ? "topLeft" : "top";
