import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fetchNutrientByIds } from "apis/CATALOGUE";
import { useReferenceListsItems } from "hooks";
import { NutrientItemDictionary } from "components/SpecificationSection/components/FormSchema/components/NutritionBasic/types";
import { useAppDispatch } from "store";
import { selectLanguageCode } from "store/user/selectors";
import { updateCatalogDictionary } from "store/generalData/asyncActions";
import {
  prepareTableData,
  collectNutrientData,
  collectMandatoryNutrients,
  createNutritionDictionary,
} from "../../../utils";
import { isListEmpty, isObjectEmpty } from "utils/general";
import { Dictionary } from "types/general";
import { getNutrientsForCatalogDictionary } from "utils/nutrition";
import { NutritionFormData } from "../../../types";

const useNutritionTable = ({
  formData,
  reFetchNutrients,
}: {
  formData: NutritionFormData;
  reFetchNutrients?: boolean;
}) => {
  const [unitOfMeasureIds, setUnitOfMeasureIds] = useState<string[]>([]);
  const [existingNutrientIds, setExistingNutrientIds] = useState<string[]>([]);
  const [nutrientDictionary, setNutrientDictionary] = useState<
    Dictionary<NutrientItemDictionary | string>
  >({});
  const [mandatoryNutrientIds, setMandatoryNutrientIds] = useState<string[]>(
    []
  );
  const [
    mandatoryNutrientIdsIncludingParentsWithMandatoryChild,
    setMandatoryNutrientIdsIncludingParentsWithMandatoryChild,
  ] = useState<string[]>([]);

  const dispatch = useAppDispatch();

  const languageCode = useSelector(selectLanguageCode);

  const { refListDictionary: unitOfMeasuresRefList } = useReferenceListsItems({
    refListIds: unitOfMeasureIds,
  });

  const fetchExistingNutrients = async (nutrientIds: string[]) => {
    try {
      const { data: nutrients } = await fetchNutrientByIds({
        nutrientIds,
        languageCode,
      });
      setNutrientDictionary(prev => {
        return { ...prev, ...createNutritionDictionary(nutrients) };
      });

      dispatch(
        updateCatalogDictionary(getNutrientsForCatalogDictionary(nutrients))
      );

      const {
        mandatoryNutrientIdsIncludingParentsWithMandatoryChild,
        mandatoryNutrientIds,
      } = collectMandatoryNutrients(nutrients);

      setMandatoryNutrientIdsIncludingParentsWithMandatoryChild(
        mandatoryNutrientIdsIncludingParentsWithMandatoryChild
      );
      setMandatoryNutrientIds(mandatoryNutrientIds);
    } catch (_) {}
  };

  useEffect(() => {
    if (!isObjectEmpty(formData)) {
      const { nutrientIds, unitOfMeasureIds } = collectNutrientData(
        formData.nutrientFamilies
      );

      setUnitOfMeasureIds(unitOfMeasureIds);
      setExistingNutrientIds(nutrientIds);
    }
  }, [JSON.stringify(formData?.nutrientFamilies)]);

  useEffect(() => {
    if (!isListEmpty(existingNutrientIds) || reFetchNutrients) {
      fetchExistingNutrients(existingNutrientIds);
    }
  }, [
    JSON.stringify(unitOfMeasureIds),
    JSON.stringify(existingNutrientIds),
    reFetchNutrients,
  ]);

  useEffect(() => {
    setNutrientDictionary(prev => {
      return { ...prev, ...unitOfMeasuresRefList };
    });
  }, [unitOfMeasuresRefList]);

  return {
    dataSource:
      prepareTableData(
        formData?.nutrientFamilies,
        mandatoryNutrientIdsIncludingParentsWithMandatoryChild
      ) || [],
    existingNutrientIds,
    nutrientDictionary,
    mandatoryNutrientIds,
  };
};

export default useNutritionTable;
