import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fetchCategoryItems } from "apis/RLMD";
import { selectLanguageCode } from "store/user/selectors";

const useCategory = ({ categoryId }: { categoryId?: string }) => {
  const languageCode = useSelector(selectLanguageCode);
  const [categoryName, setCategoryName] = useState("");

  const getCategory = async () => {
    try {
      const { data } = await fetchCategoryItems({
        ids: [categoryId],
        languageCode,
      });

      setCategoryName(data[0].categoryItemName);
    } catch (error) {
      return [];
    }
  };

  useEffect(() => {
    if (categoryId) {
      getCategory();
    }
  }, [categoryId]);

  return {
    categoryName,
  };
};

export default useCategory;
