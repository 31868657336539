import { FolderData } from "models";

export const getOnResumeButtonClick = ({
  resumeFolder,
  id,
}: {
  resumeFolder: Function;
  id: FolderData["folderId"];
}) => async () => {
  await resumeFolder(id);
};
