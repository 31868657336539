import { LibraryObjectData } from "models";
import { LIBRARY_OBJECTS_PAGES } from "utils/constants";
import { getVersionName } from "utils/specifications";
import messages from "messages/library";
import styles from "../styles.module.less";
import { PageType } from "./types";

export const getOnTagListChange = ({
  onFieldsChange,
  setFieldsValue,
}: {
  setFieldsValue: Function;
  onFieldsChange: Function;
}) => (tagIds: LibraryObjectData["tagIds"]) => {
  setFieldsValue({
    tagIds,
  });

  onFieldsChange(tagIds);
};

export const getHeadingTitle = ({
  name,
  versionNumber,
  page,
  formatMessage,
  type,
}: {
  name?: LibraryObjectData["name"];
  versionNumber?: LibraryObjectData["versionNumber"];
  page: PageType | LIBRARY_OBJECTS_PAGES.DETAILS;
  formatMessage: Function;
  type?: string;
}) => {
  let title;
  switch (page) {
    case LIBRARY_OBJECTS_PAGES.EDIT:
      title = `${name} ${getVersionName(versionNumber)} - ${formatMessage({
        id: "general.update",
      })}`;
      break;
    case LIBRARY_OBJECTS_PAGES.CREATE_NEW_VERSION:
    case LIBRARY_OBJECTS_PAGES.DUPLICATE:
    case LIBRARY_OBJECTS_PAGES.DETAILS:
      title = `${name} ${getVersionName(versionNumber)}`;
      break;
    default:
      title = formatMessage(messages[`${type}Title`]);
  }

  return <span className={styles.titleNormalLineHeight}>{title}</span>;
};
