import { useContext } from "react";
import { useIntl } from "react-intl";
import StepTitle from "components/StepTitle";
import Library from "components/Library";
import { SelectLibraryObjectsContext } from "components/SelectLibraryObjectsModal/contexts";
import { LIBRARY_OBJECT_STATES } from "utils/constants";
import { LibraryApiParamsVersion } from "components/Library/components/types";
import { LibraryObjectType } from "types/library";
import { messages } from "./messages";

export interface SelectLibraryObjectStep {
  type: LibraryObjectType;
  displaySelectedLibraryItems?: boolean;
  version?: LibraryApiParamsVersion;
  disabledLibraryItems?: string[];
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  searchPlaceholder?: string;
}

const SelectLibraryObjectsStep: React.FC<SelectLibraryObjectStep> = ({
  type,
  displaySelectedLibraryItems = false,
  version,
  disabledLibraryItems = [],
  title,
  subtitle,
  searchPlaceholder,
}) => {
  const { formatMessage } = useIntl();
  const { selectedLibraryObjects, onSelectLibraryObject } = useContext(
    SelectLibraryObjectsContext
  );

  return (
    <>
      <StepTitle
        step={1}
        title={title ?? formatMessage(messages.selectRequirementTitle)}
        subtitle={subtitle ?? formatMessage(messages.selectRequirementSubtitle)}
        titleClassName={displaySelectedLibraryItems ? "mb-1" : ""}
        subtitleClassName={displaySelectedLibraryItems ? "mb-2" : ""}
      />
      <Library.List
        onItemClick={onSelectLibraryObject}
        displayActions={false}
        hasCreateButton={false}
        hasSwitchView={false}
        selectedLibraryItems={selectedLibraryObjects}
        disabledLibraryItems={disabledLibraryItems}
        excludedLibraryItemIds={selectedLibraryObjects?.map(({ id }) => id)}
        type={type}
        view="small"
        extraParams={{
          states: [
            LIBRARY_OBJECT_STATES.PUBLISHED,
            LIBRARY_OBJECT_STATES.LOCKED,
          ],
          ...(version && {
            version,
          }),
        }}
        displaySelectedLibraryItems={displaySelectedLibraryItems}
        searchPlaceholder={searchPlaceholder}
      />
    </>
  );
};

export default SelectLibraryObjectsStep;
