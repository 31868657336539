import { useMemo, useEffect, useRef, useState } from "react";
import { Row } from "antd";
import { Button, Datatable, Alert } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import classnames from "classnames";
import { useMaterialPermissions } from "components/Library/components/MaterialForm/hooks";
import { heightOfHeadersInMaterialPage } from "components/Library/components/MaterialForm/constants";
import RecipeTableFooter from "components/RecipeTableFooter";
import FormCard from "../../../FormCard";
import RecipePanel from "./components/RecipePanel";
import { usePagination, useRecipe } from "hooks";
import { useAppDispatch } from "store";
import { removeLastIngredient } from "store/materialForm/materialFormSlice";
import { getRowKeyForTable } from "utils/table";
import { isListEmpty, isObjectEmpty, scrollToTop } from "utils/general";
import { LIBRARY_OBJECT_TYPES } from "utils/constants";
import { getClassName, getColumns } from "./utils";
import { useExpandedRowKeys, useRecipeTableActions } from "./hooks";
import { RecipeTableProps } from "./types";
import messages from "./messages";
import libraryMessages from "messages/library";
import styles from "components/Library/components/MaterialForm/styles.module.less";
import formStyles from "./styles.module.less";

const RecipeTable: React.FC<RecipeTableProps> = ({
  categoryDictionary,
  catalogDictionary,
  displayActions,
  getCategoryItem,
  updateCategoryItems,
  recipe,
  materialId,
}) => {
  const { ingredients, totalPercentage, recipeType, sortingFilter } = recipe;

  const [sortedIngredients, setSortedIngredients] = useState(ingredients);
  const ref = useRef(null);
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const { sorter, onTableChange } = usePagination();
  const { recipeIngredients, sortMaterialRecipeIngredients } = useRecipe({
    id: materialId,
    recipeType,
    shouldCallApi: false,
  });

  useEffect(() => {
    setSortedIngredients(ingredients);
  }, [ingredients]);

  useEffect(() => {
    if (!isListEmpty(recipeIngredients)) {
      setSortedIngredients(recipeIngredients);
    }
  }, [recipeIngredients]);

  const {
    onRemoveIngredient,
    onUpdateIngredientPercentage,
    onPanelOpen,
    onPanelClose,
    onCell,
    isPanelOpen,
    ingredient,
  } = useRecipeTableActions({
    sorter,
    sortRecipeIngredients: sortMaterialRecipeIngredients,
  });

  useEffect(() => {
    if (ref.current && isPanelOpen) {
      const elementPosition = ref.current.getBoundingClientRect().top;
      const offset =
        elementPosition + window.pageYOffset - heightOfHeadersInMaterialPage;
      scrollToTop(offset, "smooth");
    }
  }, [isPanelOpen, ref.current]);

  const { disableActions } = useMaterialPermissions();

  const {
    expandedRowKeys,
    onExpandedRowsChange,
    onAddExpandedRowKey,
  } = useExpandedRowKeys({
    ingredients: sortedIngredients,
  });

  const areActionsDisabled = useMemo(() => disableActions || isPanelOpen, [
    disableActions,
    isPanelOpen,
  ]);

  useEffect(() => {
    // removing the last ingredient in the table if the panel is open and page is refreshed
    if (
      !isListEmpty(sortedIngredients) &&
      !sortedIngredients[sortedIngredients.length - 1].name
    ) {
      dispatch(removeLastIngredient([]));
    }
  }, []);

  const columns = getColumns({
    formatMessage,
    onRemoveIngredient,
    onUpdateIngredientPercentage,
    displayActions,
    disableActions: areActionsDisabled,
    categoryDictionary,
    catalogDictionary,
    onCell,
    recipeSorter: isObjectEmpty(sorter) ? sortingFilter : sorter,
    canSort: sortedIngredients?.length > 1,
  }).filter(item => !isObjectEmpty(item));

  return (
    <div ref={ref}>
      <FormCard
        className={classnames({ [formStyles.formHeight]: isPanelOpen })}
        title={formatMessage(messages.cardTitle)}
        name="recipe-table"
        disableCollapse={isPanelOpen}
      >
        {displayActions && !areActionsDisabled && (
          <Alert
            message={formatMessage(
              libraryMessages.validationMessageSumOfPercentage
            )}
            type="info"
            showIcon
            closable
            className={styles.alertReadOnlyTableMargin}
            data-testid="info-banner-material-recipe"
          />
        )}
        <Datatable
          rowClassName={getClassName(ingredient)}
          expandedRowKeys={expandedRowKeys}
          indentSize={25}
          onExpandedRowsChange={onExpandedRowsChange}
          tableLayout="fixed"
          columns={columns}
          dataSource={sortedIngredients}
          pagination={false}
          rowKey={getRowKeyForTable("ingredient")}
          emptyText={formatMessage(messages["noIngredient"])}
          className={styles.ingredientsContainer}
          childrenColumnName="ingredients"
          footer={
            !isListEmpty(sortedIngredients)
              ? () => (
                  <RecipeTableFooter
                    totalPercentage={totalPercentage}
                    displayActions={displayActions}
                    type={LIBRARY_OBJECT_TYPES.MATERIALS}
                  />
                )
              : null
          }
          onChange={onTableChange}
        />
        {isPanelOpen && (
          <RecipePanel
            onPanelClose={onPanelClose}
            initialIngredient={ingredient}
            getCategoryItem={getCategoryItem}
            updateCategoryItems={updateCategoryItems}
            onAddExpandedRowKey={onAddExpandedRowKey}
            catalogDictionary={catalogDictionary}
          />
        )}
        {displayActions && (
          <Row justify="center" className={styles.rowAddButton}>
            <Button
              onClick={() => {
                onPanelOpen();
              }}
              data-test-id="add-ingredient"
              disabled={areActionsDisabled}
            >
              {formatMessage(messages.addAction)}
            </Button>
          </Row>
        )}
      </FormCard>
    </div>
  );
};

RecipeTable.defaultProps = {
  displayActions: true,
};

export default RecipeTable;
