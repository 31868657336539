import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  selectRequirements,
  selectProducts,
} from "store/folderCreation/selectors";
import { isListEmpty } from "utils/general";
import { Requirement } from "store/folderCreation/types";
import { RequirementData, TradeItemData } from "models";

const useDisableAssignedRequirements = () => {
  const requirements = useSelector(selectRequirements);
  const [disabledRequirementIds, setDisabledRequirementIds] = useState<
    RequirementData["id"][]
  >([]);
  const assignedProducts = useSelector(selectProducts);

  const getAppliedRequirementsForProduct = () => {
    const assignedProductIds = assignedProducts.map(({ id }) => id);
    return requirements.reduce(
      (
        previousState: RequirementData["id"][],
        requirementItem: Requirement
      ) => {
        if (
          assignedProductIds.every((tradeItemId: TradeItemData["id"]) =>
            requirementItem.tradeItemIds.includes(tradeItemId)
          )
        ) {
          previousState.push(requirementItem.requirementId);
        }
        return previousState;
      },
      []
    );
  };

  useEffect(() => {
    if (isListEmpty(requirements)) {
      setDisabledRequirementIds([]);
    } else {
      setDisabledRequirementIds(getAppliedRequirementsForProduct());
    }
  }, [requirements]);

  return {
    disabledRequirementIds,
  };
};

export default useDisableAssignedRequirements;
