import { useHistory } from "react-router-dom";
import { persistor, useAppDispatch } from "store";
import { setInitialFolderDetails } from "store/folderCreation/asyncActions";
import { setInitialSpecficationDetails } from "store/specificationCreation/asyncActions";
import {
  FOLDER_WIZARD_STEPS,
  SPECIFICATION_WIZARD_STEPS,
} from "utils/constants";
import { FolderData, SpecificationData } from "models";

const useResume = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const resumeSpecification = async (
    specificationId: SpecificationData["id"]
  ) => {
    await persistor.purge();
    const res = await dispatch(setInitialSpecficationDetails(specificationId));

    const step = SPECIFICATION_WIZARD_STEPS.find(
      // @ts-ignore
      ({ step }) => step === res.payload?.wizard?.currentStep
    );

    if (step) {
      history.push(step.route);
    }
  };

  const resumeFolder = async (folderId: FolderData["folderId"]) => {
    await persistor.purge();
    const res = await dispatch(setInitialFolderDetails(folderId));

    const step = FOLDER_WIZARD_STEPS.find(
      // @ts-ignore
      ({ step }) => step === res.payload?.wizard?.currentStep
    );

    if (step) {
      history.push(step.route);
    }
  };

  return {
    resumeFolder,
    resumeSpecification,
  };
};

export default useResume;
