import { useContext } from "react";
import { Select } from "@trace-one/design-system";
import { useSelector } from "react-redux";
import { selectSectionSavingInProgress } from "store/specificationDetails/selectors";
import { NutritionBasicContext } from "components/SpecificationSection/components/FormSchema/components/NutritionBasic/contexts";
import { useUnitOfMeasure } from "./hooks";
import { UnitOfMeasureProps } from "./types";

const UnitOfMeasure: React.FC<UnitOfMeasureProps> = ({
  value,
  label,
  nutrientData,
}) => {
  const { sectionId, formContext } = useContext(NutritionBasicContext);
  const { extraActions } = formContext;

  const {
    handleVisibleDropdownChange,
    handleSelect,
    unitOptions,
    value: selectValue,
    areOptionsLoading,
  } = useUnitOfMeasure({
    nutrientData,
    updateNutritionAction: extraActions?.updateNutritionAction,
  });

  const isSavingInProgress = useSelector(
    selectSectionSavingInProgress(sectionId)
  );

  return (
    <Select
      labelInValue
      filterOption={false}
      allowClear={false}
      value={selectValue || { value, label }}
      loading={areOptionsLoading}
      options={unitOptions}
      onDropdownVisibleChange={handleVisibleDropdownChange}
      onSelect={handleSelect}
      disabled={isSavingInProgress}
      data-test-id="unit-of-measure-select"
    />
  );
};

export default UnitOfMeasure;
