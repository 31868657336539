import { Heading, Paragraph } from "@trace-one/design-system";
import styles from "../../styles.module.less";

const Card: React.FC<{
  title: React.ReactNode;
  content: React.ReactNode;
}> = ({ title, content }) => (
  <div className={styles.headerCard}>
    <Heading size="xs" color="primary">
      {title}
    </Heading>
    <Paragraph size="xs" color="grey-4">
      {content}
    </Paragraph>
  </div>
);

export default Card;
