import { generatePath, useHistory } from "react-router-dom";
import qs from "qs";
import { ROUTES } from "utils/constants";
import { isObjectEmpty } from "utils/general";
import { LibraryItemPageType } from "./types";
import { PackagingSystemComponentData } from "models/packaging";
import { FolderData } from "models";

const useRedirect = () => {
  const { push } = useHistory();

  const getRedirectToLibraryItemPage = (path: string) => ({
    id,
    type,
    queryParameters,
    pushState,
    tab,
  }: LibraryItemPageType) => {
    const queryParametersString = !isObjectEmpty(queryParameters)
      ? `?${qs.stringify(queryParameters)}`
      : "";

    push({
      pathname: generatePath(path, {
        id,
        type,
        tab,
      }),
      search: queryParametersString,
      state: pushState,
    });
  };

  const redirectToHome = () => {
    push(ROUTES.HOME);
  };

  const redirectToFolderDetails = (folderId: FolderData["folderId"]) => {
    push(
      generatePath(ROUTES.FOLDER_DETAILS, {
        folderId,
      })
    );
  };

  const redirectToFolderListing = () => {
    push(ROUTES.FOLDER_LISTING);
  };

  const redirectToLibraryListing = (type: string, pushState?: object) => {
    push({
      pathname: generatePath(ROUTES.LIBRARY.LISTING, {
        type,
      }),
      ...(!isObjectEmpty(pushState) && { state: pushState }),
    });
  };

  const redirectToSpecificationDetails = (
    specificationId: string,
    sectionCode?: string
  ) => {
    const route = !!sectionCode
      ? ROUTES.SPECIFICATION_SECTION_DETAILS
      : ROUTES.SPECIFICATION_DETAILS;

    push(
      generatePath(route, {
        specificationId,
        sectionCode,
      })
    );
  };

  const redirectToSpecificationListing = () => {
    push(ROUTES.SPECIFICATION_LISTING);
  };

  const redirectToPackagingComponentEdit = ({
    packagingComponentId,
    queryParameters,
  }: {
    packagingComponentId: PackagingSystemComponentData["componentId"];
    queryParameters?: object;
  }) => {
    const queryParametersString = !isObjectEmpty(queryParameters)
      ? `?${qs.stringify(queryParameters)}`
      : "";

    push({
      pathname: generatePath(ROUTES.PACKAGING_COMPONENT.EDIT, {
        packagingComponentId,
      }),
      search: queryParametersString,
    });
  };

  return {
    redirectToHome,

    redirectToFolderDetails,
    redirectToFolderListing,

    redirectToSpecificationDetails,
    redirectToSpecificationListing,

    redirectToLibraryCreation: getRedirectToLibraryItemPage(
      ROUTES.LIBRARY.CREATION
    ),
    redirectToLibraryCreateNewVersion: getRedirectToLibraryItemPage(
      ROUTES.LIBRARY.CREATE_NEW_VERSION
    ),
    redirectToLibraryDetails: getRedirectToLibraryItemPage(
      ROUTES.LIBRARY.DETAILS
    ),
    redirectToLibraryEdition: getRedirectToLibraryItemPage(
      ROUTES.LIBRARY.EDITION
    ),
    redirectToLibraryListing,
    redirectToPackagingComponentEdit,
  };
};

export default useRedirect;
