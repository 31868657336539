import { defineMessage } from "react-intl";

export const messages = defineMessage({
  folderAlreadyCreatedTitle: {
    id: "creationFolder.folderAlreadyCreated.title",
    defaultMessage: " Folder already created",
  },
  folderAlreadyCreatedContent: {
    id: "creationFolder.folderAlreadyCreated.content",
    defaultMessage: "Another user finalized the creation of this Folder",
  },
});
