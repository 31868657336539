import { Alert, Datatable } from "@trace-one/design-system";
import RowTitle from "components/RowTitle";
import { getRowKeyForTable } from "utils/table";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { useColumns } from "./hooks";
import { RawMaterialsAllergensProps } from "./types";
import styles from "../../../styles.module.less";

const RawMaterialsAllergens: React.FC<RawMaterialsAllergensProps> = ({
  rawMaterialsAllergens,
  displayActions,
  noItemsMessage,
}) => {
  const { getTranslationForKey } = useFormSchemaTranslation();

  const { columns } = useColumns();
  return (
    <>
      <RowTitle
        title={getTranslationForKey("rawMaterialsAllergensTitle")}
        displayActions={displayActions}
      />
      {displayActions && (
        <Alert
          type="info"
          className={styles.alertReadOnlyTable}
          description={getTranslationForKey("rawMaterialsAllergensInfo")}
          showIcon
          closable
        />
      )}
      <Datatable
        tableLayout="fixed"
        className={styles.readOnlyTableWrapper}
        columns={columns}
        dataSource={rawMaterialsAllergens}
        rowKey={getRowKeyForTable("allergen")}
        emptyText={noItemsMessage}
        pagination={false}
      />
    </>
  );
};

export default RawMaterialsAllergens;
