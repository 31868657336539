import { createSelector } from "@reduxjs/toolkit";
import { TradeItemData } from "models";
import { RootState } from "store";
import { hasProductError, isListEmpty } from "utils/general";

/* istanbul ignore next */
export const _selectFolderCreation = (state: RootState) => state.folderCreation;

export const selectFolderDetails = createSelector(
  _selectFolderCreation,
  ({ folder }) => folder
);

export const selectChapters = createSelector(
  _selectFolderCreation,
  ({ chapters }) => {
    return chapters;
  }
);

export const selectFolderTemplate = createSelector(
  _selectFolderCreation,
  ({ template }) => template
);

export const selectContacts = createSelector(
  _selectFolderCreation,
  ({ contacts }) => contacts
);

export const selectSelectedContacts = createSelector(
  _selectFolderCreation,
  ({ contacts }) => contacts.filter(({ isSelected }) => isSelected)
);

export const selectFolderOwnerUserDetails = createSelector(
  _selectFolderCreation,
  ({ ownerUserDetails }) => ownerUserDetails
);

export const selectProducts = createSelector(
  _selectFolderCreation,
  ({ products }) => products
);

export const selectProductSpecifications = createSelector(
  _selectFolderCreation,
  ({ productSpecifications }) => productSpecifications
);

export const selectRequirements = createSelector(
  _selectFolderCreation,
  ({ requirements }) => requirements
);

export const selectErrors = createSelector(
  _selectFolderCreation,
  ({ errors }) => errors
);

export const selectSpecificationsForProduct = (
  productId: TradeItemData["id"]
) =>
  createSelector(
    _selectFolderCreation,
    ({ productSpecifications }) => productSpecifications[productId] || []
  );

export const selectHasError = createSelector(
  _selectFolderCreation,
  ({ hasError }) => hasError
);

export const selectIsSaving = createSelector(
  _selectFolderCreation,
  ({ isSaving }) => isSaving
);

export const selectIsSharing = createSelector(
  _selectFolderCreation,
  ({ isSharing }) => isSharing
);

export const selectIsCreationInProgress = createSelector(
  _selectFolderCreation,
  ({ isCreationInProgress }) => isCreationInProgress
);

export const selectIsFolderFinalized = createSelector(
  _selectFolderCreation,
  ({ isFolderFinalized }) => isFolderFinalized
);

export const selectIsFolderCheckInProgress = createSelector(
  _selectFolderCreation,
  ({ isFolderCheckInProgress }) => isFolderCheckInProgress
);

export const selectIsResumeInProgress = createSelector(
  _selectFolderCreation,
  ({ isResumeInProgress }) => isResumeInProgress
);

export const selectAreCollaborationsLoading = createSelector(
  _selectFolderCreation,
  ({ areCollaborationsLoading }) => areCollaborationsLoading
);

export const selectDetailsStepIsValid = createSelector(
  _selectFolderCreation,
  ({ folder }) =>
    !!folder.folderName && !!folder.productTypeId && !!folder.targetDateUtc
);

export const selectIsProductsStepValid = createSelector(
  _selectFolderCreation,
  ({ folder, products, productSpecifications }) => {
    if (isListEmpty(products)) return false;

    return (
      products.findIndex(({ id }) =>
        hasProductError({
          folderId: folder.folderId,
          productSpecifications: productSpecifications[id],
        })
      ) === -1
    );
  }
);

export const selectIsInvitationStepValid = createSelector(
  _selectFolderCreation,
  ({ isInvitationStepValid }) => isInvitationStepValid
);

export const selectProperStep = (state: RootState) => {
  const isDetailStepCompleted = selectDetailsStepIsValid(state);
  const isProductStepCompleted = selectIsProductsStepValid(state);
  const isInvitationStepCompleted = selectIsInvitationStepValid(state);
  const isSpecStepCompleted = !isListEmpty(state.folderCreation.chapters);
  const isSummaryStepCompleted =
    isDetailStepCompleted &&
    isProductStepCompleted &&
    isInvitationStepCompleted &&
    isSpecStepCompleted;

  return {
    isDetailStepCompleted,
    isProductStepCompleted,
    isInvitationStepCompleted,
    isSpecStepCompleted,
    isSummaryStepCompleted,
  };
};

export const selectWizard = createSelector(
  _selectFolderCreation,
  ({ wizard }) => wizard
);
