import { Heading } from "@trace-one/design-system";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import withUnitsOfMeasure from "hocs/withUnitsOfMeasure";
import { selectUnitsOfMeasure } from "store/generalData/selectors";
import { isListEmpty } from "utils/general";
import { messages as folderMessages } from "../../messages";
import { messages } from "./messages";
import { TradeItemListProps } from "./types";
import TradeItemCard from "./components/TradeItemCard";
import { useFolderSpecifications, useSpecificationsTradeItems } from "hooks";
import { hasError, isLoading } from "./utils";
import Spin from "components/Spin";

const TradeItemList: React.FC<TradeItemListProps> = ({
  folderId,
  tradeItemIds,
}) => {
  const unitsOfMeasure = useSelector(selectUnitsOfMeasure);

  const {
    specifications,
    isLoading: isSpecLoading,
    hasError: hasSpecError,
  } = useFolderSpecifications({
    folderId,
    tradeItemIds,
  });

  const {
    tradeItems,
    isLoading: isTradeItemsLoading,
    hasError: hasTradeItemsError,
  } = useSpecificationsTradeItems({
    specifications,
  });

  if (
    isLoading({
      isSpecLoading,
      isTradeItemsLoading,
    })
  ) {
    return <Spin />;
  }

  if (
    hasError({
      hasSpecError,
      hasTradeItemsError,
    })
  ) {
    return (
      <Heading color="red" data-testid="no-trade-items">
        <FormattedMessage {...messages.tradeItemListError} />
      </Heading>
    );
  }

  if (isListEmpty(tradeItems)) {
    return (
      <Heading data-testid="no-trade-items" size="xxs">
        <FormattedMessage {...folderMessages.noTradeItems} />
      </Heading>
    );
  }

  return (
    <div data-testid="list-items">
      {tradeItems.map(tradeItem => (
        <TradeItemCard
          key={tradeItem.tradeItemId}
          tradeItem={tradeItem}
          unitsOfMeasure={unitsOfMeasure}
        />
      ))}
    </div>
  );
};

export default withUnitsOfMeasure(TradeItemList);
