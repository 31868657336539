import { useEffect, useMemo } from "react";
import { useParams } from "react-router";
import { Row, Col } from "antd";
import { Paragraph } from "@trace-one/design-system";
import { useDispatch, useSelector } from "react-redux";
import {
  useAxiosInterceptors,
  useProduct,
  useQuery,
  useReferenceListsItems,
  useSpecificationChaptersData,
  useSpecificationStructure,
} from "hooks";
import { setUserLanguage } from "store/user/userSlice";
import { selectLanguageCode } from "store/user/selectors";
import {
  getFirstNetContent,
  getSpecificationHeaderTitle,
} from "utils/specifications";
import Spin from "components/Spin";
import Chapters from "./components/Chapters";
import Header from "./components/Header";
import { usePdfSpecificationData } from "./hooks";
import { SpecificationPdfContext } from "./contexts";
import { DIGITAL_SIGNING_ANCHOR_STRING } from "./constants";
import { SpecificationData } from "models";
import styles from "./styles.module.less";
import { setDayjsLocale } from "utils/date";

const Pdf = () => {
  const { specificationId } = useParams<{
    specificationId: SpecificationData["id"];
  }>();
  const query = useQuery();
  const dispatch = useDispatch();

  const { isLoading, hasError, specificationData } = usePdfSpecificationData({
    specificationId,
  });
  const {
    progression,
    ownerCompanyName,
    state,
    supplierName,
    tradeItemName,
    version,
    productTypeId,
  } = specificationData;

  const { productData } = useProduct({
    productId: specificationData.tradeItemId,
  });

  const { refListDictionary } = useReferenceListsItems({
    refListIds:
      productData?.netContents?.map(({ unitOfMeasure }) => unitOfMeasure) || [],
  });

  const firstNetContent = getFirstNetContent({
    netContents: productData?.netContents,
    refListDictionary,
  });

  const {
    isLoading: isStructureLoading,
    specificationStructure,
  } = useSpecificationStructure({
    specificationId,
    productTypeId,
  });

  const {
    isLoading: isChaptersLoading,
    chapters,
  } = useSpecificationChaptersData({
    specificationId,
    specificationStructure,
    specificationState: state,
  });

  const languageCode = useSelector(selectLanguageCode);

  const specificationName = useMemo(
    () =>
      getSpecificationHeaderTitle({
        tradeItemName,
        netContent: firstNetContent,
      }),
    [tradeItemName, firstNetContent]
  );

  setDayjsLocale(languageCode);

  useAxiosInterceptors();

  useEffect(() => {
    dispatch(setUserLanguage(query.get("languageCode")));
  }, [query.get("languageCode")]);

  if (isLoading || isChaptersLoading || isStructureLoading) {
    return <Spin />;
  }

  if (hasError) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <Header
        specificationName={specificationName}
        state={state}
        progression={progression}
        supplierName={supplierName}
        ownerCompanyName={ownerCompanyName}
        versionNumber={version?.number}
      />
      <SpecificationPdfContext.Provider
        value={{
          specificationData,
        }}
      >
        <Chapters chapters={chapters} />
      </SpecificationPdfContext.Provider>

      {/* anchor text needed for digital signing */}
      <Row justify="space-around">
        <Col>
          <Paragraph color="white">
            {DIGITAL_SIGNING_ANCHOR_STRING.SIGNER_1}
          </Paragraph>
        </Col>
        <Col>
          <Paragraph color="white">
            {DIGITAL_SIGNING_ANCHOR_STRING.SIGNER_2}
          </Paragraph>
        </Col>
      </Row>
    </div>
  );
};

export default Pdf;
