import { Datatable } from "@trace-one/design-system";
import { getRowKeyForTable } from "utils/table";
import { usePackagingSystemComponentMaterialTable } from "./hooks";
import { PackagingSystemComponentMaterialTableProps } from "./types";

const PackagingSystemComponentMaterialTable: React.FC<PackagingSystemComponentMaterialTableProps> = ({
  uiSchema,
  formData,
}) => {
  const { columns } = usePackagingSystemComponentMaterialTable({
    uiSchema,
  });

  return (
    <Datatable
      tableLayout="fixed"
      columns={columns}
      dataSource={formData}
      pagination={false}
      rowKey={getRowKeyForTable("packagingComponentMaterial")}
      onChange={() => {}}
    />
  );
};

export default PackagingSystemComponentMaterialTable;
