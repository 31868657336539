import { useRef } from "react";
import { Icon } from "@trace-one/design-system";
import { useSelector } from "react-redux";
import {
  REQUEST_MODIFICATION_MODAL_WIDTH,
  RequestPopinOptions,
} from "components/RequestModificationPopover";
import { useAppDispatch } from "store";
import { setRequestModification } from "store/specificationDetails/specificationDetailsSlice";
import { selectIsRequestModificationEditorOpen } from "store/specificationDetails/selectors";
import styles from "./styles.module.less";

export interface RequestModificationTooltipIconProps {
  requestPopoverOptions?: RequestPopinOptions;
  setRequestPopoverOptions?: React.Dispatch<
    React.SetStateAction<RequestPopinOptions>
  >;
}

const RequestModificationTooltipIcon = ({
  onOpenModal,
  setModalStyle,
  tableRef,
  setRequestPopoverOptions,
  request,
  modalStyle = {},
}) => {
  const dispatch = useAppDispatch();

  const iconRef = useRef(null);

  const isRequestModificationEditorOpen = useSelector(
    selectIsRequestModificationEditorOpen
  );

  const handleModalPositionFromIcon = iconPosition => {
    const tablePosition = tableRef?.current?.getBoundingClientRect();

    const isOverflowing =
      iconPosition.left +
        iconPosition.width / 2 +
        REQUEST_MODIFICATION_MODAL_WIDTH >
      window.innerWidth;

    setModalStyle({
      top: iconPosition.top - tablePosition.top,
      left: isOverflowing ? "unset" : iconPosition.left - tablePosition.left,
      right: isOverflowing
        ? tablePosition.right > iconPosition.right
          ? tablePosition.right - iconPosition.right
          : tablePosition.right - iconPosition.left
        : "unset",
      ...modalStyle,
    });
  };

  const onMouseEnter = () => {
    if (isRequestModificationEditorOpen) return;

    const iconPosition = iconRef.current.getBoundingClientRect();

    setRequestPopoverOptions(requestPopoverOptions => ({
      ...requestPopoverOptions,
      isReadOnly: true,
    }));

    dispatch(
      setRequestModification({
        detail: request,
      })
    );

    handleModalPositionFromIcon(iconPosition);
    onOpenModal?.();
  };

  return (
    <span
      className={styles["request-modification-tooltip-icon"]}
      onMouseEnter={onMouseEnter}
      ref={iconRef}
    >
      <Icon name="request-modification" size="small" color="amethyst" />
    </span>
  );
};

export default RequestModificationTooltipIcon;
