import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDebounce } from "@trace-one/business-components";
import { LabeledValue } from "antd/lib/select";
import { ChangeEventExtra } from "rc-tree-select/lib/interface";
import { useBoolean } from "hooks";
import { updateCatalogDictionary } from "store/generalData/asyncActions";
import { useAppDispatch } from "store";
import { selectLanguageCode } from "store/user/selectors";
import {
  fetchRawMaterialClassification,
  filterRawMaterialClassification,
} from "apis/CATALOGUE";
import {
  RawMaterialClassificationData,
  RawMaterialClassificationItemData,
  RawMaterialClassificationSearchData,
} from "models";
import { isListEmpty } from "utils/general";
import { numberOfCharactersForAutoComplete } from "utils/constants";
import { CategoryTreeItem } from "components/CategoryTreeSelect/types";

export interface UseClassificationDropdownProps {
  value?: LabeledValue;
  onChange?: Function;
  onFullChange?: Function;
  shouldCurrentValueBeUpdated?: boolean;
}

const useClassificationDropdown = ({
  value,
  onChange: defaultOnChange,
  onFullChange,
  shouldCurrentValueBeUpdated = true,
}: UseClassificationDropdownProps) => {
  const languageCode = useSelector(selectLanguageCode);

  const [treeExpandedKeys, setTreeExpandedKeys] = useState<string[]>([]);
  const [valueWithLabel, setValueWithLabel] = useState(value);
  const [searchTerm, setSearchTerm] = useState("");
  const [treeData, setTreeData] = useState<CategoryTreeItem[]>([]);

  const isFirstRender = useRef(true);

  const dispatch = useAppDispatch();
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const { value: isLoading, setTrue, setFalse } = useBoolean(false);

  const searchRawMaterialClassification = async () => {
    if (
      debouncedSearchTerm?.trim().length >= numberOfCharactersForAutoComplete
    ) {
      setTrue();

      try {
        const { data } = await filterRawMaterialClassification({
          params: {
            searchTerm: debouncedSearchTerm,
            includeParents: true,
            languageCode,
          },
        });

        const searchTreeData = await prepareSearchTreeData(data);

        setTreeData(searchTreeData);
      } catch (_) {
      } finally {
        setFalse();
      }
    } else if (!debouncedSearchTerm?.trim().length) {
      setTreeData([]);
      getClassification();
    }
  };

  const prepareTreeItemData = (item: RawMaterialClassificationItemData) => {
    const { id, parentId, name, hasChildren, children } = item;

    return {
      id,
      pId: parentId,
      key: id,
      value: id,
      title: name,
      hasChildren,
      selectable: !hasChildren,
      isLeaf: !hasChildren,
      children,
    };
  };

  const prepareTreeData = (
    data: RawMaterialClassificationData["items"],
    shouldOverwrite: boolean = true
  ) => {
    if (!data) return;

    let newTreeData = data?.map(prepareTreeItemData);

    if (shouldOverwrite) {
      setTreeData(newTreeData);
    } else {
      setTreeData(prevState => [...prevState, ...newTreeData]);
    }
  };

  const prepareSearchTreeData = async (
    data: RawMaterialClassificationSearchData[]
  ) => {
    const buildTree = async (item: RawMaterialClassificationSearchData) => {
      const { id, parentId, name, hasChildren, isMatch = false } = item;

      const treeItem = {
        id,
        pId: parentId,
        key: id,
        value: id,
        title: name,
        hasChildren,
        children: [],
        selectable: !hasChildren,
        isLeaf: !hasChildren,
        isMatch,
      };

      if (hasChildren && !isListEmpty(item?.children)) {
        setTreeExpandedKeys(prevState => [...prevState, item.id]);

        treeItem.children = await Promise.all(
          item?.children?.map(childNode => buildTree(childNode))
        );
      } else if (hasChildren && isListEmpty(item.children)) {
        const { data } = await fetchRawMaterialClassification({
          params: {
            parentId: id,
            languageCode,
          },
        });

        treeItem.children = await Promise.all(
          data?.items?.map(childNode => buildTree(childNode))
        );
      }

      return treeItem;
    };

    const treeData = await Promise.all(
      data?.map(async item => {
        if (!item?.parentId) {
          return await buildTree(item);
        }
      })
    );

    return treeData;
  };

  const getClassification = async () => {
    try {
      setTreeExpandedKeys([]);
      setTrue();

      const { data } = await fetchRawMaterialClassification({
        params: {
          languageCode,
        },
      });

      prepareTreeData(data?.items);
    } catch (error) {
    } finally {
      setFalse();
    }
  };

  const onTreeExpand = (expandedKeys: string[]) => {
    setTreeExpandedKeys(expandedKeys);
  };

  const onValueWithLabelChange = (changedValue: LabeledValue) => {
    if (shouldCurrentValueBeUpdated) {
      setValueWithLabel(changedValue);
    }
    onFullChange?.(changedValue);
  };

  const onSearch = (searchValue: string) => {
    setSearchTerm(searchValue);
  };

  const onChange = (
    newValue: LabeledValue,
    labelist: React.ReactNode[],
    extra: ChangeEventExtra
  ) => {
    try {
      setTrue();

      if (!newValue) {
        defaultOnChange(newValue, labelist, extra);
        onValueWithLabelChange(null);
      } else {
        const { label, value } = newValue;

        const result = {
          id: value,
          name: label,
        };

        if (shouldCurrentValueBeUpdated) {
          // @ts-ignore
          prepareTreeData([result]);
        }

        dispatch(
          updateCatalogDictionary({
            [result.id]: result.name as string,
          })
        );

        defaultOnChange(result.id, labelist, extra);
        onValueWithLabelChange({ label, value });
      }
    } finally {
      setFalse();
    }
  };

  const fetchData = async (id: string) => {
    const pageSize = 200;
    let pageNumber = 1;
    let data = [];

    while (true) {
      const {
        data: { items, totalPages },
      } = await fetchRawMaterialClassification({
        params: {
          parentId: id,
          languageCode,
          pageNumber,
          pageSize,
        },
      });

      data = data?.concat(items);

      if (totalPages === pageNumber) {
        break;
      }

      pageNumber = pageNumber + 1;
    }

    return data;
  };

  const onLoadData = async ({ id, hasChildren }: CategoryTreeItem) => {
    if (!hasChildren) {
      return;
    }

    try {
      setTrue();

      const data = await fetchData(id);

      prepareTreeData(data, false);
    } catch {
    } finally {
      setFalse();
    }
  };

  const onDropdownVisibleChange = (visible: boolean) => {
    if (visible) {
      getClassification();
    } else {
      setTreeData([]);
    }
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    searchRawMaterialClassification();
  }, [debouncedSearchTerm, languageCode]);

  useEffect(() => {
    setValueWithLabel(value);
  }, [value]);

  return {
    onChange,
    onLoadData,
    onSearch,
    onTreeExpand,
    onDropdownVisibleChange,
    searchTerm,
    isLoading,
    treeData,
    treeExpandedKeys,
    valueWithLabel,
  };
};

export default useClassificationDropdown;
