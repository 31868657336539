import { CollaborationData } from "components/CreateCollaboration/types";

export interface State {
  collaborationData: CollaborationData[];
}

const initialState: State = {
  collaborationData: [],
};

export default initialState;
