import { ArrayFieldTemplateProps } from "components/SpecificationSection/types";
import { CompactFormData } from "components/SpecificationSection/components/FormSchema/types";

export enum RefListActions {
  add = "ADD",
  remove = "REMOVE",
}

export interface ItemFormData extends CompactFormData {
  id: string;
}
export interface SelectWithComboArrayFieldTemplateProps
  extends ArrayFieldTemplateProps<ItemFormData> {}
