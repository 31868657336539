import { SPECIFICATION_STATE } from "utils/constants";

const getStatuses = ({ type }) => {
  const defaultStatuses = [
    SPECIFICATION_STATE.draft,
    SPECIFICATION_STATE.inProgress,
  ];

  switch (type) {
    case "folder":
      return [...defaultStatuses, "completed"];
    case "specification":
      return [
        "inRevision",
        ...defaultStatuses,
        SPECIFICATION_STATE.validatedByRetailer,
        SPECIFICATION_STATE.validatedBySupplier,
        SPECIFICATION_STATE.signedByRetailer,
        SPECIFICATION_STATE.signedBySupplier,
      ];

    default:
      return defaultStatuses;
  }
};

export default getStatuses;
