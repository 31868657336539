import { defineMessages } from "react-intl";

export const detailsAndLinksChapterMessages = defineMessages({
  createARevisionSubtitle: {
    id: "library.details.versionHistory.createARevision.subtitle",
    defaultMessage:
      "Selected specifications will be revised to include the latest chapter version",
  },
  createRevisionBtn: {
    id: "library.details.versionHistory.createRevision.title",
    defaultMessage: "Create revision",
  },
  reviseSpecifications: {
    id: "library.details.versionHistory.reviseSpecifications",
    defaultMessage: "Revise specifications",
  },
  unlinkSpecificationsTitle: {
    id: "library.details.versionHistory.unlinkSpecifications.title",
    defaultMessage: "Unlink specifications",
  },
  unlinkSpecificationsSubtitle: {
    id: "library.details.versionHistory.unlinkSpecifications.subtitle",
    defaultMessage:
      "Selected specifications will be unlinked from the latest chapter version",
  },
  updateToLatestVersionTitle: {
    id: "library.details.versionHistory.updateToLatestVersion.title",
    defaultMessage: "Update to latest version",
  },
  updateToLatestVersionSubtitle: {
    id: "library.details.versionHistory.updateToLatestVersion.subtitle",
    defaultMessage:
      "Selected specifications will be updated to the latest chapter version",
  },
  unlinkButtonLabel: {
    id: "library.details.versionHistory.unlinkSpecifications.button",
    defaultMessage: "Unlink",
  },
  unlinkSpecificationsToasterMessage: {
    id: "library.details.versionHistory.unlinkSpecifications.toaster.message",
    defaultMessage: "Specifications unlinked",
  },
  unlinkSpecificationsToasterDescription: {
    id:
      "library.details.versionHistory.unlinkSpecifications.toaster.description",
    defaultMessage:
      "The chapter has been unlinked from the selected specifications",
  },
  updateToLatestVersionToasterMessage: {
    id: "library.details.versionHistory.updateToLatestVersion.toaster.message",
    defaultMessage: "Update successful",
  },
  updateToLatestVersionToasterDescription: {
    id:
      "library.details.versionHistory.updateToLatestVersion.toaster.description",
    defaultMessage:
      "Selected specifications were updated to the latest chapter version",
  },
  reviseToasterMessage: {
    id: "library.details.versionHistory.revise.toaster.message",
    defaultMessage: "Revisions created successfully",
  },
  reviseToasterDescription: {
    id: "library.details.versionHistory.revise.toaster.description",
    defaultMessage:
      "Selected specifications were revised to include the latest chapter version.",
  },
});
