import { useEffect, useState } from "react";
import { useModalVisibility } from "hooks";
import { RequirementType } from "types/library";

const useRequirementType = ({
  requirementType,
  onChangeType,
}: {
  requirementType: RequirementType;
  onChangeType: Function;
}) => {
  const {
    isModalOpen,
    onOpenModal,
    onCloseModal: onDefaultCloseModal,
  } = useModalVisibility();

  const [desiredType, setDesiredType] = useState(requirementType);

  const onToggle = (type: RequirementType) => {
    setDesiredType(type);

    onOpenModal();
  };

  const onConfirmModal = async () => {
    await onChangeType(desiredType);

    onDefaultCloseModal();
  };

  const onCloseModal = () => {
    setDesiredType(requirementType);

    onDefaultCloseModal();
  };

  useEffect(() => {
    setDesiredType(requirementType);
  }, [requirementType]);

  return {
    isModalOpen,
    desiredType,
    onConfirmModal,
    onCloseModal,
    onToggle,
  };
};

export default useRequirementType;
