import { deleteMaterial, shareMaterial } from "apis/SPEC";
import { MaterialData } from "models";
import { LibraryItemApiType } from "types/library";
import { FormInstance } from "antd";
import { useSelector } from "react-redux";
import { selectOwningCompanyId } from "store/user/selectors";

const useActions = ({
  validateFields,
  materialId,
  materialType,
}: {
  materialId: MaterialData["id"];
  materialType: LibraryItemApiType;
  validateFields: FormInstance["validateFields"];
}) => {
  const companyId = useSelector(selectOwningCompanyId);

  const onKeepAsDraftAction = async () => {
    try {
      await validateFields();

      await shareMaterial({
        materialId,
        materialType,
        companyId,
      });
    } catch (e) {
      throw e;
    }
  };

  const onDeleteAction = async () => {
    try {
      await deleteMaterial({
        materialId,
        materialType,
      });
    } catch (e) {}
  };

  return {
    onKeepAsDraftAction,
    onDeleteAction,
  };
};

export default useActions;
