import { fetchCompanies } from "apis/CUMD";
import useLanguages from "components/LanguageSelect/useLanguages";
import { useBoolean } from "hooks";
import { useEffect, useMemo, useState } from "react";
import { isListEmpty } from "utils/general";

const useOnGoingTranslations = ({ userIds }: { userIds: string[] }) => {
  const { languages, isLoading: isLanguagesLoading } = useLanguages({
    preFetch: true,
  });
  const [companyData, setCompanyData] = useState([]);
  const { value: isCompaniesLoading, setTrue, setFalse } = useBoolean();

  const languageDictionary = useMemo(
    () =>
      languages?.reduce((acc, { key, label }) => {
        acc[key] = label;
        return acc;
      }, {}),
    [languages]
  );
  const companyDictionary = useMemo(
    () =>
      companyData.reduce((acc, { companyId, companyDisplayName }) => {
        acc[companyId] = companyDisplayName;
        return acc;
      }, {}),
    [companyData]
  );

  useEffect(() => {
    const getUserCompanies = async () => {
      if (!isListEmpty(userIds)) {
        try {
          setTrue();
          const { data } = await fetchCompanies({
            ids: userIds,
          });
          setCompanyData(data);
        } catch {
        } finally {
          setFalse();
        }
      }
    };

    getUserCompanies();
  }, []);

  return {
    languageDictionary,
    companyDictionary,
    isLoading: isLanguagesLoading || isCompaniesLoading,
  };
};

export default useOnGoingTranslations;
