import { useContext, useMemo } from "react";
import { useIntl } from "react-intl";
import { Col, Row } from "antd";
import { EmptyState } from "@trace-one/design-system";
import { UploadFiles } from "@trace-one/business-components";
import { FILE_TYPES } from "utils/constants";
import SpecificationSection from "components/SpecificationSection";
import { useSpecificationPermissions, useUserInfo } from "hooks";
import useChapterDocuments from "./hooks/useChapterDocuments";
import { getIsValidationDisabled } from "./utils";
import { isListEmpty } from "utils/general";
import { prepareDocumentsSectionStatus } from "utils/specifications";
import { messages } from "pages/Specification/components/Details/messages";
import generalMessages from "messages/general";
import { SelectedChapterContext } from "pages/Specification/components/Details/components/SelectedChapter/contexts";
import styles from "./styles.module.less";

const DocumentsContent = () => {
  const { formatMessage } = useIntl();

  const {
    chapterData,
    specificationId,
    chapterType,
    refetchChapter,
    fileIds: specificationFileIds,
    ownerCompanyId,
    supplierId,
    specificationState,
    specificationPermissions,
    isTargetMode,
  } = useContext(SelectedChapterContext);

  const { documents } = chapterData;

  const { isRetailer } = useUserInfo();

  const {
    hasPermissionToUpdateSpecification,
    hasPermissionToValidateDocuments,
  } = useSpecificationPermissions({
    state: specificationState,
    permissions: specificationPermissions,
  });

  const {
    files,
    onUploadFiles,
    onFileRemove,
    isValidating,
    onValidateDocuments,
  } = useChapterDocuments({
    specificationId,
    chapterType,
    refetchChapter,
    fileIds: documents?.fileIds,
  });

  const documentState = useMemo(
    () => prepareDocumentsSectionStatus(documents),
    [documents]
  );

  const isValidationDisabled = useMemo(
    () =>
      getIsValidationDisabled({
        documents,
        onValidateDocuments,
        specificationState,
        isRetailer,
      }) || isTargetMode,
    [
      documents,
      specificationState,
      isRetailer,
      onValidateDocuments,
      isTargetMode,
    ]
  );

  return (
    <Col span={24}>
      {hasPermissionToUpdateSpecification && !isTargetMode ? (
        <>
          <UploadFiles.Dragger
            fileType={FILE_TYPES.SPECIFICATION_ATTACHMENT}
            fileList={files}
            onUploadFiles={onUploadFiles}
            onRemove={onFileRemove}
            disabledFileIds={specificationFileIds}
            ownerCompanyId={isRetailer ? ownerCompanyId : supplierId}
          />
        </>
      ) : (
        <>
          {isListEmpty(documents?.fileIds) ? (
            <Row justify="center">
              <EmptyState heading={formatMessage(messages.noDocument)} />
            </Row>
          ) : (
            <Row>
              <Col span={8}>
                <UploadFiles.List fileList={files} />
              </Col>
            </Row>
          )}
        </>
      )}
      <Row justify="end" className={styles.toggleContainer}>
        <SpecificationSection.ValidationToggle
          data-testid="documentsSwitch"
          onValidate={onValidateDocuments}
          isValidationLoading={isValidating}
          hasValidationPermission={hasPermissionToValidateDocuments}
          isValidationDisabled={isValidationDisabled}
          sectionStatus={documentState}
          validationText={formatMessage(generalMessages.validate)}
        />
      </Row>
    </Col>
  );
};

export default DocumentsContent;
