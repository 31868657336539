import { ReactElement } from "react";
import dayjs from "dayjs";
import { SPECIFICATION_STATE } from "utils/constants";
import { isStateDraft } from "utils/specifications";

export const displayValueForSpecificationDraftState = ({
  specificationState,
  valueToDisplay,
}: {
  specificationState: SPECIFICATION_STATE;
  valueToDisplay: string | ReactElement;
}) => {
  if (isStateDraft(specificationState)) {
    return null;
  }
  return valueToDisplay;
};

export const getProductOrigin = ({
  companyActivity,
}: {
  companyActivity: string;
}) => (companyActivity === "2" ? "retailer" : "supplier");

export const getDataIndexForProductOrigin = ({
  productOrigin,
}: {
  productOrigin: string;
}) => (productOrigin === "supplier" ? "supplierName" : "ownerCompanyName");

/* istanbul ignore next*/
export const getValueToDisplayforLifeTime = ({
  createdDateUtc,
  todayMessage,
  oneDayMessage,
}) => {
  const today = dayjs().utc();

  if (dayjs(createdDateUtc).isSame(today, "d")) return todayMessage;

  const duration = dayjs.duration(today.diff(createdDateUtc));

  // If less than a day.
  if (duration.days() === 0) {
    return oneDayMessage;
  }

  //@ts-ignore
  return duration.format("M [__], d [__]", {
    trunc: true,
  });
};
