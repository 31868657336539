import { useMemo } from "react";
import { useUserInfo } from "hooks";
import StickyBar from "components/StickyBar";
import AskForRevisionModal from "./components/AskForRevisionModal";
import RequestSentModal from "./components/RequestSentModal";
import RevisionActions from "./components/RevisionActions";
import useAskForRevisionModalActions from "./hooks/useAskForRevisionModalActions";
import { isStickyBarVisible as getIsStickyBarVisible } from "./utils";
import { SpecificationStickyBarSupplierProps } from "./types";

const SpecificationStickyBarSupplier: React.FC<SpecificationStickyBarSupplierProps> = ({
  id,
  state,
  history,
}) => {
  const { isRetailer } = useUserInfo();
  const {
    isModalVisible,
    cancelHandler,
    sendRequestHandler,
    shareSpecificationHandler,
    isSpecificationSharedBySupplier,
  } = useAskForRevisionModalActions({ id });

  const isStickyBarVisible = useMemo(
    () =>
      getIsStickyBarVisible({
        state,
        isRetailer,
        isModalVisible,
        isSpecificationSharedBySupplier,
      }),
    [isRetailer, state, isModalVisible, isSpecificationSharedBySupplier]
  );

  return (
    <>
      {isStickyBarVisible && (
        <StickyBar>
          <RevisionActions
            specificationId={id}
            state={state}
            onClickSendRequest={sendRequestHandler}
            history={history}
          />
        </StickyBar>
      )}
      {isModalVisible && (
        <AskForRevisionModal
          onCancel={cancelHandler}
          onShareSpecification={shareSpecificationHandler}
        />
      )}
      {isSpecificationSharedBySupplier && <RequestSentModal />}
    </>
  );
};

export default SpecificationStickyBarSupplier;
