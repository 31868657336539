import { useIntl } from "react-intl";
import { Label } from "@trace-one/react-components";
import { useInputValidation } from "hooks";
import Debounced from "components/Debounced";
import libraryMessages from "messages/library";
import { SupplierNameProps } from "./types";
import styles from "../../styles.module.less";

const SupplierName: React.FC<SupplierNameProps> = ({
  displayActions,
  disableActions,
  supplierName,
  onChange,
}) => {
  const { formatMessage } = useIntl();

  const { hasError, onInputChange } = useInputValidation(onChange);

  return (
    <Label title={formatMessage(libraryMessages.nameLabel)} required>
      {displayActions ? (
        <Debounced.Input
          data-test-id="raw-material-form-supplier-details-input-name"
          defaultValue={supplierName}
          disabled={disableActions}
          placeholder={formatMessage(libraryMessages.namePlaceholder)}
          onChange={onInputChange}
          error={hasError}
          errorMessage={formatMessage(libraryMessages.formRequired)}
          shouldApplyDebounceImmediate={false}
        />
      ) : (
        <span className={styles.supplierValue}>{supplierName}</span>
      )}
    </Label>
  );
};

export default SupplierName;
