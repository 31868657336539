import { useRef } from "react";
import { MATERIAL_TYPES, RECIPE_TYPES } from "utils/constants";
import { useMaterialDetails, useScrollToSection } from "../../hooks";
import Characteristics from "../Characteristics";
import Suppliers from "../Suppliers";
import Allergens from "./components/Allergens";
import RecipeTable from "./components/RecipeTable";
import ControlPlans from "../ControlPlans";
import Attachment from "../Attachment";
import { isLibraryObjectLocked } from "utils/library";
import { MadeInHouseDetailsProps } from "./types";

const MadeInHouseDetails: React.FC<MadeInHouseDetailsProps> = ({
  displayActions,
  recipe,
  allergens,
  suppliers,
  materialId,
  fileIds,
  state,
}) => {
  const allergensRef = useRef<HTMLDivElement | null>(null);

  useScrollToSection({ elementRef: allergensRef });

  const {
    categoryItemsDictionary,
    refListDictionary,
    catalogDictionary,
    getCategoryItem,
  } = useMaterialDetails({
    suppliers,
    allergens,
    ingredients: recipe.ingredients,
  });

  return (
    <>
      <RecipeTable
        displayActions={displayActions}
        recipe={recipe}
        categoryDictionary={categoryItemsDictionary}
        getCategoryItem={getCategoryItem}
        historyId={null}
        materialId={materialId}
        catalogDictionary={catalogDictionary}
      />
      <Suppliers
        displayActions={false}
        suppliers={suppliers}
        materialType={MATERIAL_TYPES.COMPOSITE}
        materialId={materialId}
        refListDictionary={refListDictionary}
      />
      <div ref={allergensRef}>
        <Allergens
          allergenDictionary={catalogDictionary}
          allergens={allergens}
        />
      </div>
      <Attachment
        fileIds={fileIds}
        displayActions={displayActions && !isLibraryObjectLocked(state)}
      />
      <Characteristics
        displayActions={displayActions}
        refListDictionary={refListDictionary}
        materialId={materialId}
        materialType={MATERIAL_TYPES.COMPOSITE}
        recipeType={RECIPE_TYPES.MADE_IN_HOUSE}
      />
      <ControlPlans
        displayActions={displayActions}
        materialId={materialId}
        materialType={MATERIAL_TYPES.COMPOSITE}
        refListDictionary={refListDictionary}
        recipeType={RECIPE_TYPES.MADE_IN_HOUSE}
      />
    </>
  );
};

MadeInHouseDetails.defaultProps = {
  displayActions: true,
};

export default MadeInHouseDetails;
