import { createContext } from "react";
import { LIBRARY_OBJECTS_VIEW_SIZES } from "utils/constants";
import { Dictionary } from "types/general";
import { LibraryObjectType } from "types/library";
import { LibraryListItemViewModel } from "viewModels";
import { LibraryObjectData } from "models";

const LibraryContext = createContext<{
  areItemsClickable?: boolean;
  disabledLibraryItems?: string[];
  selectedLibraryItems?: LibraryObjectData[];
  displayActions?: boolean;
  isListLoading?: boolean;
  libraryList?: LibraryListItemViewModel[];
  orderBy?: string;
  orderByDescending?: boolean;
  setOrderBy?: Function;
  setOrderByDescending?: Function;
  tooltipTitle?: string;
  type?: LibraryObjectType;
  view?: LIBRARY_OBJECTS_VIEW_SIZES;
  onItemClick?: Function;
  refreshTypeList?: Function;
  typeDictionary?: Dictionary;
  currentPage?: number;
  pageSize?: number;
  totalCount?: number;
}>({
  libraryList: [],
  isListLoading: false,
  typeDictionary: {},
});

export default LibraryContext;
