import { useMemo } from "react";
import { Row, Col } from "antd";
import { Select, UserCard } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import CreationWizzard from "components/CreationWizzard";
import { getRetailerOptions } from "./utils";
import { messages } from "./messages";
import { SelectRetailerContactsProps } from "./types";
import styles from "./styles.module.less";

const SelectRetailerContacts: React.FC<SelectRetailerContactsProps> = ({
  retailerContacts,
  selectedRetailerContacts,
  ownerUserDetails,
  onDelete,
  onSelect,
  name,
  dataTestIdForDropdown,
  dataTestIdForList,
  colSpansForList,
  error,
  errorMessage,
}) => {
  const intl = useIntl();

  const retailerOptions = useMemo(() => getRetailerOptions(retailerContacts), [
    retailerContacts,
  ]);
  return (
    <>
      <Col xs={24} sm={24} md={24} lg={6}>
        <CreationWizzard.Item
          label={intl.formatMessage(messages.formRetailerContact)}
        >
          <Select
            value={null}
            name={name}
            data-testid={dataTestIdForDropdown}
            placeholder={intl.formatMessage(
              messages.formRetailerContactPlaceholder
            )}
            onSelect={onSelect}
            options={retailerOptions}
            error={error}
            errorMessage={errorMessage}
          />
        </CreationWizzard.Item>
      </Col>
      <Col {...colSpansForList} className={styles.ownerContactList}>
        <Row gutter={[8, 10]} data-testid={dataTestIdForList}>
          {ownerUserDetails && (
            <Col>
              <UserCard
                size="xs"
                name={`${ownerUserDetails.userFirstName} ${ownerUserDetails.userLastName}`}
                title={ownerUserDetails.userJobTitle}
                photoUrl={ownerUserDetails.userPhotoUrl}
              />
            </Col>
          )}
          {selectedRetailerContacts.map(
            ({
              userFirstName,
              userLastName,
              userId,
              userPhotoUrl,
              userJobTitle,
            }) => {
              return (
                <Col key={userId}>
                  <UserCard
                    size="xs"
                    name={`${userFirstName} ${userLastName}`}
                    title={userJobTitle}
                    photoUrl={userPhotoUrl}
                    closable
                    onClose={onDelete(userId)}
                  />
                </Col>
              );
            }
          )}
        </Row>
      </Col>
    </>
  );
};

SelectRetailerContacts.defaultProps = {
  retailerContacts: [],
  colSpansForList: {
    xs: 24,
    sm: 24,
    md: 24,
    lg: 10,
  },
  name: "ownerContact",
  dataTestIdForDropdown: "",
  dataTestIdForList: "",
  error: false,
  errorMessage: "",
  selectedRetailerContacts: [],
};

export default SelectRetailerContacts;
