import React from "react";
import { Paragraph } from "@trace-one/design-system";
import { FormattedMessage } from "react-intl";
import { messages } from "./messages";
import styles from "./styles.module.less";
import { NoteProps, Types } from "./types";

const Note: React.FC<NoteProps> = ({
  content = "",
  type = Types.SPECIFICATION,
}) => {
  return !content ? (
    <FormattedMessage {...messages.noSpecificationNote[type]} />
  ) : (
    <Paragraph
      data-testid="specification-note"
      className={styles.notes}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
};

export default Note;
