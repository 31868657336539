import { State } from "./types";

const initialState: State = {
  generalInfo: {
    name: null,
    tagIds: [],
    sections: [],
  },
  updateStatus: {
    inProgress: false,
    isDone: false,
    hasError: false,
  },
};

export default initialState;
