import { useState } from "react";
import { useIntl } from "react-intl";
import { addOrUpdateItemToAList } from "utils/general";
import {
  getInfoFromValue,
  isTypeField,
  isTypeSection,
} from "components/SpecificationHistorisationModal/utils";
import {
  FilterTag,
  FilterTags,
} from "components/SpecificationHistorisationModal/types";
import { filterMessages } from "components/SpecificationHistorisationModal/messages";

const useFilterTags = () => {
  const intitialFilterTags = {
    chapters: [],
  };

  const { formatMessage } = useIntl();
  const [filterTags, setFilterTags] = useState<FilterTags>(intitialFilterTags);

  const currentFilterIsNotAChildren = (
    currentFilter: FilterTag,
    parentId: string
  ) => {
    const { ids: currentFilterIds } = getInfoFromValue(currentFilter.value);

    return !currentFilterIds.includes(parentId);
  };

  const currentFilterIsNotAParent = (
    currentFilter: FilterTag,
    parentIds: string[]
  ) => {
    const { ids: currentFilterIds } = getInfoFromValue(currentFilter.value);

    const currentFilterId = currentFilterIds[currentFilterIds.length - 1];

    return !parentIds.includes(currentFilterId);
  };

  const clearFilterCHildsAndParents = (
    currentFilters: FilterTag[],
    filterId: string,
    parentIds: string[]
  ) => {
    return currentFilters.filter(currentFilter => {
      return (
        currentFilterIsNotAChildren(currentFilter, filterId) &&
        currentFilterIsNotAParent(currentFilter, parentIds)
      );
    });
  };

  const onRemoveFilterTag = (tagCategoryName: string) => (tagValue: string) => {
    setFilterTags(previousState => ({
      ...previousState,
      [tagCategoryName]: previousState[tagCategoryName].filter(
        ({ value }) => value !== tagValue
      ),
    }));
  };

  const onAddFilterTag = (tagCategoryName: string) => (filter: FilterTag) => {
    const updatedFilter = {
      tooltip: filterMessages[tagCategoryName]
        ? formatMessage(filterMessages[tagCategoryName])
        : tagCategoryName,
      ...filter,
      onClose: () => {
        onRemoveFilterTag(tagCategoryName)(filter.value);

        if (typeof filter.onClose === "function") {
          filter.onClose();
        }
      },
    };

    setFilterTags(previousState => {
      const { type, ids } = getInfoFromValue(filter.value);

      let currentFilters = previousState[tagCategoryName];

      if (isTypeSection(type) || isTypeField(type)) {
        const filterId = ids[ids.length - 1];
        const parentIds = ids.slice(0, ids.length - 1);

        currentFilters = clearFilterCHildsAndParents(
          currentFilters,
          filterId,
          parentIds
        );
      }

      return {
        ...previousState,
        [tagCategoryName]: addOrUpdateItemToAList({
          list: [...currentFilters],
          propertyName: "value",
          item: updatedFilter,
        }),
      };
    });
  };

  const resetFilterTags = (tagCategoryName?: string) => {
    if (tagCategoryName) {
      setFilterTags(previousState => ({
        ...previousState,
        [tagCategoryName]: intitialFilterTags[tagCategoryName],
      }));
    } else {
      setFilterTags(intitialFilterTags);
    }
  };

  return {
    filterTags,
    onAddFilterTag,
    onRemoveFilterTag,
    resetFilterTags,
  };
};
export default useFilterTags;
