import { Col, Row } from "antd";
import { Illustration, Paragraph } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import { errorMessages } from "messages";

const WarningFallback: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <Col span={24}>
      <Row justify="center" className="mb-1">
        <Illustration name="warning" color="grey" />
      </Row>
      <Row justify="center">
        <Paragraph>{formatMessage(errorMessages.somethingWentWrong)}</Paragraph>
      </Row>
    </Col>
  );
};

export default WarningFallback;
