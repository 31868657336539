import { useEffect, useState } from "react";
import { ColumnsType } from "antd/lib/table";
import { useIntl } from "react-intl";
import { useAppDispatch } from "store";
import {
  addAllergens,
  removeAllergen,
  updateAllergenPresent,
} from "store/materialForm/asyncActions";
import { prepareRecipeAllergens } from "utils/library";
import { ALLERGEN_DECLARATION_TYPE } from "utils/constants";
import {
  MaterialAllergenDeclarationData,
  MaterialRecipeIngredientData,
} from "models";
import AllergenActions from "components/Library/components/MaterialForm/components/AllergenActions";
import SelectRawMaterials from "./components/SelectRawMaterials";
import { AllergenDeclarationType } from "types/library";
import { Dictionary } from "types/general";
import { getDictionary, isListEmpty, isObjectEmpty } from "utils/general";
import { joinMultipleValues } from "./utils";
import {
  AllergenTable,
  CompositeAllergens,
  UseAllergensTableProps,
} from "./types";
import libraryMessages from "messages/library";
import styles from "components/Library/components/MaterialForm/styles.module.less";
import {
  prepareBoughtDetailsIngredients,
  prepareIngredientDictionary,
} from "dtos/material";

const useAllergensTable = ({
  allergenDictionary,
  displayActions,
  disableActions,
  allergens,
  ingredients: recipeIngredients,
}: UseAllergensTableProps) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const [ingredients, setIngredients] = useState<
    MaterialRecipeIngredientData[]
  >([]);
  const [ingredientDictionary, setIngredientDictionary] = useState<Dictionary>(
    {}
  );
  const [
    ingredientAllergens,
    setIngredientAllergens,
  ] = useState<CompositeAllergens>({});

  useEffect(() => {
    if (!isListEmpty(allergens)) {
      setIngredientAllergens(prepareRecipeAllergens(allergens));
    }
  }, [JSON.stringify(allergens)]);

  useEffect(() => {
    if (!isListEmpty(recipeIngredients)) {
      const recipeIngredientDictionary = prepareIngredientDictionary(
        recipeIngredients
      );

      setIngredients(
        prepareBoughtDetailsIngredients(recipeIngredientDictionary)
      );
    }
  }, [recipeIngredients]);

  useEffect(() => {
    setIngredientDictionary(
      getDictionary({
        data: ingredients,
        key: "ingredientId",
        value: "name",
      })
    );
  }, [ingredients]);

  const onAddAllergens = async (
    allergens: MaterialAllergenDeclarationData[]
  ) => {
    await dispatch(addAllergens(allergens));
  };

  const onRemoveAllergen = (
    allergenId: MaterialAllergenDeclarationData["allergenId"]
  ) => async () => {
    await dispatch(removeAllergen({ allergenId }));
  };

  const onSelectIngredient = ({
    allergenId,
    declaration,
    presentIngredientIds,
    unintentionalPresentIngredientIds,
  }: {
    allergenId: string;
    declaration?: AllergenDeclarationType;
    presentIngredientIds?: string[];
    unintentionalPresentIngredientIds?: string[];
  }) => async (ingredientId: MaterialRecipeIngredientData["ingredientId"]) => {
    declaration === ALLERGEN_DECLARATION_TYPE.PRESENT
      ? await dispatch(
          updateAllergenPresent({
            allergenId,
            presentIngredientIds: [...presentIngredientIds, ingredientId],
            unintentionalPresentIngredientIds: [
              ...unintentionalPresentIngredientIds.filter(
                item => item !== ingredientId
              ),
            ],
          })
        )
      : await dispatch(
          updateAllergenPresent({
            allergenId,
            presentIngredientIds: [
              ...presentIngredientIds.filter(item => item !== ingredientId),
            ],
            unintentionalPresentIngredientIds: [
              ...unintentionalPresentIngredientIds,
              ingredientId,
            ],
          })
        );
  };

  const onDeselectIngredient = ({
    allergenId,
    declaration,
    presentIngredientIds,
    unintentionalPresentIngredientIds,
  }: {
    allergenId: string;
    declaration?: AllergenDeclarationType;
    presentIngredientIds?: string[];
    unintentionalPresentIngredientIds?: string[];
  }) => async (ingredientId: MaterialRecipeIngredientData["ingredientId"]) => {
    declaration === ALLERGEN_DECLARATION_TYPE.PRESENT
      ? await dispatch(
          updateAllergenPresent({
            allergenId,
            presentIngredientIds: [
              ...presentIngredientIds.filter(item => item !== ingredientId),
            ],
            unintentionalPresentIngredientIds,
          })
        )
      : await dispatch(
          updateAllergenPresent({
            allergenId,
            presentIngredientIds,
            unintentionalPresentIngredientIds: [
              ...unintentionalPresentIngredientIds.filter(
                item => item !== ingredientId
              ),
            ],
          })
        );
  };

  const getColumns = (): ColumnsType<AllergenTable> =>
    [
      {
        title: formatMessage(libraryMessages.allergensColumns["allergen"]),
        dataIndex: "allergenId",
        render: allergenId => allergenDictionary[allergenId],
      },
      {
        title: formatMessage(libraryMessages.allergensColumns["present"]),
        key: "present",
        className: styles.unsetPosition,
        render: ({
          allergenId,
          presentForIngredientIds,
          unintentionalPresentForIngredientIds,
        }) => {
          return displayActions ? (
            <SelectRawMaterials
              ingredients={ingredients}
              allergenId={allergenId}
              onSelectIngredient={onSelectIngredient({
                allergenId,
                declaration: ALLERGEN_DECLARATION_TYPE.PRESENT,
                presentIngredientIds: presentForIngredientIds,
                unintentionalPresentIngredientIds: unintentionalPresentForIngredientIds,
              })}
              onDeselectIngredient={onDeselectIngredient({
                allergenId,
                declaration: ALLERGEN_DECLARATION_TYPE.PRESENT,
                presentIngredientIds: presentForIngredientIds,
                unintentionalPresentIngredientIds: unintentionalPresentForIngredientIds,
              })}
              type="presentForIngredientIds"
              allergenMaterials={ingredientAllergens?.[allergenId]}
              disableActions={disableActions}
            />
          ) : (
            joinMultipleValues({
              ids: presentForIngredientIds,
              dictionary: ingredientDictionary,
            })
          );
        },
      },
      {
        title: formatMessage(
          libraryMessages.allergensColumns["unintentionalPresent"]
        ),
        key: "unintentionalPresent",
        className: styles.unsetPosition,
        render: ({
          allergenId,
          presentForIngredientIds,
          unintentionalPresentForIngredientIds,
        }) => {
          return displayActions ? (
            <SelectRawMaterials
              ingredients={ingredients}
              allergenId={allergenId}
              onSelectIngredient={onSelectIngredient({
                allergenId,
                declaration: ALLERGEN_DECLARATION_TYPE.UNINTENTIONAL_PRESENT,
                presentIngredientIds: presentForIngredientIds,
                unintentionalPresentIngredientIds: unintentionalPresentForIngredientIds,
              })}
              onDeselectIngredient={onDeselectIngredient({
                allergenId,
                declaration: ALLERGEN_DECLARATION_TYPE.UNINTENTIONAL_PRESENT,
                presentIngredientIds: presentForIngredientIds,
                unintentionalPresentIngredientIds: unintentionalPresentForIngredientIds,
              })}
              type="unintentionalPresentForIngredientIds"
              allergenMaterials={ingredientAllergens?.[allergenId]}
              disableActions={disableActions}
            />
          ) : (
            joinMultipleValues({
              ids: unintentionalPresentForIngredientIds,
              dictionary: ingredientDictionary,
            })
          );
        },
      },
      {
        ...(displayActions && {
          title: formatMessage(libraryMessages.allergensColumns["action"]),
          key: "action",
          width: 100,
          render: allergen => {
            return (
              <div className={styles.actionColumnAlignment}>
                <AllergenActions
                  areActionsDisabled={!displayActions || disableActions}
                  onRemoveAllergen={onRemoveAllergen(allergen.allergenId)}
                />
              </div>
            );
          },
        }),
      },
    ].filter(item => !isObjectEmpty(item));

  return {
    columns: getColumns(),
    onAddAllergens,
    onRemoveAllergen,
    onSelectIngredient,
    onDeselectIngredient,
  };
};

export default useAllergensTable;
