import { useReactOidc } from "@axa-fr/react-oidc-context";
import { useEffect } from "react";
import { useAppDispatch } from "store";
import {
  setAccessToken,
  setCompanyActivity,
  setCompanyCountry,
  setUserPermissions,
} from "store/oidc/oidcSlice";
import { getPermissions } from "store/oidc/utils";
import {
  fetchUser,
  fetchUserApplications,
  fetchUserCompanyDisplayName,
} from "store/user/asyncActions";
import { PERMISSIONS } from "utils/constants";

const useOidcSetup = () => {
  const dispatch = useAppDispatch();
  const {
    oidcUser: {
      access_token,
      profile: {
        company_activity,
        company_country,
        global_user_id,
        permissions,
        accessToAdministration,
      },
    },
    events,
    logout,
  } = useReactOidc();

  const saveOidcAccessToken = async ({
    access_token: token,
  }: {
    access_token: string;
  }) => {
    dispatch(setAccessToken(token));

    localStorage.setItem("access-token", token);
  };

  const saveUserPermissions = async ({
    profile: { permissions, accessToAdministration },
  }: {
    profile: {
      permissions: string;
      accessToAdministration: string;
    };
  }) => {
    let permissionsList = getPermissions({
      permissions,
    });

    if (accessToAdministration === "True") {
      permissionsList.push(PERMISSIONS.USER.HAS_ACCESS_TO_ADMINISTRATION);
    }

    dispatch(setUserPermissions(permissionsList));
  };

  useEffect(() => {
    saveOidcAccessToken({ access_token });
    saveUserPermissions({ profile: { permissions, accessToAdministration } });

    events.addUserLoaded(saveOidcAccessToken);
    /* @ts-ignore */
    events.addUserLoaded(saveUserPermissions);

    /*
      needed if the user has another tab with the application
      it will be logout from the other tab
    */
    events.addUserSignedOut(() => {
      logout();
    });

    return () => {
      events.removeUserLoaded(saveOidcAccessToken);
      /* @ts-ignore */
      events.removeUserLoaded(saveUserPermissions);
      events.removeUserSignedOut(() => {
        logout();
      });
    };
  }, [access_token, dispatch, events, permissions, accessToAdministration]);

  useEffect(() => {
    dispatch(setCompanyActivity(company_activity));
  }, [company_activity, dispatch]);

  useEffect(() => {
    dispatch(setCompanyCountry(company_country));
  }, [company_country, dispatch]);

  useEffect(() => {
    dispatch(fetchUser(global_user_id));
    dispatch(fetchUserCompanyDisplayName(global_user_id));
    dispatch(fetchUserApplications());
  }, [global_user_id, dispatch]);
};

export default useOidcSetup;
