import { useIntl } from "react-intl";
import { Tooltip } from "@trace-one/design-system";
import { useCompanyDetails } from "hooks";
import { useReferenceListsItems } from "hooks";
import { getPropertiesObjectFromArray } from "../../templates/utils";
import RefListWidget from "../RefListWidget";
import LabellingBackground from "./components/LabellingBackground";
import { messages } from "./messages";
import { NormalizedLabelWidgetProps } from "./types";
import styles from "./styles.module.less";

const NormalizedLabelWidget: React.FC<NormalizedLabelWidgetProps> = ({
  properties,
  formContext,
  formData,
}) => {
  const intl = useIntl();

  const { emptyValue, productData } = formContext;
  const { labelling, supplierId, production } = formData;

  const netContent = productData?.netContents?.[0];

  const { refListDictionary } = useReferenceListsItems({
    refListIds:
      productData?.netContents?.map(({ unitOfMeasure }) => unitOfMeasure) || [],
  });

  const { companyData } = useCompanyDetails({
    companyId: supplierId,
  });

  const { batchNumber, displayGtin, packerCode, sanitaryCode, packingDate } =
    labelling || {};

  const {
    countryId,
    regionId,
    caliberCode,
    categoryCode,
    varietyId,
    specieId,
  } = production || {};

  const propertyObjects = getPropertiesObjectFromArray(properties);

  const productionProperties =
    propertyObjects.production.content.props.schema.properties;

  const refListProps = {
    options: {
      dropdown: false,
      displayTooltip: true,
    },
    formContext,
    onChange: () => {},
  };

  return (
    <div className={styles.labelling}>
      <div className={styles.leftSection}>
        <LabellingBackground />
        <div>
          <div>
            <p>
              {packerCode} {packerCode && sanitaryCode && emptyValue}{" "}
              {sanitaryCode}
            </p>
            {/* not using Heading component because this is specific to Labelling */}
            <p className={styles.companyName}>
              {companyData?.companyDisplayName}
            </p>
            <p className={styles.companyAddress}>
              {companyData?.companyAddress1}
            </p>
            <p className={styles.companyAddress}>
              {companyData?.companyAddress2}
            </p>
            <p className={styles.postalCode}>
              {companyData?.companyPostalCode} {companyData?.companyCity}
            </p>
            <p className={styles.postalCode}>
              {companyData?.companyCountryName}
            </p>
          </div>
          <div>
            {packingDate && (
              <p>
                <Tooltip
                  text={`${intl.formatMessage(
                    messages.packedOn
                  )}: ${packingDate}`}
                  placement="top"
                >
                  {intl.formatMessage(messages.packedOn)}: {packingDate}
                </Tooltip>
              </p>
            )}
            {batchNumber && (
              <p>
                <Tooltip
                  text={`${intl.formatMessage(messages.batch)}: ${batchNumber}`}
                  placement="top"
                >
                  {intl.formatMessage(messages.batch)}: {batchNumber}
                </Tooltip>
              </p>
            )}
            {displayGtin && (
              <p>
                <Tooltip text={productData?.gtin} placement="top">
                  {productData?.gtin}
                </Tooltip>
              </p>
            )}
          </div>
        </div>
      </div>
      <div className={styles.rightSection}>
        <div>
          <div>
            <Tooltip text={intl.formatMessage(messages.origin)} placement="top">
              {intl.formatMessage(messages.origin)}
            </Tooltip>
            <RefListWidget
              value={countryId}
              schema={productionProperties.countryId}
              {...refListProps}
            />
            <RefListWidget
              value={regionId}
              schema={productionProperties.regionId}
              {...refListProps}
            />
          </div>
          <div />
          <div>
            <Tooltip
              text={intl.formatMessage(messages.category)}
              placement="top"
            >
              {intl.formatMessage(messages.category)}
            </Tooltip>
            {categoryCode && (
              <Tooltip text={categoryCode}>{categoryCode}</Tooltip>
            )}
          </div>
        </div>
        <div>
          <p>
            <Tooltip
              text={intl.formatMessage(messages.product)}
              placement="top"
            >
              {intl.formatMessage(messages.product)}
            </Tooltip>
            <RefListWidget
              value={specieId}
              schema={productionProperties.specieId}
              {...refListProps}
            />
          </p>
          <p>
            <Tooltip
              text={intl.formatMessage(messages.variety)}
              placement="top"
            >
              {intl.formatMessage(messages.variety)}
            </Tooltip>
            <RefListWidget
              value={varietyId}
              schema={productionProperties.varietyId}
              {...refListProps}
            />
          </p>
        </div>
        <div>
          <div>
            <Tooltip
              text={intl.formatMessage(messages.caliber)}
              placement="top"
            >
              {intl.formatMessage(messages.caliber)}
            </Tooltip>
            {caliberCode && (
              <Tooltip text={caliberCode} placement="top">
                {caliberCode}
              </Tooltip>
            )}
          </div>
          <div>
            <Tooltip text={intl.formatMessage(messages.number)} placement="top">
              {intl.formatMessage(messages.number)}
            </Tooltip>
            <span>
              <Tooltip
                text={refListDictionary[netContent?.unitOfMeasure]}
                placement="top"
              >
                {netContent?.value}
                {refListDictionary[netContent?.unitOfMeasure]}
              </Tooltip>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NormalizedLabelWidget;
