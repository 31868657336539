import { defineMessages } from "react-intl";

export const messages = defineMessages({
  packagingComponentActionsEdit: {
    id: "packaging.component.actions.edit",
    defaultMessage: "Edit",
  },
  packagingComponentActionsDelete: {
    id: "packaging.component.actions.delete",
    defaultMessage: "Delete",
  },
  addMaterial: {
    id: "packaging.component.addMaterial",
    defaultMessage: "Add a material",
  },
  editMaterial: {
    id: "packaging.component.editMaterial",
    defaultMessage: "Edit a material",
  },
  confirm: {
    id: "packaging.component.addMaterial.confirm",
    defaultMessage: "Confirm",
  },
  modify: {
    id: "packaging.component.editMaterial.modify",
    defaultMessage: "Modify",
  },
  cancel: {
    id: "packaging.component.addMaterial.cancel",
    defaultMessage: "Cancel",
  },
  noMaterials: {
    id: "packaging.component.noMaterials",
    defaultMessage: "No materials",
  },
  packagingComponentNameLabel: {
    id: "packaging.component.nameLabel",
    defaultMessage: "Name",
  },
  packagingComponentUsageLevelLabel: {
    id: "packaging.component.usageLevelLabel",
    defaultMessage: "Usage level",
  },
  selectMaterial: {
    id: "packaging.component.materialForm.selectMaterial",
    defaultMessage: "Select a material",
  },
  primary: {
    id: "specificationDetails.widgets.packagingSystem.usageLevelPrimary",
    defaultMessage: "Primary",
  },
  secondary: {
    id: "specificationDetails.widgets.packagingSystem.usageLevelSecondary",
    defaultMessage: "Secondary",
  },
  tertiary: {
    id: "specificationDetails.widgets.packagingSystem.usageLevelTertiary",
    defaultMessage: "Tertiary",
  },
  generalInformationTitle: {
    id: "library.packagingSystemForm.tabs.generalInformationTitle",
    defaultMessage: "General information",
  },
  gtin: {
    id: "packaging.component.gtin",
    defaultMessage: "GTIN",
  },
  setAsMainComponent: {
    id: "packaging.component.action.setAsMainComponent",
    defaultMessage: "Set as main component",
  },
  unsetAsMainComponent: {
    id: "packaging.component.action.unsetAsMainComponent",
    defaultMessage: "Unset as main component",
  },
  main: {
    id: "packaging.component.main",
    defaultMessage: "Main",
  },
  createAndAssociate: {
    id: "packaging.createAndAssociate",
    defaultMessage: "Create & associate",
  },
});

export const packagingComponentTabMessages = defineMessages({
  title: {
    id: "packaging.component.tab.title",
    defaultMessage: "Define packaging system components",
  },
  subtitle: {
    id: "packaging.component.tab.subtitle",
    defaultMessage: "Select and edit",
  },
});

export const packagingComponentTableMessages = defineMessages({
  componentName: {
    id: "packaging.component.table.name",
    defaultMessage: "Component name",
  },
  componentType: {
    id: "packaging.component.table.type",
    defaultMessage: "Component type",
  },
  totalPercentageOfRecyclability: {
    id: "packaging.component.table.totalPercentageOfRecyclability",
    defaultMessage: "Total % of recycled material",
  },
});
