import { defineMessages } from "react-intl";

export const messages = defineMessages({
  name: {
    id: "creationFolder.name",
    defaultMessage: "Name",
  },
  type: {
    id: "creationFolder.type",
    defaultMessage: "Type",
  },
});
