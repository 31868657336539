import React from "react";

export const getHasUserGuideCursor = ({
  hasUserGuide,
  isChapterVisible,
}: {
  hasUserGuide: boolean;
  isChapterVisible: boolean;
}) => !!hasUserGuide && !!isChapterVisible;

export const getIsSectionCheckboxDisabled = ({
  isSectionMandatory,
  isChapterVisible,
  displayActions,
}: {
  isSectionMandatory: boolean;
  isChapterVisible: boolean;
  displayActions: boolean;
}) => isSectionMandatory || !isChapterVisible || !displayActions;

export const getIsSectionDisabled = ({
  isChapterVisible,
  displayActions,
}: {
  isChapterVisible: boolean;
  displayActions: boolean;
}) => !isChapterVisible || !displayActions;

export const getIsUserGuideDisabled = ({
  isChapterVisible,
  displayActions,
  hasUserGuide,
}: {
  isChapterVisible?: boolean;
  displayActions?: boolean;
  hasUserGuide?: boolean;
}) => {
  if ((!displayActions && !hasUserGuide) || !isChapterVisible) return true;

  return false;
};

export const getOnIconUserGuideClick = ({
  setCommentModal,
  initialComment,
  sectionId,
  chapterType,
}: {
  setCommentModal: Function;
  initialComment: string;
  sectionId: string;
  chapterType?: string;
}) => () => {
  setCommentModal({
    initialComment,
    sectionId,
    chapterType,
  });
};

export const getOnChangeSectionVisibility = ({
  changeVisibilityForSection,
  setIsLoading,
}: {
  changeVisibilityForSection: Function;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) => async ({ target: { checked } }) => {
  try {
    setIsLoading(true);
    await changeVisibilityForSection(checked);
  } finally {
    setIsLoading(false);
  }
};
