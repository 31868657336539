import { useState, useMemo, useContext } from "react";
import { useCookies } from "react-cookie";
import { useApiPolling } from "hooks";
import { isListEmpty } from "utils/general";
import { ResultTableContext } from "contexts";

const usePollingSpecifications = () => {
  const { searchSpecifications, specifications } = useContext(
    ResultTableContext
  );
  const [specApiCalls, setSpecApiCalls] = useState(0);

  const [cookies] = useCookies(["specificationIdsFromFolderCreation"]);

  const dispatchFetchSpecifications = () => {
    setSpecApiCalls(prev => prev + 1);

    searchSpecifications();
  };

  const specificationListIds = useMemo(() => {
    return specifications?.map(({ specificationId }) => specificationId);
  }, [specifications]);

  const isPollingCompleted = useMemo(() => {
    if (
      isListEmpty(cookies?.["specificationIdsFromFolderCreation"]) &&
      (!isListEmpty(specificationListIds) ||
        (specApiCalls >= 1 && isListEmpty(specificationListIds)))
    ) {
      return true;
    }
    if (
      !isListEmpty(cookies?.["specificationIdsFromFolderCreation"]) &&
      !isListEmpty(specificationListIds)
    ) {
      const areAllCreatedSpecificationsInTheList = cookies[
        "specificationIdsFromFolderCreation"
      ].every(id => specificationListIds.includes(id));

      return areAllCreatedSpecificationsInTheList;
    }
    return false;
  }, [JSON.stringify(specificationListIds)]);

  const { hasReachedMaxApiCalls } = useApiPolling({
    pollingFunction: dispatchFetchSpecifications,
    stopPolling: isPollingCompleted,
  });

  return { isPollingInProgress: !isPollingCompleted && !hasReachedMaxApiCalls };
};

export default usePollingSpecifications;
