import { createContext } from "react";
import {
  SpecificationData,
  SpecificationLabelingTranslationData,
  TemplateData,
} from "models";

export type TranslationManagementContextProps = {
  specificationId: SpecificationData["id"];
  specificationHeaderTitle?: string;
  translationTemplate?: TemplateData;
  setTranslationTemplate?: (_: TemplateData) => void;
  previousTranslationTemplate?: TemplateData;
  setPreviousTranslationTemplate?: (_: TemplateData) => void;
  onCancel?: () => void;
  shouldDeleteOnCancel?: boolean;
  setShouldDeleteOnCancel?: (_: boolean) => void;
  translationTemplateId?: TemplateData["generalInfo"]["id"];
  setSpecificationLabelingTranslationData?: (
    _: SpecificationLabelingTranslationData
  ) => void;
  validatedByRetailer?: boolean;
};

const TranslationManagementContext = createContext<TranslationManagementContextProps>(
  {
    specificationId: "",
    specificationHeaderTitle: "",
    translationTemplate: null,
    setTranslationTemplate: () => {},
    previousTranslationTemplate: null,
    setPreviousTranslationTemplate: () => {},
    onCancel: () => {},
    shouldDeleteOnCancel: false,
    setShouldDeleteOnCancel: () => {},
    translationTemplateId: "",
    setSpecificationLabelingTranslationData: () => {},
    validatedByRetailer: false,
  }
);

export default TranslationManagementContext;
