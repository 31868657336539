import { useIntl } from "react-intl";
import libraryMessages from "messages/library";
import styles from "./styles.module.less";

const RadioColumnTitle = () => {
  const { formatMessage } = useIntl();
  return (
    <div className={styles.radioColumnTitle}>
      <span>{formatMessage(libraryMessages.allergensColumns["present"])}</span>
      <span>
        {formatMessage(
          libraryMessages.allergensColumns["unintentionalPresent"]
        )}
      </span>
    </div>
  );
};

export default RadioColumnTitle;
