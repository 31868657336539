import { Col, Form } from "antd";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import ReadOnlyTextWidget from "../../../widgets/ReadOnlyTextWidget";
import { getFieldTitleFromUiSchemaObjectTemplate } from "utils/general";
import { PackagingMaterialCalculationProps } from "./types";
import styles from "./styles.module.less";

const PackagingMaterialCalculationObjectFieldTemplate = props => {
  const { getTranslationForKey } = useFormSchemaTranslation();

  const { formContext, schema, uiSchema } = props;
  const {
    formData: { calculations },
  } = formContext;

  if (
    !(calculations as PackagingMaterialCalculationProps)
      ?.sumOfPercentageOfTotalRecycledMaterials
  ) {
    return null;
  }

  const fieldNames = Object.keys(schema.properties);

  return (
    <Col span={24}>
      {fieldNames.map(fieldName => {
        const label = getFieldTitleFromUiSchemaObjectTemplate(
          uiSchema,
          fieldName
        );

        return (
          <Form.Item
            key={fieldName}
            className={styles.packagingMaterialCalculationItem}
          >
            <ReadOnlyTextWidget
              {...props}
              uiSchema={uiSchema[fieldName]}
              value={calculations?.[fieldName]}
              label={getTranslationForKey(label)}
            />
          </Form.Item>
        );
      })}
    </Col>
  );
};

export default PackagingMaterialCalculationObjectFieldTemplate;
