import React from "react";
import { Col, Row } from "antd";
import { Paragraph } from "@trace-one/design-system";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { isObjectEmpty } from "utils/general";
import styles from "./styles.module.less";
import { getBooleanTranslation } from "components/SpecificationSection/components/FormSchema/utils";

const AcceptsAlternativeOrigin = ({
  acceptsAlternativeOriginContent,
}: {
  acceptsAlternativeOriginContent: React.ReactElement;
}) => {
  const { getTranslationForKey } = useFormSchemaTranslation();
  const { formData, uiSchema } = acceptsAlternativeOriginContent?.props;

  const acceptsAlternativeOrigin = getBooleanTranslation(
    formData,
    getTranslationForKey
  );

  if (isObjectEmpty(uiSchema)) {
    return null;
  }

  return (
    <Col xs={24} className={styles.acceptsAlternativeOriginReadOnly}>
      <Row>
        <Col xs={4}>
          <Paragraph size="xs" color="grey-5">
            {uiSchema["ui:title"]}
          </Paragraph>
        </Col>
        <Col xs={20}>
          <Paragraph size="xs" color="grey-4">
            {acceptsAlternativeOrigin}
          </Paragraph>
        </Col>
      </Row>
    </Col>
  );
};
export default AcceptsAlternativeOrigin;
