import { useSelector } from "react-redux";
import { selectOwningCompanyId } from "store/user/selectors";
import { useRedirect } from "hooks";
import { LIBRARY_OBJECT_TYPES } from "utils/constants";
import { useRequirementFormActionsProps } from "./types";
import { useAppDispatch } from "store";
import {
  keepAsDraftRequirement,
  deleteRequirement,
  publishRequirement,
} from "store/requirementForm/asyncActions";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import libraryMessages from "messages/library";
import { selectRequirementInformation } from "store/requirementForm/selectors";
import { isLibraryObjectLocked } from "utils/library";

const useRequirementFormActions = ({
  validateFields,
}: useRequirementFormActionsProps) => {
  const { formatMessage } = useIntl();
  const { state } = useSelector(selectRequirementInformation);

  const companyId = useSelector(selectOwningCompanyId);
  const dispatch = useAppDispatch();
  const { redirectToLibraryListing } = useRedirect();

  const actionButtonLabel = useMemo(
    () =>
      !state
        ? formatMessage(libraryMessages.publishButton)
        : isLibraryObjectLocked(state)
        ? formatMessage(libraryMessages.createNewVersion)
        : formatMessage(libraryMessages.editButton),
    [state]
  );

  const onKeepAsDraftRequirement = async () => {
    try {
      await validateFields?.();
      await dispatch(keepAsDraftRequirement(companyId));
    } catch (e) {
      throw e;
    }
  };

  const onKeepAsDraftRequirementFromDropdown = async () => {
    try {
      await onKeepAsDraftRequirement();

      redirectToLibraryListing(LIBRARY_OBJECT_TYPES.REQUIREMENTS, {
        skipDetectLeavePage: true,
      });
    } catch (e) {
      throw e;
    }
  };

  const onPublishRequirement = async () => {
    try {
      await validateFields?.();

      await dispatch(publishRequirement());

      redirectToLibraryListing(LIBRARY_OBJECT_TYPES.REQUIREMENTS, {
        skipDetectLeavePage: true,
      });
    } catch (e) {
      throw e;
    }
  };

  const onDeleteRequirement = async () => {
    try {
      await dispatch(deleteRequirement());
    } catch (e) {
      throw e;
    }
  };

  return {
    onKeepAsDraftRequirement,
    onDeleteRequirement,
    onPublishRequirement,
    actionButtonLabel,
    onKeepAsDraftRequirementFromDropdown,
  };
};

export default useRequirementFormActions;
