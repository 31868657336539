import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchCompany, fetchUser, searchUsers } from "apis/CUMD";
import { fetchTradeItems } from "apis/PMD";
import { fetchReferenceListsItem } from "apis/RLMD";
import {
  createSpecificationDraft,
  createSpecificationDraftContact,
  createSpecificationDraftCollaboration,
  createSpecificationWizard,
  deleteSpecificationDraftContact,
  deleteSpecificationDraftCollaboration,
  fetchSpecificationDraft,
  fetchTemplateById,
  fetchTradeItemSpecifications,
  updateSpecificationDraftNote,
  updateSpecificationDraftTradeItem,
  updateSpecificationDraftSupplier,
  deleteSpecificationDraft,
  fetchSpecificationDraftWizard,
  updateWizardCurrentStep,
  validateWizardStep,
  invalidateWizardSteps,
  updateWizardCollaboration,
  updateSpecificationDraftFiles as updateSpecificationDraftFilesApi,
  updateSpecificationProductType,
  updateSpecificationTemplate,
} from "apis/SPEC";
import {
  SpecificationData,
  SpecificationDraftData,
  SpecificationDraftFileData,
  SpecificationListItemData,
  TemplateData,
  TradeItemData,
  UserData,
  WizardData,
} from "models";
import { AsyncThunkConfig } from "store";
import { updateRefListDictionary } from "store/generalData/asyncActions";
import initialState from "store/specificationCreation/initialState";
import { isListEmpty, isObjectEmpty, isUuidEmpty } from "utils/general";
import { isStateDraft } from "utils/specifications";
import { OwnerContact } from "store/types";
import { SelectedSuppler, State } from "./types";
import { isCurrentStepValidated } from "utils/wizard";

export const fetchOwnerContacts = createAsyncThunk<
  OwnerContact[],
  void,
  AsyncThunkConfig
>("createSpecification/fetchOwnerContacts", async (_, { getState }) => {
  const {
    specificationCreation: { ownerContacts },
    user: { owningCompanyId, userId },
  } = getState();

  if (isListEmpty(ownerContacts)) {
    const {
      data: { users },
    } = await searchUsers({
      params: {
        isEnabled: true,
        owningCompanyId,
        skip: 0,
        take: 1000,
      },
    });

    return users.filter(user => userId !== user.userId);
  }

  return ownerContacts;
});

export const startSpecificationCreation = createAsyncThunk<
  {
    specification: SpecificationDraftData;
    user: UserData;
  },
  void,
  AsyncThunkConfig
>(
  "specificationCreation/startSpecificationCreation",
  async (_, { getState, dispatch }) => {
    const { user } = getState();

    const { data: specification } = await createSpecificationDraft({});
    dispatch(setSpecificationWizard(specification.draftId));
    return {
      specification,
      user,
    };
  }
);

export const setSpecificationWizard = createAsyncThunk<
  WizardData,
  SpecificationDraftData["draftId"],
  AsyncThunkConfig
>("specificationCreation/setSpecificationWizard", async draftId => {
  const { data } = await createSpecificationWizard({ draftId });

  return data;
});

export const fetchSpecificationTemplates = createAsyncThunk<
  TemplateData,
  void,
  AsyncThunkConfig
>(
  "specificationCreation/fetchSpecificationTemplates",
  async (_, { getState }) => {
    const {
      specificationCreation: { specification },
      user: { userLanguagePreference },
    } = getState();

    if (!specification.templateId) {
      return Promise.reject();
    }

    const { data } = await fetchTemplateById({
      templateId: specification.templateId,
      languageCode: userLanguagePreference,
      excludeHiddenSections: true,
    });

    return data;
  }
);

export const setInitialSpecficationDetails = createAsyncThunk<
  {
    template?: State["template"];
    chapters?: State["chapters"];
    selectedProduct?: State["selectedProduct"];
    selectedSupplier?: State["selectedSupplier"];
    ownerContacts?: State["ownerContacts"];
    user?: State["ownerUserDetails"];
    specification?: State["specification"];
    wizard?: State["wizard"];
  },
  SpecificationData["id"],
  AsyncThunkConfig
>(
  "specificationCreation/setInitialSpecficationDetails",
  async (specificationId, { getState, dispatch }) => {
    const {
      user: { userLanguagePreference, owningCompanyId },
    } = getState();

    const { data: specification } = await fetchSpecificationDraft({
      draftId: specificationId,
    });

    const { data: user } = await fetchUser({
      id: specification.ownerUserId,
    });

    let specificationDetails = {};

    const getOwnerContacts = async () => {
      let ownerContacts = initialState.ownerContacts;
      let retailerContactIds = specification.contactIds?.filter(
        userId => specification.ownerUserId !== userId
      );

      if (!isListEmpty(retailerContactIds)) {
        try {
          const {
            data: { users },
          } = await searchUsers({
            params: {
              isEnabled: true,
              owningCompanyId,
              skip: 0,
              take: 1000,
            },
          });

          ownerContacts = users.map(user => ({
            ...user,
            isSelected:
              retailerContactIds.findIndex(userId => user.userId === userId) >
              -1,
          }));
        } catch (e) {}
      }

      ownerContacts = ownerContacts.filter(
        owner => owner.userId !== specification.ownerUserId
      );
      return ownerContacts;
    };

    const getSelectedProduct = async () => {
      let selectedProduct = initialState.selectedProduct;

      if (!isUuidEmpty(specification.tradeItemId)) {
        try {
          const { data: tradeItems } = await fetchTradeItems({
            ids: [specification.tradeItemId],
            ownerCompanyId: owningCompanyId,
          });
          if (tradeItems.length) {
            selectedProduct = tradeItems[0];
          }
        } catch (_) {}
      }

      return selectedProduct;
    };

    const getSelectedSupplier = async () => {
      let selectedSupplier = initialState.selectedSupplier;

      if (!isUuidEmpty(specification.supplierId)) {
        try {
          const { data: supplier } = await fetchCompany({
            id: specification.supplierId,
          });

          if (!isObjectEmpty(supplier)) {
            selectedSupplier = {
              value: supplier.companyId,
              label: supplier.companyDisplayName,
            };
          }
        } catch (_) {}
      }
      return selectedSupplier;
    };

    const getTemplate = async () => {
      if (specification.templateId) {
        try {
          const { data } = await fetchTemplateById({
            templateId: specification.templateId,
            languageCode: userLanguagePreference,
            excludeHiddenSections: true,
          });

          return data;
        } catch (e) {
          return initialState.template;
        }
      }
    };

    const getProductType = async () => {
      if (specification.productTypeId) {
        const { data } = await fetchReferenceListsItem({
          languageCode: userLanguagePreference,
          id: specification.productTypeId,
          companyId: owningCompanyId,
        });

        dispatch(
          updateRefListDictionary({
            [data.id]: data.text,
          })
        );
      }
    };

    const getWizard = async () => {
      let wizard = initialState.wizard;

      try {
        const { data } = await fetchSpecificationDraftWizard({
          draftId: specificationId,
        });
        wizard = data;
      } catch (e) {
      } finally {
        return wizard;
      }
    };

    await Promise.all([
      getOwnerContacts(),
      getSelectedProduct(),
      getSelectedSupplier(),
      getTemplate(),
      getWizard(),
      getProductType(),
    ]).then(result => {
      const [
        ownerContacts,
        selectedProduct,
        selectedSupplier,
        template,
        wizard,
      ] = result;

      specificationDetails = {
        template: template ?? initialState?.template,
        chapters: template?.chapters ?? [],
        selectedProduct,
        selectedSupplier,
        ownerContacts,
        user,
        specification,
        wizard,
      };
    });

    return specificationDetails;
  }
);

export const fetchExistingSpecifications = createAsyncThunk<
  SpecificationListItemData[],
  TradeItemData["id"],
  AsyncThunkConfig
>(
  "specificationCreation/fetchExistingSpecifications",
  async (tradeItemId, { getState }) => {
    const {
      user: { owningCompanyId },
      specificationCreation: { specification },
    } = getState();

    const { data: existingSpecifications } = await fetchTradeItemSpecifications(
      {
        tradeItemId,
        ownerCompanyId: owningCompanyId,
      }
    );

    return existingSpecifications.filter(
      ({ folderId, state, specificationId }) =>
        specificationId !== specification.draftId &&
        (!isStateDraft(state) || !folderId)
    );
  }
);

export const setSpecificationProductType = createAsyncThunk<
  SpecificationDraftData,
  string,
  AsyncThunkConfig
>(
  "specificationCreation/setSpecificationProductType",
  async (productTypeId, { getState, dispatch }) => {
    const {
      specificationCreation: { specification, wizard },
    } = getState();

    const { data } = await updateSpecificationProductType({
      draftId: specification.draftId,
      productTypeId,
    });

    isCurrentStepValidated(wizard.currentStep, wizard.state) &&
      (await dispatch(invalidateSteps()));

    return data;
  }
);

export const getTemplateById = createAsyncThunk<
  TemplateData,
  string,
  AsyncThunkConfig
>("specificationCreation/getTemplateById", async (templateId, { getState }) => {
  const {
    user: { userLanguagePreference },
  } = getState();

  const { data } = await fetchTemplateById({
    templateId: templateId,
    languageCode: userLanguagePreference,
    excludeHiddenSections: true,
  });
  return data;
});

export const setSpecificationTemplate = createAsyncThunk<
  SpecificationDraftData,
  string,
  AsyncThunkConfig
>(
  "specificationCreation/setSpecificationTemplate",
  async (templateId, { getState, dispatch }) => {
    const {
      specificationCreation: { specification, wizard },
    } = getState();

    const { data } = await updateSpecificationTemplate({
      draftId: specification.draftId,
      templateId,
    });

    isCurrentStepValidated(wizard.currentStep, wizard.state) &&
      (await dispatch(invalidateSteps()));

    return data;
  }
);

export const addSpecificationOwnerContact = createAsyncThunk<
  SpecificationDraftData,
  UserData["userId"],
  AsyncThunkConfig
>(
  "specificationCreation/addSpecificationOwnerContact",
  async (userId, { getState, dispatch }) => {
    const {
      specificationCreation: { specification, wizard },
    } = getState();

    const { data } = await createSpecificationDraftContact({
      draftId: specification.draftId,
      userId,
    });

    isCurrentStepValidated(wizard.currentStep, wizard.state) &&
      (await dispatch(invalidateSteps()));

    return data;
  }
);

export const removeSpecificationOwnerContact = createAsyncThunk<
  SpecificationDraftData,
  UserData["userId"],
  AsyncThunkConfig
>(
  "specificationCreation/removeSpecificationOwnerContact",
  async (userId, { getState, dispatch }) => {
    const {
      specificationCreation: { specification, wizard },
    } = getState();

    const { data } = await deleteSpecificationDraftContact({
      draftId: specification.draftId,
      userId,
    });

    isCurrentStepValidated(wizard.currentStep, wizard.state) &&
      (await dispatch(invalidateSteps()));

    return data;
  }
);

export const setSpecificationProduct = createAsyncThunk<
  SpecificationDraftData,
  TradeItemData,
  AsyncThunkConfig
>(
  "specificationCreation/setSpecificationProduct",
  async (product, { getState, dispatch }) => {
    const {
      specificationCreation: { specification, wizard },
    } = getState();

    const { data } = await updateSpecificationDraftTradeItem({
      draftId: specification.draftId,
      tradeItemId: product.id,
    });

    isCurrentStepValidated(wizard.currentStep, wizard.state) &&
      (await dispatch(invalidateSteps()));

    return data;
  }
);

export const removeSpecificationProduct = createAsyncThunk<
  SpecificationDraftData,
  void,
  AsyncThunkConfig
>(
  "specificationCreation/removeSpecificationProduct",
  async (_, { getState, dispatch, rejectWithValue }) => {
    const {
      specificationCreation: { specification, wizard },
    } = getState();

    let newSpecification = specification;

    if (specification.supplierId) {
      try {
        const { data } = await updateSpecificationDraftSupplier({
          draftId: specification.draftId,
        });

        newSpecification = data;
      } catch (_) {
        return rejectWithValue({
          supplierError: true,
        });
      }
    }

    try {
      const { data } = await updateSpecificationDraftTradeItem({
        draftId: specification.draftId,
      });

      newSpecification = data;
    } catch (_) {
      return rejectWithValue({
        productError: true,
      });
    }

    isCurrentStepValidated(wizard.currentStep, wizard.state) &&
      (await dispatch(invalidateSteps()));

    return newSpecification;
  }
);

export const setSpecificationSupplier = createAsyncThunk<
  SpecificationDraftData,
  SelectedSuppler,
  AsyncThunkConfig
>(
  "specificationCreation/setSpecificationSupplier",
  async (supplier, { getState, dispatch }) => {
    const {
      specificationCreation: { specification, wizard },
    } = getState();

    const { data } = await updateSpecificationDraftSupplier({
      draftId: specification.draftId,
      companyId: supplier.value,
    });

    isCurrentStepValidated(wizard.currentStep, wizard.state) &&
      (await dispatch(invalidateSteps()));

    return data;
  }
);

export const setSpecificationNote = createAsyncThunk<
  SpecificationDraftData,
  SpecificationDraftData["note"],
  AsyncThunkConfig
>(
  "specificationCreation/setSpecificationNote",
  async (note, { getState, dispatch }) => {
    const {
      specificationCreation: { specification, wizard },
    } = getState();

    const { data } = await updateSpecificationDraftNote({
      draftId: specification.draftId,
      note,
    });

    isCurrentStepValidated(wizard.currentStep, wizard.state) &&
      (await dispatch(invalidateSteps()));

    return data;
  }
);

export const removeSpecification = createAsyncThunk<
  void,
  void,
  AsyncThunkConfig
>("specificationCreation/deleteSpecification", async (_, { getState }) => {
  const {
    specificationCreation: { specification },
  } = getState();

  if (specification.draftId) {
    await deleteSpecificationDraft({
      specificationId: specification.draftId,
    });
  }
});

export const checkIfSpecificationIsFinalized = createAsyncThunk<
  {
    isFinalized: boolean;
    existingSpecificationId: SpecificationData["id"];
  },
  void,
  AsyncThunkConfig
>(
  "specificationCreation/checkIfSpecificationIsFinalized",
  async (_, { getState, rejectWithValue }) => {
    const {
      specificationCreation: { specification },
      user: { owningCompanyId },
    } = getState();

    if (!specification.draftId) {
      return { isFinalized: false, existingSpecificationId: null };
    }

    try {
      const {
        data: existingSpecifications,
      } = await fetchTradeItemSpecifications({
        tradeItemId: specification.tradeItemId,
        ownerCompanyId: owningCompanyId,
        supplierId: specification.supplierId,
      });

      if (isListEmpty(existingSpecifications)) {
        return { isFinalized: false, existingSpecificationId: null };
      }

      const {
        specificationId: existingSpecificationId,
      } = existingSpecifications[0];

      const isFinalized = existingSpecificationId !== specification.draftId;

      return {
        existingSpecificationId: isFinalized ? existingSpecificationId : null,
        isFinalized,
      };
    } catch (_) {
      return rejectWithValue({});
    }
  }
);

export const setSpecificationDraftCollaboration = createAsyncThunk<
  SpecificationDraftData,
  string,
  AsyncThunkConfig
>(
  "specificationCreation/setSpecificationDraftCollaboration",
  async (collaborationId, { getState, dispatch }) => {
    const {
      specificationCreation: { specification, wizard },
    } = getState();

    const { data } = await createSpecificationDraftCollaboration({
      draftId: specification.draftId,
      collaborationId,
    });

    isCurrentStepValidated(wizard.currentStep, wizard.state) &&
      (await dispatch(invalidateSteps()));

    return data;
  }
);

export const removeSpecificationDraftCollaborations = createAsyncThunk<
  SpecificationDraftData,
  string[],
  AsyncThunkConfig
>(
  "specificationCreation/removeSpecificationDraftCollaborations",
  async (collaborationIds, { getState, dispatch }) => {
    let specificationData: SpecificationDraftData;

    const {
      specificationCreation: { specification, wizard },
    } = getState();
    for (let collaborationId of collaborationIds) {
      const { data } = await deleteSpecificationDraftCollaboration({
        draftId: specification.draftId,
        collaborationId,
      });

      specificationData = data;
    }

    isCurrentStepValidated(wizard.currentStep, wizard.state) &&
      (await dispatch(invalidateSteps()));

    return specificationData;
  }
);

export const updateCurrentStep = createAsyncThunk<
  WizardData,
  string,
  AsyncThunkConfig
>("specificationCreation/updateCurrentStep", async (step, { getState }) => {
  const {
    specificationCreation: { wizard },
  } = getState();

  const { data } = await updateWizardCurrentStep({
    id: wizard.wizardId,
    step,
  });

  return data;
});

export const validateStep = createAsyncThunk<
  WizardData,
  void,
  AsyncThunkConfig
>("specificationCreation/validateStep", async (_, { getState }) => {
  const {
    specificationCreation: { wizard },
  } = getState();

  const { data } = await validateWizardStep({
    id: wizard.wizardId,
    step: wizard.currentStep,
  });

  return data;
});

export const invalidateSteps = createAsyncThunk<
  WizardData,
  void,
  AsyncThunkConfig
>("specificationCreation/invalidateSteps", async (_, { getState }) => {
  const {
    specificationCreation: { wizard },
  } = getState();

  const { data } = await invalidateWizardSteps({
    id: wizard.wizardId,
    step: wizard.currentStep,
  });

  return data;
});

export const updateCollaboration = createAsyncThunk<
  WizardData,
  { collaboratingCompanyId: string; isEnabled: boolean },
  AsyncThunkConfig
>(
  "specificationCreation/updateCollaboration",
  async ({ collaboratingCompanyId, isEnabled }, { getState }) => {
    const {
      specificationCreation: { wizard },
    } = getState();

    const { data } = await updateWizardCollaboration({
      id: wizard.wizardId,
      collaboratingCompanyId,
      isEnabled,
    });

    return data;
  }
);

export const updateSpecificationDraftFiles = createAsyncThunk<
  SpecificationDraftData,
  { payload: SpecificationDraftFileData[] },
  AsyncThunkConfig
>("specificationCreation/updateFiles", async ({ payload }, { getState }) => {
  const {
    specificationCreation: { specification },
  } = getState();

  const { data } = await updateSpecificationDraftFilesApi({
    draftId: specification?.draftId,
    payload,
  });

  return data;
});
