import { useEffect, useState, useRef, useMemo } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import { AgGridReact } from "ag-grid-react";
import { getParsedData, isListEmpty, isObjectEmpty } from "utils/general";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { CheckboxWidget, RefListWidget, UploadIconWidget } from "../../widgets";
import { useWidgetActions } from "../../hooks";
import withRequestModificationPopover from "hocs/withRequestModificationPopover";
import { selectIsRequestModificationEditorOpen } from "store/specificationDetails/selectors";
import { setRequestModification } from "store/specificationDetails/specificationDetailsSlice";
import { useAppDispatch } from "store";
import {
  isLastRow,
  getGridData,
  onGridReady,
  getOnAddRows,
  getOnUpdateGrid,
  getOnUpdateRows,
  getOnUpdateRowValues,
  getOnRemoveRows,
  onCellValueChanged,
  onCustomCellValueChanged,
  onCustomCellClear,
} from "./utils";
import {
  canTargetField,
  doesFieldHaveModificationRequest,
  getDraftRequestFromAllRequests,
} from "utils/modificationRequest";
import RequestModificationTooltipIcon from "components/RequestModificationTooltipIcon";
import { REQUEST_MODIFICATION_MODAL_WIDTH } from "components/RequestModificationPopover";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import "./ag-styles.less";
import styles from "./styles.module.less";

const Table = ({
  columns,
  formContext,
  formData,
  idName,
  onChange,
  schema: {
    items: { properties },
  },
  canAddEmptyLine,
  onOpenModal,
  setSelectedField,
  setModalStyle,
  setRequestPopoverOptions,
}) => {
  const { getTranslationForKey } = useFormSchemaTranslation();
  const [gridApi, setGridApi] = useState(null);
  const [rowData, setRowData] = useState(formData);
  const tableRef = useRef(null);
  const isEditorOpen = useSelector(selectIsRequestModificationEditorOpen);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (rowData?.length !== formData?.length) {
      setRowData(formData);
    } else {
      getOnUpdateGrid({ gridApi, updateItems });
    }
  }, [rowData, formData, gridApi]);

  const {
    isWidgetEditable,
    templateBlockId,
    sectionId,
    displayActions,
    isTargetMode,
    readOnlyFieldIds,
    modificationRequests,
    chapterData,
    ownerCompanyId,
  } = formContext;

  const defaultColDef = useMemo(
    () => ({
      editable: true,
      sortable: false,
      flex: 1,
      minWidth: 120,
      resizable: true,
      suppressMovable: true,
      marryChildren: true,
    }),
    []
  );

  const widgets = {
    RefListWidget: RefListWidget,
    Checkbox: CheckboxWidget,
    AcceptedPhotosWidget: UploadIconWidget,
    RejectedPhotosWidget: UploadIconWidget,
  };

  const { isWidgetChanged } = useWidgetActions({ templateBlockId, sectionId });

  const isCellEditable = isWidgetEditable() && displayActions;

  const updateItems = () => {
    const rowsToUpdate = [];
    const gridRows = getGridData(gridApi);
    gridApi.forEachNodeAfterFilterAndSort((rowNode, index) => {
      const newDataRow = formData[index];
      const currentDataRow = rowNode.data;
      if (newDataRow) {
        getOnUpdateRowValues({
          currentDataRow,
          newDataRow,
        });
        rowsToUpdate.push(currentDataRow);
      } else {
        if (isLastRow({ index, gridRows })) {
          getOnRemoveRows({ gridApi, rows: [currentDataRow] });
        }
      }
    });
    getOnUpdateRows({ gridApi, rows: rowsToUpdate });
    if (formData.length > gridRows.length) {
      for (let i = gridRows.length; i < formData.length; i++) {
        getOnAddRows({ gridApi, rows: [formData[i]], i });
      }
    }
    if (canAddEmptyLine) {
      addEmptyRow();
    }
  };

  const addEmptyRow = () => {
    const gridRows = getGridData(gridApi);
    const lastRow = gridRows[gridRows.length - 1];

    if (!isObjectEmpty(lastRow) || isListEmpty(gridRows)) {
      getOnAddRows({ gridApi, rows: [{}], index: gridRows.length });
    }
  };

  const handleModalPosition = event => {
    const clickedCell = event.target.getBoundingClientRect();

    const table = tableRef.current.getBoundingClientRect();

    const isOverflowing =
      clickedCell.left +
        clickedCell.width / 2 +
        REQUEST_MODIFICATION_MODAL_WIDTH >
      table.right;

    setModalStyle({
      top: isOverflowing
        ? clickedCell.top - table.top + clickedCell.height
        : clickedCell.top - table.top + clickedCell.height / 2,
      left: isOverflowing
        ? "unset"
        : clickedCell.left - table.left + clickedCell.width / 2,
      right: isOverflowing
        ? table.right > clickedCell.right
          ? table.right - clickedCell.right
          : table.right - clickedCell.left
        : "unset",
    });
  };

  const onCellClicked = property => params => {
    if (
      doesFieldHaveModificationRequest({
        modificationRequests,
        listItemKey: params?.data?.id,
        propertyId: property?.propertyId,
      })
    ) {
      return;
    }

    const cell = params?.eventPath?.find(
      event => event.getAttribute("role") === "gridcell"
    );

    cell?.classList.add("target-mode__border");

    setSelectedField({
      listItemKey: params?.data?.id,
      propertyId: property?.propertyId,
    });

    handleModalPosition(params?.event);
    onOpenModal();
    dispatch(setRequestModification({ isEditorOpen: true }));
  };

  const getCols = () => {
    return Object.keys(columns).map(key => {
      const col = columns[key];
      const title = col["ui:title"];
      const widget = col["ui:widget"];
      const placeholder = col["ui:placeholder"];
      const property = properties[key];

      let Component = {};

      const renderPopinIcon = (listItemKey, propertyId) => {
        const request = getDraftRequestFromAllRequests({
          modificationRequests,
          listItemKey,
          propertyId: propertyId,
        });

        if (isTargetMode && request) {
          return (
            <RequestModificationTooltipIcon
              key={`${listItemKey}-${propertyId}`}
              onOpenModal={onOpenModal}
              setModalStyle={setModalStyle}
              tableRef={tableRef}
              setRequestPopoverOptions={setRequestPopoverOptions}
              request={request}
            />
          );
        }

        return null;
      };

      const Widget = widget && widgets[widget];

      Component = ({
        name,
        value,
        node: { rowIndex },
        column: { colId },
        data,
      }) => {
        return (
          <span
            data-field-id={`${data?.id}-${property?.propertyId}`}
            className="ag-custom-cell-renderer"
          >
            {Widget ? (
              <Widget
                id={rowIndex}
                allowClear
                placeholder={placeholder}
                name={name}
                value={value}
                onChange={onCustomCellValueChanged({
                  onChange,
                  rowIndex,
                  colId,
                  data,
                })}
                onClear={onCustomCellClear({
                  onChange,
                  rowIndex,
                  colId,
                  data,
                })}
                schema={property}
                formContext={formContext}
                data={data}
                title={getTranslationForKey(title)}
                readonly={
                  !isCellEditable ||
                  //For Control Plan table - type column should be disabled only if there is characteristicId property - this is the relation with characteristic table
                  (!col["ui:editable"] && data.characteristicId)
                }
              />
            ) : (
              value || null
            )}

            {renderPopinIcon(data?.id, property?.propertyId)}
          </span>
        );
      };

      return {
        headerName: getTranslationForKey(title),
        field: key,
        editable: params =>
          isCellEditable && (col["ui:editable"] || !params.data[idName]),
        pinned: col["ui:pinned"],
        width: col["ui:width"],
        minWidth: col["ui:width"],
        colId: key,
        cellStyle: { overflow: "visible" },
        cellRenderer: Component,
        cellClass: ({ rowIndex }) =>
          classnames([idName + "-" + key + "-testId"], {
            [styles.inRevisionChange]: isWidgetChanged(
              property.propertyId,
              rowIndex
            ),
          }),
        cellClassRules: {
          "target-mode": () =>
            canTargetField({
              isTargetMode,
              readOnlyFieldIds,
              propertyId: property?.propertyId,
              specOwnerCompanyId: ownerCompanyId,
              chapterOwnerCompanyId: chapterData?.ownerCompanyId,
            }),

          [styles["target-mode--bg"]]: params =>
            isTargetMode &&
            getDraftRequestFromAllRequests({
              modificationRequests,
              listItemKey: params?.data?.id,
              propertyId: property?.propertyId,
            }),
        },
        onCellValueChanged: onCellValueChanged({ onChange }),
        headerComponentParams: {
          template: `<div class="ag-cell-label-container" role="presentation" style="background-color:${col["ui:color"]}" >
              <div ref="eLabel" class="ag-header-cell-label" role="presentation">
                <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
              </div>
            </div>`,
        },

        ...{
          ...(canTargetField({
            isTargetMode,
            propertyId: property?.propertyId,
            readOnlyFieldIds,
            specOwnerCompanyId: ownerCompanyId,
            chapterOwnerCompanyId: chapterData?.ownerCompanyId,
          }) && {
            onCellClicked: onCellClicked(property),
          }),
        },
        ...(!!widget
          ? {
              editable: false,
            }
          : {
              autoHeight: true,
            }),
      };
    });
  };

  const gridOptions = useMemo(
    () => ({
      columnDefs: getCols(),
      defaultColDef,
      rowData: getParsedData(JSON.stringify(rowData)),
      domLayout: "autoHeight",
      popupParent: document.body,
      headerHeight: 50,
      onGridReady: onGridReady({ setGridApi }),
      onGridSizeChanged: onGridReady({ setGridApi }),
      stopEditingWhenCellsLoseFocus: true,
      suppressCellFocus: isTargetMode,
      reactiveCustomComponents: true,
      animateRows: false,
      suppressColumnVirtualisation: true,
    }),
    [isTargetMode, rowData]
  );

  useEffect(() => {
    if (gridApi && formContext.refListDictionary) {
      gridApi.setColumnDefs([]);
      gridApi.setColumnDefs(getCols());
    }
  }, [
    JSON.stringify(formContext.refListDictionary),
    displayActions,
    modificationRequests,
  ]);

  return (
    <div
      className={classnames("ag-theme-balham", {
        "ag-theme-balham--overflow-hidden": isEditorOpen,
        "ag-theme-balham__select--target-mode-inactive":
          isTargetMode && idName === "characteristicId",
      })}
      ref={tableRef}
    >
      <AgGridReact {...gridOptions} />
    </div>
  );
};

Table.defaultProps = {
  onChange: () => {},
  idName: "",
  canAddEmptyLine: true,
};

Table.propTypes = {
  columns: PropTypes.object.isRequired,
  formContext: PropTypes.object.isRequired,
  formData: PropTypes.array.isRequired,
  schema: PropTypes.object.isRequired,
  idName: PropTypes.string,
  onChange: PropTypes.func,
  canAddEmptyLine: PropTypes.bool,
  onOpenModal: PropTypes.func,
  setSelectedField: PropTypes.func,
  setModalStyle: PropTypes.func,
};

export default withRequestModificationPopover(Table);
