import React from "react";
import { Popconfirm } from "antd";
import styles from "./styles.module.less";
import { ConfirmationDialogProps } from "./types";

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  children,
  ...rest
}) => {
  return (
    <Popconfirm {...rest} overlayClassName={styles.dialogWrapper}>
      {children}
    </Popconfirm>
  );
};

export default ConfirmationDialog;
