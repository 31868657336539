import { File, RequirementData } from "models";
import { Heading } from "@trace-one/design-system";
import { UploadFiles } from "@trace-one/business-components";
import { Col, Row, Space } from "antd";
import { useIntl } from "react-intl";
import { isObjectEmpty } from "utils/general";
import libraryMessages from "messages/library";
import styles from "./styles.module.less";

export interface RequirementDocumentContentProps {
  file: File;
  requirementDetails: RequirementData;
}

function RequirementDocumentContent({
  file,
  requirementDetails,
}: RequirementDocumentContentProps) {
  const { formatMessage } = useIntl();

  return (
    <>
      {!isObjectEmpty(file) ? (
        <Row>
          <Col
            {...{
              xs: 24,
              sm: 12,
              md: 8,
            }}
          >
            <UploadFiles.Item fileId={file.id} fileName={file.name} />
          </Col>
        </Row>
      ) : null}

      {requirementDetails?.additionalInformation ? (
        <Space className={styles.documentWrapper} direction="vertical">
          <Heading size="xxs">
            {formatMessage(libraryMessages.additionalInformationLabel)}
          </Heading>

          <div
            dangerouslySetInnerHTML={{
              __html: requirementDetails?.additionalInformation,
            }}
          />
        </Space>
      ) : null}
    </>
  );
}

export default RequirementDocumentContent;
