import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  getColorForTag,
  getLabelForTag,
  getModeForTag,
} from "components/Status/components/Tag/utils";
import statusMessages from "messages/statuses";

const useTag = (state: string) => {
  const [tag, setTag] = useState<{
    label?: string;
    color?: string;
    mode?: string;
  }>({});
  const { formatMessage } = useIntl();

  const getTag = (state: string) => {
    const label = getLabelForTag({
      messages: statusMessages,
      messageKey: state,
      formatMessage,
    });

    const color = getColorForTag(state);

    const mode = getModeForTag(color);

    return { label, color, mode };
  };

  useEffect(() => {
    const tag = getTag(state);
    setTag(tag);
  }, [state]);

  return { tag, getTag };
};

export default useTag;
