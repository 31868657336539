import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "creationFolder.summary.shareFolder",
    defaultMessage: "Share folder",
  },
  warningMessage: {
    id: "creationFolder.summary.shareFolder.warningMessage",
    defaultMessage: "Sharing {folderName} will",
  },
  firstWarning: {
    id: "creationFolder.summary.shareFolder.firstWarning",
    defaultMessage: "Notify every involved party",
  },
  secondWarning: {
    id: "creationFolder.summary.shareFolder.secondWarning",
    defaultMessage: "Create/add/revise the desired specifications",
  },
  thirdWarning: {
    id: "creationFolder.summary.shareFolder.thirdWarning",
    defaultMessage:
      "Inform and invite every assigned supplier to fill them out",
  },
  fourthWarning: {
    id: "creationFolder.summary.shareFolder.fourthWarning",
    defaultMessage: "Set the target date of the folder to the {targetDate}",
  },
  shareQuestion: {
    id: "creationFolder.summary.shareFolder.shareQuestion",
    defaultMessage: "Share the specification?",
  },
  noBtn: {
    id: "general.no",
    defaultMessage: "No",
  },
  yesBtn: {
    id: "general.yes",
    defaultMessage: "Yes",
  },
  saving: {
    id: "creation.saving",
    defaultMessage: "Saving...",
  },
  sharing: {
    id: "creationFolder.summary.shareFolder.sharing",
    defaultMessage: "Sharing...",
  },
  errorMessage: {
    id: "creationFolder.summary.shareFolder.errorMessage",
    defaultMessage: "Sorry an error occured, please try again.",
  },
});
