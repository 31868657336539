import { defineMessages } from "react-intl";

export const messages = defineMessages({
  categoryPlaceholder: {
    id: "filters.category.placeholder",
    defaultMessage: "Choose a category",
  },

  noCategory: {
    id: "filters.category.empty",
    defaultMessage: "No category found",
  },

  categoryError: {
    id: "filters.category.error",
    defaultMessage: "Error, can't load category data",
  },
});
