import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectLanguageCode } from "store/user/selectors";
import { UserData } from "models";
import { OwnerProps } from "../../types";
import { fetchUser } from "apis/CUMD";

const useOwnerInformation = ({ ownerId }: OwnerProps) => {
  const languageCode = useSelector(selectLanguageCode);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [owner, setOwner] = useState<UserData>({});

  const getOwnerInformation = async () => {
    if (!ownerId) {
      setOwner({});
      return;
    }

    setIsLoading(true);
    setHasError(false);

    try {
      const { data } = await fetchUser({
        id: ownerId,
      });

      setOwner(data);
    } catch (error) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getOwnerInformation();
  }, [ownerId, languageCode]);

  return { isLoading, hasError, owner };
};

export default useOwnerInformation;
