export const getInfoFromValue = (value: string) => {
  const [type, ...ids] = value.split("#");

  return {
    type,
    ids,
  };
};

export const isTypeField = (type: string) => type === "field";

export const isTypeSection = (type: string) => type === "section";
