import { State } from "./types";

const reducers = {
  setPackagingComponentName: (state: State, action) => {
    state.packagingComponent.name = action.payload;
  },
  setPackagingComponentUsageLevel: (state: State, action) => {
    state.packagingComponent.usageLevel = action.payload;
  },
};

export default reducers;
