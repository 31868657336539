import initialState from "./initialState";
import { State } from "./types";

const reducers = {
  setSpecificationId: (state: State, action) => {
    state.specification.draftId = action.payload;
  },
  resetSpecificationErrors: (state: State) => {
    state.errors = initialState.errors;
  },
  setIsInvitationStepValid: (state: State, action) => {
    state.isInvitationStepValid = action.payload;
  },
};

export default reducers;
