import { useState } from "react";
import { useTag } from "hooks";

const usePageHeadingInformation = (state: string = "", name: string = "") => {
  const [tabsInformation, setTabsInformation] = useState({});
  const [actionsInformation, setActionInformation] = useState({
    onClick: () => {},
    props: {
      disabled: false,
    },
  });
  const [secondaryActionInformation, setSecondaryActionInformation] = useState({
    onClick: () => {},
    props: {
      disabled: false,
    },
  });
  const { getTag } = useTag(state);
  const [headerInformation, setHeaderInformation] = useState({
    tag: getTag(state),
    title: name,
  });

  const updateTabsInformation = newTabInfo => {
    setTabsInformation(previousState => ({
      ...previousState,
      ...newTabInfo,
    }));
  };

  const updateActionsInformation = newActionInformation => {
    setActionInformation(previousState => ({
      ...previousState,
      ...newActionInformation,
    }));
  };

  const updateSecondaryActionInformation = newActionInformation => {
    setSecondaryActionInformation(previousState => ({
      ...previousState,
      ...newActionInformation,
    }));
  };

  const updateHeaderInformation = ({
    libraryItemName,
    libraryItemState,
  }: {
    libraryItemName: string;
    libraryItemState: string;
  }) => {
    setHeaderInformation({
      title: libraryItemName,
      tag: getTag(libraryItemState),
    });
  };

  return {
    actionsInformation,
    tabsInformation,
    headerInformation,
    updateTabsInformation,
    updateActionsInformation,
    updateHeaderInformation,
    secondaryActionInformation,
    updateSecondaryActionInformation,
  };
};

export default usePageHeadingInformation;
