import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Col, Row } from "antd";
import {
  Heading,
  Tooltip,
  Button,
  Paragraph,
  Badge,
} from "@trace-one/design-system";
import Status from "components/Status";
import Action from "./components/Action";
import { useRedirect } from "hooks";
import { getPercentValue } from "utils/general";
import { getVersionName } from "utils/specifications";
import { ROUTES } from "utils/constants";
import { messages } from "./messages";
import { SpecificationHeaderProps } from "./types";
import styles from "../../styles.module.less";

const SpecificationHeader: React.FC<SpecificationHeaderProps> = ({
  displayActions = true,
  id = null,
  lastVersion = false,
  progression = 0,
  state = null,
  ownerCompanyId,
  ownerCompanyName,
  supplierId,
  supplierName,
  specificationName,
  history,
  productTypeId,
  productData,
  version,
  ownerContacts,
  supplierContacts,
  selectedChapterType,
  selectedChapterOwnerCompanyId,
  isSelectedChapterLibraryChapter,
  isSelectedChapterLinked,
  isTargetMode,
  onTargetModeToggle,
  onOpenModificationRequestNotifyModal,
  permissionList,
}) => {
  const percent = useMemo(() => getPercentValue(progression), [progression]);
  const { redirectToSpecificationListing } = useRedirect();

  const versionName = getVersionName(version?.number);

  return (
    <div className={styles["specification-header__wrapper"]}>
      <div className={styles["specification-header"]}>
        <Row className="w-100" wrap={false}>
          <Button
            type="tertiary"
            iconName="long-arrow-left"
            onClick={redirectToSpecificationListing}
            className="mt-0-5"
          />
          <Row
            className="ml-2 w-100"
            justify="space-between"
            align="middle"
            wrap={false}
          >
            <Col className="overflow-hidden">
              <Row align="middle">
                <Badge count={versionName} />
                <Heading size="xs" className="text-ellipsis ml-1">
                  <Tooltip
                    text={specificationName}
                    placement="bottom"
                    getPopupContainer={(trigger: HTMLElement) =>
                      trigger?.parentElement?.parentElement?.parentElement
                        ?.parentElement
                    }
                    size="small"
                  >
                    {specificationName}
                  </Tooltip>
                </Heading>
              </Row>
              <Row className="mt-1" align="middle">
                {state && (
                  <div className="mr-3">
                    <Status.Tag state={state} />
                  </div>
                )}

                <>
                  <Heading size="xxs" className="mr-1">
                    <FormattedMessage {...messages.progress} />
                  </Heading>
                  <Paragraph variant={"body-regular"}>{percent}%</Paragraph>
                </>

                {ownerCompanyName ? (
                  <Heading className={"ml-3"} size="xxs">
                    <FormattedMessage {...messages.retailer} />
                    <Link
                      className="ml-1"
                      to={`${ROUTES.SPECIFICATION_LISTING}?serviceTypeId=${ownerCompanyId}&serviceTypeName=${ownerCompanyName}`}
                    >
                      {ownerCompanyName}
                    </Link>
                  </Heading>
                ) : null}

                {supplierName ? (
                  <Heading className={"ml-3"} size="xxs">
                    <FormattedMessage {...messages.supplier} />
                    <Link
                      className="ml-1"
                      to={`${ROUTES.SPECIFICATION_LISTING}?serviceTypeId=${supplierId}&serviceTypeName=${supplierName}`}
                    >
                      {supplierName}
                    </Link>
                  </Heading>
                ) : null}
              </Row>
            </Col>
            <Col className="ml-1">
              {displayActions ? (
                <Action
                  state={state}
                  id={id}
                  ownerCompanyId={ownerCompanyId}
                  supplierId={supplierId}
                  lastVersion={lastVersion}
                  history={history}
                  productTypeId={productTypeId}
                  productData={productData}
                  version={version}
                  ownerContacts={ownerContacts}
                  supplierContacts={supplierContacts}
                  selectedChapterType={selectedChapterType}
                  selectedChapterOwnerCompanyId={selectedChapterOwnerCompanyId}
                  isSelectedChapterLibraryChapter={
                    isSelectedChapterLibraryChapter
                  }
                  isSelectedChapterLinked={isSelectedChapterLinked}
                  isTargetMode={isTargetMode}
                  onTargetModeToggle={onTargetModeToggle}
                  onOpenModificationRequestNotifyModal={
                    onOpenModificationRequestNotifyModal
                  }
                  permissionList={permissionList}
                />
              ) : null}
            </Col>
          </Row>
        </Row>
      </div>
    </div>
  );
};

export default SpecificationHeader;
