import { useBoolean } from "hooks";
import FilterBar from "./components/FilterBar";
import Panel from "./components/Panel";

const MoreFilters = () => {
  const { value: isPanelOpen, onToggle } = useBoolean();

  return (
    <>
      <FilterBar isPanelOpen={isPanelOpen} onUpdatePanelVisibility={onToggle} />
      {isPanelOpen && <Panel />}
    </>
  );
};

export default MoreFilters;
