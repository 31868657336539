import Creation from "./components/Creation";
import Details from "./components/Details";
import List from "./components/List";
import Pdf from "./components/Pdf";
import WaitingDigitalSignature from "./components/WaitingDigitalSignature";

const Specification = {
  Creation,
  Details,
  List,
  Pdf,
  WaitingDigitalSignature,
};

export default Specification;
