import React from "react";
import { Modal, Row, Col } from "antd";
import ModalCloseIcon from "components/ModalCloseIcon";
import { useConfirmStepRedirectAction, useSelectSupplierModal } from "./hooks";
import ModalBody from "./components/ModalBody";
import { SelectSupplierModalProps } from "./types";
import styles from "./styles.module.less";

const SelectSupplierModal: React.FC<SelectSupplierModalProps> = ({
  closeSelectSupplierModal,
  specificationId,
  tradeItemId,
  tradeItemName,
  reloadListing,
}) => {
  const {
    isDuplicationInProgress,
    isConfirmedDuplicationStep,
    supplierListWithSuccess,
    supplierListWithError,
    onModalClose,
    onDuplicateSpecification,
  } = useSelectSupplierModal({
    closeSelectSupplierModal,
    specificationId,
  });

  const { onViewDetailsClick, onListingClick } = useConfirmStepRedirectAction({
    specificationId,
    onModalClose,
    reloadListing,
  });

  return (
    <Modal
      visible
      centered
      className={styles.modal}
      closeIcon={<ModalCloseIcon data-testid="closeDuplicationModalIcon" />}
      width="50%"
      footer={null}
      onCancel={onModalClose}
    >
      <Row justify="center" className={styles.modalContainer}>
        <Col xs={24} className={styles.wrapper}>
          <ModalBody
            tradeItemName={tradeItemName}
            supplierListWithSuccess={supplierListWithSuccess}
            supplierListWithError={supplierListWithError}
            isConfirmedDuplicationStep={isConfirmedDuplicationStep}
            isDuplicationInProgress={isDuplicationInProgress}
            tradeItemId={tradeItemId}
            onDuplicateSpecification={onDuplicateSpecification}
            onModalClose={onModalClose}
            onViewClick={onViewDetailsClick}
            onListingClick={onListingClick}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default SelectSupplierModal;
