import { createSelector } from "@reduxjs/toolkit";
import { isListEmpty } from "utils/general";
import { RootState } from "store";

/* istanbul ignore next */
export const _selectSpecificationCreation = (state: RootState) =>
  state.specificationCreation;

export const selectSpecification = createSelector(
  _selectSpecificationCreation,
  ({ specification }) => specification
);

export const selectChapters = createSelector(
  _selectSpecificationCreation,
  ({ chapters }) => chapters
);

export const selectSpecificationTemplate = createSelector(
  _selectSpecificationCreation,
  ({ template }) => template
);

export const selectHasError = createSelector(
  _selectSpecificationCreation,
  ({ hasError }) => hasError
);

export const selectIsSaving = createSelector(
  _selectSpecificationCreation,
  ({ isSaving }) => isSaving
);

export const selectIsCreationInProgress = createSelector(
  _selectSpecificationCreation,
  ({ isCreationInProgress }) => isCreationInProgress
);

export const selectIsSpecificationCheckInProgress = createSelector(
  _selectSpecificationCreation,
  ({ isSpecificationCheckInProgress }) => isSpecificationCheckInProgress
);

export const selectIsSpecificationFinalized = createSelector(
  _selectSpecificationCreation,
  ({ isSpecificationFinalized }) => isSpecificationFinalized
);

export const selectOwnerContacts = createSelector(
  _selectSpecificationCreation,
  ({ ownerContacts }) => ownerContacts
);

export const selectSelectedOwnerContacts = createSelector(
  _selectSpecificationCreation,
  ({ ownerContacts }) => ownerContacts.filter(({ isSelected }) => isSelected)
);

export const selectSelectedProduct = createSelector(
  _selectSpecificationCreation,
  ({ selectedProduct }) => selectedProduct
);

export const selectSelectedSupplier = createSelector(
  _selectSpecificationCreation,
  ({ selectedSupplier }) => selectedSupplier
);

export const selectOwnerUserDetails = createSelector(
  _selectSpecificationCreation,
  ({ ownerUserDetails }) => ownerUserDetails
);

export const selectExistingSpecifications = createSelector(
  _selectSpecificationCreation,
  ({ existingSpecifications }) => existingSpecifications
);

export const selectExistingSpecificationId = createSelector(
  _selectSpecificationCreation,
  ({ existingSpecificationId }) => existingSpecificationId
);

export const selectErrors = createSelector(
  _selectSpecificationCreation,
  ({ errors }) => errors
);

export const selectIsProductStepValid = createSelector(
  _selectSpecificationCreation,
  ({ specification }) =>
    !!specification.tradeItemId &&
    !!specification.productTypeId &&
    !!specification?.supplierId
);

export const selectIsInvitationStepValid = createSelector(
  _selectSpecificationCreation,
  ({ isInvitationStepValid }) => isInvitationStepValid
);

export const selectIsSpecificationStepValid = createSelector(
  _selectSpecificationCreation,
  ({ chapters }) => !isListEmpty(chapters)
);

export const selectProperStep = (state: RootState) => {
  const isProductStepCompleted = selectIsProductStepValid(state);
  const isInvitationStepCompleted = selectIsInvitationStepValid(state);
  const isSpecStepCompleted = selectIsSpecificationStepValid(state);

  const isSummaryStepCompleted =
    isProductStepCompleted && isInvitationStepCompleted && isSpecStepCompleted;

  return {
    isProductStepCompleted,
    isInvitationStepCompleted,
    isSpecStepCompleted,
    isSummaryStepCompleted,
  };
};

export const selectWizard = createSelector(
  _selectSpecificationCreation,
  ({ wizard }) => wizard
);
