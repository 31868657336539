import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import cleanDeep from "clean-deep";
import useBoolean from "hooks/useBoolean";
import { useAppDispatch } from "store";
import { updateCatalogDictionary } from "store/generalData/asyncActions";
import { selectCatalogDictionary } from "store/generalData/selectors";
import { selectLanguageCode } from "store/user/selectors";
import {
  fetchAllergensByIds,
  fetchClassificationByIds,
  fetchSubstanceFunctionsByIds,
} from "apis/CATALOGUE";
import { isListEmpty } from "utils/general";
import { CatalogListItemData } from "models";
import { Dictionary } from "types/general";

const useCatalogItems = ({
  catalogIds,
  catalogAllergenIds,
  catalogClassificationIds,
  shouldFilterIds = true,
}: {
  catalogIds?: string[];
  catalogAllergenIds?: string[];
  catalogClassificationIds?: string[];
  shouldFilterIds?: boolean;
}) => {
  const [catalogItems, setCatalogItems] = useState<CatalogListItemData[]>([]);

  const dispatch = useAppDispatch();
  const languageCode = useSelector(selectLanguageCode);
  const catalogDictionary = useSelector(selectCatalogDictionary);

  const { value: isLoading, setTrue, setFalse } = useBoolean(false);

  const prepareCatalogDictionary = (list: CatalogListItemData[]) =>
    list.reduce(
      (previousState, { id, name }) => ({
        ...previousState,
        [id]: name,
      }),
      {}
    ) as Dictionary;

  const getFilteredIds = (ids: string[]) =>
    shouldFilterIds ? ids?.filter(id => !catalogDictionary?.[id]) : ids;

  const getCatalogItems = async (catalogItemIds: string[]) => {
    try {
      setTrue();

      const { data } = await fetchSubstanceFunctionsByIds({
        substanceFunctionIds: cleanDeep(catalogItemIds),
        languageCode,
      });

      setCatalogItems(data);

      dispatch(updateCatalogDictionary(prepareCatalogDictionary(data)));
    } catch (error) {
      setCatalogItems([]);
    } finally {
      setFalse();
    }
  };

  const getCatalogAllergens = async (catalogAllergenItemIds: string[]) => {
    try {
      setTrue();

      const { data } = await fetchAllergensByIds({
        allergenIds: cleanDeep(catalogAllergenItemIds),
        languageCode,
      });

      setCatalogItems(data);

      dispatch(updateCatalogDictionary(prepareCatalogDictionary(data)));
    } catch (error) {
      setCatalogItems([]);
    } finally {
      setFalse();
    }
  };

  const getCatalogClassificationIds = async (
    catalogClassificationIds: string[]
  ) => {
    try {
      setTrue();

      const { data } = await fetchClassificationByIds({
        rawMaterialClassificationIds: cleanDeep(catalogClassificationIds),
        languageCode,
      });

      setCatalogItems(data);

      dispatch(updateCatalogDictionary(prepareCatalogDictionary(data)));
    } catch {
      setCatalogItems([]);
    } finally {
      setFalse();
    }
  };

  useEffect(() => {
    const filteredIds = getFilteredIds(catalogIds);

    if (!isListEmpty(filteredIds)) {
      getCatalogItems(filteredIds);
    }
  }, [JSON.stringify(catalogIds)]);

  useEffect(() => {
    const filteredIds = getFilteredIds(catalogAllergenIds);

    if (!isListEmpty(filteredIds)) {
      getCatalogAllergens(filteredIds);
    }
  }, [JSON.stringify(catalogAllergenIds)]);

  useEffect(() => {
    const filteredIds = getFilteredIds(catalogClassificationIds);

    if (!isListEmpty(filteredIds)) {
      getCatalogClassificationIds(filteredIds);
    }
  }, [catalogClassificationIds]);

  return {
    catalogDictionary,
    catalogItems,
    isLoading,
  };
};

export default useCatalogItems;
