import { AxiosRequestConfig } from "axios";
import { axiosInstance } from ".";
import { LibraryObjectData } from "models";
import { ClaimData, ClaimSentenceTranslation, ClaimType } from "models/claim";
import { PaginatedList, PaginationParams } from "types/api";

const baseUrl = "/api/marketing-claims";

export const fetchClaimTypes = ({ languageCode }: { languageCode: string }) =>
  axiosInstance.get<ClaimType[]>(`/api/claim-types`, {
    params: {
      languageCode,
    },
  });

export const createOrPublishClaim = ({
  payload,
  params,
}: {
  payload: {
    name: ClaimData["name"];
    claimType: ClaimData["claimType"];
    productTypeId: ClaimData["productTypeId"];
    sentenceTranslations: ClaimSentenceTranslation[];
    logoFileId: ClaimData["logoFileId"];
    tagIds?: ClaimData["tagIds"];
    requiresSupportingDocuments?: ClaimData["requiresSupportingDocuments"];
  };
  params: {
    publish?: boolean;
  };
}) => axiosInstance.post<ClaimData>(`${baseUrl}`, payload, { params });

export const saveOrPublish = ({
  id,
  payload,
  params,
}: {
  id: ClaimData["id"];
  payload: {
    name?: ClaimData["name"];
    sentenceTranslations: ClaimSentenceTranslation[];
    logoFileId: ClaimData["logoFileId"];
    tagIds: ClaimData["tagIds"];
    requiresSupportingDocuments?: ClaimData["requiresSupportingDocuments"];
  };
  params: {
    publish?: boolean;
  };
}) =>
  axiosInstance.put<ClaimData>(`${baseUrl}/${id}`, payload, {
    params,
  });

export const fetchClaims = ({
  params,
  paramsSerializer,
}: {
  params: PaginationParams & {
    ownerCompanyId: ClaimData["ownerCompanyId"];
    name?: ClaimData["name"];
    productTypeId?: ClaimData["productTypeId"];
    excludeDrafts?: boolean;
  };
  paramsSerializer?: AxiosRequestConfig["paramsSerializer"];
}) =>
  axiosInstance.get<PaginatedList<LibraryObjectData>>(`${baseUrl}/search`, {
    params,
    paramsSerializer,
  });

export const fetchClaim = ({
  id,
  languageCode,
}: {
  id: ClaimData["id"];
  languageCode: string;
}) =>
  axiosInstance.get<ClaimData>(`${baseUrl}/${id}`, {
    params: { languageCode },
  });

export const fetchClaimCollection = ({
  payload,
}: {
  payload: {
    claimIds: ClaimData["id"][];
  };
}) => axiosInstance.post<ClaimData[]>(`${baseUrl}/collection`, payload);
