import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectLanguageCode } from "store/user/selectors";
import { fetchReferenceListItems } from "apis/RLMD";
import { isListEmpty, getDictionary, isObjectEmpty } from "utils/general";
import { REFLIST_IDS } from "utils/constants";
import { useOptimizationCCPProps, CCPTableNames } from "../types";

const useOptimizationCCP = ({
  formData,
  tableName,
}: useOptimizationCCPProps) => {
  const [refListDictionary, setRefListDictionary] = useState({});
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [controlPhases, setControlPhases] = useState([]);

  const languageCode = useSelector(selectLanguageCode);

  useEffect(() => {
    const getControlPhases = async () => {
      let phases;
      try {
        const { data } = await fetchReferenceListItems({
          languageCode,
          id: REFLIST_IDS.CONTROL_PHASES,
        });

        phases = data.referenceListItems;

        setControlPhases(data.referenceListItems);
      } catch (_) {
      } finally {
        return phases;
      }
    };

    const getControlTypes = async () => {
      let types;
      try {
        const { data } = await fetchReferenceListItems({
          languageCode,
          id: REFLIST_IDS.CONTROL_TYPES,
        });

        types = data.referenceListItems;
      } catch (_) {
      } finally {
        return types;
      }
    };

    const fetchTypesAndPhases = async () => {
      await Promise.all([getControlPhases(), getControlTypes()]).then(
        result => {
          const [phases, types] = result;

          setRefListDictionary(
            getDictionary({
              data: [...phases, ...types],
              key: "id",
              value: "text",
            })
          );
          setIsInitialLoading(false);
        }
      );
    };

    if (isListEmpty(formData) && tableName === CCPTableNames.Characteristic) {
      setIsInitialLoading(false);
    }

    if (!isListEmpty(formData) && isObjectEmpty(refListDictionary)) {
      fetchTypesAndPhases();
    }
  }, [languageCode, JSON.stringify(formData), refListDictionary]);

  return {
    isInitialLoading,
    refListDictionary,
    controlPhases,
  };
};

export default useOptimizationCCP;
