import { useEffect, useState } from "react";
import cx from "classnames";
import { Heading, Paragraph, Modal } from "@trace-one/design-system";
import { FormattedMessage } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import ConfirmedSharingModal from "components/ConfirmedSharingModal";

import {
  startConfirmedModal,
  getShareButtonTranslationKey,
  shouldErrorMessageBeDisplayed,
} from "pages/utils";
import {
  onCreateClick,
  getOnViewClick,
  getStartConfirmedSharingModal,
  getShareSpecification,
} from "./utils";
import { ShareSpecificationModalProps } from "./types";
import { messages } from "./messages";
import styles from "./styles.module.less";

const ShareSpecificationModal: React.FC<ShareSpecificationModalProps> = ({
  closeShareSpecificationModal,
  specificationId,
  state,
}) => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const [isDone, setIsDone] = useState(false);
  const [isSharing, setIsSharing] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [sharedSpecificationId, setSharedSpecificationId] = useState("");
  const [
    isConfirmedSharignModalOpen,
    setIsConfirmedSharignModalOpen,
  ] = useState(false);

  useEffect(() => {
    startConfirmedModal({
      isDone,
      isSharing,
      hasError,
      startConfirmedSharingModal: getStartConfirmedSharingModal({
        setIsConfirmedSharignModalOpen,
      }),
    });
  }, [isSharing, hasError, isDone]);

  return (
    <>
      {isConfirmedSharignModalOpen ? (
        <ConfirmedSharingModal
          type="specification"
          onCreateClick={onCreateClick({ push })}
          onViewClick={getOnViewClick({
            push,
            specificationId: sharedSpecificationId,
            pathname,
          })}
        />
      ) : (
        <Modal.Simple
          open
          onCancel={closeShareSpecificationModal}
          wrapClassName={styles.shareSpecificationModal}
          illustrationName="confirmation"
          illustrationColor="grey"
          title={<FormattedMessage {...messages.title} />}
          primaryButtonText={
            <FormattedMessage
              {...messages[getShareButtonTranslationKey({ isSharing })]}
            />
          }
          onPrimaryButtonClick={getShareSpecification({
            specificationId,
            setIsDone,
            setIsSharing,
            setHasError,
            setSharedSpecificationId,
            state,
          })}
          primaryButtonProps={{ disabled: isSharing }}
          secondaryButtonText={<FormattedMessage {...messages.noBtn} />}
          onSecondaryButtonClick={closeShareSpecificationModal}
        >
          <div className={styles.modalBody}>
            <Paragraph className={cx(styles.content, styles.warningMessage)}>
              <Paragraph>
                <FormattedMessage {...messages.warningMessage} />
                &nbsp;:
              </Paragraph>
              <ul>
                <li>
                  <FormattedMessage {...messages.firstWarning} />
                </li>
                <li>
                  <FormattedMessage {...messages.secondWarning} />
                </li>
              </ul>
            </Paragraph>
            <div className={styles.desc}>
              <Heading size="xs">
                <FormattedMessage {...messages.shareQuestion} />
              </Heading>

              {shouldErrorMessageBeDisplayed({ hasError, isDone }) && (
                <div data-testid="errorMessage" className={styles.errorMessage}>
                  <FormattedMessage {...messages.errorMessage} />
                </div>
              )}
            </div>
          </div>
        </Modal.Simple>
      )}
    </>
  );
};

export default ShareSpecificationModal;
