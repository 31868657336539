import { Contact } from "@trace-one/react-components";
import { UserCard } from "@trace-one/design-system";
import { getBadgeName } from "./utils";

const ContactWidget = ({ actions, formData, userData, formContext }) => {
  const { specificationData } = formContext;
  const { signatory, userId } = formData;

  const userProps = {
    name: `${userData?.userFirstName} ${userData?.userLastName}`,
    jobTitle: userData?.userJobTitle,
    phone: userData?.userPhone,
    notificationEmail: userData?.userNotificationEmail,
    photoUrl: userData?.userPhotoUrl,
  };

  if (!actions) {
    const userCardProps = {
      ...userProps,
      title: userProps.jobTitle,
      email: userProps.notificationEmail,
    };

    return <UserCard {...userCardProps} />;
  }

  return (
    <Contact.Card
      actions={actions}
      isSelected={signatory}
      badge={getBadgeName({
        ownerUserId: specificationData?.ownerUserId,
        signatory,
        userId,
      })}
      {...userProps}
    />
  );
};

export default ContactWidget;
