import React from "react";
import { Row, Col } from "antd";
import { Picker } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import { useStatuses } from "./hooks";
import statusMessages from "messages/statuses";
import { StatusesProps } from "./types";
import styles from "./styles.module.less";

const Statuses: React.FC<StatusesProps> = props => {
  const { statuses } = props;
  const intl = useIntl();

  const { isStatusSelected, onStatusClick, onStatusRemove } = useStatuses(
    props
  );

  return (
    <Row gutter={[8, 10]}>
      {statuses.map(status => (
        <Col key={status}>
          <Picker
            className={styles.status}
            title={intl.formatMessage(statusMessages[status])}
            data-test-id={`${status}-status-btn`}
            {...(isStatusSelected(status)
              ? {
                  selected: true,
                  onClick: onStatusRemove(status),
                }
              : {
                  onClick: onStatusClick({
                    value: status,
                    label: intl.formatMessage(statusMessages[status]),
                  }),
                })}
          />
        </Col>
      ))}
    </Row>
  );
};

Statuses.defaultProps = {
  selectedStatuses: [],
  statuses: [],
};

export default Statuses;
