import { useAppDispatch } from "store";
import {
  updateCurrentStep,
  validateStep,
} from "store/specificationCreation/asyncActions";
import { useStepper } from "hooks";
import { SPECIFICATION_WIZARD_STEPS } from "utils/constants";
import { WizardData } from "models";

const useSteps = ({
  currentStep,
  stepsState,
}: {
  currentStep: string;
  stepsState: WizardData["state"];
}) => {
  const dispatch = useAppDispatch();

  const onValidateStep = async () => {
    await dispatch(validateStep());
  };

  const onUpdateStep = async (step: string) => {
    await dispatch(updateCurrentStep(step));
  };

  const {
    currentIsValid,
    stepIndex,
    steps,
    onNextStep,
    onPreviousStep,
  } = useStepper({
    currentStep,
    steps: SPECIFICATION_WIZARD_STEPS,
    stepsState,
    onValidateStep,
    onUpdateStep,
  });

  return { stepIndex, currentIsValid, steps, onNextStep, onPreviousStep };
};

export default useSteps;
