import { RefObject, useEffect, useState } from "react";
import { debounce } from "lodash";
import { isListEmpty } from "utils/general";
import { getCurrentRefWidth, getExtraWidth } from "./utils";
import { Element } from "./types";

const useMoreInfo = ({
  elements,
  moreInfoRef,
  elementListRefs,
  elementsRef,
  wrapperRef,
  elementExtraWidth = 0,
}: {
  elements: Element[];
  moreInfoRef: RefObject<HTMLDivElement>;
  elementListRefs: RefObject<HTMLDivElement>[];
  elementsRef: RefObject<HTMLDivElement>;
  wrapperRef: RefObject<HTMLDivElement>;
  elementExtraWidth?: number;
}) => {
  const [currentElements, setCurrentElements] = useState(elements);
  const [moreInfoElements, setMoreInfoElements] = useState<Element[]>([]);
  const [finalIndex, setFinalIndex] = useState(null);

  useEffect(() => {
    setFinalIndex(null);
  }, [elements.length]);

  const prepareElements = () => {
    const wrapperWidth = getCurrentRefWidth(wrapperRef.current);
    const moreInfoWidth = getCurrentRefWidth(moreInfoRef.current);
    const elementsWidth = getCurrentRefWidth(elementsRef.current);
    const extraWidth = getExtraWidth(elements);

    if (wrapperWidth < elementsWidth) {
      let actualWidth = 0;
      let desiredWidth = wrapperWidth - moreInfoWidth - extraWidth;

      if (!isListEmpty(elementListRefs)) {
        for (let index = 0; index < elementListRefs.length; index++) {
          const ref = elementListRefs[index];
          const currentRefWidth =
            getCurrentRefWidth(ref.current) + elementExtraWidth;

          if (actualWidth + currentRefWidth >= desiredWidth) {
            setFinalIndex(index - 1);
            break;
          }

          actualWidth += currentRefWidth;
        }
      }
    }
  };

  const debouncedPrepareElements = debounce(() => {
    prepareElements();
  }, 500);

  useEffect(() => {
    prepareElements();
  }, [elementListRefs.length]);

  useEffect(() => {
    window.addEventListener("resize", debouncedPrepareElements);

    return () => window.removeEventListener("resize", debouncedPrepareElements);
  });

  useEffect(() => {
    if (finalIndex !== null) {
      setMoreInfoElements(elements.slice(finalIndex + 1));
      setCurrentElements(elements.slice(0, finalIndex + 1));
    } else {
      setMoreInfoElements([]);
      setCurrentElements(elements);
    }
  }, [finalIndex, elements.length]);

  return {
    currentElements,
    moreInfoElements,
  };
};

export default useMoreInfo;
