export const getOnItemChange = ({
  onChange,
  setActualForm,
  setIsDatePickerOpen,
}: {
  onChange: Function;
  setActualForm: Function;
  setIsDatePickerOpen: Function;
}) => (value: string) => {
  const newValue = value && value.trim() !== "" ? value : null;
  onChange(newValue);
  setActualForm(newValue);
  setIsDatePickerOpen(false);
};

export const isValidDate = ({ value }: { value: string }) => {
  const date_regex = /^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/;
  return date_regex.test(value);
};
