import Details from "./components/Details";
import Products from "./components/Products";
import Invitation from "./components/Invitation";
import Specifications from "./components/Specifications";
import Summary from "./components/Summary";
import { withDraftValidation, withProperStepValidation } from "./hocs";
import { WIZARD_STEPS } from "utils/constants";

const Creation = {
  Details: withDraftValidation(
    withProperStepValidation(WIZARD_STEPS.FOLDER)(Details)
  ),
  Products: withDraftValidation(
    withProperStepValidation(WIZARD_STEPS.PRODUCT)(Products)
  ),
  Invitation: withDraftValidation(
    withProperStepValidation(WIZARD_STEPS.INVITATION)(Invitation)
  ),
  Specifications: withDraftValidation(
    withProperStepValidation(WIZARD_STEPS.SPECIFICATION)(Specifications)
  ),
  Summary: withDraftValidation(
    withProperStepValidation(WIZARD_STEPS.SUMMARY)(Summary)
  ),
};

export default Creation;
