import { createContext } from "react";
import { SpecificationListItemViemModel } from "viewModels/specificationList";

export interface ResultTableContextProps {
  currentPage: number;
  searchSpecifications: Function;
  resetCurrentPage: Function;
  specifications: SpecificationListItemViemModel[];
  setShouldRefetchSpecifications: Function;
}

const ResultTableContext = createContext<ResultTableContextProps>({
  currentPage: 1,
  searchSpecifications: () => {},
  resetCurrentPage: () => {},
  specifications: [],
  setShouldRefetchSpecifications: () => {},
});

export default ResultTableContext;
