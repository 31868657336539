import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { searchSpecificationList } from "apis/SPEC";
import { selectLanguageCode } from "store/user/selectors";
import { prepareStatusesForFilters } from "utils/specifications";
import usePagination from "../usePagination";
import {
  FiltersViewModel,
  SpecificationListItemViemModel,
} from "viewModels/specificationList";
import axios, { CancelTokenSource } from "axios";

const useSpecificationPaginatedList = () => {
  const languageCode = useSelector(selectLanguageCode);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [specifications, setSpecifications] = useState<
    SpecificationListItemViemModel[]
  >([]);
  const [
    totalNumberOfSpecifications,
    setTotalNumberOfSpecifications,
  ] = useState(0);
  const [filters, setFilters] = useState<FiltersViewModel>({
    statuses: [],
    productTypeIds: [],
    ownerOrOwnerContactIds: [],
    ownerCompanyIds: [],
    specificationName: "",
    mySpecificationsOnly: false,
    supplierIds: [],
    categoryId: "",
  });
  const [source, setSource] = useState<CancelTokenSource>();
  const [
    shouldRefetchSpecifications,
    setShouldRefetchSpecifications,
  ] = useState<boolean>(false);

  const {
    pagination,
    sorter,
    onTableChange,
    resetCurrentPage,
  } = usePagination();

  const searchSpecifications = async () => {
    try {
      setIsLoading(true);
      setHasError(false);

      if (source) {
        source.cancel("cancel");
      }

      const newSource = axios.CancelToken.source();

      setSource(newSource);

      const { current, pageSize } = pagination;
      const skip = current <= 1 ? 0 : (current - 1) * pageSize;

      const { data } = await searchSpecificationList({
        params: {
          take: pageSize,
          skip: skip,
          languageCode,
          orderBy: sorter.orderBy,
          orderByDescending: sorter.orderByDescending,
          tradeItemName: filters?.specificationName,
          mySpecificationsOnly: filters?.mySpecificationsOnly,
          categoryItemId: filters?.categoryItemId,
        },
        data: {
          supplierIds: filters?.supplierIds,
          ownerOrContactIds: filters?.ownerOrOwnerContactIds,
          ownerCompanyIds: filters?.ownerCompanyIds,
          states: prepareStatusesForFilters(filters?.statuses),
          productTypeIds: filters?.productTypeIds,
        },
        cancelToken: newSource?.token,
      });

      setSpecifications(data.items);
      setTotalNumberOfSpecifications(data.totalNumberOfItems);
    } catch (e) {
      if (e?.message !== "cancel") {
        setHasError(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onUpdateFilters = (newFilters: FiltersViewModel) => {
    setFilters(previousState => ({
      ...previousState,
      ...newFilters,
    }));

    resetCurrentPage();
  };

  useEffect(() => {
    searchSpecifications();
  }, [
    pagination.current,
    pagination.pageSize,
    sorter.orderBy,
    sorter.orderByDescending,
    filters?.specificationName,
    filters?.ownerOrOwnerContactIds?.length,
    filters?.ownerCompanyIds?.length,
    filters?.supplierIds?.length,
    filters?.productTypeIds?.length,
    filters?.statuses?.length,
    filters?.mySpecificationsOnly,
    filters?.categoryId,
    filters?.categoryItemId,
  ]);

  useEffect(() => {
    if (shouldRefetchSpecifications) {
      searchSpecifications();
      setShouldRefetchSpecifications(false);
    }
  }, [shouldRefetchSpecifications]);

  return {
    specifications,
    totalNumberOfSpecifications,
    filters,
    isLoading,
    hasError,
    pagination,
    onTableChange,
    onUpdateFilters,
    resetCurrentPage,
    searchSpecifications,
    setShouldRefetchSpecifications,
  };
};

export default useSpecificationPaginatedList;
