import { useIntl } from "react-intl";
import { useRedirect } from "hooks";
import { PackagingSystemComponentData } from "models/packaging";
import { messages } from "messages/packaging";
import { PackagingSystemTableContext } from "components/SpecificationSection/components/FormSchema/widgets/PackagingSystemWidget/contexts";
import { useContext } from "react";

const useActions = (
  packagingComponent: PackagingSystemComponentData
): {
  key?: string;
  label?: React.ReactNode;
  onClick?: Function;
  "data-test-id"?: string;
}[] => {
  const { formatMessage } = useIntl();
  const { redirectToPackagingComponentEdit } = useRedirect();
  const { specificationData, chapterData } = useContext(
    PackagingSystemTableContext
  );

  const { componentId: packagingComponentId } = packagingComponent;
  const queryParameters = {
    specificationId: specificationData?.specificationId,
    sectionId: chapterData?.chapterType,
  };

  const actions = [
    {
      key: formatMessage(messages.packagingComponentActionsEdit),
      label: formatMessage(messages.packagingComponentActionsEdit),
      onClick: () =>
        redirectToPackagingComponentEdit({
          packagingComponentId,
          queryParameters,
        }),
      "data-test-id": "edit-packaging-system-component",
    },
  ];

  return actions;
};

export default useActions;
