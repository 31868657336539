import {
  FolderData,
  FolderListItemData,
  FolderSpecificationData,
  RequirementAssocationData,
  SpecificationData,
  SpecificationDraftData,
  WizardData,
} from "models";
import { API_VERSION } from "apis/constants";
import { Permissions } from "types/general";
import { axiosInstance } from "./index";
import { FolderSearchBody, PaginatedList, PaginationParams } from "types/api";

export const fetchFolder = ({
  id,
  languageCode,
}: {
  id: FolderData["folderId"];
  languageCode: string;
}) =>
  axiosInstance.get<FolderData>(`/api/${API_VERSION}folders/${id}`, {
    params: {
      languageCode,
    },
  });

export const fetchFolderQuickView = ({ id }: { id: FolderData["folderId"] }) =>
  axiosInstance.get<FolderSpecificationData[]>(
    `/api/${API_VERSION}folders/${id}/quick-view`
  );

export const fetchFolderSpecifications = ({
  id,
}: {
  id: FolderData["folderId"];
}) =>
  axiosInstance.get<FolderSpecificationData[]>(
    `/api/${API_VERSION}folders/${id}/specifications`
  );

export const fetchFolderWizard = ({
  folderId,
}: {
  folderId: FolderData["folderId"];
}) =>
  axiosInstance.get<WizardData>(
    `/api/${API_VERSION}folders/${folderId}/wizard`
  );

export const createFolder = () =>
  axiosInstance.post<FolderData>(`/api/${API_VERSION}folders`);

export const createFolderWizard = ({
  folderId,
}: {
  folderId: FolderData["folderId"];
}) =>
  axiosInstance.post<WizardData>(
    `/api/${API_VERSION}folders/${folderId}/wizard`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

export const updateFolderProductType = ({
  folderId,
  productTypeId,
}: {
  folderId: FolderData["folderId"];
  productTypeId?: FolderData["productTypeId"];
}) =>
  axiosInstance.put<FolderData>(
    `/api/${API_VERSION}folders/${folderId}/product-type`,
    productTypeId,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

export const updateFolderTemplate = ({
  folderId,
  templateId,
}: {
  folderId: FolderData["folderId"];
  templateId?: FolderData["templateId"];
}) =>
  axiosInstance.put<FolderData>(
    `/api/${API_VERSION}folders/${folderId}/template`,
    templateId,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

export const createFolderProducts = ({
  folderId,
  tradeItemIds,
}: {
  folderId: FolderData["folderId"];
  tradeItemIds: FolderData["tradeItemIds"];
}) =>
  axiosInstance.post<FolderData>(
    `/api/${API_VERSION}folders/${folderId}/trade-items`,
    tradeItemIds,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

export const updateFolderRequirements = ({
  folderId,
  requirementAssociations,
}: {
  folderId: FolderData["folderId"];
  requirementAssociations: RequirementAssocationData[];
}) =>
  axiosInstance.put<FolderData>(
    `/api/${API_VERSION}folders/${folderId}/requirements`,
    requirementAssociations
  );

export const updateFolderName = ({
  id,
  name,
}: {
  id: FolderData["folderId"];
  name: FolderData["folderName"];
}) =>
  axiosInstance.put<FolderData>(`/api/${API_VERSION}folders/${id}/name`, name, {
    headers: {
      "Content-Type": "application/json",
    },
  });

export const updateFolderDescription = ({
  id,
  description,
}: {
  id: FolderData["folderId"];
  description: FolderData["folderDescription"];
}) =>
  axiosInstance.put<FolderData>(
    `/api/${API_VERSION}folders/${id}/description`,
    description,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

export const updateFolderSpecificationNote = ({
  id,
  specificationNote,
}: {
  id: FolderData["folderId"];
  specificationNote: FolderData["specificationNote"];
}) =>
  axiosInstance.put<FolderData>(
    `/api/${API_VERSION}folders/${id}/specification-note`,
    specificationNote,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

export const updateFolderTargetDate = ({
  id,
  targetDateUtc,
}: {
  id: FolderData["folderId"];
  targetDateUtc: FolderData["targetDateUtc"];
}) =>
  axiosInstance.put<FolderData>(
    `/api/${API_VERSION}folders/${id}/target-date`,
    targetDateUtc,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

export const updateFolderFilesApi = ({
  id,
  payload,
}: {
  id: FolderData["folderId"];
  payload: SpecificationDraftData["files"];
}) =>
  axiosInstance.put<FolderData>(
    `/api/${API_VERSION}folders/${id}/files`,
    payload,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

export const addFolderContact = ({
  folderId,
  userId,
}: {
  folderId: FolderData["folderId"];
  userId: FolderData["ownerUserId"];
}) =>
  axiosInstance.post<FolderData>(
    `/api/${API_VERSION}folders/${folderId}/contacts`,
    userId,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

export const deleteFolder = ({ id }: { id: FolderData["folderId"] }) =>
  axiosInstance.delete(`/api/${API_VERSION}folders/${id}`);

export const deleteFolderContact = ({
  folderId,
  userId,
}: {
  folderId: FolderData["folderId"];
  userId: FolderData["ownerUserId"];
}) =>
  axiosInstance.delete<FolderData>(
    `/api/${API_VERSION}folders/${folderId}/contacts/${userId}`
  );

export const deleteFolderFile = ({
  folderId,
  fileId,
}: {
  folderId: FolderData["folderId"];
  fileId: string;
}) =>
  axiosInstance.delete<FolderData>(
    `/api/${API_VERSION}folders/${folderId}/files/${fileId}`
  );

export const deleteFolderProduct = ({
  folderId,
  tradeItemId,
}: {
  folderId: FolderData["folderId"];
  tradeItemId: string;
}) =>
  axiosInstance.delete<FolderData>(
    `/api/${API_VERSION}folders/${folderId}/trade-items/${tradeItemId}`
  );

export const updateFolderSpecification = ({
  specificationId,
  folderId = null,
}: {
  specificationId: SpecificationData["id"];
  folderId?: FolderData["folderId"];
}) => {
  axiosInstance.put<void>(
    `/api/${API_VERSION}specifications/${specificationId}/folder`,
    {
      folderId,
    }
  );
};

export const shareFolder = ({ id }: { id: FolderData["folderId"] }) =>
  axiosInstance.post<FolderData>(`/api/${API_VERSION}folders/${id}/share`);

export const searchFolderList = ({
  params,
  data,
}: {
  data: FolderSearchBody;
  params: PaginationParams & {
    languageCode: string;
    folderName?: FolderData["folderName"];
  };
}) =>
  axiosInstance.post<PaginatedList<FolderListItemData>>(
    `/api/${API_VERSION}folders/search`,
    data,
    {
      params,
    }
  );

export const fetchFolderPermissions = ({
  folderId,
}: {
  folderId: FolderData["folderId"];
}) =>
  axiosInstance.get<Permissions>(
    `/api/${API_VERSION}folders/${folderId}/permissions`
  );

export const createFolderCollaboration = ({
  folderId,
  collaborationId,
}: {
  folderId: FolderData["folderId"];
  collaborationId: string;
}) =>
  axiosInstance.post<FolderData>(
    `/api/${API_VERSION}folders/${folderId}/collaborations`,
    collaborationId,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

export const deleteFolderCollaboration = ({
  folderId,
  collaborationId,
}: {
  folderId: FolderData["folderId"];
  collaborationId: string;
}) =>
  axiosInstance.delete<FolderData>(
    `/api/${API_VERSION}folders/${folderId}/collaborations/${collaborationId}`
  );
