import classNames from "classnames";
import { Heading, Paragraph, Tag } from "@trace-one/design-system";
import { Row, Col } from "antd";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import CategoriesListing from "../CategoriesListing";
import NetContentsListingReadOnly from "./components/NetContentsListingReadOnly";
import SeasonalityWidgetReadOnly from "./components/SeasonalityWidgetReadOnly";
import styles from "./styles.module.less";
import { ProductWidgetReadOnlyProps } from "./types";
import { getProductWidgetFields } from "./utils";

const ProductWidgetReadOnly: React.FC<ProductWidgetReadOnlyProps> = ({
  formContext,
  formData,
  productData,
  refListDictionary,
  typeValue,
}) => {
  const { emptyValue } = formContext;
  const { getTranslationForKey } = useFormSchemaTranslation();

  const {
    productCategories,
    netContents,
    itemName,
    brandName,
    seasonalAvailabilityStartDate,
    seasonalAvailabilityEndDate,
  } = productData;

  const seasonalityProps = {
    startDateUtc: seasonalAvailabilityStartDate,
    endDateUtc: seasonalAvailabilityEndDate,
  };

  const rowProps = {
    gutter: 10,
    className: styles.noPageBreak,
  };

  const productIdFields = getProductWidgetFields([
    "gtin",
    "ean7",
    "internalGencode",
  ]);

  const packagingFields = getProductWidgetFields([
    "dryWeight",
    "mainPackaging",
    "packagingType",
  ]);

  const productHeading =
    itemName && brandName
      ? `${itemName} - ${brandName}`
      : itemName
      ? itemName
      : brandName
      ? brandName
      : "";

  const seasonalityTitleTranslation = getTranslationForKey("seasonalityTitle");

  return (
    <div className={styles.productionReadOnlyContainer}>
      <Row
        gutter={[2, 10]}
        className={classNames(styles.noPageBreak, styles.productName)}
      >
        <Heading size="xxs" color="grey-5">
          {productHeading}
        </Heading>
      </Row>
      <Row {...rowProps}>
        <Col>
          <Tag
            label={`${getTranslationForKey(
              "templateTypeIdTitle"
            )}: ${typeValue}`}
            size="medium"
            color="grey"
            mode="light"
          />
        </Col>
        <CategoriesListing
          categories={productCategories}
          title={getTranslationForKey("categoryTitle")}
        />
      </Row>

      <Row {...rowProps} className={styles.productIds}>
        <Col span={24}>
          <Row {...rowProps} justify="start">
            {productIdFields.map(field => {
              const fieldTitleTranslation = getTranslationForKey(field.title);
              return (
                <Col key={field.name} span={6}>
                  <Paragraph size="xs" color="grey-5">
                    {fieldTitleTranslation}
                  </Paragraph>
                </Col>
              );
            })}
          </Row>
          <Row justify="start" {...rowProps}>
            {productIdFields.map(field => (
              <Col key={field.name} span={6}>
                <Paragraph size="xs" color="grey-5">
                  {!!productData[field.name]
                    ? productData[field.name]
                    : emptyValue}
                </Paragraph>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>

      <Row {...rowProps}>
        <Col xs={6} className={styles.packagingFields}>
          {packagingFields.map(field => {
            const fieldTitleTranslation = getTranslationForKey(field.title);
            return (
              <div key={field.name}>
                <Row>
                  <Paragraph size="xs" color="grey-5">
                    {fieldTitleTranslation}
                  </Paragraph>
                </Row>
                <Row>
                  <Paragraph size="xs" color="grey-5">
                    {!!formData[field.name] &&
                    typeof formData[field.name] !== "object"
                      ? formData[field.name]
                      : emptyValue}
                  </Paragraph>
                </Row>
              </div>
            );
          })}
        </Col>
        <Col xs={6}>
          <NetContentsListingReadOnly
            netContents={netContents}
            refListDictionary={refListDictionary}
            title={getTranslationForKey("netContentTitle")}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Paragraph size="xs" color="grey-5">
            {seasonalityTitleTranslation}
          </Paragraph>
          <SeasonalityWidgetReadOnly {...seasonalityProps} />
        </Col>
      </Row>
    </div>
  );
};

export default ProductWidgetReadOnly;
