import { State } from "./types";

const initialState: State = {
  folder: {
    folderId: "",
  },
  template: {
    generalInfo: {},
    chapters: [],
  },
  contacts: [],
  isSaving: false,
  isSharing: false,
  hasError: false,
  isCreationInProgress: false,
  isResumeInProgress: false,
  isFolderFinalized: false,
  isFolderCheckInProgress: false,
  isInvitationStepValid: false,
  areCollaborationsLoading: false,
  errors: {
    folderName: false,
    folderDescription: false,
    folderProductType: false,
    targetDateUtc: false,
    addProducts: false,
    addProductSupplier: {},
    addProductSpecifications: {},
    removeProduct: {},
    removeProductSpecifications: {},
    specificationNote: false,
    sections: {},
    addRequirements: false,
    addCollaboration: false,
    removeCollaborations: false,
    changeChapters: {},
    removeRequirementProducts: {},
    contacts: false,
    wizard: false,
    files: false,
    template: false,
  },
  products: [],
  productSpecifications: {},
  chapters: [],
  requirements: [],
  ownerUserDetails: {},
  wizard: { wizardId: null, collaborations: [], currentStep: null, state: [] },
};

export default initialState;
