import { useIntl } from "react-intl";
import { Alert } from "@trace-one/design-system";
import Library from "components/Library";
import { PageType } from "components/Library/components/types";
import { LibraryItemDetails } from "pages/Library/hocs/withLibraryItemDetails/types";
import { isLibraryObjectLocked } from "utils/library";
import libraryMessages from "messages/library";
import styles from "../../styles.module.less";

export interface TemplateProps {
  libraryItemDetails: LibraryItemDetails;
  page: PageType;
  updateActionsInformation: Function;
}

const Template: React.FC<TemplateProps> = ({
  page,
  updateActionsInformation,
  libraryItemDetails,
}) => {
  const { formatMessage } = useIntl();

  return (
    <>
      {!isLibraryObjectLocked(libraryItemDetails.generalInformation.state) && (
        <Alert
          showIcon
          closable
          className={styles.alertContainer}
          message={formatMessage(libraryMessages.templateEditionAlertTitle)}
          description={formatMessage(
            libraryMessages.templateEditionAlertDescription
          )}
          type="warning"
          data-testid="warning-banner-template"
        />
      )}

      <Library.TemplateForm
        page={page}
        updateActionsInformation={updateActionsInformation}
        initialValues={libraryItemDetails}
      />
    </>
  );
};

export default Template;
