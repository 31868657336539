import { useContext } from "react";
import { useIntl } from "react-intl";
import { Col, Row } from "antd";
import classnames from "classnames";
import { Datatable } from "@trace-one/design-system";
import TableHeader from "components/TableHeader";
import { LibraryContext } from "../../contexts";
import { useColumns } from "./hooks";
import { getNumberPageDisplayed, getRowKeyForTable } from "utils/table";
import { getOnTableChange } from "./utils";
import { isLibraryObjectChaptersType } from "utils/library";
import { messages } from "../../messages";
import { libraryMessages } from "messages";
import styles from "./styles.module.less";

const ListView = () => {
  const {
    areItemsClickable,
    libraryList,
    orderBy,
    orderByDescending,
    setOrderBy,
    setOrderByDescending,
    type,
    typeDictionary,
    displayActions,
    currentPage,
    pageSize,
    totalCount,
  } = useContext(LibraryContext);
  const columns = useColumns({
    areItemsClickable,
    orderBy,
    orderByDescending,
    type,
    typeDictionary,
    displayActions,
  });

  const { formatMessage } = useIntl();

  return (
    <Row gutter={[0, 30]} data-test-id="library-list">
      <Col xs={24}>
        {isLibraryObjectChaptersType(type) && (
          <TableHeader
            listResult={formatMessage(messages.listingResultNumber, {
              current: getNumberPageDisplayed({
                totalNumber: totalCount,
                current: currentPage,
                pageSize: pageSize,
              }),
              total: totalCount,
              libraryObjectType: formatMessage(
                libraryMessages.chaptersCountLabel
              ),
            })}
          />
        )}
        <Datatable
          tableLayout="fixed"
          columns={columns}
          dataSource={libraryList}
          pagination={false}
          onChange={getOnTableChange({ setOrderBy, setOrderByDescending })}
          rowClassName={classnames(areItemsClickable && styles.isClickable)}
          rowKey={getRowKeyForTable("library")}
        />
      </Col>
    </Row>
  );
};

export default ListView;
