import { defineMessages } from "react-intl";

export const messages = defineMessages({
  showFilters: {
    id: "filters.showFilters",
    defaultMessage: "Show filters",
  },

  hideFilters: {
    id: "filters.hideFilters",
    defaultMessage: "Hide filters",
  },

  clearAllFilters: {
    id: "filters.clearAllFilters",
    defaultMessage: "Clear all filters",
  },

  searchBtn: {
    id: "general.search",
    defaultMessage: "Search",
  },

  panelTitle: {
    id: "filters.panelTitle",
    defaultMessage: "Add a filter",
  },
});
