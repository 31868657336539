import { CheckboxProps as AntCheckboxProps } from "antd";
import { Actions } from "components/Card/types";
import { LibraryObjectData } from "models";

export interface ObjectProps {
  actions?: Actions[];
  actionsProps?: ActionsProps;
  displayActions?: boolean;
  errorMessage?: string;
  hasError?: boolean;
  isDisabled?: boolean;
  isSelected?: boolean;
  onDelete?: () => void;
  checkboxProps?: CheckboxProps;
  onClick?: () => void;
  status?: string;
  version?: string;
  object: LibraryObjectData;
}

export interface ActionsProps {
  disabled?: boolean;
  loading?: boolean;
}

export interface CheckboxProps extends Omit<AntCheckboxProps, "onChange"> {
  hasCheckbox?: boolean;
  disabled?: boolean;
  isChecked?: boolean;
  tooltipTitle?: string;
  onChange?: Function;
}

export enum Types {
  SPECIFICATION = "specification",
}
