import { createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import initialState from "./initialState";
import {
  addFileId,
  addTag,
  createRequirement,
  deleteRequirement,
  keepAsDraftRequirement,
  publishRequirement,
  removeTag,
  updateAdditionalInformation,
  updateContent,
  updateName,
  updateType,
} from "./asyncActions";
import {
  handleActionFulfilled,
  handleActionPending,
  handleActionRejected,
} from "store/helpers";
import reducers from "./reducers";
import { prepareRequirementInformationFromApiData } from "dtos/requirement";

export const slice = createSlice({
  name: "requirementForm",
  initialState,
  reducers: reducers,
  extraReducers: builder => {
    /* istanbul ignore next*/
    builder.addCase(PURGE, () => ({ ...initialState }));

    builder.addCase(createRequirement.pending, state => {
      handleActionPending(state.creationStatus);
    });
    builder.addCase(createRequirement.fulfilled, (state, { payload }) => {
      state.requirementInformation = prepareRequirementInformationFromApiData(
        payload
      );

      handleActionFulfilled(state.creationStatus);
    });
    builder.addCase(createRequirement.rejected, state => {
      handleActionRejected(state.creationStatus);
    });

    // keep as draft
    builder.addCase(keepAsDraftRequirement.pending, state => {
      handleActionPending(state.keepAsDraftStatus);
    });
    builder.addCase(keepAsDraftRequirement.fulfilled, state => {
      handleActionFulfilled(state.keepAsDraftStatus);
    });
    builder.addCase(keepAsDraftRequirement.rejected, state => {
      handleActionRejected(state.keepAsDraftStatus);
    });

    // delete
    builder.addCase(deleteRequirement.pending, state => {
      handleActionPending(state.deleteStatus);
    });
    builder.addCase(deleteRequirement.fulfilled, state => {
      state.requirementInformation = initialState.requirementInformation;
      state.type = initialState.type;

      handleActionFulfilled(state.deleteStatus);
    });
    builder.addCase(deleteRequirement.rejected, state => {
      handleActionRejected(state.deleteStatus);
    });

    // publish
    builder.addCase(publishRequirement.pending, state => {
      handleActionPending(state.publishStatus);
    });
    builder.addCase(publishRequirement.fulfilled, state => {
      handleActionFulfilled(state.publishStatus);
    });
    builder.addCase(publishRequirement.rejected, state => {
      handleActionRejected(state.publishStatus);
    });

    builder.addCase(updateName.pending, state => {
      handleActionPending(state.updateStatus);
    });
    builder.addCase(updateName.fulfilled, (state, { payload }) => {
      state.requirementInformation = prepareRequirementInformationFromApiData(
        payload
      );

      handleActionFulfilled(state.updateStatus);
    });
    builder.addCase(updateName.rejected, (state, action) => {
      state.requirementInformation.name = action.meta.arg;
      handleActionRejected(state.updateStatus);
    });

    builder.addCase(addTag.pending, state => {
      handleActionPending(state.updateStatus);
    });
    builder.addCase(addTag.fulfilled, (state, { payload }) => {
      state.requirementInformation = prepareRequirementInformationFromApiData(
        payload
      );

      handleActionFulfilled(state.updateStatus);
    });
    builder.addCase(addTag.rejected, state => {
      handleActionRejected(state.updateStatus);
    });

    builder.addCase(removeTag.pending, state => {
      handleActionPending(state.updateStatus);
    });
    builder.addCase(removeTag.fulfilled, (state, { payload }) => {
      state.requirementInformation = prepareRequirementInformationFromApiData(
        payload
      );

      handleActionFulfilled(state.updateStatus);
    });
    builder.addCase(removeTag.rejected, state => {
      handleActionRejected(state.updateStatus);
    });

    builder.addCase(updateContent.pending, state => {
      handleActionPending(state.updateStatus);
    });
    builder.addCase(updateContent.fulfilled, (state, { payload }) => {
      state.requirementInformation = prepareRequirementInformationFromApiData(
        payload
      );

      handleActionFulfilled(state.updateStatus);
    });
    builder.addCase(updateContent.rejected, state => {
      handleActionRejected(state.updateStatus);
    });

    builder.addCase(updateType.pending, state => {
      handleActionPending(state.updateStatus);
    });
    builder.addCase(updateType.fulfilled, (state, { payload, meta }) => {
      state.requirementInformation = prepareRequirementInformationFromApiData(
        payload
      );

      state.type = meta.arg;

      handleActionFulfilled(state.updateStatus);
    });
    builder.addCase(updateType.rejected, (state, action) => {
      state.type = action.meta.arg;
      state.requirementInformation.id = null;
      handleActionRejected(state.updateStatus);
    });

    builder.addCase(addFileId.pending, state => {
      handleActionPending(state.updateStatus);
    });
    builder.addCase(addFileId.fulfilled, (state, { payload }) => {
      state.requirementInformation = prepareRequirementInformationFromApiData(
        payload
      );

      handleActionFulfilled(state.updateStatus);
    });
    builder.addCase(addFileId.rejected, state => {
      handleActionRejected(state.updateStatus);
    });

    builder.addCase(updateAdditionalInformation.pending, state => {
      handleActionPending(state.updateStatus);
    });
    builder.addCase(
      updateAdditionalInformation.fulfilled,
      (state, { payload }) => {
        state.requirementInformation = prepareRequirementInformationFromApiData(
          payload
        );

        handleActionFulfilled(state.updateStatus);
      }
    );
    builder.addCase(updateAdditionalInformation.rejected, state => {
      handleActionRejected(state.updateStatus);
    });
  },
});

export const { setFormData } = slice.actions;

export default slice.reducer;
