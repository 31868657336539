import { Col, Row } from "antd";
import { Button, EmptyState } from "@trace-one/design-system";
import { withConfigConsumer } from "antd/lib/config-provider/context";
import ArrayFieldTemplateItem from "./ArrayFieldTemplateItem";
import { isListEmpty } from "utils/general";
import { useArrayFieldTemplateProps } from "./hooks";
import widgetStyles from "components/SpecificationSection/components/FormSchema/styles.module.less";

const NormalArrayFieldTemplate = ({
  items = [],
  onAddClick,
  noItemsMessage,
  addBtnLabel,
  formContext,
  schema,
}) => {
  const { displayActions, isSpecificationUpdating } = formContext;

  const {
    hasRemove,
    lastChanges,
    onAddBtnClick,
    isAddButtonDisabled,
  } = useArrayFieldTemplateProps({
    onAddClick,
    formContext,
    schema,
    items,
  });

  return (
    <Col span={24}>
      {!isListEmpty(items) ? (
        items.map((itemProps, index) => (
          <ArrayFieldTemplateItem
            key={itemProps.key}
            {...itemProps}
            displayActions={displayActions}
            hasRemove={hasRemove}
            lastChange={lastChanges?.[index]}
            isSpecificationUpdating={isSpecificationUpdating}
            formContext={formContext}
          />
        ))
      ) : (
        <Row gutter={[0, 30]} justify="center">
          <EmptyState heading={noItemsMessage} />
        </Row>
      )}
      {displayActions ? (
        <Row justify="center" className={widgetStyles.rowAddButton}>
          <Button
            type="tertiary"
            onClick={onAddBtnClick}
            disabled={isAddButtonDisabled}
          >
            {addBtnLabel}
          </Button>
        </Row>
      ) : null}
    </Col>
  );
};

export default withConfigConsumer({ prefixCls: "form" })(
  NormalArrayFieldTemplate
);
