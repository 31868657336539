import { getParsedData, isListEmpty, isObjectEmpty } from "utils/general";
import {
  getReflistIdsFromFormData,
  isDesiredPropertyType,
  isHierarchy,
  isReflist,
} from "./utils";
import { SectionData } from "models";
import { ObjectSchema } from "components/SpecificationSection/types";
import { ChapterStructureFormData } from "types/library";
import { BlockType, Ids, SectionType } from "./types";

const useSpecificationReflistIds = () => {
  const defaultIds = {
    reflistIds: new Set([]),
    hierarchyIds: new Set([]),
  };

  const getReflistIdsFromProperties = ({
    properties,
    ids = defaultIds,
    parentPaths = [],
    formData,
    definitions,
  }: {
    properties: ObjectSchema;
    ids?: Ids;
    parentPaths?: string[];
    formData: any;
    definitions?: ObjectSchema["definitions"];
  }) => {
    if (
      isObjectEmpty(properties) ||
      !formData ||
      (Array.isArray(formData) && isListEmpty(formData))
    ) {
      return ids;
    }

    return Object.entries(properties).reduce(
      (previousState, [key, currentValue]) => {
        if (currentValue.hasOwnProperty("properties")) {
          return {
            ...previousState,
            ...getReflistIdsFromProperties({
              properties: currentValue.properties,
              ids: previousState,
              parentPaths: [...parentPaths, key],
              formData: !isListEmpty(formData) ? formData : formData?.[key],
              definitions,
            }),
          };
        }

        if (isDesiredPropertyType(currentValue.type, "array")) {
          let items = currentValue.items;

          if (isDesiredPropertyType(items.type, "string")) {
            if (isReflist(items)) {
              previousState = {
                ...previousState,
                reflistIds: new Set([
                  ...previousState.reflistIds,
                  ...getReflistIdsFromFormData({
                    formData,
                    key,
                    parentPaths,
                  }),
                ]),
              };
            } else {
              if (isHierarchy(items)) {
                previousState = {
                  ...previousState,
                  hierarchyIds: new Set([
                    ...previousState.hierarchyIds,
                    ...getReflistIdsFromFormData({
                      formData,
                      key,
                      parentPaths,
                    }),
                  ]),
                };
              }
            }
          } else {
            if (items.hasOwnProperty("$ref")) {
              let definitionKey = items["$ref"].split("/");
              items = definitions[definitionKey[definitionKey.length - 1]];
            }

            if (!isListEmpty(formData)) {
              return formData.reduce((previousState, currentItem) => {
                return {
                  ...getReflistIdsFromProperties({
                    properties: items.properties,
                    ids: previousState,
                    parentPaths: [...parentPaths, key],
                    formData: currentItem,
                    definitions,
                  }),
                };
              }, previousState);
            } else {
              return {
                ...previousState,
                ...getReflistIdsFromProperties({
                  properties: items.properties,
                  ids: previousState,
                  parentPaths: [...parentPaths, key],
                  formData: formData?.[key],
                  definitions,
                }),
              };
            }
          }
        }

        if (isReflist(currentValue)) {
          return {
            ...previousState,
            reflistIds: new Set([
              ...previousState.reflistIds,
              ...getReflistIdsFromFormData({
                formData,
                key,
                parentPaths,
              }),
            ]),
          };
        } else {
          if (isHierarchy(currentValue)) {
            return {
              ...previousState,
              hierarchyIds: new Set([
                ...previousState.hierarchyIds,
                ...getReflistIdsFromFormData({
                  formData,
                  key,
                  parentPaths,
                }),
              ]),
            };
          }
        }

        return { ...previousState };
      },
      ids
    );
  };

  const getReflistIdsFromBlock = (block: BlockType, ids = defaultIds) => {
    const jsonSchema = getParsedData(block.jsonSchema);
    const formData = getParsedData(block.dataAsJson);

    return {
      ...getReflistIdsFromProperties({
        properties: jsonSchema?.properties,
        definitions: jsonSchema?.definitions,
        formData,
        ids,
      }),
    };
  };

  const getReflistIdsFromSections = (
    sections: SectionType[] = [],
    ids = defaultIds
  ) =>
    sections?.reduce((previousState, currentItem) => {
      let currentIds = previousState;

      let blocks: BlockType[];

      blocks =
        (currentItem as SectionData).sectionBlocks ||
        (currentItem as ChapterStructureFormData).blocks;

      currentIds = blocks?.reduce(
        (previousBlocksState, currentBlock) =>
          getReflistIdsFromBlock(currentBlock, previousBlocksState),
        previousState
      );
      let subSections =
        (currentItem as SectionData).subSections ||
        (currentItem as ChapterStructureFormData).sections;

      if (!isListEmpty(subSections)) {
        currentIds = getReflistIdsFromSections(subSections, currentIds);
      }

      return {
        ...previousState,
        reflistIds: new Set([
          ...previousState.reflistIds,
          ...(currentIds?.reflistIds || []),
        ]),
        hierarchyIds: new Set([
          ...previousState.hierarchyIds,
          ...(currentIds?.hierarchyIds || []),
        ]),
      };
    }, ids);

  return {
    getReflistIdsFromProperties,
    getReflistIdsFromBlock,
    getReflistIdsFromSections,
  };
};

export default useSpecificationReflistIds;
