import { retry } from "@reduxjs/toolkit/query/react";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { API_VERSION } from "./../constants";
import { axiosInstance } from "./../TAGS";

// Define baseQuery
// This can be used at global level for for tags
export const baseQuery = async ({ url, method, data }: AxiosRequestConfig) => {
  try {
    const result: AxiosResponse = await axiosInstance({
      url: `/api/${API_VERSION}${url}`,
      method,
      data: data ?? {},
    });
    return { data: result.data };
  } catch (axiosError) {
    let err = axiosError;
    if (!axiosError.response) throw err;
    return {
      error: {
        status: axiosError.response.status,
        data: axiosError.response.data,
      },
    };
  }
};

export const fetchBaseQuery = retry(baseQuery, { maxRetries: 0 });
