import { useContext } from "react";
import Content from "./components/Content";
import { LibraryDetails } from "../../contexts";
import InformationCard from "components/InformationCard";
import {
  isLibraryObjectClaimType,
  isLibraryObjectTemplatesType,
} from "utils/library";

const GeneralInformation = () => {
  const { libraryItemDetails, typeDictionary } = useContext(LibraryDetails);

  const { generalInformation, versionNumber, generalInfo } = libraryItemDetails;

  const {
    type,
    lastModificationDate,
    status,
    tags,
    internalCode,
  } = generalInformation;

  return (
    <>
      <InformationCard
        type={typeDictionary?.[type] || type}
        lastModificationDate={lastModificationDate}
        status={status}
        tags={tags}
        internalCode={internalCode}
        versionNumber={versionNumber}
        productTypeId={generalInfo?.productTypeId}
        displayVersionNumber={
          !isLibraryObjectTemplatesType(type) && !isLibraryObjectClaimType(type)
        }
      />
      <Content libraryItemDetails={libraryItemDetails} />
    </>
  );
};

export default GeneralInformation;
