import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { Row, Col } from "antd";
import { Heading } from "@trace-one/design-system";
import Chapters from "./components/Chapters";
import Action from "./components/Action";
import Username from "./components/Username";
import { filterMessages } from "components/SpecificationHistorisationModal/messages";
import styles from "./styles.module.less";
import { HistorisationContext } from "components/SpecificationHistorisationModal/contexts";

const Panel = () => {
  const {
    selectedFilters,
    setSelectedFilterCriterias,
    areFiltersDisabled,
  } = useContext(HistorisationContext);

  const { actions, users } = selectedFilters;

  return (
    <div className={styles.morefiltersPanel}>
      <Row gutter={[0, 16]}>
        <Col>
          <Heading size="xs">
            <FormattedMessage {...filterMessages.moreFilters} />
          </Heading>
        </Col>
      </Row>
      <Row gutter={[24, 0]}>
        <Col xs={12}>
          <Chapters />
        </Col>
        <Col xs={6}>
          <Action
            selectedActions={actions}
            setSelectedActions={setSelectedFilterCriterias("actions")}
            disabled={areFiltersDisabled}
          />
        </Col>
        <Col xs={6}>
          <Username
            selectedUsers={users}
            setSelectedUsers={setSelectedFilterCriterias("users")}
            disabled={areFiltersDisabled}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Panel;
