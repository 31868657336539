import { Col, Row } from "antd";
import { EmptyState } from "@trace-one/design-system";
import { useSelector } from "react-redux";
import ArrayFieldTemplateItem from "../ArrayFieldTemplateItem";
import AddRow from "./components/AddRow";
import { useSpecificationDetailsRefList } from "hooks";
import { useWidgetActions } from "../../../hooks";
import { useReferenceList } from "./hooks";
import withRequestModificationPopover from "hocs/withRequestModificationPopover";
import { selectSectionSavingInProgress } from "store/specificationDetails/selectors";
import { ReferenceListFieldTemplateContext } from "./contexts";
import { isListEmpty } from "utils/general";
import { ReferenceListArrayFieldTemplateProps } from "./types";
import widgetStyles from "components/SpecificationSection/components/FormSchema/styles.module.less";

const ReferenceListArrayFieldTemplate: React.FC<ReferenceListArrayFieldTemplateProps> = ({
  items,
  addBtnLabel,
  noItemsMessage,
  formContext,
  schema,
  formData,
  reflistId,
  uiSchema,
  onOpenModal,
  setModalStyle,
  setSelectedField,
  setRequestPopoverOptions,
}) => {
  const {
    displayActions,
    isWidgetEditable,
    sectionId,
    templateBlockId,
  } = formContext;
  const { propertyId, itemKey } = schema;

  const isSavingInProgress = useSelector(
    selectSectionSavingInProgress(sectionId)
  );

  const { isLoading, referenceList } = useSpecificationDetailsRefList({
    listId: reflistId,
    displayActions,
  });

  const {
    referenceOptions,
    shouldAddActionBeDisabled,
    updateExcludedRefListIds,
  } = useReferenceList({
    referenceList,
    displayActions,
    defaultExcludedRefListIds: formData.map(item => item?.[itemKey]),
  });

  const { getWidgetLastChanges } = useWidgetActions({
    templateBlockId,
    sectionId,
  });

  const areActionsDisabled =
    !isWidgetEditable(propertyId) || isSavingInProgress;

  const lastChanges = getWidgetLastChanges(propertyId);

  return (
    <Col span={24}>
      <ReferenceListFieldTemplateContext.Provider
        value={{
          referenceOptions,
          updateExcludedRefListIds,
        }}
      >
        <span data-field-id={`${propertyId}`}>
          {!isListEmpty(items) ? (
            items.map((itemProps, index) => (
              <ArrayFieldTemplateItem
                hasRemoveConfirmation
                key={index}
                {...itemProps}
                displayActions={displayActions}
                hasRemove={!areActionsDisabled}
                lastChange={lastChanges?.[index]}
                formContext={formContext}
                formData={formData}
                propertyId={propertyId}
              />
            ))
          ) : (
            <Row gutter={[0, 30]} justify="center">
              <EmptyState heading={noItemsMessage} />
            </Row>
          )}
        </span>
      </ReferenceListFieldTemplateContext.Provider>
      {displayActions ? (
        <Row justify="center" className={widgetStyles.rowAddButton}>
          <AddRow
            formContext={formContext}
            formData={formData}
            propertyId={propertyId}
            addBtnLabel={addBtnLabel}
            referenceOptions={referenceOptions}
            updateExcludedRefListIds={updateExcludedRefListIds}
            areActionsDisabled={areActionsDisabled}
            shouldAddActionBeDisabled={shouldAddActionBeDisabled}
            isLoading={isLoading}
            multiple={uiSchema?.["ui:multipleMode"]}
            itemKey={itemKey}
            setModalStyle={setModalStyle}
            setSelectedField={setSelectedField}
            onOpenModal={onOpenModal}
            setRequestPopoverOptions={setRequestPopoverOptions}
          />
        </Row>
      ) : null}
    </Col>
  );
};

export default withRequestModificationPopover(ReferenceListArrayFieldTemplate);
