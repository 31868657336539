import { DragEndEvent } from "@dnd-kit/core";
import { TemplateSectionData } from "models";
import { HandleDragEndProps } from "./components/Sections/types";

export const getIsChapterSwitchDisabled = ({
  mandatory,
  displayActions,
}: {
  mandatory: TemplateSectionData["mandatory"];
  displayActions?: boolean;
}) => mandatory || !displayActions;

export const getOnChangeChapterVisibility = ({
  changeVisibilityForSection,
  setIsChapterVisible,
  setAreUserGuidesUpdating,
}: {
  changeVisibilityForSection: Function;
  setIsChapterVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setAreUserGuidesUpdating: React.Dispatch<React.SetStateAction<boolean>>;
}) => async (visibility: boolean) => {
  try {
    setAreUserGuidesUpdating(true);
    setIsChapterVisible(visibility);
    await changeVisibilityForSection(visibility);
  } finally {
    setAreUserGuidesUpdating(false);
  }
};

export const handleDragEnd = ({
  arrayMove,
  sections,
  moveUpSection,
  moveDownSection,
  setChapterSections,
  onReorderSections,
  chapterType,
}: HandleDragEndProps) => (event: DragEndEvent) => {
  const { active, over } = event;

  const activeId = active?.id;
  const overId = over?.id;

  if (activeId !== overId) {
    const oldIndex = sections?.findIndex(section => section?.id === activeId);
    const newIndex = sections?.findIndex(section => section?.id === overId);

    const section = sections[oldIndex];
    const number = oldIndex - newIndex;

    const sortedSections = arrayMove(sections, oldIndex, newIndex);
    setChapterSections(sortedSections);

    if (onReorderSections) {
      onReorderSections({
        chapterType,
        sectionIds: sortedSections.map(section => section.id),
      });
    } else {
      if (number > 0) {
        moveUpSection({
          sectionId: section.id,
          number,
        });
      } else {
        moveDownSection({
          sectionId: section.id,
          number: -1 * number,
        });
      }
    }
  }
};
