import { SpecificationData, SpecificationVersionData } from "models";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { selectSpecificationPermissions } from "store/specificationDetails/selectors";
import { useSpecificationActions, useUserInfo } from "hooks";
import { isObjectEmpty } from "utils/general";
import {
  getNextVersion,
  isStateInRevisionByRetailer,
  isStateInRevisionBySupplier,
  isStateSignedByRetailer,
} from "utils/specifications";
import { messages } from "./messages";

const useWarnings = ({
  state,
  lastVersion,
  id,
  history,
}: {
  state: SpecificationData["state"];
  lastVersion: boolean;
  id: SpecificationData["id"];
  history: SpecificationVersionData[];
}) => {
  const [nextVersionState, setNextVersionState] = useState<
    SpecificationData["state"]
  >(null);
  const [warningMessage, setWarningMessage] = useState("");

  const { formatMessage } = useIntl();
  const permissions = useSelector(selectSpecificationPermissions);
  const { revise } = useSpecificationActions(
    {
      specificationId: id,
      state,
    },
    permissions
  );
  const { isRetailer } = useUserInfo();

  useEffect(() => {
    if (
      isStateSignedByRetailer(state) &&
      isObjectEmpty(revise) &&
      !lastVersion
    ) {
      const nextVersion = getNextVersion(id, history);
      if (nextVersion) {
        setNextVersionState(nextVersion.state);
      }
    }
  }, [state, revise, lastVersion]);

  useEffect(() => {
    if (isStateInRevisionByRetailer(state)) {
      setWarningMessage(formatMessage(messages.inRevision));
    } else if (!isRetailer && isStateInRevisionByRetailer(nextVersionState)) {
      setWarningMessage(formatMessage(messages.inRevisionByRetailer));
    } else if (isRetailer && isStateInRevisionBySupplier(nextVersionState)) {
      setWarningMessage(formatMessage(messages.inRevisionBySupplier));
    }
  }, [isRetailer, state, nextVersionState]);

  return { warningMessage };
};

export default useWarnings;
