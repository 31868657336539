import { LANGUAGES } from "utils/constants";
import { UserData } from "models";

export const parseLanguage = (
  userLanguage: UserData["userLanguagePreference"]
) => {
  switch (userLanguage) {
    case LANGUAGES.FRENCH:
      return "fr-fr";

    default:
      return "en-us";
  }
};

export const getCookieSettingsWidgetPublicKey = (
  legalMonsterLanguage: string
) => {
  switch (legalMonsterLanguage) {
    case "fr-fr":
      return window.env.LEGAL_MONSTER_COOKIE_WIDGET_FR_ID;

    default:
      return window.env.LEGAL_MONSTER_COOKIE_WIDGET_EN_ID;
  }
};
