import { useCallback } from "react";
import { Icon } from "@trace-one/design-system";
import dayjs from "dayjs";
import { useIntl } from "react-intl";
import { useRedirect } from "hooks";
import { getVersionName } from "utils/specifications";
import { getOnLibraryItemClick } from "components/Library/components/List/utils";
import TableColumns from "components/TableColumns";
import Status from "components/Status";
import Actions from "../../components/Actions";
import LinkedSpecificationsCount from "../../components/LinkedSpecificationsCount";
import {
  isLibraryObjectChaptersType,
  isLibraryObjectClaimType,
  isLibraryObjectTemplatesType,
} from "utils/library";
import {
  getDefaultSortOrderForColumn,
  shouldTypeColumnBeDisplayed,
} from "./utils";
import { Dictionary } from "types/general";
import { LibraryObjectType } from "types/library";
import { LibraryListItemViewModel } from "viewModels";
import { messages } from "./messages";
import generalMessages from "messages/general";
import styles from "./styles.module.less";

const useColumns = ({
  areItemsClickable,
  orderBy,
  orderByDescending,
  type,
  typeDictionary,
  displayActions = true,
}: {
  areItemsClickable: boolean;
  orderBy: string;
  orderByDescending: boolean;
  type: LibraryObjectType;
  typeDictionary: Dictionary;
  displayActions?: boolean;
}) => {
  const { formatMessage } = useIntl();
  const { redirectToLibraryDetails, redirectToLibraryEdition } = useRedirect();

  const getDefaultSortOrder = getDefaultSortOrderForColumn({
    orderBy,
    orderByDescending,
  });

  const onCell = useCallback(
    (libraryItem: LibraryListItemViewModel) => ({
      ...(areItemsClickable && {
        onClick: getOnLibraryItemClick({
          libraryItem,
          redirectToLibraryDetails,
          redirectToLibraryEdition,
        }),
      }),
    }),
    [areItemsClickable]
  );

  const shouldDisplayTypeColumn = shouldTypeColumnBeDisplayed(type);

  const shouldDisplayStatusColumn =
    isLibraryObjectTemplatesType(type) ||
    isLibraryObjectChaptersType(type) ||
    isLibraryObjectClaimType(type);

  const shouldDisplayLinksColumn = isLibraryObjectChaptersType(type);

  const shouldDisplayTagsColumn =
    !isLibraryObjectChaptersType(type) && !isLibraryObjectClaimType(type);
  const shouldDisplayProductTypeColumn = isLibraryObjectClaimType(type);

  return [
    {
      title: formatMessage(messages.nameColumn),
      dataIndex: "name",
      key: "name",
      sorter: !isLibraryObjectChaptersType(type),
      defaultSortOrder: getDefaultSortOrder("name"),
      className: styles.nameColumn,
      onCell,
    },
    shouldDisplayTypeColumn && {
      title: formatMessage(messages.typeColumn),
      dataIndex: "type",
      render: type => typeDictionary[type],
      key: "type",
      onCell,
    },
    shouldDisplayProductTypeColumn && {
      title: formatMessage(generalMessages.productTypeLabel),
      dataIndex: "productType",
      key: "productType",
      onCell,
    },
    shouldDisplayStatusColumn && {
      title: formatMessage(messages.statusColumn),
      dataIndex: "state",
      key: "state",
      width: "15%",
      render: state => <Status.Tag state={state} />,
      onCell,
    },
    {
      title: formatMessage(messages.lastModificationDateColumn),
      dataIndex: "lastUpdateDateUtc",
      key: "lastUpdateDateUtc",
      sorter:
        !isLibraryObjectChaptersType(type) && !isLibraryObjectClaimType(type),
      width: "15%",
      defaultSortOrder: getDefaultSortOrder("lastUpdateDateUtc"),
      render: lastUpdateDateUtc => dayjs(lastUpdateDateUtc).local().format("L"),
      onCell,
    },
    shouldDisplayTagsColumn && {
      title: formatMessage(messages.tagsColumn),
      dataIndex: "tags",
      key: "tags",
      width: "25%",
      render: tags => <TableColumns.Tags tags={tags} />,
      onCell,
    },
    {
      title: formatMessage(generalMessages.version),
      dataIndex: "versionNumber",
      key: "versionNumber",
      width: "10%",
      render: versionNumber => getVersionName(versionNumber),
      onCell,
    },
    shouldDisplayLinksColumn && {
      title: formatMessage(messages.linksColumn),
      key: "links",
      width: 75,
      render: (_, libraryItem) => {
        return <LinkedSpecificationsCount libraryItem={libraryItem} />;
      },
    },
    displayActions && {
      title: () => (
        <Icon name="settings" color="white" className={styles.actionColumn} />
      ),
      key: "action",
      width: 50,
      render: (_, libraryItem) => <Actions libraryItem={libraryItem} />,
    },
  ].filter(Boolean);
};

export default useColumns;
