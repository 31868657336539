import { LibraryItemState } from "types/library";
import { isLibraryObjectLocked } from "utils/library";
import { detailsAndLinksChapterMessages } from "../../messages";
import { GeneralInfoData } from "models";
import { ModalActionType } from "components/DetailsAndLinksChapterModal/types";

export const getActionsList = ({
  formatMessage,
  activeVersion,
  chapterId,
  chapterState,
  handleOpenModal,
  isRetailer,
}: {
  formatMessage: Function;
  activeVersion: boolean;
  chapterId: string;
  chapterState: LibraryItemState;
  handleOpenModal?: ({
    chapterId,
    actionType,
  }: {
    chapterId: GeneralInfoData["id"];
    actionType: ModalActionType;
  }) => () => void;
  isRetailer?: boolean;
}) => {
  return [
    ...(activeVersion
      ? [
          {
            label: formatMessage(
              detailsAndLinksChapterMessages.unlinkSpecificationsTitle
            ),
            name: formatMessage(
              detailsAndLinksChapterMessages.unlinkSpecificationsTitle
            ),
            onClick: handleOpenModal({
              actionType: ModalActionType.UNLINK,
              chapterId,
            }),
          },
        ]
      : isLibraryObjectLocked(chapterState)
      ? [
          {
            label: formatMessage(
              detailsAndLinksChapterMessages.updateToLatestVersionTitle
            ),
            name: formatMessage(
              detailsAndLinksChapterMessages.updateToLatestVersionTitle
            ),
            onClick: handleOpenModal({
              actionType: ModalActionType.UPDATE,
              chapterId,
            }),
          },
          ...(isRetailer
            ? [
                {
                  label: formatMessage(
                    detailsAndLinksChapterMessages.reviseSpecifications
                  ),
                  name: formatMessage(
                    detailsAndLinksChapterMessages.reviseSpecifications
                  ),
                  onClick: handleOpenModal({
                    actionType: ModalActionType.REVISE,
                    chapterId,
                  }),
                },
              ]
            : []),
        ]
      : []),
  ];
};
