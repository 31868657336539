import { useUserInfo } from "hooks";
import Retailer from "./components/Retailer";
import Supplier from "./components/Supplier";
import DigitalSigning from "./components/DigitalSigning";
import { useDigitalSignModal } from "./hooks";
import { SignModalProps } from "./types";

const SignModal: React.FC<SignModalProps> = ({
  companyName,
  tradeItem,
  onClose,
  specificationId,
  refetchSpecificationInformation,
}) => {
  const { isSupplier } = useUserInfo();
  const {
    hasDigitalSigning,
    isLoading,
    hasSignError,
    closeHandler,
  } = useDigitalSignModal({ onClose, refetchSpecificationInformation });

  if (isLoading) return null;

  if (hasDigitalSigning) {
    return (
      <DigitalSigning hasSignError={hasSignError} onClose={closeHandler} />
    );
  }

  if (isSupplier) {
    return (
      <Supplier
        companyName={companyName}
        tradeItem={tradeItem}
        visible
        onClose={onClose}
        specificationId={specificationId}
      />
    );
  } else {
    return (
      <Retailer
        companyName={companyName}
        tradeItem={tradeItem}
        visible
        onClose={onClose}
        specificationId={specificationId}
      />
    );
  }
};

export default SignModal;
