import { SimpleListItemData } from "models";
import { axiosInstance } from "./";

export const fetchConditioningTemperatures = ({
  languageCode,
}: {
  languageCode?: string;
}) =>
  axiosInstance.get<SimpleListItemData[]>(`/api/conditioning-temperatures`, {
    params: { languageCode },
  });

export const fetchTreatmentFrequencyOfUse = ({
  languageCode,
}: {
  languageCode?: string;
}) =>
  axiosInstance.get<SimpleListItemData[]>(`/api/treatment-frequencies`, {
    params: { languageCode },
  });

export const fetchDateFormats = () =>
  axiosInstance.get<string[]>(`/api/date-formats`);
