import {
  REFLIST_IDS,
  SECTION_CODE,
  SPECIFICATION_PAGE,
  SPECIFICATION_STATE,
  SPECIFICATION_TRANSLATION_STATE,
} from "./constants";
import {
  SpecificationChapterDocumentData,
  SpecificationChapterRequirementData,
  SpecificationData,
  SpecificationVersionData,
} from "models";
import { isListEmpty, isObjectEmpty } from "./general";
import { PageType, SpecificationTranslationState } from "types/specification";
import { Dictionary } from "types/general";

export const getSpecificationName = (
  tradeItemName: string,
  version: number
): string => `${tradeItemName} - ${getVersionName(version)}`;

export const getSpecificationHeaderTitle = ({
  tradeItemName,
  netContent,
}: {
  tradeItemName: string;
  netContent?: string;
}) => {
  if (netContent) {
    return `${tradeItemName} - ${netContent}`;
  }

  return `${tradeItemName}`;
};

export const getVersionName = (version: number): string => `V${version}`;

export const isStatusNotNone = (status: string): boolean => status !== "none";

export const isDesiredState = (desiredState: string) => (
  state: string
): boolean => state === desiredState;

export const isStateDraft = (state: string): boolean =>
  isDesiredState(SPECIFICATION_STATE.draft)(state);

export const isStateInProgress = (state: string): boolean =>
  isDesiredState(SPECIFICATION_STATE.inProgress)(state);

export const isStateInRevisionBySupplier = (state: string): boolean =>
  isDesiredState(SPECIFICATION_STATE.inRevisionBySupplier)(state);

export const isStateInRevisionByRetailer = (state: string): boolean =>
  isDesiredState(SPECIFICATION_STATE.inRevisionByRetailer)(state);

export const isStateInRevision = (state: string): boolean =>
  isDesiredState(SPECIFICATION_STATE.inRevisionByRetailer)(state) ||
  isDesiredState(SPECIFICATION_STATE.inRevisionBySupplier)(state);

export const isStateSignedByRetailer = (state: string): boolean =>
  isDesiredState(SPECIFICATION_STATE.signedByRetailer)(state);

export const isStateSignedBySupplier = (state: string): boolean =>
  isDesiredState(SPECIFICATION_STATE.signedBySupplier)(state);

export const isStateValidatedByRetailer = (state: string): boolean =>
  isDesiredState(SPECIFICATION_STATE.validatedByRetailer)(state);

export const isStateValidatedBySupplier = (state: string): boolean =>
  isDesiredState(SPECIFICATION_STATE.validatedBySupplier)(state);

export const isSectionCodeLabeling = (sectionCode: string): boolean =>
  sectionCode === SECTION_CODE.LABELING;

export const isSpecificationSigned = (state: string): boolean =>
  isStateSignedByRetailer(state) ||
  isStateSignedBySupplier(state) ||
  isSpecificationLockedForSupplierSignature(state) ||
  isSpecificationLockedForRetailerSignature(state);

export const isSpecificationLockedForSupplierSignature = (
  state: string
): boolean =>
  isDesiredState(SPECIFICATION_STATE.lockedForSupplierSignature)(state);

export const isSpecificationLockedForRetailerSignature = (
  state: string
): boolean =>
  isDesiredState(SPECIFICATION_STATE.lockedForRetailerSignature)(state);

export const isSpecificationLockedForSignature = (state: string): boolean =>
  isSpecificationLockedForSupplierSignature(state) ||
  isSpecificationLockedForRetailerSignature(state);

export const prepareStatusesForFilters = (statuses: string[]) =>
  statuses.reduce((currentState, status) => {
    if (status === "inRevision") {
      return [
        ...currentState,
        SPECIFICATION_STATE.inRevisionByRetailer,
        SPECIFICATION_STATE.inRevisionBySupplier,
      ];
    } else {
      return [...currentState, status];
    }
  }, []);

export const isFAndVSpecTemplate = (
  productTypeId: SpecificationData["productTypeId"]
) => {
  return REFLIST_IDS.F_AND_V_PRODUCT_TYPE === productTypeId;
};

export const getPreviousVersion = (
  specificationId: SpecificationData["id"],
  history: SpecificationVersionData[]
) => {
  const index = history.findIndex(
    version => version.specificationId === specificationId
  );

  if (index < history.length - 1) {
    return history[index + 1];
  }
  return null;
};

export const getNextVersion = (
  specificationId: SpecificationData["id"],
  history: SpecificationVersionData[]
) => {
  if (isListEmpty(history)) {
    return null;
  }
  const index = history.findIndex(
    version => version.specificationId === specificationId
  );

  if (index > 0) {
    return history[index - 1];
  }
  return null;
};

export const prepareRequirementsSectionStatus = (
  requirements: SpecificationChapterRequirementData[]
) => {
  if (isListEmpty(requirements)) {
    return SPECIFICATION_STATE.none;
  }
  if (requirements.every(req => req.validated)) {
    return SPECIFICATION_STATE.validatedByRetailer;
  }
  return SPECIFICATION_STATE.inProgress;
};

export const prepareDocumentsSectionStatus = (
  documents: SpecificationChapterDocumentData
) => {
  if (isObjectEmpty(documents)) {
    return SPECIFICATION_STATE.none;
  }

  if (documents?.validatedByRetailer) {
    return SPECIFICATION_STATE.validatedByRetailer;
  }

  if (documents?.validatedBySupplier) {
    return SPECIFICATION_STATE.validatedBySupplier;
  }

  return SPECIFICATION_STATE.inProgress;
};

export const isSpecificationPdfPage = (page: PageType): boolean =>
  page === SPECIFICATION_PAGE.PDF;

export const getCanRequestForModification = ({
  state,
  selectedChapterOwnerCompanyId,
  ownerCompanyId,
  isSelectedChapterLibraryChapter,
  isSelectedChapterLinked,
}: {
  state: SpecificationData["state"];
  selectedChapterOwnerCompanyId: SpecificationData["ownerCompanyId"];
  ownerCompanyId: SpecificationData["ownerCompanyId"];
  isSelectedChapterLibraryChapter: boolean;
  isSelectedChapterLinked: boolean;
}) => {
  if (isSpecificationSigned(state)) {
    return false;
  }

  if (!isSelectedChapterLibraryChapter) {
    return false;
  }

  if (!isSelectedChapterLinked && isSelectedChapterLibraryChapter) {
    return false;
  }

  return !isChapterOwnedByCurrentUserCompany({
    specOwnerCompanyId: ownerCompanyId,
    chapterOwnerCompanyId: selectedChapterOwnerCompanyId,
  });
};

export const isChapterOwnedByCurrentUserCompany = ({
  specOwnerCompanyId,
  chapterOwnerCompanyId,
}: {
  specOwnerCompanyId: string;
  chapterOwnerCompanyId: string;
}) => specOwnerCompanyId === chapterOwnerCompanyId;

export const getFirstNetContent = ({
  netContents = [],
  refListDictionary = {},
}: {
  netContents: Array<{ value?: string; unitOfMeasure?: string }>;
  refListDictionary: Dictionary;
}) => {
  if (isListEmpty(netContents) || isObjectEmpty(refListDictionary)) {
    return null;
  }

  if (!refListDictionary[netContents[0].unitOfMeasure]) {
    return null;
  }

  return `${netContents[0].value}${
    refListDictionary[netContents[0].unitOfMeasure]
  }`;
};

export const isLabelingTranslationStateNotStarted = (
  state: SpecificationTranslationState
) => state === SPECIFICATION_TRANSLATION_STATE.NOT_STARTED;

export const isLabelingTranslationStateStarted = (
  state: SpecificationTranslationState
) => state === SPECIFICATION_TRANSLATION_STATE.STARTED;

export const isLabelingTranslationStateCompleted = (
  state: SpecificationTranslationState
) => state === SPECIFICATION_TRANSLATION_STATE.COMPLETED;
