import { useContext } from "react";
import { MenuContext } from "components/SpecificationSection/components/Menu/contexts";
import SectionName from "./components/SectionName";
import { SectionProps } from "./types";
import styles from "./styles.module.less";
import Status from "components/Status";

const Section: React.FC<SectionProps> = ({
  section,
  parentSectionId,
  parentChapterType,
}) => {
  const { onClickSubSection, selectedSubSectionId } = useContext(MenuContext);

  return (
    <div className={styles.section}>
      <Status.Dot className={styles[section?.sectionStatus?.toLowerCase()]} />
      <SectionName
        sectionName={section.sectionName}
        onClick={onClickSubSection(
          parentSectionId,
          section.sectionId,
          parentChapterType
        )}
        isSelected={section.sectionId === selectedSubSectionId}
      />
    </div>
  );
};

export default Section;
