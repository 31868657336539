import { isListEmpty, isObjectEmpty, getParsedData } from "utils/general";
import {
  NutrientFamily,
  NutrientItem,
  NutrientTableDataItem,
  NutrientStatus,
  NutritionFormData,
  NutrientItemDictionary,
} from "./types";
import { NutrientFamilyData, NutrientItemData } from "models";
import { Dictionary } from "types/general";
import { NUTRIENT_STATE } from "utils/constants";

export const prepareTableData = (
  data: NutrientFamily[],
  mandatoryNutrientIds: string[]
) => {
  const dataWithKeys = data?.map(({ nutrients, ...rest }, index) => {
    const key = `[${index}]`;
    return {
      ...rest,
      key,
      nutrients: nutrients.map((nutrient, childIndex) => {
        return { ...nutrient, key: `${key}.nutrients[${childIndex}]` };
      }),
    };
  });

  if (!isListEmpty(dataWithKeys)) {
    return dataWithKeys.map(({ nutrients, ...rest }, index) => {
      const key = `[${index}]`;
      return {
        ...rest,
        key,
        nutrientId: rest.nutrientFamilyId,
        children: convertListToTree({ items: nutrients, mandatoryNutrientIds }),
      };
    });
  }

  return [];
};

export const convertListToTree = ({
  items,
  linkValue = undefined,
  link = "parentId",
  mandatoryNutrientIds = [],
}: {
  items: {
    nutrientId?: string;
    parentId?: string;
    unit?: string;
    unroundedValue?: string;
  }[];
  linkValue?: string;
  link?: string;
  mandatoryNutrientIds?: string[];
}) =>
  items
    .filter(item => item[link] === linkValue)
    .map(({ nutrientId, parentId, ...rest }) => {
      return {
        ...rest,
        nutrientId,
        state: mandatoryNutrientIds?.includes(nutrientId)
          ? NUTRIENT_STATE.MANDATORY
          : NUTRIENT_STATE.OPTIONAL,
        children: convertListToTree({
          items,
          linkValue: nutrientId,
          mandatoryNutrientIds,
        }),
      };
    });

export const getExpandedRowKeys = (
  data: NutrientTableDataItem[],
  keys?: string[]
) => {
  if (!keys) {
    keys = [];
  }
  if (!isListEmpty(data)) {
    data.forEach(tree => {
      if (tree?.children && !isListEmpty(tree.children)) {
        const nutrientId = tree.nutrientId || tree.nutrientFamilyId;
        keys.push(nutrientId);
        getExpandedRowKeys(tree.children, keys);
      }
    });
  }

  return keys;
};

export const collectNutrientData = (
  data: NutrientItem[] | NutrientFamily[]
) => {
  const nutrientIds = [];

  const unitOfMeasureIds = [];

  if (!isListEmpty(data)) {
    data.forEach(tree => {
      if (tree?.nutrients) {
        nutrientIds.push(tree.nutrientFamilyId);

        const { nutrients, unitsOfMeasure } = tree.nutrients.reduce(
          (acc, { nutrientId, unitId }) => {
            acc.nutrients.push(nutrientId);
            acc.unitsOfMeasure.push(unitId);
            return acc;
          },
          { nutrients: [], unitsOfMeasure: [] }
        );
        nutrientIds.push(...nutrients);
        unitOfMeasureIds.push(...unitsOfMeasure);
      }
    });
  }
  return { nutrientIds, unitOfMeasureIds: [...new Set(unitOfMeasureIds)] };
};

export const createNutritionDictionary = (
  data: NutrientFamilyData[] | NutrientItemData[],
  dictionary?: Dictionary<NutrientItemDictionary>
) => {
  if (isObjectEmpty(dictionary)) {
    dictionary = {};
  }

  if (!isListEmpty(data)) {
    data.forEach(tree => {
      if (tree?.nutrients || tree?.children) {
        const nutrientId = tree.familyId || tree.id;
        dictionary[nutrientId] = {
          name: tree.familyName || tree.name,
          dailyIntake: tree.dailyIntake ?? null,
        };
        createNutritionDictionary(tree.nutrients || tree.children, dictionary);
      }
    });
  }
  return dictionary;
};

export const getNutritionFamilyStatus = (
  data: NutrientTableDataItem[],
  statusNutrientFamilyDictionary?: Dictionary<NutrientStatus>,
  nutrientFamilyId?: string
): Dictionary<NutrientStatus> => {
  if (isObjectEmpty(statusNutrientFamilyDictionary)) {
    statusNutrientFamilyDictionary = {};
  }

  if (!isListEmpty(data)) {
    data.forEach(tree => {
      const isRoot = !!tree.nutrientFamilyId;

      if (isRoot && !isListEmpty(tree.children)) {
        statusNutrientFamilyDictionary[tree.nutrientFamilyId] = {
          totalNutrients: 0,
          completedNutrients: 0,
        };
      } else {
        const currentTotalNutrients =
          statusNutrientFamilyDictionary[nutrientFamilyId].totalNutrients;
        const currentCompletedNutrients =
          statusNutrientFamilyDictionary[nutrientFamilyId].completedNutrients;
        statusNutrientFamilyDictionary[nutrientFamilyId] = {
          totalNutrients: currentTotalNutrients + 1,
          completedNutrients: tree.unroundedValue
            ? currentCompletedNutrients + 1
            : currentCompletedNutrients,
        };
      }

      if (!isListEmpty(tree.children)) {
        getNutritionFamilyStatus(
          tree.children,
          statusNutrientFamilyDictionary,
          tree.nutrientFamilyId || nutrientFamilyId
        );
      }
    });
  }
  return statusNutrientFamilyDictionary;
};

export const getNumberOfItemsColor = ({
  numberOfCompletedItems,
  numberOfItems,
}: {
  numberOfCompletedItems: number;
  numberOfItems: number;
}) => {
  if (!numberOfCompletedItems) return "grey";

  if (numberOfCompletedItems === numberOfItems) return "green";

  return "orange";
};

export const collectMandatoryNutrients = (
  data: NutrientFamilyData[] | NutrientItemData[],
  mandatoryNutrientIds?: string[],
  mandatoryNutrientIdsIncludingParentsWithMandatoryChild?: string[],
  hasChildWithMandatoryState?: boolean
) => {
  if (!mandatoryNutrientIds) {
    mandatoryNutrientIds = [];
  }
  if (!mandatoryNutrientIdsIncludingParentsWithMandatoryChild) {
    mandatoryNutrientIdsIncludingParentsWithMandatoryChild = [];
  }
  hasChildWithMandatoryState = false;

  if (!isListEmpty(data)) {
    data.forEach(tree => {
      const state = tree?.state;
      if (NUTRIENT_STATE.MANDATORY === state) {
        mandatoryNutrientIds.push(tree.id);
        mandatoryNutrientIdsIncludingParentsWithMandatoryChild.push(tree.id);
        hasChildWithMandatoryState = true;
      }
      if (tree?.nutrients && !isListEmpty(tree.nutrients)) {
        collectMandatoryNutrients(
          tree.nutrients,
          mandatoryNutrientIds,
          mandatoryNutrientIdsIncludingParentsWithMandatoryChild,
          hasChildWithMandatoryState
        );
      }
      if (tree?.children && !isListEmpty(tree.children)) {
        const mandatoryChildrenInfo = collectMandatoryNutrients(
          tree.children,
          mandatoryNutrientIds,
          mandatoryNutrientIdsIncludingParentsWithMandatoryChild,
          hasChildWithMandatoryState
        );
        if (mandatoryChildrenInfo.hasChildWithMandatoryState) {
          mandatoryNutrientIdsIncludingParentsWithMandatoryChild.push(tree.id);
        }
      }
    });
  }

  return {
    mandatoryNutrientIds,
    mandatoryNutrientIdsIncludingParentsWithMandatoryChild,
    hasChildWithMandatoryState,
  };
};

export const extractEditableFieldsForNutrition = (
  nutritionData: NutrientTableDataItem
) => {
  return {
    unitId: nutritionData.unitId,
    unroundedValue: nutritionData.unroundedValue,
  };
};

export const updateNutrientFormData = ({
  formData,
  nutrientId,
  dataToUpdate,
}: {
  formData: NutritionFormData;
  nutrientId: string;
  dataToUpdate: object;
}) => {
  let familyNutrientIndex;
  let nutrientIndex;

  formData.nutrientFamilies.forEach(({ nutrients }, index) => {
    const foundNutrientIndex = nutrients.findIndex(
      nutrient => nutrient.nutrientId === nutrientId
    );
    if (foundNutrientIndex > -1) {
      familyNutrientIndex = index;
      nutrientIndex = foundNutrientIndex;
      return;
    }
    return;
  });

  const updatedFormData = getParsedData(JSON.stringify(formData));

  updatedFormData.nutrientFamilies[familyNutrientIndex].nutrients[
    nutrientIndex
  ] = {
    ...updatedFormData.nutrientFamilies[familyNutrientIndex].nutrients[
      nutrientIndex
    ],
    ...dataToUpdate,
  };

  return updatedFormData;
};
