import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { Checkbox } from "@trace-one/design-system";
import { Card } from "@trace-one/react-components";
import { selectUnitsOfMeasure } from "store/generalData/selectors";
import { createNameForProduct } from "utils/form";
import { isListEmpty } from "utils/general";
import { useBoolean } from "hooks";
import ProductName from "components/ProductName";
import RequirementItem from "./components/RequirementItem";
import { AssignedProductProps } from "./types";
import { messages } from "./messages";
import styles from "./styles.module.less";

const AssignedProduct: React.FC<AssignedProductProps> = ({
  assignedProduct,
  onSelectProduct,
  isSelected,
  requirements,
  onRemoveProductRequirement,
}) => {
  const { formatMessage } = useIntl();
  const unitsOfMeasure = useSelector(selectUnitsOfMeasure);
  const { value: isCardOpen, onToggle, setFalse } = useBoolean();

  const hasRequirements = !isListEmpty(requirements);

  const productName = useMemo(
    () =>
      `${createNameForProduct(assignedProduct, unitsOfMeasure)} ${
        hasRequirements
          ? `(${formatMessage(messages.productRequirementsCount, {
              number: requirements.length,
            })})`
          : ""
      }`,
    [assignedProduct.itemName, hasRequirements, requirements]
  );

  useEffect(() => {
    if (!hasRequirements) {
      setFalse();
    }
  }, [hasRequirements]);

  return (
    <Card
      className={styles.card}
      collapsible
      collapseProps={{
        disabled: !hasRequirements,
        in: isCardOpen,
        onToggle,
      }}
      title={
        <ProductName
          isHighlighted={hasRequirements}
          productName={productName}
        />
      }
      data-test-id="assigned-product-card"
      data-testid="assigned-product-card"
      {...(!!onSelectProduct && {
        extra: (
          <Checkbox
            checked={isSelected}
            onChange={onSelectProduct(assignedProduct.id)}
            data-test-id="assigned-product-select-action"
            data-testid="assigned-product-select-action"
          />
        ),
      })}
    >
      {hasRequirements ? (
        <div className={styles.requirementList}>
          {requirements.map(({ requirementId, requirementName }) => (
            <RequirementItem
              key={requirementId}
              onRemove={onRemoveProductRequirement(requirementId)}
              name={requirementName}
            />
          ))}
        </div>
      ) : (
        <></>
      )}
    </Card>
  );
};

export default AssignedProduct;
