import { Col, Row } from "antd";
import RowTitle from "components/RowTitle";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import CaliberCategoryReadOnly from "./components/CaliberCategoryReadOnly";
import { CaliberCategoryWidgetProps } from "./types";

const CaliberCategoryWidget: React.FC<CaliberCategoryWidgetProps> = ({
  uiSchema,
  properties,
  formContext,
}) => {
  const { displayActions, emptyValue, refListDictionary } = formContext;
  const { getTranslationForKey } = useFormSchemaTranslation();

  return (
    <Row>
      <Col span={24}>
        <RowTitle
          title={getTranslationForKey(uiSchema["ui:title"])}
          displayActions={displayActions}
        />
      </Col>
      {properties.map(({ content }, index) => (
        <Col key={index} span={24}>
          {displayActions ? (
            content
          ) : (
            <CaliberCategoryReadOnly
              caliberCategoryContent={content}
              refListDictionary={refListDictionary}
              emptyValue={emptyValue}
            />
          )}
        </Col>
      ))}
    </Row>
  );
};

export default CaliberCategoryWidget;
