import { createContext, useEffect } from "react";
import {
  SelectSectionValidationErrors,
  SetSectionBlockValidationError,
  useValidation,
} from "hooks";
import { ValidationErrors } from "hooks/useValidation/types";

const ValidationChapterContext = createContext<{
  validateHandler: Function;
  isSectionValidationLoading: Function;
  selectSectionValidationErrors: SelectSectionValidationErrors;
  resetSectionValidationErrors: Function;
  setSectionBlockValidationError: SetSectionBlockValidationError;
  validationErrors?: ValidationErrors;
}>({
  validateHandler: () => {},
  isSectionValidationLoading: () => {},
  setSectionBlockValidationError: (sectionId: string) => (blockId: string) => (
    errors: []
  ) => {},
  resetSectionValidationErrors: () => {},
  selectSectionValidationErrors: (sectionId: string) => {
    return {};
  },
  validationErrors: {},
});

export const ValidationChapterContextProvider = ({
  onValidate,
  children,
  chapterId,
  afterValidationCallback,
}) => {
  const {
    validationErrors,
    validateHandler,
    isSectionValidationLoading,
    selectSectionValidationErrors,
    resetSectionValidationErrors,
    setSectionBlockValidationError,
    resetValidationErrors,
  } = useValidation({
    // @ts-ignore
    onValidate,
    chapterId,
    afterValidationCallback,
  });

  useEffect(() => {
    resetValidationErrors();
  }, [chapterId]);

  return (
    <ValidationChapterContext.Provider
      value={{
        validationErrors,
        validateHandler,
        isSectionValidationLoading,
        selectSectionValidationErrors,
        resetSectionValidationErrors,
        setSectionBlockValidationError,
      }}
    >
      {children}
    </ValidationChapterContext.Provider>
  );
};

export default ValidationChapterContext;
