import { ObjectFieldTemplateProps } from "@rjsf/core";
import {
  PropertyType,
  TemplateProperty,
} from "components/SpecificationSection/types";
import { UiSchemaInfo } from "types/general";
import { isListEmpty } from "utils/general";

export const getPropertiesObjectFromArray = (
  properties: ObjectFieldTemplateProps["properties"]
) => {
  const propertyObjects: { [key: string]: TemplateProperty } = {};

  if (!isListEmpty(properties)) {
    properties.forEach(property => {
      propertyObjects[property.name] = property;
    });
  }

  return propertyObjects;
};

export const isSchemaTypeObject = (schemaType: PropertyType) =>
  schemaType === "object" || schemaType.includes("object");

export const hasValidationRequiredFields = validationRequired =>
  Array.isArray(validationRequired) && validationRequired.length;

export const getErrorsForValidationRequiredFields = ({
  validationRequiredFields,
  parentPath,
  rawErrors,
}) => {
  let errors = [];

  validationRequiredFields.forEach(requiredField => {
    rawErrors.forEach(rawError => {
      errors.push({
        path: `${parentPath}.${requiredField}`,
        error: rawError,
      });
    });
  });

  return errors;
};

export const createSectionDataTestId = (uiSchema: UiSchemaInfo) => {
  let dataTestId;
  // Checking if a ui:widget on top level is available
  if (uiSchema["ui:widget"]) {
    dataTestId = `${(uiSchema["ui:widget"] as string).replace(
      "Widget",
      ""
    )}-subsection`;
    return dataTestId;
  }

  Object.keys(uiSchema).forEach(key => {
    // Checking if the ui:title is available
    if (uiSchema[key]?.["ui:title"]) {
      dataTestId = `${uiSchema[key]["ui:title"].replace(
        "Title",
        ""
      )}-subsection`;
      return;
    }
    // Checking if a nested ui:widget is available
    if (uiSchema[key]?.["ui:widget"]) {
      dataTestId = `${uiSchema[key]["ui:widget"].replace(
        "Widget",
        ""
      )}-subsection`;
      return;
    }
  });
  return dataTestId;
};

export const getLabel = ({
  title,
  label,
  getTranslationForKey,
}: {
  title: string;
  label: string;
  getTranslationForKey: Function;
}) => (title === "" ? "" : label && getTranslationForKey(label));

export const getIsFormItemRequired = ({
  required,
  formItemName,
  validationRequired,
}: {
  required: boolean;
  formItemName: string;
  validationRequired: string[];
}) => required || validationRequired?.some(item => item === formItemName);
