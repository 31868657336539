import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Row, Col } from "antd";
import { Stepper } from "@trace-one/design-system";
import { Title } from "@trace-one/react-components";
import { useAppDispatch } from "store";
import {
  selectIsSaving,
  selectProperStep,
  selectFolderDetails,
  selectProductSpecifications,
  selectAreCollaborationsLoading,
  selectWizard,
} from "store/folderCreation/selectors";
import { resetFolderCreationErrors } from "store/folderCreation/folderCreationSlice";
import Spin from "components/Spin";
import CreationWizzard from "components/CreationWizzard";
import CreateCollaboration from "components/CreateCollaboration";
import {
  useSteps,
  useCreationActions,
} from "pages/Folder/components/Creation/hooks";
import { APPLICATION_TYPE_IDS, REFLIST_IDS } from "utils/constants";
import { getCompanyIdsWithLinkedProducts } from "./utils";
import { useFolderCollaboration } from "./hooks";
import { messages } from "./messages";

const Invitation = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const productSpecifications = useSelector(selectProductSpecifications);
  const {
    handleCreateCollaboration,
    handleRemoveCollaborations,
    handleChangeValidation,
  } = useFolderCollaboration();
  const {
    getOnFolderDelete,
    handleChangeEnableSupplierCollaboration,
  } = useCreationActions();
  const isSaving = useSelector(selectIsSaving);
  const areCollaborationsLoading = useSelector(selectAreCollaborationsLoading);
  const { folderId, collaborationIds } = useSelector(selectFolderDetails);

  const { isInvitationStepCompleted } = useSelector(selectProperStep);
  const wizard = useSelector(selectWizard);

  const {
    stepIndex,
    currentIsValid,
    steps,
    onPreviousStep,
    onNextStep,
  } = useSteps({
    currentStep: wizard.currentStep,
    stepsState: wizard.state,
  });

  useEffect(() => {
    dispatch(resetFolderCreationErrors());
  }, [dispatch]);

  return (
    <>
      <CreationWizzard.Title.Folder />
      <Stepper
        stepIndex={stepIndex}
        currentIsValid={currentIsValid}
        steps={steps}
      />
      <Title
        level={2}
        value={intl.formatMessage(messages.title)}
        subtitle={intl.formatMessage(messages.subtitle)}
      />

      {areCollaborationsLoading ? (
        <Spin />
      ) : (
        <Row gutter={[8, 0]}>
          <Col xs={24}>
            <CreateCollaboration
              applicationId={APPLICATION_TYPE_IDS.SPEC}
              parentDataObjectId={folderId}
              parentDataObjectSystem="SPEC"
              roleIds={[REFLIST_IDS.QUALITY_MANAGER_ROLE]}
              companyIdsWithLinkedProducts={getCompanyIdsWithLinkedProducts({
                productSpecifications,
                folderId,
                collaborations: wizard.collaborations,
              })}
              responsibilityIds={[REFLIST_IDS.QUALITY_MANAGER_RESPONSIBILITY]}
              onCreatedCollaboration={handleCreateCollaboration}
              onRemoveCollaborations={handleRemoveCollaborations}
              existingCollaborationIds={collaborationIds}
              onChangeValidation={handleChangeValidation}
              onChangeEnableSupplierCollaboration={
                handleChangeEnableSupplierCollaboration
              }
              isLoading={isSaving}
            />
          </Col>
        </Row>
      )}
      <CreationWizzard.Footer
        isSaving={isSaving}
        isNextDisabled={!isInvitationStepCompleted}
        onBackClick={onPreviousStep}
        onNextClick={onNextStep}
        onCancel={getOnFolderDelete()}
      />
    </>
  );
};

export default Invitation;
