import { defineMessages } from "react-intl";

export const messages = defineMessages({
  description: {
    id: "folderDetails.details.descripion",
    defaultMessage: "Description",
  },
  descriptionEmpty: {
    id: "folderDetails.details.descripion.empty",
    defaultMessage: "No description",
  },
});
