import { Row, Col } from "antd";
import { debounce } from "lodash";
import RowTitle from "components/RowTitle";
import PdfTable from "../../../components/PdfTable";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { useOptimizationCCP } from "../hooks";
import { useCharacteristicsField } from "./hooks";
import TablesModal from "../../../components/TablesModal";
import Tables from "../../../components/Tables";
import { isSpecificationPdfPage } from "utils/specifications";
import { CharacteristicsFieldTemplateProps } from "./types";
import { CCPTableNames } from "../hooks/types";

const CharacteristicsFieldTemplate: React.FC<CharacteristicsFieldTemplateProps> = ({
  uiSchema,
  formData,
  formContext,
  schema,
}) => {
  const { getTranslationForKey } = useFormSchemaTranslation();
  const title = uiSchema["ui:title"];
  const columns = uiSchema["ui:columns"];
  const { saveTemplateBlock, displayActions, page } = formContext;
  const tableId = "characteristics";
  const groups = [
    {
      characteristicId: tableId,
      id: tableId,
    },
  ];

  const { actualForm, handleChange } = useCharacteristicsField({
    formData,
    saveTemplateBlock,
    displayActions,
  });

  // needed for the table in modal
  const { refListDictionary, isInitialLoading } = useOptimizationCCP({
    formData,
    tableName: CCPTableNames.Characteristic,
  });

  if (isSpecificationPdfPage(page)) {
    return (
      <PdfTable
        formContext={formContext}
        columns={columns}
        formData={actualForm}
      />
    );
  }

  return (
    <Row gutter={[10, 10]}>
      {title && (
        <Col span={24}>
          <RowTitle
            title={getTranslationForKey(title)}
            displayActions={displayActions}
          />
        </Col>
      )}
      <Col span={24}>
        {isInitialLoading ? null : (
          <>
            <TablesModal
              title={getTranslationForKey(title)}
              entries={actualForm}
              formContext={{
                ...formContext,
                shouldCallApi: false,
                refListDictionary,
              }}
              groups={groups}
              idName={tableId}
              schema={schema}
              uiSchema={uiSchema}
              update={debounce(handleChange, 300)}
              canAddEmptyLine={false}
            />
            <Tables
              entries={actualForm}
              formContext={{
                ...formContext,
                shouldCallApi: false,
                refListDictionary,
              }}
              groups={groups}
              idName={tableId}
              schema={schema}
              uiSchema={uiSchema}
              update={debounce(handleChange, 300)}
              canAddEmptyLine={false}
            />
          </>
        )}
      </Col>
    </Row>
  );
};

export default CharacteristicsFieldTemplate;
