import React from "react";
import PropTypes from "prop-types";
import { Form } from "antd";
import { Heading } from "@trace-one/design-system";
import Table from "../Table";
import { getLabelFromListById } from "../../utils";
import { getTables } from "./utils";

const Tables = ({
  entries,
  formContext,
  groupId,
  groups,
  idName,
  isEmptyTableHidden,
  schema,
  uiSchema,
  update,
  withSubtitle,
  canAddEmptyLine,
}) => {
  const columns = uiSchema["ui:columns"];
  const tables = getTables({ groupId, idName, entries });

  return (
    <>
      {groups.map(key => {
        const { id } = key;
        const tableData = tables[id] || [];

        if (!isEmptyTableHidden || tableData.length > 0) {
          return (
            <div key={id}>
              <Form.Item
                label={
                  withSubtitle ? (
                    <Heading size="xxs">
                      {getLabelFromListById({
                        list: groups,
                        id: id,
                      })}
                    </Heading>
                  ) : null
                }
                labelCol={{ span: 24 }}
              >
                <Table
                  formData={tableData}
                  columns={columns}
                  onChange={event => update(event, id)}
                  schema={schema}
                  formContext={formContext}
                  idName={idName}
                  canAddEmptyLine={canAddEmptyLine}
                />
              </Form.Item>
            </div>
          );
        }
        return null;
      })}
    </>
  );
};

Tables.defaultProps = {
  groupId: null,
  idName: null,
  isEmptyTableHidden: false,
  withSubtitle: false,
  canAddEmptyLine: true,
};

Tables.propTypes = {
  entries: PropTypes.array.isRequired,
  formContext: PropTypes.object.isRequired,
  groups: PropTypes.array.isRequired,
  schema: PropTypes.object.isRequired,
  uiSchema: PropTypes.object.isRequired,
  update: PropTypes.func.isRequired,
  groupId: PropTypes.string,
  idName: PropTypes.string,
  isEmptyTableHidden: PropTypes.bool,
  withSubtitle: PropTypes.bool,
  canAddEmptyLine: PropTypes.bool,
};

export default Tables;
