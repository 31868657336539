import { defineMessages } from "react-intl";

export const messages = defineMessages({
  sign: {
    id: "specificationDetails.signature.sign",
    defaultMessage: "Sign",
  },
  waitingForRetailer: {
    id: "specificationDetails.signature.waiting.retailer",
    defaultMessage: "Waiting for retailer",
  },
  waitingForSupplier: {
    id: "specificationDetails.signature.waiting.supplier",
    defaultMessage: "Waiting for supplier",
  },
  dialogWarning: {
    id: "specificationDetails.signature.warning",
    defaultMessage:
      "Specification is linked to a draft Folder, if you proceed with the signature it will be removed from it",
  },
  confirmButton: {
    id: "general.yes",
    defaultMessage: "Yes",
  },
  cancelButton: {
    id: "general.no",
    defaultMessage: "No",
  },
});
