import { Button } from "@trace-one/design-system";
import { PackagingSystemComponentData } from "models";
import { PERMISSIONS } from "utils/constants";
import useRbac from "hooks/useRbac";
import { useActions } from "./hooks";

const Actions = ({
  packagingComponent,
  permissionList,
}: {
  packagingComponent: PackagingSystemComponentData;
  permissionList: string[];
}) => {
  const actions = useActions(packagingComponent);
  const { hasPermission } = useRbac(permissionList);
  const hasWritePermission = hasPermission(
    PERMISSIONS.PACKAGING_COMPONENT.WRITE
  );

  return (
    <Button
      type="tertiary"
      iconName="kebab"
      color="grey"
      items={actions}
      disabled={!hasWritePermission}
      dropdownProps={{ placement: "left" }}
    />
  );
};

export default Actions;
