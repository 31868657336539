/* istanbul ignore file */

import axios from "axios";
import SiteMasterDataApi from "@trace-one/api-clients.smd";

export const axiosInstance = axios.create({
  baseURL: window.env.SMD_URL,
});

export const SmdAPI = new SiteMasterDataApi(axiosInstance);

export const searchSites = ({
  ids,
  params,
}: {
  ids?: string[];
  params: object;
}) => SmdAPI.getSitesByFilters({ ownerCompanyIdCollection: ids }, params);

export const fetchSitesByIds = ({
  ids,
  params,
}: {
  ids: string[];
  params: object;
}) => SmdAPI.getSitesByIdCollection({ idCollection: ids }, params);
