import { useIntl } from "react-intl";
import { SelectValue } from "antd/lib/select";
import ReflistSelect from "components/ReflistSelect";
import { REFLIST_IDS } from "utils/constants";
import { messages } from "./messages";
import { ProductTypesProps } from "./types";

const ProductTypes: React.FC<ProductTypesProps> = ({
  selectedProductTypes,
  setSelectedProductTypes,
}) => {
  const { formatMessage } = useIntl();

  const handleChange = (value: SelectValue) => {
    setSelectedProductTypes(value);
  };

  return (
    <ReflistSelect
      mode="multiple"
      name="selectedProductTypes"
      placeholder={formatMessage(messages.productTypePlaceholder)}
      value={selectedProductTypes}
      onChange={handleChange}
      data-testid="product-type-ids"
      notFoundMessage={formatMessage(messages.noProductType)}
      refListId={REFLIST_IDS.PRODUCT_TYPES}
    />
  );
};

export default ProductTypes;
