import { Form } from "antd";
import { Paragraph } from "@trace-one/design-system";
import { FormItemProps } from "./types";

const FormItem: React.FC<FormItemProps> = ({
  label,
  value,
  emptyValue,
  dataTestId,
}) => {
  return (
    <Form.Item label={label} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
      <Paragraph data-test-id={dataTestId}>{value ?? emptyValue}</Paragraph>
    </Form.Item>
  );
};

export default FormItem;
