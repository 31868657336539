import { RequirementData, TradeItemData } from "models";
import { isListEmpty, isObjectEmpty } from "utils/general";
import { Requirement } from "store/folderCreation/types";
import { AppliedRequirements } from "viewModels/folderCreation";

export const shouldValidationOverflowBeDisplayed = ({
  appliedRequirements,
  selectedRequirements,
}: {
  appliedRequirements: AppliedRequirements;
  selectedRequirements: RequirementData[];
}) => isListEmpty(selectedRequirements) && isObjectEmpty(appliedRequirements);

export const getSelectAllMessageKey = ({
  assignedProducts,
  selectedProductIds,
}: {
  assignedProducts: TradeItemData[];
  selectedProductIds: TradeItemData["id"][];
}) =>
  selectedProductIds.length === assignedProducts.length
    ? "deselectAllBtn"
    : "selectAllBtn";

export const prepareAppliedRequirements = (requirements: Requirement[] = []) =>
  Object.values(requirements).reduce(
    (previousState: AppliedRequirements, currentRequirement) => {
      previousState[currentRequirement.requirementId] = {
        ...currentRequirement,
        tradeItemIds: new Set([...currentRequirement.tradeItemIds]),
      };

      return previousState;
    },
    {}
  );
