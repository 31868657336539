import { defineMessages } from "react-intl";

export const messages = defineMessages({
  generalInformationTitle: {
    id: "library.chapterForm.generalInfo.title",
    defaultMessage: "Define chapter information",
  },
  productTypeLabel: {
    id: "library.chapterForm.generalInfo.productType.label",
    defaultMessage: "Product type",
  },
  productTypePlaceholder: {
    id: "library.chapterForm.generalInfo.productType.placeholder",
    defaultMessage: "Enter a product type",
  },
  chapterTypeLabel: {
    id: "library.chapterForm.generalInfo.chapterType.label",
    defaultMessage: "Chapter type",
  },
  chapterTypePlaceholder: {
    id: "library.chapterForm.generalInfo.chapterType.placeholder",
    defaultMessage: "Enter a chapter type",
  },
  configureChapterTitle: {
    id: "library.chapterForm.configureChapter.title",
    defaultMessage: "Configure chapter",
  },
  selectChapterType: {
    id: "library.chapterForm.configureChapter.emptyMessage",
    defaultMessage:
      "Select a chapter type and a product type to start filling sections informations",
  },
  configureChapterDescription: {
    id: "library.chapterForm.configureChapter.description",
    defaultMessage:
      "You selected {chapterTypeName}. There are {sectionCount} section(s) in total.",
  },
  publishedState: {
    id: "library.chapterForm.actions.publishedState",
    defaultMessage: "Published",
  },
  chapterModificationTitle: {
    id: "library.chapterForm.chapterModification.title",
    defaultMessage: "Chapter modification",
  },
  chapterModificationDescription: {
    id: "library.chapterForm.chapterModification.description",
    defaultMessage:
      "Changing the chapter type will erase all filed informations.",
  },
  chapterModificationStartOverDescription: {
    id: "library.chapterForm.chapterModification.startOver.description",
    defaultMessage: "You will need to start over.",
  },
  cancelDeleteChapterTitle: {
    id: "library.chapterForm.cancelDeleteChapter.title",
    defaultMessage: "Cancel chapter creation",
  },
  cancelChapterVersionTitle: {
    id: "library.chapterForm.cancelChapterVersionTitle",
    defaultMessage: "Cancel chapter version",
  },
  cancelDeleteChapterDescription: {
    id: "library.chapterForm.cancelDeleteChapter.description",
    defaultMessage:
      "The chapter has been automatically saved as a draft. By leaving now, you can either keep it as a draft or cancel the creation and delete it.",
  },
  cancelChapterVersionDescription: {
    id: "library.chapterForm.cancelChapterVersionDescription",
    defaultMessage:
      "The chapter has been automatically saved as a draft. By leaving now, you can either keep it as a draft or cancel the version and delete it.",
  },
  cancelDeleteChapterDeleteBtn: {
    id: "library.chapterForm.cancelDeleteChapter.delete",
    defaultMessage: "Delete chapter",
  },
});
