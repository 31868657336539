import React, { useMemo } from "react";
import classnames from "classnames";
import { Col, Row } from "antd";
import { EmptyState, Button } from "@trace-one/design-system";
import { UploadFiles } from "@trace-one/business-components";
import { ArrayFieldTemplateProps } from "components/SpecificationSection/types";
import RowTitle from "components/RowTitle";
import ExpandableCardWithToggleAndDelete from "components/ExpandableCardWithToggleAndDelete";
import ErrorsList from "components/ErrorsList";
import SelectClaimsModal from "./components/SelectClaimsModal";
import ClaimsReadOnly from "./components/ClaimsReadOnly";
import { useModalVisibility, useClaimCollection } from "hooks";
import { useClaimFieldTemplate } from "./useClaimFieldTemplate";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { LIBRARY_OBJECT_TYPES } from "utils/constants";
import {
  getArraySchemaItemsPropertyIdByKey,
  getArraySchemaMaxItems,
  getFieldTitleFromUiSchemaArrayTemplate,
  isListEmpty,
} from "utils/general";
import { ClaimFormData } from "types/specification";
import styles from "./styles.module.less";

const ClaimsFieldTemplate: React.FC<
  ArrayFieldTemplateProps<ClaimFormData[]>
> = ({ formData = [], noItemsMessage, uiSchema, formContext, schema }) => {
  const {
    isTargetMode,
    extraActions,
    hasPermissionToSave,
    ownerCompanyId,
    isRetailerContact,
    isSpecContact,
    specificationData,
    extraErrors,
    isWidgetEditable,
    displayActions,
  } = formContext;

  const { getTranslationForKey } = useFormSchemaTranslation();
  const { isModalOpen, onOpenModal, onCloseModal } = useModalVisibility();
  const { claimCollection } = useClaimCollection({
    claimIds: formData.map(claim => claim?.claimId),
  });

  const {
    handleDisplayOnPackToggle,
    handleRemoveClaim,
    handleFilesUpload,
    handleFileRemove,
    handleEnter,
    claimFileMap,
  } = useClaimFieldTemplate({ formData, extraActions });

  const disabledClaimIds = useMemo(
    () => formData.map(({ claimId }) => claimId),
    [formData]
  );

  const errorIndexes = Object.keys(extraErrors?.claims ?? {}).map(Number);

  const maxItems = getArraySchemaMaxItems(schema);
  const maxSupportingDocuments =
    schema?.items?.properties?.supportingDocumentIds?.maxItems;
  const supportingDocumentsFileType =
    schema?.items?.properties?.supportingDocumentIds?.fileType;

  const propertyId = schema?.propertyId;
  const displayOnPackPropertyId = getArraySchemaItemsPropertyIdByKey(
    schema,
    "displayOnPack"
  );
  const supportingDocumentIdsPropertyId = getArraySchemaItemsPropertyIdByKey(
    schema,
    "supportingDocumentIds"
  );

  const readOnlyFieldIds = formContext.readOnlyFieldIds ?? [];

  const canUpdateFiles = isWidgetEditable(supportingDocumentIdsPropertyId);
  const canUpdateClaims = isWidgetEditable(propertyId);

  if (!displayActions) {
    return (
      <ClaimsReadOnly
        claimCollection={claimCollection}
        formData={formData}
        noItemsMessage={noItemsMessage}
      />
    );
  }

  return (
    <>
      {isListEmpty(claimCollection) ? (
        <Row justify="center">
          <EmptyState heading={noItemsMessage} />
        </Row>
      ) : (
        <div>
          <RowTitle title={getTranslationForKey("marketingClaim")}></RowTitle>
          {claimCollection?.map((claim, index) => {
            const { displayOnPack: isChecked, supportingDocumentIds } =
              formData.find(
                formDataClaim => formDataClaim?.claimId === claim?.id
              ) || {};

            const hasError = errorIndexes.includes(index);

            return (
              <React.Fragment key={claim?.id}>
                <ExpandableCardWithToggleAndDelete
                  key={claim?.id}
                  title={claim?.name}
                  description={
                    claim?.sentenceTranslations.find(
                      translation => translation.isDefault
                    ).text
                  }
                  removeConfirmationText={getTranslationForKey(
                    "unlinkClaimConfirmation"
                  )}
                  onEntered={() =>
                    handleEnter({
                      claimId: claim?.id,
                      fileIds: supportingDocumentIds,
                    })
                  }
                  onToggle={() => handleDisplayOnPackToggle(claim.id)}
                  toggleText={getFieldTitleFromUiSchemaArrayTemplate(
                    uiSchema,
                    "displayOnPack"
                  )}
                  toggleTextPlacement="left"
                  isChecked={isChecked}
                  libraryObjectType={LIBRARY_OBJECT_TYPES.CLAIMS}
                  isTargetMode={isTargetMode}
                  isToggleDisabled={
                    readOnlyFieldIds.includes(displayOnPackPropertyId) ||
                    !hasPermissionToSave
                  }
                  {...(canUpdateClaims && {
                    onRemove: () => handleRemoveClaim(claim.id),
                  })}
                  className={classnames("mb-2", {
                    [styles["card--error"]]: hasError,
                    [styles["card__action--error"]]:
                      canUpdateClaims && hasError,
                  })}
                  iconColor={hasError ? "red" : "primary"}
                >
                  <Col span={20}>
                    <UploadFiles.Dragger
                      disabled={!canUpdateFiles}
                      fileType={supportingDocumentsFileType}
                      fileList={
                        isListEmpty(claimFileMap?.[claim?.id])
                          ? []
                          : claimFileMap?.[claim?.id]
                      }
                      multiple={true}
                      maxCount={maxSupportingDocuments}
                      disabledFileIds={supportingDocumentIds}
                      {...(isSpecContact && {
                        ownerCompanyId: isRetailerContact
                          ? ownerCompanyId
                          : specificationData?.supplierId,
                      })}
                      {...(canUpdateFiles && {
                        onUploadFiles: handleFilesUpload(claim?.id),
                        onRemove: handleFileRemove(claim?.id),
                      })}
                      {...(hasError && {
                        hasError,
                        errorMessage: getTranslationForKey(
                          "claimSupportingDocumentFieldErrorMsg"
                        ),
                      })}
                    />
                  </Col>
                </ExpandableCardWithToggleAndDelete>
                {hasError && (
                  <div className={styles["errors__wrapper"]}>
                    <ErrorsList
                      list={[
                        getTranslationForKey(
                          "claimSupportingDocumentCardErrorMsg"
                        ),
                      ]}
                    />
                  </div>
                )}
              </React.Fragment>
            );
          })}
        </div>
      )}

      {canUpdateClaims && (
        <Row
          justify={isListEmpty(claimCollection) ? "center" : "start"}
          className={isListEmpty(claimCollection) ? "mt-2" : "mt-3"}
        >
          <Button
            onClick={onOpenModal}
            type="secondary"
            disabled={maxItems === formData.length}
          >
            {getTranslationForKey("addClaims")}
          </Button>
        </Row>
      )}

      {isModalOpen && (
        <SelectClaimsModal
          onCloseModal={onCloseModal}
          disabledClaimIds={disabledClaimIds}
          updateClaims={extraActions?.onUpdateClaims}
          formData={formData}
          maxItems={maxItems}
        />
      )}
    </>
  );
};

export default ClaimsFieldTemplate;
