import { Modal as DSModal } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import DMS from "./components/DMS";
import { getModalTitle } from "./utils";
import { messages } from "./messages";
import { UploadPhotosModalProps } from "./types";

const UPLOAD_PHOTO_MODAL_MIN_HEIGHT = "160px";

const UploadPhotosModal: React.FC<UploadPhotosModalProps> = ({
  onChange,
  onClose,
  data,
  photos,
  title,
  displayActions,
  ownerCompanyId,
}) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <DSModal.Simple
        open
        size="s"
        title={getModalTitle({
          defaultCharacteristicText: formatMessage(
            messages.defaultCharacteristicText
          ),
          characteristicText: data?.type,
          photosText: title,
        })}
        onCancel={onClose}
        bodyStyle={{
          minHeight: UPLOAD_PHOTO_MODAL_MIN_HEIGHT,
        }}
      >
        <DMS
          photos={photos}
          onChange={onChange}
          displayActions={displayActions}
          ownerCompanyId={ownerCompanyId}
        />
      </DSModal.Simple>
    </>
  );
};

UploadPhotosModal.defaultProps = {
  photos: [],
};

export default UploadPhotosModal;
