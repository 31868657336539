import { useEffect } from "react";
import { useIntl } from "react-intl";
import { Button } from "@trace-one/design-system";
import DetailsAndLinksChapterModal from "components/DetailsAndLinksChapterModal";
import Spin from "components/Spin";
import { useLinkedSpecifications, useModalVisibility } from "hooks";
import {
  isLibraryObjectChapterSubType,
  isLibraryObjectDraft,
} from "utils/library";
import { LibraryListItemViewModel } from "viewModels";
import { messages } from "../../../../messages";

const LinkedSpecifications = ({
  libraryItem: { id, type, state },
}: {
  libraryItem: LibraryListItemViewModel;
}) => {
  const { formatMessage } = useIntl();

  const {
    totalCount,
    getLinkedSpecifications,
    isLoading,
  } = useLinkedSpecifications({
    chapterType: type,
    chapterId: id,
  });

  const { isModalOpen, onOpenModal, onCloseModal } = useModalVisibility();

  useEffect(() => {
    if (!isLibraryObjectDraft(state) && isLibraryObjectChapterSubType(type)) {
      getLinkedSpecifications();
    }
  }, []);

  if (isLoading) return <Spin size="small" />;

  return (
    <>
      <Button
        type="link"
        children={totalCount.toString()}
        onClick={onOpenModal}
        disabled={totalCount === 0}
      />
      {isModalOpen && (
        <DetailsAndLinksChapterModal
          onCloseModal={onCloseModal}
          chapterId={id}
          chapterType={type}
          title={formatMessage(messages.detailsAndLinksChapterModalTitle)}
        />
      )}
    </>
  );
};

export default LinkedSpecifications;
