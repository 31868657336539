import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Editor } from "@trace-one/react-components";
import { Modal, Paragraph } from "@trace-one/design-system";
import { useBoolean } from "hooks";
import { messages } from "./messages";
import { UserGuideModalProps } from "./types";
import styles from "./styles.module.less";

const UserGuideModal: React.FC<UserGuideModalProps> = ({
  userGuide,
  onSave,
  onCancel,
  displayActions,
}) => {
  const { formatMessage } = useIntl();
  const [currentComment, setCurrentComment] = useState<string>(userGuide);
  const { value: isLoading, setTrue, setFalse } = useBoolean();

  const onPrimaryButtonClick = async () => {
    if (displayActions) {
      try {
        setTrue();
        await onSave(currentComment);
      } finally {
        setFalse();
      }
    } else {
      onCancel();
    }
  };

  return (
    <Modal.Simple
      title={formatMessage(messages.title)}
      open
      size="s"
      onPrimaryButtonClick={onPrimaryButtonClick}
      primaryButtonText={
        displayActions ? (
          <FormattedMessage {...messages.saveComment} />
        ) : (
          <FormattedMessage {...messages.cancelComment} />
        )
      }
      primaryButtonProps={{ loading: isLoading }}
      onSecondaryButtonClick={displayActions && onCancel}
      secondaryButtonText={
        displayActions && <FormattedMessage {...messages.cancelComment} />
      }
      onCancel={onCancel}
    >
      {displayActions ? (
        <>
          <Paragraph className={styles.modalSubtitle}>
            <FormattedMessage {...messages.subtitle} />
          </Paragraph>
          <Editor
            name="description"
            initData={currentComment}
            onChange={setCurrentComment}
            height={200}
            configProps={{ versionCheck: false }} // added versionCheck to suppress upgrade warning; remove after upgrade
          />
        </>
      ) : (
        <div dangerouslySetInnerHTML={{ __html: currentComment }} />
      )}
    </Modal.Simple>
  );
};

export default UserGuideModal;
