import { Input as DSInput } from "@trace-one/design-system";
import { useDebouncedActions } from "components/Debounced/hooks";
import { InputProps } from "./types";

const Input: React.FC<InputProps> = ({
  defaultValue,
  onChange,
  shouldApplyDebounceImmediate,
  debounceTimer,
  ...rest
}) => {
  const {
    currentValue,
    onTextChange,
    handleDebouncedValueChange,
  } = useDebouncedActions({
    defaultValue,
    debounceTimer,
    shouldApplyDebounceImmediate,
    onChange,
  });

  return (
    <DSInput
      onChange={onTextChange}
      onBlur={handleDebouncedValueChange}
      defaultValue={defaultValue}
      value={currentValue}
      allowClear={false}
      {...rest}
    />
  );
};

Input.defaultProps = {
  shouldApplyDebounceImmediate: true,
  debounceTimer: 2000,
};

export default Input;
