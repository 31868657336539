export const getColSpans = ({
  itemSpan,
  displayActions,
}: {
  itemSpan: number;
  displayActions: boolean;
}) => {
  if (displayActions) {
    return !!itemSpan
      ? {
          lg: itemSpan,
          md: 12,
          sm: 24,
          xs: 24,
        }
      : {
          xs: 24,
        };
  }

  return {
    xs: itemSpan ? 8 : 24,
  };
};
