import { createContext } from "react";
import {
  SelectSectionValidationErrors,
  SetSectionBlockValidationError,
} from "hooks";
import {
  ChapterAllergenData,
  MaterialIngredientData,
  MaterialRecipeData,
} from "models";
import { PageType } from "components/Library/components/types";
import { Sorter } from "types/pagination";

const SelectedSectionContext = createContext<{
  validateHandler: Function;
  isSectionValidationLoading: Function;
  selectSectionValidationErrors: SelectSectionValidationErrors;
  resetSectionValidationErrors: Function;
  setSectionBlockValidationError: SetSectionBlockValidationError;
  fetchRecipeIngredients?: () => Promise<MaterialIngredientData[]>;
  sortRecipeIngredient?: ({
    orderBy,
    orderByDescending,
  }: {
    orderBy: string;
    orderByDescending: boolean;
  }) => Promise<MaterialIngredientData[]>;
  recipeIngredients?: MaterialIngredientData[];
  fetchAllergens?: () => void;
  rawMaterialsAllergens?: ChapterAllergenData[];
  crossContaminationAllergens?: ChapterAllergenData[];
  chapterState?: string;
  page?: PageType;
  recipeSorter?: Sorter;
  totalPercentageOfRecipeIngredients?: MaterialRecipeData["totalPercentage"];
}>({
  validateHandler: () => {},
  isSectionValidationLoading: () => {},
  setSectionBlockValidationError: (sectionId: string) => (blockId: string) => (
    errors: []
  ) => {},
  resetSectionValidationErrors: () => {},
  selectSectionValidationErrors: (sectionId: string) => {
    return {};
  },
});

export default SelectedSectionContext;
