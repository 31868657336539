import classNames from "classnames";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import Menu from "../Menu";
import ModificationRequestPanel from "components/ModificationRequestPanel";
import { useMinified } from "../Menu/hooks";
import { useScroll } from "hooks";
import { selectIsRequestPanelOpen } from "store/specificationDetails/selectors";
import { selectLanguageCode } from "store/user/selectors";
import { modificationRequestMessages } from "messages";
import { ContentProps } from "./types";
import styles from "./styles.module.less";

const Content: React.FC<ContentProps> = ({
  sections,
  selectedSectionId,
  selectedSubSectionId,
  onClickSection,
  onClickSubSection,
  headerHeight,
  children,
  menuExtraActions,
  specificationId,
  selectedChapter,
  isSpecContact,
  userId,
}) => {
  const { formatMessage } = useIntl();
  const isRequestPanelOpen = useSelector(selectIsRequestPanelOpen);
  const languageCode = useSelector(selectLanguageCode);

  const { contentRef, ref: menuRef } = useScroll(headerHeight);
  const { isMinified, toggleMinified } = useMinified();

  return (
    <div
      className={classNames(styles.contentWrapper, styles.sticky, {
        "pt-4": !!specificationId,
      })}
    >
      <div className={styles.contentMenuWrapper} ref={menuRef}>
        <Menu
          sections={sections}
          selectedSectionId={selectedSectionId}
          selectedSubSectionId={selectedSubSectionId}
          onClickSection={onClickSection}
          onClickSubSection={onClickSubSection}
          style={{
            top: headerHeight,
          }}
          menuExtraActions={menuExtraActions}
          isMinified={isMinified}
          toggleMinified={toggleMinified}
        />
      </div>
      <div
        ref={contentRef}
        className={classNames(styles.childrenWrapper, {
          [styles.childrenWrapperMinified]: isMinified,
        })}
      >
        {children}
      </div>
      {isRequestPanelOpen && (
        <ModificationRequestPanel
          title={formatMessage(
            modificationRequestMessages.modificationRequests
          )}
          params={{
            specificationId,
            languageCode,
          }}
          onClickSubSection={onClickSubSection}
          selectedChapter={selectedChapter}
          isSpecContact={isSpecContact}
          userId={userId}
        />
      )}
    </div>
  );
};

export default Content;
