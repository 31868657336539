import { LibraryObjectData, TagData } from "models";
import { Dictionary } from "types/general";
import { LibraryObjectType } from "types/library";
import {
  LIBRARY_OBJECT_TYPES,
  LIBRARY_OBJECT_URL_TYPES,
  LIBRARY_OBJECT_STATES,
  LIBRARY_TYPE_GRAPHICS,
  PRODUCT_TYPES,
} from "utils/constants";
import {
  isLibraryObjectChaptersType,
  isLibraryObjectClaimType,
  isLibraryObjectMaterialType,
  isLibraryObjectPackagingSystemsType,
  isLibraryObjectRequirement,
  isLibraryObjectRequirementType,
  isLibraryObjectTemplatesType,
  isLibraryRequirementTypeText,
  isMaterialAdditiveType,
  isMaterialCompositeMadeInHouse,
  isMaterialProcessingAidType,
  isMaterialRawMaterialType,
  isProductTypeFAndV,
} from "utils/library";
import {
  LibraryItemActionItemViewModel,
  LibraryListItemViewModel,
} from "viewModels";

const prepareLibraryItemActionItemType = (libraryItem: LibraryObjectData) => {
  if (isLibraryObjectMaterialType(libraryItem?.materialType)) {
    if (isMaterialRawMaterialType(libraryItem?.materialType)) {
      return LIBRARY_OBJECT_TYPES.RAW_MATERIALS;
    }

    if (isMaterialAdditiveType(libraryItem?.materialType)) {
      return LIBRARY_OBJECT_TYPES.ADDITIVES;
    }

    if (isMaterialProcessingAidType(libraryItem?.materialType)) {
      return LIBRARY_OBJECT_TYPES.PROCESSING_AIDS;
    }

    let recipeType = "";

    if (libraryItem.hasOwnProperty("recipe")) {
      // @ts-ignore
      recipeType = libraryItem.recipe?.recipeType;
    } else {
      recipeType = libraryItem.recipeType;
    }

    if (isMaterialCompositeMadeInHouse(recipeType)) {
      return LIBRARY_OBJECT_TYPES.MADE_IN_HOUSE;
    }

    return LIBRARY_OBJECT_TYPES.BOUGHT_TO_THIRD_PARTY;
  }

  if (
    isLibraryObjectRequirement(libraryItem?.requirementType) ||
    isLibraryObjectRequirementType(libraryItem?.type)
  ) {
    if (isLibraryRequirementTypeText(libraryItem?.requirementType)) {
      return LIBRARY_OBJECT_TYPES.TEXT_REQUIREMENTS;
    }

    return LIBRARY_OBJECT_TYPES.DOCUMENT_REQUIREMENTS;
  }

  if (isLibraryObjectTemplatesType(libraryItem?.type)) {
    return LIBRARY_OBJECT_TYPES.TEMPLATES;
  }

  if (isLibraryObjectClaimType(libraryItem?.type)) {
    return LIBRARY_OBJECT_TYPES.CLAIMS;
  }

  return LIBRARY_OBJECT_URL_TYPES[libraryItem.type];
};

export const prepareLibraryItemActionItem = (
  libraryItem: LibraryObjectData
): LibraryItemActionItemViewModel => {
  return {
    id: libraryItem.id,
    state: libraryItem.state,
    lastVersion:
      libraryItem?.version?.activeVersion || libraryItem?.lastVersion,
    type: prepareLibraryItemActionItemType(libraryItem),
    name: libraryItem.name,
  };
};

const prepareLibraryListItemType = ({
  libraryItem,
  typeDictionary,
  type,
}: {
  libraryItem: LibraryObjectData;
  typeDictionary?: Dictionary<string>;
  type?: LibraryObjectType;
}) => {
  let itemType = "";

  if (isLibraryObjectMaterialType(type)) {
    if (
      isMaterialRawMaterialType(libraryItem?.materialType) ||
      isMaterialAdditiveType(libraryItem?.materialType) ||
      isMaterialProcessingAidType(libraryItem?.materialType)
    ) {
      itemType = libraryItem.materialType;
    }

    let recipeType = "";

    if (libraryItem.hasOwnProperty("recipe")) {
      // @ts-ignore
      recipeType = libraryItem.recipe?.recipeType;
    } else {
      recipeType = libraryItem.recipeType;
    }

    if (recipeType) {
      itemType = recipeType;
    }
  } else if (isLibraryObjectClaimType(libraryItem.claimType)) {
    itemType = libraryItem.claimType;
  } else if (isLibraryObjectRequirementType(type)) {
    itemType = libraryItem.requirementType;
  } else if (isLibraryObjectChaptersType(type)) {
    itemType = libraryItem.type;
  } else if (isLibraryObjectTemplatesType(type)) {
    if (isProductTypeFAndV(libraryItem?.productTypeId))
      itemType = PRODUCT_TYPES.F_AND_V;
    else itemType = PRODUCT_TYPES.FOOD;
  } else {
    if (libraryItem.hasOwnProperty("componentTypeId"))
      itemType = libraryItem.componentTypeId;
    else itemType = LIBRARY_OBJECT_TYPES.PACKAGING_SYSTEMS;
  }

  return typeDictionary?.[itemType] || itemType;
};

const prepareLibraryListItemState = (libraryItem: LibraryObjectData) => {
  if (libraryItem.state) {
    return libraryItem.state;
  }

  if (libraryItem.hasOwnProperty("allowsUpdate") && !libraryItem.allowsUpdate) {
    return LIBRARY_OBJECT_STATES.LOCKED;
  }

  return null;
};

const prepareLibraryListIconName = (
  libraryItem: LibraryObjectData,
  type: LibraryObjectType
) => {
  if (isLibraryObjectMaterialType(type)) {
    if (
      isMaterialRawMaterialType(libraryItem?.materialType) ||
      isMaterialAdditiveType(libraryItem?.materialType) ||
      isMaterialProcessingAidType(libraryItem?.materialType)
    ) {
      return LIBRARY_TYPE_GRAPHICS[libraryItem.materialType];
    }

    let recipeType = "";

    if (libraryItem.hasOwnProperty("recipe")) {
      // @ts-ignore
      recipeType = libraryItem.recipe?.recipeType;
    } else {
      recipeType = libraryItem.recipeType;
    }

    return LIBRARY_TYPE_GRAPHICS[recipeType];
  }

  if (isLibraryObjectRequirementType(type)) {
    return LIBRARY_TYPE_GRAPHICS["requirement"];
  }

  if (isLibraryObjectClaimType(type)) {
    return LIBRARY_TYPE_GRAPHICS[LIBRARY_OBJECT_TYPES.CLAIMS];
  }

  if (isLibraryObjectChaptersType(type)) {
    return LIBRARY_TYPE_GRAPHICS[libraryItem.type];
  }

  if (isLibraryObjectTemplatesType(type)) {
    return LIBRARY_TYPE_GRAPHICS[LIBRARY_OBJECT_TYPES.TEMPLATE];
  }

  if (isLibraryObjectPackagingSystemsType(type)) {
    if (libraryItem.hasOwnProperty("componentTypeId")) {
      return LIBRARY_TYPE_GRAPHICS[LIBRARY_OBJECT_TYPES.PACKAGING_COMPONENTS];
    } else {
      return LIBRARY_TYPE_GRAPHICS[LIBRARY_OBJECT_TYPES.PACKAGING_SYSTEMS];
    }
  }

  return LIBRARY_TYPE_GRAPHICS[LIBRARY_OBJECT_TYPES.PACKAGING_SYSTEMS];
};

export const prepareLibraryListItemFromApiData = ({
  libraryItem,
  tags = [],
  typeDictionary,
  type,
  productTypes = {},
}: {
  libraryItem: LibraryObjectData;
  tags?: TagData[];
  typeDictionary?: Dictionary<string>;
  type?: LibraryObjectType;
  productTypes?: {
    [key: string]: string;
  };
}) => {
  let newLibraryItem: LibraryListItemViewModel = {
    id: libraryItem.id,
    name: libraryItem.name,
    state: prepareLibraryListItemState(libraryItem),
    versionNumber: libraryItem?.versionNumber ?? libraryItem?.version?.number,
    type: prepareLibraryListItemType({ libraryItem, type }),
    formattedType: prepareLibraryListItemType({
      libraryItem,
      typeDictionary,
      type,
    }),
    productType: productTypes[libraryItem?.productTypeId],
    lastUpdateDateUtc: libraryItem?.lastUpdateDateUtc,
    iconName: prepareLibraryListIconName(libraryItem, type),
    tags: tags?.reduce((previousState, { tagId, tagText }) => {
      let currentState = [...previousState];
      if (libraryItem?.tagIds?.includes(tagId)) {
        currentState.push(tagText);
      }

      return currentState;
    }, []),
    logoFileId: libraryItem.logoFileId,
    sentenceTranslations: libraryItem?.sentenceTranslations ?? [],
  };

  return newLibraryItem;
};
