import { EnhancedExtraActions } from "components/SpecificationSection/types";
import { File } from "models";

const useTableActions = ({
  addAllergen,
  removeAllergen,
  uploadFile,
  removeFile,
  fetchAllergens,
}: {
  addAllergen: EnhancedExtraActions["addFreeFromAllergens"];
  removeAllergen: EnhancedExtraActions["removeFreeFromAllergen"];
  uploadFile: EnhancedExtraActions["updateFreeFromAllergen"];
  removeFile: EnhancedExtraActions["updateFreeFromAllergen"];
  fetchAllergens: EnhancedExtraActions["fetchAllergens"];
}) => {
  const onAddAllergens = async (selectedObjects: { id: string }[]) => {
    const allergenIds = selectedObjects.map(item => item.id);
    addAllergen(allergenIds);
  };

  const onRemoveAllergen = ({
    allergenId,
  }: {
    allergenId: string;
  }) => async () => {
    removeAllergen({ allergenId });
  };

  const onUploadFile = ({ allergenId }: { allergenId: string }) => async (
    files: File[]
  ) => {
    uploadFile({
      fileId: files[0].id,
      allergenId,
    });
  };

  const onRemoveFile = ({ allergenId }: { allergenId: string }) => async () => {
    removeFile({
      allergenId,
    });
  };

  const onFetchAllergens = () => fetchAllergens();

  return {
    onAddAllergens,
    onRemoveAllergen,
    onUploadFile,
    onRemoveFile,
    onFetchAllergens,
  };
};

export default useTableActions;
