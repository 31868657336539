import { useRef } from "react";
import { Modal, Search } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import InfiniteScrollLibraryContent from "components/InfiniteScrollLibraryContent";
import { TemplateModalProps } from "./types";
import { useSelectedLibraryObject } from "hooks";
import useTemplateModal from "./useTemplateModal";
import { messages } from "./messages";
import generalMessages from "messages/general";
import styles from "./styles.module.less";

const TemplateModal: React.FC<TemplateModalProps> = ({
  onCloseModal,
  onPrimaryButtonClick,
  existingItemsIds = [],
  productTypeId,
}) => {
  const { formatMessage } = useIntl();
  const infiniteScrollWrapper = useRef<HTMLDivElement>(null);

  const { onSelectLibraryObject, selectedItem } = useSelectedLibraryObject({
    existingLibraryObjectIds: existingItemsIds,
    isSingleSelect: true,
  });

  const {
    templates,
    canLoadMore,
    totalNumberOfItems,
    searchText,
    setSearchText,
    loadTemplates,
  } = useTemplateModal({
    infiniteScrollWrapper,
    productTypeId,
  });

  const handlePrimaryButtonClick = () => {
    onPrimaryButtonClick(selectedItem);
  };

  return (
    <Modal
      open
      size="l"
      onCancel={onCloseModal}
      title={formatMessage(messages.templateModalTitle)}
      headerChildren={
        <Search
          className={styles.searchBar}
          onSearch={setSearchText}
          placeholder={formatMessage(messages.searchPlaceholder)}
        />
      }
      onPrimaryButtonClick={handlePrimaryButtonClick}
      onSecondaryButtonClick={onCloseModal}
      primaryButtonText={formatMessage(generalMessages.select)}
      secondaryButtonText={formatMessage(generalMessages.cancel)}
      primaryButtonProps={{
        disabled: !selectedItem,
      }}
    >
      <InfiniteScrollLibraryContent
        title={formatMessage(messages.allTemplates)}
        totalNumberOfItems={totalNumberOfItems}
        canLoadMore={canLoadMore}
        libraryObjectList={templates}
        nextFetchCallback={() => loadTemplates({ searchText })}
        onSelectLibraryItem={onSelectLibraryObject}
        existingLibraryObjectIds={existingItemsIds}
        selectedLibraryObjectIds={[selectedItem?.id]}
        infiniteScrollWrapper={infiniteScrollWrapper}
      />
    </Modal>
  );
};

export default TemplateModal;
