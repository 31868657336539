const getSelectedOwnerContactsNames = ownerContacts =>
  ownerContacts
    .reduce((previousState, { isSelected, userFirstName, userLastName }) => {
      let currentState = [...previousState];
      if (isSelected) {
        currentState.push(`${userFirstName} ${userLastName}`);
      }

      return currentState;
    }, [])
    .join(", ");

export default getSelectedOwnerContactsNames;
