import { RefListActions } from "./types";

export const getOnChangeHandlerRefList = ({
  saveTemplateBlock,
  inputGroupName,
  setActualForm,
  itemKey = "id",
}: {
  saveTemplateBlock: Function;
  inputGroupName: string;
  setActualForm: Function;
  itemKey?: string;
}) => ({ name, action }: { name: string; action: RefListActions }) => (data: {
  value: string;
  label: string;
}) => {
  setActualForm(previousState => {
    let dataToSend;

    if (action === RefListActions.add) {
      dataToSend = [
        ...(previousState?.[inputGroupName] || []),
        { [name]: data.value },
      ];
    } else if (action === RefListActions.remove) {
      dataToSend = previousState[inputGroupName].filter(
        item => item[itemKey] !== data.value
      );
    }

    const newState = {
      ...previousState,
      [inputGroupName]: dataToSend,
    };

    saveTemplateBlock(JSON.stringify(newState), JSON.stringify(previousState));

    return newState;
  });
};
