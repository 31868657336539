import { Tooltip, Checkbox as DSCheckbox } from "@trace-one/design-system";
import { Divider } from "antd";
import { CheckboxProps } from "components/Card/components/Object/types";
import { useCheckbox } from "./hooks";
import styles from "../../styles.module.less";

const Checkbox: React.FC<CheckboxProps> = ({ tooltipTitle, ...props }) => {
  const checkboxProps = useCheckbox(props);

  return (
    <>
      {!!tooltipTitle ? (
        <Tooltip title={tooltipTitle}>
          <DSCheckbox {...checkboxProps} />
        </Tooltip>
      ) : (
        <DSCheckbox {...checkboxProps} />
      )}
      <Divider type="vertical" className={styles.divider} />
    </>
  );
};

export default Checkbox;
