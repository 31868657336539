import Section from "./components/Section";
import { SectionsProps } from "./types";
import styles from "./styles.module.less";

const Sections: React.FC<SectionsProps> = ({
  sections,
  parentSectionId,
  parentChapterType,
}) => {
  return (
    <ul className={styles.sections} data-test-id="menu-item-sections">
      {sections.map(section => (
        <li key={section.sectionId}>
          <Section
            section={section}
            parentSectionId={parentSectionId}
            parentChapterType={parentChapterType}
          />
        </li>
      ))}
    </ul>
  );
};

export default Sections;
