import { createContext } from "react";
import { Row, Col } from "antd";
import { getPropertiesObjectFromArray } from "../../templates/utils";
import { CompanyWidgetProps } from "./types";
import styles from "./styles.module.less";

export const CompanyContext = createContext({
  companyId: "",
  colSpans: {},
});

const CompanyWidget: React.FC<CompanyWidgetProps> = ({
  properties,
  formContext,
  formData,
}) => {
  const { displayActions } = formContext;
  const propertyObjects = getPropertiesObjectFromArray(properties);

  const colSpans = {
    xs: displayActions ? 24 : 12,
    sm: displayActions ? 8 : 12,
    md: displayActions ? 8 : 12,
    lg: 8,
  };

  return (
    <Row gutter={[30, 30]} className={styles.contactCardList}>
      <Col {...colSpans}>{propertyObjects.companyId.content}</Col>
      <CompanyContext.Provider
        value={{ companyId: formData.companyId, colSpans }}
      >
        {propertyObjects.contacts.content}
      </CompanyContext.Provider>
    </Row>
  );
};

export default CompanyWidget;
