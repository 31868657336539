import { UploadFiles } from "@trace-one/business-components";
import { Heading } from "@trace-one/design-system";
import { FormattedMessage } from "react-intl";
import { useFiles } from "./hooks";
import { FILE_TYPES } from "utils/constants";
import { messages } from "./messages";
import { FilesProps } from "./types";
import styles from "./styles.module.less";

const Files: React.FC<FilesProps> = ({
  files,
  chapters,
  onUpdate,
  ownerCompanyId,
}) => {
  const fileIds = files?.map(({ fileId }) => fileId);

  const { onUploadFiles, onRemove, filesWithDropdown } = useFiles({
    files,
    chapters,
    onUpdate,
  });

  return (
    <div className={styles.uploadFiles}>
      <Heading size="xxs" className={styles.info}>
        <FormattedMessage {...messages.documentToShare} />
      </Heading>
      <UploadFiles.Dragger
        multiple
        fileType={FILE_TYPES.SPECIFICATION_ATTACHMENT}
        onUploadFiles={onUploadFiles}
        fileList={filesWithDropdown}
        onRemove={onRemove}
        size="large"
        showDropdown={true}
        disabledFileIds={fileIds}
        ownerCompanyId={ownerCompanyId}
      />
    </div>
  );
};

export default Files;
