import { useMemo } from "react";
import { Row, Col } from "antd";
import { Label } from "@trace-one/react-components";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { selectUnitsOfMeasure } from "store/generalData/selectors";
import { formatNetContents } from "utils/form";
import { getValueFromProductProperty } from "./utils";
import { InfoProps } from "./types";
import { messages } from "./messages";

const Info: React.FC<InfoProps> = ({ product }) => {
  const intl = useIntl();
  const unitsOfMeasure = useSelector(selectUnitsOfMeasure);

  const colSpans = {
    xs: 24,
    sm: 12,
    md: 12,
    lg: 6,
  };

  const getValueFromProperty = getValueFromProductProperty(product);

  const netContentsTexts = useMemo(
    () =>
      formatNetContents({
        netContents: product.netContents,
        unitsOfMeasure,
        separator: ", ",
        emptyValue: "-",
      }),
    [product.netContents, unitsOfMeasure]
  );

  return (
    <Row gutter={[16, 0]}>
      <Col {...colSpans}>
        <Label title={intl.formatMessage(messages.productName)}>
          {getValueFromProperty("itemName")}
        </Label>
      </Col>
      <Col {...colSpans}>
        <Label title={intl.formatMessage(messages.gtin)}>
          {getValueFromProperty("gtin")}
        </Label>
      </Col>
      <Col {...colSpans}>
        <Label title={intl.formatMessage(messages.category)}>
          {getValueFromProperty("categoryName")}
        </Label>
      </Col>
      <Col {...colSpans}>
        <Label title={intl.formatMessage(messages.type)}>
          {intl.formatMessage(messages.comingSoon)}
        </Label>
      </Col>
      <Col {...colSpans}>
        <Label title={intl.formatMessage(messages.productBrand)}>
          {getValueFromProperty("brandName")}
        </Label>
      </Col>

      <Col {...colSpans}>
        <Label title={intl.formatMessage(messages.netContents)}>
          {netContentsTexts}
        </Label>
      </Col>
    </Row>
  );
};

export default Info;
