import { Form } from "antd";
import { useIntl } from "react-intl";
import { Heading } from "@trace-one/design-system";
import { Editor } from "@trace-one/react-components";
import { useDebouncedActions } from "components/Debounced/hooks";
import { useRequirementInfoActions } from "components/Library/components/RequirementForm/hooks";
import libraryMessages from "messages/library";
import { TextProps } from "./types";
import styles from "../../styles.module.less";

const Text: React.FC<TextProps> = ({
  content,
  isLocked,
  displayActions = true,
}) => {
  const { formatMessage } = useIntl();
  const { onSaveContent } = useRequirementInfoActions();

  const {
    onTextareaChange: onContentChange,
    handleDebouncedValueChange: onContentBlur,
  } = useDebouncedActions({
    defaultValue: content,
    onChange: onSaveContent,
  });

  if (!displayActions) {
    return (
      <div className={styles.contentInfo}>
        <Heading size="xxs">
          {formatMessage(libraryMessages.contentLabel)}
        </Heading>
        <div
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
      </div>
    );
  }

  return (
    <Form.Item
      wrapperCol={{
        span: 24,
      }}
      rules={[{ required: true, whitespace: true }]}
      name="content"
      label={formatMessage(libraryMessages.contentLabel)}
    >
      <Editor
        name="content"
        initData={content}
        readOnly={isLocked}
        onChange={onContentChange}
        onBlur={onContentBlur}
        configProps={{ versionCheck: false }} // added versionCheck to suppress upgrade warning; remove after upgrade
      />
    </Form.Item>
  );
};

export default Text;
