import { Col, Row } from "antd";
import { Button, Heading, Icon, Paragraph } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import { useTag } from "hooks";
import { PANEL_DATA_HEADER_ID } from "utils/constants";
import { ModificationRequestViewModel } from "viewModels/modificationRequest";
import { modificationRequestMessages } from "messages";
import styles from "../../styles.module.less";

interface DiscussionPanelHeaderProps {
  onClose: Function;
  modificationRequest: ModificationRequestViewModel;
}

const DiscussionPanelHeader: React.FC<DiscussionPanelHeaderProps> = ({
  onClose,
  modificationRequest,
}) => {
  const { formatMessage } = useIntl();

  const { state } = modificationRequest || {};
  const { tag } = useTag(state);

  return (
    <Row
      align="middle"
      gutter={[16, 0]}
      className={styles["discussion-panel-header"]}
      data-header-id={PANEL_DATA_HEADER_ID}
    >
      <Col span={3}>
        <Button
          type="tertiary"
          iconName="long-arrow-left"
          color="amethyst"
          data-test-id="discussion-panel-back-icon"
          onClick={onClose}
        />
      </Col>
      <Col span={18}>
        <Row justify="center" gutter={[8, 0]}>
          <Col>
            <Icon
              name="request-modification-full"
              size="medium"
              color={tag?.color}
            />
          </Col>
          <Col>
            <Heading size="xxs">
              {formatMessage(modificationRequestMessages.modificationRequest)}
            </Heading>
          </Col>
        </Row>
        <Row
          justify="center"
          className={styles["discussion-panel-header__field-name"]}
        >
          <Paragraph size="s" color="amethyst">
            {modificationRequest?.targetField?.fieldName}
          </Paragraph>
        </Row>
      </Col>
      <Col span={3}></Col>
    </Row>
  );
};

export default DiscussionPanelHeader;
