import { isLibraryObjectPackagingSystemsType } from "utils/library";

export const getDefaultSortOrderForColumn = ({
  orderBy,
  orderByDescending,
}: {
  orderBy?: string;
  orderByDescending?: boolean;
}) => (column: string) => {
  if (!orderBy || orderBy !== column) return false;

  return !orderByDescending ? "ascend" : "descend";
};

export const shouldTypeColumnBeDisplayed = (type: string) =>
  !isLibraryObjectPackagingSystemsType(type);
