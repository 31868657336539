import { isListEmpty, isUuidEmpty } from "utils/general";
import {
  MaterialRecipeIngredientViewModel,
  MaterialSupplierViewModel,
} from "viewModels";

export const getRefListIdsFromSuppliers = (
  suppliers: MaterialSupplierViewModel[] = []
) => {
  let refListIds = [];

  suppliers?.forEach(({ origins }) => {
    if (!isListEmpty(origins)) {
      origins.forEach(origin => {
        refListIds.push.apply(
          refListIds,
          Object.values(origin).filter(value => !isUuidEmpty(value as string))
        );
      });
    }
  });

  return refListIds;
};

export const getCategoryIdsFromIngredients = (
  ingredients: MaterialRecipeIngredientViewModel[] = []
) => {
  return ingredients.reduce(
    (previousState, { classificationId, functionId }) => {
      let newState = [...previousState];

      if (!isUuidEmpty(classificationId)) {
        newState.push(classificationId);
      }

      if (!isUuidEmpty(functionId)) {
        newState.push(functionId);
      }

      return newState;
    },
    []
  );
};
