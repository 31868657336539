import { isListEmpty } from "utils/general";
import { MaterialIngredientData } from "models";

export const prepareIngredientsFunctionIdsData = ({
  ingredientData,
  functionIds = [],
}: {
  ingredientData: MaterialIngredientData[];
  functionIds?: string[];
}) => {
  if (isListEmpty(ingredientData)) return [];

  return ingredientData.reduce(
    (previousState, currentItem) => {
      let newState = [...previousState];
      if (currentItem["functionId"]) {
        newState = [...previousState, currentItem["functionId"]];
      }

      if (!isListEmpty(currentItem["ingredients"])) {
        return [
          ...prepareIngredientsFunctionIdsData({
            ingredientData: currentItem["ingredients"],
            functionIds: newState,
          }),
        ];
      }

      return [...newState];
    },
    [...functionIds]
  );
};
