import { convertArrayToObjectOfArrays } from "../../utils";

export const getTables = ({ groupId, idName, entries }) => {
  return groupId
    ? convertArrayToObjectOfArrays({
        array: entries,
        id: groupId,
      })
    : {
        [idName]: entries,
      };
};
