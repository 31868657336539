import ConfirmationStepPanel from "./components/ConfirmationStepPanel";
import LoadingStepPanel from "./components/LoadingStepPanel";
import SelectSuppliersStepPanel from "./components/SelectSuppliersStepPanel";
import { ModalBodyProps } from "./types";

const ModalBody: React.FC<ModalBodyProps> = ({
  isConfirmedDuplicationStep,
  isDuplicationInProgress,
  tradeItemId,
  tradeItemName,
  supplierListWithSuccess,
  supplierListWithError,
  onModalClose,
  onViewClick,
  onListingClick,
  onDuplicateSpecification,
}) => {
  if (isConfirmedDuplicationStep) {
    return (
      <ConfirmationStepPanel
        tradeItemName={tradeItemName}
        onViewClick={onViewClick}
        onListingClick={onListingClick}
        supplierListWithSuccess={supplierListWithSuccess}
        supplierListWithError={supplierListWithError}
      />
    );
  }

  if (isDuplicationInProgress) {
    return <LoadingStepPanel />;
  }

  return (
    <SelectSuppliersStepPanel
      tradeItemId={tradeItemId}
      onDuplicateSpecification={onDuplicateSpecification}
      onModalClose={onModalClose}
    />
  );
};

export default ModalBody;
