import { Modal, Paragraph } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import generalMessages from "messages/general";
import { ConfirmationModalProps } from "./types";

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  onConfirmModal,
  onCloseModal,
  title,
  children,
  primaryButtonText,
  illustrationName = "warning",
  illustrationColor = "grey",
}) => {
  const { formatMessage } = useIntl();

  return (
    <Modal.Simple
      open
      size="s"
      keyboard={false}
      onCancel={onCloseModal}
      onPrimaryButtonClick={onConfirmModal}
      primaryButtonText={
        primaryButtonText ?? formatMessage(generalMessages.confirmAndEdit)
      }
      onSecondaryButtonClick={onCloseModal}
      secondaryButtonText={formatMessage(generalMessages.cancel)}
      illustrationName={illustrationName}
      illustrationColor={illustrationColor}
      title={title}
    >
      <Paragraph>{children}</Paragraph>
    </Modal.Simple>
  );
};

export default ConfirmationModal;
