import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const useDetectLeavingPage = ({ currentPath }: { currentPath: string }) => {
  const history = useHistory();
  const [wantsToLeaveThePage, setWantsToLeaveThePage] = useState(false);
  const [intendedLeavingPage, setIntendedLeavingPage] = useState("");
  const [isLeavingPageConfirmed, setIsLeavingPageConfirmed] = useState(false);

  const declineLeavingThePage = () => {
    setWantsToLeaveThePage(false);
  };
  const confirmLeavingThePage = () => {
    setIsLeavingPageConfirmed(true);
  };

  const handleGoToIntendedPage = () => {
    history.push(intendedLeavingPage);
  };

  useEffect(() => {
    if (isLeavingPageConfirmed) {
      handleGoToIntendedPage();
    }
    //@ts-ignore
    const unblock = history.block(location => {
      //@ts-ignore
      if (location?.state?.skipDetectLeavePage) {
        return true;
      }

      if (location.pathname !== currentPath) {
        setWantsToLeaveThePage(true);
      }

      setIntendedLeavingPage(location.pathname);
      //@ts-ignore
      if (isLeavingPageConfirmed) {
        return true;
      }
      return false;
    });

    return () => {
      unblock();
    };
  }, [history, isLeavingPageConfirmed]);

  return {
    wantsToLeaveThePage,
    declineLeavingThePage,
    confirmLeavingThePage,
    intendedLeavingPage,
  };
};

export default useDetectLeavingPage;
