import { Icon, ProgressBar } from "@trace-one/design-system";
import dayjs from "dayjs";
import { getPercentValue } from "utils/general";
import Status from "components/Status";
import Actions from "./components/Actions";
import { messages } from "./messages";

export const getColumns = ({ intl, onCellClick }) => [
  {
    title: intl.formatMessage(messages.folderName),
    dataIndex: "folderName",
    key: "folderName",
    sorter: true,
    onCell: onCellClick,
  },
  {
    title: intl.formatMessage(messages.productType),
    dataIndex: "productType",
    sorter: true,
    onCell: onCellClick,
  },
  {
    title: intl.formatMessage(messages.owner),
    dataIndex: "ownerUserName",
    sorter: true,
    onCell: onCellClick,
  },
  {
    title: intl.formatMessage(messages.creationDate),
    dataIndex: "creationDateUtc",
    sorter: true,
    render: date => dayjs(date).local().format("L"),
    onCell: onCellClick,
  },
  {
    title: intl.formatMessage(messages.lastUpdate),
    dataIndex: "lastUpdateDateUtc",
    sorter: true,
    render: date => dayjs(date).local().format("L"),
    onCell: onCellClick,
  },
  {
    title: intl.formatMessage(messages.targetDate),
    dataIndex: "targetDateUtc",
    sorter: true,
    render: date =>
      dayjs(date).isValid() ? dayjs(date).local().format("L") : "",
    onCell: onCellClick,
  },
  {
    title: intl.formatMessage(messages.progression),
    dataIndex: "progression",
    render: percent => (
      <ProgressBar
        responsive
        color="primary"
        percent={getPercentValue(percent)}
      />
    ),
    onCell: onCellClick,
  },
  {
    title: intl.formatMessage(messages.status),
    dataIndex: "state",
    render: state => <Status.Tag state={state} />,
    onCell: onCellClick,
  },
  {
    title: () => <Icon name="settings" color="white" />,
    render: (_, folder) => <Actions folder={folder} />,
  },
];

export const getOnCellClick = ({ redirectToFolderDetails }) => record => ({
  onClick: _ => {
    redirectToFolderDetails(record.folderId);
  },
});
