import classnames from "classnames";
import { Paragraph } from "@trace-one/design-system";
import Info from "./components/Info";
import Actions from "./components/Actions";
import styles from "./styles.module.less";
import { ObjectProps, Types } from "./types";

const ObjectBox: React.FC<ObjectProps> = ({
  actions = [],
  actionsProps,
  displayActions = true,
  errorMessage,
  hasError,
  isDisabled,
  isSelected,
  onDelete,
  checkboxProps = {
    hasCheckbox: false,
    isChecked: false,
    onChange: () => {},
  },
  onClick,
  status = "",
  object = {
    type: Types.SPECIFICATION,
  },
}) => {
  const { versionNumber } = object;

  return (
    <>
      <div
        className={classnames(styles.wrapper, {
          [styles.hasError]: hasError,
          [styles.isDisabled]: isDisabled,
          [styles.isSelected]: isSelected,
        })}
      >
        <Info
          isDisabled={isDisabled}
          object={object}
          onClick={onClick}
          checkboxProps={checkboxProps}
        />
        <Actions
          versionNumber={versionNumber}
          isDisabled={isDisabled}
          status={status}
          displayActions={displayActions}
          onDelete={onDelete}
          actions={actions}
          actionsProps={actionsProps}
        />
      </div>
      {hasError && <Paragraph color="red">{errorMessage}</Paragraph>}
    </>
  );
};

export default ObjectBox;
