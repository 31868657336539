import classnames from "classnames";
import { getNumberOfItemsColor } from "components/SpecificationSection/components/FormSchema/components/NutritionBasic/utils";
import { RootNutrientNameProps } from "./types";
import styles from "components/SpecificationSection/components/FormSchema/components/NutritionBasic/styles.module.less";

const RootNutrientName: React.FC<RootNutrientNameProps> = ({
  nutrientName,
  statusData,
}) => {
  const completedNutrients = statusData.completedNutrients;
  const totalNutrients = statusData.totalNutrients;

  const progressStatus = `(${completedNutrients}/${totalNutrients})`;

  return (
    <>
      {nutrientName}
      <span
        className={classnames(
          styles.nutrientFamilyStatus,
          styles[
            getNumberOfItemsColor({
              numberOfCompletedItems: completedNutrients,
              numberOfItems: totalNutrients,
            })
          ]
        )}
      >
        {` ${progressStatus}`}
      </span>
    </>
  );
};

export default RootNutrientName;
