import { useEffect, useState } from "react";
import { getAllIngredientIds } from "./utils";
import { MaterialRecipeIngredientViewModel } from "viewModels";

const useExpandedRowKeys = ({
  ingredients,
}: {
  ingredients: MaterialRecipeIngredientViewModel[];
}) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

  const onExpandedRowsChange = (newExpandedRowKeys: string[]) => {
    setExpandedRowKeys(newExpandedRowKeys);
  };

  const onAddExpandedRowKey = (rowKey: string) => {
    setExpandedRowKeys(previousState => [...previousState, rowKey]);
  };

  useEffect(() => {
    setExpandedRowKeys(getAllIngredientIds(ingredients));
  }, []);

  return { expandedRowKeys, onExpandedRowsChange, onAddExpandedRowKey };
};
export default useExpandedRowKeys;
