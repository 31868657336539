import { TemplateProperty } from "components/SpecificationSection/types";

export const getOnChangeHandle = ({
  setCompactData,
  propertyObject,
}: {
  setCompactData?: Function;
  propertyObject?: {
    [key: string]: TemplateProperty;
  };
}) => (name: string) => (value: string | number) => {
  setCompactData(previousState => ({
    ...previousState,
    [name]: value,
  }));

  propertyObject[name].content.props.onChange(
    value === null ? undefined : value
  );
};
