import { useState, useContext } from "react";
import { Grid } from "antd";
import { Button, Tooltip } from "@trace-one/design-system";
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { selectLanguageCode } from "store/user/selectors";
import { useRbac } from "hooks";
import ConfirmationDialog from "components/ConfirmationDialog";
import ConditionalWrapper from "components/ConditionalWrapper";
import { MenuContext } from "components/SpecificationSection/components/Menu/contexts";
import { PERMISSIONS } from "utils/constants";
import {
  getSignProperties,
  getOnConfirmClick,
  getOnVisibleChange,
  getOnButtonClick,
  getPlacementForConfirmationDialog,
} from "./utils";
import { messages } from "./messages";
import { SignButtonProps } from "./types";
import styles from "./styles.module.less";

const { useBreakpoint } = Grid;

const SignButton: React.FC<SignButtonProps> = ({
  handleOpenModal,
  isRetailer,
  permissionList,
  specificationState,
  isTargetMode,
}) => {
  const { formatMessage } = useIntl();
  const { isMinified } = useContext(MenuContext);
  const languageCode = useSelector(selectLanguageCode);
  const { hasPermission } = useRbac(permissionList);
  const hasSignPermission = hasPermission(PERMISSIONS.SPECIFICATION.SIGN);
  const [
    isConfirmationDialogVisible,
    setIsConfirmationDialogVisible,
  ] = useState(false);
  const { lg } = useBreakpoint();
  const { buttonProps, messageKey } = getSignProperties({
    //@ts-ignore
    specificationState,
    hasSignPermission,
    isRetailer,
    isTargetMode,
    onButtonClick: getOnButtonClick({
      isRetailer,
      // previously there was a folderId property in the specification data from the BE which is missing now
      folderId: null,
      languageCode,
      setIsConfirmationDialogVisible,
      handleOpenModal,
    }),
  });

  return (
    <ConfirmationDialog
      visible={isConfirmationDialogVisible}
      title={formatMessage(messages.dialogWarning)}
      okText={formatMessage(messages.confirmButton)}
      cancelText={formatMessage(messages.cancelButton)}
      onConfirm={getOnConfirmClick({
        setIsConfirmationDialogVisible,
        handleOpenModal,
      })}
      onVisibleChange={getOnVisibleChange({
        setIsConfirmationDialogVisible,
      })}
      placement={getPlacementForConfirmationDialog(lg)}
    >
      <ConditionalWrapper
        condition={buttonProps.disabled && messageKey !== "sign"}
        wrapper={children => (
          <Tooltip text={<FormattedMessage {...messages[messageKey]} />}>
            {children}
          </Tooltip>
        )}
      >
        <div>
          <Button
            {...buttonProps}
            data-test-id="sign-button"
            className={styles.button}
            iconName={isMinified ? "annotation" : ""}
          >
            {!isMinified && <FormattedMessage {...messages["sign"]} />}
          </Button>
        </div>
      </ConditionalWrapper>
    </ConfirmationDialog>
  );
};

export default SignButton;
