import { useState } from "react";
import { deleteSpecification, acceptSpecificationRevision } from "apis/SPEC";
import { useRedirect } from "hooks";
import { reloadPage } from "utils/general";
import { SpecificationData, SpecificationVersionData } from "models";
import { getPreviousVersion } from "utils/specifications";

const useRevisionActions = ({
  specificationId,
  history,
}: {
  specificationId: SpecificationData["id"];
  history: SpecificationVersionData[];
}) => {
  const { redirectToSpecificationDetails } = useRedirect();

  const [loading, setLoading] = useState(false);

  const rejectRevision = async () => {
    try {
      setLoading(true);

      await deleteSpecification({ id: specificationId });

      const previousVersion = getPreviousVersion(specificationId, history);

      redirectToSpecificationDetails(previousVersion.specificationId);
    } catch (_) {
      setLoading(false);
    }
  };

  const acceptRevision = async () => {
    try {
      setLoading(true);
      await acceptSpecificationRevision({ id: specificationId });

      reloadPage();
    } catch (_) {
      setLoading(false);
    }
  };

  return { rejectRevision, acceptRevision, loading };
};

export default useRevisionActions;
