import { useState } from "react";
import { Tooltip } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import generalMessages from "messages/general";
import { ConfirmationTooltipProps } from "./types";

const ConfirmationTooltip: React.FC<ConfirmationTooltipProps> = ({
  children,
  onConfirmation,
  placement = "bottomLeft",
  text,
  onCancel,
  cancelButtonText,
  confirmButtonText,
  ...rest
}) => {
  const { formatMessage } = useIntl();
  const [isOpen, setIsOpen] = useState(false);

  const openChangeHandler = (open: boolean) => {
    setIsOpen(open);
  };

  return (
    <Tooltip
      text={text || formatMessage(generalMessages.confirmDeleteItem)}
      actions={[
        {
          text: cancelButtonText ?? formatMessage(generalMessages.no),
          onClick: () => {
            onCancel && onCancel();
            setIsOpen(false);
          },
        },
        {
          text: confirmButtonText ?? formatMessage(generalMessages.yes),
          onClick: onConfirmation,
        },
      ]}
      placement={placement}
      trigger="click"
      open={isOpen}
      onOpenChange={openChangeHandler}
      {...rest}
    >
      {children}
    </Tooltip>
  );
};

export default ConfirmationTooltip;
