/* istanbul ignore file */

import axios from "axios";
import {
  CategoryListItemData,
  LibraryObjectData,
  SectionBlockData,
  SimpleListItemData,
} from "models";
import {
  PackagingSystemComponentObjectData,
  PackagingSystemComponentData,
  PackagingSystemData,
} from "models/packaging";

export const axiosInstance = axios.create({
  baseURL: window.env.PKG_URL,
});

export const fetchPackagingSystemById = ({
  packagingSystemId,
}: {
  packagingSystemId: PackagingSystemData["packagingSystemId"];
}) =>
  axiosInstance.get<PackagingSystemData>(
    `/api/packaging-systems/${packagingSystemId}`
  );

export const fetchPackagingSystems = ({ params }) =>
  axiosInstance.get<{
    skipAndTakeQueryStats: {
      totalCount: number;
      currentCount: number;
    };
    packagingSystems: LibraryObjectData[];
  }>(`/api/packaging-systems/filters`, {
    params,
  });

export const fetchPackagingComponents = ({ params }) =>
  axiosInstance.get<{
    totalNumberOfItems: number;
    skippedNumberOfItems: number;
    items: LibraryObjectData[];
  }>(`/api/packaging-components/filters`, {
    params,
  });

export const fetchPackagingSystemSmartDoc = ({
  packagingSystemId,
  languageCode,
}: {
  packagingSystemId: PackagingSystemData["packagingSystemId"];
  languageCode: string;
}) =>
  axiosInstance.get<PackagingSystemData>(
    `/api/packaging-systems/${packagingSystemId}/smart-document`,
    {
      params: {
        languageCode,
      },
    }
  );

export const fetchPackagingComponentCollection = ({
  componentIds,
}: {
  componentIds: string[];
}) =>
  axiosInstance.post<PackagingSystemComponentData[]>(
    `/api/packaging-components/collection`,
    {
      idsIn: componentIds,
    }
  );

export const fetchComponentsForPackagingSystem = ({
  packagingSystemId,
}: {
  packagingSystemId: PackagingSystemData["packagingSystemId"];
}) =>
  axiosInstance.get<PackagingSystemComponentData[]>(
    `/api/packaging-systems/${packagingSystemId}/components`
  );

export const updateComponentsAssociationForPackagingSystem = ({
  packagingSystemId,
  data,
}: {
  packagingSystemId: PackagingSystemData["packagingSystemId"];
  data: {
    componentIds: PackagingSystemComponentData["componentId"][];
    mainComponentId?: string;
  };
}) =>
  axiosInstance.put<void>(
    `/api/packaging-systems/${packagingSystemId}/components`,
    data
  );

export const updatePackagingSystemsTemplateBlock = ({
  packagingSystemId,
  templateBlockId,
  dataAsJson,
}: {
  packagingSystemId: string;
  templateBlockId: string;
  dataAsJson: string;
}) =>
  axiosInstance.put<PackagingSystemData>(
    `/api/packaging-systems/${packagingSystemId}/blocks/${templateBlockId}`,
    {
      value: dataAsJson,
    }
  );

export const updatePackagingSystemName = ({
  packagingSystemId,
  name,
}: Pick<PackagingSystemData, "packagingSystemId" | "name">) =>
  axiosInstance.put<void>(`/api/packaging-systems/${packagingSystemId}/name`, {
    value: name,
  });

export const addPackagingSystemTags = ({
  packagingSystemId,
  tagIds,
}: Pick<PackagingSystemData, "packagingSystemId" | "tagIds">) =>
  axiosInstance.post<void>(
    `/api/packaging-systems/${packagingSystemId}/tags`,
    tagIds
  );

export const deletePackagingSystemTag = ({
  packagingSystemId,
  tagId,
}: {
  packagingSystemId: PackagingSystemData["packagingSystemId"];
  tagId: string;
}) =>
  axiosInstance.delete<void>(
    `/api/packaging-systems/${packagingSystemId}/tags/${tagId}`
  );

export const fetchPackagingComponentSmartDoc = ({
  packagingComponentId,
  languageCode,
}: {
  packagingComponentId: PackagingSystemComponentData["componentId"];
  languageCode: string;
}) =>
  axiosInstance.get<PackagingSystemComponentObjectData>(
    `/api/packaging-components/${packagingComponentId}/smart-document`,
    {
      params: {
        languageCode,
      },
    }
  );

export const updatePackagingComponentName = ({
  packagingComponentId,
  name,
}: {
  packagingComponentId: PackagingSystemComponentData["componentId"];
  name: PackagingSystemComponentData["name"];
}) =>
  axiosInstance.put<Pick<PackagingSystemComponentObjectData, "name">>(
    `/api/packaging-components/${packagingComponentId}/name`,
    {
      value: name,
    }
  );

export const updatePackagingComponentUsageLevel = ({
  packagingComponentId,
  usageLevel,
}: {
  packagingComponentId: PackagingSystemComponentData["componentId"];
  usageLevel: PackagingSystemComponentData["usageLevel"];
}) =>
  axiosInstance.put<Pick<PackagingSystemComponentObjectData, "usageLevel">>(
    `/api/packaging-components/${packagingComponentId}/usage-level`,
    {
      value: usageLevel,
    }
  );

export const updatePackagingComponentSectionTemplateBlock = ({
  packagingComponentId,
  templateBlockId,
  dataAsJson,
}: {
  packagingComponentId: PackagingSystemComponentData["componentId"];
  templateBlockId: SectionBlockData["templateBlockId"];
  dataAsJson: SectionBlockData["dataAsJson"];
}) =>
  axiosInstance.put<PackagingSystemComponentData>(
    `/api/packaging-components/${packagingComponentId}/blocks/${templateBlockId}`,
    {
      dataAsJson,
    }
  );

export const fetchPackagingComponentPermissions = ({
  packagingComponentId,
}: {
  packagingComponentId: PackagingSystemData["packagingSystemId"];
}) =>
  axiosInstance.get<string[]>(
    `/api/packaging-components/${packagingComponentId}/permissions`
  );

export const fetchPackagingUsage = ({
  name,
  languageCode,
}: {
  name?: string;
  languageCode?: string;
}) =>
  axiosInstance.get<SimpleListItemData[]>(`/api/packaging-usage`, {
    params: { name, languageCode },
  });

export const fetchPackagingMaterial = ({
  languageCode,
}: {
  languageCode?: string;
}) =>
  axiosInstance.get<CategoryListItemData[]>(`/api/packaging-material`, {
    params: { languageCode },
  });

export const fetchPackagingAdditive = ({
  languageCode,
}: {
  languageCode?: string;
}) =>
  axiosInstance.get<CategoryListItemData[]>(`/api/packaging-additive`, {
    params: { languageCode },
  });

export const fetchPackagingTint = ({
  languageCode,
}: {
  languageCode?: string;
}) =>
  axiosInstance.get<SimpleListItemData[]>(`/api/packaging-tint`, {
    params: { languageCode },
  });

export const fetchPackagingTransparency = ({
  languageCode,
}: {
  languageCode?: string;
}) =>
  axiosInstance.get<SimpleListItemData[]>(`/api/packaging-transparency`, {
    params: { languageCode },
  });

export const fetchPackagingRigidity = ({
  languageCode,
}: {
  languageCode?: string;
}) =>
  axiosInstance.get<SimpleListItemData[]>(`/api/packaging-rigidity`, {
    params: { languageCode },
  });

export const fetchExternalList = ({
  route,
  languageCode,
}: {
  route: string;
  languageCode?: string;
}) =>
  axiosInstance.get<SimpleListItemData[]>(`/api/${route}`, {
    params: { languageCode },
  });
