import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { fetchChapterSpecifications } from "apis/SPEC";
import { Sorter } from "types/pagination";
import { CHAPTER_TYPES_API } from "utils/constants";
import { PaginationProps } from "antd";
import { SpecificationListItemData } from "models";
import { ParamTypes } from "pages/Library/components/types";

const useLinkedSpecifications = ({
  chapterType,
  chapterId,
  sorter,
  pagination,
}: {
  chapterType: string;
  chapterId: string;
  sorter?: Sorter;
  pagination?: PaginationProps;
}) => {
  const { id: chapterIdFromUrl } = useParams<ParamTypes>();
  const { current = 1, pageSize = 10 } = pagination || {};

  const params = {
    skip: (current - 1) * pageSize,
    take: pageSize,
    orderBy: sorter?.orderBy,
    orderByDescending: sorter?.orderByDescending,
  };

  const [specifications, setSpecifications] = useState<
    SpecificationListItemData[]
  >([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const getLinkedSpecifications = async () => {
    try {
      setIsLoading(true);

      const res = await fetchChapterSpecifications({
        chapterType: CHAPTER_TYPES_API[chapterType],
        chapterId,
        params,
      });

      setSpecifications(res?.data?.items);
      setTotalCount(res?.data?.totalNumberOfItems || 0);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (chapterType && chapterId && chapterId === chapterIdFromUrl) {
      getLinkedSpecifications();
    }
  }, [chapterType, chapterId, sorter, current, pageSize]);

  return { specifications, isLoading, totalCount, getLinkedSpecifications };
};

export default useLinkedSpecifications;
