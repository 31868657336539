import { defineMessages } from "react-intl";

export const timeMessages = defineMessages({
  minuteAbbreviation: {
    id: "time.minute.abbreviation",
    defaultMessage: "min",
  },
  hourAbbreviation: {
    id: "time.hour.abbreviation",
    defaultMessage: "h",
  },
  dayAbbreviation: {
    id: "time.day.abbreviation",
    defaultMessage: "d",
  },
  ago: {
    id: "time.ago",
    defaultMessage: "ago",
  },
  justNow: {
    id: "time.justNow",
    defaultMessage: "just now",
  },
  day: {
    id: "time.day",
    defaultMessage: "day",
  },
  days: {
    id: "time.days",
    defaultMessage: "days",
  },
  month: {
    id: "time.month",
    defaultMessage: "month",
  },
  months: {
    id: "time.months",
    defaultMessage: "months",
  },
});
