import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "creationFolder.summary.confirmedSharing",
    defaultMessage: "Confirmed sharing",
  },
  folderConfirmationMessage: {
    id: "creationFolder.summary.confirmedSharing.confirmationMessage",
    defaultMessage: "The {folderName} folder has been shared",
  },
  specificationConfirmationMessage: {
    id: "specificationCreation.summary.confirmedSharing.confirmationMessage",
    defaultMessage: "The specification has been shared",
  },
  decisionMessage: {
    id: "creationFolder.summary.confirmedSharing.decisionMessage",
    defaultMessage: "You wish to:",
  },
  folderCreateBtn: {
    id: "creationFolder.summary.confirmedSharing.createNewFolder",
    defaultMessage: "Create a new folder",
  },
  requirementsCreateBtn: {
    id: "library.draftValidation.buttons.create.requirement",
    defaultMessage: "Create a new requirement",
  },
  specificationCreateBtn: {
    id: "specificationCreation.summary.confirmedSharing.createNewSpecification",
    defaultMessage: "Create a new specification",
  },
  folderViewBtn: {
    id: "creationFolder.summary.confirmedSharing.viewFolder",
    defaultMessage: "View this folder",
  },
  requirementsViewBtn: {
    id: "library.draftValidation.buttons.view.requirement",
    defaultMessage: "View this requirement",
  },
  specificationViewBtn: {
    id: "specificationCreation.summary.confirmedSharing.viewSpecification",
    defaultMessage: "View this specification",
  },
});
