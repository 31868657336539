import "./polyfills";
import "dayjs/locale/fr";

import React from "react";
import ReactDOM from "react-dom";
import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import { defaultStore, persistor } from "./store";
import App from "./App";

import "styles/_app.less";
import "styles/antd-custom.less";

dayjs.extend(updateLocale);

dayjs.updateLocale("fr", {
  durationLabelsStandard: {
    d: "jour",
    dd: "jours",
    M: "mois",
    MM: "mois",
    y: "année",
    yy: "années",
  },
  invalidDate: "Date non valide",

  // this is not yet supported by dayjs
  durationLabelTypes: [
    { type: "standard", string: "__" },
    { type: "short", string: "_" },
  ],
});

ReactDOM.render(
  <Provider store={defaultStore}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
