import dayjs from "dayjs";
import { LANGUAGES } from "./constants";

export async function setDayjsLocale(languageCode: string) {
  const languageLocaleMap = {
    [LANGUAGES.ENGLISH]: "en",
    [LANGUAGES.FRENCH]: "fr",
    [LANGUAGES.DUTCH]: "nl",
    [LANGUAGES.GERMAN]: "de",
    [LANGUAGES.ITALIAN]: "it",
    [LANGUAGES.PORTUGUESE]: "pt",
    [LANGUAGES.SPANISH]: "es",
  };

  try {
    const dayjsLocale = languageLocaleMap[languageCode];
    await import(`dayjs/locale/${dayjsLocale}.js`);
    dayjs.locale(languageLocaleMap[languageCode] || "en");
  } catch (error) {
    console.error("Error loading locale:", error);
  }
}
