import { defineMessages } from "react-intl";

export const messages = defineMessages({
  back: {
    id: "general.back",
    defaultMessage: "Back",
  },
  cancelCreation: {
    id: "creation.cancelCreation",
    defaultMessage: "Cancel creation",
  },
  next: {
    id: "general.next",
    defaultMessage: "Next",
  },
});
