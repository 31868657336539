import { useEffect, useState } from "react";
import usePrevious from "hooks/usePrevious";
import { isListEmpty } from "utils/general";
import { IUsePromiseQueue, Queue, QueuePromise } from "./types";

function usePromiseQueue<T = {}>({
  defaultCurrentData,
  onResolve,
  onReject,
  onFinish,
}: IUsePromiseQueue<T>) {
  const [queue, setQueue] = useState<Queue<T>>({
    isProcessing: false,
    promises: [],
    currentData: defaultCurrentData,
  });
  const previousQueue = usePrevious(queue);

  useEffect(() => {
    setQueue(previousState => ({
      ...previousState,
      currentData: defaultCurrentData,
    }));
  }, [JSON.stringify(defaultCurrentData)]);

  useEffect(() => {
    const dequeue = async () => {
      const promise = queue.promises[0];

      setQueue(prev => ({
        ...prev,
        isProcessing: true,
      }));

      try {
        const response = await promise(queue.currentData);

        setQueue(previousState => ({
          ...previousState,
          isProcessing: false,
          currentData: response,
          promises: previousState.promises.slice(1),
        }));

        onResolve?.(response);
      } catch (e) {
        onReject?.(e);

        setQueue(previousState => ({
          ...previousState,
          isProcessing: false,
          promises: previousState.promises.slice(1),
        }));
      }
    };

    if (isListEmpty(queue.promises)) {
      if (
        queue.isProcessing === false &&
        previousQueue?.promises?.length === 1
      ) {
        onFinish?.(queue.currentData);
      }
      return;
    }

    if (queue.isProcessing) return;

    dequeue();
  }, [queue]);

  const enqueue = (promise: QueuePromise<T>) => {
    setQueue(previousState => ({
      ...previousState,
      promises: [...previousState.promises, promise],
    }));
  };

  return { enqueue };
}

export default usePromiseQueue;
