import { Heading, Paragraph } from "@trace-one/design-system";
import { Typography } from "antd";
import { DetailsProps } from "./types";
import styles from "../../styles.module.less";

const Details: React.FC<DetailsProps> = ({ name, typeName }) => {
  return (
    <div className={styles.details}>
      {name ? (
        <Heading size="xxs" data-test-id="card-name">
          <Typography.Text ellipsis={{ tooltip: name }}>{name}</Typography.Text>
        </Heading>
      ) : null}
      <Paragraph size="xs" data-test-id="card-type">
        <Typography.Text
          ellipsis={{
            tooltip: typeName,
          }}
        >
          {typeName}
        </Typography.Text>
      </Paragraph>
    </div>
  );
};

export default Details;
