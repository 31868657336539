import { useContext, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import Library from "components/Library";
import { useLibraryNavigation, useRedirect, useUserInfo } from "hooks";
import { LayoutContext } from "contexts";
import { checkIfUserHasPermissionForThisPage, getOnTabClick } from "./utils";
import { ParamTypes } from "../types";

const List = () => {
  const { type } = useParams<ParamTypes>();
  const { redirectToHome, redirectToLibraryListing } = useRedirect();
  const { isRetailer, isRetailerAdmin } = useUserInfo();
  const tabs = useLibraryNavigation();
  const { updatePageHeadingInfo } = useContext(LayoutContext);

  const onTabClick = useMemo(
    () =>
      getOnTabClick({
        currentType: type,
        redirectToLibraryListing,
      }),
    [type]
  );

  useEffect(() => {
    checkIfUserHasPermissionForThisPage({
      isRetailer,
      type,
      redirectToHome,
      isRetailerAdmin,
    });
  }, [type, isRetailer]);

  useEffect(() => {
    updatePageHeadingInfo({ tabs, currentTab: type, onTabClick });
  }, [type]);

  return <Library.List type={type} areItemsClickable />;
};

export default List;
