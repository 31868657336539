import Block from "./components/Block";
import Content from "./components/Content";
import ErrorsList from "./components/ErrorsList";
import FormSchema from "./components/FormSchema";
import Menu from "./components/Menu";
import Title from "./components/Title";
import ValidationToggle from "./components/ValidationToggle";

const SpecificationSection = {
  Block,
  Content,
  ErrorsList,
  FormSchema,
  Menu,
  Title,
  ValidationToggle,
};

export default SpecificationSection;
