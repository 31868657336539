import { Col, Row, Form } from "antd";
import {
  getFieldTitleFromUiSchemaArrayTemplate,
  getUuidValue,
  isObjectEmpty,
} from "utils/general";
import ReflistSelect from "components/ReflistSelect";
import { REFLIST_IDS } from "utils/constants";
import { useOrigin } from "./hooks";
import { hasItemError } from "./utils";
import { OriginProps } from "./types";

const Origin: React.FC<OriginProps> = ({
  displayActions,
  data,
  onChange,
  classNameWrapper,
  shouldCallApi = true,
  refListDictionary,
  uiSchema,
}) => {
  const {
    originTypeId,
    countryId,
    regionId,
    zoneId,
    originData,
    getMessage,
    getName,
    handleChange,
  } = useOrigin({ displayActions, data, onChange });

  const colSpan = 24 / Object.values(data).length;
  const colSpans = {
    lg: colSpan,
    md: colSpan,
    sm: colSpan,
    xs: displayActions ? 24 : colSpan,
  };

  const refListProps = {
    shouldCallApi,
    refListDictionary,
    allowClear: true,
    showSearch: true,
  };

  return (
    <Row gutter={[10, 10]} className={classNameWrapper}>
      {!isObjectEmpty(originTypeId) && (
        <Col {...colSpans} data-testid={`${getName("originTypeId")}-content`}>
          <Form.Item
            label={
              getFieldTitleFromUiSchemaArrayTemplate(
                uiSchema,
                "originTypeId"
              ) || getMessage("originTypeId", "label")
            }
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            required={originTypeId.required}
            validateStatus={originTypeId.validateStatus}
            help={originTypeId.help}
          >
            {displayActions ? (
              <ReflistSelect
                {...refListProps}
                {...(originTypeId?.allowClear !== undefined && {
                  allowClear: originTypeId?.allowClear,
                })}
                refListId={REFLIST_IDS.ORIGIN_TYPES}
                selectedOptionIds={originTypeId.selectedOptionIds}
                className={originTypeId.className}
                data-test-id={originTypeId.dataTestId}
                placeholder={getMessage("originTypeId", "placeholder")}
                value={getUuidValue(originTypeId.value)}
                onChange={handleChange({ name: getName("originTypeId") })}
                disabled={originTypeId.disabled}
                error={hasItemError(originTypeId.validateStatus)}
              />
            ) : (
              originTypeId.value
            )}
          </Form.Item>
        </Col>
      )}
      <Col {...colSpans} data-testid={`${getName("countryId")}-content`}>
        <Form.Item
          label={
            getFieldTitleFromUiSchemaArrayTemplate(uiSchema, "countryId") ||
            getMessage("countryId", "label")
          }
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          required={countryId.required}
          validateStatus={countryId.validateStatus}
          help={countryId.help}
        >
          {displayActions ? (
            <ReflistSelect
              {...refListProps}
              {...(countryId?.allowClear !== undefined && {
                allowClear: countryId?.allowClear,
              })}
              refListId={REFLIST_IDS.COUNTRIES}
              className={countryId.className}
              data-test-id={countryId.dataTestId}
              placeholder={getMessage("countryId", "placeholder")}
              value={getUuidValue(originData[countryId.name])}
              onChange={handleChange({ name: getName("countryId") })}
              disabled={countryId.disabled}
              error={hasItemError(countryId.validateStatus)}
              errorMessage={countryId?.errorMessage}
            />
          ) : (
            countryId.value
          )}
        </Form.Item>
      </Col>
      <Col {...colSpans} data-testid={`${getName("regionId")}-content`}>
        <Form.Item
          label={
            getFieldTitleFromUiSchemaArrayTemplate(uiSchema, "regionId") ||
            getMessage("regionId", "label")
          }
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          required={regionId.required}
          validateStatus={regionId.validateStatus}
          help={regionId.help}
        >
          {displayActions ? (
            <ReflistSelect
              {...refListProps}
              {...(regionId?.allowClear !== undefined && {
                allowClear: regionId?.allowClear,
              })}
              refListId={REFLIST_IDS.REGIONS}
              parentItemIds={[originData[countryId.name]].filter(elem => elem)}
              className={regionId.className}
              data-test-id={regionId.dataTestId}
              placeholder={getMessage("regionId", "placeholder")}
              value={getUuidValue(originData[regionId.name])}
              onChange={handleChange({ name: getName("regionId") })}
              disabled={regionId.disabled}
              error={hasItemError(regionId.validateStatus)}
            />
          ) : (
            regionId.value
          )}
        </Form.Item>
      </Col>
      <Col {...colSpans} data-testid={`${getName("zoneId")}-content`}>
        <Form.Item
          label={
            getFieldTitleFromUiSchemaArrayTemplate(uiSchema, "zoneId") ||
            getMessage("zoneId", "label")
          }
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          required={zoneId.required}
          validateStatus={zoneId.validateStatus}
          help={zoneId.help}
        >
          {displayActions ? (
            <ReflistSelect
              {...refListProps}
              {...(zoneId?.allowClear !== undefined && {
                allowClear: zoneId?.allowClear,
              })}
              refListId={REFLIST_IDS.ZONES}
              parentItemIds={[
                originData[regionId.name] || originData[countryId.name],
              ].filter(elem => elem)}
              className={zoneId.className}
              data-test-id={zoneId.dataTestId}
              placeholder={getMessage("zoneId", "placeholder")}
              value={getUuidValue(originData[zoneId.name])}
              onChange={handleChange({ name: getName("zoneId") })}
              disabled={zoneId.disabled}
              error={hasItemError(zoneId.validateStatus)}
            />
          ) : (
            zoneId.value
          )}
        </Form.Item>
      </Col>
    </Row>
  );
};

Origin.defaultProps = {
  displayActions: true,
  onChange: () => {},
};

export default Origin;
