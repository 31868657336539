import { defineMessages } from "react-intl";

export const messages = defineMessages({
  selectedCounter: {
    id: "creation.spec.selectedCounter",
    defaultMessage:
      "The following structure will be created for the <b>{productCount} {productCount, plural, one {product} other {products}}</b> and <b>{supplierCount} {supplierCount, plural, one {supplier} other {suppliers}}</b> added to the folder",
  },

  errorFieldMessage: {
    id: "creation.errors.notSaved",
    defaultMessage: "An error has occurred, the last changes were not saved",
  },
});
