export const MATERIAL_PROPS = Object.freeze({
  NAME: "name",
  TAG_IDS: "tagIds",
  ACTIVE: "active",
  SOURCE_ID: "sourceId",
  PART_ID: "partId",
  FUNCTION_IDS: "functionIds",
  SUPPLIERS: "suppliers",
});

export const PATCH_OPERATIONS = Object.freeze({
  ADD: "add",
  REMOVE: "remove",
});

export const API_VERSION = "";
