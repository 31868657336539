import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";

/* istanbul ignore next */
export const _selectUser = (state: RootState) => state.user;

export const selectLanguageCode = createSelector(
  _selectUser,
  ({ userLanguagePreference }) => userLanguagePreference
);

export const selectUserId = createSelector(_selectUser, ({ userId }) => userId);

export const selectUserLogin = createSelector(
  _selectUser,
  ({ userLogin }) => userLogin
);

export const selectUserName = createSelector(
  _selectUser,
  ({ userFirstName, userLastName }) => `${userFirstName} ${userLastName}`
);

export const selectUserPhotoUrl = createSelector(
  _selectUser,
  ({ userPhotoUrl }) => userPhotoUrl
);

export const selectUserJobTitle = createSelector(
  _selectUser,
  ({ userJobTitle }) => userJobTitle
);

export const selectUserApplications = createSelector(
  _selectUser,
  ({ applications }) => applications
);

export const selectUserApplication = (aplicationTypeId: number) =>
  createSelector(_selectUser, ({ applications }) =>
    applications.find(
      application => application.applicationTypeId === aplicationTypeId
    )
  );

export const selectUserIsLoading = createSelector(
  _selectUser,
  ({ isLoading }) => isLoading
);

export const selectOwningCompanyId = createSelector(
  _selectUser,
  ({ owningCompanyId }) => owningCompanyId
);

export const selectOwningCompanyName = createSelector(
  _selectUser,
  ({ owningCompanyName }) => owningCompanyName
);

export const selectIsAccountAdministrator = createSelector(
  _selectUser,
  ({ isAccountAdministrator }) => isAccountAdministrator
);

export const selectUserCompanyActivityNameInEng = createSelector(
  _selectUser,
  ({ companyActivityNameInEn }) => companyActivityNameInEn
);

export const selectUserOrAppsIsLoading = createSelector(
  _selectUser,
  ({ isLoading, isLoadingApplications }) => isLoading || isLoadingApplications
);
