import { useState, useEffect } from "react";
import { useRedirect } from "hooks";
import { scrollToTop } from "utils/general";
import { ChapterProgressData, SpecificationChapterProgressData } from "models";
import { ProgressionProps } from "../../types";
import { CHAPTER_TYPES_API } from "utils/constants";

const useProgression = ({
  chapters,
  specificationId,
  progress,
  chapterType,
}: ProgressionProps) => {
  const { redirectToSpecificationDetails } = useRedirect();
  const [progressListData, setProgressListData] = useState<
    ChapterProgressData[]
  >([]);

  const onSectionClick = (section: SpecificationChapterProgressData) => {
    const newSelectedSection = chapters.find(
      currentSection => currentSection.chapterType === section.chapterType
    );

    if (section.chapterType !== chapterType) {
      scrollToTop(0, "smooth");

      redirectToSpecificationDetails(
        specificationId,
        CHAPTER_TYPES_API[newSelectedSection.chapterType]
      );
    }
  };

  useEffect(() => {
    const chapterDictionary = chapters.reduce((previousState, item) => {
      return {
        ...previousState,
        [item.chapterType]: {
          sectionName: item.sectionName,
          sectionId: item.sectionId,
        },
      };
    }, {});

    setProgressListData(
      progress.map(currentChapter => ({
        ...currentChapter,
        ...(chapterDictionary[currentChapter.chapterType] && {
          sectionName:
            chapterDictionary[currentChapter.chapterType].sectionName,
          sectionId: chapterDictionary[currentChapter.chapterType].sectionId,
        }),
      }))
    );
  }, [JSON.stringify(chapters), JSON.stringify(progress)]);

  return {
    progressListData,
    onSectionClick,
  };
};

export default useProgression;
