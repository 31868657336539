import { useEffect, useMemo, useState } from "react";
import classnames from "classnames";
import { DatePicker } from "@trace-one/design-system";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { selectLanguageCode } from "store/user/selectors";
import { useWidgetActions } from "../../hooks";
import { isWidgetDisabled } from "../utils";
import { DateWidgetProps } from "./types";
import widgetStyle from "../styles.module.less";

const DateWidget: React.FC<DateWidgetProps> = ({
  disabled,
  disabledDate,
  formContext,
  id,
  onChange,
  onFocus = () => {},
  onBlur = () => {},
  placeholder,
  readonly,
  value,
  schema,
  isOpen,
  hasSuffixIcon = true,
}) => {
  const languageCode = useSelector(selectLanguageCode);
  const {
    displayActions,
    emptyValue,
    readonlyAsDisabled = true,
    templateBlockId,
    isWidgetEditable,
    sectionId,
    isTargetMode,
  } = formContext;
  const { propertyId } = schema;

  const [dateValue, setDateValue] = useState(undefined);
  useEffect(() => {
    setDateValue(value ? dayjs(value) : undefined);
  }, [value]);

  const isDisabled = useMemo(
    () =>
      isWidgetDisabled({
        isWidgetEditable: isWidgetEditable(propertyId),
        disabled,
        readonlyAsDisabled,
        readonly,
      }),
    [disabled, readonlyAsDisabled, readonly, propertyId, isTargetMode]
  );

  const { getTranslationForKey } = useFormSchemaTranslation();

  const { isWidgetChanged } = useWidgetActions({ templateBlockId, sectionId });

  const handleChange = nextValue => {
    if (nextValue) {
      onChange(nextValue.format("YYYY-MM-DD"));
    }
  };

  const handleBlur = () => onBlur(id, value);
  const handleFocus = () => onFocus(id, value);

  if (!displayActions) {
    return value ? (
      <>{dayjs(value).local().format("MMM D YYYY")}</>
    ) : (
      <>{emptyValue}</>
    );
  }

  return (
    <DatePicker
      open={isOpen}
      {...(!hasSuffixIcon && {
        suffixIcon: null,
      })}
      allowClear={false}
      className={classnames({
        [widgetStyle.inRevisionChange]: isWidgetChanged(propertyId, id),
      })}
      disabled={isDisabled}
      disabledDate={disabledDate}
      id={id}
      name={id}
      onChange={!readonly ? handleChange : undefined}
      onBlur={!readonly ? handleBlur : undefined}
      onFocus={!readonly ? handleFocus : undefined}
      {...(placeholder && {
        placeholder: getTranslationForKey(placeholder),
      })}
      value={dateValue}
      locale={languageCode}
    />
  );
};

export default DateWidget;
