import { useIntl } from "react-intl";
import { useLibraryNavigation, useUserPermission } from "hooks";
import { ROUTES } from "utils/constants";
import { isFAndVOnly } from "utils/general";
import navMenuMessages from "messages/navMenu";

export type NavItem = {
  text: string;
  path?: string;
  subNav?: NavItem[];
};

const useNavigation = () => {
  const { formatMessage } = useIntl();
  const libraryNavigation = useLibraryNavigation();
  const {
    specificationsRead,
    foldersRead,
    requirementsCreate,
    chaptersCreate,
    claimsCreate,
    packagingSystemsWrite,
    templatesCreate,
    materialsCreate,
    chapterTranslationsRead,
  } = useUserPermission();

  const hasSpecificationsRouteAccess =
    foldersRead || specificationsRead || chapterTranslationsRead;
  const hasLibraryRouteAccess =
    requirementsCreate ||
    materialsCreate ||
    chaptersCreate ||
    claimsCreate ||
    (packagingSystemsWrite && !isFAndVOnly) ||
    templatesCreate;

  let routes: NavItem[] = [
    {
      text: formatMessage(navMenuMessages.home),
      path: "/home",
      subNav: [],
    },
  ];

  if (hasSpecificationsRouteAccess) {
    routes.push({
      text: formatMessage(navMenuMessages.specifications),
      subNav: [
        foldersRead && {
          text: formatMessage(navMenuMessages.folderList),
          path: ROUTES.FOLDER_LISTING,
        },
        specificationsRead && {
          text: formatMessage(navMenuMessages.specificationList),
          path: ROUTES.SPECIFICATION_LISTING,
        },
        chapterTranslationsRead && {
          text: formatMessage(navMenuMessages.translationList),
          path: ROUTES.TRANSLATION_LISTING,
        },
      ].filter(Boolean),
    });
  }

  if (hasLibraryRouteAccess) {
    routes.push({
      text: formatMessage(navMenuMessages.library),
      subNav: libraryNavigation,
    });
  }

  return routes;
};

export default useNavigation;
