import { Heading, Icon, Tooltip } from "@trace-one/design-system";
import Action from "./components/Action";
import { FolderProps } from "./types";
import styles from "../../styles.module.less";

const Folder: React.FC<FolderProps> = ({ id, name, state }) => {
  return (
    <div className={styles.header}>
      <div className={styles.icon}>
        <Icon name="folder" size="large" color="white" />
      </div>
      <div className={styles.headerContent}>
        <div>
          <Tooltip text={name} placement="top">
            <Heading size="l">{name}</Heading>
          </Tooltip>
        </div>
      </div>
      <Action state={state} id={id} />
    </div>
  );
};

Folder.defaultProps = {
  id: null,
  name: null,
  state: null,
};

export default Folder;
