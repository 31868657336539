import { useEffect, useState } from "react";
import { fetchComponentsForPackagingSystem } from "apis/PKG";
import { useReferenceListsItems } from "hooks";
import { preparePackagingComponents } from "dtos/packagingSystem";
import {
  PackagingSystemComponentsTableViewModel,
  PackagingSystemComponentsViewModel,
} from "viewModels";
import { PackagingSystemComponentData } from "models";

const usePackagingSystemComponents = (
  packagingSystemId: PackagingSystemComponentsViewModel["packagingSystemId"]
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [packagingSystemComponents, setPackagingSystemComponents] = useState<
    PackagingSystemComponentsTableViewModel[]
  >([]);

  const [
    packagingComponentFieldsFromApi,
    setPackagingComponentFieldsFromApi,
  ] = useState<{
    packagingComponentTypeIds: PackagingSystemComponentData["componentTypeId"][];
    packagingComponentIds: PackagingSystemComponentData["componentId"][];
  }>({ packagingComponentIds: [], packagingComponentTypeIds: [] });

  const getPackagingSystemComponents = async () => {
    try {
      setIsLoading(true);
      const { data } = await fetchComponentsForPackagingSystem({
        packagingSystemId,
      });

      setPackagingSystemComponents(preparePackagingComponents(data));
      setPackagingComponentFieldsFromApi({
        packagingComponentTypeIds: data?.map(
          component => component?.componentTypeId
        ),
        packagingComponentIds: data?.map(component => component?.componentId),
      });
    } catch (_) {
      setPackagingSystemComponents([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (packagingSystemId) {
      getPackagingSystemComponents();
    } else {
      setPackagingSystemComponents([]);
    }
  }, [packagingSystemId]);

  const { refListDictionary } = useReferenceListsItems({
    refListIds: [...packagingComponentFieldsFromApi?.packagingComponentTypeIds],
  });

  return {
    isLoading,
    packagingSystemComponents,
    refListDictionary,
    packagingComponentIds: [
      ...packagingComponentFieldsFromApi?.packagingComponentIds,
    ],
    getPackagingSystemComponents,
  };
};

export default usePackagingSystemComponents;
