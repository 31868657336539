import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  addChapterTags,
  deleteChapterTag,
  deleteChapterType,
  fetchChapter,
  updateChapterBlock,
  updateChapterName,
  updateChapterSites,
  updateChapterType,
  updateChapterCrossContaminationAllergen,
  createChapterIngredient,
  updateChapterRecipeIngredient,
  deleteChapterIngredient,
  updateChapterNutrition,
  removeChapterNutrient,
  createChapterNutrition,
  addChapterFreeFromAllergens as addChapterFreeFromAllergensApi,
  deleteChapterFreeFromAllergen,
  updateChapterFreeFromAllergen as updateChapterFreeFromAllergenApi,
  updateEnergyCalculation as updateEnergyCalculationApi,
} from "apis/SPEC";
import { CHAPTER_TYPES_API } from "utils/constants";
import { getNewBlockData } from "./utils";
import { ChapterData } from "models";
import { AsyncThunkConfig } from "store";

export const updateName = createAsyncThunk<
  ChapterData,
  string,
  AsyncThunkConfig
>("chapterForm/updateName", async (chapterName, { getState }) => {
  if (!chapterName) {
    return;
  }

  const {
    chapterForm: { generalInfo },
    user: { userLanguagePreference },
  } = getState();

  if (generalInfo?.id) {
    const { data } = await updateChapterName({
      chapterId: generalInfo.id,
      chapterName,
      chapterType: CHAPTER_TYPES_API[generalInfo.type],
    });

    return data;
  } else if (generalInfo?.type && generalInfo?.productTypeId) {
    const { data } = await updateChapterType({
      chapterName,
      chapterType: CHAPTER_TYPES_API[generalInfo?.type],
      productTypeId: generalInfo?.productTypeId,
      tagIds: generalInfo?.tagIds,
      languageCode: userLanguagePreference,
    });
    return data;
  }
});

export const updateType = createAsyncThunk<
  ChapterData,
  string,
  AsyncThunkConfig
>("chapterForm/updateType", async (chapterType, { getState, dispatch }) => {
  const {
    chapterForm: { generalInfo },
    user: { userLanguagePreference },
  } = getState();

  if (!chapterType || !generalInfo.productTypeId) return;

  await dispatch(resetChapterStructure());

  try {
    const { data } = await updateChapterType({
      chapterType: CHAPTER_TYPES_API[chapterType],
      productTypeId: generalInfo.productTypeId,
      chapterName: generalInfo?.name,
      tagIds: generalInfo?.tagIds,
      languageCode: userLanguagePreference,
    });

    return data;
  } catch (error) {}
});

export const updateProductType = createAsyncThunk<
  ChapterData,
  string,
  AsyncThunkConfig
>(
  "chapterForm/updateProductType",
  async (productTypeId, { getState, dispatch }) => {
    const {
      chapterForm: { generalInfo },
      user: { userLanguagePreference },
    } = getState();

    await dispatch(resetChapterStructure());

    if (productTypeId && generalInfo.type) {
      const { data } = await updateChapterType({
        chapterType: CHAPTER_TYPES_API[generalInfo.type],
        productTypeId,
        chapterName: generalInfo?.name,
        tagIds: generalInfo?.tagIds,
        languageCode: userLanguagePreference,
      });

      return data;
    }
  }
);

export const addTag = createAsyncThunk<ChapterData, string, AsyncThunkConfig>(
  "chapterForm/addTag",
  async (tagId, { getState }) => {
    const {
      chapterForm: { generalInfo },
    } = getState();

    if (generalInfo?.id) {
      const { data } = await addChapterTags({
        chapterId: generalInfo.id,
        tagIds: [tagId],
        chapterType: CHAPTER_TYPES_API[generalInfo.type],
      });

      return data;
    }

    return;
  }
);

export const removeTag = createAsyncThunk<
  ChapterData,
  string,
  AsyncThunkConfig
>("chapterForm/removeTag", async (tagId, { getState }) => {
  const {
    chapterForm: { generalInfo },
  } = getState();

  if (generalInfo?.id) {
    const { data } = await deleteChapterTag({
      chapterId: generalInfo.id,
      tagId: tagId,
      chapterType: CHAPTER_TYPES_API[generalInfo.type],
    });

    return data;
  }

  return;
});

export const updateBlock = createAsyncThunk<
  ChapterData,
  {
    blockId: string;
    blockJson: string;
  },
  AsyncThunkConfig
>("chapterForm/updateBlock", async ({ blockId, blockJson }, { getState }) => {
  const {
    chapterForm: { generalInfo },
  } = getState();

  const { data } = await updateChapterBlock({
    chapterId: generalInfo.id,
    chapterType: CHAPTER_TYPES_API[generalInfo.type],
    blockId,
    blockJson,
  });

  return getNewBlockData({
    blockId,
    currentData: data,
  });
});

export const getChapter = createAsyncThunk<ChapterData, void, AsyncThunkConfig>(
  "chapterForm/getChapter",
  async (_, { getState }) => {
    const {
      chapterForm: { generalInfo },
      user: { userLanguagePreference },
    } = getState();

    const { data } = await fetchChapter({
      chapterId: generalInfo.id,
      chapterType: CHAPTER_TYPES_API[generalInfo.type],
      languageCode: userLanguagePreference,
    });

    return data;
  }
);

export const resetChapterStructure = createAsyncThunk<
  void,
  void,
  AsyncThunkConfig
>("chapterForm/resetChapterStructure", async (_, { getState }) => {
  const {
    chapterForm: { generalInfo },
  } = getState();

  if (generalInfo?.id) {
    try {
      await deleteChapterType({
        chapterId: generalInfo.id,
        chapterType: CHAPTER_TYPES_API[generalInfo.type],
      });
    } catch (error) {}
  }
});

export const updateChapterNutritionData = createAsyncThunk<
  ChapterData,
  {
    nutrientId: string;
    data: object;
  },
  AsyncThunkConfig
>(
  "chapterForm/updateChapterNutritionData",
  async ({ nutrientId, data }, { getState }) => {
    const {
      chapterForm: { generalInfo },
    } = getState();

    const { data: chapterData } = await updateChapterNutrition({
      chapterId: generalInfo?.id,
      chapterType: generalInfo?.type,
      nutrientId,
      data,
    });

    return chapterData;
  }
);

export const deleteChapterNutrition = createAsyncThunk<
  ChapterData,
  {
    nutrientId: string;
  },
  AsyncThunkConfig
>(
  "chapterForm/deleteChapterNutrition",
  async ({ nutrientId }, { getState }) => {
    const {
      chapterForm: { generalInfo },
    } = getState();

    const { data } = await removeChapterNutrient({
      chapterId: generalInfo?.id,
      chapterType: generalInfo?.type,
      nutrientId,
    });
    return data;
  }
);

export const addChapterNutrition = createAsyncThunk<
  ChapterData,
  {
    nutrientIds: string[];
  },
  AsyncThunkConfig
>("chapterForm/addChapterNutrition", async ({ nutrientIds }, { getState }) => {
  const {
    chapterForm: { generalInfo },
  } = getState();

  const { data } = await createChapterNutrition({
    chapterId: generalInfo?.id,
    chapterType: generalInfo?.type,
    nutrientIds,
  });
  return data;
});

export const addChapterIngredient = createAsyncThunk<
  ChapterData,
  {
    materialId: string;
  },
  AsyncThunkConfig
>("chapterForm/addChapterIngredient", async ({ materialId }, { getState }) => {
  const {
    chapterForm: { generalInfo },
  } = getState();

  const { data } = await createChapterIngredient({
    id: generalInfo?.id,
    chapterType: generalInfo?.type,
    materialId,
  });
  return data;
});

export const updateEnergyCalculation = createAsyncThunk<
  ChapterData,
  { automaticCalculation: boolean },
  AsyncThunkConfig
>(
  "chapterForm/updateEnergyCalculation",
  async ({ automaticCalculation }, { getState }) => {
    const {
      chapterForm: { generalInfo },
    } = getState();

    const { data } = await updateEnergyCalculationApi({
      id: generalInfo?.id,
      chapterType: generalInfo?.type,
      automaticCalculation,
    });

    return data;
  }
);

export const updateChapterIngredient = createAsyncThunk<
  ChapterData,
  {
    ingredientId: string;
    functionId: string;
    percentage: number;
  },
  AsyncThunkConfig
>(
  "chapterForm/updateChapterIngredient",
  async ({ ingredientId, functionId, percentage }, { getState }) => {
    const {
      chapterForm: { generalInfo },
    } = getState();

    const { data } = await updateChapterRecipeIngredient({
      id: generalInfo?.id,
      chapterType: generalInfo?.type,
      ingredientId,
      functionId,
      ...(percentage && {
        percentage,
      }),
    });

    return data;
  }
);

export const removeChapterIngredient = createAsyncThunk<
  ChapterData,
  {
    ingredientId: string;
  },
  AsyncThunkConfig
>(
  "chapterForm/removeChapterIngredient",
  async ({ ingredientId }, { getState }) => {
    const {
      chapterForm: { generalInfo },
    } = getState();

    const { data } = await deleteChapterIngredient({
      id: generalInfo?.id,
      chapterType: generalInfo?.type,
      ingredientId,
    });
    return data;
  }
);

export const updateSites = createAsyncThunk<
  ChapterData,
  string[],
  AsyncThunkConfig
>("chapterForm/updateSites", async (siteIds, { getState }) => {
  const {
    chapterForm: { generalInfo },
  } = getState();

  const { data } = await updateChapterSites({
    chapterId: generalInfo.id,
    chapterType: CHAPTER_TYPES_API[generalInfo.type],
    siteIds: JSON.stringify({ siteIds }),
  });

  return data;
});

export const updateChapterCrossContaminationAllergenRisk = createAsyncThunk<
  ChapterData,
  {
    allergenId: string;
    controlledRisk: boolean;
  },
  AsyncThunkConfig
>(
  "chapterForm/updateChapterCrossContaminationAllergenRisk",
  async ({ allergenId, controlledRisk }, { getState }) => {
    const {
      chapterForm: { generalInfo },
    } = getState();

    const { data } = await updateChapterCrossContaminationAllergen({
      chapterId: generalInfo.id,
      chapterType: CHAPTER_TYPES_API[generalInfo.type],
      allergenId,
      controlledRisk,
    });

    return data;
  }
);

export const addChapterFreeFromAllergens = createAsyncThunk<
  ChapterData,
  {
    allergenIds: string[];
  },
  AsyncThunkConfig
>(
  "chapterForm/addChapterFreeFromAllergens",
  async ({ allergenIds }, { getState }) => {
    const {
      chapterForm: { generalInfo },
    } = getState();

    const { data } = await addChapterFreeFromAllergensApi({
      chapterId: generalInfo?.id,
      chapterType: generalInfo?.type,
      allergenIds,
    });

    return data;
  }
);

export const removeChapterFreeFromAllergen = createAsyncThunk<
  ChapterData,
  {
    allergenId: string;
  },
  AsyncThunkConfig
>(
  "chapterForm/removeChapterFreeFromAllergen",
  async ({ allergenId }, { getState }) => {
    const {
      chapterForm: { generalInfo },
    } = getState();

    const { data } = await deleteChapterFreeFromAllergen({
      chapterId: generalInfo?.id,
      chapterType: generalInfo?.type,
      allergenId,
    });
    return data;
  }
);

export const updateChapterFreeFromAllergen = createAsyncThunk<
  ChapterData,
  {
    allergenId: string;
    fileId: string;
  },
  AsyncThunkConfig
>(
  "chapterForm/updateChapterFreeFromAllergen",
  async ({ allergenId, fileId }, { getState }) => {
    const {
      chapterForm: { generalInfo },
    } = getState();

    const { data } = await updateChapterFreeFromAllergenApi({
      chapterId: generalInfo?.id,
      chapterType: generalInfo?.type,
      allergenId,
      fileId,
    });
    return data;
  }
);
