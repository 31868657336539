import { useIntl } from "react-intl";
import ActionButton from "components/ActionButton";
import libraryMessages from "messages/library";
import generalMessages from "messages/general";
import { ActionsProps } from "./types";

const Actions = ({
  ingredient,
  onRemoveIngredient,
  onViewMaterialAction,
}: ActionsProps) => {
  const { formatMessage } = useIntl();

  return (
    <ActionButton
      data-test-id="recipe-ingredients-actions"
      actions={[
        {
          label: formatMessage(libraryMessages.recipeActionsDisplay),
          "data-test-id": "display-ingredient",
          onClick: onViewMaterialAction({
            materialId: ingredient.materialId,
            type: ingredient.recipeType || ingredient.type,
          }),
        },
        {
          label: formatMessage(generalMessages.remove),
          "data-test-id": "remove-ingredient",
          tooltipProps: {
            onConfirmation: onRemoveIngredient({
              ingredientId: ingredient.ingredientId,
            }),
          },
        },
      ]}
    />
  );
};

export default Actions;
