import { useEffect, useState } from "react";
import { fetchTradeItem } from "apis/PMD";
import { UseProductProps } from "./types";
import { TradeItemData } from "models";

const useProduct = ({ productId }: UseProductProps) => {
  const [productData, setProductData] = useState<TradeItemData>();

  useEffect(() => {
    if (productId) {
      getProduct();
    }
  }, [productId]);

  const getProduct = async () => {
    try {
      const { data } = await fetchTradeItem({ id: productId });

      setProductData({
        ...data,
      });
    } catch (_) {}
  };

  return { productData };
};

export default useProduct;
