/* istanbul ignore file */

import { isListEmpty } from "utils/general";
import { getSubSectionsData } from "store/specificationDetails/utils";
import initialState from "./initialState";

const reducers = {
  setSelectedSection: (state, action) => {
    state.selectedSection = {
      ...action.payload,
    };
    if (!isListEmpty(state.sections)) {
      state.selectedSection = state.sections.find(
        ({ sectionId }) => sectionId === state.selectedSection.sectionId
      );
    }
    state.validationErrors = {};
    if (!isListEmpty(state.specification.chapters)) {
      const selectedSection = state.specification.chapters.find(
        ({ sectionId }) => sectionId === action.payload.sectionId
      );
      const forms = getSubSectionsData(selectedSection.subSections);
      state.selectedSectionForms = forms;
    }

    state.selectedSectionRequirements = [];
    state.selectedSectionLastChanges = initialState.selectedSectionLastChanges;
  },
  resetRevisedSpecification: (state, _) => {
    state.revisedSpecificationId = null;
  },
  setSpecificationId: (state, action) => {
    state.specification.specificationId = action.payload;
  },
  setValidationDisabled: (state, action) => {
    const { sectionId, isDisabled } = action.payload;
    state.validationDisabled[sectionId] = isDisabled;
  },
  setIsSpecificationUpdating: (state, action) => {
    state.isUpdating = action.payload;
  },
  setIsRequestPanelOpen: (state, action) => {
    state.isRequestPanelOpen = action.payload;
  },
  setRequestModification: (state, action) => {
    state.selectedModificationRequest = {
      ...state.selectedModificationRequest,
      ...action.payload,
    };
  },
  setModificationRequests: (state, action) => {
    state.modificationRequests = action.payload;
  },
  setTranslationManagement: (state, action) => {
    state.translationManagement = action.payload;
  },
  setTranslationManagementCompanies: (state, action) => {
    state.translationManagement.companies = action.payload;
  },
};
export default reducers;
