import {
  createRequirement as createRequirementApi,
  updateRequirement,
  addRequirementTags,
  deleteRequirementTag,
  shareRequirement,
  deleteRequirement as deleteRequirementApi,
  publishRequirement as publishRequirementApi,
} from "apis/SPEC";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getRequirementType } from "utils/requirement";
import { File, RequirementData } from "models";
import { AsyncThunkConfig } from "store";
import { RequirementType } from "types/library";

export const createRequirement = createAsyncThunk<
  RequirementData,
  void,
  AsyncThunkConfig
>("requirementForm/createRequirement", async (_, { getState }) => {
  const {
    requirementForm: { type },
  } = getState();

  const { data } = await createRequirementApi({
    requirementType: getRequirementType(type),
    payload: {
      name: null,
      content: null,
      tagIds: null,
    },
  });

  return data;
});

export const keepAsDraftRequirement = createAsyncThunk<
  void,
  string,
  AsyncThunkConfig
>("requirementForm/keepAsDraftRequirement", async (companyId, { getState }) => {
  const {
    requirementForm: {
      type,
      requirementInformation: { id },
    },
  } = getState();

  await shareRequirement({
    requirementId: id,
    requirementType: getRequirementType(type),
    companyId,
  });
});

export const deleteRequirement = createAsyncThunk<void, void, AsyncThunkConfig>(
  "requirementForm/deleteRequirement",
  async (_, { getState }) => {
    const {
      requirementForm: {
        type,
        requirementInformation: { id },
      },
    } = getState();

    await deleteRequirementApi({
      requirementId: id,
      requirementType: getRequirementType(type),
    });
  }
);

export const publishRequirement = createAsyncThunk<
  void,
  void,
  AsyncThunkConfig
>("requirementForm/publishRequirement", async (_, { dispatch, getState }) => {
  const {
    requirementForm: {
      type,
      requirementInformation: { id },
    },
  } = getState();

  await publishRequirementApi({
    requirementId: id,
    requirementType: getRequirementType(type),
  });
});

export const updateName = createAsyncThunk<
  RequirementData,
  RequirementData["name"],
  AsyncThunkConfig
>("requirementForm/updateName", async (name, { getState }) => {
  if (!name) {
    return;
  }

  const {
    requirementForm: { requirementInformation, type },
  } = getState();

  if (requirementInformation?.id) {
    const { data } = await updateRequirement({
      requirementId: requirementInformation.id,
      requirementType: getRequirementType(type),
      payload: {
        name,
        content: requirementInformation.content,
        additionalInformation: requirementInformation.additionalInformation,
        fileId: requirementInformation.fileId,
      },
    });

    return data;
  } else {
    const { data } = await createRequirementApi({
      requirementType: getRequirementType(type),
      payload: {
        name,
        tagIds: requirementInformation.tagIds,
        content: null,
      },
    });

    return data;
  }
});

export const addTag = createAsyncThunk<
  RequirementData,
  string,
  AsyncThunkConfig
>("requirementForm/addTag", async (tagId, { getState }) => {
  const {
    requirementForm: { requirementInformation, type },
  } = getState();

  const { data } = await addRequirementTags({
    requirementId: requirementInformation.id,
    tagIds: [tagId],
    requirementType: getRequirementType(type),
  });

  return data;
});

export const removeTag = createAsyncThunk<
  RequirementData,
  string,
  AsyncThunkConfig
>("requirementForm/removeTag", async (tagId, { getState }) => {
  const {
    requirementForm: { requirementInformation, type },
  } = getState();

  const { data } = await deleteRequirementTag({
    requirementId: requirementInformation.id,
    tagId: tagId,
    requirementType: getRequirementType(type),
  });

  return data;
});

export const updateContent = createAsyncThunk<
  RequirementData,
  RequirementData["content"],
  AsyncThunkConfig
>("requirementForm/updateContent", async (content, { getState }) => {
  const {
    requirementForm: { requirementInformation, type },
  } = getState();

  const { data } = await updateRequirement({
    requirementId: requirementInformation.id,
    requirementType: getRequirementType(type),
    payload: {
      content,
      name: requirementInformation.name,
    },
  });

  return data;
});

export const updateType = createAsyncThunk<
  RequirementData,
  RequirementType,
  AsyncThunkConfig
>("requirementForm/updateType", async (newType, { getState }) => {
  const {
    requirementForm: { requirementInformation, type },
  } = getState();

  await deleteRequirementApi({
    requirementId: requirementInformation.id,
    requirementType: getRequirementType(type),
  });

  const { data } = await createRequirementApi({
    requirementType: getRequirementType(newType),
    payload: {
      name: requirementInformation.name,
      tagIds: requirementInformation.tagIds,
      content: null,
    },
  });

  return data;
});

export const addFileId = createAsyncThunk<
  RequirementData,
  File["id"],
  AsyncThunkConfig
>("requirementForm/addFileId", async (fileId, { getState }) => {
  const {
    requirementForm: { requirementInformation, type },
  } = getState();

  const { data } = await updateRequirement({
    requirementId: requirementInformation.id,
    requirementType: getRequirementType(type),
    payload: {
      name: requirementInformation.name,
      additionalInformation: requirementInformation.additionalInformation,
      fileId,
    },
  });

  return data;
});

export const updateAdditionalInformation = createAsyncThunk<
  RequirementData,
  string,
  AsyncThunkConfig
>(
  "requirementForm/updateAdditionalInformation",
  async (additionalInformation, { getState }) => {
    const {
      requirementForm: { requirementInformation, type },
    } = getState();

    const { data } = await updateRequirement({
      requirementId: requirementInformation.id,
      requirementType: getRequirementType(type),
      payload: {
        additionalInformation,
        name: requirementInformation.name,
        fileId: requirementInformation.fileId,
      },
    });

    return data;
  }
);
