import { fetchRequirement } from "apis/SPEC";
import { useState } from "react";
import { RequirementData } from "models";
import { getRequirementType } from "utils/requirement";
import { RequirementType } from "types/library";
import { isObjectEmpty } from "utils/general";

export interface UseChapterRequirementDetailsProps {
  requirementId: string;
  type: RequirementType;
}

const useChapterRequirementDetails = ({
  requirementId,
  type,
}: UseChapterRequirementDetailsProps) => {
  const [
    requirementDetails,
    setRequirementDetails,
  ] = useState<RequirementData>();

  const getRequirementDetails = async () => {
    if (isObjectEmpty(requirementDetails)) {
      try {
        const { data } = await fetchRequirement({
          requirementId,
          requirementType: getRequirementType(type),
        });

        setRequirementDetails(data);
      } catch {}
    }
  };

  return {
    requirementDetails,
    getRequirementDetails,
  };
};

export default useChapterRequirementDetails;
