import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import { useDispatch } from "react-redux";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import rootReducer, { RootState as RootStateFromReducer } from "./rootReducer";

const persistConfig = {
  key: "root",
  whitelist: [
    "folderCreation",
    "specificationCreation",
    "chapterForm",
    "packagingSystemForm",
    "materialForm",
    "generalData",
    "requirementForm",
    "templateForm",
  ],
  stateReconciler: autoMergeLevel2,
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const initStore = () =>
  configureStore({
    reducer: persistedReducer,
    middleware: [
      ...getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
    ],

    /**
     * TODO: need to use this below to support rtk-query caching, but getting error right now, package needs to be updated
     *  */
    // middleware: getDefaultMiddleware =>
    //   getDefaultMiddleware({
    //     // Redux persist
    //     serializableCheck: {
    //       ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    //     },
    //   })
    //     .concat(specRtkBaseApi.middleware)
    //     .concat(tagsRtkBaseApi.middleware),
  });

export type StoreType = ReturnType<typeof initStore>;
export type AppDispatch = StoreType["dispatch"];
export type RootState = RootStateFromReducer;
export type AsyncThunkConfig = {
  state: RootState;
};

export const useAppDispatch = () => useDispatch<AppDispatch>();

export const defaultStore = initStore();
export const persistor = persistStore(defaultStore);
