import { useEffect, useState } from "react";
import { useDebounce } from "@trace-one/business-components";
import { LabeledValue } from "antd/lib/select";
import { searchUsers } from "apis/CUMD";
import { numberOfCharactersForAutoComplete } from "utils/constants";
import { UseUsersAutocompleteProps } from "./types";
import { UserData } from "models";

const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const USER_PAGINATION_SIZE = 20;

const useUsersAutocomplete = ({
  collaboratingCompanyId,
  onSelectUser,
  selectedUserIds,
  isCollaborationEnabled,
  collaborations,
  ignoreApplicationTypeIds,
}: UseUsersAutocompleteProps) => {
  const [searchText, setSearchText] = useState("");
  const [valueWithLabel, setValueWithLabel] = useState<LabeledValue>();
  const [usersList, setUsersList] = useState<LabeledValue[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [canLoadMore, setCanLoadMore] = useState(true);

  const debouncedSearchText = useDebounce(searchText, 1000);

  const hideNotFoundContent =
    isValidEmail ||
    (debouncedSearchText.length < numberOfCharactersForAutoComplete &&
      !valueWithLabel);

  const handleSearch = (collaborationKey: string) => (searchTerm: string) => {
    // when onBlur this function is triggered with searchTerm =""
    if (searchTerm) {
      setSearchText(searchTerm);
    }

    if (searchTerm?.length === 0) {
      getUsersList("");
    }

    // checking if user type a valid email
    if (regexEmail.test(searchTerm)) {
      setIsValidEmail(true);
      setValueWithLabel({ value: searchTerm, label: searchTerm });
      onSelectUser({
        collaborationKey,
        userEmail: searchTerm,
      });
    }

    // if the user had a valid email but then change it and is not valid anymore
    if (searchTerm && isValidEmail && !regexEmail.test(searchTerm)) {
      setIsValidEmail(false);
      onSelectUser({
        collaborationKey,
        userEmail: null,
      });
    }
  };

  const onBlur = () => {
    if (!isValidEmail) {
      setSearchText("");
    }
  };

  const clearUsersList = () => {
    setUsersList([]);
    setCanLoadMore(true);
    setCurrentPage(1);
  };

  const handleClear = (collaborationKey: string) => () => {
    setValueWithLabel(undefined);
    clearUsersList();
    setSearchText("");

    onSelectUser &&
      onSelectUser({
        collaborationKey,
        userId: null,
      });
  };

  const handleAddUser = (onAddCollaboration: Function) => () => {
    setValueWithLabel(undefined);
    clearUsersList();
    setSearchText("");
    onAddCollaboration(collaboratingCompanyId);
  };

  const handleSelect = (collaborationKey: string) => (
    valueItem: LabeledValue
  ) => {
    onSelectUser({
      collaborationKey,
      userId: valueItem.value as string,
      userName: valueItem.label as string,
    });
    setValueWithLabel(valueItem);
  };

  const getUserOptions = (users: UserData[]): LabeledValue[] => {
    return users.reduce(
      (accumulator, { userFirstName, userLastName, userId }) => {
        // exclude already added users from the list
        if (!selectedUserIds.includes(userId)) {
          return [
            ...accumulator,
            { label: `${userFirstName} ${userLastName}`, value: userId },
          ];
        }
        return accumulator;
      },
      []
    );
  };

  const getUsersList = async (userName?: string) => {
    if (canLoadMore) {
      try {
        setIsLoading(true);

        const {
          data: { users, skipAndTakeQueryStats },
        } = await searchUsers({
          params: {
            isEnabled: true,
            userName: userName ?? debouncedSearchText,
            owningCompanyId: collaboratingCompanyId,
            skip: (currentPage - 1) * USER_PAGINATION_SIZE,
            take: USER_PAGINATION_SIZE,
          },
          ignoreApplicationTypeIds,
        });

        let newUsersLength = users.length;

        setUsersList(previousState => {
          const newUsersList = [...previousState, ...getUserOptions(users)];

          const uniqueUsersList = newUsersList.filter(
            (elem, index, self) =>
              self.findIndex(t => {
                return t.value === elem.value;
              }) === index
          );

          newUsersLength = uniqueUsersList.length;
          return uniqueUsersList;
        });

        setCurrentPage(previousState => previousState + 1);
        setCanLoadMore(
          skipAndTakeQueryStats.totalCount > newUsersLength &&
            skipAndTakeQueryStats.currentCount > 0
        );
      } catch (_) {
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleDropdownVisibleChange = (open: boolean) => {
    if (open) {
      getUsersList();
    }
  };

  useEffect(() => {
    if (!isValidEmail) {
      if (debouncedSearchText.length === 0 && !valueWithLabel) {
        clearUsersList();
      }
      if (debouncedSearchText.length >= numberOfCharactersForAutoComplete) {
        getUsersList();
      }
    } else {
      clearUsersList();
    }
  }, [debouncedSearchText, isValidEmail]);

  useEffect(() => {
    clearUsersList();
  }, [searchText]);

  useEffect(() => {
    collaborations?.forEach(collaboration => {
      handleClear(collaboration.key)();
    });
  }, [isCollaborationEnabled]);

  return {
    handleSearch,
    usersList,
    clearUsersList,
    onBlur,
    handleSelect,
    valueWithLabel,
    isLoading,
    handleClear,
    handleAddUser,
    hideNotFoundContent,
    handleDropdownVisibleChange,
    getUsersList,
  };
};

export default useUsersAutocomplete;
