import { useIntl } from "react-intl";
import { useRbac } from "hooks";
import { isStateSignedByRetailer } from "utils/specifications";
import { messages } from "./messages";
import { PERMISSIONS } from "utils/constants";

const useDisabledProps = ({
  state,
  folderId,
  currentFolderId,
  permissions,
  lastVersion = true,
}) => {
  const { formatMessage } = useIntl();
  const { hasPermission } = useRbac(permissions);
  const hasRevisePermission = hasPermission(PERMISSIONS.SPECIFICATION.REVISE);

  let disabledCheckboxProps = {
    disabled: true,
  };

  if (isStateSignedByRetailer(state) && !lastVersion) {
    return {
      ...disabledCheckboxProps,
      tooltipTitle: formatMessage(messages.supplierIsWorkingOnRevision),
    };
  }

  if (isStateSignedByRetailer(state) && !hasRevisePermission) {
    return {
      ...disabledCheckboxProps,
      tooltipTitle: formatMessage(messages.noRevisePermission),
    };
  }

  if (!!folderId && folderId !== currentFolderId) {
    return {
      ...disabledCheckboxProps,
      tooltipTitle: formatMessage(messages.specificationInAnotherFolder),
    };
  }

  return {};
};

export default useDisabledProps;
