import { Select } from "@trace-one/design-system";
import { useInfiniteScrollSelect } from "./hooks";
import { InfiniteScrollSelectProps } from "./types";

const InfiniteScrollSelect: React.FC<InfiniteScrollSelectProps> = ({
  next,
  onPopupScroll: defaultOnPopupScroll,
  ...rest
}) => {
  const { onPopupScroll } = useInfiniteScrollSelect({
    next,
    defaultOnPopupScroll,
  });

  return (
    <Select
      showArrow
      data-test-id="infinite-scroll-select"
      {...rest}
      filterOption={false}
      onPopupScroll={onPopupScroll}
    />
  );
};

export default InfiniteScrollSelect;
