import { useEffect, useState } from "react";
import { UseValidationProps } from "./types";

const useValidation = ({
  collaborations,
  companyList,
  onChangeValidation,
}: UseValidationProps) => {
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    const isCurrentValid = companyList.every(company => {
      if (!company.isCollaborationEnabled) return true;

      const collaborationsInEnabledSupplier = collaborations.filter(
        collaboration =>
          collaboration.collaboratingCompanyId ===
          company.collaboratingCompanyId
      );

      return collaborationsInEnabledSupplier.some(
        collaboration => collaboration.id
      );
    });

    if (
      isValid !== isCurrentValid &&
      typeof onChangeValidation === "function"
    ) {
      onChangeValidation(isCurrentValid);
    }

    setIsValid(isCurrentValid);
  }, [collaborations, companyList]);
};

export default useValidation;
