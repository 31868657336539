import { Row, Col } from "antd";
import {
  Datatable,
  Button,
  Heading,
  Paragraph,
} from "@trace-one/design-system";
import { useIntl } from "react-intl";
import AddPackagingComponentsModal from "./components/AddPackagingComponentsModal";
import { useModalVisibility } from "hooks";
import {
  usePackagingSystemComponents,
  usePackagingSystemComponentsColumns,
} from "./hooks";
import { packagingComponentTabMessages } from "messages/packaging";
import messages from "messages/library";
import { PackagingSystemComponentsProps } from "./types";
import styles from "../../styles.module.less";

const PackagingSystemComponents: React.FC<PackagingSystemComponentsProps> = ({
  packagingSystemId,
  packagingSystemAllowUpdate,
  mainComponentId,
}) => {
  const { formatMessage } = useIntl();
  const { isModalOpen, onOpenModal, onCloseModal } = useModalVisibility();

  const {
    packagingSystemComponents,
    isLoading,
    refListDictionary,
    packagingComponentIds,
    getPackagingSystemComponents,
  } = usePackagingSystemComponents(packagingSystemId);

  const { columns } = usePackagingSystemComponentsColumns({
    refListDictionary,
    packagingSystemId,
    packagingComponentIds,
    getPackagingSystemComponents,
    packagingSystemAllowUpdate,
    mainComponentId,
  });

  return (
    <>
      <Row className={styles.componentsHeader}>
        <Col span={20}>
          <Row className={styles.componentsTitle}>
            <Heading size="xs" color="grey-5">
              {formatMessage(packagingComponentTabMessages.title)}
            </Heading>
          </Row>
          <Row>
            <Paragraph size="m" color="grey-4">
              {formatMessage(packagingComponentTabMessages.subtitle)}
            </Paragraph>
          </Row>
        </Col>
        <Col span={4} className={styles.componentsAction}>
          <Button
            type="tertiary"
            onClick={onOpenModal}
            disabled={!packagingSystemAllowUpdate}
            data-testid="add-component"
          >
            {formatMessage(messages.addComponent)}
          </Button>
        </Col>
      </Row>
      {isModalOpen && (
        <AddPackagingComponentsModal
          onCloseModal={onCloseModal}
          packagingSystemId={packagingSystemId}
          packagingComponentIds={packagingComponentIds}
          getPackagingSystemComponents={getPackagingSystemComponents}
          mainComponentId={mainComponentId}
        />
      )}
      <Datatable
        dataSource={packagingSystemComponents}
        columns={columns}
        rowKey={packagingSystemComponent =>
          packagingSystemComponent?.componentId
        }
        loading={isLoading}
        pagination={false}
        tableLayout="fixed"
      />
    </>
  );
};

export default PackagingSystemComponents;
