import { useIntl } from "react-intl";
import { Tree } from "@trace-one/react-components";
import { EmptyState } from "@trace-one/design-system";
import { Row } from "antd";
import Spin from "components/Spin";
import { isListEmpty } from "utils/general";
import { ModalBodyProps } from "./types";
import { messages } from "../../messages";

const AddNutrientBody: React.FC<ModalBodyProps> = ({
  isLoading,
  allNutrientsTree,
  handleCheck,
  disabledNutrientIds,
  newlySelectedItems,
}) => {
  const intl = useIntl();

  if (isLoading) {
    return <Spin />;
  }

  if (isListEmpty(allNutrientsTree)) {
    return (
      <Row justify="center">
        <EmptyState heading={intl.formatMessage(messages.noItemFound)} />
      </Row>
    );
  }

  return (
    <Tree
      treeData={allNutrientsTree}
      onCheck={handleCheck}
      defaultCheckedKeys={[...disabledNutrientIds, ...newlySelectedItems]}
      defaultExpandAll
    />
  );
};
export default AddNutrientBody;
