import { Datatable } from "@trace-one/design-system";
import { getRowKeyForTable } from "utils/table";
import { usePackagingSystemTable } from "./hooks";
import { PackagingSystemTableProps } from "./types";

const PackagingSystemTable: React.FC<PackagingSystemTableProps> = ({
  packagingComponents,
  refListDictionary,
}) => {
  const { columns } = usePackagingSystemTable({
    refListDictionary,
    packagingComponentIds: packagingComponents.map(
      ({ componentId }) => componentId
    ),
  });

  return (
    <Datatable
      tableLayout="fixed"
      columns={columns}
      dataSource={packagingComponents}
      pagination={false}
      rowKey={getRowKeyForTable("packagingComponent")}
    />
  );
};

export default PackagingSystemTable;
