import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  fetchConditioningTemperatures,
  fetchTreatmentFrequencyOfUse,
} from "apis/SPEC";
import { selectLanguageCode } from "store/user/selectors";
import { SimpleListItemData } from "models";
import { SchemaPropertyList } from "components/SpecificationSection/types";
import {
  fetchExternalList,
  fetchPackagingRigidity,
  fetchPackagingTransparency,
  fetchPackagingUsage,
} from "apis/PKG";
import { PackagingListRoutes } from "types/packaging";

const useListItems = ({ api, getItemBy, route }: SchemaPropertyList) => {
  const languageCode = useSelector(selectLanguageCode);
  const [items, setItems] = useState<
    {
      value?: string;
      label?: React.ReactNode;
    }[]
  >([]);

  const getItemsFromApi = async () => {
    let data: SimpleListItemData[] = [];

    try {
      if (route.includes(PackagingListRoutes.PACKAGING_REFLIST)) {
        const { data: refListData } = await fetchExternalList({
          route,
          languageCode,
        });
        data = refListData;
      } else {
        switch (route) {
          case "conditioning-temperatures":
            const {
              data: conditioningData,
            } = await fetchConditioningTemperatures({
              languageCode,
            });
            data = conditioningData;

            break;

          case "treatment-frequencies":
            const {
              data: treatmentFrequencies,
            } = await fetchTreatmentFrequencyOfUse({
              languageCode,
            });
            data = treatmentFrequencies;

            break;

          case PackagingListRoutes.PACKAGING_USAGE:
            const { data: packagingUsageTypes } = await fetchPackagingUsage({
              languageCode,
            });
            data = packagingUsageTypes;

            break;

          case PackagingListRoutes.PACKAGING_TRANSPARENCY:
            const {
              data: packagingTransparencyTypes,
            } = await fetchPackagingTransparency({
              languageCode,
            });
            data = packagingTransparencyTypes;

            break;

          case PackagingListRoutes.PACKAGING_RIGIDITY:
            const {
              data: packagingRigidityTypes,
            } = await fetchPackagingRigidity({
              languageCode,
            });
            data = packagingRigidityTypes;

            break;
          default:
        }
      }

      setItems(
        data.map(({ id, code, value }) => ({
          value: code || id,
          label: value,
        }))
      );
    } catch (e) {
      setItems([]);
    }
  };

  useEffect(() => {
    getItemsFromApi();
  }, [api, getItemBy, route, languageCode]);

  return { items };
};

export default useListItems;
