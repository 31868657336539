import { Row } from "antd";
import { ColumnType } from "antd/lib/table";
import { useContext } from "react";
import { useIntl } from "react-intl";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import Actions from "./components/Actions";
import { PackagingSystemComponentMaterialArrayFieldTemplateContext } from "../../../contexts";
import { PackagingSystemComponentMaterialData } from "models/packaging";
import { UiSchemaInfo } from "types/general";
import { isListEmpty, isObjectEmpty } from "utils/general";
import generalMessages from "messages/general";

const usePackagingSystemComponentMaterialTable = ({
  uiSchema,
}: {
  uiSchema: UiSchemaInfo;
}) => {
  const { formatMessage } = useIntl();
  const { getTranslationForKey } = useFormSchemaTranslation();
  const { displayActions, materialTypes } = useContext(
    PackagingSystemComponentMaterialArrayFieldTemplateContext
  );

  const columnFields = Object.keys(uiSchema.items);

  const sorter = columnItem => (a, b) => {
    if (columnItem === "type") {
      if (!isListEmpty(materialTypes)) {
        const valA = materialTypes.find(
          materialType => materialType.value === a.type
        )?.title;
        const valB = materialTypes.find(
          materialType => materialType.value === b.type
        )?.title;

        return valA && valB
          ? valA.toLocaleString().localeCompare(valB.toLocaleString())
          : 0;
      }
    } else {
      const valA =
        a[columnItem] === undefined || a[columnItem] === null
          ? -Infinity
          : a[columnItem];
      const valB =
        b[columnItem] === undefined || b[columnItem] === null
          ? -Infinity
          : b[columnItem];
      return valA - valB;
    }
  };

  let columns: ColumnType<PackagingSystemComponentMaterialData>[] = columnFields.map(
    columnItem => {
      return {
        title: getTranslationForKey(uiSchema.items[columnItem]["ui:title"]),
        dataIndex: columnItem,
        sorter: sorter(columnItem),
        render: value => {
          if (!isListEmpty(materialTypes)) {
            const materialType = materialTypes.find(
              materialType => materialType.value === value
            );

            return !isObjectEmpty(materialType) ? materialType.title : value;
          }
          return value;
        },
      };
    }
  );

  displayActions &&
    columns.push({
      key: "action",
      title: formatMessage(generalMessages.actions),
      width: 90,
      render: (_, packagingSystemComponentMaterial) => (
        <Row justify="center">
          <Actions
            packagingSystemComponentMaterial={packagingSystemComponentMaterial}
          />
        </Row>
      ),
    });

  return { columns };
};

export default usePackagingSystemComponentMaterialTable;
