import { defineMessages } from "react-intl";

export const messages = defineMessages({
  sectionName: {
    id: "specificationCreation.specification.title",
    defaultMessage: "Structure",
  },

  note: {
    id: "specificationCreation.specification.note",
    defaultMessage: "Note",
  },

  noteDescription: {
    id: "specificationCreation.specification.noteDescription",
    defaultMessage: "Additional information",
  },

  errorFieldMessage: {
    id: "creation.errors.notSaved",
    defaultMessage: "An error has occurred, the last changes were not saved",
  },
});
