import { useEffect, useState } from "react";
import { AxiosRequestConfig } from "axios";
import { axiosInstance as specAxiosInstance } from "apis/SPEC";
import { scrollToTop } from "utils/general";

const useErrorStatusCheck = ({
  statusCode,
  scrollToTopOfPage,
}: {
  statusCode: number;
  scrollToTopOfPage?: boolean;
}) => {
  const [isDesiredErrorStatus, setIsDesiredErrorStatus] = useState(false);

  useEffect(() => {
    const onFulfilled = (config: AxiosRequestConfig) => {
      setIsDesiredErrorStatus(false);

      return config;
    };

    const onRejected = error => {
      setIsDesiredErrorStatus(error?.response?.status === statusCode);

      return Promise.reject(error);
    };

    const specIndexRequestInterceptor = specAxiosInstance.interceptors.request.use(
      onFulfilled,
      onRejected
    );

    const specIndexResponseInterceptor = specAxiosInstance.interceptors.response.use(
      onFulfilled,
      onRejected
    );

    return () => {
      specAxiosInstance.interceptors.request.eject(specIndexRequestInterceptor);
      specAxiosInstance.interceptors.response.eject(
        specIndexResponseInterceptor
      );
    };
  }, []);

  useEffect(() => {
    if (isDesiredErrorStatus && scrollToTopOfPage) {
      scrollToTop();
    }
  }, [scrollToTopOfPage, isDesiredErrorStatus]);

  return {
    isDesiredErrorStatus,
  };
};

export default useErrorStatusCheck;
