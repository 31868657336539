import { fetchFilesByIds } from "apis/DOCUMENT";
import { File, FileData } from "models";
import { isListEmpty } from "./general";

export const getFilesInfo = async ({
  fileIds,
  initialFiles = [],
}: {
  fileIds: FileData["fileId"][];
  initialFiles?: File[];
}): Promise<File[]> => {
  let files = initialFiles;

  if (!isListEmpty(fileIds)) {
    try {
      const { data } = await fetchFilesByIds({
        ids: fileIds,
      });

      files = data.map(
        ({ fileId, fileName, fileSizeInBytes, fileTypeName }) => ({
          id: fileId,
          uid: fileId,
          name: fileName,
          size: fileSizeInBytes,
          fileType: fileTypeName,
        })
      );
    } catch (_) {}
  }

  return files;
};
