import { useContext, useEffect, useState } from "react";
import { ResultTableContext } from "contexts";
import {
  useSpecificationActions,
  useSpecificationPermissionsFromApi,
} from "hooks";
import { isObjectEmpty, isListEmpty } from "utils/general";
import { getActionsList } from "./utils";
import { MenuActions } from "types/general";
import { SpecificationListItemViemModel } from "viewModels/specificationList";

const useActions = ({
  specification,
  onOpenSelectSupplierModal,
  setSpecificationPermissions,
}: {
  specification: SpecificationListItemViemModel;

  onOpenSelectSupplierModal: Function;
  setSpecificationPermissions: Function;
}) => {
  const {
    currentPage,
    resetCurrentPage,
    setShouldRefetchSpecifications,
  } = useContext(ResultTableContext);

  const [actionList, setActionList] = useState<MenuActions[]>([{}]);

  const { isDone, permissionList } = useSpecificationPermissionsFromApi({
    specificationId: specification.specificationId,
    state: specification.state,
  });

  let actions = useSpecificationActions(specification, permissionList);

  useEffect(() => {
    if (!isListEmpty(permissionList)) {
      setSpecificationPermissions({
        [specification.specificationId]: permissionList,
      });
    }
  }, [permissionList]);

  useEffect(() => {
    if (!isObjectEmpty(actions)) {
      setActionList(
        getActionsList({
          actions,
          resetCurrentPage,
          onOpenSelectSupplierModal,
          currentPage,
          setShouldRefetchSpecifications,
        })
      );
    }
  }, [JSON.stringify(actions)]);

  useEffect(() => {
    if (isDone && isObjectEmpty(actions)) {
      //set disabled the 3 dot icon
      setActionList([]);
    }
  }, [isDone]);

  return { isDone, actionList };
};

export default useActions;
