import { useAppDispatch } from "store";
import { MaterialRecipeIngredientData, MaterialSupplierData } from "models";
import { MaterialRecipeIngredientViewModel } from "viewModels";
import { updateSupplierIngredient } from "store/materialForm/asyncActions";
import { isListEmpty } from "utils/general";

const useSuppliersList = ({
  materialIngredients,
  assignedIngredientIds,
}: {
  materialIngredients: MaterialRecipeIngredientViewModel[];
  assignedIngredientIds: MaterialSupplierData["ingredientIds"];
}) => {
  const dispatch = useAppDispatch();

  const ingredientOptions = !isListEmpty(materialIngredients)
    ? materialIngredients.reduce((prevValue, currentValue) => {
        if (currentValue.firstLevel) {
          prevValue = [
            ...prevValue,
            {
              label: currentValue.name,
              value: currentValue.ingredientId,
            },
          ];
        }
        return prevValue;
      }, [])
    : [];

  const onSelectSupplierIngredient = ({
    supplierId,
  }: {
    supplierId: MaterialSupplierData["supplierId"];
  }) => (ingredientId: MaterialRecipeIngredientData["ingredientId"]) => {
    const ingredientIds = [...assignedIngredientIds, ingredientId];

    dispatch(
      updateSupplierIngredient({
        supplierId,
        ingredientIds,
      })
    );
  };

  const onDeselectSupplierIngredient = ({
    supplierId,
  }: {
    supplierId: MaterialSupplierData["supplierId"];
  }) => (ingredientId: MaterialRecipeIngredientData["ingredientId"]) => {
    const ingredientIds = assignedIngredientIds.filter(
      assignedIngredientId => assignedIngredientId !== ingredientId
    );

    dispatch(
      updateSupplierIngredient({
        supplierId,
        ingredientIds,
      })
    );
  };

  return {
    onSelectSupplierIngredient,
    onDeselectSupplierIngredient,
    ingredientOptions,
  };
};

export default useSuppliersList;
