import { State } from "./types";

const initialState: State = {
  unitsOfMeasure: [],
  categoryDictionary: {},
  refListDictionary: {},
  catalogDictionary: {},
  servingDictionary: {},
};

export default initialState;
