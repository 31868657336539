import { Col, Row } from "antd";
import { Button } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import { messages } from "./messages";
import { isListEmpty } from "utils/general";
import { ExpandedRowKeysButtonsProps } from "./types";
import styles from "./styles.module.less";

const ExpandedRowKeysButtons: React.FC<ExpandedRowKeysButtonsProps> = ({
  expandedRowKeys,
  allExpandableRowKeys,
  onExpandAllClick,
  onCollapseAllClick,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Row gutter={[24, 0]} className={styles.expandedButtons}>
      <Col>
        <Button
          type="link"
          onClick={onExpandAllClick}
          disabled={expandedRowKeys.length === allExpandableRowKeys.length}
        >
          {formatMessage(messages.expandAll)}
        </Button>
      </Col>
      <Col>
        <Button
          type="link"
          onClick={onCollapseAllClick}
          disabled={isListEmpty(expandedRowKeys)}
        >
          {formatMessage(messages.collapseAll)}
        </Button>
      </Col>
    </Row>
  );
};
export default ExpandedRowKeysButtons;
