import { Col, Row } from "antd";
import { Paragraph } from "@trace-one/design-system";
import { Dictionary } from "types/general";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { getCaliberCategory } from "./utils";
import styles from "./styles.module.less";
import { isObjectEmpty } from "utils/general";

const CaliberCategoryReadOnly = ({
  caliberCategoryContent,
  refListDictionary,
  emptyValue,
}: {
  caliberCategoryContent?: React.ReactElement<
    any,
    string | React.JSXElementConstructor<any>
  >;
  refListDictionary?: Dictionary<string>;
  emptyValue?: string;
}) => {
  const { getTranslationForKey } = useFormSchemaTranslation();

  if (isObjectEmpty(caliberCategoryContent)) {
    return null;
  }

  const { formData, uiSchema } = caliberCategoryContent?.props;

  const { title, content, toleranceTitle, tolerance } = getCaliberCategory({
    formData,
    uiSchema,
    refListDictionary,
    emptyValue,
    getTranslationForKey,
  });

  if (!title && !toleranceTitle) {
    return null;
  }

  return (
    <Row className={styles.caliberCategoryReadOnlyRow}>
      <Col xs={4}>
        <Paragraph size="xs" color="grey-5">
          {title}
        </Paragraph>
      </Col>
      <Col xs={8}>
        <Paragraph size="xs" color="grey-4">
          {content}
        </Paragraph>
      </Col>
      <Col xs={6}>
        <Paragraph size="xs" color="grey-5">
          {toleranceTitle}
        </Paragraph>
      </Col>
      <Col xs={6}>
        <Paragraph size="xs" color="grey-4">
          {tolerance}
        </Paragraph>
      </Col>
    </Row>
  );
};

export default CaliberCategoryReadOnly;
