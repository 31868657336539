import React from "react";
import { Editor } from "@trace-one/react-components";
import { useDebouncedActions } from "components/Debounced/hooks";
import { TextareaProps } from "./types";

const Textarea: React.FC<TextareaProps> = ({
  defaultValue,
  onChange,
  shouldApplyDebounceImmediate,
  debounceTimer,
  errorMessage,
  ...rest
}) => {
  const { onTextareaChange, handleDebouncedValueChange } = useDebouncedActions({
    defaultValue,
    debounceTimer,
    shouldApplyDebounceImmediate,
    onChange,
  });

  return (
    <Editor
      initData={defaultValue}
      onBlur={handleDebouncedValueChange}
      onChange={onTextareaChange}
      error={errorMessage}
      configProps={{ versionCheck: false }} // added versionCheck to suppress upgrade warning; remove after upgrade
      {...rest}
    />
  );
};

Textarea.defaultProps = {
  shouldApplyDebounceImmediate: true,
  debounceTimer: 2000,
};

export default Textarea;
