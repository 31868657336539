import React, { useMemo, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Heading } from "@trace-one/design-system";
import { Row, Col } from "antd";
import { FilterBarProps } from "./types";
import { HistorisationContext } from "components/SpecificationHistorisationModal/contexts";
import { filterMessages } from "components/SpecificationHistorisationModal/messages";
import styles from "components/SpecificationHistorisationModal/styles.module.less";
import { hasFilters } from "components/SpecificationHistorisationModal/components/HeaderChildren/utils";

const FilterBar: React.FC<FilterBarProps> = ({
  isPanelOpen,
  onUpdatePanelVisibility,
}) => {
  const { formatMessage } = useIntl();

  const {
    selectedFilters,
    areFiltersDisabled,
    onClearFiltersClick,
  } = useContext(HistorisationContext);

  const showClearFiltersButton = useMemo(() => hasFilters(selectedFilters), [
    selectedFilters,
  ]);

  return (
    <Row gutter={[30, 24]} className={styles.moreFiltersBar}>
      <Col>
        <Button type="tertiary" onClick={onUpdatePanelVisibility}>
          {isPanelOpen
            ? formatMessage(filterMessages.hideFilters)
            : formatMessage(filterMessages.moreFilters)}
        </Button>
      </Col>
      {showClearFiltersButton && (
        <Col>
          <Heading size="xs">
            <Button
              type="link"
              onClick={() => onClearFiltersClick()}
              disabled={areFiltersDisabled}
            >
              <FormattedMessage {...filterMessages["clearAllFilters"]} />
            </Button>
          </Heading>
        </Col>
      )}
    </Row>
  );
};

export default FilterBar;
