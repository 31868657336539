import { useMemo } from "react";
import {
  useCategoryItems,
  useReferenceListsItems,
  useSpecificationReflistIds,
} from "hooks";
import { isListEmpty } from "utils/general";
import Chapter from "./components/Chapter";
import { ChaptersProps } from "./types";

const Chapters: React.FC<ChaptersProps> = ({ chapters }) => {
  const { getReflistIdsFromSections } = useSpecificationReflistIds();

  const ids = useMemo(() => {
    return getReflistIdsFromSections(chapters);
  }, []);

  useReferenceListsItems({
    refListIds: [...ids.reflistIds],
  });

  useCategoryItems({
    categoryItemsIds: [...ids.hierarchyIds],
  });

  return (
    <div>
      {chapters.map(chapter => {
        if (isListEmpty(chapter.sections)) {
          return null;
        }

        return <Chapter key={chapter.sectionId} chapter={chapter} />;
      })}
    </div>
  );
};

export default Chapters;
