import React from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import {
  selectSelectedProduct,
  selectSpecification,
} from "store/specificationCreation/selectors";
import withUnitsOfMeasure from "hocs/withUnitsOfMeasure";
import AssignedProduct from "components/AssignedProduct";
import { isAssignProductButtonDisabled } from "./utils";
import { messages } from "../../messages";
import { AssignProductProps } from "./types";

const AssignProduct: React.FC<AssignProductProps> = ({
  onRemoveProduct,
  onSaveProducts,
}) => {
  const { formatMessage } = useIntl();
  const { productTypeId } = useSelector(selectSpecification);
  const selectedProduct = useSelector(selectSelectedProduct);
  return (
    <>
      {!selectedProduct.id ? (
        <AssignedProduct.NoProduct />
      ) : (
        <div data-testid="list-items">
          <AssignedProduct.Specification
            onRemoveClick={onRemoveProduct}
            product={selectedProduct}
          />
        </div>
      )}
      <AssignedProduct.AddButtons
        areButtonsDisabled={isAssignProductButtonDisabled({
          selectedProductId: selectedProduct.id,
          selectedProductTypeId: productTypeId,
        })}
        onSaveProducts={onSaveProducts}
        maxNumberOfProducts={1}
        modalDescription={
          <span data-testid="page-subtitle">
            {formatMessage(messages.firstHelpMessage)}
            <br />
            {formatMessage(messages.secondHelpMessage)}
          </span>
        }
      />
    </>
  );
};

export default withUnitsOfMeasure(AssignProduct);
