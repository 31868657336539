import { useState } from "react";
import { Col, Form, Popconfirm, Radio, Row } from "antd";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { ConditionalRadioProps } from "./types";

const ConditionalRadio: React.FC<ConditionalRadioProps> = ({
  items,
  onConfirm,
  onChange,
  conditionalLabel,
  disabled,
  value,
}) => {
  const { getTranslationForKey } = useFormSchemaTranslation();
  const [isPopconfirmVisible, setIsPopconfirmVisible] = useState(false);

  const handleOnVisibleChange = value => {
    if (!value) {
      setIsPopconfirmVisible(false);
    } else {
      setIsPopconfirmVisible(value);
    }
  };

  return (
    <Row gutter={[10, 10]}>
      <Col xl={24}>
        <Form.Item
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          label={conditionalLabel}
        >
          <Radio.Group onChange={onChange} value={value} disabled={disabled}>
            <Radio value={true}>
              <span>{getTranslationForKey("yes")}</span>
            </Radio>
            <Popconfirm
              visible={isPopconfirmVisible}
              title={getTranslationForKey("confirmRemoveTitle")}
              okText={getTranslationForKey("yes")}
              cancelText={getTranslationForKey("no")}
              onConfirm={onConfirm}
              onVisibleChange={handleOnVisibleChange}
            >
              <Radio value={false}>
                <span>{getTranslationForKey("no")}</span>
              </Radio>
            </Popconfirm>
          </Radio.Group>
        </Form.Item>
      </Col>
    </Row>
  );
};

ConditionalRadio.defaultProps = {
  disabled: false,
};

export default ConditionalRadio;
