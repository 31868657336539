import { MaterialRecipeIngredientViewModel } from "viewModels";

export const getTotalPercentageRootIngredients = (
  ingredients: MaterialRecipeIngredientViewModel[]
) =>
  ingredients?.reduce((acc, curr) => {
    if (curr.firstLevel) {
      acc += curr.percentage;
    }
    return acc;
  }, 0);

export const getLastParentIngredients = (
  ingredients: MaterialRecipeIngredientViewModel[],
  parentIdsPath: string[] = []
) => {
  const parentIngredients: MaterialRecipeIngredientViewModel[] = parentIdsPath?.reduce(
    (previousData, currentElement) => {
      const parentIngredient = previousData.find(
        ({ ingredientId }) => ingredientId === currentElement
      );

      return parentIngredient?.ingredients || [];
    },
    ingredients
  );

  return parentIngredients;
};
