import { useSelector } from "react-redux";
import { useAppDispatch } from "store";
import {
  addTag,
  removeTag,
  updateName,
  updateProductType,
  updateType,
} from "store/chapterForm/asyncActions";
import { selectGeneraInfo } from "store/chapterForm/selectors";

const useGeneralInfoActions = () => {
  const dispatch = useAppDispatch();
  const generalInfo = useSelector(selectGeneraInfo);

  const onUpdateChapterName = async (value: string) => {
    await dispatch(updateName(value));
  };

  const onUpdateChapterType = async (chapterType: string) => {
    const { meta } = await dispatch(updateType(chapterType));
    return meta;
  };

  const onUpdateProductType = async (productTypeId: string) => {
    const { meta } = await dispatch(updateProductType(productTypeId));
    return meta;
  };

  const onUpdateTags = async (tagIds: string[]) => {
    const formDataTags = generalInfo?.tagIds || [];

    if (formDataTags?.length === tagIds.length) {
      return;
    }

    if (formDataTags?.length > tagIds.length) {
      const tagToRemove = formDataTags.find(
        currentTagId => !tagIds.includes(currentTagId)
      );

      await dispatch(removeTag(tagToRemove));
    } else {
      const tagToAdd = tagIds.find(
        currentTagId => !formDataTags.includes(currentTagId)
      );

      await dispatch(addTag(tagToAdd));
    }
  };

  return {
    onUpdateChapterName,
    onUpdateChapterType,
    onUpdateProductType,
    onUpdateTags,
  };
};

export default useGeneralInfoActions;
