import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { fetchNutriscoreProductTypes } from "apis/CATALOGUE";
import { selectLanguageCode } from "store/user/selectors";
import { NutriScoreProductTypeData } from "models";

export default function useNutriscoreWidget() {
  const languageCode = useSelector(selectLanguageCode);
  const [productTypes, setProductTypes] = useState<NutriScoreProductTypeData[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const productTypeOptions = useMemo(() => {
    return productTypes.map(({ code = "", name = "" }) => ({
      label: name,
      value: code,
    }));
  }, [productTypes]);

  useEffect(() => {
    const getNutriscoreProductTypes = async () => {
      try {
        setIsLoading(true);
        const { data } = await fetchNutriscoreProductTypes({
          params: { languageCode },
        });

        setProductTypes(data);
      } catch (error) {
        setHasError(true);
      } finally {
        setIsLoading(false);
      }
    };

    getNutriscoreProductTypes();
  }, []);

  return {
    productTypeOptions,
    isLoading,
    hasError,
  };
}
