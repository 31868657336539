import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { fetchMaterial } from "apis/SPEC";
import { useAppDispatch } from "store";
import { setFormData } from "store/materialForm/materialFormSlice";
import { selectMaterialInformation } from "store/materialForm/selectors";
import { selectLanguageCode } from "store/user/selectors";
import { isObjectEmpty, isUuidEmpty } from "utils/general";
import { getMaterialApiType } from "utils/library";
import { MaterialData } from "models";

const useMaterialFormInitialisation = ({
  initialFormData = {},
}: {
  initialFormData: MaterialData;
}) => {
  const dispatch = useAppDispatch();
  const materialInformation = useSelector(selectMaterialInformation);
  const languageCode = useSelector(selectLanguageCode);
  const [isLoading, setIsLoading] = useState(true);

  const isInitialFormDataEmpty = useMemo(
    () => isObjectEmpty(initialFormData) || isUuidEmpty(initialFormData.id),
    [initialFormData]
  );

  const getMaterial = async () => {
    try {
      const { data } = await fetchMaterial({
        materialId: materialInformation.id,
        materialType: getMaterialApiType({
          materialType: materialInformation.materialType,
          recipeType: materialInformation.recipeType,
        }),
        languageCode,
      });

      dispatch(setFormData(data));
    } catch (_) {}
  };

  // initialize the store with the data provided from initialFormData or from the API
  useEffect(() => {
    const prepareMaterial = async () => {
      setIsLoading(true);

      if (!isInitialFormDataEmpty) {
        dispatch(setFormData(initialFormData));
      }

      setIsLoading(false);
    };

    prepareMaterial();
  }, []);

  // Refetch the material on the creation page when the language is changed
  useEffect(() => {
    if (isInitialFormDataEmpty && materialInformation.id) {
      getMaterial();
    }
  }, [languageCode]);

  return {
    isLoading,
  };
};

export default useMaterialFormInitialisation;
