import { Button } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import { useRedirect } from "hooks";
import { messages } from "./messages";
import styles from "./styles.module.less";

const BackButton = () => {
  const intl = useIntl();
  const { redirectToFolderListing } = useRedirect();

  return (
    <div className={styles.backButtonWrapper}>
      <Button
        onClick={redirectToFolderListing}
        type="link"
        data-testid="back-btn"
        iconName="arrow-left"
        linkColor="grey-3"
      >
        {intl.formatMessage(messages.backToFolders)}
      </Button>
    </div>
  );
};

export default BackButton;
