import { PackagingSystemComponentData } from "models";
import { isListEmpty } from "utils/general";

export const getPackagingComponentIdsForAssociationUpdate = ({
  packagingComponentIds = [],
  componentId,
  selectedPackagingComponentsIds,
}: {
  packagingComponentIds?: PackagingSystemComponentData["componentId"][];
  componentId?: PackagingSystemComponentData["componentId"];
  selectedPackagingComponentsIds?: PackagingSystemComponentData["componentId"][];
}) => {
  if (componentId) {
    return packagingComponentIds?.filter(id => id !== componentId);
  }

  if (!isListEmpty(selectedPackagingComponentsIds)) {
    return [...packagingComponentIds, ...selectedPackagingComponentsIds];
  }

  return [...packagingComponentIds];
};
