import useQuery from "hooks/useQuery";
import useRedirect from "hooks/useRedirect";
import {
  LIBRARY_OBJECT_API_TYPES,
  LIBRARY_OBJECT_TYPES,
} from "utils/constants";

const REDIRECT_MAP = {
  [LIBRARY_OBJECT_API_TYPES.material]: LIBRARY_OBJECT_TYPES.MATERIALS,
  [LIBRARY_OBJECT_API_TYPES.requirement]: LIBRARY_OBJECT_TYPES.REQUIREMENTS,
  [LIBRARY_OBJECT_API_TYPES.chapter]: LIBRARY_OBJECT_TYPES.CHAPTERS,
  [LIBRARY_OBJECT_API_TYPES["packaging-systems"]]:
    LIBRARY_OBJECT_TYPES.PACKAGING_SYSTEMS,
  [LIBRARY_OBJECT_API_TYPES.template]: LIBRARY_OBJECT_TYPES.TEMPLATES,
  [LIBRARY_OBJECT_API_TYPES.claim]: LIBRARY_OBJECT_TYPES.CLAIMS,
};

const useGoBack = (libraryObjectType?: LIBRARY_OBJECT_API_TYPES) => {
  const {
    redirectToLibraryListing,
    redirectToSpecificationDetails,
  } = useRedirect();
  const query = useQuery();

  const specificationId = query.get("specificationId");
  const sectionId = query.get("sectionId");

  const goBack = () => {
    if (specificationId && sectionId) {
      redirectToSpecificationDetails(specificationId, sectionId);
      return;
    }

    redirectToLibraryListing(REDIRECT_MAP[libraryObjectType]);
  };

  return {
    goBack,
  };
};

export default useGoBack;
