import { useState } from "react";
import { useRedirect, useResume } from "hooks";
import { PERMISSIONS } from "utils/constants";
import { isListEmpty, isObjectEmpty } from "utils/general";
import { isStateDraft } from "utils/specifications";
import { getOnCellClick } from "./utils";
import { SpecificationPermissionsData } from "../../types";
import { SpecificationListItemViemModel } from "viewModels/specificationList";

const usePermissionsRowClick = () => {
  const { resumeSpecification } = useResume();
  const { redirectToSpecificationDetails } = useRedirect();
  const [
    specificationPermissionList,
    setSpecificationPermissionList,
  ] = useState<SpecificationPermissionsData>({});

  const setSpecificationPermissions = (
    specificationPermissions: SpecificationPermissionsData
  ) => {
    if (!isObjectEmpty(specificationPermissions)) {
      setSpecificationPermissionList(previousState => ({
        ...previousState,
        ...specificationPermissions,
      }));
    }
  };

  const onCellClick = (specification: SpecificationListItemViemModel) => {
    const { state } = specification;

    if (isStateDraft(state)) {
      if (isObjectEmpty(specificationPermissionList)) {
        return;
      }
      const specificationPermissions =
        specificationPermissionList[specification.specificationId];

      if (
        isListEmpty(specificationPermissions) ||
        (specificationPermissions &&
          specificationPermissions.length === 1 &&
          specificationPermissions[0] === PERMISSIONS.SPECIFICATION.READ)
      )
        return;
    }

    return {
      onClick: getOnCellClick({
        specification,
        resumeSpecification,
        redirectToSpecificationDetails,
      }),
    };
  };

  return {
    onCellClick,
    specificationPermissionList,
    setSpecificationPermissions,
  };
};

export default usePermissionsRowClick;
