import { useState } from "react";
import { Button, Heading, TextArea } from "@trace-one/design-system";
import { Popover } from "@trace-one/react-components";
import { useIntl } from "react-intl";
import { messages } from "components/CreateCollaboration/messages";
import { CollaborationEmailInvitationProps } from "./types";
import styles from "./styles.module.less";

const CollaborationEmailInvitation: React.FC<CollaborationEmailInvitationProps> = ({
  collaboration,
  onCreateCollaboration,
}) => {
  const { formatMessage } = useIntl();
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [customMessage, setCustomMessage] = useState<string>("");

  return (
    <Popover
      placement="rightTop"
      trigger="click"
      visible={isPopoverVisible}
      onVisibleChange={setIsPopoverVisible}
      content={() => (
        <div className={styles.launchInvitation}>
          <Heading size="xs" className={styles.emailInvitationTitle}>
            {formatMessage(messages.emailInvitationTitle)}
          </Heading>
          <TextArea
            value={customMessage}
            onChange={({ target: { value } }) => setCustomMessage(value)}
          />
          <div className={styles.buttonContainer}>
            <Button
              type="secondary"
              data-test-id="cancel-email-invitation"
              onClick={() => setIsPopoverVisible(false)}
            >
              {formatMessage(messages.cancelEmailInvitation)}
            </Button>
            <Button
              type="primary"
              data-test-id="send-email-invitation"
              loading={collaboration.loading}
              onClick={onCreateCollaboration({
                collaborationKey: collaboration.key,
                userEmail: collaboration.userEmail,
                customMessage,
              })}
            >
              {formatMessage(messages.sendEmailInvitation)}
            </Button>
          </div>
        </div>
      )}
      getPopupContainer={trigger => trigger.parentNode}
      overlayClassName={styles.launchInvitationPopover}
    >
      <Button
        type="tertiary"
        data-testid="create-new-collaboration"
        className={isPopoverVisible ? styles.pressedState : ""}
      >
        {formatMessage(messages.invite)}
      </Button>
    </Popover>
  );
};

export default CollaborationEmailInvitation;
