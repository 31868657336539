import { defineMessages } from "react-intl";

export const messages = defineMessages({
  formRetailerContact: {
    id: "creationFolder.form.retailerContact",
    defaultMessage: "Retailer Contact(s)",
  },

  formRetailerContactPlaceholder: {
    id: "creationFolder.form.retailerContact.placeholder",
    defaultMessage: "Choose a Retailer contact",
  },
});
