import { RequirementData } from "models";
import { PaginatedList, PaginationParams } from "types/api";
import { AxiosRequestConfig } from "axios";
import { axiosInstance } from "./";
import { RequirementListItemData } from "models/requirements";
import { LibraryItemApiType, LibraryItemState } from "types/library";
export interface RequirementApiParams {
  requirementId?: RequirementData["id"];
  requirementType?: LibraryItemApiType;
}

export const fetchRequirements = ({ params }) =>
  axiosInstance.get<{
    totalRequirements: number;
    requirements: RequirementData[];
  }>(`api/requirements`, {
    params,
  });

export const fetchRequirement = ({
  requirementId,
  requirementType,
}: RequirementApiParams) =>
  axiosInstance.get<RequirementData>(
    `/api/${requirementType}/${requirementId}`
  );

export const fetchRequirementHistory = ({
  id,
}: {
  id: RequirementData["id"];
}) => axiosInstance.get(`/api/requirements/${id}/history`);

export const fetchRequirementListing = ({
  params,
  paramsSerializer,
}: {
  params: PaginationParams & {
    ownerCompanyId: string;
    name?: string;
    requirementType?: string;
    states?: LibraryItemState[];
  };
  paramsSerializer?: AxiosRequestConfig["paramsSerializer"];
}) =>
  axiosInstance.get<PaginatedList<RequirementListItemData>>(
    `/api/requirements/search`,
    {
      params,
      paramsSerializer,
    }
  );

export const createRequirement = ({
  requirementType,
  payload,
}: {
  requirementType: LibraryItemApiType;
  payload:
    | Pick<RequirementData, "name" | "content" | "tagIds">
    | Pick<
        RequirementData,
        "name" | "fileId" | "additionalInformation" | "tagIds"
      >;
}) => axiosInstance.post<RequirementData>(`/api/${requirementType}`, payload);

export const updateRequirement = ({
  requirementId,
  requirementType,
  payload,
}: RequirementApiParams & {
  payload:
    | Pick<RequirementData, "name" | "content">
    | Pick<RequirementData, "name" | "fileId" | "additionalInformation">;
}) =>
  axiosInstance.put<RequirementData>(
    `/api/${requirementType}/${requirementId}`,
    payload
  );

export const addRequirementTags = ({
  requirementId,
  requirementType,
  tagIds,
}: RequirementApiParams & {
  tagIds: RequirementData["tagIds"];
}) =>
  axiosInstance.post<RequirementData>(
    `/api/${requirementType}/${requirementId}/tags`,
    tagIds,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

export const deleteRequirementTag = ({
  requirementId,
  requirementType,
  tagId,
}: RequirementApiParams & {
  tagId: string;
}) =>
  axiosInstance.delete<RequirementData>(
    `/api/${requirementType}/${requirementId}/tags/${tagId}`
  );

export const shareRequirement = ({
  requirementId,
  requirementType,
  companyId,
}: RequirementApiParams & {
  companyId?: string;
}) =>
  axiosInstance.post<void>(`/api/${requirementType}/${requirementId}/share`, {
    companyId,
  });

export const deleteRequirement = ({
  requirementId,
  requirementType,
}: RequirementApiParams) =>
  axiosInstance.delete<void>(`/api/${requirementType}/${requirementId}`);

export const publishRequirement = ({
  requirementId,
  requirementType,
}: RequirementApiParams) =>
  axiosInstance.post<void>(`/api/${requirementType}/${requirementId}/publish`);

export const fetchRequirementById = ({
  requirementId,
  requirementType,
}: RequirementApiParams) =>
  axiosInstance.get<RequirementData>(
    `/api/${requirementType}/${requirementId}`
  );

export const validateRequirement = ({
  requirementId,
  requirementType,
}: RequirementApiParams) =>
  axiosInstance.post<void>(`/api/${requirementType}/${requirementId}/validate`);
