import { FormContext } from "components/SpecificationSection/types";
import { createContext } from "react";

const PackagingSystemTableContext = createContext<{
  displayActions: boolean;
  hasAddPackagingSystemPermission: boolean;
  chapterData: any;
  specificationData: FormContext["specificationData"];
}>({
  displayActions: true,
  hasAddPackagingSystemPermission: false,
  chapterData: {},
  specificationData: {},
});

export default PackagingSystemTableContext;
