export const loadExistingSpecificationsForProduct = async ({
  setIsSpecificationsLoading,
  fetchExistingSpecificationsForProduct,
  dispatch,
  productId,
}) => {
  if (productId) {
    setIsSpecificationsLoading(true);

    await dispatch(fetchExistingSpecificationsForProduct(productId));

    setIsSpecificationsLoading(false);
  }
};
