import { Col, Row } from "antd";
import { Paragraph } from "@trace-one/design-system";
import classNames from "classnames";
import { isObjectEmpty } from "utils/general";
import { OriginProps } from "components/Origin/types";
import styles from "./styles.module.less";

const OriginReadOnly = ({ data }: { data?: OriginProps["data"] }) => {
  const originFields = ["originTypeId", "countryId", "regionId", "zoneId"];

  if (isObjectEmpty(data)) {
    return null;
  }

  return (
    <Col xs={24} className={styles.originReadOnly}>
      {originFields.map(field =>
        !isObjectEmpty(data[field]) ? (
          <Row className={styles.originFieldRow} key={field}>
            <Col xs={4}>
              <Paragraph
                size="xs"
                color="grey-5"
                className={classNames(
                  data[field].required ? styles.required : ""
                )}
              >
                {data[field].label}
              </Paragraph>
            </Col>
            <Col xs={20}>
              <Paragraph size="xs" color="grey-4">
                {data[field].value}
              </Paragraph>
            </Col>
          </Row>
        ) : null
      )}
    </Col>
  );
};

export default OriginReadOnly;
