import { deleteSpecification } from "apis/SPEC";
import { useRedirect } from "hooks";
import { SpecificationData, SpecificationVersionData } from "models";
import { getPreviousVersion } from "utils/specifications";

const useRevisionActions = ({
  specificationId,
  history,
}: {
  specificationId: SpecificationData["id"];
  history: SpecificationVersionData[];
}) => {
  const { redirectToSpecificationDetails } = useRedirect();

  const onCancelRevision = async () => {
    try {
      await deleteSpecification({ id: specificationId });

      const previousVersion = getPreviousVersion(specificationId, history);

      redirectToSpecificationDetails(previousVersion.specificationId);
    } catch (e) {}
  };

  return {
    onCancelRevision,
  };
};

export default useRevisionActions;
