import dayjs from "dayjs";
import { FormattedMessage } from "react-intl";
import { isStateInRevision } from "utils/specifications";
import { VersionMessageProps } from "./types";
import { quickViewMessages } from "components/Quickview/messages";

const VersionMessage: React.FC<VersionMessageProps> = ({
  activeVersion,
  creationDateUtc,
  state,
}) => {
  if (activeVersion)
    return <FormattedMessage {...quickViewMessages.currentVersion} />;

  if (isStateInRevision(state))
    return <FormattedMessage {...quickViewMessages.inRevisionVersion} />;

  return <>{dayjs(creationDateUtc).local().format("L")}</>;
};

export default VersionMessage;
