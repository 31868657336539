import cx from "classnames";
import { FormattedMessage } from "react-intl";
import { QuickviewLegendProps } from "./types";
import { messages } from "./messages";
import styles from "./styles.module.less";

const QuickviewLegend: React.FC<QuickviewLegendProps> = ({
  hasSignatureColumn,
  hasDownloadColumn,
}) => {
  return (
    <>
      <div className={cx(styles.legendWrapper, styles.secondColumn)}>
        <div className={cx(styles.legend, styles.grey)}>
          <div className={styles.miniprogress} />
          <FormattedMessage {...messages.fill} />
        </div>
        <div className={cx(styles.legend, styles.orange)}>
          <div className={styles.miniprogress} />
          <FormattedMessage {...messages.writing} />
        </div>
        <div className={cx(styles.legend, styles.blue)}>
          <div className={styles.miniprogress} />
          <FormattedMessage {...messages.validatedSuplier} />
        </div>
        <div className={cx(styles.legend, styles.green)}>
          <div className={styles.miniprogress} />
          <FormattedMessage {...messages.validatedRetailer} />
        </div>
      </div>
      {hasSignatureColumn && (
        <div className={cx(styles.signatureWrapper)}>
          <FormattedMessage {...messages.signature} />
        </div>
      )}
      {hasDownloadColumn && <div className={styles.downloadWrapper} />}
    </>
  );
};

QuickviewLegend.defaultProps = {
  hasSignatureColumn: true,
};

export default QuickviewLegend;
