import { Dictionary } from "types/general";
import {
  capitalizeFirstLetter,
  getRefListDictionaryValue,
  isListEmpty,
  isObjectEmpty,
} from "utils/general";
import { TreatmentWidgetReadOnlyField } from "./types";

export const getTreatmentFieldValue = ({
  field,
  refListDictionary,
  emptyValue,
}: {
  field: TreatmentWidgetReadOnlyField;
  refListDictionary: Dictionary<string>;
  emptyValue: string;
}) => {
  if (isObjectEmpty(field) || isObjectEmpty(field.content?.props)) {
    return emptyValue;
  }

  const { formData } = field?.content?.props;
  let value = emptyValue;

  if (!isListEmpty(field.options)) {
    value =
      (formData &&
        (field.options.filter(option => option.value === formData)[0]
          .label as string)) ||
      emptyValue;
  } else if (typeof formData === "object" && !isObjectEmpty(formData)) {
    value = `${
      formData.value ? formData.value : emptyValue
    } ${getRefListDictionaryValue({
      id: formData.unit,
      refListDictionary,
      emptyValue,
    })}`;
  } else {
    value = field.hasRefListValue
      ? getRefListDictionaryValue({
          id: formData,
          refListDictionary,
          emptyValue,
        })
      : formData
      ? formData
      : emptyValue;
  }

  return capitalizeFirstLetter(value);
};
