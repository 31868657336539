import { defineMessages } from "react-intl";

const messages = defineMessages({
  characteristicsCardTitle: {
    id: "library.rawMaterialForm.characteristics.cardTitle",
    defaultMessage: "Characteristics",
  },
});

export default messages;
