import { FormattedMessage } from "react-intl";
import { Paragraph } from "@trace-one/design-system";
import { Col } from "antd";
import { ResultTableContext } from "contexts";
import { useSpecificationPaginatedList } from "hooks";
import Filters from "./components/Filters";
import ResultTable from "components/ResultTable";
import { errorMessages } from "messages";
import styles from "./styles.module.less";

const SpecificationListing = () => {
  const {
    isLoading,
    hasError,
    specifications,
    totalNumberOfSpecifications,
    pagination,
    filters,
    onUpdateFilters,
    resetCurrentPage,
    onTableChange,
    searchSpecifications,
    setShouldRefetchSpecifications,
  } = useSpecificationPaginatedList();

  return (
    <ResultTableContext.Provider
      value={{
        currentPage: pagination.current,
        searchSpecifications,
        resetCurrentPage,
        specifications,
        setShouldRefetchSpecifications,
      }}
    >
      <Filters onUpdateFilters={onUpdateFilters} />
      <div className={styles.specificationListContainer}>
        <Col>
          {hasError && (
            <Paragraph color="red" data-testid="searchErrorMessage">
              <FormattedMessage {...errorMessages.searchError} />
            </Paragraph>
          )}
          <ResultTable
            onTableChange={onTableChange}
            pagination={pagination}
            isLoading={isLoading}
            specifications={specifications}
            totalNumberOfSpecifications={totalNumberOfSpecifications}
            mySpecificationsOnly={filters.mySpecificationsOnly}
            onUpdateFilters={onUpdateFilters}
          />
        </Col>
      </div>
    </ResultTableContext.Provider>
  );
};

export default SpecificationListing;
