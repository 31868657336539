import { IngredientListFormData } from "components/SpecificationSection/components/FormSchema/types";
import { FormContext } from "components/SpecificationSection/types";

const useIngredientListWidget = ({
  formContext,
  formData,
}: {
  formContext: FormContext;
  formData: IngredientListFormData;
}) => {
  const { extraActions } = formContext;

  const { displayOnPack, originalIngredientList } = formData;

  const handleChange = (fieldName: string) => (value: string | boolean) => {
    if (fieldName === "modifiedIngredientList" && value === undefined) {
      value = "";
    }

    const { originalIngredientList, ...rest } = formData;

    const dataToSend = {
      ...rest,
      [fieldName]: value,
    };

    extraActions?.onUpdateIngredientList({
      ...dataToSend,
    });
  };

  const editIngredientList = () => {
    extraActions?.onUpdateIngredientList({
      displayOnPack,
      modifiedIngredientList: originalIngredientList,
    });
  };

  const resetIngredientList = () => {
    extraActions?.onUpdateIngredientList({
      displayOnPack,
      modifiedIngredientList: null,
    });
  };

  return {
    handleChange,
    resetIngredientList,
    editIngredientList,
  };
};

export default useIngredientListWidget;
