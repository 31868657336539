import { defineMessages } from "react-intl";

export const messages = defineMessages({
  listingResultNumber: {
    id: "specificationListing.table.listingResultNumber",
    defaultMessage: "Displaying {current} out of {total} specifications",
  },

  creationDate: {
    id: "folderListing.table.creationDate",
    defaultMessage: "Creation date",
  },

  lastUpdate: {
    id: "folderListing.table.lastUpdate",
    defaultMessage: "Last update",
  },

  lifeTime: {
    id: "specificationListing.table.lifetime",
    defaultMessage: "Life Time",
  },

  oneDay: {
    id: "specificationListing.table.oneDay",
    defaultMessage: "1 day",
  },

  owner: {
    id: "folderListing.table.owner",
    defaultMessage: "Owner",
  },

  productName: {
    id: "specificationListing.table.productName",
    defaultMessage: "Product",
  },

  productType: {
    id: "folderListing.table.productType",
    defaultMessage: "Product type",
  },

  progression: {
    id: "specificationListing.table.progression",
    defaultMessage: "Progression (%)",
  },

  retailerName: {
    id: "specificationListing.table.retailerName",
    defaultMessage: "Retailer",
  },

  supplierName: {
    id: "specificationListing.table.supplierName",
    defaultMessage: "Supplier",
  },

  status: {
    id: "status",
    defaultMessage: "Status",
  },

  today: {
    id: "general.today",
    defaultMessage: "Today",
  },

  switchText: {
    id: "specificationListing.table.switchText",
    defaultMessage: "My Specifications only",
  },
  lastModification: {
    id: "specificationListing.table.lastModification",
    defaultMessage: "Last modification",
  },

  generalDelete: {
    id: "specification.actions.delete",
    defaultMessage: "Delete specification",
  },

  draftDelete: {
    id: "specification.actions.draft.delete",
    defaultMessage: "Delete draft",
  },

  versionDelete: {
    id: "specification.actions.version.delete",
    defaultMessage: "Delete version",
  },

  inRevisionByRetailerCancel: {
    id: "specification.actions.revision.delete",
    defaultMessage: "Delete Revised Specification",
  },

  inRevisionBySupplierCancel: {
    id: "specification.actions.revision.cancel",
    defaultMessage: "Cancel revision",
  },

  display: {
    id: "specification.actions.display",
    defaultMessage: "Display specification",
  },

  edit: {
    id: "specification.actions.edit",
    defaultMessage: "Edit specification",
  },

  resumeCreation: {
    id: "specification.actions.draft.resume",
    defaultMessage: "Resume creation",
  },

  retailerRevise: {
    id: "specification.actions.revision.create.retailer",
    defaultMessage: "Create a revision",
  },

  supplierRevise: {
    id: "specification.actions.revision.create.supplier",
    defaultMessage: "Ask for Revision",
  },

  share: {
    id: "specification.actions.share",
    defaultMessage: "Share Specification",
  },

  duplicate: {
    id: "specification.actions.duplicate",
    defaultMessage: "Duplicate",
  },

  history: {
    id: "specification.actions.history",
    defaultMessage: "History",
  },
  createARevision: {
    id: "specification.actions.createARevision",
    defaultMessage: "Create a revision",
  },
  templateDescription: {
    id: "specification.templateDescription",
    defaultMessage: "Chapters and sections of specification",
  },
  selectATemplate: {
    id: "specification.selectATemplate",
    defaultMessage: "Select a template",
  },
  changeTemplate: {
    id: "specification.changeTemplate",
    defaultMessage: "Change Template",
  },
  changeTemplateBody: {
    id: "specification.changeTemplateBody",
    defaultMessage:
      "You are going to change the template. You will need to start over. Are you sure about this action?",
  },
});

export const translationManagementMessages = defineMessages({
  manageTranslation: {
    id: "specification.translationManagement.manageTranslation",
    defaultMessage: "Manage Translation",
  },
  startTranslation: {
    id: "specification.translationManagement.startTranslation",
    defaultMessage: "Start Translation",
  },
  overlayTitle: {
    id: "specification.translationManagement.overlayTitle",
    defaultMessage: "Translate Labelling chapter",
  },
  overlaySubtitle: {
    id: "specification.translationManagement.overlaySubtitle",
    defaultMessage:
      "You are about to define and notify collaborators to translate the chapter. The assigned translator(s) will be granted the translator role and access to this specification:",
  },
  stepOneTitle: {
    id: "specification.translationManagement.stepOneTitle",
    defaultMessage: "Select section(s) to translate",
  },
  stepOneDescription: {
    id: "specification.translationManagement.stepOneDescription",
    defaultMessage:
      "Select section(s) of the labeling chapter that are going to be reviewed by the designated translator.",
  },
  translationCompanySearchPlaceholder: {
    id:
      "specification.translationManagement.translationCompanySearchPlaceholder",
    defaultMessage: "Search for a translation agency",
  },
  translationCompanyLanguagePlaceholder: {
    id:
      "specification.translationManagement.translationCompanyLanguagePlaceholder",
    defaultMessage: "Select languages",
  },
  translationCompaniesTitle: {
    id: "specification.translationManagement.translationCompaniesTitle",
    defaultMessage: "Choose translation companies",
  },
  translationCompaniesSubtitle: {
    id: "specification.translationManagement.translationCompaniesSubtitle",
    defaultMessage:
      "Choose the translation companies that will translate your specifications and select the languages to assign to each company.",
  },
  inviteUserTitle: {
    id: "specification.translationManagement.inviteUserTitle",
    defaultMessage: "Invite user to translate",
  },
  inviteUserEmptyStateSubtitle: {
    id: "specification.translationManagement.inviteUserEmptyStateSubtitle",
    defaultMessage: "Select a company in step 2 to enable user invitation",
  },
  inviteUserSubtitle: {
    id: "specification.translationManagement.inviteUserSubtitle",
    defaultMessage:
      "Enable collaboration and invite user for each company to start the translation process",
  },
  addCompany: {
    id: "specification.translationManagement.addCompany",
    defaultMessage: "Add company",
  },
  removeCompanyConfirmation: {
    id: "specification.translationManagement.removeCompanyConfirmation",
    defaultMessage:
      "This company will no longer have access to the chapter translation for the selected languages.",
  },
  onGoingTranslations: {
    id: "specification.translationManagement.onGoingTranslations",
    defaultMessage: "On-going translations",
  },
});
