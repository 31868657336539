import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";

/* istanbul ignore next */
export const _selectChapterForm = (state: RootState) => state.chapterForm;

export const selectGeneraInfo = createSelector(
  _selectChapterForm,
  chapterForm => chapterForm.generalInfo
);

export const selectStructure = createSelector(
  _selectChapterForm,
  chapterForm => chapterForm.structure
);

export const selectSections = createSelector(
  _selectChapterForm,
  chapterForm => chapterForm.sections
);

export const selectCreationStatus = createSelector(
  _selectChapterForm,
  chapterForm => chapterForm.creationStatus
);

export const selectUpdateStatus = createSelector(
  _selectChapterForm,
  chapterForm => chapterForm.updateStatus
);

export const selectProgressState = createSelector(
  _selectChapterForm,
  chapterForm => chapterForm.progressState
);
