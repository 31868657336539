import React from "react";
import { Row, Col } from "antd";
import { Paragraph } from "@trace-one/design-system";
import styles from "./styles.module.less";
import {
  TreatmentWidgetReadOnlyField,
  TreatmentWidgetReadOnlyProps,
} from "./types";
import { getTreatmentFieldValue } from "./utils";
import { isObjectEmpty } from "utils/general";
import { useListItems } from "hooks";

const TreatmentWidgetReadOnly: React.FC<TreatmentWidgetReadOnlyProps> = ({
  typeContent,
  productNameContent,
  frequencyOfUseContent,
  preHarvestEndOfUseContent,
  commentContent,
  refListDictionary,
  emptyValue,
}) => {
  const { items: options } = useListItems(
    frequencyOfUseContent?.props?.schema?.list
  );

  const treatmentFields: TreatmentWidgetReadOnlyField[] = [
    { name: "type", content: typeContent, hasRefListValue: true },
    {
      name: "productName",
      content: productNameContent,
      hasRefListValue: false,
    },
    {
      name: "frequencyOfUse",
      content: frequencyOfUseContent,
      hasRefListValue: false,
      options,
    },
    {
      name: "preHarvestEndOfUse",
      content: preHarvestEndOfUseContent,
      hasRefListValue: true,
    },
  ];

  const commentField = {
    name: "comment",
    content: commentContent,
    hasRefListValue: false,
  };

  return (
    <Col span={24} className={styles.treatmentWidgetReadOnly}>
      <Row gutter={[0, 16]} className={styles.treatmentWidgetRow}>
        {treatmentFields.map(field =>
          !isObjectEmpty(field.content) && field.content ? (
            <React.Fragment key={field.name}>
              <Col xs={6}>
                <Paragraph size="xs" color="grey-5">
                  {field.content?.props?.uiSchema["ui:title"]}
                </Paragraph>
              </Col>
              <Col xs={6}>
                <Paragraph
                  size="xs"
                  color="grey-4"
                  dangerouslySetInnerHTML={{
                    __html: getTreatmentFieldValue({
                      field,
                      refListDictionary,
                      emptyValue,
                    }),
                  }}
                />
              </Col>
            </React.Fragment>
          ) : null
        )}
        <Col span={24}>
          <Row>
            <Col xs={6}>
              <Paragraph size="xs" color="grey-5">
                {commentContent?.props?.uiSchema["ui:title"]}
              </Paragraph>
            </Col>
            <Col xs={18}>
              <Paragraph
                size="xs"
                color="grey-4"
                dangerouslySetInnerHTML={{
                  __html: getTreatmentFieldValue({
                    field: commentField,
                    refListDictionary,
                    emptyValue,
                  }),
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

export default TreatmentWidgetReadOnly;
