import { Dictionary } from "types/general";
import { FormValidationError } from "types/validation";
import { hasNumber } from "../../../../utils/general";

export const getErrorList = (
  sectionErrors: Dictionary<FormValidationError[]>,
  getTranslationForKey: Function
) => {
  let errorList: string[] = [];

  Object.values(sectionErrors).forEach(blockErrors => {
    blockErrors.forEach(({ error, path }) => {
      if (
        path === "" ||
        path.includes("siteIds") ||
        path.includes("contacts")
      ) {
        errorList.push(getTranslationForKey(error));
      } // number in the path means the error corresponds to an individual entry, so it should not be shown at section level
      else if (path.includes("mainOrigins") && !hasNumber(path)) {
        errorList.push(
          getTranslationForKey("MinimumItemsRequired", {
            item: getTranslationForKey("mainOriginTitle"),
          })
        );
      } else if (path.includes("proofFileId")) {
        errorList.push(getTranslationForKey("ProofFileRequired"));
      } else if (path.includes("nutrientFamilies")) {
        errorList.push(getTranslationForKey("RequiredFields"));
      } else if (path.includes("claims")) {
        errorList.push(getTranslationForKey("claimSectionErrorMsg"));
      } else if (blockErrors?.length === 1 && path.includes("nutriscore")) {
        errorList.push(
          getTranslationForKey("nutriscoreValidationErrorMessage")
        );
      }
    });
  });

  return errorList;
};
