import Form from "@rjsf/core";
import { isListEmpty, isObjectEmpty } from "utils/general";
import { setCleanValue } from "./utils";

class CustomForm extends Form {
  constructor(props) {
    super(props);

    const {
      formContext: { saveTemplateBlock, setErrors },
    } = this.props;

    const superOnChange = this.onChange;

    this.onChange = newFormData => {
      let preparedFormData = newFormData;

      if (!isObjectEmpty(newFormData)) {
        preparedFormData = Object.entries(newFormData).reduce(
          (previousState, [key, value]) => {
            return {
              ...previousState,
              ...setCleanValue({ key, value }),
            };
          },
          {}
        );
      }

      superOnChange(preparedFormData);

      const { errors, errorSchema } = this.validate(preparedFormData);

      this.setState({
        errors,
        errorSchema,
      });

      if (isListEmpty(errors)) {
        saveTemplateBlock(
          JSON.stringify(preparedFormData),
          JSON.stringify(this.state.formData)
        );
      } else {
        setErrors(
          errors.map(({ property, name }) => ({
            path: property,
            error: name,
          }))
        );
      }
    };
  }
}

export default CustomForm;
