import { useEffect } from "react";
import { Stepper, Tooltip } from "@trace-one/design-system";
import { Title } from "@trace-one/react-components";
import { Row, Col } from "antd";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import CreationWizzard from "components/CreationWizzard";
import Spin from "components/Spin";
import ReflistSelect from "components/ReflistSelect";
import { useAppDispatch } from "store";
import {
  fetchOwnerContacts,
  startSpecificationCreation,
} from "store/specificationCreation/asyncActions";
import {
  selectErrors,
  selectOwnerContacts,
  selectSelectedOwnerContacts,
  selectSelectedProduct,
  selectIsProductStepValid,
  selectIsSaving,
  selectIsCreationInProgress,
  selectOwnerUserDetails,
  selectSpecification,
  selectWizard,
} from "store/specificationCreation/selectors";
import { selectRefListDictionary } from "store/generalData/selectors";
import {
  useSteps,
  useCreationActions,
} from "pages/Specification/components/Creation/hooks";
import { resetSpecificationErrors } from "store/specificationCreation/specificationCreationSlice";
import { REFLIST_IDS } from "utils/constants";
import AssignProduct from "./components/AssignProduct";
import { messages } from "./messages";
import { useProductTypeTooltip } from "hooks";
import { isFAndVOnly } from "utils/general";

const Product = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { productTypeId, draftId } = useSelector(selectSpecification);
  const wizard = useSelector(selectWizard);

  const { stepIndex, currentIsValid, steps, onNextStep } = useSteps({
    currentStep: wizard.currentStep,
    stepsState: wizard.state,
  });

  const {
    onSpecificationDelete,
    changeProductType,
    onAddOwnerContact,
    onRemoveOwnerContact,
    onRemoveProduct,
    onSaveProducts,
  } = useCreationActions();
  const onwerContacts = useSelector(selectOwnerContacts);
  const ownerUserDetails = useSelector(selectOwnerUserDetails);

  const selectedOwnerContacts = useSelector(selectSelectedOwnerContacts);
  const selectedProduct = useSelector(selectSelectedProduct);
  const isProductStepValid = useSelector(selectIsProductStepValid);

  const isSaving = useSelector(selectIsSaving);
  const isCreationInProgress = useSelector(selectIsCreationInProgress);
  const {
    productType: hasProductTypeError,
    ownerContacts: hasOwnerContactsError,
  } = useSelector(selectErrors);
  const refListDictionary = useSelector(selectRefListDictionary);

  const { isVisible, getOnVisibleChange } = useProductTypeTooltip();

  useEffect(() => {
    if (!draftId) {
      dispatch(startSpecificationCreation());
    }
  }, []);

  useEffect(() => {
    dispatch(fetchOwnerContacts());
    // @ts-ignore
    dispatch(resetSpecificationErrors());
  }, []);

  return (
    <Spin fixed spinning={isCreationInProgress}>
      <CreationWizzard.Title.Specification />
      <Stepper
        stepIndex={stepIndex}
        currentIsValid={currentIsValid}
        steps={steps}
      />
      <Title
        level={2}
        value={intl.formatMessage(messages.specificationDetails)}
        subtitle={intl.formatMessage(messages.specificationDetailsSubtitle)}
      />
      <Row gutter={[20, 0]}>
        <Col xs={24} sm={24} md={24} lg={8}>
          <CreationWizzard.Item
            required
            label={intl.formatMessage(messages.formProductType)}
          >
            <Tooltip
              open={isVisible}
              text={intl.formatMessage(messages.productTypeTooltipTitle)}
              onOpenChange={getOnVisibleChange(!!selectedProduct.id)}
              placement="top"
            >
              <ReflistSelect
                allowClear={false}
                value={productTypeId}
                disabled={!!selectedProduct.id}
                data-testid="product-type-id"
                placeholder={intl.formatMessage(
                  messages.formProductTypePlaceholder
                )}
                onSelect={changeProductType}
                error={hasProductTypeError}
                errorMessage={intl.formatMessage(messages.errorFieldMessage)}
                refListId={REFLIST_IDS.PRODUCT_TYPES}
                shouldCallApi={false}
                refListDictionary={refListDictionary}
                filterRefListIds={
                  isFAndVOnly ? [REFLIST_IDS.FOOD_PRODUCT_TYPE] : []
                }
              />
            </Tooltip>
          </CreationWizzard.Item>
        </Col>
        <CreationWizzard.SelectRetailerContacts
          retailerContacts={onwerContacts}
          selectedRetailerContacts={selectedOwnerContacts}
          ownerUserDetails={ownerUserDetails}
          onSelect={onAddOwnerContact}
          onDelete={onRemoveOwnerContact}
          dataTestIdForDropdown="owner-contact-ids"
          dataTestIdForList="owner-contact-list"
          error={hasOwnerContactsError}
          errorMessage={intl.formatMessage(messages.errorFieldMessage)}
        />
      </Row>
      <Title
        level={2}
        value={intl.formatMessage(messages.assignProduct)}
        subtitle={intl.formatMessage(messages.assignProductSubtitle)}
      />
      <AssignProduct
        onRemoveProduct={onRemoveProduct}
        onSaveProducts={onSaveProducts}
      />
      <CreationWizzard.Footer
        onNextClick={onNextStep}
        hasBackButton={false}
        isNextDisabled={!isProductStepValid}
        isSaving={isSaving}
        onCancel={onSpecificationDelete}
      />
    </Spin>
  );
};

export default Product;
