import { MaterialAllergenDeclarationData } from "models";
import { useAppDispatch } from "store";
import {
  removeAllergen,
  addAllergens,
  updateAllergenPresent,
} from "store/materialForm/asyncActions";
import { isListEmpty } from "utils/general";
import { AllergenDeclarationType } from "types/library";

const useAllergensActions = () => {
  const dispatch = useAppDispatch();

  const onRemoveAllergen = ({
    allergenId,
    declaration,
  }: MaterialAllergenDeclarationData) => async () => {
    dispatch(removeAllergen({ allergenId, declaration }));
  };

  const onAddAllergens = async (
    allergens: MaterialAllergenDeclarationData[]
  ) => {
    if (isListEmpty(allergens)) return;

    dispatch(addAllergens(allergens));
  };

  const onChangeAllergenPresent = (
    allergenId: MaterialAllergenDeclarationData["allergenId"]
  ) => async ({
    target: { value },
  }: {
    target: { value: AllergenDeclarationType };
  }) => {
    dispatch(
      updateAllergenPresent({
        declaration: value,
        allergenId,
      })
    );
  };

  return {
    onChangeAllergenPresent,
    onRemoveAllergen,
    onAddAllergens,
  };
};

export default useAllergensActions;
