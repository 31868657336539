import { createContext } from "react";
import { RequirementType } from "types/library";

const RequirementsContentContext = createContext<{
  onRemove?: Function;
  isTargetMode?: boolean;
  onValidate?: ({
    requirementId,
    requirementType,
  }: {
    requirementId: string;
    requirementType: RequirementType;
  }) => Promise<void>;
}>({
  onValidate: null,
  onRemove: null,
  isTargetMode: false,
});

export default RequirementsContentContext;
