import { Heading, Graphic } from "@trace-one/design-system";
import ActionButton from "components/ActionButton";
import Status from "components/Status";
import { getVersionName } from "utils/specifications";
import { ActionsProps } from "./types";
import styles from "./styles.module.less";

const Actions: React.FC<ActionsProps> = ({
  versionNumber,
  isDisabled,
  status,
  displayActions = true,
  onDelete,
  actions = [],
  actionsProps,
}) => {
  return (
    <div className={styles.actions}>
      {versionNumber ? (
        <Heading
          size="xxs"
          data-test-id="card-version"
          color={isDisabled ? "grey-2" : "primary"}
        >
          {getVersionName(versionNumber)}
        </Heading>
      ) : null}
      {status ? (
        <div>
          <Status.Circle status={status} withIcon />
        </div>
      ) : null}
      {displayActions ? (
        <>
          {!!onDelete ? (
            <Graphic
              className={styles.delete}
              name="close"
              onClick={onDelete}
              data-testid="remove-supplier"
            />
          ) : null}
          <div className={styles.actionIcon}>
            <ActionButton
              actions={actions}
              disabled={actionsProps?.disabled}
              loading={actionsProps?.loading}
            />
          </div>
        </>
      ) : null}
    </div>
  );
};

export default Actions;
