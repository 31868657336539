import classNames from "classnames";
import {
  ConditionalEllipsisWithTooltip,
  Heading,
  Paragraph,
} from "@trace-one/design-system";
import styles from "./styles.module.less";
import { InfoProps } from "./types";

const Info: React.FC<InfoProps> = ({ label, children, className, ...rest }) => {
  return (
    <div className={classNames(styles.info, className)} {...rest}>
      <Heading size="xxs">
        <ConditionalEllipsisWithTooltip text={label}>
          {label}
        </ConditionalEllipsisWithTooltip>
      </Heading>
      <Paragraph>{children}</Paragraph>
    </div>
  );
};

export default Info;
