import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fetchReferenceListItems } from "apis/RLMD";
import { ReferenceListItemData } from "models";
import { selectLanguageCode } from "store/user/selectors";
import {
  numberOfCharactersForAutoComplete,
  takeItemsOnSelectPage,
} from "utils/constants";
import usePrevious from "../usePrevious";
import { UseReRefListAutocompleteProps } from "./types";

const useRefListAutocomplete = ({
  refListId,
  shouldCallApi = true,
  take = takeItemsOnSelectPage,
  parentItemIds = [],
  searchText = "",
}: UseReRefListAutocompleteProps) => {
  const [skip, setSkip] = useState(0);
  const [refList, setRefList] = useState<ReferenceListItemData[]>([]);
  const [skipAndTakeQueryStats, setSkipAndTakeQueryStats] = useState({
    totalCount: 0,
    currentCount: 0,
  });
  const [isLoading, setIsLoading] = useState(false);

  const languageCode = useSelector(selectLanguageCode);

  const prevSearchText = usePrevious(searchText);

  const getRefList = async (withLoading: boolean) => {
    try {
      // covering a case where user type something but then remove it
      if (prevSearchText.length > 0 && searchText.length === 0) {
        setRefList([]);
      }

      setIsLoading(withLoading);

      const { data } = await fetchReferenceListItems({
        languageCode,
        id: refListId,
        take,
        skip,
        searchText,
        parentItemIds,
      });

      setSkipAndTakeQueryStats(data.skipAndTakeQueryStats);

      if (searchText.length === 0 && skip > 0) {
        setRefList(prev => [...prev, ...data.referenceListItems]);
      } else {
        setRefList(data.referenceListItems);
      }
    } catch (_) {
    } finally {
      setIsLoading(false);
    }
  };

  const onScrollToBottom = event => {
    const target = event.target;

    if (
      Math.ceil(target.scrollTop) + target.offsetHeight ===
      target.scrollHeight
    ) {
      target.scrollTo(0, target.scrollHeight);

      /* This change is used for the end of the dropdown list */
      const { currentCount, totalCount } = skipAndTakeQueryStats;
      if (totalCount > 0 && currentCount > 0 && currentCount < totalCount) {
        setSkip(prev => prev + takeItemsOnSelectPage);
      }
    }
  };

  useEffect(() => {
    if (
      shouldCallApi &&
      (searchText.length === 0 ||
        searchText.length >= numberOfCharactersForAutoComplete)
    ) {
      skip === 0 ? getRefList(true) : getRefList(false);
    }
  }, [
    take,
    skip,
    shouldCallApi,
    searchText,
    refListId,
    languageCode,
    JSON.stringify(parentItemIds),
  ]);

  useEffect(() => {
    setRefList([]);
    setSkip(0);
  }, [JSON.stringify(parentItemIds)]);

  useEffect(() => {
    setSkip(0);
  }, [searchText]);

  return {
    refList,
    isLoading,
    onScrollToBottom,
  };
};

export default useRefListAutocomplete;
