import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fetchReferenceListItemsByName } from "apis/RLMD";
import { useBoolean } from "hooks";
import { useAppDispatch } from "store";
import {
  selectClaimForm,
  setClaimFormData,
} from "store/claimForm/claimFormSlice";
import { selectLanguageCode } from "store/user/selectors";
import { ClaimFormContext } from "components/Library/components/ClaimForm/contexts/ClaimFormContext";
import TranslationTabContent from "../../../TranslationTabConent";
import { isListEmpty } from "utils/general";

const useClaimSentence = () => {
  const languageCode = useSelector(selectLanguageCode);
  const claimForm = useSelector(selectClaimForm);
  const { form } = useContext(ClaimFormContext);
  const dispatch = useAppDispatch();

  const { value: isLoading, setTrue, setFalse } = useBoolean();
  const [languages, setLanguages] = useState([]);
  const [userPreferredLanguage, setUserPreferredLanguage] = useState<{
    label: string;
    languageCode: string;
    key: string;
  }>({ label: "", languageCode: "", key: null });
  const [activeKey, setActiveKey] = useState(null);
  const [tabItems, setTabItems] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);

  const { sentenceTranslations } = claimForm;

  useEffect(() => {
    const getLanguages = async () => {
      setTrue();

      try {
        const { data } = await fetchReferenceListItemsByName({
          name: "languages",
          languageCode,
        });

        const languageDictionary = data?.reduce((acc, { itemCode, text }) => {
          acc[itemCode] = text;
          return acc;
        }, {});

        setLanguages(
          data.reduce((previousState, { isItemActive, itemCode, text }) => {
            const languages = [...previousState];
            if (isItemActive) {
              languages.push({
                label: text,
                languageCode: itemCode,
                key: itemCode,
                default: itemCode === languageCode,
              });
            }
            if (itemCode === languageCode) {
              const defaultLang = {
                label: text,
                languageCode: itemCode,
                key: itemCode,
                closable: false,
              };

              if (isListEmpty(sentenceTranslations)) {
                setTabItems([
                  {
                    ...defaultLang,
                    children: (
                      <TranslationTabContent
                        selectedTabKey={defaultLang.key}
                        form={form}
                      />
                    ),
                  },
                ]);
                setSelectedLanguages([itemCode]);
              } else {
                setTabItems(
                  sentenceTranslations.map(({ languageCode, isDefault }) => ({
                    languageCode,
                    key: languageCode,
                    closable: !isDefault,
                    label: languageDictionary[languageCode],
                    children: (
                      <TranslationTabContent
                        selectedTabKey={languageCode}
                        form={form}
                      />
                    ),
                  }))
                );
                setSelectedLanguages(
                  sentenceTranslations.map(({ languageCode }) => languageCode)
                );
              }

              setActiveKey(defaultLang.key);
              setUserPreferredLanguage(defaultLang);
              dispatch(
                setClaimFormData({
                  ...claimForm,
                  sentenceTranslations: isListEmpty(sentenceTranslations)
                    ? [
                        {
                          isDefault: true,
                          text: "",
                          languageCode,
                        },
                      ]
                    : sentenceTranslations,
                })
              );
            }
            return languages;
          }, [])
        );
      } catch (error) {
      } finally {
        setFalse();
      }
    };

    getLanguages();
  }, []);

  const onTabChange = (key: string) => {
    setActiveKey(key);
  };

  const add = selectedItem => {
    setTabItems([
      ...tabItems,
      {
        label: selectedItem.label,
        children: (
          <TranslationTabContent
            selectedTabKey={selectedItem.key}
            form={form}
          />
        ),
        key: selectedItem.key,
        closable: selectedItem.key !== userPreferredLanguage.languageCode,
      },
    ]);

    dispatch(
      setClaimFormData({
        ...claimForm,
        sentenceTranslations: [
          ...sentenceTranslations,
          {
            isDefault: false,
            text: "",
            languageCode: selectedItem.key,
          },
        ],
      })
    );

    setActiveKey(selectedItem.value);
    setSelectedLanguages(prevLangs => [...prevLangs, selectedItem.value]);
  };

  const remove = (targetKey: string) => {
    const targetIndex = tabItems.findIndex(pane => pane.key === targetKey);
    const newPanes = tabItems.filter(pane => pane.key !== targetKey);
    if (newPanes.length && targetKey === activeKey) {
      const { key } = newPanes[
        targetIndex === newPanes.length ? targetIndex - 1 : targetIndex
      ];
      setActiveKey(key);
    }

    setTabItems(newPanes);
    setSelectedLanguages(newPanes.map(tab => tab.key));

    dispatch(
      setClaimFormData({
        ...claimForm,
        sentenceTranslations: sentenceTranslations.filter(
          translation => translation.languageCode !== targetKey
        ),
      })
    );
  };

  const onLanguageChange = ({ selectedItem }) => {
    if (selectedItem.value === userPreferredLanguage.languageCode) {
      return;
    }

    if (!tabItems.map(item => item.key).includes(selectedItem.value)) {
      add(selectedItem);
    } else {
      remove(selectedItem.value);
    }
  };

  const onEdit = (targetKey: string, action: "add" | "remove") => {
    if (action === "remove") {
      remove(targetKey);
    }
  };

  return {
    tabItems,
    isLoading,
    selectedLanguages,
    languages,
    onEdit,
    onTabChange,
    onLanguageChange,
    activeKey,
    userPreferredLanguage,
  };
};

export default useClaimSentence;
