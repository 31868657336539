import { useIntl } from "react-intl";
import { Icon, Typography, Tooltip } from "@trace-one/design-system";
import classNames from "classnames";
import { ColumnType } from "antd/lib/table";
import IngredientFunctionDropdown from "components/IngredientFunctionDropdown";
import PercentageInput from "components/PercentageInput";
import Actions from "../../components/Actions";
import ActionButton from "components/ActionButton";
import ConditionalWrapper from "components/ConditionalWrapper";
import { useCategoryDetails } from "..";
import { useMaterialPermissions } from "components/Library/components/MaterialForm/hooks";
import { getSortOrder } from "utils/pagination";
import { UseChapterRecipeWidgetColumns } from "./types";
import { MaterialRecipeIngredientViewModel } from "viewModels";
import { messages } from "../../messages";
import libraryMessages from "messages/library";
import generalMessages from "messages/general";
import styles from "../../styles.module.less";

const useChapterRecipeWidgetColumns = ({
  onUpdateIngredientPercentage,
  onUpdateIngredientFunction,
  onRemoveIngredient,
  onViewMaterialAction,
  recipeIngredients,
  catalogDictionary,
  displayActions,
  emptyValue,
  recipeSorter,
  canSortOtherIngredients,
  canSortProcessingAidIngredients,
  isTargetMode,
}: UseChapterRecipeWidgetColumns) => {
  const { formatMessage } = useIntl();

  const { getCategoryItem } = useCategoryDetails({
    ingredientData: recipeIngredients,
  });

  const { disableActions } = useMaterialPermissions();

  const columns: ColumnType<MaterialRecipeIngredientViewModel>[] = [
    {
      title: formatMessage(messages["name"]),
      dataIndex: "name",
      width: "28%",
      sorter: canSortOtherIngredients,
      sortDirections: ["ascend", "descend", "ascend"],
      sortOrder:
        recipeSorter?.orderBy === "name"
          ? getSortOrder(recipeSorter?.orderByDescending)
          : null,
      render: (value, ingredient) => {
        const { isPrimaryIngredient } = ingredient;

        return (
          <>
            <Typography variant={"body-regular"} color={"grey-5"}>
              {value}
            </Typography>
            {isPrimaryIngredient && (
              <Typography
                className={styles["chapter-recipe__primary-ingredient"]}
                variant={"body-s"}
                color={"grey-4"}
              >
                {formatMessage(libraryMessages["primaryIngredient"])}
              </Typography>
            )}
          </>
        );
      },
    },
    {
      title: formatMessage(messages["type"]),
      dataIndex: "type",
      width: "15%",
      render: value => formatMessage(libraryMessages["type"][value]),
      sorter: canSortOtherIngredients,
      sortDirections: ["ascend", "descend", "ascend"],
      sortOrder:
        recipeSorter?.orderBy === "type"
          ? getSortOrder(recipeSorter?.orderByDescending)
          : null,
    },
    {
      title: formatMessage(messages["percentage"]),
      dataIndex: "percentage",
      width: "15%",
      className: styles.unsetPosition,
      sorter: canSortOtherIngredients,
      sortDirections: ["ascend", "descend", "ascend"],
      sortOrder:
        recipeSorter?.orderBy === "percentage"
          ? getSortOrder(recipeSorter?.orderByDescending)
          : null,
      render: (_, ingredient) => {
        const { percentage, relativePercentage } = ingredient;
        const value = relativePercentage ?? percentage;

        return displayActions ? (
          <PercentageInput
            defaultValue={value}
            onChange={percentage => {
              onUpdateIngredientPercentage(ingredient, percentage as number);
            }}
            shouldApplyDebounceImmediate={false}
            keepDefaultValue
            disabled={!ingredient.firstLevel || isTargetMode}
            precision={3}
            onBlur={() => {}}
          />
        ) : (
          value || emptyValue
        );
      },
    },
    {
      title: formatMessage(messages["function"]),
      dataIndex: "functionId",
      width: "30%",
      className: styles.unsetPosition,
      render: (_, ingredient) =>
        displayActions && !!ingredient.firstLevel ? (
          <IngredientFunctionDropdown
            ingredient={ingredient}
            update={onUpdateIngredientFunction}
            getCategoryItem={getCategoryItem}
            disableActions={disableActions}
          />
        ) : (
          catalogDictionary?.[ingredient.functionId]
        ),
    },
  ];

  if (displayActions && !isTargetMode) {
    columns.push({
      key: "action",
      title: () => <Icon name="settings" color="white" />,
      width: 50,
      className: styles.lastColumn,
      render: (_, ingredient) =>
        !!ingredient.firstLevel && (
          <Actions
            ingredient={ingredient}
            onRemoveIngredient={onRemoveIngredient}
            onViewMaterialAction={onViewMaterialAction}
          />
        ),
    });
  }

  const processingAidColumns = [
    {
      title: formatMessage(libraryMessages["columns"]["name"]),
      dataIndex: "name",
      sorter: canSortProcessingAidIngredients,
      sortDirections: ["ascend", "descend", "ascend"],
      sortOrder:
        recipeSorter?.orderBy === "name"
          ? getSortOrder(recipeSorter?.orderByDescending)
          : null,
      render: (_, ingredient) => {
        return ingredient?.name;
      },
    },
    {
      title: "%",
      dataIndex: "percentage",
      width: "15%",
      sorter: canSortProcessingAidIngredients,
      sortDirections: ["ascend", "descend", "ascend"],
      sortOrder:
        recipeSorter?.orderBy === "percentage"
          ? getSortOrder(recipeSorter?.orderByDescending)
          : null,
      render: (_, ingredient) => {
        const { percentage, relativePercentage } = ingredient;
        const value = relativePercentage ?? percentage;

        return displayActions ? (
          <ConditionalWrapper
            condition={!ingredient?.firstLevel}
            wrapper={children => (
              <Tooltip
                placement="left"
                text={formatMessage(
                  libraryMessages?.["processingAidsTableDisabledTooltip"],
                  {
                    materialName: ingredient?.parentName,
                  }
                )}
              >
                <span className={styles.tooltipChildrenWrapper}>
                  {children}
                </span>
              </Tooltip>
            )}
          >
            <PercentageInput
              defaultValue={value}
              onChange={percentage => {
                onUpdateIngredientPercentage(ingredient, percentage as number);
              }}
              keepDefaultValue
              disabled={disableActions || !ingredient?.firstLevel}
              precision={3}
              required={false}
            />
          </ConditionalWrapper>
        ) : (
          value
        );
      },
    },
    {
      title: formatMessage(libraryMessages["columns"]["function"]),
      dataIndex: "function",
      className: styles.unsetPosition,
      width: "30%",
      render: (_, ingredient) =>
        displayActions && !!ingredient.firstLevel ? (
          <IngredientFunctionDropdown
            ingredient={ingredient}
            update={onUpdateIngredientFunction}
            getCategoryItem={getCategoryItem}
            disableActions={disableActions}
          />
        ) : (
          catalogDictionary?.[ingredient.functionId]
        ),
    },
    {
      ...(displayActions && {
        key: "action",
        title: formatMessage(libraryMessages["columns"]["action"]),
        width: 100,
        render: (_, { ingredientId, firstLevel, parentName }) => (
          <ConditionalWrapper
            condition={!firstLevel}
            wrapper={children => (
              <Tooltip
                placement="left"
                text={formatMessage(
                  libraryMessages?.["processingAidsTableDisabledTooltip"],
                  {
                    materialName: parentName,
                  }
                )}
              >
                <span
                  className={classNames(
                    styles.tooltipChildrenWrapper,
                    styles.actionColumnAlignment
                  )}
                >
                  {children}
                </span>
              </Tooltip>
            )}
          >
            <ActionButton
              {...(!disableActions && {
                actions: [
                  {
                    label: formatMessage(generalMessages.remove),
                    tooltipProps: {
                      onConfirmation: onRemoveIngredient({ ingredientId }),
                    },
                  },
                ],
                disabled: !firstLevel,
              })}
              className={classNames({
                [`${styles.actionColumnAlignment}`]: firstLevel,
              })}
            />
          </ConditionalWrapper>
        ),
      }),
    },
  ];

  return {
    columns,
    processingAidColumns,
  };
};

export default useChapterRecipeWidgetColumns;
