import React from "react";
import { Col, Row } from "antd";
import { Paragraph } from "@trace-one/design-system";
import { ArrayFieldTemplateProps } from "@rjsf/core";
import { isObjectEmpty } from "utils/general";
import { Dictionary } from "types/general";
import styles from "./styles.module.less";

type ProductOriginsReadOnlyProps = {
  productOrigins: any[];
  refListDictionary: Dictionary<string>;
  uiSchema: ArrayFieldTemplateProps["uiSchema"];
  emptyValue: string;
};

const ProductOriginsReadOnly: React.FC<ProductOriginsReadOnlyProps> = ({
  productOrigins,
  refListDictionary,
  uiSchema,
  emptyValue,
}) => {
  const originFields = ["originTypeId", "countryId", "regionId", "zoneId"];

  return (
    <>
      {productOrigins?.map(origin => (
        <Row
          key={`${origin[originFields[1]]}`}
          gutter={[0, 16]}
          className={styles.productOriginReadOnly}
        >
          {originFields.map(field =>
            !isObjectEmpty(uiSchema?.items[field]) ? (
              <React.Fragment key={uiSchema.items[field]?.["ui:title"]}>
                <Col xs={6}>
                  <Paragraph size="xs" color="grey-5">
                    {uiSchema.items[field]?.["ui:title"]}
                  </Paragraph>
                </Col>
                <Col xs={6}>
                  <Paragraph size="xs" color="grey-4">
                    {!isObjectEmpty(origin) && origin[field]
                      ? refListDictionary[origin[field]]
                      : emptyValue}
                  </Paragraph>
                </Col>
              </React.Fragment>
            ) : null
          )}
        </Row>
      ))}
    </>
  );
};

export default ProductOriginsReadOnly;
