import { useMemo } from "react";
import { Badge, Button } from "@trace-one/design-system";
import classNames from "classnames";
import { useBoolean } from "hooks";
import { isListEmpty } from "utils/general";
import { isChapterOwnedByCurrentUserCompany } from "utils/specifications";
import UploadPhotosModal from "./components/UploadPhotosModal";
import { UploadIconWidgetProps } from "./types";
import styles from "./styles.module.less";

const UploadIconWidget: React.FC<UploadIconWidgetProps> = ({
  onChange,
  value: photos,
  data,
  title,
  schema,
  formContext,
}) => {
  const {
    value: isModalVisible,
    setTrue: onModalOpen,
    setFalse: onModalClose,
  } = useBoolean();
  const {
    isWidgetEditable,
    ownerCompanyId,
    displayActions: displayActionsFromFormContext,
    isTargetMode,
    chapterData,
  } = formContext;
  const { propertyId } = schema;

  const displayActions = useMemo(
    () => isWidgetEditable(propertyId) && displayActionsFromFormContext,
    [propertyId, displayActionsFromFormContext]
  );
  const isPhotoListEmpty = useMemo(() => isListEmpty(photos), [photos]);

  const iconProps = {
    type: "tertiary",
    onClick: onModalOpen,
    disabled: !(displayActions || !isPhotoListEmpty),
  };

  if (isTargetMode && isPhotoListEmpty) {
    return null;
  }

  return (
    <>
      <span className={styles.uploadIcon}>
        {!isPhotoListEmpty ? (
          <>
            <Badge count={photos.length}>
              <Button
                iconName="photos"
                className={classNames({
                  "target-mode":
                    isTargetMode &&
                    !isChapterOwnedByCurrentUserCompany({
                      specOwnerCompanyId: ownerCompanyId,
                      chapterOwnerCompanyId: chapterData?.ownerCompanyId,
                    }),
                })}
                {...iconProps}
              />
            </Badge>
          </>
        ) : (
          <Button iconName="upload" {...iconProps} />
        )}
      </span>
      {isModalVisible && !isTargetMode && (
        <UploadPhotosModal
          onClose={onModalClose}
          photos={photos}
          onChange={onChange}
          data={data}
          title={title}
          displayActions={displayActions}
          ownerCompanyId={ownerCompanyId}
        />
      )}
    </>
  );
};

export default UploadIconWidget;
