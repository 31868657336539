import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "specification.actions.duplicate.step.confirmation.title",
    defaultMessage: "Sharing confirmed",
  },

  description: {
    id: "specification.actions.duplicate.step.confirmation.description",
    defaultMessage:
      "Your specification for {tradeItemName} have been duplicated for the following suppliers: ",
  },

  whatDoYouWantToDo: {
    id: "specification.actions.duplicate.step.confirmation.whatDoYouWantToDo",
    defaultMessage: "What do you want to do?",
  },

  descriptionError: {
    id: "specification.actions.duplicate.step.confirmation.description.error",
    defaultMessage: "An error occurred for the following suppliers :",
  },

  backToSpecification: {
    id:
      "specification.actions.duplicate.step.confirmation.action.backToSpecification",
    defaultMessage: "Back to specification",
  },

  viewSpecificationList: {
    id:
      "specification.actions.duplicate.step.confirmation.action.viewSpecificationList",
    defaultMessage: "View specification list",
  },
});
