import { Radio } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import TableColumns from "components/TableColumns";
import { UseAllergensTableProps } from "./types";
import { ALLERGEN_DECLARATION_TYPE } from "utils/constants";
import libraryMessages from "messages/library";
import { ColumnsType } from "antd/lib/table";
import { MaterialAllergenViewModel } from "viewModels";

const useAllergensTable = ({ allergenDictionary }: UseAllergensTableProps) => {
  const { formatMessage } = useIntl();

  const getColumns = (): ColumnsType<MaterialAllergenViewModel> => [
    {
      title: formatMessage(libraryMessages.allergensColumns["allergen"]),
      dataIndex: "allergenId",
      render: allergenId => allergenDictionary[allergenId],
    },
    {
      title: formatMessage(libraryMessages.allergensColumns["ingredients"]),
      dataIndex: "ingredients",
      width: "50%",
      render: ingredients => (
        <TableColumns.Tags tags={ingredients.map(({ name }) => name)} />
      ),
    },
    {
      title: formatMessage(libraryMessages.allergensColumns["present"]),
      key: "present",
      width: "10%",
      render: (_, allergen) => (
        <Radio
          disabled
          checked={allergen.declaration === ALLERGEN_DECLARATION_TYPE.PRESENT}
        />
      ),
    },
    {
      title: formatMessage(
        libraryMessages.allergensColumns["unintentionalPresent"]
      ),
      width: "20%",
      key: "unintentionalPresent",
      render: (_, allergen) => (
        <Radio
          disabled
          checked={
            allergen.declaration ===
            ALLERGEN_DECLARATION_TYPE.UNINTENTIONAL_PRESENT
          }
        />
      ),
    },
  ];

  return {
    columns: getColumns(),
  };
};

export default useAllergensTable;
