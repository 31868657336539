import { AxiosRequestConfig } from "axios";
import {
  MaterialCharacteristicsSchemaData,
  MaterialData,
  MaterialListItemData,
  MaterialSupplierData,
  MaterialAllergenDeclarationData,
  MaterialOriginData,
  MaterialIngredientData,
  MaterialControlPlansSchemaData,
  MaterialRecipeData,
} from "models";
import {
  AddMaterialIngredientBody,
  AddCompositeIngredientBody,
  AddRawMaterialIngredientBody,
  PaginatedList,
  PaginationParams,
} from "types/api";
import { axiosInstance } from "./";
import { API_VERSION } from "apis/constants";
import { LibraryItemApiType, AllergenDeclarationType } from "types/library";
import { Sorter } from "types/pagination";
import { MaterialAllergenViewModel } from "viewModels";

export interface MaterialVNextApiParams {
  materialId?: MaterialData["id"];
  materialType?: LibraryItemApiType;
}

export const createMaterial = ({
  materialType,
  name,
  active,
  tagIds,
  internalCode,
}: MaterialVNextApiParams & {
  name?: MaterialData["name"];
  active?: MaterialData["active"];
  tagIds?: MaterialData["tagIds"];
  internalCode?: MaterialData["internalCode"];
}) =>
  axiosInstance.post<MaterialData>(`/api/${API_VERSION}${materialType}`, {
    name,
    active,
    tagIds,
    internalCode,
  });

export const updateMaterialDetails = ({
  materialId,
  materialType,
  name,
  internalCode,
  active,
  classificationId,
  partId,
  functionIds,
  substanceId,
}: MaterialVNextApiParams & {
  name?: MaterialData["name"];
  internalCode?: MaterialData["internalCode"];
  active?: MaterialData["active"];
  classificationId?: MaterialData["classificationId"];
  partId?: MaterialData["partId"];
  functionIds?: MaterialData["functionIds"];
  substanceId?: MaterialData["substanceId"];
}) =>
  axiosInstance.put<MaterialData>(
    `/api/${API_VERSION}${materialType}/${materialId}/details`,
    {
      name,
      active,
      internalCode,
      classificationId,
      partId,
      functionIds,
      substanceId,
    }
  );

export const addMaterialAllergens = ({
  materialId,
  materialType,
  payload,
}: MaterialVNextApiParams & {
  payload:
    | MaterialAllergenDeclarationData[]
    | {
        allergenIds: string[];
      };
}) =>
  axiosInstance.post<MaterialData>(
    `/api/${API_VERSION}${materialType}/${materialId}/allergens`,
    payload
  );

export const deleteMaterialAllergen = ({
  materialId,
  materialType,
  allergenId,
}: MaterialVNextApiParams & {
  allergenId: string;
}) =>
  axiosInstance.delete<MaterialData>(
    `/api/${API_VERSION}${materialType}/${materialId}/allergens/${allergenId}`
  );

export const updateMaterialAllergen = ({
  materialId,
  materialType,
  allergenId,
  declaration,
  ingredientId,
}: MaterialVNextApiParams & {
  allergenId: string;
  declaration: AllergenDeclarationType;
  ingredientId?: string;
}) =>
  axiosInstance.put<MaterialData>(
    `/api/${API_VERSION}${materialType}/${materialId}/allergens/${allergenId}`,
    {
      declaration,
      ingredientId,
    }
  );

export const updateAllergenForBoughtMaterials = ({
  materialId,
  materialType,
  allergenId,
  presentIngredientIds,
  unintentionalPresentIngredientIds,
}: MaterialVNextApiParams & {
  allergenId: MaterialAllergenViewModel["allergenId"];
  presentIngredientIds: MaterialAllergenViewModel["presentForIngredientIds"];
  unintentionalPresentIngredientIds: MaterialAllergenViewModel["unintentionalPresentForIngredientIds"];
}) =>
  axiosInstance.put<MaterialData>(
    `/api/${API_VERSION}${materialType}/${materialId}/allergens/${allergenId}`,
    {
      presentIngredientIds,
      unintentionalPresentIngredientIds,
    }
  );

export const updateMaterialAttachments = ({
  materialId,
  materialType,
  fileIds,
}: MaterialVNextApiParams & {
  fileIds: string[];
}) =>
  axiosInstance.put<MaterialData>(
    `/api/${API_VERSION}${materialType}/${materialId}/attachments`,
    fileIds
  );

export const addMaterialTags = ({
  materialId,
  materialType,
  tagIds,
}: MaterialVNextApiParams & {
  tagIds: string[];
}) =>
  axiosInstance.post<MaterialData>(
    `/api/${API_VERSION}${materialType}/${materialId}/tags`,
    tagIds
  );

export const deleteMaterialTag = ({
  materialId,
  materialType,
  tagId,
}: MaterialVNextApiParams & {
  tagId: string;
}) =>
  axiosInstance.delete<MaterialData>(
    `/api/${API_VERSION}${materialType}/${materialId}/tags/${tagId}`
  );

export const createMaterialIngredientFromMaterial = ({
  materialId,
  materialType,
  ingredient,
}: MaterialVNextApiParams & {
  ingredient: AddMaterialIngredientBody;
}) =>
  axiosInstance.post<MaterialData>(
    `/api/${API_VERSION}${materialType}/${materialId}/ingredients`,
    ingredient
  );

export const createMaterialIngredientComposite = ({
  materialId,
  materialType,
  ingredient,
}: MaterialVNextApiParams & {
  ingredient: AddCompositeIngredientBody;
}) =>
  axiosInstance.post<MaterialData>(
    `/api/${API_VERSION}${materialType}/${materialId}/ingredients/composite`,
    ingredient
  );

export const createMaterialIngredientRawMaterial = ({
  materialId,
  materialType,
  ingredient,
}: MaterialVNextApiParams & {
  ingredient: AddRawMaterialIngredientBody;
}) =>
  axiosInstance.post<MaterialData>(
    `/api/${API_VERSION}${materialType}/${materialId}/ingredients/raw-material`,
    ingredient
  );

export const updateMaterialIngredient = ({
  materialId,
  materialType,
  ingredientId,
  percentage,
  functionId,
  classificationId,
  name,
}: MaterialVNextApiParams & {
  ingredientId: MaterialIngredientData["ingredientId"];
  percentage?: MaterialIngredientData["percentage"];
  functionId?: MaterialIngredientData["functionId"];
  classificationId?: MaterialIngredientData["classificationId"];
  name?: MaterialIngredientData["name"];
}) =>
  axiosInstance.put<MaterialData>(
    `/api/${API_VERSION}${materialType}/${materialId}/ingredients/${ingredientId}`,
    {
      percentage,
      functionId,
      classificationId,
      name,
    }
  );

export const deleteMaterialIngredient = ({
  materialId,
  materialType,
  ingredientId,
}: MaterialVNextApiParams & {
  ingredientId: string;
}) =>
  axiosInstance.delete<MaterialData>(
    `/api/${API_VERSION}${materialType}/${materialId}/ingredients/${ingredientId}`
  );

export const getMaterialCharacteristics = ({
  materialId,
  materialType,
  languageCode,
}: {
  materialId: MaterialVNextApiParams["materialId"];
  materialType: MaterialVNextApiParams["materialType"];
  languageCode: string;
}) =>
  axiosInstance.get<MaterialCharacteristicsSchemaData>(
    `/api/${API_VERSION}${materialType}/${materialId}/characteristics`,
    {
      params: { languageCode },
    }
  );

export const updateMaterialCharacteristics = ({
  materialId,
  materialType,
  json,
}: MaterialVNextApiParams & {
  json: string;
}) =>
  axiosInstance.put<void>(
    `/api/${API_VERSION}${materialType}/${materialId}/characteristics`,
    json,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

export const getMaterialControlPlans = ({
  materialId,
  materialType,
  languageCode,
}: MaterialVNextApiParams & {
  languageCode: string;
}) =>
  axiosInstance.get<MaterialControlPlansSchemaData>(
    `/api/${API_VERSION}${materialType}/${materialId}/control-plans`,
    {
      params: { languageCode },
    }
  );

export const updateMaterialControlPlans = ({
  materialId,
  materialType,
  json,
}: MaterialVNextApiParams & {
  json: string;
}) =>
  axiosInstance.put<void>(
    `/api/${API_VERSION}${materialType}/${materialId}/control-plans`,
    json,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

export const updateMaterialIngredientType = ({
  materialId,
  materialType,
  ingredientId,
}: MaterialVNextApiParams & {
  ingredientId: MaterialIngredientData["ingredientId"];
}) =>
  axiosInstance.post<MaterialData>(
    `/api/${API_VERSION}${materialType}/${materialId}/ingredients/${ingredientId}/change-type`
  );

export const createMaterialSupplier = ({
  materialId,
  materialType,
  name,
  code,
}: MaterialVNextApiParams & {
  name: MaterialSupplierData["name"];
  code: MaterialSupplierData["code"];
}) =>
  axiosInstance.post<MaterialData>(
    `/api/${API_VERSION}${materialType}/${materialId}/suppliers`,
    {
      name,
      code,
    }
  );

export const updateMaterialSupplier = ({
  materialId,
  materialType,
  name,
  code,
  supplierId,
}: MaterialVNextApiParams & {
  name: MaterialSupplierData["name"];
  code: MaterialSupplierData["code"];
  supplierId: MaterialSupplierData["supplierId"];
}) =>
  axiosInstance.put<MaterialData>(
    `/api/${API_VERSION}${materialType}/${materialId}/suppliers/${supplierId}`,
    {
      name,
      code,
    }
  );

export const updateMaterialSupplierIngredient = ({
  materialId,
  materialType,
  supplierId,
  ingredientIds,
}: MaterialVNextApiParams & {
  supplierId: MaterialSupplierData["supplierId"];
  ingredientIds: string[];
}) =>
  axiosInstance.put<MaterialData>(
    `/api/${API_VERSION}${materialType}/${materialId}/suppliers/${supplierId}/ingredients`,
    {
      ingredientIds,
    }
  );

export const deleteMaterialSupplier = ({
  materialId,
  materialType,
  supplierId,
}: MaterialVNextApiParams & {
  supplierId: MaterialSupplierData["supplierId"];
}) =>
  axiosInstance.delete<MaterialData>(
    `/api/${API_VERSION}${materialType}/${materialId}/suppliers/${supplierId}`
  );

export const createMaterialSupplierOrigin = ({
  materialId,
  materialType,
  supplierId,
  typeId,
}: MaterialVNextApiParams & {
  supplierId: MaterialSupplierData["supplierId"];
  typeId: MaterialOriginData["typeId"];
}) =>
  axiosInstance.post<MaterialData>(
    `/api/${API_VERSION}${materialType}/${materialId}/suppliers/${supplierId}/origins`,
    {
      typeId,
    }
  );

export const updateMaterialSupplierOrigin = ({
  materialId,
  materialType,
  supplierId,
  originId,
  payloadData,
}: MaterialVNextApiParams & {
  supplierId: MaterialSupplierData["supplierId"];
  originId: MaterialOriginData["typeId"];
  payloadData: MaterialOriginData;
}) =>
  axiosInstance.put<MaterialData>(
    `/api/${API_VERSION}${materialType}/${materialId}/suppliers/${supplierId}/origins/${originId}`,
    {
      countryId: payloadData.countryId,
      regionId: payloadData.regionId,
      zoneId: payloadData.zoneId,
    }
  );

export const deleteMaterialSupplierOrigin = ({
  materialId,
  materialType,
  supplierId,
  typeId,
}: MaterialVNextApiParams & {
  supplierId: MaterialSupplierData["supplierId"];
  typeId: MaterialOriginData["typeId"];
}) =>
  axiosInstance.delete<MaterialData>(
    `/api/${API_VERSION}${materialType}/${materialId}/suppliers/${supplierId}/origins/${typeId}`
  );

export const fetchMaterial = ({
  materialId,
  materialType,
  languageCode,
}: MaterialVNextApiParams & {
  languageCode: string;
}) =>
  axiosInstance.get<MaterialData>(
    `/api/${API_VERSION}${materialType}/${materialId}`,
    {
      params: {
        languageCode,
      },
    }
  );

export const fetchMaterials = ({
  params,
  paramsSerializer,
}: {
  params: PaginationParams & {
    ownerCompanyId: string;
    excludeDrafts?: boolean;
  };
  paramsSerializer?: AxiosRequestConfig["paramsSerializer"];
}) =>
  axiosInstance.get<PaginatedList<MaterialListItemData>>(
    `/api/materials/search`,
    {
      params,
      paramsSerializer,
    }
  );

export const reviseMaterial = ({ id }: { id: MaterialData["id"] }) =>
  axiosInstance.post<MaterialData>(`/api/materials/${id}/revise`);

export const publishMaterial = ({
  materialId,
  materialType,
}: MaterialVNextApiParams) =>
  axiosInstance.post<void>(
    `/api/${API_VERSION}${materialType}/${materialId}/publish`
  );

export const shareMaterial = ({
  materialId,
  materialType,
  companyId,
}: MaterialVNextApiParams & {
  companyId: string;
}) =>
  axiosInstance.post<void>(
    `/api/${API_VERSION}${materialType}/${materialId}/share`,
    {
      companyId,
    }
  );

export const deleteMaterial = ({
  materialId,
  materialType,
}: MaterialVNextApiParams) =>
  axiosInstance.delete<void>(
    `/api/${API_VERSION}${materialType}/${materialId}`
  );

export const createMaterialVersion = ({
  materialId,
  materialType,
  params,
}: {
  materialId: MaterialData["type"];
  materialType: MaterialData["id"];
  params: {
    languageCode?: string;
  };
}) =>
  axiosInstance.post<MaterialData["id"]>(
    `/api/${API_VERSION}${materialType}/${materialId}/new-version`,
    {},
    {
      params,
    }
  );

export const sortMaterialRecipe = ({
  materialId,
  materialType,
  orderBy,
  orderByDescending,
}: MaterialVNextApiParams & Sorter) =>
  axiosInstance.post<MaterialRecipeData>(
    `/api/${materialType}/${materialId}/sort-recipe`,
    {},
    {
      params: {
        orderBy,
        orderByDescending,
      },
    }
  );
