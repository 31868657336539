import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectLanguageCode } from "store/user/selectors";
import { GeneralInfoData } from "models";
import { CHAPTER_TYPES_API } from "utils/constants";
import { fetchChapter } from "apis/SPEC";

const useChapterDetailsModal = ({
  chapterType,
  chapterId,
}: {
  chapterType: GeneralInfoData["type"];
  chapterId: GeneralInfoData["id"];
}) => {
  const languageCode = useSelector(selectLanguageCode);
  const [chapterInfo, setChapterInfo] = useState<GeneralInfoData>(null);

  const getChapter = async () => {
    try {
      const { data } = await fetchChapter({
        chapterId,
        chapterType: CHAPTER_TYPES_API[chapterType],
        languageCode,
      });
      setChapterInfo(data.generalInfo);
    } catch (_) {}
  };

  useEffect(() => {
    if (chapterId && chapterType) {
      getChapter();
    }
  }, [chapterId, chapterType]);

  return { chapterInfo };
};

export default useChapterDetailsModal;
