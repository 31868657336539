import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { toaster } from "@trace-one/design-system";
import { useBoolean } from "hooks";
import { useAppDispatch } from "store";
import {
  archiveSpecificationModificationRequest,
  completeSpecificationModificationRequest,
  createModificationRequestDiscussionMessage,
  declineSpecificationModificationRequest,
  deleteSpecificationModificationRequest,
  getSpecificationModificationRequestById,
  notifySpecificationModificationRequest,
  performSpecificationModificationRequest,
} from "store/specificationDetails/asyncActions";
import { selectSelectedModificationRequestDiscussion } from "store/specificationDetails/selectors";
import { fetchCompany, fetchUsers } from "apis/CUMD";
import { isListEmpty } from "utils/general";
import { messages } from "messages/modificationRequest";
import { ModificationRequestData } from "models/modificationRequest";
import { UserData } from "models";

export interface UseDiscussionPanelProps {
  requestId?: ModificationRequestData["requestId"];
  onClose?: Function;
  userIdsWithRequest?: string[];
}

const useDiscussionPanel = ({
  requestId,
  onClose,
}: UseDiscussionPanelProps = {}) => {
  const [discussionUsers, setDiscussionUsers] = useState<UserData[]>([]);
  const { formatMessage } = useIntl();

  const selectedDiscussion = useSelector(
    selectSelectedModificationRequestDiscussion
  );

  const dispatch = useAppDispatch();
  const { value: isLoading, setTrue, setFalse } = useBoolean(false);
  const {
    value: areUsersLoading,
    setTrue: setUsersLoadingTrue,
    setFalse: setUsersLoadingFalse,
  } = useBoolean(false);
  const {
    value: isSendingMessage,
    setTrue: setIsSendingMessageTrue,
    setFalse: setIsSendingMessageFalse,
  } = useBoolean(false);

  const fetchModificationRequest = async () => {
    try {
      setTrue();
      await dispatch(
        // @ts-ignore
        getSpecificationModificationRequestById({
          requestId,
        })
      );
    } catch {
    } finally {
      setFalse();
    }
  };

  const onNotify = async () => {
    try {
      setTrue();
      const {
        // @ts-ignore
        payload: { recipientCompanyId },
      } = await dispatch(
        // @ts-ignore
        notifySpecificationModificationRequest({
          requestId,
        })
      );

      const {
        data: { companyDisplayName },
      } = await fetchCompany({ id: recipientCompanyId });

      toaster.confirmation({
        message: formatMessage(messages.confirmationToasterTitle),
        description: formatMessage(messages.confirmationToasterDescription, {
          companyDisplayName,
        }),
      });
    } catch {
    } finally {
      setFalse();
    }
  };

  const onPerform = async () => {
    try {
      setTrue();
      // @ts-ignore
      await dispatch(performSpecificationModificationRequest({ requestId }));
    } catch {
    } finally {
      setFalse();
    }
  };

  const onDecline = async () => {
    try {
      setTrue();
      // @ts-ignore
      await dispatch(declineSpecificationModificationRequest({ requestId }));
    } catch {
    } finally {
      setFalse();
    }
  };

  const onComplete = async () => {
    try {
      setTrue();
      // @ts-ignore
      await dispatch(completeSpecificationModificationRequest({ requestId }));
    } catch {
    } finally {
      setFalse();
    }
  };

  const onDelete = async () => {
    try {
      setTrue();
      // @ts-ignore
      await dispatch(deleteSpecificationModificationRequest({ requestId }));

      onClose?.();
    } catch {
    } finally {
      setFalse();
    }
  };

  const onArchive = async () => {
    try {
      setTrue();
      // @ts-ignore
      await dispatch(archiveSpecificationModificationRequest({ requestId }));

      onClose?.();
    } catch {
    } finally {
      setFalse();
    }
  };

  const onSendMessage = async (text: string) => {
    try {
      setIsSendingMessageTrue();
      return await dispatch(
        // @ts-ignore
        createModificationRequestDiscussionMessage({ text })
      );
    } catch {
    } finally {
      setIsSendingMessageFalse();
    }
  };

  useEffect(() => {
    if (requestId) {
      fetchModificationRequest();
    }
  }, [requestId]);

  useEffect(() => {
    const fetchDiscussionUsers = async () => {
      try {
        const userIds = Array.from(
          new Set([
            ...(selectedDiscussion?.messages?.map(
              ({ authorUserId }) => authorUserId
            ) || []),
          ])
        );

        if (
          userIds.every(userId =>
            discussionUsers.some(user => user.userId === userId)
          )
        ) {
          return;
        }

        setUsersLoadingTrue();

        const { data: users } = await fetchUsers({
          ids: userIds,
        });

        setDiscussionUsers(users);
      } catch {
      } finally {
        setUsersLoadingFalse();
      }
    };

    if (!isListEmpty(selectedDiscussion?.messages) && requestId) {
      fetchDiscussionUsers();
    }
  }, [selectedDiscussion?.messages?.length]);

  return {
    isLoading: isLoading || areUsersLoading,
    isSendingMessage,
    onNotify,
    onPerform,
    onDecline,
    onComplete,
    onDelete,
    onArchive,
    onSendMessage,
    discussionUsers,
  };
};

export default useDiscussionPanel;
