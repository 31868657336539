import Packaging from "components/Packaging";
import { useEffect, useState } from "react";
import { isObjectEmpty } from "utils/general";

export enum PackagingComponentTabKeys {
  BASIC_INFORMATION = "basic_informations",
  RECYCLABILITY = "recyclability",
}

const usePackagingComponentNavigation = ({ sections, allowsUpdate }) => {
  const [activeTab, setActiveTab] = useState(
    PackagingComponentTabKeys.BASIC_INFORMATION
  );
  const [tabContent, setTabContent] = useState(null);

  const tabCodes = sections?.map(section => section.sectionCode);

  const getSectionsByCode = (code: string) =>
    sections.filter(section => section.sectionCode === code);

  const tabData = tabCodes?.reduce((acc, curr) => {
    const sections = getSectionsByCode(curr);

    const tabObj = {
      title: sections[0].sectionName,
      sections,
    };

    acc = { ...acc, [curr]: tabObj };

    return acc;
  }, {});

  let tabItems, componentMap;
  if (!isObjectEmpty(tabData)) {
    componentMap = {
      [PackagingComponentTabKeys.BASIC_INFORMATION]: (
        <Packaging.BasicInformation
          sections={
            tabData[PackagingComponentTabKeys.BASIC_INFORMATION]?.sections
          }
          isEditable={allowsUpdate}
        />
      ),
      [PackagingComponentTabKeys.RECYCLABILITY]: (
        <Packaging.Recyclability
          sections={tabData[PackagingComponentTabKeys.RECYCLABILITY]?.sections}
          isEditable={allowsUpdate}
        />
      ),
    };

    tabItems = [
      {
        key: PackagingComponentTabKeys.BASIC_INFORMATION,
        label: tabData[PackagingComponentTabKeys.BASIC_INFORMATION]?.title,
      },
      {
        key: PackagingComponentTabKeys.RECYCLABILITY,
        label: tabData[PackagingComponentTabKeys.RECYCLABILITY]?.title,
      },
    ];
  }

  useEffect(() => {
    if (activeTab && !isObjectEmpty(componentMap)) {
      setTabContent(componentMap[activeTab]);
    }
  }, [activeTab, sections]);

  return {
    activeTab,
    setActiveTab,
    tabItems,
    tabContent,
  };
};

export default usePackagingComponentNavigation;
