import { defineMessages } from "react-intl";

export const messages = defineMessages({
  selectedChapterInfo: {
    id: "specificationDetails.selectedChapter.info",
    defaultMessage:
      "Select an existing chapter or create one to fill the specification.",
  },
  browseLibrary: {
    id: "specificationDetails.selectedChapter.browseLibrary",
    defaultMessage: "Browse Library",
  },
  linkChapterModalTitle: {
    id: "specificationDetails.selectedChapter.linkChapterModalTitle",
    defaultMessage: "Add an existing chapter from library",
  },
  linkChapterModalSubtitle: {
    id: "specificationDetails.selectedChapter.linkChapterModalSubtitle",
    defaultMessage:
      "You can add published chapter to this specification. This action is not irreversible. Be aware that linking a chapter means that every information will be shared.",
  },
  selectChapter: {
    id: "specificationDetails.selectedChapter.selectChapter",
    defaultMessage: "Select a chapter",
  },
  chapterLinkedSuccessTitle: {
    id: "specificationDetails.selectedChapter.chapterLinkedSuccessTitle",
    defaultMessage: "Chapter linked",
  },
  chapterLinkedSuccessDescription: {
    id: "specificationDetails.selectedChapter.chapterLinkedSuccessDescription",
    defaultMessage:
      "The chapter {chapterName} has been successfully linked to the specification",
  },
  unlinkChapterModalTitle: {
    id: "specificationDetails.selectedChapter.unlinkChapterModalTitle",
    defaultMessage: "Unlink chapter",
  },

  unlinkChapterModalDescription: {
    id: "specificationDetails.selectedChapter.unlinkChapterModalDescription",
    defaultMessage:
      "This action will unlink the chapter in your library from this specification. Upon confirmation you will, once again, be able to link another chapter.",
  },
  unlinkChapterModalActionConfirm: {
    id:
      "specificationDetails.selectedChapter.unlinkChapterModal.action.confirm",
    defaultMessage: "Confirm",
  },
  chapterUnlinkedSuccessTitle: {
    id: "specificationDetails.selectedChapter.chapterUnlinkedSuccessTitle",
    defaultMessage: "Chapter unlinked",
  },
  chapterUnlinkedSuccessDescription: {
    id:
      "specificationDetails.selectedChapter.chapterUnlinkedSuccessDescription",
    defaultMessage:
      "The chapter {chapterType} has been unlinked from the specification {specificationName}.",
  },
  linkToSpecification: {
    id: "library.linkToSpecification",
    defaultMessage: "Link to specification",
  },
  unlinkChapterAction: {
    id: "specificationDetails.selectedChapter.unlinkChapterAction",
    defaultMessage: "Unlink chapter",
  },
  detailsAndLinksChapterAction: {
    id: "specificationDetails.selectedChapter.detailsAndLinksChapterAction",
    defaultMessage: "See details and links",
  },
  detailsAndLinksChapterModalTitle: {
    id: "specificationDetails.selectedChapter.detailsAndLinksChapterModalTitle",
    defaultMessage: "Chapter details and linked specifications",
  },
});
