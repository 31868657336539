import { useRef, useState } from "react";
import classNames from "classnames";
import { InputNumber } from "@trace-one/design-system";
import ErrorsList from "components/ErrorsList";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { useDual, useInputNumberProps, useWidgetActions } from "../../hooks";
import { isListEmpty } from "utils/general";
import { getPropertiesObjectFromArray } from "../../templates/utils";
import { getArrayIndexFromId, getErrorsListFromProperties } from "../utils";
import { getOnChangeHandle } from "./utils";
import { RangeNumberWidgetProps } from "./types";
import { Property } from "components/SpecificationSection/types";
import widgetStyle from "../styles.module.less";
import withRequestModificationPopover from "hocs/withRequestModificationPopover";
import { REQUEST_MODIFICATION_MODAL_WIDTH } from "components/RequestModificationPopover";
import {
  canTargetField,
  doesFieldHaveModificationRequest,
  getDraftRequestFromAllRequests,
} from "utils/modificationRequest";
import RequestModificationTooltipIcon from "components/RequestModificationTooltipIcon";
import { getDataFieldId } from "../../utils";
import { useAppDispatch } from "store";
import { setRequestModification } from "store/specificationDetails/specificationDetailsSlice";

const RangeNumberWidget: React.FC<RangeNumberWidgetProps> = ({
  formData,
  formContext,
  schema,
  properties: widgetProperties,
  idSchema,
  uiSchema,
  setModalStyle,
  setSelectedField,
  onOpenModal,
  setRequestPopoverOptions,
}) => {
  const { getTranslationForKey } = useFormSchemaTranslation();
  const [compactData, setCompactData] = useState(formData);
  const {
    displayActions,
    emptyValue,
    refListDictionary,
    isWidgetEditable,
    templateBlockId,
    sectionId,
    isTypingInProgress,
    onUpdateIsTypingInProgress,
    isTargetMode,
    ownerCompanyId,
    arraySchemaItemKey,
    chapterData,
    modificationRequests,
    formData: cardFormData,
  } = formContext;
  const { properties } = schema;

  const minValueRef = useRef(null);
  const maxValueRef = useRef(null);
  const unitIdRef = useRef(null);
  const dispatch = useAppDispatch();

  const {
    propertyId: propertyIdUnit,
    referenceList: { listId, parentItemIds } = {
      listId: undefined,
      parentItemIds: [],
    },
  } = (properties.unit as Property) || {};

  const errors = getErrorsListFromProperties(widgetProperties);

  const onChangeHandle = getOnChangeHandle({
    setCompactData,
    propertyObject: getPropertiesObjectFromArray(widgetProperties),
  });

  const { items, buttonProps } = useDual({
    listId: listId,
    parentItemIds,
    onChange: onChangeHandle("unit"),
  });

  let minInputProps = {
    id: idSchema?.$id,
    isTypingInProgress,
    onUpdateIsTypingInProgress,
  };

  const minimumInputProps = useInputNumberProps({
    properties: properties.minimumValue,
    onChange: onChangeHandle("minimumValue"),
    value:
      compactData?.minimumValue !== undefined ? compactData.minimumValue : "",
    key: "min",
    ...minInputProps,
  });

  const maximumInputProps = useInputNumberProps({
    properties: properties.maximumValue,
    onChange: onChangeHandle("maximumValue"),
    value:
      compactData?.maximumValue !== undefined ? compactData.maximumValue : "",
    key: "max",
    ...minInputProps,
  });

  const { isWidgetChanged } = useWidgetActions({ templateBlockId, sectionId });

  if (!displayActions) {
    if (!compactData?.minimumValue && !compactData?.maximumValue) {
      return (
        <span data-field-id={properties?.minimumValue?.propertyId}>
          {emptyValue}
        </span>
      );
    }

    return (
      <span data-field-id={properties?.minimumValue?.propertyId}>
        {`${compactData.minimumValue || emptyValue} ${getTranslationForKey(
          "to"
        )} ${compactData.maximumValue || emptyValue} ${
          refListDictionary[compactData?.unit] || emptyValue
        }`}
      </span>
    );
  }

  const isInputDisabled =
    !isWidgetEditable(properties.minimumValue.propertyId) ||
    !isWidgetEditable(properties.maximumValue.propertyId);

  const isButtonDisabled = !isWidgetEditable(propertyIdUnit);

  const hideButton = !properties.unit;

  const index = getArrayIndexFromId(idSchema?.$id);
  const currentCardFormData = cardFormData?.items?.[index] || {};
  const listItemKey = currentCardFormData?.[arraySchemaItemKey] as string;

  const onFieldClick = (
    event: React.MouseEvent<HTMLElement>,
    propertyId,
    ref
  ) => {
    event.stopPropagation();

    const canClick =
      canTargetField({
        isTargetMode,
        specOwnerCompanyId: ownerCompanyId,
        chapterOwnerCompanyId: chapterData?.ownerCompanyId,
      }) &&
      !doesFieldHaveModificationRequest({
        modificationRequests,
        propertyId,
        listItemKey: listItemKey ?? null,
      });

    if (!canClick) return;

    setSelectedField({
      propertyId,
      listItemKey: listItemKey ?? null,
    });

    const clickedElement = event.target as HTMLElement;

    const buttonElement = clickedElement.querySelector("button");

    if (buttonElement) {
      buttonElement.classList.add("target-mode__border");
    } else {
      clickedElement.classList.add("target-mode__border");
    }

    const { left, top } = ref?.current?.getBoundingClientRect();

    const isOverFlowing =
      event.clientX + REQUEST_MODIFICATION_MODAL_WIDTH > window.innerWidth;

    if (Array.from(clickedElement.classList).includes("pointer-event-none")) {
      setModalStyle({
        top: event.clientY - top,
        left: isOverFlowing
          ? "unset"
          : event.clientX +
            event.currentTarget.previousElementSibling.clientWidth -
            left,
        right: isOverFlowing ? 0 : "unset",
      });
    } else {
      setModalStyle({
        top: event.clientY - top,
        left: isOverFlowing ? "unset" : event.clientX - left,
        right: isOverFlowing ? 0 : "unset",
      });
    }

    onOpenModal();

    dispatch(setRequestModification({ isEditorOpen: true }));
  };

  const renderPopoverIcon = (
    propertyId: string,
    ref,
    isMinValueElement = false
  ) => {
    const request = getDraftRequestFromAllRequests({
      modificationRequests,
      propertyId,
      listItemKey,
    });

    if (request) {
      return (
        <RequestModificationTooltipIcon
          key={`${propertyId}`}
          onOpenModal={onOpenModal}
          setModalStyle={setModalStyle}
          tableRef={ref}
          setRequestPopoverOptions={setRequestPopoverOptions}
          request={request}
          modalStyle={isMinValueElement ? {} : { left: "unset", right: 0 }}
        />
      );
    }
  };

  const modificationRequestProps = (
    propertyId,
    ref,
    isMinValueElement = false
  ) => {
    if (!isTargetMode) return {};

    const hasDraftRequest = getDraftRequestFromAllRequests({
      modificationRequests,
      propertyId: propertyId,
      listItemKey: listItemKey ?? null,
    });

    return {
      wrapperRef: ref,
      "data-field-id": getDataFieldId(propertyId, listItemKey),
      onClick: event => onFieldClick(event, propertyId, ref),
      extraAbsoluteContent: renderPopoverIcon(
        propertyId,
        ref,
        isMinValueElement
      ),
      wrapperClassName: classNames({
        "field-has-modification-request": hasDraftRequest,
        "design-system-dual-input-wrapper": true,
        "width-auto": isTargetMode,
      }),
    };
  };

  return (
    <span onClick={event => event.stopPropagation()}>
      <InputNumber.Range
        className={classNames({
          [widgetStyle.inRevisionChange]:
            isWidgetChanged(
              properties.minimumValue.propertyId,
              idSchema?.$id
            ) ||
            isWidgetChanged(
              properties.maximumValue.propertyId,
              idSchema?.$id
            ) ||
            isWidgetChanged(propertyIdUnit, idSchema?.$id),
        })}
        minValueProps={{
          ...minimumInputProps,
          placeholder: getTranslationForKey(
            uiSchema["ui:minValue:placeholder"] ||
              "rangeNumberMinValuePlaceholder"
          ),
          dataId: "data-idd",
          ...modificationRequestProps(
            properties.minimumValue.propertyId,
            minValueRef,
            true
          ),
        }}
        maxValueProps={{
          ...maximumInputProps,
          placeholder: getTranslationForKey(
            uiSchema["ui:maxValue:placeholder"] ||
              "rangeNumberMaxValuePlaceholder"
          ),
          ...modificationRequestProps(
            properties.maximumValue.propertyId,
            maxValueRef
          ),
        }}
        disabledInput={isInputDisabled}
        buttonPlaceholder={getTranslationForKey(
          uiSchema["ui:unit:placeholder"] || "rangeNumberUnitPlaceholder"
        )}
        buttonValue={refListDictionary[compactData?.unit]}
        disabledButton={isButtonDisabled}
        onClearButton={onChangeHandle("unit")}
        buttonProps={{
          ...buttonProps,
          "data-test-id": `${idSchema?.$id}_unit`,
          ...modificationRequestProps(propertyIdUnit, unitIdRef),
        }}
        items={items}
        error={!isListEmpty(errors)}
        errorMessage={<ErrorsList list={errors} />}
        hideButton={hideButton}
        disableFieldEnableWrapperClick={isTargetMode}
      />
    </span>
  );
};

export default withRequestModificationPopover(RangeNumberWidget);
