import { defineMessages } from "react-intl";

export const messages = defineMessages({
  detailsProductType: {
    id: "creationFolder.form.productType",
    defaultMessage: "Product type",
  },

  detailsRetailerContact: {
    id: "creationFolder.form.retailerContact",
    defaultMessage: "Retailer contact(s)",
  },
});
