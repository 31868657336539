import { useState } from "react";
import { NutrientItem } from "components/SpecificationSection/components/FormSchema/components/NutritionBasic/types";
import { EnhancedExtraActions } from "components/SpecificationSection/types";

const useNutrientAction = ({
  deleteNutritionAction,
}: {
  deleteNutritionAction: EnhancedExtraActions["deleteNutritionAction"];
}) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const onDeleteNutrient = (nutrientId: NutrientItem["nutrientId"]) => () => {
    deleteNutritionAction({
      nutrientId,
    });
  };

  const onTooltipVisibleChange = value => {
    if (!value) {
      setIsTooltipVisible(false);
    } else {
      setIsTooltipVisible(value);
    }
  };

  return {
    isTooltipVisible,
    onTooltipVisibleChange,
    onDeleteNutrient,
  };
};

export default useNutrientAction;
