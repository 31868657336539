import { useContext } from "react";
import classNames from "classnames";
import { Button, Icon, Tooltip } from "@trace-one/design-system";
import { MenuContext } from "components/SpecificationSection/components/Menu/contexts";
import Sections from "./components/Sections";
import ConditionalWrapper from "components/ConditionalWrapper";
import { ItemProps } from "./types";
import { CHAPTER_ICONS } from "utils/constants";
import styles from "./styles.module.less";

const Item: React.FC<ItemProps> = ({ section }) => {
  const { selectedSectionId, onClickSection, isMinified } = useContext(
    MenuContext
  );
  const {
    sectionId,
    sectionName,
    chapterType,
    isChapterLibrary,
    isLinked,
    sections,
  } = section;

  const isSelected = selectedSectionId === sectionId;

  const color = isSelected ? "secondary-dark" : "grey-5";

  return (
    <>
      <div
        className={classNames(styles.menuItem, {
          [styles.menuItemSelected]: isSelected,
          [styles.menuItemLibrary]: isChapterLibrary,
          [styles.menuItemLinked]: isLinked,
        })}
      >
        <ConditionalWrapper
          condition={isMinified}
          wrapper={children => (
            <Tooltip
              text={sectionName}
              zIndex={6}
              getPopupContainer={trigger => trigger.parentNode}
            >
              {children}
            </Tooltip>
          )}
        >
          <Icon
            name={CHAPTER_ICONS[chapterType]}
            size="medium"
            color={color}
            data-test-id="menu-item-icon"
            onClick={
              isMinified ? onClickSection?.(sectionId, chapterType) : null
            }
          />
        </ConditionalWrapper>
        {!isMinified ? (
          <>
            <Button
              type="link"
              linkColor={color}
              onClick={onClickSection?.(sectionId, chapterType)}
              data-test-id="menu-item-name"
            >
              {sectionName}
            </Button>
            {isChapterLibrary && (
              <Icon
                name={isLinked ? "linked" : "not-linked"}
                size="small"
                color={color}
                data-test-id="menu-item-link-icon"
              />
            )}
          </>
        ) : null}
      </div>
      {!isMinified &&
        isSelected &&
        ((isChapterLibrary && isLinked) || !isChapterLibrary) && (
          <Sections
            sections={sections}
            parentSectionId={sectionId}
            parentChapterType={chapterType}
          />
        )}
    </>
  );
};

export default Item;
