/* istanbul ignore file */
import React, { useState } from "react";
import ShareSpecificationModal from "components/ShareSpecificationModal";
import { getOnShareSpecificationModalClose } from "utils/form";

const withShareSpecificationModal = Component => props => {
  const [isShareModalOpen, setIsShareModalOpen] = useState();

  return (
    <>
      <Component {...props} setIsShareModalOpen={setIsShareModalOpen} />
      {isShareModalOpen ? (
        <ShareSpecificationModal
          closeShareSpecificationModal={getOnShareSpecificationModalClose({
            setIsShareModalOpen,
          })}
          specificationId={props.specificationId}
          state={props.state}
        />
      ) : null}
    </>
  );
};

export default withShareSpecificationModal;
