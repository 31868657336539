import classnames from "classnames";
import { Heading } from "@trace-one/design-system";
import { SectionCountProps } from "./types";
import styles from "./styles.module.less";

const SectionCount: React.FC<SectionCountProps> = ({
  className,
  currentCount,
  dataTestId,
  totalCount,
}) => {
  return !!currentCount ? (
    <div
      data-testid={dataTestId}
      className={classnames(styles.innerBar, styles[className])}
      style={{
        width: `${(currentCount / totalCount) * 100}%`,
      }}
    >
      <Heading size="xxs">{currentCount}</Heading>
    </div>
  ) : null;
};

export default SectionCount;
