import { useMemo } from "react";
import {
  useCategoryItems,
  useReferenceListsItems,
  useSpecificationReflistIds,
} from "hooks";
import { isListEmpty } from "utils/general";
import Section from "./components/Section";
import LinkChapter from "./components/LinkChapter";
import { SectionsProps } from "./types";

const Sections: React.FC<SectionsProps> = ({ sections }) => {
  const { getReflistIdsFromSections } = useSpecificationReflistIds();

  const ids = useMemo(() => {
    return getReflistIdsFromSections(sections);
  }, [JSON.stringify(sections)]);

  useReferenceListsItems({
    refListIds: [...ids.reflistIds],
  });

  useCategoryItems({
    categoryItemsIds: [...ids.hierarchyIds],
  });

  if (isListEmpty(sections)) {
    return <LinkChapter />;
  }

  return (
    <>
      {sections.map(section => (
        <Section key={section.sectionId} section={section} />
      ))}
    </>
  );
};

export default Sections;
