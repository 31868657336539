import { useState } from "react";
import { isListEmpty } from "utils/general";
import { UseEnableCollaborationProps } from "./types";

const useEnableCollaboration = ({
  onChange,
  collaborationIds,
  onRemoveCollaborations,
}: UseEnableCollaborationProps) => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [isRemovingCollaborations, setIsRemovingCollaborations] = useState(
    false
  );

  const handleChange = (value: boolean) => {
    if (!value && !isListEmpty(collaborationIds)) {
      setIsPopoverVisible(true);
    } else {
      onChange(value);
    }
  };

  const handleConfirmDisableCollaborations = async () => {
    setIsRemovingCollaborations(true);
    await onRemoveCollaborations(collaborationIds);
    setIsRemovingCollaborations(false);
    setIsPopoverVisible(false);
    onChange(false);
  };

  const handleCancelDisableCollaborations = () => {
    setIsPopoverVisible(false);
  };

  return {
    isPopoverVisible,
    isRemovingCollaborations,
    handleChange,
    handleConfirmDisableCollaborations,
    handleCancelDisableCollaborations,
  };
};

export default useEnableCollaboration;
