import ActionButton from "components/ActionButton";
import { useDropdownActionsVisibility } from "hooks";
import { useActions } from "./hooks";
import { ActionsProps } from "./types";

export const Actions: React.FC<ActionsProps> = ({ folder }) => {
  const { isLoading, actionList, onOpenDropdown } = useActions({
    folder,
  });

  const { isVisible, onVisibleChange } = useDropdownActionsVisibility({
    onOpenDropdown,
  });

  return (
    <ActionButton
      actions={actionList}
      loading={isLoading}
      data-test-id="folder-list-table-row"
      //@ts-ignore
      dropdownProps={{
        open: isVisible,
        onOpenChange: onVisibleChange,
      }}
    />
  );
};

export default Actions;
