import { useState, useEffect } from "react";
import { Col, Row } from "antd";
import { Label } from "@trace-one/react-components";
import { isListEmpty } from "utils/general";
import RowTitle from "components/RowTitle";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { RefListWidget } from "components/SpecificationSection/components/FormSchema/widgets";
import { getOnChangeHandlerRefList } from "./utils";
import {
  RefListActions,
  SelectWithComboArrayFieldTemplateProps,
} from "./types";
import styles from "components/SpecificationSection/components/FormSchema/templates/ArrayFieldTemplate/styles.module.less";

const SelectWithComboArrayFieldTemplate: React.FC<SelectWithComboArrayFieldTemplateProps> = ({
  schema,
  uiSchema,
  formContext,
  items,
}) => {
  const {
    formData,
    saveTemplateBlock,
    refListDictionary,
    displayActions,
    isWidgetEditable,
  } = formContext;

  const inputGroupName = schema["name"];
  const { properties } = schema["items"];
  const itemKey = schema.itemKey || "id";
  const { id } = properties;

  const [actualForm, setActualForm] = useState(formData);

  const { getTranslationForKey } = useFormSchemaTranslation();

  const hasSavePermission = isWidgetEditable(schema.propertyId);

  const onChangeHandlerRefList = getOnChangeHandlerRefList({
    saveTemplateBlock,
    inputGroupName,
    setActualForm,
    itemKey,
  });

  useEffect(() => {
    setActualForm(formData);
  }, [formData]);

  return (
    <div className={styles.selectWithComboWrapper}>
      <RowTitle
        title={getTranslationForKey(uiSchema["ui:title"])}
        displayActions={displayActions}
      />
      <div className={styles.selectWithComboContentWrapper}>
        <Row className={styles.selectWithComboRow}>
          <Col xs={24} md={8}>
            {uiSchema?.items?.[itemKey]?.["ui:title"] && (
              <Label
                title={getTranslationForKey(
                  uiSchema.items[itemKey]["ui:title"]
                )}
              />
            )}
          </Col>
          <Col xs={24} md={12}>
            <RefListWidget
              // @ts-ignore
              value={
                actualForm && !isListEmpty(actualForm["items"])
                  ? actualForm["items"].map(item => {
                      return { value: item[itemKey] };
                    })
                  : null
              }
              onChange={onChangeHandlerRefList({
                name: itemKey,
                action: RefListActions.add,
              })}
              onDeselect={onChangeHandlerRefList({
                name: itemKey,
                action: RefListActions.remove,
              })}
              schema={id}
              formContext={formContext}
              uiSchema={uiSchema.items?.[itemKey]}
              placeholder={getTranslationForKey(
                uiSchema?.["ui:placeholder:select"] ||
                  "placeholderMultipleSelect"
              )}
              id="refListWidgetInSelectWithComboArrayFieldTemplate"
              labelInValue
              readonly={!hasSavePermission}
            />
          </Col>
        </Row>
        {items.map(({ children }) => ({
          ...children,
          key: children.props.formData[itemKey],
          props: {
            ...children.props,
            uiSchema: {
              "ui:widget": "CompactWidget",
              "ui:title": refListDictionary[children.props.formData?.[itemKey]],
              "ui:label:col": { xs: 24, md: 8 },
              "ui:wrapper:col": { xs: 24, md: 7 },
              "ui:horizontal": true,
              "ui:allowClear": true,
            },
          },
        }))}
      </div>
    </div>
  );
};

export default SelectWithComboArrayFieldTemplate;
