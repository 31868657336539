import { isListEmpty } from "utils/general";

export const getNoItemsMessageKey = widget => {
  switch (widget) {
    case "TreatmentsWidget":
      return "noTreatmentAdded";
    case "TablesWidget":
      return "noControlPlanAdded";
    case "FreeFromAllergensWidget":
      return "noAllergenAdded";
    case "PackagingsWidget":
      return "noPackagingAdded";
    case "RecipeWidget":
      return "noIngredientAdded";
    case "rawMaterialsAllergensWidget":
    case "CrossContaminationAllergensWidget":
    case "AllergensWidget":
      return "noAllergens";
    case "StorageConditionsWidget":
      return "noStorageConditions";
    case "MainOriginsWidget":
    case "alternativeOriginsWidget":
      return "noOriginAdded";
    case "ClaimsWidget":
      return "noInformationAdded";
    default:
      return "noItemAdded";
  }
};

export const getAddBtnLabelKey = widget => {
  switch (widget) {
    case "TreatmentsWidget":
      return "addATreatment";
    case "PackagingsWidget":
      return "addAComponent";
    case "AllergensFreeFromWidget":
      return "addAnAllergen";
    case "MainOriginsWidget":
    case "alternativeOriginsWidget":
      return "addAnOrigin";
    case "StorageConditionsWidget":
      return "addAStorageCondition";
    default:
      return "Add";
  }
};

export const getConditionalLabelKey = widget => {
  switch (widget) {
    case "TreatmentsWidget":
      return "addATreatment";
    case "alternativeOriginsWidget":
      return "acceptsAlternativeOriginTitle";
    default:
      return "Add";
  }
};

export const hasObjectValue = data => {
  const values = Object.values(data).filter(value => {
    if (Array.isArray(value)) {
      return !isListEmpty(value);
    }
    return !!value;
  });

  return values.length;
};

export const cleanTableOnEmptyRow = ({ array, index, id }) => {
  const newArray = [...array];
  const { [id]: rowId, ...dataToCheck } = newArray[index];

  if (!hasObjectValue(dataToCheck)) {
    newArray.splice(index, 1);
  }

  return newArray;
};
