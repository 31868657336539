import { useState, useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { searchSites, fetchSitesByIds } from "apis/SMD";
import { fetchReferenceListItemByCode } from "apis/RLMD";
import { selectLanguageCode } from "store/user/selectors";
import { REFLIST_IDS } from "utils/constants";
import { isListEmpty, isObjectEmpty } from "utils/general";
import { Dictionary } from "types/general";
import { selectOwningCompanyId } from "store/user/selectors";
import { UseSitesArrayProps } from "./types";

const useSitesArray = ({ siteIds, specificationData }: UseSitesArrayProps) => {
  const languageCode = useSelector(selectLanguageCode);
  const owningCompanyId = useSelector(selectOwningCompanyId);

  const [hasSitesError, setHasSitesError] = useState(false);
  const [isSitesLoading, setIsSitesLoading] = useState(false);

  const [sites, setSites] = useState([]);
  const [addedSites, setAddedSites] = useState([]);
  const [siteCountryCodes, setSiteCountryCodes] = useState<string[]>([]);
  const [canLoadMore, setCanLoadMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  const siteCountryCodeDictionaryRef = useRef<Dictionary>({});

  useEffect(() => {
    if (!isListEmpty(siteIds)) {
      fetchAddedSites();
    } else {
      setAddedSites([]);
    }
  }, [JSON.stringify(siteIds)]);

  useEffect(() => {
    //fetch full country names for codes which are not in siteCountryCodeDictionary
    if (!isListEmpty(siteCountryCodes)) {
      const nonFetchedCountries = siteCountryCodes.filter(
        countryCode =>
          !Object.keys(siteCountryCodeDictionaryRef.current).includes(
            countryCode
          )
      );

      if (!isListEmpty(nonFetchedCountries)) {
        Promise.all(
          siteCountryCodes.map(async countryCode => {
            return fetchReferenceListItemByCode({
              languageCode,
              id: REFLIST_IDS.COUNTRIES,
              code: countryCode,
            });
          })
        ).then(allCountries => {
          const countryCodeDictionary = allCountries?.reduce(
            (acc, { data: { text, itemCode } }) => {
              return { ...acc, [itemCode]: text };
            },
            {}
          );
          siteCountryCodeDictionaryRef.current = {
            ...siteCountryCodeDictionaryRef.current,
            ...countryCodeDictionary,
          };
          updateSiteCountryInAddedSites(countryCodeDictionary);
        });
      }
    }
  }, [JSON.stringify(siteCountryCodes)]);

  const fetchAddedSites = async () => {
    try {
      const {
        data: { sites },
      } = await fetchSitesByIds({
        ids: siteIds,
        params: {
          SiteActive: true,
        },
      });

      const sitesWithCountryCode = sites.map(({ siteCountry, ...rest }) => {
        return { ...rest, siteCountryCode: siteCountry };
      });

      setAddedSites(sitesWithCountryCode);

      const countryCodes = sitesWithCountryCode.reduce(
        (acc, { siteCountryCode }) => {
          if (!acc.includes(siteCountryCode) && siteCountryCode) {
            return [...acc, siteCountryCode];
          }
          return acc;
        },
        []
      );

      setSiteCountryCodes(countryCodes);
      updateSiteCountryInAddedSites(siteCountryCodeDictionaryRef.current);
    } catch (_) {}
  };

  const updateSiteCountryInAddedSites = (dictionary: Dictionary) => {
    if (!isObjectEmpty(dictionary)) {
      setAddedSites(prev => {
        return prev.map(({ siteCountryCode, ...rest }) => {
          return {
            ...rest,
            siteCountry: dictionary[siteCountryCode],
          };
        });
      });
    }
  };

  const handleDropdownVisibleChange = (open: boolean) => {
    if (open) {
      getSites();
    }
  };

  const getSites = async () => {
    if (canLoadMore) {
      try {
        setIsSitesLoading(true);
        setHasSitesError(false);

        let searchSitesProps: {
          ids?: string[];
          params: {
            [key: string]: number | boolean | string;
          };
        } = {
          params: { skip: (currentPage - 1) * 50, take: 50, SiteActive: true },
        };

        if (!isObjectEmpty(specificationData)) {
          searchSitesProps = {
            ...searchSitesProps,
            ids: [owningCompanyId],
            params: {
              ...searchSitesProps.params,
              AssociatedCompanyId: specificationData.ownerCompanyId,
              AssociatedCompanyRelationStatus: true,
            },
          };
        }

        const {
          data: { sites, skipAndTakeQueryStats },
        } = await searchSites(searchSitesProps);

        let newItemsLength = sites.length;

        setSites(previousState => {
          const newItemsList = [...previousState, ...sites];

          newItemsLength = newItemsList.length;
          return newItemsList;
        });

        setCurrentPage(previousState => previousState + 1);

        setCanLoadMore(skipAndTakeQueryStats.totalCount > newItemsLength);
      } catch (e) {
        setHasSitesError(true);
      } finally {
        setIsSitesLoading(false);
      }
    }
  };

  const siteOptions = useMemo(() => {
    return sites
      .filter(({ id }) => !siteIds.includes(id))
      .map(({ id, siteName }) => ({
        value: id,
        label: `${siteName}`,
      }));
  }, [sites, siteIds]);

  return {
    addedSites,
    siteOptions,
    hasSitesError,
    isSitesLoading,
    handleDropdownVisibleChange,
    getSites,
  };
};

export default useSitesArray;
