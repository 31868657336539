import ChapterForm from "./components/ChapterForm";
import ClaimForm from "./components/ClaimForm";
import List from "./components/List";
import MaterialForm from "./components/MaterialForm";
import RequirementForm from "./components/RequirementForm";
import TemplateForm from "./components/TemplateForm";
import { withUserValidation } from "./hocs";

const Library = {
  ChapterForm,
  ClaimForm,
  List: withUserValidation(List),
  MaterialForm: withUserValidation(MaterialForm),
  RequirementForm,
  TemplateForm,
};

export default Library;
