import { Radio } from "@trace-one/design-system";
import classnames from "classnames";
import { useWidgetActions } from "../../hooks";
import { useSpecListRadioWidget } from "./hooks";
import { SpecListRadioWidgetProps } from "./types";
import widgetStyle from "../styles.module.less";

const SpecListRadioWidget: React.FC<SpecListRadioWidgetProps> = props => {
  const { formContext, id, schema } = props;
  const { propertyId } = schema;
  const { templateBlockId, sectionId } = formContext;

  const { isWidgetChanged } = useWidgetActions({ templateBlockId, sectionId });

  const radioProps = useSpecListRadioWidget(props);

  return (
    <div
      className={classnames({
        [widgetStyle.inRevisionChange]: isWidgetChanged(propertyId, id),
      })}
    >
      <Radio.Group {...radioProps} />
    </div>
  );
};

export default SpecListRadioWidget;
