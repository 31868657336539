import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  fetchSpecificationChapter,
  fetchSpecificationChapterAllergens,
} from "apis/SPEC";
import {
  CHAPTER_SECTION_DOCUMENTS,
  CHAPTER_SECTION_REQUIREMENTS,
  CHAPTER_TYPES_API,
  PERMISSIONS,
} from "utils/constants";
import { addOrUpdateItemToAList, isListEmpty } from "utils/general";
import { updateInfoForStructure } from "utils/library";
import {
  isSpecificationSigned,
  prepareDocumentsSectionStatus,
  prepareRequirementsSectionStatus,
} from "utils/specifications";
import { ChapterStructureFormData } from "types/library";
import { SpecificationChapter } from "types/specification";
import { ChapterSectionData, SpecificationData } from "models";
import { Permissions } from "types/general";
import { messages } from "pages/Specification/components/Details/messages";

const useSpecificationChaptersData = ({
  specificationId,
  specificationStructure,
  chapterType,
  specificationState,
}: {
  specificationId: string;
  specificationStructure: ChapterStructureFormData[];
  chapterType?: string;
  specificationState?: SpecificationData["state"];
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [chapters, setChapters] = useState<SpecificationChapter[]>([]);
  const { formatMessage } = useIntl();

  const prepareChaptersData = (withLoading = true) => async () => {
    try {
      setIsLoading(withLoading);

      let selectedChapters = chapterType
        ? specificationStructure.filter(
            structure => structure.chapterType === chapterType
          )
        : specificationStructure;

      const chapterPromises = await Promise.allSettled(
        selectedChapters.map(async structure => {
          let chapter: SpecificationChapter = {
            sections: [],
            chapterSection: {
              permissions: [],
            },
          };

          try {
            const { data } = await fetchSpecificationChapter({
              specificationId,
              sectionCode: structure.chapterType,
            });

            chapter = data;

            let chapterPermissions: Permissions = [];

            if (
              data.sections.findIndex(({ permissions }) =>
                permissions?.includes(PERMISSIONS.SECTION.VALIDATE)
              ) > -1
            ) {
              chapterPermissions.push(PERMISSIONS.SECTION.VALIDATE);
            }

            if (
              data.sections.findIndex(({ permissions }) =>
                permissions?.includes(PERMISSIONS.SECTION.SAVE)
              ) > -1
            ) {
              chapterPermissions.push(PERMISSIONS.SECTION.SAVE);
            }

            const chapterSection: ChapterSectionData = {
              validatedByRetailer: data.validatedByRetailer,
              validatedBySupplier: data.validatedBySupplier,
              sectionState: data.chapterProgressState,
              permissions: chapterPermissions,
            };

            if (
              structure.chapterType === CHAPTER_TYPES_API.production &&
              structure.isChapterLibrary
            ) {
              const result = await Promise.allSettled([
                fetchSpecificationChapterAllergens({
                  id: specificationId,
                  chapterType: structure.chapterType,
                  unintentionalPresent: false,
                }),
                fetchSpecificationChapterAllergens({
                  id: specificationId,
                  chapterType: structure.chapterType,
                  unintentionalPresent: true,
                }),
              ]);

              const [
                rawMaterialsAllergensPromise,
                crossContaminationAllergensPromise,
              ] = result;

              if (rawMaterialsAllergensPromise.status === "fulfilled") {
                chapter.rawMaterialsAllergens =
                  rawMaterialsAllergensPromise.value?.data;
              }

              if (crossContaminationAllergensPromise.status === "fulfilled") {
                chapter.crossContaminationAllergens =
                  crossContaminationAllergensPromise.value?.data;
              }
            }

            let chapterStructure = [...structure.sections];

            if (isSpecificationSigned(specificationState)) {
              if (isListEmpty(data.requirements)) {
                chapterStructure = chapterStructure.filter(
                  ({ sectionId }) =>
                    sectionId !== CHAPTER_SECTION_REQUIREMENTS.id
                );
              } else {
                chapterStructure = addOrUpdateItemToAList({
                  list: chapterStructure,
                  propertyName: "sectionId",
                  item: {
                    sectionName: formatMessage(
                      messages.requirementsSectionTitle
                    ),
                    sectionStatus: prepareRequirementsSectionStatus(
                      data.requirements
                    ),
                    sectionId: CHAPTER_SECTION_REQUIREMENTS.id,
                    sectionCode: CHAPTER_SECTION_REQUIREMENTS.code,
                    sections: [],
                  },
                });
              }

              if (isListEmpty(data?.documents?.fileIds)) {
                chapterStructure = chapterStructure.filter(
                  ({ sectionId }) => sectionId !== CHAPTER_SECTION_DOCUMENTS.id
                );
              } else {
                chapterStructure = addOrUpdateItemToAList({
                  list: chapterStructure,
                  propertyName: "sectionId",
                  item: {
                    sectionName: formatMessage(messages.documentsSectionTitle),
                    sectionStatus: prepareDocumentsSectionStatus(
                      data.documents
                    ),
                    sectionId: CHAPTER_SECTION_DOCUMENTS.id,
                    sectionCode: CHAPTER_SECTION_DOCUMENTS.code,
                    sections: [],
                  },
                });
              }
            } else {
              chapterStructure = addOrUpdateItemToAList({
                list: chapterStructure,
                propertyName: "sectionId",
                item: {
                  sectionName: formatMessage(messages.requirementsSectionTitle),
                  sectionStatus: prepareRequirementsSectionStatus(
                    data.requirements
                  ),
                  sectionId: CHAPTER_SECTION_REQUIREMENTS.id,
                  sectionCode: CHAPTER_SECTION_REQUIREMENTS.code,
                  sections: [],
                },
              });

              chapterStructure = addOrUpdateItemToAList({
                list: chapterStructure,
                propertyName: "sectionId",
                item: {
                  sectionName: formatMessage(messages.documentsSectionTitle),
                  sectionStatus: prepareDocumentsSectionStatus(data.documents),
                  sectionId: CHAPTER_SECTION_DOCUMENTS.id,
                  sectionCode: CHAPTER_SECTION_DOCUMENTS.code,
                  sections: [],
                },
              });
            }

            return {
              ...chapter,
              ...structure,
              chapterSection,
              sections: updateInfoForStructure({
                sections: chapter.sections,
                structure: chapterStructure,
              }),
            };
          } catch (e) {
            return chapter;
          } finally {
          }
        })
      );

      let newChapters: ChapterStructureFormData[] = [];

      chapterPromises.forEach(chapterPromise => {
        if (chapterPromise.status === "fulfilled") {
          newChapters.push(chapterPromise.value);
        }
      });

      setChapters(newChapters);
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    prepareChaptersData(true)();
  }, [specificationStructure.length, chapterType]);

  return {
    isLoading,
    chapters,
    fetchChapter: prepareChaptersData(false),
    fetchChapterWithLoading: prepareChaptersData(true),
  };
};

export default useSpecificationChaptersData;
