import { useActions } from "./hooks";
import ActionButton from "components/ActionButton";
import withDuplicateSpecificationAction from "hocs/withDuplicateSpecificationAction";
import { ActionsProps } from "./types";

export const Actions: React.FC<ActionsProps> = ({
  specification,
  onOpenSelectSupplierModal,
  setSpecificationPermissions,
}) => {
  const { isDone, actionList } = useActions({
    specification,
    onOpenSelectSupplierModal,
    setSpecificationPermissions,
  });

  return (
    <ActionButton
      actions={actionList}
      loading={!isDone}
      data-test-id="table-row"
    />
  );
};

export default withDuplicateSpecificationAction(Actions);
