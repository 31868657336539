import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { fetchReferenceListItems } from "apis/RLMD";
import { NutritionServingData, ReferenceListItemData } from "models";
import { isListEmpty, isObjectEmpty } from "utils/general";
import { selectLanguageCode } from "store/user/selectors";
import { updateRefListDictionary } from "store/generalData/asyncActions";
import { useAppDispatch } from "store";
import generalMessages from "messages/general";
import { GRAM_UNIT_ID, REFLIST_IDS } from "utils/constants";
import { EnhancedExtraActions } from "components/SpecificationSection/types";
import { NutritionServingFormData } from "components/SpecificationSection/components/FormSchema/types";

const useNutritionServingForm = ({
  extraActions,
  hasUpdatePermission,
}: {
  extraActions: EnhancedExtraActions;
  hasUpdatePermission: boolean;
}) => {
  const defaultInitialFormData = {
    servingName: undefined,
    servingSize: undefined,
    automaticCalculation: true,
  };

  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const languageCode = useSelector(selectLanguageCode);
  const defaultUnitRef = useRef<ReferenceListItemData>(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [formErrors, setFormErrors] = useState([]);
  const [shouldRefetchServings, setShouldRefetchServings] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [referenceList, setReferenceList] = useState<ReferenceListItemData[]>(
    []
  );
  const [selectedUnit, setSelectedUnit] = useState<ReferenceListItemData>();
  const [selectedServingId, setSelectedServingId] = useState<string>();
  const [
    nutritionServingFormData,
    setNutritionServingFormData,
  ] = useState<NutritionServingFormData>(defaultInitialFormData);

  useEffect(() => {
    onVisibleChange(true);
  }, []);

  useEffect(() => {
    const defaultUnit = referenceList.filter(
      item => item.id === GRAM_UNIT_ID
    )[0];

    if (
      selectedUnit?.id !== defaultUnit?.id &&
      defaultUnitRef.current?.id !== defaultUnit?.id
    ) {
      setSelectedUnit(defaultUnit);
      defaultUnitRef.current = defaultUnit;
    }
  }, [referenceList]);

  const handleServingNameChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setNutritionServingFormData(prevState => ({
      ...prevState,
      servingName: value as string,
    }));
  };

  const handleServingSizeChange = (value: number) => {
    setNutritionServingFormData(prevState => ({
      ...prevState,
      servingSize: value,
    }));
  };

  const handleAutomaticCalculationChange = (value: boolean) => {
    setNutritionServingFormData(prevState => ({
      ...prevState,
      automaticCalculation: value,
    }));
  };

  const onVisibleChange = async (open: boolean) => {
    if (open) {
      try {
        setIsLoading(true);

        const { data } = await fetchReferenceListItems({
          id: REFLIST_IDS.UNITS_OF_MEASURE,
          languageCode,
          parentItemIds: [
            REFLIST_IDS.MASS,
            REFLIST_IDS.VOLUME,
            REFLIST_IDS.INTERNATIONAL_SYSTEM_SOURCE,
          ],
        });

        setReferenceList(data.referenceListItems);
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
    }
  };

  const onChangeUnit = (unitOfMeasure: ReferenceListItemData) => () => {
    const { text, id } = unitOfMeasure;
    dispatch(
      updateRefListDictionary({
        [id]: text,
      })
    );

    setSelectedUnit(unitOfMeasure);
  };

  const getIsPrimaryModalButtonDisabled = () => {
    return (
      formErrors.some(item => !isListEmpty(item.errors)) ||
      !nutritionServingFormData.servingName ||
      !nutritionServingFormData.servingSize ||
      isObjectEmpty(selectedUnit)
    );
  };

  const resetFormAndCloseModal = () => {
    setNutritionServingFormData(defaultInitialFormData);
    setSelectedUnit(defaultUnitRef.current);
    setIsModalOpen(false);
    setIsEditMode(false);
  };

  const onPrimaryButtonClick = async () => {
    try {
      if (isEditMode) {
        extraActions?.updateNutritionServingAction({
          name: nutritionServingFormData?.servingName,
          value: nutritionServingFormData?.servingSize,
          unitId: selectedUnit?.id,
          automatic: nutritionServingFormData?.automaticCalculation,
          servingId: selectedServingId,
          setShouldRefetchServings,
        });
      } else {
        extraActions?.createNutritionServingAction({
          name: nutritionServingFormData?.servingName,
          value: nutritionServingFormData?.servingSize,
          unitId: selectedUnit?.id,
          automatic: nutritionServingFormData?.automaticCalculation,
          setShouldRefetchServings,
        });
      }
      resetFormAndCloseModal();
    } catch (error) {
      setShouldRefetchServings(false);
    }
  };

  const onSecondaryButtonClick = () => {
    resetFormAndCloseModal();
  };

  const onEditServing = ({
    servingId,
    name,
    size,
    automaticCalculation,
  }: NutritionServingData) => {
    setIsModalOpen(true);
    setIsEditMode(true);
    setNutritionServingFormData({
      servingName: name,
      servingSize: size.value,
      automaticCalculation,
    });
    setSelectedServingId(servingId);

    const selectedRefList = referenceList?.find(item => item.id === size.unit);

    setSelectedUnit(selectedRefList);
  };

  return {
    isModalOpen,
    setIsModalOpen,
    nutritionServingFormData,
    setNutritionServingFormData,
    setFormErrors,
    shouldRefetchServings,
    setShouldRefetchServings,
    handleServingNameChange,
    handleServingSizeChange,
    handleAutomaticCalculationChange,
    getIsPrimaryModalButtonDisabled,
    onPrimaryButtonClick,
    onSecondaryButtonClick,
    selectedUnit,
    isEditMode,
    onEditServing,
    onClearUnit: () => setSelectedUnit({}),
    isCalculationToggleDisabled: !hasUpdatePermission,
    items: isLoading
      ? [{ label: formatMessage(generalMessages.loading), disabled: true }]
      : referenceList.map(({ text, id }) => ({
          label: text,
          onClick: onChangeUnit({ text, id }),
        })),
    buttonProps: {
      loading: isLoading,
      dropdownProps: {
        onVisibleChange,
      },
    },
  };
};

export default useNutritionServingForm;
