import { useSelector } from "react-redux";
import { Title } from "@trace-one/react-components";
import { useIntl } from "react-intl";
import { Stepper } from "@trace-one/design-system";
import {
  selectSelectedSupplier,
  selectProperStep,
  selectIsSaving,
  selectSpecification,
  selectWizard,
} from "store/specificationCreation/selectors";
import CreationWizzard from "components/CreationWizzard";
import CreateCollaboration from "components/CreateCollaboration";
import { APPLICATION_TYPE_IDS, REFLIST_IDS } from "utils/constants";
import { useCreationActions, useSteps } from "../../hooks";
import { messages } from "./messages";

const Invitation = () => {
  const selectedSupplier = useSelector(selectSelectedSupplier);
  const { formatMessage } = useIntl();

  const { collaborationIds, draftId } = useSelector(selectSpecification);
  const { isInvitationStepCompleted } = useSelector(selectProperStep);
  const isSaving = useSelector(selectIsSaving);
  const wizard = useSelector(selectWizard);

  const {
    stepIndex,
    currentIsValid,
    steps,
    onPreviousStep,
    onNextStep,
  } = useSteps({
    currentStep: wizard.currentStep,
    stepsState: wizard.state,
  });

  const {
    onSpecificationDelete,
    handleCreateCollaboration,
    handleRemoveCollaborations,
    handleChangeValidation,
    handleChangeEnableSupplierCollaboration,
  } = useCreationActions();

  return (
    <>
      <CreationWizzard.Title.Specification />
      <Stepper
        stepIndex={stepIndex}
        currentIsValid={currentIsValid}
        steps={steps}
      />
      <Title
        level={2}
        value={formatMessage(messages.title)}
        subtitle={formatMessage(messages.subtitle)}
      />
      <CreateCollaboration
        applicationId={APPLICATION_TYPE_IDS.SPEC}
        parentDataObjectId={draftId}
        parentDataObjectSystem="SPEC"
        roleIds={[REFLIST_IDS.QUALITY_MANAGER_ROLE]}
        companyIdsWithLinkedProducts={[
          {
            numberOfLinkedProducts: 1,
            collaboratingCompanyId: selectedSupplier?.value,
            isCollaborationEnabled: wizard?.collaborations?.[0]?.enabled,
          },
        ]}
        responsibilityIds={[REFLIST_IDS.QUALITY_MANAGER_RESPONSIBILITY]}
        onCreatedCollaboration={handleCreateCollaboration}
        onRemoveCollaborations={handleRemoveCollaborations}
        existingCollaborationIds={collaborationIds}
        onChangeValidation={handleChangeValidation}
        isLoading={isSaving}
        onChangeEnableSupplierCollaboration={
          handleChangeEnableSupplierCollaboration
        }
      />

      <CreationWizzard.Footer
        onBackClick={onPreviousStep}
        onNextClick={onNextStep}
        onCancel={onSpecificationDelete}
        isNextDisabled={!isInvitationStepCompleted}
      />
    </>
  );
};

export default Invitation;
