import Specification from "./components/SpecificationQuickView";
import Progress from "./components/Progress";
import ProgressBar from "./components/ProgressBar";
import Legend from "./components/QuickviewLegend";
import FolderRow from "./components/FolderRow";
import SpecificationRow from "./components/SpecificationRow";
import ChapterQuickViewTitle from "./components/SpecificationRow/components/ChapterQuickViewTitle";
import SpecificationQuickViewTitle from "./components/SpecificationRow/components/SpecificationQuickViewTitle";
import FolderRowList from "./components/FolderRowList";

const Quickview = {
  FolderRow,
  FolderRowList,
  Specification,
  Progress,
  ProgressBar,
  Legend,
  ChapterTitle: ChapterQuickViewTitle,
  SpecificationTitle: SpecificationQuickViewTitle,
  SpecificationRow,
};

export * from "./components/SpecificationQuickView";
export default Quickview;
