import { useModalVisibility } from "hooks";
import { useState } from "react";

const useChangeMaterialType = ({
  materialType,
  onChangeType,
}: {
  materialType: string;
  onChangeType: Function;
}) => {
  const { isModalOpen, onOpenModal, onCloseModal } = useModalVisibility();

  const [selectedMaterialType, setSelectedMaterialType] = useState<string>(
    materialType
  );

  const [
    newlySelectedMaterialType,
    setNewlySelectedMaterialType,
  ] = useState<string>();

  const onChangeMaterialType = async (value: string) => {
    if (value && materialType && materialType !== value) {
      setNewlySelectedMaterialType(value);
      onOpenModal();
    } else {
      await onChangeType(value);
      setSelectedMaterialType(value);
    }
  };

  const onConfirmChangeMaterialType = async () => {
    if (newlySelectedMaterialType) {
      await onChangeType(newlySelectedMaterialType);
      setSelectedMaterialType(newlySelectedMaterialType);
      setNewlySelectedMaterialType(undefined);
    }

    onCloseModal();
  };

  const onCancelChangeMaterialType = () => {
    if (newlySelectedMaterialType) {
      setSelectedMaterialType(materialType);
      setNewlySelectedMaterialType(undefined);
    }

    onCloseModal();
  };

  return {
    selectedMaterialType,
    isModalOpen,
    onChangeMaterialType,
    onConfirmChangeMaterialType,
    onCancelChangeMaterialType,
  };
};

export default useChangeMaterialType;
