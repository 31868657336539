import { useState } from "react";
import { useIntl } from "react-intl";
import classNames from "classnames";
import { Button, TextArea } from "@trace-one/design-system";
import { Col, Row } from "antd";
import { useDiscussionPanel } from "components/DiscussionPanel/hooks";
import { isListEmpty } from "utils/general";
import { isStatusCodeServerError } from "utils/httpUtils";
import { modificationRequestMessages } from "messages";
import { MenuActions } from "types/general";

interface DiscussionPanelFooterProps {
  tertiaryActions?: MenuActions[];
  canSendMessage?: boolean;
}

const DiscussionPanelFooter: React.FC<DiscussionPanelFooterProps> = ({
  tertiaryActions,
  canSendMessage,
}) => {
  const { formatMessage } = useIntl();
  const [message, setMessage] = useState<string>(null);

  const { onSendMessage, isSendingMessage } = useDiscussionPanel();

  const sendMessageHandler = async () => {
    try {
      const thunkRes = await onSendMessage(message);

      // @ts-ignore
      if (!isStatusCodeServerError(thunkRes?.payload?.statusCode)) {
        setMessage(null);
      }
    } catch {}
  };

  return (
    <Row gutter={[8, 0]}>
      <Col span={18}>
        <TextArea
          value={message}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setMessage(e?.target?.value)
          }
          allowClear={false}
          disabled={!canSendMessage}
          data-test-id="discussion-panel-message-textArea"
          placeholder={formatMessage(
            modificationRequestMessages.messagePlaceholder
          )}
          autoSize={{ minRows: 1, maxRows: 13 }}
        />
      </Col>

      <div className={classNames("d-flex", "ai-flex-end")}>
        <Col span={12}>
          <Button
            type="primary"
            iconName="send"
            color="amethyst"
            disabled={!canSendMessage || !message?.trim()}
            onClick={sendMessageHandler}
            loading={isSendingMessage}
            data-test-id="discussion-panel-footer-send"
          />
        </Col>
        <Col span={12} className="mb-0-5">
          <Button
            items={tertiaryActions}
            type="tertiary"
            iconName="kebab"
            size="large"
            color="grey"
            dropdownProps={{ placement: "topRight" }}
            disabled={
              isListEmpty(tertiaryActions) ||
              tertiaryActions?.every(({ disabled }) => disabled)
            }
            data-test-id="discussion-panel-footer-tertiary"
          />
        </Col>
      </div>
    </Row>
  );
};

export default DiscussionPanelFooter;
