import { useContext } from "react";
import { Checkbox } from "@trace-one/design-system";
import { AllergensArrayFieldTemplateContext } from "components/SpecificationSection/components/FormSchema/templates/ArrayFieldTemplate/AllergensArrayFieldTemplate/contexts";
import { RiskProps } from "./types";

const Risk: React.FC<RiskProps> = ({
  allergenId,
  areActionsDisabled,
  value,
}) => {
  const { updateChapterCCAllergenRiskAction } = useContext(
    AllergensArrayFieldTemplateContext
  );

  return (
    <Checkbox
      checked={value}
      onChange={event => {
        updateChapterCCAllergenRiskAction({
          allergenId,
          controlledRisk: event.target.checked,
        });
      }}
      disabled={areActionsDisabled}
    />
  );
};

export default Risk;
