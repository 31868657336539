import initialState from "./initialState";
import { State } from "./types";

const reducers = {
  setFolderId: (state: State, action) => {
    state.folder.folderId = action.payload;
  },

  setFolderChapters: (state: State, action) => {
    state.chapters = action.payload;
  },

  resetFolderCreationErrors: (state: State) => {
    state.errors = initialState.errors;
  },

  setIsInvitationStepValid: (state: State, action) => {
    state.isInvitationStepValid = action.payload;
  },
};

export default reducers;
