import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useFilters } from "hooks";
import {
  getOnClearSearch,
  getOnStatusClick,
  getOnStatusRemove,
  getStatuses,
  getOnSearchChange,
  getOnClearAllFilters,
} from "pages/utils";
import FiltersComponent from "components/Filters";
import filterMessages from "messages/filters";
import { messages } from "./messages";
import { FiltersProps } from "./types";

const Filters: React.FC<FiltersProps> = ({ onUpdateFilters }) => {
  const intl = useIntl();
  const [searchTerm, setSearchTerm] = useState("");
  const statuses = getStatuses({ type: "folder" });
  const [selectedProductTypes, setSelectedProductTypes] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);

  const {
    prepareSelectedFilters,
    selectedFilters,
    selectedFiltersList,
    handleChangeSelectedFilters,
  } = useFilters();

  const onSearch = () => {
    onUpdateFilters({
      folderName: searchTerm?.trim(),
    });
  };

  useEffect(() => {
    prepareSelectedFilters({
      currentSelectedFilters: selectedUsers,
      setCurrentSelectedFilters: setSelectedUsers,
      key: "ownerOrContactIds",
      nameKey: "ownerOrRetailerContact",
    });
  }, [selectedUsers]);

  useEffect(() => {
    prepareSelectedFilters({
      currentSelectedFilters: selectedProductTypes,
      setCurrentSelectedFilters: setSelectedProductTypes,
      key: "productTypeIds",
      nameKey: "productType",
    });
  }, [selectedProductTypes]);

  useEffect(() => {
    prepareSelectedFilters({
      currentSelectedFilters: selectedStatuses,
      setCurrentSelectedFilters: setSelectedStatuses,
      key: "statuses",
      nameKey: "status",
    });
  }, [selectedStatuses]);

  useEffect(() => {
    handleChangeSelectedFilters({
      onFilterSelect: onUpdateFilters,
      selectedFilters,
    });
  }, [selectedFilters]);

  return (
    <FiltersComponent.Container
      filters={[
        {
          label: intl.formatMessage(filterMessages.productType),
          content: (
            <FiltersComponent.ProductTypes
              selectedProductTypes={selectedProductTypes}
              setSelectedProductTypes={setSelectedProductTypes}
            />
          ),
        },
        {
          label: intl.formatMessage(filterMessages.ownerOrRetailerContact),
          content: (
            <FiltersComponent.OwnersOrContacts
              value={selectedUsers}
              onChange={setSelectedUsers}
              data-testid="owner-or-contact-ids"
            />
          ),
        },
        {
          label: intl.formatMessage(filterMessages.status),
          content: (
            <FiltersComponent.Statuses
              statuses={statuses}
              selectedStatuses={selectedStatuses}
              onStatusRemove={getOnStatusRemove({
                setSelectedStatuses,
              })}
              onStatusClick={getOnStatusClick({
                setSelectedStatuses,
              })}
            />
          ),
        },
      ]}
      onClearFiltersClick={getOnClearAllFilters({
        setSelectedStatuses,
        setSelectedProductTypes,
        setSelectedUsers,
      })}
      onClearSearch={getOnClearSearch({ setSearchTerm })}
      onSearch={onSearch}
      onSearchChange={getOnSearchChange({ setSearchTerm })}
      searchPlaceholder={intl.formatMessage(messages.searchPlaceholder)}
      searchValue={searchTerm}
      selectedFilters={selectedFiltersList}
    />
  );
};

export default Filters;
