import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useRedirect } from "hooks";
import { selectGeneralInfo } from "store/packagingSystemForm/selectors";
import {
  usePackagingSystemNavigation,
  usePackagingSystemFormInitialisation,
  PackagingSystemTabKeys,
} from "./hooks";
import { LIBRARY_OBJECT_TYPES } from "utils/constants";
import { onTabChange } from "pages/Library/utils";
import { preparePackagingSystemFromApiData } from "dtos/packagingSystem";
import { PackagingSystemProps } from "./types";

const PackagingSystem: React.FC<PackagingSystemProps> = ({
  initialPackagingSystemData,
  updateTabsInformation,
  updateActionsInformation,
}) => {
  const { redirectToLibraryEdition } = useRedirect();

  usePackagingSystemFormInitialisation({
    initialFormData: initialPackagingSystemData,
  });

  const packagingSystemGeneralInfo = useSelector(selectGeneralInfo);

  const { generalInformation, components } = useMemo(
    () => preparePackagingSystemFromApiData(packagingSystemGeneralInfo),
    [packagingSystemGeneralInfo]
  );

  const {
    activeTab,
    setActiveTab,
    tabItems,
    tabContent,
  } = usePackagingSystemNavigation({
    generalInfo: generalInformation,
    components,
  });

  useEffect(() => {
    updateTabsInformation({
      defaultActiveKey: PackagingSystemTabKeys.GENERAL_INFORMATION,
      onTabClick: setActiveTab,
      items: tabItems,
    });
  }, []);

  useEffect(() => {
    updateTabsInformation({
      activeKey: activeTab,
    });
  }, [activeTab]);

  useEffect(() => {
    updateTabsInformation({
      onChange: (tabKey: string) =>
        onTabChange({
          tabKey,
          libraryItemId: generalInformation?.packagingSystemId,
          libraryItemType: LIBRARY_OBJECT_TYPES.PACKAGING_SYSTEMS,
          redirectToPage: redirectToLibraryEdition,
        }),
    });
  }, [generalInformation?.packagingSystemId]);

  useEffect(() => {
    updateTabsInformation({
      items: tabItems,
    });
  }, [tabContent]);

  useEffect(() => {
    updateActionsInformation({
      onClick: null,
    });
  }, []);

  return <>{tabContent}</>;
};

export default PackagingSystem;
