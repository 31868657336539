import cleanDeep from "clean-deep";
import { setCleanValue } from "components/SpecificationSection/components/FormSchema/components/CustomForm/utils";
import {
  isListEmpty,
  isObjectEmpty,
  transformStringToPathList,
} from "utils/general";

export const getArrayDiff = (
  firstArray: { [key: string]: any }[],
  secondArray: { [key: string]: any }[],
  itemKey = "id"
) =>
  firstArray.find(
    previousItem =>
      secondArray.findIndex(
        currentItem => currentItem[itemKey] === previousItem[itemKey]
      ) === -1
  );

export const getSchemaObjectDiff = (
  firstObject: { [key: string]: any },
  secondObject: { [key: string]: any }
): string[] => {
  const firstObjectKeys = Object.keys(firstObject);
  const secondObjectKeys = Object.keys(secondObject);

  return firstObjectKeys.reduce((previousState, key) => {
    if (!secondObject.hasOwnProperty(key)) {
      if (typeof firstObject[key] === "object") {
        if (!isObjectEmpty(setCleanValue({ key, value: firstObject[key] }))) {
          return [...previousState, key];
        }

        return [...previousState];
      } else {
        return [...previousState, key];
      }
    }

    if (typeof firstObject[key] === "object") {
      const childDifferences = getSchemaObjectDiff(
        firstObject[key],
        secondObject[key]
      );
      previousState = previousState.filter(currentKey => currentKey !== key);

      if (!isListEmpty(childDifferences)) {
        return [
          ...previousState,
          ...childDifferences.map(
            currentChildKey => `${key}.${currentChildKey}`
          ),
        ];
      }
    }

    if (firstObject[key] === secondObject[key]) {
      return previousState.filter(currentKey => currentKey !== key);
    }

    return [...previousState];
  }, secondObjectKeys);
};

export const prepareBlockData = ({
  blockData,
  newBlockData,
  previousBlockData,
}) => {
  let newData = JSON.parse(JSON.stringify(newBlockData));

  let differencesKeys = getSchemaObjectDiff(previousBlockData, blockData);

  if (!isListEmpty(differencesKeys)) {
    differencesKeys.forEach(key => {
      const currentKeysList = transformStringToPathList(key);

      if (currentKeysList.length === 1) {
        newData[key] = blockData[key];
      } else {
        let currNewData = newData;
        let currOldData = blockData;

        for (var i = 0; i < currentKeysList.length - 1; i++) {
          if (currOldData.hasOwnProperty(currentKeysList[i])) {
            currOldData = currOldData[currentKeysList[i]];

            if (!currNewData.hasOwnProperty(currentKeysList[i])) {
              currNewData[currentKeysList[i]] = {};
            }

            currNewData = currNewData[currentKeysList[i]];
          }
        }

        currNewData[currentKeysList[currentKeysList.length - 1]] =
          currOldData[currentKeysList[currentKeysList.length - 1]];
      }
    });
  }

  return {
    newData: cleanDeep(newData, {
      emptyArrays: false,
      emptyStrings: false,
    }),
    differencesKeys,
  };
};

export const findItemByItemKeyHandler = ({
  itemKey,
  currentItem,
  currentList,
}) => listToSearch => {
  let item = listToSearch.find(
    previousItem => previousItem[itemKey] === currentItem[itemKey]
  );

  if (isObjectEmpty(item)) {
    item = getArrayDiff(listToSearch, currentList, itemKey);
  }

  return item;
};

export const prepareArrayBlockData = ({
  previousBlockData,
  blockData,
  newBlockData,
  itemKey,
  propertyName = "items",
}) => {
  let newData = JSON.parse(JSON.stringify(newBlockData));

  if (isListEmpty(newData?.[propertyName])) {
    newData[propertyName] = [];
  }

  if (isListEmpty(previousBlockData?.[propertyName])) {
    previousBlockData[propertyName] = [];
  }

  let previousLength = previousBlockData?.[propertyName]?.length || 0;

  // New element is added
  if (blockData[propertyName].length > previousLength) {
    // find the itemKey that has been added
    const addedElement = getArrayDiff(
      blockData[propertyName],
      previousBlockData[propertyName],
      itemKey
    );

    // if the added element doesn't exist in the payload we need to add it
    if (
      newData[propertyName].findIndex(
        currentItem => currentItem[itemKey] === addedElement[itemKey]
      ) === -1
    ) {
      newData[propertyName].push(addedElement);
    }

    return {
      newData,
      differencesKeys: [`${propertyName}.${newData[propertyName].length - 1}`],
    };
  }

  // Element is removed
  if (blockData[propertyName].length < previousLength) {
    // find the itemKey that has been removed
    const removedElement = getArrayDiff(
      previousBlockData[propertyName],
      blockData[propertyName],
      itemKey
    );

    newData[propertyName] = newBlockData[propertyName].filter(
      currentItem => currentItem[itemKey] !== removedElement[itemKey]
    );

    return { newData, differencesKeys: [`${propertyName}`] };
  }

  // Element is updated
  let differencesKeys = [];

  return {
    newData: {
      [propertyName]: blockData[propertyName].map((currentItem, i) => {
        const findItemByItemKey = findItemByItemKeyHandler({
          itemKey,
          currentItem,
          currentList: blockData[propertyName],
        });

        const oldItem = findItemByItemKey(previousBlockData[propertyName]);

        const apiItem = findItemByItemKey(newData[propertyName]);

        const prepBlock = prepareBlockData({
          blockData: currentItem,
          newBlockData: apiItem,
          previousBlockData: oldItem,
        });

        differencesKeys.push(
          ...prepBlock.differencesKeys.map(key => `${propertyName}.${i}.${key}`)
        );

        return prepBlock.newData;
      }),
    },
    differencesKeys,
  };
};
