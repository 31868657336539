import { useMemo, useRef } from "react";
import { Select } from "@trace-one/design-system";
import classNames from "classnames";
import ConditionalWrapper from "components/ConditionalWrapper";
import RequestModificationTooltipIcon from "components/RequestModificationTooltipIcon";
import withRequestModificationPopover from "hocs/withRequestModificationPopover";
import { setRequestModification } from "store/specificationDetails/specificationDetailsSlice";
import { useAppDispatch } from "store";
import {
  canTargetField,
  doesFieldHaveModificationRequest,
  getDraftRequestFromAllRequests,
  getSelectClosestClassname,
  getSelectPopoverStyle,
} from "utils/modificationRequest";
import { StorageConditionData } from "components/SpecificationSection/components/FormSchema/models";
import { RefListOption } from "../../types";
import { FormContext } from "components/SpecificationSection/types";
import templateStyles from "../../../../styles.module.less";

interface AddRowProps {
  referenceOptions: RefListOption[];
  areActionsDisabled: boolean;
  addBtnLabel: string;
  updateExcludedRefListIds: (reflistId: string) => void;
  formData: StorageConditionData[];
  itemKey: string;
  shouldAddActionBeDisabled: boolean;
  formContext: FormContext;
  propertyId: string;
  setModalStyle: Function;
  setSelectedField: Function;
  onOpenModal: Function;
  setRequestPopoverOptions: Function;
  isLoading: boolean;
  multiple?: boolean;
}

const AddRow: React.FC<AddRowProps> = ({
  isLoading,
  referenceOptions,
  areActionsDisabled,
  addBtnLabel,
  updateExcludedRefListIds,
  formData,
  itemKey,
  shouldAddActionBeDisabled,
  formContext,
  propertyId,
  setModalStyle,
  setSelectedField,
  onOpenModal,
  setRequestPopoverOptions,
  multiple,
}) => {
  const addRowRef = useRef(null);

  const dispatch = useAppDispatch();

  const {
    saveTemplateBlock,
    isTargetMode,
    modificationRequests,
    ownerCompanyId,
    chapterData,
  } = formContext;

  const hasDraftModificationRequest = useMemo(() => {
    return getDraftRequestFromAllRequests({
      modificationRequests,
      propertyId,
    });
  }, [modificationRequests, propertyId]);

  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    const wrapper = event.target;
    const wrapperBoundingClientRect = addRowRef.current.getBoundingClientRect();
    const targetBoundingClientRect = (event.target as HTMLElement).getBoundingClientRect();

    if (wrapper) {
      (wrapper as HTMLElement)
        .closest(
          getSelectClosestClassname({
            wrapperBoundingClientRectWidth: wrapperBoundingClientRect?.width,
            targetBoundingClientRectWidth: targetBoundingClientRect?.width,
          })
        )
        .classList.add("target-mode__border");
    }

    setModalStyle(
      getSelectPopoverStyle({
        wrapperBoundingClientRect,
        targetBoundingClientRect,
      })
    );

    setSelectedField({
      propertyId,
    });

    onOpenModal?.();
    dispatch(setRequestModification({ isEditorOpen: true }));
  };

  const onSelect = (value: string) => {
    updateExcludedRefListIds(value);

    saveTemplateBlock(
      JSON.stringify({
        items: [
          ...formData,
          {
            [itemKey]: value,
          },
        ],
      }),
      JSON.stringify({ items: [...formData] })
    );
  };

  const renderPopoverIcon = (propertyId: string) => {
    const request = getDraftRequestFromAllRequests({
      modificationRequests,
      propertyId,
    });

    if (request) {
      return (
        <RequestModificationTooltipIcon
          key={`${propertyId}`}
          onOpenModal={onOpenModal}
          setModalStyle={setModalStyle}
          tableRef={addRowRef}
          setRequestPopoverOptions={setRequestPopoverOptions}
          request={request}
        />
      );
    }
  };

  return (
    <ConditionalWrapper
      condition={isTargetMode}
      wrapper={children => (
        <div className="target-mode__wrapper" ref={addRowRef}>
          {children}
        </div>
      )}
    >
      <>
        <Select
          value={null}
          loading={isLoading}
          options={referenceOptions.map(({ id, text, disabled }) => ({
            name: text,
            value: id,
            disabled,
          }))}
          disabled={areActionsDisabled || shouldAddActionBeDisabled}
          placeholder={addBtnLabel}
          onSelect={onSelect}
          {...(isTargetMode &&
            !doesFieldHaveModificationRequest({
              modificationRequests,
              propertyId: propertyId,
            }) && {
              onClick,
            })}
          className={classNames({
            "target-mode": canTargetField({
              isTargetMode,
              specOwnerCompanyId: ownerCompanyId,
              chapterOwnerCompanyId: chapterData?.ownerCompanyId,
            }),
            "target-mode__background": hasDraftModificationRequest,
            "target-mode__border-color": hasDraftModificationRequest,
            [templateStyles["target-mode__single-select"]]:
              isTargetMode && !multiple,
          })}
        />

        {isTargetMode ? renderPopoverIcon(propertyId) : null}
      </>
    </ConditionalWrapper>
  );
};

export default withRequestModificationPopover(AddRow);
