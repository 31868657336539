import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Col, Row } from "antd";
import { useIntl } from "react-intl";
import {
  selectGeneraInfo,
  selectProgressState,
  selectSections,
  selectStructure,
  selectUpdateStatus,
} from "store/chapterForm/selectors";
import { useLibraryFormActions } from "components/Library/hooks";
import { useChapterFormActions, useChapterFormInitialisation } from "./hooks";
import { ChapterFormProvider } from "./contexts";
import GeneralInfo from "./components/GeneralInfo";
import Configure from "./components/Configure";
import CancelDeleteChapterModal from "./components/CancelDeleteChapterModal";
import { ChapterFormProps } from "./types";
import libraryMessages from "messages/library";

export const ChapterForm: React.FC<ChapterFormProps> = ({
  page,
  updateActionsInformation,
}) => {
  const { formatMessage } = useIntl();
  const generalInfo = useSelector(selectGeneraInfo);
  const progressState = useSelector(selectProgressState);
  const structure = useSelector(selectStructure);
  const sections = useSelector(selectSections);
  const { inProgress: isUpdateInProgress } = useSelector(selectUpdateStatus);

  const { areActionButtonsDisabled, onFieldsChange } = useLibraryFormActions({
    isUpdateInProgress,
    requiredFields: ["name", "productTypeId", "type"],
    libraryItem: generalInfo,
  });

  const chapterIdRef = useRef("");

  useEffect(() => {
    chapterIdRef.current = generalInfo.id;
  }, [generalInfo.id]);

  useChapterFormInitialisation({
    initialFormData: {},
  });

  const { id, type, name } = generalInfo || {};

  const { onKeepAsDraftClick, onPublishChapter } = useChapterFormActions({
    chapterId: id,
    chapterType: type,
    chapterName: name,
    page,
  });

  useEffect(() => {
    updateActionsInformation({
      props: {
        disabled: areActionButtonsDisabled,
        items: [
          {
            name: formatMessage(libraryMessages.publishButton),
            label: formatMessage(libraryMessages.publishButton),
            onClick: onPublishChapter,
          },
          {
            name: formatMessage(libraryMessages.keepAsDraftButton),
            label: formatMessage(libraryMessages.keepAsDraftButton),
            onClick: onKeepAsDraftClick,
          },
        ],
      },
    });
  }, [areActionButtonsDisabled]);

  return (
    <ChapterFormProvider chapterId={chapterIdRef.current}>
      <Row gutter={[20, 0]}>
        <Col xs={24}>
          <GeneralInfo
            generalInfo={generalInfo}
            onFieldsChange={onFieldsChange}
            page={page}
          />
        </Col>
        <Col xs={24}>
          <Configure
            structure={structure}
            sections={sections}
            progressState={progressState}
            generalInfo={generalInfo}
          />
        </Col>
      </Row>
      <CancelDeleteChapterModal isNewVersion={false} />
    </ChapterFormProvider>
  );
};

export default ChapterForm;
