import { useEffect, useMemo, useState } from "react";
import { RadioChangeEvent } from "antd";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { isWidgetDisabled } from "../../../utils";
import { RadioWidgetProps } from "../../types";

const useRadioWidget = ({
  disabled,
  formContext,
  readonly,
  options,
  schema,
  value,
  onChange: jsonOnChange,
}: RadioWidgetProps) => {
  const [radioValue, setRadioValue] = useState(value);
  const [radioOptions, setRadioOptions] = useState([]);

  const { getTranslationForKey } = useFormSchemaTranslation();

  const { readonlyAsDisabled = true, isWidgetEditable } = formContext;

  const { propertyId } = schema;

  const { enumOptions } = options;

  const isDisabled = useMemo(
    () =>
      isWidgetDisabled({
        isWidgetEditable: isWidgetEditable(propertyId),
        disabled,
        readonlyAsDisabled,
        readonly,
      }),
    [disabled, readonlyAsDisabled, readonly, propertyId]
  );

  useEffect(() => {
    setRadioOptions(
      // @ts-ignore-next
      enumOptions.reduce((previousState, { value, label }) => {
        return [
          ...previousState,
          {
            value: `${value}`,
            label: getTranslationForKey(options[`${value}:title`] || label),
          },
        ];
      }, [])
    );
  }, [enumOptions]);

  const onChange = ({ target: { value: nextValue } }: RadioChangeEvent) => {
    const valueToSend =
      schema.type === "boolean" ? nextValue !== "false" : nextValue;

    jsonOnChange(valueToSend);

    setRadioValue(nextValue);
  };

  return {
    disabled: isDisabled,
    value: `${radioValue}`,
    onChange,
    options: radioOptions,
  };
};

export default useRadioWidget;
