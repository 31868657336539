import React from "react";

const Sun = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64.604"
    height="66.813"
    viewBox="0 0 64.604 66.813"
    {...props}
  >
    <g transform="translate(-4.197 -1.661)">
      <path
        fill="#e5f6f5"
        stroke="#00a499"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5px"
        d="M108.494,49.577h3.87"
        transform="translate(-53.724 -24.408)"
      />
      <path
        fill="#e5f6f5"
        stroke="#00a499"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5px"
        d="M98.356,17.87l2.9-2.9"
        transform="translate(-48.422 -6.306)"
      />
      <path
        fill="#e5f6f5"
        stroke="#00a499"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5px"
        d="M69.942,6.784V2.911"
        transform="translate(-33.561 0)"
      />
      <path
        fill="#e5f6f5"
        stroke="#00a499"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5px"
        d="M38.357,17.87l-2.9-2.9"
        transform="translate(-15.523 -6.306)"
      />
      <path
        fill="#e5f6f5"
        stroke="#00a499"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5px"
        d="M27.148,49.577H23.275"
        transform="translate(-9.153 -24.408)"
      />
      <path
        fill="#e5f6f5"
        stroke="#00a499"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5px"
        d="M6.457,81.876a1.391,1.391,0,0,0-.682,1.2v20.086a1.391,1.391,0,0,0,1.391,1.391H65.6a1.391,1.391,0,0,0,1.391-1.391V83.064a1.391,1.391,0,0,0-.671-1.191C51.036,72.608,21.743,72.772,6.457,81.876Z"
        transform="translate(0 -37.697)"
      />
      <path
        fill="#e5f6f5"
        stroke="#00a499"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5px"
        d="M89.923,104.782,80.85,75.32"
        transform="translate(-39.266 -37.872)"
      />
      <path
        fill="#e5f6f5"
        stroke="#00a499"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5px"
        d="M110.775,102.361,89.39,76.084"
        transform="translate(-43.733 -38.271)"
      />
      <path
        fill="#e5f6f5"
        stroke="#00a499"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5px"
        d="M40.017,104.748,49.09,75.367"
        transform="translate(-17.909 -37.896)"
      />
      <path
        fill="#e5f6f5"
        stroke="#00a499"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5px"
        d="M6.154,102.361,27.587,76.084"
        transform="translate(-0.198 -38.271)"
      />
      <path
        fill="#e5f6f5"
        stroke="#00a499"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5px"
        d="M69.942,104.612V74.993"
        transform="translate(-33.561 -37.701)"
      />
      <path
        fill="#e5f6f5"
        stroke="#00a499"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5px"
        d="M42.76,41.466a13.912,13.912,0,1,1,23.85.067"
        transform="translate(-18.315 -9.159)"
      />
    </g>
  </svg>
);

export default Sun;
