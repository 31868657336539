import { SeasonalityFormData } from "../../types";

export const isSeasonalNull = (data: SeasonalityFormData) => {
  if (data === null) {
    return false;
  } else {
    const { seasonal = false } = data;
    return seasonal;
  }
};
