import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "specification.actions.duplicate.selectSupplierModal.title",
    defaultMessage: "Select supplier",
  },

  subtitle: {
    id: "specification.actions.duplicate.selectSupplierModal.subtitle",
    defaultMessage:
      "Please note that all supplier information from the original specification will be replaced.",
  },

  search: {
    id: "general.search",
    defaultMessage: "Search",
  },

  searchPlaceholder: {
    id:
      "specification.actions.duplicate.selectSupplierModal.search.placeholder",
    defaultMessage: "Search a name",
  },
  cancel: {
    id: "general.cancel",
    defaultMessage: "Cancel",
  },

  createSpecification: {
    id:
      "specification.actions.duplicate.selectSupplierModal.create.specifications",
    defaultMessage: "Create specifications",
  },

  createSpecificationCount: {
    id:
      "specification.actions.duplicate.selectSupplierModal.create.specifications.count",
    defaultMessage:
      "{count, plural, =0 {Create specification} one {Create specification (#)} other {Create specifications (#)}}",
  },
});
