import { useContext } from "react";
import { useIntl } from "react-intl";
import { Heading } from "@trace-one/design-system";
import Chapters from "components/Chapters";
import { LibraryDetails } from "pages/Library/components/Details/contexts";
import { LIBRARY_OBJECTS_PAGES } from "utils/constants";
import libraryMessages from "messages/library";
import styles from "./styles.module.less";

const TemplateDetails = () => {
  const { formatMessage } = useIntl();
  const { libraryItemDetails } = useContext(LibraryDetails);

  return (
    <div className={styles.templateDetails}>
      <div className={styles.structureHeading}>
        <Heading size="xs">
          {formatMessage(libraryMessages.templateStructureTitle)}
        </Heading>
      </div>
      <Chapters
        chapters={libraryItemDetails?.chapters}
        displayCounter={false}
        displayActions={false}
        page={LIBRARY_OBJECTS_PAGES.DETAILS}
      />
    </div>
  );
};

export default TemplateDetails;
