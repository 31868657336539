export const getModalTitle = ({
  defaultCharacteristicText,
  characteristicText,
  photosText,
}) => `${characteristicText || defaultCharacteristicText} - ${photosText}`;

export const getOnLinkFiles = ({ onChange, photos }) => files => {
  onChange([...photos, ...files.map(({ id }) => id)]);
};

export const getOnRemoveFile = ({ onChange, photos }) => deletedPhotoId => {
  onChange(photos.filter(fileId => fileId !== deletedPhotoId));
};

export const getOnRemoveFileV2 = ({ onChange, photos }) => deletedPhoto => {
  onChange(photos.filter(fileId => fileId !== deletedPhoto.id));
};
