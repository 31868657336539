import { useIntl } from "react-intl";
import { Col, Row } from "antd";
import { Icon, Typography } from "@trace-one/design-system";
import Spin from "components/Spin";
import TranslationCard from "./components/TranslationCard";
import { useOnGoingTranslations } from "./hooks";
import { TranslationData } from "models";
import { translationManagementMessages } from "messages";
import styles from "./styles.module.less";

export type OnGoingTranslationsProps = {
  translations: TranslationData[];
  isChapterLoading: boolean;
};

const OnGoingTranslations: React.FC<OnGoingTranslationsProps> = ({
  translations = [],
  isChapterLoading,
}) => {
  const { formatMessage } = useIntl();
  const translatorIds = [
    ...new Set(translations.map(({ translatorId }) => translatorId)),
  ];
  const {
    languageDictionary,
    companyDictionary,
    isLoading,
  } = useOnGoingTranslations({
    userIds: translatorIds,
  });

  if (isChapterLoading) {
    return null;
  }

  return (
    <div className={styles["on-going-translations"]}>
      {isLoading ? (
        <Spin className="mt-2 mb-2" />
      ) : (
        <>
          <Row align="middle">
            <Col>
              <Row align="middle">
                <Icon name="language" color="grey-5" />
                <Typography
                  variant="heading-xs"
                  color="grey-5"
                  className="ml-1"
                >
                  {formatMessage(
                    translationManagementMessages.onGoingTranslations
                  )}
                </Typography>
              </Row>
            </Col>
            <Col></Col>
          </Row>
          <Row className="mt-2 overflow-auto hide-scrollbar" wrap={false}>
            {translations.map(({ languageCode, progression, translatorId }) => (
              <TranslationCard
                language={languageDictionary[languageCode]}
                progression={progression}
                companyName={companyDictionary[translatorId]}
                key={languageCode}
              />
            ))}
          </Row>
        </>
      )}
    </div>
  );
};

export default OnGoingTranslations;
