import { defineMessages } from "react-intl";

export const messages = defineMessages({
  detailsAndLinksChapterModalTitle: {
    id: "specificationDetails.selectedChapter.detailsAndLinksChapterModalTitle",
    defaultMessage: "Chapter details and linked specifications",
  },
  detailsAndLinksChapterModalChapterName: {
    id:
      "specificationDetails.selectedChapter.detailsAndLinksChapterModalChapterName",
    defaultMessage: "Chapter name",
  },
  detailsAndLinksChapterModalChapterStatus: {
    id:
      "specificationDetails.selectedChapter.detailsAndLinksChapterModalChapterStatus",
    defaultMessage: "Status",
  },
  detailsAndLinksChapterModalChapterCreationDate: {
    id:
      "specificationDetails.selectedChapter.detailsAndLinksChapterModalChapterCreationDate",
    defaultMessage: "Creation date",
  },
});
