import { Card } from "@trace-one/react-components";
import { Heading } from "@trace-one/design-system";
import { FormattedMessage } from "react-intl";
import Quickview from "components/Quickview";
import ProductName from "components/ProductName";
import { createNameForProduct } from "utils/form";
import { messages } from "../../messages";
import { TradeItemListProps } from "./types";
import styles from "../../styles.module.less";

const TradeItemCard: React.FC<TradeItemListProps> = ({
  tradeItem,
  unitsOfMeasure,
}) => {
  const {
    tradeItemId,
    tradeItemName,
    tradeItemGtin,
    tradeItemSpecifications,
    tradeItemBrandName,
    tradeItemNetContents,
  } = tradeItem;

  return (
    <Card
      collapsible
      className={styles.card}
      title={
        <ProductName
          productName={createNameForProduct(
            {
              itemName: tradeItemName,
              brandName: tradeItemBrandName,
              netContents: tradeItemNetContents,
            },
            unitsOfMeasure
          )}
          productGtin={tradeItemGtin}
        />
      }
      key={tradeItemId}
    >
      <div className={styles.specificationsHead}>
        <Heading
          className={styles.specificationsTitle}
          size="xs"
          color="primary"
        >
          <FormattedMessage {...messages.specificationsTitle} />
        </Heading>
        <Quickview.Legend />
      </div>
      {tradeItemSpecifications.map(specification => (
        <Quickview.FolderRow
          key={specification.specificationId}
          specification={specification}
        />
      ))}
    </Card>
  );
};

export default TradeItemCard;
