import { defineMessages } from "react-intl";

export const messages = defineMessages({
  subtitle: {
    id: "specification.waiting.digitalSignature.subtitle",
    defaultMessage: "Waiting for digital signature",
  },
  title: {
    id: "specification.waiting.digitalSignature.title",
    defaultMessage: "Loading...",
  },
});
