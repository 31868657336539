import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { Stepper } from "@trace-one/design-system";
import { Title } from "@trace-one/react-components";
import { useAppDispatch } from "store";
import {
  fetchSpecificationTemplates,
  getTemplateById,
  setSpecificationTemplate,
  updateSpecificationDraftFiles,
} from "store/specificationCreation/asyncActions";
import {
  selectErrors,
  selectSpecification,
  selectSpecificationTemplate,
  selectWizard,
} from "store/specificationCreation/selectors";
import { selectLanguageCode } from "store/user/selectors";
import CreationWizzard from "components/CreationWizzard";
import Debounced from "components/Debounced";
import {
  useSteps,
  useCreationActions,
} from "pages/Specification/components/Creation/hooks";
import { messages } from "./messages";
import { documentMessages } from "messages";
import { Col, Row } from "antd";
import { useSpecificationChapters } from "hooks/useSpecificationChapters";
import { resetSpecificationErrors } from "store/specificationCreation/specificationCreationSlice";
import { LibraryObjectData } from "models";
import { isListEmpty } from "utils/general";
import SpecificationTemplate from "components/SpecificationTemplate";

const Specification = () => {
  const intl = useIntl();
  const { note, files, ownerCompanyId, productTypeId } = useSelector(
    selectSpecification
  );
  const wizard = useSelector(selectWizard);

  const {
    stepIndex,
    currentIsValid,
    steps,
    onPreviousStep,
    onNextStep,
  } = useSteps({
    currentStep: wizard.currentStep,
    stepsState: wizard.state,
  });

  const {
    onSpecificationDelete,
    onSpecificationNoteChange,
  } = useCreationActions();
  const dispatch = useAppDispatch();

  const template = useSelector(selectSpecificationTemplate);

  const languageCode = useSelector(selectLanguageCode);
  const { note: hasNoteError } = useSelector(selectErrors);

  const { sections: chaptersErrors } = useSelector(selectErrors);

  const onTemplateSelect = async (selectedTemplate: LibraryObjectData) => {
    await dispatch(setSpecificationTemplate(selectedTemplate.id));
    await dispatch(getTemplateById(selectedTemplate.id));
  };

  const { specChapters } = useSpecificationChapters({
    chapters: template?.chapters,
    productTypeId,
  });

  useEffect(() => {
    dispatch(resetSpecificationErrors());
  }, []);

  useEffect(() => {
    dispatch(fetchSpecificationTemplates());
  }, [languageCode]);

  return (
    <>
      <CreationWizzard.Title.Specification />
      <Stepper
        stepIndex={stepIndex}
        currentIsValid={currentIsValid}
        steps={steps}
      />

      <Row gutter={[0, 48]}>
        <Col span={24}>
          <SpecificationTemplate
            template={template}
            onTemplateSelect={onTemplateSelect}
            chaptersErrors={chaptersErrors}
            displayCounter={false}
            productTypeId={productTypeId}
          />
        </Col>
        <Col span={24}>
          <Title
            level={2}
            value={intl.formatMessage(documentMessages.documents)}
            subtitle={intl.formatMessage(
              documentMessages.creationDocumentSubtitle
            )}
          />
          <CreationWizzard.Files
            files={files}
            chapters={specChapters}
            onUpdate={updateSpecificationDraftFiles}
            ownerCompanyId={ownerCompanyId}
          />
        </Col>
      </Row>

      <Title
        level={2}
        value={intl.formatMessage(messages.note)}
        subtitle={intl.formatMessage(messages.noteDescription)}
      />

      <Debounced.Textarea
        defaultValue={note}
        onChange={onSpecificationNoteChange}
        {...(hasNoteError && {
          errorMessage: intl.formatMessage(messages.errorFieldMessage),
        })}
      />

      <CreationWizzard.Footer
        onBackClick={onPreviousStep}
        onNextClick={onNextStep}
        onCancel={onSpecificationDelete}
        isNextDisabled={isListEmpty(template?.chapters)}
      />
    </>
  );
};

export default Specification;
