import { createContext } from "react";
import { RequirementData } from "models";

export const SelectLibraryObjectsContext = createContext<{
  onSelectLibraryObject?: Function;
  selectedLibraryObjects: RequirementData[];
  setSelectedLibraryObjects?: Function;
  onClose?: Function;
  updateClaims?: Function;
  formData?: Array<any>;
}>({
  onSelectLibraryObject: () => {},
  selectedLibraryObjects: [],
  setSelectedLibraryObjects: () => {},
  onClose: () => {},
  updateClaims: () => {},
  formData: [],
});
