import { isStateDraft } from "utils/specifications";
import { SpecificationListItemViemModel } from "viewModels/specificationList";

export const getOnCellClick = ({
  specification,
  resumeSpecification,
  redirectToSpecificationDetails,
}: {
  specification: SpecificationListItemViemModel;
  resumeSpecification: Function;
  redirectToSpecificationDetails: Function;
}) => async () => {
  if (isStateDraft(specification.state)) {
    await resumeSpecification(specification.specificationId);

    return null;
  } else {
    redirectToSpecificationDetails(specification.specificationId);
  }
};
