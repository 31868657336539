import { defineMessages } from "react-intl";

export const messages = defineMessages({
  inRevision: {
    id: "status.warning.inRevisionSpecification",
    defaultMessage: "Your specification is in revision",
  },
  inRevisionBySupplier: {
    id: "status.warning.inRevisionBySupplier",
    defaultMessage: "The supplier is currently working on a revision request.",
  },
  inRevisionByRetailer: {
    id: "status.warning.inRevisionByRetailer",
    defaultMessage: "The retailer is currently working on a revision request.",
  },
});
