import { useIntl } from "react-intl";
import CategoryTreeSelect from "components/CategoryTreeSelect";
import { ProductTreeCategoriesProps } from "./types";
import { messages } from "./messages";
import { isObjectEmpty } from "utils/general";

export const ProductTreeCategories: React.FC<ProductTreeCategoriesProps> = ({
  onCategoryChange,
  productCategoryItem,
}) => {
  const { formatMessage } = useIntl();

  return (
    <CategoryTreeSelect
      shouldFirstLevelBeSelectable={false}
      name="productCategory"
      placeholder={formatMessage(messages.categoryPlaceholder)}
      onChange={() => {}}
      onFullChange={onCategoryChange}
      notFoundMessage={formatMessage(messages.noCategory)}
      data-testid="product-category"
      {...(!isObjectEmpty(productCategoryItem) && {
        value: {
          categoryItemId: productCategoryItem?.value as string,
        },
      })}
    />
  );
};

export default ProductTreeCategories;
