import { createSelector, createSlice } from "@reduxjs/toolkit";
import { TemplateData } from "models";
import { PURGE } from "redux-persist";
import { RootState } from "store";

export interface State extends TemplateData {
  isLoading?: boolean;
  userGuides?: {
    [key: string]: string;
  };
}

export const initialTemplateForm: State = {
  generalInfo: {
    id: null,
    name: null,
    productTypeId: null,
    tagIds: [],
    state: null,
    version: null,
  },
  chapters: [],
  isLoading: false,
  userGuides: {},
};

export const slice = createSlice({
  name: "templateFrom",
  initialState: initialTemplateForm,
  reducers: {
    setTemplateFormData: (state, action) => {
      state.chapters = action.payload?.chapters;
      state.generalInfo = action.payload?.generalInfo;
    },
    setTemplateFormIsLoading: (state, action) => {
      state.isLoading = action.payload?.isLoading;
    },
    setTemplateUserGuides: (state, action) => {
      state.userGuides = action.payload?.userGuides;
    },
  },
  extraReducers: builder => {
    builder.addCase(PURGE, () => ({ ...initialTemplateForm }));
  },
});

export const {
  setTemplateFormData,
  setTemplateFormIsLoading,
  setTemplateUserGuides,
} = slice.actions;

export const selectTemplateForm = (state: RootState) => state.templateForm;

export const selectTemplateGeneraInfo = createSelector(
  selectTemplateForm,
  templateForm => templateForm.generalInfo
);

export const selectTemplateChapters = createSelector(
  selectTemplateForm,
  templateForm => templateForm.chapters
);

export const selectTemplateStatus = createSelector(
  selectTemplateForm,
  templateForm => templateForm.isLoading
);

export default slice.reducer;
