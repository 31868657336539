import { defineMessages } from "react-intl";

export const messages = defineMessages({
  attachment: {
    id: "attachment",
    defaultMessage: "Attachment",
  },
  noAttachment: {
    id: "noAttachment",
    defaultMessage: "No attachment",
  },
  technicalDataSheet: {
    id: "technicalDataSheet",
    defaultMessage: "Technical data sheet",
  },
  otherDocuments: {
    id: "otherDocuments",
    defaultMessage: "Other documents",
  },
  documents: {
    id: "documents",
    defaultMessage: "Documents",
  },
  creationDocumentSubtitle: {
    id: "creation.document.subtitle",
    defaultMessage: "Share and ask for documents",
  },
});

export default messages;
