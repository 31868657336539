import { useAppDispatch } from "store";
import { setClaimFormFile } from "store/claimForm/claimFormSlice";

const useClaimLogo = (setLogoFile: Function = () => {}) => {
  const dispatch = useAppDispatch();

  const onSelect = ([file]) => {
    dispatch(
      setClaimFormFile({
        file: {
          id: file.fileId,
          fileName: file.fileName,
          fileSize: file.fileTotalLength,
        },
      })
    );
  };

  const onUploadFile = async ([file]) => {
    setLogoFile(file);

    dispatch(
      setClaimFormFile({
        file: {
          id: null,
          fileName: file.name,
          fileSize: file.size,
        },
      })
    );
  };

  const onRemove = () => {
    setLogoFile(null);
    dispatch(
      setClaimFormFile({
        file: {
          id: undefined,
          fileName: undefined,
          fileSize: undefined,
        },
      })
    );
  };

  return { onSelect, onUploadFile, onRemove };
};

export default useClaimLogo;
