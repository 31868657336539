import { Paragraph } from "@trace-one/design-system";
import { Row, Col } from "antd";
import { isListEmpty, isObjectEmpty } from "utils/general";
import { NetContentsListingProps } from "../../../NetContentsListing/types";
import styles from "./styles.module.less";

const NetContentsListingReadOnly: React.FC<NetContentsListingProps> = ({
  netContents,
  title,
  refListDictionary,
}) => (
  <>
    <Row className={styles.netContentsRowReadOnly}>
      <Col className={styles.netContentsTitle}>
        <Paragraph size="xs" color="grey-5">
          {title}
        </Paragraph>
      </Col>
      {!isListEmpty(netContents) &&
        !isObjectEmpty(refListDictionary) &&
        netContents.map(netContent => (
          <Col
            key={netContent.unitOfMeasure}
            className={styles.netContentsValue}
          >
            <Paragraph size="xs" color="grey-5">{`${netContent.value} - ${
              refListDictionary[netContent.unitOfMeasure]
            }`}</Paragraph>
          </Col>
        ))}
    </Row>
  </>
);

export default NetContentsListingReadOnly;
