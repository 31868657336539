import { useMemo } from "react";
import { Col } from "antd";
import { Tag } from "@trace-one/design-system";
import { useCategoryItems } from "hooks";
import { isListEmpty, isObjectEmpty } from "utils/general";
import { CategoriesListingProps } from "./types";

const CategoriesListing: React.FC<CategoriesListingProps> = ({
  categories,
  title,
}) => {
  const categoryItemsIds = useMemo(() => {
    if (isListEmpty(categories)) {
      return [];
    }

    return categories.reduce((previousState, { categoryItemId }) => {
      let newState = [...previousState];

      if (categoryItemId) {
        newState.push(categoryItemId);
      }

      return newState;
    }, []);
  }, [categories]);

  const { categoryItemsDictionary } = useCategoryItems({
    categoryItemsIds,
  });

  return (
    <>
      {!isListEmpty(categories) &&
        !isObjectEmpty(categoryItemsDictionary) &&
        categories.map(({ categoryId, categoryItemId }) => {
          return (
            <Col key={categoryItemId}>
              <Tag
                label={`${title}: ${categoryItemsDictionary[categoryId]} - ${categoryItemsDictionary[categoryItemId]}`}
                size="medium"
                color="grey"
                mode="light"
              />
            </Col>
          );
        })}
    </>
  );
};

export default CategoriesListing;
