import { useEffect, useState } from "react";
import { LabeledValue, OptionProps } from "antd/lib/select";
import { useSelector } from "react-redux";
import { useDebounce } from "@trace-one/business-components";
import { fetchReferenceListsItem, fetchReferenceListsItems } from "apis/RLMD";
import { useAppDispatch } from "store";
import { selectLanguageCode } from "store/user/selectors";
import { updateRefListDictionary } from "store/generalData/asyncActions";
import { getCurrentValueFromValue } from "utils/select";
import { isListEmpty, isObjectEmpty } from "utils/general";
import { Dictionary } from "types/general";

const useReflistSelect = ({
  defaultValue,
  value,
  defaultLabelInValue,
  defaultOnBlur,
  defaultOnChange,
  defaultOnDeselect,
  defaultOnSelect,
  defaultOnSearch,
  defaultOnDropdownVisibleChange,
  mode,
  shouldCallApi = true,
  refListDictionary,
}: {
  defaultValue: string | string[];
  value: string | string[];
  defaultLabelInValue?: boolean;
  defaultOnBlur?: Function;
  defaultOnChange?: Function;
  defaultOnDeselect?: Function;
  defaultOnSelect?: Function;
  defaultOnSearch?: Function;
  defaultOnDropdownVisibleChange?: Function;
  mode?: string;
  shouldCallApi?: boolean;
  refListDictionary?: Dictionary;
}) => {
  const dispatch = useAppDispatch();
  const [focus, setFocus] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [valueWithLabel, setValueWithLabel] = useState<
    LabeledValue | LabeledValue[]
  >();
  const languageCode = useSelector(selectLanguageCode);

  const debouncedSearchText = useDebounce(searchText, 1000);

  const refreshSearchTerm = () => {
    setSearchText(previousState => {
      if (!!previousState) {
        setFocus(false);
      }
      return "";
    });
  };

  const updateDictionary = value => {
    if (value) {
      dispatch(
        updateRefListDictionary({
          [value.value]: value.label,
        })
      );
    }
  };

  const setSelectValue = (item, action = "add") => {
    refreshSearchTerm();

    updateDictionary(item);

    if (mode === "multiple") {
      if (action === "deselect") {
        setValueWithLabel((previousState: LabeledValue[]) => {
          /* This check is for the case when we deselect the last item from list */
          if (previousState.length === 1) {
            return [];
          }

          return previousState.filter(
            currentItem => currentItem.value !== item.value
          );
        });
      } else {
        setValueWithLabel((previousState: LabeledValue[]) => {
          /* This check is for the case when we first add an item to the list. Because the list is undefined first we cannot spread it. */
          if (!previousState) return [item];

          return [...previousState, item];
        });
      }
    } else {
      setValueWithLabel(item);
    }
  };

  const onSelectChange = (callback: Function, action = "add") => (
    value,
    option: OptionProps
  ) => {
    if (typeof callback === "function") {
      setSelectValue(value, action);

      if (defaultLabelInValue) {
        callback(value, option);
      } else {
        callback(value?.value, option);
      }
    }
  };

  const onSelect = onSelectChange(defaultOnSelect);

  const onDeselect = onSelectChange(defaultOnDeselect, "deselect");

  const onChange = (value, option: OptionProps) => {
    if (typeof defaultOnChange === "function") {
      setValueWithLabel(value);

      updateDictionary(value);

      if (mode === "multiple") {
        defaultOnChange(value, option);
      } else {
        if (defaultLabelInValue) {
          defaultOnChange(value, option);
        } else {
          defaultOnChange(value?.value, option);
        }
      }
    }
  };

  const onSearch = (searchTerm: string) => {
    if (typeof defaultOnSearch === "function") {
      defaultOnSearch(searchTerm);
    }

    setSearchText(searchTerm);
  };

  const onDropdownVisibleChange = (open: boolean) => {
    if (typeof defaultOnDropdownVisibleChange === "function") {
      defaultOnDropdownVisibleChange(open);
    }

    if (open) {
      setFocus(true);
    }
  };

  const onBlur = props => {
    if (typeof defaultOnBlur === "function") {
      defaultOnBlur(props);
    }

    refreshSearchTerm();
  };

  useEffect(() => {
    if (!value && valueWithLabel) {
      if (mode === "multiple") {
        setValueWithLabel([]);
      } else {
        setValueWithLabel(undefined);
      }
    }
  }, [value, valueWithLabel]);

  useEffect(() => {
    const itemValue = defaultValue || value;

    const getItem = async () => {
      try {
        if (Array.isArray(itemValue)) {
          if (
            !isListEmpty(itemValue) &&
            (!(valueWithLabel as LabeledValue[])?.length ||
              itemValue.length > (valueWithLabel as LabeledValue[]).length)
          ) {
            const { data } = await fetchReferenceListsItems({
              languageCode,
              ids: itemValue.map(getCurrentValueFromValue),
            });

            setValueWithLabel(
              data.map(({ id, text }) => ({
                key: id,
                value: id,
                label: text,
              })) as LabeledValue[]
            );
          }
        } else {
          if (itemValue !== (valueWithLabel as LabeledValue)?.value) {
            const { data } = await fetchReferenceListsItem({
              languageCode,
              id: getCurrentValueFromValue(itemValue),
            });

            setValueWithLabel({
              key: data.id,
              value: data.id,
              label: data.text,
            });
          }
        }
      } catch (_) {}
    };

    // optimizing not to make call with shouldCallApi
    if (itemValue && shouldCallApi !== false) {
      getItem();
    }
  }, [languageCode, value]);

  useEffect(() => {
    // optimizing to set values with refListDictionary
    if (shouldCallApi === false && !isObjectEmpty(refListDictionary) && value) {
      if (Array.isArray(value)) {
        setValueWithLabel(
          value.map(currentValue => ({
            key: currentValue,
            value: currentValue,
            label: refListDictionary[currentValue],
          }))
        );
      } else {
        setValueWithLabel({
          key: value as string,
          value: value as string,
          label: refListDictionary[value as string],
        });
      }
    }
  }, [JSON.stringify(refListDictionary), JSON.stringify(value), shouldCallApi]);

  return {
    focus,
    valueWithLabel,
    searchText: debouncedSearchText,
    getCurrentValueFromValue,
    onBlur,
    onChange,
    onDeselect,
    onSelect,
    onSearch,
    onDropdownVisibleChange,
  };
};

export default useReflistSelect;
