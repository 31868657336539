/* istanbul ignore file */

import axios from "axios";
import { CollaborationItemData } from "models";

export const axiosInstance = axios.create({
  baseURL: window.env.CLB_URL,
});

export const createCollaboration = ({
  collaboratingCompanyId,
  collaboratingUserId,
  collaboratingUserEmail,
  customMessage,
  applicationId,
  parentDataObjectId,
  parentDataObjectSystem,
  roleIds,
  responsibilityIds,
}: {
  collaboratingCompanyId: string;
  collaboratingUserId?: string;
  collaboratingUserEmail?: string;
  customMessage?: string;
  applicationId: number;
  parentDataObjectId: string;
  parentDataObjectSystem: string;
  roleIds: string[];
  responsibilityIds: string[];
}) =>
  axiosInstance.post<{ collaborationId: string }>(`/api/collaborations`, {
    collaboratingCompanyId,
    collaboratingUserId,
    collaboratingUserEmail,
    customMessage,
    applicationId,
    parentDataObjectId,
    parentDataObjectSystem,
    roleIds,
    responsibilityIds,
    offerIds: [],
  });

export const fetchCollaboration = (collaborationId: string) =>
  axiosInstance.get<CollaborationItemData>(
    `/api/collaborations/${collaborationId}`
  );

export const fetchCollaborationsByIds = ({ ids }: { ids: string[] }) =>
  axiosInstance.post<CollaborationItemData[]>(
    `/api/collaborations/collections`,
    {
      idCollection: ids,
    }
  );

export const removeCollaboration = (collaborationId: string) =>
  axiosInstance.delete<void>(`/api/collaborations/${collaborationId}`);
