import { defineMessages } from "react-intl";

export const messages = defineMessages({
  specificationsTitle: {
    id: "folderDetails.specifications.title",
    defaultMessage: "Supplier",
  },
  tradeItemListError: {
    id: "folderDetails.tradeItems.error",
    defaultMessage:
      "Sorry, an error occurred : we were unable to retrieve the products for this folder",
  },
});
