import { useIntl } from "react-intl";
import { Icon, Typography, Button } from "@trace-one/design-system";
import { Col, Row } from "antd";
import { LabeledValue } from "antd/lib/select";
import { generalMessages, translationManagementMessages } from "messages";
import { CompanySelect, ConfirmationTooltip, LanguageSelect } from "components";
import { CompanyLangaugeBlock } from "components/TranslationManagement/types";

export type CompanyLanguageCardProps = {
  index: number;
  companyBlock: CompanyLangaugeBlock;
  onCompanySelect: (selectedCompaney: LabeledValue) => void;
  onCompanyClear?: () => void;
  excludedCompanies: string[];
  onRemove: () => void;
  onLanguageSelect: (value: LabeledValue) => void;
  onLanguageDeselect: (value: LabeledValue) => void;
  excludedLanguages: string[];
  onLanguagesClear?: () => void;
};

const CompanyLanguageCard: React.FC<CompanyLanguageCardProps> = ({
  index,
  companyBlock,
  onCompanySelect,
  onCompanyClear,
  excludedCompanies,
  onRemove,
  onLanguageSelect,
  onLanguageDeselect,
  excludedLanguages,
  onLanguagesClear,
}) => {
  const { formatMessage } = useIntl();

  return (
    <div>
      <Row className="mt-3" align="middle">
        <Col sm={4}>
          <Row>
            <Typography variant="heading-xxs" color="grey-5">
              {formatMessage(generalMessages.company)}
            </Typography>
          </Row>
        </Col>
        <Col sm={8}>
          <CompanySelect
            type="translators"
            onSelect={onCompanySelect}
            excludedValues={excludedCompanies.filter(
              companyValue => companyValue !== companyBlock?.company?.value
            )}
            onClear={onCompanyClear}
            placeholder={formatMessage(
              translationManagementMessages.translationCompanySearchPlaceholder
            )}
            notFoundMessage={formatMessage(generalMessages.noItemFound)}
            values={companyBlock?.company}
            ignoreApplicationTypeIds
          />
        </Col>
        {index > 0 && (
          <Col sm={4}>
            <ConfirmationTooltip
              key={companyBlock?.key}
              onConfirmation={onRemove}
              text={formatMessage(
                translationManagementMessages.removeCompanyConfirmation
              )}
              cancelButtonText={formatMessage(generalMessages.cancel)}
              confirmButtonText={formatMessage(generalMessages.ok)}
            >
              <Button type="tertiary" iconName="trash" color="red"></Button>
            </ConfirmationTooltip>
          </Col>
        )}
      </Row>
      <Row className="mt-3">
        <Col sm={4}>
          <Row className="ai-baseline ">
            <Icon name="hierarchy-link" size="large" color="grey-5" />
            <Typography variant="heading-xxs" color="grey-5" className="ml-1">
              {formatMessage(generalMessages.languages)}
            </Typography>
          </Row>
        </Col>
        <Col sm={8}>
          <LanguageSelect
            onSelect={onLanguageSelect}
            onDeselect={onLanguageDeselect}
            excludedLanguages={excludedLanguages}
            onClear={onLanguagesClear}
            values={companyBlock?.languages}
          />
        </Col>
      </Row>
    </div>
  );
};

export default CompanyLanguageCard;
