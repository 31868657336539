/* istanbul ignore file */
import SelectSupplierModal from "components/SelectSupplierModal";
import { useSelectSuppliersModal } from "./hooks";

const withDuplicateSpecificationAction = Component => props => {
  const { specification, reloadListing } = props;

  const {
    isModalOpen,
    onOpenSelectSuppliersModal,
    onCloseSelectSuppliersModal,
  } = useSelectSuppliersModal();
  return (
    <>
      <Component
        {...props}
        onOpenSelectSupplierModal={onOpenSelectSuppliersModal}
      />
      {isModalOpen && (
        <SelectSupplierModal
          specificationId={specification.specificationId}
          tradeItemId={specification.tradeItemId}
          tradeItemName={specification.tradeItemName}
          closeSelectSupplierModal={onCloseSelectSuppliersModal}
          reloadListing={reloadListing}
        />
      )}
    </>
  );
};

export default withDuplicateSpecificationAction;
