import { createSelector } from "@reduxjs/toolkit";

export const _selectSpecificationDetails = state => state.specificationDetails;

export const selectIsRevisionLoading = ({
  specificationDetails: { isRevisionLoading },
}) => isRevisionLoading;

export const selectRequestModification = ({
  specificationDetails: { selectedModificationRequest },
}) => selectedModificationRequest;

export const selectIsRequestModificationEditorOpen = ({
  specificationDetails: { selectedModificationRequest },
}) => selectedModificationRequest?.isEditorOpen;

export const selectRequestModificationDetail = ({
  specificationDetails: { selectedModificationRequest },
}) => selectedModificationRequest?.detail;

export const selectRevisedSpecification = ({
  specificationDetails: { revisedSpecificationId },
}) => revisedSpecificationId;

export const selectSelectedSection = ({
  specificationDetails: { selectedSection },
}) => selectedSection;

export const selectSelectedSectionTemplateBlockLastChanges = templateBlockId => ({
  specificationDetails: { selectedSectionLastChanges },
}) => selectedSectionLastChanges[templateBlockId] || {};

export const selectSectionValidationDisabled = sectionId => ({
  specificationDetails: { validationDisabled },
}) => {
  if (!sectionId) return validationDisabled;

  return !!validationDisabled?.[sectionId];
};

export const selectSectionSavingInProgress = sectionId => ({
  specificationDetails: { sectionSavingInProgress },
}) => !!sectionSavingInProgress?.[sectionId];

export const selectCompanyName = ({ specificationDetails: { companyName } }) =>
  companyName;

export const selectSpecificationPermissions = ({
  specificationDetails: { specificationPermissions },
}) => specificationPermissions;

export const selectIsSpecificationUpdating = createSelector(
  _selectSpecificationDetails,
  specificationDetails => specificationDetails.isUpdating
);

export const selectModificationRequests = createSelector(
  _selectSpecificationDetails,
  speciicationDetails => speciicationDetails.modificationRequests
);

export const selectIsRequestPanelOpen = createSelector(
  _selectSpecificationDetails,
  specificationDetails => specificationDetails.isRequestPanelOpen
);

export const selectSelectedModificationRequestDiscussion = createSelector(
  selectRequestModification,
  requestModification => requestModification.discussion
);

export const selectTranslationManagement = createSelector(
  _selectSpecificationDetails,
  specificationDetails => specificationDetails.translationManagement
);
