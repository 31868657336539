import { defineMessages } from "react-intl";

const messages = defineMessages({
  cardTitle: {
    id: "library.rawMaterialForm.recipeTable.cardTile",
    defaultMessage: "Recipe table",
  },

  noIngredient: {
    id: "library.rawMaterialForm.recipeTable.noIngredient",
    defaultMessage: "No ingredient",
  },

  addAction: {
    id: "library.rawMaterialForm.recipeTable.actions.add",
    defaultMessage: "Add an ingredient",
  },
});

export default messages;
