import { createContext } from "react";
import { AllergensArrayFieldTemplateProviderProps } from "./types";

export const AllergensArrayFieldTemplateContext = createContext(null);
export const AllergensArrayFieldTemplateDispatchContext = createContext(null);

export const AllergensArrayFieldTemplateProvider = ({
  children,
  updateChapterCCAllergenRiskAction,
  catalogDictionary,
  allergenControlledRiskDictionary,
}: AllergensArrayFieldTemplateProviderProps) => {
  return (
    <AllergensArrayFieldTemplateContext.Provider
      value={{
        updateChapterCCAllergenRiskAction,
        catalogDictionary,
        allergenControlledRiskDictionary,
      }}
    >
      <AllergensArrayFieldTemplateDispatchContext.Provider value={() => {}}>
        {children}
      </AllergensArrayFieldTemplateDispatchContext.Provider>
    </AllergensArrayFieldTemplateContext.Provider>
  );
};
