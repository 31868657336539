import { Heading, Paragraph } from "@trace-one/design-system";
import { FormattedMessage } from "react-intl";
import dayjs from "dayjs";
import { messages } from "./messages";
import { TargetDateProps } from "./types";
import styles from "../../styles.module.less";

const TargetDate: React.FC<TargetDateProps> = ({ targetDateUtc }) => {
  return (
    <>
      <Heading className={styles.title} size="xs">
        <FormattedMessage {...messages.targetDate} />
      </Heading>
      <Paragraph color="grey-3">
        {dayjs(targetDateUtc).isValid()
          ? dayjs(targetDateUtc).format("DD MMMM YYYY")
          : ""}
      </Paragraph>
    </>
  );
};

export default TargetDate;
