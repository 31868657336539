import { useContext } from "react";
import { useIntl } from "react-intl";
import { Button } from "@trace-one/design-system";
import { useTranslationManagement } from "components/TranslationManagement/hooks";
import { TranslationManagementContext } from "components/TranslationManagement/contexts";
import { generalMessages, translationManagementMessages } from "messages";
import styles from "../../styles.module.less";

const TranslationManagementFooter: React.FC = () => {
  const { formatMessage } = useIntl();

  const {
    specificationId,
    translationTemplate,
    setTranslationTemplate,
    previousTranslationTemplate,
    setPreviousTranslationTemplate,
    shouldDeleteOnCancel,
    setSpecificationLabelingTranslationData,
    onCancel: onCloseTranslationMgmtOverlay,
    validatedByRetailer,
  } = useContext(TranslationManagementContext);

  const {
    isCancelling,
    onCancel,
    isSaving,
    onSave,
    isChecked,
    isIndeterminate,
    isSaveTranslationDisabled,
    isStartingTranslations,
    onStartTranslations,
  } = useTranslationManagement({
    specificationId,
    onCloseTranslationMgmtOverlay,
    translationTemplate,
    setTranslationTemplate,
    setPreviousTranslationTemplate,
    previousTranslationTemplate,
    shouldDeleteOnCancel,
    setSpecificationLabelingTranslationData,
  });

  const disableSaveButton =
    isSaveTranslationDisabled || (!isChecked && !isIndeterminate);

  const disableStartButton =
    isSaveTranslationDisabled ||
    (!isChecked && !isIndeterminate) ||
    !validatedByRetailer;

  return (
    <div className={styles["translation-management-footer"]}>
      <div className="ml-7">
        <Button
          type="tertiary"
          color="red"
          loading={isCancelling}
          onClick={onCancel}
        >
          {formatMessage(generalMessages.cancel)}
        </Button>
      </div>

      <div className={styles["translation-management-footer--right"]}>
        <Button
          type="secondary"
          loading={isSaving}
          onClick={onSave}
          disabled={disableSaveButton || isStartingTranslations}
        >
          {formatMessage(generalMessages.saveAndClose)}
        </Button>
        <Button
          type="primary"
          loading={isStartingTranslations}
          onClick={onStartTranslations}
          disabled={disableStartButton || isSaving}
        >
          {formatMessage(translationManagementMessages.startTranslation)}
        </Button>
      </div>
    </div>
  );
};

export default TranslationManagementFooter;
