import { useAppDispatch } from "store";
import { updateAttachments } from "store/materialForm/asyncActions";
import { File } from "models";

const useMaterialDocumentsUpload = ({
  fileIds = [],
}: {
  fileIds: string[];
}) => {
  const dispatch = useAppDispatch();

  const onUploadFiles = async (files: File[]) => {
    const selectedFileIds = (files ?? []).map(({ id }) => id);

    await dispatch(updateAttachments([...fileIds, ...selectedFileIds]));
  };

  const onRemoveFile = async (file: File) => {
    const filteredFileIds = fileIds?.filter(fileId => fileId !== file?.id);
    await dispatch(updateAttachments(filteredFileIds));
  };

  return { onUploadFiles, onRemoveFile };
};

export default useMaterialDocumentsUpload;
