import { getLastParentIngredients } from "utils/material";
import { MaterialRecipeIngredientViewModel } from "viewModels";

export const getLastAddedIngredientId = (
  ingredients: MaterialRecipeIngredientViewModel[],
  parentIdsPath: string[] = []
) => {
  const parentIngredients = getLastParentIngredients(
    ingredients,
    parentIdsPath
  );

  return parentIngredients.slice(-1)?.[0]?.ingredientId || null;
};
