import { createContext } from "react";
import { HistorisationContextProps } from "../types";

export const HistorisationContext = createContext<HistorisationContextProps>({
  specificationId: null,
  chapters: [],
  filterTags: {
    chapters: [],
  },
  selectedFilters: {
    dates: [],
    actions: [],
    users: [],
    chapters: [],
  },
  areFiltersDisabled: false,
  actions: [],
  isLoadingActions: false,
  hasErrorActions: false,
});
