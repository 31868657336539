import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  selectUserApplications,
  selectLanguageCode,
} from "store/user/selectors";
import { APPLICATION_TYPE_IDS, HELP_CENTER_URLS } from "utils/constants";

const useErrorPage = () => {
  const availableApps = useSelector(selectUserApplications);
  const languageCode = useSelector(selectLanguageCode);
  const [helpCenterUrl, setHelpCenterUrl] = useState(
    HELP_CENTER_URLS[languageCode]
  );
  const [isAppAllowed, setIsAppAllowed] = useState(false);
  const [tonUrl, setTonUrl] = useState("");

  useEffect(() => {
    const currentIsAppAllowed = availableApps.reduce(
      (previousState, { applicationTypeId, getEndpointUrl }) => {
        if (applicationTypeId === APPLICATION_TYPE_IDS.TON) {
          setTonUrl(getEndpointUrl);
        }

        if (applicationTypeId === APPLICATION_TYPE_IDS.SPEC) {
          return true;
        }

        return previousState;
      },
      isAppAllowed
    );

    setIsAppAllowed(currentIsAppAllowed);
  }, [availableApps]);

  useEffect(() => {
    setHelpCenterUrl(HELP_CENTER_URLS[languageCode]);
  }, [languageCode]);

  const onNotAllowedButtonPressed = () => {
    window.location.href = tonUrl;
  };

  return {
    isAppAllowed,
    onNotAllowedButtonPressed,
    helpCenterUrl,
  };
};

export default useErrorPage;
