import ConfirmationModal from "components/ConfirmationModal";
import { useConfirmEditModal } from "./hooks";
import { ConfirmImpactSpecificationModalProps } from "./types";

const ConfirmEditModal: React.FC<ConfirmImpactSpecificationModalProps> = ({
  libraryItemState,
  libraryItemType,
  title,
  children,
}) => {
  const {
    isModalVisible,
    confirmModalHandler,
    closeModalHandler,
  } = useConfirmEditModal({
    libraryItemState,
    libraryItemType,
  });

  return (
    <>
      {isModalVisible && (
        <ConfirmationModal
          onConfirmModal={confirmModalHandler}
          onCloseModal={closeModalHandler}
          title={title}
        >
          {children}
        </ConfirmationModal>
      )}
    </>
  );
};

export default ConfirmEditModal;
