import { useContext } from "react";
import { useIntl } from "react-intl";
import { Row } from "antd";
import {
  Illustration,
  Paragraph,
  Button,
  Heading,
} from "@trace-one/design-system";
import {
  LIBRARY_API_PARAMS_VERSION,
  LIBRARY_OBJECT_STATES,
  LIBRARY_OBJECT_TYPES,
  PERMISSIONS,
} from "utils/constants";
import { isObjectEmpty } from "utils/general";
import Modal from "components/Modal";
import Library from "components/Library";
import StepTitle from "components/StepTitle";
import { useLinkChapter } from "../../../../hooks";
import { SelectedChapterContext } from "pages/Specification/components/Details/components/SelectedChapter/contexts";
import { messages } from "../../../../messages";
import { useRbac } from "hooks";
import styles from "./styles.module.less";

const LinkChapter = () => {
  const { formatMessage } = useIntl();

  const {
    chapterType,
    specificationId,
    productTypeId,
    refetchChapterWithLoading,
    specificationPermissions,
    isTargetMode,
  } = useContext(SelectedChapterContext);

  const { hasPermission } = useRbac(specificationPermissions);

  const {
    onChapterSelect,
    onLinkChapterToSpecification,
    onBrowseClick,
    onClose,
    isOpen,
    selectedChapter,
  } = useLinkChapter({
    specificationId,
    chapterType,
    afterLinkCallback: refetchChapterWithLoading,
  });

  return (
    <>
      <div className={styles.emptyChapter}>
        <Illustration name="link" color="grey" />
        <Paragraph>{formatMessage(messages.selectedChapterInfo)}</Paragraph>
        <Row className={styles.topSpace} justify="end">
          <Button
            onClick={onBrowseClick}
            disabled={
              !hasPermission(PERMISSIONS.SPECIFICATION.UPDATE) || isTargetMode
            }
          >
            {formatMessage(messages.browseLibrary)}
          </Button>
        </Row>
      </div>
      {isOpen && (
        <Modal onClose={onClose}>
          <StepTitle
            title={formatMessage(messages.linkChapterModalTitle)}
            subtitle={formatMessage(messages.linkChapterModalSubtitle)}
          />
          <div className={styles.bottomSpace}>
            <Heading size="xxs">
              {formatMessage(messages.selectChapter)}
            </Heading>
          </div>
          <Library.List
            type={LIBRARY_OBJECT_TYPES.CHAPTERS}
            onItemClick={onChapterSelect}
            areItemsClickable
            displayActions={false}
            hasCreateButton={false}
            hasSwitchView={false}
            hideChapterFilters={true}
            extraParams={{
              productTypeId,
              chapterTypes: [chapterType],
              states: [
                LIBRARY_OBJECT_STATES.PUBLISHED,
                LIBRARY_OBJECT_STATES.LOCKED,
              ],
              version: LIBRARY_API_PARAMS_VERSION.activeVersion,
            }}
            {...(selectedChapter && {
              selectedLibraryItems: [selectedChapter],
            })}
            extraActionComponent={
              <Row justify="end">
                <Button
                  onClick={onLinkChapterToSpecification}
                  disabled={isObjectEmpty(selectedChapter)}
                >
                  {formatMessage(messages.linkToSpecification)}
                </Button>
              </Row>
            }
          />
        </Modal>
      )}
    </>
  );
};

export default LinkChapter;
