import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fetchCompany } from "apis/CUMD";
import { fetchSpecification } from "apis/SPEC";
import { selectLanguageCode } from "store/user/selectors";
import { useUserInfo } from "hooks";
import { SpecificationData } from "models";
import { UseSpecificationDetailsProps } from "./types";

const useSpecificationDetails = ({
  specificationId,
}: UseSpecificationDetailsProps) => {
  const [specification, setSpecification] = useState<SpecificationData>({});
  const [companyName, setCompanyName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(true);

  const languageCode = useSelector(selectLanguageCode);

  const { isRetailer } = useUserInfo();

  const getSpecification = (withLoading: boolean) => async () => {
    try {
      setIsLoading(withLoading);
      setHasError(false);

      const { data } = await fetchSpecification({
        id: specificationId,
        languageCode,
      });

      setSpecification(data);
    } catch (e) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getSpecification(true)();
  }, [specificationId]);

  useEffect(() => {
    const getCompanyName = async (id: string) => {
      try {
        const {
          data: { companyDisplayName },
        } = await fetchCompany({
          id,
        });

        setCompanyName(companyDisplayName);
      } catch (e) {}
    };

    if (isRetailer) {
      if (specification.supplierId) {
        getCompanyName(specification.supplierId);
      }
    } else {
      if (specification.ownerCompanyId) {
        getCompanyName(specification.ownerCompanyId);
      }
    }
  }, [specification.supplierId, specification.ownerCompanyId]);

  return {
    specification,
    companyName,
    isLoading,
    hasError,
    refetchSpecification: getSpecification(false),
  };
};

export default useSpecificationDetails;
