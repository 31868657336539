import { MATERIAL_TYPES } from "utils/constants";
import { isListEmpty } from "utils/general";
import { getLastParentIngredients } from "utils/material";
import { prepareFormState } from "./utils";
import { State } from "./types";

const reducers = {
  setFormData: (state: State, action) => {
    prepareFormState(state, action.payload);
  },

  addNewIngredientLine: (state: State, action: any) => {
    const { parentIdsPath, ...ingredient } = action.payload;

    const newLine = {
      ingredientId: "new-ingredient-id",
      type: MATERIAL_TYPES.RAW_MATERIAL,
      percentage: null,
      ...ingredient,
      parentIdsPath,
    };

    if (!isListEmpty(parentIdsPath)) {
      const parentIngredients = getLastParentIngredients(
        state.recipe.ingredients,
        parentIdsPath
      );

      parentIngredients.push(newLine);
    } else {
      state.recipe.ingredients = [...state.recipe.ingredients, newLine];
    }
  },

  removeLastIngredient: (state: State, action: any) => {
    const parentIdsPath = action.payload;

    if (!isListEmpty(state.recipe.ingredients)) {
      const parentIngredients = getLastParentIngredients(
        state.recipe.ingredients,
        parentIdsPath
      );

      parentIngredients.pop();
    }
  },
};

export default reducers;
