import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "store";
import { ClaimFile, ClaimSentenceTranslation } from "models/claim";
import { TagData } from "models";
import { createClaimTag, uploadClaimLogoFile } from "./asyncActions";

export interface ReduxClaimType {
  generalInfo: {
    id: string;
    name: string;
    productTypeId: string;
    claimType: string;
    tags: TagData[] | string[];
    requiresSupportingDocuments: boolean;
  };
  sentenceTranslations: ClaimSentenceTranslation[];
  file: ClaimFile;
}

export const initialClaimForm: ReduxClaimType = {
  generalInfo: {
    id: null,
    name: null,
    productTypeId: null,
    claimType: null,
    tags: null,
    requiresSupportingDocuments: false,
  },
  sentenceTranslations: [],
  file: {},
};

export const slice = createSlice({
  name: "claimForm",
  initialState: initialClaimForm,
  reducers: {
    setClaimFormData: (state, action) => {
      state.generalInfo = action.payload?.generalInfo;
      state.sentenceTranslations = action.payload?.sentenceTranslations;
    },
    setClaimFormFile: (state, action) => {
      state.file = action.payload?.file;
    },
  },
  extraReducers: builder => {
    /* istanbul ignore next*/
    builder.addCase(createClaimTag.fulfilled, (state, action) => {
      state.generalInfo.tags = state?.generalInfo?.tags?.map(tag => {
        if (action?.payload?.tagText === tag?.tagText) {
          return { ...tag, tagId: action?.payload?.tagId };
        }
        return tag;
      });
    });

    builder.addCase(uploadClaimLogoFile.fulfilled, (state, action) => {
      state.file = {
        ...state.file,
        id: action?.payload?.fileId,
      };
    });
  },
});

export const selectClaimForm = (state: RootState) => state.claimForm;

export const { setClaimFormData, setClaimFormFile } = slice.actions;

export default slice.reducer;
