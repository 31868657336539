import { isListEmpty } from "utils/general";
import { ActionState } from "./types";

export const prepareTradeItemHelper = ({
  netContentUnits,
  categories,
}) => currentItem => {
  let unit;
  let category;
  let netContentUnitText = null;
  let categoryName = null;

  if (!isListEmpty(netContentUnits)) {
    unit = netContentUnits.find(({ id }) => id === currentItem.netContentUnit);
    if (unit) {
      netContentUnitText = unit.text;
    }
  }

  if (!isListEmpty(categories)) {
    category = categories.find(
      ({ categoryItemId }) =>
        currentItem.category &&
        categoryItemId === currentItem.category.categoryItemId
    );

    if (category) {
      categoryName = category.categoryItemName;
    }
  }

  return {
    ...currentItem,
    categoryName,
    netContentUnitText,
  };
};

export const handleActionPending = (actionState: ActionState) => {
  actionState.inProgress = true;
  actionState.isDone = false;
};

export const handleActionFulfilled = (actionState: ActionState) => {
  actionState.inProgress = false;
  actionState.isDone = true;
  actionState.hasError = false;
};

export const handleActionRejected = (actionState: ActionState) => {
  actionState.inProgress = false;
  actionState.isDone = true;
  actionState.hasError = true;
};
