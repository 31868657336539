import { SPECIFICATION_STATE } from "utils/constants";
import {
  isStateDraft,
  isStateInRevisionBySupplier,
} from "utils/specifications";

export const getDeleteMessageKey = (state: string, versionNumber = 1) => {
  if (isStateDraft(state)) return SPECIFICATION_STATE.draft;

  if (versionNumber > 1) return "version";

  return "general";
};

export const getReviseMessageKey = (isRetailer: boolean) =>
  isRetailer ? "retailer" : "supplier";

export const getCancelRevisionMessageKey = (state: string) => {
  if (isStateInRevisionBySupplier(state))
    return SPECIFICATION_STATE.inRevisionBySupplier;

  return SPECIFICATION_STATE.inRevisionByRetailer;
};
