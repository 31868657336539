import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RadioChangeEvent } from "antd";
import { fetchClaimTypes } from "apis/SPEC";
import { useBoolean } from "hooks";
import { useAppDispatch } from "store";
import {
  selectClaimForm,
  setClaimFormData,
} from "store/claimForm/claimFormSlice";
import { selectLanguageCode } from "store/user/selectors";
import { REFLIST_IDS } from "utils/constants";
import { ClaimType } from "models/claim";
import { TagData } from "models";

const useClaimGeneralInfo = () => {
  const claimForm = useSelector(selectClaimForm);
  const languageCode = useSelector(selectLanguageCode);

  const [claimTypes, setClaimTypes] = useState([]);
  const [data, setData] = useState<ClaimType[]>([]);
  const [filteredProductTypeId, setFilteredProductTypeId] = useState<string>();
  const { value: isLoading, setTrue, setFalse } = useBoolean();

  const dispatch = useAppDispatch();

  const {
    generalInfo: { claimType, productTypeId },
  } = claimForm;

  const getClaimOptions = (items: ClaimType[]) =>
    items?.map(({ claimName, claimType }) => ({
      label: claimName,
      value: claimType,
      key: claimType,
    }));

  useEffect(() => {
    const getClaimTypes = async () => {
      try {
        setTrue();
        const { data } = await fetchClaimTypes({ languageCode });
        setData(data);
        setClaimTypes(getClaimOptions(data));
      } catch {
      } finally {
        setFalse();
      }
    };

    getClaimTypes();
  }, []);

  useEffect(() => {
    if (!claimType) return;

    const [selectedClaimData] = data?.filter(
      ({ claimType: selectedClaimType }) => selectedClaimType === claimType
    );

    setFilteredProductTypeId(
      selectedClaimData?.productTypeId === REFLIST_IDS.FOOD_PRODUCT_TYPE
        ? REFLIST_IDS.F_AND_V_PRODUCT_TYPE
        : REFLIST_IDS.FOOD_PRODUCT_TYPE
    );
  }, [claimType]);

  useEffect(() => {
    if (!productTypeId) return;

    const filteredClaimData = data?.filter(
      ({ productTypeId: selectedProductType }) =>
        selectedProductType === productTypeId
    );

    setClaimTypes(getClaimOptions(filteredClaimData));
  }, [productTypeId]);

  const onClaimTypeChange = (claimType: string) => {
    dispatch(
      setClaimFormData({
        ...claimForm,
        generalInfo: { ...claimForm.generalInfo, claimType: claimType },
      })
    );
  };

  const onNameChange = (value: string) => {
    dispatch(
      setClaimFormData({
        ...claimForm,
        generalInfo: { ...claimForm.generalInfo, name: value },
      })
    );
  };

  const onProductTypeChange = (value: string) => {
    dispatch(
      setClaimFormData({
        ...claimForm,
        generalInfo: { ...claimForm.generalInfo, productTypeId: value },
      })
    );
  };

  const onTagsChange = (value: Pick<TagData, "tagId" | "tagText">[]) => {
    if (value?.some(tag => !tag?.tagText)) {
      return;
    }

    dispatch(
      setClaimFormData({
        ...claimForm,
        generalInfo: {
          ...claimForm.generalInfo,
          tags: [...value],
        },
      })
    );
  };

  const onRequiresSupportingDocumentsChange = (event: RadioChangeEvent) => {
    dispatch(
      setClaimFormData({
        ...claimForm,
        generalInfo: {
          ...claimForm.generalInfo,
          requiresSupportingDocuments: event.target.value,
        },
      })
    );
  };

  return {
    isLoading,
    claimTypes,
    filteredProductTypeId,
    onNameChange,
    onClaimTypeChange,
    onProductTypeChange,
    onTagsChange,
    onRequiresSupportingDocumentsChange,
  };
};

export default useClaimGeneralInfo;
