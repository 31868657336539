import { useSelector } from "react-redux";
import { toaster as toasterService } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import { useChapterFormActions } from "components/Library/components/ChapterForm/hooks";
import { useRedirect } from "hooks";
import useBoolean from "hooks/useBoolean";
import { LIBRARY_OBJECT_TYPES } from "utils/constants";
import { messages } from "../../messages";
import { selectGeneraInfo } from "store/chapterForm/selectors";
import { GeneralInfoData } from "models";

const useDeleteChapterVersion = (chapterId: GeneralInfoData["id"]) => {
  const { formatMessage } = useIntl();
  const { redirectToLibraryListing } = useRedirect();
  const generalInfo = useSelector(selectGeneraInfo);
  const { onDeleteChapter } = useChapterFormActions({
    chapterId: generalInfo.id,
    chapterType: generalInfo.type,
  });
  const { value: isDeleteModalOpen, setFalse, setTrue } = useBoolean(false);

  const onDeleteChapterVersion = async () => {
    try {
      if (chapterId) {
        await onDeleteChapter();

        redirectToLibraryListing(LIBRARY_OBJECT_TYPES.CHAPTERS, {
          skipDetectLeavePage: true,
        });

        toasterService.confirmation({
          message: formatMessage(messages.deleteChapterVersionToasterTitle),
          description: formatMessage(
            messages.deleteChapterVersionToasterDescription,
            {
              chapterName: generalInfo.name,
              versionNumber: generalInfo.version?.number,
            }
          ),
        });
      }
    } catch (_) {}
  };

  return {
    isDeleteModalOpen,
    onOpenDeleteModal: setTrue,
    onDeleteChapterVersion,
    onCancelDeleteChapterVersion: setFalse,
  };
};

export default useDeleteChapterVersion;
