import { defineMessages } from "react-intl";

export const messages = defineMessages({
  requirementTypeModificationTitle: {
    id: "library.requirementForm.typeModification.title",
    defaultMessage: "Requirement modification",
  },
  requirementModificationDescription: {
    id: "library.requirementForm.typeModification.description",
    defaultMessage:
      "Changing the requirement type will erase all filled informations and files.",
  },
  requirementModificationStartOverDescription: {
    id: "library.requirementForm.typeModification.startOver.description",
    defaultMessage: "You will need to start over.",
  },
});
