const initialState = {
  isRequestPanelOpen: false,
  selectedModificationRequest: {
    detail: null,
    isEditorOpen: false,
    discussion: null,
  },
  specification: { progression: { progressions: 0 }, files: [] },
  isLoading: false,
  isUpdating: false,
  hasError: false,
  hasSignError: false,
  isRevisionLoading: false,
  isSigned: false,
  revisedSpecificationId: null,
  sections: [],
  selectedSection: {
    sectionId: null,
    currentRefs: {},
    subSections: [],
  },
  selectedSectionForms: [],
  selectedSectionLastChanges: {},
  selectedSectionRequirements: [],
  selectedSectionRequirementsFiles: [],
  isRequirementValidationInProgress: false,
  isAddRequirementsInProgress: false,
  isRequirementDeletionInProgress: false,
  companyName: null,
  updateErrors: {},
  updateLoading: {},
  validationErrors: {},
  validationLoading: {},
  validationDisabled: {},
  sectionSavingInProgress: {},
  progressList: {
    list: [],
    isLoading: false,
    hasError: false,
  },
  waitingSpecificationsNumber: 0,
  specificationPermissions: [],
  recipeIngredientsMaterial: {},
  recipeIngredientIdsWithExtraError: [],
  clearExtraError: true,
  productData: {
    itemName: null,
  },
  modificationRequests: [],
  translationManagement: {
    companies: [
      { key: "firstblock", company: null, languages: [], collaborations: [] },
    ],
    collaborations: [],
  },
};

export default initialState;
