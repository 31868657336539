import { Modal, Paragraph } from "@trace-one/design-system";
import { FormattedMessage, useIntl } from "react-intl";
import { messages } from "components/Library/components/ChapterForm/messages";
import generalMessages from "messages/general";
import { ConfirmChangeChapterTypeModalProps } from "./types";

const ConfirmChangeChapterTypeModal: React.FC<ConfirmChangeChapterTypeModalProps> = ({
  onConfirmModal,
  onCloseModal,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Modal.Simple
      visible
      size="s"
      keyboard={false}
      onCancel={onCloseModal}
      onPrimaryButtonClick={onConfirmModal}
      primaryButtonText={formatMessage(generalMessages.confirm)}
      onSecondaryButtonClick={onCloseModal}
      secondaryButtonText={formatMessage(generalMessages.cancel)}
      illustrationName="warning"
      illustrationColor="grey"
      title={<FormattedMessage {...messages.chapterModificationTitle} />}
    >
      <Paragraph>
        <FormattedMessage {...messages.chapterModificationDescription} />
        <br />
        <FormattedMessage
          {...messages.chapterModificationStartOverDescription}
        />
      </Paragraph>
    </Modal.Simple>
  );
};

export default ConfirmChangeChapterTypeModal;
