import { defineMessages } from "react-intl";

export const messages = defineMessages({
  listingResultNumber: {
    id: "folderListing.table.listingResultNumber",
    defaultMessage: "Displaying {current} out of {total} folders",
  },

  folderName: {
    id: "folderListing.table.folderName",
    defaultMessage: "Folder name",
  },

  productType: {
    id: "folderListing.table.productType",
    defaultMessage: "Product type",
  },

  owner: {
    id: "folderListing.table.owner",
    defaultMessage: "Owner",
  },

  creationDate: {
    id: "folderListing.table.creationDate",
    defaultMessage: "Creation date",
  },

  lastUpdate: {
    id: "folderListing.table.lastUpdate",
    defaultMessage: "Last update",
  },

  targetDate: {
    id: "folderListing.table.targetDate",
    defaultMessage: "Target date",
  },

  progression: {
    id: "folderListing.table.progression",
    defaultMessage: "Progression (%)",
  },

  status: {
    id: "status",
    defaultMessage: "Status",
  },

  editFolder: {
    id: "folderListing.table.editFolder",
    defaultMessage: "Edit folder",
  },

  deleteFolder: {
    id: "folderListing.table.deleteFolder",
    defaultMessage: "Delete folder",
  },
});
