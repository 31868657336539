import { Paragraph, Spinner } from "@trace-one/design-system";
import { Modal } from "antd";
import { useIntl } from "react-intl";
import { DigitalSigningProps } from "./types";
import { messages } from "../../messages";
import styles from "./styles.module.less";

const DigitalSigning: React.FC<DigitalSigningProps> = ({
  hasSignError,
  onClose,
}) => {
  const { formatMessage } = useIntl();
  return (
    <Modal
      centered
      visible={true}
      footer={null}
      maskClosable={false}
      closable={hasSignError ? true : false}
      onCancel={onClose}
      width={500}
      wrapClassName={styles.modal}
    >
      {!hasSignError ? (
        <>
          <Spinner size="large" className={styles.spinner} />
          <Paragraph>
            {formatMessage(messages.waitingStateDigitalSignaturePrepared)}
          </Paragraph>
          <Paragraph className={styles.centerText}>
            {formatMessage(messages.doNotQuit)}
          </Paragraph>
        </>
      ) : (
        <Paragraph className={styles.centerText}>
          {formatMessage(messages.digitalSigningErrorText)}
        </Paragraph>
      )}
    </Modal>
  );
};

export default DigitalSigning;
