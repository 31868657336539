import { useIntl } from "react-intl";
import { deleteFolder } from "apis/SPEC";
import useResume from "../useResume";
import useRbac from "hooks/useRbac";
import { PERMISSIONS } from "utils/constants";
import { isObjectEmpty, isListEmpty } from "utils/general";
import { FolderData } from "models";
import {
  getDeleteMessageKey,
  getResumeCreationAction,
  isDraftState,
} from "./utils";
import { messages } from "./messages";
import { Permissions } from "types/general";

const useFolderActions = (
  folder: FolderData,
  permissionList: Permissions = []
) => {
  const { formatMessage } = useIntl();
  const { resumeFolder } = useResume();
  const { folderId, state } = folder;
  const { hasPermission } = useRbac(permissionList);

  const hasUpdatePermission = hasPermission(PERMISSIONS.FOLDER.UPDATE);
  const hasDeletePermission = hasPermission(PERMISSIONS.FOLDER.DELETE);

  if (isListEmpty(permissionList) || isObjectEmpty(folder) || !folderId) {
    return {};
  }

  const deleteAction = async () => {
    try {
      await deleteFolder({ id: folderId });
    } catch {}
  };

  return {
    ...(hasUpdatePermission &&
      isDraftState(state) && {
        resume: {
          name: formatMessage(messages.resumeCreation),
          onClick: getResumeCreationAction({
            resumeFolder,
            id: folderId,
          }),
        },
      }),
    ...(hasDeletePermission && {
      delete: {
        name: formatMessage(messages[getDeleteMessageKey(state)]),
        tooltipProps: {
          onConfirmation: deleteAction,
          text: !isDraftState(state)
            ? formatMessage(messages.deleteGeneralConfirmation)
            : null,
          placement: "left",
        },
      },
    }),
  };
};

export default useFolderActions;
