/* istanbul ignore file */

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectLanguageCode } from "store/user/selectors";
import { fetchUnitsOfMeasure } from "store/generalData/asyncActions";
import { selectUnitsOfMeasure } from "store/generalData/selectors";
import { getOnUnitsOfMeasureFetch } from "./utils";

const withUnitsOfMeasure = Component => props => {
  const dispatch = useDispatch();
  const userLanguageCode = useSelector(selectLanguageCode);
  const unitsOfMeasure = useSelector(selectUnitsOfMeasure);

  useEffect(() => {
    getOnUnitsOfMeasureFetch({
      dispatch,
      fetchUnitsOfMeasure,
      unitsOfMeasure,
      hasLanguageChanged: true,
    });
  }, [userLanguageCode]);

  return <Component {...props} />;
};

export default withUnitsOfMeasure;
