import { TradeItemSupplierData } from "models";

export const prepareSelectedSupplier = ({
  supplier,
  selectedSuppliers,
}: {
  supplier: TradeItemSupplierData;
  selectedSuppliers: TradeItemSupplierData[];
}) => {
  if (
    selectedSuppliers.findIndex(
      item => item.supplierId === supplier.supplierId
    ) > -1
  ) {
    return selectedSuppliers.filter(
      item => item.supplierId !== supplier.supplierId
    );
  }
  return [...selectedSuppliers, supplier];
};
