import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";

/* istanbul ignore next */
export const _selectMaterialForm = (state: RootState) => state.materialForm;

export const selectMaterialInformation = createSelector(
  _selectMaterialForm,
  materialForm => materialForm.materialInformation
);

export const selectRawMaterial = createSelector(
  _selectMaterialForm,
  materialForm => materialForm.rawMaterial
);

export const selectSuppliers = createSelector(
  _selectMaterialForm,
  materialForm => materialForm.suppliers
);

export const selectAllergens = createSelector(
  _selectMaterialForm,
  materialForm => materialForm.allergens
);

export const selectRecipe = createSelector(
  _selectMaterialForm,
  materialForm => materialForm.recipe
);

export const selectCreationStatus = createSelector(
  _selectMaterialForm,
  materialForm => materialForm.creationStatus
);

export const selectUpdateStatus = createSelector(
  _selectMaterialForm,
  materialForm => materialForm.updateStatus
);

export const selectPublishStatus = createSelector(
  _selectMaterialForm,
  materialForm => materialForm.publishStatus
);

export const selectKeepAsDraftStatus = createSelector(
  _selectMaterialForm,
  materialForm => materialForm.keepAsDraftStatus
);

export const selectCharachteristicsSchema = createSelector(
  _selectMaterialForm,
  materialForm => materialForm.characteristicsSchema
);

export const selectControlPlansSchema = createSelector(
  _selectMaterialForm,
  materialForm => materialForm.controlPlansSchema
);

export const selectFileIds = createSelector(
  _selectMaterialForm,
  materialForm => materialForm.fileIds
);

export const selectSubstanceId = createSelector(
  _selectMaterialForm,
  materialForm => materialForm.substanceId
);
