import { SpecificationDraftData, UserData, WizardData } from "models";
import { Permissions } from "types/general";
import { axiosInstance } from "./";
import { API_VERSION } from "apis/constants";

export const fetchSpecificationDraft = ({ draftId }) =>
  axiosInstance.get<SpecificationDraftData>(
    `/api/${API_VERSION}specification-drafts/${draftId}`
  );

export const fetchSpecificationDraftWizard = ({
  draftId,
}: {
  draftId: SpecificationDraftData["draftId"];
}) =>
  axiosInstance.get<WizardData>(
    `/api/${API_VERSION}specification-drafts/${draftId}/wizard`
  );

export const createSpecificationDraft = ({
  folderId,
  tradeItemId,
  supplierId,
}: {
  folderId?: SpecificationDraftData["folderId"];
  tradeItemId?: SpecificationDraftData["tradeItemId"];
  supplierId?: SpecificationDraftData["ownerCompanyId"];
}) =>
  axiosInstance.post<SpecificationDraftData>(
    `/api/${API_VERSION}specification-drafts`,
    {
      folderId,
      tradeItemId,
      supplierId,
    }
  );

export const createSpecificationWizard = ({
  draftId,
}: {
  draftId: SpecificationDraftData["draftId"];
}) =>
  axiosInstance.post<WizardData>(
    `/api/${API_VERSION}specification-drafts/${draftId}/wizard`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

export const createSpecificationDraftContact = ({
  draftId,
  userId,
}: {
  draftId: SpecificationDraftData["draftId"];
  userId: UserData["userId"];
}) =>
  axiosInstance.post<SpecificationDraftData>(
    `/api/${API_VERSION}specification-drafts/${draftId}/contacts`,
    {
      userId,
    }
  );

export const updateSpecificationDraftSupplier = ({
  draftId,
  companyId,
}: {
  draftId: SpecificationDraftData["draftId"];
  companyId?: SpecificationDraftData["ownerCompanyId"];
}) =>
  axiosInstance.put<SpecificationDraftData>(
    `/api/${API_VERSION}specification-drafts/${draftId}/supplier`,
    {
      companyId,
    }
  );

export const updateSpecificationProductType = ({
  draftId,
  productTypeId,
}: {
  draftId: SpecificationDraftData["draftId"];
  productTypeId?: SpecificationDraftData["productTypeId"];
}) =>
  axiosInstance.put<SpecificationDraftData>(
    `/api/${API_VERSION}specification-drafts/${draftId}/product-type`,
    productTypeId,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

export const updateSpecificationTemplate = ({
  draftId,
  templateId,
}: {
  draftId: SpecificationDraftData["draftId"];
  templateId?: SpecificationDraftData["templateId"];
}) =>
  axiosInstance.put<SpecificationDraftData>(
    `/api/${API_VERSION}specification-drafts/${draftId}/template`,
    templateId,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

export const updateSpecificationDraftTradeItem = ({
  draftId,
  tradeItemId,
}: {
  draftId: SpecificationDraftData["draftId"];
  tradeItemId?: SpecificationDraftData["tradeItemId"];
}) =>
  axiosInstance.put<SpecificationDraftData>(
    `/api/${API_VERSION}specification-drafts/${draftId}/trade-item`,
    {
      tradeItemId,
    }
  );

export const updateSpecificationDraftNote = ({
  draftId,
  note,
}: {
  draftId: SpecificationDraftData["draftId"];
  note: SpecificationDraftData["note"];
}) =>
  axiosInstance.put<SpecificationDraftData>(
    `/api/${API_VERSION}specification-drafts/${draftId}/note`,
    {
      note,
    }
  );

export const shareSpecificationDraft = ({
  draftId,
}: {
  draftId: SpecificationDraftData["draftId"];
}) =>
  axiosInstance.post<string>(
    `/api/${API_VERSION}specification-drafts/${draftId}/share`
  );

export const deleteSpecificationDraftContact = ({
  draftId,
  userId,
}: {
  draftId: SpecificationDraftData["draftId"];
  userId: UserData["userId"];
}) =>
  axiosInstance.delete<SpecificationDraftData>(
    `/api/${API_VERSION}specification-drafts/${draftId}/contacts/${userId}`
  );

export const deleteSpecificationDraft = ({
  specificationId,
}: {
  specificationId: SpecificationDraftData["draftId"];
}) =>
  axiosInstance.delete<void>(
    `/api/${API_VERSION}specification-drafts/${specificationId}`
  );

export const fetchSpecificationDraftPermissions = ({
  draftId,
}: {
  draftId: SpecificationDraftData["draftId"];
}) =>
  axiosInstance.get<Permissions>(
    `/api/${API_VERSION}specification-drafts/${draftId}/permissions`
  );

export const createSpecificationDraftCollaboration = ({
  draftId,
  collaborationId,
}: {
  draftId: SpecificationDraftData["draftId"];
  collaborationId: string;
}) =>
  axiosInstance.post<SpecificationDraftData>(
    `/api/${API_VERSION}specification-drafts/${draftId}/collaborations`,
    {
      collaborationId,
    }
  );

export const deleteSpecificationDraftCollaboration = ({
  draftId,
  collaborationId,
}: {
  draftId: SpecificationDraftData["draftId"];
  collaborationId: string;
}) =>
  axiosInstance.delete<SpecificationDraftData>(
    `/api/${API_VERSION}specification-drafts/${draftId}/collaborations/${collaborationId}`
  );

export const updateSpecificationDraftFiles = ({
  draftId,
  payload,
}: {
  draftId: SpecificationDraftData["draftId"];
  payload: SpecificationDraftData["files"];
}) =>
  axiosInstance.put<SpecificationDraftData>(
    `/api/${API_VERSION}specification-drafts/${draftId}/files`,
    payload
  );
