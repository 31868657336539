import { isStateDraft } from "utils/specifications";

export const getOnSpecificationNoteChange = ({
  dispatch,
  setFolderSpecificationNote,
}) => specificationNote => {
  dispatch(setFolderSpecificationNote(specificationNote));
};

export const getUniqueNumberOfSuppliers = ({
  folderId,
  productSpecifications,
}) => {
  let supplierIds = Object.values(productSpecifications).reduce(
    (previousState, specifications) => {
      specifications.forEach(
        ({ state, folderId: specFolderId, supplierId }) => {
          if (isStateDraft(state) && specFolderId === folderId) {
            previousState.add(supplierId);
          }
        }
      );
      return previousState;
    },
    new Set([])
  );

  return supplierIds.size;
};
