import { defineMessages } from "react-intl";

export const pdfMessages = defineMessages({
  viewPdf: {
    id: "specificationDetails.signature.viewPdf",
    defaultMessage: "PDF",
  },
  viewPdfOnly: {
    id: "specificationDetails.signature.viewPdfOnly",
    defaultMessage: "View PDF only",
  },
  viewPdfWithAttachments: {
    id: "specificationDetails.signature.viewPdfWithAttachments",
    defaultMessage: "View PDF with attachments",
  },
});
