export const ROUTES = Object.freeze({
  ADMINISTRATION: {
    TAGS: "/administration/tags",
  },
  HOME: "/",
  CREATION_DETAILS_STEP: "/folders/creation/details",
  CREATION_PRODUCTS_STEP: "/folders/creation/products",
  CREATION_INVITATION_STEP: "/folders/creation/invitation",
  CREATION_SPECIFICATIONS_STEP: "/folders/creation/specifications",
  CREATION_SUMMARY_STEP: "/folders/creation/summary",
  FOLDER_LISTING: "/folders/list",
  FOLDER_DETAILS: "/folders/:folderId",
  LIBRARY: {
    ASSIGN_SPECIFICATIONS: "assign-specifications",
    CREATE_NEW_VERSION: "/library/:type/:id/create-new-version/:tab?",
    CREATION: "/library/creation/:type",
    DETAILS: "/library/:type/:id/details/:tab?",
    EDITION: "/library/:type/:id/edit/:tab?",
    LINKED_SPECIFICATIONS: "/library/:type/:id/linked-specifications",
    VERSION_HISTORY: "/library/:type/:id/version-history",
    LISTING: "/library/list/:type",
  },
  SPECIFICATION_LISTING: "/specifications/list",
  SPECIFICATION_CREATION_PRODUCT_STEP: "/specifications/creation/product",
  SPECIFICATION_CREATION_INVITATION_STEP: "/specifications/creation/invitation",
  SPECIFICATION_CREATION_SPECIFICATION_STEP:
    "/specifications/creation/specification",
  SPECIFICATION_CREATION_SUMMARY_STEP: "/specifications/creation/summary",
  SPECIFICATION_DETAILS: "/specifications/:specificationId",
  SPECIFICATION_SECTION_DETAILS:
    "/specifications/:specificationId/:sectionCode",
  SPECIFICATION_GENERATE_PDF: "/specifications/:specificationId/generate-pdf",
  SPECIFICATION_WAITING_DIGITAL_SIGNATURE:
    "/specifications/waiting-digital-signature/:specificationId/:specificationState",
  PACKAGING_COMPONENT: {
    EDIT: "/packaging/components/:packagingComponentId/edit",
  },
  TRANSLATION_LISTING: "/translations/list",
});

export const PERMISSIONS = Object.freeze({
  SPECIFICATION: {
    READ: "specifications.read",
    UPDATE: "specifications.update",
    DELETE: "specifications.delete",
    REVISE: "specifications.revise",
    SIGN: "specifications.sign",
    SHARE: "specifications.share",
    ACCEPT_REVISION: "specifications.acceptRevision",
    DUPLICATE: "specifications.duplicate",
    UPDATE_REQUIREMENTS: "specifications.updateRequirements",
    VALIDATE_REQUIREMENTS: "specifications.validateRequirements",
    VALIDATE_DOCUMENTS: "specifications.validateDocuments",
  },
  FOLDER: {
    READ: "folders.read",
    UPDATE: "folders.update",
    DELETE: "folders.delete",
  },
  SECTION: {
    VALIDATE: "specifications.validate",
    SAVE: "specifications.update",
  },
  MATERIAL: {
    READ: "materials.read",
    UPDATE: "materials.update",
    CREATE: "materials.create",
    UPDATE_NAME: "materials.update.name",
    UPDATE_TAGS: "materials.update.tags",
    REVISE: "materials.revise",
  },
  USER: {
    HAS_ACCESS_TO_ADMINISTRATION: "has.access.to.administration",
  },
  PACKAGING_COMPONENT: {
    READ: "packagingComponents.read",
    WRITE: "packagingComponents.write",
  },
  PACKAGING_SYSTEM: {
    WRITE: "packagingSystems.write",
  },
  REQUIREMENT: {
    UPDATE: "requirements.update",
    CREATE: "requirements.create",
  },
  CHAPTER: {
    CREATE: "chapters.create",
    TRANSLATION_MANAGEMENT: "chapterTranslations.manage",
    TRANSLATION_READ: "chapterTranslations.read",
  },
  TEMPLATE: {
    CREATE: "templates.create",
  },
  CLAIM: {
    CREATE: "claims.create",
  },
  TAG: {
    CREATE: "tags.create",
  },
});

export enum ERROR_CODES {
  CANNOT_ADD_SPECIFICATION = "ec9e236b-5293-4538-a5a4-eae9f5316dc2",
  CANNOT_SHARE = "1feff1e9-7801-43a4-a791-de94bb6ec10d",
  FILE_TOO_BIG = "1d6a4f16-ddba-4915-8c7e-51d4ac8fcc81",
  FORBIDDEN_FILE_EXTENSION = "2c8ff17e-3ee9-4304-b65e-4d245ffbc168",
  NOT_FOUND = "ef1bc152-a1a3-4d89-b81e-8857cfb7e1ac",
  UPLOAD_EMPTY_FILE = "68d8ae14-1825-42e7-a05a-cb9d198c3632",
  DUPLICATE_TAG = "3e36005b-c2b7-4d9e-bb7d-831f120ecdc7",
  LIBRARY_OBJECT_ALREADY_SAVED = "1feff1e9-7801-43a4-a791-de94bb6ec10d",
  DUPLICATE_SPECIFICATION = "e810e9ec-9f9e-47fa-ba6a-63fd351dad60",
  TESTING_SECTION_IS_MISSING = "84a70523-6fe4-48cf-bd17-375fc064b336",
  DUPLICATE_LIBRARY_NAME = "4d7a1a96-5e47-4f96-a417-abd4e17ff691",
  CHAPTER_NOT_LINKED = "2f492427-5ed2-4395-9016-ba53a3dcbce4",
  FILE_NOT_FOUND = "c2241aaa-4212-4d36-88d2-c1244655afce",
  TAG_NOT_FOUND = "6ae3628a-094f-4616-a181-e58a9489be44",
}

export enum ERROR_STATUSES {
  PAYLOAD_TOO_LARGE = 413,
}

export enum REFLIST_IDS {
  PRODUCT_TYPES = "af391fb2-1d34-45ef-8400-cb14642b8445",
  CONTROL_PHASES = "a3fbe681-73fd-4bdb-b072-8454416e4f9a",
  CONTROL_TYPES = "3daf8078-dea2-47c8-9725-0725538ef9af",
  ORIGIN_TYPES = "7ace3dbd-4181-4c56-97e1-039794e56e8c",
  COUNTRIES = "3B123F34-E7A3-4FE0-AFF0-D31CE33E924C",
  REGIONS = "ffff99ef-0d1f-4602-acb8-0a78e0d3c14e",
  ZONES = "d3f1c34f-9cb9-4100-90f1-410a1841ca77",
  SOURCES = "2093fc20-f99a-4c25-a691-4123aa6d30a3",
  PARTS = "b67f5c74-257f-4ef7-9650-c5ad791e96e2",
  FUNCTIONS = "cbb55aac-6ac5-4766-99c7-286421145537",
  ALLERGENS = "bc7d5937-7f05-4363-a811-a91218386049",
  NUTRIENTS = "aa81f2b0-6bc4-45a7-9eaf-49600876eede",
  QUALITY_MANAGER_RESPONSIBILITY = "4f86a4e7-be95-4cba-a9c0-c42bc8f54fef",
  QUALITY_MANAGER_ROLE = "41627065-367c-488b-acb6-01ba6963906a",
  COMPANY_ACTIVITY = "2a585e99-1ca3-49c7-83cd-d8ec352f442e",
  UNITS_OF_MEASURE = "8ff32c51-aef6-4ff8-826d-0baa2b0ae10e",
  INTERNATIONAL_SYSTEM_SOURCE = "14dfe812-c247-4d6e-8c99-4332c865b2f3",
  MASS = "97c9a7a1-9f4d-4aba-a710-3f569e95d683",
  VOLUME = "cdea27bd-807f-4a57-b5f8-4b7073a5e98c",
  F_AND_V_PRODUCT_TYPE = "51e3e0fc-de32-4ca7-b1d3-ae6d64df3297",
  FOOD_PRODUCT_TYPE = "fda02edf-d3ff-47f0-b9b2-7a06f9aa832f",
  PACKAGING_COMPONENTS_TYPE = "b171e5be-f0b5-433f-a27f-7a06a640ccfc",
  TRANSLATOR_ROLE = "9cf64ed7-9ee2-4edb-bcba-749ffc4d3c9a",
  TRANSLATION_AGENCY_TRANSLATOR_MANAGER = "bb0f52ac-6ec9-4ad3-aeca-309a231221da",
}

export enum FILE_TYPES {
  CHARACTERISTIC_PHOTO = "characteristic-photo",
  REQUIREMENT = "requirement",
  SPECIFICATION_ATTACHMENT = "spec-app-default",
  MATERIAL_TECHNICAL_DATA_SHEET = "material-technical-datasheet",
  MATERIAL_DEFAULT = "material-default",
  CLAIM_LOGO = "claim-logo",
  CLAIM_SUPPORTING_DOCUMENT = "claim-supporting-document",
}

export enum FILE_STATUSES {
  DOWNLOADING = "downloading",
  DONE = "done",
  ERROR = "error",
}

export enum LIBRARY_REQUIREMENT_TYPES {
  DOCUMENT = "document",
  TEXT = "text",
}

export enum LIBRARY_OBJECT_TYPES {
  REQUIREMENTS = "requirements",
  CLAIMS = "claims",
  TEXT_REQUIREMENTS = "text-requirements",
  DOCUMENT_REQUIREMENTS = "document-requirements",
  MATERIALS = "materials",
  RAW_MATERIALS = "raw-materials",
  BOUGHT_TO_THIRD_PARTY = "bought-composites",
  MADE_IN_HOUSE = "made-composites",
  COMPOSITES = "composites",
  ADDITIVES = "additives",
  PROCESSING_AIDS = "processing-aids",
  CHAPTERS = "chapters",
  PRODUCTION = "production",
  CONDITIONING = "conditioning",
  TESTING = "testing",
  FINISHED_PRODUCTS = "finished-product",
  PACKAGING_SYSTEMS = "packaging-systems",
  PACKAGING_COMPONENTS = "packaging-components",
  TEMPLATES = "templates",
  TEMPLATE = "template",
  FOOD = "food",
  F_AND_V = "fAndV",
}

export enum PRODUCT_TYPES {
  FOOD = "food",
  F_AND_V = "fAndV",
}

export enum LIBRARY_OBJECT_API_TYPES {
  requirements = "requirement",
  requirement = "requirement",
  text = "text-requirements",
  document = "document-requirements",
  materials = "material",
  material = "material",
  chapter = "chapter",
  chapters = "chapter",
  "packaging-system" = "packaging-system",
  "packaging-systems" = "packaging-system",
  template = "template",
  templates = "template",
  claims = "claim",
  claim = "claim",
}

export enum LIBRARY_OBJECT_URL_TYPES {
  requirement = LIBRARY_OBJECT_TYPES.REQUIREMENTS,
  requirements = LIBRARY_OBJECT_TYPES.REQUIREMENTS,
  material = LIBRARY_OBJECT_TYPES.MATERIALS,
  production = LIBRARY_OBJECT_TYPES.PRODUCTION,
  testing = LIBRARY_OBJECT_TYPES.TESTING,
  conditioning = LIBRARY_OBJECT_TYPES.CONDITIONING,
  finishedProduct = LIBRARY_OBJECT_TYPES.FINISHED_PRODUCTS,
  text = LIBRARY_OBJECT_TYPES.TEXT_REQUIREMENTS,
  document = LIBRARY_OBJECT_TYPES.DOCUMENT_REQUIREMENTS,
  rawMaterial = LIBRARY_OBJECT_TYPES.RAW_MATERIALS,
  madeInHouse = LIBRARY_OBJECT_TYPES.MADE_IN_HOUSE,
  boughtToThirdParty = LIBRARY_OBJECT_TYPES.BOUGHT_TO_THIRD_PARTY,
  "packaging-systems" = LIBRARY_OBJECT_TYPES.PACKAGING_SYSTEMS,
  processingAid = LIBRARY_OBJECT_TYPES.PROCESSING_AIDS,
  additive = LIBRARY_OBJECT_TYPES.ADDITIVES,
}

export enum LIBRARY_OBJECTS_VIEWS {
  MOSAIC = "mosaic",
  LIST = "list",
}

export enum LIBRARY_OBJECTS_VIEW_SIZES {
  SMALL = "small",
  LARGE = "large",
}

export enum LIBRARY_OBJECTS_PAGES {
  CREATE = "create",
  CREATE_NEW_VERSION = "create-new-version",
  DUPLICATE = "duplicate",
  DETAILS = "details",
  EDIT = "edit",
}

export enum SPECIFICATION_PAGE {
  PDF = "pdf",
}

export enum LIBRARY_OBJECT_STATES {
  DRAFT = "draft",
  LOCKED = "locked",
  READY = "ready",
  PUBLISHED = "published",
}

export enum LIBRARY_TAB_KEYS {
  GENERAL_INFORMATION = "general-information",
  LINKED_SPECIFICATION = "linked-specifications",
  VERSION_HISTORY = "version-history",
}

export enum MATERIAL_TYPES {
  RAW_MATERIAL = "rawMaterial",
  COMPOSITE = "composite",
  ADDITIVE = "additive",
  PROCESSING_AID = "processingAid",
}

export enum RECIPE_TYPES {
  BOUGHT_TO_THIRD_PARTY = "boughtToThirdParty",
  MADE_IN_HOUSE = "madeInHouse",
}

export enum AREA_CATEGORY_TYPES {
  additive = "additive",
  processingAid = "processingAid",
  packaging = "packaging",
}

export enum ALLERGEN_DECLARATION_TYPE {
  PRESENT = "present",
  UNINTENTIONAL_PRESENT = "unintentionalPresent",
}

export enum LIBRARY_CHAPTER_TYPE {
  PRODUCTION = "production",
  TESTING = "testing",
  FINISHED_PRODUCT = "finishedProduct",
}

export enum SPECIFICATION_STATE {
  none = "none",
  inProgress = "inProgress",
  draft = "draft",
  inRevisionBySupplier = "inRevisionBySupplier",
  inRevisionByRetailer = "inRevisionByRetailer",
  signedByRetailer = "signedByRetailer",
  signedBySupplier = "signedBySupplier",
  validatedByRetailer = "validatedByRetailer",
  validatedBySupplier = "validatedBySupplier",
  toFill = "toFill",
  lockedForSupplierSignature = "lockedForSupplierSignature",
  lockedForRetailerSignature = "lockedForRetailerSignature",
}

export enum FOLDER_STATE {
  DRAFT = "draft",
  IN_PROGRESS = "inProgress",
}

export enum MODIFICATION_REQUEST_STATE {
  DRAFT = "draft",
  IN_PROGRESS = "inProgress",
  PERFORMED = "performed",
  DECLINED = "declined",
  COMPLETED = "completed",
  ARCHIVED = "archived",
}

export enum SPECIFICATION_TRANSLATION_STATE {
  NOT_STARTED = "notStarted",
  STARTED = "started",
  COMPLETED = "completed",
}

export enum NUTRISCORE_CALCULATION_STATE {
  SUCCESSFUL = "successful",
  MANDATORY_FIELDS_MISSING = "mandatoryFieldsMissing",
  PRODUCTION_CHAPTER_NOT_LINKED = "productionChapterNotLinked",
  ERROR = "error",
}

export enum MODIFICATION_REQUEST_STATE_FILTER {
  ALL = "all",
  ACTIVE = "active",
}

export enum PAGE_HEADING_TYPES {
  LIST = "list",
  ACTIONS = "actions",
}

export enum LANGUAGES {
  ENGLISH = "en-US",
  FRENCH = "fr-FR",
  DUTCH = "nl-BE",
  GERMAN = "de-DE",
  ITALIAN = "it-IT",
  PORTUGUESE = "pt-PT",
  SPANISH = "es-ES",
}

export enum APPLICATION_TYPE_IDS {
  SPEC = 70,
  TON = 40,
  INSIGHT = 21,
  NEW_INSIGHT = 110,
}

export enum CHAPTER_TYPES_API {
  general = "general",
  production = "production",
  conditioning = "conditioning",
  packaging = "packaging",
  finishedProduct = "finished-product",
  testing = "testing",
  labeling = "labeling",
  documents = "documents",
}

export enum CHAPTER_TYPES_URL_API {
  general = "general",
  production = "production",
  conditioning = "conditioning",
  packaging = "packaging",
  "finished-product" = "finishedProduct",
  testing = "testing",
  labeling = "labeling",
  documents = "documents",
}

export enum CHAPTER_ICONS {
  general = "general",
  production = "production",
  conditioning = "cart",
  packaging = "package",
  finishedProduct = "finished-product",
  testing = "testing",
  labeling = "labelling",
  documents = "document",
}

export enum LIBRARY_API_PARAMS_VERSION {
  activeVersion = "activeVersion",
  lastVersion = "lastVersion",
}

export enum LIBRARY_TYPE_GRAPHICS {
  production = "production",
  finishedProduct = "finish-product",
  testing = "testing",
  template = "template",
  rawMaterial = "raw-material",
  "packaging-systems" = "packaging-system",
  "packaging-components" = "component",
  component = "component",
  requirement = "requirement",
  text = "requirement",
  document = "requirement",
  madeInHouse = "composite-made-in-house",
  boughtToThirdParty = "composite-third-party",
  additive = "additive",
  processingAid = "processing-aid",
  claims = "claim",
}

export enum CHAPTER_SECTION_CODE_API {
  version = "version",
  pack_product_info = "product-info",
  pack_business_name_and_address = "business-name-address",
  pack_ingredient_list = "ingredient-list",
  pack_marketing_info = "marketing-info",
  consumer_instruction = "consumer-instructions",
  labeling_codes_and_dates = "codes-and-dates",
}

export enum CLAIM_TYPES {
  MARKETING = "marketing",
}

export type ClaimTypes = `${CLAIM_TYPES}`;

export const CHAPTER_SECTION_REQUIREMENTS = Object.freeze({
  id: "requirements-section",
  code: "requirements",
});

export const CHAPTER_SECTION_DOCUMENTS = Object.freeze({
  id: "documents-section",
  code: "documents",
});

export enum SECTION_CODE {
  LABELING = "labeling",
}

// Hardcoded for now...
// TODO: See with backend people how to remove that.
export const PORTAIL_ID = "2280fc83-0b5e-4d05-93b5-27a3aa3a3fcb";

export const NET_CONTENT_NAME = "NetContent";

export const takeItemsOnSelectPage = 50;

export const numberOfCharactersForAutoComplete = 3;

export const MAX_INPUT_NUMBER_LENGTH = 16;

export const MAX_INPUT_TEXT_LENGTH = 32;

export const MAX_CHAR_LENGTH = 256;

export const MAX_TEXTAREA_LENGTH = 512;

export const PAGE_SIZE_OPTIONS = ["2", "10", "20", "30", "40", "50"];

export const GRAM_UNIT_ID = "a919ce71-9e50-404a-84e0-92d64dee1ffb";

export const STICKY_POSITION_OFFSET = 190;

export const PANEL_DATA_HEADER_ID = "panel-header";

export const HELP_CENTER_URLS = {
  [LANGUAGES.ENGLISH]: window.env.TO_HELP_LINK_EN,
  [LANGUAGES.FRENCH]: window.env.TO_HELP_LINK_FR,
};

export enum NUTRIENT_STATE {
  MANDATORY = "Mandatory",
  OPTIONAL = "Optional",
}

export enum WIZARD_STEPS {
  FOLDER = "folder",
  PRODUCT = "product",
  INVITATION = "invitation",
  SPECIFICATION = "specification",
  SUMMARY = "summary",
}

export const SPECIFICATION_WIZARD_STEPS = [
  {
    step: WIZARD_STEPS.PRODUCT,
    titleKey: "specificationProduct",
    route: ROUTES.SPECIFICATION_CREATION_PRODUCT_STEP,
  },
  {
    step: WIZARD_STEPS.INVITATION,
    titleKey: "invitation",
    route: ROUTES.SPECIFICATION_CREATION_INVITATION_STEP,
  },
  {
    step: WIZARD_STEPS.SPECIFICATION,
    route: ROUTES.SPECIFICATION_CREATION_SPECIFICATION_STEP,
  },
  {
    step: WIZARD_STEPS.SUMMARY,
    route: ROUTES.SPECIFICATION_CREATION_SUMMARY_STEP,
  },
];

export const FOLDER_WIZARD_STEPS = [
  {
    step: WIZARD_STEPS.FOLDER,
    route: ROUTES.CREATION_DETAILS_STEP,
  },
  {
    step: WIZARD_STEPS.PRODUCT,
    titleKey: "folderProduct",
    route: ROUTES.CREATION_PRODUCTS_STEP,
  },
  {
    step: WIZARD_STEPS.INVITATION,
    route: ROUTES.CREATION_INVITATION_STEP,
  },
  {
    step: WIZARD_STEPS.SPECIFICATION,
    titleKey: "folderSpecifications",
    route: ROUTES.CREATION_SPECIFICATIONS_STEP,
  },
  {
    step: WIZARD_STEPS.SUMMARY,
    route: ROUTES.CREATION_SUMMARY_STEP,
  },
];

export const TAKE_ITEMS_FOR_INFINITE_PAGINATION = 24;

export const EMPTY_VALUE = "-";

export enum SpecificationContactType {
  RETAILER = "retailer",
  SUPPLIER = "supplier",
  TRANSLATOR = "translator",
  OTHER = "other",
}
