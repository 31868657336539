import { LabeledValue } from "antd/lib/select";

const useExternalListSelect = ({
  defaultOnSelect,
  defaultOnDeselect,
}: {
  defaultOnSelect: Function;
  defaultOnDeselect: Function;
}): {
  onSelect: Function;
  onDeselect: Function;
} => {
  const onSelect = (selectedValue: LabeledValue, option: LabeledValue) => {
    defaultOnSelect(selectedValue.value, option);
  };

  const onDeselect = (selectedValue: LabeledValue, option: LabeledValue) =>
    defaultOnDeselect(selectedValue.value, option);

  return {
    onSelect,
    onDeselect,
  };
};

export default useExternalListSelect;
