import dayjs from "dayjs";
import {
  displayValueForSpecificationDraftState,
  getDataIndexForProductOrigin,
} from "pages/Specification/utils";
import Status from "components/Status";
import { LinkedColumnsProps } from "./types";
import { messages } from "messages/specification";
import generalMessages from "messages/general";

export const getColumns = ({ intl, productOrigin }: LinkedColumnsProps) => [
  {
    title: intl.formatMessage(messages.productName),
    dataIndex: "tradeItemName",
    key: "tradeItemName",
    sorter: true,
  },
  {
    title: intl.formatMessage(messages[`${productOrigin}Name`]),
    dataIndex: getDataIndexForProductOrigin({ productOrigin }),
    sorter: true,
  },
  {
    title: intl.formatMessage(messages.owner),
    dataIndex: "ownerUserName",
    sorter: true,
  },
  {
    title: intl.formatMessage(messages.productType),
    dataIndex: "productType",
    sorter: true,
  },
  {
    title: intl.formatMessage(messages.lastUpdate),
    dataIndex: "lastUpdateDateUtc",
    sorter: true,
    render: (date, specification) =>
      displayValueForSpecificationDraftState({
        specificationState: specification.state,
        valueToDisplay: dayjs(date).local().format("L"),
      }),
  },
  {
    title: intl.formatMessage(generalMessages.version),
    dataIndex: "version",
    render: (version, specification) =>
      displayValueForSpecificationDraftState({
        specificationState: specification.state,
        valueToDisplay: version?.number,
      }),
  },
  {
    title: intl.formatMessage(messages.status),
    dataIndex: "state",
    render: state => <Status.Tag state={state} />,
  },
];
