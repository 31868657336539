import { SpecificationChapterDocumentData } from "models";
import { SpecificationState } from "types/specification";
import { isListEmpty } from "utils/general";
import { isSpecificationSigned } from "utils/specifications";

export const getIsValidationDisabled = ({
  documents,
  onValidateDocuments,
  specificationState,
  isRetailer,
}: {
  documents: SpecificationChapterDocumentData;
  onValidateDocuments: () => Promise<void>;
  specificationState: SpecificationState;
  isRetailer: boolean;
}) =>
  !onValidateDocuments ||
  isSpecificationSigned(specificationState) ||
  (isRetailer && documents?.validatedByRetailer) ||
  (!isRetailer &&
    (documents?.validatedBySupplier || documents?.validatedByRetailer)) ||
  isListEmpty(documents?.fileIds);
