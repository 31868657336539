const startConfirmedModal = ({
  isDone,
  isSharing,
  hasError,
  startConfirmedSharingModal,
}) => {
  if (isDone && !isSharing && !hasError) {
    startConfirmedSharingModal();
  }
};

export default startConfirmedModal;
