import { useMemo, useRef } from "react";
import classnames from "classnames";
import { Col, Row } from "antd";
import withRequestModificationPopover from "hocs/withRequestModificationPopover";
import { isObjectEmpty } from "utils/general";
import RemoveAction from "./components/RemoveAction";
import { REQUEST_MODIFICATION_MODAL_WIDTH } from "components/RequestModificationPopover";
import {
  canTargetField,
  doesFieldHaveModificationRequest,
} from "utils/modificationRequest";
import { setRequestModification } from "store/specificationDetails/specificationDetailsSlice";
import { useAppDispatch } from "store";
import { ArrayFieldTemplateItemProps } from "./types";
import styles from "../styles.module.less";

const ArrayFieldTemplateItem: React.FC<ArrayFieldTemplateItemProps> = ({
  children,
  displayActions,
  key,
  index,
  onDropIndexClick,
  hasRemove,
  lastChange,
  hasRemoveConfirmation,
  isSpecificationUpdating,
  formContext,
  onOpenModal,
  setModalStyle,
  formData,
  propertyId,
  setSelectedField,
}) => {
  const {
    isTargetMode,
    chapterData,
    ownerCompanyId,
    modificationRequests,
    arraySchemaItemKey,
  } = formContext;

  const rowRef = useRef(null);

  const dispatch = useAppDispatch();

  const listItemKey = formData?.[index]?.[arraySchemaItemKey];

  const hasOnClick = useMemo(
    () =>
      canTargetField({
        isTargetMode,
        specOwnerCompanyId: ownerCompanyId,
        chapterOwnerCompanyId: chapterData?.ownerCompanyId,
      }) &&
      !doesFieldHaveModificationRequest({
        modificationRequests,
        propertyId,
        listItemKey,
      }),
    [
      modificationRequests,
      propertyId,
      listItemKey,
      ownerCompanyId,
      chapterData?.ownerCompanyId,
      isTargetMode,
    ]
  );

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    if ((event.target as HTMLElement).tagName === "LABEL") return;

    setSelectedField({
      listItemKey,
      propertyId,
    });

    const { left, top } = rowRef?.current?.getBoundingClientRect();

    const isOverFlowing =
      event.clientX + REQUEST_MODIFICATION_MODAL_WIDTH > window.innerWidth;

    setModalStyle({
      top: event.clientY - top,
      left: isOverFlowing ? "unset" : event.clientX - left,
      right: isOverFlowing ? 0 : "unset",
    });

    onOpenModal();

    dispatch(setRequestModification({ isEditorOpen: true }));
  };

  return (
    <Row
      className={classnames({
        [styles.arrayRow]: displayActions,
        [styles.inRevisionChange]: !isObjectEmpty(lastChange),
        "target-mode": canTargetField({
          isTargetMode,
          specOwnerCompanyId: ownerCompanyId,
          chapterOwnerCompanyId: chapterData?.ownerCompanyId,
        }),
      })}
      onClick={hasOnClick ? handleClick : null}
      ref={rowRef}
      key={key}
    >
      {!displayActions ? (
        <Col span={24}>{children}</Col>
      ) : (
        <>
          <Col span={22}>{children}</Col>
          <Col span={2} className={styles.deleteButton}>
            <RemoveAction
              hasRemove={hasRemove}
              hasRemoveConfirmation={hasRemoveConfirmation}
              onRemove={onDropIndexClick(index)}
              isSpecificationUpdating={isSpecificationUpdating}
            />
          </Col>
        </>
      )}
    </Row>
  );
};

export default withRequestModificationPopover(ArrayFieldTemplateItem);
