import { createContext, useReducer, useEffect } from "react";
import { setValidationDisabled } from "store/specificationDetails/specificationDetailsSlice";
import { useAppDispatch } from "store";
import { isListEmpty } from "utils/general";
import { NutritionContextState, Action } from "../types";

export const NutritionBasicContext = createContext(null);
export const NutritionBasicDispatchContext = createContext(null);

export const NutritionBasicProvider = ({
  children,
  sectionId,
  formContext,
  schema,
}) => {
  const appDispatch = useAppDispatch();

  const nutritionReducer = (state: NutritionContextState, action: Action) => {
    switch (action.type) {
      case "addFieldError":
        return {
          ...state,
          fieldErrors: [...state.fieldErrors, action.nutrientId],
        };
      case "removeFieldError":
        return {
          ...state,
          fieldErrors: state.fieldErrors.filter(id => id !== action.nutrientId),
        };
      default:
        return state;
    }
  };

  const [contextState, dispatch] = useReducer(nutritionReducer, {
    sectionId,
    formContext,
    schema,
    fieldErrors: [],
  });

  useEffect(() => {
    if (!isListEmpty(contextState?.fieldErrors)) {
      appDispatch(setValidationDisabled({ sectionId, isDisabled: true }));
    } else {
      appDispatch(setValidationDisabled({ sectionId, isDisabled: false }));
    }
  }, [contextState?.fieldErrors, sectionId]);

  return (
    <NutritionBasicContext.Provider value={contextState}>
      <NutritionBasicDispatchContext.Provider value={dispatch}>
        {children}
      </NutritionBasicDispatchContext.Provider>
    </NutritionBasicContext.Provider>
  );
};
