import { Icon } from "@trace-one/design-system";
import styles from "./styles.module.less";

const ModalCloseIcon = props => {
  return (
    <Icon
      className={styles.closeModalIcon}
      name="close"
      size="medium"
      {...props}
    />
  );
};

export default ModalCloseIcon;
