import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import {
  selectHasError,
  selectIsSharing,
} from "store/folderCreation/selectors";
import { shareFolder } from "store/folderCreation/asyncActions";
import { startConfirmedModal } from "pages/utils";
import { isListEmpty } from "utils/general";

const secondsForExpiringAfterShareFolder = 10;

const useShareFolderModal = ({
  startConfirmedSharingModal,
}: {
  startConfirmedSharingModal: boolean;
}) => {
  const dispatch = useDispatch();
  const isSharing = useSelector(selectIsSharing);
  const hasError = useSelector(selectHasError);

  const [isDone, setIsDone] = useState(false);
  const [specificationIds, setSpecificationIds] = useState<string[]>([]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setCookie] = useCookies(["specificationIdsFromFolderCreation"]);

  useEffect(() => {
    startConfirmedModal({
      isDone,
      isSharing,
      hasError,
      startConfirmedSharingModal,
    });
  }, [isSharing, hasError, isDone, startConfirmedSharingModal]);

  useEffect(() => {
    if (!isListEmpty(specificationIds)) {
      setCookie("specificationIdsFromFolderCreation", specificationIds, {
        maxAge: secondsForExpiringAfterShareFolder,
        path: "/",
      });
    }
  }, [JSON.stringify(specificationIds)]);

  const handleShareFolder = async () => {
    setIsDone(false);

    const data = await dispatch(shareFolder());

    // @ts-ignore
    setSpecificationIds(data.payload);
    setIsDone(true);
  };

  return { isSharing, hasError, isDone, handleShareFolder };
};

export default useShareFolderModal;
