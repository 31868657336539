import { Row, Col } from "antd";
import { getPropertiesObjectFromArray } from "../../templates/utils";
import { GuaranteedShelfLifeWidgetProps } from "./types";

const GuaranteedShelfLifeWidget: React.FC<GuaranteedShelfLifeWidgetProps> = ({
  properties,
  formData,
}) => {
  const propertyObjects = getPropertiesObjectFromArray(properties);

  return (
    <Row gutter={[24, 0]}>
      <Col xs={24} md={17}>
        {propertyObjects["guaranteedShelfLifeId"].content}
      </Col>
      {formData.guaranteedShelfLifeId && (
        <Col xs={24} md={7}>
          {propertyObjects["duration"].content}
        </Col>
      )}
    </Row>
  );
};

export default GuaranteedShelfLifeWidget;
