import { useState } from "react";
import { Row, Col } from "antd";
import RowTitle from "components/RowTitle";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { getPropertiesObjectFromArray } from "../../templates/utils";
import { isSeasonalNull } from "./utils";
import { SeasonalityWidgetProps } from "./types";
import ProductionSeasonalityWidgetReadOnly from "./components/ProductionSeasonalityWidgetReadOnly";

const SeasonalityWidget: React.FC<SeasonalityWidgetProps> = ({
  properties,
  uiSchema,
  formContext,
  formData,
}) => {
  const { displayActions, saveTemplateBlock, emptyValue } = formContext;
  const [showDates, setShowDates] = useState(isSeasonalNull(formData));
  const propertyObjects = getPropertiesObjectFromArray(properties);
  const { getTranslationForKey } = useFormSchemaTranslation();

  const colRadioSpans = {
    sm: displayActions ? 24 : 8,
    md: 8,
    lg: 8,
  };

  const colDatesSpans = {
    xs: displayActions ? 24 : 8,
    sm: displayActions ? 12 : 8,
    md: 16,
    lg: 16,
  };

  const handleSeasonalityClick = (value: boolean) => {
    setShowDates(value);

    saveTemplateBlock(
      JSON.stringify({
        seasonal: value,
      })
    );
  };

  return (
    <Row gutter={[10, displayActions ? 10 : 0]}>
      <Col span={24}>
        <RowTitle
          title={getTranslationForKey(uiSchema["ui:title"])}
          displayActions={displayActions}
        />
      </Col>

      {displayActions ? (
        <>
          <Col {...colRadioSpans}>
            {{
              ...propertyObjects?.["seasonal"]?.content,
              props: {
                ...propertyObjects?.["seasonal"]?.content?.props,
                onChange: handleSeasonalityClick,
              },
            }}
          </Col>
          {showDates && (
            <Col {...colDatesSpans}>
              {propertyObjects?.["seasonality"]?.content}
            </Col>
          )}
        </>
      ) : (
        <ProductionSeasonalityWidgetReadOnly
          propertyObjects={propertyObjects}
          emptyValue={emptyValue}
        />
      )}
    </Row>
  );
};

export default SeasonalityWidget;
