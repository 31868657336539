import { useEffect, useState, useRef } from "react";
import { useDebounce } from "@trace-one/business-components";
import { isObjectEmpty } from "utils/general";
import {
  FormContext,
  PropertyType,
} from "components/SpecificationSection/types";

const useTextActions = ({
  emptyValue,
  id,
  onChange,
  onFocus,
  min,
  value,
  type,
  precision,
  isTypingInProgress,
  onUpdateIsTypingInProgress,
}: {
  onChange: Function;
  emptyValue?: number | string;
  id?: string;
  onFocus?: Function;
  min?: number;
  value?: string | number;
  type?: PropertyType;
  precision?: number;
  isTypingInProgress: FormContext["isTypingInProgress"];
  onUpdateIsTypingInProgress: FormContext["onUpdateIsTypingInProgress"];
}) => {
  const isTypingInProgressRef = useRef(isTypingInProgress);

  const [inputValue, setInputValue] = useState(value);
  const [shouldOnChangeBeCalled, setShouldOnChangeBeCalled] = useState(false);

  const debouncedSearch = useDebounce(inputValue, 5000);

  useEffect(() => {
    isTypingInProgressRef.current = isTypingInProgress;
  }, [isTypingInProgress]);

  const handleChange = () => {
    if (
      !isTypingInProgressRef.current &&
      (inputValue === value ||
        (inputValue === "" && value === undefined) ||
        (type &&
          (type === "number" || type === "integer") &&
          (inputValue as number) < min))
    ) {
      onUpdateIsTypingInProgress?.(false);

      return;
    }

    onChange(inputValue === "" ? emptyValue : inputValue);
    setShouldOnChangeBeCalled(false);
  };

  const handleBlur = () => {
    setShouldOnChangeBeCalled(true);
  };

  const handleFocus = (value: React.FocusEvent<HTMLInputElement>) => {
    setShouldOnChangeBeCalled(false);

    if (typeof onFocus === "function") {
      if (!isObjectEmpty(value)) {
        onFocus(id, value.target.value);
      } else {
        onFocus(value);
      }
    }
  };

  const handleTextChange = value => {
    if (!isTypingInProgressRef.current) {
      onUpdateIsTypingInProgress?.(true);
    }
    let newValue = value;

    if (!isObjectEmpty(value)) {
      newValue = value.target.value;
    }
    setInputValue(newValue);
  };

  const handleInputNumberBlur = ({
    target: { value: newValue },
  }: React.FocusEvent<HTMLInputElement>) => {
    let updatedValue;

    if (Number.isInteger(precision)) {
      updatedValue = !newValue
        ? null
        : parseFloat(parseFloat(newValue).toFixed(precision));
    } else {
      updatedValue = !newValue ? null : parseFloat(newValue);
    }
    if (updatedValue !== value && inputValue !== undefined) {
      setInputValue(updatedValue);
      onChange(updatedValue);
      return;
    }
    // covers cases when we have an error and remove the value
    if (!newValue && value === null) {
      onChange(null);
    }
  };

  const handleInputNumberChange = (value: number | string | null) => {
    if (!isTypingInProgressRef.current) {
      onUpdateIsTypingInProgress?.(true);
    }
    setInputValue(value);
  };

  useEffect(() => {
    setShouldOnChangeBeCalled(true);
  }, [debouncedSearch]);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  useEffect(() => {
    if (shouldOnChangeBeCalled) {
      handleChange();
    }
  }, [shouldOnChangeBeCalled]);

  return {
    inputValue,
    handleBlur,
    handleFocus,
    handleInputNumberBlur,
    handleInputNumberChange,
    handleTextChange,
  };
};

export default useTextActions;
