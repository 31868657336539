import { ChapterTypeData } from "models";

export const prepareLibraryChapterTypes = (
  chapterTypes: ChapterTypeData[],
  productTypeId: string
) =>
  chapterTypes.reduce((previousState, currentChapterType) => {
    if (
      currentChapterType.productTypeId === productTypeId &&
      currentChapterType.isChapterLibrary
    ) {
      return [...previousState, currentChapterType.chapterType];
    }

    return [...previousState];
  }, []);
