import { State } from "./types";

const initialState: State = {
  generalInfo: {
    name: null,
    type: null,
    productTypeId: null,
    tagIds: [],
  },
  progressState: null,
  sections: [],
  structure: [],
  creationStatus: {
    inProgress: false,
    isDone: false,
    hasError: false,
  },
  updateStatus: {
    inProgress: false,
    isDone: false,
    hasError: false,
  },
  recipeIngredientsMaterial: {},
};

export default initialState;
