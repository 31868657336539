import { defineMessages } from "react-intl";

export const claimMessages = defineMessages({
  addTranslation: {
    id: "claim.addtranslation",
    defaultMessage: "Add a translation",
  },
  claimTypeLabel: {
    id: "claim.type.label",
    defaultMessage: "Claim type",
  },
  claimTypePlaceholder: {
    id: "claim.type.placeholder",
    defaultMessage: "Enter a claim type",
  },
  stepTwoTitle: {
    id: "claim.stepTwo.title",
    defaultMessage: "Configure the claim",
  },
  stepTwoDescription: {
    id: "claim.stepTwo.description",
    defaultMessage: "Select a claim type to start filling sections information",
  },
  claimSentenceLabel: {
    id: "claim.claimSentence.label",
    defaultMessage: "Write a claim sentence",
  },
  claimLogoLabel: {
    id: "claim.logo.label",
    defaultMessage: "Add a logo to your claim",
  },
  claimUploadFileTitle: {
    id: "claim.upload.title",
    defaultMessage: "You can add logo to complete your claim",
  },
  cancelClaimCreationTitle: {
    id: "claim.cancelCreation.title",
    defaultMessage: "Cancel claim creation",
  },
  cancelCreationDescription: {
    id: "claim.cancelCreation.description",
    defaultMessage:
      "By leaving now, you can either keep your claim creation or cancel and delete it.",
  },
  cancelSaveModalTitle: {
    id: "claim.cancelSave.title",
    defaultMessage: "Modified data not saved",
  },
  cancelSaveDescription: {
    id: "claim.cancelSave.description",
    defaultMessage:
      "Some changes have not been saved. If you leave the page the data changes may be lost.",
  },
  claimTitle: {
    id: "claim.generalInformation.claimTitle",
    defaultMessage: "Claim",
  },
  claimLogoTitle: {
    id: "claim.logo.title",
    defaultMessage: "Claim logo",
  },
  claimLogoEmptyState: {
    id: "claim.logo.emptyState",
    defaultMessage: "There is no claim logo added.",
  },
  claimSentenceTitle: {
    id: "claim.generalInformation.claimSentenceTitle",
    defaultMessage: "Claim sentence",
  },
  claimSentenceTranslationTitle: {
    id: "claim.claimSentence.translation.title",
    defaultMessage: "Claim sentence translation",
  },
  claimSentenceTranslationEmptyState: {
    id: "claim.claimSentence.translation.emptyState",
    defaultMessage: "There is no translation added.",
  },
  languageTitle: {
    id: "claim.claimSentence.language.title",
    defaultMessage: "Language",
  },
  saveAsDraft: {
    id: "claim.cancelCreation.action.saveAsDraft",
    defaultMessage: "Save as draft",
  },
  deleteClaim: {
    id: "claim.cancelCreation.action.deleteClaim",
    defaultMessage: "Delete claim",
  },
  claimsConfirmationToasterTitle: {
    id: "claim.confirmationToaster.title",
    defaultMessage: "Claims linked",
  },
  claimsConfirmationToasterDescription: {
    id: "claim.confirmationToaster.description",
    defaultMessage: "Claims have been successfully linked to the specification",
  },
  requiresSupportingDocument: {
    id: "claim.generalInformation.requiresSupportingDocument.label",
    defaultMessage: "This claim requires supporting document",
  },
});
