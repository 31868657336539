import { useEffect, useState } from "react";
import { fetchCompanies } from "apis/CUMD";
import { fetchTradeItems } from "apis/PMD";
import { isListEmpty } from "utils/general";
import useUserInfo from "hooks/useUserInfo";
import {
  FolderSpecificationData,
  QuickViewTradeItemData,
  SpecificationQuickViewData,
} from "models";

const useSpecificationsTradeItems = ({
  specifications,
  shouldFetch = true,
}: {
  specifications: FolderSpecificationData[] | SpecificationQuickViewData[];
  shouldFetch?: boolean;
}) => {
  const [tradeItems, setTradeItems] = useState<QuickViewTradeItemData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { isRetailer } = useUserInfo();

  const getTradeItems = async () => {
    try {
      setIsLoading(true);
      setHasError(false);

      let tradeItemsIds: SpecificationQuickViewData["tradeItemId"][] = [];
      let suppliersListIds: SpecificationQuickViewData["supplierId"][] = [];
      let ownerCompanyIds: SpecificationQuickViewData["ownerCompanyId"][] = [];

      specifications.forEach(({ tradeItemId, supplierId, ownerCompanyId }) => {
        tradeItemsIds.push(tradeItemId);
        suppliersListIds.push(supplierId);
        ownerCompanyIds.push(ownerCompanyId);
      });

      await Promise.all([
        fetchTradeItems({ ids: tradeItemsIds }),
        fetchCompanies({
          ids: isRetailer ? suppliersListIds : ownerCompanyIds,
        }),
      ]).then(result => {
        const [tradeItems, suppliers] = result;

        const specificationsWithTradeItemInformation = specifications.map(
          currentSpecification => {
            const currentSupplier = suppliers.data.find(
              ({ companyId }) =>
                companyId ===
                (isRetailer
                  ? currentSpecification.supplierId
                  : currentSpecification.ownerCompanyId)
            );

            if (currentSupplier) {
              currentSpecification = {
                ...currentSpecification,
                companyDisplayName: currentSupplier.companyDisplayName,
              };
            }

            const currentTradeItem = tradeItems.data.find(
              ({ id }) => id === currentSpecification.tradeItemId
            );

            return {
              tradeItemSpecifications: [currentSpecification],
              tradeItemId: currentTradeItem.id,
              tradeItemName: currentTradeItem.itemName,
              tradeItemGtin: currentTradeItem.gtin,
              tradeItemBrandName: currentTradeItem.brandName,
              tradeItemNetContents: currentTradeItem.netContents,
            };
          }
        );

        setTradeItems(specificationsWithTradeItemInformation);
      });
    } catch (e) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!isListEmpty(specifications) && shouldFetch) {
      getTradeItems();
    } else {
      setTradeItems([]);
    }
  }, [specifications, shouldFetch]);

  return {
    tradeItems,
    isLoading,
    hasError,
  };
};

export default useSpecificationsTradeItems;
