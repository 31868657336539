import { Col, Row } from "antd";
import { Button, Heading, Paragraph, Search } from "@trace-one/design-system";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { isListEmpty } from "utils/general";
import { selectOwningCompanyId } from "store/user/selectors";
import { useSelectedSuppliers, useTradeItemSuppliers } from "./hooks";
import ResultTable from "./components/ResultTable";
import { messages } from "./messages";
import styles from "../../../../styles.module.less";

const SelectSuppliersStepPanel = ({
  tradeItemId,
  onDuplicateSpecification,
  onModalClose,
}) => {
  const intl = useIntl();
  const owningCompanyId = useSelector(selectOwningCompanyId);

  const { selectedSuppliers, onSelectSupplier } = useSelectedSuppliers();

  const { supplierList, isLoading, handleSearch } = useTradeItemSuppliers({
    tradeItemId,
    owningCompanyId,
  });

  return (
    <>
      <Heading className={styles.title} size="l">
        <FormattedMessage {...messages.title} />
      </Heading>
      <Paragraph size="m" color="grey-3" className={styles.subtitle}>
        <FormattedMessage {...messages.subtitle} />
      </Paragraph>
      <Search
        className={styles.searchBar}
        placeholder={intl.formatMessage(messages.searchPlaceholder)}
        onSearch={handleSearch}
      />
      <ResultTable
        selectedSuppliers={selectedSuppliers}
        onSelectSupplier={onSelectSupplier}
        supplierList={supplierList}
        isLoading={isLoading}
      />
      <Row justify="center" gutter={[16, 0]} className={styles.validateRow}>
        <Col>
          <Button
            type="secondary"
            data-test-id="cancel-btn"
            onClick={onModalClose}
          >
            <FormattedMessage {...messages.cancel} />
          </Button>
        </Col>
        <Col>
          <Button
            data-test-id="create-specification-btn"
            disabled={isListEmpty(selectedSuppliers)}
            onClick={onDuplicateSpecification(selectedSuppliers)}
          >
            {intl.formatMessage(messages.createSpecificationCount, {
              count: selectedSuppliers.length,
            })}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default SelectSuppliersStepPanel;
