/* istanbul ignore file */

import axios from "axios";
import ReferenceListMasterDataApi from "@trace-one/api-clients.rlmd";
import { CategoryListData, ReferenceListItemData } from "models";
import { PORTAIL_ID } from "utils/constants";

export const axiosInstance = axios.create({
  baseURL: window.env.RLDM_URL,
});

export const RlmdAPI = new ReferenceListMasterDataApi(axiosInstance);

export const fetchCategoryLists = ({ companyId }: { companyId: string }) =>
  RlmdAPI.getCategoryListsByCompanyId({ companyId });

export const fetchCategoryList = ({
  languageCode,
  id,
}: {
  languageCode: string;
  id: string;
}) =>
  axiosInstance.get(`/api/categorylists/${id}`, {
    params: {
      portalTypeId: PORTAIL_ID,
      languageCode,
    },
  });

export const fetchCategoryItems = ({
  languageCode,
  ids,
}: {
  languageCode: string;
  ids: string[];
}) =>
  RlmdAPI.getCategoryItems(
    { idCollection: ids },
    {
      languageCode,
    }
  );

export const fetchCategoryItem = ({
  languageCode,
  id,
}: {
  languageCode: string;
  id: string;
}) =>
  RlmdAPI.getCategoryItemById(id, {
    languageCode,
  });

export const fetchCategoryListHierarchy = ({
  languageCode,
  categoryId,
}: {
  languageCode: string;
  categoryId: string;
}) =>
  RlmdAPI.getCategoryItemsByIdInHierarchy(categoryId, {
    languageCode,
  });

export const fetchCategoryListHierarchyV2 = ({
  languageCode,
  categoryId,
}: {
  languageCode: string;
  categoryId: string;
}) =>
  axiosInstance.get<CategoryListData>(
    `/api/categorylists/${categoryId}/hierarchy/v2`,
    {
      params: {
        languageCode,
      },
    }
  );

export const fetchCategoryListDirectChildren = ({
  languageCode,
  categoryId,
  parentId,
}: {
  languageCode: string;
  categoryId: string;
  parentId?: string;
}) =>
  RlmdAPI.getCategoryItemsByIdInDirectChildIds(categoryId, {
    languageCode,
    parentId,
  });

export const searchCategoryItems = ({
  ownerCompanyId,
  languageCode,
  itemName,
  includeInactive,
  categoryId,
  parentCategoryItemId,
}: {
  ownerCompanyId?: string;
  languageCode?: string;
  itemName?: string;
  includeInactive?: boolean;
  categoryId?: string;
  parentCategoryItemId?: string;
}) =>
  RlmdAPI.getCategoryItemsByFilters({
    ownerCompanyId,
    languageCode,
    itemName,
    includeInactive,
    categoryId,
    parentCategoryItemId,
  });

export const fetchReferenceListsItems = ({
  languageCode,
  ids,
}: {
  languageCode: string;
  ids: string[];
}) =>
  RlmdAPI.getReferenceListItemsByIdCollection(
    { idCollection: ids },
    {
      languageCode,
      portalTypeId: PORTAIL_ID,
    }
  );

export const fetchReferenceListItems = ({
  languageCode,
  id,
  take,
  skip,
  searchText,
  parentItemIds,
  ...params
}: {
  languageCode: string;
  id: string;
  take?: number;
  skip?: number;
  searchText?: string;
  parentItemIds?: string[];
}) =>
  axiosInstance.post<{
    referenceListItems: ReferenceListItemData[];
    skipAndTakeQueryStats: {
      currentCount: number;
      totalCount: number;
    };
  }>(
    `/api/referencelist/${id}/items/v2`,

    { parentItemIds },
    {
      params: {
        languageCode,
        portalTypeId: PORTAIL_ID,
        take,
        skip,
        searchText,
        sortByItemOrder: true,
        ...params,
      },
    }
  );

export const fetchReferenceListItemsByParentItemIds = ({
  languageCode,
  id,
  parentItemIds,
}: {
  languageCode: string;
  id: string;
  parentItemIds: string[];
}) =>
  RlmdAPI.getReferenceListItemsByReferenceListIdAndParentItemIds(
    id,
    { parentItemIds },
    {
      languageCode,
      portalTypeId: PORTAIL_ID,
      includeParentItems: true,
    }
  );

export const fetchReferenceListsItem = ({
  languageCode,
  id,
  companyId,
}: {
  languageCode: string;
  id: string;
  companyId?: string;
}) =>
  RlmdAPI.getReferenceListItemById(id, {
    languageCode,
    portalTypeId: PORTAIL_ID,
    companyId,
  });

export const fetchReferenceListItemsByName = ({
  languageCode,
  name,
  parentItemId,
  includeParentItems,
}: {
  languageCode: string;
  name: string;
  parentItemId?: string;
  includeParentItems?: boolean;
}) =>
  RlmdAPI.getReferenceListItemsByReferenceListName(name, {
    languageCode,
    portalTypeId: PORTAIL_ID,
    parentItemId,
    includeParentItems,
  });

export const fetchReferenceListItemByCode = ({
  id,
  code,
  languageCode,
}: {
  id: string;
  code: string;
  languageCode: string;
}) =>
  RlmdAPI.getReferenceListItemByIdAndItemCode(id, code, {
    languageCode,
    portalTypeId: PORTAIL_ID,
  });
