import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchUserApplications as fetchUserApplicationsApi,
  fetchUser as fetchUserApi,
  fetchUserCompany,
} from "apis/CUMD";
import { fetchReferenceListItemByCode } from "apis/RLMD";
import { CompanyData, UserApplicationData, UserData } from "models";
import { REFLIST_IDS } from "utils/constants";
import { AsyncThunkConfig } from "store";
import { LANGUAGES } from "utils/constants";

export const fetchUser = createAsyncThunk<
  UserData,
  UserData["userId"],
  AsyncThunkConfig
>("user/fetchUser", async (id, { getState }) => {
  const {
    oidc: { companyActivity },
  } = getState();
  const [user, companyActivityNameInEn] = await Promise.all([
    fetchUserApi({ id }).then(({ data }) => data),

    fetchReferenceListItemByCode({
      languageCode: LANGUAGES.ENGLISH,
      id: REFLIST_IDS.COMPANY_ACTIVITY,
      code: companyActivity,
    }).then(({ data }) => data.text),
  ]);
  return { ...user, companyActivityNameInEn };
});

export const fetchUserApplications = createAsyncThunk<
  UserApplicationData[],
  void
>("user/fetchUserApplications", async _ => {
  const {
    data: { userApplications },
  } = await fetchUserApplicationsApi();
  return userApplications;
});

export const fetchUserCompanyDisplayName = createAsyncThunk<
  CompanyData,
  UserData["userId"]
>("user/fetchUserCompanyDisplayName", async id => {
  const { data: company } = await fetchUserCompany({ id });
  return company;
});
