import { defineMessages } from "react-intl";

export const messages = defineMessages({
  productsCount: {
    id: "creationFolder.spec.requirements.products.count",
    defaultMessage:
      "Assigned to {count} {count , plural, one {product} other {products}}",
  },

  selectChapterLabel: {
    id: "creationFolder.spec.requirements.selectChapter.label",
    defaultMessage: "Choose a chapter where this requirement will appear",
  },

  selectedChapterLabel: {
    id: "creationFolder.spec.requirements.selectedChapter.label",
    defaultMessage: "Chapter where this requirement will be displayed",
  },

  errorFieldMessage: {
    id: "creation.errors.notSaved",
    defaultMessage: "An error has occurred, the last changes were not saved",
  },
});
