import { useEffect, useState } from "react";
import { FormInstance } from "antd";
import { useAppDispatch } from "store";
import { setTemplateFormData } from "store/templates/templateFormSlice";
import { isObjectEmpty } from "utils/general";
import { TemplateData } from "models";

const useTemplateFormInitialization = ({
  initialValues,
  form,
}: {
  initialValues: TemplateData;
  form?: FormInstance<any>;
}) => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const prepareTemplate = async () => {
      setIsLoading(true);

      if (!isObjectEmpty(initialValues)) {
        await dispatch(setTemplateFormData(initialValues));
        form?.setFieldsValue(initialValues?.generalInfo);
      }

      setIsLoading(false);
    };

    prepareTemplate();
  }, []);

  return { isLoading: isLoading };
};

export default useTemplateFormInitialization;
