import { isListEmpty } from "utils/general";
import { Property, PropertyType } from "components/SpecificationSection/types";

export const isDesiredPropertyType = (
  type: PropertyType,
  desiredType: string
) =>
  type === desiredType || (Array.isArray(type) && type.includes(desiredType));

export const isReflist = (value: Property) =>
  value?.referenceList?.getItemBy === "ItemId";

export const isHierarchy = (value: Property) => !!value?.hierarchyId;

export const getReflistIdsFromValue = (value: string | string[]) => {
  if (!value) return [];

  return Array.isArray(value) ? value : [value];
};

export const getReflistIdsfromArrayFormData = ({
  key,
  parentPaths,
}: {
  key: string;
  parentPaths: string[];
}) => (formData: any[]) => {
  return formData.reduce((previousState, currentItem) => {
    if (currentItem[key]) {
      return [...previousState, ...getReflistIdsFromValue(currentItem[key])];
    } else {
      if (currentItem?.[parentPaths[parentPaths.length - 1]]?.[key]) {
        return [
          ...previousState,
          ...getReflistIdsFromValue(
            currentItem[parentPaths[parentPaths.length - 1]][key]
          ),
        ];
      }
    }

    return previousState;
  }, []);
};

export const getReflistIdsFromFormData = ({
  formData,
  key,
  parentPaths,
}: {
  formData: any;
  key: string;
  parentPaths: string[];
}) => {
  const reflistIdsfromArrayFormData = getReflistIdsfromArrayFormData({
    key,
    parentPaths,
  });

  if (!isListEmpty(formData)) {
    return reflistIdsfromArrayFormData(formData);
  }

  if (!isListEmpty(formData?.[parentPaths[0]])) {
    return reflistIdsfromArrayFormData(formData[parentPaths[0]]);
  }

  if (formData?.[key]) {
    return getReflistIdsFromValue(formData[key]);
  }

  return [];
};
