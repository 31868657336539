import { FolderData, FolderSpecificationData } from "models";
import { isListEmpty } from "utils/general";

export const areTradeItemsAssociatedToFolder = ({
  tradeItemIds,
  specifications,
}: {
  tradeItemIds: FolderData["tradeItemIds"];
  specifications: FolderSpecificationData[];
}) => {
  if (isListEmpty(tradeItemIds)) return true;
  if (isListEmpty(specifications)) return false;

  const specTradeItemIds = specifications.map(({ tradeItemId }) => tradeItemId);

  return tradeItemIds.length === [...new Set(specTradeItemIds)].length;
};
