import Footer from "./components/Footer";
import Item from "./components/Item";
import SelectRetailerContacts from "./components/SelectRetailerContacts";
import Title from "./components/Title";
import Files from "./components/Files";

const CreationWizzard = {
  Footer,
  Item,
  SelectRetailerContacts,
  Title,
  Files,
};

export default CreationWizzard;
