import { defineMessages } from "react-intl";

export const messages = defineMessages({
  selectRequirementTitle: {
    id: "selectRequirements.step.selectRequirement.title",
    defaultMessage: "Select requirements",
  },
  selectRequirementSubtitle: {
    id: "selectRequirements.step.selectRequirement.subtitle",
    defaultMessage: "You can select one or multiple requirements",
  },
});
