import { Heading, Paragraph, Modal } from "@trace-one/design-system";
import { FormattedMessage } from "react-intl";
import { persistor } from "store";
import { getOnCreateHandler, getOnViewHandler } from "./utils";
import { messages } from "./messages";
import styles from "./styles.module.less";
import { ConfirmedSharingModalProps } from "./types";

const ConfirmedSharingModal: React.FC<ConfirmedSharingModalProps> = ({
  type,
  onCreateClick,
  onViewClick,
  name,
  titleMessage,
  contentMessage,
}) => {
  const onViewHandler = getOnViewHandler({
    purge: persistor.purge,
    onViewClick,
  });

  return (
    <Modal.Simple
      open
      onCancel={onViewHandler}
      wrapClassName={styles.confirmationSharingModal}
      illustrationName="confirmation"
      illustrationColor="grey"
      title={
        titleMessage ? (
          titleMessage
        ) : (
          <FormattedMessage {...messages["title"]} />
        )
      }
      primaryButtonText={<FormattedMessage {...messages[`${type}ViewBtn`]} />}
      onPrimaryButtonClick={onViewHandler}
      secondaryButtonText={
        <FormattedMessage {...messages[`${type}CreateBtn`]} />
      }
      onSecondaryButtonClick={getOnCreateHandler({
        purge: persistor.purge,
        onCreateClick,
      })}
    >
      <div>
        <Paragraph className={styles.content}>
          {contentMessage ? (
            contentMessage
          ) : (
            <FormattedMessage
              {...messages[`${type}ConfirmationMessage`]}
              values={{
                ...(type === "folder" && {
                  folderName: <span className={styles.primary}>{name}</span>,
                }),
              }}
            />
          )}
        </Paragraph>
        <div className={styles.desc}>
          <Heading size="xs">
            <FormattedMessage {...messages["decisionMessage"]} />
          </Heading>
        </div>
      </div>
    </Modal.Simple>
  );
};

export default ConfirmedSharingModal;
