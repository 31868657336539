import React from "react";
import { useIntl } from "react-intl";
import { Col, Form, Row } from "antd";
import { Input, Select } from "@trace-one/design-system";
import { getParsedData, isListEmpty } from "utils/general";
import SpecificationSection from "components/SpecificationSection";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { useBasicInformation } from "./hooks";
import { BasicInformationProps } from "./types";
import { getUsageLevelOptions } from "./utils";
import { PackagingSystemComponentObjectData } from "models";
import { useDebouncedActions } from "components/Debounced/hooks";
import { usePackaging } from "components/Packaging/hooks";
import libraryMessages from "messages/library";
import { messages } from "messages/packaging";
import { inputErrorMessages } from "messages/errors";
import { MAX_CHAR_LENGTH } from "utils/constants";

const BasicInformation: React.FC<BasicInformationProps> = ({
  sections,
  isEditable,
}) => {
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();
  const { getTranslationForKey } = useFormSchemaTranslation();
  const { onSaveTemplateBlock } = usePackaging();
  const {
    initialValues,
    hasFormErrors,
    setBasicInformationFormErrors,
    onChangeName,
    onChangeUsageLevel,
    errorMessageKey,
  } = useBasicInformation(form);

  const handleChangeName = () => {
    !hasFormErrors && onChangeName({ value: currentValue as string });
  };

  const { currentValue, onTextChange } = useDebouncedActions({
    defaultValue: initialValues["packaging-component-name"],
    onChange: handleChangeName,
    debounceTimer: 500,
  });

  const handleChangeUsageLevel = (
    value: PackagingSystemComponentObjectData["usageLevel"]
  ) => {
    !hasFormErrors && onChangeUsageLevel({ value });
  };

  const usageLevelOptions = getUsageLevelOptions(
    ["primary", "secondary", "tertiary"],
    formatMessage
  );

  const colSpans = {
    labelCol: { xs: 6 },
    wrapperCol: { xs: 10 },
  };

  if (isListEmpty(sections)) {
    return null;
  }

  return (
    <>
      {sections.map(({ sectionBlocks, sectionId }) => {
        return sectionBlocks.map(
          ({ dataAsJson, jsonSchema, uiSchema, templateBlockId }) => {
            const formData = getParsedData(dataAsJson);

            return (
              <React.Fragment key={templateBlockId}>
                <Form
                  name="basicInformationForm"
                  initialValues={initialValues}
                  form={form}
                  onFieldsChange={() =>
                    setBasicInformationFormErrors(form.getFieldsError())
                  }
                  labelAlign="left"
                  validateMessages={{
                    required: formatMessage(libraryMessages.formRequired),
                    whitespace: formatMessage(libraryMessages.formRequired),
                  }}
                  {...colSpans}
                >
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        rules={[
                          {
                            max: MAX_CHAR_LENGTH,
                            whitespace: true,
                            required: true,
                          },
                          {
                            validator: _ => {
                              if (!errorMessageKey) {
                                return Promise.resolve();
                              }

                              return Promise.reject(
                                new Error(
                                  formatMessage(
                                    inputErrorMessages[errorMessageKey]
                                  )
                                )
                              );
                            },
                          },
                        ]}
                        name="packaging-component-name"
                        label={formatMessage(
                          messages.packagingComponentNameLabel
                        )}
                      >
                        <Input
                          allowClear={false}
                          data-test-id="packaging-component-name"
                          value={currentValue}
                          onChange={onTextChange}
                          disabled={!isEditable}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name="packaging-component-usage-level"
                        label={formatMessage(
                          messages.packagingComponentUsageLevelLabel
                        )}
                        rules={[{ required: true }]}
                      >
                        <Select
                          allowClear={false}
                          data-test-id="packaging-component-usage-level"
                          options={usageLevelOptions}
                          placeholder={getTranslationForKey(
                            "refListPlaceholder"
                          )}
                          onChange={handleChangeUsageLevel}
                          disabled={!isEditable}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>

                <SpecificationSection.Block
                  key={templateBlockId}
                  sectionId={sectionId}
                  templateBlockId={templateBlockId}
                  uiSchema={getParsedData(uiSchema)}
                  jsonSchema={getParsedData(jsonSchema)}
                  formData={formData}
                  saveTemplateBlock={onSaveTemplateBlock(templateBlockId)}
                  hasPermissionToSave={isEditable}
                />
              </React.Fragment>
            );
          }
        );
      })}
    </>
  );
};

export default BasicInformation;
