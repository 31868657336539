import { defineMessages } from "react-intl";

export const messages = defineMessages({
  modalTitle: {
    id: "library.packagingSystemForm.addComponent.modalTitle",
    defaultMessage: "Browse existing components",
  },
  modalSubTitle: {
    id: "library.packagingSystemForm.addComponent.modalSubTitle",
    defaultMessage: "Add one or more components to you packaging system",
  },
  modalBodyTitle: {
    id: "library.packagingSystemForm.addComponent.modalBodyTitle",
    defaultMessage: "Components list",
  },
  searchPlaceholder: {
    id: "library.packagingSystemForm.addComponent.searchPlaceholder",
    defaultMessage: "Enter a component name",
  },
});
