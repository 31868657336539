import { isListEmpty } from "utils/general";
import { CultivationOriginFormData } from "../../types";

export const hasAlternativeOrigins = (data: CultivationOriginFormData) => {
  if (data === null) {
    return false;
  } else {
    const { acceptsAlternativeOrigin = false, alternativeOrigins } = data;
    return acceptsAlternativeOrigin && !isListEmpty(alternativeOrigins);
  }
};
