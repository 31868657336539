import { useState } from "react";
import { useIntl } from "react-intl";
import { toaster as toasterService } from "@trace-one/design-system";
import { unlinkChapterToSpecification } from "apis/SPEC";
import { useBoolean } from "hooks";
import { SpecificationData } from "models";
import { messages } from "../../messages";

const useUnlinkChapter = ({
  specificationId,
  specificationName,
  chapterType,
  chapterTypeName,
  afterUnlinkCallback,
}: {
  specificationId: SpecificationData["id"];
  specificationName: string;
  chapterType: string;
  chapterTypeName: string;
  afterUnlinkCallback: Function;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { value: isOpen, setFalse: onClose, setTrue } = useBoolean(false);
  const { formatMessage } = useIntl();

  const onUnlinkChapterToSpecification = async () => {
    try {
      setIsLoading(true);

      await unlinkChapterToSpecification({
        specificationId,
        chapterType,
      });

      toasterService.open({
        message: formatMessage(messages.chapterUnlinkedSuccessTitle),
        description: formatMessage(messages.chapterUnlinkedSuccessDescription, {
          chapterType: chapterTypeName,
          specificationName,
        }),
        type: "confirmation",
      });

      if (typeof afterUnlinkCallback === "function") {
        afterUnlinkCallback();
      }

      onClose();
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    isOpen,
    onUnlinkChapterToSpecification,
    onOpenUnlinkModal: setTrue,
    onClose,
  };
};

export default useUnlinkChapter;
