import { Form, Input, Row, Col } from "antd";
import { useIntl } from "react-intl";
import { Heading, TextArea } from "@trace-one/design-system";
import { UploadFiles } from "@trace-one/business-components";
import { useDocument } from "./hooks";
import { useDebouncedActions } from "components/Debounced/hooks";
import { useRequirementInfoActions } from "components/Library/components/RequirementForm/hooks";
import { FILE_TYPES } from "utils/constants";
import { isObjectEmpty } from "utils/general";
import libraryMessages from "messages/library";
import { DocumentProps } from "./types";
import styles from "../../styles.module.less";

const Document: React.FC<DocumentProps> = ({
  additionalInformation,
  fileId,
  isLocked,
  displayActions = true,
}) => {
  const { formatMessage } = useIntl();
  const form = Form.useFormInstance();
  const {
    onUploadFiles,
    onSaveAdditionalInformation,
  } = useRequirementInfoActions();

  const {
    onTextareaChange: onAdditionalInformationChange,
    handleDebouncedValueChange: onAdditionalInformationBlur,
  } = useDebouncedActions({
    defaultValue: additionalInformation,
    onChange: onSaveAdditionalInformation,
  });

  const { currentFile, onRemove, onUploadFile } = useDocument({
    fileId,
    form,
    onUploadFiles,
  });

  if (!displayActions) {
    return (
      <>
        <div className={styles.contentInfo}>
          <Heading size="xxs">
            {formatMessage(libraryMessages.documentLabel)}
          </Heading>
          {!isObjectEmpty(currentFile) ? (
            <Row>
              <Col
                {...{
                  xs: 24,
                  sm: 12,
                  md: 8,
                }}
              >
                <UploadFiles.Item
                  fileId={currentFile.id}
                  fileName={currentFile.name}
                  fileSize={currentFile.size}
                />
              </Col>
            </Row>
          ) : null}
        </div>
        <div className={styles.contentInfo}>
          <Heading size="xxs">
            {formatMessage(libraryMessages.additionalInformationLabel)}
          </Heading>
          <div
            dangerouslySetInnerHTML={{
              __html: additionalInformation,
            }}
          />
        </div>
      </>
    );
  }

  return (
    <>
      <Form.Item
        wrapperCol={{
          span: 12,
        }}
        label={formatMessage(libraryMessages.fileLabel)}
        required
      >
        <Form.Item
          required
          hidden
          name="fileId"
          noStyle
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <UploadFiles.Dragger
          disabled={isLocked}
          fileType={FILE_TYPES.REQUIREMENT}
          fileList={isObjectEmpty(currentFile) ? [] : [currentFile]}
          multiple={false}
          dmsControl={false}
          onUploadFile={onUploadFile}
          maxCount={1}
          {...(!isLocked && {
            onRemove,
          })}
        />
      </Form.Item>
      <Form.Item
        wrapperCol={{
          span: 10,
        }}
        rules={[
          { whitespace: true },
          {
            max: 1024,
            message: formatMessage(libraryMessages.formMaxCharLength, {
              max: 1024,
            }),
          },
        ]}
        name="additionalInformation"
        label={formatMessage(libraryMessages.additionalInformationLabel)}
      >
        <TextArea
          value={additionalInformation}
          disabled={isLocked}
          onChange={onAdditionalInformationChange}
          onBlur={onAdditionalInformationBlur}
          placeholder={formatMessage(
            libraryMessages.additionalInformationPlaceholder
          )}
        />
      </Form.Item>
    </>
  );
};

export default Document;
