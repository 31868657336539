import { defineMessages } from "react-intl";

export const quickViewMessages = defineMessages({
  status: {
    id: "status",
    defaultMessage: "Status",
  },

  inprogress: {
    id: "status.inProgress",
    defaultMessage: "In progress",
  },

  folderQuickviewHeader: {
    id: "folderListing.quickview.header",
    defaultMessage:
      "{nbOfSpec} {nbOfSpec, plural, one {specification} other {specifications}} ({nbOfSection} {nbOfSection, plural, one {section} other {sections}})",
  },

  specQuickviewHeader: {
    id: "specificationListing.quickview.header",
    defaultMessage:
      "History ({historyNumber} {historyNumber, plural, one {version} other {versions}})",
  },

  folderQuickviewError: {
    id: "folderListing.quickview.error",
    defaultMessage: "Sorry, an error occured while accessing folder data",
  },

  specQuickviewError: {
    id: "specificationListing.quickview.error",
    defaultMessage:
      "Sorry, an error occured while accessing specification data",
  },

  progressListQuickviewError: {
    id: "quickview.progressList.error",
    defaultMessage: "There was an error retrieving the Specification data",
  },

  currentVersion: {
    id: "specificationListing.quickview.currentVersion",
    defaultMessage: "Active version",
  },

  inRevisionVersion: {
    id: "specificationListing.quickview.inRevisionVersion",
    defaultMessage: "Version in revision",
  },

  allChapters: {
    id: "quickview.allChapters",
    defaultMessage: "All chapters",
  },

  progressListQuickviewTitle: {
    id: "quickview.progressList.title",
    defaultMessage: "Progress",
  },
  chapterQuickViewHeader: {
    id: "quickview.progressList.chapterQuickViewHeader",
    defaultMessage: "Linked Specification {nbOfSpec} Links",
  },
});
