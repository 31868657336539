import FilterTag from "./components/FilterTag";
import { FilterTagsProps } from "./types";
import styles from "./styles.module.less";

const FilterTags: React.FC<FilterTagsProps> = ({ filterTags, disabled }) => {
  return (
    <div className={styles.filtersTagsWrapper}>
      {filterTags.chapters.map(filterTag => (
        <FilterTag
          key={filterTag.value}
          filterTag={filterTag}
          disabled={disabled}
        />
      ))}
    </div>
  );
};

export default FilterTags;
