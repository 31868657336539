import { SVGProps } from "react";

const NutriScoreE = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="240"
    height="130"
    version="1.1"
    viewBox="0 0 744.094 1052.362"
    data-test-id="nutri-score-e"
    data-testid="nutri-score-e"
    {...props}
  >
    <g strokeOpacity="1">
      <path
        fill="#fff"
        fillOpacity="1"
        fillRule="evenodd"
        stroke="none"
        strokeDasharray="none"
        strokeDashoffset="0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="4"
        strokeWidth="1"
        d="M-419.315 0H1163.41c99.748 0 180.05 80.302 180.05 180.05v632.48c0 99.748-80.302 180.05-180.05 180.05H-419.315c-99.747 0-180.049-80.302-180.049-180.05V180.05C-599.364 80.301-519.062 0-419.314 0z"
        opacity="1"
      ></path>
      <path
        fill="#038141"
        fillOpacity="1"
        fillRule="evenodd"
        stroke="none"
        strokeDasharray="none"
        strokeDashoffset="0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="4"
        strokeWidth="1"
        d="M-308.515 357.79h363.03v586.315h-363.03z"
        opacity="1"
      ></path>
      <path
        fill="#e63e11"
        fillOpacity="1"
        fillRule="evenodd"
        stroke="none"
        strokeDasharray="none"
        strokeDashoffset="0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="4"
        strokeWidth="1"
        d="M748.698 357.79h363.03v586.315h-363.03z"
        opacity="1"
      ></path>
      <path
        fill="#038141"
        fillOpacity="1"
        fillRule="evenodd"
        stroke="none"
        strokeDasharray="none"
        strokeDashoffset="0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="4"
        strokeWidth="1"
        d="M-382.381 357.79h90.647c75.45 0 136.192 60.742 136.192 136.191v313.933c0 75.45-60.742 136.191-136.192 136.191h-90.647c-75.45 0-136.191-60.741-136.191-136.191V493.98c0-75.45 60.74-136.19 136.19-136.19z"
        opacity="1"
      ></path>
      <path
        fill="#ee8100"
        fillOpacity="1"
        fillRule="evenodd"
        stroke="none"
        strokeDasharray="none"
        strokeDashoffset="0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="4"
        strokeWidth="1"
        d="M570.957 357.79h363.03v586.315h-363.03z"
        opacity="1"
      ></path>
      <path
        style={{ lineHeight: "89.99999762%" }}
        fill="#fff"
        fillOpacity="0.446"
        stroke="none"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeWidth="1"
        d="M600.08 779.804V514.002h99.209q32.944 0 57.653 10.482 24.708 10.482 41.18 28.452 16.847 17.97 25.083 42.304 8.61 23.96 8.61 51.288 0 30.324-9.359 55.033-9.359 24.334-26.954 41.93-17.221 17.22-41.93 26.954-24.334 9.359-54.283 9.359H600.08zm169.216-133.276q0-17.595-4.867-31.821-4.492-14.6-13.477-25.083-8.985-10.482-22.088-16.098-13.103-5.615-29.575-5.615h-37.811v157.984h37.81q16.848 0 29.95-5.99 13.103-5.99 21.714-16.472 8.985-10.857 13.477-25.083 4.867-14.6 4.867-31.822z"
        fontFamily="Raleway"
        fontSize="medium"
        fontStretch="normal"
        fontStyle="normal"
        fontVariant="normal"
        fontWeight="800"
        letterSpacing="0"
        wordSpacing="0"
      ></path>
      <path
        fill="#fecb02"
        fillOpacity="1"
        fillRule="evenodd"
        stroke="none"
        strokeDasharray="none"
        strokeDashoffset="0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="4"
        strokeWidth="1"
        d="M188.26 357.79h383.32v586.315H188.26z"
        opacity="1"
      ></path>
      <path
        fill="#85bb2f"
        fillOpacity="1"
        fillRule="evenodd"
        stroke="none"
        strokeDasharray="none"
        strokeDashoffset="0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="4"
        strokeWidth="1"
        d="M206.865 357.79h-363.03v586.315h363.03z"
        opacity="1"
      ></path>
      <path
        style={{ lineHeight: "89.99999762%" }}
        fill="#fff"
        fillOpacity="0.446"
        stroke="none"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeWidth="1"
        d="M258.811 642.41q0-23.96 8.985-47.545 8.985-23.96 26.206-42.678 17.22-18.718 41.93-30.324 24.708-11.605 56.155-11.605 37.437 0 64.765 16.098 27.704 16.098 41.181 41.93l-47.17 32.944q-4.493-10.483-11.606-17.221-6.739-7.113-14.975-11.231-8.236-4.493-16.846-5.99-8.61-1.872-16.847-1.872-17.595 0-30.698 7.113-13.103 7.113-21.714 18.344-8.61 11.231-12.728 25.457t-4.118 28.827q0 15.723 4.867 30.323 4.866 14.6 13.851 25.832 9.36 11.231 22.088 17.97 13.103 6.364 29.2 6.364 8.237 0 16.847-1.872 8.985-2.246 16.847-6.364 8.236-4.493 14.975-11.231 6.738-7.113 10.857-17.221l50.165 29.575q-5.99 14.6-17.97 26.206-11.605 11.605-26.954 19.467-15.35 7.862-32.57 11.98-17.221 4.118-33.694 4.118-28.826 0-53.16-11.605-23.96-11.98-41.555-31.448-17.221-19.467-26.955-44.175-9.359-24.709-9.359-50.166z"
        fontFamily="Raleway"
        fontSize="medium"
        fontStretch="normal"
        fontStyle="normal"
        fontVariant="normal"
        fontWeight="800"
        letterSpacing="0"
        wordSpacing="0"
      ></path>
      <path
        style={{ lineHeight: "89.99999762%" }}
        fill="#fff"
        fillOpacity="0.446"
        stroke="none"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeWidth="1"
        d="M154.552 711.669q0 17.22-7.113 29.95-7.113 12.728-19.467 21.338-12.354 8.236-28.826 12.729-16.473 4.118-34.817 4.118H-65.203V514.002H82.673q13.852 0 25.083 5.99 11.231 5.99 19.093 15.723 7.862 9.36 11.98 21.713 4.492 11.98 4.492 24.709 0 19.093-9.733 35.94-9.36 16.846-28.452 25.456 22.836 6.739 35.939 23.96 13.477 17.221 13.477 44.176zm-62.52-12.355q0-12.354-7.112-20.964-7.113-8.61-17.97-8.61H-3.806v58.027H64.33q11.98 0 19.842-7.862 7.862-7.862 7.862-20.59zm-95.838-132.9v55.032h60.273q10.108 0 17.97-6.739 7.862-6.739 7.862-20.965 0-13.103-7.113-20.216-6.739-7.113-16.472-7.113h-62.52z"
        fontFamily="Raleway"
        fontSize="medium"
        fontStretch="normal"
        fontStyle="normal"
        fontVariant="normal"
        fontWeight="800"
        letterSpacing="0"
        wordSpacing="0"
      ></path>
      <path
        fill="#0039ff"
        fillOpacity="0.63"
        fillRule="evenodd"
        stroke="#fff"
        strokeDasharray="none"
        strokeDashoffset="0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="4"
        strokeWidth="61.032"
        d="M1075.638 281.616h39.327c108.699 0 196.207 87.508 196.207 196.207v343.941c0 108.699-87.508 196.208-196.207 196.208h-39.327c-108.7 0-196.208-87.509-196.208-196.208v-343.94c0-108.7 87.509-196.208 196.208-196.208z"
        opacity="1"
      ></path>
      <path
        style={{ lineHeight: "89.99999762%" }}
        fill="#fff"
        fillOpacity="0.446"
        stroke="none"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeWidth="1"
        d="M-384.712 514.002h55.406l96.962 265.802h-62.894l-20.59-59.525h-82.736l-20.216 59.525h-62.894l96.962-265.802zm58.776 163.973l-31.073-93.966-31.821 93.966h62.894z"
        fontFamily="Raleway"
        fontSize="medium"
        fontStretch="normal"
        fontStyle="normal"
        fontVariant="normal"
        fontWeight="800"
        letterSpacing="0"
        wordSpacing="0"
      ></path>
      <path
        fill="#e63e11"
        fillOpacity="1"
        fillRule="evenodd"
        stroke="none"
        strokeDasharray="none"
        strokeDashoffset="0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="4"
        strokeWidth="1"
        d="M1069.556 357.79h90.647c75.45 0 136.192 60.742 136.192 136.191v313.933c0 75.45-60.742 136.191-136.192 136.191h-90.647c-75.45 0-136.192-60.741-136.192-136.191V493.98c0-75.45 60.742-136.19 136.192-136.19z"
        opacity="1"
      ></path>
      <path
        style={{ lineHeight: "89.99999762%" }}
        fill="#fff"
        fillOpacity="0.446"
        stroke="none"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeWidth="1"
        d="M1188.707 725.895v53.909h-186.81V514.002h183.44v53.909h-122.044v51.663h104.824v49.79h-104.824v56.53h125.414z"
        fontFamily="Raleway"
        fontSize="medium"
        fontStretch="normal"
        fontStyle="normal"
        fontVariant="normal"
        fontWeight="800"
        letterSpacing="0"
        wordSpacing="0"
      ></path>
      <path
        fill="#e63e11"
        fillOpacity="1"
        fillRule="evenodd"
        stroke="none"
        strokeDasharray="none"
        strokeDashoffset="0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="4"
        strokeWidth="15.945"
        d="M1075.638 281.616h39.327c108.699 0 196.207 87.508 196.207 196.207v343.941c0 108.699-87.508 196.208-196.207 196.208h-39.327c-108.7 0-196.208-87.509-196.208-196.208v-343.94c0-108.7 87.509-196.208 196.208-196.208z"
        opacity="1"
      ></path>
      <path
        style={{ lineHeight: "89.99999762%" }}
        fill="#fff"
        fillOpacity="1"
        stroke="none"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeWidth="1"
        d="M1188.343 761.116v71.075H962.419V481.753h221.849v71.075H1036.67v68.113h126.77v65.646h-126.77v74.53h151.673z"
        fontFamily="Raleway"
        fontSize="medium"
        fontStretch="normal"
        fontStyle="normal"
        fontVariant="normal"
        fontWeight="800"
        letterSpacing="0"
        wordSpacing="0"
      ></path>
      <path
        style={{ lineHeight: "89.99999762%" }}
        fill="#7d7d7d"
        fillOpacity="1"
        stroke="none"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeWidth="1"
        d="M-443.389 163.147v76.827h-31.033V105.623h24.22l62.635 78.907v-78.907h31.033v134.35h-24.978l-61.877-76.826z"
        fontFamily="Raleway"
        fontSize="medium"
        fontStretch="normal"
        fontStyle="normal"
        fontVariant="normal"
        fontWeight="800"
        letterSpacing="0"
        wordSpacing="0"
      ></path>
      <path
        style={{ lineHeight: "89.99999762%" }}
        fill="#7d7d7d"
        fillOpacity="1"
        stroke="none"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeWidth="1"
        d="M-272.94 213.482q7.947 0 13.435-3.217 5.488-3.406 8.894-8.894 3.406-5.487 4.73-12.489 1.514-7.19 1.514-14.57v-68.69h31.033v68.69q0 14.003-3.595 26.113-3.406 12.11-10.786 21.194-7.19 9.082-18.544 14.38-11.165 5.11-26.681 5.11-16.084 0-27.438-5.488-11.354-5.487-18.544-14.57-7.002-9.272-10.408-21.383-3.216-12.11-3.216-25.356v-68.69h31.033v68.69q0 7.758 1.514 14.76 1.513 7 4.92 12.488 3.405 5.488 8.704 8.705 5.487 3.217 13.435 3.217z"
        fontFamily="Raleway"
        fontSize="medium"
        fontStretch="normal"
        fontStyle="normal"
        fontVariant="normal"
        fontWeight="800"
        letterSpacing="0"
        wordSpacing="0"
      ></path>
      <path
        style={{ lineHeight: "89.99999762%" }}
        fill="#7d7d7d"
        fillOpacity="1"
        stroke="none"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeWidth="1"
        d="M-87.102 132.871h-40.873v107.103h-31.033V132.87h-41.062v-27.248h112.968v27.248z"
        fontFamily="Raleway"
        fontSize="medium"
        fontStretch="normal"
        fontStyle="normal"
        fontVariant="normal"
        fontWeight="800"
        letterSpacing="0"
        wordSpacing="0"
      ></path>
      <path
        style={{ lineHeight: "89.99999762%" }}
        fill="#7d7d7d"
        fillOpacity="1"
        stroke="none"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeWidth="1"
        d="M-71.946 239.974V105.623h60.553q9.461 0 17.409 3.973 8.136 3.974 14.002 10.408 5.866 6.433 9.083 14.57 3.406 8.137 3.406 16.463 0 6.244-1.513 12.11-1.514 5.677-4.353 10.786-2.838 5.11-7.001 9.273-3.974 3.973-9.083 6.812l29.52 49.956H5.068l-25.734-43.333h-20.248v43.333h-31.033zm31.033-70.393h28.384q5.488 0 9.462-5.109 3.973-5.298 3.973-13.435 0-8.326-4.541-13.246t-9.84-4.92h-27.438v36.71z"
        fontFamily="Raleway"
        fontSize="medium"
        fontStretch="normal"
        fontStyle="normal"
        fontVariant="normal"
        fontWeight="800"
        letterSpacing="0"
        wordSpacing="0"
      ></path>
      <path
        style={{ lineHeight: "89.99999762%" }}
        fill="#7d7d7d"
        fillOpacity="1"
        stroke="none"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeWidth="1"
        d="M54.821 239.974V105.623h31.033v134.35H54.821z"
        fontFamily="Raleway"
        fontSize="medium"
        fontStretch="normal"
        fontStyle="normal"
        fontVariant="normal"
        fontWeight="800"
        letterSpacing="0"
        wordSpacing="0"
      ></path>
      <path
        style={{ lineHeight: "89.99999762%" }}
        fill="#7d7d7d"
        fillOpacity="1"
        stroke="none"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeWidth="1"
        d="M109.088 201.56v-27.248h58.282v27.248h-58.282z"
        fontFamily="Raleway"
        fontSize="medium"
        fontStretch="normal"
        fontStyle="normal"
        fontVariant="normal"
        fontWeight="800"
        letterSpacing="0"
        wordSpacing="0"
      ></path>
      <path
        style={{ lineHeight: "89.99999762%" }}
        fill="#7d7d7d"
        fillOpacity="1"
        stroke="none"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeWidth="1"
        d="M271.069 144.982q-.568-.757-3.974-2.839-3.406-2.081-8.515-4.352-5.11-2.27-11.164-3.974-6.056-1.703-12.11-1.703-16.653 0-16.653 11.165 0 3.406 1.703 5.676 1.892 2.271 5.299 4.163 3.595 1.703 8.893 3.217 5.299 1.514 12.3 3.406 9.65 2.65 17.409 5.866 7.758 3.028 13.056 7.759 5.488 4.541 8.326 11.164 3.028 6.623 3.028 15.895 0 11.354-4.352 19.301-4.163 7.759-11.165 12.678-7 4.731-16.084 7.002-9.083 2.081-18.733 2.081-7.38 0-15.138-1.135-7.759-1.135-15.138-3.217-7.38-2.27-14.382-5.298-6.812-3.028-12.678-7.002l13.624-27.059q.757.946 4.92 3.595 4.163 2.65 10.219 5.299 6.244 2.649 13.813 4.73 7.57 2.082 15.327 2.082 16.463 0 16.463-10.03 0-3.784-2.46-6.244t-6.812-4.352q-4.352-2.081-10.407-3.785-5.866-1.703-12.868-3.784-9.272-2.838-16.084-6.055-6.812-3.406-11.354-7.759-4.352-4.352-6.623-10.029-2.081-5.676-2.081-13.245 0-10.597 3.974-18.734 3.973-8.137 10.785-13.624 6.813-5.677 15.706-8.515 9.083-2.839 19.112-2.839 7.002 0 13.814 1.325 6.812 1.324 13.056 3.406 6.245 2.081 11.543 4.73 5.488 2.65 10.03 5.299l-13.625 25.735z"
        fontFamily="Raleway"
        fontSize="medium"
        fontStretch="normal"
        fontStyle="normal"
        fontVariant="normal"
        fontWeight="800"
        letterSpacing="0"
        wordSpacing="0"
      ></path>
      <path
        style={{ lineHeight: "89.99999762%" }}
        fill="#7d7d7d"
        fillOpacity="1"
        stroke="none"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeWidth="1"
        d="M299.119 171.663q0-12.11 4.541-24.032 4.542-12.11 13.246-21.572 8.705-9.461 21.194-15.327 12.488-5.866 28.384-5.866 18.922 0 32.736 8.136 14.003 8.137 20.815 21.194l-23.843 16.652q-2.27-5.299-5.866-8.705-3.406-3.595-7.569-5.677-4.163-2.27-8.515-3.027-4.352-.946-8.515-.946-8.894 0-15.517 3.595-6.623 3.596-10.975 9.272-4.352 5.677-6.434 12.868-2.081 7.19-2.081 14.57 0 7.948 2.46 15.328 2.46 7.38 7.001 13.056 4.73 5.677 11.164 9.083 6.623 3.217 14.76 3.217 4.163 0 8.515-.946 4.542-1.135 8.515-3.217 4.163-2.27 7.57-5.677 3.406-3.595 5.487-8.704l25.356 14.949q-3.027 7.38-9.082 13.246-5.866 5.866-13.625 9.84-7.758 3.973-16.462 6.055-8.705 2.081-17.03 2.081-14.571 0-26.871-5.866-12.11-6.055-21.004-15.895-8.705-9.84-13.624-22.329-4.731-12.489-4.731-25.356z"
        fontFamily="Raleway"
        fontSize="medium"
        fontStretch="normal"
        fontStyle="normal"
        fontVariant="normal"
        fontWeight="800"
        letterSpacing="0"
        wordSpacing="0"
      ></path>
      <path
        style={{ lineHeight: "89.99999762%" }}
        fill="#7d7d7d"
        fillOpacity="1"
        stroke="none"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeWidth="1"
        d="M494.342 241.109q-14.95 0-27.249-5.866-12.3-5.866-21.004-15.328-8.705-9.65-13.624-21.95-4.731-12.3-4.731-25.356 0-13.246 4.92-25.546 5.109-12.3 14.003-21.572 9.082-9.461 21.382-14.949 12.3-5.676 26.87-5.676 14.95 0 27.249 5.866 12.3 5.866 21.004 15.516 8.705 9.65 13.435 21.95 4.73 12.3 4.73 24.978 0 13.246-5.108 25.546-4.92 12.3-13.814 21.761-8.893 9.272-21.193 14.95-12.3 5.676-26.87 5.676zm-35.007-68.122q0 7.759 2.27 15.138 2.271 7.191 6.623 12.868 4.542 5.677 11.165 9.083 6.623 3.406 15.138 3.406 8.894 0 15.516-3.596 6.623-3.595 10.976-9.272 4.352-5.866 6.433-13.056 2.271-7.38 2.271-14.95 0-7.758-2.27-14.948-2.271-7.38-6.813-12.868-4.541-5.676-11.164-8.893-6.434-3.406-14.95-3.406-8.893 0-15.516 3.595-6.433 3.406-10.975 9.083-4.352 5.677-6.623 13.057-2.081 7.19-2.081 14.76z"
        fontFamily="Raleway"
        fontSize="medium"
        fontStretch="normal"
        fontStyle="normal"
        fontVariant="normal"
        fontWeight="800"
        letterSpacing="0"
        wordSpacing="0"
      ></path>
      <path
        style={{ lineHeight: "89.99999762%" }}
        fill="#7d7d7d"
        fillOpacity="1"
        stroke="none"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeWidth="1"
        d="M579.26 239.974V105.623h60.553q9.461 0 17.409 3.973 8.136 3.974 14.002 10.408 5.866 6.433 9.083 14.57 3.406 8.137 3.406 16.463 0 6.244-1.514 12.11-1.513 5.677-4.352 10.786-2.838 5.11-7.001 9.273-3.974 3.973-9.083 6.812l29.52 49.956h-35.008l-25.734-43.333h-20.248v43.333H579.26zm31.033-70.393h28.384q5.488 0 9.462-5.109 3.973-5.298 3.973-13.435 0-8.326-4.541-13.246t-9.84-4.92h-27.438v36.71z"
        fontFamily="Raleway"
        fontSize="medium"
        fontStretch="normal"
        fontStyle="normal"
        fontVariant="normal"
        fontWeight="800"
        letterSpacing="0"
        wordSpacing="0"
      ></path>
      <path
        style={{ lineHeight: "89.99999762%" }}
        fill="#7d7d7d"
        fillOpacity="1"
        stroke="none"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeWidth="1"
        d="M800.451 212.725v27.249h-94.424V105.623h92.721v27.248H737.06v26.113h52.984v25.168H737.06v28.573h63.391z"
        fontFamily="Raleway"
        fontSize="medium"
        fontStretch="normal"
        fontStyle="normal"
        fontVariant="normal"
        fontWeight="800"
        letterSpacing="0"
        wordSpacing="0"
      ></path>
    </g>
  </svg>
);

export default NutriScoreE;
