import { ChapterTypeData } from "models";
import { specRtkBaseApi } from "./specRtkApi";

const chapterApi = specRtkBaseApi.injectEndpoints({
  endpoints: build => ({
    getChapterTypes: build.query<
      ChapterTypeData[],
      {
        params?: { languageCode?: string };
      }
    >({
      query: ({ params }) => ({
        url: `chapter-types`,
        method: "GET",
        params,
      }),
      providesTags: ["ChapterTypes"],
    }),
  }),
});

export const { useGetChapterTypesQuery: useGetChapterTypes } = chapterApi;
