import { defineMessages } from "react-intl";

export const messages = defineMessages({
  noSpecificationNote: {
    specification: {
      id: "specificationCreation.summary.noSpecificationNote",
      defaultMessage: "No Note added to the Specification",
    },
    folder: {
      id: "creationFolder.summary.noSpecificationNote",
      defaultMessage: "No Note added to the Folder",
    },
  },
});
