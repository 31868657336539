import Creation from "./components/Creation";
import Details from "./components/Details";
import Edition from "./components/Edition";
import List from "./components/List";
import { withLibraryItemDetails } from "./hocs";

const Library = {
  Creation,
  Details: withLibraryItemDetails(Details),
  Edition: withLibraryItemDetails(Edition),
  List,
};

export default Library;
