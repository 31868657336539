import { createContext } from "react";
import { RefListOption } from "../types";

const ReferenceListFieldTemplateContext = createContext<{
  referenceOptions: RefListOption[];
  updateExcludedRefListIds?: Function;
}>({
  referenceOptions: [],
  updateExcludedRefListIds: Function,
});

export default ReferenceListFieldTemplateContext;
