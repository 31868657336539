import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Typography } from "antd";
import styles from "./styles.module.less";

const { Paragraph } = Typography;

const StaticWidget = ({ value, formContext }) => {
  const { emptyValue } = formContext;
  const [initialValue, setInitialValue] = useState(emptyValue);

  useEffect(() => {
    if (value) {
      setInitialValue(value);
    }
    return () => {};
  }, [value]);

  return (
    <Paragraph className={styles.fakeInput} ellipsis>
      {initialValue}
    </Paragraph>
  );
};

StaticWidget.propTypes = {
  value: PropTypes.string,
  formContext: PropTypes.object,
};

export default StaticWidget;
