import { FormattedMessage } from "react-intl";
import { Button, Tooltip } from "@trace-one/design-system";
import { isListEmpty } from "utils/general";
import ConditionalWrapper from "components/ConditionalWrapper";
import { ActionButtonProps } from "./types";
import { messages } from "./messages";

const ActionButton: React.FC<ActionButtonProps> = ({
  actions,
  size = "small",
  disabled,
  loading,
  ...rest
}) => {
  return (
    <ConditionalWrapper
      condition={loading}
      wrapper={children => (
        <Tooltip
          placement="left"
          text={<FormattedMessage {...messages.actionsAreLoading} />}
        >
          {children}
        </Tooltip>
      )}
    >
      <>
        <Button
          items={actions}
          type="tertiary"
          iconName="kebab"
          size={size}
          color="grey"
          disabled={disabled || isListEmpty(actions)}
          loading={loading}
          {...rest}
        />
      </>
    </ConditionalWrapper>
  );
};

export default ActionButton;
