import { createRef, RefObject, useEffect, useState } from "react";
import { ChapterStructureFormData } from "types/library";
import { isListEmpty, scrollToTop } from "utils/general";

const useCurrentSectionsRefs = ({
  sections,
  heightOfHeader = 74,
}: {
  sections: ChapterStructureFormData[];
  heightOfHeader?: number;
}) => {
  const [currentSectionsRefs, setCurrentSectionsRefs] = useState<{
    [key: string]: RefObject<any>;
  }>({});
  const [selectedSubSectionId, setSelectedSubSectionId] = useState<string>(
    null
  );

  const onUpdateSelectedSectionId = (newSelectedSectionId: string) => {
    if (newSelectedSectionId) {
      setSelectedSubSectionId(newSelectedSectionId);
    }
  };

  useEffect(() => {
    if (!isListEmpty(sections)) {
      const newRefs = sections.reduce(
        (currentState, { sectionId }) => ({
          ...currentState,
          [sectionId]: createRef(),
        }),
        {}
      ) as { [key: string]: RefObject<any> };

      setCurrentSectionsRefs(newRefs);
    }
  }, [JSON.stringify(sections)]);

  useEffect(() => {
    if (
      selectedSubSectionId &&
      currentSectionsRefs?.[selectedSubSectionId] &&
      currentSectionsRefs?.[selectedSubSectionId]?.current
    ) {
      setTimeout(() => {
        const elementPosition = currentSectionsRefs?.[
          selectedSubSectionId
        ].current.getBoundingClientRect().top;

        const offset = elementPosition + window.scrollY - heightOfHeader;

        scrollToTop(offset, "smooth");
      }, 300);
    }

    if (!selectedSubSectionId) {
      setSelectedSubSectionId(null);
    }
  }, [selectedSubSectionId]);

  return {
    currentSectionsRefs,
    selectedSubSectionId,
    onUpdateSelectedSectionId,
  };
};

export default useCurrentSectionsRefs;
