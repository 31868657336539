import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { toaster as toasterService } from "@trace-one/design-system";
import { persistor } from "store";
import { selectLanguageCode } from "store/user/selectors";
import {
  createChapterVersion,
  createMaterialVersion,
  deleteChapterType,
  duplicateChapter,
} from "apis/SPEC";
import useRedirect from "../useRedirect";
import {
  isLibraryObjectChapterSubType,
  isLibraryObjectLocked,
} from "utils/library";
import { CHAPTER_TYPES_URL_API, LIBRARY_OBJECT_TYPES } from "utils/constants";
import { getRedirectToAction } from "./utils";
import { messages } from "./messages";
import libraryMessages from "messages/library";
import { LibraryItemActionItemViewModel } from "viewModels";
import { useDeleteTemplate } from "apis/SPEC/templates";
import { generalMessages } from "messages";

const useLibraryItemActions = ({
  libraryItemInfo,
  refetchLibraryItems,
}: {
  libraryItemInfo: LibraryItemActionItemViewModel;
  refetchLibraryItems?: Function;
}) => {
  const { formatMessage } = useIntl();
  const languageCode = useSelector(selectLanguageCode);

  const {
    redirectToLibraryCreateNewVersion,
    redirectToLibraryDetails,
    redirectToLibraryEdition,
  } = useRedirect();

  const [deleteTemplate] = useDeleteTemplate();

  const getNewActionName = (action: string) => {
    const mappedKeys = {
      [LIBRARY_OBJECT_TYPES.REQUIREMENTS]: "requirement",
      [LIBRARY_OBJECT_TYPES.RAW_MATERIALS]: "rawMaterial",
      [LIBRARY_OBJECT_TYPES.MADE_IN_HOUSE]: "composite",
      [LIBRARY_OBJECT_TYPES.BOUGHT_TO_THIRD_PARTY]: "composite",
      [LIBRARY_OBJECT_TYPES.TEMPLATES]: "template",
    };

    let messageKey = mappedKeys[libraryItemInfo.type];

    return messages?.[`${messageKey}${action}`]
      ? formatMessage(messages?.[`${messageKey}${action}`])
      : action;
  };

  const redirectToAction = getRedirectToAction({
    libraryItemInfo,
    purge: persistor.purge,
  });

  const createNewVersionHandler = () => {
    switch (libraryItemInfo.type) {
      case LIBRARY_OBJECT_TYPES.REQUIREMENTS:
        redirectToAction(redirectToLibraryCreateNewVersion)();
        break;

      case LIBRARY_OBJECT_TYPES.PRODUCTION:
      case LIBRARY_OBJECT_TYPES.FINISHED_PRODUCTS:
      case LIBRARY_OBJECT_TYPES.TESTING:
        onCreateChapterNewVersion();
        break;

      case LIBRARY_OBJECT_TYPES.RAW_MATERIALS:
      case LIBRARY_OBJECT_TYPES.MADE_IN_HOUSE:
      case LIBRARY_OBJECT_TYPES.BOUGHT_TO_THIRD_PARTY:
        onCreateMaterialNewVersion();
        break;

      default:
        break;
    }
  };

  const onCreateMaterialNewVersion = async () => {
    try {
      const { data: id } = await createMaterialVersion({
        materialId: libraryItemInfo.id,
        materialType: libraryItemInfo.type,
        params: {
          languageCode,
        },
      });
      redirectToLibraryCreateNewVersion({
        id,
        type: libraryItemInfo.type,
        queryParameters: {
          prevVersionId: libraryItemInfo?.id,
        },
      });

      toasterService.open({
        message: formatMessage(messages.createNewVersionTitle),
        description: formatMessage(
          libraryMessages.createNewVersionDescription,
          {
            libraryItemName: libraryItemInfo?.name,
          }
        ),
        type: "confirmation",
      });
    } catch (_) {}
  };

  const onCreateChapterNewVersion = async () => {
    try {
      const { data: id } = await createChapterVersion({
        chapterId: libraryItemInfo?.id,
        chapterType: libraryItemInfo?.type,
        params: { languageCode },
      });

      redirectToLibraryCreateNewVersion({
        id,
        type: libraryItemInfo?.type,
        queryParameters: {
          prevVersionId: libraryItemInfo?.id,
        },
      });

      toasterService.open({
        message: formatMessage(libraryMessages.createNewVersionTitle),
        description: formatMessage(
          libraryMessages.createNewVersionDescription,
          {
            libraryItemName: libraryItemInfo?.name,
          }
        ),
        type: "confirmation",
      });
    } catch (_) {}
  };

  const onDeleteTemplate = async () => {
    await deleteTemplate(libraryItemInfo?.id);
    await refetchLibraryItems?.(true);
  };

  const onDeleteChapter = async () => {
    await deleteChapterType({
      chapterId: libraryItemInfo?.id,
      chapterType: libraryItemInfo?.type,
    });
    await refetchLibraryItems?.(true);
  };

  const deleteHandler = () => {
    switch (libraryItemInfo?.type) {
      case LIBRARY_OBJECT_TYPES.TEMPLATES:
        onDeleteTemplate();
        break;
      case LIBRARY_OBJECT_TYPES.PRODUCTION:
      case LIBRARY_OBJECT_TYPES.TESTING:
      case LIBRARY_OBJECT_TYPES.FINISHED_PRODUCTS:
        onDeleteChapter();
        break;

      default:
        break;
    }
  };

  const onDuplicateChapter = async () => {
    try {
      const { data } = await duplicateChapter({
        chapterId: libraryItemInfo?.id,
        chapterType: libraryItemInfo?.type,
        params: { languageCode },
      });

      redirectToLibraryEdition({
        id: data?.generalInfo?.id,
        type: libraryItemInfo?.type,
      });

      toasterService.open({
        message: formatMessage(libraryMessages.chapterDuplicateTitle),
        description: formatMessage(
          libraryMessages.chapterDuplicateDescription,
          {
            chapterName: libraryItemInfo?.name,
          }
        ),
        type: "confirmation",
      });
    } catch (_) {}
  };

  const isChapterObject = isLibraryObjectChapterSubType(
    CHAPTER_TYPES_URL_API[libraryItemInfo?.type]
  );

  return {
    details: {
      name: getNewActionName("View"),
      onClick: redirectToAction(redirectToLibraryDetails),
    },
    createNewVersion: {
      disabled: !isLibraryObjectLocked(libraryItemInfo.state),
      name: formatMessage(libraryMessages.createNewVersion),
      onClick: createNewVersionHandler,
    },
    edit: {
      name: getNewActionName("Edit"),
      shortName: formatMessage(libraryMessages.editButton),
      onClick: redirectToAction(redirectToLibraryEdition),
    },
    ...(isChapterObject
      ? {
          duplicate: {
            name: getNewActionName("Duplicate"),
            shortName: formatMessage(generalMessages.duplicate),
            onClick: onDuplicateChapter,
          },
        }
      : {}),
    delete: {
      name: getNewActionName("Delete"),
      shortName: formatMessage(libraryMessages.deleteAction),
      tooltipProps: {
        onConfirmation: deleteHandler,
      },
    },
  };
};

export default useLibraryItemActions;
