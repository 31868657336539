import { defineMessages } from "react-intl";

export const messages = defineMessages({
  requirementsPlaceholder: {
    id: "creationFolder.spec.requirements.placeholder",
    defaultMessage:
      "Search your library for requirements that refer to the selected products",
  },

  addRequirements: {
    id: "creationFolder.spec.requirements.addButton",
    defaultMessage: "Add requirements",
  },
});
