import { defineMessages } from "react-intl";

export const messages = defineMessages({
  detailsFolderName: {
    id: "creationFolder.form.folderName",
    defaultMessage: "Folder name",
  },
  detailsRetailerContact: {
    id: "creationFolder.form.retailerContact",
    defaultMessage: "Retailer contact(s)",
  },
  detailsProductType: {
    id: "creationFolder.form.productType",
    defaultMessage: "Product type",
  },
  detailsTargetDate: {
    id: "creationFolder.form.targetDate",
    defaultMessage: "Target date",
  },
  detailsDescription: {
    id: "creationFolder.form.description",
    defaultMessage: "Description",
  },
});
