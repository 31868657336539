import { useMemo } from "react";
import { Button, Tooltip } from "@trace-one/design-system";
import ConfirmationTooltip from "components/ConfirmationTooltip";
import CharacteristicIngredient from "./components/CharacteristicIngredient";
import { Row, Col, Form } from "antd";
import { Paragraph } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import RowTitle from "components/RowTitle";
import TrackChanges from "components/TrackChanges";
import SwitchWidget from "../SwitchWidget";
import TextareaWidget from "../TextareaWidget";
import { isLibraryObjectProduction } from "utils/library";
import { useIngredientListWidget } from "./hooks";
import { messages } from "./messages";
import { IngredientListWidgetProps } from "./types";
import styles from "./styles.module.less";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";

const IngredientListWidget: React.FC<IngredientListWidgetProps> = ({
  formData,
  schema,
  formContext,
  idSchema,
  uiSchema,
}) => {
  const { formatMessage } = useIntl();
  const {
    isWidgetEditable,
    displayActions,
    labelAlign,
    specificationData,
    extraActions,
    isRetailerContact,
    hasPermissionToSave,
  } = formContext;
  const hasEditPermission = isWidgetEditable();
  const { getTranslationForKey } = useFormSchemaTranslation();

  const colSpans = {
    xs: 24,
    md: 5,
  };

  const showEditMode = typeof formData.modifiedIngredientList === "string";

  const isProductionChapterLinked = useMemo(
    () =>
      specificationData?.chapters?.find(chapter =>
        isLibraryObjectProduction(chapter?.chapterType)
      )?.isLinked,
    [specificationData?.chapters]
  );

  const {
    handleChange,
    resetIngredientList,
    editIngredientList,
  } = useIngredientListWidget({
    formData,
    formContext,
  });

  return (
    <>
      {isProductionChapterLinked && (
        <CharacteristicIngredient
          specificationId={specificationData?.specificationId}
          characteristicIngredients={formData?.characteristicIngredients}
          updateCharacteristicIngredients={
            extraActions?.onUpdateCharacteristicIngredients
          }
          canEdit={isRetailerContact && hasPermissionToSave}
        />
      )}

      <RowTitle
        title={
          formData.modifiedIngredientList
            ? formatMessage(messages.ingredientListEditTitle)
            : formatMessage(messages.ingredientListReadOnlyTitle)
        }
        displayActions={displayActions}
      />
      <Form.Item
        label={getTranslationForKey(uiSchema?.displayOnPack?.["ui:title"])}
        labelAlign={labelAlign}
        labelCol={colSpans}
        wrapperCol={colSpans}
      >
        <SwitchWidget
          value={formData.displayOnPack}
          formContext={formContext}
          schema={schema.properties.displayOnPack}
          onChange={handleChange("displayOnPack")}
          readonly={false}
          disabled={false}
          options={{}}
        />
      </Form.Item>
      {showEditMode ? (
        <>
          <TextareaWidget
            formContext={formContext}
            schema={schema.properties.modifiedIngredientList}
            onChange={handleChange("modifiedIngredientList")}
            disabled={false}
            readonly={false}
            id={idSchema.$id}
            value={formData.modifiedIngredientList}
            options={{}}
            placeholder=""
            onFocus={() => {}}
            configProps={{
              toolbar: [
                {
                  name: "basicstyles",
                  items: [
                    "Underline",
                    "Strike",
                    "Italic",
                    "Bold",
                    "Link",
                    "Unlink",
                    "NumberedList",
                    "BulletedList",
                  ],
                },
              ],
            }}
          />
          {displayActions && (
            <div className={styles.resetButton}>
              <ConfirmationTooltip
                onConfirmation={resetIngredientList}
                text={formatMessage(
                  messages.resetIngredientListConfirmationText
                )}
              >
                <Button
                  data-testid="edit-ingredient-list-btn"
                  type="tertiary"
                  disabled={!hasEditPermission}
                >
                  {formatMessage(messages.resetButtonTitle)}
                </Button>
              </ConfirmationTooltip>
            </div>
          )}
          <RowTitle
            title={formatMessage(messages.trackChangesTitle)}
            displayActions={displayActions}
          />
          <Row gutter={[10, 0]}>
            <Col>
              <Paragraph size="s" className={styles.added}>
                {formatMessage(messages.addedTextLegend)}
              </Paragraph>
            </Col>
            <Col>
              <Paragraph size="s" className={styles.modified}>
                {formatMessage(messages.modifiedTextLegend)}
              </Paragraph>
            </Col>
            <Col>
              <Paragraph size="s" color="red" className={styles.deleted}>
                {formatMessage(messages.deletedTextLegend)}
              </Paragraph>
            </Col>
          </Row>
          <TrackChanges
            initText={`<p>${formData.originalIngredientList}</p>`}
            modifiedText={formData.modifiedIngredientList}
            className={styles.trackChanges}
          />
        </>
      ) : (
        <>
          <Paragraph
            dangerouslySetInnerHTML={{
              __html: formData.originalIngredientList,
            }}
          ></Paragraph>
          <Tooltip
            title={
              !formData.originalIngredientList
                ? formatMessage(messages.tooltipMessage)
                : ""
            }
            placement="bottomLeft"
          >
            {displayActions && (
              <span className={styles.editButtonWrapper}>
                <Button
                  data-testid="edit-ingredient-list-btn"
                  type="primary"
                  onClick={editIngredientList}
                  disabled={
                    !formData.originalIngredientList || !hasEditPermission
                  }
                >
                  {formatMessage(messages.editButtonTitle)}
                </Button>
              </span>
            )}
          </Tooltip>
        </>
      )}
    </>
  );
};

export default IngredientListWidget;
