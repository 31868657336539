export const getOnClearAllFilters = ({
  setSelectedStatuses,
  setSelectedProductTypes,
  setSelectedUsers = () => {},
  setSelectedServiceTypes = () => {},
  setProductCategoryId = () => {},
  setProductCategoryItem = () => {},
}: {
  setSelectedStatuses: Function;
  setSelectedProductTypes: Function;
  setSelectedUsers?: Function;
  setSelectedServiceTypes?: Function;
  setProductCategoryId?: Function;
  setProductCategoryItem?: Function;
}) => () => {
  setSelectedStatuses([]);
  setSelectedProductTypes([]);
  setSelectedUsers([]);
  setSelectedServiceTypes([]);
  setProductCategoryId("");
  setProductCategoryItem(null);
};

export default getOnClearAllFilters;
