import { useEffect, useState } from "react";
import { v4 } from "uuid";
import { useAppDispatch } from "store";
import {
  createSupplier,
  removeSupplier,
  updateSupplier,
} from "store/materialForm/asyncActions";
import { MaterialSupplierData } from "models";
import { Supplier } from "../../types";

const useActions = ({ suppliers }: { suppliers: Supplier[] }) => {
  const dispatch = useAppDispatch();

  const [suppliersList, setSuppliersList] = useState(suppliers);

  useEffect(() => {
    setSuppliersList(suppliers);
  }, [JSON.stringify(suppliers)]);

  const onAddSupplier = () => {
    setSuppliersList(previousState => [
      ...previousState,
      {
        key: v4(),
        origins: [],
        name: "",
        code: "",
        ingredients: [],
      },
    ]);
  };

  const onRemoveSupplier = (
    supplierId: MaterialSupplierData["supplierId"]
  ) => () => {
    if (supplierId) {
      dispatch(removeSupplier({ supplierId }));
    } else {
      setSuppliersList(previousState => previousState.slice(0, -1));
    }
  };

  const onChangeSupplierName = (supplierId?: string) => async (
    value: MaterialSupplierData["name"]
  ) => {
    if (supplierId) {
      const currentSupplier = getCurrentSupplier(supplierId);
      dispatch(
        updateSupplier({ name: value, code: currentSupplier.code, supplierId })
      );
    } else {
      dispatch(
        createSupplier({
          name: value,
        })
      );
    }
  };

  const onChangeSupplierCode = (supplierId: string) => async (
    value: MaterialSupplierData["code"]
  ) => {
    if (supplierId) {
      const currentSupplier = getCurrentSupplier(supplierId);
      dispatch(
        updateSupplier({ name: currentSupplier.name, code: value, supplierId })
      );
    }
  };

  const getCurrentSupplier = (supplierId: string) => {
    return suppliersList.find(supplier => supplierId === supplier.supplierId);
  };

  return {
    suppliersList,
    onAddSupplier,
    onRemoveSupplier,
    onChangeSupplierName,
    onChangeSupplierCode,
  };
};

export default useActions;
