import { FallbackProps } from "react-error-boundary";
import { ErrorPage } from "@trace-one/react-components";
import { useRedirect } from "hooks";

const ErrorPageFallback: React.FC<FallbackProps> = ({ resetErrorBoundary }) => {
  const { redirectToHome } = useRedirect();

  const handleButtonPress = () => {
    resetErrorBoundary();
    redirectToHome();
  };

  return (
    <ErrorPage
      hasCustomClickAction
      onButtonPress={handleButtonPress}
      secondaryButton={{
        path: "",
        isVisible: false,
      }}
    />
  );
};

export default ErrorPageFallback;
