import { SelectProps } from "antd";

const useInfiniteScrollSelect = ({
  next,
  defaultOnPopupScroll,
}: {
  next: Function;
  defaultOnPopupScroll?: SelectProps["onPopupScroll"];
}) => {
  const onPopupScroll = event => {
    defaultOnPopupScroll?.(event);

    const target = event.target;

    if (
      Math.ceil(target.scrollTop) + target.offsetHeight ===
      target.scrollHeight
    ) {
      target.scrollTo(0, target.scrollHeight);
      next?.();
    }
  };

  return { onPopupScroll };
};

export default useInfiniteScrollSelect;
