import { ControlPlanFormData } from "components/SpecificationSection/components/FormSchema/types";

export const getIndexOfItemOfId = ({
  array,
  tablesId,
  id,
  index,
}: {
  array: ControlPlanFormData[];
  tablesId: string;
  id: string;
  index: number;
}) => {
  let i = -1;

  return array.findIndex(row => {
    if (row[tablesId] === id) i++;
    if (index === i) return true;

    return false;
  });
};
