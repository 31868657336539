import { Col } from "antd";
import { FormattedMessage } from "react-intl";
import { Paragraph } from "@trace-one/design-system";
import { FolderListingContext } from "./context";
import { useFolderPaginatedList } from "hooks";
import Filters from "./components/Filters";
import ResultTable from "./components/ResultTable";
import { messages } from "./messages";
import styles from "./styles.module.less";

const FolderListing = () => {
  const {
    pagination,
    folders,
    isLoading,
    hasError,
    totalNumberOfFolders,
    onTableChange,
    onUpdateFilters,
    resetCurrentPage,
    setShouldRefetchFolders,
  } = useFolderPaginatedList();

  return (
    <FolderListingContext.Provider
      value={{
        currentPage: pagination.current,
        resetCurrentPage,
        setShouldRefetchFolders,
      }}
    >
      <Filters onUpdateFilters={onUpdateFilters} />
      <div className={styles.folderListContainer}>
        <Col>
          {hasError && (
            <Paragraph color="red" data-testid="searchErrorMessage">
              <FormattedMessage {...messages.errorMessage} />
            </Paragraph>
          )}
          <ResultTable
            pagination={pagination}
            onTableChange={onTableChange}
            folders={folders}
            isLoading={isLoading}
            totalNumberOfFolders={totalNumberOfFolders}
          />
        </Col>
      </div>
    </FolderListingContext.Provider>
  );
};

export default FolderListing;
