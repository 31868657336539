import { AxiosRequestConfig } from "axios";
import { axiosInstance } from ".";
import { ChapterStructureBlockData, SpecificationData } from "models";
import { ModificationRequestData } from "models/modificationRequest";
import { PaginatedList, PaginationParams } from "types/api";
import { ModificationRequestState } from "types/modificationRequest";

const baseURL = "/api/requests-for-modification";

export const fetchModificationRequests = <T>({
  params,
  paramsSerializer,
}: {
  params: PaginationParams & {
    specificationId: SpecificationData["id"];
    requestStates?: ModificationRequestState[];
    chapterType?: string;
    languageCode?: string;
  };
  paramsSerializer?: AxiosRequestConfig["paramsSerializer"];
}) =>
  axiosInstance.get<PaginatedList<T>>(`${baseURL}`, {
    params,
    paramsSerializer,
  });

export const fetchModificationRequestById = ({
  requestId,
  params,
}: {
  requestId: string;
  params?: {
    languageCode?: string;
  };
}) =>
  axiosInstance.get<ModificationRequestData>(`${baseURL}/${requestId}`, {
    params,
  });

export const createModificationRequest = ({
  chapterType,
  payload,
  params,
}: {
  chapterType: string;
  payload: {
    specificationId: SpecificationData["id"];
    blockId: ChapterStructureBlockData["blockId"];
    propertyId: string;
    listItemKey: string;
    message: "string";
  };
  params?: {
    languageCode?: string;
  };
}) =>
  axiosInstance.post<ModificationRequestData>(
    `${baseURL}/${chapterType}`,
    payload,
    { params }
  );

export const updateModificationRequest = ({
  requestId,
  payload,
  params,
}: {
  requestId: string;
  payload: {
    message: "string";
  };
  params?: {
    languageCode?: string;
  };
}) =>
  axiosInstance.put<ModificationRequestData>(
    `${baseURL}/${requestId}`,
    payload,
    { params }
  );

export const deleteModificationRequest = ({
  requestId,
}: {
  requestId: ModificationRequestData["requestId"];
}) => axiosInstance.delete<void>(`${baseURL}/${requestId}`);

export const notifyModificationRequest = ({
  requestId,
  params,
}: {
  requestId: ModificationRequestData["requestId"];
  params?: {
    languageCode: string;
  };
}) =>
  axiosInstance.post<ModificationRequestData>(
    `${baseURL}/${requestId}/notify`,
    {},
    { params }
  );

export const performModificationRequest = ({
  requestId,
  params,
}: {
  requestId: ModificationRequestData["requestId"];
  params?: {
    languageCode?: string;
  };
}) =>
  axiosInstance.post<ModificationRequestData>(
    `${baseURL}/${requestId}/perform`,
    {},
    { params }
  );

export const declineModificationRequest = ({
  requestId,
  params,
}: {
  requestId: ModificationRequestData["requestId"];
  params?: {
    languageCode?: string;
  };
}) =>
  axiosInstance.post<ModificationRequestData>(
    `${baseURL}/${requestId}/decline`,
    {},
    { params }
  );

export const archiveModificationRequest = ({
  requestId,
  params,
}: {
  requestId: ModificationRequestData["requestId"];
  params?: {
    languageCode?: string;
  };
}) =>
  axiosInstance.post<ModificationRequestData>(
    `${baseURL}/${requestId}/archive`,
    {},
    { params }
  );

export const completeModificationRequest = ({
  requestId,
  params,
}: {
  requestId: ModificationRequestData["requestId"];
  params?: {
    languageCode?: string;
  };
}) =>
  axiosInstance.post<ModificationRequestData>(
    `${baseURL}/${requestId}/complete`,
    {},
    { params }
  );
