import { isListEmpty } from "utils/general";
import Requirement from "./components/Requirement";
import { RequirementsTabsProps } from "./types";

export const getOnMenuTabSelect = ({ setSelectedTabKey }) => menuTabKey => {
  setSelectedTabKey(menuTabKey);
};

export const getTabsFromRequirements = <T extends RequirementsTabsProps>({
  requirements,
  displayActions,
}: T) => () =>
  requirements.map(requirement => ({
    key: requirement.requirementId,
    title: requirement.requirementName,
    iconName: "checklist",
    content: (
      <Requirement requirement={requirement} displayActions={displayActions} />
    ),
  }));

export const getOnTabsLengthChange = ({ setSelectedTabKey, tabs }) => () => {
  if (!isListEmpty(tabs)) {
    setSelectedTabKey(tabs[0].key);
  }
};
