import { Row } from "antd";
import { EmptyState } from "@trace-one/design-system";
import ClaimCardReadOnly from "../ClaimCardReadOnly";
import { isListEmpty } from "utils/general";
import { ClaimFormData } from "types/specification";
import { ClaimData } from "models";

export type ClaimsReadOnlyProps = {
  claimCollection: ClaimData[];
  formData: ClaimFormData[];
  noItemsMessage: string;
};

const ClaimsReadOnly: React.FC<ClaimsReadOnlyProps> = ({
  claimCollection = [],
  formData = [],
  noItemsMessage,
}) => {
  if (isListEmpty(claimCollection) || isListEmpty(formData)) {
    return (
      <Row justify="center">
        <EmptyState heading={noItemsMessage} />
      </Row>
    );
  }

  return (
    <>
      {claimCollection.map(({ name, sentenceTranslations = [], id }, index) => {
        const claimSentence = sentenceTranslations.find(
          ({ isDefault }) => isDefault
        )?.text;
        const { supportingDocumentIds = [] } = formData.find(
          formDataClaim => formDataClaim?.claimId === id
        );
        return (
          <ClaimCardReadOnly
            name={name}
            fileIds={supportingDocumentIds}
            claimSentence={claimSentence}
            key={id}
          />
        );
      })}
    </>
  );
};

export default ClaimsReadOnly;
