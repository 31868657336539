import { TreeSelect } from "@trace-one/react-components";
import { useCategoryListItems } from "hooks";
import { getExternalCategoryTreeSelectName } from "../utils";
import { ExternalCategoryTreeSelectProps } from "./types";

const ExternalCategoryTreeSelect: React.FC<ExternalCategoryTreeSelectProps> = ({
  list,
  value,
  treeExpandedKeys,
  onTreeExpand,
  placeholder = "",
  multiple = false,
  labelInValue = false,
  isOnlyLeafSelectable = false,
  allowClear,
  onSelect,
  onDeselect,
  name = "",
}) => {
  const { api, route } = list;
  const { items: treeData } = useCategoryListItems({
    api,
    route,
    isOnlyLeafSelectable,
  });

  return (
    <TreeSelect
      name={getExternalCategoryTreeSelectName(name, list)}
      value={value}
      multiple={multiple}
      treeData={treeData}
      placeholder={placeholder}
      treeExpandedKeys={treeExpandedKeys}
      onTreeExpand={onTreeExpand}
      labelInValue={labelInValue}
      allowClear={allowClear}
      onSelect={onSelect}
      onDeselect={onDeselect}
    />
  );
};

export default ExternalCategoryTreeSelect;
