import { useEffect, useState } from "react";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { CheckboxProps } from "components/Card/components/Object/types";

const useCheckbox = (props: CheckboxProps) => {
  const { isChecked, onChange: onDefaultChange, ...rest } = props;
  const [checkboxValue, setCheckboxValue] = useState(isChecked);

  const onChange = ({ target: { checked } }: CheckboxChangeEvent) => {
    if (typeof onDefaultChange === "function") {
      onDefaultChange(checked);
    }
    setCheckboxValue(checked);
  };

  useEffect(() => {
    setCheckboxValue(isChecked);
  }, [isChecked]);

  return {
    checked: checkboxValue,
    onChange,
    "data-test-id": "object-card-checkbox",
    ...rest,
  };
};

export default useCheckbox;
