import CrossContaminationAllergens from "./components/CrossContaminationAllergens";
import RawMaterialsAllergens from "./components/RawMaterialsAllergens";
import { ArrayFieldTemplateProps } from "components/SpecificationSection/types";
import { AllergensArrayFieldTemplateProvider } from "./contexts";
import { getDictionary } from "utils/general";

const AllergensArrayFieldTemplate: React.FC<ArrayFieldTemplateProps> = ({
  formContext,
  uiSchema,
  noItemsMessage,
  formData,
}) => {
  const {
    chapterData,
    displayActions,
    catalogDictionary,
    extraActions,
  } = formContext;

  const allergenControlledRiskDictionary = getDictionary({
    data: formData,
    key: "allergenId",
    value: "controlledRisk",
  });

  return (
    <AllergensArrayFieldTemplateProvider
      updateChapterCCAllergenRiskAction={
        extraActions.updateChapterCCAllergenRisk
      }
      catalogDictionary={catalogDictionary}
      allergenControlledRiskDictionary={allergenControlledRiskDictionary}
    >
      <>
        <RawMaterialsAllergens
          rawMaterialsAllergens={chapterData?.rawMaterialsAllergens}
          displayActions={displayActions}
          noItemsMessage={noItemsMessage}
        />
        <CrossContaminationAllergens
          crossContaminationAllergens={chapterData?.crossContaminationAllergens}
          displayActions={displayActions}
          uiSchema={uiSchema}
          noItemsMessage={noItemsMessage}
        />
      </>
    </AllergensArrayFieldTemplateProvider>
  );
};

export default AllergensArrayFieldTemplate;
