import { Col } from "antd";
import { getPropertiesObjectFromArray } from "../../templates/utils";
import { ObjectFieldTemplateProps } from "components/SpecificationSection/types";

const PackagingComponentCharacteristicsWidget: React.FC<ObjectFieldTemplateProps> = ({
  properties,
  formData,
}) => {
  const propertyObjects = getPropertiesObjectFromArray(properties);

  return (
    <>
      <Col xs={24}>{propertyObjects["recyclable"].content}</Col>
      {formData["hasPlastic"] && (
        <>
          <Col xs={24}>{propertyObjects["density"].content}</Col>
          <Col xs={24}>{propertyObjects["rigidity"].content}</Col>
          <Col xs={24}>{propertyObjects["tint"].content}</Col>
          <Col xs={24}>{propertyObjects["transparency"].content}</Col>
        </>
      )}
      <Col xs={24}>{propertyObjects["additives"].content}</Col>
    </>
  );
};

export default PackagingComponentCharacteristicsWidget;
