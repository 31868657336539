import { defineMessages } from "react-intl";

export const messages = defineMessages({
  materialInformationTitle: {
    id: "library.materialForm.generalInfo.title",
    defaultMessage: "Define material information",
  },
  cancelDeleteMaterialTitle: {
    id: "library.materialForm.cancelDeleteMaterial.title",
    defaultMessage: "Cancel material creation",
  },
  cancelDeleteMaterialDescription: {
    id: "library.materialForm.cancelDeleteMaterial.description",
    defaultMessage:
      "The material has been automatically saved as a draft. By leaving now, you can either keep it as a draft or cancel the creation and delete it.",
  },
  cancelDeleteMaterialDeleteBtn: {
    id: "library.materialForm.cancelDeleteMaterial.delete",
    defaultMessage: "Delete material",
  },
});
