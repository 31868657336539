import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  selectOwningCompanyId,
  selectLanguageCode,
} from "store/user/selectors";
import { fetchPackagingComponents } from "apis/PKG";
import { fetchReferenceListItems } from "apis/RLMD";
import { LibraryObjectData } from "models";
import { Dictionary } from "types/general";
import { prepareLibraryListItemFromApiData } from "dtos/libraryItem";
import { getDictionary, getSkipParamForApi, isListEmpty } from "utils/general";
import {
  LIBRARY_OBJECT_TYPES,
  REFLIST_IDS,
  TAKE_ITEMS_FOR_INFINITE_PAGINATION,
} from "utils/constants";
import { UseAddPackagingComponentsProps } from "./types";

const useAddPackagingComponents = ({
  selectedPackagingComponents,
  setSelectedPackagingComponents,
  onCloseModal,
  infiniteScrollWrapper,
}: UseAddPackagingComponentsProps) => {
  const ownerCompanyId = useSelector(selectOwningCompanyId);
  const languageCode = useSelector(selectLanguageCode);

  const [typeDictionary, setTypeDictionary] = useState<Dictionary>({});
  const [packagingComponentsList, setPackagingComponentsList] = useState<
    LibraryObjectData[]
  >([]);

  const [isLoading, setIsLoading] = useState(true);
  const [canLoadMore, setCanLoadMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalNumberOfItems, setTotalNumberOfItems] = useState(1);
  const [searchText, setSearchText] = useState<string>();

  const isPrimaryButtonDisabled = useMemo(
    () => isListEmpty(selectedPackagingComponents),
    [selectedPackagingComponents]
  );

  const getPackagingComponentTypes = async () => {
    setIsLoading(true);
    try {
      const { data } = await fetchReferenceListItems({
        languageCode,
        id: REFLIST_IDS.PACKAGING_COMPONENTS_TYPE,
        take: 500,
      });
      setTypeDictionary(
        getDictionary({
          data: data.referenceListItems,
          key: "id",
          value: "text",
        })
      );
    } catch (_) {
    } finally {
      setIsLoading(false);
    }
  };

  const searchPackagingComponents = async ({
    searchText,
    isNewSearch,
  }: {
    searchText: string;
    isNewSearch?: boolean;
  }) => {
    try {
      setIsLoading(true);

      const {
        data: { items, totalNumberOfItems },
      } = await fetchPackagingComponents({
        params: {
          take: TAKE_ITEMS_FOR_INFINITE_PAGINATION,
          skip: getSkipParamForApi({
            isNewSearch,
            currentPage,
            pageSize: TAKE_ITEMS_FOR_INFINITE_PAGINATION,
          }),
          ownerCompanyId,
          searchText,
        },
      });

      let newItemsLength = items.length;

      setCurrentPage(previousState => (isNewSearch ? 2 : previousState + 1));

      if (isNewSearch) {
        setPackagingComponentsList(items);
      } else {
        setPackagingComponentsList(previousState => {
          const newItemsList = [...previousState, ...items];

          newItemsLength = newItemsList.length;

          return newItemsList;
        });
      }

      setTotalNumberOfItems(totalNumberOfItems);

      setCanLoadMore(totalNumberOfItems > newItemsLength);
    } catch (_) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getPackagingComponentTypes();
    searchPackagingComponents({ searchText });
  }, []);

  useEffect(() => {
    if (searchText !== undefined) {
      // this is solving the issue of making multiple requests because the scroll is not at the top
      infiniteScrollWrapper.current.scrollTo({ top: 0 });

      searchPackagingComponents({ searchText, isNewSearch: true });
    }
  }, [searchText]);

  const onCloseModalHandler = () => {
    setSelectedPackagingComponents([]);
    onCloseModal();
  };

  return {
    isLoading,
    packagingComponentsList: packagingComponentsList.map(item =>
      prepareLibraryListItemFromApiData({
        libraryItem: item,
        typeDictionary,
        type: LIBRARY_OBJECT_TYPES.PACKAGING_SYSTEMS,
      })
    ),
    canLoadMore,
    currentPage,
    totalNumberOfItems,
    searchPackagingComponents,
    onCloseModalHandler,
    isPrimaryButtonDisabled,
    searchText,
    setSearchText,
  };
};

export default useAddPackagingComponents;
