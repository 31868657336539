import { LibraryItemDetails } from "pages/Library/hocs/withLibraryItemDetails/types";
import { ClaimInformationViewModal } from "viewModels/claim";

export const getClaimInformationFormData = (
  libraryItemDetails: LibraryItemDetails
) => {
  let formData: ClaimInformationViewModal = {};

  formData.name = libraryItemDetails?.generalInformation?.name;
  formData.productTypeId = libraryItemDetails?.productTypeId;
  formData.requiresSupportingDocuments =
    libraryItemDetails?.generalInformation?.requiresSupportingDocuments;
  formData.claimSentence = libraryItemDetails?.sentenceTranslations?.find(
    ({ isDefault }) => isDefault
  )?.text;

  return formData;
};
