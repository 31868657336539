import { defineMessages } from "react-intl";

const supplierDetailsMessages = defineMessages({
  addOrigin: {
    id: "library.rawMaterialForm.supplierDetails.addOrigin",
    defaultMessage: "Add an origin",
  },
});

export default supplierDetailsMessages;
