import { useEffect, useState } from "react";
import { ReferenceListItemData } from "models";
import { LabeledValue } from "antd/lib/select";
import { ChapterType } from "../../types";

const useSelectTypes = ({
  chapterTypes,
  productTypes,
  chapterType,
  productTypeId,
}: {
  chapterTypes: ChapterType[];
  productTypes: ReferenceListItemData[];
  chapterType: string;
  productTypeId: string;
}) => {
  const [chapterTypeOptions, setChapterTypeOptions] = useState<LabeledValue[]>(
    []
  );
  const [productTypeOptions, setProductTypeOptions] = useState<LabeledValue[]>(
    []
  );

  const updateChapterTypeOptions = (chapterTypes: ChapterType[]) => {
    setChapterTypeOptions(
      chapterTypes.map(({ chapterType, chapterName }) => ({
        value: chapterType,
        label: chapterName,
      }))
    );
  };

  const updateProductTypeOptions = (productTypes: ReferenceListItemData[]) => {
    setProductTypeOptions(
      productTypes.map(({ text, id }) => ({
        value: id,
        label: text,
      }))
    );
  };
  useEffect(() => {
    const newChapterTypes = productTypeId
      ? chapterTypes.filter(({ productTypeIds }) =>
          productTypeIds.includes(productTypeId)
        )
      : chapterTypes;

    updateChapterTypeOptions(newChapterTypes);
  }, [chapterTypes.length, productTypeId]);

  useEffect(() => {
    let newProductTypes = [];

    if (!chapterType) {
      newProductTypes = productTypes;
    } else {
      const selectedChapterType = chapterTypes.find(
        currentChapterType => currentChapterType.chapterType === chapterType
      );

      newProductTypes = productTypes.filter(({ id }) =>
        selectedChapterType.productTypeIds.includes(id)
      );
    }

    updateProductTypeOptions(newProductTypes);
  }, [productTypes.length, chapterType]);

  return {
    chapterTypeOptions,
    productTypeOptions,
  };
};

export default useSelectTypes;
