import { ChangeEvent, useEffect, useState } from "react";
import classNames from "classnames";
import { Form } from "antd";
import { useIntl } from "react-intl";
import { Input, Heading } from "@trace-one/design-system";
import { useErrorCodeCheck } from "hooks";
import { useDebouncedActions } from "components/Debounced/hooks";
import { ERROR_CODES, MAX_CHAR_LENGTH } from "utils/constants";
import { LibraryItemNameProps } from "./types";
import libraryMessages from "messages/library";
import { inputErrorMessages } from "messages/errors";
import libraryStyles from "components/Library/styles.module.less";

const LibraryItemName: React.FC<LibraryItemNameProps> = ({
  disableActions,
  onChange,
  versionName,
  dataTestId,
  defaultValue,
  disabledOnBlur = false,
  form,
  wrapperCol = { md: 9 },
  manualSaveEnabled = false,
}) => {
  const { formatMessage } = useIntl();
  const [errorMessageKey, setErrorMessageKey] = useState("");

  const { isDesiredErrorCode } = useErrorCodeCheck(
    ERROR_CODES.DUPLICATE_LIBRARY_NAME
  );

  useEffect(() => {
    setErrorMessageKey(isDesiredErrorCode ? "duplicateName" : "");
  }, [isDesiredErrorCode]);

  useEffect(() => {
    if (errorMessageKey) {
      form?.validateFields(["name"]);
    } else {
      form?.setFields([{ name: "name", errors: [] }]);
    }
  }, [errorMessageKey]);

  const {
    currentValue,
    onTextChange,
    handleDebouncedValueChange,
  } = useDebouncedActions({
    debounceTimer: 1000,
    defaultValue,
    onChange,
  });

  const textChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (manualSaveEnabled && errorMessageKey) {
      setErrorMessageKey("");
      form?.setFields([{ name: "name", errors: [] }]);
    }

    onTextChange?.(event);
  };

  return (
    <div
      className={classNames({
        [libraryStyles.nameWrapper]: !!versionName,
      })}
    >
      <Form.Item
        rules={[
          {
            max: MAX_CHAR_LENGTH,
            required: true,
            whitespace: true,
          },
          {
            validator: _ => {
              if (!errorMessageKey) {
                return Promise.resolve();
              }

              return Promise.reject(
                new Error(formatMessage(inputErrorMessages[errorMessageKey]))
              );
            },
          },
        ]}
        name="name"
        label={formatMessage(libraryMessages.nameLabel)}
        wrapperCol={wrapperCol}
      >
        <Input
          allowClear={false}
          disabled={disableActions}
          value={currentValue}
          data-test-id={dataTestId}
          placeholder={formatMessage(libraryMessages.namePlaceholder)}
          onChange={textChangeHandler}
          onBlur={disabledOnBlur ? undefined : handleDebouncedValueChange}
        />
      </Form.Item>
      {versionName ? (
        <Heading className={libraryStyles.versionName} size="m">
          {versionName}
        </Heading>
      ) : null}
    </div>
  );
};

export default LibraryItemName;
