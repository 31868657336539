import { useEffect, useMemo, useState } from "react";
import { useBoolean, useRecipe } from "hooks";
import { SpecificationData } from "models";
import { getUniqueFlatlistFromIngredients } from "dtos/material";
import { CHAPTER_TYPES_API } from "utils/constants";
import { sortByKey } from "utils/general";
import { IngredientListFormData } from "components/SpecificationSection/components/FormSchema/types";
import { CharacteristicIngredientViewModel } from "viewModels";

interface UseCharacteristicIngredientProps {
  specificationId: SpecificationData["id"];
  characteristicIngredients: IngredientListFormData["characteristicIngredients"];
  updateCharacteristicIngredients: Function;
  setSelectedIngredientId: Function;
}

const useCharacteristicIngredient = ({
  specificationId,
  characteristicIngredients,
  updateCharacteristicIngredients,
  setSelectedIngredientId,
}: UseCharacteristicIngredientProps) => {
  const {
    recipeIngredients,
    fetchRecipeIngredients,
    isLoading: isRecipeLoading,
  } = useRecipe({
    id: specificationId,
    chapterType: CHAPTER_TYPES_API["production"],
    isSpecContext: true,
    shouldCallApi: false,
  });

  const [
    characteristicIngredientsViewData,
    setCharacteristicIngredientsViewData,
  ] = useState<CharacteristicIngredientViewModel[]>();

  const {
    value: isAdding,
    setTrue: setIsAddingTrue,
    setFalse: setIsAddingFalse,
  } = useBoolean(false);
  const {
    value: isRemoving,
    setTrue: setIsRemovingTrue,
    setFalse: setIsRemovingFalse,
  } = useBoolean(false);

  const onDropdownVisibleChange = async (visible: boolean) => {
    if (visible) {
      await fetchRecipeIngredients?.();
    }
  };

  const uniqueSortedFlatList = sortByKey({
    array: getUniqueFlatlistFromIngredients(recipeIngredients),
    key: "name",
  });

  useEffect(() => {
    const charIngWithMaterialIdAndIngredientId =
      characteristicIngredients?.map(ingredient => {
        const ingredientWholeData = uniqueSortedFlatList.find(item =>
          ingredient?.ingredientId
            ? item.ingredientId === ingredient?.ingredientId
            : item.materialId === ingredient.materialId
        );

        return {
          ingredientId: ingredientWholeData?.ingredientId,
          materialId: ingredientWholeData?.materialId,
          name: ingredientWholeData?.name,
        };
      }) || [];

    setCharacteristicIngredientsViewData(charIngWithMaterialIdAndIngredientId);
  }, [characteristicIngredients, recipeIngredients]);

  const characteristicIngredientIds = (
    characteristicIngredientsViewData ?? []
  ).map(item => item.ingredientId);

  const onAddCharacteristicIngredient = async (ingredientId: string) => {
    try {
      setIsAddingTrue();

      await updateCharacteristicIngredients?.({
        ingredientIds: [...characteristicIngredientIds, ingredientId],
      });

      setSelectedIngredientId?.(null);
    } catch {
    } finally {
      setIsAddingFalse();
    }
  };

  const onRemoveCharacteristicIngredient = async (ingredientId: string) => {
    try {
      setIsRemovingTrue();

      await updateCharacteristicIngredients?.({
        ingredientIds: [
          ...characteristicIngredientIds.filter(
            characteristicIngredientId =>
              characteristicIngredientId !== ingredientId
          ),
        ],
      });
    } catch (error) {
    } finally {
      setIsRemovingFalse();
    }
  };

  useEffect(() => {
    onDropdownVisibleChange(true);
  }, []);

  const { options } = useMemo(() => {
    const options = uniqueSortedFlatList?.reduce((acc, ingredient) => {
      if (
        !characteristicIngredients?.some(
          item =>
            item?.materialId === ingredient?.materialId ||
            item?.ingredientId === ingredient?.ingredientId
        )
      ) {
        acc.push({
          key: ingredient?.ingredientId,
          label: ingredient?.name,
          value: ingredient?.ingredientId,
        });
      }
      return acc;
    }, []);

    return {
      options,
    };
  }, [recipeIngredients, JSON.stringify(characteristicIngredients)]);

  return {
    onDropdownVisibleChange,
    onAddCharacteristicIngredient,
    onRemoveCharacteristicIngredient,
    isRecipeLoading,
    isAdding,
    isRemoving,
    options,
    characteristicIngredientsViewData,
  };
};

export default useCharacteristicIngredient;
