import { useIntl } from "react-intl";
import { Form } from "antd";
import classNames from "classnames";
import { Heading, Paragraph, Divider } from "@trace-one/design-system";
import { messages } from "components/Library/components/ChapterForm/messages";
import { getClaimInformationFormData } from "./utils";
import { isProductTypeFood } from "utils/library";
import { LibraryItemDetails } from "pages/Library/hocs/withLibraryItemDetails/types";
import libraryMessages, { tabMessages } from "messages/library";
import { claimMessages, generalMessages } from "messages";
import claimFormStyles from "../../styles.module.less";

const ClaimInformation = ({
  libraryItemDetails,
}: {
  libraryItemDetails: LibraryItemDetails;
}) => {
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();

  const formData = getClaimInformationFormData(libraryItemDetails);

  return (
    <div
      className={classNames(claimFormStyles["section-wrapper"], "mr-3", "mb-3")}
    >
      <div className="p-3">
        <Form
          name="claim-information"
          layout="horizontal"
          form={form}
          initialValues={formData}
          labelAlign="left"
          labelCol={{ span: 4 }}
          labelWrap
        >
          <Heading size="xs" color="grey-5" className="mb-3">
            {formatMessage(tabMessages.generalInformation)}
          </Heading>

          <Form.Item
            name="name"
            label={formatMessage(libraryMessages.nameLabel)}
            className="mb-2"
          >
            <Paragraph className="overflow-wrap" data-test-id="claim-name">
              {formData?.name}
            </Paragraph>
          </Form.Item>

          <Form.Item
            name="name"
            label={formatMessage(messages.productTypeLabel)}
            className="mb-2"
          >
            <Paragraph data-test-id="claim-product-type">
              {formatMessage(
                isProductTypeFood(formData?.productTypeId)
                  ? generalMessages.productTypeFood
                  : generalMessages.productTypeFruitsAndVegetables
              )}
            </Paragraph>
          </Form.Item>

          <Form.Item
            name="requiresSupportingDocuments"
            label={formatMessage(claimMessages.requiresSupportingDocument)}
            className="mb-0"
          >
            <Paragraph data-test-id="claim-requires-supporting-documents">
              {formatMessage(
                formData?.requiresSupportingDocuments
                  ? generalMessages.yes
                  : generalMessages.no
              )}
            </Paragraph>
          </Form.Item>

          <Divider color="grey-3" height={24} />

          <Heading size="xs" color="grey-5" className="mb-3">
            {formatMessage(claimMessages.claimTitle)}
          </Heading>

          <Form.Item
            name="claim-sentence"
            label={formatMessage(claimMessages.claimSentenceTitle)}
            className="mb-2"
          >
            <Paragraph
              className="overflow-wrap white-space-break"
              data-test-id="claim-sentence"
            >
              {formData?.claimSentence}
            </Paragraph>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ClaimInformation;
