import { defineMessages } from "react-intl";

export const messages = defineMessages({
  addServing: {
    id: "specificationDetails.widgets.nutritionDeclaration.addServing",
    defaultMessage: "Add Serving",
  },
  modalTitle: {
    id: "specificationDetails.widgets.nutritionDeclaration.modal.title",
    defaultMessage: "Create a serving",
  },
  modalSubtitle: {
    id: "specificationDetails.widgets.nutritionDeclaration.modal.subtitle",
    defaultMessage:
      "Create as many serving(s) as needed. Don't worry, you will be able to edit the details of each serving later.",
  },
  servingName: {
    id: "specificationDetails.widgets.nutritionDeclaration.modal.servingName",
    defaultMessage: "Serving name",
  },
  servingNamePlaceholder: {
    id:
      "specificationDetails.widgets.nutritionDeclaration.modal.servingNamePlaceholder",
    defaultMessage: "Type a name",
  },
  servingSize: {
    id: "specificationDetails.widgets.nutritionDeclaration.modal.servingSize",
    defaultMessage: "Serving name",
  },
  automaticCalculation: {
    id:
      "specificationDetails.widgets.nutritionDeclaration.modal.automaticCalculation",
    defaultMessage: "Automatic calculation",
  },
  automaticCalculationDescription: {
    id:
      "specificationDetails.widgets.nutritionDeclaration.modal.automaticCalculation.description",
    defaultMessage:
      "Changing between automatic and manual calculation will clear previously entered data for this serving",
  },
  modalPrimaryButton: {
    id: "specificationDetails.widgets.nutritionDeclaration.modal.primaryButton",
    defaultMessage: "Confirm",
  },
  modalSecondaryButton: {
    id:
      "specificationDetails.widgets.nutritionDeclaration.modal.secondaryButton",
    defaultMessage: "Cancel",
  },
  editServing: {
    id: "specificationDetails.widgets.nutrition.editServing",
    defaultMessage: "Edit serving",
  },
  removeServing: {
    id: "specificationDetails.widgets.nutrition.removeServing",
    defaultMessage: "Remove serving",
  },
  removeServingDescription: {
    id: "specificationDetails.widgets.nutrition.removeServingDescription",
    defaultMessage:
      "By clicking on delete, you will delete this serving do you confirm this choice?",
  },
});
