import { useContext } from "react";
import { Button } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import { useSpecificationPdfActions } from "hooks";
import { ViewPdfProps } from "./types";
import { SPECIFICATION_STATE } from "utils/constants";
import { pdfMessages } from "hooks/useSpecificationPdfActions/messages";
import { MenuContext } from "components/SpecificationSection/components/Menu/contexts";

const ViewPdf = ({
  specificationId,
  state,
  tradeItemName,
  signature,
  disabled,
}: ViewPdfProps) => {
  const { formatMessage } = useIntl();
  const { isMinified } = useContext(MenuContext);
  const specState = state as SPECIFICATION_STATE;

  const { actions, isLoading } = useSpecificationPdfActions({
    specificationId,
    state: specState,
    tradeItemName,
    signedPdfId: signature?.signedPdfId,
    digitalSigningCertificateId: signature?.digitalSigningCertificateId,
    shouldFetchSpecificationFilesInitially: false,
  });

  return (
    <Button
      type="secondary"
      loading={isLoading}
      items={actions}
      data-test-id="view-pdf-button"
      iconPlacement="right"
      iconName={isMinified ? "pdf-view" : ""}
      disabled={disabled}
    >
      {!isMinified && formatMessage(pdfMessages.viewPdf)}
    </Button>
  );
};

export default ViewPdf;
