import { State } from "./types";

const initialState: State = {
  applications: [],
  isAccountAdministrator: false,
  owningCompanyId: null,
  userId: null,
  userLanguagePreference: "en-US",
  userJobTitle: null,
  userPhone: null,
  userPhotoUrl: null,
  isLoading: true,
  isLoadingApplications: true,
  owningCompanyName: null,
  companyActivityNameInEn: "",
};

export default initialState;
