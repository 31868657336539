import { EmptyState } from "@trace-one/design-system";
import { Row } from "antd";
import { useIntl } from "react-intl";
import { messages } from "./messages";
import styles from "./styles.module.less";

const NoProduct = () => {
  const intl = useIntl();

  return (
    <Row justify="center" className={styles.noProducts}>
      <EmptyState heading={intl.formatMessage(messages.noAddedProducts)} />
    </Row>
  );
};

export default NoProduct;
