import classnames from "classnames";
import { Heading, Paragraph, Modal } from "@trace-one/design-system";
import dayjs from "dayjs";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { selectFolderDetails } from "store/folderCreation/selectors";
import {
  getShareButtonTranslationKey,
  shouldErrorMessageBeDisplayed,
} from "pages/utils";
import { useShareFolderModal } from "./hooks";
import { messages } from "./messages";
import styles from "./styles.module.less";

const ShareFolderModal = ({
  closeShareFolderModal,
  startConfirmedSharingModal,
}) => {
  const { folderName, targetDateUtc } = useSelector(selectFolderDetails);

  const {
    isSharing,
    hasError,
    isDone,
    handleShareFolder,
  } = useShareFolderModal({
    startConfirmedSharingModal,
  });

  return (
    <Modal.Simple
      open
      onCancel={closeShareFolderModal}
      wrapClassName={styles.shareFolderModal}
      title={<FormattedMessage {...messages.title} />}
      illustrationName="confirmation"
      illustrationColor="grey"
      primaryButtonText={
        <FormattedMessage
          {...messages[getShareButtonTranslationKey({ isSharing })]}
        />
      }
      onPrimaryButtonClick={handleShareFolder}
      primaryButtonProps={{
        disabled: isSharing,
      }}
      secondaryButtonText={<FormattedMessage {...messages.noBtn} />}
      onSecondaryButtonClick={closeShareFolderModal}
    >
      <div className={styles.modalBody}>
        <Paragraph
          className={classnames(styles.content, styles.warningMessage)}
        >
          <Paragraph>
            <FormattedMessage
              {...messages.warningMessage}
              values={{
                folderName: (
                  <span className={styles.primary}>{folderName}</span>
                ),
              }}
            />
            &nbsp;:
          </Paragraph>
          <ul>
            <li>
              <FormattedMessage {...messages.firstWarning} />
            </li>
            <li>
              <FormattedMessage {...messages.secondWarning} />
            </li>
            <li>
              <FormattedMessage {...messages.thirdWarning} />
            </li>
            <li>
              <FormattedMessage
                {...messages.fourthWarning}
                values={{
                  targetDate: (
                    <span className={styles.primary}>
                      {dayjs(targetDateUtc).format("DD-MM-YYYY")}
                    </span>
                  ),
                }}
              />
            </li>
          </ul>
        </Paragraph>
        <div className={styles.desc}>
          <Heading size="xs">
            <FormattedMessage {...messages.shareQuestion} />
          </Heading>
          {shouldErrorMessageBeDisplayed({ hasError, isDone }) && (
            <div data-testid="errorMessage" className={styles.errorMessage}>
              <FormattedMessage {...messages.errorMessage} />
            </div>
          )}
        </div>
      </div>
    </Modal.Simple>
  );
};

export default ShareFolderModal;
