import { useState, useEffect } from "react";
import { TagData } from "models";
import { getTagsByTagIds } from "../../utils";

const useGeneralInfo = ({ tagIds }: { tagIds: string[] }) => {
  const [tagNames, setTagNames] = useState<TagData["tagText"][]>();

  useEffect(() => {
    getTagsByTagIds(tagIds).then(res => {
      setTagNames(res.map(item => item.tagText));
    });
  }, [tagIds]);

  return {
    tagNames,
  };
};

export default useGeneralInfo;
