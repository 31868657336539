import { defineMessages } from "react-intl";

export const messages = defineMessages({
  specificationsAreCreatedAndShared: {
    id: "specification.actions.duplicate.specificationsAreCreatedAndShared",
    defaultMessage: "Your specifications are being created and shared.",
  },

  doNotQuit: {
    id: "specification.actions.duplicate.doNotQuit",
    defaultMessage: "Please wait.",
  },
});
