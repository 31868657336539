import { defineMessages } from "react-intl";

export const messages = defineMessages({
  sectionName: {
    id: "creationFolder.spec",
    defaultMessage: "Structure",
  },

  note: {
    id: "creationFolder.spec.note",
    defaultMessage: "Notes",
  },

  noteDescription: {
    id: "creationFolder.spec.noteDescription",
    defaultMessage: "Additional information",
  },

  requirementsTitle: {
    id: "creationFolder.spec.requirements.title",
    defaultMessage: "Requirements",
  },

  requirementsSubtitle: {
    id: "creationFolder.spec.requirements.subtitle",
    defaultMessage: "Assign requirements to the chapters of the specification",
  },

  errorFieldMessage: {
    id: "creation.errors.notSaved",
    defaultMessage: "An error has occurred, the last changes were not saved",
  },
});
