import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { RouterState } from "types/general";
import { scrollToTop } from "utils/general";
import { heightOfHeadersInMaterialPage } from "components/Library/components/MaterialForm/constants";

const useScrollToSection = ({
  elementRef,
}: {
  elementRef: { current: HTMLDivElement };
}) => {
  const { state }: { state: RouterState } = useLocation();

  useEffect(() => {
    if (state?.scrollToMaterialAllergens) {
      // we need setTimeout otherwise while loading other sections can change the height of viewport

      setTimeout(() => {
        const elementPosition = elementRef.current.getBoundingClientRect().top;

        const offset = elementPosition - heightOfHeadersInMaterialPage;

        scrollToTop(offset, "smooth");
      }, 300);
    }
  }, [elementRef.current]);
};

export default useScrollToSection;
