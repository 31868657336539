import { isObjectEmpty } from "utils/general";

export const getHasSpecificationError = ({
  addSpecificationErrors,
  removeSpecificationErrors,
}) => specificationId =>
  (!isObjectEmpty(addSpecificationErrors) &&
    !!addSpecificationErrors[specificationId]) ||
  (!isObjectEmpty(removeSpecificationErrors) &&
    !!removeSpecificationErrors[specificationId]);

export const getLabelMessageKey = ({ displayActions, displayActionList }) => {
  if (!displayActions) return "existingSpecifications";

  return displayActionList
    ? "existingSpecifications"
    : "addExistingSpecifications";
};
