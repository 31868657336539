import { useMemo, useState } from "react";
import { LibraryObjectData } from "models";
import { prepareSelectedLibraryObject } from "./utils";

const useSelectedLibraryObject = ({
  existingLibraryObjectIds,
  isSingleSelect,
  maxItems,
}: {
  existingLibraryObjectIds?: string[];
  isSingleSelect?: boolean;
  maxItems?: number;
}) => {
  const [selectedLibraryObjects, setSelectedLibraryObjects] = useState<
    LibraryObjectData[]
  >([]);
  const [selectedItem, setSelectedItem] = useState<LibraryObjectData>();

  const onSelectLibraryObject = (libraryObject: LibraryObjectData) => {
    if (isSingleSelect) {
      setSelectedItem(libraryObject);
      return;
    }

    if (existingLibraryObjectIds?.includes(libraryObject.id)) return;

    if (
      !!maxItems &&
      selectedLibraryObjects.length + existingLibraryObjectIds.length >=
        maxItems
    )
      return;

    setSelectedLibraryObjects(
      prepareSelectedLibraryObject({ libraryObject, selectedLibraryObjects })
    );
  };

  const selectedLibraryObjectsIds = useMemo(
    () => selectedLibraryObjects?.map(libraryObject => libraryObject?.id),
    [selectedLibraryObjects]
  );

  return {
    selectedLibraryObjects,
    setSelectedLibraryObjects,
    onSelectLibraryObject,
    selectedLibraryObjectsIds,
    selectedItem,
  };
};

export default useSelectedLibraryObject;
