import { useEffect, useState } from "react";
import { downloadFile } from "apis/DOCUMENT";
import useBoolean from "hooks/useBoolean";

const useImageUrl = ({ fileId }: { fileId: string }) => {
  const { value: isDownloading, setTrue, setFalse } = useBoolean();
  const [imageUrl, setImageUrl] = useState<string>();

  useEffect(() => {
    const downloadImage = async () => {
      try {
        setTrue();

        const { data } = await downloadFile({ id: fileId });

        const image = new Blob([data], { type: data?.type });
        const url = URL.createObjectURL(image);

        setImageUrl(url);
      } catch (e) {
      } finally {
        setFalse();
      }
    };

    if (fileId) {
      downloadImage();
    }

    return () => {
      URL.revokeObjectURL(imageUrl);
    };
  }, []);

  return { imageUrl, isDownloading };
};

export default useImageUrl;
