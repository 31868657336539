import { createContext, useReducer } from "react";
import {
  ChapterFormContextState,
  Action,
  ChapterFormProviderProps,
} from "./types";

export const ChapterFormContext = createContext(null);
export const ChapterFormDispatchContext = createContext(null);

export const ChapterFormProvider = ({
  children,
  chapterId,
  getLinkedSpecifications,
}: ChapterFormProviderProps) => {
  const chapterFormReducer = (
    state: ChapterFormContextState,
    action: Action
  ) => {
    switch (action.type) {
      case "initializeForm":
        return {
          ...state,
          form: action.form,
        };
      default:
        return state;
    }
  };

  const [contextState, dispatch] = useReducer(chapterFormReducer, { form: {} });

  return (
    <ChapterFormContext.Provider
      value={{ ...contextState, id: chapterId, getLinkedSpecifications }}
    >
      <ChapterFormDispatchContext.Provider value={dispatch}>
        {children}
      </ChapterFormDispatchContext.Provider>
    </ChapterFormContext.Provider>
  );
};
