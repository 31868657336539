import { useAppDispatch } from "store";
import { useSelector } from "react-redux";
import { selectTranslationManagement } from "store/specificationDetails/selectors";
import { setTranslationManagement } from "store/specificationDetails/specificationDetailsSlice";
import { CompanyListItem } from "components/CreateCollaboration/types";
import { CompanyLangaugeBlock } from "components/TranslationManagement/types";

export interface UseTranslationCompaniesProps {}

const useTranslationCollaboration = () => {
  const {
    companies,
    collaborations,
    ...restOfTranslationManagement
  } = useSelector(selectTranslationManagement);
  const dispatch = useAppDispatch();

  const onAddCollaboration = (
    collaborationId: string,
    collaboratingCompanyId: string
  ) => {
    const updatedCompanies = companies?.map(currentCompanyBlock => {
      if (currentCompanyBlock?.company?.value === collaboratingCompanyId) {
        return {
          ...currentCompanyBlock,
          collaborations: [
            ...currentCompanyBlock?.collaborations,
            collaborationId,
          ],
        };
      }
      return currentCompanyBlock;
    });

    dispatch(
      setTranslationManagement({
        ...restOfTranslationManagement,
        companies: updatedCompanies,
        collaborations: [...collaborations, collaborationId],
      })
    );
  };

  const companyIdsWithLinkedProducts: CompanyListItem[] = companies.reduce(
    (acc, item: CompanyLangaugeBlock) => {
      if (item.company) {
        return [
          ...acc,
          {
            collaboratingCompanyId: item.company.value,
            isCollaborationEnabled: true,
            numberOfLinkedProducts: item.languages.length || 0,
            name: item.company.label,
          },
        ];
      }

      return acc;
    },
    []
  );

  return {
    onAddCollaboration,
    companyIdsWithLinkedProducts,
    existingCollaborationIds: collaborations,
  };
};

export default useTranslationCollaboration;
