import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useIntl } from "react-intl";
import messages from "messages/stepper";
import { StepperStep, UseStepperProps } from "./types";

const useStepper = ({
  currentStep,
  steps,
  stepsState,
  onValidateStep,
  onUpdateStep,
}: UseStepperProps) => {
  const [stepIndex, setStepIndex] = useState(0);
  const [currentIsValid, setCurrentIsValid] = useState(false);
  const { push, location } = useHistory();
  const { formatMessage } = useIntl();

  const isStepValidated = (stepName: string) => {
    return stepsState.find(({ step }) => step === stepName)?.validated;
  };

  const onStepClick = (route: string, stepName: string) => async (
    shouldUpdateCurrentStep = true
  ) => {
    if (shouldUpdateCurrentStep) {
      await onUpdateStep(stepName);
    }

    push(route);
  };

  const stepperSteps = steps.map(({ step, titleKey, route }) => {
    return {
      isCompleted: isStepValidated(step),
      title: formatMessage(messages[titleKey || step]),
      link: route,
      onClick: onStepClick(route, step),
    };
  });

  const onNextStep = async () => {
    if (steps[stepIndex + 1]) {
      await onValidateStep();

      stepperSteps[stepIndex + 1]?.onClick(false);
    }
  };

  const onPreviousStep = async () => {
    if (steps[stepIndex - 1]) {
      await stepperSteps[stepIndex - 1]?.onClick();
    }
  };

  const onStepNavigation = async (stepTo: StepperStep["step"]) => {
    const newStepIndex = steps.findIndex(({ step }) => step === stepTo);
    if (steps[newStepIndex]) {
      await stepperSteps[newStepIndex]?.onClick();
    }
  };

  useEffect(() => {
    setStepIndex(stepsState.findIndex(({ step }) => step === currentStep));

    setCurrentIsValid(isStepValidated(currentStep));
  }, [currentStep, stepsState]);

  useEffect(() => {
    if (currentStep) {
      const step = steps.find(({ step }) => step === currentStep);

      if (step?.route !== location?.pathname) {
        const newStep = steps.find(({ route }) => route === location?.pathname);

        if (newStep) {
          onStepNavigation(newStep.step);
        }
      }
    }
  }, [location?.pathname]);

  return {
    stepIndex,
    currentIsValid,
    steps: stepperSteps,
    onNextStep,
    onPreviousStep,
    isStepValidated,
    onStepNavigation,
  };
};

export default useStepper;
