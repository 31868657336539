import { Picker } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import filterMessages from "messages/filters";
import libraryMessages from "messages/library";
import { LIBRARY_REQUIREMENT_TYPES } from "utils/constants";

const RequirementType = ({
  getSelectedPickerItems,
}: {
  getSelectedPickerItems: Function;
}) => {
  const { formatMessage } = useIntl();

  return (
    <Picker.FilterV1
      title={formatMessage(filterMessages.requirementType)}
      data={[
        {
          label: formatMessage(libraryMessages.typeDocument),
          value: LIBRARY_REQUIREMENT_TYPES.DOCUMENT,
        },
        {
          label: formatMessage(libraryMessages.typeText),
          value: LIBRARY_REQUIREMENT_TYPES.TEXT,
        },
      ]}
      getSelectedPickerItems={requirementTypes =>
        getSelectedPickerItems(
          requirementTypes?.length === 1 ? requirementTypes[0] : null
        )
      }
    />
  );
};

export default RequirementType;
