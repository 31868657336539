import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fetchReferenceListsItem } from "apis/RLMD";
import { selectLanguageCode } from "store/user/selectors";
import { ProductTypeProps } from "../../types";

const useProductType = ({ productTypeId }: ProductTypeProps) => {
  const languageCode = useSelector(selectLanguageCode);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [productType, setProductType] = useState<string>("");

  const getProductType = async () => {
    if (!productTypeId) {
      setProductType("");
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    setHasError(false);

    try {
      const { data } = await fetchReferenceListsItem({
        languageCode,
        id: productTypeId,
      });

      setProductType(data.text);
    } catch (error) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getProductType();
  }, [productTypeId, languageCode]);

  return { isLoading, hasError, productType };
};

export default useProductType;
