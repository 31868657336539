import dayjs from "dayjs";
import { FormattedMessage, useIntl } from "react-intl";
import { isListEmpty } from "utils/general";
import TableColumns from "components/TableColumns";
import Info from "./components/Info";
import { isLibraryObjectTemplatesType, isProductTypeFood } from "utils/library";
import messages from "messages/library";
import generalMessages from "messages/general";
import { InformationCardProps } from "./types";
import styles from "./styles.module.less";

const InformationCard: React.FC<InformationCardProps> = ({
  name,
  nameLabel,
  type,
  status,
  lastModificationDate,
  tags,
  internalCode,
  versionNumber,
  productTypeId,
  displayVersionNumber,
}) => {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.informationCard}>
      {!!name && !!nameLabel && <Info label={nameLabel}>{name}</Info>}

      {!!type && !isLibraryObjectTemplatesType(type) && (
        <Info label={formatMessage(messages.typeLabel)}>{type}</Info>
      )}

      {!!productTypeId && (
        <Info label={formatMessage(generalMessages.productTypeLabel)}>
          {formatMessage(
            isProductTypeFood(productTypeId)
              ? generalMessages.productTypeFood
              : generalMessages.productTypeFruitsAndVegetables
          )}
        </Info>
      )}

      {typeof status !== "undefined" && (
        <Info
          label={formatMessage(messages.statusField["label"])}
          className={styles.statusInfo}
        >
          {status ? (
            <FormattedMessage {...messages.statusField["active"]} />
          ) : (
            <FormattedMessage {...messages.statusField["inactive"]} />
          )}
        </Info>
      )}

      {!!lastModificationDate && (
        <Info
          label={formatMessage(messages.lastModificationLabel)}
          className={styles.lastModificationInfo}
        >
          {dayjs(lastModificationDate).isValid()
            ? dayjs(lastModificationDate).local().format("L")
            : ""}
        </Info>
      )}
      {!!versionNumber && displayVersionNumber && (
        <Info label={formatMessage(generalMessages.version)}>
          {versionNumber}
        </Info>
      )}
      {!!internalCode ? (
        <Info label={formatMessage(messages.internalCodeLabel)}>
          {internalCode}
        </Info>
      ) : (
        internalCode !== undefined && (
          <Info label={`${formatMessage(messages.internalCodeLabel)} -`} />
        )
      )}
      {!!tags && (
        <Info
          label={formatMessage(messages.tagsLabel)}
          className={styles.tagsInfo}
        >
          {isListEmpty(tags) ? (
            <FormattedMessage {...generalMessages.noTags} />
          ) : (
            <TableColumns.Tags tags={tags} />
          )}
        </Info>
      )}
    </div>
  );
};

export default InformationCard;
