import { Paragraph, Icon, Button } from "@trace-one/design-system";
import { Row, Col } from "antd";
import { useIntl } from "react-intl";
import { useUsersAutocomplete } from "./hooks";
import { CollaborationStatusAndActions } from "./components";
import { messages } from "components/CreateCollaboration/messages";
import { isListEmpty } from "utils/general";
import { CollaborationsProps } from "./types";
import { CollaborationData } from "../../types";
import styles from "components/CreateCollaboration/styles.module.less";
import InfiniteScrollSelect from "components/InfiniteScrollSelect";

const Collaborations: React.FC<CollaborationsProps> = ({
  collaborations,
  collaboratingCompanyId,
  onSelectUser,
  onCreateCollaboration,
  onRemoveCollaborations,
  onAddCollaboration,
  hideCollaborationActions,
  isCollaborationEnabled,
  hideRemoveCollaboration,
  ignoreApplicationTypeIds,
}) => {
  const { formatMessage } = useIntl();

  const {
    handleSearch,
    usersList,
    clearUsersList,
    onBlur,
    handleSelect,
    handleClear,
    handleAddUser,
    valueWithLabel,
    isLoading,
    handleDropdownVisibleChange,
    getUsersList,
  } = useUsersAutocomplete({
    collaboratingCompanyId,
    onSelectUser,
    selectedUserIds: collaborations.map(({ userId }) => userId),
    isCollaborationEnabled,
    collaborations,
    ignoreApplicationTypeIds,
  });

  const isAddUserDisabled =
    !isListEmpty(collaborations) &&
    !collaborations[collaborations.length - 1]?.id;

  const isUserSelectedOrInvited = (collaboration: CollaborationData) =>
    collaboration.userId || collaboration.userEmail;

  return (
    <Col xs={24}>
      {collaborations.map(collaboration => (
        <Row key={collaboration.key} className={styles.collaborationRow}>
          <Col>
            <Icon name="hierarchy-link" size="large" color="grey-5" />
          </Col>
          <Col xs={5}>
            <Paragraph>{collaboration.responsibilityName}</Paragraph>
          </Col>
          <Col xs={12} md={8}>
            {["notInvited", "invite"].includes(collaboration.status) ? (
              <InfiniteScrollSelect
                labelInValue
                filterOption={false}
                allowClear={true}
                showSearch={true}
                value={isUserSelectedOrInvited(collaboration) && valueWithLabel}
                loading={isLoading}
                options={usersList}
                onBlur={onBlur}
                onClear={handleClear(collaboration.key)}
                onSelect={handleSelect(collaboration.key)}
                placeholder={formatMessage(messages.userInputPlaceholder)}
                onSearch={handleSearch(collaboration.key)}
                onDropdownVisibleChange={handleDropdownVisibleChange}
                next={getUsersList}
              />
            ) : (
              <Paragraph>
                {collaboration.userName || collaboration.userEmail}
              </Paragraph>
            )}
          </Col>
          <Col className={styles.tagColumn}>
            <CollaborationStatusAndActions
              clearUsersList={clearUsersList}
              collaboration={collaboration}
              onCreateCollaboration={onCreateCollaboration}
              onRemoveCollaborations={onRemoveCollaborations}
              hideCollaborationActions={hideCollaborationActions}
              hideRemoveCollaboration={hideRemoveCollaboration}
            />
          </Col>
        </Row>
      ))}
      {!hideCollaborationActions && (
        <Row className={styles.collaborationRow}>
          <Col>
            <Icon name="hierarchy-link" size="large" color="grey-5" />
          </Col>
          <Button
            type="link"
            onClick={handleAddUser(onAddCollaboration)}
            data-testid="add-new-collaboration"
            disabled={isAddUserDisabled}
          >
            {formatMessage(messages.addAUser)}
          </Button>
        </Row>
      )}
    </Col>
  );
};

export default Collaborations;
