import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "business.create.collaboration.title",
    defaultMessage: "Suppliers",
  },
  productLinked: {
    id: "business.create.collaboration.supplier.productLinked",
    defaultMessage:
      "{count, plural, one {# product linked} other {# products linked}}",
  },
  languageLinked: {
    id: "business.create.collaboration.languageLinked",
    defaultMessage:
      "{count, plural, one {# language to translate} other {# languages to translate}}",
  },
  enableCollaboration: {
    id: "business.create.collaboration.supplier.enableCollaboration",
    defaultMessage: "Enable collaboration",
  },
  disableCollaborations: {
    id: "business.create.collaboration.supplier.disableCollaborations",
    defaultMessage:
      "Disabling collaboration will remove invited users and cancel all pending invitations",
  },
  cancelDisableCollaborations: {
    id: "business.create.collaboration.supplier.disableCollaborations.cancel",
    defaultMessage: "Cancel",
  },
  confirmDisableCollaborations: {
    id: "business.create.collaboration.supplier.disableCollaborations.confirm",
    defaultMessage: "Disable",
  },
  infoTitle: {
    id: "business.create.collaboration.supplier.enableCollaboration.info.title",
    defaultMessage: "Invitation to collaborate",
  },
  infoDescription1: {
    id:
      "business.create.collaboration.supplier.enableCollaboration.info.description1",
    defaultMessage: "Invite each person that need to access to your project.",
  },
  infoDescription2: {
    id:
      "business.create.collaboration.supplier.enableCollaboration.info.description2",
    defaultMessage:
      "Select a user in the list for this company or enter an email address if you don't find the right person.",
  },
  userInputPlaceholder: {
    id: "business.create.collaboration.supplier.user.inputPlaceholder",
    defaultMessage: "Search an existing user or type an email address",
  },
  notInvited: {
    id:
      "business.create.collaboration.supplier.collaboration.status.notInvited",
    defaultMessage: "Not invited",
  },
  invite: {
    id: "business.create.collaboration.supplier.collaboration.status.invite",
    defaultMessage: "Invite",
  },
  pending: {
    id: "business.create.collaboration.supplier.collaboration.status.pending",
    defaultMessage: "Sent at {sentAt}",
  },
  active: {
    id: "business.create.collaboration.supplier.collaboration.status.active",
    defaultMessage: "Invited",
  },
  removeUser: {
    id: "business.create.collaboration.supplier.collaboration.removeUser",
    defaultMessage: "Remove user",
  },
  cancelInvitation: {
    id: "business.create.collaboration.supplier.collaboration.cancelInvitation",
    defaultMessage: "Cancel invitation",
  },
  addAUser: {
    id: "business.create.collaboration.supplier.collaboration.addAUser",
    defaultMessage: "Add a user",
  },
  emailInvitationTitle: {
    id:
      "business.create.collaboration.supplier.collaboration.email.invitation.title",
    defaultMessage: "Add a message (optional)",
  },
  sendEmailInvitation: {
    id:
      "business.create.collaboration.supplier.collaboration.email.invitation.send",
    defaultMessage: "Send invitation",
  },
  cancelEmailInvitation: {
    id:
      "business.create.collaboration.supplier.collaboration.email.invitation.cancel",
    defaultMessage: "Cancel",
  },
});
