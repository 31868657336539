import { Tag, Tooltip } from "@trace-one/design-system";
import { FilterTagProps } from "./types";

const FilterTag: React.FC<FilterTagProps> = ({ filterTag, disabled }) => {
  return (
    <Tooltip size="small" text={filterTag.tooltip}>
      <Tag
        label={filterTag.name}
        {...(!disabled && {
          onClose: filterTag.onClose,
        })}
        disabled={disabled}
      />
    </Tooltip>
  );
};

export default FilterTag;
