import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectOwningCompanyId } from "store/user/selectors";
import { searchUsers } from "apis/CUMD";
import { UserData } from "models";

const useUsers = () => {
  const owningCompanyId = useSelector(selectOwningCompanyId);

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [users, setUsers] = useState<UserData[]>([]);

  const getUsers = async () => {
    try {
      setIsLoading(true);

      const { data } = await searchUsers({
        params: {
          isEnabled: true,
          owningCompanyId,
          skip: 0,
          take: 1000,
        },
      });

      setUsers(data.users);
    } catch (e) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  return {
    isLoading,
    hasError,
    users,
  };
};

export default useUsers;
