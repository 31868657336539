import { useMemo } from "react";
import { Row, Col } from "antd";
import { Heading } from "@trace-one/design-system";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { selectRefListDictionary } from "store/generalData/selectors";
import {
  selectOwnerContacts,
  selectOwnerUserDetails,
  selectSpecification,
} from "store/specificationCreation/selectors";
import { getSelectedOwnerContactsNames } from "pages/utils";
import { messages } from "./messages";
import styles from "./styles.module.less";

const Details = () => {
  const { formatMessage } = useIntl();
  const ownerContacts = useSelector(selectOwnerContacts);
  const reflistDictionary = useSelector(selectRefListDictionary);
  const ownerUserDetails = useSelector(selectOwnerUserDetails);
  const { productTypeId } = useSelector(selectSpecification);

  const selectedOwnerContacts = useMemo(
    () =>
      getSelectedOwnerContactsNames([
        { ...ownerUserDetails, isSelected: true },
        ...ownerContacts,
      ]),
    [ownerContacts]
  );

  return (
    <Row className={styles.detailsRow} gutter={[20, 15]}>
      <Col>
        <Heading size="xs">
          {formatMessage(messages.detailsProductType)}:
          <span className={styles.value}>
            {reflistDictionary[productTypeId]}
          </span>
        </Heading>
      </Col>
      <Col>
        <Heading size="xs">
          {formatMessage(messages.detailsRetailerContact)}:
          <span className={styles.value}>{selectedOwnerContacts}</span>
        </Heading>
      </Col>
    </Row>
  );
};

export default Details;
