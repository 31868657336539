import { useIntl } from "react-intl";
import { Select } from "@trace-one/design-system";
import { LabeledValue } from "antd/lib/select";
import { generalMessages, translationManagementMessages } from "messages";
import useLanguages from "./useLanguages";

export type TranslationCompaniesProps = {
  onSelect: (values: LabeledValue) => void;
  onDeselect?: (values: LabeledValue) => void;
  excludedLanguages: string[];
  onClear?: () => void;
  values?: string[];
};

const LanguageSelect: React.FC<TranslationCompaniesProps> = ({
  onSelect,
  onDeselect,
  excludedLanguages,
  onClear,
  values = [],
}) => {
  const { formatMessage } = useIntl();

  const { languages, isLoading, onDropdownVisibleChange } = useLanguages({
    preFetch: values.length > 0,
  });

  const options = languages.filter(
    language => !excludedLanguages.includes(language.value as string)
  );

  return (
    <Select
      value={languages?.filter(language =>
        values?.includes(language.value as string)
      )}
      showArrow
      labelInValue
      mode="multiple"
      name="languages"
      data-test-id="languages-select"
      placeholder={formatMessage(
        translationManagementMessages.translationCompanyLanguagePlaceholder
      )}
      options={options}
      notFoundMessage={formatMessage(generalMessages.noItemFound)}
      loading={isLoading}
      onDropdownVisibleChange={onDropdownVisibleChange}
      onSelect={onSelect}
      onDeselect={onDeselect}
      onClear={onClear}
      showSearch={false}
    />
  );
};

export default LanguageSelect;
