import { useMemo, useRef } from "react";
import classnames from "classnames";
import { Radio } from "@trace-one/design-system";
import RequestModificationTooltipIcon from "components/RequestModificationTooltipIcon";
import withRequestModificationPopover from "hocs/withRequestModificationPopover";
import { useWidgetActions } from "../../hooks";
import { useRadioWidget } from "./hooks";
import { useAppDispatch } from "store";
import { setRequestModification } from "store/specificationDetails/specificationDetailsSlice";
import {
  canTargetField,
  doesFieldHaveModificationRequest,
  getDraftRequestFromAllRequests,
} from "utils/modificationRequest";
import { getArrayIndexFromId } from "../utils";
import { RadioWidgetProps } from "./types";
import widgetStyle from "../styles.module.less";

const RadioWidget: React.FC<RadioWidgetProps> = props => {
  const {
    formContext,
    schema,
    id,
    setModalStyle,
    setSelectedField,
    onOpenModal,
    setRequestPopoverOptions,
  } = props;

  const dispatch = useAppDispatch();

  const radioWidgetRef = useRef<HTMLDivElement>(null);

  const {
    templateBlockId,
    sectionId,
    arraySchemaItemKey,
    isTargetMode,
    formData,
    modificationRequests,
    ownerCompanyId,
    chapterData,
  } = formContext;

  const { propertyId } = schema;

  const { isWidgetChanged } = useWidgetActions({ templateBlockId, sectionId });

  const radioProps = useRadioWidget(props);

  const index = getArrayIndexFromId(id);

  const listItemKey =
    (formData?.items?.[index]?.[arraySchemaItemKey] as string) || null;

  const fieldId = `${listItemKey ? `${listItemKey}-` : ""}${propertyId}`;

  const hasOnClick = useMemo(
    () =>
      canTargetField({
        isTargetMode,
        specOwnerCompanyId: ownerCompanyId,
        chapterOwnerCompanyId: chapterData?.ownerCompanyId,
      }),
    [ownerCompanyId, chapterData?.ownerCompanyId, isTargetMode]
  );

  const hasDraftModificationRequest = useMemo(() => {
    return getDraftRequestFromAllRequests({
      modificationRequests,
      propertyId: propertyId,
      listItemKey: listItemKey,
    });
  }, [modificationRequests, propertyId]);

  const renderPopoverIcon = () => {
    const request = getDraftRequestFromAllRequests({
      modificationRequests,
      propertyId,
      listItemKey,
    });

    if (request) {
      return (
        <RequestModificationTooltipIcon
          key={`${propertyId}`}
          onOpenModal={onOpenModal}
          setModalStyle={setModalStyle}
          tableRef={radioWidgetRef}
          setRequestPopoverOptions={setRequestPopoverOptions}
          request={request}
        />
      );
    }
  };

  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    if (
      doesFieldHaveModificationRequest({
        modificationRequests,
        propertyId,
        listItemKey,
      })
    ) {
      return;
    }

    (event.target as HTMLElement)
      .closest(".ant-radio-group")
      .classList.add("target-mode__border");

    setModalStyle({ left: "7%", top: "50%" });

    onOpenModal?.();

    setSelectedField({
      propertyId,
      listItemKey,
    });

    dispatch(setRequestModification({ isEditorOpen: true }));
  };

  return (
    <div
      ref={radioWidgetRef}
      data-field-id={fieldId}
      className={classnames({
        [widgetStyle["input-wrapper"]]: true,
        [widgetStyle.inRevisionChange]: isWidgetChanged(propertyId, id),
        [widgetStyle["target-mode__input-pointer-event"]]: canTargetField({
          isTargetMode,
          specOwnerCompanyId: ownerCompanyId,
          chapterOwnerCompanyId: chapterData?.ownerCompanyId,
        }),
        "target-mode": canTargetField({
          isTargetMode,
          specOwnerCompanyId: ownerCompanyId,
          chapterOwnerCompanyId: chapterData?.ownerCompanyId,
        }),
        "target-mode__background": isTargetMode && hasDraftModificationRequest,
        "target-mode__border-color":
          isTargetMode && hasDraftModificationRequest,
        "target-mode__border-width":
          isTargetMode && hasDraftModificationRequest,
      })}
      onClick={hasOnClick ? onClick : null}
    >
      <Radio.Group {...radioProps} />

      {isTargetMode ? renderPopoverIcon() : null}
    </div>
  );
};

export default withRequestModificationPopover(RadioWidget);
