import React from "react";
import { Form } from "antd";
import cleanDeep from "clean-deep";
import OriginsList from "components/Library/components/MaterialForm/components/Suppliers/components/OriginsList";
import { ArrayFieldTemplateProps } from "components/SpecificationSection/types";
import { MaterialOriginData } from "models";
import {
  getArraySchemaMaxItems,
  isListEmpty,
  isObjectEmpty,
} from "utils/general";
import ProductOriginsReadOnly from "./components/ProductOriginsReadOnly";

const ProductOriginsArrayFieldTemplate: React.FC<ArrayFieldTemplateProps> = ({
  formContext,
  uiSchema,
  schema,
}) => {
  const {
    formData,
    saveTemplateBlock,
    refListDictionary,
    extraErrors,
    isWidgetEditable,
    emptyValue,
    displayActions,
  } = formContext;

  const maxItems = getArraySchemaMaxItems(schema);

  const propertyId = schema?.propertyId;
  const canUpdateOrigins = isWidgetEditable(propertyId);

  const addOriginHandler = async (
    value: MaterialOriginData["originTypeId"]
  ) => {
    const payload = isObjectEmpty(formData)
      ? {
          codeAndDates: {
            productOrigins: [
              {
                originTypeId: value,
              },
            ],
          },
        }
      : {
          ...formData,
          codeAndDates: {
            //@ts-ignore
            ...formData?.codeAndDates,
            productOrigins: [
              //@ts-ignore
              ...(formData?.codeAndDates?.productOrigins
                ? //@ts-ignore
                  formData?.codeAndDates?.productOrigins
                : []),
              { originTypeId: value },
            ],
          },
        };

    await saveTemplateBlock(JSON.stringify(payload));
  };

  const updateOriginHandler = async (value: MaterialOriginData) => {
    //@ts-ignore
    const updatedOrigins = formData?.codeAndDates?.productOrigins?.map(item => {
      if (item?.originTypeId === value.typeId) {
        const { typeId, ...rest } = value;

        return cleanDeep({
          ...rest,
          originTypeId: typeId,
        });
      }
      return item;
    });

    const payload = {
      ...formData,
      codeAndDates: {
        //@ts-ignore
        ...formData?.codeAndDates,
        productOrigins: [...updatedOrigins],
      },
    };

    await saveTemplateBlock(JSON.stringify(payload));
  };

  const removeOriginHandler = async (
    value: MaterialOriginData["originTypeId"]
  ) => {
    //@ts-ignore
    const updatedOriginsPayload = formData?.codeAndDates?.productOrigins?.filter(
      item => item?.originTypeId !== value
    );

    const payload = {
      ...formData,
      codeAndDates: {
        //@ts-ignore
        ...formData?.codeAndDates,
        productOrigins: [...updatedOriginsPayload],
      },
    };

    await saveTemplateBlock(JSON.stringify(payload));
  };

  // @ts-ignore
  const productOrigins = formData?.codeAndDates?.productOrigins;

  if (!canUpdateOrigins && isListEmpty(productOrigins)) {
    return null;
  }

  return (
    <Form.Item
      label={uiSchema["ui:title"]}
      labelAlign="left"
      labelCol={{ xs: 24 }}
    >
      {displayActions ? (
        <OriginsList
          origins={productOrigins}
          refListDictionary={refListDictionary}
          supplierId=""
          displayActions={canUpdateOrigins}
          isAddOriginDisabled={!canUpdateOrigins}
          {...(!maxItems || maxItems !== productOrigins?.length
            ? {
                handleAddOrigin: addOriginHandler,
              }
            : {})}
          handleUpdateOrigin={updateOriginHandler}
          handleRemoveOrigin={removeOriginHandler}
          uiSchema={uiSchema}
          extraErrors={extraErrors?.codeAndDates?.productOrigins}
        />
      ) : (
        <ProductOriginsReadOnly
          productOrigins={productOrigins}
          emptyValue={emptyValue}
          refListDictionary={refListDictionary}
          uiSchema={uiSchema}
        />
      )}
    </Form.Item>
  );
};

export default ProductOriginsArrayFieldTemplate;
