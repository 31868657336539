import { useIntl } from "react-intl";
import libraryMessages from "messages/library";
import useRequirementFormActions from "../../hooks/useRequirementFormActions";
import DeleteAndKeepDraftModal from "components/Library/components/DeleteAndKeepDraftModal";
import { useSelector } from "react-redux";
import { selectRequirementInformation } from "store/requirementForm/selectors";
import { FormInstance } from "antd";

const CancelDeleteRequirementModal = ({ form }: { form: FormInstance }) => {
  const { formatMessage } = useIntl();

  const { id } = useSelector(selectRequirementInformation);

  const {
    onKeepAsDraftRequirement,
    onDeleteRequirement,
  } = useRequirementFormActions({
    validateFields: form.validateFields,
  });

  return (
    <DeleteAndKeepDraftModal
      id={id}
      title={formatMessage(libraryMessages.cancelRequirementCreationTitle)}
      description={formatMessage(
        libraryMessages.cancelRequirementCreationDescription
      )}
      primaryButtonText={formatMessage(libraryMessages.deleteRequirement)}
      onKeepAsDraftAction={onKeepAsDraftRequirement}
      onDeleteAction={onDeleteRequirement}
    />
  );
};

export default CancelDeleteRequirementModal;
