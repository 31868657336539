import { ChapterData } from "models";
import { isListEmpty, isObjectEmpty } from "utils/general";

export const getNewBlockData = ({
  currentData,
  blockId,
}: {
  currentData: ChapterData;
  blockId: string;
}) => {
  let newBlockData = {};

  if (isObjectEmpty(currentData)) {
    return newBlockData;
  }

  if (isListEmpty(currentData?.sections)) {
    return newBlockData;
  }

  currentData?.sections.forEach(currentSection => {
    if (!isListEmpty(currentSection?.blocks)) {
      currentSection.blocks.forEach(currentBlock => {
        if (currentBlock.blockId === blockId) {
          newBlockData = JSON.parse(currentBlock.blockJson);
        }
      });
    }
  });

  return newBlockData;
};
