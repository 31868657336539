import { useEffect, useState } from "react";
import { useParams, generatePath } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  fetchSpecificationSignatureType,
  updateSpecificationDigitalSignature,
  fetchSpecification,
} from "apis/SPEC";
import { fetchEmbeddedSigning } from "apis/DOCUMENT";
import { selectLanguageCode } from "store/user/selectors";
import { SpecificationData } from "models";
import { ROUTES, SPECIFICATION_STATE } from "utils/constants";
import { UseDigitalSignModalProps } from "./types";
import { SignatureType } from "../../constants";

const useDigitalSignModal = ({
  onClose,
  refetchSpecificationInformation,
}: UseDigitalSignModalProps) => {
  const languageCode = useSelector(selectLanguageCode);
  const [hasDigitalSigning, setHasDigitalSigning] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasSignError, setHasSignError] = useState<boolean>(false);
  const [
    digitalSigningRequestId,
    setDigitalSigningRequestId,
  ] = useState<string>(null);

  const [
    specificationState,
    setSpecificationState,
  ] = useState<SPECIFICATION_STATE>(null);

  const { specificationId } = useParams<{
    specificationId: SpecificationData["id"];
  }>();

  useEffect(() => {
    getSignatureType();
  }, []);

  useEffect(() => {
    if (hasDigitalSigning) {
      createDigitalSigning();
    }
  }, [hasDigitalSigning]);

  useEffect(() => {
    if (digitalSigningRequestId) {
      getSpecification();
    }
  }, [digitalSigningRequestId]);

  useEffect(() => {
    if (specificationState) {
      getEmbeddedSigning();
    }
  }, [specificationState]);

  const getSignatureType = async () => {
    try {
      const { data } = await fetchSpecificationSignatureType({
        id: specificationId,
      });
      setHasDigitalSigning(data === SignatureType.BASIC_DIGITAL_SIGNATURE);
    } catch (_) {
    } finally {
      setIsLoading(false);
    }
  };

  const createDigitalSigning = async () => {
    try {
      const { data } = await updateSpecificationDigitalSignature({
        id: specificationId,
        languageCode,
      });
      setDigitalSigningRequestId(data);
    } catch (e) {
      errorHandler();
    }
  };

  const getSpecification = async () => {
    try {
      const { data } = await fetchSpecification({
        id: specificationId,
        languageCode,
      });

      setSpecificationState(data.state as SPECIFICATION_STATE);
    } catch (_) {
      errorHandler();
    }
  };

  const getEmbeddedSigning = async () => {
    const redirectUrl = generatePath(
      ROUTES.SPECIFICATION_WAITING_DIGITAL_SIGNATURE,
      {
        specificationId,
        specificationState: specificationState,
      }
    );

    try {
      const { data } = await fetchEmbeddedSigning({
        requestId: digitalSigningRequestId,
        redirectUrl: `${window.env.BASE_URL}${redirectUrl}`,
      });

      window.location.href = data;
    } catch (_) {
      errorHandler();
    }
  };

  const closeHandler = e => {
    refetchSpecificationInformation();
    setHasSignError(false);
    onClose(e);
  };

  const errorHandler = () => {
    setHasSignError(true);
  };

  return { hasDigitalSigning, isLoading, hasSignError, closeHandler };
};

export default useDigitalSignModal;
