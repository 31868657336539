/* istanbul ignore file */
import { useModalVisibility } from "hooks";
import SpecificationHistorisationModal from "components/SpecificationHistorisationModal";

const withHistorySpecificationAction = Component => props => {
  const { isModalOpen, onOpenModal, onCloseModal } = useModalVisibility();

  const { specification } = props;

  return (
    <>
      <Component {...props} onOpenHistoryModal={onOpenModal} />
      {isModalOpen && (
        <SpecificationHistorisationModal
          specificationId={specification.specificationId}
          ownerCompanyId={specification.ownerCompanyId}
          supplierId={specification.supplierId}
          onCloseModal={onCloseModal}
        />
      )}
    </>
  );
};

export default withHistorySpecificationAction;
