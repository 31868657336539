import { useContext } from "react";
import { Checkbox } from "@trace-one/design-system";
import { utils } from "@rjsf/core";
import { NutritionBasicContext } from "components/SpecificationSection/components/FormSchema/components/NutritionBasic/contexts";
import { DisplayToConsumerProps } from "./types";

const DisplayToConsumer: React.FC<DisplayToConsumerProps> = ({
  value,
  disabled,
  nutrientId,
  onChange,
}) => {
  const { resolveSchema } = utils;
  const { sectionId, schema, formContext } = useContext(NutritionBasicContext);
  const { isWidgetEditable } = formContext;

  const displayToConsumerSchema = resolveSchema(
    // @ts-ignore-next
    schema?.properties?.nutrientFamilies?.items?.properties?.nutrients?.items
      ?.properties?.displayToConsumer,
    schema
  );

  const hasUpdatePermission = isWidgetEditable(
    // @ts-ignore-next
    displayToConsumerSchema.propertyId
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const data = {
      displayToConsumer: event.target.checked,
    };
    onChange({ sectionId, nutrientId, data });
  };

  return (
    <Checkbox
      checked={value}
      onChange={handleChange}
      disabled={!hasUpdatePermission || disabled}
    />
  );
};

export default DisplayToConsumer;
