import { defineMessages } from "react-intl";

export const messages = defineMessages({
  sendRequest: {
    id: "specification.actions.revision.send",
    defaultMessage: "Send Request",
  },
  cancel: {
    id: "library.form.button.cancel",
    defaultMessage: "Cancel",
  },
  specificationInRevision: {
    id: "status.warning.inRevisionMode",
    defaultMessage: "This Specification is in Revision mode",
  },
  shareSpecification: {
    id: "general.yes",
    defaultMessage: "Yes",
  },
  closeAskForRevisionModal: {
    id: "general.no",
    defaultMessage: "No",
  },
  askForRevisionModalTitle: {
    id: "specification.actions.revision.modal.askForRevisionModal.title",
    defaultMessage: "Ask for Revision",
  },
  modalDescription: {
    id: "specification.actions.revision.modal.askForRevisionModal.description",
    defaultMessage: "Confirm the request for revision to <b>{retailerName}</b>",
  },
  requestSentModalDescription: {
    id: "specification.actions.revision.modal.requestSentModal.description",
    defaultMessage:
      "Your specification revision request has been sent to <b>{retailerName}</b>",
  },
  requestSentModalTitle: {
    id: "specification.actions.revision.modal.requestSentModal.title",
    defaultMessage: "Request Sent",
  },
  buttonsActionText: {
    id:
      "specification.actions.revision.modal.requestSentModal.buttonsActionText",
    defaultMessage: "Do you wish to:",
  },
  specificationViewBtn: {
    id:
      "specification.actions.revision.modal.requestSentModal.buttonViewSpecification",
    defaultMessage: "View this specification",
  },
  specificationsListingBtn: {
    id:
      "specification.actions.revision.modal.requestSentModal.buttonViewListing",
    defaultMessage: "Go to specifications listing",
  },
});
