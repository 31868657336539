import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import GeneralInformation from "../../components/GeneralInformation";
import LinkedSpecifications from "components/Library/components/ChapterForm/components/LinkedSpecifications";
import VersionHistory from "components/Library/components/ChapterForm/components/VersionHistory";
import { useLinkedSpecifications } from "hooks";
import { CHAPTER_TYPES_URL_API, LIBRARY_TAB_KEYS } from "utils/constants";
import { tabMessages } from "messages/library";
import { isLibraryObjectChapterSubType } from "utils/library";
import { LibraryItemDetails } from "pages/Library/hocs/withLibraryItemDetails/types";
import { LibraryObjectType } from "types/library";

const useTabNavigation = ({
  libraryItemDetails,
  type,
}: {
  libraryItemDetails: LibraryItemDetails;
  type: LibraryObjectType;
}) => {
  const { formatMessage } = useIntl();
  const { location } = useHistory();
  const [activeTab, setActiveTab] = useState("");
  const [tabContent, setTabContent] = useState(null);

  const {
    totalCount: numberOfLinkedSpecs,
    getLinkedSpecifications,
  } = useLinkedSpecifications({
    chapterType: libraryItemDetails?.generalInfo?.type,
    chapterId: libraryItemDetails?.generalInfo?.id,
  });

  const componentMap = {
    [LIBRARY_TAB_KEYS.GENERAL_INFORMATION]: <GeneralInformation />,
    [LIBRARY_TAB_KEYS.LINKED_SPECIFICATION]: (
      <LinkedSpecifications
        chapterId={libraryItemDetails?.generalInfo?.id}
        chapterType={libraryItemDetails?.generalInfo?.type}
      />
    ),
    [LIBRARY_TAB_KEYS.VERSION_HISTORY]: (
      <VersionHistory
        chapterId={libraryItemDetails?.generalInfo?.id}
        chapterType={libraryItemDetails?.generalInfo?.type}
        linkedSpecificationsCallback={getLinkedSpecifications}
      />
    ),
  };

  useEffect(() => {
    if (location.pathname.includes(LIBRARY_TAB_KEYS.LINKED_SPECIFICATION)) {
      setActiveTab(LIBRARY_TAB_KEYS.LINKED_SPECIFICATION);
    } else if (location.pathname.includes(LIBRARY_TAB_KEYS.VERSION_HISTORY)) {
      setActiveTab(LIBRARY_TAB_KEYS.VERSION_HISTORY);
    } else {
      setActiveTab(LIBRARY_TAB_KEYS.GENERAL_INFORMATION);
    }
  }, [location]);

  useEffect(() => {
    if (activeTab) {
      setTabContent(componentMap[activeTab]);
    }
  }, [activeTab]);

  const tabItems = isLibraryObjectChapterSubType(CHAPTER_TYPES_URL_API[type])
    ? [
        {
          key: LIBRARY_TAB_KEYS.GENERAL_INFORMATION,
          label: formatMessage(tabMessages.generalInformation),
        },
        {
          key: LIBRARY_TAB_KEYS.LINKED_SPECIFICATION,
          label: formatMessage(tabMessages.linkedSpecifications, {
            numberOfLinkedSpecs,
          }),
        },
        {
          key: LIBRARY_TAB_KEYS.VERSION_HISTORY,
          label: formatMessage(tabMessages.versionHistory),
        },
      ]
    : [
        {
          key: LIBRARY_TAB_KEYS.GENERAL_INFORMATION,
          label: formatMessage(tabMessages.generalInformation),
        },
      ];

  return {
    activeTab,
    setActiveTab,
    tabItems,
    tabContent,
  };
};

export default useTabNavigation;
