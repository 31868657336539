import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Modal } from "antd";
import { Button, Icon } from "@trace-one/design-system";
import Tables from "../Tables";
import styles from "./styles.module.less";

const TablesModal = ({
  entries,
  formContext,
  groupId,
  groups,
  idName,
  isEmptyTableHidden,
  schema,
  title,
  update,
  uiSchema,
  withSubtitle,
  canAddEmptyLine,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentEntries, setCurrentEntries] = useState(entries);
  const { type } = formContext;
  useEffect(() => {
    setCurrentEntries(JSON.parse(JSON.stringify(entries)));
  }, [entries]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleClose = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <span
        className={classNames(styles.openButton, {
          [styles.noBackground]: type === "library-object",
        })}
      >
        <Button
          type="tertiary"
          iconName="zoom-in"
          color="grey"
          onClick={showModal}
          className={classNames(styles.openButton, {
            [styles.noBackground]: type === "library-object",
          })}
        />
      </span>
      <Modal
        width="98%"
        height="98%"
        className={styles.modal}
        title={title}
        visible={isModalVisible}
        onCancel={handleClose}
        footer={null}
        closeIcon={
          <Icon className={styles.closeButton} name="zoom-out" size="medium" />
        }
      >
        <div className={styles.scrollableContainer}>
          <Tables
            entries={currentEntries}
            formContext={formContext}
            groupId={groupId}
            groups={groups}
            idName={idName}
            schema={schema}
            uiSchema={uiSchema}
            update={update}
            withSubtitle={withSubtitle}
            isEmptyTableHidden={isEmptyTableHidden}
            canAddEmptyLine={canAddEmptyLine}
          />
        </div>
      </Modal>
    </>
  );
};

TablesModal.defaultProps = {
  groupId: null,
  idName: null,
  isEmptyTableHidden: false,
  title: null,
  withSubtitle: false,
  canAddEmptyLine: true,
};

TablesModal.propTypes = {
  entries: PropTypes.array.isRequired,
  formContext: PropTypes.object.isRequired,
  groups: PropTypes.array.isRequired,
  schema: PropTypes.object.isRequired,
  uiSchema: PropTypes.object.isRequired,
  update: PropTypes.func.isRequired,
  groupId: PropTypes.string,
  idName: PropTypes.string,
  isEmptyTableHidden: PropTypes.bool,
  title: PropTypes.string,
  withSubtitle: PropTypes.bool,
  canAddEmptyLine: PropTypes.bool,
};

export default TablesModal;
