import { useEffect, useState } from "react";
import { searchUsers } from "apis/CUMD";
import { UserData } from "models";

const useUsers = ({
  ownerCompanyId,
  supplierId,
}: {
  ownerCompanyId?: string;
  supplierId?: string;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [users, setUsers] = useState<UserData[]>([]);

  const getUsers = async () => {
    try {
      setIsLoading(true);

      const results = await Promise.allSettled([
        searchUsers({
          params: {
            isEnabled: true,
            owningCompanyId: ownerCompanyId,
            skip: 0,
            take: 1000,
          },
        }),
        searchUsers({
          params: {
            isEnabled: true,
            owningCompanyId: supplierId,
            skip: 0,
            take: 1000,
          },
        }),
      ]);

      setUsers(
        results.reduce((previousState, result) => {
          if (result.status === "fulfilled") {
            return [...previousState, ...result.value.data.users];
          } else {
            setHasError(true);
          }

          return previousState;
        }, [])
      );
    } catch (e) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  return {
    isLoading,
    hasError,
    users,
  };
};

export default useUsers;
