export const getColorForTag = (state: string) => {
  switch (state) {
    case "inProgress":
      return "orange";
    case "inRevisionByRetailer":
    case "inRevisionBySupplier":
    case "locked":
      return "purple";
    case "validatedBySupplier":
    case "signedBySupplier":
    case "toStart":
      return "blue";
    case "validatedByRetailer":
    case "signedByRetailer":
    case "completed":
    case "published":
    case "performed":
      return "green";
    case "draft":
      return "grey";
    case "abandon":
    case "declined":
      return "red";
    default:
      return "default";
  }
};

export const getLabelForTag = ({ messageKey, messages, formatMessage }) =>
  messages[messageKey] ? formatMessage(messages[messageKey]) : messageKey;

export const getModeForTag = (color: string) => {
  if (["default", "disabled"].some(tagColor => tagColor === color)) {
    return "full";
  } else {
    return "light";
  }
};
