import { useEffect, useState } from "react";
import { isListEmpty } from "utils/general";
import { ChapterStructureData } from "models";
import { ChapterStructureFormData } from "types/library";

const useSelectedChapter = ({
  specificationStructure,
  chapterType,
}: {
  specificationStructure: ChapterStructureData[];
  chapterType?: string;
}) => {
  const [
    selectedChapter,
    setSelectedChapter,
  ] = useState<ChapterStructureFormData>();

  useEffect(() => {
    if (!isListEmpty(specificationStructure)) {
      let specificationChapter: ChapterStructureFormData = {};

      if (!chapterType) {
        specificationChapter = specificationStructure[0];
      } else {
        specificationChapter = specificationStructure.find(
          chapter => chapter.chapterType === chapterType
        );
      }

      setSelectedChapter(specificationChapter);
    }
  }, [chapterType, JSON.stringify(specificationStructure)]);

  return {
    selectedChapter,
  };
};

export default useSelectedChapter;
