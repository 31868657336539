import React from "react";
import PropTypes from "prop-types";
import Images from "./components/Images";

const Image = props => {
  const Component = Images[props.name];

  return Component ? (
    <Component {...props} data-testid="image-component" />
  ) : null;
};

Image.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Image;
