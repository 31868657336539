import Document from "./components/Document";
import Text from "./components/Text";
import { isLibraryRequirementTypeText } from "utils/library";
import { RequirementContentProps } from "./types";

const RequirementContent: React.FC<RequirementContentProps> = ({
  requirementInformation,
  requirementType,
  isLocked,
  displayActions = true,
}) => {
  return (
    <>
      {isLibraryRequirementTypeText(requirementType) ? (
        <Text
          isLocked={isLocked}
          content={requirementInformation.content}
          displayActions={displayActions}
        />
      ) : (
        <Document
          additionalInformation={requirementInformation.additionalInformation}
          fileId={requirementInformation.fileId}
          isLocked={isLocked}
          displayActions={displayActions}
        />
      )}
    </>
  );
};

export default RequirementContent;
