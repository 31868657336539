import { EmptyState } from "@trace-one/design-system";
import { UploadFiles } from "@trace-one/business-components";
import { useIntl } from "react-intl";
import { Row } from "antd";
import { isListEmpty } from "utils/general";
import { useFilesInfo } from "hooks";
import ActionsFiles from "./components/ActionsFiles";
import { messages } from "./messages";
import { SharedDocumentsWidgetProps } from "./types";
import styles from "../styles.module.less";

const SharedDocumentsWidget: React.FC<SharedDocumentsWidgetProps> = ({
  formContext,
  formData,
  schema,
}) => {
  const { files } = useFilesInfo(formData.fileIds);

  const { formatMessage } = useIntl();
  const { displayActions, isWidgetEditable } = formContext;
  const {
    properties: {
      fileIds: { propertyId },
    },
  } = schema;

  if (!displayActions || !isWidgetEditable(propertyId)) {
    if (isListEmpty(files)) {
      return (
        <Row justify="center">
          <EmptyState heading={formatMessage(messages.noDocumentShared)} />
        </Row>
      );
    }

    return (
      <div className={styles.filesList}>
        <UploadFiles.List fileList={files} />
      </div>
    );
  }

  return <ActionsFiles files={files} formContext={formContext} />;
};

export default SharedDocumentsWidget;
