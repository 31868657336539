import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Divider, Form, Row } from "antd";
import { Card, Company } from "@trace-one/react-components";
import { Heading } from "@trace-one/design-system";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { selectUnitsOfMeasure } from "store/generalData/selectors";
import {
  selectErrors,
  selectExistingSpecifications,
  selectSelectedSupplier,
} from "store/specificationCreation/selectors";
import {
  fetchExistingSpecifications,
  setSpecificationSupplier,
} from "store/specificationCreation/asyncActions";
import { getOnToggle } from "utils/card";
import { createNameForProduct } from "utils/form";
import withReviseSpecificationAction from "hocs/withReviseSpecificationAction";
import CompanySelect from "components/CompanySelect";
import CreationWizzard from "components/CreationWizzard";
import Info from "../Info";
import ProductSpecifications from "../ProductSpecifications";
import { loadExistingSpecificationsForProduct } from "../utils";
import { getErrorsProps, getHandleSelectSupplier } from "./utils";
import { useCategory } from "./hooks";
import { messages } from "../messages";
import generalMessages from "messages/general";
import styles from "../styles.module.less";

export const Specification = ({ displayActions, onRemoveClick, product }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const unitsOfMeasure = useSelector(selectUnitsOfMeasure);
  const selectedSupplier = useSelector(selectSelectedSupplier);
  const specifications = useSelector(selectExistingSpecifications);
  const {
    addSupplier: hasAddSupplierError,
    removeSupplier: hasRemoveSupplierError,
    removeProduct: hasRemoveProductError,
  } = useSelector(selectErrors);
  const [form] = Form.useForm();
  const [isCardOpen, setIsCardOpen] = useState(true);
  const [isSpecificationsLoading, setIsSpecificationsLoading] = useState(false);

  const { categoryName } = useCategory({
    categoryId: product.category?.categoryItemId,
  });

  const productName = useMemo(
    () => createNameForProduct(product, unitsOfMeasure),
    [product, unitsOfMeasure]
  );

  const productWithCategoryName = useMemo(() => {
    const enhancedProduct = { ...product };
    enhancedProduct.categoryName = categoryName;
    return enhancedProduct;
  }, [categoryName]);

  useEffect(() => {
    loadExistingSpecificationsForProduct({
      setIsSpecificationsLoading,
      dispatch,
      productId: product.id,
      fetchExistingSpecificationsForProduct: fetchExistingSpecifications,
    });
  }, [dispatch, product.id]);

  const errorProps = useMemo(
    () =>
      getErrorsProps({
        hasRemoveProductError,
        hasRemoveSupplierError,
        errorRemoveProductMessage: intl.formatMessage(
          messages.errorRemoveProduct
        ),
        errorRemoveSupplierMessage: intl.formatMessage(
          messages.errorRemoveSupplier
        ),
      }),
    [intl, hasRemoveProductError, hasRemoveSupplierError]
  );

  return (
    <Card
      collapsible
      className={styles.card}
      title={<Heading size="xs">{productName}</Heading>}
      collapseProps={{
        in: isCardOpen,
        onToggle: getOnToggle({
          setIsCardOpen,
        }),
      }}
      {...(displayActions && {
        extra: (
          <>
            <span>{intl.formatMessage(generalMessages.remove)}</span>
            <span className={styles.icon}>-</span>
          </>
        ),
        extraProps: {
          actionType: "button",
          danger: true,
          onClick: onRemoveClick,
        },
      })}
      {...errorProps}
    >
      <>
        <Form form={form}>
          <Info product={productWithCategoryName} />
          <Divider className={styles.divider} />
          {displayActions ? (
            <>
              <CreationWizzard.Item
                required
                label={intl.formatMessage(messages.selectSupplier)}
              >
                <CompanySelect
                  onSelect={getHandleSelectSupplier({
                    dispatch,
                    setSpecificationSupplier,
                  })}
                  type="tradeItemSuppliers"
                  labelInValue
                  tradeItemId={product.id}
                  values={selectedSupplier}
                  error={hasAddSupplierError}
                  errorMessage={intl.formatMessage(messages.errorFieldMessage)}
                  productSpecifications={specifications}
                />
              </CreationWizzard.Item>
              <ProductSpecifications
                displayActions
                displayActionList
                displayCheckbox={false}
                productId={product.id}
                isLoading={isSpecificationsLoading}
                specifications={specifications}
              />
            </>
          ) : (
            selectedSupplier?.label && (
              <Row span={12} data-testid="selected-suppliers-list">
                <Company.Tag name={selectedSupplier.label} />
              </Row>
            )
          )}
        </Form>
      </>
    </Card>
  );
};

Specification.defaultProps = {
  displayActions: true,
  onRemoveClick: () => {},
};

Specification.propTypes = {
  product: PropTypes.object.isRequired,
  displayActions: PropTypes.bool,
  onRemoveClick: PropTypes.func,
};

export default withReviseSpecificationAction(Specification);
