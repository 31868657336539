import { State } from "./types";

const initialState: State = {
  specification: {},
  chapters: [],
  template: {
    generalInfo: {},
    chapters: [],
  },
  hasError: false,
  isSaving: false,
  isCreationInProgress: false,
  isSpecificationCheckInProgress: false,
  isSpecificationFinalized: false,
  ownerContacts: [],
  ownerUserDetails: {},
  selectedProduct: {},
  selectedSupplier: null,
  existingSpecifications: [],
  isInvitationStepValid: false,
  //already created specification id for same supplier and tradeItem
  existingSpecificationId: null,
  wizard: { wizardId: null, collaborations: [], currentStep: null, state: [] },
  errors: {
    addProduct: false,
    addSupplier: false,
    sections: {},
    note: false,
    ownerContacts: false,
    productType: false,
    removeProduct: false,
    removeSupplier: false,
    addCollaboration: false,
    removeCollaborations: false,
    wizard: false,
    files: false,
  },
};

export default initialState;
