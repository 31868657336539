import { WizardData, WizardStateData } from "models";
import { getDictionary } from "./general";

export const isCurrentStepValidated = (
  currentStep: WizardData["currentStep"],
  wizardState: WizardData["state"]
) => wizardState.find(({ step }) => step === currentStep)?.validated;

export const getProperStepUrl = (
  currentStep: string,
  steps,
  stepsState: WizardStateData[]
) => {
  let url = null;

  const completedSteps = getDictionary({
    data: stepsState,
    key: "step",
    value: "validated",
  });

  if (completedSteps[currentStep]) {
    return url;
  }

  const stepIndex = steps.findIndex(({ step }) => step === currentStep);

  if (stepIndex === 0) {
    return url;
  }

  if (completedSteps[steps[stepIndex - 1].step]) {
    return url;
  }

  for (let index = stepIndex - 1; index >= 0; index--) {
    if (completedSteps[steps[index].step] || index === 0) {
      url = steps[index].route;
      break;
    }
  }

  return url;
};
