import { defineMessages } from "react-intl";

export const messages = defineMessages({
  detailsSectionTitle: {
    id: "specificationCreation.specificationDetails",
    defaultMessage: "Specification details",
  },

  detailsSectionSubtitle: {
    id: "specificationCreation.summary.detailsSubtitle",
    defaultMessage: "Basic information about your specification",
  },

  assignedProductSectionTitle: {
    id: "specificationCreation.summary.assignedProductsTitle",
    defaultMessage: "Assigned product",
  },

  assignedProductSectionSubtitle: {
    id: "specificationCreation.summary.assignedProductsSubtitle",
    defaultMessage: "Summary of your product selection",
  },

  documentSectionTitle: {
    id: "documents",
    defaultMessage: "Documents",
  },

  documentSectionSubtitle: {
    id: "creation.document.subtitle",
    defaultMessage: "Share and ask for documents",
  },

  notesSectionTitle: {
    id: "specificationCreation.specification.note",
    defaultMessage: "Note",
  },

  notesSectionSubtitle: {
    id: "specificationCreation.specification.noteDescription",
    defaultMessage: "Additional information",
  },
});
