import { useState, useRef, useEffect } from "react";
import cleanDeep from "clean-deep";
import { usePackaging } from "components/Packaging/hooks";
import {
  ArraySchema,
  FormContext,
} from "components/SpecificationSection/types";
import { useCategoryListItems } from "hooks";
import { PackagingSystemComponentMaterialData, SectionBlockData } from "models";
import { isListEmpty, isObjectEmpty } from "utils/general";
import { UiSchemaInfo } from "types/general";

const usePackagingSystemComponentMaterial = ({
  formData,
  schema,
  uiSchema,
  templateBlockId,
}: {
  materials?: PackagingSystemComponentMaterialData[];
  formData?: FormContext["formData"];
  schema?: ArraySchema;
  uiSchema?: UiSchemaInfo;
  templateBlockId?: SectionBlockData["templateBlockId"];
}) => {
  const { onSaveTemplateBlock } = usePackaging();

  //@ts-ignore
  const { api, route } = schema?.items?.properties?.type?.list ?? {};
  const { items: materialTypeCategoryData } = useCategoryListItems({
    api,
    route,
    isOnlyLeafSelectable: true,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [modalTitle, setModalTitle] = useState("");

  const [modalPrimaryButtonText, setModalPrimaryButtonText] = useState("");

  const { materials, calculations } = formData;

  const prevMaterialFormDataRef = useRef<PackagingSystemComponentMaterialData>(
    null
  );

  const existingDataRef = useRef<PackagingSystemComponentMaterialData[]>([]);

  useEffect(() => {
    existingDataRef.current = !isListEmpty(
      materials as PackagingSystemComponentMaterialData[]
    )
      ? (materials as PackagingSystemComponentMaterialData[])
      : [];
  }, [materials]);

  const defaultInitialValues: PackagingSystemComponentMaterialData = {
    type: undefined,
    percentageOfMaterial: undefined,
    thickness: undefined,
    percentageOfRecycledMaterials: undefined,
    includingPcr: undefined,
    percentageOfRenewableResources: undefined,
  };

  const [materialFormData, setMaterialFormData] = useState(
    defaultInitialValues
  );

  const [materialFormErrors, setMaterialFormErrors] = useState([]);

  const [materialTypeExpandedKeys, setMaterialTypeExpandedKeys] = useState([]);

  const { "ui:widget": widget, ...tableUiSchema } = uiSchema ?? {};

  const getIsPrimaryModalButtonDisabled = () => {
    return (
      materialFormErrors.some(item => !isListEmpty(item.errors)) ||
      !materialFormData["type"] ||
      (!isObjectEmpty(materialFormData) &&
        JSON.stringify(prevMaterialFormDataRef.current) ===
          JSON.stringify(
            cleanDeep({
              ...materialFormData,
            })
          ))
    );
  };

  const resetMaterialForm = () => {
    setIsModalOpen(false);
    setMaterialFormData(defaultInitialValues);
    setMaterialTypeExpandedKeys([]);
  };

  const onConfirm = () => {
    const materialData = cleanDeep({
      ...materialFormData,
    });

    let updatedData;
    if (
      !isObjectEmpty(prevMaterialFormDataRef.current) &&
      prevMaterialFormDataRef.current.type &&
      materialData.type !== prevMaterialFormDataRef.current.type
    ) {
      updatedData = existingDataRef.current.map(item => {
        if (item.type === prevMaterialFormDataRef.current.type) {
          return materialData;
        }
        return item;
      });
    } else if (
      existingDataRef.current.some(item => item.type === materialData.type)
    ) {
      updatedData = existingDataRef.current.map(item => {
        if (item.type === materialData.type) {
          return materialData;
        }
        return item;
      });
    } else {
      updatedData = [...existingDataRef.current, materialData];
    }

    onSaveTemplateBlock(templateBlockId)(
      JSON.stringify({ materials: [...updatedData], calculations })
    );
    resetMaterialForm();
  };

  const onDeleteMaterial = (
    materialTypeId: PackagingSystemComponentMaterialData["type"]
  ) => {
    const updatedData = existingDataRef.current.filter(
      material => (material.type as string) !== materialTypeId
    );

    onSaveTemplateBlock(templateBlockId)(
      JSON.stringify({ materials: [...updatedData], calculations })
    );
  };

  const onMaterialTypeTreeExpand = (keys: string[]) =>
    setMaterialTypeExpandedKeys(keys);

  return {
    isModalOpen,
    setIsModalOpen,
    modalTitle,
    setModalTitle,
    prevMaterialFormDataRef,
    existingDataRef,
    materialFormData,
    setMaterialFormData,
    materialFormErrors,
    setMaterialFormErrors,
    materialTypeCategoryData,
    materialTypeExpandedKeys,
    onMaterialTypeTreeExpand,
    modalPrimaryButtonText,
    setModalPrimaryButtonText,
    getIsPrimaryModalButtonDisabled,
    tableUiSchema,
    onConfirm,
    onDeleteMaterial,
    resetMaterialForm,
  };
};

export default usePackagingSystemComponentMaterial;
