import { SelectedStatus, StatusesProps } from "../../types";

const useStatuses = ({
  selectedStatuses,
  onStatusClick: defaultOnStatusClick,
  onStatusRemove: defaultonStatusRemove,
}: StatusesProps) => {
  const isStatusSelected = (status: string) =>
    selectedStatuses.some(({ value }) => value === status);

  const onStatusClick = (status: SelectedStatus) => () => {
    defaultOnStatusClick(status);
  };

  const onStatusRemove = (status: string) => () => {
    defaultonStatusRemove(status);
  };

  return {
    isStatusSelected,
    onStatusClick,
    onStatusRemove,
  };
};

export default useStatuses;
