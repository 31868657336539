import { Col, Row } from "antd";
import { EmptyState } from "@trace-one/design-system";
import RequirementContent from "./components/RequirementContent";
import { isListEmpty } from "utils/general";
import { messages } from "pages/Specification/components/Details/messages";
import { useIntl } from "react-intl";
import { SpecificationChapterRequirementData } from "models";

export interface RequirementsContentProps {
  requirements: SpecificationChapterRequirementData[];
}

const RequirementsContent: React.FC<RequirementsContentProps> = ({
  requirements,
}) => {
  const { formatMessage } = useIntl();
  return (
    <>
      <Col span={24}>
        <Row gutter={[0, 30]} justify="center">
          {isListEmpty(requirements) ? (
            <EmptyState heading={formatMessage(messages.noRequirement)} />
          ) : (
            requirements?.map(requirement => {
              return (
                <RequirementContent
                  key={requirement.requirementId}
                  requirement={requirement}
                />
              );
            })
          )}
        </Row>
      </Col>
    </>
  );
};

export default RequirementsContent;
