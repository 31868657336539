import { SVGProps } from "react";
import NutriScoreA from "./components/NutriScoreA";
import NutriScoreB from "./components/NutriScoreB";
import NutriScoreC from "./components/NutriScoreC";
import NutriScoreD from "./components/NutriScoreD";
import NutriScoreE from "./components/NutriScoreE";
import { NutriScoreGrade } from "types/nutrition";

interface NutriScoreIndicatorProps extends SVGProps<SVGSVGElement> {
  nutriScore: NutriScoreGrade;
}

const NutriScoreIndicator: React.FC<NutriScoreIndicatorProps> = ({
  nutriScore,
  height = 66.464,
  width = 124,
}) => {
  if (!nutriScore) {
    return null;
  }

  const indicatorProps = {
    height,
    width,
  };

  const nutriScoreIndicatorMap = {
    A: <NutriScoreA {...indicatorProps} />,
    B: <NutriScoreB {...indicatorProps} />,
    C: <NutriScoreC {...indicatorProps} />,
    D: <NutriScoreD {...indicatorProps} />,
    E: <NutriScoreE {...indicatorProps} />,
  };

  const getNutriScoreComponent = (grade: NutriScoreGrade) => {
    const normalizedGrade = grade.toUpperCase();
    return nutriScoreIndicatorMap[
      normalizedGrade as keyof typeof nutriScoreIndicatorMap
    ];
  };

  return <>{getNutriScoreComponent(nutriScore)}</>;
};

export default NutriScoreIndicator;
