import { Radio } from "@trace-one/design-system";
import AllergenActions from "../AllergenActions";
import RadioColumnTitle from "./components/RadioColumnTitle";
import libraryMessages from "messages/library";
import { isObjectEmpty } from "utils/general";
import { ALLERGEN_DECLARATION_TYPE } from "utils/constants";
import styles from "components/Library/components/MaterialForm/styles.module.less";

const ALLERGENS_COLUMNS = {
  ID: "allergenId",
  DECLARATION: "declaration",
  ACTION: "action",
};
export const getColumns = ({
  displayActions,
  formatMessage,
  allergenDictionary,
  onChangeAllergenPresent,
  onRemoveAllergen,
  disableActions,
}) =>
  [
    {
      title: formatMessage(libraryMessages.allergensColumns["allergen"]),
      dataIndex: ALLERGENS_COLUMNS.ID,
      render: value => {
        return allergenDictionary[value];
      },
      width: "35%",
    },
    {
      title: <RadioColumnTitle />,
      dataIndex: ALLERGENS_COLUMNS.DECLARATION,
      render: (value, allergen) => {
        return (
          <div>
            <Radio.Group
              value={value}
              disabled={disableActions}
              gutter={[170]}
              options={[
                {
                  value: ALLERGEN_DECLARATION_TYPE.PRESENT,
                },
                {
                  value: ALLERGEN_DECLARATION_TYPE.UNINTENTIONAL_PRESENT,
                },
              ]}
              data-test-id="raw-material-form-allergen-present"
              {...(displayActions
                ? {
                    onChange: onChangeAllergenPresent(allergen.allergenId),
                  }
                : {
                    disabled: true,
                  })}
            />
          </div>
        );
      },
    },
    {
      ...(displayActions && {
        key: "action",
        title: formatMessage(libraryMessages.allergensColumns["action"]),
        fixed: "right",
        width: 100,
        className: styles.actionColumnCenter,
        render: allergen => (
          <AllergenActions
            areActionsDisabled={disableActions}
            onRemoveAllergen={onRemoveAllergen(allergen)}
          />
        ),
      }),
    },
  ].filter(item => !isObjectEmpty(item));
