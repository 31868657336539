import { useSelector } from "react-redux";
import {
  selectGeneraInfo,
  selectProgressState,
  selectSections,
  selectStructure,
} from "store/chapterForm/selectors";
import GeneralInfo from "components/Library/components/ChapterForm/components/GeneralInfo";
import Configure from "components/Library/components/ChapterForm/components/Configure";
import { LIBRARY_OBJECTS_PAGES } from "utils/constants";
import { GeneralInformationProps } from "./types";

const GeneralInformation: React.FC<GeneralInformationProps> = ({
  onFieldsChange,
}) => {
  const generalInfo = useSelector(selectGeneraInfo);
  const progressState = useSelector(selectProgressState);
  const structure = useSelector(selectStructure);
  const sections = useSelector(selectSections);

  return (
    <>
      <GeneralInfo
        generalInfo={generalInfo}
        onFieldsChange={onFieldsChange}
        page={LIBRARY_OBJECTS_PAGES.EDIT}
      />
      <Configure
        structure={structure}
        sections={sections}
        progressState={progressState}
        generalInfo={generalInfo}
      />
    </>
  );
};

export default GeneralInformation;
