/* istanbul ignore file */

import { createSlice } from "@reduxjs/toolkit";
import initialState from "./initialState";
import reducers from "./reducers";
import {
  fetchSpecificationPermissionsFromId,
  reviseSpecification,
  getSpecificationSectionLastChanges,
  getSpecificationModificationRequests,
  createSpecificationModificationRequest,
  updateSpecificationModificationRequest,
  deleteSpecificationModificationRequest,
  notifySpecificationModificationRequest,
  getSpecificationModificationRequestById,
  getSpecificationModificationRequestDiscussionById,
  performSpecificationModificationRequest,
  declineSpecificationModificationRequest,
  completeSpecificationModificationRequest,
  archiveSpecificationModificationRequest,
} from "./asyncActions";
import { updateModificationRequests } from "./utils";
import {
  prepareModificationRequest,
  prepareModificationRequests,
} from "dtos/modificationRequest";

export const slice = createSlice({
  name: "specificationDetails",
  initialState,
  reducers,
  extraReducers: builder => {
    builder.addCase(
      fetchSpecificationPermissionsFromId.fulfilled,
      (state, action) => {
        state.specificationPermissions = action.payload;
      }
    );

    builder.addCase(reviseSpecification.pending, (state, _) => {
      state.isRevisionLoading = true;
      state.revisedSpecificationId = null;
    });
    builder.addCase(reviseSpecification.fulfilled, (state, action) => {
      state.isRevisionLoading = false;
      state.revisedSpecificationId = action.payload;
    });
    builder.addCase(reviseSpecification.rejected, (state, _) => {
      state.isRevisionLoading = false;
      state.revisedSpecificationId = null;
    });

    builder.addCase(
      getSpecificationSectionLastChanges.fulfilled,
      (state, action) => {
        state.selectedSectionLastChanges = action.payload;
      }
    );

    builder.addCase(
      getSpecificationModificationRequests.fulfilled,
      (state, action) => {
        state.modificationRequests = prepareModificationRequests(
          action.payload?.items
        );
      }
    );

    builder.addCase(
      createSpecificationModificationRequest.fulfilled,
      (state, action) => {
        state.modificationRequests = [
          ...(state?.modificationRequests || []),
          prepareModificationRequest(action.payload),
        ];
      }
    );

    builder.addCase(
      updateSpecificationModificationRequest.fulfilled,
      (state, action) => {
        const editRequestIndex = state.modificationRequests?.findIndex(
          request => request.requestId === action.payload.requestId
        );

        state.modificationRequests[
          editRequestIndex
        ] = prepareModificationRequest(action.payload);
      }
    );

    builder.addCase(
      deleteSpecificationModificationRequest.fulfilled,
      (state, action) => {
        state.modificationRequests = action.payload;
      }
    );

    builder.addCase(
      notifySpecificationModificationRequest.fulfilled,
      (state, action) => {
        updateModificationRequests({
          existingRequests: state?.modificationRequests,
          updatedRequest: prepareModificationRequest(action?.payload),
        });
      }
    );

    builder.addCase(
      performSpecificationModificationRequest.fulfilled,
      (state, action) => {
        updateModificationRequests({
          existingRequests: state?.modificationRequests,
          updatedRequest: prepareModificationRequest(action?.payload),
        });
      }
    );

    builder.addCase(
      declineSpecificationModificationRequest.fulfilled,
      (state, action) => {
        updateModificationRequests({
          existingRequests: state?.modificationRequests,
          updatedRequest: prepareModificationRequest(action?.payload),
        });
      }
    );

    builder.addCase(
      completeSpecificationModificationRequest.fulfilled,
      (state, action) => {
        updateModificationRequests({
          existingRequests: state?.modificationRequests,
          updatedRequest: prepareModificationRequest(action?.payload),
        });
      }
    );

    builder.addCase(
      archiveSpecificationModificationRequest.fulfilled,
      (state, action) => {
        state.modificationRequests = action.payload;
      }
    );

    builder.addCase(
      getSpecificationModificationRequestById.fulfilled,
      (state, action) => {
        state.selectedModificationRequest.detail = prepareModificationRequest(
          action.payload
        );
      }
    );

    builder.addCase(
      getSpecificationModificationRequestDiscussionById.fulfilled,
      (state, action) => {
        state.selectedModificationRequest.discussion = action.payload;
      }
    );
  },
});

export const {
  setSelectedSection,
  setSpecificationId,
  resetRevisedSpecification,
  setValidationDisabled,
  setIsSpecificationUpdating,
  setIsRequestPanelOpen,
  setRequestModification,
  setModificationRequests,
  setTranslationManagement,
  setTranslationManagementCompanies,
} = slice.actions;

export default slice.reducer;
