import { useEffect, useState } from "react";
import { RadioChangeEvent } from "antd";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { ArrayFieldTemplateProps } from "components/SpecificationSection/types";
import ConditionalRadio from "../ConditionalRadio";
import { isListEmpty } from "utils/general";
import { getConditionalLabelKey } from "../../../../utils";

const ConditionalArray: React.FC<ArrayFieldTemplateProps> = ({
  items,
  onAddClick,
  formContext,
  title,
  uiSchema,
  schema,
  children,
}) => {
  const [isComponentDisplayed, setIsComponentDisplayed] = useState(false);

  const { saveTemplateBlock, formData, isWidgetEditable } = formContext;
  const { propertyId } = schema;

  const isActionDisabled = !isWidgetEditable(propertyId);

  const { getTranslationForKey } = useFormSchemaTranslation();

  const conditionalLabel = getTranslationForKey(
    uiSchema["ui:conditional:label"] ||
      uiSchema["ui:title"] ||
      getConditionalLabelKey(uiSchema["ui:widget"])
  );

  const isMultipleReflistWidget =
    uiSchema["ui:widget"] === "MultipleRefListWidget";

  const onChange = ({ target: { value } }: RadioChangeEvent) => {
    if (value) {
      setIsComponentDisplayed(true);

      if (!isMultipleReflistWidget) {
        onAddClick();
      }
    }
  };

  const handleOnConfirm = () => {
    saveTemplateBlock(
      JSON.stringify({
        ...formData,
        [title]: [],
      })
    );

    setIsComponentDisplayed(false);
  };

  useEffect(() => {
    if (uiSchema["ui:conditional"]) {
      setIsComponentDisplayed(!isListEmpty(items));
    }
  }, [uiSchema["ui:conditional"]]);

  useEffect(() => {
    if (uiSchema["ui:conditional"] && isListEmpty(items)) {
      setIsComponentDisplayed(false);
    }
  }, [items.length, isMultipleReflistWidget]);

  return (
    <>
      <ConditionalRadio
        conditionalLabel={conditionalLabel}
        items={items}
        onConfirm={handleOnConfirm}
        onChange={onChange}
        disabled={isActionDisabled}
        value={isComponentDisplayed}
      />
      {isComponentDisplayed && children}
    </>
  );
};

export default ConditionalArray;
