import { defineMessages } from "react-intl";

const messages = defineMessages({
  countryId: {
    label: {
      id: "library.rawMaterialForm.supplierDetails.country.label",
      defaultMessage: "Country",
    },

    placeholder: {
      id: "library.rawMaterialForm.supplierDetails.country.placeholder",
      defaultMessage: "Select the country of origin",
    },
  },

  regionId: {
    label: {
      id: "library.rawMaterialForm.supplierDetails.region.label",
      defaultMessage: "Region",
    },

    placeholder: {
      id: "library.rawMaterialForm.supplierDetails.region.placeholder",
      defaultMessage: "Select the region",
    },
  },

  originTypeId: {
    label: {
      id: "library.rawMaterialForm.supplierDetails.originType.label",
      defaultMessage: "Origin type",
    },

    placeholder: {
      id: "library.rawMaterialForm.supplierDetails.originType.placeholder",
      defaultMessage: "Select the origin type",
    },
  },

  zoneId: {
    label: {
      id: "library.rawMaterialForm.supplierDetails.zone.label",
      defaultMessage: "Zone",
    },

    placeholder: {
      id: "library.rawMaterialForm.supplierDetails.zone.placeholder",
      defaultMessage: "Select the zone",
    },
  },
});

export default messages;
