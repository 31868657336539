import { useEffect, useState } from "react";
import { isListEmpty, isObjectEmpty } from "utils/general";
import { MultipleRefListWidgetProps } from "../../types";

const useMultipleRefListWidget = ({
  formContext,
  formData,
  title,
  uiSchema,
  schema,
  rawErrors,
}: MultipleRefListWidgetProps) => {
  const { saveTemplateBlock, formData: bigFormData } = formContext;
  const [actualForm, setActualForm] = useState(formData);

  const handleChange = (id: string) => {
    let dataToSend = [];

    if (isObjectEmpty(actualForm)) {
      dataToSend = [id];
    } else {
      dataToSend = [...actualForm, id];
    }

    sendClearData(dataToSend);
  };

  const handleDeselect = (id: string) => {
    sendClearData(actualForm.filter(item => item !== id));
  };

  const sendClearData = data => {
    setActualForm(data);

    saveTemplateBlock(
      JSON.stringify({ ...bigFormData, [title]: data }),
      JSON.stringify({ ...bigFormData })
    );
  };

  useEffect(() => {
    setActualForm(isListEmpty(formData) ? undefined : formData);
  }, [JSON.stringify(formData)]);

  return {
    reflistProps: {
      uiSchema: {
        "ui:multipleMode": true,
        ...uiSchema,
      },
      value: actualForm,
      placeholder: uiSchema["ui:placeholder"],
      onChange: handleChange,
      onDeselect: handleDeselect,
      schema: { ...schema.items, propertyId: schema.propertyId },
      formContext,
      rawErrors,
    },
  };
};

export default useMultipleRefListWidget;
