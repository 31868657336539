import { useIntl } from "react-intl";
import { Label } from "@trace-one/react-components";
import Debounced from "components/Debounced";
import messages from "./messages";
import { SupplierCodeProps } from "./types";
import styles from "../../styles.module.less";

const SupplierCode: React.FC<SupplierCodeProps> = ({
  displayActions,
  disableActions,
  supplierCode,
  onChange,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Label
      title={formatMessage(
        messages.rawMaterialSupplierDetailsInternalCodeLabel
      )}
    >
      {displayActions ? (
        <Debounced.Input
          data-test-id="raw-material-form-supplier-details-input-internal-code"
          defaultValue={supplierCode}
          disabled={disableActions}
          placeholder={formatMessage(
            messages.rawMaterialSupplierDetailsInternalCodePlaceholder
          )}
          //@ts-ignore
          onChange={onChange}
        />
      ) : (
        <span className={styles.supplierValue}>{supplierCode}</span>
      )}
    </Label>
  );
};

export default SupplierCode;
