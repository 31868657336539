import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { getDecimalPercentValue } from "utils/general";
import { PercentageInputProps } from "../../types";
import { inputErrorMessages } from "messages/errors";

const usePercentageInput = ({
  defaultValue,
  onChange: defaultOnChange,
  onErrorChange,
  keepDefaultValue,
  error,
  errorMessage,
  max,
  precision,
  required = true,
}: PercentageInputProps) => {
  const { formatMessage } = useIntl();
  const [hasError, setHasError] = useState(false);
  const [percentageDefaultValue, setPercentageDefaultValue] = useState(
    defaultValue
  );
  const [inputErrorMessage, setInputErrorMessage] = useState(
    formatMessage(inputErrorMessages.negativePercentage)
  );

  useEffect(() => {
    if (typeof error === "boolean") {
      setHasError(error);
    }
  }, [error]);

  useEffect(() => {
    if (errorMessage) {
      setInputErrorMessage(errorMessage);
    } else {
      //clean up: set to the default value if the errorMessage was received before
      setInputErrorMessage(
        formatMessage(inputErrorMessages.negativePercentage)
      );
    }
  }, [errorMessage]);

  useEffect(() => {
    setPercentageDefaultValue(prepareDefaultValue);
  }, [defaultValue, keepDefaultValue]);

  const onChange = (value: number) => {
    if (
      ((required && value > 0) || (!required && value >= 0)) &&
      value <= (max as number)
    ) {
      if (typeof defaultOnChange === "function") {
        let updatedValue = value;

        if (value && Number.isInteger(precision)) {
          updatedValue = parseFloat(value.toFixed(precision));
        }

        defaultOnChange(updatedValue);
      }
      setHasError(false);
    } else {
      setHasError(true);

      if (typeof onErrorChange === "function") {
        onErrorChange();
      }

      setInputErrorMessage(
        value <= 0
          ? formatMessage(inputErrorMessages.negativePercentage)
          : formatMessage(inputErrorMessages.maximumPercentage, {
              max,
            })
      );
    }
  };

  const prepareDefaultValue = () => {
    if (!!defaultValue) {
      if (keepDefaultValue) return defaultValue;

      return getDecimalPercentValue(defaultValue as number);
    }
    return null;
  };

  return {
    onChange,
    hasError,
    inputErrorMessage,
    defaultValue: percentageDefaultValue,
  };
};

export default usePercentageInput;
