/* istanbul ignore file */

import axios from "axios";
import { TagData } from "models";

export const axiosInstance = axios.create({
  baseURL: window.env.TAGS_URL,
});

export const fetchTags = ({ ids = [] }: { ids: TagData["tagId"][] }) =>
  axiosInstance.post<TagData[]>(`/api/tags/collection`, {
    tagIds: ids,
  });

export const createTag = ({
  applicationTypeId,
  tagText,
  ignoreIfDoesExist,
}: {
  applicationTypeId: number;
  tagText: string;
  ignoreIfDoesExist: boolean;
}) =>
  axiosInstance.post<TagData>(`/api/tags`, {
    applicationTypeId,
    tagText,
    ignoreIfDoesExist,
  });
