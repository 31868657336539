import { defineMessages } from "react-intl";

export const messages = defineMessages({
  productTypePlaceholder: {
    id: "filters.productType.placeholder",
    defaultMessage: "Product type",
  },

  noProductType: {
    id: "filters.productType.empty",
    defaultMessage: "No product type found",
  },

  productTypeError: {
    id: "filters.productType.error",
    defaultMessage: "Error, can't load product type data",
  },
});
