import { useIntl } from "react-intl";
import { useChapterFormActions } from "../../hooks";
import DeleteAndKeepDraftModal from "components/Library/components/DeleteAndKeepDraftModal";
import { messages } from "components/Library/components/ChapterForm/messages";
import libraryMessages from "messages/library";
import { CancelDeleteChapterModalProps } from "./types";
import { ChapterFormContext } from "../../contexts";
import { useContext } from "react";
import { isObjectEmpty } from "utils/general";

const CancelDeleteChapterModal: React.FC<CancelDeleteChapterModalProps> = ({
  isNewVersion,
  previousVersionChapterId,
}) => {
  const { formatMessage } = useIntl();

  const { form, id } = useContext(ChapterFormContext);

  let chapterType = null;
  if (!isObjectEmpty(form)) {
    if (typeof form.getFieldValue === "function") {
      chapterType = form?.getFieldValue("type");
    }
  }
  const { keepAsDraftAction, onDeleteChapter } = useChapterFormActions({
    chapterId: id,
    chapterType,
    validateFields: form.validateFields,
    isNewVersion,
    previousVersionChapterId,
  });

  return (
    <DeleteAndKeepDraftModal
      id={id}
      title={formatMessage(
        isNewVersion
          ? messages.cancelChapterVersionTitle
          : messages.cancelDeleteChapterTitle
      )}
      description={formatMessage(
        isNewVersion
          ? messages.cancelChapterVersionDescription
          : messages.cancelDeleteChapterDescription
      )}
      primaryButtonText={formatMessage(
        isNewVersion
          ? libraryMessages.deleteVersion
          : messages.cancelDeleteChapterDeleteBtn
      )}
      onKeepAsDraftAction={keepAsDraftAction}
      onDeleteAction={onDeleteChapter}
    />
  );
};

export default CancelDeleteChapterModal;
