import { ServingItem } from "components/SpecificationSection/components/FormSchema/components/NutritionBasic/types";
import { isListEmpty } from "utils/general";

export const getServingValue = ({
  servings,
  key,
  servingId,
}: {
  servings: ServingItem[];
  key: string;
  servingId: string;
}) => {
  if (!isListEmpty(servings)) {
    const serving = servings.find(serving => serving.servingId === servingId);
    return serving?.[key];
  }
  return null;
};
