import { Row, Col } from "antd";
import { useIntl } from "react-intl";
import cleanDeep from "clean-deep";
import RowTitle from "components/RowTitle";
import { Heading } from "@trace-one/design-system";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { isListEmpty, isObjectEmpty } from "utils/general";
import CodeAndDateItem from "./components/CodeAndDateItem";
import { getPropertiesObjectFromArray } from "../../templates/utils";
import { messages } from "./messages";
import { LabellingCodesAndDatesWidgetProps } from "./types";
import styles from "./styles.module.less";

const LabellingCodesAndDatesWidget: React.FC<LabellingCodesAndDatesWidgetProps> = ({
  properties,
  formData,
  uiSchema,
  formContext,
}) => {
  const { formatMessage } = useIntl();

  const propertyObjects = getPropertiesObjectFromArray(properties);

  const { refListDictionary } = formContext;
  const { getTranslationForKey } = useFormSchemaTranslation();

  const guaranteedShelfLifeId =
    formData.shelfLife?.guaranteedShelfLife?.guaranteedShelfLifeId;
  const guaranteedShelfLifeDurationValue =
    formData.shelfLife?.guaranteedShelfLife?.duration?.value;
  const guaranteedShelfLifeDurationUnit =
    formData.shelfLife?.guaranteedShelfLife?.duration?.unit;
  const cleanedShelfLifeFormData = cleanDeep(formData.shelfLife);

  return (
    <>
      <RowTitle title={getTranslationForKey(uiSchema["ui:title"])} />
      {propertyObjects["displayOnPack"].content}
      {/* Grouping Shelf life */}
      {!isListEmpty(Object.keys(cleanedShelfLifeFormData)) && (
        <>
          <Row gutter={[0, 20]} className={styles.itemRow}>
            <Col>
              <Heading size="xs">
                {getTranslationForKey("guaranteedShelfLifeTitle")}
              </Heading>
            </Col>
          </Row>
          {Object.keys(cleanedShelfLifeFormData).map(fieldName => {
            let item;
            if (fieldName === "guaranteedShelfLife") {
              item = {
                value: `${refListDictionary[guaranteedShelfLifeId]}, ${
                  guaranteedShelfLifeDurationValue ?? ""
                }`,
                unit: guaranteedShelfLifeDurationUnit ?? "",
              };
            } else {
              item = formData.shelfLife[fieldName];
            }
            return (
              <CodeAndDateItem
                key={fieldName}
                item={item}
                fieldUnit={refListDictionary[item.unit]}
                fieldTitle={
                  messages[fieldName] ? formatMessage(messages[fieldName]) : ""
                }
              />
            );
          })}
        </>
      )}

      {/* Grouping Consumer shelf life indication on pack */}
      {!isObjectEmpty(formData.consumerShelfLife) && (
        <>
          <Row gutter={[0, 20]} className={styles.itemRow}>
            <Col>
              <Heading size="xs">
                {getTranslationForKey("consumerShelfLifeTitle")}
              </Heading>
            </Col>
          </Row>
          {!!formData.consumerShelfLife?.beforeOpeningIndicationId && (
            <CodeAndDateItem
              item={{
                unit: `${
                  formData.consumerShelfLife?.beforeOpeningIndicationId ?? ""
                }`,
              }}
              fieldUnit={
                refListDictionary[
                  formData.consumerShelfLife?.beforeOpeningIndicationId ?? ""
                ]
              }
              fieldTitle={getTranslationForKey("beforeOpeningIndicationTitle")}
            />
          )}

          {propertyObjects["bestBeforeDateFormat"].content}

          {!isObjectEmpty(
            formData.consumerShelfLife?.afterOpeningShelfLife
          ) && (
            <CodeAndDateItem
              item={{
                value: `${
                  formData.consumerShelfLife?.afterOpeningShelfLife.value ?? ""
                }`,
                unit:
                  formData.consumerShelfLife?.afterOpeningShelfLife.unitId ??
                  "",
              }}
              fieldUnit={
                refListDictionary[
                  formData.consumerShelfLife?.afterOpeningShelfLife.unitId ?? ""
                ]
              }
              fieldTitle={getTranslationForKey("afterOpeningShelfLifeTitle")}
            />
          )}

          {propertyObjects["batchLotNumber"].content}

          {typeof formData.consumerShelfLife?.shelfLifeTesting && (
            <CodeAndDateItem
              item={{
                value: getTranslationForKey(
                  formData.consumerShelfLife?.shelfLifeTesting ? "yes" : "no"
                ),
              }}
              fieldUnit={""}
              fieldTitle={getTranslationForKey("shelfLifeTesting")}
            />
          )}
        </>
      )}

      {/* Grouping Production date*/}
      {!isListEmpty(formData.productionDate?.items) && (
        <>
          {formData.productionDate?.items.map(item => (
            <CodeAndDateItem
              key={item.id}
              item={item}
              fieldUnit={refListDictionary[item.unit]}
              fieldTitle={refListDictionary[item.id]}
            />
          ))}
        </>
      )}

      {propertyObjects["productOrigins"].content}
    </>
  );
};

export default LabellingCodesAndDatesWidget;
