import { defineMessages } from "react-intl";

export const messages = defineMessages({
  cantAddSpecificationMessage: {
    id: "apis.errors.cantAddSpecification.message",
    defaultMessage: "Can't add Specification",
  },
  cantAddSpecificationDescription: {
    id: "apis.errors.cantAddSpecification.description",
    defaultMessage:
      "At least one selected Specification is already part of another folder",
  },
});
