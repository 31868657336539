import { useContext, useMemo } from "react";
import { LibraryDetails } from "pages/Library/components/Details/contexts";
import { Col, Row } from "antd";
import ClaimInformation from "components/Library/components/ClaimForm/components/ClaimInformation";
import ClaimImage from "components/Library/components/ClaimForm/components/ClaimImage";
import ClaimSentenceTranslation from "components/Library/components/ClaimForm/components/ClaimSentenceTranslation";

const ClaimDetails = () => {
  const { libraryItemDetails } = useContext(LibraryDetails);

  const claimSentences = useMemo(
    () =>
      libraryItemDetails?.sentenceTranslations?.filter(
        ({ isDefault }) => !isDefault
      ),
    [libraryItemDetails?.sentenceTranslations]
  );

  return (
    <div className="mt-3">
      <Row>
        <Col xs={18}>
          <ClaimInformation libraryItemDetails={libraryItemDetails} />
          <ClaimSentenceTranslation claimSentences={claimSentences} />
        </Col>
        <Col xs={6}>
          <ClaimImage fileId={libraryItemDetails?.logoFileId} />
        </Col>
      </Row>
    </div>
  );
};

export default ClaimDetails;
