import { defineMessages } from "react-intl";

const messages = defineMessages({
  rawMaterialSupplierDetailsInternalCodeLabel: {
    id: "library.rawMaterialForm.supplierDetails.internalCode.label",
    defaultMessage: "Internal code",
  },
  rawMaterialSupplierDetailsInternalCodePlaceholder: {
    id: "library.rawMaterialForm.supplierDetails.internalCode.placeholder",
    defaultMessage: "Enter the internal code",
  },
});

export default messages;
