import { useContext } from "react";
import { Button } from "@trace-one/design-system";
import ConfirmationTooltip from "components/ConfirmationTooltip";
import { FreeFromAllergensArrayFieldTemplateContext } from "components/SpecificationSection/components/FormSchema/templates/ArrayFieldTemplate/FreeFromAllergensArrayFieldTemplate/contexts";

import { ActionsProps } from "./types";

const Actions = ({ allergenId }: ActionsProps) => {
  const { onRemoveAllergen } = useContext(
    FreeFromAllergensArrayFieldTemplateContext
  );

  return (
    <ConfirmationTooltip onConfirmation={onRemoveAllergen({ allergenId })}>
      <Button
        type="tertiary"
        iconName="trash"
        data-test-id="free-from-allergen-item-delete-icon"
      />
    </ConfirmationTooltip>
  );
};

export default Actions;
