import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import useResume from "hooks/useResume";
import useRedirect from "hooks/useRedirect";
import useUserInfo from "hooks/useUserInfo";
import useRbac from "hooks/useRbac";
import { deleteSpecificationDraft, deleteSpecification } from "apis/SPEC";
import { useAppDispatch } from "store";
import { reviseSpecification } from "store/specificationDetails/asyncActions";
import { PERMISSIONS } from "utils/constants";
import {
  isFAndVSpecTemplate,
  isStateDraft,
  isStateInProgress,
  isStateSignedByRetailer,
} from "utils/specifications";
import { isListEmpty, isObjectEmpty } from "utils/general";
import { getDeleteMessageKey, getReviseMessageKey } from "./utils";
import { messages } from "messages/specification";
import { resetRevisedSpecification } from "store/specificationDetails/specificationDetailsSlice";
import { selectRevisedSpecification } from "store/specificationDetails/selectors";
import { selectUserId } from "store/user/selectors";
import useSpecificationPermissions from "hooks/useSpecificationPermissions";
import { MenuActions, Permissions } from "types/general";
import { SpecificationActionViewModel } from "./types";

const useSpecificationActions = (
  specification: SpecificationActionViewModel,
  permissionList: Permissions
): {
  [key: string]: MenuActions;
} => {
  const {
    specificationId,
    state,
    version,
    productTypeId,
    ownerContacts,
  } = specification;

  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const { redirectToSpecificationDetails } = useRedirect();
  const { resumeSpecification } = useResume();
  const { isRetailer } = useUserInfo();

  const { hasPermission } = useRbac(permissionList);
  const hasReadPermission = hasPermission(PERMISSIONS.SPECIFICATION.READ);
  const hasRevisePermission = hasPermission(PERMISSIONS.SPECIFICATION.REVISE);
  const hasUpdatePermission = hasPermission(PERMISSIONS.SPECIFICATION.UPDATE);
  const hasSharePermission = hasPermission(PERMISSIONS.SPECIFICATION.SHARE);
  const hasDeletePermission = hasPermission(PERMISSIONS.SPECIFICATION.DELETE);
  const hasDuplicatePermission = hasPermission(
    PERMISSIONS.SPECIFICATION.DUPLICATE
  );

  const { hasPermissionToUpdateSpecification } = useSpecificationPermissions({
    state,
    permissions: permissionList,
  });

  const revisedSpecification = useSelector(selectRevisedSpecification);
  const userId = useSelector(selectUserId);

  const isRetailerContact =
    (ownerContacts ?? []).findIndex(contact => contact.userId === userId) >= 0;

  const hasNoPermissionButIsRetailerContact =
    !hasRevisePermission && isRetailerContact;

  let showReviseActionButton =
    hasRevisePermission &&
    isStateSignedByRetailer(state) &&
    version?.activeVersion;

  if (hasNoPermissionButIsRetailerContact) {
    showReviseActionButton = true;
  }

  useEffect(() => {
    if (revisedSpecification) {
      redirectToSpecificationDetails(revisedSpecification);

      // @ts-ignore
      dispatch(resetRevisedSpecification());
    }
  }, [revisedSpecification]);

  if (
    isListEmpty(permissionList) ||
    isObjectEmpty(specification) ||
    !specification.specificationId
  ) {
    return {};
  }

  const onDeleteAction = async () => {
    try {
      if (isStateDraft(state)) {
        await deleteSpecificationDraft({ specificationId });
      } else {
        await deleteSpecification({ id: specificationId });
      }
    } catch (e) {}
  };

  const onReviseAction = async () => {
    // @ts-ignore
    await dispatch(reviseSpecification(specificationId));
  };

  const onResumeAction = async () => {
    await resumeSpecification(specificationId);
  };

  const onViewAction = () => {
    redirectToSpecificationDetails(specificationId);
  };

  return {
    ...(hasUpdatePermission &&
      isStateDraft(state) && {
        resume: {
          name: formatMessage(messages.resumeCreation),
          onClick: onResumeAction,
        },
      }),

    ...(hasPermissionToUpdateSpecification &&
      !isStateDraft(state) && {
        edit: {
          name: formatMessage(messages.edit),
          onClick: onViewAction,
        },
      }),

    ...(!hasUpdatePermission &&
      !isStateDraft(state) && {
        display: {
          name: formatMessage(messages.display),
          onClick: onViewAction,
        },
      }),

    ...(showReviseActionButton && {
      revise: {
        name: formatMessage(
          messages[`${getReviseMessageKey(isRetailer)}Revise`]
        ),
        onClick: onReviseAction,
        disabled: hasNoPermissionButIsRetailerContact,
      },
    }),

    ...(hasDeletePermission &&
      (isStateDraft(state) || isStateInProgress(state)) && {
        delete: {
          name: formatMessage(
            messages[`${getDeleteMessageKey(state, version?.number)}Delete`]
          ),
          onClick: onDeleteAction,
        },
      }),

    ...(hasSharePermission && {
      share: {
        name: formatMessage(messages.share),
      },
    }),

    ...(hasDuplicatePermission &&
      isFAndVSpecTemplate(productTypeId) && {
        duplicate: {
          name: formatMessage(messages.duplicate),
        },
      }),

    ...(hasReadPermission && {
      history: {
        name: formatMessage(messages.history),
      },
    }),
  };
};

export default useSpecificationActions;
