import React, { useContext } from "react";
import { ChaptersContext } from "components/Chapters";
import SortableItem from "./components/Section";
import { SectionsWrapperProps } from "./types";
import { DndContext, closestCenter } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

export const SectionsWrapper: React.FC<SectionsWrapperProps> = ({
  sections,
  isChapterVisible,
  onDragEnd,
  chapterType,
  areUserGuidesUpdating,
}) => {
  const { displayActions } = useContext(ChaptersContext);

  return (
    <DndContext onDragEnd={onDragEnd} collisionDetection={closestCenter}>
      <SortableContext
        items={sections.map(section => section.id)}
        strategy={verticalListSortingStrategy}
      >
        {sections.map(section => (
          <SortableItem
            isChapterVisible={isChapterVisible}
            section={section}
            key={`section-${section.id}`}
            displayActions={displayActions}
            chapterType={chapterType}
            areUserGuidesUpdating={areUserGuidesUpdating}
          />
        ))}
      </SortableContext>
    </DndContext>
  );
};

export default SectionsWrapper;
