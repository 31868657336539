import cx from "classnames";
import { FormattedMessage } from "react-intl";
import Spin from "components/Spin";
import { Heading } from "@trace-one/design-system";
import QuickviewLegend from "../QuickviewLegend";
import ProgressBar from "../ProgressBar";
import ProgressRow from "./components/ProgressRow";
import { getAllChaptersCount } from "./utils";
import { ProgressProps } from "./types";
import { messages } from "./messages";
import styles from "components/Quickview/styles.module.less";

const ProgressQuickview: React.FC<ProgressProps> = ({
  isLoading,
  hasError,
  list,
  onSectionClick,
}) => {
  if (isLoading) {
    return <Spin />;
  }
  if (hasError) {
    return (
      <Heading
        color="red"
        className={styles.error}
        data-testid="quickview-error"
      >
        <FormattedMessage {...messages[`progressListQuickviewError`]} />
      </Heading>
    );
  }

  const {
    totalCount,
    toFillSectionCount,
    inProgressSectionCount,
    validatedBySupplierSectionCount,
    validatedByRetailerSectionCount,
  } = getAllChaptersCount(list);

  return (
    <>
      <div className={styles.head}>
        <Heading className={styles.firstSpecificationColumn} size="xs">
          <FormattedMessage {...messages.progressListQuickviewTitle} />
        </Heading>

        <QuickviewLegend hasDownloadColumn={false} hasSignatureColumn={false} />
      </div>
      <div
        className={cx(styles.sectionRow, styles.allSectionsRow)}
        data-testid="all-chapters"
      >
        <div className={styles.firstSpecificationColumn}>
          <Heading className={styles.firstSpecificationColumn} size="xxs">
            <FormattedMessage {...messages.allChapters} />
          </Heading>
          <div className={styles.greyBar} />
        </div>
        <div className={styles.secondColumn}>
          <ProgressBar
            totalCount={totalCount}
            toFillSectionCount={toFillSectionCount}
            inProgressSectionCount={inProgressSectionCount}
            validatedBySupplierSectionCount={validatedBySupplierSectionCount}
            validatedByRetailerSectionCount={validatedByRetailerSectionCount}
          />
        </div>
      </div>
      {list.map(section => (
        <ProgressRow
          key={section.sectionId}
          section={section}
          onSectionClick={onSectionClick}
        />
      ))}
    </>
  );
};

export default ProgressQuickview;
