import { defineMessages } from "react-intl";

export const messages = defineMessages({
  configureMaterialTitle: {
    id: "library.materialForm.configureMaterial.title",
    defaultMessage: "Configure material",
  },

  selectMaterialType: {
    id: "library.materialForm.configureMaterial.emptyMessage",
    defaultMessage:
      "Select a material type to start filling sections informations",
  },
});
