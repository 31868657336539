import { useContext } from "react";
import { useIntl } from "react-intl";
import { Select } from "@trace-one/design-system";
import {
  columnMessages,
  filterMessages,
} from "components/SpecificationHistorisationModal/messages";
import { useUsers } from "./hooks";
import { UsernameProps } from "./types";
import { HistorisationContext } from "components/SpecificationHistorisationModal/contexts";

const Username: React.FC<UsernameProps> = ({
  selectedUsers,
  setSelectedUsers,
  ...rest
}) => {
  const { formatMessage } = useIntl();
  const { ownerCompanyId, supplierId } = useContext(HistorisationContext);
  const { isLoading, hasError, users } = useUsers({
    ownerCompanyId,
    supplierId,
  });

  return (
    <Select
      showArrow
      labelInValue
      mode="multiple"
      name="user"
      label={formatMessage(columnMessages.userName)}
      placeholder={formatMessage(columnMessages.userName)}
      value={selectedUsers}
      onChange={setSelectedUsers}
      options={users.map(user => ({
        value: user.userId,
        name: `${user.userFirstName} ${user.userLastName}`,
      }))}
      loading={isLoading}
      {...(hasError && {
        errorMessage: formatMessage(filterMessages.usernameError),
        error: true,
      })}
      {...rest}
    />
  );
};

export default Username;
