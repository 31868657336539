import { useState } from "react";

const useButtonWithModalTree = ({
  defaultCheckedItems,
}: {
  defaultCheckedItems: string[];
}) => {
  const [newlySelectedItems, setNewlySelectedItems] = useState<
    { id: string }[]
  >([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldCallApi, setShouldCallApi] = useState(false);

  const onCloseModal = () => {
    setIsModalVisible(false);
    setNewlySelectedItems([]);
  };

  const onOpenModal = ({
    onOpenModalCallback,
  }: {
    onOpenModalCallback: Function;
  }) => async () => {
    if (onOpenModalCallback) {
      try {
        setIsLoading(true);
        await onOpenModalCallback();
      } catch (_) {
      } finally {
        setIsLoading(false);
      }
    }
    setShouldCallApi(true);
    setIsModalVisible(true);
  };

  const onCheckHandler = (_, e) => {
    const newItems = e.checkedNodes.reduce((previousValue, { key }) => {
      if (!defaultCheckedItems.includes(key)) {
        previousValue = [
          ...previousValue,
          {
            id: key,
          },
        ];
      }

      return previousValue;
    }, []);

    setNewlySelectedItems(newItems);
  };

  const onConfirmModalHandler = ({
    onConfirmModal,
  }: {
    onConfirmModal: Function;
  }) => async () => {
    setIsLoading(true);
    await onConfirmModal(newlySelectedItems);
    setIsLoading(false);
    onCloseModal();
  };

  return {
    onCheckHandler,
    onConfirmModalHandler,
    onOpenModal,
    onCloseModal,
    newlySelectedItems,
    isModalVisible,
    isLoading,
    shouldCallApi,
  };
};

export default useButtonWithModalTree;
