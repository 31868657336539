import { useLocation } from "react-router-dom";
import { useRedirect } from "hooks";
import { SpecificationData } from "models";
import { ROUTES } from "utils/constants";

const useConfirmStepRedirectAction = ({
  specificationId,
  onModalClose,
  reloadListing,
}: {
  specificationId: SpecificationData["id"];
  onModalClose: Function;
  reloadListing: Function;
}) => {
  const { pathname } = useLocation();
  const {
    redirectToSpecificationDetails,
    redirectToSpecificationListing,
  } = useRedirect();

  const onListingClick = () => {
    if (pathname.includes(ROUTES.SPECIFICATION_LISTING)) {
      reloadListing();
      onModalClose();
    } else {
      redirectToSpecificationListing();
    }
  };

  const onViewDetailsClick = () => {
    const specificationDetailsRoute = ROUTES.SPECIFICATION_DETAILS.replace(
      ":specificationId",
      specificationId
    );

    if (pathname.includes(specificationDetailsRoute)) {
      onModalClose();
    } else {
      redirectToSpecificationDetails(specificationId);
    }
  };

  return {
    onListingClick,
    onViewDetailsClick,
  };
};

export default useConfirmStepRedirectAction;
