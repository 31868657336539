import { isValueFieldDisabled, preparePropsForContent } from "./utils";
import { ConditionalEditableWidgetProps } from "./types";
import { getPropertiesObjectFromArray } from "../../templates/utils";

const ConditionalEditableWidget = ({
  properties,
  formData,
  schema,
}: ConditionalEditableWidgetProps) => {
  const propertyObjects = getPropertiesObjectFromArray(properties);

  return (
    <>
      {preparePropsForContent({
        properties: propertyObjects.value.content,
        disabled: isValueFieldDisabled({ formData, schema }),
      })}
    </>
  );
};

export default ConditionalEditableWidget;
