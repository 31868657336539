import { FormattedMessage, useIntl } from "react-intl";
import { Form } from "antd";
import ConfirmationModal from "components/ConfirmationModal";
import SelectItem from "components/Library/components/SelectItem";
import { useChangeMaterialType } from "./hooks";
import generalMessages from "messages/general";
import libraryMessages from "messages/library";
import { messages } from "../../messages";

export interface MaterialTypeProps {
  options: { label: string; value: string }[];
  materialType: string;
  disabled: boolean;
  onChangeType: Function;
}

const MaterialType: React.FC<MaterialTypeProps> = ({
  options,
  materialType,
  disabled,
  onChangeType,
}) => {
  const { formatMessage } = useIntl();

  const {
    selectedMaterialType,
    isModalOpen,
    onChangeMaterialType,
    onConfirmChangeMaterialType,
    onCancelChangeMaterialType,
  } = useChangeMaterialType({ materialType, onChangeType });

  return (
    <>
      <Form.Item
        name="materialType"
        label={formatMessage(libraryMessages.typeLabel)}
        rules={[{ required: true }]}
        wrapperCol={{ md: 4 }}
      >
        <SelectItem
          options={options}
          data-test-id="material-form-type"
          disabled={disabled}
          selectedValue={selectedMaterialType}
          onChange={onChangeMaterialType}
          placeholder={formatMessage(
            libraryMessages.typeRawMaterialPlaceholder
          )}
        />
      </Form.Item>
      {isModalOpen && (
        <ConfirmationModal
          onCloseModal={onCancelChangeMaterialType}
          onConfirmModal={onConfirmChangeMaterialType}
          title={formatMessage(messages.materialTypeModificationTitle)}
          children={
            <>
              <FormattedMessage
                {...messages.materialTypeModificationDescription}
              />
              <br />
              <FormattedMessage
                {...messages.materialTypeModificationStartOverDescription}
              />
            </>
          }
          primaryButtonText={formatMessage(generalMessages.confirm)}
        />
      )}
    </>
  );
};

export default MaterialType;
