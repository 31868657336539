import { Tags as TOTags } from "@trace-one/react-components";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import {
  selectLanguageCode,
  selectUserApplications,
} from "store/user/selectors";

import { messages } from "../../messages";
import styles from "./styles.module.less";

const Tags = () => {
  const languageCode = useSelector(selectLanguageCode);
  const userApplications = useSelector(selectUserApplications);
  const intl = useIntl();

  return (
    <div className={styles.tagsAdminWrapper}>
      <TOTags.Administration
        languageCode={languageCode}
        userApplications={userApplications}
        app="spec"
        subtitle={intl.formatMessage(messages.administrationSubtitle)}
      />
    </div>
  );
};

export default Tags;
