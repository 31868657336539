import { useEffect, useState, useMemo } from "react";
import classnames from "classnames";
import { Checkbox } from "@trace-one/design-system";
import { useWidgetActions } from "../../hooks";
import { isWidgetDisabled } from "../utils";
import { getOnCheckboxChange } from "./utils";
import { canTargetField } from "utils/modificationRequest";
import { CheckboxWidgetProps } from "./types";
import widgetStyle from "../styles.module.less";

const CheckboxWidget: React.FC<CheckboxWidgetProps> = ({
  disabled,
  formContext,
  onChange,
  readonly,
  schema,
  value,
}) => {
  const {
    readonlyAsDisabled = true,
    isWidgetEditable,
    templateBlockId,
    sectionId,
    isTargetMode,
    ownerCompanyId,
    chapterData,
  } = formContext;

  const { propertyId } = schema;

  const { isWidgetChanged } = useWidgetActions({ templateBlockId, sectionId });

  const isDisabled = useMemo(
    () =>
      isWidgetDisabled({
        isWidgetEditable: isWidgetEditable(propertyId),
        disabled,
        readonlyAsDisabled,
        readonly,
      }),
    [disabled, readonlyAsDisabled, readonly, propertyId]
  );

  const [checkboxValue, setCheckboxValue] = useState(value);

  useEffect(() => {
    setCheckboxValue(value);
  }, [value]);

  return (
    <Checkbox
      className={classnames({
        [widgetStyle.inRevisionChange]: isWidgetChanged(propertyId),
        "target-mode": canTargetField({
          isTargetMode,
          specOwnerCompanyId: ownerCompanyId,
          chapterOwnerCompanyId: chapterData?.ownerCompanyId,
        }),
      })}
      checked={!!checkboxValue}
      disabled={isDisabled}
      onChange={getOnCheckboxChange({
        onChange,
        setCheckboxValue,
      })}
    />
  );
};

export default CheckboxWidget;
