/* istanbul ignore file */

import axios, { CancelToken } from "axios";
import { v4 } from "uuid";
import { ParamsWithPagination } from "@trace-one/api-clients.base";
import CompanyUserMasterDataApi, {
  CompanyParams,
} from "@trace-one/api-clients.cumd";
import { APPLICATION_TYPE_IDS } from "utils/constants";
import { UserData } from "models";

export const axiosInstance = axios.create({
  baseURL: window.env.CUMD_URL,
});

export const CumdAPI = new CompanyUserMasterDataApi(axiosInstance);

export const types = {
  CUMD_CHANGE_USER_LANGUAGE:
    "TraceOne.Api.CompanyUserMasterData.Default.Messages.Commands.ChangeUserLanguagePreferencesCommand, TraceOne.Api.CompanyUserMasterData.Default.Messages",
};

export const fetchUser = ({ id }: { id: string }) => CumdAPI.getUserById(id);

export const fetchUserCompany = ({ id }: { id: string }) =>
  CumdAPI.getCompanyByUserId(id);

export const fetchUsers = ({ ids }: { ids: string[] }) =>
  CumdAPI.getUsersByUserIds({ userIds: ids });

export const searchUsers = ({
  params,
  ignoreApplicationTypeIds,
}: {
  params: object;
  ignoreApplicationTypeIds?: boolean;
}) =>
  axiosInstance.post<{
    skipAndTakeQueryStats: {
      totalCount: number;
      currentCount: number;
    };
    users: UserData[];
  }>(
    `/api/users/filters`,
    {},
    {
      params: {
        ...(ignoreApplicationTypeIds
          ? {}
          : { applicationTypeId: APPLICATION_TYPE_IDS.SPEC }),
        ...params,
      },
    }
  );

export const fetchUserApplications = () => CumdAPI.getUserApplications();

export const updateUserLanguage = ({ id, selectedLang }) =>
  axiosInstance.post(`/command`, {
    $type: types.CUMD_CHANGE_USER_LANGUAGE,
    userId: id,
    newLanguagePreference: selectedLang,
    messageId: v4(),
  });

export const fetchCompanies = ({ ids = [] }: { ids: string[] }) =>
  CumdAPI.getCompaniesByCompanyIds({ ...(ids.length && { companyIds: ids }) });

export const fetchCompany = ({ id }: { id: string }) =>
  CumdAPI.getCompanyById(id);

export const searchCompanies = ({
  params = {},
  data = {},
  cancelToken,
}: {
  data?: {
    companyIds?: string[];
    applicationTypeIds?: number[];
  };
  params?: ParamsWithPagination<CompanyParams>;
  cancelToken: CancelToken;
}) => CumdAPI.getCompaniesByFilters(data, params, { cancelToken });
