import { createContext } from "react";
import { PackagingSystemComponentMaterialArrayFieldTemplateContextProps } from "./types";

const PackagingSystemComponentMaterialArrayFieldTemplateContext = createContext<PackagingSystemComponentMaterialArrayFieldTemplateContextProps>(
  {
    displayActions: true,
    materialTypes: [],
    materialFormData: { type: undefined },
    setMaterialFormData: null,
    materialFormErrors: [],
    setMaterialFormErrors: null,
    isMaterialFormModalOpen: false,
    setIsMaterialFormModalOpen: null,
    setMaterialFormModalTitle: null,
    setMaterialModalPrimaryButtonText: null,
    onDeleteMaterial: null,
    prevMaterialFormDataRef: null,
  }
);

export default PackagingSystemComponentMaterialArrayFieldTemplateContext;
