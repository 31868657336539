import { v4 } from "uuid";

export const prepareConfigForApi = etagMap => config => {
  const token = localStorage.getItem("access-token");

  config.headers.common["Authorization"] = `Bearer ${token}`;
  config.headers.common["messageId"] = v4();

  if (config.method === "put" && etagMap?.[config.url]) {
    config.headers = {
      ...config.headers,
      "If-Match": etagMap[config.url],
    };
  }

  return config;
};

export const prepareResponseConfigForApi = etagMap => response => {
  const { config, headers } = response;

  if (config?.method === "get" && headers?.["etag"]) {
    etagMap[config.url] = headers["etag"];
  }

  return response;
};
