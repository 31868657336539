import { createRef, RefObject, useEffect, useState } from "react";
import { BadgeList, Tag } from "@trace-one/design-system";
import { isListEmpty } from "utils/general";
import { useMoreInfo } from "hooks";
import { TagsProps } from "./types";
import styles from "./styles.module.less";

const Tags: React.FC<TagsProps> = ({ tags }) => {
  const wrapperRef = createRef<HTMLDivElement>();
  const elementsRef = createRef<HTMLDivElement>();
  const moreInfoRef = createRef<HTMLDivElement>();
  const [elementListRefs, setElementListRefs] = useState<
    RefObject<HTMLDivElement>[]
  >([]);

  const { currentElements, moreInfoElements } = useMoreInfo({
    elements: tags,
    moreInfoRef,
    elementListRefs,
    elementsRef,
    wrapperRef,
    elementExtraWidth: 8,
  });

  useEffect(() => {
    setElementListRefs(tags.map(() => createRef()));
  }, [tags.length]);

  return (
    <div className={styles.tagsWrapper} ref={wrapperRef}>
      <div className={styles.tags} ref={elementsRef}>
        {currentElements.map((element, index) => (
          <div
            className={styles.tag}
            key={`${element}`}
            ref={elementListRefs[index]}
          >
            <Tag label={element} mode="full" />
          </div>
        ))}
      </div>
      <div className={styles.moreInfo} ref={moreInfoRef}>
        {!isListEmpty(moreInfoElements) && (
          <BadgeList options={moreInfoElements} trigger="hover" />
        )}
      </div>
    </div>
  );
};

export default Tags;
