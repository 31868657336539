import React from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { DiscussionCard } from "@trace-one/design-system";
import { ModificationRequestViewModel } from "viewModels/modificationRequest";
import { useTag, useModificationRequestPermission } from "hooks";
import { useRfmPanelCard } from "./hooks";
import { selectLanguageCode } from "store/user/selectors";
import {
  getModificationRequestionActions,
  getTimeSince,
} from "utils/modificationRequest";
import { isLibraryObjectTesting } from "utils/library";
import { ChapterStructureFormData } from "types/library";

export interface ModificationRequestPanelCardProps {
  request: ModificationRequestViewModel;
  onClick?: Function;
  onClickSubSection?: Function;
  selectedChapter?: ChapterStructureFormData;
  refetchModificationRequests?: Function;
  isSpecContact?: boolean;
  userId?: string;
}

export const scrollToField = (fieldId: string, chapterType: string) => {
  const field = document.querySelector(`[data-field-id="${fieldId}"]`);
  if (field) {
    field.scrollIntoView?.({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
    if (isLibraryObjectTesting(chapterType)) {
      field
        .closest('[role="gridcell"]')
        ?.classList.add("target-mode__border", "target-mode__background");
    } else {
      field?.classList.add("target-mode__border", "target-mode__background");
    }
  }
};

const ModificationRequestPanelCard: React.FC<ModificationRequestPanelCardProps> = ({
  request,
  onClick,
  onClickSubSection,
  selectedChapter,
  refetchModificationRequests,
  isSpecContact,
  userId,
}) => {
  const { formatMessage } = useIntl();
  const languageCode = useSelector(selectLanguageCode);

  const {
    state,
    targetField,
    user,
    message,
    requestId,
    chapterType,
    lastUpdateDateUtc,
  } = request;

  const {
    onArchive,
    onDecline,
    onDelete,
    onPerform,
    onComplete,
  } = useRfmPanelCard({
    refetchModificationRequests,
    requestId,
  });

  const {
    canCancel,
    canDelete,
    canPerformAndDecline,
    canSetAsComplete,
  } = useModificationRequestPermission({
    userId,
    modificationRequest: request,
    isSpecContact,
  });

  const { tag } = useTag(state);

  const handleClick = async event => {
    // Open discussion panel
    onClick?.(requestId);

    // scroll to field
    const fieldId = `${
      targetField?.listItemKey ? `${targetField?.listItemKey}-` : ""
    }${targetField?.propertyId}`;

    try {
      if (chapterType !== selectedChapter.chapterType) {
        await onClickSubSection?.(
          null,
          targetField?.sectionId,
          chapterType
        )(event);

        setTimeout(() => {
          scrollToField(fieldId, chapterType);
        }, 3000);
      } else {
        scrollToField(fieldId, chapterType);
      }
    } catch (error) {}
  };

  const actions = getModificationRequestionActions({
    formatMessage,
    onPerform,
    onDecline,
    canPerformAndDecline,
    onComplete,
    canSetAsComplete,
    canCancel,
    onArchive,
    canDelete,
    onDelete,
    state,
  });

  const users = user ? [user] : [];

  return (
    <DiscussionCard
      app="spec"
      cardIcon="request-modification-full"
      cardIconColor={tag?.color}
      title={targetField?.sectionName}
      subTitle={targetField?.fieldName}
      body={message}
      tag={tag?.label}
      tagColor={tag?.color}
      users={users}
      timestamp={lastUpdateDateUtc}
      formatedTimestamp={getTimeSince({
        elapsedTime: Date.now() - new Date(lastUpdateDateUtc).getTime(),
        formatMessage,
        languageCode,
      })}
      onClick={handleClick}
      bodyIcon={"kebab"}
      actions={actions}
      disableButtonActions={actions?.every(({ disabled }) => disabled)}
    />
  );
};

export default ModificationRequestPanelCard;
