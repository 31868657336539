import { useIntl, FormattedMessage } from "react-intl";
import { Title } from "@trace-one/react-components";
import { Heading } from "@trace-one/design-system";
import { isStateDraft } from "utils/specifications";
import Header from "components/Header";
import Spin from "components/Spin";
import Details from "./components/Details";
import TradeItemList from "./components/TradeItemList";
import BackButton from "../BackButton";
import { useFolderDetails } from "../../hooks";
import { messages } from "./messages";

const FolderDetails = () => {
  const intl = useIntl();

  const { folderDetails, isLoading, hasError } = useFolderDetails();

  if (isLoading) return <Spin />;

  if (hasError)
    return (
      <>
        <BackButton />
        <Heading size="xxs" color="red" data-testid="folder-details-error">
          <FormattedMessage {...messages.errorMessage} />
        </Heading>
      </>
    );

  return (
    <>
      <BackButton />
      <Header.Folder
        id={folderDetails.folderId}
        name={folderDetails.folderName}
        state={folderDetails.state}
      />
      <Title
        level={2}
        value={intl.formatMessage(messages.detailsTitle)}
        subtitle={intl.formatMessage(messages.detailsSubtitle)}
      />
      <Details folderDetails={folderDetails} />
      <Title
        level={2}
        value={intl.formatMessage(messages.assignedProductsTitle)}
        subtitle={intl.formatMessage(messages.assignedProductsSubtitle)}
      />
      {!isStateDraft(folderDetails.state) ? (
        <TradeItemList
          folderId={folderDetails.folderId}
          tradeItemIds={folderDetails.tradeItemIds}
        />
      ) : (
        <Heading data-testid="no-trade-items" size="xxs">
          <FormattedMessage {...messages.noTradeItems} />
        </Heading>
      )}
    </>
  );
};

export default FolderDetails;
