import { LIBRARY_OBJECT_TYPES } from "utils/constants";

export const getIsMaterialType = (type: string) => {
  return (
    type === LIBRARY_OBJECT_TYPES.MATERIALS ||
    type === LIBRARY_OBJECT_TYPES.RAW_MATERIALS ||
    type === LIBRARY_OBJECT_TYPES.BOUGHT_TO_THIRD_PARTY ||
    type === LIBRARY_OBJECT_TYPES.MADE_IN_HOUSE
  );
};

export const onTabChange = ({
  tabKey,
  libraryItemId,
  libraryItemType,
  redirectToPage,
}: {
  tabKey: string;
  libraryItemId: string;
  libraryItemType: string;
  redirectToPage: Function;
}) => {
  redirectToPage({
    id: libraryItemId,
    type: libraryItemType,
    tab: tabKey,
    pushState: {
      skipDetectLeavePage: true,
    },
  });
};
