import { useEffect, useMemo, useState } from "react";
import { Row } from "antd";
import { MenuTabs } from "@trace-one/react-components";
import {
  getOnMenuTabSelect,
  getOnTabsLengthChange,
  getTabsFromRequirements,
} from "./utils";
import { RequirementsTabsProps } from "./types";

const RequirementsTabs: React.FC<RequirementsTabsProps> = ({
  requirements,
  displayActions = true,
}) => {
  const tabs = useMemo(
    getTabsFromRequirements({ requirements, displayActions }),
    [requirements]
  );
  const [selectedTabKey, setSelectedTabKey] = useState(tabs[0]?.key);

  useEffect(
    getOnTabsLengthChange({
      tabs,
      setSelectedTabKey,
    }),
    [tabs.length]
  );

  return (
    <Row gutter={[0, 45]}>
      <MenuTabs
        dataTestId="requirement-tab-menu"
        onTabSelect={getOnMenuTabSelect({
          setSelectedTabKey,
        })}
        selectedKey={selectedTabKey}
        tabs={tabs}
      />
    </Row>
  );
};

export default RequirementsTabs;
