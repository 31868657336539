import { useEffect } from "react";
import { Form } from "antd";
import { useIntl } from "react-intl";
import {
  Input,
  InputNumber,
  Toggle,
  Paragraph,
} from "@trace-one/design-system";
import { messages } from "../../messages";
import libraryMessages from "messages/library";
import { inputErrorMessages } from "messages/errors";
import { NutritionServingFormProps } from "./types";
import styles from "./styles.module.less";

const NutritionServingForm: React.FC<NutritionServingFormProps> = ({
  nutritionServingFormData,
  setFormErrors,
  selectedUnit,
  items,
  buttonProps,
  handleServingNameChange,
  handleServingSizeChange,
  handleAutomaticCalculationChange,
  onClearUnit,
  isCalculationToggleDisabled,
  isEditMode,
}) => {
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(nutritionServingFormData);
  }, [nutritionServingFormData]);

  return (
    <Form
      name="nutritionServing"
      layout="horizontal"
      form={form}
      onFieldsChange={() => setFormErrors(form.getFieldsError())}
      validateMessages={{
        required: formatMessage(libraryMessages.formRequired),
        whitespace: formatMessage(libraryMessages.formRequired),
        string: {
          max: formatMessage(libraryMessages.formMaxCharLength, {
            max: 64,
          }),
        },
        number: {
          min: formatMessage(inputErrorMessages.minimumNumber, {
            min: 0,
          }),
        },
      }}
      labelAlign="left"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <Form.Item
        name="servingName"
        label={formatMessage(messages.servingName)}
        rules={[{ required: true, whitespace: true, max: 64 }]}
      >
        <Input
          placeholder={formatMessage(messages.servingNamePlaceholder)}
          onChange={handleServingNameChange}
        />
      </Form.Item>
      <Form.Item
        name="servingSize"
        label={formatMessage(messages.servingSize)}
        rules={[{ type: "number", required: true, min: 0.001 }]}
      >
        <InputNumber.Dual
          placeholder={0}
          onChange={handleServingSizeChange}
          step={0.001}
          precision={3}
          buttonValue={selectedUnit?.text}
          items={items}
          buttonProps={{ ...buttonProps }}
          onClearButton={onClearUnit}
          className={styles.dualInput}
        />
      </Form.Item>
      <Form.Item label={formatMessage(messages.automaticCalculation)}>
        {isEditMode && (
          <Paragraph
            size="s"
            className={styles.automaticCalculationDescription}
          >
            {formatMessage(messages.automaticCalculationDescription)}
          </Paragraph>
        )}

        <Form.Item name="automaticCalculation" noStyle>
          <Toggle
            data-testid="automatic-calculation-switch"
            onChange={handleAutomaticCalculationChange}
            checked={nutritionServingFormData?.automaticCalculation}
            disabled={isCalculationToggleDisabled}
          />
        </Form.Item>
      </Form.Item>
    </Form>
  );
};

export default NutritionServingForm;
