import { defineMessages } from "react-intl";

export const messages = defineMessages({
  mosaicView: {
    id: "library.view.mosaic",
    defaultMessage: "Mosaic",
  },

  listView: {
    id: "library.view.list",
    defaultMessage: "List",
  },
});
