import { ReduxClaimType } from "store/claimForm/claimFormSlice";
import { TagData } from "models";

export const prepareClaimFormDataForComparison = (
  claimForm: ReduxClaimType
) => {
  let updatedClaimForm;

  updatedClaimForm = {
    generalInfo: {
      ...claimForm?.generalInfo,
      tags: claimForm?.generalInfo?.tags?.map((tag: TagData | string) => {
        if (typeof tag === "string") {
          return tag;
        }
        return tag.tagId;
      }),
    },
    sentenceTranslations: claimForm?.sentenceTranslations,
    file: { id: claimForm?.file?.id },
  };

  return updatedClaimForm;
};
