import { State } from "./types";

const reducers = {
  setUserLanguage: (state: State, action) => {
    state.userLanguagePreference = action.payload;
  },

  setCompanyId: (state: State, action) => {
    state.owningCompanyId = action.payload;
  },
};

export default reducers;
