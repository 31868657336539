import { State } from "./types";

const initialState: State = {
  accessToken: null,
  companyActivity: null,
  companyCountry: null,
  permissions: [],
};

export default initialState;
