import classnames from "classnames";
import { Col, Form, Row } from "antd";
import { getPropertiesObjectFromArray } from "../../templates/utils";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import ErrorsList from "components/ErrorsList";
import { isListEmpty } from "utils/general";
import { isValueFieldDisabled, preparePropsForContent } from "./utils";
import styles from "./styles.module.less";

const ConditionalWidget = ({
  formContext,
  properties,
  uiSchema,
  formData,
  schema,
}) => {
  const propertyObjects = getPropertiesObjectFromArray(properties);
  const { getTranslationForKey } = useFormSchemaTranslation();
  const { displayActions } = formContext;
  const hasError = !isListEmpty(
    propertyObjects.value.content.props.errorSchema.__errors
  );

  const colSpans = {
    xs: displayActions ? 24 : 8,
    sm: 8,
    md: 8,
    lg: 8,
  };

  return (
    <Row align="top" className={styles.conditionalWrapper}>
      <Col {...colSpans} {...uiSchema["ui:label:col"]}>
        {preparePropsForContent({
          properties: propertyObjects.display.content,
        })}
        <span className={classnames(styles.label, hasError && styles.hasError)}>
          {getTranslationForKey(uiSchema["ui:title"])}
        </span>
      </Col>
      <Col {...colSpans} {...uiSchema["ui:wrapper:col"]}>
        <Form.Item
          {...(hasError && {
            help: (
              <ErrorsList
                list={propertyObjects.value.content.props.errorSchema.__errors}
              />
            ),
            validateStatus: "error",
          })}
        >
          {preparePropsForContent({
            properties: propertyObjects.value.content,
            disabled: isValueFieldDisabled({
              schemaName: schema.name,
              display: formData.display,
            }),
          })}
        </Form.Item>
      </Col>
    </Row>
  );
};

export default ConditionalWidget;
