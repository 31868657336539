import { createSlice } from "@reduxjs/toolkit";
import { fetchPackagingComponentAsync } from "./asyncActions";
import reducers from "./reducers";
import initialState from "./intialState";

export const slice = createSlice({
  name: "packagingSlice",
  initialState,
  reducers,
  extraReducers: builder => {
    builder.addCase(fetchPackagingComponentAsync.pending, (state, action) => {
      if (!action?.meta?.arg?.withoutLoading) {
        state.isLoading = true;
      }
      state.hasError = false;
    });
    builder.addCase(fetchPackagingComponentAsync.fulfilled, (state, action) => {
      state.isLoading = false;
      state.hasError = false;
      state.packagingComponent = action.payload;
    });
    builder.addCase(fetchPackagingComponentAsync.rejected, (state, action) => {
      state.isLoading = false;
      state.hasError = true;
    });
  },
});

export const {
  setPackagingComponentName,
  setPackagingComponentUsageLevel,
} = slice.actions;

export default slice.reducer;
