import { defineMessages } from "react-intl";

export const messages = defineMessages({
  specificationName: {
    id: "specificationListing.table.specificationName",
    defaultMessage: "Specification",
  },
  retailerName: {
    id: "specificationListing.table.retailerName",
    defaultMessage: "Retailer",
  },

  supplierName: {
    id: "specificationListing.table.supplierName",
    defaultMessage: "Supplier",
  },

  status: {
    id: "status",
    defaultMessage: "Status",
  },
});
