import { useEffect, useState } from "react";
import { fetchDateFormats } from "apis/SPEC";
import { SelectValue } from "antd/lib/select";

const useDateFormatWidget = () => {
  const [dateFormats, setDateFormats] = useState<SelectValue>([]);

  const getDateFormats = async () => {
    try {
      const { data } = await fetchDateFormats();

      setDateFormats(
        data.map(dateFormat => ({ value: dateFormat, label: dateFormat }))
      );
    } catch (e) {
      setDateFormats([]);
    }
  };

  useEffect(() => {
    getDateFormats();
  }, []);

  return {
    dateFormats,
  };
};

export default useDateFormatWidget;
