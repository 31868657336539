import { defineMessages } from "react-intl";

export const messages = defineMessages({
  removeTheSite: {
    id: "specificationDetails.sections.site.remove",
    defaultMessage: "Remove the site",
  },

  chooseASite: {
    id: "specificationDetails.sections.site.choose",
    defaultMessage: "Choose a site",
  },

  addASite: {
    id: "specificationDetails.sections.site.add",
    defaultMessage: "Add a site",
  },

  noSite: {
    id: "specificationDetails.sections.site.noSite",
    defaultMessage: "No site",
  },

  noSiteAdded: {
    id: "specificationDetails.sections.site.noSiteAdded",
    defaultMessage: "No site added",
  },

  siteError: {
    id: "specificationDetails.sections.site.error",
    defaultMessage: "An error ocurred: unable to recover the sites",
  },
});
