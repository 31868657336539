import { useContext, useMemo } from "react";
import { useIntl } from "react-intl";
import { Form } from "antd";
import { useSelector } from "react-redux";
import { Typography } from "@trace-one/design-system";
import { UploadFiles } from "@trace-one/business-components";
import Spin from "components/Spin";
import { selectClaimForm } from "store/claimForm/claimFormSlice";
import { selectOwningCompanyId } from "store/user/selectors";
import { ClaimFormContext } from "../../contexts/ClaimFormContext";
import { useErrorCodeCheck } from "hooks";
import { useClaimLogo } from "./hooks";
import { isObjectEmpty } from "utils/general";
import { ERROR_CODES, FILE_TYPES } from "utils/constants";
import { claimMessages, libraryMessages } from "messages";
import styles from "./style.module.less";

const ClaimLogo = () => {
  const { formatMessage } = useIntl();

  const { isLoading, setLogoFile } = useContext(ClaimFormContext);

  const { file } = useSelector(selectClaimForm);
  const ownerCompanyId = useSelector(selectOwningCompanyId);

  const { onSelect, onUploadFile, onRemove } = useClaimLogo(setLogoFile);

  const { isDesiredErrorCode } = useErrorCodeCheck(ERROR_CODES.FILE_NOT_FOUND);
  const notFoundFileId = useMemo(() => (isDesiredErrorCode ? file?.id : null), [
    isDesiredErrorCode,
  ]);

  if (isLoading) return <Spin />;

  return (
    <Form.Item
      name="claimLogo"
      label={formatMessage(claimMessages.claimLogoLabel)}
      wrapperCol={{ md: 14 }}
    >
      {!isObjectEmpty(file) ? (
        <UploadFiles.Item
          fileName={file?.fileName}
          fileSize={file?.fileSize}
          showFileDownload={!!file?.id}
          fileId={file?.id}
          onRemove={onRemove}
          {...(isDesiredErrorCode && file?.id === notFoundFileId
            ? {
                status: "error",
                errorMessage: formatMessage(libraryMessages.fileNotFound),
              }
            : {})}
        />
      ) : (
        <>
          <div className={styles["claim-logo__upload-file__title"]}>
            <Typography variant={"body-regular"} color={"grey-4"}>
              {formatMessage(claimMessages.claimUploadFileTitle)}
            </Typography>
          </div>
          <UploadFiles.Dragger
            fileList={[]}
            fileType={FILE_TYPES.CLAIM_LOGO}
            onUploadFiles={onUploadFile}
            onSelect={onSelect}
            ownerCompanyId={ownerCompanyId}
            multiple={false}
            shouldAvoidDMSUpload={true}
          />
        </>
      )}
    </Form.Item>
  );
};

export default ClaimLogo;
