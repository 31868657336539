import { Form } from "antd";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { useFormItemCols } from "../../hooks";
import ErrorsList from "components/ErrorsList";
import RefListWidget from "../RefListWidget";
import { useMultipleRefListWidget } from "./hooks";
import { MultipleRefListWidgetProps } from "./types";
import { isListEmpty } from "utils/general";

const MultipleRefListWidget: React.FC<MultipleRefListWidgetProps> = props => {
  const { reflistProps } = useMultipleRefListWidget(props);
  const { uiSchema, formContext } = props;

  const { getTranslationForKey } = useFormSchemaTranslation();

  const { formItemLabelCol, formItemWrapperCol } = useFormItemCols({
    labelCol: formContext.labelCol,
    wrapperCol: formContext.wrapperCol,
    displayActions: formContext.displayActions,
    uiSchema,
  });

  return (
    <Form.Item
      label={getTranslationForKey(uiSchema["ui:title"])}
      labelAlign={formContext.labelAlign}
      labelCol={formItemLabelCol}
      wrapperCol={formItemWrapperCol}
      {...(!isListEmpty(props.rawErrors) && {
        help: <ErrorsList list={props.rawErrors} />,
      })}
    >
      <RefListWidget {...reflistProps} />
    </Form.Item>
  );
};

export default MultipleRefListWidget;
