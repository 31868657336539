import { useRbac } from "hooks";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { PERMISSIONS, ROUTES } from "utils/constants";

const AdministratorRoute = (props: RouteProps) => {
  const { hasPermission } = useRbac();

  return hasPermission(PERMISSIONS.USER.HAS_ACCESS_TO_ADMINISTRATION) ? (
    <Route {...props} />
  ) : (
    <Redirect to={ROUTES.HOME} />
  );
};

export default AdministratorRoute;
