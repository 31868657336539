import { LIBRARY_OBJECT_TYPES } from "utils/constants";

export const getOnCreateActionClick = ({
  redirectToLibraryCreation,
  purge,
}: {
  redirectToLibraryCreation: Function;
  purge: Function;
}) => (type: LIBRARY_OBJECT_TYPES, queryParameters?: object) => async () => {
  await purge();

  redirectToLibraryCreation({ type, queryParameters });
};
