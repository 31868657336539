import { defineMessages } from "react-intl";

export const messages = defineMessages({
  progressListQuickviewError: {
    id: "quickview.progressList.error",
    defaultMessage: "There was an error retrieving the Specification data",
  },

  allChapters: {
    id: "quickview.allChapters",
    defaultMessage: "All chapters",
  },

  progressListQuickviewTitle: {
    id: "quickview.progressList.title",
    defaultMessage: "Progress",
  },
});
