import { useEffect, useState } from "react";
import {
  useSpecificationActions,
  useSpecificationPermissionsFromApi,
} from "hooks";
import { isObjectEmpty } from "utils/general";
import { MenuActions } from "types/general";
import { getActionList } from "./utils";
import { ProductSpecification } from "store/folderCreation/types";

const useActions = ({
  specification,
  displayActionList,
}: {
  specification: ProductSpecification;
  displayActionList: boolean;
}) => {
  const [actionList, setActionList] = useState<MenuActions[]>([{}]);

  const { isDone, permissionList } = useSpecificationPermissionsFromApi({
    specificationId: specification.specificationId,
    state: specification.state,
  });

  let actions = useSpecificationActions(specification, permissionList);

  useEffect(() => {
    if (!isObjectEmpty(actions)) {
      setActionList(
        getActionList({
          actions,
          displayActionList,
        })
      );
    }
  }, [JSON.stringify(actions)]);

  useEffect(() => {
    if (isDone && isObjectEmpty(actions)) {
      //set disabled the 3 dot icon
      setActionList([]);
    }
  }, [isDone]);

  return { isDone, actionList };
};

export default useActions;
