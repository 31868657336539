import { Heading } from "@trace-one/design-system";
import { FormattedMessage } from "react-intl";
import { CreationSubtitleProps } from "./types";
import { messages } from "./messages";
import styles from "./styles.module.less";

const CreationSubtitle: React.FC<CreationSubtitleProps> = ({ name, type }) => {
  if (!name && !type) return null;

  return (
    <div className={styles.subtitle} data-testid="createFolderSubtitle">
      {name ? (
        <>
          <Heading size="xs" color="grey-3">
            <FormattedMessage {...messages.name} />:
          </Heading>
          &nbsp;
          <Heading size="xs" color="primary">
            {name}
          </Heading>
          {type && (
            <Heading size="xs" color="grey-3">
              <span>&nbsp;/&nbsp;</span>
            </Heading>
          )}
        </>
      ) : null}
      {type ? (
        <>
          <Heading size="xs" color="grey-3">
            <FormattedMessage {...messages.type} />:
          </Heading>
          &nbsp;
          <Heading size="xs" color="primary">
            {type}
          </Heading>
        </>
      ) : null}
    </div>
  );
};
export default CreationSubtitle;
