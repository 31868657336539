import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { searchFolderList } from "apis/SPEC";
import { selectLanguageCode } from "store/user/selectors";
import usePagination from "../usePagination";
import { FolderListItemData } from "models";
import { FiltersViewModel } from "viewModels/folderList";

const useFolderPaginatedList = () => {
  const languageCode = useSelector(selectLanguageCode);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [folders, setFolders] = useState<FolderListItemData[]>([]);
  const [totalNumberOfFolders, setTotalNumberOfFolders] = useState(0);
  const [filters, setFilters] = useState<FiltersViewModel>({
    statuses: [],
    productTypeIds: [],
    ownerOrContactIds: [],
    folderName: "",
  });
  const [shouldRefetchFolders, setShouldRefetchFolders] = useState<boolean>(
    false
  );

  const {
    pagination,
    sorter,
    onTableChange,
    resetCurrentPage,
  } = usePagination();

  const searchFolders = async () => {
    try {
      setIsLoading(true);
      setHasError(false);

      const { current, pageSize } = pagination;
      const skip = current <= 1 ? 0 : (current - 1) * pageSize;

      const { data } = await searchFolderList({
        params: {
          take: pageSize,
          skip: skip,
          languageCode,
          orderBy: sorter.orderBy,
          orderByDescending: sorter.orderByDescending,
          folderName: filters?.folderName,
        },
        data: {
          states: filters?.statuses,
          productTypeIds: filters?.productTypeIds,
          ownerOrContactIds: filters?.ownerOrContactIds,
        },
      });

      setFolders(data.items);
      setTotalNumberOfFolders(data.totalNumberOfItems);
    } catch (e) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const onUpdateFilters = (newFilters: FiltersViewModel) => {
    setFilters(previousState => ({
      ...previousState,
      ...newFilters,
    }));

    resetCurrentPage();
  };

  useEffect(() => {
    searchFolders();
  }, [
    pagination.current,
    pagination.pageSize,
    sorter.orderBy,
    sorter.orderByDescending,
    filters?.folderName,
    filters?.ownerOrContactIds?.length,
    filters?.productTypeIds?.length,
    filters?.statuses?.length,
  ]);

  useEffect(() => {
    if (shouldRefetchFolders) {
      searchFolders();
      setShouldRefetchFolders(false);
    }
  }, [shouldRefetchFolders]);

  return {
    folders,
    totalNumberOfFolders,
    filters,
    isLoading,
    hasError,
    pagination,
    onTableChange,
    onUpdateFilters,
    resetCurrentPage,
    setShouldRefetchFolders,
  };
};

export default useFolderPaginatedList;
