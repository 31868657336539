import { SyntheticEvent } from "react";
import { useRedirect } from "hooks";
import { CHAPTER_TYPES_API } from "utils/constants";

const useMenuActions = ({
  specificationId,
  chapterType,
  onUpdateSelectedSectionId,
  refetchSpecificationInformation,
}: {
  specificationId: string;
  chapterType: string;
  onUpdateSelectedSectionId: Function;
  refetchSpecificationInformation: Function;
}) => {
  const { redirectToSpecificationDetails } = useRedirect();

  const onClickSection = (_, selectedChapterType: string) => async (
    event: SyntheticEvent
  ) => {
    event.stopPropagation();
    onUpdateSelectedSectionId(null);

    if (chapterType !== selectedChapterType) {
      await refetchSpecificationInformation();

      redirectToSpecificationDetails(
        specificationId,
        CHAPTER_TYPES_API[selectedChapterType]
      );
    }
  };

  const onClickSubSection = (
    sectionId: string,
    subSectionId: string,
    chapterType: string
  ) => async (event: SyntheticEvent) => {
    await onClickSection(sectionId, chapterType)(event);

    await onUpdateSelectedSectionId(subSectionId);
  };

  return {
    onClickSection,
    onClickSubSection,
  };
};

export default useMenuActions;
