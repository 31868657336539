import { getVersionName } from "utils/specifications";
import VersionMessage from "../VersionMessage";
import { SpecificationQuickViewTitleProps } from "./types";

export default function SpecificationQuickViewTitle({
  specification,
}: SpecificationQuickViewTitleProps) {
  const {
    state,
    version: { activeVersion, number: versionNumber },
    creationDateUtc,
  } = specification;

  return (
    <div>
      {getVersionName(versionNumber)}{" "}
      <VersionMessage
        activeVersion={activeVersion}
        creationDateUtc={creationDateUtc}
        state={state}
      />
    </div>
  );
}
