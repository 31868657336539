import { useEffect, useMemo, useState } from "react";
import { getPropertiesObjectFromArray } from "components/SpecificationSection/components/FormSchema/templates/utils";
import { getErrorsListFromProperties } from "../../../utils";
import { CompactFormData } from "components/SpecificationSection/components/FormSchema/types";
import { ObjectFieldTemplateProps } from "components/SpecificationSection/types";

const useCompactWidget = ({
  formData,
  widgetProperties,
}: {
  formData: CompactFormData;
  widgetProperties: ObjectFieldTemplateProps["properties"];
}) => {
  const [compactData, setCompactData] = useState(formData);

  const errors = useMemo(() => getErrorsListFromProperties(widgetProperties), [
    widgetProperties,
  ]);

  const propertyObject = getPropertiesObjectFromArray(widgetProperties);

  const onChangeHandle = (name: string) => (value: string) => {
    if (
      typeof propertyObject?.[name]?.content?.props?.onChange === "function"
    ) {
      propertyObject[name].content.props.onChange(value);
    }

    setCompactData(previousState => ({
      ...previousState,
      [name]: value,
    }));
  };

  useEffect(() => {
    setCompactData(previousState => ({
      ...previousState,
      unit: formData.unit,
    }));
  }, [formData.unit]);

  useEffect(() => {
    setCompactData(previousState => ({
      ...previousState,
      unitId: formData.unitId,
    }));
  }, [formData.unitId]);

  useEffect(() => {
    setCompactData(previousState => ({
      ...previousState,
      value: formData.value,
    }));
  }, [formData.value]);

  return {
    compactData,
    errors,
    onChangeHandle,
  };
};

export default useCompactWidget;
