import { useCallback, useState } from "react";
import { useAppDispatch } from "store";
import { isListEmpty, isObjectEmpty } from "utils/general";
import {
  removeRequirementProduct,
  setFolderRequirements,
} from "store/folderCreation/asyncActions";
import { RequirementData, TradeItemData } from "models";
import { AppliedRequirements } from "viewModels/folderCreation";

const useAppliedRequirements = ({
  selectedRequirements,
  selectedProductIds,
  setSelectedRequirements,
  defaultAppliedRequirements = {},
}: {
  selectedProductIds: TradeItemData["id"][];
  selectedRequirements: RequirementData[];
  setSelectedRequirements: Function;
  defaultAppliedRequirements?: AppliedRequirements;
}) => {
  const dispatch = useAppDispatch();

  const [
    appliedRequirements,
    setAppliedRequirements,
  ] = useState<AppliedRequirements>(defaultAppliedRequirements);

  const onApplyRequirements = () => {
    setAppliedRequirements(previousAppliedRequirements => {
      const newAppliedRequirements = selectedRequirements.reduce(
        (previousState: AppliedRequirements, currentSelectedRequirement) => {
          if (!previousState[currentSelectedRequirement.id]) {
            previousState[currentSelectedRequirement.id] = {
              tradeItemIds: new Set([...selectedProductIds]),
              requirementId: currentSelectedRequirement.id,
              requirementName: currentSelectedRequirement.name,
            };
          } else {
            previousState[
              currentSelectedRequirement.id
            ].tradeItemIds = new Set([
              ...previousState[currentSelectedRequirement.id].tradeItemIds,
              ...selectedProductIds,
            ]);
          }

          return previousState;
        },
        { ...previousAppliedRequirements }
      );

      dispatch(setFolderRequirements(newAppliedRequirements));

      return newAppliedRequirements;
    });

    setSelectedRequirements([]);
  };

  const getAppliedRequirementsForProduct = useCallback(
    (productId: TradeItemData["id"]) => {
      if (isObjectEmpty(appliedRequirements)) return [];

      const requirementsObject = Object.entries(appliedRequirements).reduce(
        (currentState, [requirementId, { tradeItemIds, requirementName }]) => {
          if (tradeItemIds.has(productId)) {
            currentState[requirementId] = {
              requirementId,
              requirementName,
            };
          }

          return currentState;
        },
        {}
      );

      return Object.values(requirementsObject);
    },
    [JSON.stringify(appliedRequirements)]
  );

  const onRemoveProductRequirement = (productId: TradeItemData["id"]) => (
    requirementId: RequirementData["id"]
  ) => () => {
    dispatch(
      removeRequirementProduct({ tradeItemId: productId, requirementId })
    );

    setAppliedRequirements(previousAppliedRequirements => {
      const {
        [requirementId]: currentRequirement,
        ...restCurrentRequirements
      } = previousAppliedRequirements;

      currentRequirement.tradeItemIds.delete(productId);

      if (isListEmpty(currentRequirement.tradeItemIds)) {
        return { ...restCurrentRequirements };
      } else {
        return {
          ...restCurrentRequirements,
          [requirementId]: currentRequirement,
        };
      }
    });
  };

  return {
    appliedRequirements,
    onApplyRequirements,
    onRemoveProductRequirement,
    getAppliedRequirementsForProduct,
  };
};
export default useAppliedRequirements;
