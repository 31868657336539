import { Col, Row } from "antd";
import { Heading } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import classNames from "classnames";
import { messages } from "./messages";
import { getTotalPercentageColor, getTotalPercentageString } from "./utils";
import { LIBRARY_OBJECT_TYPES } from "utils/constants";
import {
  isLibraryObjectChaptersType,
  isMaterialMaterialsType,
} from "utils/library";
import styles from "./styles.module.less";

const RecipeTableFooter = ({
  totalPercentage,
  displayActions,
  type,
}: {
  totalPercentage: number;
  displayActions: boolean;
  type: LIBRARY_OBJECT_TYPES;
}) => {
  const { formatMessage } = useIntl();

  const totalPercentageString = getTotalPercentageString(
    parseFloat(Number(totalPercentage).toFixed(3))
  );
  const totalPercentageColor = getTotalPercentageColor(totalPercentage);

  return (
    <Row>
      <Col
        className={classNames({
          [styles["recipe-footer-title__chapters-with-actions"]]:
            displayActions && isLibraryObjectChaptersType(type),
          [styles["recipe-footer-title__chapters"]]:
            !displayActions && isLibraryObjectChaptersType(type),
          [styles["recipe-footer-title__materials"]]: isMaterialMaterialsType(
            type
          ),
        })}
      >
        <Heading size="xs" color="grey-5">
          {formatMessage(messages.totalPercentage)}
        </Heading>
      </Col>
      <Col className={styles.recipeFooterValue}>
        <Heading size="xs" color={totalPercentageColor}>
          {totalPercentageString}
        </Heading>
      </Col>
    </Row>
  );
};

export default RecipeTableFooter;
