import { useState } from "react";
import { useSelector } from "react-redux";
import { fetchMaterial } from "apis/SPEC";
import { useAppDispatch } from "store";
import { selectLanguageCode } from "store/user/selectors";
import { isListEmpty } from "utils/general";
import { MaterialIngredientData } from "models";
import { getMaterialApiType } from "utils/library";

const useIngredientFunctionDropdownActions = ({
  ingredient,
  update,
}: {
  ingredient: MaterialIngredientData;
  update: Function;
}) => {
  const languageCode = useSelector(selectLanguageCode);

  const dispatch = useAppDispatch();

  const [currentFunctionIds, setCurrentFunctionIds] = useState<string[]>([]);

  const fetchCurrentMaterial = async () => {
    try {
      const { data } = await fetchMaterial({
        materialId: ingredient.materialId,
        materialType: getMaterialApiType({
          materialType: ingredient.type,
        }),
        languageCode,
      });

      setCurrentFunctionIds(data.functionIds);
    } catch (e) {}
  };

  const onUpdateFunction = (
    functionId: MaterialIngredientData["functionId"]
  ) => {
    dispatch(
      update({
        ingredientId: ingredient?.ingredientId,
        percentage: ingredient?.percentage,
        ...{ ...(functionId && { functionId }) },
      })
    );
  };

  const onDropdownVisibleFunction = (isOpen: boolean) => {
    if (isOpen && isListEmpty(currentFunctionIds)) {
      fetchCurrentMaterial();
    }
  };

  return {
    currentFunctionIds,
    onUpdateFunction,
    onDropdownVisibleFunction,
  };
};

export default useIngredientFunctionDropdownActions;
