import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import {
  Button,
  ConditionalEllipsisWithTooltip,
} from "@trace-one/design-system";
import { selectLanguageCode } from "store/user/selectors";
import { selectCompanyActivity } from "store/oidc/selectors";
import { getProductOrigin } from "pages/Specification/components/List/utils";
import Status from "components/Status";
import { getDictionary, isListEmpty, isObjectEmpty } from "utils/general";
import { createNameForProduct } from "utils/form";
import {
  useRedirect,
  useTableInfiniteScroll,
  useNetContentFromTradeItems,
} from "hooks";
import { CHAPTER_TYPES_API, PERMISSIONS } from "utils/constants";
import {
  fetchChapterSpecifications,
  fetchSpecificationPermissions,
} from "apis/SPEC";
import { fetchCompanies } from "apis/CUMD";
import { Dictionary } from "types/general";
import { LinkedSpecification, UseLinkedSpecificationsProps } from "./types";
import { messages } from "./messages";
import generalMessages from "messages/general";
import styles from "../../styles.module.less";
import { ModalActionType } from "components/DetailsAndLinksChapterModal/types";

const useLinkedSpecifications = ({
  tableContent,
  onCloseModal,
  chapterId,
  chapterType,
  states,
  defaultRowSelection,
  actionType,
}: UseLinkedSpecificationsProps) => {
  const { formatMessage } = useIntl();
  const { redirectToSpecificationDetails } = useRedirect();

  const languageCode = useSelector(selectLanguageCode);
  const productOrigin = getProductOrigin({
    companyActivity: useSelector(selectCompanyActivity),
  });

  const [linkedSpecifications, setLinkedSpecifications] = useState<
    LinkedSpecification[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [canLoadMore, setCanLoadMore] = useState(true);
  const [companyIds, setCompanyIds] = useState<string[]>([]);
  const [tradeItemIds, setTradeItemIds] = useState<string[]>([]);

  const [companyDictionary, setCompanyDictionary] = useState<Dictionary>({});

  const { currentPage, scrollToTop } = useTableInfiniteScroll({
    tableContent,
    canLoadMore,
  });

  const { tradeItems, netContentRefList } = useNetContentFromTradeItems({
    tradeItemIds,
  });

  const getDataIndexForProductOrigin = ({
    productOrigin,
  }: {
    productOrigin: string;
  }) => (productOrigin === "supplier" ? "supplierId" : "ownerCompanyId");

  const onSpecificationNameClick = (specificationId: string) => () => {
    redirectToSpecificationDetails(specificationId);
    onCloseModal();
  };

  const getSpecificationName = ({
    tradeItemName,
    tradeItemId,
  }: {
    tradeItemName: string;
    tradeItemId: string;
  }) => {
    let itemName = tradeItemName;
    let netContents = [];

    const tradeItem = tradeItems?.find(({ id }) => id === tradeItemId);

    if (tradeItem) {
      itemName = tradeItem.itemName;
      netContents = [tradeItem.netContents[0]];
    }

    return createNameForProduct(
      {
        itemName,
        netContents,
      },
      netContentRefList
    );
  };

  const columns = [
    {
      title: formatMessage(messages.specificationName),
      dataIndex: "tradeItemName",
      key: "tradeItemName",
      render: (tradeItemName, specification) => {
        return (
          <Button
            type="link"
            onClick={onSpecificationNameClick(specification.specificationId)}
            className={styles.buttonLink}
          >
            <span>
              <ConditionalEllipsisWithTooltip
                text={getSpecificationName({
                  tradeItemName,
                  tradeItemId: specification.tradeItemId,
                })}
                placement="bottom"
              >
                {getSpecificationName({
                  tradeItemName,
                  tradeItemId: specification.tradeItemId,
                })}
              </ConditionalEllipsisWithTooltip>
            </span>
          </Button>
        );
      },
    },
    {
      title: formatMessage(generalMessages.version),
      dataIndex: ["version", "number"],
      width: 90,
    },
    {
      title: formatMessage(messages[`${productOrigin}Name`]),
      dataIndex: getDataIndexForProductOrigin({ productOrigin }),
      render: companyId => companyDictionary[companyId],
      width: 220,
    },
    {
      title: formatMessage(messages.status),
      dataIndex: "state",
      render: state => <Status.Tag state={state} />,
      width: 190,
    },
  ];

  const getLinkedSpecifications = async () => {
    if (canLoadMore) {
      try {
        setIsLoading(true);

        const { data } = await fetchChapterSpecifications({
          chapterType: CHAPTER_TYPES_API[chapterType],
          chapterId,
          params: {
            languageCode,
            take: 10,
            skip: (currentPage - 1) * 10,
            orderBy: "lastUpdateDateUtc",
            orderByDescending: true,
            ...(states ? { states: states.join(",") } : {}),
          },
        });

        let items: LinkedSpecification[] = [];
        let totalItems: number;

        if (isListEmpty(data?.items)) {
          items = [];
          totalItems = 0;
        } else {
          setTradeItemIds(data?.items.map(({ tradeItemId }) => tradeItemId));

          totalItems = data?.totalNumberOfItems;

          for (const item of data?.items) {
            const res = await fetchSpecificationPermissions({
              specificationId: item.specificationId,
            });

            items.push({ ...item, permissions: res.data });
          }

          let uniqueCompanyIds = [
            ...new Set(
              items.map(
                item => item[getDataIndexForProductOrigin({ productOrigin })]
              )
            ),
          ];
          setCompanyIds(uniqueCompanyIds);
        }
        let newItemsLength = items.length;

        setLinkedSpecifications(previousState => {
          const newItemsList =
            currentPage > 1 ? [...previousState, ...items] : [...items];

          newItemsLength = newItemsList.length;

          return newItemsList;
        });

        setCanLoadMore(totalItems > newItemsLength);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    getLinkedSpecifications();
  }, [currentPage, canLoadMore]);

  const getCompaniesData = async () => {
    try {
      const { data } = await fetchCompanies({
        ids: companyIds,
      });

      setCompanyDictionary(prev => {
        return {
          ...prev,
          ...getDictionary({
            data,
            key: "companyId",
            value: "companyDisplayName",
          }),
        };
      });
    } catch (_) {}
  };

  useEffect(() => {
    if (!isListEmpty(companyIds)) {
      getCompaniesData();
    }
  }, [JSON.stringify(companyIds)]);

  const isDisabled = (linkedSpecification: LinkedSpecification) => {
    if (actionType && actionType === ModalActionType.REVISE) {
      return {
        disabled:
          !linkedSpecification.version.activeVersion ||
          linkedSpecification.permissions.findIndex(
            item => item === PERMISSIONS.SPECIFICATION.REVISE
          ) === -1,
      };
    } else {
      return {
        disabled:
          linkedSpecification.permissions.findIndex(
            item => item === PERMISSIONS.SPECIFICATION.UPDATE
          ) === -1,
      };
    }
  };

  const rowSelection = !isObjectEmpty(defaultRowSelection)
    ? {
        ...defaultRowSelection,
        getCheckboxProps: isDisabled,
      }
    : null;

  return {
    columns,
    linkedSpecifications,
    isLoading,
    scrollToTop,
    rowSelection,
  };
};

export default useLinkedSpecifications;
