import { useContext } from "react";
import MadeInHouseDetails from "components/Library/components/MaterialForm/components/MadeInHouseDetails";
import RawMaterialDetails from "components/Library/components/MaterialForm/components/RawMaterialDetails";
import BoughtDetails from "components/Library/components/MaterialForm/components/BoughtDetails";
import SubstanceDetails from "components/Library/components/MaterialForm/components/SubstanceDetails";
import { prepareMaterialFromApiData } from "dtos/material";
import { LibraryDetails } from "pages/Library/components/Details/contexts";
import {
  isMaterialAdditiveType,
  isMaterialCompositeMadeInHouse,
  isMaterialProcessingAidType,
  isMaterialRawMaterialType,
} from "utils/library";

const MaterialDetails = () => {
  const { libraryItemDetails } = useContext(LibraryDetails);
  const material = prepareMaterialFromApiData(libraryItemDetails);

  let minProps = {
    displayActions: false,
    materialId: material.materialInformation.id,
    ...material,
  };

  if (isMaterialRawMaterialType(material?.materialInformation?.materialType)) {
    return <RawMaterialDetails {...minProps} />;
  }

  if (
    isMaterialAdditiveType(material?.materialInformation?.materialType) ||
    isMaterialProcessingAidType(material?.materialInformation?.materialType)
  ) {
    return (
      <SubstanceDetails
        {...{
          ...minProps,
          materialType: material?.materialInformation?.materialType,
        }}
      />
    );
  }

  if (
    isMaterialCompositeMadeInHouse(material?.materialInformation?.recipeType)
  ) {
    return <MadeInHouseDetails {...minProps} />;
  }

  return <BoughtDetails {...minProps} />;
};

export default MaterialDetails;
