import { SorterResult } from "antd/lib/table/interface";
import { LibraryObjectData } from "models";

export const getOnTableChange = ({
  setOrderBy,
  setOrderByDescending,
}: {
  setOrderBy: Function;
  setOrderByDescending: Function;
}) => (_, __, sorter: SorterResult<LibraryObjectData>) => {
  setOrderBy(sorter.field);
  setOrderByDescending(sorter.order !== "ascend");
};
