import { defineMessages } from "react-intl";

export const messages = defineMessages({
  nameColumn: {
    id: "library.table.column.name",
    defaultMessage: "Name",
  },

  typeColumn: {
    id: "library.table.column.type",
    defaultMessage: "Type",
  },

  statusColumn: {
    id: "library.table.column.status",
    defaultMessage: "Status",
  },

  lastModificationDateColumn: {
    id: "library.table.column.lastModificationDate",
    defaultMessage: "Last modification",
  },

  tagsColumn: {
    id: "library.table.column.tags",
    defaultMessage: "Tags",
  },

  linksColumn: {
    id: "library.table.column.links",
    defaultMessage: "Links",
  },
});
