import { Heading } from "@trace-one/design-system";
import Status from "components/Status";
import { isStatusNotNone } from "utils/specifications";
import { SectionHeaderProps } from "./types";
import styles from "./styles.module.less";

const SectionHeader: React.FC<SectionHeaderProps> = ({
  sectionName,
  sectionStatus,
}) => {
  return (
    <div className={styles.header}>
      <Heading size="xxs" className={styles.title}>
        {sectionName}
      </Heading>
      {isStatusNotNone(sectionStatus) ? (
        <Status.Tag state={sectionStatus} />
      ) : null}
    </div>
  );
};
export default SectionHeader;
