import { useState } from "react";
import { TradeItemData } from "models";

const useSelectedProducts = () => {
  const [selectedProductIds, setSelectedProductIds] = useState<
    TradeItemData["id"][]
  >([]);

  const onSelectAllProducts = (assignedProducts: TradeItemData[]) => () => {
    setSelectedProductIds(previousSelectedProducts =>
      previousSelectedProducts.length === assignedProducts.length
        ? []
        : assignedProducts.map(({ id }) => id)
    );
  };

  const onSelectProduct = (productId: TradeItemData["id"]) => ({
    target: { checked },
  }) => {
    setSelectedProductIds(previousSelectedProductIds =>
      checked
        ? [...previousSelectedProductIds, productId]
        : previousSelectedProductIds.filter(id => id !== productId)
    );
  };

  const resetSelectedProducts = () => {
    setSelectedProductIds([]);
  };

  const isProductSelected = (productId: TradeItemData["id"]) =>
    selectedProductIds.findIndex(id => productId === id) > -1;

  return {
    selectedProductIds,
    onSelectAllProducts,
    onSelectProduct,
    resetSelectedProducts,
    isProductSelected,
  };
};

export default useSelectedProducts;
