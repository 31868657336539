import { persistor } from "store";
import { useRedirect } from "hooks";
import {
  LIBRARY_OBJECT_TYPES,
  LIBRARY_OBJECT_API_TYPES,
} from "utils/constants";
import { MenuActions } from "types/general";
import { getOnCreateActionClick } from "../../utils";

const useAction = (type: LIBRARY_OBJECT_TYPES) => {
  const { redirectToLibraryCreation } = useRedirect();

  const onCreateActionClick = getOnCreateActionClick({
    purge: persistor.purge,
    redirectToLibraryCreation,
  });

  let action: MenuActions = {
    onClick: onCreateActionClick(type),
    "data-test-id": `create-${LIBRARY_OBJECT_API_TYPES[type]}-option`,
  };

  return action;
};

export default useAction;
