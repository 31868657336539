import { LoadingPage } from "@trace-one/react-components";
import { useIntl } from "react-intl";
import { useWaitingDigitalSignature } from "./hooks";
import { messages } from "./messages";

const WaitingDigitalSignature: React.FC = () => {
  const { formatMessage } = useIntl();

  useWaitingDigitalSignature();

  return (
    <LoadingPage
      subtitle={formatMessage(messages.subtitle)}
      title={formatMessage(messages.title)}
    />
  );
};

export default WaitingDigitalSignature;
