import { useEffect, useState } from "react";
import { Radio } from "@trace-one/design-system";
import { useSelector } from "react-redux";
import { selectLanguageCode } from "store/user/selectors";
import { fetchReferenceListItems } from "apis/RLMD";
import { ReferenceListItemData } from "models";
import { ReflistRadioProps } from "./types";

const ReflistRadio: React.FC<ReflistRadioProps> = ({ refListId, ...rest }) => {
  const [referenceList, setReferenceList] = useState<ReferenceListItemData[]>(
    []
  );

  const languageCode = useSelector(selectLanguageCode);

  const getReferenceList = async () => {
    try {
      const { data } = await fetchReferenceListItems({
        languageCode,
        id: refListId,
      });

      setReferenceList(data.referenceListItems);
    } catch (e) {}
  };

  useEffect(() => {
    getReferenceList();
  }, [refListId]);

  return (
    <Radio.Group
      options={referenceList.map(({ id, text }) => ({
        label: text,
        value: id,
      }))}
      {...rest}
    />
  );
};

export default ReflistRadio;
