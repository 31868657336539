import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectUserPermissions } from "store/oidc/selectors";

const USER_PERMISSIONS = Object.freeze({
  chaptersCreate: "chapters.create",
  chapterTranslationManagement: "chapterTranslations.manage",
  chapterTranslationsRead: "chapterTranslations.read",
  librarysectionsRead: "librarysections.read",
  librarysectionsCreate: "librarysections.create",
  requirementsCreate: "requirements.create",
  requirementsUpdate: "requirements.update",
  requirementsRead: "requirements.read",
  specificationsRead: "specifications.read",
  specificationsCreate: "specifications.create",
  specificationsUpdate: "specifications.update",
  specificationsDuplicate: "specifications.duplicate",
  specificationsRequirementsUpdate: "specifications.requirements.update",
  specificationsAttachmentsUpdate: "specifications.attachments.update",
  specificationsValidate: "specifications.validate",
  specificationsShare: "specifications.share",
  specificationsSign: "specifications.sign",
  specificationsRevise: "specifications.revise",
  specificationsAcceptRevision: "specifications.acceptRevision",
  specificationsDelete: "specifications.delete",
  foldersCreate: "folders.create",
  foldersRead: "folders.read",
  foldersUpdate: "folders.update",
  foldersDelete: "folders.delete",
  sitesRead: "sites.read",
  productsRead: "products.read",
  productsWrite: "products.write",
  productsDelete: "products.delete",
  refListsRead: "refLists.read",
  refListsWrite: "refLists.write",
  refListsDelete: "refLists.delete",
  categoryListsRead: "categoryLists.read",
  categoryListsWrite: "categoryLists.write",
  categoryListsDelete: "categoryLists.delete",
  tagsCreate: "tags.create",
  tagsRead: "tags.read",
  packagingSystemsRead: "packagingSystems.read",
  packagingSystemsWrite: "packagingSystems.write",
  packagingComponentsRead: "packagingComponents.read",
  packagingComponentsWrite: "packagingComponents.write",
  materialsRead: "materials.read",
  materialsUpdate: "materials.update",
  materialsCreate: "materials.create",
  materialsRevise: "materials.revise",
  materialsUpdateName: "materials.update.name",
  materialsUpdateTags: "materials.update.tags",
  specificationsCancelRevision: "specifications.cancelRevision",
  collaborationsWrite: "collaborations.write",
  collaborationsRead: "collaborations.read",
  specificationsContactsUpdate: "specifications.contacts.update",
  usersResponsibilitiesWrite: "users.responsibilities.write",
  tagsUpdate: "tags.update",
  templatesCreate: "templates.create",
  claimsCreate: "claims.create",
  hasAccessToAdministration: "has.access.to.administration",
});

type UserPermissionObject = Record<keyof typeof USER_PERMISSIONS, boolean>;

function getPermissionsObject(permissionsList = []) {
  const hasPermission = permissionType =>
    permissionsList.includes(permissionType);

  return Object.fromEntries(
    Object.keys(USER_PERMISSIONS).map(key => [
      key,
      hasPermission(USER_PERMISSIONS[key]),
    ])
  ) as UserPermissionObject;
}

const useUserPermission = () => {
  const permissionsList = useSelector(selectUserPermissions);

  const permissions = useMemo(() => getPermissionsObject(permissionsList), [
    permissionsList,
  ]);

  return {
    ...permissions,
  };
};

export default useUserPermission;
