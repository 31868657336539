import styles from "./styles.module.less";

const LabellingBackground = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="621.551"
    height="66.901"
    viewBox="0 0 621.551 66.901"
  >
    <g transform="translate(0 -0.001)">
      <path
        className={styles.lightGreen}
        d="M0,120.923s41.64-17.406,156.048-14.785,185.209,21.575,301.585,25.272,163.918-10.487,163.918-10.487v51.848H0Z"
        transform="translate(0 -105.869)"
      />
      <path
        className={styles.green}
        d="M0,119.839s41.64-16.153,156.048-13.72,185.209,20.022,301.585,23.452,163.918-9.732,163.918-9.732v36.7H0Z"
        transform="translate(0 -89.635)"
      />
    </g>
  </svg>
);

export default LabellingBackground;
