import { useSelector } from "react-redux";
import { useAppDispatch } from "store";
import {
  updateName,
  addTag,
  removeTag,
  updateGtin,
} from "store/packagingSystemForm/asyncActions";
import { selectGeneralInfo } from "store/packagingSystemForm/selectors";
import { getParsedData } from "utils/general";

const useGeneralInfoActions = () => {
  const dispatch = useAppDispatch();
  const generalInfo = useSelector(selectGeneralInfo);

  const onUpdatePackagingSystemName = async (value: string) => {
    await dispatch(updateName(value));
  };

  const onUpdateTags = async (tagIds: string[]) => {
    const formDataTags = generalInfo?.tagIds || [];

    if (formDataTags?.length === tagIds.length) {
      return;
    }

    if (formDataTags?.length > tagIds.length) {
      const tagToRemove = formDataTags.find(
        currentTagId => !tagIds.includes(currentTagId)
      );

      await dispatch(removeTag(tagToRemove));
    } else {
      const tagToAdd = tagIds.find(
        currentTagId => !formDataTags.includes(currentTagId)
      );

      await dispatch(addTag(tagToAdd));
    }
  };

  const onSaveTemplateBlock = (templateBlockId: string) => (
    dataAsJson: string
  ) => {
    const formData = getParsedData(dataAsJson);

    const { displayGtin, displayedComponent } = formData;

    const updatedFormData = {
      displayGtin,
      displayedComponent: displayGtin ? displayedComponent : undefined,
    };

    dispatch(
      updateGtin({
        templateBlockId,
        dataAsJson: JSON.stringify(updatedFormData),
      })
    );
  };

  return {
    onUpdatePackagingSystemName,
    onUpdateTags,
    onSaveTemplateBlock,
  };
};

export default useGeneralInfoActions;
