import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "specification.actions.share",
    defaultMessage: "Share specification",
  },
  warningMessage: {
    id: "shareSpecification.warningMessage",
    defaultMessage: "Sharing this specification will",
  },
  firstWarning: {
    id: "shareSpecification.firstWarning",
    defaultMessage: "Notify every involved party.",
  },
  secondWarning: {
    id: "shareSpecification.secondWarning",
    defaultMessage:
      "Inform and invite the selected supplier to fill out the specification.",
  },
  shareQuestion: {
    id: "shareSpecification.shareQuestion",
    defaultMessage: "Share the specification?",
  },
  noBtn: {
    id: "general.no",
    defaultMessage: "No",
  },
  yesBtn: {
    id: "general.yes",
    defaultMessage: "Yes",
  },
  saving: {
    id: "creation.saving",
    defaultMessage: "Saving...",
  },
  sharing: {
    id: "creationFolder.summary.shareFolder.sharing",
    defaultMessage: "Sharing...",
  },
  errorMessage: {
    id: "creationFolder.summary.shareFolder.errorMessage",
    defaultMessage: "Sorry an error occured, please try again.",
  },
});
