import { useMemo } from "react";
import useRbac from "../useRbac";
import useUserInfo from "hooks/useUserInfo";
import { isLibraryObjectMaterialType } from "utils/library";
import { PERMISSIONS } from "utils/constants";
import { LibraryItemDetails } from "pages/Library/hocs/withLibraryItemDetails/types";

const useLibraryItemPermissions = (libraryItemDetails: LibraryItemDetails) => {
  const { permissions } = libraryItemDetails;
  const { hasPermission } = useRbac(permissions);
  const { isRetailer } = useUserInfo();

  const hasModifyPermission = useMemo(() => {
    const isMaterialObject = isLibraryObjectMaterialType(
      libraryItemDetails?.generalInformation?.type
    );

    if (isRetailer) {
      if (isMaterialObject) return false;

      return hasPermission(PERMISSIONS.REQUIREMENT.UPDATE);
    }

    return (
      hasPermission(PERMISSIONS.MATERIAL.UPDATE) ||
      hasPermission(PERMISSIONS.MATERIAL.UPDATE_NAME) ||
      hasPermission(PERMISSIONS.MATERIAL.UPDATE_TAGS) ||
      hasPermission(PERMISSIONS.MATERIAL.REVISE)
    );
  }, [permissions]);

  return {
    hasModifyPermission,
  };
};

export default useLibraryItemPermissions;
