import { Col, Row } from "antd";
import { Heading, Paragraph, Spinner } from "@trace-one/design-system";
import { FormattedMessage } from "react-intl";
import { messages } from "./messages";
import styles from "./styles.module.less";

const LoadingStepPanel = () => {
  return (
    <Row className={styles.centerText}>
      <Col xs={24} className={styles.spinner}>
        <Spinner size="large" />
      </Col>
      <Col xs={24}>
        <Heading size="xxs">
          <FormattedMessage {...messages.specificationsAreCreatedAndShared} />
        </Heading>
        <Paragraph>
          <FormattedMessage {...messages.doNotQuit} />
        </Paragraph>
      </Col>
    </Row>
  );
};

export default LoadingStepPanel;
