import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { Col, Row } from "antd";
import { Editor } from "@trace-one/react-components";
import { Heading, Icon, Paragraph, Button } from "@trace-one/design-system";
import ConfirmationTooltip from "components/ConfirmationTooltip";
import ConditionalWrapper from "components/ConditionalWrapper";
import { useBoolean, useInterval } from "hooks";
import { useAppDispatch } from "store";
import { setRequestModification } from "store/specificationDetails/specificationDetailsSlice";
import { selectRequestModification } from "store/specificationDetails/selectors";
import { getTimeSince } from "utils/modificationRequest";
import { isStatusCodeServerError } from "utils/httpUtils";
import generalMessages from "messages/general";
import { timeMessages } from "messages/time";
import { RequestModificationPopoverProps } from "./types";
import styles from "./styles.module.less";

export const REQUEST_MODIFICATION_MODAL_WIDTH = 388;

const RequestModificationPopover = ({
  onConfirm,
  onClose,
  onCancelRequest,
  editorContent,
  setEditorContent,
  userName,
  languageCode = "en-US",
  modalStyle,
  requestPopoverOptions,
  setRequestPopoverOptions,
  selectedRequest,
}: RequestModificationPopoverProps) => {
  const { requestId: selectedRequestId, createdAtUtc } = selectedRequest || {};
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const { isEditorOpen, detail: request } = useSelector(
    selectRequestModification
  );
  const [modalOpenTime] = useState(Date.now());
  const [timeSinceOpen, setTimeSinceOpen] = useState(
    selectedRequestId
      ? getTimeSince({
          elapsedTime: Date.now() - new Date(createdAtUtc).getTime(),
          formatMessage,
          languageCode,
        })
      : formatMessage(timeMessages.justNow)
  );
  const modalRef = useRef(null);
  const { value: isUpdating, setTrue, setFalse } = useBoolean(false);
  const { isReadOnly } = requestPopoverOptions || {};
  const {
    value: isRequestCancelling,
    setTrue: setIsRequestCancellingTrue,
    setFalse: setIsRequestCancellingFalse,
  } = useBoolean(false);

  useEffect(() => {
    const handleOutsideClick = event => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose?.();
        event.stopPropagation();
      }
    };

    document.addEventListener("click", handleOutsideClick, true);

    return () => {
      document.removeEventListener("click", handleOutsideClick, true);
      setRequestPopoverOptions(requestPopoverOptions => ({
        ...requestPopoverOptions,
        isReadOnly: false,
      }));
      dispatch(
        setRequestModification({
          detail: null,
          isEditorOpen: false,
        })
      );
    };
  }, []);

  useInterval(() => {
    setTimeSinceOpen(
      getTimeSince({
        elapsedTime:
          Date.now() -
          (selectedRequestId
            ? new Date(createdAtUtc).getTime()
            : modalOpenTime),
        formatMessage,
        languageCode,
      })
    );
  }, 1000);

  const handlePrimaryButtonClick = async () => {
    try {
      setTrue();
      await onConfirm?.();
      setEditorContent?.(null);
      onClose();
    } catch (error) {
      if (!isStatusCodeServerError(error?.statusCode)) {
        setEditorContent?.(null);
        onClose();
      }
    } finally {
      setFalse();
    }
  };

  const handleSecondaryButtonClick = async () => {
    try {
      setIsRequestCancellingTrue();
      await onCancelRequest?.();
      setEditorContent?.(null);
    } finally {
      setIsRequestCancellingFalse();
    }
  };

  return (
    <div
      ref={modalRef}
      className={styles["request-modification-modal"]}
      data-field-id="request-modification-modal"
      onMouseLeave={() => {
        if (!isEditorOpen) {
          setRequestPopoverOptions(request => ({
            ...request,
            isReadOnly: true,
          }));
          onClose();
        }
      }}
      onClick={event => {
        event.stopPropagation();

        setRequestPopoverOptions(request => ({
          ...request,
          isReadOnly: false,
        }));

        dispatch(
          setRequestModification({
            isEditorOpen: true,
          })
        );
      }}
      style={{
        ...modalStyle,
      }}
    >
      <Row align="middle" gutter={[8, 0]}>
        <Col>
          <Icon name="request-modification" size="large" color="amethyst" />
        </Col>
        <Col>
          <Heading size="xxs">{userName}</Heading>
          <Paragraph size="s">{timeSinceOpen}</Paragraph>
        </Col>
      </Row>

      <div className={styles["request-modification-modal--message"]}>
        <Col span={24}>
          {isReadOnly ? (
            <Paragraph
              size="s"
              dangerouslySetInnerHTML={{
                __html: editorContent,
              }}
            />
          ) : (
            <Editor
              name="message"
              initData={editorContent}
              onChange={setEditorContent}
              height={100}
              configProps={{
                toolbar: [
                  {
                    items: [
                      "Bold",
                      "Underline",
                      "Italic",
                      "BulletedList",
                      "NumberedList",
                    ],
                  },
                ],
                versionCheck: false, // added versionCheck to suppress upgrade warning; remove after upgrade
              }}
            />
          )}
        </Col>
      </div>
      {!isReadOnly && (
        <footer className={styles["request-modification-modal--footer"]}>
          <Row justify="end" gutter={[8, 0]}>
            <Col>
              <ConditionalWrapper
                condition={!!selectedRequestId}
                wrapper={children => (
                  <ConfirmationTooltip
                    onConfirmation={handleSecondaryButtonClick}
                    placement="top"
                    // @ts-ignore
                    getPopupContainer={trigger => trigger.parentNode}
                  >
                    {children}
                  </ConfirmationTooltip>
                )}
              >
                <Button
                  type="secondary"
                  color="amethyst"
                  disabled={isUpdating}
                  loading={isRequestCancelling}
                  onClick={!selectedRequestId ? onClose : null}
                >
                  {formatMessage(generalMessages.cancel)}
                </Button>
              </ConditionalWrapper>
            </Col>
            <Col>
              <Button
                type="primary"
                color="amethyst"
                onClick={handlePrimaryButtonClick}
                loading={isUpdating}
                disabled={!editorContent}
              >
                {formatMessage(
                  request ? generalMessages.update : generalMessages.add
                )}
              </Button>
            </Col>
          </Row>
        </footer>
      )}
    </div>
  );
};

export default RequestModificationPopover;
