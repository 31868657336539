import { Button, EmptyState, Modal } from "@trace-one/design-system";
import { Col, Row } from "antd";
import { useIntl } from "react-intl";
import PackagingSystemComponentMaterialTable from "./PackagingSystemComponentMaterialTable";
import MaterialForm from "components/Packaging/components/Recyclability/components/MaterialForm";
import { usePackagingSystemComponentMaterial } from "./hooks";
import { PackagingSystemComponentMaterialArrayFieldTemplateContext } from "./contexts";
import { isListEmpty, isObjectEmpty } from "utils/general";
import { PackagingSystemComponentMaterialArrayFieldTemplateProps } from "./types";
import { PackagingSystemComponentMaterialData } from "models";
import { messages } from "messages/packaging";
import styles from "./styles.module.less";

const PackagingSystemComponentMaterialArrayFieldTemplate: React.FC<PackagingSystemComponentMaterialArrayFieldTemplateProps> = ({
  formContext,
  schema,
  uiSchema,
}) => {
  const { formatMessage } = useIntl();

  const {
    formData,
    displayActions,
    templateBlockId,
    hasPermissionToSave,
  } = formContext;

  const {
    isModalOpen,
    setIsModalOpen,
    modalTitle,
    setModalTitle,
    materialFormData,
    setMaterialFormData,
    materialFormErrors,
    setMaterialFormErrors,
    materialTypeCategoryData,
    materialTypeExpandedKeys,
    onMaterialTypeTreeExpand,
    modalPrimaryButtonText,
    setModalPrimaryButtonText,
    getIsPrimaryModalButtonDisabled,
    tableUiSchema,
    prevMaterialFormDataRef,
    onConfirm,
    onDeleteMaterial,
    resetMaterialForm,
  } = usePackagingSystemComponentMaterial({
    formData,
    schema,
    uiSchema,
    templateBlockId,
  });

  return (
    <PackagingSystemComponentMaterialArrayFieldTemplateContext.Provider
      value={{
        displayActions,
        materialTypes: materialTypeCategoryData,
        materialFormData,
        setMaterialFormData,
        materialFormErrors,
        setMaterialFormErrors,
        isMaterialFormModalOpen: isModalOpen,
        setIsMaterialFormModalOpen: setIsModalOpen,
        setMaterialFormModalTitle: setModalTitle,
        setMaterialModalPrimaryButtonText: setModalPrimaryButtonText,
        onDeleteMaterial,
        prevMaterialFormDataRef,
      }}
    >
      {!isObjectEmpty(formData) &&
      !isListEmpty(
        formData.materials as PackagingSystemComponentMaterialData[]
      ) ? (
        <PackagingSystemComponentMaterialTable
          uiSchema={tableUiSchema}
          formData={
            formData.materials as PackagingSystemComponentMaterialData[]
          }
        />
      ) : null}

      {isListEmpty(
        formData.materials as PackagingSystemComponentMaterialData[]
      ) ? (
        <Row justify="center">
          <EmptyState heading={formatMessage(messages.noMaterials)} />
        </Row>
      ) : null}

      <Row justify="center" className={styles.addMaterialButton}>
        <Col>
          <Button
            data-testid="add-packaging-component-material-btn"
            type="primary"
            disabled={!hasPermissionToSave}
            onClick={() => {
              prevMaterialFormDataRef.current = null;
              setModalPrimaryButtonText(formatMessage(messages.confirm));
              setModalTitle(formatMessage(messages.addMaterial));
              setIsModalOpen(true);
            }}
          >
            {formatMessage(messages.addMaterial)}
          </Button>
        </Col>
      </Row>

      <Modal.Simple
        size="s"
        title={modalTitle}
        open={isModalOpen}
        onCancel={resetMaterialForm}
        primaryButtonText={modalPrimaryButtonText}
        secondaryButtonText={formatMessage(messages.cancel)}
        onPrimaryButtonClick={onConfirm}
        primaryButtonProps={{ disabled: getIsPrimaryModalButtonDisabled() }}
        onSecondaryButtonClick={resetMaterialForm}
      >
        <MaterialForm
          uiSchema={tableUiSchema}
          materialTypeCategoryData={materialTypeCategoryData}
          materialTypeExpandedKeys={materialTypeExpandedKeys}
          onMaterialTypeTreeExpand={onMaterialTypeTreeExpand}
        />
      </Modal.Simple>
    </PackagingSystemComponentMaterialArrayFieldTemplateContext.Provider>
  );
};

export default PackagingSystemComponentMaterialArrayFieldTemplate;
