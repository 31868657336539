import React, { useContext } from "react";
import { useIntl } from "react-intl";
import { Select } from "@trace-one/design-system";
import { HistorisationContext } from "components/SpecificationHistorisationModal/contexts";
import {
  filterMessages,
  columnMessages,
} from "components/SpecificationHistorisationModal/messages";
import { ActionProps } from "./types";

const Action: React.FC<ActionProps> = ({
  selectedActions,
  setSelectedActions,
  ...rest
}) => {
  const { formatMessage } = useIntl();

  const { actions, isLoadingActions, hasErrorActions } = useContext(
    HistorisationContext
  );

  return (
    <Select
      showArrow
      labelInValue
      mode="multiple"
      name="action"
      label={formatMessage(columnMessages.action)}
      placeholder={formatMessage(columnMessages.action)}
      value={selectedActions}
      onChange={setSelectedActions}
      options={actions.map(action => ({
        value: action.value,
        name: action.translation,
      }))}
      loading={isLoadingActions}
      {...(hasErrorActions && {
        errorMessage: formatMessage(filterMessages.actionError),
        error: true,
      })}
      {...rest}
    />
  );
};

export default Action;
