import { useIntl } from "react-intl";
import { messages } from "pages/Specification/components/Details/messages";

const useFormSchemaTranslation = () => {
  const intl = useIntl();

  return {
    getTranslationForKey: (key, values) =>
      messages[key] ? intl.formatMessage(messages[key], values) : key,
  };
};

export default useFormSchemaTranslation;
