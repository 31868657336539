import { FormattedMessage, useIntl } from "react-intl";
import { ToggleButtons } from "@trace-one/design-system";
import { LIBRARY_REQUIREMENT_TYPES } from "utils/constants";
import ConfirmationModal from "components/ConfirmationModal";
import { useRequirementTypeToggle } from "./hooks";
import { useRequirementInfoActions } from "../../hooks";
import generalMessages from "messages/general";
import libraryMessages from "messages/library";
import { messages } from "./messages";
import { RequirementTypeProps } from "./types";

const RequirementType: React.FC<RequirementTypeProps> = ({
  requirementType,
  isLocked,
  disableActions,
}) => {
  const { formatMessage } = useIntl();
  const { onChangeType } = useRequirementInfoActions();
  const {
    isModalOpen,
    desiredType,
    onConfirmModal,
    onCloseModal,
    onToggle,
  } = useRequirementTypeToggle({
    requirementType,
    onChangeType,
  });

  return (
    <>
      <ToggleButtons
        value={desiredType}
        options={[
          {
            iconName: "text",
            name: formatMessage(libraryMessages.typeText),
            value: LIBRARY_REQUIREMENT_TYPES.TEXT,
            "data-testid": "requirement-type-text",
            "data-test-id": "requirement-type-text",
          },
          {
            iconName: "file",
            name: formatMessage(libraryMessages.typeDocument),
            value: LIBRARY_REQUIREMENT_TYPES.DOCUMENT,
            "data-testid": "requirement-type-document",
            "data-test-id": "requirement-type-document",
          },
        ]}
        disabled={disableActions || isLocked}
        onChange={onToggle}
      />

      {isModalOpen && (
        <ConfirmationModal
          onConfirmModal={onConfirmModal}
          onCloseModal={onCloseModal}
          title={
            <FormattedMessage {...messages.requirementTypeModificationTitle} />
          }
          children={
            <>
              <FormattedMessage
                {...messages.requirementModificationDescription}
              />
              <br />
              <FormattedMessage
                {...messages.requirementModificationStartOverDescription}
              />
            </>
          }
          primaryButtonText={formatMessage(generalMessages.confirm)}
        />
      )}
    </>
  );
};

export default RequirementType;
