import { useIntl } from "react-intl";
import { Paragraph } from "@trace-one/design-system";
import { Row } from "antd";
import Spin from "components/Spin";
import Panel from "./components/Panel";
import { ResultTableProps } from "./types";
import { isListEmpty } from "utils/general";
import { isSupplierSelected } from "components/SelectSupplierModal/utils";
import { generalMessages } from "messages";
import styles from "./styles.module.less";

export const ResultTable: React.FC<ResultTableProps> = ({
  selectedSuppliers,
  onSelectSupplier,
  supplierList,
  isLoading,
}) => {
  const { formatMessage } = useIntl();

  if (isLoading) return <Spin />;

  return (
    <div className={styles.scrollableContainer}>
      {!isListEmpty(supplierList) ? (
        supplierList.map(item => (
          <Panel
            key={item.supplierId}
            title={item.supplierName}
            selected={isSupplierSelected(item.supplierId, selectedSuppliers)}
            onSelect={() => {
              onSelectSupplier(item);
            }}
          />
        ))
      ) : (
        <Row justify="center">
          <Paragraph>{formatMessage(generalMessages.noResultsFound)}</Paragraph>
        </Row>
      )}
    </div>
  );
};

export default ResultTable;
