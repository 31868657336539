import { Col, Row } from "antd";
import {
  ModalService,
  ModalProductCreate,
  ModalProductSelect,
} from "@trace-one/react-components";
import { Button } from "@trace-one/design-system";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { searchCompanies } from "apis/CUMD";
import {
  fetchCategoryLists,
  fetchCategoryListHierarchy,
  fetchReferenceListItemsByName,
} from "apis/RLMD";
import { createTradeItem, createManufacturedItem, fetchBrands } from "apis/PMD";
import {
  selectLanguageCode,
  selectOwningCompanyId,
} from "store/user/selectors";
import { PORTAIL_ID } from "utils/constants";
import { getOnCloseAddExistingProductsModal } from "./utils";
import { messages } from "../messages";
import styles from "./styles.module.less";
import { AddButtonsProps } from "./types";

const AddButtons: React.FC<AddButtonsProps> = ({
  alreadySelectedProductIds,
  areButtonsDisabled,
  onSaveProducts,
  maxNumberOfProducts,
  modalDescription,
}) => {
  const companyId = useSelector(selectOwningCompanyId);
  const languageCode = useSelector(selectLanguageCode);

  const onCreateProductClick = async () => {
    ModalService.openModal({
      component: (
        <ModalProductCreate
          companyId={companyId}
          languageCode={languageCode}
          referenceListAPIRoutes={{
            getCategorylists: fetchCategoryLists,
            getCategorylistsHierarchyById: fetchCategoryListHierarchy,
            fetchReferenceListItemsByName,
          }}
          productAPIRoutes={{
            createTradeItem,
            createManufacturedItem,
            getBrands: fetchBrands,
          }}
          cumdAPIRoutes={{
            searchCompanies,
          }}
          onSaveProducts={onSaveProducts}
          maxNumberOfProducts={maxNumberOfProducts}
          canAddManufItems={false}
        />
      ),
    });
  };

  const onAddExistingProductClick = async () => {
    ModalService.openModal({
      component: (
        <ModalProductSelect
          isPossibleToAddManufacturedItems={false}
          hideSupplierFilter={true}
          companyId={companyId}
          languageCode={languageCode}
          portalTypeId={PORTAIL_ID}
          alreadySelectedProducts={alreadySelectedProductIds}
          modalDescription={modalDescription}
          hideProductsBasket={maxNumberOfProducts === 1}
        />
      ),
      onClose: getOnCloseAddExistingProductsModal({
        onSaveProducts,
      }),
    });
  };

  return (
    <Row justify="center" gutter={[16, 0]} className={styles.addButtons}>
      <Col>
        <Button
          type="secondary"
          onClick={onCreateProductClick}
          data-testid="create-new-product-button"
          disabled={areButtonsDisabled}
        >
          <FormattedMessage {...messages.createNewProduct} />
        </Button>
      </Col>
      <Col>
        <Button
          type="secondary"
          onClick={onAddExistingProductClick}
          data-testid="add-existing-product-button"
          disabled={areButtonsDisabled}
        >
          <FormattedMessage {...messages.addExisitingProduct} />
        </Button>
      </Col>
    </Row>
  );
};

export default AddButtons;
