import { defineMessages } from "react-intl";

export const messages = defineMessages({
  saveComment: {
    id: "template.userGuide.modal.save",
    defaultMessage: "Save",
  },
  cancelComment: {
    id: "template.userGuide.modal.cancel",
    defaultMessage: "Cancel",
  },
  title: {
    id: "template.userGuide.modal.title",
    defaultMessage: "User guide",
  },
  subtitle: {
    id: "template.userGuide.modal.subtitle",
    defaultMessage:
      "You can enter a full user guide here, options are available for advanced styling to make it easily readable for your suppliers. It will be available to them on every specification based on this template, that you will collaborate on.",
  },
});
