import { defineMessages } from "react-intl";

export const columnMessages = defineMessages({
  chapter: {
    id: "specification.history.columns.chapter",
    defaultMessage: "Chapter",
  },
  section: {
    id: "specification.history.columns.section",
    defaultMessage: "Section",
  },
  field: {
    id: "specification.history.columns.field",
    defaultMessage: "Field",
  },
  action: {
    id: "specification.history.columns.action",
    defaultMessage: "Action",
  },
  lastValue: {
    id: "specification.history.columns.lastValue",
    defaultMessage: "Last value",
  },
  newValue: {
    id: "specification.history.columns.newValue",
    defaultMessage: "New value",
  },
  userName: {
    id: "specification.history.columns.userName",
    defaultMessage: "User name",
  },
  modificationDate: {
    id: "specification.history.columns.modificationDate",
    defaultMessage: "Modification date",
  },
});

export const messages = defineMessages({
  results: {
    id: "specification.history.results",
    defaultMessage: "Results",
  },
  history: {
    id: "specification.actions.history",
    defaultMessage: "History",
  },
  choosePeriod: {
    id: "specification.actions.history.selectPeriod",
    defaultMessage: "Please select a period to see a specific data history",
  },
  export: {
    id: "specification.history.export",
    defaultMessage: "Export",
  },
});

export const filterMessages = defineMessages({
  moreFilters: {
    id: "specification.history.filters.moreFilters",
    defaultMessage: "More Filters",
  },
  hideFilters: {
    id: "filters.hideFilters",
    defaultMessage: "Hide filters",
  },
  usernameError: {
    id: "specification.history.filters.username.error",
    defaultMessage: "Error, can't load person data",
  },
  actionError: {
    id: "specification.history.filters.action.error",
    defaultMessage: "Error, can't load action data",
  },
  clearAllFilters: {
    id: "filters.clearAllFilters",
    defaultMessage: "Clear all filters",
  },
  chapters: {
    id: "specification.history.filters.chapters",
    defaultMessage: "Chapter / Section / Field",
  },
});
