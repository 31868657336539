import { defineMessages } from "react-intl";

const rawMaterialFormMessages = defineMessages({
  noData: {
    id: "library.rawMaterialForm.allergens.noData",
    defaultMessage: "You can start to add allergens with the button bellow",
  },

  noAllergens: {
    id: "library.rawMaterialForm.allergens.noAllergen",
    defaultMessage: "No allergen",
  },
});

export default rawMaterialFormMessages;
