import { Button } from "@trace-one/design-system";
import ConfirmationTooltip from "components/ConfirmationTooltip";
import { useActions } from "./hooks";
import { PackagingSystemComponentMaterialData } from "models";

const Actions = ({
  packagingSystemComponentMaterial,
}: {
  packagingSystemComponentMaterial: PackagingSystemComponentMaterialData;
}) => {
  const {
    actions,
    deleteMaterial,
    isPopoverVisible,
    setIsPopoverVisible,
    onVisibleChange,
  } = useActions(packagingSystemComponentMaterial);

  return (
    <ConfirmationTooltip
      //@ts-ignore
      open={isPopoverVisible}
      onConfirmation={deleteMaterial}
      onCancel={() => setIsPopoverVisible(false)}
      onOpenChange={onVisibleChange}
    >
      <Button type="tertiary" iconName="kebab" color="grey" items={actions} />
    </ConfirmationTooltip>
  );
};

export default Actions;
