import { useSelector } from "react-redux";
import {
  addTag,
  removeTag,
  updateName,
  updateRecipe,
  updateStatus,
  updateInternalCode,
  updateType,
} from "store/materialForm/asyncActions";
import { selectMaterialInformation } from "store/materialForm/selectors";
import { useAppDispatch } from "store";
import { MaterialData } from "models";
import { RadioChangeEvent } from "antd";
import { MaterialType } from "types/library";

const useMaterialInfoActions = () => {
  const dispatch = useAppDispatch();
  const materialInformation = useSelector(selectMaterialInformation);

  const onSaveName = async (value: MaterialData["name"]) => {
    dispatch(updateName(value));
  };

  const onSaveTags = async (tagIds: string[]) => {
    const formDataTags = materialInformation?.tagIds || [];

    if (formDataTags?.length === tagIds.length) {
      return;
    }

    if (formDataTags?.length > tagIds.length) {
      const tagToRemove = formDataTags.find(
        currentTagId => !tagIds.includes(currentTagId)
      );

      await dispatch(removeTag(tagToRemove));
    } else {
      const tagToAdd = tagIds.find(
        currentTagId => !formDataTags.includes(currentTagId)
      );

      await dispatch(addTag(tagToAdd));
    }
  };

  const onChangeStatus = async ({ target: { value } }: RadioChangeEvent) => {
    dispatch(updateStatus(value));
  };

  const onChangeInternalCode = async (value: MaterialData["internalCode"]) => {
    if (value === "") {
      value = null;
    }

    dispatch(updateInternalCode(value));
  };

  const onChangeType = async (value: MaterialType) => {
    dispatch(updateType(value));
  };

  const onChangeRecipe = async ({ target: { value } }: RadioChangeEvent) => {
    dispatch(updateRecipe(value));
  };

  return {
    onChangeStatus,
    onChangeInternalCode,
    onSaveName,
    onSaveTags,
    onChangeType,
    onChangeRecipe,
  };
};

export default useMaterialInfoActions;
