import { useState } from "react";

const useRowSelection = (itemKey = "specificationId") => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);

  const onSelectRow = (record: { [key: string]: any }, selected: boolean) => {
    if (selected) {
      setSelectedRowKeys(previousSelectedRowKeys => [
        ...previousSelectedRowKeys,
        record?.[itemKey],
      ]);
    } else {
      setSelectedRowKeys(previousSelectedRowKeys =>
        previousSelectedRowKeys.filter(id => id !== record?.[itemKey])
      );
    }
  };

  const onSelectAllRows = (
    selected: boolean,
    _,
    changeRows: { [key: string]: any }[]
  ) => {
    const keys = changeRows.map(changeRow => changeRow?.[itemKey]);

    if (selected) {
      setSelectedRowKeys(previousSelectedRowKeys => [
        ...previousSelectedRowKeys,
        ...keys,
      ]);
    } else {
      setSelectedRowKeys(previousSelectedRowKeys =>
        previousSelectedRowKeys.filter(id => !keys.includes(id))
      );
    }
  };

  const resetSelectedRowKeys = () => {
    setSelectedRowKeys([]);
  };

  return {
    selectedRowKeys,
    onSelectRow,
    onSelectAllRows,
    resetSelectedRowKeys,
  };
};

export default useRowSelection;
