import React, { useMemo } from "react";
import { Col, Row } from "antd";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { EmptyState } from "@trace-one/design-system";
import { UploadFiles } from "@trace-one/business-components";
import RowTitle from "components/RowTitle";
import { useMaterialDocumentsUpload } from "./hooks";
import { selectMaterialInformation } from "store/materialForm/selectors";
import { isListEmpty } from "utils/general";
import messages from "messages/documents";
import { File } from "models";

export interface MaterialDocumentUploadProps {
  files?: File[]; // Specific files, Either technical or other
  allFileIds?: string[]; // All attachments, technial and other documents, need this because BE required to send all filesId in each section
  displayActions?: boolean;
  title: string;
  fileType: string;
}

const MaterialDocumentUpload: React.FC<MaterialDocumentUploadProps> = ({
  displayActions,
  title,
  fileType,
  files,
  allFileIds,
}) => {
  const materialInformation = useSelector(selectMaterialInformation);
  const { formatMessage } = useIntl();
  const fileIds = useMemo(() => {
    return files.map(file => file.id);
  }, [files]);

  const { onUploadFiles, onRemoveFile } = useMaterialDocumentsUpload({
    fileIds: allFileIds,
  });

  return (
    <>
      <RowTitle title={title} />
      {!displayActions ? (
        isListEmpty(files) ? (
          <Row justify="center">
            <EmptyState heading={formatMessage(messages.noAttachment)} />
          </Row>
        ) : (
          <Row>
            <Col
              {...{
                xs: 24,
                sm: 12,
                md: 8,
              }}
            >
              <UploadFiles.List fileList={files} />
            </Col>
          </Row>
        )
      ) : (
        <UploadFiles.Dragger
          fileType={fileType}
          fileList={files}
          multiple
          onUploadFiles={onUploadFiles}
          onRemove={onRemoveFile}
          ownerCompanyId={materialInformation?.ownerCompanyId}
          disabledFileIds={fileIds}
        />
      )}
    </>
  );
};

export default MaterialDocumentUpload;
