import React from "react";
import cx from "classnames";
import { Heading, Paragraph } from "@trace-one/design-system";
import { PanelProps } from "./types";
import styles from "./styles.module.less";

const Panel: React.FC<PanelProps> = ({
  title,
  subTitle,
  selected,
  onSelect,
}) => {
  return (
    <div
      //@ts-ignore
      onClick={onSelect}
      className={cx(styles.panelSupplier, {
        [styles.isSelected]: selected,
      })}
      data-test-id="supplier-panel"
    >
      <Heading size="xs" data-test-id="supplier-panel-title">
        {title}
      </Heading>
      <Paragraph data-test-id="supplier-subtitle">{subTitle}</Paragraph>
    </div>
  );
};

export default Panel;
