import classNames from "classnames";
import { Card } from "@trace-one/react-components";
import { Heading } from "@trace-one/design-system";
import { isListEmpty, isObjectEmpty } from "utils/general";
import SectionContent from "./components/SectionContent";
import { SubSectionProps } from "./types";
import styles from "./styles.module.less";

const SubSection: React.FC<SubSectionProps> = ({
  subSection,
  currentSectionsRefs,
}) => {
  if (isObjectEmpty(subSection)) return null;

  if (isListEmpty(subSection.blocks)) return null;

  const { sectionId, sectionName, sectionStatus, blocks } = subSection;

  return (
    <Card
      collapsible
      expand
      className={classNames(
        styles.header,
        styles[sectionStatus?.toLowerCase()]
      )}
      title={
        <span
          data-testid="subsection-name"
          ref={currentSectionsRefs[sectionId]}
        >
          <Heading size="s">{sectionName}</Heading>
        </span>
      }
    >
      <SectionContent
        sectionId={sectionId}
        blocks={blocks}
        sectionStatus={sectionStatus}
      />
    </Card>
  );
};

export default SubSection;
