import { Row, Col } from "antd";
import dayjs from "dayjs";
import { Heading, Paragraph } from "@trace-one/design-system";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { getMonthsForSeasonalityWidget } from "components/SpecificationSection/components/FormSchema/utils";
import { RoSeasonalityProps } from "./types";
import styles from "./styles.module.less";

const RoSeasonality: React.FC<RoSeasonalityProps> = ({
  startDateUtc,
  endDateUtc,
}) => {
  const { getTranslationForKey } = useFormSchemaTranslation();

  if (!startDateUtc || !endDateUtc) {
    return (
      <Col>
        <div>{getTranslationForKey("none")}</div>
      </Col>
    );
  }

  const startDate = dayjs(startDateUtc);
  const endDate = dayjs(endDateUtc);

  const months: Array<{
    id: string;
    value: string;
    activeClass: string;
  }> = getMonthsForSeasonalityWidget({
    startDate,
    endDate,
    styles,
  });

  return (
    <>
      <Col>
        <div>
          <Heading size="xxs" className={styles.dateAvailability}>
            {startDate.format("MMMM DD")}{" "}
          </Heading>
          {getTranslationForKey("to")}
          <Heading size="xxs" className={styles.dateAvailability}>
            {" "}
            {endDate.format("MMMM DD")}
          </Heading>
        </div>
      </Col>
      <Row gutter={[5, 5]} className={styles.seasonalContainer}>
        {months.map(month => {
          const { activeClass, value } = month;

          return (
            <Col key={value} span={2} className={styles.wrapper}>
              <Paragraph size="xs">{getTranslationForKey(value)}</Paragraph>
              <div className={activeClass} />
            </Col>
          );
        })}
      </Row>
    </>
  );
};

export default RoSeasonality;
