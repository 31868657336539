import { Heading, Paragraph } from "@trace-one/design-system";
import { Row, Col } from "antd";
import { useIntl, FormattedMessage } from "react-intl";
import Spin from "components/Spin";
import { useSuppliers, useCollaborations, useValidation } from "./hooks";
import { EnableCollaboration, Collaborations } from "./components";
import { messages } from "./messages";
import { CreateCollaborationProps } from "./types";
import styles from "./styles.module.less";

const CreateCollaboration: React.FC<CreateCollaborationProps> = ({
  applicationId,
  parentDataObjectId,
  parentDataObjectSystem,
  roleIds,
  companyIdsWithLinkedProducts,
  responsibilityIds,
  onCreatedCollaboration,
  onRemoveCollaborations,
  existingCollaborationIds,
  onChangeValidation,
  isLoading,
  hideSupplierBar,
  skipRemoveCollaborationAPI,
  hideCollaborationActions,
  onChangeEnableSupplierCollaboration,
  hideHeading,
  hideRemoveCollaboration,
  disableCompanyFetch,
  hideEnableCollaboration,
  productLinkedMessage,
  ignoreApplicationTypeIds,
}) => {
  const { formatMessage } = useIntl();

  const {
    collaborations,
    handleSelectUser,
    handleCreateCollaboration,
    handleRemoveCollaborations,
    handleAddCollaboration,
  } = useCollaborations({
    responsibilityIds: responsibilityIds,
    collaboratingCompanyIds: companyIdsWithLinkedProducts.map(
      ({ collaboratingCompanyId }) => collaboratingCompanyId
    ),
    applicationId,
    parentDataObjectId,
    parentDataObjectSystem,
    roleIds,
    onCreatedCollaboration,
    onRemoveCollaborations,
    existingCollaborationIds,
    skipRemoveCollaborationAPI,
  });

  const { companyList, handleChangeSupplierCollaboration } = useSuppliers({
    companyIdsWithLinkedProducts,
    hideSupplierBar,
    onChangeEnableSupplierCollaboration,
    disableCompanyFetch,
  });

  useValidation({ collaborations, companyList, onChangeValidation });

  const filteredCollaborationsBySupplier = (collaboratingCompanyId: string) =>
    collaborations.filter(
      collaboration =>
        collaboratingCompanyId === collaboration.collaboratingCompanyId
    );

  return (
    <>
      <Spin spinning={isLoading}>
        {hideSupplierBar || hideHeading ? null : (
          <Heading size="s" color="primary" className={styles.title}>
            {formatMessage(messages.title)}
          </Heading>
        )}

        {companyList.map(company => (
          <Row
            className={styles.supplierRow}
            justify="space-between"
            key={company.collaboratingCompanyId}
          >
            {hideSupplierBar ? null : (
              <>
                <Col xs={10}>
                  <Row>
                    <Col xs={10}>
                      <Paragraph>{company.name}</Paragraph>
                    </Col>
                    <Col>
                      <Paragraph size="s" color="primary">
                        <FormattedMessage
                          values={{ count: company.numberOfLinkedProducts }}
                          {...(productLinkedMessage ?? messages.productLinked)}
                        />
                      </Paragraph>
                    </Col>
                  </Row>
                </Col>
                {!hideEnableCollaboration && (
                  <Col>
                    <Row>
                      <EnableCollaboration
                        onChange={handleChangeSupplierCollaboration(
                          company.collaboratingCompanyId
                        )}
                        checked={company.isCollaborationEnabled}
                        collaborationIds={filteredCollaborationsBySupplier(
                          company.collaboratingCompanyId
                        ).map(({ id }) => id)}
                        onRemoveCollaborations={handleRemoveCollaborations}
                      />
                    </Row>
                  </Col>
                )}
              </>
            )}
            {company.isCollaborationEnabled && (
              <Collaborations
                collaborations={filteredCollaborationsBySupplier(
                  company.collaboratingCompanyId
                )}
                collaboratingCompanyId={company.collaboratingCompanyId}
                onSelectUser={handleSelectUser}
                onCreateCollaboration={handleCreateCollaboration}
                onRemoveCollaborations={handleRemoveCollaborations}
                onAddCollaboration={handleAddCollaboration}
                hideCollaborationActions={hideCollaborationActions}
                isCollaborationEnabled={company.isCollaborationEnabled}
                hideRemoveCollaboration={hideRemoveCollaboration}
                ignoreApplicationTypeIds={ignoreApplicationTypeIds}
              />
            )}
          </Row>
        ))}
      </Spin>
    </>
  );
};

export default CreateCollaboration;
