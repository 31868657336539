import { useSelector } from "react-redux";
import { toaster as toasterService } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import {
  createChapterVersion,
  deleteChapterType,
  duplicateChapter,
  publishChapter,
  shareChapter,
} from "apis/SPEC";
import { useAppDispatch } from "store";
import {
  selectLanguageCode,
  selectOwningCompanyId,
} from "store/user/selectors";
import { getChapter } from "store/chapterForm/asyncActions";
import { setFormData } from "store/chapterForm/chapterFormSlice";
import initialState from "store/chapterForm/initialState";
import { useRedirect } from "hooks";
import {
  isLibraryObjectCreatePage,
  isLibraryObjectEditPage,
} from "utils/library";
import { generateListPath } from "utils/general";
import { CHAPTER_TYPES_API, LIBRARY_OBJECT_TYPES } from "utils/constants";
import libraryMessages from "messages/library";
import { useChapterFormActionsProps } from "./types";

const useChapterFormActions = (
  {
    chapterId,
    chapterType,
    chapterName,
    validateFields,
    isNewVersion,
    previousVersionChapterId,
    page,
  }: useChapterFormActionsProps = { validateFields: () => {} }
) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();

  const companyId = useSelector(selectOwningCompanyId);
  const languageCode = useSelector(selectLanguageCode);

  const {
    redirectToLibraryListing,
    redirectToLibraryEdition,
    redirectToLibraryCreateNewVersion,
    redirectToLibraryDetails,
  } = useRedirect();

  const keepAsDraftAction = async () => {
    try {
      // if there are validation errors it will show them and go into the catch statement
      await validateFields?.();

      await shareChapter({
        chapterId,
        chapterType: CHAPTER_TYPES_API[chapterType],
        companyId,
      });
    } catch (e) {
      throw e;
    }
  };

  const onKeepAsDraftClick = async () => {
    try {
      await keepAsDraftAction();

      redirectToLibraryListing(LIBRARY_OBJECT_TYPES.CHAPTERS, {
        skipDetectLeavePage: true,
      });
    } catch (e) {}
  };

  const onPublishChapter = async () => {
    try {
      await publishChapter({
        chapterId,
        chapterType: CHAPTER_TYPES_API[chapterType],
      });

      if (isNewVersion) {
        redirectToLibraryEdition({
          id: chapterId,
          type: CHAPTER_TYPES_API[chapterType],
          pushState: {
            skipDetectLeavePage: true,
            shouldRefetch: true,
          },
        });
      } else {
        if (page && isLibraryObjectCreatePage(page)) {
          redirectToLibraryListing(LIBRARY_OBJECT_TYPES.CHAPTERS, {
            skipDetectLeavePage: true,
          });
        } else if (page && isLibraryObjectEditPage(page)) {
          redirectToLibraryDetails({
            id: chapterId,
            type: CHAPTER_TYPES_API[chapterType],
            pushState: {
              skipDetectLeavePage: true,
            },
          });
        }
        dispatch(getChapter());
      }
    } catch (_) {}
  };

  const onDeleteChapter = async (intendedLeavingPage: string = null) => {
    try {
      await deleteChapterType({
        chapterId: chapterId,
        chapterType: CHAPTER_TYPES_API[chapterType],
      });

      if (
        isNewVersion &&
        previousVersionChapterId &&
        intendedLeavingPage?.includes(
          generateListPath(LIBRARY_OBJECT_TYPES.CHAPTERS)
        )
      ) {
        redirectToLibraryDetails({
          id: previousVersionChapterId,
          type: CHAPTER_TYPES_API[chapterType],
        });
      }

      dispatch(setFormData(initialState));
    } catch (e) {
      throw e;
    }
  };

  const onCreateNewVersion = async () => {
    try {
      const { data: id } = await createChapterVersion({
        chapterId,
        chapterType: CHAPTER_TYPES_API[chapterType],
        params: {
          languageCode,
        },
      });

      redirectToLibraryCreateNewVersion({
        id,
        type: CHAPTER_TYPES_API[chapterType],
        queryParameters: {
          prevVersionId: chapterId,
        },
      });

      toasterService.open({
        message: formatMessage(libraryMessages.createNewVersionTitle),
        description: formatMessage(
          libraryMessages.createNewVersionDescription,
          {
            libraryItemName: chapterName,
          }
        ),
        type: "confirmation",
      });
    } catch (_) {}
  };

  const onDuplicateChapter = async () => {
    try {
      const { data } = await duplicateChapter({
        chapterId,
        chapterType: CHAPTER_TYPES_API[chapterType],
        params: { languageCode },
      });

      redirectToLibraryEdition({
        id: data?.generalInfo?.id,
        type: CHAPTER_TYPES_API[chapterType],
      });

      toasterService.open({
        message: formatMessage(libraryMessages.chapterDuplicateTitle),
        description: formatMessage(
          libraryMessages.chapterDuplicateDescription,
          {
            chapterName: chapterName,
          }
        ),
        type: "confirmation",
      });
    } catch (_) {}
  };

  return {
    keepAsDraftAction,
    onKeepAsDraftClick,
    onPublishChapter,
    onDeleteChapter,
    onCreateNewVersion,
    onDuplicateChapter,
  };
};

export default useChapterFormActions;
