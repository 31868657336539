import { isListEmpty } from "utils/general";
import { MaterialRecipeIngredientViewModel } from "viewModels";

export const getAllIngredientIds = (
  ingredients: MaterialRecipeIngredientViewModel[]
): string[] =>
  ingredients.reduce((previousState, { ingredientId, ingredients }) => {
    let newState: string[] = [...previousState, ingredientId];

    if (!isListEmpty(ingredients)) {
      newState.push.apply(newState, getAllIngredientIds(ingredients));
    }

    return newState;
  }, []);
