import { defineMessages } from "react-intl";

export const messages = defineMessages({
  detailsSectionTitle: {
    id: "creationFolder.details.title",
    defaultMessage: "Folder details",
  },
  detailsSectionSubtitle: {
    id: "creationFolder.summary.detailsSubtitle",
    defaultMessage: "Basic information about your folder",
  },
  assignedProductsSectionTitle: {
    id: "creationFolder.summary.assignedProductsTitle",
    defaultMessage: "Assigned products",
  },
  assignedProductsSectionSubtitle: {
    id: "creationFolder.summary.assignedProductsSubtitle",
    defaultMessage: "Summary of your product selection",
  },
  structureSectionTitle: {
    id: "creationFolder.spec",
    defaultMessage: "Structure",
  },
  structureSectionSubtitle: {
    id: "creationFolder.spec.description",
    defaultMessage: "Chapters and sections of specification",
  },
  requirementsSectionTitle: {
    id: "creationFolder.spec.requirements.title",
    defaultMessage: "Requirements",
  },
  requirementsSectionSubtitle: {
    id: "creationFolder.summary.requirements.subtitle",
    defaultMessage:
      "Assigned requirements for the chapters of the specification",
  },
  notesSectionTitle: {
    id: "creationFolder.spec.note",
    defaultMessage: "Notes",
  },
  notesSectionSubtitle: {
    id: "creationFolder.spec.noteDescription",
    defaultMessage: "Additional information",
  },
  nextButtonText: {
    id: "general.validate",
    defaultMessage: "Validate",
  },
});
