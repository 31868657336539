import { defineMessages } from "react-intl";

export const messages = defineMessages({
  versionTitle: {
    id: "specificationDetails.widgets.version.version.title",
    defaultMessage: "Version {versionNumber}",
  },
  creationTitle: {
    id: "specificationDetails.widgets.version.creation.title",
    defaultMessage: "Creation",
  },
  signatureTitle: {
    id: "specificationDetails.widgets.version.signature.title",
    defaultMessage: "Signature",
  },
  notSigned: {
    id: "specificationDetails.widgets.version.signature.notSigned",
    defaultMessage: "Not signed",
  },
});
