import { useState } from "react";
import { TradeItemSupplierData } from "models";
import { prepareSelectedSupplier } from "./utils";

const useSelectedSuppliers = () => {
  const [selectedSuppliers, setSelectedSuppliers] = useState<
    TradeItemSupplierData[]
  >([]);

  const onSelectSupplier = (supplier: TradeItemSupplierData) => {
    setSelectedSuppliers(
      prepareSelectedSupplier({ supplier, selectedSuppliers })
    );
  };

  return {
    selectedSuppliers,
    onSelectSupplier,
  };
};

export default useSelectedSuppliers;
