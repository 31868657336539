import { File } from "models";
import { useEffect, useState } from "react";
import { getFilesInfo } from "utils/files";
import { isListEmpty } from "utils/general";

const useFilesInfo = (fileIds: string[]) => {
  const [files, setFiles] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const getFiles = async () => {
      setIsLoading(true);
      try {
        const files = await getFilesInfo({ fileIds });
        setFiles(files);
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
    };

    if (!isListEmpty(fileIds)) {
      getFiles();
    } else {
      setFiles([]);
    }
  }, [JSON.stringify(fileIds)]);

  return { files, isLoading };
};

export default useFilesInfo;
