import { useRef } from "react";
import { useIntl } from "react-intl";
import {
  useAxiosErrorsInterceptor,
  useAxiosErrorsInterceptors,
} from "@trace-one/business-components";
import { axiosInstance as axiosInstanceSPEC } from "apis/SPEC";
import { axiosInstance as axiosInstanceCUMD } from "apis/CUMD";
import { axiosInstance as axiosInstanceDOCUMENT } from "apis/DOCUMENT";
import { axiosInstance as axiosInstancePMD } from "apis/PMD";
import { axiosInstance as axiosInstanceRLMD } from "apis/RLMD";
import { axiosInstance as axiosInstanceSMD } from "apis/SMD";
import { axiosInstance as axiosInstanceTAGS } from "apis/TAGS";
import { axiosInstance as axiosInstanceNOTIF } from "apis/NOTIF";
import { axiosInstance as axiosInstancePKG } from "apis/PKG";
import { axiosInstance as axiosInstanceCLB } from "apis/CLB";
import { axiosInstance as axiosInstanceCATALOGUE } from "apis/CATALOGUE";
import { axiosInstance as axiosInstanceDISCUSS } from "apis/DISCUSS";
import { ERROR_CODES } from "utils/constants";
import { prepareConfigForApi, prepareResponseConfigForApi } from "./utils";
import { messages } from "./messages";

const axiosInstances = [
  axiosInstanceSPEC,
  axiosInstanceCUMD,
  axiosInstanceDOCUMENT,
  axiosInstancePMD,
  axiosInstanceRLMD,
  axiosInstanceSMD,
  axiosInstanceTAGS,
  axiosInstanceNOTIF,
  axiosInstancePKG,
  axiosInstanceCLB,
  axiosInstanceCATALOGUE,
  axiosInstanceDISCUSS,
];

const errorCodesToSkipForSpec = [
  ERROR_CODES.CANNOT_SHARE,
  ERROR_CODES.FORBIDDEN_FILE_EXTENSION,
  ERROR_CODES.UPLOAD_EMPTY_FILE,
  ERROR_CODES.DUPLICATE_TAG,
  ERROR_CODES.LIBRARY_OBJECT_ALREADY_SAVED,
  ERROR_CODES.DUPLICATE_SPECIFICATION,
  ERROR_CODES.TESTING_SECTION_IS_MISSING,
  ERROR_CODES.DUPLICATE_LIBRARY_NAME,
  ERROR_CODES.CHAPTER_NOT_LINKED,
  ERROR_CODES.TAG_NOT_FOUND,
  ERROR_CODES.FILE_NOT_FOUND,
];

const errorStatusesToSkipForSpec = [409, 412];

const useAxiosInterceptors = () => {
  const { formatMessage } = useIntl();
  const etagMapRef = useRef({});

  useAxiosErrorsInterceptors({
    errorCodesToSkip: [ERROR_CODES.DUPLICATE_LIBRARY_NAME],
  });

  useAxiosErrorsInterceptor(axiosInstanceSPEC, {
    errorCodesToSkip: errorCodesToSkipForSpec,
    errorStatusesToSkip: errorStatusesToSkipForSpec,
    extraMessages: {
      [`${ERROR_CODES.CANNOT_ADD_SPECIFICATION}Message`]: formatMessage(
        messages.cantAddSpecificationMessage
      ),
      [`${ERROR_CODES.CANNOT_ADD_SPECIFICATION}Description`]: formatMessage(
        messages.cantAddSpecificationDescription
      ),
    },
  });

  axiosInstances.forEach(axiosInstance => {
    axiosInstance.interceptors.request.use(
      prepareConfigForApi(etagMapRef.current)
    );

    axiosInstance.interceptors.response.use(
      prepareResponseConfigForApi(etagMapRef.current)
    );
  });
};

export default useAxiosInterceptors;
