import { Heading } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { selectRefListDictionary } from "store/generalData/selectors";
import { selectFolderDetails } from "store/folderCreation/selectors";
import CreationSubtitle from "../CreationSubtitle";
import { messages } from "./messages";

const Folder = () => {
  const intl = useIntl();
  const { folderName, productTypeId } = useSelector(selectFolderDetails);
  const refListDictionary = useSelector(selectRefListDictionary);

  return (
    <>
      <Heading size="l">{intl.formatMessage(messages.title)}</Heading>
      <CreationSubtitle
        type={refListDictionary[productTypeId]}
        name={folderName}
      />
    </>
  );
};

export default Folder;
