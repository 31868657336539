const TitleField = ({ formContext, id, prefixCls, required, title }) => {
  const { colon = true } = formContext;

  let labelChildren = title;
  if (colon && typeof title === "string" && title.trim() !== "") {
    labelChildren = title.replace(/[：:]\s*$/, "");
  }

  return title ? labelChildren : null;
};

TitleField.defaultProps = {
  formContext: {},
};

export default TitleField;
