import { useEffect } from "react";
import { Col, Row } from "antd";
import { Container, Button } from "@trace-one/design-system";
import SelectLibraryObjectsStep from "components/SelectLibraryObjectsStep";
import { SelectLibraryObjectsContext } from "components/SelectLibraryObjectsModal/contexts";
import { useSelectedLibraryObject } from "hooks";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import {
  LIBRARY_API_PARAMS_VERSION,
  LIBRARY_OBJECT_TYPES,
} from "utils/constants";
import styles from "./styles.module.less";

export interface SelectClaimsModalProps {
  onCloseModal: Function;
  updateClaims: Function;
  disabledClaimIds: string[];
  formData: Array<any>;
  maxItems?: number;
}

const SelectClaimsModal: React.FC<SelectClaimsModalProps> = ({
  onCloseModal,
  updateClaims,
  disabledClaimIds = [],
  formData = [],
  maxItems,
}) => {
  const {
    selectedLibraryObjects,
    onSelectLibraryObject,
    setSelectedLibraryObjects,
  } = useSelectedLibraryObject({
    maxItems,
    existingLibraryObjectIds: disabledClaimIds,
  });

  const { getTranslationForKey } = useFormSchemaTranslation();

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  return (
    <div className={styles.wrapper}>
      <span className={styles.close}>
        <Button
          data-testid="close-icon"
          type="tertiary"
          iconName="close"
          size="large"
          color="primary"
          onClick={onCloseModal}
        />
      </span>
      <Container>
        <SelectLibraryObjectsContext.Provider
          value={{
            selectedLibraryObjects,
            setSelectedLibraryObjects,
            onSelectLibraryObject,
            onClose: onCloseModal,
            updateClaims,
            formData,
          }}
        >
          <Row className="h-100">
            <Col xs={24}>
              <SelectLibraryObjectsStep
                type={LIBRARY_OBJECT_TYPES.CLAIMS}
                displaySelectedLibraryItems={true}
                version={LIBRARY_API_PARAMS_VERSION.activeVersion}
                disabledLibraryItems={disabledClaimIds}
                title={getTranslationForKey("claimModalTitle")}
                subtitle={getTranslationForKey("claimModalSubtitle")}
                searchPlaceholder={getTranslationForKey(
                  "claimSearchPlaceholder"
                )}
              />
            </Col>
          </Row>
        </SelectLibraryObjectsContext.Provider>
      </Container>
    </div>
  );
};

export default SelectClaimsModal;
