import { useContext, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Row } from "antd";
import { Button, Heading, Paragraph } from "@trace-one/design-system";
import { selectProducts } from "store/folderCreation/selectors";
import { isListEmpty, isObjectEmpty } from "utils/general";
import { SelectLibraryObjectsContext } from "components/SelectLibraryObjectsModal/contexts";
import StepTitle from "components/StepTitle";
import AssignedProduct from "./components/AssignedProduct";
import { useAppliedRequirements, useSelectedProducts } from "./hooks";
import {
  getSelectAllMessageKey,
  prepareAppliedRequirements,
  shouldValidationOverflowBeDisplayed,
} from "./utils";
import { messages } from "./messages";
import styles from "./styles.module.less";
import { AssignedProductsStepProps } from "./types";

const AssignedProductsStep: React.FC<AssignedProductsStepProps> = ({
  alreadyAppliedRequirements,
}) => {
  const { formatMessage } = useIntl();
  const assignedProducts = useSelector(selectProducts);
  const {
    onClose,
    selectedLibraryObjects,
    setSelectedLibraryObjects,
  } = useContext(SelectLibraryObjectsContext);
  const {
    selectedProductIds,
    onSelectAllProducts,
    onSelectProduct,
    resetSelectedProducts,
    isProductSelected,
  } = useSelectedProducts();

  const defaultAppliedRequirements = useMemo(
    () => prepareAppliedRequirements(alreadyAppliedRequirements),
    [alreadyAppliedRequirements]
  );

  const {
    appliedRequirements,
    onApplyRequirements,
    onRemoveProductRequirement,
    getAppliedRequirementsForProduct,
  } = useAppliedRequirements({
    selectedRequirements: selectedLibraryObjects,
    selectedProductIds,
    setSelectedRequirements: setSelectedLibraryObjects,
    defaultAppliedRequirements,
  });

  useEffect(() => {
    if (isListEmpty(selectedLibraryObjects)) {
      resetSelectedProducts();
    }
  }, [selectedLibraryObjects.length]);

  return (
    <>
      <StepTitle
        step={2}
        title={formatMessage(messages.assignedProductsTitle)}
        subtitle={formatMessage(messages.assignedProductsSubtitle)}
      />
      <Paragraph size="xs" className={styles.selectedProductsCount}>
        {formatMessage(messages.selectedProductsCount, {
          number: selectedProductIds.length,
        })}
      </Paragraph>
      <div className={styles.selectAllBtn}>
        <Button
          data-test-id="select-all-products"
          type="link"
          size="small"
          onClick={onSelectAllProducts(assignedProducts)}
          disabled={isListEmpty(selectedLibraryObjects)}
        >
          {formatMessage(
            messages[
              getSelectAllMessageKey({
                assignedProducts,
                selectedProductIds,
              })
            ]
          )}
        </Button>
      </div>
      <div className={styles.content}>
        <div className={styles.cards}>
          {assignedProducts.map(assignedProduct => (
            <AssignedProduct
              key={assignedProduct.id}
              assignedProduct={assignedProduct}
              {...(!isListEmpty(selectedLibraryObjects) && {
                onSelectProduct,
              })}
              isSelected={isProductSelected(assignedProduct.id)}
              requirements={getAppliedRequirementsForProduct(
                assignedProduct.id
              )}
              onRemoveProductRequirement={onRemoveProductRequirement(
                assignedProduct.id
              )}
            />
          ))}
        </div>
        <Row justify="center">
          <Button
            data-test-id="apply-button"
            disabled={isListEmpty(selectedProductIds)}
            onClick={onApplyRequirements}
          >
            {formatMessage(messages.applyBtn)}
          </Button>
        </Row>
        <Row justify="end" className={styles.validateBtn}>
          <Button
            data-test-id="validate-button"
            disabled={isObjectEmpty(appliedRequirements)}
            onClick={onClose}
          >
            {formatMessage(messages.validateBtn)}
          </Button>
        </Row>
      </div>
      {shouldValidationOverflowBeDisplayed({
        appliedRequirements,
        selectedRequirements: selectedLibraryObjects,
      }) ? (
        <div className={styles.validationOverflow}>
          <Heading size="s">
            {formatMessage(messages.validationOverflowMessage)}
          </Heading>
        </div>
      ) : null}
    </>
  );
};

export default AssignedProductsStep;
