import { useIntl } from "react-intl";
import DeleteAndKeepDraftModal from "components/Library/components/DeleteAndKeepDraftModal";
import { useActions } from "./hooks";
import { CancelDeleteMaterialModalProps } from "./types";
import { messages } from "./messages";

const CancelDeleteMaterialModal: React.FC<CancelDeleteMaterialModalProps> = ({
  materialId,
  materialType,
  validateFields,
}) => {
  const { formatMessage } = useIntl();

  const { onKeepAsDraftAction, onDeleteAction } = useActions({
    materialId,
    materialType,
    validateFields,
  });

  return (
    <DeleteAndKeepDraftModal
      id={materialId}
      title={formatMessage(messages.cancelDeleteMaterialTitle)}
      description={formatMessage(messages.cancelDeleteMaterialDescription)}
      primaryButtonText={formatMessage(messages.cancelDeleteMaterialDeleteBtn)}
      onKeepAsDraftAction={onKeepAsDraftAction}
      onDeleteAction={onDeleteAction}
    />
  );
};

export default CancelDeleteMaterialModal;
