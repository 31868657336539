import { ClaimSentenceTranslation } from "models/claim";
import { Graphic, Paragraph } from "@trace-one/design-system";
import { Col, Row } from "antd";
import { LANGUAGES } from "utils/constants";
import { useIntl } from "react-intl";
import { claimMessages } from "messages";

const flagMapper = {
  [LANGUAGES.FRENCH]: "france-flag",
  [LANGUAGES.GERMAN]: "germany-flag",
  [LANGUAGES.ITALIAN]: "italy-flag",
  [LANGUAGES.DUTCH]: "holland-flag",
  [LANGUAGES.PORTUGUESE]: "portugal-flag",
  [LANGUAGES.SPANISH]: "spain-flag",
  [LANGUAGES.ENGLISH]: "uk-flag",
} as const;

const useClaimSentenceTranslation = ({
  languageDictionary,
}: {
  languageDictionary: { [key: string]: string };
}) => {
  const { formatMessage } = useIntl();

  const columns = [
    {
      title: formatMessage(claimMessages.languageTitle),
      dataIndex: "languageCode",
      width: "25%",
      render: (languageCode: ClaimSentenceTranslation["languageCode"]) => {
        return (
          <Row gutter={[16, 0]} align="middle">
            <Col>
              <Graphic size="small" name={flagMapper[languageCode]} />
            </Col>
            <Col>
              <Paragraph color="grey-5" variant="body-regular">
                {languageDictionary[languageCode]}
              </Paragraph>
            </Col>
          </Row>
        );
      },
    },
    {
      title: formatMessage(claimMessages.claimSentenceTranslationTitle),
      dataIndex: "text",
      render: (text: ClaimSentenceTranslation["text"]) => {
        return (
          <Paragraph color="grey-5" variant="body-regular">
            {text}
          </Paragraph>
        );
      },
    },
  ];

  return {
    columns,
  };
};

export default useClaimSentenceTranslation;
