import { useState, useEffect } from "react";
import { useRedirect } from "hooks";
import { isLibraryObjectPublished } from "utils/library";
import { LibraryItemApiType, LibraryItemState } from "types/library";

const useConfirmImpactSpecificationModal = ({
  libraryItemState,
  libraryItemType,
}: {
  libraryItemState: LibraryItemState;
  libraryItemType: LibraryItemApiType;
}) => {
  const { redirectToLibraryListing } = useRedirect();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const confirmModalHandler = () => {
    setIsModalVisible(false);
  };
  const closeModalHandler = () => {
    redirectToLibraryListing(libraryItemType);
  };

  useEffect(() => {
    //show modal only when initially load the published chapter page
    if (isLibraryObjectPublished(libraryItemState)) {
      setIsModalVisible(true);
    }
  }, []);

  return { isModalVisible, closeModalHandler, confirmModalHandler };
};

export default useConfirmImpactSpecificationModal;
