import { StorageConditionWidgetProps } from "./types";
import styles from "./styles.module.less";

const StorageConditionWidget: React.FC<StorageConditionWidgetProps> = ({
  properties,
  schema,
}) => {
  const { validationRequired } = schema;

  return (
    <>
      {properties.map(({ name, content }) => {
        const preparedContent = {
          ...content,
          props: {
            ...content.props,
            required: validationRequired.includes(name),
          },
        };

        return (
          <div key={name} className={styles.row}>
            {preparedContent}
          </div>
        );
      })}
    </>
  );
};

export default StorageConditionWidget;
