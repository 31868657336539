import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectLanguageCode } from "store/user/selectors";
import {
  fetchChapterTypes,
  fetchSpecificationLinkedChapters,
  fetchSpecificationStructure,
} from "apis/SPEC";
import { prepareLibraryChapterTypes } from "./utils";
import { ChapterStructureFormData } from "types/library";
import { SpecificationData } from "models";

const useSpecificationStructure = ({
  specificationId,
  productTypeId,
}: {
  specificationId: string;
  productTypeId: string;
  state?: SpecificationData["state"];
  requirementIds?: SpecificationData["requirementIds"];
  fileIds?: string[];
}) => {
  const [specificationStructure, setSpecificationStructure] = useState<
    ChapterStructureFormData[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const languageCode = useSelector(selectLanguageCode);

  const getSpecificationStructure = async () => {
    setIsLoading(true);

    try {
      const [
        specificationStructurePromise,
        chapterTypesPromise,
      ] = await Promise.allSettled([
        fetchSpecificationStructure({
          specificationId,
          languageCode,
        }),
        fetchChapterTypes({ languageCode }),
      ]);

      let libraryChapterTypes: string[] = [];

      if (chapterTypesPromise.status === "fulfilled") {
        libraryChapterTypes = prepareLibraryChapterTypes(
          chapterTypesPromise?.value?.data,
          productTypeId
        );
      }

      if (specificationStructurePromise.status === "fulfilled") {
        setSpecificationStructure(
          specificationStructurePromise?.value?.data.map(currentChapter => ({
            ...currentChapter,
            isChapterLibrary: libraryChapterTypes.includes(
              currentChapter.chapterType
            ),
          }))
        );
      }
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  const updateChapterInformation = async ({
    chapterType,
    sections,
  }: {
    chapterType: string;
    sections: ChapterStructureFormData["sections"];
  }) => {
    let linkedChapters: string[] = [];
    try {
      const { data } = await fetchSpecificationLinkedChapters({
        id: specificationId,
      });

      linkedChapters = data;
    } catch (e) {}

    setSpecificationStructure(previousState =>
      previousState.map(chapter => ({
        ...chapter,
        isLinked: linkedChapters?.includes(chapter.chapterType) ? true : false,
        ...(chapter.chapterType === chapterType && {
          sections,
        }),
      }))
    );
  };

  useEffect(() => {
    if (productTypeId) {
      getSpecificationStructure();
    }
  }, [specificationId, productTypeId]);

  return {
    specificationStructure,
    isLoading,
    updateChapterInformation,
  };
};

export default useSpecificationStructure;
