import { useEffect, useMemo, useState } from "react";
import { useAppDispatch } from "store";
import { setFormData } from "store/packagingSystemForm/packagingSystemFormSlice";
import { isObjectEmpty } from "utils/general";
import { PackagingSystemData } from "models";

const usePackagingSystemFormInitialisation = ({
  initialFormData = {},
}: {
  initialFormData: PackagingSystemData;
}) => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(true);

  const isInitialFormDataEmpty = useMemo(() => isObjectEmpty(initialFormData), [
    initialFormData,
  ]);

  useEffect(() => {
    const preparePackagingSystem = async () => {
      setIsLoading(true);

      if (!isInitialFormDataEmpty) {
        dispatch(setFormData(initialFormData));
      }

      setIsLoading(false);
    };

    preparePackagingSystem();
  }, [JSON.stringify(initialFormData)]);

  return {
    isLoading,
  };
};

export default usePackagingSystemFormInitialisation;
