import cleanDeep from "clean-deep";
import { isObjectEmpty } from "utils/general";

export const setCleanValue = ({ key, value }: { key: string; value: any }) => {
  // we need to keep the value if it is "" because if the field is read only and it was
  // received as "" from dataAsJson and we remove it in the payload BE consider that as a change of this field
  if (!value && value !== false && value !== "") {
    return {};
  }
  if (Array.isArray(value)) {
    return {
      [key]: prepareArrayForJsonSchema(value),
    };
  }
  if (typeof value === "object") {
    const cleanValue = cleanDeep(value, {
      emptyArrays: false,
      emptyStrings: false,
    });
    if (isObjectEmpty(cleanValue)) {
      return {};
    }
    return {
      [key]: cleanValue,
    };
  }
  return { [key]: value };
};

export const prepareArrayForJsonSchema = <T>(value: Array<T>) => {
  const isObjectType = value.every(item => typeof item === "object");

  // we need to send empty object if we want to add something in the array
  //  good = { packagingItems: [{}, {}] };
  //  bad = { packagingItems: [{ size: {}, weight: {} }] };

  if (isObjectType) {
    return value.map(item => {
      return cleanDeep(item, {
        emptyArrays: false,
      });
    });
  }

  return cleanDeep(value, {
    emptyArrays: false,
  });
};
