import SelectWidget from "../SelectWidget";
import { useDateFormatWidget } from "./hooks";
import { DateFormatWidgetProps } from "./types";

const DateFormatWidget: React.FC<DateFormatWidgetProps> = ({
  formContext,
  value,
  onChange,
  ...rest
}) => {
  const { dateFormats } = useDateFormatWidget();

  return (
    <SelectWidget
      allowClear={true}
      {...rest}
      options={{
        enumOptions: dateFormats,
      }}
      formContext={formContext}
      value={value}
      onChange={onChange}
    />
  );
};

export default DateFormatWidget;
