import { Route, Redirect, RouteProps } from "react-router-dom";
import { ROUTES } from "utils/constants";
import { useUserInfo } from "hooks";
import { isFAndVOnly } from "utils/general";

const FAndVRoute = (props: RouteProps) => {
  const { isSupplier } = useUserInfo();
  return isFAndVOnly && isSupplier ? (
    <Redirect to={ROUTES.HOME} />
  ) : (
    <Route {...props} />
  );
};

export default FAndVRoute;
