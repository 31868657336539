import Debounced from "components/Debounced";
import { usePercentageInput } from "./hooks";
import { PercentageInputProps } from "./types";

const PercentageInput: React.FC<PercentageInputProps> = props => {
  const {
    onChange,
    hasError,
    inputErrorMessage,
    defaultValue,
  } = usePercentageInput(props);

  const { keepDefaultValue, onErrorChange, ...rest } = props;

  return (
    <Debounced.InputNumber
      data-test-id="ingredient-percentage"
      {...rest}
      onChange={onChange}
      defaultValue={defaultValue}
      shouldApplyDebounceImmediate={false}
      error={hasError}
      errorMessage={inputErrorMessage}
      max={undefined}
    />
  );
};

PercentageInput.defaultProps = {
  precision: 2,
  max: 100,
  keepDefaultValue: true,
};

export default PercentageInput;
