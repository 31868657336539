import cx from "classnames";
import dayjs from "dayjs";
import { Button, Icon, Paragraph } from "@trace-one/design-system";
import { Link } from "react-router-dom";
import { ROUTES } from "utils/constants";
import ProgressBar from "components/Quickview/components/ProgressBar";
import { getCircleStyleKey } from "components/Quickview/components/utils";
import { useSpecificationPdfActions } from "hooks";
import { QuickViewRowProps } from "./types";
import generalMessages from "messages/general";
import { useIntl } from "react-intl";
import styles from "components/Quickview/styles.module.less";
import { QuickViewType } from "../SpecificationQuickView";

const SpecificationRow: React.FC<QuickViewRowProps> = ({
  type,
  specification: {
    progression: {
      toFillSectionCount,
      inProgressSectionCount,
      validatedBySupplierSectionCount,
      validatedByRetailerSectionCount,
    },
    state,
    version: { activeVersion },
    signature,
    specificationId,
    creationDateUtc,
  },
  title,
}) => {
  const { signedPdfId } = signature || {};
  const totalCount =
    toFillSectionCount +
    inProgressSectionCount +
    validatedByRetailerSectionCount +
    validatedBySupplierSectionCount;

  const {
    isLoading: pdfIsLoading,
    openPdfInSeparateTab,
  } = useSpecificationPdfActions({
    specificationId,
    state,
    shouldFetchSpecificationFilesInitially: false,
    signedPdfId,
  });
  const intl = useIntl();

  return (
    <div>
      <div
        className={cx(styles.sectionRow, styles.specificationRow)}
        data-testid={`specification-${specificationId}`}
      >
        <div className={cx(styles.firstSpecificationColumn, styles.name)}>
          <Icon name="hierarchy-link" size="small" />
          <Link
            className={cx(
              styles.padding,
              styles.link,
              activeVersion && styles.currentVersion
            )}
            to={ROUTES.SPECIFICATION_DETAILS.replace(
              ":specificationId",
              specificationId
            )}
          >
            {title}
          </Link>
          <div className={styles.greyBar} />
        </div>
        <div className={styles.secondSpecificationColumn}>
          <ProgressBar
            totalCount={totalCount}
            toFillSectionCount={toFillSectionCount}
            inProgressSectionCount={inProgressSectionCount}
            validatedBySupplierSectionCount={validatedBySupplierSectionCount}
            validatedByRetailerSectionCount={validatedByRetailerSectionCount}
          />
        </div>
        <div className={cx(styles.circleWrapper, styles.extraBorder)}>
          <div
            className={cx(styles.circle, styles[getCircleStyleKey(state)])}
          />
        </div>
        <div className={styles.downloadWrapper} data-testid="spec-row-download">
          <Button
            iconName="download"
            type="tertiary"
            color="grey"
            onClick={openPdfInSeparateTab}
            loading={pdfIsLoading}
          />
        </div>
      </div>

      {type === QuickViewType.CHAPTER && (
        <Paragraph size="s" color="grey-4">
          {intl.formatMessage(generalMessages.creationDate)}:{" "}
          {dayjs(creationDateUtc).local().format("L")}
        </Paragraph>
      )}
    </div>
  );
};

export default SpecificationRow;
