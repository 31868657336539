import { Datatable } from "@trace-one/design-system";
import { FormattedMessage } from "react-intl";
import { useRedirect } from "hooks";
import { useVersionHistoryActions, useVersionHistoryColumns } from "./hooks";
import { useVersionHistory } from "./hooks";
import { VersionHistoryProps } from "./types";
import { CHAPTER_TYPES_API } from "utils/constants";
import DetailsAndLinksChapterModal from "components/DetailsAndLinksChapterModal";
import Quickview, { QuickViewType } from "components/Quickview";
import { isStateDraft } from "utils/specifications";
import { fetchChapterQuickView } from "apis/SPEC";
import { quickViewMessages } from "components/Quickview/messages";
import { ChapterVersion, QuickViewTradeItemData } from "models";
import styles from "./styles.module.less";

const VersionHistory = ({
  chapterId,
  chapterType,
  linkedSpecificationsCallback,
}: VersionHistoryProps) => {
  const chapterTypeApi = CHAPTER_TYPES_API[chapterType];

  const { redirectToLibraryEdition } = useRedirect();

  const {
    versionHistoryList,
    isLoading,
    isDetailsAndLinksModalOpen,
    modalProps,
    handleOpenModal,
    handleCloseModal,
    rowSelection,
    actionType,
    selectedChapterId,
    activeChapterId,
    shouldRefetchVersionHistory,
    setShouldRefetchVersionHistory,
    states,
  } = useVersionHistory({
    chapterType: chapterTypeApi,
    chapterId,
  });

  const { handlePrimaryButtonClick } = useVersionHistoryActions({
    chapterId: activeChapterId,
    chapterTypeApi,
    selectedRowKeys: rowSelection?.selectedRowKeys,
    actionType,
    handleCloseModal,
    setShouldRefetchVersionHistory,
    linkedSpecificationsCallback,
  });

  const onCellClick = (row: ChapterVersion) => ({
    onClick: () =>
      redirectToLibraryEdition({
        id: row.chapterId,
        type: CHAPTER_TYPES_API[chapterType],
      }),
  });

  const { columns } = useVersionHistoryColumns({
    onCellClick,
    handleOpenModal,
  });

  return (
    <>
      <Datatable
        loading={isLoading}
        rowClassName={styles.row}
        columns={columns}
        pagination={{
          hideOnSinglePage: true,
        }}
        expandable={{
          expandedRowRender: (chapter: ChapterVersion) => {
            return (
              <Quickview.Specification
                type={QuickViewType.CHAPTER}
                fetcher={() =>
                  fetchChapterQuickView({
                    chapterId: chapter.chapterId,
                    chapterType: chapterTypeApi,
                  })
                }
                header={totalItem => (
                  <FormattedMessage
                    {...quickViewMessages.chapterQuickViewHeader}
                    values={{
                      nbOfSpec: totalItem,
                    }}
                  />
                )}
                body={(items: QuickViewTradeItemData[]) => {
                  return items.map(item => {
                    return (
                      <Quickview.SpecificationRow
                        type={QuickViewType.CHAPTER}
                        key={item.tradeItemId}
                        specification={item.tradeItemSpecifications[0]}
                        title={<Quickview.ChapterTitle tradeItem={item} />}
                      />
                    );
                  });
                }}
                shouldRefetch={shouldRefetchVersionHistory}
              />
            );
          },
          rowExpandable: ({ state }) => !isStateDraft(state),
        }}
        dataSource={versionHistoryList}
        rowKey={(chapter: ChapterVersion) => chapter.chapterId}
      />
      {isDetailsAndLinksModalOpen && (
        <DetailsAndLinksChapterModal
          onCloseModal={handleCloseModal}
          chapterId={chapterId}
          selectedChapterId={selectedChapterId}
          chapterType={chapterType}
          rowSelection={rowSelection}
          states={states}
          title={modalProps.title}
          subtitle={modalProps.subtitle}
          primaryButtonLabel={modalProps.primaryButtonLabel}
          onPrimaryButtonClick={handlePrimaryButtonClick}
          secondaryButtonLabel={modalProps.secondaryButtonLabel}
          onSecondaryButtonClick={modalProps.onSecondaryButtonClick}
          actionType={actionType}
        />
      )}
    </>
  );
};

export default VersionHistory;
