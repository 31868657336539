import { RequirementType } from "types/library";
import { isLibraryRequirementTypeText } from "./library";
import { LIBRARY_OBJECT_API_TYPES } from "./constants";

export const getRequirementType = (requirementType: RequirementType) => {
  if (isLibraryRequirementTypeText(requirementType)) {
    return LIBRARY_OBJECT_API_TYPES.text;
  }

  return LIBRARY_OBJECT_API_TYPES.document;
};
