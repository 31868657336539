import { useMemo, useState, useEffect } from "react";
import { RadioChangeEvent } from "antd";
import { isWidgetDisabled } from "../../../utils";
import { RadioPopconfirmWidgetProps } from "../../types";

const useRadioPopconfirmWidget = ({
  disabled,
  formContext,
  readonly,
  schema,
  value,
  onChange: jsonOnChange,
}: RadioPopconfirmWidgetProps) => {
  const [radioValue, setRadioValue] = useState(value);
  const [isPopconfirmVisible, setIsPopconfirmVisible] = useState(false);
  const { readonlyAsDisabled = true, isWidgetEditable } = formContext;
  const { propertyId } = schema;

  const isDisabled = useMemo(
    () =>
      isWidgetDisabled({
        isWidgetEditable: isWidgetEditable(propertyId),
        disabled,
        readonlyAsDisabled,
        readonly,
      }),
    [disabled, readonlyAsDisabled, readonly, propertyId]
  );

  const onChange = ({ target: { value: nextValue } }: RadioChangeEvent) => {
    const valueToSend =
      schema.type === "boolean" ? nextValue !== "false" : nextValue;

    if (valueToSend !== false) {
      jsonOnChange(valueToSend);

      setRadioValue(nextValue);
    }
  };

  const handlePopconfirmOnVisibleChange = value => {
    setIsPopconfirmVisible(!!value);
  };

  const onConfirm = () => {
    const valueToSend = false;

    jsonOnChange(valueToSend);
    setRadioValue(valueToSend);
  };

  useEffect(() => {
    setRadioValue(value);
  }, [value]);

  return {
    disabled: isDisabled,
    value: `${radioValue}`,
    onChange,
    isPopconfirmVisible,
    handlePopconfirmOnVisibleChange,
    onConfirm,
  };
};

export default useRadioPopconfirmWidget;
