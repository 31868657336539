import { useIntl, FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { Modal } from "antd";
import { Button, Heading, Paragraph } from "@trace-one/design-system";
import { selectCompanyName } from "store/specificationDetails/selectors";
import { useRedirect } from "hooks";
import ModalCloseIcon from "components/ModalCloseIcon";
import { reloadPage } from "utils/general";
import { messages } from "../RevisionActions/messages";
import styles from "./styles.module.less";

const RequestSentModal = () => {
  const { formatMessage } = useIntl();
  const companyName = useSelector(selectCompanyName);
  const { redirectToSpecificationListing } = useRedirect();

  return (
    <Modal
      width={"50%"}
      visible
      onCancel={redirectToSpecificationListing}
      footer={null}
      closeIcon={<ModalCloseIcon />}
    >
      <div className={styles.container}>
        <div>
          <Heading size="s">
            {formatMessage(messages.requestSentModalTitle)}
          </Heading>
        </div>
        <div>
          <Paragraph size="m">
            <FormattedMessage
              {...messages.requestSentModalDescription}
              values={{
                retailerName: companyName,
                b: (...chunks) => (
                  <span className={styles.green}>&nbsp;{chunks}</span>
                ),
              }}
            />
          </Paragraph>
        </div>
        <div>
          <Heading size="xxs">
            {formatMessage(messages.buttonsActionText)}
          </Heading>
        </div>
        <div>
          <Button
            type="secondary"
            onClick={redirectToSpecificationListing}
            data-test-id="view-all-btn"
            className={styles.marginRight}
          >
            {formatMessage(messages.specificationsListingBtn)}
          </Button>
          <Button type="secondary" onClick={reloadPage} data-test-id="view-btn">
            {formatMessage(messages.specificationViewBtn)}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default RequestSentModal;
