import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";

export const _selectPackaging = (state: RootState) => state.packaging;

export const selectIsLoading = createSelector(
  _selectPackaging,
  ({ isLoading }) => isLoading
);

export const selectHasError = createSelector(
  _selectPackaging,
  ({ hasError }) => hasError
);

export const selectPackagingComponent = createSelector(
  _selectPackaging,
  ({ packagingComponent }) => packagingComponent
);
