import { Popover } from "@trace-one/react-components";
import { Icon } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import {
  isMaterialAdditiveType,
  isMaterialCompositeType,
  isMaterialProcessingAidType,
  isMaterialRawMaterialType,
} from "utils/library";
import CategoryTreeSelect from "components/CategoryTreeSelect";
import SubstanceFunction from "./components/SubstanceFunction";
import useIngredientFunctionDropdownActions from "./hooks/useIngredientFunctionDropdownActions";
import { REFLIST_IDS } from "utils/constants";
import { IngredientFunctionDropdownProps } from "./types";
import messages from "./messages";
import styles from "./styles.module.less";

const IngredientFunctionDropdown: React.FC<IngredientFunctionDropdownProps> = ({
  ingredient,
  disableActions,
  getCategoryItem,
  update,
}) => {
  const { formatMessage } = useIntl();

  const {
    onUpdateFunction,
    onDropdownVisibleFunction,
    currentFunctionIds,
  } = useIngredientFunctionDropdownActions({
    ingredient,
    update,
  });

  // TODO: remove function related code for raw materials
  if (
    isMaterialCompositeType(ingredient.type) ||
    isMaterialRawMaterialType(ingredient.type)
  ) {
    return <span data-test-id="ingredient-function" />;
  }

  if (
    isMaterialAdditiveType(ingredient.type) ||
    isMaterialProcessingAidType(ingredient.type)
  ) {
    return (
      <SubstanceFunction
        ingredient={ingredient}
        onUpdate={onUpdateFunction}
        disableActions={disableActions}
      />
    );
  }

  return (
    <div className={styles.functionSelectContainer}>
      <CategoryTreeSelect
        data-test-id="ingredient-function"
        name={`ingredient-function${ingredient?.ingredientId}`}
        placeholder={formatMessage(messages.functionsPlaceholder)}
        onChange={onUpdateFunction}
        categoryId={REFLIST_IDS.FUNCTIONS}
        disabled={disableActions}
        {...(ingredient.functionId && {
          value: getCategoryItem(ingredient.functionId),
        })}
        onDropdownVisibleChange={onDropdownVisibleFunction}
        highlightedItems={currentFunctionIds}
        shouldFirstLevelBeSelectable={false}
        shouldCallApi={false}
      />
      {!disableActions && (
        <Popover
          trigger="hover"
          placement="right"
          content={formatMessage(messages.functionTooltip)}
        >
          <Icon
            className={styles.tooltipIcon}
            name="more-info"
            color="primary"
          />
        </Popover>
      )}
    </div>
  );
};

export default IngredientFunctionDropdown;
