import React from "react";
import { Col, Row } from "antd";
import { Heading } from "@trace-one/design-system";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { isListEmpty } from "utils/general";
import { WidgetProps } from "components/SpecificationSection/types";

export interface ArrayItemsWidgetProps extends Omit<WidgetProps, "formData"> {
  formData?: string[];
}

const ArrayItemsWidget: React.FC<ArrayItemsWidgetProps> = props => {
  const { getTranslationForKey } = useFormSchemaTranslation();

  const { formData = [], uiSchema } = props;

  return (
    <Row>
      <Col xs={24} md={8}>
        <Heading size="xxs">
          {getTranslationForKey(uiSchema?.["ui:title"])}
        </Heading>
      </Col>
      <Col xs={24} md={16}>
        {isListEmpty(formData) ? (
          <>-</>
        ) : (
          formData.map((item, index) => (
            <React.Fragment key={item}>
              {item}
              {index === formData.length - 1 ? "" : ", "}
            </React.Fragment>
          ))
        )}
      </Col>
    </Row>
  );
};

export default ArrayItemsWidget;
