import { ALLERGEN_DECLARATION_TYPE } from "utils/constants";

export const getOnConfirmModalHandler = (onAddAllergens: Function) => (
  allergens: { id: string }[]
) => {
  onAddAllergens(
    allergens.map(allergen => {
      return {
        allergenId: allergen.id,
        declaration: ALLERGEN_DECLARATION_TYPE.PRESENT,
      };
    })
  );
};
