import { Row, Col } from "antd";
import { Paragraph, Heading } from "@trace-one/design-system";
import classNames from "classnames";
import styles from "./styles.module.less";

const FieldReadOnly = ({
  title,
  content,
  isLastRow = false,
}: {
  title: string;
  content: string;
  isLastRow?: boolean;
}) => {
  return (
    <Row className={classNames({ [styles.fieldReadOnlyRow]: !isLastRow })}>
      <Col xs={4} md={8}>
        <Heading size="xxs" color="grey-5">
          {title}
        </Heading>
      </Col>
      <Col xs={20} md={16}>
        <Paragraph
          size="m"
          color="grey-4"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </Col>
    </Row>
  );
};

export default FieldReadOnly;
