import { retry } from "@reduxjs/toolkit/query/react";
import { axiosInstance } from "apis/SPEC";
import { API_VERSION } from "apis/constants";
import { AxiosRequestConfig, AxiosResponse } from "axios";

// Define baseQuery
// This can be used at global level for for spec
export const baseQuery = async ({
  url,
  method,
  data,
  params,
  headers,
}: AxiosRequestConfig) => {
  try {
    const result: AxiosResponse = await axiosInstance({
      url: `/api/${API_VERSION}${url}`,
      method,
      data: data ?? {},
      params,
      headers,
    });
    return { data: result.data };
  } catch (axiosError) {
    let err = axiosError;
    if (!axiosError.response) throw err;
    return {
      error: {
        status: axiosError.response.status,
        data: axiosError.response.data,
      },
    };
  }
};

export const fetchBaseQuery = retry(baseQuery, { maxRetries: 0 });
