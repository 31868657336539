import { useEffect, useMemo, useState } from "react";
import { Stepper } from "@trace-one/design-system";
import { Title } from "@trace-one/react-components";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { UploadFiles } from "@trace-one/business-components";
import classNames from "classnames";
import withUnitsOfMeasure from "hocs/withUnitsOfMeasure";
import CreationWizzard from "components/CreationWizzard";
import AssignedProduct from "components/AssignedProduct";
import ConfirmedSharingModal from "components/ConfirmedSharingModal";
import Note from "components/Note";
import RequirementsTabs from "components/RequirementsTabs";
import FolderDetails from "./components/FolderDetails";
import ShareFolderModal from "./components/ShareFolderModal";
import {
  checkIfFolderIsFinalized,
  fetchFolderTemplate,
  validateStep,
} from "store/folderCreation/asyncActions";
import {
  selectFolderDetails,
  selectProducts,
  selectChapters,
  selectProductSpecifications,
  selectIsFolderCheckInProgress,
  selectWizard,
  selectRequirements,
  selectFolderTemplate,
} from "store/folderCreation/selectors";
import { selectLanguageCode } from "store/user/selectors";
import { useRedirect } from "hooks";
import { isListEmpty, isObjectEmpty } from "utils/general";
import { onCreateClick, onViewClick } from "../../utils";
import {
  useCategory,
  useSteps,
  useCreationActions,
} from "pages/Folder/components/Creation/hooks";
import { getUniqueNumberOfSuppliers } from "../Specifications/utils";
import {
  getCloseShareFolderModal,
  getOnNextButtonClick,
  getStartConfirmedSharingModal,
} from "./utils";
import { messages } from "./messages";
import documentMessages from "messages/documents";
import { useFiles } from "components/CreationWizzard/components/Files/hooks";
import styles from "./styles.module.less";
import SpecificationTemplate from "components/SpecificationTemplate";

export const Summary = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const { getOnFolderDelete } = useCreationActions();
  const {
    folderId,
    folderName,
    specificationNote,
    fileAssociations,
  } = useSelector(selectFolderDetails);
  const chapters = useSelector(selectChapters);
  const template = useSelector(selectFolderTemplate);
  const products = useSelector(selectProducts);
  const productSpecifications = useSelector(selectProductSpecifications);
  const languageCode = useSelector(selectLanguageCode);
  const requirements = useSelector(selectRequirements);
  const isFolderCheckInProgress = useSelector(selectIsFolderCheckInProgress);
  const wizard = useSelector(selectWizard);
  const { redirectToFolderDetails } = useRedirect();
  const [isShareFolderModalOpen, setIsShareFolderModalOpen] = useState(false);
  const [
    isConfirmedSharignModalOpen,
    setIsConfirmedSharignModalOpen,
  ] = useState(false);

  const { stepIndex, currentIsValid, steps, onPreviousStep } = useSteps({
    currentStep: wizard.currentStep,
    stepsState: wizard.state,
  });

  useEffect(() => {
    wizard.currentStep === "summary" &&
      !currentIsValid &&
      dispatch(validateStep());
  }, [wizard.currentStep]);

  const { categoryNamesDictionary } = useCategory({ products });

  const { filesWithDropdown } = useFiles({
    files: fileAssociations,
    chapters,
    disabled: true,
  });

  const numberOfSuppliers = useMemo(
    () =>
      getUniqueNumberOfSuppliers({
        folderId,
        productSpecifications,
      }),
    [productSpecifications]
  );

  useEffect(() => {
    dispatch(fetchFolderTemplate());
  }, [dispatch, languageCode]);

  return (
    <>
      <CreationWizzard.Title.Folder />
      <Stepper
        stepIndex={stepIndex}
        currentIsValid={currentIsValid}
        steps={steps}
      />
      <Title
        level={2}
        value={intl.formatMessage(messages.detailsSectionTitle)}
        subtitle={intl.formatMessage(messages.detailsSectionSubtitle)}
      />
      <div className={styles.marginBottom}>
        <FolderDetails />
      </div>
      <Title
        level={2}
        value={intl.formatMessage(messages.assignedProductsSectionTitle)}
        subtitle={intl.formatMessage(messages.assignedProductsSectionSubtitle)}
      />
      <div className={styles.marginBottom}>
        <div data-testid="list-items">
          {products.map(product => (
            <AssignedProduct.Folder
              key={product.id}
              displayActions={false}
              product={{
                ...product,
                categoryName:
                  categoryNamesDictionary?.[product?.category?.categoryItemId],
              }}
            />
          ))}
        </div>
      </div>

      <SpecificationTemplate
        template={template}
        numberOfProducts={products?.length}
        numberOfSuppliers={numberOfSuppliers}
        displayActions={false}
      />
      {!isObjectEmpty(requirements) ? (
        <>
          <Title
            level={2}
            value={intl.formatMessage(messages.requirementsSectionTitle)}
            subtitle={intl.formatMessage(messages.requirementsSectionSubtitle)}
          />
          <RequirementsTabs
            requirements={requirements}
            displayActions={false}
          />
        </>
      ) : null}

      {!isListEmpty(filesWithDropdown) && (
        <>
          <Title
            level={2}
            value={intl.formatMessage(documentMessages.documents)}
            subtitle={intl.formatMessage(
              documentMessages.creationDocumentSubtitle
            )}
          />
          <div className={classNames(styles.marginBottom, styles.filesList)}>
            <UploadFiles.List fileList={filesWithDropdown} showDropdown />
          </div>
        </>
      )}

      <Title
        level={2}
        value={intl.formatMessage(messages.notesSectionTitle)}
        subtitle={intl.formatMessage(messages.notesSectionSubtitle)}
      />
      <Note content={specificationNote} type="folder" />
      <CreationWizzard.Footer
        onBackClick={onPreviousStep}
        onNextClick={getOnNextButtonClick({
          dispatch,
          checkIfFolderIsFinalized,
          setIsShareFolderModalOpen,
        })}
        onCancel={getOnFolderDelete()}
        nextButtonText={intl.formatMessage(messages.nextButtonText)}
        isNextDisabled={isFolderCheckInProgress}
      />
      {isShareFolderModalOpen ? (
        <ShareFolderModal
          closeShareFolderModal={getCloseShareFolderModal({
            setIsShareFolderModalOpen,
          })}
          startConfirmedSharingModal={getStartConfirmedSharingModal({
            setIsConfirmedSharignModalOpen,
            setIsShareFolderModalOpen,
          })}
        />
      ) : null}
      {isConfirmedSharignModalOpen ? (
        <ConfirmedSharingModal
          type="folder"
          name={folderName}
          onCreateClick={onCreateClick({ push: history.push })}
          onViewClick={onViewClick({
            redirectToFolderDetails,
            folderId,
          })}
        />
      ) : null}
    </>
  );
};

export default withUnitsOfMeasure(Summary);
