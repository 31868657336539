import { Col, Form, Row } from "antd";
import { Alert, Radio } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import { useMaterialFormInfo } from "components/Library/components/MaterialForm/hooks";
import { RecipeProps } from "./types";

const Recipe: React.FC<RecipeProps> = ({
  recipeType,
  onChangeRecipe,
  isRecipeTypeDisabled,
}) => {
  const { formatMessage } = useIntl();
  const { recipeValues } = useMaterialFormInfo();

  return (
    <Form.Item
      label={formatMessage({
        id: "library.rawMaterialForm.recipe.label",
      })}
      rules={[{ required: true }]}
    >
      <Row gutter={[16, 16]} align="middle">
        <Col>
          <Radio.Group
            options={recipeValues}
            data-test-id="material-form-recipe"
            display="horizontal"
            onChange={onChangeRecipe}
            disabled={isRecipeTypeDisabled}
            value={recipeType}
          />
        </Col>
        {recipeType && (
          <Col>
            <Alert
              type="info"
              message={formatMessage({
                id: `library.rawMaterialForm.recipe.info.${recipeType}`,
              })}
              closable
            />
          </Col>
        )}
      </Row>
    </Form.Item>
  );
};

export default Recipe;
