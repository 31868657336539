import { ROUTES } from "utils/constants";

export const onCreateClick = ({ push }) => () => {
  push(ROUTES.SPECIFICATION_CREATION_PRODUCT_STEP);
};

export const onViewClick = ({
  redirectToSpecificationDetails,
  specificationId,
}) => () => {
  redirectToSpecificationDetails(specificationId);
};
