import { useState } from "react";

const useRemoveServing = ({
  onDeleteNutritionServing,
  setShouldRefetchServings,
}: {
  onDeleteNutritionServing: Function;
  setShouldRefetchServings: Function;
}) => {
  const [showRemoveServingModal, setShowRemoveServingModal] = useState<boolean>(
    false
  );
  const [servingId, setServingId] = useState<string>(undefined);

  const onRemoveServing = (servingId: string) => {
    setShowRemoveServingModal(true);
    setServingId(servingId);
  };

  const onConfirmRemoveServing = () => {
    onDeleteNutritionServing({
      servingId,
      setShouldRefetchServings,
      setShowRemoveServingModal,
    });
  };

  return {
    showRemoveServingModal,
    onRemoveServing,
    onConfirmRemoveServing,
    onCloseRemoveServing: setShowRemoveServingModal,
  };
};

export default useRemoveServing;
