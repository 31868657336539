import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { fetchNutrientByIds } from "apis/CATALOGUE";
import { useBoolean } from "hooks";
import { useAppDispatch } from "store";
import { selectLanguageCode } from "store/user/selectors";
import { updateCatalogDictionary } from "store/generalData/asyncActions";
import { selectCatalogDictionary } from "store/generalData/selectors";
import {
  getNegativeNutrients,
  getNutrientIdsNotInCatalogDictionary,
  getPositiveNutrients,
} from "components/NutriscorePointsDetails/utils";
import { isListEmpty, sortByKey } from "utils/general";
import { getNutrientsForCatalogDictionary } from "utils/nutrition";
import { NutriscorePointData } from "models";

const useNutriscorePointsDetails = ({
  nutriscorePoints,
}: {
  nutriscorePoints: NutriscorePointData[];
}) => {
  const catalogDictionary = useSelector(selectCatalogDictionary);
  const languageCode = useSelector(selectLanguageCode);
  const dispatch = useAppDispatch();
  const { value: isLoading, setTrue, setFalse } = useBoolean();

  const positiveNutrients = useMemo(
    () =>
      sortByKey({
        array: getPositiveNutrients({
          nutriscorePoints,
          catalogDictionary,
        }),
        key: "name",
      }),
    [nutriscorePoints, catalogDictionary]
  );

  const negativeNutrients = useMemo(
    () =>
      sortByKey({
        array: getNegativeNutrients({
          nutriscorePoints,
          catalogDictionary,
        }),
        key: "name",
      }),
    [nutriscorePoints, catalogDictionary]
  );

  useEffect(() => {
    const fetchNutrients = async () => {
      const nutrientIdsNotInCatalogDictionary = getNutrientIdsNotInCatalogDictionary(
        { nutriscorePoints, catalogDictionary }
      );

      if (!isListEmpty(nutrientIdsNotInCatalogDictionary)) {
        try {
          setTrue();
          const { data: nutrients } = await fetchNutrientByIds({
            nutrientIds: nutrientIdsNotInCatalogDictionary,
            languageCode,
          });

          dispatch(
            updateCatalogDictionary(getNutrientsForCatalogDictionary(nutrients))
          );
        } catch {
        } finally {
          setFalse();
        }
      }
    };

    fetchNutrients();
  }, []);

  return {
    isLoading,
    positiveNutrients,
    negativeNutrients,
  };
};

export default useNutriscorePointsDetails;
