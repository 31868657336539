import { Icon } from "@trace-one/design-system";
import { StatusIconProps } from "./types";

const StatusIcon: React.FC<StatusIconProps> = ({ status }) => {
  switch (status) {
    case "inProgress":
    case "correctionNeeded":
      return <Icon data-testid="icon-edit" name="edit" color="white" />;
    case "validatedBySupplier":
    case "validatedByRetailer":
      return <Icon data-testid="icon-check" name="check" color="white" />;
    case "signedBySupplier":
    case "signedByRetailer":
      return <Icon data-testid="icon-signed" name="signed" color="white" />;
    case "inRevisionBySupplier":
    case "inRevisionByRetailer":
      return <Icon data-testid="icon-refresh" name="refresh" color="white" />;
    default:
      return <></>;
  }
};

export default StatusIcon;
