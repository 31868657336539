import { Heading, Paragraph, EmptyState } from "@trace-one/design-system";
import { Row } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { messages } from "./messages";
import { DescriptionProps } from "./types";
import styles from "../../styles.module.less";

const Description: React.FC<DescriptionProps> = ({ folderDescription }) => {
  const intl = useIntl();

  return (
    <>
      <Heading className={styles.title} size="xs">
        <FormattedMessage {...messages.description} />
      </Heading>
      {!folderDescription ? (
        <Row justify="center">
          <EmptyState heading={intl.formatMessage(messages.descriptionEmpty)} />
        </Row>
      ) : (
        <Paragraph
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: folderDescription,
          }}
        />
      )}
    </>
  );
};

export default Description;
