import { Button } from "@trace-one/design-system";
import { useSelector } from "react-redux";
import { selectSpecificationPermissions } from "store/specificationDetails/selectors";
import { useSpecificationActions } from "hooks";
import withShareSpecificationModal from "hocs/withShareSpecificationModal";
import { getOnShareButtonClick } from "utils/form";
import { ShareButtonProps } from "./types";

export const ShareButton: React.FC<ShareButtonProps> = ({
  setIsShareModalOpen,
  specificationId,
  state,
}) => {
  const permissions = useSelector(selectSpecificationPermissions);
  const { share } = useSpecificationActions(
    {
      specificationId,
      state,
    },
    permissions
  );

  return !!share ? (
    <Button
      type="primary"
      onClick={getOnShareButtonClick({
        setIsShareModalOpen,
      })}
      data-testid="share-btn"
    >
      {share.name}
    </Button>
  ) : null;
};

export default withShareSpecificationModal(ShareButton);
