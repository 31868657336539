import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Form } from "antd";
import { Tags } from "@trace-one/react-components";
import { selectLanguageCode } from "store/user/selectors";
import { APPLICATION_TYPE_IDS, MAX_CHAR_LENGTH } from "utils/constants";
import { isLibraryObjectLocked } from "utils/library";
import LibraryItemName from "components/Library/components/LibraryItemName";
import StepTitle from "components/Library/components/StepTitle";
import { useGeneralInfoActions } from "./hooks";
import libraryMessages from "messages/library";
import { messages as packagingMessages } from "messages/packaging";
import { PackagingGeneralInformationProps } from "./types";
import SpecificationSection from "components/SpecificationSection";
import { getParsedData } from "utils/general";

const PackagingGeneralInformation: React.FC<PackagingGeneralInformationProps> = ({
  generalInfo,
  onFieldsChange,
}) => {
  const { formatMessage } = useIntl();
  const languageCode = useSelector(selectLanguageCode);

  const {
    onUpdatePackagingSystemName,
    onUpdateTags,
    onSaveTemplateBlock,
  } = useGeneralInfoActions();

  const isPSLocked = isLibraryObjectLocked(generalInfo.state);

  const [form] = Form.useForm();

  return (
    <>
      <Form
        name="packagingSystemForm"
        layout="horizontal"
        validateMessages={{
          required: formatMessage(libraryMessages.formRequired),
          whitespace: formatMessage(libraryMessages.formRequired),
          string: {
            max: formatMessage(libraryMessages.formMaxCharLength, {
              max: MAX_CHAR_LENGTH,
            }),
          },
        }}
        initialValues={generalInfo}
        form={form}
        labelAlign="left"
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          xs: 24,
          md: 9,
        }}
        onFieldsChange={onFieldsChange}
      >
        <StepTitle
          title={formatMessage(packagingMessages.generalInformationTitle)}
          step={1}
        />
        <LibraryItemName
          onChange={onUpdatePackagingSystemName}
          defaultValue={generalInfo.name}
          disableActions={isPSLocked}
          form={form}
        />
        <Form.Item
          name="tagIds"
          label={formatMessage(libraryMessages.tagsLabel)}
          wrapperCol={{
            xs: 24,
            md: 20,
          }}
        >
          {isPSLocked ? (
            <Tags.List
              languageCode={languageCode}
              tagIds={generalInfo?.tagIds}
            />
          ) : (
            <Tags.Select
              languageCode={languageCode}
              applicationTypeId={APPLICATION_TYPE_IDS.SPEC}
              tagIds={generalInfo?.tagIds || []}
              onTagListChange={onUpdateTags}
            />
          )}
        </Form.Item>
      </Form>

      <StepTitle title={formatMessage(packagingMessages.gtin)} step={2} />

      {generalInfo?.sections?.map(({ sectionBlocks, sectionId }) => {
        return sectionBlocks?.map(
          ({ dataAsJson, jsonSchema, uiSchema, templateBlockId }) => {
            return (
              <SpecificationSection.Block
                key={templateBlockId}
                sectionId={sectionId}
                templateBlockId={templateBlockId}
                uiSchema={getParsedData(uiSchema)}
                jsonSchema={getParsedData(jsonSchema)}
                formData={getParsedData(dataAsJson)}
                saveTemplateBlock={onSaveTemplateBlock(templateBlockId)}
                packagingSystemData={{ generalInformation: generalInfo }}
                hasPermissionToSave={!isPSLocked}
              />
            );
          }
        );
      })}
    </>
  );
};

export default PackagingGeneralInformation;
