import { defineMessages } from "react-intl";

export const messages = defineMessages({
  addAPackagingSystem: {
    id: "specificationDetails.widgets.packagingSystem.addPackagingSystem",
    defaultMessage: "Add a packaging system",
  },
  noPackagingSystemAdded: {
    id: "specificationDetails.widgets.packagingSystem.noPackagingSystemAdded",
    defaultMessage: "No packaging system",
  },
  modalAssociateTitle: {
    id: "specificationDetails.widgets.packagingSystem.modalAssociate.title",
    defaultMessage: "Select packaging system",
  },
  modalAssociateSubtitle: {
    id: "specificationDetails.widgets.packagingSystem.modalAssociate.subtitle",
    defaultMessage:
      "Select the packaging system to associate to the specification",
  },
});
