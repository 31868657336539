import { useRef } from "react";
import { useIntl } from "react-intl";
import { Modal, Search } from "@trace-one/design-system";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import InfiniteScrollLibraryContent from "components/InfiniteScrollLibraryContent";
import Filters from "components/Filters";
import { useRequirementItems } from "./hooks";
import generalMessages from "messages/general";
import { AddRequirementsModalProps } from "./types";
import { useSelectedLibraryObject } from "hooks";
import { isListEmpty } from "utils/general";
import { messages } from "pages/Specification/components/Details/messages";
import styles from "./styles.module.less";

const AddRequirementsModal: React.FC<AddRequirementsModalProps> = ({
  onCloseModal,
  onPrimaryButtonClick,
  existingRequirementsIds,
}) => {
  const { getTranslationForKey } = useFormSchemaTranslation();
  const { formatMessage } = useIntl();
  const infiniteScrollWrapper = useRef<HTMLDivElement>(null);

  const {
    onSelectLibraryObject: onSelectRequirement,
    selectedLibraryObjectsIds: selectedRequirementsIds,
  } = useSelectedLibraryObject({
    existingLibraryObjectIds: existingRequirementsIds,
  });

  const {
    canLoadMore,
    requirementItems,
    totalNumberOfItems,
    setSearchText,
    setRequirementTypesFilter,
    searchRequirements,
  } = useRequirementItems({ infiniteScrollWrapper });

  return (
    <Modal
      open
      size="l"
      onCancel={onCloseModal}
      title={getTranslationForKey("requirementsSectionAddARequirementTitle")}
      headerChildren={
        <>
          <Search
            className={styles.searchBar}
            onSearch={setSearchText}
            placeholder={formatMessage(
              messages.addRequirementSearchPlaceholder
            )}
          />
          <Filters.RequirementType
            getSelectedPickerItems={setRequirementTypesFilter}
          />
        </>
      }
      onPrimaryButtonClick={() => onPrimaryButtonClick(selectedRequirementsIds)}
      onSecondaryButtonClick={onCloseModal}
      primaryButtonText={formatMessage(generalMessages.select)}
      secondaryButtonText={formatMessage(generalMessages.cancel)}
      primaryButtonProps={{
        disabled: isListEmpty(selectedRequirementsIds),
      }}
    >
      <InfiniteScrollLibraryContent
        title={formatMessage(messages.addRequirementModalBodyTitle)}
        totalNumberOfItems={totalNumberOfItems}
        canLoadMore={canLoadMore}
        libraryObjectList={requirementItems}
        nextFetchCallback={searchRequirements}
        onSelectLibraryItem={onSelectRequirement}
        existingLibraryObjectIds={existingRequirementsIds}
        selectedLibraryObjectIds={selectedRequirementsIds}
        infiniteScrollWrapper={infiniteScrollWrapper}
      />
    </Modal>
  );
};

export default AddRequirementsModal;
