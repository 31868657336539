import { SpecificationData } from "models";
import { isStateInRevisionBySupplier } from "utils/specifications";

export const isStickyBarVisible = ({
  state,
  isRetailer,
  isModalVisible,
  isSpecificationSharedBySupplier,
}: {
  state: SpecificationData["state"];
  isRetailer: boolean;
  isModalVisible: boolean;
  isSpecificationSharedBySupplier: boolean;
}) =>
  !isRetailer &&
  isStateInRevisionBySupplier(state) &&
  !isModalVisible &&
  !isSpecificationSharedBySupplier;
