import { useState } from "react";
import { EnhancedExtraActions } from "components/SpecificationSection/types";
import { getFilesInfo } from "utils/files";
import { ClaimData, File } from "models";
import { ClaimFormData } from "types/specification";

export interface UseClaimFieldTemplateProps {
  formData: ClaimFormData[];
  extraActions: EnhancedExtraActions;
}

export const useClaimFieldTemplate = ({
  formData,
  extraActions,
}: UseClaimFieldTemplateProps) => {
  const [claimFileMap, setClaimFileMap] = useState({});

  const handleDisplayOnPackToggle = async (claimId: ClaimData["id"]) => {
    try {
      const updatedClaims = formData.map(claim => {
        if (claim?.claimId === claimId) {
          return {
            ...claim,
            displayOnPack: !claim?.displayOnPack,
          };
        }

        return claim;
      });

      await extraActions?.onUpdateClaims({
        claims: updatedClaims,
      });
    } catch {}
  };

  const handleRemoveClaim = async (claimId: ClaimData["id"]) => {
    try {
      const updatedClaims = formData.filter(claim => claim.claimId !== claimId);

      await extraActions?.onUpdateClaims({
        claims: updatedClaims,
      });
    } catch {}
  };

  const handleFilesUpload = (claimId: ClaimData["id"]) => async (
    files: File[]
  ) => {
    try {
      const updatedClaims = formData.map(claim => {
        if (claim?.claimId === claimId) {
          return {
            ...claim,
            supportingDocumentIds: Array.from(
              new Set([
                ...claim.supportingDocumentIds,
                ...files.map(({ id }) => id),
              ])
            ),
          };
        }

        return claim;
      });

      await extraActions?.onUpdateClaims({
        claims: updatedClaims,
      });

      setClaimFileMap(prevState => {
        const updatedFiles = [...(prevState?.[claimId] || []), ...files];

        return {
          ...prevState,
          [claimId]: updatedFiles,
        };
      });
    } catch {}
  };

  const handleFileRemove = (claimId: ClaimData["id"]) => async (file: File) => {
    try {
      const updatedClaims = formData.map(claim => {
        if (claim?.claimId === claimId) {
          return {
            ...claim,
            supportingDocumentIds: claim.supportingDocumentIds?.filter(
              documentId => documentId !== file.id
            ),
          };
        }

        return claim;
      });

      await extraActions?.onUpdateClaims({
        claims: updatedClaims,
      });

      setClaimFileMap(prevState => {
        const updatedFiles = prevState?.[claimId]?.filter(
          existingFiles => existingFiles.id !== file.id
        );

        return {
          ...prevState,
          [claimId]: updatedFiles,
        };
      });
    } catch {}
  };

  const handleEnter = async ({
    claimId,
    fileIds,
  }: {
    claimId: string;
    fileIds: string[];
  }) => {
    try {
      const files = await getFilesInfo({ fileIds });

      setClaimFileMap(prevState => ({
        ...prevState,
        [claimId]: files,
      }));
    } catch {}
  };

  return {
    handleDisplayOnPackToggle,
    handleRemoveClaim,
    handleFilesUpload,
    handleFileRemove,
    handleEnter,
    claimFileMap,
  };
};
