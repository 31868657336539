import { isListEmpty } from "utils/general";
import { Supplier } from "./types";

export const isAddButtonDisabled = ({
  isMaterialComposite,
  disableActions,
  suppliers,
}: {
  isMaterialComposite: boolean;
  disableActions: boolean;
  suppliers: Supplier[];
}) => {
  if (disableActions) return true;

  if (isListEmpty(suppliers)) return false;

  return (
    suppliers.findIndex(({ name, ingredients }) => {
      if (!name) return true;

      if (isMaterialComposite && isListEmpty(ingredients)) {
        return true;
      }

      return false;
    }) > -1
  );
};
