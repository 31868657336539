import { useState } from "react";
import classnames from "classnames";
import cleanDeep from "clean-deep";
import ErrorsList from "components/ErrorsList";
import Origin from "components/Origin";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import styles from "../styles.module.less";
import { useWidgetActions } from "../../hooks";
import { OriginWidgetProps } from "./types";
import { OriginFormData } from "../../types";
import { OriginField } from "components/Origin/types";
import OriginReadOnly from "./components/OriginReadOnly";

const OriginWidget = ({
  properties,
  formData,
  schema,
  formContext,
  idSchema,
}: OriginWidgetProps) => {
  const [actualForm, setActualForm] = useState(formData);
  const {
    displayActions,
    formData: bigFormData,
    saveTemplateBlock,
    isWidgetEditable,
    emptyValue,
    templateBlockId,
    sectionId,
    refListDictionary,
    shouldCallApi,
  } = formContext;
  const { validationRequired = [] } = schema;
  const { getTranslationForKey } = useFormSchemaTranslation();
  const { isWidgetChanged } = useWidgetActions({ templateBlockId, sectionId });

  const onChange = dataToSend => {
    setActualForm(dataToSend);

    const currentIndex = idSchema["$id"].split("_")[2];
    const originType =
      idSchema?.["$id"].search("alternativeOrigins") > -1
        ? "alternativeOrigins"
        : "mainOrigins";

    saveTemplateBlock(
      JSON.stringify({
        ...bigFormData,
        [originType]: (bigFormData?.[
          originType
        ] as OriginFormData[]).map((origin, index) =>
          `${currentIndex}` === `${index}`
            ? { ...cleanDeep(dataToSend) }
            : origin
        ),
      })
    );
  };

  const getValueForItem = name => {
    if (!displayActions) {
      return refListDictionary[actualForm[name]] || emptyValue;
    }
    return actualForm?.[name] || null;
  };

  const data: { [key: string]: OriginField } = properties.reduce(
    (previousState, property) => {
      const {
        name,
        uiSchema,
        schema: { propertyId },
      } = property.content.props;

      const propertyName = name === "zoneOrDepartmentId" ? "zoneId" : name;
      const errors = property.content.props?.errorSchema["__errors"];

      return {
        ...previousState,
        [propertyName]: {
          name,
          className: classnames({
            [styles.inRevisionChange]: isWidgetChanged(propertyId),
          }),
          label: getTranslationForKey(uiSchema["ui:title"]),
          placeholder: getTranslationForKey(uiSchema["ui:placeholder"]),
          required: validationRequired.includes(name),
          validateStatus: !!errors ? "error" : undefined,
          value: getValueForItem(name),
          disabled: !isWidgetEditable(propertyId),
          help: <ErrorsList list={errors} />,
        },
      };
    },
    {}
  );

  if (!displayActions) {
    // @ts-ignore
    return <OriginReadOnly data={data} />;
  }

  return (
    <Origin
      // @ts-ignore
      data={data}
      onChange={onChange}
      displayActions={displayActions}
      shouldCallApi={shouldCallApi}
      refListDictionary={refListDictionary}
    />
  );
};

export default OriginWidget;
