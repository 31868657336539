import { MenuActions } from "types/general";
import { isObjectEmpty } from "utils/general";
import { Action } from "./types";

export const getActionsList = ({
  actions,
  resetCurrentPage,
  currentPage,
  setShouldRefetchFolders,
}: {
  actions: Action;
  resetCurrentPage: Function;
  currentPage: number;
  setShouldRefetchFolders: Function;
}) => {
  let currentActions: MenuActions[] = [];

  if (isObjectEmpty(actions)) return [{}];

  for (const [key, action] of Object.entries(actions)) {
    switch (key) {
      case "delete":
        currentActions.push({
          ...action,
          label: action.name,
          tooltipProps: {
            ...action?.tooltipProps,
            onConfirmation: async () => {
              await action.tooltipProps.onConfirmation();

              if (currentPage === 1) setShouldRefetchFolders(true);
              else resetCurrentPage();
            },
          },
        });
        break;
      default:
        currentActions.push({ ...action, label: action.name });
    }
  }

  return currentActions;
};
