import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import axios, { CancelTokenSource } from "axios";
import { useDebounce } from "@trace-one/business-components";
import { searchCompanies } from "apis/CUMD";
import { ProductSpecification } from "store/folderCreation/types";
import { selectOwningCompanyId } from "store/user/selectors";
import {
  APPLICATION_TYPE_IDS,
  numberOfCharactersForAutoComplete,
  takeItemsOnSelectPage,
} from "utils/constants";
import { getProductOriginParams } from "../../utils";

const useCompaniesSelect = ({
  type,
  productSpecifications,
  onDefaultOnSelect,
  ignoreApplicationTypeIds,
}: {
  type: string;
  productSpecifications: ProductSpecification[];
  onDefaultOnSelect: Function;
  ignoreApplicationTypeIds?: boolean;
}) => {
  const owningCompanyId = useSelector(selectOwningCompanyId);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [productOrigins, setProductOrigins] = useState([]);
  const [source, setSource] = useState<CancelTokenSource>(null);
  const [canLoadMore, setCanLoadMore] = useState<boolean>(true);
  const [searchText, setSearchText] = useState<string>(undefined);
  const currentPage = useRef(1);
  const debouncedSearchText = useDebounce(searchText, 1000);

  const setDefaultPage = () => {
    setCanLoadMore(true);
    currentPage.current = 1;
  };

  const fetchProductOrigin = async (
    value: string = undefined,
    withLoading: boolean = false
  ) => {
    if (canLoadMore) {
      try {
        if (source) {
          source.cancel();
        }

        const newSource = axios.CancelToken.source();
        setSource(newSource);

        setIsLoading(withLoading);

        const searchValue = value ?? debouncedSearchText;

        const {
          data: { companies, skipAndTakeQueryStats },
        } = await searchCompanies({
          data: ignoreApplicationTypeIds
            ? {}
            : { applicationTypeIds: [APPLICATION_TYPE_IDS.SPEC] },
          params: {
            ...{
              ...getProductOriginParams({ type, owningCompanyId }),
            },
            isEnabled: true,
            companyDisplayName: searchValue,
            skip: (currentPage.current - 1) * takeItemsOnSelectPage,
            take: takeItemsOnSelectPage,
          },
          cancelToken: newSource.token,
        });

        const suppliers = companies.reduce((acc, curr) => {
          if (
            !productSpecifications?.some(
              specification => specification.supplierId === curr.companyId
            )
          ) {
            acc.push({
              value: curr.companyId,
              name: curr.companyDisplayName,
            });
          }

          return acc;
        }, []);

        let suppliersLength = suppliers.length;

        setProductOrigins(previousState => {
          let updatedSuppliersList;

          if (searchValue?.length === 0 && currentPage.current < 2) {
            updatedSuppliersList = [...suppliers];
          } else {
            updatedSuppliersList = [...previousState, ...suppliers];
          }

          const uniqueSuppliersList = updatedSuppliersList.filter(
            (elem, index, self) =>
              self.findIndex(t => {
                return t.value === elem.value;
              }) === index
          );

          suppliersLength = updatedSuppliersList.length;
          return uniqueSuppliersList;
        });

        currentPage.current += 1;
        setCanLoadMore(skipAndTakeQueryStats.totalCount > suppliersLength);
      } catch {
        setProductOrigins([]);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (
      debouncedSearchText?.length === 0 ||
      debouncedSearchText?.length >= numberOfCharactersForAutoComplete
    ) {
      setProductOrigins([]);
      fetchProductOrigin(debouncedSearchText, true);
    }
    setDefaultPage();
  }, [debouncedSearchText]);

  const onSearch = (searchTerm: string) => {
    setSearchText(searchTerm);
    setDefaultPage();
  };

  const onDropdownVisibleChange = (open: boolean) => {
    if (open) {
      currentPage.current = 1;
      fetchProductOrigin("", true);
    }
  };

  const onSelect = value => {
    setDefaultPage();
    onDefaultOnSelect?.(value);
  };

  const onBlur = () => setSearchText(undefined);

  return {
    isLoading,
    productOrigins,
    fetchProductOrigin,
    onSearch,
    onDropdownVisibleChange,
    onSelect,
    onBlur,
  };
};

export default useCompaniesSelect;
