import { scrollToTop } from "utils/general";

export const isNextButtonDisabled = ({
  isNextDisabled,
  isSaving,
}: {
  isNextDisabled: boolean;
  isSaving: boolean;
}) => isNextDisabled || isSaving;

export const onBackButtonClick = ({
  onBackClick,
}: {
  onBackClick: Function;
}) => () => {
  scrollToTop();

  onBackClick();
};

export const onCancelButtonClick = ({
  purge,
  onCancel,
}: {
  purge: Function;
  onCancel: Function;
}) => async () => {
  await onCancel();
  await purge();
};

export const onNextButtonClick = ({
  onNextClick,
}: {
  onNextClick: Function;
}) => () => {
  scrollToTop();

  onNextClick();
};
