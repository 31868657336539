import { isListEmpty } from "utils/general";
import { ValidationErrors, ValidationSection } from "./types";

export const getErrorsBySectionAndBlock = (sections: ValidationSection[]) => {
  const errorsBySectionAndBlock: ValidationErrors = {};

  sections.forEach(({ sectionId, errors, status }) => {
    if (status === "failure" && !isListEmpty(errors)) {
      errorsBySectionAndBlock[sectionId] = {};

      errors.forEach(({ blockId, path, error }) => {
        if (Array.isArray(errorsBySectionAndBlock[sectionId]?.[blockId])) {
          errorsBySectionAndBlock[sectionId][blockId].push({
            path,
            error,
          });
        } else {
          errorsBySectionAndBlock[sectionId][blockId] = [{ path, error }];
        }
      });
    }
  });

  return errorsBySectionAndBlock;
};
