import { useContext, useEffect } from "react";
import { useIntl } from "react-intl";
import { Col, Form, Row } from "antd";
import { InputNumber, Icon } from "@trace-one/design-system";
import { TreeSelect } from "@trace-one/react-components";
import { validateIncludingPcr } from "./utils";
import { getFieldTitleFromUiSchemaArrayTemplate } from "utils/general";
import { MaterialFormProps } from "./types";
import { messages } from "messages/packaging";
import libraryMessages from "messages/library";
import { inputErrorMessages } from "messages/errors";
import { PackagingSystemComponentMaterialArrayFieldTemplateContext } from "components/SpecificationSection/components/FormSchema/templates/ArrayFieldTemplate/PackagingSystemComponentMaterialArrayFieldTemplate/contexts";
import styles from "./styles.module.less";

const MaterialForm: React.FC<MaterialFormProps> = ({
  materialTypeCategoryData,
  materialTypeExpandedKeys,
  onMaterialTypeTreeExpand,
  uiSchema,
}) => {
  const { formatMessage } = useIntl();

  const {
    materialFormData,
    setMaterialFormData,
    setMaterialFormErrors,
  } = useContext(PackagingSystemComponentMaterialArrayFieldTemplateContext);

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(validateIncludingPcr(materialFormData));
  }, [materialFormData]);

  const colSpans = {
    labelCol: { xs: 24 },
    wrapperCol: { xs: 16 },
  };

  return (
    <Form
      name="packagingSystemComponentMaterialForm"
      initialValues={materialFormData}
      form={form}
      onValuesChange={(_, allValues) => setMaterialFormData(allValues)}
      onFieldsChange={() => setMaterialFormErrors(form.getFieldsError())}
      labelAlign="left"
      validateMessages={{
        required: formatMessage(libraryMessages.formRequired),
        number: {
          min: formatMessage(inputErrorMessages.minimumNumber, { min: 0 }),
          max: formatMessage(inputErrorMessages.maximumNumber, { max: 100 }),
        },
      }}
      {...colSpans}
    >
      <Col span={24}>
        <Form.Item
          name="type"
          label={getFieldTitleFromUiSchemaArrayTemplate(uiSchema, "type")}
          rules={[{ required: true }]}
        >
          <TreeSelect
            name="packaging-material-type"
            multiple={false}
            treeData={materialTypeCategoryData}
            placeholder={formatMessage(messages.selectMaterial)}
            treeExpandedKeys={materialTypeExpandedKeys}
            onTreeExpand={onMaterialTypeTreeExpand}
            allowClear={false}
          />
        </Form.Item>
        <Form.Item
          name="percentageOfMaterial"
          label={getFieldTitleFromUiSchemaArrayTemplate(
            uiSchema,
            "percentageOfMaterial"
          )}
          rules={[{ type: "number", min: 0, max: 100 }]}
        >
          <InputNumber
            data-testid="packaging-system-component-material-percent"
            step={0.01}
            placeholder={0}
            precision={2}
          />
        </Form.Item>
        <Form.Item
          name="thickness"
          label={getFieldTitleFromUiSchemaArrayTemplate(uiSchema, "thickness")}
          rules={[{ type: "number", min: 0 }]}
        >
          <InputNumber
            data-testid="packaging-system-component-material-thickness"
            step={0.01}
            placeholder={0}
            precision={2}
          />
        </Form.Item>
        <Form.Item
          name="percentageOfRecycledMaterials"
          label={getFieldTitleFromUiSchemaArrayTemplate(
            uiSchema,
            "percentageOfRecycledMaterials"
          )}
          rules={[{ type: "number", min: 0, max: 100 }]}
        >
          <InputNumber
            data-testid="packaging-system-component-material-percent-recycled"
            step={0.01}
            placeholder={0}
            precision={2}
          />
        </Form.Item>
        <Form.Item
          name="includingPcr"
          label={
            <Row gutter={8}>
              <Col className={styles.includingPcrIcon}>
                <Icon name="hierarchy-link" size="small" />
              </Col>
              <Col>
                <span>
                  {getFieldTitleFromUiSchemaArrayTemplate(
                    uiSchema,
                    "includingPcr"
                  )}
                </span>
              </Col>
            </Row>
          }
          rules={[{ type: "number", min: 0 }]}
        >
          <InputNumber
            data-testid="packaging-system-component-material-pcr"
            step={0.01}
            placeholder={0}
            precision={2}
            disabled={
              form.getFieldValue("percentageOfRecycledMaterials") <= 0 ||
              !materialFormData.percentageOfRecycledMaterials
            }
            className={styles.includingPcrInput}
          />
        </Form.Item>
        <Form.Item
          name="percentageOfRenewableResources"
          label={getFieldTitleFromUiSchemaArrayTemplate(
            uiSchema,
            "percentageOfRenewableResources"
          )}
          rules={[{ type: "number", min: 0, max: 100 }]}
        >
          <InputNumber
            data-testid="packaging-system-component-material-percent-renewable"
            step={0.01}
            placeholder={0}
            precision={2}
          />
        </Form.Item>
      </Col>
    </Form>
  );
};

export default MaterialForm;
