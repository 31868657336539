export const getSelectedFiltersList = ({
  selectedFilters,
  formatMessage,
  messages,
}) => () => {
  let filtersList = [];

  selectedFilters.forEach((filterValues, key) => {
    let value = filterValues;

    if (key !== "isTouched") {
      value = filterValues.map(filterValue => {
        const { nameKey, ...rest } = filterValue;
        return {
          ...rest,
          name: formatMessage(messages[filterValue.nameKey]),
        };
      });
    }

    switch (key) {
      case "isTouched":
        return;
      case "statuses":
        const statuses = value.map(status => ({
          ...status,
          value: formatMessage(messages[status.filterValue]),
        }));

        filtersList = [...statuses, ...filtersList];
        return;
      default:
        filtersList = [...value, ...filtersList];
    }
  });

  return filtersList;
};
