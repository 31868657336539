import { RequirementType } from "types/library";
import { isLibraryRequirementTypeText } from "utils/library";

export const getRequiredFields = (requirementType: RequirementType) => {
  let requiredFields = ["name"];

  if (isLibraryRequirementTypeText(requirementType)) {
    requiredFields.push("content");
  } else {
    requiredFields.push("fileId");
  }

  return requiredFields;
};
