import { Persistor } from "redux-persist";
import { scrollToTop } from "utils/general";

export const getOnCreateButtonClick = ({
  pathname,
  persistor,
  push,
}: {
  pathname: string;
  persistor: Persistor;
  push: Function;
}) => (createPageRoute: string) => async () => {
  await persistor.purge();

  scrollToTop();

  if (pathname === createPageRoute) {
    window.location.reload();
  } else {
    push(createPageRoute);
  }
};
