import { useState } from "react";
import { useIntl } from "react-intl";
import { geDefaultViewType } from "./utils";
import { messages } from "./messages";
import { LIBRARY_OBJECTS_VIEWS } from "utils/constants";

const useSwitchView = () => {
  const { formatMessage } = useIntl();
  const [viewType, setViewType] = useState<string>(geDefaultViewType());

  const viewList = [
    {
      iconName: "mosaic",
      name: formatMessage(messages.mosaicView),
      value: LIBRARY_OBJECTS_VIEWS.MOSAIC,
      "data-test-id": `view-${LIBRARY_OBJECTS_VIEWS.MOSAIC}`,
    },
    {
      iconName: "list",
      name: formatMessage(messages.listView),
      value: LIBRARY_OBJECTS_VIEWS.LIST,
      "data-test-id": `view-${LIBRARY_OBJECTS_VIEWS.LIST}`,
    },
  ];

  const onSwitchViewChange = (value: string) => {
    setViewType(value);
  };

  return {
    viewType,
    viewList,
    onSwitchViewChange,
  };
};

export default useSwitchView;
