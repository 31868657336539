import React from "react";
import { InputNumber as DSInputNumber } from "@trace-one/design-system";
import { useDebouncedActions } from "components/Debounced/hooks";
import { InputNumberProps } from "./types";

const InputNumber: React.FC<InputNumberProps> = ({
  defaultValue,
  onChange,
  shouldApplyDebounceImmediate,
  debounceTimer,
  ...rest
}) => {
  const {
    onInputNumberChange,
    handleDebouncedValueChange,
    currentValue,
  } = useDebouncedActions({
    defaultValue,
    debounceTimer,
    shouldApplyDebounceImmediate,
    onChange,
  });

  return (
    <DSInputNumber
      onChange={onInputNumberChange}
      onBlur={handleDebouncedValueChange}
      defaultValue={defaultValue}
      value={currentValue as number}
      name="debounced-input-number"
      {...rest}
    />
  );
};

InputNumber.defaultProps = {
  shouldApplyDebounceImmediate: true,
  debounceTimer: 2000,
};

export default InputNumber;
