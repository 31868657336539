import { useMemo, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useCurrentSectionsRefs, useUserInfo } from "hooks";
import SpecificationSection from "components/SpecificationSection";
import StepTitle from "components/Library/components/StepTitle";
import EmptyState from "components/Library/components/EmptyState";
import SelectedSection from "./components/SelectedSection";
import {
  isLibraryObjectDetailsPage,
  prepareSectionStatus,
  updateInfoForStructure,
} from "utils/library";
import { isListEmpty } from "utils/general";
import { ConfigureProps } from "./types";
import { ChapterStructureFormData } from "types/library";
import { messages } from "components/Library/components/ChapterForm/messages";

const HEADER_HEIGHT = 212;

const Configure: React.FC<ConfigureProps> = ({
  structure,
  sections,
  progressState,
  page,
  generalInfo,
}) => {
  const { formatMessage } = useIntl();

  const { isRetailer } = useUserInfo();

  const [configureStructure, setConfigureStructure] = useState<
    ChapterStructureFormData[]
  >([]);

  const { isStructureListEmpty, selectedSelection } = useMemo(() => {
    if (isListEmpty(configureStructure)) {
      return {
        isStructureListEmpty: true,
        selectedSelection: { sectionId: null, sections: [] },
      };
    }
    return {
      isStructureListEmpty: false,
      selectedSelection: {
        ...configureStructure[0],
        sectionStatus: prepareSectionStatus(progressState, isRetailer),
      },
    };
  }, [JSON.stringify(configureStructure)]);

  const {
    currentSectionsRefs,
    onUpdateSelectedSectionId,
    selectedSubSectionId,
  } = useCurrentSectionsRefs({
    sections: selectedSelection?.sections,
    heightOfHeader: HEADER_HEIGHT,
  });

  const onClickSubSection = (sectionId: string, subSectionId: string) => () => {
    onUpdateSelectedSectionId(subSectionId);
  };

  useEffect(() => {
    setConfigureStructure(
      updateInfoForStructure({
        structure,
        sections,
        validatedByRetailer: isRetailer,
      })
    );
  }, [structure, sections]);

  return (
    <>
      {!isLibraryObjectDetailsPage(page) && (
        <StepTitle
          title={formatMessage(messages.configureChapterTitle)}
          step={2}
          {...(!isStructureListEmpty && {
            subtitle: formatMessage(messages.configureChapterDescription, {
              chapterTypeName: configureStructure[0]?.sectionName,
              sectionCount: configureStructure[0].sections?.length,
            }),
          })}
        />
      )}

      {!isStructureListEmpty ? (
        <SpecificationSection.Content
          sections={configureStructure}
          onClickSubSection={onClickSubSection}
          selectedSectionId={selectedSelection.sectionId}
          selectedSubSectionId={selectedSubSectionId}
          headerHeight={HEADER_HEIGHT}
        >
          <SelectedSection
            selectedSection={selectedSelection}
            currentSectionsRefs={currentSectionsRefs}
            page={page}
            generalInfo={generalInfo}
          />
        </SpecificationSection.Content>
      ) : (
        <EmptyState description={formatMessage(messages.selectChapterType)} />
      )}
    </>
  );
};

export default Configure;
