import { createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import initialState from "./initialState";
import reducers from "./reducers";
import {
  addFolderContact,
  checkIfFolderIsFinalized,
  fetchExistingSpecificationsForProduct,
  fetchFolderTemplate,
  getContacts,
  getFolderCollaborations,
  getFolderRequirements,
  invalidateSteps,
  removeFolderCollaborations,
  removeFolderContact,
  removeFolderProduct,
  removeProductSpecification,
  removeProductSupplier,
  removeRequirementProduct,
  setFolderCollaboration,
  setFolderDescription,
  setFolderName,
  setFolderProducts,
  setFolderProductType,
  setFolderRequirements,
  setFolderSpecification,
  setFolderSpecificationNote,
  setFolderTargetDate,
  setFolderWizard,
  setInitialFolderDetails,
  setProductSupplier,
  setRequirementChapter,
  shareFolder,
  startFolderCreation,
  updateCurrentStep,
  validateStep,
  updateCollaboration,
  updateFolderFiles,
  setFolderTemplate,
} from "./asyncActions";
import { SPECIFICATION_STATE } from "utils/constants";

export const slice = createSlice({
  name: "folderCreation",
  initialState,
  reducers,
  extraReducers: builder => {
    /* istanbul ignore next*/
    builder.addCase(PURGE, () => ({ ...initialState }));

    builder.addCase(getContacts.fulfilled, (state, action) => {
      state.contacts = action.payload;
    });

    builder.addCase(startFolderCreation.pending, state => {
      state.isCreationInProgress = true;
    });
    builder.addCase(startFolderCreation.fulfilled, (state, action) => {
      state.folder = action.payload.folder;
      state.ownerUserDetails = action.payload.user;
      state.isCreationInProgress = false;
    });
    builder.addCase(startFolderCreation.rejected, state => {
      state.hasError = true;
      state.isCreationInProgress = false;
    });

    builder.addCase(shareFolder.pending, state => {
      state.isSharing = true;
      state.hasError = false;
    });
    builder.addCase(shareFolder.fulfilled, state => {
      state.isSharing = false;
      state.hasError = false;
    });
    builder.addCase(shareFolder.rejected, state => {
      state.isSharing = false;
      state.hasError = true;
    });

    builder.addCase(setInitialFolderDetails.pending, state => {
      state.isResumeInProgress = true;
    });
    builder.addCase(setInitialFolderDetails.fulfilled, (state, action) => {
      state.folder = action.payload.folder;
      state.requirements = action.payload.requirements;
      state.contacts = action.payload.contacts;
      state.products = action.payload.products;
      state.productSpecifications = action.payload.productSpecifications;
      state.template = action.payload.template;
      state.chapters = action.payload.chapters;
      state.ownerUserDetails = action.payload.ownerUserDetails;
      state.wizard = action.payload.wizard;
      state.isResumeInProgress = false;
    });
    builder.addCase(setInitialFolderDetails.rejected, state => {
      state.isResumeInProgress = false;
    });

    builder.addCase(setFolderName.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(setFolderName.fulfilled, (state, action) => {
      state.folder = action.payload;
      state.errors.folderName = false;
      state.isSaving = false;
    });
    builder.addCase(setFolderName.rejected, state => {
      state.errors.folderName = true;
      state.isSaving = false;
    });

    builder.addCase(setFolderProductType.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(setFolderProductType.fulfilled, (state, action) => {
      state.folder.productTypeId = action.meta.arg;
      state.folder = action.payload;
      state.errors.folderProductType = false;
      state.isSaving = false;
    });

    builder.addCase(setFolderProductType.rejected, state => {
      state.errors.folderProductType = true;
      state.isSaving = false;
    });

    builder.addCase(fetchFolderTemplate.fulfilled, (state, action) => {
      state.template = action.payload;
      state.chapters = action.payload.chapters;
    });

    builder.addCase(fetchFolderTemplate.rejected, state => {
      state.template = initialState.template;
    });

    builder.addCase(setFolderTemplate.pending, state => {
      state.isSaving = true;
    });

    builder.addCase(setFolderTemplate.fulfilled, (state, action) => {
      state.folder = action.payload;
      state.requirements = action.payload.requirementAssociations;
      state.errors.template = false;
      state.isSaving = false;
    });

    builder.addCase(setFolderTemplate.rejected, state => {
      state.errors.template = true;
      state.isSaving = false;
    });

    builder.addCase(setFolderTargetDate.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(setFolderTargetDate.fulfilled, (state, action) => {
      state.folder = action.payload;
      state.errors.targetDateUtc = false;
      state.isSaving = false;
    });
    builder.addCase(setFolderTargetDate.rejected, state => {
      state.errors.targetDateUtc = true;
      state.isSaving = false;
    });

    builder.addCase(addFolderContact.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(addFolderContact.fulfilled, (state, action) => {
      const contacts = [...state.contacts];

      state.contacts = contacts.map(contact => ({
        ...contact,
        isSelected:
          contact.userId === action.meta.arg ? true : contact.isSelected,
      }));
      state.folder = action.payload;
      state.errors.contacts = false;
      state.isSaving = false;
    });
    builder.addCase(addFolderContact.rejected, state => {
      state.errors.contacts = true;
      state.isSaving = false;
    });

    builder.addCase(removeFolderContact.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(removeFolderContact.fulfilled, (state, action) => {
      const contacts = [...state.contacts];

      state.contacts = contacts.map(contact => ({
        ...contact,
        isSelected:
          contact.userId === action.meta.arg ? false : contact.isSelected,
      }));
      state.folder = action.payload;
      state.errors.contacts = false;
      state.isSaving = false;
    });
    builder.addCase(removeFolderContact.rejected, state => {
      state.errors.contacts = true;
      state.isSaving = false;
    });

    builder.addCase(setFolderDescription.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(setFolderDescription.fulfilled, (state, action) => {
      state.folder = action.payload;
      state.errors.folderDescription = false;
      state.isSaving = false;
    });
    builder.addCase(setFolderDescription.rejected, state => {
      state.errors.folderDescription = true;
      state.isSaving = false;
    });

    builder.addCase(setFolderProducts.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(setFolderProducts.fulfilled, (state, action) => {
      state.products = [...state.products, ...action.meta.arg];
      state.folder = action.payload;
      state.errors.addProducts = false;
      state.isSaving = false;
    });
    builder.addCase(setFolderProducts.rejected, state => {
      state.errors.addProducts = true;
      state.isSaving = false;
    });

    builder.addCase(removeFolderProduct.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(removeFolderProduct.fulfilled, (state, action) => {
      state.products = state.products.filter(
        product => product.id !== action.meta.arg
      );
      delete state.productSpecifications[action.meta.arg];
      state.folder = action.payload.folder;
      state.requirements = action.payload.requirements;
      state.isSaving = false;
      state.errors.removeProduct[action.meta.arg] = false;
    });
    builder.addCase(removeFolderProduct.rejected, (state, action) => {
      state.errors.removeProduct[action.meta.arg] = true;
      state.isSaving = false;
    });

    builder.addCase(
      fetchExistingSpecificationsForProduct.fulfilled,
      (state, action) => {
        state.productSpecifications = {
          ...state.productSpecifications,
          [action.meta.arg]: action.payload,
        };
      }
    );

    builder.addCase(setProductSupplier.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(setProductSupplier.fulfilled, (state, action) => {
      const { tradeItemId } = action.meta.arg;

      state.productSpecifications[tradeItemId] = [
        ...state.productSpecifications[tradeItemId],
        action.payload,
      ];
      state.errors.addProductSupplier[tradeItemId] = false;
      state.isSaving = false;
    });
    builder.addCase(setProductSupplier.rejected, (state, action) => {
      const { tradeItemId } = action.meta.arg;
      state.errors.addProductSupplier[tradeItemId] = true;

      state.isSaving = false;
    });

    builder.addCase(removeProductSupplier.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(removeProductSupplier.fulfilled, (state, action) => {
      const { tradeItemId } = action.meta.arg;

      state.productSpecifications[tradeItemId] = state.productSpecifications[
        tradeItemId
      ].filter(
        currentSpecification =>
          currentSpecification.specificationId !== action.payload
      );
      state.errors.removeProductSpecifications[tradeItemId] = {
        ...state.errors.removeProductSpecifications[tradeItemId],
        [action.payload]: false,
      };
      state.isSaving = false;
    });
    builder.addCase(removeProductSupplier.rejected, (state, action) => {
      const { tradeItemId, specificationId } = action.meta.arg;

      state.errors.removeProductSpecifications[tradeItemId] = {
        ...state.errors.removeProductSpecifications[tradeItemId],
        [specificationId]: true,
      };
      state.isSaving = false;
    });

    builder.addCase(setFolderSpecification.pending, state => {
      state.isSaving = true;
    });

    builder.addCase(setFolderSpecification.fulfilled, (state, action) => {
      const { tradeItemId, specificationId } = action.meta.arg;

      state.productSpecifications[tradeItemId] = state.productSpecifications[
        tradeItemId
      ].map(currentSpecification => ({
        ...currentSpecification,
        ...(currentSpecification.specificationId === specificationId && {
          folderId: state.folder.folderId,
          versionNumber: action.payload.version.number,
          state: SPECIFICATION_STATE[action.payload.state],
          specificationId: action.payload.id,
        }),
      }));

      state.errors.addProductSpecifications[tradeItemId] = {
        ...state.errors.addProductSpecifications[tradeItemId],
        [specificationId]: false,
      };

      state.isSaving = false;
    });
    builder.addCase(setFolderSpecification.rejected, (state, action) => {
      const { tradeItemId, specificationId } = action.meta.arg;

      state.errors.addProductSpecifications[tradeItemId] = {
        ...state.errors.addProductSpecifications[tradeItemId],
        [specificationId]: true,
      };

      state.isSaving = false;
    });

    builder.addCase(removeProductSpecification.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(removeProductSpecification.fulfilled, (state, action) => {
      const { tradeItemId, specificationId } = action.meta.arg;

      state.productSpecifications[tradeItemId] = state.productSpecifications[
        tradeItemId
      ].map(currentSpecification => ({
        ...currentSpecification,
        folderId:
          currentSpecification.specificationId === specificationId
            ? null
            : currentSpecification.folderId,
      }));

      state.errors.removeProductSpecifications[tradeItemId] = {
        ...state.errors.removeProductSpecifications[tradeItemId],
        [specificationId]: false,
      };

      state.isSaving = false;
    });
    builder.addCase(removeProductSpecification.rejected, (state, action) => {
      const { tradeItemId, specificationId } = action.meta.arg;

      state.errors.removeProductSpecifications[tradeItemId] = {
        ...state.errors.removeProductSpecifications[tradeItemId],
        [specificationId]: true,
      };

      state.isSaving = false;
    });

    builder.addCase(setFolderSpecificationNote.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(setFolderSpecificationNote.fulfilled, (state, action) => {
      state.folder = action.payload;
      state.errors.specificationNote = false;
      state.isSaving = false;
    });
    builder.addCase(setFolderSpecificationNote.rejected, state => {
      state.errors.specificationNote = true;
      state.isSaving = false;
    });

    builder.addCase(getFolderRequirements.fulfilled, (state, action) => {
      state.folder = action.payload.folder;
      state.requirements = action.payload.requirements;
    });

    builder.addCase(setFolderRequirements.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(setFolderRequirements.fulfilled, (state, action) => {
      state.requirements = action.payload.requirements;
      state.folder = action.payload.folder;
      state.isSaving = false;
      state.errors.addRequirements = false;
    });
    builder.addCase(setFolderRequirements.rejected, state => {
      state.isSaving = false;
      state.errors.addRequirements = true;
    });

    builder.addCase(setRequirementChapter.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(setRequirementChapter.fulfilled, (state, action) => {
      const { chapterType, requirementId } = action.meta.arg;

      state.requirements = state.requirements.map(requirement => ({
        ...requirement,
        chapterType:
          requirement.requirementId === requirementId
            ? chapterType
            : requirement.chapterType,
      }));
      state.folder = action.payload;
      state.isSaving = false;
      state.errors.changeChapters[requirementId] = false;
    });
    builder.addCase(setRequirementChapter.rejected, (state, action) => {
      state.isSaving = false;
      state.errors.changeChapters[action.meta.arg.requirementId] = true;
    });

    builder.addCase(removeRequirementProduct.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(removeRequirementProduct.fulfilled, (state, action) => {
      state.requirements = action.payload.requirements;
      state.folder = action.payload.folder;
      state.errors.removeRequirementProducts[action.meta.arg.requirementId] = {
        ...state.errors.removeRequirementProducts[
          action.meta.arg.requirementId
        ],
        [action.meta.arg.tradeItemId]: false,
      };
      state.isSaving = false;
    });
    builder.addCase(removeRequirementProduct.rejected, (state, action) => {
      state.errors.removeRequirementProducts[action.meta.arg.requirementId] = {
        ...state.errors.removeRequirementProducts[
          action.meta.arg.requirementId
        ],
        [action.meta.arg.tradeItemId]: true,
      };

      state.isSaving = false;
    });

    builder.addCase(getFolderCollaborations.pending, state => {
      state.areCollaborationsLoading = true;
    });
    builder.addCase(getFolderCollaborations.fulfilled, (state, action) => {
      state.folder = action.payload;
      state.areCollaborationsLoading = false;
    });
    builder.addCase(getFolderCollaborations.rejected, state => {
      state.areCollaborationsLoading = false;
    });

    builder.addCase(setFolderCollaboration.pending, state => {
      state.isSaving = true;
      state.errors.addCollaboration = false;
    });
    builder.addCase(setFolderCollaboration.fulfilled, (state, action) => {
      state.folder = action.payload;
      state.isSaving = false;
    });
    builder.addCase(setFolderCollaboration.rejected, state => {
      state.isSaving = false;
      state.errors.addCollaboration = true;
    });

    builder.addCase(removeFolderCollaborations.pending, state => {
      state.isSaving = true;
      state.errors.removeCollaborations = false;
    });
    builder.addCase(removeFolderCollaborations.fulfilled, (state, action) => {
      state.folder = action.payload;
      state.isSaving = false;
    });
    builder.addCase(removeFolderCollaborations.rejected, state => {
      state.isSaving = false;
      state.errors.removeCollaborations = true;
    });

    builder.addCase(setFolderWizard.pending, state => {
      state.isSaving = true;
      state.errors.wizard = false;
    });
    builder.addCase(setFolderWizard.fulfilled, (state, action) => {
      state.wizard = action.payload;
      state.isSaving = false;
    });
    builder.addCase(setFolderWizard.rejected, state => {
      state.isSaving = false;
      state.errors.wizard = true;
    });

    builder.addCase(checkIfFolderIsFinalized.pending, state => {
      state.isFolderCheckInProgress = true;
    });
    builder.addCase(checkIfFolderIsFinalized.fulfilled, (state, action) => {
      state.isFolderFinalized = action.payload;
      state.isFolderCheckInProgress = false;
    });
    builder.addCase(checkIfFolderIsFinalized.rejected, state => {
      state.isFolderCheckInProgress = false;
    });

    builder.addCase(updateCurrentStep.fulfilled, (state, action) => {
      state.wizard = action.payload;
    });

    builder.addCase(validateStep.fulfilled, (state, action) => {
      state.wizard = action.payload;
    });

    builder.addCase(invalidateSteps.fulfilled, (state, action) => {
      state.wizard = action.payload;
    });

    builder.addCase(updateCollaboration.fulfilled, (state, action) => {
      state.wizard = action.payload;
    });

    builder.addCase(updateFolderFiles.pending, state => {
      state.isSaving = true;
      state.errors.files = false;
    });

    builder.addCase(updateFolderFiles.fulfilled, (state, action) => {
      state.folder = action.payload;
      state.isSaving = false;
    });

    builder.addCase(updateFolderFiles.rejected, state => {
      state.isSaving = false;
      state.errors.files = true;
    });
  },
});

export const {
  setFolderId,
  resetFolderCreationErrors,
  setIsInvitationStepValid,
  setFolderChapters,
} = slice.actions;

export default slice.reducer;
