import { Datatable } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import FormCard from "../../../FormCard";
import { useAllergensInOrder } from "components/Library/components/MaterialForm/hooks";
import { useAllergensTable } from "./hooks";
import { AllergensProps } from "./types";
import libraryMessages from "messages/library";
import styles from "components/Library/components/MaterialForm/styles.module.less";

const Allergens: React.FC<AllergensProps> = ({
  allergenDictionary,
  allergens,
}) => {
  const { formatMessage } = useIntl();
  const { columns } = useAllergensTable({
    allergenDictionary,
  });

  const { currentAllergens } = useAllergensInOrder({
    allergenDictionary,
    selectedAllergens: allergens,
  });

  return (
    <FormCard
      className={styles.rawMaterialFormWideCardBody}
      title={formatMessage(libraryMessages.cardTitle["allergens"])}
      name="allergens"
    >
      <Datatable
        tableLayout="fixed"
        columns={columns}
        dataSource={currentAllergens}
        emptyText={formatMessage(libraryMessages.noAllergens)}
        rowKey={({ allergenId, declaration }) => `${allergenId}-${declaration}`}
        pagination={false}
      />
    </FormCard>
  );
};

export default Allergens;
