import { defineMessages } from "react-intl";

export const messages = defineMessages({
  owner: {
    id: "folderDetails.details.owner",
    defaultMessage: "Owner",
  },
  errorOwnerMessage: {
    id: "folderDetails.error.ownerMessage",
    defaultMessage: "Sorry, an error occured while accessing User data.",
  },
});
