import { AxiosRequestConfig } from "axios";
import { LibraryObjectData, TemplateData } from "models";
import { axiosInstance } from "./";
import { API_VERSION } from "apis/constants";
import { PaginatedList, PaginationParams } from "types/api";

export const fetchTemplateById = ({
  templateId,
  languageCode,
  excludeHiddenSections,
}: {
  templateId: string;
  languageCode: string;
  excludeHiddenSections?: boolean;
}) =>
  axiosInstance.get<TemplateData>(
    `/api/${API_VERSION}templates/${templateId}`,
    {
      params: {
        languageCode,
        excludeHiddenSections,
      },
    }
  );

export const hideTemplateSection = ({
  templateId,
  templateSectionId,
  languageCode,
}: {
  templateId: string;
  templateSectionId: string;
  languageCode: string;
}) =>
  axiosInstance.post<TemplateData>(
    `/api/${API_VERSION}templates/${templateId}/sections/${templateSectionId}/hide`,
    {},
    {
      params: {
        languageCode,
      },
    }
  );

export const unhideTemplateSection = ({
  templateId,
  templateSectionId,
  languageCode,
}: {
  templateId: string;
  templateSectionId: string;
  languageCode: string;
}) =>
  axiosInstance.post<TemplateData>(
    `/api/${API_VERSION}templates/${templateId}/sections/${templateSectionId}/un-hide`,
    {},
    {
      params: {
        languageCode,
      },
    }
  );

export const moveDownTemplateSection = ({
  templateId,
  templateSectionId,
  languageCode,
  number,
}: {
  templateId: string;
  templateSectionId: string;
  languageCode: string;
  number: number;
}) =>
  axiosInstance.post<TemplateData>(
    `/api/${API_VERSION}templates/${templateId}/sections/${templateSectionId}/move-down`,
    {
      number,
    },
    {
      params: {
        languageCode,
      },
    }
  );

export const moveUpTemplateSection = ({
  templateId,
  templateSectionId,
  languageCode,
  number,
}: {
  templateId: string;
  templateSectionId: string;
  languageCode: string;
  number: number;
}) =>
  axiosInstance.post<TemplateData>(
    `/api/${API_VERSION}templates/${templateId}/sections/${templateSectionId}/move-up`,
    {
      number,
    },
    {
      params: {
        languageCode,
      },
    }
  );

export const updateTemplateSectionComment = ({
  templateId,
  templateSectionId,
  comment,
  languageCode,
}: {
  templateId: string;
  templateSectionId: string;
  comment: string;
  languageCode: string;
}) =>
  axiosInstance.patch<TemplateData>(
    `/api/${API_VERSION}templates/${templateId}/sections/${templateSectionId}/comment`,
    {
      comment,
    },
    {
      params: {
        languageCode,
      },
    }
  );

export const fetchTemplates = ({
  params,
  paramsSerializer,
}: {
  params: PaginationParams & {
    ownerCompanyId: string;
    name?: string;
    productTypeId?: string;
    excludeDrafts?: boolean;
  };
  paramsSerializer?: AxiosRequestConfig["paramsSerializer"];
}) =>
  axiosInstance.get<PaginatedList<LibraryObjectData>>(`/api/templates/search`, {
    params,
    paramsSerializer,
  });
