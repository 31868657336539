import { CategoryItemData } from "models";
import { isObjectEmpty } from "utils/general";

const getOnCategoryChange = ({
  setProductCategoryItem,
  setProductCategoryId,
}: {
  setProductCategoryItem: Function;
  setProductCategoryId: Function;
}) => (selectedValue?: CategoryItemData) => {
  if (!isObjectEmpty(selectedValue)) {
    setProductCategoryId(selectedValue.categoryId);

    setProductCategoryItem({
      value: selectedValue.categoryItemId,
      label: selectedValue.categoryItemName,
    });
  } else {
    setProductCategoryItem(undefined);
  }
};

export default getOnCategoryChange;
