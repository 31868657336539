import { utils } from "@rjsf/core";
import classNames from "classnames";
import { ObjectFieldTemplateProps } from "components/SpecificationSection/types";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { UseColumnProps } from "./types";
import styles from "components/SpecificationSection/components/FormSchema/styles.module.less";

const { resolveSchema } = utils;

const useColumnProps = ({ schema, formData, uiSchema }: UseColumnProps) => {
  const { getTranslationForKey } = useFormSchemaTranslation();
  const nutrientFamiliesSchema = resolveSchema(
    // @ts-ignore-next
    schema?.properties?.nutrientFamilies?.items,
    schema,
    formData
  );

  const itemsSchema = resolveSchema(
    //@ts-ignore
    nutrientFamiliesSchema?.properties?.nutrients?.items,
    schema,
    formData
  ) as ObjectFieldTemplateProps["schema"];

  const getColumnProps = (columnKey: string) => ({
    title: getTranslationForKey(
      uiSchema?.nutrientFamilies?.items?.nutrients?.items?.[columnKey]?.[
        "ui:title"
      ]
    ),
    dataIndex: columnKey,
    className: classNames({
      [styles.required]: itemsSchema.validationRequired.includes(columnKey),
    }),
  });
  return {
    getColumnProps,
  };
};

export default useColumnProps;
