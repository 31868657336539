import { useMemo } from "react";
import { Button, Modal, Paragraph } from "@trace-one/design-system";
import { useIntl, FormattedMessage } from "react-intl";
import NutritionBasic from "components/SpecificationSection/components/FormSchema/components/NutritionBasic";
import NutritionServingForm from "./components/NutritionServingForm";
import { getNutritionFamilyStatus } from "components/SpecificationSection/components/FormSchema/components/NutritionBasic/utils";
import { useNutritionTable } from "components/SpecificationSection/components/FormSchema/components/NutritionBasic/shared/hooks";
import { useNutritionColumns, useNutritionServingForm } from "./hooks";
import { NutritionDeclarationWidgetProps } from "./types";
import { messages } from "./messages";
import ConfirmationModal from "components/ConfirmationModal";
import generalMessages from "messages/general";
import useRemoveServing from "./hooks/useRemoveServing";
import { useRbac } from "hooks";
import { PERMISSIONS } from "utils/constants";
import styles from "./styles.module.less";

const NutritionDeclarationWidget: React.FC<NutritionDeclarationWidgetProps> = ({
  formData,
  formContext,
  schema,
  uiSchema,
  properties,
}) => {
  const { formatMessage } = useIntl();

  const {
    saveTemplateBlock,
    displayActions,
    extraActions,
    specificationData,
    chapterData,
    sectionId,
    isTargetMode,
  } = formContext;

  const permissions = useMemo(() => {
    return (
      chapterData?.sections?.find(section => section?.sectionId === sectionId)
        ?.permissions || []
    );
  }, [formContext?.chapterData]);

  const { hasPermission } = useRbac(permissions);
  const hasUpdatePermission = hasPermission(PERMISSIONS.SECTION.SAVE);

  const {
    dataSource,
    nutrientDictionary,
    mandatoryNutrientIds,
  } = useNutritionTable({
    formData,
  });

  const {
    isModalOpen,
    setIsModalOpen,
    nutritionServingFormData,
    setNutritionServingFormData,
    setFormErrors,
    handleServingNameChange,
    handleServingSizeChange,
    handleAutomaticCalculationChange,
    getIsPrimaryModalButtonDisabled,
    onPrimaryButtonClick,
    onSecondaryButtonClick,
    selectedUnit,
    onClearUnit,
    items,
    buttonProps,
    isCalculationToggleDisabled,
    shouldRefetchServings,
    setShouldRefetchServings,
    onEditServing,
    isEditMode,
  } = useNutritionServingForm({
    extraActions,
    hasUpdatePermission,
  });

  const {
    showRemoveServingModal,
    onCloseRemoveServing,
    onConfirmRemoveServing,
    onRemoveServing,
  } = useRemoveServing({
    onDeleteNutritionServing: extraActions?.onDeleteNutritionServing,
    setShouldRefetchServings,
  });

  const { columns } = useNutritionColumns({
    formData,
    formContext,
    uiSchema,
    schema,
    errorSchema: properties?.[0]?.content?.props?.errorSchema,
    statusNutrientFamilyDictionary: getNutritionFamilyStatus(dataSource),
    nutrientDictionary,
    mandatoryNutrientIds,
    saveTemplateBlock,
    extraActions,
    displayActions,
    specificationId: specificationData?.specificationId,
    onRemoveServing,
    onEditServing,
    shouldRefetchServings,
    hasUpdatePermission,
  });

  return (
    <>
      <Button
        onClick={() => setIsModalOpen(true)}
        data-test-id="add-nutrition-declaration-serving"
        disabled={!hasUpdatePermission || isTargetMode}
      >
        {formatMessage(messages.addServing)}
      </Button>
      <Modal.Simple
        title={formatMessage(
          isEditMode ? messages.editServing : messages.modalTitle
        )}
        open={isModalOpen}
        onCancel={onSecondaryButtonClick}
        primaryButtonText={formatMessage(messages.modalPrimaryButton)}
        secondaryButtonText={formatMessage(messages.modalSecondaryButton)}
        onPrimaryButtonClick={onPrimaryButtonClick}
        onSecondaryButtonClick={onSecondaryButtonClick}
        primaryButtonProps={{ disabled: getIsPrimaryModalButtonDisabled() }}
      >
        {!isEditMode && (
          <Paragraph className={styles.modalSubtitle}>
            <FormattedMessage {...messages.modalSubtitle} />
          </Paragraph>
        )}
        <NutritionServingForm
          nutritionServingFormData={nutritionServingFormData}
          setNutritionServingFormData={setNutritionServingFormData}
          setFormErrors={setFormErrors}
          selectedUnit={selectedUnit}
          onClearUnit={onClearUnit}
          items={items}
          buttonProps={buttonProps}
          handleServingNameChange={handleServingNameChange}
          handleServingSizeChange={handleServingSizeChange}
          handleAutomaticCalculationChange={handleAutomaticCalculationChange}
          isCalculationToggleDisabled={isCalculationToggleDisabled}
          isEditMode={isEditMode}
        />
      </Modal.Simple>
      <NutritionBasic
        formContext={formContext}
        schema={schema}
        columns={columns}
        dataSource={dataSource}
      />

      {showRemoveServingModal && (
        <ConfirmationModal
          onCloseModal={() => onCloseRemoveServing(false)}
          onConfirmModal={onConfirmRemoveServing}
          title={formatMessage(messages.removeServing)}
          primaryButtonText={formatMessage(generalMessages.confirm)}
          illustrationName="delete"
        >
          {formatMessage(messages.removeServingDescription)}
        </ConfirmationModal>
      )}
    </>
  );
};

export default NutritionDeclarationWidget;
