import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import FormCard from "../FormCard";
import SpecificationSection from "components/SpecificationSection";
import { useControlPlans } from "./hooks";
import { selectControlPlansSchema } from "store/materialForm/selectors";
import { messages } from "./messages";
import { getParsedData } from "utils/general";
import { ControlPlansProps } from "./types";

const ControlPlans: React.FC<ControlPlansProps> = ({
  displayActions = true,
  materialId,
  materialType,
  recipeType,
  refListDictionary,
}) => {
  const { formatMessage } = useIntl();
  const materialControlPlansSchema = useSelector(selectControlPlansSchema);

  const { isWidgetEditable, saveTemplateBlock } = useControlPlans({
    displayActions,
    materialId,
    materialType,
    recipeType,
  });

  const formData = getParsedData(materialControlPlansSchema?.json);

  return (
    <FormCard
      title={formatMessage(messages.controlPlanCardTitle)}
      name="controlPlans"
    >
      <SpecificationSection.FormSchema
        schema={getParsedData(materialControlPlansSchema?.jsonSchema)}
        uiSchema={getParsedData(materialControlPlansSchema?.uiSchema)}
        formData={formData}
        formContext={{
          shouldCallApi: false,
          refListDictionary,
          type: "library-object",
          displayActions,
          formData,
          isWidgetEditable,
          saveTemplateBlock,
        }}
      />
    </FormCard>
  );
};

export default ControlPlans;
