import { State } from "./types";

const reducers = {
  setAccessToken: (state: State, action) => {
    state.accessToken = action.payload;
  },
  setCompanyActivity: (state: State, action) => {
    state.companyActivity = action.payload;
  },
  setCompanyCountry: (state: State, action) => {
    state.companyCountry = action.payload;
  },
  setUserPermissions: (state: State, action) => {
    state.permissions = action.payload;
  },
};

export default reducers;
