import { defineMessages } from "react-intl";

export const messages = defineMessages({
  addExistingSpecifications: {
    id: "creationFolder.products.addExistingSpecifications",
    defaultMessage: "Add existing specifications for this product",
  },

  existingSpecifications: {
    id: "creationFolder.products.existingSpecifications",
    defaultMessage: "Existing specifications for this product",
  },

  errorFieldMessage: {
    id: "creation.errors.notSaved",
    defaultMessage: "An error has occurred, the last changes were not saved",
  },
});
