import { useContext } from "react";
import { useIntl } from "react-intl";
import { Button, Tooltip } from "@trace-one/design-system";
import { NutritionBasicContext } from "components/SpecificationSection/components/FormSchema/components/NutritionBasic/contexts";
import { useNutrientAction } from "./hooks";
import { DeleteNutrientActionProps } from "./types";
import { messages } from "../../messages";

export const DeleteNutrientAction: React.FC<DeleteNutrientActionProps> = ({
  nutrientData,
  disabled,
}) => {
  const { formContext } = useContext(NutritionBasicContext);
  const { extraActions } = formContext;

  const { formatMessage } = useIntl();
  const {
    onDeleteNutrient,
    isTooltipVisible,
    onTooltipVisibleChange,
  } = useNutrientAction({
    deleteNutritionAction: extraActions?.deleteNutritionAction,
  });

  return (
    <>
      <Tooltip
        text={formatMessage(messages.removeElementConfirmationMessage)}
        trigger="click"
        actions={[
          {
            text: formatMessage(messages.cancelRemoveElement),
            onClick: () => onTooltipVisibleChange(false),
          },
          {
            text: formatMessage(messages.deleteElement),
            onClick: onDeleteNutrient(nutrientData.nutrientId),
          },
        ]}
        visible={isTooltipVisible}
        onVisibleChange={onTooltipVisibleChange}
        placement="left"
      >
        <Button
          type="tertiary"
          iconName="trash"
          disabled={disabled}
          onClick={() => onTooltipVisibleChange(true)}
        />
      </Tooltip>
    </>
  );
};

export default DeleteNutrientAction;
