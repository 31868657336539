import { Datatable } from "@trace-one/design-system";
import styles from "./styles.module.less";

const PdfTable = ({ columns, formContext, formData }) => {
  const { refListDictionary } = formContext;

  const dataSource = Object.entries(columns).reduce(
    (previousState, [key, column]) => {
      if (column?.["ui:hiddenForContext"] === "PDF") {
        return [...previousState];
      }

      return [
        ...previousState,
        {
          columnName: column["ui:title"],
          height: column["ui:width"],
          color: column["ui:color"],
          ...formData.reduce(
            (previousState, currentItem) => ({
              ...previousState,
              [currentItem.id]:
                refListDictionary[currentItem[key]] || currentItem[key],
            }),
            {}
          ),
        },
      ];
    },
    []
  );

  const tableColumns = [
    {
      dataIndex: "columnName",
    },
    ...formData.map(item => ({
      dataIndex: item.id,
      render: item => <div className={styles?.["row-content"]}>{item}</div>,
    })),
  ];

  return (
    <Datatable
      name="pdf-table"
      className={styles?.["pdf-table"]}
      showHeader={false}
      dataSource={dataSource}
      columns={tableColumns?.reverse()}
      pagination={false}
      rowKey="columnName"
      rowClassName={styles?.row}
    />
  );
};

export default PdfTable;
