import { useState, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useFilters, useQuery } from "hooks";
import { selectCompanyActivity } from "store/oidc/selectors";
import {
  getOnCategoryChange,
  getOnClearAllFilters,
  getOnClearSearch,
  getOnSearchChange,
  getOnStatusClick,
  getOnStatusRemove,
  getStatuses,
} from "pages/utils";
import {
  getKeyForServiceType,
  getProductOrigin,
  prepareInitialSelectedServiceTypes,
  prepareInitialselectedStatuses,
} from "./utils";
import FiltersComponent from "components/Filters";
import filterMessages from "messages/filters";
import { messages } from "./messages";
import { FiltersProps } from "./types";
import { Filter } from "types/filters";
import { CompanySelect } from "components";

const Filters: React.FC<FiltersProps> = ({ onUpdateFilters }) => {
  const intl = useIntl();
  const query = useQuery();
  const companyActivity = useSelector(selectCompanyActivity);
  const productOrigin = getProductOrigin({
    companyActivity,
  });

  const statuses = useMemo(
    () =>
      getStatuses({
        type: "specification",
      }),
    [companyActivity]
  );
  const initialselectedStatuses = useMemo(
    prepareInitialselectedStatuses({
      formatMessage: intl.formatMessage,
      messages,
      specificationStatus: query.get("statuses"),
      statuses,
    }),
    [query.get("statuses")]
  );
  const initialSelectedServiceTypes = useMemo(
    prepareInitialSelectedServiceTypes({
      serviceTypeId: query.get("serviceTypeId"),
      serviceTypeName: query.get("serviceTypeName"),
    }),
    [query.get("serviceTypeId"), query.get("serviceTypeName")]
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStatuses, setSelectedStatuses] = useState(
    initialselectedStatuses
  );
  const [selectedServiceTypes, setSelectedServiceTypes] = useState<Filter[]>(
    initialSelectedServiceTypes
  );
  const [selectedProductTypes, setSelectedProductTypes] = useState<Filter[]>(
    []
  );
  const [selectedUsers, setSelectedUsers] = useState<Filter[]>([]);
  const [productCategoryId, setProductCategoryId] = useState("");
  const [productCategoryItem, setProductCategoryItem] = useState<Filter>();

  const {
    handleChangeSelectedFilters,
    prepareSelectedFilters,
    selectedFilters,
    selectedFiltersList,
  } = useFilters();

  const onSearch = () => {
    onUpdateFilters({
      specificationName: searchTerm?.trim(),
    });
  };

  useEffect(() => {
    prepareSelectedFilters({
      currentSelectedFilters: selectedServiceTypes,
      setCurrentSelectedFilters: setSelectedServiceTypes,
      key: getKeyForServiceType(productOrigin),
      nameKey: productOrigin,
    });
  }, [selectedServiceTypes]);

  useEffect(() => {
    prepareSelectedFilters({
      currentSelectedFilters: selectedUsers,
      setCurrentSelectedFilters: setSelectedUsers,
      key: "ownerOrOwnerContactIds",
      nameKey: "ownerOrRetailerContact",
    });
  }, [selectedUsers]);

  useEffect(() => {
    prepareSelectedFilters({
      currentSelectedFilters: selectedProductTypes,
      setCurrentSelectedFilters: setSelectedProductTypes,
      key: "productTypeIds",
      nameKey: "productType",
    });
  }, [selectedProductTypes]);

  useEffect(() => {
    prepareSelectedFilters({
      currentSelectedFilters: selectedStatuses,
      setCurrentSelectedFilters: setSelectedStatuses,
      key: "statuses",
      nameKey: "status",
    });
  }, [selectedStatuses]);

  useEffect(() => {
    prepareSelectedFilters({
      currentSelectedFilters: productCategoryItem,
      singleFilter: true,
      setCurrentSelectedFilters: setProductCategoryItem,
      key: "categoryItemId",
      nameKey: "category",
    });
  }, [productCategoryItem]);

  useEffect(() => {
    handleChangeSelectedFilters({
      onFilterSelect: onUpdateFilters,
      selectedFilters,
      productCategoryId,
    });
  }, [selectedFilters]);

  return (
    <FiltersComponent.Container
      filters={[
        {
          label: intl.formatMessage(filterMessages[productOrigin]),
          content: (
            <CompanySelect
              type={productOrigin}
              mode="multiple"
              onChange={setSelectedServiceTypes}
              values={selectedServiceTypes}
              labelInValue
            />
          ),
        },
        {
          label: intl.formatMessage(filterMessages.ownerOrRetailerContact),
          content: (
            <FiltersComponent.OwnersOrContacts
              data-testid="owner-or-owner-contact-ids"
              value={selectedUsers}
              onChange={setSelectedUsers}
            />
          ),
        },
        {
          label: intl.formatMessage(filterMessages.productType),
          content: (
            <FiltersComponent.ProductTypes
              selectedProductTypes={selectedProductTypes}
              setSelectedProductTypes={setSelectedProductTypes}
            />
          ),
        },
        {
          label: intl.formatMessage(filterMessages.category),
          content: (
            <FiltersComponent.ProductTreeCategories
              productCategoryItem={productCategoryItem}
              onCategoryChange={getOnCategoryChange({
                setProductCategoryItem,
                setProductCategoryId,
              })}
            />
          ),
        },
        {
          label: intl.formatMessage(filterMessages.status),
          content: (
            <FiltersComponent.Statuses
              statuses={statuses}
              selectedStatuses={selectedStatuses}
              onStatusRemove={getOnStatusRemove({
                setSelectedStatuses,
              })}
              onStatusClick={getOnStatusClick({
                setSelectedStatuses,
              })}
            />
          ),
        },
      ]}
      onClearFiltersClick={getOnClearAllFilters({
        setSelectedStatuses,
        setSelectedProductTypes,
        setSelectedUsers,
        setSelectedServiceTypes,
        setProductCategoryItem,
        setProductCategoryId,
      })}
      onClearSearch={getOnClearSearch({ setSearchTerm })}
      onSearch={onSearch}
      onSearchChange={getOnSearchChange({ setSearchTerm })}
      searchPlaceholder={intl.formatMessage(messages.searchPlaceholder)}
      searchValue={searchTerm}
      selectedFilters={selectedFiltersList}
    />
  );
};

export default Filters;
