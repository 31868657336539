import { defineMessages } from "react-intl";

export const messages = defineMessages({
  homeTitle: {
    id: "home.title",
    defaultMessage: "Welcome !",
  },
  sectionTitle: {
    id: "home.sectionTitle.title",
    defaultMessage: "Data from all your files and specifications",
  },
  sectionSubtitle: {
    id: "home.sectionTitle.subtitle",
    defaultMessage: "An overview of all your tasks",
  },
  firstParagraph: {
    id: "home.content.firstParagraph",
    defaultMessage: "It's quite empty here !",
  },
  secondParagraph: {
    id: "home.content.secondParagraph",
    defaultMessage:
      "On this page you can add some widget(s) to track your project status",
  },
  thirdParagraph: {
    id: "home.content.thirdParagraph",
    defaultMessage: "and even more !",
  },
  comingSoonBtn: {
    id: "home.comingSoonBtn",
    defaultMessage: "Coming soon",
  },
});
