import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fetchCategoryItems } from "apis/RLMD";
import { useAppDispatch } from "store";
import { selectCategoryDictionary } from "store/generalData/selectors";
import { updateCategoryDictionary } from "store/generalData/asyncActions";
import { selectLanguageCode } from "store/user/selectors";
import { isListEmpty } from "utils/general";
import { Dictionary } from "types/general";

const useCategoryItems = ({
  categoryItemsIds,
  shouldFilterIds = true,
}: {
  categoryItemsIds?: string[];
  shouldFilterIds?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const languageCode = useSelector(selectLanguageCode);
  const categoryItemsDictionary = useSelector(selectCategoryDictionary);

  const [categoryItems, setCategoryItems] = useState([]);

  const getCategoryItems = async (categoryItemsIds: string[]) => {
    try {
      const { data } = await fetchCategoryItems({
        ids: categoryItemsIds,
        languageCode,
      });

      setCategoryItems(data);

      dispatch(
        updateCategoryDictionary(
          data.reduce(
            (
              previousState,
              { categoryId, categoryItemId, categoryItemName, categoryName }
            ) => ({
              ...previousState,
              [categoryId]: categoryName,
              [categoryItemId]: categoryItemName,
            }),
            {}
          ) as Dictionary
        )
      );
    } catch (error) {
      setCategoryItems([]);
    }
  };

  useEffect(() => {
    const filteredIds = shouldFilterIds
      ? categoryItemsIds.filter(
          categoryItemId => !categoryItemsDictionary[categoryItemId]
        )
      : categoryItemsIds;

    if (!isListEmpty(filteredIds)) {
      getCategoryItems(filteredIds);
    }
  }, [languageCode, JSON.stringify(categoryItemsIds)]);

  return {
    categoryItems,
    categoryItemsDictionary,
  };
};

export default useCategoryItems;
