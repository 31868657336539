import { Form } from "antd";
import { useIntl } from "react-intl";
import { Input } from "@trace-one/design-system";
import { useDebouncedActions } from "components/Debounced/hooks";
import { isListEmpty } from "utils/general";
import { InternalCodeProps } from "./types";
import libraryMessages from "messages/library";

const InternalCode: React.FC<InternalCodeProps> = ({
  disableActions,
  onChange,
  defaultValue,
}) => {
  const { formatMessage } = useIntl();
  const form = Form.useFormInstance();

  const onUpdateChange = (newValue: string) => {
    if (isListEmpty(form.getFieldError("internalCode"))) {
      onChange(newValue);
    }
  };

  const {
    currentValue,
    onTextChange,
    handleDebouncedValueChange,
  } = useDebouncedActions({
    debounceTimer: 2000,
    defaultValue,
    onChange: onUpdateChange,
  });

  return (
    <Form.Item
      rules={[
        {
          max: 64,
          message: formatMessage(libraryMessages.formMaxCharLength, {
            max: 64,
          }),
        },
        { whitespace: true },
      ]}
      name="internalCode"
      label={formatMessage(libraryMessages.internalCodeLabel)}
      wrapperCol={{ md: 4 }}
    >
      <Input
        allowClear={false}
        disabled={disableActions}
        value={currentValue}
        data-test-id="material-form-input-internalCode"
        placeholder={formatMessage(libraryMessages.internalCodePlaceholder)}
        onChange={onTextChange}
        onBlur={handleDebouncedValueChange}
      />
    </Form.Item>
  );
};

export default InternalCode;
