import { defineMessages } from "react-intl";

export const messages = defineMessages({
  sectionTitle: {
    id: "creationFolder.products.sectionTitle",
    defaultMessage: "Assign products",
  },
  sectionSubtitle: {
    id: "creationFolder.products.sectionSubtitle",
    defaultMessage: "Add some products to your folder",
  },
  createNewProduct: {
    id: "creationFolder.products.createNewProduct",
    defaultMessage: "Create a new product",
  },
  addExisitingProduct: {
    id: "creationFolder.products.addExisitingProduct",
    defaultMessage: "Add an existing product",
  },
  firstHelpMessage: {
    id: "creationFolder.products.addExistingProduct.firstHelpMessage",
    defaultMessage: "Add as many existing products as you need in your folder.",
  },
  secondHelpMessage: {
    id: "creationFolder.products.addExistingProduct.secondHelpMessage",
    defaultMessage:
      "Don't worry, you can always add more or remove some of them later.",
  },
});
