import { FormattedMessage, useIntl } from "react-intl";
import { Button } from "@trace-one/design-system";
import { Title } from "@trace-one/react-components";
import Chapters from "components/Chapters";
import {
  generalMessages,
  libraryMessages,
  specificationMessages,
} from "messages";
import { useModalVisibility } from "hooks";
import TemplateModal from "components/TemplateModal";
import { LibraryObjectData, TemplateData } from "models";
import { isListEmpty } from "utils/general";
import InformationCard from "components/InformationCard";
import { Col, Row } from "antd";
import { LIBRARY_OBJECTS_PAGES } from "utils/constants";
import { useGetTags } from "apis/tags-rtk";
import ConfirmationModal from "components/ConfirmationModal";
import styles from "./styles.module.less";

export interface SpecificationTemplateProps {
  template: TemplateData;
  onTemplateSelect?: (selectedItem: LibraryObjectData) => Promise<void>;
  chaptersErrors?: object;
  numberOfProducts?: number;
  numberOfSuppliers?: number;
  displayCounter?: boolean;
  displayActions?: boolean;
  productTypeId?: string;
}

const SpecificationTemplate: React.FC<SpecificationTemplateProps> = ({
  template,
  onTemplateSelect,
  chaptersErrors,
  numberOfProducts,
  numberOfSuppliers,
  displayCounter,
  displayActions = true,
  productTypeId,
}) => {
  const { formatMessage } = useIntl();

  const {
    isModalOpen: isSelectTemplateModalOpen,
    onOpenModal: onSelectTemplateModalOpen,
    onCloseModal: onSelectTemplateCloseModal,
  } = useModalVisibility();

  const {
    isModalOpen: isChangeTemplateModalOpen,
    onOpenModal: onChangeTemplateOpenModal,
    onCloseModal: onChangeTemplateCloseModal,
  } = useModalVisibility();

  const { generalInfo, chapters } = template;
  const { tagIds } = generalInfo;

  const { data: tags } = useGetTags(tagIds, {
    skip: isListEmpty(tagIds),
  });

  const isTemplateSelected = !isListEmpty(chapters);

  return (
    <div>
      <Row gutter={[24, 0]}>
        <Col span={isTemplateSelected && displayActions ? 20 : 24}>
          <Title
            level={2}
            value={formatMessage(libraryMessages.templates)}
            subtitle={formatMessage(specificationMessages.templateDescription)}
          />
        </Col>

        {isTemplateSelected && displayActions && (
          <Col span={4} className={styles["change-template-margin-top"]}>
            <Button onClick={onChangeTemplateOpenModal} type="secondary">
              {formatMessage(specificationMessages.changeTemplate)}
            </Button>
          </Col>
        )}
      </Row>

      <Row gutter={[16, 16]}>
        {!isTemplateSelected && displayActions && (
          <Col span={24}>
            <Button onClick={onSelectTemplateModalOpen}>
              {formatMessage(specificationMessages.selectATemplate)}
            </Button>
          </Col>
        )}

        {!isListEmpty(chapters) && (
          <Row gutter={[0, 24]}>
            <Col span={24}>
              <InformationCard
                name={generalInfo?.name}
                nameLabel={formatMessage(generalMessages.templateName)}
                type={generalInfo?.type}
                lastModificationDate={generalInfo?.lastUpdateDateUtc}
                tags={
                  isListEmpty(tagIds)
                    ? []
                    : (tags ?? []).map(tag => tag.tagText)
                }
                productTypeId={generalInfo?.productTypeId}
              />
            </Col>

            <Col span={24}>
              <Chapters
                chapters={chapters}
                errors={chaptersErrors}
                displayCounter={displayCounter}
                displayActions={false}
                numberOfProducts={numberOfProducts}
                numberOfSuppliers={numberOfSuppliers}
                page={LIBRARY_OBJECTS_PAGES.DETAILS}
              />
            </Col>
          </Row>
        )}
      </Row>

      {isSelectTemplateModalOpen && (
        <TemplateModal
          onCloseModal={() => {
            onSelectTemplateCloseModal();
            onChangeTemplateCloseModal();
          }}
          onPrimaryButtonClick={async selectedItem => {
            await onTemplateSelect(selectedItem);
            onSelectTemplateCloseModal();
            onChangeTemplateCloseModal();
          }}
          existingItemsIds={[generalInfo?.id]}
          productTypeId={productTypeId}
        />
      )}

      {isChangeTemplateModalOpen && (
        <ConfirmationModal
          title={formatMessage(specificationMessages.changeTemplate)}
          illustrationName="templates"
          illustrationColor="primary"
          onCloseModal={onChangeTemplateCloseModal}
          onConfirmModal={onSelectTemplateModalOpen}
          children={
            <FormattedMessage {...specificationMessages.changeTemplateBody} />
          }
          primaryButtonText={formatMessage(generalMessages.confirm)}
        />
      )}
    </div>
  );
};

export default SpecificationTemplate;
