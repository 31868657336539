import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";

/* istanbul ignore next */
export const _selectOidc = (state: RootState) => state.oidc;

export const selectAccessToken = createSelector(
  _selectOidc,
  ({ accessToken }) => accessToken
);

export const selectCompanyActivity = createSelector(
  _selectOidc,
  ({ companyActivity }) => companyActivity
);
export const selectCompanyCountry = createSelector(
  _selectOidc,
  ({ companyCountry }) => companyCountry
);
export const selectUserPermissions = createSelector(
  _selectOidc,
  ({ permissions }) => permissions
);
