export const getOnCheckboxChange = ({
  onChange,
  setCheckboxValue,
}: {
  onChange: Function;
  setCheckboxValue: Function;
}) => ({ target: { checked } }) => {
  onChange(checked);
  setCheckboxValue(checked);
};
