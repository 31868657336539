import { isListEmpty } from "utils/general";
import { BlockProps } from "../../types";

const useBlocks = ({
  readOnlyFieldIds,
  hasPermissionToSave,
  displayActions,
  isTargetMode,
}: BlockProps) => {
  const isWidgetEditable = (propertyId?: string) => {
    if (isTargetMode) return false;

    if (!displayActions) return false;

    if (!hasPermissionToSave) return false;

    if (!propertyId) return true;

    if (isListEmpty(readOnlyFieldIds)) return true;

    return readOnlyFieldIds.indexOf(propertyId) === -1;
  };

  return {
    isWidgetEditable,
  };
};

export default useBlocks;
