import { ChangeEvent, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { CancelTokenSource } from "axios";
import { selectLanguageCode } from "store/user/selectors";
import { isListEmpty } from "utils/general";
import { getSelectedFiltersList } from "./utils";
import filterMessages from "messages/filters";
import statusMessages from "messages/statuses";
import { useFiltersProps } from "./types";

const useFilters = (props: useFiltersProps = {}) => {
  const intl = useIntl();
  const languageCode = useSelector(selectLanguageCode);
  const {
    onSearch: defaultOnSearch,
    onSearchChange: defaultOnSearchChange,
    onClearSearch: defaultOnClearSearch,
  } = props;
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedFilters, setSelectedFilters] = useState(new Map());
  const selectedFiltersList = useMemo(
    getSelectedFiltersList({
      selectedFilters,
      formatMessage: intl.formatMessage,
      messages: {
        ...filterMessages,
        ...statusMessages,
      },
    }),
    [selectedFilters, languageCode]
  );
  const [source, setSource] = useState<CancelTokenSource>();

  const onClearSearch = () => {
    setSearchTerm("");

    if (defaultOnClearSearch) {
      defaultOnClearSearch();
    }
  };

  const onSearchChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(value);

    if (defaultOnSearchChange) {
      defaultOnSearchChange(value);
    }
  };

  const onSearch = () => {
    if (defaultOnSearch) {
      defaultOnSearch(searchTerm);
    }
  };

  const prepareSelectedFilters = ({
    currentSelectedFilters,
    setCurrentSelectedFilters,
    key,
    nameKey,
    singleFilter = false,
  }) => {
    if (
      !isListEmpty(currentSelectedFilters) ||
      (singleFilter && !!currentSelectedFilters)
    ) {
      let filters = !singleFilter
        ? currentSelectedFilters.reduce(
            (currentState, { label, value }) => [
              {
                id: `${key}-${value}`,
                nameKey,
                filterValue: value,
                value: label,
                onCloseClick: () => {
                  setCurrentSelectedFilters(previousCurrentFilters =>
                    previousCurrentFilters.filter(
                      previousSelectedFilter =>
                        previousSelectedFilter.value !== value
                    )
                  );
                },
              },
              ...currentState,
            ],
            []
          )
        : [
            {
              id: `${key}-${currentSelectedFilters.value}`,
              filterValue: currentSelectedFilters.value,
              nameKey,
              value: currentSelectedFilters.label,
              onCloseClick: () => {
                setCurrentSelectedFilters(null);
              },
            },
          ];

      setSelectedFilters(previousSelectedFilters => {
        previousSelectedFilters.set("isTouched", true);
        previousSelectedFilters.set(key, filters);

        return new Map(previousSelectedFilters);
      });
    } else {
      setSelectedFilters(previousSelectedFilters => {
        previousSelectedFilters.set(key, []);

        return new Map(previousSelectedFilters);
      });
    }
  };

  const handleChangeSelectedFilters = ({
    onFilterSelect,
    selectedFilters,
    productCategoryId = null,
  }) => {
    if (selectedFilters.size > 1) {
      let filters: { [key: string]: string } = {};

      selectedFilters.forEach((value, key) => {
        switch (key) {
          case "isTouched":
            return;
          case "categoryItemId":
            filters.categoryId = productCategoryId;
            filters.categoryItemId = value?.[0]?.filterValue;
            break;
          default:
            filters[key] = value.map(({ filterValue }) => filterValue);
        }
      });

      onFilterSelect(filters);
    } else {
      if (selectedFilters.get("isTouched")) {
        onFilterSelect({});
      }
    }
  };

  return {
    onClearSearch,
    onSearchChange,
    onSearch,
    searchTerm,
    selectedFilters,
    selectedFiltersList,
    prepareSelectedFilters,
    handleChangeSelectedFilters,
    source,
    setSource,
  };
};

export default useFilters;
