/* istanbul ignore file */

import axios from "axios";
import {
  CatalogListItemData,
  NutriScoreProductTypeData,
  NutrientFamilyData,
  NutrientUnitOfMeasureData,
  RawMaterialClassificationData,
  RawMaterialClassificationItemData,
  RawMaterialClassificationSearchData,
  SubstanceData,
  SubstanceFunctionData,
} from "models";

export const axiosInstance = axios.create({
  baseURL: window.env.CATALOGUE_URL,
});

export const fetchAllNutrients = ({ languageCode }: { languageCode: string }) =>
  axiosInstance.post<NutrientFamilyData[]>(
    `/api/nutrients`,
    {},
    {
      params: { includeChildren: true, languageCode },
    }
  );

export const searchNutrients = ({
  languageCode,
  searchTerm,
}: {
  languageCode: string;
  searchTerm: string;
}) =>
  axiosInstance.get<NutrientFamilyData[]>(`/api/nutrients/filters`, {
    params: { includeChildren: true, languageCode, searchTerm },
  });

export const fetchNutrientByIds = ({
  nutrientIds,
  languageCode,
}: {
  nutrientIds: string[];
  languageCode: string;
}) =>
  axiosInstance.post<NutrientFamilyData[]>(
    `/api/nutrients`,
    { nutrientIds },
    {
      params: { languageCode },
    }
  );

export const fetchUnitsOfMeasureForNutrient = ({
  nutrientId,
  languageCode,
}: {
  nutrientId: string;
  languageCode: string;
}) =>
  axiosInstance.get<NutrientUnitOfMeasureData[]>(
    `/api/nutrients/${nutrientId}/unitsOfMeasure`,
    {
      params: { languageCode },
    }
  );

export const searchSubstances = ({
  params,
}: {
  params: {
    languageCode?: string;
    searchTerm?: string;
    areaId?: string;
    areaCategory?: string;
    companyId?: string;
  };
}) =>
  axiosInstance.get<SubstanceData[]>(`/api/substances/filters`, {
    params,
  });

export const fetchSubstance = ({
  substanceId,
  params,
}: {
  substanceId: string;
  params: {
    languageCode?: string;
    companyId?: string;
  };
}) =>
  axiosInstance.get<SubstanceData>(`/api/substances/${substanceId}`, {
    params,
  });

export const fetchSubstanceFunctions = ({
  substanceId,
  params,
}: {
  substanceId: string;
  params: {
    languageCode?: string;
    searchTerm?: string;
    areaId?: string;
    areaCategory?: string;
  };
}) =>
  axiosInstance.get<SubstanceFunctionData[]>(
    `/api/substances/${substanceId}/functions/filters`,
    {
      params,
    }
  );

export const fetchSubstanceFunction = ({
  substanceId,
  substanceFunctionId,
  params,
}: {
  substanceId: string;
  substanceFunctionId: string;
  params: {
    languageCode?: string;
    areaId?: string;
    areaCategory?: string;
  };
}) =>
  axiosInstance.get<SubstanceFunctionData>(
    `/api/substances/${substanceId}/functions/${substanceFunctionId}`,
    {
      params,
    }
  );

export const fetchSubstanceFunctionsByIds = ({
  substanceFunctionIds,
  languageCode,
}: {
  substanceFunctionIds: string[];
  languageCode?: string;
}) =>
  axiosInstance.post<SubstanceFunctionData[]>(
    `/api/substanceFunctions`,
    {
      substanceFunctionIds,
    },
    {
      params: { languageCode },
    }
  );

export const fetchAllergensHierarchy = ({
  languageCode,
}: {
  languageCode: string;
}) =>
  axiosInstance.get<CatalogListItemData[]>(`/api/allergens/hierarchy`, {
    params: {
      languageCode,
    },
  });

export const fetchAllergensByIds = ({
  languageCode,
  allergenIds,
}: {
  languageCode?: string;
  allergenIds: string[];
}) =>
  axiosInstance.post<CatalogListItemData[]>(
    `/api/allergens`,
    { allergenIds },
    { params: { languageCode } }
  );

export const fetchClassificationByIds = ({
  rawMaterialClassificationIds,
  languageCode,
}: {
  rawMaterialClassificationIds: string[];
  languageCode?: string;
}) =>
  axiosInstance.post<RawMaterialClassificationItemData[]>(
    `/api/rawmaterialclassifications`,
    { rawMaterialClassificationIds },
    { params: { languageCode } }
  );

export const fetchRawMaterialClassification = ({
  params,
}: {
  params: {
    parentId?: string;
    productTypeId?: string;
    languageCode?: string;
    pageNumber?: number;
    pageSize?: number;
  };
}) =>
  axiosInstance.get<RawMaterialClassificationData>(
    `/api/rawmaterialclassifications`,
    {
      params,
    }
  );

export const filterRawMaterialClassification = ({
  params,
}: {
  params: {
    searchTerm: string;
    includeParents?: boolean;
    productTypeId?: string;
    languageCode?: string;
  };
}) =>
  axiosInstance.get<RawMaterialClassificationSearchData[]>(
    `/api/rawmaterialclassifications/filters`,
    {
      params,
    }
  );

export const fetchNutriscoreProductTypes = ({
  params,
}: {
  params: { languageCode: string };
}) =>
  axiosInstance.get<NutriScoreProductTypeData[]>(
    `/api/Nutrients/NutriscoreProductTypes`,
    {
      params,
    }
  );
