import HtmlDiff from "htmldiff-js";
import classnames from "classnames";
import { TrackChangesProps } from "./types";
import styles from "./styles.module.less";

const TrackChanges: React.FC<TrackChangesProps> = ({
  initText,
  modifiedText,
  className,
}) => {
  const htmlDiff = HtmlDiff.execute(initText, modifiedText);
  return (
    <div
      dangerouslySetInnerHTML={{ __html: htmlDiff }}
      className={classnames(styles.trackChangesWrapper, className)}
    ></div>
  );
};

export default TrackChanges;
