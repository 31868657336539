import { useContext } from "react";
import { useSelector } from "react-redux";
import { utils } from "@rjsf/core";
import { selectSectionSavingInProgress } from "store/specificationDetails/selectors";
import { InputNumber } from "@trace-one/design-system";
import validateFormData from "@rjsf/core/lib/validate";
import { isListEmpty } from "utils/general";
import ErrorsList from "components/ErrorsList";
import { NutritionBasicContext } from "components/SpecificationSection/components/FormSchema/components/NutritionBasic/contexts";
import { useInputNumberProps } from "components/SpecificationSection/components/FormSchema/hooks";
import { extractEditableFieldsForNutrition } from "components/SpecificationSection/components/FormSchema/components/NutritionBasic/utils";
import { UnroundedValueProps } from "./types";

const { resolveSchema } = utils;

const UnroundedValue: React.FC<UnroundedValueProps> = ({
  value,
  nutrientData,
  errors,
  displayActions,
}) => {
  const { sectionId, schema, formContext } = useContext(NutritionBasicContext);
  const { isWidgetEditable, extraActions } = formContext;
  const { setErrors, transformErrors } = formContext;

  const unroundedValueSchema = resolveSchema(
    // @ts-ignore-next
    schema?.properties?.nutrientFamilies?.items?.properties?.nutrients?.items
      ?.properties?.unroundedValue,
    schema
  );
  // @ts-ignore-next
  const hasUpdatePermission = isWidgetEditable(unroundedValueSchema.propertyId);

  const updateNutrition = (value: number | null) =>
    extraActions?.updateNutritionAction({
      sectionId,
      nutrientId: nutrientData.nutrientId,
      data: {
        ...extractEditableFieldsForNutrition(nutrientData),
        unroundedValue: value,
      },
    });

  const handleChange = (value: number | null) => {
    if (value === null) {
      updateNutrition(value);
      return;
    }
    const errorsData = validateFormData(
      value,
      unroundedValueSchema,
      undefined,
      transformErrors
    );

    if (!isListEmpty(errorsData.errors)) {
      // setting general error below chapter toggle
      setErrors([
        {
          error: errorsData.errors[0].name,
          //@ts-ignore
          path: `.nutrientFamilies.${nutrientData.key}.unroundedValue`,
        },
      ]);
      return;
    }
    updateNutrition(value);
  };

  const inputNumberProps = useInputNumberProps({
    //@ts-ignore
    properties: unroundedValueSchema,
    onChange: handleChange,
    value: value === undefined ? null : value,
    sectionId,
  });

  const isSavingInProgress = useSelector(
    selectSectionSavingInProgress(sectionId)
  );

  if (!displayActions) {
    return <>{inputNumberProps.value}</>;
  }

  return (
    <>
      <InputNumber
        {...inputNumberProps}
        data-test-id="unrounded-value"
        error={!isListEmpty(errors)}
        disabled={!hasUpdatePermission || isSavingInProgress}
      />
      {!isListEmpty(errors) && <ErrorsList list={errors} />}
    </>
  );
};

export default UnroundedValue;
