import { defineMessages } from "react-intl";

const messages = defineMessages({
  classificationPlaceholder: {
    id: "library.rawMaterialForm.details.classification.placeholder",
    defaultMessage: "Choose a classification",
  },
});

export default messages;
