import { CharacteristicFormData, ControlPlanFormData } from "../../../types";

export enum CCPTableNames {
  Characteristic = "CHARACTERISTIC",
  ControlPlan = "CONTROL_PLAN",
}

export interface useOptimizationCCPProps {
  formData: (ControlPlanFormData & CharacteristicFormData)[];
  tableName: CCPTableNames;
}
