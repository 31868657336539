import classnames from "classnames";
import { Col, Row } from "antd";
import { isObjectEmpty } from "utils/general";
import RemoveAction from "./components/RemoveAction";
import { FlexibleArrayFieldTemplateItemProps } from "./types";
import styles from "components/SpecificationSection/components/FormSchema/templates/ArrayFieldTemplate/styles.module.less";

const FlexibleArrayFieldTemplateItem: React.FC<FlexibleArrayFieldTemplateItemProps> = ({
  children,
  displayActions,
  index,
  onDropIndexClick,
  hasRemove,
  lastChange,
  hasRemoveConfirmation,
  contentWidth,
  hideRemoveButton,
  className,
}) => {
  return (
    <Row
      align="middle"
      className={classnames({
        [styles[className]]: className,
        [styles.inRevisionChange]: !isObjectEmpty(lastChange),
      })}
    >
      {!displayActions ? (
        <Col span={contentWidth?.xs || 24}>{children}</Col>
      ) : (
        <>
          <Col xs={contentWidth?.xs || 22}>{children}</Col>
          <Col>
            {hideRemoveButton !== true && (
              <RemoveAction
                hasRemove={hasRemove}
                hasRemoveConfirmation={hasRemoveConfirmation}
                onRemove={onDropIndexClick(index)}
              />
            )}
          </Col>
        </>
      )}
    </Row>
  );
};

export default FlexibleArrayFieldTemplateItem;
