import React from "react";
import { Col, Row } from "antd";
import { Card } from "@trace-one/react-components";
import { Heading } from "@trace-one/design-system";
import { getParsedData, isListEmpty } from "utils/general";
import SpecificationSection from "components/SpecificationSection";
import { RecyclabilityProps } from "./types";
import styles from "./styles.module.less";
import { usePackaging } from "components/Packaging/hooks";

const Recyclability: React.FC<RecyclabilityProps> = ({
  sections,
  isEditable,
}) => {
  const { onSaveTemplateBlock } = usePackaging();

  if (isListEmpty(sections)) {
    return null;
  }

  return (
    <Row gutter={[0, 16]} className={styles.recyclabilityContainer}>
      {sections[0].subSections?.map(subSection => {
        return (
          <React.Fragment key={subSection.sectionId}>
            {subSection.sectionBlocks.map(
              ({ dataAsJson, jsonSchema, uiSchema, templateBlockId }) => {
                const formData = getParsedData(dataAsJson);

                return (
                  <Col span={24} key={templateBlockId}>
                    <Card
                      collapsible
                      expand
                      key={templateBlockId}
                      title={
                        <span data-testid="subsection-name">
                          <Heading size="xs">{subSection.sectionName}</Heading>
                        </span>
                      }
                    >
                      <SpecificationSection.Block
                        key={templateBlockId}
                        sectionId={subSection.sectionId}
                        templateBlockId={templateBlockId}
                        uiSchema={getParsedData(uiSchema)}
                        jsonSchema={getParsedData(jsonSchema)}
                        formData={formData}
                        saveTemplateBlock={onSaveTemplateBlock(templateBlockId)}
                        hasPermissionToSave={isEditable}
                      />
                    </Card>
                  </Col>
                );
              }
            )}
          </React.Fragment>
        );
      })}
    </Row>
  );
};

export default Recyclability;
