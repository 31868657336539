import { Row, Col } from "antd";
import { EmptyState } from "@trace-one/design-system";
import RowTitle from "components/RowTitle";
import PdfTable from "../../../components/PdfTable";
import TablesModal from "../../../components/TablesModal";
import Tables from "../../../components/Tables";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { useControlPlansField } from "./hooks";
import { useOptimizationCCP } from "../hooks";
import { isListEmpty } from "utils/general";
import { CCPTableNames } from "../hooks/types";
import { isSpecificationPdfPage } from "utils/specifications";
import { ControlPlansFieldTemplateProps } from "./types";

const ControlPlansFieldTemplate: React.FC<ControlPlansFieldTemplateProps> = ({
  uiSchema,
  formData,
  formContext,
  schema,
  noItemsMessage,
}) => {
  const { getTranslationForKey } = useFormSchemaTranslation();
  const { saveTemplateBlock, displayActions, page } = formContext;
  const title = uiSchema["ui:title"];
  const columns = uiSchema?.["ui:columns"];
  const linkedTableId = "characteristicId";
  const tablesId = "controlPhaseId";

  const { actualForm, handleChange } = useControlPlansField({
    formData,
    saveTemplateBlock,
    tablesId,
  });

  const {
    refListDictionary,
    controlPhases,
    isInitialLoading,
  } = useOptimizationCCP({
    formData,
    tableName: CCPTableNames.ControlPlan,
  });

  const tablesProps = {
    entries: actualForm,
    formContext: {
      ...formContext,
      shouldCallApi: false,
      refListDictionary,
    },
    groupId: tablesId,
    groups: controlPhases,
    idName: linkedTableId,
    schema: schema,
    uiSchema: uiSchema,
    update: handleChange,
    withSubtitle: true,
    isEmptyTableHidden: true,
    canAddEmptyLine: false,
  };

  if (isSpecificationPdfPage(page)) {
    return (
      <PdfTable
        formContext={formContext}
        columns={columns}
        formData={actualForm}
      />
    );
  }

  return (
    <Row gutter={[10, 10]}>
      {title && (
        <Col span={24}>
          <RowTitle
            title={getTranslationForKey(title)}
            displayActions={displayActions}
          />
        </Col>
      )}
      <Col span={24}>
        {!isListEmpty(actualForm) ? (
          <>
            {!isInitialLoading && (
              <>
                <TablesModal
                  title={getTranslationForKey(title)}
                  {...tablesProps}
                />
                <Tables {...tablesProps} />
              </>
            )}
          </>
        ) : (
          <Row gutter={[0, 30]} justify="center">
            <EmptyState heading={noItemsMessage} />
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default ControlPlansFieldTemplate;
