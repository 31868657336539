/* istanbul ignore file */
import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useErrorCodeCheck, useRedirect } from "hooks";
import ConfirmedSharingModal from "components/ConfirmedSharingModal";
import {
  selectFolderDetails,
  selectIsFolderFinalized,
} from "store/folderCreation/selectors";
import { ERROR_CODES } from "utils/constants";
import { onCreateClick, onViewClick } from "../../utils";
import { messages } from "./messages";

const withDraftValidation = Component => props => {
  const history = useHistory();
  const intl = useIntl();
  const { folderId } = useSelector(selectFolderDetails);
  const isFolderFinalized = useSelector(selectIsFolderFinalized);
  const { redirectToFolderDetails } = useRedirect();
  const { isDesiredErrorCode } = useErrorCodeCheck(ERROR_CODES.CANNOT_SHARE);

  return isDesiredErrorCode || isFolderFinalized ? (
    <ConfirmedSharingModal
      type="folder"
      onCreateClick={onCreateClick({ push: history.push })}
      onViewClick={onViewClick({ redirectToFolderDetails, folderId })}
      titleMessage={intl.formatMessage(messages.folderAlreadyCreatedTitle)}
      contentMessage={intl.formatMessage(messages.folderAlreadyCreatedContent)}
    />
  ) : (
    <Component {...props} />
  );
};

export default withDraftValidation;
