import { Button } from "@trace-one/design-system";
import ConfirmationTooltip from "components/ConfirmationTooltip";
import { RemoveActionProps } from "./types";

export const RemoveAction: React.FC<RemoveActionProps> = ({
  hasRemoveConfirmation,
  hasRemove,
  onRemove,
}) => {
  const buttonProps = {
    type: "tertiary",
    iconName: "trash",
    disabled: !hasRemove,
    "data-testid": "array-item-remove-icon",
  };

  if (hasRemoveConfirmation && hasRemove && !!onRemove) {
    return (
      <ConfirmationTooltip onConfirmation={onRemove}>
        <Button {...buttonProps} />
      </ConfirmationTooltip>
    );
  }

  return <Button {...buttonProps} onClick={onRemove} />;
};

export default RemoveAction;
