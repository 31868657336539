import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { fetchSpecification } from "apis/SPEC";
import { useRedirect, useApiPolling } from "hooks";
import { selectLanguageCode } from "store/user/selectors";
import { SPECIFICATION_STATE } from "utils/constants";
import { ParamTypes } from "../../types";

const useWaitingDigitalSignature = () => {
  const { redirectToSpecificationDetails } = useRedirect();
  const languageCode = useSelector(selectLanguageCode);

  const { specificationState, specificationId } = useParams<ParamTypes>();

  const [newSpecificationState, setNewSpecificationState] = useState(
    specificationState
  );

  useEffect(() => {
    if (newSpecificationState !== specificationState) {
      redirectToSpecificationDetails(specificationId);
    }
  }, [newSpecificationState]);

  const fetchState = async () => {
    try {
      const { data } = await fetchSpecification({
        id: specificationId,
        languageCode,
      });
      setNewSpecificationState(data.state as SPECIFICATION_STATE);
    } catch (_) {}
  };

  useApiPolling({
    pollingFunction: fetchState,
    stopPolling: newSpecificationState !== specificationState,
    ignoreMaximumApiCalls: true,
  });
};

export default useWaitingDigitalSignature;
