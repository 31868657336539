import { useState, useEffect } from "react";
import { useDebounce } from "@trace-one/business-components";
import { fetchPackagingUsage } from "apis/PKG";
import { numberOfCharactersForAutoComplete } from "utils/constants";
import {
  UseExternalListAutocompleteProps,
  UseExternalListAutocompleteReturnProps,
} from "./types";

const useExternalListAutocomplete = ({
  items,
  defaultOnSearch,
  defaultOnBlur,
}: UseExternalListAutocompleteProps): UseExternalListAutocompleteReturnProps => {
  const [selectOptions, setSelectOptions] = useState(items);
  const [searchText, setSearchText] = useState("");

  const debouncedSearchText = useDebounce(searchText, 500);

  useEffect(() => {
    if (
      debouncedSearchText &&
      debouncedSearchText.length >= numberOfCharactersForAutoComplete
    ) {
      fetchPackagingUsage({ name: debouncedSearchText }).then(res => {
        setSelectOptions(
          res.data.map(({ id, value }) => ({
            value: id,
            label: value,
          }))
        );
      });
    } else {
      setSelectOptions(items);
    }
  }, [debouncedSearchText]);

  const onSearch = (searchTerm: string) => {
    if (typeof defaultOnSearch === "function") {
      defaultOnSearch(searchTerm);
    }

    setSearchText(searchTerm);
  };

  const onBlur = (e: React.FocusEvent<HTMLElement, Element>) => {
    if (typeof defaultOnBlur === "function") {
      defaultOnBlur(e);
    }

    setSearchText("");
    setSelectOptions(items);
  };

  return {
    selectOptions,
    onSearch,
    onBlur,
  };
};

export default useExternalListAutocomplete;
