import { useState } from "react";

const useMinified = () => {
  const [isMinified, setIsMinified] = useState(false);

  const toggleMinified = () => setIsMinified(prevState => !prevState);

  return {
    isMinified,
    toggleMinified,
  };
};

export default useMinified;
