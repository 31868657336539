import { useContext } from "react";
import { Col, Row } from "antd";
import { EmptyState } from "@trace-one/design-system";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { useModalVisibility, useSpecificationPermissions } from "hooks";
import { isListEmpty } from "utils/general";
import { useRequirementsActions } from "./hooks";
import RequirementsList from "./components/RequirementsList";
import AddRequirementsModal from "./components/AddRequirementsModal";
import { SelectedChapterContext } from "pages/Specification/components/Details/components/SelectedChapter/contexts";
import { RequirementsContentProps } from "./types";
import { RequirementsContentContext } from "./contexts";

const RequirementsContent: React.FC<RequirementsContentProps> = ({
  isRetailer,
  permissions,
}) => {
  const { getTranslationForKey } = useFormSchemaTranslation();
  const { isModalOpen, onOpenModal, onCloseModal } = useModalVisibility();

  const {
    specificationId,
    chapterType,
    refetchChapter,
    requirementIds,
    chapterData: { requirements },
    specificationState,
    isTargetMode,
  } = useContext(SelectedChapterContext);

  const {
    onLinkRequirementsToSpecification,
    onUnlinkRequirementFromSpecification,
    onValidateRequirement,
  } = useRequirementsActions({
    specificationId,
    chapterType,
    refetchChapter,
    onCloseModal,
  });

  const {
    hasPermissionToUpdateRequirement,
    hasPermissionToValidateRequirements,
  } = useSpecificationPermissions({
    state: specificationState,
    permissions,
  });

  return (
    <>
      <Col span={24}>
        <Row gutter={[0, 30]} justify="center">
          {isListEmpty(requirements) ? (
            <EmptyState
              {...(!isRetailer
                ? {
                    description: getTranslationForKey(
                      "requirementsSectionEmptyStateDescription"
                    ),
                  }
                : {
                    heading: getTranslationForKey("noRequirement"),
                    ...(hasPermissionToUpdateRequirement &&
                      !isTargetMode && {
                        primaryButtonText: getTranslationForKey(
                          "addRequirements"
                        ),
                        onPrimaryButtonClick: onOpenModal,
                      }),
                  })}
            />
          ) : (
            <RequirementsContentContext.Provider
              value={{
                onValidate:
                  hasPermissionToValidateRequirements && onValidateRequirement,
                onRemove:
                  hasPermissionToUpdateRequirement &&
                  !isTargetMode &&
                  onUnlinkRequirementFromSpecification,
                isTargetMode,
              }}
            >
              <RequirementsList
                {...(hasPermissionToUpdateRequirement &&
                  !isTargetMode && {
                    onOpenModal,
                  })}
              />
            </RequirementsContentContext.Provider>
          )}
        </Row>
      </Col>
      {isModalOpen && (
        <AddRequirementsModal
          onCloseModal={onCloseModal}
          onPrimaryButtonClick={onLinkRequirementsToSpecification}
          existingRequirementsIds={requirementIds}
        />
      )}
    </>
  );
};

export default RequirementsContent;
