import { createContext } from "react";

export interface FolderListingContextProps {
  currentPage: number;
  resetCurrentPage: Function;
  setShouldRefetchFolders: Function;
}

const FolderListingContext = createContext<FolderListingContextProps>({
  currentPage: 1,
  resetCurrentPage: () => {},
  setShouldRefetchFolders: () => {},
});

export default FolderListingContext;
