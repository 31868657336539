import { defineMessages } from "react-intl";

export const messages = defineMessages({
  fill: {
    id: "quickview.fill",
    defaultMessage: "To fill",
  },

  writing: {
    id: "quickview.writing",
    defaultMessage: "In progress",
  },

  validatedSuplier: {
    id: "quickview.validatedSuplier",
    defaultMessage: "Supplier validation",
  },

  validatedRetailer: {
    id: "quickview.validatedRetailer",
    defaultMessage: "Retailer validation",
  },

  signature: {
    id: "quickview.signature",
    defaultMessage: "Signature",
  },
});
