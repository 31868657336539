import { useEffect, useState } from "react";
import classnames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { fetchCategoryItem } from "apis/RLMD";
import { selectLanguageCode } from "store/user/selectors";
import { CategoryItemData } from "models";
import { isObjectEmpty } from "utils/general";
import CategoryTreeSelect from "components/CategoryTreeSelect";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { useWidgetActions } from "../../hooks";
import { WidgetProps } from "components/SpecificationSection/types";
import widgetStyle from "../styles.module.less";

const HierarchyWidget: React.FC<WidgetProps> = ({
  value,
  readonly,
  placeholder,
  schema,
  onChange,
  formContext,
  id,
}) => {
  const dispatch = useDispatch();
  const languageCode = useSelector(selectLanguageCode);
  const { hierarchyId, propertyId } = schema;
  const {
    displayActions,
    emptyValue,
    isWidgetEditable,
    templateBlockId,
    sectionId,
  } = formContext;
  const { getTranslationForKey } = useFormSchemaTranslation();
  const { isWidgetChanged } = useWidgetActions({ templateBlockId, sectionId });
  const [selectedValue, setSelectedValue] = useState<CategoryItemData>(
    value && {
      categoryItemId: value,
    }
  );

  useEffect(() => {
    const getCategoryItem = async () => {
      try {
        const { data } = await fetchCategoryItem({
          languageCode,
          id: value,
        });

        setSelectedValue(data);
      } catch (_) {
        setSelectedValue(null);
      }
    };

    if (!displayActions && value) {
      getCategoryItem();
    }
  }, [dispatch, displayActions, value]);

  useEffect(() => {
    if (value) {
      setSelectedValue({
        categoryItemId: value,
      });
    } else {
      setSelectedValue(null);
    }
  }, [value]);

  return displayActions ? (
    <CategoryTreeSelect
      allowClear
      className={classnames({
        [widgetStyle.inRevisionChange]: isWidgetChanged(propertyId, id),
      })}
      disabled={!isWidgetEditable(propertyId) || readonly}
      name="hierarchy-widget"
      placeholder={getTranslationForKey(placeholder)}
      value={selectedValue}
      onChange={onChange}
      data-testid="schema-category-widget"
      categoryId={hierarchyId}
    />
  ) : !isObjectEmpty(selectedValue) ? (
    <>{selectedValue.categoryItemName}</>
  ) : (
    <>{emptyValue}</>
  );
};

export default HierarchyWidget;
