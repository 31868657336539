import { defineMessages } from "react-intl";

export const messages = defineMessages({
  batch: {
    id: "specificationDetails.widgets.normalizedLabel.batch",
    defaultMessage: "Batch",
  },
  caliber: {
    id: "specificationDetails.widgets.normalizedLabel.caliber",
    defaultMessage: "Caliber",
  },
  category: {
    id: "specificationDetails.widgets.normalizedLabel.category",
    defaultMessage: "Category",
  },
  number: {
    id: "specificationDetails.widgets.normalizedLabel.number",
    defaultMessage: "Number or net weight",
  },
  origin: {
    id: "specificationDetails.widgets.normalizedLabel.origin",
    defaultMessage: "Origin",
  },
  packedOn: {
    id: "specificationDetails.widgets.normalizedLabel.packedOn",
    defaultMessage: "Packed on",
  },
  product: {
    id: "specificationDetails.widgets.normalizedLabel.product",
    defaultMessage: "Product",
  },
  variety: {
    id: "specificationDetails.widgets.normalizedLabel.variety",
    defaultMessage: "Variety",
  },
});
