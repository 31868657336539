import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import SpecificationSection from "components/SpecificationSection";
import { getParsedData } from "utils/general";
import FormCard from "../FormCard";
import { selectCharachteristicsSchema } from "store/materialForm/selectors";
import { selectOwningCompanyId } from "store/user/selectors";
import { useCharacteristics } from "./hooks";
import { CharacteristicsProps } from "./types";
import messages from "./messages";

const Characteristics: React.FC<CharacteristicsProps> = ({
  displayActions = true,
  materialId,
  materialType,
  recipeType,
  refListDictionary,
}) => {
  const { formatMessage } = useIntl();
  const { isWidgetEditable, saveTemplateBlock } = useCharacteristics({
    displayActions,
    materialId,
    materialType,
    recipeType,
  });
  const materialCharacteristicsSchema = useSelector(
    selectCharachteristicsSchema
  );
  const owningCompanyId = useSelector(selectOwningCompanyId);

  const formData = getParsedData(materialCharacteristicsSchema.json);

  return (
    <FormCard
      title={formatMessage(messages.characteristicsCardTitle)}
      name="characteristics"
    >
      <SpecificationSection.FormSchema
        schema={getParsedData(materialCharacteristicsSchema?.jsonSchema)}
        uiSchema={getParsedData(materialCharacteristicsSchema?.uiSchema)}
        formData={formData}
        formContext={{
          shouldCallApi: false,
          refListDictionary,
          type: "library-object",
          displayActions,
          formData,
          isWidgetEditable,
          saveTemplateBlock,
          ownerCompanyId: owningCompanyId,
        }}
      />
    </FormCard>
  );
};

export default Characteristics;
