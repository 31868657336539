import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { FormProps } from "antd";
import GeneralInformation from "../../components/GeneralInformation";
import VersionHistory from "components/Library/components/ChapterForm/components/VersionHistory";
import LinkedSpecifications from "components/Library/components/ChapterForm/components/LinkedSpecifications";
import { LIBRARY_TAB_KEYS } from "utils/constants";
import { tabMessages } from "messages/library";
import { GeneralInfoData } from "models";

const useChapterNavigation = ({
  generalInfo,
  onFieldsChange,
  numberOfLinkedSpecs,
  linkedSpecificationsCallback,
}: {
  generalInfo: GeneralInfoData;
  onFieldsChange: FormProps["onFieldsChange"];
  numberOfLinkedSpecs: number;
  linkedSpecificationsCallback?: Function;
}) => {
  const { formatMessage } = useIntl();
  const { location } = useHistory();
  const [activeTab, setActiveTab] = useState("");
  const [tabContent, setTabContent] = useState(null);

  const componentMap = {
    [LIBRARY_TAB_KEYS.GENERAL_INFORMATION]: (
      <GeneralInformation onFieldsChange={onFieldsChange} />
    ),
    [LIBRARY_TAB_KEYS.LINKED_SPECIFICATION]: (
      <LinkedSpecifications
        chapterId={generalInfo?.id}
        chapterType={generalInfo?.type}
      />
    ),
    [LIBRARY_TAB_KEYS.VERSION_HISTORY]: (
      <VersionHistory
        chapterId={generalInfo?.id}
        chapterType={generalInfo?.type}
        linkedSpecificationsCallback={linkedSpecificationsCallback}
      />
    ),
  };

  useEffect(() => {
    if (location.pathname.includes(LIBRARY_TAB_KEYS.LINKED_SPECIFICATION)) {
      setActiveTab(LIBRARY_TAB_KEYS.LINKED_SPECIFICATION);
    } else if (location.pathname.includes(LIBRARY_TAB_KEYS.VERSION_HISTORY)) {
      setActiveTab(LIBRARY_TAB_KEYS.VERSION_HISTORY);
    } else {
      setActiveTab(LIBRARY_TAB_KEYS.GENERAL_INFORMATION);
    }
  }, [location]);

  useEffect(() => {
    if (activeTab) {
      setTabContent(componentMap[activeTab]);
    }
  }, [activeTab, generalInfo]);

  const tabItems = [
    {
      key: LIBRARY_TAB_KEYS.GENERAL_INFORMATION,
      label: formatMessage(tabMessages.generalInformation),
    },
    {
      key: LIBRARY_TAB_KEYS.LINKED_SPECIFICATION,
      label: formatMessage(tabMessages.linkedSpecifications, {
        numberOfLinkedSpecs,
      }),
    },
    {
      key: LIBRARY_TAB_KEYS.VERSION_HISTORY,
      label: formatMessage(tabMessages.versionHistory),
    },
  ];

  return {
    activeTab,
    setActiveTab,
    tabItems,
    tabContent,
  };
};

export default useChapterNavigation;
