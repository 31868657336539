export const getOnCreateHandler = ({
  purge,
  onCreateClick,
}: {
  purge: Function;
  onCreateClick: Function;
}) => async () => {
  await purge();
  onCreateClick();
};

export const getOnViewHandler = ({
  purge,
  onViewClick,
}: {
  purge: Function;
  onViewClick: Function;
}) => () => {
  purge();
  onViewClick();
};
