import React, { useEffect } from "react";
import { Stepper } from "@trace-one/design-system";
import { Title } from "@trace-one/react-components";
import { useIntl } from "react-intl";
import CreationWizzard from "components/CreationWizzard";
import { useDispatch, useSelector } from "react-redux";
import { resetFolderCreationErrors } from "store/folderCreation/folderCreationSlice";
import { selectProperStep, selectWizard } from "store/folderCreation/selectors";
import {
  useSteps,
  useCreationActions,
} from "pages/Folder/components/Creation/hooks";
import AssignProducts from "./components/AssignProducts";
import { messages } from "./messages";

const Products = () => {
  const intl = useIntl();
  const { getOnFolderDelete } = useCreationActions();
  const dispatch = useDispatch();
  const { isProductStepCompleted } = useSelector(selectProperStep);
  const wizard = useSelector(selectWizard);

  const {
    stepIndex,
    currentIsValid,
    steps,
    onPreviousStep,
    onNextStep,
  } = useSteps({
    currentStep: wizard.currentStep,
    stepsState: wizard.state,
  });

  useEffect(() => {
    dispatch(resetFolderCreationErrors());
  }, [dispatch]);

  return (
    <>
      <CreationWizzard.Title.Folder />
      <Stepper
        stepIndex={stepIndex}
        currentIsValid={currentIsValid}
        steps={steps}
      />
      <Title
        level={2}
        value={intl.formatMessage(messages.sectionTitle)}
        subtitle={intl.formatMessage(messages.sectionSubtitle)}
      />
      <AssignProducts />
      <CreationWizzard.Footer
        onBackClick={onPreviousStep}
        isNextDisabled={!isProductStepCompleted}
        onNextClick={onNextStep}
        onCancel={getOnFolderDelete()}
      />
    </>
  );
};

export default Products;
