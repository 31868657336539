export const getRetailerOptions = retailerContacts => {
  let options = [];

  retailerContacts.forEach(contact => {
    if (!contact.isSelected) {
      options.push({
        name: `${contact.userFirstName} ${contact.userLastName}`,
        value: contact.userId,
      });
    }
  });

  return options;
};
