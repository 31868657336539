import { Row, Col } from "antd";
import { getPropertiesObjectFromArray } from "../../templates/utils";
import { RangeToleranceWidgetProps } from "./types";

const RangeToleranceWidget: React.FC<RangeToleranceWidgetProps> = ({
  properties,
  uiSchema,
  idSchema,
}) => {
  const propertyObjects = getPropertiesObjectFromArray(properties);

  const colSpans = {
    xs: 24,
    sm: 12,
    md: 12,
    lg: 12,
    xl: 12,
  };

  return (
    <Row gutter={[30, 0]}>
      <Col {...colSpans}>
        {{
          ...propertyObjects["range"].content,
          props: {
            ...propertyObjects["range"].content.props,
            uiSchema: {
              ...uiSchema?.range,
              "ui:widget": "RangeNumberWidget",
            },
            idSchema: {
              $id: `${idSchema.$id}_${propertyObjects["range"].content.props.name}`,
            },
          },
        }}
      </Col>
      <Col {...colSpans}>
        {{
          ...propertyObjects["tolerance"].content,
          props: {
            ...propertyObjects["tolerance"].content.props,
            uiSchema: {
              ...uiSchema?.tolerance,
              "ui:widget": "CompactWidget",
            },
          },
        }}
      </Col>
    </Row>
  );
};

export default RangeToleranceWidget;
