import CreationWizzard from "components/CreationWizzard";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { checkIfSpecificationIsFinalized } from "store/specificationCreation/asyncActions";
import { selectIsSpecificationCheckInProgress } from "store/specificationCreation/selectors";
import withShareSpecificationModal from "hocs/withShareSpecificationModal";
import { useCreationActions } from "pages/Specification/components/Creation/hooks";
import { getOnNextButtonClick } from "./utils";
import { messages } from "./messages";

const Footer = ({ setIsShareModalOpen, onPreviousStep }) => {
  const dispatch = useDispatch();
  const { onSpecificationDelete } = useCreationActions();
  const { formatMessage } = useIntl();
  const isSpecificationCheckInProgress = useSelector(
    selectIsSpecificationCheckInProgress
  );

  return (
    <CreationWizzard.Footer
      onBackClick={onPreviousStep}
      onNextClick={getOnNextButtonClick({
        dispatch,
        checkIfSpecificationIsFinalized,
        setIsShareModalOpen,
      })}
      nextButtonText={formatMessage(messages.nextButtonText)}
      isNextDisabled={isSpecificationCheckInProgress}
      onCancel={onSpecificationDelete}
    />
  );
};

export default withShareSpecificationModal(Footer);
