import { useContext, useMemo } from "react";
import { LibraryCard } from "@trace-one/design-system";
import { LibraryContext } from "components/Library/components/List/contexts";
import { useLibraryItemActions, useRedirect } from "hooks";
import { isLibraryItemDisabled } from "../../utils";
import {
  getActionsListForLibraryItem,
  getOnLibraryItemClick,
} from "components/Library/components/List/utils";
import { getVersionName } from "utils/specifications";
import { prepareLibraryItemActionItem } from "dtos/libraryItem";
import { MosaicItemProps } from "./types";
import {
  isMaterialAdditiveType,
  isMaterialProcessingAidType,
} from "utils/library";

const MosaicItem: React.FC<MosaicItemProps> = ({ libraryItem, isSelected }) => {
  const {
    disabledLibraryItems,
    displayActions,
    onItemClick,
    refreshTypeList,
  } = useContext(LibraryContext);

  const actions = useLibraryItemActions({
    libraryItemInfo: prepareLibraryItemActionItem(libraryItem),
    refetchLibraryItems: refreshTypeList,
  });

  const { redirectToLibraryDetails, redirectToLibraryEdition } = useRedirect();

  const onClick = getOnLibraryItemClick({
    onItemClick,
    libraryItem,
    redirectToLibraryDetails,
    redirectToLibraryEdition,
  });

  const isDisabled = useMemo(
    () =>
      isLibraryItemDisabled({
        libraryItemId: libraryItem.id,
        disabledLibraryItems,
      }),
    [libraryItem.id, disabledLibraryItems]
  );

  return (
    <LibraryCard
      name={libraryItem.name}
      status={libraryItem.state}
      iconName={libraryItem.iconName}
      type={libraryItem.formattedType}
      selected={isSelected}
      version={getVersionName(libraryItem?.versionNumber)}
      disabled={isDisabled}
      {...(!isDisabled && {
        onClick,
      })}
      {...(displayActions &&
        !isMaterialAdditiveType(libraryItem?.type) &&
        !isMaterialProcessingAidType(libraryItem?.type) && {
          actions: getActionsListForLibraryItem({
            actions,
            type: libraryItem?.type,
            state: libraryItem?.state,
          }),
        })}
    />
  );
};

export default MosaicItem;
