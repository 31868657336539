import { defineMessages } from "react-intl";

const messages = defineMessages({
  cardTitle: {
    id: "library.rawMaterialForm.recipeTable.cardTile",
    defaultMessage: "Recipe table",
  },

  noIngredient: {
    id: "library.rawMaterialForm.recipeTable.noIngredient",
    defaultMessage: "No ingredient",
  },

  addAction: {
    id: "library.rawMaterialForm.recipeTable.actions.add",
    defaultMessage: "Add an ingredient",
  },

  tableSubtitle: {
    id: "library.recipeTable.subtitle",
    defaultMessage: "Raw materials, composites and additives",
  },

  processingAidsTableSubtitle: {
    id: "library.recipeTable.processingAids.subtitle",
    defaultMessage: "Processing aids",
  },

  processingAidsTableDisabledTooltip: {
    id: "library.recipeTable.processingAids.tooltip",
    defaultMessage:
      "Inherited from {materialName}, please edit in the composite.",
  },

  modalTitle: {
    id: "library.rawMaterialForm.recipeTable.addIngredient.modalTitle",
    defaultMessage: "Select existing materials",
  },
  modalSubTitle: {
    id: "library.rawMaterialForm.recipeTable.addIngredient.modalSubTitle",
    defaultMessage: "You can select one or multiple materials",
  },
  modalConfirmBtnText: {
    id: "components.button.addToTable",
    defaultMessage: "Add to the table",
  },
});

export default messages;
