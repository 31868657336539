import React from "react";
import ConfirmationTooltip from "components/ConfirmationTooltip";
import { Button } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import { persistor } from "store";
import {
  isNextButtonDisabled,
  onBackButtonClick,
  onCancelButtonClick,
  onNextButtonClick,
} from "./utils";
import { messages } from "./messages";
import styles from "./styles.module.less";
import { FooterTypes } from "./types";

const Footer: React.FC<FooterTypes> = ({
  onBackClick,
  onNextClick,
  isNextDisabled = false,
  isSaving,
  hasBackButton = true,
  nextButtonText,
  onCancel,
}) => {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.container}>
      {hasBackButton && (
        <Button
          type="secondary"
          data-testid="backButton"
          onClick={onBackButtonClick({
            onBackClick,
          })}
          disabled={isSaving}
          iconName="arrow-left"
        >
          {formatMessage(messages.back)}
        </Button>
      )}
      <ConfirmationTooltip
        onConfirmation={onCancelButtonClick({
          purge: persistor.purge,
          onCancel,
        })}
      >
        <Button type="link" data-testid="cancelCreation">
          {formatMessage(messages.cancelCreation)}
        </Button>
      </ConfirmationTooltip>
      <Button
        disabled={isNextButtonDisabled({
          isNextDisabled,
          isSaving,
        })}
        onClick={onNextButtonClick({
          onNextClick,
        })}
        data-testid="nextButton"
        iconName="arrow-right"
        iconPlacement="right"
      >
        {nextButtonText || formatMessage(messages.next)}
      </Button>
    </div>
  );
};

export default Footer;
