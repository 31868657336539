import { useEffect, useState } from "react";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { isListEmpty, transformStringToPathList } from "utils/general";
import { transformErrors } from "utils/form";
import { UseBlockErrorsProps } from "./types";
import { ErrorSchema } from "components/SpecificationSection/types";
import { FormValidationError } from "types/validation";

const useBlockErrors = ({
  extraTemplateBlockErrors = [],
  onErrorsChange,
}: UseBlockErrorsProps) => {
  const [blockErrors, setBlockErrors] = useState(extraTemplateBlockErrors);

  const { getTranslationForKey } = useFormSchemaTranslation();

  const setErrors = (newFormErrors: FormValidationError[]) => {
    let errors = newFormErrors;

    if (!isListEmpty(blockErrors)) {
      errors = newFormErrors.filter(
        ({ path: newFormPath, error: newFormError }) =>
          !blockErrors.find(
            ({ path: currentPath, error: currentError }) =>
              newFormPath === currentPath && newFormError === currentError
          )
      );
    }

    setBlockErrors(previousState => [...previousState, ...errors]);

    onErrorsChange?.(errors);
  };

  const prepareBlockErrors = () => {
    let extraErrors: ErrorSchema = {};

    if (isListEmpty(blockErrors)) {
      return extraErrors;
    }

    return blockErrors.reduce((errorSchema, currentError) => {
      const { path, error } = currentError;
      const pathList = transformStringToPathList(path);
      let parent = errorSchema;

      for (const segment of pathList) {
        if (!(segment in parent)) {
          parent[segment] = {};
        }
        parent = parent[segment];
      }

      if (error) {
        const errorMessage = getTranslationForKey(error);
        parent.__errors = Array.isArray(parent.__errors)
          ? [...parent.__errors, ...[errorMessage]]
          : [errorMessage];
      }

      return errorSchema;
    }, extraErrors);
  };

  useEffect(() => {
    setBlockErrors(extraTemplateBlockErrors);
  }, [JSON.stringify(extraTemplateBlockErrors)]);

  return {
    extraErrors: prepareBlockErrors(),
    setErrors,
    transformErrors: transformErrors(getTranslationForKey),
  };
};

export default useBlockErrors;
