import { useMemo } from "react";
import { Row } from "antd";
import { Heading, Badge } from "@trace-one/design-system";
import Status from "components/Status";
import Card from "./components/Card";
import { PdfHeaderProps } from "./types";
import { getPercentValue, isListEmpty } from "utils/general";
import { FormattedMessage } from "react-intl";
import { messages } from "./messages";
import { getCards } from "./components/Card/utils";
import styles from "./styles.module.less";
import { getVersionName } from "utils/specifications";

const Header: React.FC<PdfHeaderProps> = ({
  progression = 0,
  state = null,
  ownerCompanyName = "",
  supplierName = "",
  specificationName = "",
  versionNumber,
}) => {
  const percent = useMemo(() => getPercentValue(progression), [progression]);
  const versionName = getVersionName(versionNumber);

  const cards = getCards(Object.keys(messages), [
    supplierName,
    ownerCompanyName,
    `${percent}%`,
  ]);

  return (
    <div className={styles.pdfHeader}>
      <div>
        <Row align="middle">
          <Badge count={versionName} />
          <Heading size="s" className="ml-1">
            {specificationName}
          </Heading>
        </Row>
        <div>{state && <Status.Tag state={state} />}</div>
      </div>
      <div>
        {!isListEmpty(cards)
          ? cards.map(({ title, content }) =>
              !!content ? (
                <Card
                  key={title}
                  title={<FormattedMessage {...messages[title]} />}
                  content={content}
                />
              ) : null
            )
          : null}
      </div>
    </div>
  );
};

export default Header;
