import {
  CompactFormData,
  RangeToleranceFormData,
} from "components/SpecificationSection/components/FormSchema/types";
import { getRangeString } from "components/SpecificationSection/components/FormSchema/utils";
import { Dictionary, UiSchemaInfo } from "types/general";
import { isObjectEmpty } from "utils/general";

export const getTolerance = ({
  tolerance,
  refListDictionary,
  emptyValue,
}: {
  tolerance: CompactFormData;
  refListDictionary: Dictionary<string>;
  emptyValue: string;
}) => {
  if (isObjectEmpty(tolerance)) {
    return emptyValue;
  }

  return `${tolerance.value ? tolerance.value : emptyValue} ${
    refListDictionary[tolerance.unit]
      ? refListDictionary[tolerance.unit]
      : emptyValue
  }`;
};

export const getCaliberCategory = ({
  formData,
  uiSchema,
  refListDictionary,
  emptyValue,
  getTranslationForKey,
}: {
  formData: RangeToleranceFormData;
  uiSchema: UiSchemaInfo;
  refListDictionary: Dictionary<string>;
  emptyValue: string;
  getTranslationForKey: Function;
}) => {
  if (isObjectEmpty(uiSchema)) {
    return null;
  }

  let title = "",
    content = "",
    toleranceTitle = "",
    tolerance = "";

  if (typeof formData === "object" && !isObjectEmpty(formData)) {
    if (formData.range) {
      title = getTranslationForKey(uiSchema.range["ui:title"]);

      content = getRangeString({
        range: formData.range,
        refListDictionary,
        emptyValue,
        getTranslationForKey,
      });
    }

    if (formData.tolerance) {
      toleranceTitle = getTranslationForKey(uiSchema.tolerance["ui:title"]);

      tolerance = getTolerance({
        tolerance: formData.tolerance,
        refListDictionary,
        emptyValue,
      });
    }
  } else {
    title = getTranslationForKey(uiSchema["ui:title"]);
    content = (formData as string) || emptyValue;
  }

  return {
    title,
    content,
    toleranceTitle,
    tolerance,
  };
};
