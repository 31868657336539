import { useEffect, useState } from "react";
import cleanDeep from "clean-deep";
import { cleanTableOnEmptyRow } from "../../../utils";
import {
  convertArrayToObjectOfArrays,
  getOnFieldSave,
} from "components/SpecificationSection/components/FormSchema/utils";
import { getIndexOfItemOfId } from "./utils";
import { ControlPlanFormData } from "components/SpecificationSection/components/FormSchema/types";

const useControlPlansField = ({
  formData,
  saveTemplateBlock,
  tablesId,
}: {
  formData: ControlPlanFormData[];
  saveTemplateBlock: Function;
  tablesId: string;
}) => {
  const [actualForm, setActualForm] = useState(formData);

  useEffect(() => {
    setActualForm(JSON.parse(JSON.stringify(formData)));
  }, [formData]);

  const handleChange = ({ name, value, rowIndex }, id) => {
    setActualForm(prev => {
      const newForm = [...prev];
      const tables = convertArrayToObjectOfArrays({
        array: newForm,
        id: tablesId,
      });

      const index = tables[id][rowIndex]
        ? getIndexOfItemOfId({
            array: newForm,
            tablesId,
            id,
            index: rowIndex,
          })
        : newForm.length;

      const controlPhaseId = tables[id][rowIndex] ? {} : { [tablesId]: id };

      newForm[index] = {
        ...newForm[index],
        ...controlPhaseId,
        [name]: value,
      };

      const formToSend = cleanTableOnEmptyRow({
        array: newForm,
        index,
        id: tablesId,
      });

      getOnFieldSave({ saveTemplateBlock })(
        cleanDeep(formToSend, { emptyArrays: false }),
        prev
      );

      return formToSend;
    });
  };

  return {
    actualForm,
    handleChange,
  };
};

export default useControlPlansField;
