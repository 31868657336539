import { useIntl } from "react-intl";
import { ColumnType } from "antd/lib/table";
import { useContext, useEffect, useState } from "react";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import { Dictionary } from "types/general";
import { fetchPackagingComponentPermissions } from "apis/PKG";
import { isListEmpty } from "utils/general";
import { messages } from "./messages";
import generalMessages from "messages/general";
import Actions from "./components/Actions";
import { PackagingSystemComponentData } from "models/packaging";
import { PackagingSystemTableContext } from "../../../../contexts";
import styles from "./styles.module.less";

const usePackagingSystemTable = ({
  refListDictionary,
  packagingComponentIds,
}: {
  refListDictionary: Dictionary;
  packagingComponentIds: string[];
}) => {
  const { formatMessage } = useIntl();
  const { getTranslationForKey } = useFormSchemaTranslation();
  const { displayActions } = useContext(PackagingSystemTableContext);
  const [
    packagingComponentIdPermissionDictionary,
    setPackagingComponentIdPermissionDictionary,
  ] = useState<Dictionary<string[]>>({});

  const getPackagingPermissions = async (packagingComponentId: string) => {
    try {
      const { data } = await fetchPackagingComponentPermissions({
        packagingComponentId,
      });

      setPackagingComponentIdPermissionDictionary(prev => {
        return { ...prev, [packagingComponentId]: data };
      });
    } catch (_) {}
  };

  useEffect(() => {
    if (!isListEmpty(packagingComponentIds)) {
      packagingComponentIds.forEach(packagingComponentId => {
        getPackagingPermissions(packagingComponentId);
      });
    }
  }, [JSON.stringify(packagingComponentIds)]);

  const columnFields = [
    { columnItem: "name", title: "packagingComponentName" },
    { columnItem: "componentTypeId", title: "packagingComponentType" },
    { columnItem: "materialId", title: "packagingComponentMaterial" },
    { columnItem: "usageLevel", title: "packagingComponentUsageLevel" },
  ];

  let columns: ColumnType<PackagingSystemComponentData>[] = columnFields.map(
    ({ columnItem, title }) => {
      return {
        title: getTranslationForKey(title),
        dataIndex: columnItem,
        render: value => {
          if (columnItem === "usageLevel") {
            return formatMessage(messages?.[value]);
          }

          return refListDictionary[value] ?? value;
        },
      };
    }
  );

  displayActions &&
    columns.push({
      key: "action",
      title: formatMessage(generalMessages.actions),
      fixed: "right",
      width: 90,
      render: (_, packagingComponent) => (
        <div className={styles.packagingSystemTableActionsContainer}>
          <Actions
            packagingComponent={packagingComponent}
            permissionList={
              packagingComponentIdPermissionDictionary[
                packagingComponent.componentId
              ] ?? []
            }
          />
        </div>
      ),
    });

  return { columns };
};

export default usePackagingSystemTable;
