import {
  Paragraph,
  Toggle,
  Icon,
  Tooltip,
  Button,
} from "@trace-one/design-system";
import { Popover } from "@trace-one/react-components";
import { Row, Col } from "antd";
import { useIntl } from "react-intl";
import { messages } from "components/CreateCollaboration/messages";
import { useEnableCollaboration } from "./hooks";
import { EnableCollaborationProps } from "./types";
import styles from "./styles.module.less";

const EnableCollaboration: React.FC<EnableCollaborationProps> = ({
  onChange,
  checked,
  collaborationIds,
  onRemoveCollaborations,
}) => {
  const { formatMessage } = useIntl();

  const {
    isPopoverVisible,
    isRemovingCollaborations,
    handleChange,
    handleConfirmDisableCollaborations,
    handleCancelDisableCollaborations,
  } = useEnableCollaboration({
    onChange,
    collaborationIds,
    onRemoveCollaborations,
  });

  return (
    <>
      <Popover
        placement="rightTop"
        trigger="click"
        visible={isPopoverVisible}
        content={() => (
          <div className={styles.disableCollaborations}>
            <Row wrap={false}>
              <Col>
                <Icon name="information" color="orange-dark" size="large" />
              </Col>
              <Col>
                <Paragraph size="s" className={styles.emailInvitationTitle}>
                  {formatMessage(messages.disableCollaborations)}
                </Paragraph>
              </Col>
            </Row>
            <div className={styles.buttonContainer}>
              <Button
                type="secondary"
                data-test-id="cancel-disable-collaboration"
                onClick={handleCancelDisableCollaborations}
              >
                {formatMessage(messages.cancelDisableCollaborations)}
              </Button>
              <Button
                type="primary"
                data-test-id="send-email-invitation"
                loading={isRemovingCollaborations}
                onClick={handleConfirmDisableCollaborations}
              >
                {formatMessage(messages.confirmDisableCollaborations)}
              </Button>
            </div>
          </div>
        )}
        getPopupContainer={trigger => trigger.parentNode}
        overlayClassName={styles.disableCollaborationsPopover}
      >
        <Toggle
          text={formatMessage(messages.enableCollaboration)}
          size="small"
          onChange={handleChange}
          checked={checked}
        />
      </Popover>

      <Tooltip
        showFullText={true}
        text={
          <>
            <Paragraph color="primary">
              {formatMessage(messages.infoTitle)}
            </Paragraph>
            <Paragraph color="white" className={styles.tooltipParagraph}>
              {formatMessage(messages.infoDescription1)}
            </Paragraph>
            <Paragraph color="white">
              {formatMessage(messages.infoDescription2)}
            </Paragraph>
          </>
        }
        placement="bottom"
      >
        <Icon name="more-info" color="grey-5" />
      </Tooltip>
    </>
  );
};

export default EnableCollaboration;
