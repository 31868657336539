import { useEffect, useState } from "react";
import { FormProps } from "antd";
import { isFormValid } from "./utils";

const useLibraryFormActions = ({
  requiredFields,
  libraryItem,
  isUpdateInProgress,
  disabled = false,
}: {
  requiredFields: string[];
  libraryItem: { [key: string]: any };
  isUpdateInProgress: boolean;
  disabled?: boolean;
}) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(
    requiredFields.some(field => !libraryItem[field])
  );

  const onFieldsChange = (_, allFields: FormProps["fields"]) => {
    const requiredFieldsToValidate = allFields.filter(field =>
      requiredFields?.some(item => item === field.name[0])
    );

    setIsButtonDisabled(
      requiredFieldsToValidate?.some(({ value }) => !value) ||
        !isFormValid(allFields) ||
        disabled
    );
  };

  useEffect(() => {
    setIsButtonDisabled(
      requiredFields.some(field => !libraryItem[field]) || disabled
    );
  }, [JSON.stringify(libraryItem), disabled]);

  return {
    areActionButtonsDisabled: isButtonDisabled || isUpdateInProgress,
    onFieldsChange,
  };
};

export default useLibraryFormActions;
