import { useEffect, useState } from "react";
import { RefListOption } from "../../types";
import { isListEmpty } from "utils/general";
import { ReferenceListItemData } from "models";

const useReferenceList = ({
  referenceList,
  displayActions,
  defaultExcludedRefListIds,
}: {
  referenceList: ReferenceListItemData[];
  displayActions: boolean;
  defaultExcludedRefListIds: string[];
}) => {
  const [referenceOptions, setReferenceOptions] = useState<RefListOption[]>([]);
  const [excludedRefListIds, setExcludedRefListIds] = useState(
    defaultExcludedRefListIds
  );
  const [shouldAddActionBeDisabled, setShouldAddActionBeDisabled] = useState(
    false
  );

  const updateExcludedRefListIds = (reflistId: string) => {
    setExcludedRefListIds(previousState => [...previousState, reflistId]);
  };

  useEffect(() => {
    if (displayActions) {
      if (isListEmpty(excludedRefListIds)) {
        setReferenceOptions(referenceList);
      } else {
        setReferenceOptions(
          referenceList.map(item => ({
            ...item,
            disabled: excludedRefListIds.includes(item.id),
          }))
        );
      }
    }
  }, [displayActions, referenceList, excludedRefListIds]);

  useEffect(() => {
    if (displayActions) {
      if (
        !isListEmpty(excludedRefListIds) &&
        referenceOptions.findIndex(({ disabled }) => !disabled) === -1
      ) {
        setShouldAddActionBeDisabled(true);
      } else {
        setShouldAddActionBeDisabled(false);
      }
    }
  }, [referenceOptions, displayActions]);

  useEffect(() => {
    setExcludedRefListIds(defaultExcludedRefListIds);
  }, [JSON.stringify(defaultExcludedRefListIds)]);

  return {
    referenceOptions,
    shouldAddActionBeDisabled,
    updateExcludedRefListIds,
  };
};

export default useReferenceList;
