import { Heading, Paragraph, Stepper } from "@trace-one/design-system";
import { StepTitleProps } from "./types";
import styles from "./styles.module.less";

const StepTitle: React.FC<StepTitleProps> = ({ step, subtitle, title }) => {
  return (
    <div className={styles.stepTitle}>
      <div>
        <Stepper.Item number={step} isActive />
        <Heading size="xs">{title}</Heading>
      </div>
      {subtitle && <Paragraph className="mt-2">{subtitle}</Paragraph>}
    </div>
  );
};

export default StepTitle;
