import { useIntl } from "react-intl";
import { Alert } from "@trace-one/design-system";
import Library from "components/Library";
import { LIBRARY_OBJECTS_PAGES } from "utils/constants";
import { isLibraryObjectLocked, isLibraryObjectPublished } from "utils/library";
import libraryMessages from "messages/library";
import { ContentProps } from "../Content/types";
import styles from "../../styles.module.less";

const Requirement: React.FC<ContentProps> = ({
  libraryItemDetails,
  updateActionsInformation,
}) => {
  const { formatMessage } = useIntl();

  return (
    <>
      {!isLibraryObjectLocked(libraryItemDetails.generalInformation.state) && (
        <Alert
          showIcon
          closable
          className={styles.alertContainer}
          message={formatMessage(libraryMessages.requirementEditionAlertTitle)}
          description={formatMessage(
            isLibraryObjectPublished(
              libraryItemDetails.generalInformation.state
            )
              ? libraryMessages.requirementEditionAlertDescription
              : libraryMessages.requirementEditionDraftAlertDescription
          )}
          type="warning"
          data-testid="warning-banner-requirement"
        />
      )}

      <Library.RequirementForm
        initialValues={libraryItemDetails}
        page={LIBRARY_OBJECTS_PAGES.EDIT}
        updateActionsInformation={updateActionsInformation}
      />
    </>
  );
};

export default Requirement;
