import { useMemo } from "react";
import {
  useCatalogItems,
  useReferenceListsItems,
  useSpecificationReflistIds,
} from "hooks";
import SubSection from "./components/SubSection";
import { SubSectionsProps } from "./types";

const SubSections: React.FC<SubSectionsProps> = ({
  subSections,
  currentSectionsRefs,
}) => {
  const { getReflistIdsFromSections } = useSpecificationReflistIds();

  const ids = useMemo(() => {
    return getReflistIdsFromSections(subSections);
  }, [JSON.stringify(subSections)]);

  useReferenceListsItems({
    refListIds: [...ids.reflistIds],
  });

  useCatalogItems({
    catalogAllergenIds: [...ids.hierarchyIds],
  });

  return (
    <>
      {subSections.map(subSection => (
        <SubSection
          key={subSection.sectionId}
          subSection={subSection}
          currentSectionsRefs={currentSectionsRefs}
        />
      ))}
    </>
  );
};

export default SubSections;
