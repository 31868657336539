import { defineMessages } from "react-intl";

export const messages = defineMessages({
  acceptRevision: {
    id: "specification.actions.revision.accept",
    defaultMessage: "Accept Revision",
  },
  rejectRevision: {
    id: "specification.actions.revision.reject",
    defaultMessage: "Reject Revision",
  },
  stickyBarText: {
    id: "specification.actions.revision.retailer.stickyBar.text",
    defaultMessage:
      "<b>{supplierName}</b> asked for a revision of this Specification, do you want to:",
  },
});
