import { useEffect, useState, useMemo, useRef } from "react";
import { uniq } from "lodash";
import {
  fetchChapters,
  fetchClaims,
  fetchMaterials,
  fetchRequirementListing,
  fetchTemplates,
} from "apis/SPEC";
import { useSelector } from "react-redux";
import {
  selectLanguageCode,
  selectOwningCompanyId,
} from "store/user/selectors";
import { LIBRARY_OBJECT_TYPES } from "utils/constants";
import {
  getTagsByLibraryObjects,
  prepareTypeParameter,
  paramsSerializer,
} from "./utils";
import { LibraryObjectData } from "models";
import { UseLibraryListProps } from "./types";
import { LibraryListItemViewModel } from "viewModels";
import { prepareLibraryListItemFromApiData } from "dtos/libraryItem";
import { fetchPackagingSystems } from "apis/PKG";
import { getSkipParamForApi, isListEmpty, isObjectEmpty } from "utils/general";
import { fetchReferenceListsItems } from "apis/RLMD";

export const FetchApiMapping = {
  [LIBRARY_OBJECT_TYPES.CHAPTERS]: fetchChapters,
  [LIBRARY_OBJECT_TYPES.MATERIALS]: fetchMaterials,
  [LIBRARY_OBJECT_TYPES.REQUIREMENTS]: fetchRequirementListing,
  [LIBRARY_OBJECT_TYPES.PACKAGING_SYSTEMS]: fetchPackagingSystems,
  [LIBRARY_OBJECT_TYPES.TEMPLATES]: fetchTemplates,
  [LIBRARY_OBJECT_TYPES.CLAIMS]: fetchClaims,
};

const useLibraryList = ({
  excludeDrafts,
  historyId,
  filters,
  updateFilter,
  excludedLibraryItemIds,
  typeDictionary,
  isLibraryListingPage,
  displaySelectedLibraryItems,
}: UseLibraryListProps) => {
  const ownerCompanyId = useSelector(selectOwningCompanyId);
  const languageCode = useSelector(selectLanguageCode);

  const [isListLoading, setIsListLoading] = useState(false);
  const [libraryList, setLibraryList] = useState<LibraryListItemViewModel[]>(
    []
  );
  const [totalCount, setTotalCount] = useState(0);
  const [refreshListCounter, setRefreshListCounter] = useState(0);
  const [productTypes, setProductTypes] = useState({});

  const isFirstRender = useRef(true);

  const fetchTypeList = async (isNewSearch = false) => {
    const {
      currentPage,
      pageSize,
      orderBy,
      orderByDescending,
      type,
      ...rest
    } = filters;

    const params = {
      skip: getSkipParamForApi({ isNewSearch, currentPage, pageSize }),
      take: pageSize,
      orderBy,
      orderByDescending,
      type: prepareTypeParameter(type),
      excludeDrafts,
      ownerCompanyId,
      ...rest,
    };

    setLibraryList([]);
    setTotalCount(0);

    setIsListLoading(true);

    try {
      let totalCount = 0;
      let libraryObjects: LibraryObjectData[] = [];
      let updateLibraryList: LibraryListItemViewModel[] = [];

      const { data } = await FetchApiMapping[type]({
        params,
        paramsSerializer,
      });

      libraryObjects = data.items;
      totalCount = data.totalNumberOfItems;

      const tags = await getTagsByLibraryObjects(libraryObjects);

      const productTypeIds = uniq(
        libraryObjects.map(item => item.productTypeId)
      );
      let productTypesObject = productTypes;

      if (
        !isListEmpty(productTypeIds) &&
        Object.keys(productTypes)?.length !== productTypeIds?.length
      ) {
        const productsRes = await fetchReferenceListsItems({
          languageCode,
          ids: productTypeIds,
        });

        productTypesObject = (productsRes?.data ?? []).reduce((acc, item) => {
          acc[item.id] = item.itemCode;
          return acc;
        }, {});

        setProductTypes(productTypesObject);
      }

      if (historyId) {
        updateLibraryList = libraryObjects.reduce(
          (previousState, currentLibraryObject) => {
            if (historyId === currentLibraryObject.historyId) {
              return [...previousState];
            }
            return [
              ...previousState,
              prepareLibraryListItemFromApiData({
                libraryItem: currentLibraryObject,
                tags,
                typeDictionary,
                type,
                productTypes: productTypesObject,
              }),
            ];
          },
          []
        );
      } else {
        updateLibraryList = libraryObjects.map(libraryItem =>
          prepareLibraryListItemFromApiData({
            libraryItem,
            tags,
            typeDictionary,
            type,
            productTypes: productTypesObject,
          })
        );
      }

      if (
        !isListEmpty(excludedLibraryItemIds) &&
        !displaySelectedLibraryItems
      ) {
        updateLibraryList = updateLibraryList.filter(
          libraryItem =>
            !excludedLibraryItemIds.some(
              excludedLibraryItemId => excludedLibraryItemId === libraryItem.id
            )
        );
      }

      setLibraryList(updateLibraryList);
      setTotalCount(totalCount);
    } catch (_) {
    } finally {
      setIsListLoading(false);
    }
  };

  const refreshTypeList = (fetchCurrentPage = false) => {
    if (fetchCurrentPage) {
      fetchTypeList();
    } else {
      setRefreshListCounter(prev => prev + 1);
      updateFilter("currentPage")(1);
    }
  };

  useEffect(() => {
    fetchTypeList();
  }, [
    filters.name,
    filters.pageSize,
    filters.currentPage,
    filters.orderByDescending,
    filters.orderBy,
    filters.type,
    refreshListCounter,
  ]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (isLibraryListingPage) {
      if (
        filters.states !== undefined ||
        filters.requirementType !== undefined ||
        filters.chapterTypes !== undefined
      ) {
        fetchTypeList(true);
        updateFilter("currentPage")(1);
      }
    } else if (
      (!isListEmpty(filters.states) || filters.requirementType !== undefined) &&
      !isFirstRender.current
    ) {
      fetchTypeList(true);
      updateFilter("currentPage")(1);
    }
  }, [filters.requirementType, filters.states, filters.chapterTypes]);

  const transformedList = useMemo(() => {
    let updatedList = [];
    if (!isObjectEmpty(typeDictionary) && !isListEmpty(libraryList)) {
      updatedList = libraryList.map(item => ({
        ...item,
        formattedType: typeDictionary[item.type],
      }));
    }

    if (displaySelectedLibraryItems && !isListEmpty(excludedLibraryItemIds)) {
      updatedList = libraryList.filter(
        libraryItem =>
          !excludedLibraryItemIds.some(
            excludedLibraryItemId => excludedLibraryItemId === libraryItem.id
          )
      );
    }
    return updatedList;
  }, [libraryList, typeDictionary, excludedLibraryItemIds]);

  return {
    isListLoading,
    libraryList: transformedList,
    totalCount,
    refreshTypeList,
  };
};

export default useLibraryList;
