import Quickview from "components/Quickview";
import { useProgression } from "./hooks";
import { ProgressionProps } from "./types";

const Progression: React.FC<ProgressionProps> = props => {
  const { progressListData, onSectionClick } = useProgression(props);

  return (
    <Quickview.Progress
      list={progressListData}
      onSectionClick={onSectionClick}
    />
  );
};

export default Progression;
