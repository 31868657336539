import { useContext } from "react";
import { Col, Row } from "antd";
import { LibraryContext } from "../../contexts";
import MosaicItem from "./components/MosaicItem";
import { getColSpansFromView } from "./utils";

const MosaicView = () => {
  const { libraryList, view, selectedLibraryItems } = useContext(
    LibraryContext
  );

  return (
    <Row gutter={[16, 30]} data-test-id="library-mosaic">
      {libraryList.map(libraryItem => (
        <Col
          data-test-id="library-card"
          key={libraryItem.id}
          {...getColSpansFromView(view)}
        >
          <MosaicItem
            isSelected={
              selectedLibraryItems?.findIndex(
                selectedLibraryItem =>
                  selectedLibraryItem?.id === libraryItem.id
              ) > -1
            }
            key={libraryItem.id}
            libraryItem={libraryItem}
          />
        </Col>
      ))}
    </Row>
  );
};

export default MosaicView;
