import { isObjectEmpty, isListEmpty } from "utils/general";
import { useAppDispatch } from "store";
import { useChapterAllergens, useRecipe, useValidation } from "hooks";
import { validateChapterSection } from "apis/SPEC";
import SpecificationSection from "components/SpecificationSection";
import { CHAPTER_TYPES_API } from "utils/constants";
import {
  isLibraryObjectDetailsPage,
  isLibraryObjectLocked,
} from "utils/library";
import { getChapter } from "store/chapterForm/asyncActions";
import SelectedSectionContext from "./contexts";
import SubSections from "./components/SubSections";
import { SelectedSectionProps } from "./types";

const SelectedSection = ({
  selectedSection,
  currentSectionsRefs,
  page,
  generalInfo,
}: SelectedSectionProps) => {
  const dispatch = useAppDispatch();
  const { sectionName, sections, sectionId, sectionStatus } = selectedSection;

  const {
    fetchRecipeIngredients,
    recipeIngredients,
    sortRecipeIngredient,
    recipeSorter,
    totalPercentageOfRecipeIngredients,
  } = useRecipe({
    id: generalInfo?.id,
    chapterType: generalInfo?.type,
    shouldCallApi: true,
  });

  const {
    fetchAllergens,
    rawMaterialsAllergens,
    crossContaminationAllergens,
  } = useChapterAllergens({
    id: generalInfo?.id,
    chapterType: generalInfo?.type,
    shouldCallApi: true,
  });

  const {
    validateHandler,
    validationErrors,
    isSectionValidationLoading,
    selectSectionValidationErrors,
    resetSectionValidationErrors,
    setSectionBlockValidationError,
  } = useValidation({
    // @ts-ignore
    onValidate: validateChapterSection({
      chapterId: generalInfo?.id,
      chapterType: CHAPTER_TYPES_API[generalInfo?.type],
    }),
    chapterId: generalInfo?.id,
    afterValidationCallback: () => dispatch(getChapter()),
  });

  return (
    <SelectedSectionContext.Provider
      value={{
        validateHandler,
        isSectionValidationLoading,
        setSectionBlockValidationError,
        resetSectionValidationErrors,
        selectSectionValidationErrors,
        fetchRecipeIngredients,
        recipeIngredients,
        sortRecipeIngredient,
        fetchAllergens,
        rawMaterialsAllergens,
        crossContaminationAllergens,
        chapterState: generalInfo?.state,
        page,
        recipeSorter,
        totalPercentageOfRecipeIngredients,
      }}
    >
      <SpecificationSection.Title
        sectionName={sectionName}
        sectionStatus={sectionStatus}
        onValidate={validateHandler()}
        isValidationDisabled={isLibraryObjectLocked(generalInfo?.state)}
        hasValidationErrors={!isObjectEmpty(validationErrors)}
        isValidationLoading={isSectionValidationLoading(sectionId)}
        hasValidationPermission={true}
        hasValidation={!isLibraryObjectDetailsPage(page)}
      />
      {!isListEmpty(sections) && (
        <SubSections
          subSections={sections}
          currentSectionsRefs={currentSectionsRefs}
        />
      )}
    </SelectedSectionContext.Provider>
  );
};

export default SelectedSection;
