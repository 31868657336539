import { Col } from "antd";
import { Dictionary } from "types/general";
import { useListItems } from "hooks";
import { getFieldReadOnlyContent } from "../../utils";
import FieldReadOnly from "./components/FieldReadOnly";
import { getIsLastRow } from "./utils";

const FieldReadOnlyWrapper = ({
  element,
  widgetName,
  title,
  getTranslationForKey,
  refListDictionary,
  emptyValue,
}: {
  element: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  widgetName: string;
  title: string;
  getTranslationForKey: Function;
  refListDictionary: Dictionary<string>;
  emptyValue: string;
}) => {
  const { items: options } = useListItems(element?.props?.schema?.list || []);

  const content = getFieldReadOnlyContent({
    widgetName,
    element,
    getTranslationForKey,
    refListDictionary,
    emptyValue,
    options,
  });

  const isLastRow = getIsLastRow({ widgetName, title });

  if (!title && !content) {
    return null;
  }

  return (
    <Col span={24}>
      <FieldReadOnly {...{ title, content, isLastRow }} />
    </Col>
  );
};

export default FieldReadOnlyWrapper;
