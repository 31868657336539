import { useIntl } from "react-intl";
import { MATERIAL_TYPES, RECIPE_TYPES } from "utils/constants";
import messages from "messages/library";

const useMaterialFormInfo = () => {
  const { formatMessage } = useIntl();

  const recipeValues = [
    {
      dataTestId: "material-form-made-in-house",
      label: formatMessage(messages["recipeField"]["madeInHouse"]),
      value: RECIPE_TYPES.MADE_IN_HOUSE,
    },
    {
      dataTestId: "material-form-bought-to-third-party",
      label: formatMessage(messages["recipeField"]["boughtToThirdParty"]),
      value: RECIPE_TYPES.BOUGHT_TO_THIRD_PARTY,
    },
  ];

  const statusValues = [
    {
      label: formatMessage(messages["statusField"]["active"]),
      value: true,
    },
    {
      label: formatMessage(messages["statusField"]["inactive"]),
      value: false,
    },
  ];

  const typeValues = [
    {
      label: formatMessage(messages.typeRawMaterialOptionLabel),
      value: MATERIAL_TYPES.RAW_MATERIAL,
    },
    {
      label: formatMessage(messages.typeCompositeOptionLabel),
      value: MATERIAL_TYPES.COMPOSITE,
    },
    {
      label: formatMessage(messages.typeAdditiveOptionLabel),
      value: MATERIAL_TYPES.ADDITIVE,
    },
    {
      label: formatMessage(messages.typeProcessingAidOptionLabel),
      value: MATERIAL_TYPES.PROCESSING_AID,
    },
  ];

  return {
    recipeValues,
    statusValues,
    typeValues,
  };
};

export default useMaterialFormInfo;
