import { useRef } from "react";
import { Modal, Search, Paragraph } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import InfiniteScrollLibraryContent from "components/InfiniteScrollLibraryContent";
import { AddPackagingComponentsModalProps } from "./types";
import { useSelectedLibraryObject } from "hooks";
import { useAddPackagingComponents } from "./hooks";
import { usePackagingSystemComponentsActions } from "../../hooks";
import { messages } from "./messages";
import generalMessages from "messages/general";
import styles from "./styles.module.less";

const AddPackagingComponentsModal: React.FC<AddPackagingComponentsModalProps> = ({
  onCloseModal,
  packagingSystemId,
  packagingComponentIds: existingPackagingComponentsIds,
  getPackagingSystemComponents,
  mainComponentId,
}) => {
  const { formatMessage } = useIntl();
  const infiniteScrollWrapper = useRef<HTMLDivElement>(null);

  const {
    selectedLibraryObjects: selectedPackagingComponents,
    setSelectedLibraryObjects: setSelectedPackagingComponents,
    onSelectLibraryObject: onSelectPackagingComponent,
    selectedLibraryObjectsIds: selectedPackagingComponentsIds,
  } = useSelectedLibraryObject({
    existingLibraryObjectIds: existingPackagingComponentsIds,
  });

  const {
    packagingComponentsList,
    canLoadMore,
    totalNumberOfItems,
    searchPackagingComponents,
    onCloseModalHandler,
    isPrimaryButtonDisabled,
    searchText,
    setSearchText,
  } = useAddPackagingComponents({
    selectedPackagingComponents,
    setSelectedPackagingComponents,
    onCloseModal,
    infiniteScrollWrapper,
  });

  const { updateComponentsAssociation } = usePackagingSystemComponentsActions({
    packagingSystemId,
    packagingComponentIds: existingPackagingComponentsIds,
    getPackagingSystemComponents,
    selectedPackagingComponentsIds,
    mainComponentId,
  });

  const handlePrimaryButtonClick = async () => {
    await updateComponentsAssociation();
    onCloseModalHandler();
  };

  return (
    <Modal
      open
      size="l"
      onCancel={onCloseModalHandler}
      title={formatMessage(messages.modalTitle)}
      headerChildren={
        <>
          <Paragraph>{formatMessage(messages.modalSubTitle)}</Paragraph>
          <Search
            className={styles.searchBar}
            onSearch={setSearchText}
            placeholder={formatMessage(messages.searchPlaceholder)}
          />
        </>
      }
      onPrimaryButtonClick={handlePrimaryButtonClick}
      onSecondaryButtonClick={onCloseModalHandler}
      primaryButtonText={formatMessage(generalMessages.select)}
      secondaryButtonText={formatMessage(generalMessages.cancel)}
      primaryButtonProps={{
        disabled: isPrimaryButtonDisabled,
      }}
    >
      <InfiniteScrollLibraryContent
        title={formatMessage(messages.modalBodyTitle)}
        totalNumberOfItems={totalNumberOfItems}
        canLoadMore={canLoadMore}
        libraryObjectList={packagingComponentsList}
        nextFetchCallback={() => searchPackagingComponents({ searchText })}
        onSelectLibraryItem={onSelectPackagingComponent}
        existingLibraryObjectIds={existingPackagingComponentsIds}
        selectedLibraryObjectIds={selectedPackagingComponentsIds}
        infiniteScrollWrapper={infiniteScrollWrapper}
      />
    </Modal>
  );
};

export default AddPackagingComponentsModal;
