import { WIZARD_STEPS } from "utils/constants";
import Invitation from "./components/Invitation";
import Product from "./components/Product";
import Specification from "./components/Specification";
import Summary from "./components/Summary";
import { withDraftValidation, withProperStepValidation } from "./hocs";

const Creation = {
  Product: withDraftValidation(
    withProperStepValidation(WIZARD_STEPS.PRODUCT)(Product)
  ),
  Invitation: withDraftValidation(
    withProperStepValidation(WIZARD_STEPS.INVITATION)(Invitation)
  ),
  Specification: withDraftValidation(
    withProperStepValidation(WIZARD_STEPS.SPECIFICATION)(Specification)
  ),
  Summary: withDraftValidation(
    withProperStepValidation(WIZARD_STEPS.SUMMARY)(Summary)
  ),
};

export default Creation;
