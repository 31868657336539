import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store";
import {
  keepAsDraftMaterial,
  publishMaterial,
} from "store/materialForm/asyncActions";
import {
  selectKeepAsDraftStatus,
  selectMaterialInformation,
  selectPublishStatus,
} from "store/materialForm/selectors";
import { useRedirect } from "hooks";
import { LIBRARY_OBJECT_TYPES } from "utils/constants";
import { getMaterialApiType, isLibraryObjectEditPage } from "utils/library";
import { PageType } from "components/Library/components/types";

const useMaterialFormActions = ({ page }: { page: PageType }) => {
  const dispatch = useAppDispatch();
  const materialInformation = useSelector(selectMaterialInformation);

  const {
    inProgress: isPublishInProgress,
    isDone: isPublishDone,
    hasError: hasPublishError,
  } = useSelector(selectPublishStatus);
  const {
    inProgress: isKeepAsDraftInProgress,
    isDone: isKeepAsDraftDone,
    hasError: hasKeepAsDraftError,
  } = useSelector(selectKeepAsDraftStatus);

  const { redirectToLibraryListing, redirectToLibraryDetails } = useRedirect();

  const onPublish = async () => {
    await dispatch(publishMaterial());
  };

  const onKeepAsDraft = async () => {
    await dispatch(keepAsDraftMaterial());
  };

  useEffect(() => {
    if (isPublishDone && !hasPublishError) {
      if (isLibraryObjectEditPage(page)) {
        redirectToLibraryDetails({
          id: materialInformation.id,
          type: getMaterialApiType({
            materialType: materialInformation.materialType,
            recipeType: materialInformation.recipeType,
          }),
          pushState: {
            skipDetectLeavePage: true,
          },
        });
      } else {
        redirectToLibraryListing(LIBRARY_OBJECT_TYPES.MATERIALS, {
          skipDetectLeavePage: true,
        });
      }
    }
  }, [isPublishDone]);

  useEffect(() => {
    if (isKeepAsDraftDone && !hasKeepAsDraftError) {
      redirectToLibraryListing(LIBRARY_OBJECT_TYPES.MATERIALS, {
        skipDetectLeavePage: true,
      });
    }
  }, [isKeepAsDraftDone]);

  return {
    areActionsInProgress: isPublishInProgress || isKeepAsDraftInProgress,
    onPublish,
    onKeepAsDraft,
  };
};

export default useMaterialFormActions;
