import { Select } from "@trace-one/design-system";
import { Label } from "@trace-one/react-components";
import { useIntl } from "react-intl";
import { useSuppliersList } from "./hooks";
import { SupplierIngredientsProps } from "./types";
import messages from "./messages";
import styles from "../../styles.module.less";

const SupplierIngredients: React.FC<SupplierIngredientsProps> = ({
  displayActions,
  disableActions,
  ingredients,
  supplierId,
  assignedIngredientIds,
  materialIngredients,
}) => {
  const { formatMessage } = useIntl();

  const {
    onSelectSupplierIngredient,
    onDeselectSupplierIngredient,
    ingredientOptions,
  } = useSuppliersList({ materialIngredients, assignedIngredientIds });

  return (
    <Label title={formatMessage(messages.rawMaterialSupplierDetailsIngredient)}>
      {displayActions ? (
        <Select
          mode="multiple"
          name="supplierIngredientsIds"
          placeholder={formatMessage(
            messages.rawMaterialSupplierDetailsIngredientPlaceholder
          )}
          value={assignedIngredientIds}
          options={ingredientOptions}
          optionFilterProp="children"
          onSelect={onSelectSupplierIngredient({
            supplierId,
          })}
          onDeselect={onDeselectSupplierIngredient({
            supplierId,
          })}
          data-testid="suppliers-ingredients-ids"
          notFoundMessage={formatMessage(
            messages.rawMaterialSupplierDetailsIngredientEmpty
          )}
          disabled={disableActions}
          allowClear={false}
        />
      ) : (
        <span className={styles.supplierValue}>
          {ingredients.map(({ name }) => name).join(", ")}
        </span>
      )}
    </Label>
  );
};

export default SupplierIngredients;
