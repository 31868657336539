import { SpecificationState } from "types/specification";

export const getCircleStyleKey = (state: SpecificationState) => {
  switch (state) {
    case "signedBySupplier":
      return "circleBlue";
    case "signedByRetailer":
      return "circleGreen";
    default:
      return null;
  }
};
