import { useMemo } from "react";
import { Tag as TagTO } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import { getColorForTag, getLabelForTag, getModeForTag } from "./utils";
import statusMessages from "messages/statuses";

const Tag = ({
  state = "draft",
  size = "medium",
}: {
  state: string;
  size?: "small" | "medium" | "large";
}) => {
  const { formatMessage } = useIntl();

  const label = useMemo(
    () =>
      getLabelForTag({
        messages: statusMessages,
        messageKey: state,
        formatMessage,
      }),
    [formatMessage, statusMessages, state]
  );

  const color = useMemo(() => getColorForTag(state), [state]);

  const mode = useMemo(() => getModeForTag(color), [color]);

  return <TagTO mode={mode} size={size} label={label} color={color} />;
};

export default Tag;
