import classnames from "classnames";
import { Col, Row } from "antd";
import { Button } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import Origin from "components/Origin";
import ReflistSelect from "components/ReflistSelect";
import { useMaterialPermissions } from "components/Library/components/MaterialForm/hooks";
import { useOriginActions } from "./hooks";
import { isAddButtonDisabled } from "./utils";
import { isListEmpty } from "utils/general";
import { OriginsListProps } from "./types";
import messages from "./messages";
import { REFLIST_IDS } from "utils/constants";
import { MaterialOriginData } from "models";
import styles from "./styles.module.less";

const OriginsList: React.FC<OriginsListProps> = ({
  displayActions = true,
  supplierId,
  origins = [],
  refListDictionary = {},
  isAddOriginDisabled,
  handleAddOrigin = null,
  handleUpdateOrigin = null,
  handleRemoveOrigin = null,
  uiSchema,
  extraErrors,
}) => {
  const { formatMessage } = useIntl();
  const {
    onAddOrigin,
    onRemoveOrigin,
    onUpdateOrigin,
    getValue,
  } = useOriginActions({
    displayActions,
    supplierId,
    refListDictionary,
  });
  const { disableActions } = useMaterialPermissions();

  const actionsDisabled = supplierId ? disableActions : !displayActions;

  const handleRemoveClick = async (value: MaterialOriginData["typeId"]) => {
    if (handleRemoveOrigin && typeof handleRemoveOrigin === "function") {
      return await handleRemoveOrigin?.(value);
    }
    onRemoveOrigin(value)();
  };

  const getFieldErrors = (index: number, fieldName: string) => {
    const itemErrors = extraErrors && extraErrors[index];
    if (itemErrors && itemErrors[fieldName] && itemErrors[fieldName].__errors) {
      return itemErrors[fieldName].__errors;
    }
    return [];
  };

  return (
    <>
      <div>
        {origins.map((origin, index) => (
          <Row
            gutter={[16, 16]}
            key={`${origin.typeId || origin?.originTypeId}`}
            className={styles.originBox}
          >
            <Col xs={22}>
              <Origin
                shouldCallApi={false}
                refListDictionary={refListDictionary}
                classNameWrapper={styles.originWrapper}
                displayActions={displayActions}
                onChange={handleUpdateOrigin || onUpdateOrigin}
                uiSchema={uiSchema}
                data={{
                  originTypeId: {
                    required: true,
                    value: getValue(origin.typeId || origin?.originTypeId),
                    dataTestId:
                      "raw-material-form-supplier-details-origin-type",
                    name: "typeId",
                    disabled: true,
                  },
                  countryId: {
                    required: true,
                    value: getValue(origin.countryId),
                    dataTestId: "raw-material-form-supplier-details-country",
                    name: "countryId",
                    disabled: actionsDisabled,
                    allowClear: false,
                    validateStatus:
                      !isListEmpty(
                        getFieldErrors(index, "countryId") as string[]
                      ) && "error",
                    errorMessage: getFieldErrors(index, "countryId")?.[0] || "",
                  },
                  regionId: {
                    value: getValue(origin.regionId),
                    dataTestId: "raw-material-form-supplier-details-region",
                    name: "regionId",
                    disabled: actionsDisabled,
                  },
                  zoneId: {
                    value: getValue(origin.zoneId),
                    dataTestId: "raw-material-form-supplier-details-zone",
                    name: "zoneId",
                    disabled: actionsDisabled,
                  },
                }}
              />
            </Col>
            {displayActions && (
              <Col xs={2} className={classnames(styles.removeOriginBtn)}>
                <Button
                  type="tertiary"
                  iconName="trash"
                  disabled={actionsDisabled}
                  data-test-id="remove-origin-btn"
                  onClick={() =>
                    handleRemoveClick(origin?.typeId || origin?.originTypeId)
                  }
                />
              </Col>
            )}
          </Row>
        ))}
      </div>
      {displayActions && (
        <Row>
          <Col xs={5}>
            <ReflistSelect
              value={null}
              defaultValue={null}
              refListId={REFLIST_IDS.ORIGIN_TYPES}
              data-test-id="add-origin-btn"
              disabled={
                handleAddOrigin
                  ? actionsDisabled
                  : isAddButtonDisabled({
                      disableActions,
                      isAddOriginDisabled,
                      origins,
                    })
              }
              placeholder={formatMessage(messages.addOrigin)}
              onChange={handleAddOrigin || onAddOrigin}
              selectedOptionIds={origins.map(
                ({ typeId, originTypeId }) => typeId || originTypeId
              )}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default OriginsList;
