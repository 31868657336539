/* istanbul ignore file */
import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useErrorCodeCheck, useRedirect } from "hooks";
import ConfirmedSharingModal from "components/ConfirmedSharingModal";
import {
  selectIsSpecificationFinalized,
  selectExistingSpecificationId,
  selectSpecification,
} from "store/specificationCreation/selectors";
import { ERROR_CODES } from "utils/constants";
import { onCreateClick, onViewClick } from "./utils";
import { messages } from "./messages";

const withDraftValidation = Component => props => {
  const { push } = useHistory();
  const intl = useIntl();
  const { draftId } = useSelector(selectSpecification);
  const isSpecificationFinalized = useSelector(selectIsSpecificationFinalized);
  const existingSpecificationId = useSelector(selectExistingSpecificationId);
  const redirectToSpecificationId = existingSpecificationId
    ? existingSpecificationId
    : draftId;
  const { isDesiredErrorCode } = useErrorCodeCheck(ERROR_CODES.CANNOT_SHARE);
  const { redirectToSpecificationDetails } = useRedirect();

  return isDesiredErrorCode || isSpecificationFinalized ? (
    <ConfirmedSharingModal
      type="specification"
      onCreateClick={onCreateClick({ push })}
      onViewClick={onViewClick({
        redirectToSpecificationDetails,
        specificationId: redirectToSpecificationId,
      })}
      titleMessage={intl.formatMessage(
        messages.specificationAlreadyCreatedTitle
      )}
      contentMessage={intl.formatMessage(
        messages.specificationAlreadyCreatedContent
      )}
    />
  ) : (
    <Component {...props} />
  );
};

export default withDraftValidation;
