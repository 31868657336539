import { LibraryItemState, LibraryObjectType } from "types/library";
import { isLibraryObjectClaimType, isLibraryObjectLocked } from "utils/library";
import { libraryMessages } from "messages";

export const getPrimaryButtonLabel = ({
  type,
  state,
  formatMessage,
}: {
  type: LibraryObjectType;
  state: LibraryItemState;
  formatMessage: Function;
}) => {
  let message;

  if (isLibraryObjectClaimType(type)) {
    message = formatMessage(libraryMessages.save);
  } else if (isLibraryObjectLocked(state)) {
    message = formatMessage(libraryMessages.createNewVersion);
  } else {
    message = formatMessage(libraryMessages.editButton);
  }

  return message;
};
