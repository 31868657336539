import React from "react";
import { FormattedMessage } from "react-intl";
import { Row, Col } from "antd";
import {
  Button,
  Illustration,
  Heading,
  Paragraph,
} from "@trace-one/design-system";
import { isListEmpty } from "utils/general";
import SupplierList from "./components/SupplierList";
import { ConfirmationStepPanelProps } from "./types";
import { messages } from "./messages";
import styles from "./styles.module.less";

const ConfirmationStepPanel: React.FC<ConfirmationStepPanelProps> = ({
  tradeItemName,
  onViewClick,
  onListingClick,
  supplierListWithSuccess,
  supplierListWithError,
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.headImage} data-testid="headImage">
        <Illustration
          color="grey"
          name="supplier"
          data-testid="image-component"
        />
      </div>
      <Heading size="s" className={styles.title}>
        <FormattedMessage {...messages.title} />
      </Heading>
      {!isListEmpty(supplierListWithSuccess) && (
        <SupplierList
          supplierList={supplierListWithSuccess}
          description={
            <FormattedMessage
              {...messages.description}
              values={{
                tradeItemName: (
                  <span className={styles.tradeItemName}>{tradeItemName}</span>
                ),
              }}
            />
          }
        />
      )}
      {!isListEmpty(supplierListWithError) && (
        <SupplierList
          supplierList={supplierListWithError}
          description={<FormattedMessage {...messages.descriptionError} />}
        />
      )}
      <Paragraph>
        <FormattedMessage {...messages.whatDoYouWantToDo} />
      </Paragraph>
      <Row justify="center" gutter={[16, 0]} className={styles.validateRow}>
        <Col>
          <Button
            type="secondary"
            data-test-id="back-to-specification-btn"
            onClick={onViewClick}
          >
            <FormattedMessage {...messages.backToSpecification} />
          </Button>
        </Col>
        <Col>
          <Button
            data-test-id="view-specification-list-btn"
            onClick={onListingClick}
          >
            <FormattedMessage {...messages.viewSpecificationList} />
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default ConfirmationStepPanel;
