import { defineMessages } from "react-intl";

export const messages = defineMessages({
  search: {
    id: "general.search",
    defaultMessage: "Search",
  },

  searchPlaceholder: {
    id: "library.search.placeholder",
    defaultMessage: "Type a name",
  },

  noItemFound: {
    id: "select.notFoundMessage",
    defaultMessage: "No item found",
  },

  listingResultNumber: {
    id: "library.table.listingResultNumber",
    defaultMessage: "Displaying {current} of {total} {libraryObjectType}",
  },

  detailsAndLinksChapterModalTitle: {
    id: "library.table.column.links.detailsAndLinksChapterModalTitle",
    defaultMessage: "Chapter details and linked specifications",
  },

  libraryItemSelectionTitle: {
    id: "library.itemSelection.title",
    defaultMessage: "Your selection",
  },
});
