import { FOLDER_STATE } from "utils/constants";

export const isDraftState = (state: FOLDER_STATE) =>
  FOLDER_STATE.DRAFT === state;

export const getDeleteMessageKey = (state: FOLDER_STATE) =>
  isDraftState(state) ? "deleteDraft" : "deleteGeneral";

export const getResumeCreationAction = ({
  resumeFolder,
  id,
}: {
  resumeFolder: Function;
  id: string;
}) => async () => {
  await resumeFolder(id);
};
