import { useMemo } from "react";
import { LabeledValue } from "antd/lib/select";
import { useAppDispatch } from "store";
import { useSelector } from "react-redux";
import { selectTranslationManagement } from "store/specificationDetails/selectors";
import {
  setTranslationManagementCompanies,
  setTranslationManagement,
} from "store/specificationDetails/specificationDetailsSlice";
import { SpecificationContactType } from "utils/constants";
import { v4 } from "uuid";

export interface CompanyLangaugeBlock {
  company: LabeledValue;
  languages: string[];
  collaborations: {
    collaborationId: string;
    contactType: SpecificationContactType;
  }[];
}

export interface UseTranslationCompaniesProps {}

const useTranslationCompanies = () => {
  const {
    companies,
    collaborations,
    ...restOfTranslationManagement
  } = useSelector(selectTranslationManagement);

  const dispatch = useAppDispatch();

  const onAddCompany = () => {
    dispatch(
      setTranslationManagementCompanies([
        ...companies,
        {
          key: v4(),
          company: null,
          languages: [],
          collaborations: [],
        },
      ])
    );
  };

  const excludedCompanies = useMemo(() => {
    return companies
      .map(currentCompaneyBlock => {
        return currentCompaneyBlock?.company?.value as string;
      })
      .filter(value => value);
  }, [companies]);

  const excludedLanguages = useMemo(() => {
    return companies.reduce((acc, company) => {
      return [...acc, ...[company.languages]].flat();
    }, []);
  }, [companies]);

  const onLanguageChange = (
    index: number,
    type: "select" | "deselect",
    selectedLanguage
  ) => {
    dispatch(
      setTranslationManagementCompanies(
        companies.map((company, preIndex) => {
          if (preIndex === index) {
            if (type === "select") {
              return {
                ...company,
                languages: [...company.languages, selectedLanguage.value],
              };
            } else {
              return {
                ...company,
                languages: company.languages.filter(
                  lang => lang !== selectedLanguage.value
                ),
              };
            }
          }
          return company;
        })
      )
    );
  };

  const onChange = (
    index: number,
    field: string,
    value: LabeledValue | null | string[]
  ) => {
    let collaborationsToBeRemoved = [];

    const updatedCompanies = companies.map((currentBlock, currentIndex) => {
      if (currentIndex === index) {
        // when clear field or change the field
        if (field === "company" && (value === null || currentBlock.company)) {
          collaborationsToBeRemoved = currentBlock?.collaborations;
          return {
            ...currentBlock,
            [field]: value,
            collaborations: [],
          };
        }

        return {
          ...currentBlock,
          [field]: value,
        };
      }
      return currentBlock;
    });

    const updatedCollaborations = collaborations?.filter(
      collab => !collaborationsToBeRemoved.includes(collab)
    );

    dispatch(
      setTranslationManagement({
        ...restOfTranslationManagement,
        companies: updatedCompanies,
        collaborations: updatedCollaborations,
      })
    );
  };

  const onRemoveBlock = (index: number) => {
    const filteredCompanies = companies.filter(
      (_: CompanyLangaugeBlock, currentIndex: number) => currentIndex !== index
    );

    const collaborationsToBeRemoved = companies[index]?.collaborations;

    const updatedCollaborations = collaborations?.filter(
      collab => !collaborationsToBeRemoved.includes(collab)
    );

    dispatch(
      setTranslationManagement({
        ...restOfTranslationManagement,
        companies: filteredCompanies,
        collaborations: updatedCollaborations,
      })
    );
  };

  return {
    companies,
    onAddCompany,
    onLanguageChange,
    onChange,
    excludedCompanies,
    excludedLanguages,
    onRemoveBlock,
  };
};

export default useTranslationCompanies;
