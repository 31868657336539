import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { REFLIST_IDS } from "utils/constants";
import { selectLanguageCode } from "store/user/selectors";
import { fetchReferenceListItemByCode } from "apis/RLMD";
import { fetchCompany } from "apis/CUMD";
import { UseCompanyDetailsProps, CompanyDetailsData } from "./types";

const useCompanyDetails = ({
  companyId,
  shouldFetchCompanyType = false,
}: UseCompanyDetailsProps) => {
  const languageCode = useSelector(selectLanguageCode);
  const [companyData, setCompanyData] = useState<CompanyDetailsData>({});

  useEffect(() => {
    getCompanyData();
  }, [companyId, shouldFetchCompanyType]);

  const getCompanyData = async () => {
    try {
      const {
        data: {
          companyDisplayName,
          companyActivityId,
          companyLogoUrl,
          companyAddress1,
          companyAddress2,
          companyCity,
          companyCountry,
          companyPostalCode,
        },
      } = await fetchCompany({ id: companyId });

      setCompanyData({
        companyDisplayName,
        companyActivityId,
        companyLogoUrl,
        companyAddress1,
        companyAddress2,
        companyCity,
        companyPostalCode,
        companyCountryName: "",
      });

      const fetchCountryName = fetchReferenceListItemByCode({
        languageCode,
        id: REFLIST_IDS.COUNTRIES,
        code: companyCountry,
      });

      const fetchCompanyType = shouldFetchCompanyType
        ? fetchReferenceListItemByCode({
            languageCode,
            id: REFLIST_IDS.COMPANY_ACTIVITY,
            code: companyActivityId.toString(),
          })
        : Promise.reject();

      Promise.allSettled([fetchCountryName, fetchCompanyType]).then(results => {
        const [countryName, companyType] = results;

        if (countryName.status === "fulfilled") {
          setCompanyData(prev => {
            return { ...prev, companyCountryName: countryName.value.data.text };
          });
        }
        if (companyType.status === "fulfilled") {
          setCompanyData(prev => {
            return { ...prev, typeName: companyType.value.data.text };
          });
        }
      });
    } catch (_) {}
  };

  return { companyData };
};

export default useCompanyDetails;
