import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectLanguageCode } from "store/user/selectors";
import { fetchChapterTypes } from "apis/SPEC";
import { ChapterType } from "../../types";
import { REFLIST_IDS } from "utils/constants";
import { isFAndVOnly } from "utils/general";

const useChapterTypes = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [chapterTypes, setChapterTypes] = useState<ChapterType[]>([]);
  const [productTypeIds, setProductTypeIds] = useState<string[]>([]);
  const languageCode = useSelector(selectLanguageCode);

  useEffect(() => {
    const getChapterTypes = async () => {
      setIsLoading(true);
      setHasError(false);

      try {
        const { data } = await fetchChapterTypes({ languageCode });

        let productTypeIds = new Set<string>([]);
        let chapterTypesObject = data.reduce(
          (previousState, { productTypeId, ...currentChapterType }) => {
            if (!currentChapterType["isChapterLibrary"]) {
              return previousState;
            }

            productTypeIds.add(productTypeId);
            if (previousState[currentChapterType["chapterType"]]) {
              return {
                ...previousState,
                [currentChapterType["chapterType"]]: {
                  ...previousState[currentChapterType["chapterType"]],
                  productTypeIds: [
                    ...previousState[currentChapterType["chapterType"]]
                      .productTypeIds,
                    productTypeId,
                  ],
                },
              };
            }

            return {
              ...previousState,
              [currentChapterType["chapterType"]]: {
                ...currentChapterType,
                productTypeIds: [productTypeId],
              },
            };
          },
          {}
        );

        let filterProductTypeIds = [...productTypeIds];
        let filteredChapterTypes = Object.values(chapterTypesObject);

        if (isFAndVOnly) {
          filterProductTypeIds = filterProductTypeIds.filter(
            productTypeId => productTypeId !== REFLIST_IDS.FOOD_PRODUCT_TYPE
          );

          filteredChapterTypes = filteredChapterTypes.filter(item => {
            const hasOnlyOneTypeId =
              item.productTypeIds.length === 1 &&
              item.productTypeIds[0] === REFLIST_IDS.FOOD_PRODUCT_TYPE;
            return !hasOnlyOneTypeId;
          });
        }

        setProductTypeIds(filterProductTypeIds);
        setChapterTypes(filteredChapterTypes);
      } catch (e) {
        setHasError(true);
      } finally {
        setIsLoading(false);
      }
    };

    getChapterTypes();
  }, [languageCode]);

  return {
    isLoading,
    hasError,
    chapterTypes,
    productTypeIds,
  };
};

export default useChapterTypes;
