import { CategoryItemData } from "models";
import update from "lodash.update";
import {
  SimplifiedHierarchyItem,
  GeneralTree,
  Dictionary,
} from "types/general";
import { isListEmpty } from "./general";

export const prepareHierarchyList = (data: CategoryItemData[]) =>
  data.map(item => {
    return {
      title: item.categoryItemName,
      key: item.categoryItemId,
      parentCategoryItemId:
        item.parent4CategoryItemId ||
        item.parent3CategoryItemId ||
        item.parent2CategoryItemId ||
        item.parent1CategoryItemId,
    };
  });

export const convertListToTree = ({
  items,
  defaultCheckedItems,
  key = null,
  link = "parentCategoryItemId",
}: {
  items: SimplifiedHierarchyItem[];
  defaultCheckedItems: string[];
  key?: string;
  link?: string;
}) =>
  items
    .filter(item => item[link] === key)
    .map(item => ({
      title: item.title,
      key: item.key,
      children: convertListToTree({
        items,
        defaultCheckedItems,
        key: item.key,
      }),
      ...(defaultCheckedItems && {
        disabled:
          defaultCheckedItems.includes(item.key) ||
          defaultCheckedItems.includes(item.parentCategoryItemId),
      }),
    }));

export const getNodeFromTree = ({
  tree,
  nodeValue,
  link,
}: {
  tree: GeneralTree;
  nodeValue: string;
  link: string;
}) => {
  if (tree[link] === nodeValue) {
    return tree;
  } else if (!isListEmpty(tree.children)) {
    let result = null;

    tree.children.forEach(child => {
      if (result === null) {
        result = getNodeFromTree({ tree: child, nodeValue, link });
      }
    });

    return result;
  }
  return null;
};

export const deleteNodeFromTree = ({
  tree,
  nodeValue,
}: {
  tree: GeneralTree;
  nodeValue: string;
}): boolean => {
  if (!isListEmpty(tree.children)) {
    //@ts-ignore
    return tree.children.forEach(child => {
      let filtered = tree.children.filter(
        item => item.hierarchyItemId === nodeValue
      );
      if (!isListEmpty(filtered)) {
        tree.children = tree.children.filter(
          item => item.hierarchyItemId !== nodeValue
        );
        return true;
      }
      deleteNodeFromTree({ tree: child, nodeValue });
    });
  }
  return false;
};

export const addChildItemToTree = ({
  children,
  parentIds,
  selectedId,
  link,
}: {
  children: GeneralTree["children"];
  parentIds: string[];
  selectedId: string;
  link: string;
}) => {
  const newItem = {
    [link]: selectedId,
    children: [],
  };

  if (isListEmpty(parentIds) && selectedId) {
    return [...children, newItem];
  }

  if (
    isListEmpty(children) ||
    children.findIndex(child => child[link] === parentIds[0]) === -1
  ) {
    return [
      ...children,
      parentIds.reduceRight(
        (previousState, currentItem) => ({
          [link]: currentItem,
          children: [previousState],
        }),
        newItem
      ),
    ];
  }

  return children.map(child => {
    if (child[link] !== parentIds[0]) {
      return { ...child };
    }

    const newParentsList = parentIds.slice(1);

    if (isListEmpty(newParentsList)) {
      return {
        ...child,
        children: [...child.children, newItem],
      };
    }

    return {
      ...child,
      children: addChildItemToTree({
        children: child.children,
        parentIds: newParentsList,
        selectedId,
        link,
      }),
    };
  });
};

export const updateNodeInTree = ({
  tree,
  nodeValue,
  newValue,
  link,
  fieldToUpdate,
}: {
  tree: GeneralTree;
  nodeValue: string;
  newValue: any;
  link: string;
  fieldToUpdate: string;
}) => {
  if (tree[link] === nodeValue) {
    update(tree, fieldToUpdate, () => newValue);
  } else if (!isListEmpty(tree.children)) {
    for (let i = 0; i < tree.children.length; i++) {
      updateNodeInTree({
        tree: tree.children[i],
        nodeValue,
        newValue,
        link,
        fieldToUpdate,
      });
    }
  }
};

export const getReflistIdsFromTree = ({
  items,
  categoryIds = [],
  link = "id",
  childrenKey = "children",
}: {
  items: any[];
  categoryIds?: string[];
  link?: string;
  childrenKey?: string;
}) => {
  if (isListEmpty(items)) return [];

  return items.reduce(
    (previousState, currentItem) => {
      let newState = [...previousState, currentItem[link]];

      if (!isListEmpty(currentItem[childrenKey])) {
        return [
          ...getReflistIdsFromTree({
            categoryIds: newState,
            items: currentItem[childrenKey],
            link,
            childrenKey,
          }),
        ];
      }

      return [...newState];
    },
    [...categoryIds]
  );
};

export const isParentHalfChecked = ({
  children,
  key,
}: {
  children: GeneralTree["children"];
  key: string;
}) => {
  return children.some(child => {
    if (!isListEmpty(child.children)) {
      if (child[key]) {
        return true;
      } else {
        return isParentHalfChecked({ children: child.children, key });
      }
    }
    return child[key];
  });
};

export const getHierarchyCategoryItem = (
  searchCategoryItemId: CategoryItemData["categoryItemId"],
  currentCategoryItems: CategoryItemData[],
  categoryItemsDictionary: Dictionary
) => {
  if (!searchCategoryItemId || isListEmpty(currentCategoryItems))
    return undefined;

  const currentCategoryItemsTemporary = currentCategoryItems.map(item => {
    if (item.parent1CategoryItemId && !item.parent1CategoryItemName) {
      item.parent1CategoryItemName =
        categoryItemsDictionary[item.parent1CategoryItemId];
    }
    if (item.parent2CategoryItemId && !item.parent2CategoryItemName) {
      item.parent2CategoryItemName =
        categoryItemsDictionary[item.parent2CategoryItemId];
    }
    if (item.parent3CategoryItemId && !item.parent3CategoryItemName) {
      item.parent3CategoryItemName =
        categoryItemsDictionary[item.parent3CategoryItemId];
    }
    if (item.parent4CategoryItemId && !item.parent4CategoryItemName) {
      item.parent4CategoryItemName =
        categoryItemsDictionary[item.parent4CategoryItemId];
    }
    return item;
  });

  return currentCategoryItemsTemporary.find(
    ({ categoryItemId }) => categoryItemId === searchCategoryItemId
  );
};
