import { createContext } from "react";
import { Dictionary } from "types/general";
import { LibraryItemDetails } from "pages/Library/hocs/withLibraryItemDetails/types";

const LibraryDetails = createContext<{
  libraryItemDetails: LibraryItemDetails;
  typeDictionary?: Dictionary;
}>({
  libraryItemDetails: {},
  typeDictionary: {},
});

export default LibraryDetails;
