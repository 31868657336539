import { defineMessages } from "react-intl";

export const templateMessages = defineMessages({
  createYourTemplate: {
    id: "template.createYourTemplate",
    defaultMessage: "Create your template",
  },
  templateCustomizeStructure: {
    id: "template.customizeStructure",
    defaultMessage: "Customize your structure",
  },
  templateCustomizeStructureDescription: {
    id: "template.customize.description",
    defaultMessage:
      "In order to create a valid structure, you will need to define which chapters and sections will be displayed in your specifications.",
  },
  templateModification: {
    id: "template.templateModification",
    defaultMessage: "Template modification",
  },
  templateModificationDescription: {
    id: "template.modification.description",
    defaultMessage:
      "Changing the product type will erase all filled informations and files. You will need to start over.",
  },
  cancelTemplateCreationTitle: {
    id: "template.cancelTemplateCreationTitle",
    defaultMessage: "Cancel template creation",
  },
  deleteTemplateButtonLabel: {
    id: "template.deleteTemplateButtonLabel",
    defaultMessage: "Delete template",
  },
  cancelTemplateCreationDescription: {
    id: "template.cancelTemplateCreationDescription",
    defaultMessage:
      "The template has been automatically saved as a draft. By leaving now, you can either keep it as a draft or cancel the creation and delete it.",
  },
});
