import { Heading, Paragraph } from "@trace-one/design-system";
import styles from "./styles.module.less";
import { SupplierListProps } from "./types";

const SupplierList: React.FC<SupplierListProps> = ({
  supplierList,
  description,
}) => {
  return (
    <>
      <Paragraph>{description}</Paragraph>
      <div className={styles.supplierList}>
        {supplierList.map(supplierName => (
          <Heading key={supplierName} size="xxs">
            {supplierName}
          </Heading>
        ))}
      </div>
    </>
  );
};

export default SupplierList;
