import { defineMessages } from "react-intl";

export const messages = defineMessages({
  detailsTitle: {
    id: "folderDetails.details.title",
    defaultMessage: "Folder details",
  },
  detailsSubtitle: {
    id: "folderDetails.details.subtitle",
    defaultMessage: "Basic information about your folder",
  },
  assignedProductsTitle: {
    id: "folderDetails.assignedProducts.title",
    defaultMessage: "Assigned products",
  },
  assignedProductsSubtitle: {
    id: "folderDetails.assignedProducts.subtitle",
    defaultMessage:
      "Products assigned to the Folder and their related Specifications statuses",
  },
  errorMessage: {
    id: "folderDetails.error.message",
    defaultMessage: "Sorry, an error occured while accessing Folder data.",
  },
  noTradeItems: {
    id: "folderDetails.tradeItems.noItem",
    defaultMessage: "No product has been assigned to this Folder",
  },
});
