import { defineMessages } from "react-intl";

export const messages = defineMessages({
  createNewProduct: {
    id: "creationFolder.products.createNewProduct",
    defaultMessage: "Create a new product",
  },

  addExisitingProduct: {
    id: "creationFolder.products.addExisitingProduct",
    defaultMessage: "Add an existing product",
  },

  selectSupplier: {
    id: "specificationCreation.product.selectSuppliers",
    defaultMessage: "Select  a supplier",
  },

  selectSuppliers: {
    id: "creationFolder.products.selectSuppliers",
    defaultMessage:
      "Select one or multiple suppliers to create a new Specification",
  },

  noSupplierAssigned: {
    id: "creationFolder.products.noSupplierAssigned",
    defaultMessage:
      "Please assign a supplier or remove this product before proceeding to the next step",
  },

  existingSpecifications: {
    id: "specificationCreation.product.existingSpecifications",
    defaultMessage: "Existing specifications for this product",
  },

  errorFieldMessage: {
    id: "creation.errors.notSaved",
    defaultMessage: "An error has occurred, the last changes were not saved",
  },

  errorRemoveProduct: {
    id: "creation.errors.remove.product",
    defaultMessage: "An error has occurred, the product couldn't be removed",
  },

  errorRemoveSupplier: {
    id: "creation.errors.remove.supplier",
    defaultMessage: "An error has occurred, the supplier couldn't be removed",
  },
});
