import { useMemo } from "react";
import { Form } from "antd";
import { Radio } from "@trace-one/design-system";
import { Tags } from "@trace-one/react-components";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { APPLICATION_TYPE_IDS, MAX_CHAR_LENGTH, ROUTES } from "utils/constants";
import { getVersionName } from "utils/specifications";
import {
  getMaterialApiType,
  isLibraryObjectEditPage,
  isMaterialCompositeType,
} from "utils/library";
import { selectLanguageCode } from "store/user/selectors";
import StepTitle from "components/Library/components/StepTitle";
import LibraryItemName from "components/Library/components/LibraryItemName";
import { getOnTagListChange } from "components/Library/components/utils";
import { useMaterialPermissions } from "components/Library/components/MaterialForm/hooks";
import { useMaterialFormInfo } from "../../hooks";
import CancelDeleteMaterialModal from "./components/CancelDeleteMaterialModal";
import InternalCode from "./components/InternalCode";
import MaterialType from "./components/MaterialType";
import Recipe from "./components/Recipe";
import { useMaterialInfoActions } from "./hooks";
import { MaterialInfoProps } from "./types";
import libraryMessages from "messages/library";
import { messages } from "./messages";
import styles from "../../styles.module.less";

const MaterialInfo: React.FC<MaterialInfoProps> = ({
  materialInformation,
  onFieldsChange,
  versionNumber,
  page,
}) => {
  const { formatMessage } = useIntl();
  const languageCode = useSelector(selectLanguageCode);

  const isNewVersionPage = useRouteMatch(ROUTES.LIBRARY.CREATE_NEW_VERSION);

  const isNewVersion = isNewVersionPage?.isExact;

  const [form] = Form.useForm();
  const { disableActions, canUpdateInformation } = useMaterialPermissions();
  const {
    onSaveName,
    onChangeStatus,
    onSaveTags,
    onChangeType,
    onChangeRecipe,
    onChangeInternalCode,
  } = useMaterialInfoActions();

  const { statusValues, typeValues } = useMaterialFormInfo();

  const versionName = useMemo(
    () => (versionNumber ? getVersionName(versionNumber + 1) : ""),
    [versionNumber]
  );

  const isMaterialCreationDisabled = useMemo(() => {
    if (disableActions) return true;

    if (isLibraryObjectEditPage(page)) {
      return true;
    }

    return false;
  }, []);

  return (
    <>
      <Form
        name="rawMaterialForm"
        layout="horizontal"
        validateMessages={{
          required: formatMessage(libraryMessages.formRequired),
          whitespace: formatMessage(libraryMessages.formRequired),
          string: {
            max: formatMessage(libraryMessages.formMaxCharLength, {
              max: MAX_CHAR_LENGTH,
            }),
          },
        }}
        initialValues={materialInformation}
        onFieldsChange={onFieldsChange}
        form={form}
        labelAlign="left"
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          xs: 24,
          md: 22,
        }}
      >
        <StepTitle
          title={formatMessage(messages.materialInformationTitle)}
          step={1}
        />
        <LibraryItemName
          disableActions={!canUpdateInformation || isNewVersion}
          onChange={onSaveName}
          versionName={versionName}
          dataTestId="material-form-input-name"
          defaultValue={materialInformation.name}
          libraryItemId={materialInformation.id}
          form={form}
        />
        <InternalCode
          disableActions={!canUpdateInformation || isNewVersion}
          onChange={onChangeInternalCode}
          defaultValue={materialInformation.internalCode}
        />
        <Form.Item
          name="tagIds"
          label={formatMessage(libraryMessages.tagsLabel)}
        >
          {disableActions ? (
            <Tags.List
              languageCode={languageCode}
              tagIds={materialInformation.tagIds}
            />
          ) : (
            <div className={styles.rawMaterialFormTagsWrapper}>
              <Tags.Select
                languageCode={languageCode}
                applicationTypeId={APPLICATION_TYPE_IDS.SPEC}
                tagIds={materialInformation.tagIds}
                data-test-id="material-form-tagIds"
                onTagListChange={getOnTagListChange({
                  setFieldsValue: form.setFieldsValue,
                  onFieldsChange: onSaveTags,
                })}
              />
            </div>
          )}
        </Form.Item>
        <Form.Item
          name="active"
          label={formatMessage(libraryMessages["statusField"]["label"])}
          rules={[{ required: true }]}
        >
          <Radio.Group
            options={statusValues}
            data-test-id="material-form-status"
            display="horizontal"
            onChange={onChangeStatus}
            disabled={!canUpdateInformation}
          />
        </Form.Item>
        <MaterialType
          options={typeValues}
          materialType={materialInformation?.materialType}
          disabled={isMaterialCreationDisabled}
          onChangeType={onChangeType}
        />
        {isMaterialCompositeType(materialInformation.materialType) && (
          <Recipe
            recipeType={materialInformation.recipeType}
            onChangeRecipe={onChangeRecipe}
            isRecipeTypeDisabled={isMaterialCreationDisabled}
          />
        )}
      </Form>
      {!isLibraryObjectEditPage(page) && (
        <CancelDeleteMaterialModal
          materialId={materialInformation.id}
          materialType={getMaterialApiType({
            materialType: materialInformation.materialType,
            recipeType: materialInformation.recipeType,
          })}
          validateFields={form.validateFields}
        />
      )}
    </>
  );
};

export default MaterialInfo;
