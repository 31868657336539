import { defineMessage } from "react-intl";

export const messages = defineMessage({
  specificationAlreadyCreatedTitle: {
    id: "specificationCreation.specificationAlreadyCreated.title",
    defaultMessage: " Specification already created",
  },
  specificationAlreadyCreatedContent: {
    id: "specificationCreation.specificationAlreadyCreated.content",
    defaultMessage: "Another user finalized the creation of this Specification",
  },
});
