import { State } from "./types";
import { prepareRequirementInformationFromApiData } from "dtos/requirement";

const reducers = {
  setFormData: (state: State, action) => {
    const requirementInformation = prepareRequirementInformationFromApiData(
      action.payload
    );
    state.requirementInformation = requirementInformation;
    state.type = action.payload.requirementType;
  },
};

export default reducers;
