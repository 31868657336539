/* istanbul ignore file */

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ReferenceListItemData } from "models";
import { fetchReferenceListItems } from "apis/RLMD";
import { selectLanguageCode } from "store/user/selectors";

const useSpecificationDetailsRefList = ({
  listId,
  displayActions,
}: {
  listId: string;
  displayActions: boolean;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [referenceList, setReferenceList] = useState<ReferenceListItemData[]>(
    []
  );

  const languageCode = useSelector(selectLanguageCode);

  const getRefList = async () => {
    try {
      setIsLoading(true);

      const { data } = await fetchReferenceListItems({
        id: listId,
        languageCode,
      });

      setReferenceList(data.referenceListItems);
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (displayActions) {
      getRefList();
    }
  }, [displayActions, listId]);

  return { isLoading, referenceList };
};

export default useSpecificationDetailsRefList;
