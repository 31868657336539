import { useContext, useEffect, useMemo, useRef, useState } from "react";
import classnames from "classnames";
import { Select } from "@trace-one/design-system";
import ConditionalWrapper from "components/ConditionalWrapper";
import RequestModificationTooltipIcon from "components/RequestModificationTooltipIcon";
import { useWidgetActions } from "../../hooks";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import withRequestModificationPopover from "hocs/withRequestModificationPopover";
import { ReferenceListFieldTemplateContext } from "../../templates/ArrayFieldTemplate/ReferenceListArrayFieldTemplate/contexts";
import { setRequestModification } from "store/specificationDetails/specificationDetailsSlice";
import { useAppDispatch } from "store";
import {
  canTargetField,
  doesFieldHaveModificationRequest,
  getDraftRequestFromAllRequests,
  getSelectClosestClassname,
  getSelectPopoverStyle,
} from "utils/modificationRequest";
import { isListEmpty, isObjectEmpty } from "utils/general";
import { ConditionalRefListWidgetProps } from "./types";
import widgetStyle from "../styles.module.less";

const ConditionalRefListWidget: React.FC<ConditionalRefListWidgetProps> = ({
  placeholder,
  readonly = false,
  schema,
  value,
  onChange,
  showSearch = false,
  formContext,
  uiSchema,
  id,
  rawErrors,
  onOpenModal,
  setModalStyle,
  setRequestPopoverOptions,
  setSelectedField,
}) => {
  const [textValue, setTextValue] = useState("");
  const { referenceOptions, updateExcludedRefListIds } = useContext(
    ReferenceListFieldTemplateContext
  );
  const conditionalRefListWidgetWrapperRef = useRef(null);

  const dispatch = useAppDispatch();

  const { propertyId } = schema;

  const {
    displayActions,
    emptyValue,
    isWidgetEditable = () => {},
    templateBlockId,
    sectionId,
    shouldCallApi,
    refListDictionary,
    isTargetMode,
    modificationRequests,
    arraySchemaPropertyId,
    ownerCompanyId,
    chapterData,
  } = formContext;

  const isReadOnly = !isWidgetEditable(propertyId) || readonly;

  const { getTranslationForKey } = useFormSchemaTranslation();
  const { isWidgetChanged } = useWidgetActions({ templateBlockId, sectionId });

  const isSearchable = uiSchema?.["ui:searchable"] || showSearch;

  const hasDraftModificationRequest = useMemo(() => {
    return getDraftRequestFromAllRequests({
      modificationRequests,
      propertyId: arraySchemaPropertyId,
      listItemKey: value,
    });
  }, [modificationRequests, propertyId, value]);

  const onSelect = (value: string) => {
    updateExcludedRefListIds(value);

    onChange(value);
  };

  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    const wrapper = event.target;
    const wrapperBoundingClientRect = conditionalRefListWidgetWrapperRef.current.getBoundingClientRect();
    const targetBoundingClientRect = (event.target as HTMLElement).getBoundingClientRect();

    if (wrapper) {
      (wrapper as HTMLElement)
        .closest(
          getSelectClosestClassname({
            wrapperBoundingClientRectWidth: wrapperBoundingClientRect?.width,
            targetBoundingClientRectWidth: targetBoundingClientRect?.width,
          })
        )
        .classList.add("target-mode__border");
    }

    setModalStyle(
      getSelectPopoverStyle({
        wrapperBoundingClientRect,
        targetBoundingClientRect,
      })
    );

    setSelectedField({
      propertyId: arraySchemaPropertyId,
      listItemKey: value,
    });

    onOpenModal?.();
    dispatch(setRequestModification({ isEditorOpen: true }));
  };

  useEffect(() => {
    if (shouldCallApi === false && !isObjectEmpty(refListDictionary)) {
      if (Array.isArray(value)) {
        setTextValue(
          value
            .map(currentValue => {
              return refListDictionary[currentValue?.value || currentValue];
            })
            .join(", ")
        );
      } else {
        if (refListDictionary[value as string]) {
          setTextValue(refListDictionary[value as string]);
        }
      }
    }
  }, [refListDictionary, shouldCallApi]);

  const renderPopoverIcon = (propertyId: string) => {
    const request = getDraftRequestFromAllRequests({
      modificationRequests,
      propertyId: arraySchemaPropertyId,
      listItemKey: value,
    });

    if (request) {
      return (
        <RequestModificationTooltipIcon
          key={`${propertyId}`}
          onOpenModal={onOpenModal}
          setModalStyle={setModalStyle}
          tableRef={conditionalRefListWidgetWrapperRef}
          setRequestPopoverOptions={setRequestPopoverOptions}
          request={request}
        />
      );
    }
  };

  if (!displayActions) {
    if (textValue) {
      return (
        <span data-field-id={`${value}-${arraySchemaPropertyId}`}>
          {textValue}
        </span>
      );
    }

    if (emptyValue) {
      return (
        <span data-field-id={`${value}-${arraySchemaPropertyId}`}>
          {emptyValue}
        </span>
      );
    }

    return <></>;
  }

  return (
    <ConditionalWrapper
      condition={isTargetMode}
      wrapper={children => (
        <div ref={conditionalRefListWidgetWrapperRef}>{children}</div>
      )}
    >
      <>
        <Select
          mode={uiSchema?.["ui:multipleMode"] && "multiple"}
          className={classnames({
            "target-mode": canTargetField({
              isTargetMode,
              specOwnerCompanyId: ownerCompanyId,
              chapterOwnerCompanyId: chapterData?.ownerCompanyId,
            }),
            "target-mode__background": hasDraftModificationRequest,
            "target-mode__border-color": hasDraftModificationRequest,
            [widgetStyle.inRevisionChange]: isWidgetChanged(propertyId, id),
            [widgetStyle["target-mode__input-pointer-event"]]:
              isTargetMode && !value && !uiSchema?.["ui:multipleMode"],
          })}
          showSearch={isSearchable}
          disabled={isReadOnly}
          name="reflistSelect"
          data-testid="reflist"
          placeholder={getTranslationForKey(
            placeholder || "refListPlaceholder"
          )}
          defaultValue={value}
          value={value}
          onSelect={onSelect}
          {...(canTargetField({
            isTargetMode,
            specOwnerCompanyId: ownerCompanyId,
            chapterOwnerCompanyId: chapterData?.ownerCompanyId,
          }) &&
            !doesFieldHaveModificationRequest({
              modificationRequests,
              propertyId: arraySchemaPropertyId,
              listItemKey: value,
            }) && {
              onClick,
            })}
          options={referenceOptions.map(({ id, text, disabled }) => ({
            name: text,
            value: id,
            disabled,
          }))}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          error={!isListEmpty(rawErrors)}
        />

        {isTargetMode ? renderPopoverIcon(propertyId) : null}
      </>
    </ConditionalWrapper>
  );
};

export default withRequestModificationPopover(ConditionalRefListWidget);
