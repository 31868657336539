import { useEffect, useState } from "react";
import { getIsChapterVersionCreationAllowed } from "apis/SPEC";
import { GeneralInfoData } from "models";
import { CHAPTER_TYPES_API } from "utils/constants";

const useChapterDraftValidation = ({
  chapterId,
  chapterType,
}: {
  chapterId: GeneralInfoData["id"];
  chapterType: GeneralInfoData["type"];
}) => {
  const [allowVersionCreation, setAllowVersionCreation] = useState(false);

  useEffect(() => {
    if (chapterId && chapterType) {
      getIsVersionCreationAllowed();
    }
  }, [chapterId, chapterType]);

  const getIsVersionCreationAllowed = async () => {
    try {
      const { data } = await getIsChapterVersionCreationAllowed({
        chapterType: CHAPTER_TYPES_API[chapterType],
        chapterId,
      });

      const { response: isAllowed } = data;

      setAllowVersionCreation(isAllowed);
    } catch (_) {
      setAllowVersionCreation(false);
    }
  };

  return {
    allowVersionCreation,
  };
};

export default useChapterDraftValidation;
