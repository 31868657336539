import { Row, Col } from "antd";
import { Heading } from "@trace-one/design-system";
import { CodeAndDateItemProps } from "./types";
import styles from "../../styles.module.less";

const CodeAndDateItem: React.FC<CodeAndDateItemProps> = ({
  item,
  fieldUnit,
  fieldTitle,
}) => {
  if (!item.unit && !item.value) {
    return null;
  }
  return (
    <Row gutter={[0, 30]} className={styles.itemRow}>
      <Col xs={8}>
        <Heading size="xxs">{fieldTitle}</Heading>
      </Col>
      <Col>
        <Heading size="xxs">
          {item.value ?? ""} {fieldUnit ?? ""}
        </Heading>
      </Col>
    </Row>
  );
};

export default CodeAndDateItem;
