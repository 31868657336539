import { useMemo, useRef } from "react";
import classnames from "classnames";
import { Input, InputNumber } from "@trace-one/design-system";
import RequestModificationTooltipIcon from "components/RequestModificationTooltipIcon";
import { REQUEST_MODIFICATION_MODAL_WIDTH } from "components/RequestModificationPopover";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import withRequestModificationPopover from "hocs/withRequestModificationPopover";
import { useTextActions, useWidgetActions } from "../../hooks";
import { useAppDispatch } from "store";
import { setRequestModification } from "store/specificationDetails/specificationDetailsSlice";
import { MAX_INPUT_NUMBER_LENGTH } from "utils/constants";
import { isListEmpty } from "utils/general";
import { getInputNumberProps, isWidgetDisabled } from "../utils";
import {
  canTargetField,
  doesFieldHaveModificationRequest,
  getDraftRequestFromAllRequests,
} from "utils/modificationRequest";
import { TextWidgetProps } from "./types";
import widgetStyle from "../styles.module.less";

const TextWidget: React.FC<TextWidgetProps> = ({
  className,
  disabled,
  formContext,
  id,
  onChange,
  onFocus = () => {},
  options = {},
  placeholder,
  readonly,
  schema,
  value,
  rawErrors,
  setModalStyle,
  onOpenModal,
  setSelectedField,
  setRequestPopoverOptions,
}) => {
  const {
    displayActions,
    emptyValue,
    readonlyAsDisabled = true,
    isWidgetEditable,
    templateBlockId,
    sectionId,
    isTypingInProgress,
    onUpdateIsTypingInProgress,
    isTargetMode,
    ownerCompanyId,
    chapterData,
    modificationRequests,
  } = formContext;

  const inputRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();

  const { propertyId, maxLength = MAX_INPUT_NUMBER_LENGTH, type } = schema;

  const { min, precision, step } = getInputNumberProps(schema);

  const { getTranslationForKey } = useFormSchemaTranslation();
  const { isWidgetChanged } = useWidgetActions({ templateBlockId, sectionId });
  const {
    inputValue,
    handleBlur,
    handleFocus,
    handleInputNumberBlur,
    handleInputNumberChange,
    handleTextChange,
  } = useTextActions({
    emptyValue: options.emptyValue,
    id,
    onChange,
    onFocus,
    min,
    value,
    type,
    precision,
    isTypingInProgress,
    onUpdateIsTypingInProgress,
  });

  const hasOnClick = useMemo(
    () =>
      canTargetField({
        isTargetMode,
        specOwnerCompanyId: ownerCompanyId,
        chapterOwnerCompanyId: chapterData?.ownerCompanyId,
      }),
    [ownerCompanyId, chapterData?.ownerCompanyId, isTargetMode]
  );

  const isNumberInput = schema.type === "integer" || schema.type === "number";

  const hasDraftModificationRequest = useMemo(() => {
    return getDraftRequestFromAllRequests({
      modificationRequests,
      propertyId,
    });
  }, [modificationRequests, propertyId]);

  const isDisabled = useMemo(
    () =>
      isWidgetDisabled({
        isWidgetEditable: isWidgetEditable(propertyId),
        disabled,
        readonlyAsDisabled,
        readonly,
      }),
    [disabled, readonlyAsDisabled, readonly, propertyId, isTargetMode]
  );

  const renderPopoverIcon = () => {
    const request = getDraftRequestFromAllRequests({
      modificationRequests,
      propertyId,
    });

    if (request) {
      return (
        <RequestModificationTooltipIcon
          key={`${propertyId}`}
          onOpenModal={onOpenModal}
          setModalStyle={setModalStyle}
          tableRef={inputRef}
          setRequestPopoverOptions={setRequestPopoverOptions}
          request={request}
        />
      );
    }
  };

  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    if (
      doesFieldHaveModificationRequest({ modificationRequests, propertyId })
    ) {
      return;
    }

    const { left } = inputRef.current.getBoundingClientRect();

    const isOverFlowing =
      event.clientX + REQUEST_MODIFICATION_MODAL_WIDTH > window.innerWidth;

    setModalStyle({
      left: isOverFlowing ? "unset" : event.clientX - left,
      right: isOverFlowing ? 0 : "unset",
      top: "50%",
    });

    (event.target as HTMLElement)
      .closest(".design-system-form-item")
      .querySelector(
        `${
          isNumberInput ? ".design-system-input-number" : ".design-system-input"
        }`
      )
      .classList.add("target-mode__border");

    onOpenModal?.();

    dispatch(setRequestModification({ isEditorOpen: true }));

    setSelectedField({ propertyId });
  };

  const classNames = classnames(className, {
    [widgetStyle.inRevisionChange]: isWidgetChanged(propertyId, id),
    [widgetStyle["target-mode__input-pointer-event"]]: canTargetField({
      isTargetMode,
      specOwnerCompanyId: ownerCompanyId,
      chapterOwnerCompanyId: chapterData?.ownerCompanyId,
    }),
    "target-mode": canTargetField({
      isTargetMode,
      specOwnerCompanyId: ownerCompanyId,
      chapterOwnerCompanyId: chapterData?.ownerCompanyId,
    }),
    "target-mode__background": isTargetMode && hasDraftModificationRequest,
    "target-mode__border-color": isTargetMode && hasDraftModificationRequest,
  });

  if (!displayActions) {
    return <span data-field-id={propertyId}>{inputValue || emptyValue}</span>;
  }

  switch (schema.type) {
    case "integer":
    case "number":
      return (
        <InputNumber
          className={classNames}
          id={id}
          name={id}
          disabled={isDisabled}
          onBlur={!readonly ? handleInputNumberBlur : undefined}
          onChange={!readonly ? handleInputNumberChange : undefined} //Note: the check for max value was removed
          onFocus={!readonly ? handleFocus : undefined}
          placeholder={getTranslationForKey(placeholder)}
          parser={value => value.substring(0, MAX_INPUT_NUMBER_LENGTH)}
          value={inputValue}
          step={step}
          type="number"
          precision={precision}
          maxLength={maxLength}
          error={!isListEmpty(rawErrors)}
          disableFieldEnableWrapperClick={isTargetMode}
          wrapperRef={inputRef}
          extraAbsoluteContent={isTargetMode && renderPopoverIcon()}
          wrapperClassName={widgetStyle["input-wrapper"]}
          onClick={hasOnClick ? onClick : null}
        />
      );
    default:
      return (
        <Input
          className={classNames}
          id={id}
          name={id}
          disabled={isDisabled}
          onBlur={!readonly ? handleBlur : undefined}
          onChange={!readonly ? handleTextChange : undefined}
          onFocus={!readonly ? handleFocus : undefined}
          placeholder={getTranslationForKey(placeholder)}
          type={options.inputType || "text"}
          value={inputValue}
          maxLength={maxLength}
          allowClear={false}
          error={!isListEmpty(rawErrors)}
          disableFieldEnableWrapperClick={isTargetMode}
          wrapperRef={inputRef}
          extraAbsoluteContent={isTargetMode && renderPopoverIcon()}
          onClick={hasOnClick ? onClick : null}
        />
      );
  }
};

export default withRequestModificationPopover(TextWidget);
