import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  createSpecificationCollaboration,
  createSpecificationContact,
  deleteSpecificationCollaboration,
  deleteSpecificationContact,
  setSignatoryForSpecificationContact,
  updateSpecificationChapterBlock,
  createSpecificationSharedDocuments,
  deleteSpecificationSharedDocument,
  updateSpecificationNutritionDeclaration,
  deleteNutritionServing,
  updateSpecificationNutritionDeclarationManualServing,
  createNutritionServing,
  updateNutritionServing,
  updateIngredientList,
  updateCharacteristicIngredients,
  updateClaims,
  updateNutriscore,
} from "apis/SPEC";
import { useAppDispatch } from "store";
import { setIsSpecificationUpdating } from "store/specificationDetails/specificationDetailsSlice";
import { CHAPTER_SECTION_CODE_API, CHAPTER_TYPES_API } from "utils/constants";
import {
  GetSaveTemplateBlockBySectionSignature,
  UpdateSitesActionSignature,
} from "./types";
import { EnhancedExtraActions } from "components/SpecificationSection/types";
import { ParamTypes } from "pages/Specification/components/Details/types";
import { AddCollaborationBody, NutriscoreFormData } from "models";

const useSectionActions = ({
  chapterType,
  specificationId,
  refetchChapter,
  resetSectionValidationErrors,
  sectionId,
}: {
  specificationId: string;
  sectionId: string;
  chapterType: string;
  refetchChapter: Function;
  resetSectionValidationErrors: Function;
}) => {
  const [isSavingInProgress, setIsSavingInProgress] = useState(false);

  const dispatch = useAppDispatch();

  const {
    sectionCode: currentChapterApiType = chapterType,
  } = useParams<ParamTypes>();

  const currentChapterApiTypeRef = useRef(currentChapterApiType);

  useEffect(() => {
    currentChapterApiTypeRef.current = currentChapterApiType;
  }, [currentChapterApiType]);

  const refetchCurrentChapter = async (sectionCode: string = "") => {
    resetSectionValidationErrors(sectionId);

    if (
      currentChapterApiTypeRef.current === CHAPTER_TYPES_API[chapterType] ||
      (!chapterType && CHAPTER_SECTION_CODE_API[sectionCode])
    ) {
      await refetchChapter();
    }
  };

  const getSaveTemplateBlockBySection: GetSaveTemplateBlockBySectionSignature = sectionCode => blockId => async json => {
    try {
      setIsSavingInProgress(true);
      dispatch(setIsSpecificationUpdating(true));

      await updateSpecificationChapterBlock({
        specificationId,
        chapterType:
          CHAPTER_SECTION_CODE_API[sectionCode] ||
          CHAPTER_TYPES_API[chapterType],
        blockId,
        json,
      });

      await refetchCurrentChapter(sectionCode);
    } catch (e) {
    } finally {
      setIsSavingInProgress(false);
      dispatch(setIsSpecificationUpdating(false));
    }
  };

  const addContactAction: EnhancedExtraActions["addContactAction"] = async ({
    userId,
    signatory,
    contactType,
  }) => {
    try {
      setIsSavingInProgress(true);

      await createSpecificationContact({
        specificationId,
        userId,
        signatory,
        contactType,
      });

      await refetchCurrentChapter();
    } catch (e) {
    } finally {
      setIsSavingInProgress(false);
    }
  };

  const removeContactAction: EnhancedExtraActions["removeContactAction"] = async ({
    userId,
  }) => {
    try {
      setIsSavingInProgress(true);

      await deleteSpecificationContact({
        specificationId,
        userId,
      });

      await refetchCurrentChapter();
    } catch (e) {
    } finally {
      setIsSavingInProgress(false);
    }
  };

  const setSignatoryContactAction: EnhancedExtraActions["setSignatoryContactAction"] = async ({
    userId,
    signatory,
  }) => {
    try {
      setIsSavingInProgress(true);

      await setSignatoryForSpecificationContact({
        userId,
        signatory,
        specificationId,
      });

      await refetchCurrentChapter();
    } catch (e) {
    } finally {
      setIsSavingInProgress(false);
    }
  };

  const setSpecificationCollaborationAction: EnhancedExtraActions["setSpecificationCollaborationAction"] = async (
    payload: AddCollaborationBody[]
  ) => {
    try {
      setIsSavingInProgress(true);

      await createSpecificationCollaboration({
        specificationId,
        payload,
      });

      await refetchCurrentChapter();
    } catch (e) {
    } finally {
      setIsSavingInProgress(false);
    }
  };

  const removeSpecificationCollaborationAction: EnhancedExtraActions["removeSpecificationCollaborationAction"] = async collaborationId => {
    try {
      setIsSavingInProgress(true);

      await deleteSpecificationCollaboration({
        specificationId,
        collaborationId,
      });

      await refetchCurrentChapter();
    } catch (e) {
    } finally {
      setIsSavingInProgress(false);
    }
  };

  const uploadFilesAction: EnhancedExtraActions["uploadFilesAction"] = async files => {
    try {
      setIsSavingInProgress(true);

      await createSpecificationSharedDocuments({
        specificationId,
        fileIds: files.map(({ id }) => id),
      });

      await refetchChapter();
    } catch (e) {
    } finally {
      setIsSavingInProgress(false);
    }
  };

  const removeFileAction: EnhancedExtraActions["removeFileAction"] = async file => {
    try {
      setIsSavingInProgress(true);

      await deleteSpecificationSharedDocument({
        specificationId,
        fileId: file.id,
      });

      await refetchChapter();
    } catch (e) {
    } finally {
      setIsSavingInProgress(false);
    }
  };

  const updateSitesAction: UpdateSitesActionSignature = (
    blockId: string
  ) => async ({ currentSiteIds }) => {
    try {
      setIsSavingInProgress(true);
      resetSectionValidationErrors(sectionId);

      await updateSpecificationChapterBlock({
        specificationId,
        chapterType: CHAPTER_TYPES_API[chapterType],
        blockId,
        json: JSON.stringify({ siteIds: currentSiteIds }),
      });

      await refetchChapter();
    } catch (e) {
    } finally {
      setIsSavingInProgress(false);
    }
  };

  const updateSpecificationNutritionDeclarationManualServingAction: EnhancedExtraActions["updateSpecificationNutritionDeclarationManualServingAction"] = async ({
    nutrientId,
    servingId,
    data,
  }) => {
    try {
      setIsSavingInProgress(true);

      await updateSpecificationNutritionDeclarationManualServing({
        specificationId,
        nutrientId,
        servingId,
        data,
      });

      await refetchCurrentChapter();
    } catch (e) {
    } finally {
      setIsSavingInProgress(false);
    }
  };

  const updateSpecificationNutritionDeclarationAction: EnhancedExtraActions["updateSpecificationNutritionDeclarationAction"] = async ({
    nutrientId,
    data,
  }) => {
    try {
      setIsSavingInProgress(true);

      await updateSpecificationNutritionDeclaration({
        specificationId,
        nutrientId,
        data,
      });

      await refetchCurrentChapter();
    } catch (e) {
    } finally {
      setIsSavingInProgress(false);
    }
  };

  const createNutritionServingAction: EnhancedExtraActions["createNutritionServingAction"] = async ({
    name,
    value,
    unitId,
    automatic,
    setShouldRefetchServings,
  }) => {
    try {
      setIsSavingInProgress(true);

      await createNutritionServing({
        specificationId,
        name,
        value,
        unitId,
        automatic,
      });

      setShouldRefetchServings(true);

      await refetchChapter();
    } catch (e) {
    } finally {
      setIsSavingInProgress(false);
      setShouldRefetchServings(false);
    }
  };

  const updateNutritionServingAction: EnhancedExtraActions["updateNutritionServingAction"] = async ({
    name,
    value,
    unitId,
    automatic,
    servingId,
    setShouldRefetchServings,
  }) => {
    try {
      setIsSavingInProgress(true);

      await updateNutritionServing({
        specificationId,
        name,
        value,
        unitId,
        automatic,
        servingId,
      });

      setShouldRefetchServings(true);

      await refetchChapter();
    } catch (e) {
    } finally {
      setIsSavingInProgress(false);
      setShouldRefetchServings(false);
    }
  };

  const onDeleteNutritionServing: EnhancedExtraActions["onDeleteNutritionServing"] = async ({
    servingId,
    setShouldRefetchServings,
    setShowRemoveServingModal,
  }) => {
    try {
      setIsSavingInProgress(true);

      await deleteNutritionServing({
        specificationId,
        servingId,
      });

      setShowRemoveServingModal(false);
      setShouldRefetchServings(true);
      await refetchChapter();
    } catch (e) {
    } finally {
      setIsSavingInProgress(false);
      setShouldRefetchServings(false);
    }
  };

  const onUpdateIngredientList: EnhancedExtraActions["onUpdateIngredientList"] = async ({
    modifiedIngredientList,
    displayOnPack,
  }): Promise<void> => {
    try {
      setIsSavingInProgress(true);

      const payload = {
        displayOnPack,
        modifiedIngredientList,
      };

      await updateIngredientList({
        specificationId,
        payload,
      });

      await refetchChapter();
    } catch (e) {
    } finally {
      setIsSavingInProgress(false);
    }
  };

  const onUpdateCharacteristicIngredients: EnhancedExtraActions["onUpdateCharacteristicIngredients"] = async ({
    ingredientIds,
  }): Promise<void> => {
    try {
      setIsSavingInProgress(true);

      await updateCharacteristicIngredients({
        specificationId,
        ingredientIds,
      });

      await refetchChapter();
    } catch (e) {
    } finally {
      setIsSavingInProgress(false);
    }
  };

  const onUpdateClaims: EnhancedExtraActions["onUpdateClaims"] = async ({
    claims,
  }): Promise<void> => {
    try {
      setIsSavingInProgress(true);

      await updateClaims({
        specificationId,
        payload: {
          claims,
        },
      });

      await refetchCurrentChapter();
    } catch (e) {
    } finally {
      setIsSavingInProgress(false);
    }
  };

  const onUpdateNutriscore: EnhancedExtraActions["onUpdateNutriscore"] = async (
    payload: NutriscoreFormData
  ): Promise<void> => {
    try {
      setIsSavingInProgress(true);

      await updateNutriscore({
        specificationId,
        payload,
      });

      await refetchCurrentChapter();
    } catch (e) {
    } finally {
      setIsSavingInProgress(false);
    }
  };

  return {
    isSavingInProgress,
    getSaveTemplateBlockBySection,
    addContactAction,
    removeContactAction,
    setSignatoryContactAction,
    setSpecificationCollaborationAction,
    removeSpecificationCollaborationAction,
    uploadFilesAction,
    removeFileAction,
    updateSitesAction,
    updateSpecificationNutritionDeclarationManualServingAction,
    updateSpecificationNutritionDeclarationAction,
    createNutritionServingAction,
    updateNutritionServingAction,
    onDeleteNutritionServing,
    onUpdateIngredientList,
    onUpdateCharacteristicIngredients,
    onUpdateClaims,
    onUpdateNutriscore,
  };
};

export default useSectionActions;
