import { useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { selectLanguageCode } from "store/user/selectors";
import { SPECIFICATION_STATE } from "utils/constants";
import { fetchSpecificationPdf } from "apis/SPEC";
import { downloadFile as downloadFileApi } from "apis/DOCUMENT";
import { UseSpecificationPdfActionsProps } from "./types";
import useSpecificationFiles from "hooks/useSpecificationFiles";
import { pdfMessages } from "./messages";
import { SpecificationState } from "types/specification";

const useSpecificationPdfActions = ({
  specificationId,
  state,
  tradeItemName,
  shouldFetchSpecificationFilesInitially,
  signedPdfId,
}: UseSpecificationPdfActionsProps) => {
  const { formatMessage } = useIntl();
  const languageCode = useSelector(selectLanguageCode);

  const [isLoading, setIsLoading] = useState(false);

  const { fetchSpecificationFileData } = useSpecificationFiles({
    specificationId,
    shouldFetchSpecificationFilesInitially: shouldFetchSpecificationFilesInitially,
  });

  const statesRequiringDMS: SpecificationState[] = [
    SPECIFICATION_STATE.signedByRetailer,
    SPECIFICATION_STATE.signedBySupplier,
    SPECIFICATION_STATE.lockedForSupplierSignature,
    SPECIFICATION_STATE.lockedForRetailerSignature,
  ];

  const openPdfInSeparateTab = async () => {
    try {
      setIsLoading(true);
      let data;
      if (!statesRequiringDMS.includes(state)) {
        const respond = await fetchSpecificationPdf({
          id: specificationId,
          languageCode,
        });
        data = respond.data;
      } else if (signedPdfId) {
        const respond = await downloadFileApi({
          id: signedPdfId,
        });
        data = respond.data;
      }
      if (data) {
        const file = new Blob([data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);

        window.open(fileURL);
      }
    } catch (_) {
    } finally {
      setIsLoading(false);
    }
  };

  const hasRequiredValuesForActions = specificationId && state && tradeItemName;

  const actions = [
    {
      label: formatMessage(pdfMessages.viewPdfOnly),
      onClick: openPdfInSeparateTab,
    },
    {
      label: formatMessage(pdfMessages.viewPdfWithAttachments),
      onClick: () => {},
      disabled: true,
    },
  ];

  return {
    actions: hasRequiredValuesForActions ? actions : [],
    isLoading,
    openPdfInSeparateTab,
    fetchSpecificationFileData,
  };
};

export default useSpecificationPdfActions;
