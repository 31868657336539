/* istanbul ignore file */

import { useHeapAnalytic, useIntercom, useLegalMonsterRef } from "./hooks";

const useLegalMonster = () => {
  const legalMonsterRef = useLegalMonsterRef();

  useHeapAnalytic(legalMonsterRef);
  useIntercom(legalMonsterRef);
};

export default useLegalMonster;
