import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fetchSpecificationHistoryActions } from "apis/SPEC";
import { selectLanguageCode } from "store/user/selectors";
import { getDictionary } from "utils/general";
import { SpecificationHistoryActionData } from "models";
import { Dictionary } from "types/general";

const useActions = () => {
  const languageCode = useSelector(selectLanguageCode);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [actions, setActions] = useState<SpecificationHistoryActionData[]>([]);
  const [actionsDictionary, setActionsDictionary] = useState<Dictionary>({});

  const getActions = async () => {
    try {
      setIsLoading(true);

      const { data } = await fetchSpecificationHistoryActions({ languageCode });

      setActions(data);

      setActionsDictionary(
        getDictionary({
          data: data,
          key: "value",
          value: "translation",
        })
      );
    } catch (e) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getActions();
  }, []);

  return {
    isLoading,
    hasError,
    actions,
    actionsDictionary,
  };
};

export default useActions;
