import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { ParamTypes } from "pages/Library/components/types";
import {
  isLibraryObjectClaimType,
  isLibraryObjectMaterialType,
  isLibraryObjectRequirementType,
  isLibraryObjectTemplatesType,
} from "utils/library";
import { getComponentDisplayName } from "utils/general";
import { LIBRARY_OBJECT_TYPES } from "utils/constants";
import ConfirmEditModal from "components/Library/components/ConfirmEditModal";
import libraryMessages from "messages/library";

const withConfirmEditModal = <P extends object>(
  WrappedComponent: React.ComponentType<P>
) => props => {
  WrappedComponent.displayName = getComponentDisplayName(
    withConfirmEditModal,
    WrappedComponent
  );
  //currently we do not have ConfirmEditModal implementation for PS
  if (props.libraryItemDetails.packagingSystemId) {
    return <WrappedComponent {...(props as P)} />;
  }

  const { type } = useParams<ParamTypes>();

  // we will not display confirm edit modal for template and claims
  if (isLibraryObjectTemplatesType(type) || isLibraryObjectClaimType(type)) {
    return <WrappedComponent {...(props as P)} />;
  }

  const confirmEditModalProps = useMemo(() => {
    if (isLibraryObjectMaterialType(props.libraryItemDetails.materialType)) {
      return {
        libraryItemType: LIBRARY_OBJECT_TYPES.MATERIALS,
        libraryItemState: props.libraryItemDetails.state,
        modalTitle: libraryMessages.materialEditionModalTitle,
        modalBody: libraryMessages.materialEditionModalBody,
      };
    } else if (isLibraryObjectRequirementType(type)) {
      return {
        libraryItemType: LIBRARY_OBJECT_TYPES.REQUIREMENTS,
        libraryItemState: props.libraryItemDetails.state,
        modalTitle: libraryMessages.requirementEditionModalTitle,
        modalBody: libraryMessages.requirementEditionModalBody,
      };
    }
    //if is a chapter
    return {
      libraryItemType: LIBRARY_OBJECT_TYPES.CHAPTERS,
      libraryItemState: props.libraryItemDetails.generalInfo.state,
      modalTitle: libraryMessages.chapterEditionModalTitle,
      modalBody: libraryMessages.chapterEditionModalBody,
    };
  }, [
    type,
    props.libraryItemDetails?.materialType,
    props.libraryItemDetails?.state,
    props.libraryItemDetails?.generalInfo?.state,
  ]);

  return (
    <>
      <ConfirmEditModal
        libraryItemState={confirmEditModalProps.libraryItemState}
        libraryItemType={confirmEditModalProps.libraryItemType}
        title={<FormattedMessage {...confirmEditModalProps.modalTitle} />}
      >
        <FormattedMessage {...confirmEditModalProps.modalBody} />
      </ConfirmEditModal>
      <WrappedComponent {...(props as P)} />
    </>
  );
};

export default withConfirmEditModal;
