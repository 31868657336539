import { useState, useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { useDebounce } from "@trace-one/business-components";
import { useBoolean } from "hooks";
import { fetchSubstance, searchSubstances } from "apis/CATALOGUE";
import { useAppDispatch } from "store";
import { selectLanguageCode } from "store/user/selectors";
import { updateSubstanceId } from "store/materialForm/asyncActions";
import {
  AREA_CATEGORY_TYPES,
  numberOfCharactersForAutoComplete,
} from "utils/constants";
import { MaterialData, SubstanceData } from "models";
import { MaterialType } from "types/library";
import { isListEmpty } from "utils/general";

const useSubstanceInformation = ({
  materialType,
  substanceId,
}: {
  materialType: MaterialType;
  substanceId?: MaterialData["substanceId"];
}) => {
  const [substanceList, setSubstanceList] = useState<SubstanceData[]>(null);
  const [selectedSubstance, setSubstance] = useState<SubstanceData>(null);
  const [searchTerm, setSearchTerm] = useState<string>(null);

  const languageCode = useSelector(selectLanguageCode);

  const clearedSubstanceIdRef = useRef(null);

  const debouncedSearchTerm = useDebounce(searchTerm, 1000);

  const dispatch = useAppDispatch();
  const { value: isLoading, setTrue, setFalse } = useBoolean(false);

  const getSubstances = async (searchTerm?: string) => {
    try {
      if (searchTerm === selectedSubstance?.id) return;
      setTrue();

      const { data } = await searchSubstances({
        params: {
          languageCode,
          searchTerm,
          areaCategory: AREA_CATEGORY_TYPES[materialType],
        },
      });

      setSubstanceList(data);
    } catch (error) {
    } finally {
      setFalse();
    }
  };

  const getSubstance = async () => {
    try {
      const { data } = await fetchSubstance({
        substanceId,
        params: { languageCode },
      });
      setSubstance(data);
    } catch (error) {}
  };

  useEffect(() => {
    if (
      clearedSubstanceIdRef.current !== substanceId &&
      substanceList?.some(item => item?.id === substanceId)
    ) {
      setSubstance(
        substanceId
          ? substanceList?.find(item => item?.id === substanceId)
          : null
      );
    } else if (isListEmpty(substanceList) && substanceId) {
      getSubstance();
    }
  }, [substanceList, substanceId]);

  useEffect(() => {
    if (debouncedSearchTerm?.length === 0) {
      getSubstances("");
    }
    if (debouncedSearchTerm?.length >= numberOfCharactersForAutoComplete) {
      getSubstances(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm, languageCode]);

  const onDropdownVisibleChange = (open: boolean) => {
    if (open) {
      getSubstances("");
    }
  };

  const onSelect = async (selectedValue: string) => {
    try {
      await dispatch(updateSubstanceId(selectedValue || null));

      setSubstance(
        selectedValue
          ? substanceList?.find(item => item?.id === selectedValue)
          : null
      );
    } catch {}
  };

  const onClear = () => {
    clearedSubstanceIdRef.current = selectedSubstance?.id;
    setSubstance(null);
  };

  const onSearch = (value: string) => {
    setSearchTerm(value);
  };

  const options = useMemo(() => {
    return substanceList?.map(substance => ({
      label: substance?.name,
      name: substance?.name,
      value: substance?.id,
    }));
  }, [substanceList]);

  return {
    isLoading,
    options,
    selectedSubstance,
    onSearch,
    onSelect,
    onClear,
    onDropdownVisibleChange,
  };
};

export default useSubstanceInformation;
