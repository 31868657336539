import { PaginationProps } from "antd";
import { useState } from "react";
import { PAGE_SIZE_OPTIONS } from "utils/constants";
import { isObjectEmpty } from "utils/general";
import { Sorter } from "types/pagination";

const usePagination = () => {
  const [pagination, setPagination] = useState<PaginationProps>({
    current: 1,
    pageSize: 10,
    defaultCurrent: 1,
    pageSizeOptions: PAGE_SIZE_OPTIONS,
    showSizeChanger: true,
  });
  const [sorter, setSorting] = useState<Sorter>({
    orderBy: null,
    orderByDescending: null,
  });

  const resetCurrentPage = () => {
    setPagination(previousState => ({
      ...previousState,
      current: previousState.defaultCurrent,
    }));
  };

  const onTableChange = (pagination, _, sorter) => {
    setPagination(pagination);

    if (!isObjectEmpty(sorter)) {
      const { field, order } = sorter;

      setSorting({
        orderByDescending: order ? order !== "ascend" : null,
        orderBy: order ? field : null,
      });
    }
  };

  return {
    pagination,
    sorter,
    resetCurrentPage,
    onTableChange,
  };
};

export default usePagination;
