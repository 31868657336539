import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "creation.invitation.title",
    defaultMessage: "Invitation",
  },
  subtitle: {
    id: "creation.invitation.subtitle",
    defaultMessage: "Invite users to collaborate",
  },
});
