import { defineMessages } from "react-intl";

export const messages = defineMessages({
  clickToSign: {
    id: "specificationDetails.signature.click",
    defaultMessage: "Click here to sign for",
  },
  contractSent: {
    id: "specificationDetails.signature.contractSent",
    defaultMessage:
      "The signed version of the contract has been sent by email to all participants",
  },
  notified: {
    id: "specificationDetails.signature.notified",
    defaultMessage: "{name} has been notified of your signature",
  },
  retailerSignature: {
    id: "specificationDetails.signature.retailer",
    defaultMessage: "Retailer signature",
  },
  signature: {
    id: "specificationDetails.signature.signature",
    defaultMessage: "Signature",
  },
  signed: {
    id: "specificationDetails.signature.signed",
    defaultMessage: "You signed this specification",
  },
  signedBy: {
    id: "specificationDetails.signature.signedBy",
    defaultMessage: "Signed By {name}",
  },
  signSpecifications: {
    id: "specificationDetails.signature.signSpecifications",
    defaultMessage: "Sign other specifications",
  },
  specificationNotSignedYet: {
    id: "specificationDetails.signature.specification.notSignedYet",
    defaultMessage: "The Specification has not yet been signed",
  },
  specificationSigned: {
    id: "specificationDetails.signature.specification.signed",
    defaultMessage: "This specification is signed",
  },
  specificationsLeft: {
    id: "specificationDetails.signature.specification.left",
    defaultMessage: "specifications waiting for signature",
  },
  specificationSignedBySupplier: {
    id: "specificationDetails.signature.specification.signedBySupplier",
    defaultMessage: "The {name} Specification has been signed by the supplier",
  },
  viewAllSpecifications: {
    id: "specificationDetails.signature.viewAllSpecifications",
    defaultMessage: "View all specifications",
  },
  waitingForSignature: {
    id: "specificationDetails.signature.waiting.signature",
    defaultMessage: "Waiting for signature",
  },
  waitingStateDigitalSignaturePrepared: {
    id: "specificationDetails.signature.waitingState.digitalSignature",
    defaultMessage: "Your signature is being prepared",
  },
  doNotQuit: {
    id: "specificationDetails.signature.doNotQuit",
    defaultMessage: "Please wait.",
  },
  digitalSigningErrorText: {
    id: "specificationDetails.signature.digitalSigningErrorText",
    defaultMessage:
      "An error occurred during the signing process, please close this modal and try again",
  },
});
