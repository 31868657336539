import { defineMessages } from "react-intl";

export const messages = defineMessages({
  primary: {
    id: "specificationDetails.widgets.packagingSystem.usageLevelPrimary",
    defaultMessage: "Primary",
  },
  secondary: {
    id: "specificationDetails.widgets.packagingSystem.usageLevelSecondary",
    defaultMessage: "Secondary",
  },
  tertiary: {
    id: "specificationDetails.widgets.packagingSystem.usageLevelTertiary",
    defaultMessage: "Tertiary",
  },
});
