import { useLocation } from "react-router-dom";
import { useDetectLeavingPage } from "hooks";
import { scrollToTop } from "utils/general";
import { UseDeleteAndKeepDraftModalProps } from "./types";

const useDeleteAndKeepDraftModal = ({
  id,
  onDeleteAction,
  onKeepAsDraftAction,
  shouldScrollToTop = true,
}: UseDeleteAndKeepDraftModalProps) => {
  const { pathname } = useLocation();
  const {
    wantsToLeaveThePage,
    declineLeavingThePage,
    confirmLeavingThePage,
    intendedLeavingPage,
  } = useDetectLeavingPage({
    currentPath: pathname,
  });

  const handleDelete = async () => {
    if (id) {
      await onDeleteAction(intendedLeavingPage);
    }

    confirmLeavingThePage();
  };

  const handleKeepAsDraft = async () => {
    try {
      await onKeepAsDraftAction?.();

      confirmLeavingThePage();
    } catch (_) {
      declineLeavingThePage();
      if (shouldScrollToTop) {
        scrollToTop(0, "smooth");
      }
    }
  };

  const handleCancelModal = () => {
    declineLeavingThePage();
  };

  return {
    wantsToLeaveThePage,
    handleDelete,
    handleKeepAsDraft,
    handleCancelModal,
  };
};

export default useDeleteAndKeepDraftModal;
