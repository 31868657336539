import { Modal, Paragraph } from "@trace-one/design-system";
import { useIntl } from "react-intl";
import { useDeleteAndKeepDraftModal } from "./hooks";
import { messages } from "./messages";
import { DeleteAndKeepDraftModalProps } from "./types";

const DeleteAndKeepDraftModal: React.FC<DeleteAndKeepDraftModalProps> = ({
  id,
  title,
  description,
  primaryButtonText,
  secondaryButtonText,
  onDeleteAction,
  onKeepAsDraftAction,
  primaryButtonProps = {},
  secondaryButtonProps = {},
  shouldScrollToTop = true,
}) => {
  const { formatMessage } = useIntl();

  const {
    wantsToLeaveThePage,
    handleDelete,
    handleKeepAsDraft,
    handleCancelModal,
  } = useDeleteAndKeepDraftModal({
    id,
    onDeleteAction,
    onKeepAsDraftAction,
    shouldScrollToTop,
  });

  return (
    <Modal.Simple
      visible={wantsToLeaveThePage}
      size="s"
      keyboard={false}
      onCancel={handleCancelModal}
      onPrimaryButtonClick={handleDelete}
      primaryButtonText={primaryButtonText}
      onSecondaryButtonClick={handleKeepAsDraft}
      secondaryButtonText={
        secondaryButtonText ?? formatMessage(messages.keepAsDraftBtn)
      }
      illustrationName="delete"
      illustrationColor="grey"
      title={title}
      primaryButtonProps={primaryButtonProps}
      secondaryButtonProps={secondaryButtonProps}
    >
      <Paragraph>{description}</Paragraph>
    </Modal.Simple>
  );
};

export default DeleteAndKeepDraftModal;
