import { Row } from "antd";
import classNames from "classnames";
import { Typography } from "@trace-one/design-system";
import Status from "components/Status";
import { useFormSchemaTranslation } from "pages/Specification/components/Details/hooks";
import styles from "./styles.module.less";

export type NutriscorePointDetailsProps = {
  name: string;
  score: number;
  statusColor: string;
};

const NutriscorePointDetails: React.FC<NutriscorePointDetailsProps> = ({
  name,
  score,
  statusColor,
}) => {
  const { getTranslationForKey } = useFormSchemaTranslation();

  return (
    <Row align="middle" wrap={false} className="mb-1" key={name}>
      <Status.Dot
        className={`bg-${statusColor} mr-2 flex-grow-0 flex-shrink-0`}
      />
      <Typography
        variant="heading-xxs"
        color="grey-5"
        className="flex-grow-1 word-break"
      >
        {name}
      </Typography>
      <Typography
        variant="body-regular"
        color="grey-5"
        className="ml-7 flex-grow-0 flex-shrink-0 word-break"
      >
        <span
          className={classNames(
            "d-inline-block",
            styles["nutriscore-points--score"]
          )}
        >
          {score}
        </span>
        <span
          className={classNames(
            "d-inline-block",
            styles["nutriscore-points--points-label"]
          )}
        >
          {score === 0 || score === 1
            ? getTranslationForKey("nutriscorePoint")
            : getTranslationForKey("nutriscorePoints")}
        </span>
      </Typography>
    </Row>
  );
};

export default NutriscorePointDetails;
