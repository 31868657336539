import { useEffect, useState } from "react";
import { isListEmpty } from "utils/general";
import { getInfoFromValue } from "components/SpecificationHistorisationModal/utils";
import { SectionSimpleViewData } from "models";
import { HistorisationContextProps } from "components/SpecificationHistorisationModal/types";
import { TreeSelectProps } from "antd";
import { Dictionary } from "types/general";

const useChapters = ({
  chapters,
  filterTags,
  addChapter,
  clearChapter,
  onClearFiltersClick,
}: {
  addChapter: HistorisationContextProps["addChapter"];
  clearChapter: HistorisationContextProps["clearChapter"];
  onClearFiltersClick: HistorisationContextProps["onClearFiltersClick"];
  filterTags: HistorisationContextProps["filterTags"];
  chapters: SectionSimpleViewData[];
}) => {
  const [treeData, setTreeData] = useState<TreeSelectProps["treeData"]>([]);
  const [
    treeDataNameDictionary,
    setTreeDataNameDictionary,
  ] = useState<Dictionary>({});
  const [selectedChapters, setSelectedChapters] = useState<string[]>([]);

  const onSelect: TreeSelectProps["onSelect"] = (value: string) => {
    const { ids } = getInfoFromValue(value);

    const tagName = ids.map(id => treeDataNameDictionary[id]).join(" / ");

    addChapter(value, tagName);

    setSelectedChapters(previousState => [...previousState, value]);
  };

  const onDeselect: TreeSelectProps["onDeselect"] = (value: string) => {
    clearChapter(value);

    setSelectedChapters(previousState =>
      previousState.filter(currentValue => currentValue !== value)
    );
  };

  const onClear: TreeSelectProps["onClear"] = () => {
    onClearFiltersClick("chapters");

    setSelectedChapters([]);
  };

  const prepareValueForTreeNode = (treeNodeId: string, parentId: string) => {
    if (!parentId) {
      return treeNodeId;
    }

    return `${parentId}#${treeNodeId}`;
  };

  const getTreeDataInfoFromChapters = (
    sections: SectionSimpleViewData[],
    parentId: string = ""
  ) => {
    return sections.reduce(
      (previousState, { sectionId, sections, name, fields }) => {
        const isLeaf = isListEmpty(sections) && isListEmpty(fields);

        const currentElementValue = prepareValueForTreeNode(
          sectionId,
          parentId
        );

        let currentTreeData: TreeSelectProps["treeData"] = [
          {
            id: sectionId,
            title: name,
            pId: parentId,
            key: `section#${currentElementValue}`,
            value: `section#${currentElementValue}`,
            isLeaf,
          },
        ];

        let currentNameDictionary: Dictionary = {
          [sectionId]: name,
        };

        if (!isListEmpty(sections)) {
          const {
            treeData: sectionsTreeData,
            nameDictionary: sectionsNameDictionary,
          } = getTreeDataInfoFromChapters(sections, sectionId);
          currentTreeData.push(...sectionsTreeData);
          currentNameDictionary = {
            ...currentNameDictionary,
            ...sectionsNameDictionary,
          };
        }

        if (!isListEmpty(fields)) {
          currentTreeData.push(
            ...fields.map(field => {
              const fieldId = `${field.propertyId}@${field.templateBlockId}`;
              const fieldValue = prepareValueForTreeNode(
                fieldId,
                currentElementValue
              );

              currentNameDictionary[fieldId] = field.name;

              return {
                id: fieldId,
                pId: sectionId,
                title: field.name,
                key: `field#${fieldValue}`,
                value: `field#${fieldValue}`,
                isLeaf: true,
              };
            })
          );
        }

        return {
          treeData: [...previousState.treeData, ...currentTreeData],
          nameDictionary: {
            ...previousState.nameDictionary,
            ...currentNameDictionary,
          },
        };
      },
      { treeData: [], nameDictionary: {} }
    );
  };

  useEffect(() => {
    const treeDataInfo = getTreeDataInfoFromChapters(chapters);

    setTreeData(treeDataInfo.treeData);
    setTreeDataNameDictionary(treeDataInfo.nameDictionary);
  }, [chapters.length]);

  useEffect(() => {
    setSelectedChapters(filterTags?.chapters.map(({ value }) => value));
  }, [filterTags?.chapters]);

  return {
    treeData,
    selectedChapters,
    onSelect,
    onDeselect,
    onClear,
  };
};

export default useChapters;
