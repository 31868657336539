import React from "react";
import classNames from "classnames";
import styles from "./styles.module.less";
import { ErrorListProps } from "./types";

const ErrorsList: React.FC<ErrorListProps> = ({ list = [] }) => {
  const errorList = [...new Set(list)];

  if (errorList.length === 1) {
    return <span className={styles.errorColor}>{errorList[0]}</span>;
  }

  return (
    <ul className={classNames(styles.errorList, styles.errorColor)}>
      {errorList.map(error => (
        <li key={`error-${error}`}>{error}</li>
      ))}
    </ul>
  );
};

export default ErrorsList;
